import '../App.css';
import React from 'react';
import { connect } from 'react-redux'
import { language } from '../language';
import Form from 'react-bootstrap/Form'
import { Spinner } from 'react-bootstrap'
const { getPackageDetails } = require('../Api');

class DisplayPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // ID: this.props.packageDetails.PackageID,
      // Games: [],
      ParentCommission: {},
      ParentOdd: {},
      Commission: {},
      Odd: {},
      loading: true,
      activated: false,
    }
  }

  async componentDidMount() {
    const packageDetails = await getPackageDetails(this.props.username, this.props.token, this.props.SelectedPackageID, null)
    const parentCommissionJSON = JSON.parse(packageDetails.parentPackage.Commission)
    const parentOddJSON = JSON.parse(packageDetails.parentPackage.Odd)
    if (packageDetails.childPackage) {
      const childCommissionJSON = JSON.parse(packageDetails.childPackage.Commission)
      const childOddJSON = JSON.parse(packageDetails.childPackage.Odd)
      this.setState({
        ParentCommission: parentCommissionJSON,
        ParentOdd: parentOddJSON,
        Commission: JSON.parse(JSON.stringify(childCommissionJSON)),
        Odd: JSON.parse(JSON.stringify(childOddJSON)),
        loading: false,
        activated: true,
      })
    } else {
      this.setState({ 
        ParentCommission: parentCommissionJSON,
        ParentOdd: parentOddJSON,
        loading: false,
      })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Spinner style={{ margin: 10}} animation="border" />
      )
    }
    return(
      <div className="dcc" style={{ overflowX: 'auto' }}>
        <div style={{width: '100%', borderRadius: 10, paddingTop: 10, color: '#000'}}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', fontSize: 20, justifyContent: 'center', marginBottom: 10}}>
            <div style={{ marginLeft: 20, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Games}: {'Default'}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width:'100%'}}>
            <div style={{ margin: '0px 10px', width: 90, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Water}</div>
            {this.state.activated && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
              { this.props.AdminModal && <div style={{ paddingBottom: 38 }}/> }
            </div>}
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                {['大', '小', '单A', '干字(A)', 'CF(ABC)', '百字','5D', '6D'].map((item, idx) => {
                  return <div key={idx} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, width: 100, fontWeight: 'bold'}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                  </div>
                })}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {(['B', 'S', 'SA', 'CA','CF', 'PA', '5D', '6D']).map((item, index) => {
                  return <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <Form.Control
                      disabled={true}
                      style={{ width: 100, textAlign: 'center',fontWeight: 'bold'}} 
                      type="text"
                      value={this.state.Commission['Default'] ? this.state.Commission['Default'][item] : 
                        this.state.ParentCommission['Default'][item]}
                    />
                  </div>
                })}
              </div>
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'center', width:'100%'}}>
            <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 0}}>
                <div style={{ margin: '0px 10px', width: 90, fontWeight: 'bold'}}>{'奖金 1'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {(['B', 'S', 'SA', 'CA','CF1', 'PA', '5D', '6D']).map((item, idd) => {
                  return <div key={idd} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <Form.Control 
                      disabled={true}
                      style={{ width: 100, textAlign: 'center',fontWeight: 'bold'}} 
                      type="text"
                      value={
                        (item === 'B' || item === 'S'  || item === 'SA') ? this.state.ParentOdd['Default']['1ST'][item] 
                          : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.ParentOdd['Default'][item] 
                            : (item === '5D') ?  this.state.ParentOdd['Default']['5D']['5D1'] 
                              :   this.state.ParentOdd['Default']['6D'][item]
                      } 
                    />
                  </div>
                })}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width:'100%'}}>
            <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 0}}>
                <div style={{ margin: '0px 10px', width: 90, fontWeight: 'bold'}}>{'奖金 2'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {(['2B', '2S', 'SB', 'CB', 'CF2','PB', '5D2', '6D2']).map((item, idn) => {
                  return <div key={idn} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <Form.Control 
                      disabled={true}
                      style={{ width: 100, textAlign: 'center',fontWeight: 'bold'}} 
                      type="text"
                      value={(item === '2B' || item === '2S' || item === 'SB') ?    this.state.ParentOdd['Default']['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                        : (item === 'CB' || item === 'PB'|| item === 'CF2') ?   this.state.ParentOdd['Default'][item] 
                          : (item === '5D2') ?   this.state.ParentOdd['Default']['5D'][item] 
                            :   this.state.ParentOdd['Default']['6D'][item]}
                    />
                  </div>
                })}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width:'100%'}}>
            <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 0}}>
                <div style={{ margin: '0px 10px', width: 90, fontWeight: 'bold'}}>{'奖金 3'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {(['3B', '3S', 'SC', 'CC','CF3', 'PC', '5D3', '6D3']).map((item, idh) => {
                  return <div key={idh} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <Form.Control 
                      disabled={true}
                      style={{ width: 100, textAlign: 'center',fontWeight: 'bold'}} 
                      type="text"
                      value={
                        (item === '3B' || item === '3S' || item === 'SC') ?  this.state.ParentOdd['Default']['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                          : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.ParentOdd['Default'][item] 
                            : (item === '5D3') ?  this.state.ParentOdd['Default']['5D'][item] 
                              :   this.state.ParentOdd['Default']['6D'][item]}
                    />
                  </div>
                })}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width:'100%'}}>
            <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 0}}>
                <div style={{ margin: '0px 10px', width: 90, fontWeight: 'bold'}}>{'特别'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {(['B', 'empty', 'SD', 'CD', 'emptyC', 'PD', '5D4', '6D4']).map((item, idf) => {
                  return <div key={idf} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    {item !== 'empty' && item !== 'emptyC' ? 
                      <Form.Control 
                        disabled={true}
                        style={{ width: 100, textAlign: 'center',fontWeight: 'bold'}} 
                        type="text"
                        value={(item === 'B' || item === 'SD') ?  this.state.ParentOdd['Default']['4TH'][item] 
                          : (item === 'CD' || item === 'PD') ?  this.state.ParentOdd['Default'][item] 
                            : (item === '5D4') ?  this.state.ParentOdd['Default']['5D'][item] 
                              : this.state.ParentOdd['Default']['6D'][item]
                        }
                      />
                      : 
                      <div style={{ width: 100, textAlign: 'center'}} ></div>
                    }
                  </div>
                })}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width:'100%'}}>
            <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 0}}>
                <div style={{ margin: '0px 10px', width: 90, fontWeight: 'bold'}}>{'安慰奖'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {(['B', 'empty', 'SE', 'CE', 'emptyC','PE', '5D5', '6D5']).map((item, idb) => {
                  return <div key={idb} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    {item !== 'empty' && item !== 'emptyC' ? 
                      <Form.Control 
                        disabled={true}
                        style={{ width: 100, textAlign: 'center',fontWeight: 'bold'}} 
                        type="text"
                        value={(item === 'B' || item === 'SE') ? this.state.ParentOdd['Default']['5TH'][item]
                          : (item === 'CE' || item === 'PE') ? this.state.ParentOdd['Default'][item] 
                            : (item === '5D5') ? this.state.ParentOdd['Default']['5D'][item] 
                              : this.state.ParentOdd['Default']['6D'][item]
                        }
                      />
                      : 
                      <div style={{ width: 100, textAlign: 'center'}} ></div>
                    }
                  </div>
                })}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', width:'100%'}}>
            <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 0}}>
                <div style={{ margin: '0px 10px', width: 90, fontWeight: 'bold'}}>{'奖金 6'}</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {(['empty', 'empty2', 'SF', 'emptyD',  'emptyC', 'PF', '5D6', 'empty3']).map((item, iid) => {
                  return <div key={iid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    {item !== 'empty' && item !== 'empty2' && item !== 'empty3' && item !== 'emptyC' && item !== 'emptyD' ? 
                      <Form.Control 
                        disabled={true}
                        style={{ width: 100, textAlign: 'center',fontWeight: 'bold'}} 
                        type="text"
                        value={(item === 'SF' ) ?  this.state.ParentOdd['Default']['1ST'][item] 
                          : (item === 'CD' || item === 'PF') ?  this.state.ParentOdd['Default'][item] :
                            this.state.ParentOdd['Default']['5D'][item]}
                      />
                      : 
                      <div style={{ width: 100, textAlign: 'center'}} ></div>
                    }
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(DisplayPackage);