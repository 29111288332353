import '../App.css';
import React, {Fragment}  from 'react';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import Header2 from '../Component/Header';
import Table from 'react-bootstrap/Table'
import { findPermutations } from '../utility/Permutation';
import { Button } from 'react-bootstrap';
const { getDataByLink, geti82Data, } = require('../Api');

class GameResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wordBettingModal: false,
      wordBettingToShow: {
        B: [],
        S: [],
        SA: [],
        SB: [],
        SC: [],
        SD: [],
        SE: [],
        SF: [],
        CA: [],
        CB: [],
        CC: [],
        CD: [],
        CE: [],
        CF: [],
        PA: [],
        PB: [],
        PC: [],
        PD: [],
        PE: [],
        PF: [],
        '5D': [],
        '6D': [],
      },
      alreadyCalculateWordBetting: false,
      wordBettingSortingAsc: true,
      betmodeToRepeat: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D'],
      pausing: false,
      toCopyWord: '',
      includeI82: true,
    }
  }
  
  async componentDidMount() {
    this.pullResult('8')
  }
  
  
  convertssdatatoresult(ssdata, ptdata, jjdata, i82data) {
    let sumss = 0
    let sumpt = 0
    let sumjj = 0
    let sum82 = 0
    let sumall = 0
    const result = {}
    for (let k = 0; k < 3; k += 1) {
      let dataToCount = {}
      let sumToUse = 0
      if (k === 0) {
        dataToCount = ssdata
      } else if (k === 1) {
        dataToCount = ptdata
      } else if (k === 2) {
        dataToCount = jjdata
      }

      for (let i = 0; i < dataToCount.length; i += 1) {
        sumToUse += Number(dataToCount[i].sum)
        if (!result[dataToCount[i].betNumber]) {
          result[dataToCount[i].betNumber] = {
            B: 0,
            S: 0,
            SA: 0,
            SB: 0,
            SC: 0,
            SD: 0,
            SE: 0,
            SF: 0,
            IBB: 0,
            IBS: 0,
            IBSA: 0,
            IBSB: 0,
            IBSC: 0,
            IBSD: 0,
            IBSE: 0,
            IBSF: 0,
            CA: 0,
            CB: 0,
            CC: 0,
            CD: 0,
            CE: 0,
            CF: 0,
            PA: 0,
            PB: 0,
            PC: 0,
            PD: 0,
            PE: 0,
            PF: 0,
            '5D': 0,
            '6D': 0,
          }
        }
        if (dataToCount[i].gameRelationId === '100') {
          result[dataToCount[i].betNumber].B += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '200') {
          result[dataToCount[i].betNumber].S += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '300') {
          result[dataToCount[i].betNumber].SA += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '400') {
          result[dataToCount[i].betNumber].SF += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '500') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].B += costForEachPermutation
            result[allPermutation[j]].IBB += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '600') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].S += costForEachPermutation
            result[allPermutation[j]].IBS += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '700') {
          const wordToRecord = dataToCount[i].betNumber.length === 3 ? dataToCount[i].betNumber : dataToCount[i].betNumber.substring(1, 4)
          if (!result[wordToRecord]) {
            result[wordToRecord] = {
              B: 0,
              S: 0,
              SA: 0,
              SB: 0,
              SC: 0,
              SD: 0,
              SE: 0,
              SF: 0,
              IBB: 0,
              IBS: 0,
              IBSA: 0,
              IBSB: 0,
              IBSC: 0,
              IBSD: 0,
              IBSE: 0,
              IBSF: 0,
              CA: 0,
              CB: 0,
              CC: 0,
              CD: 0,
              CE: 0,
              CF: 0,
              PA: 0,
              PB: 0,
              PC: 0,
              PD: 0,
              PE: 0,
              PF: 0,
              '5D': 0,
              '6D': 0,
            }
          }
          result[wordToRecord].CA += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '900') {
          const wordToRecord = dataToCount[i].betNumber.length === 3 ? dataToCount[i].betNumber : dataToCount[i].betNumber.substring(1, 4)
          if (!result[wordToRecord]) {
            result[wordToRecord] = {
              B: 0,
              S: 0,
              SA: 0,
              SB: 0,
              SC: 0,
              SD: 0,
              SE: 0,
              SF: 0,
              IBB: 0,
              IBS: 0,
              IBSA: 0,
              IBSB: 0,
              IBSC: 0,
              IBSD: 0,
              IBSE: 0,
              IBSF: 0,
              CA: 0,
              CB: 0,
              CC: 0,
              CD: 0,
              CE: 0,
              CF: 0,
              PA: 0,
              PB: 0,
              PC: 0,
              PD: 0,
              PE: 0,
              PF: 0,
              '5D': 0,
              '6D': 0,
            }
          }
          result[wordToRecord].CF += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '1200') {
          const wordToRecord = dataToCount[i].betNumber.length === 2 ? dataToCount[i].betNumber : dataToCount[i].betNumber.substring(2, 4)
          if (!result[wordToRecord]) {
            result[wordToRecord] = {
              B: 0,
              S: 0,
              SA: 0,
              SB: 0,
              SC: 0,
              SD: 0,
              SE: 0,
              SF: 0,
              IBB: 0,
              IBS: 0,
              IBSA: 0,
              IBSB: 0,
              IBSC: 0,
              IBSD: 0,
              IBSE: 0,
              IBSF: 0,
              CA: 0,
              CB: 0,
              CC: 0,
              CD: 0,
              CE: 0,
              CF: 0,
              PA: 0,
              PB: 0,
              PC: 0,
              PD: 0,
              PE: 0,
              PF: 0,
              '5D': 0,
              '6D': 0,
            }
          }
          result[wordToRecord].PA += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '1300') {
          const wordToRecord = dataToCount[i].betNumber.length === 2 ? dataToCount[i].betNumber : dataToCount[i].betNumber.substring(2, 4)
          if (!result[wordToRecord]) {
            result[wordToRecord] = {
              B: 0,
              S: 0,
              SA: 0,
              SB: 0,
              SC: 0,
              SD: 0,
              SE: 0,
              SF: 0,
              IBB: 0,
              IBS: 0,
              IBSA: 0,
              IBSB: 0,
              IBSC: 0,
              IBSD: 0,
              IBSE: 0,
              IBSF: 0,
              CA: 0,
              CB: 0,
              CC: 0,
              CD: 0,
              CE: 0,
              CF: 0,
              PA: 0,
              PB: 0,
              PC: 0,
              PD: 0,
              PE: 0,
              PF: 0,
              '5D': 0,
              '6D': 0,
            }
          }
          result[wordToRecord].PF += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '1400') {
          result[dataToCount[i].betNumber].SB += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '1800') {
          result[dataToCount[i].betNumber].SC += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '2200') {
          result[dataToCount[i].betNumber].SD += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '2300') {
          result[dataToCount[i].betNumber].SE += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '2410') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SA += costForEachPermutation
            result[allPermutation[j]].IBSA += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2420') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SB += costForEachPermutation
            result[allPermutation[j]].IBSB += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2430') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SC += costForEachPermutation
            result[allPermutation[j]].IBSC += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2440') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SF += costForEachPermutation
            result[allPermutation[j]].IBSF += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2450') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SD += costForEachPermutation
            result[allPermutation[j]].IBSD += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2460') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SE += costForEachPermutation
            result[allPermutation[j]].IBSE += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '5000') {
          result[dataToCount[i].betNumber]['5D'] += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '6000') {
          result[dataToCount[i].betNumber]['6D'] += Number(dataToCount[i].sum)
        } 
      }
      if (k === 0) {
        sumss = sumToUse
        sumall += sumToUse
      } else if (k === 1) {
        sumpt = sumToUse
        sumall += sumToUse
      } else if (k === 2) {
        sumjj = sumToUse
        sumall += sumToUse
      }
    }
    if (i82data) {
      let sumToUse = 0
      for (let i = 0; i < i82data.length; i += 1) {
        if (!result[i82data[i].Word]) {
          result[i82data[i].Word] = {
            B: 0,
            S: 0,
            SA: 0,
            SB: 0,
            SC: 0,
            SD: 0,
            SE: 0,
            SF: 0,
            IBB: 0,
            IBS: 0,
            IBSA: 0,
            IBSB: 0,
            IBSC: 0,
            IBSD: 0,
            IBSE: 0,
            IBSF: 0,
            CA: 0,
            CB: 0,
            CC: 0,
            CD: 0,
            CE: 0,
            CF: 0,
            PA: 0,
            PB: 0,
            PC: 0,
            PD: 0,
            PE: 0,
            PF: 0,
            '5D': 0,
            '6D': 0,
          }
        }
        if (i82data[i].B) { result[i82data[i].Word].B += i82data[i].B; sumToUse += i82data[i].B }
        if (i82data[i].S) { result[i82data[i].Word].S += i82data[i].S; sumToUse += i82data[i].S }
        if (i82data[i].SA) { result[i82data[i].Word].SA += i82data[i].SA; sumToUse += i82data[i].SA }
        if (i82data[i].SB) { result[i82data[i].Word].SB += i82data[i].SB; sumToUse += i82data[i].SB }
        if (i82data[i].SC) { result[i82data[i].Word].SC += i82data[i].SC; sumToUse += i82data[i].SC }
        if (i82data[i].SD) { result[i82data[i].Word].SD += i82data[i].SD; sumToUse += i82data[i].SD }
        if (i82data[i].SE) { result[i82data[i].Word].SE += i82data[i].SE; sumToUse += i82data[i].SE }
        if (i82data[i].SF) { result[i82data[i].Word].SF += i82data[i].SF; sumToUse += i82data[i].SF }
        if (i82data[i].CA) { result[i82data[i].Word].CA += i82data[i].CA; sumToUse += i82data[i].CA }
        if (i82data[i].CB) { result[i82data[i].Word].CB += i82data[i].CB; sumToUse += i82data[i].CB }
        if (i82data[i].CC) { result[i82data[i].Word].CC += i82data[i].CC; sumToUse += i82data[i].CC }
        if (i82data[i].CD) { result[i82data[i].Word].CD += i82data[i].CD; sumToUse += i82data[i].CD }
        if (i82data[i].CE) { result[i82data[i].Word].CE += i82data[i].CE; sumToUse += i82data[i].CE }
        if (i82data[i].CF) { result[i82data[i].Word].CF += i82data[i].CF; sumToUse += i82data[i].CF }
        if (i82data[i].PA) { result[i82data[i].Word].PA += i82data[i].PA; sumToUse += i82data[i].PA }
        if (i82data[i].PB) { result[i82data[i].Word].PB += i82data[i].PB; sumToUse += i82data[i].PB }
        if (i82data[i].PC) { result[i82data[i].Word].PC += i82data[i].PC; sumToUse += i82data[i].PC }
        if (i82data[i].PD) { result[i82data[i].Word].PD += i82data[i].PD; sumToUse += i82data[i].PD }
        if (i82data[i].PE) { result[i82data[i].Word].PE += i82data[i].PE; sumToUse += i82data[i].PE }
        if (i82data[i].PF) { result[i82data[i].Word].PF += i82data[i].PF; sumToUse += i82data[i].PF }
        if (i82data[i]['5D']) { result[i82data[i].Word]['5D'] += i82data[i]['5D']; sumToUse += i82data[i]['5D'] }
        if (i82data[i]['6D']) { result[i82data[i].Word]['6D'] += i82data[i]['6D']; sumToUse += i82data[i]['6D'] }
      }
      sum82 = sumToUse
      sumall += sumToUse
    }
    this.setState({ result, totalSalesss: sumss.toFixed(2), totalSalespt: sumpt.toFixed(2), totalSalesjj: sumjj.toFixed(2), totalSales82: sum82.toFixed(2), totalSales: sumall.toFixed(2) }, () => {this.openWordBettingModal()})
  }
  async pullResult(type) {
    if (type === '8' && this.state.includeI82) {
      this.setState({ Selectedgame: type, pullingProgress: '拉 SS 中' })
      const ssdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://91.121.33.26/index.php?r=api/emhosrapmfqe80i3j&gh8gevcvakhpjev6=89morkzr1gyqxfod`)
      this.setState({ pullingProgress: '拉 PT 中'})
      const ptdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://54.38.221.165/index.php?r=api/whqtj6mhit7iptp6&casg8jpysqii321l=ioshwpxe1l60nevj`)
      this.setState({ pullingProgress: '拉 JJ 中'})
      const jjdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://51.68.194.199/index.php?r=api/0ytfznmwr6aj9oco&fxxl0sig30460wth=rll0hpimhc3twm8n`)
      this.setState({ pullingProgress: '拉 82 中'})
      const i82data = await geti82Data(this.props.username, this.props.token)
      this.setState({ pullingProgress: '拉完了'})
      this.convertssdatatoresult(ssdata, ptdata, jjdata, i82data)
    } else {
      this.setState({ Selectedgame: type, pullingProgress: '拉 SS 中' })
      const ssdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://91.121.33.26/index.php?r=api/emhosrapmfqe80i3j&gh8gevcvakhpjev6=89morkzr1gyqxfod`)
      this.setState({ pullingProgress: '拉 PT 中'})
      const ptdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://54.38.221.165/index.php?r=api/whqtj6mhit7iptp6&casg8jpysqii321l=ioshwpxe1l60nevj`)
      this.setState({ pullingProgress: '拉 JJ 中'})
      const jjdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://51.68.194.199/index.php?r=api/0ytfznmwr6aj9oco&fxxl0sig30460wth=rll0hpimhc3twm8n`)
      this.setState({ pullingProgress: '拉完了'})
      this.convertssdatatoresult(ssdata, ptdata, jjdata)
    }
  }

  openWordBettingModal() {
    const tempWord = JSON.parse(JSON.stringify(this.state.result))
    const betmodeToRepeat = this.state.betmodeToRepeat
    const wordBettingToShow = {
      B: [],
      S: [],
      SA: [],
      SB: [],
      SC: [],
      SD: [],
      SE: [],
      SF: [],
      CA: [],
      CB: [],
      CC: [],
      CD: [],
      CE: [],
      CF: [],
      PA: [],
      PB: [],
      PC: [],
      PD: [],
      PE: [],
      PF: [],
      '5D': [],
      '6D': [],
    }
    const wordArray = Object.keys(tempWord)
    for (let i = 0; i < wordArray.length; i += 1) {
      for (let j = 0; j < betmodeToRepeat.length; j += 1) {
        if (tempWord[wordArray[i]][betmodeToRepeat[j]] !== 0) {
          wordBettingToShow[betmodeToRepeat[j]].push({ w: wordArray[i], a: tempWord[wordArray[i]][betmodeToRepeat[j]]})
        }
      }
    }
    for (let i = 0; i < betmodeToRepeat.length; i += 1) {
      if (this.state.wordBettingSortingAsc) {
        wordBettingToShow[betmodeToRepeat[i]].sort((j1, j2) => j1.a - j2.a);
      } else {
        wordBettingToShow[betmodeToRepeat[i]].sort((j1, j2) => j1.a - j2.a);
      }
    }
    this.setState({ wordBettingModal: true, wordBettingToShow, alreadyCalculateWordBetting: true })
  }

  changeWordBettingSort() {
    const betmodeToRepeat = this.state.betmodeToRepeat
    const wordBettingToShow = JSON.parse(JSON.stringify(this.state.wordBettingToShow))
    for (let i = 0; i < betmodeToRepeat.length; i += 1) {
      if (this.state.wordBettingSortingAsc) {
        wordBettingToShow[betmodeToRepeat[i]].sort((j1, j2) => j2.a - j1.a);
      } else {
        wordBettingToShow[betmodeToRepeat[i]].sort((j1, j2) => j1.a - j2.a);
      }
    }
    this.setState({ wordBettingToShow, wordBettingSortingAsc: !this.state.wordBettingSortingAsc})
  }

  render() {
    return(
      <div style={{ width: '100%', flexDirection: 'column', overflowY: 'auto' }}> 
        <div style={{ marginBottom: '2%'}}>
          <Header2 />
        </div>
        <div>
          {this.state.pullingProgress}
          <Button onClick={() => this.changeWordBettingSort()}>{this.state.wordBettingSortingAsc ? '▲' : '▼'}</Button>
        </div>
        <div style={{display: 'flex', flexWrap:'wrap'}}>
          {this.state.betmodeToRepeat.map((betmode) => 
            <Table style={{display: 'flex', flexDirection: 'column', height: 200, margin: 10, width: 200}}>
              <tbody style={{ flexWrap: 'wrap', display: 'flex',  justifyContent : 'space-between', overflowY: 'scroll', maxHeight:'150px', flexDirection: 'row' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20}}>{betmode}</div>
                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', backgroundColor: '#52D017', fontWeight: 'bold', justifyContent: 'space-evenly'}}>
                  <div>字</div>
                  <div>下注</div>
                </div>
                {this.state.wordBettingToShow[betmode].map((item, index) => <div style={{ width: '100%', flexDirection: 'row', display: 'flex', backgroundColor: (index%2) ? 'white' : '#D3D3D3', fontWeight: 'bold', justifyContent: 'space-evenly'}}>
                  <div style={{}}>{item.w}</div>
                  <div>{item.a.toFixed(2)}</div>
                </div>)}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GameResult);