import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Reducers from './reducers/reducer';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import AppFunction from './AppFunction';

function App() {

  const middlewares = applyMiddleware(thunk);
  const store = createStore(
    Reducers, {
      username: localStorage.getItem('Username') || '',
      Role: localStorage.getItem('Role') || '',
      token: localStorage.getItem('Token') || '',
      currentLanguage: localStorage.getItem('currentLanguage') || 'cn',
      userID: localStorage.getItem('userID') || '',
      isSub: localStorage.getItem('isSub') || '',
      UserAuthority: localStorage.getItem('UserAuthority') || '',
      ViewUserTable: localStorage.getItem('ViewUserTable') || '',
    },
    middlewares,
  )

  return (
    <Provider store={store}>
      <AppFunction/>
    </Provider>
  );
}

export default App;

