import '../App.css';
import React from 'react';
import Header from '../Component/Header';
import { connect } from 'react-redux'
import Alert from 'react-bootstrap/Alert';
import { language } from '../language';
// import { isMobile } from 'react-device-detect';
import DatePicker from 'react-datepicker';
import Loading3 from '../Images/loading-3.gif';
import 'react-datepicker/dist/react-datepicker.css';
import WindowOpener from 'react-window-opener';
import Moment from 'moment-timezone';
import { Form, Button } from 'react-bootstrap';
import { Platformcolor } from '../static/PlatformColor';
const { FindNumbers } = require('../Api');

var timer

function PlatformChange(platform) {
  if (platform === '1') return 'MAG'
  else if (platform === '2') return 'DMC'
  else if (platform === '3') return 'TOTO'
  else if (platform === '4') return 'SG'
  else if (platform === '5') return 'SWK'
  else if (platform === '6') return 'SBH'
  else if (platform === '7') return 'SDK'
  else if (platform === '8') return 'GD'
  else if (platform === '9') return '9'
  return 'null'
}

function WordColor(platform) {
  if (platform === '1') return 'black'
  else if (platform === '2') return 'black'
  else if (platform === '3') return 'white'
  else if (platform === '4') return 'black'
  else if (platform === '5') return 'white'
  else if (platform === '6') return 'black'
  else if (platform === '7') return 'black'
  else if (platform === '8') return 'white'
  else if (platform === '9') return 'black'
  return 'null'
}

//
//                       _oo0oo_
//                      o8888888o
//                      88" . "88
//                      (| -_- |)
//                      0\  =  /0
//                    ___/`---'\___
//                  .' \\|     |// '.
//                 / \\|||  :  |||// \
//                / _||||| -:- |||||- \
//               |   | \\\  -  /// |   |
//               | \_|  ''\---/''  |_/ |
//               \  .-\__  '-'  ___/-. /
//             ___'. .'  /--.--\  `. .'___
//          ."" '<  `.___\_<|>_/___.' >' "".
//         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//         \  \ `_.   \_ __\ /__ _/   .-` /  /
//     =====`-.____`.___ \_____/___.-`___.-'=====
//                       `=---='
//
//
//     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//               佛祖保佑         永无BUG

class FindNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Platform: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      Platform2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'],
      Type: ['B', 'S', 'CA', 'CD', 'SD', 'SA', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF'],
      username: this.props.username,
      UserID: this.props.userID,
      token: this.props.token,
      FromDate: Moment(new Date()).format('YYYY-MM-DD'),
      ToDate: Moment(new Date()).format('YYYY-MM-DD'),
      SelectedDate: Moment(new Date()).toDate(),
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      Authority: '',
      SearchNumber: '',
      SelectedGame: 'All',
      SelectedType: 'All',
      GameDisplay: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      ObjectToAPI: {},
      TotalAmount: 0,
      Amount9: 0,
      Amount8: 0,
      Amount1: 0,
      Amount2: 0,
      Amount3: 0,
      Amount4: 0,
      Amount5: 0,
      Amount6: 0,
      Amount7: 0,
      loading: false,
      DisplayObject: {},
      ObjectDetails: {},
      is_visible: false,
      CheckBaiWord: true,
    }
  }

  async componentDidMount() {
    this.SetUsersAuthorities();
    this.setPlatform9();
    this.NumberInput.focus();
  }

  setPlatform9() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if (UserAuthorities.GameBetting.includes('9')) {
      let Platform = ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9']
      let Platform2 = ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9']
      this.setState({ Platform, Platform2})
    }
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }

    // if (node.scrollTop > 400) {
    //   this.setState({ BackgroundC: 'black' })
    // } else if(node.scrollTop < 400) {
    //   this.setState({ BackgroundC: '#efefef'})
    // }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  async ConfirmSearch() {
    this.setState({ loading: true })
    const USerID = (this.state.Authority.isSub === 1) ? this.state.Authority.ActingAs : this.props.userID
    const SelectedGame = this.state.SelectedGame
    const SelectedType = this.state.SelectedType
    const SearchNumber = this.state.SearchNumber
    const SplitNumber = SearchNumber.split('')
    const Todate = Moment(this.state.SelectedDate).format('YYYY-MM-DD')
    if(SearchNumber === '') {
      this.openNotification('EmptyFindNumber')
      this.setState({ notificationType: 'error' });
      this.NumberInput.focus();
    } else if(SplitNumber.length < 2) {
      this.openNotification('minimum4Word')
      this.setState({ notificationType: 'error' });
      this.NumberInput.focus();
    } else {
      const Details = await FindNumbers(USerID, SearchNumber, SelectedGame, SelectedType, this.state.CheckBaiWord, Todate, this.props.token, this.props.username)
      // console.log('Details', Details)
      if(Details.error === 'No Data') {
        this.openNotification('NoDataFound')
        this.setState({ notificationType: 'error', loading: false });
        this.NumberInput.focus();
      } else {
        this.openNotification('Success')
        this.setState({ notificationType: 'Success' });
        this.setState({ DisplayObject: Details.summaryObject, ObjectDetails: Details.orderDetailObject, loading: false }, ()=> {
          this.calculate();
        })
      }
    }
  }

  calculate() {
    const DisplayObject = this.state.DisplayObject
    let tempObj = {
      B: 0,
      S: 0,
      CA: 0,
      CD: 0,
      SD: 0,
      SA: 0,
      SB: 0,
      SC: 0,
      SE: 0,
      SF: 0,
      '5D': 0,
      '6D': 0,
      PA: 0,
      PB: 0,
      PC: 0,
      PD: 0,
      PE: 0,
      PF: 0,
      CB: 0,
      CC: 0,
      CE: 0,
      CF: 0,
    }
    let Amount = 0
    let Amount1 = 0
    let Amount2 = 0
    let Amount3 = 0
    let Amount4 = 0
    let Amount5 = 0
    let Amount6 = 0
    let Amount7 = 0
    let Amount8 = 0
    let Amount9 = 0

    console.log('DisplayObject', DisplayObject)
    Object.keys(DisplayObject).forEach((item) => {
      if(item.includes('1')) {
        const TotalMagnumA = Object.values(DisplayObject[1])
        TotalMagnumA.forEach((amount) => {
          Amount1 += amount
        })
        this.setState({ Amount1 })
      }
      if(item.includes('2')) {
        const TotalMagnumA = Object.values(DisplayObject[2])
        TotalMagnumA.forEach((amount) => {
          Amount2 += amount
        })
        this.setState({ Amount2 })
      }
      if(item.includes('3')) {
        const TotalMagnumA = Object.values(DisplayObject[3])
        TotalMagnumA.forEach((amount) => {
          Amount3 += amount
        })
        this.setState({ Amount3 })
      }
      if(item.includes('4')) {
        const TotalMagnumA = Object.values(DisplayObject[4])
        TotalMagnumA.forEach((amount) => {
          Amount4 += amount
        })
        this.setState({ Amount4 })
      }
      if(item.includes('5')) {
        const TotalMagnumA = Object.values(DisplayObject[5])
        TotalMagnumA.forEach((amount) => {
          Amount5 += amount
        })
        this.setState({ Amount5 })
      }
      if(item.includes('6')) {
        const TotalMagnumA = Object.values(DisplayObject[6])
        TotalMagnumA.forEach((amount) => {
          Amount6 += amount
        })
        this.setState({ Amount6 })
      }
      if(item.includes('7')) {
        const TotalMagnumA = Object.values(DisplayObject[7])
        TotalMagnumA.forEach((amount) => {
          Amount7 += amount
        })
        this.setState({ Amount7 })
      }
      if(item.includes('8')) {
        const TotalMagnumA = Object.values(DisplayObject[8])
        TotalMagnumA.forEach((amount) => {
          Amount8 += amount
        })
        this.setState({ Amount8 })
      }
      if(item.includes('9')) {
        const TotalMagnumA = Object.values(DisplayObject[9])
        TotalMagnumA.forEach((amount) => {
          Amount9 += amount
        })
        this.setState({ Amount9 })
      }
    })
    Object.values(DisplayObject).forEach((item) => {
      tempObj.B += Number(item.B) || 0
      tempObj.S += Number(item.S) || 0
      tempObj.CA += Number(item.CA) || 0
      tempObj.CD += Number(item.CD) || 0
      tempObj.SD += Number(item.SD) || 0
      tempObj.SA += Number(item.SA) || 0
      tempObj.SB += Number(item.SB) || 0
      tempObj.SC += Number(item.SC) || 0
      tempObj.SE += Number(item.SE) || 0
      tempObj.SF += Number(item.SF) || 0
      tempObj['5D'] += Number(item['5D']) || 0
      tempObj['6D'] += Number(item['6D']) || 0
      tempObj.PA += Number(item.PA) || 0
      tempObj.PB += Number(item.PB) || 0
      tempObj.PC += Number(item.PC) || 0
      tempObj.PD += Number(item.PD) || 0
      tempObj.PE += Number(item.PE) || 0
      tempObj.PF += Number(item.PF) || 0
      tempObj.CB += Number(item.CB) || 0
      tempObj.CC += Number(item.CC) || 0
      tempObj.CE += Number(item.CE) || 0
      tempObj.CF += Number(item.CF) || 0
    })
    const TempAmount = Object.values(tempObj)
    TempAmount.forEach((amount) => {
      Amount += amount
    })
    this.setState({ TotalAmount: Amount })
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({
      GamesDisplay: GDisplay,
    })
  }

  async handleSearchText(e, field) {
    const value = (e.target.value).replace(/[^0-9]/g, '')
    this.setState({ [field]: value })
  }

  async SelectedOption(e, SelectedOption) {
    if(SelectedOption === 'Games') {
      this.setState({ SelectedGame: e.target.value })
    } else {
      this.setState({ SelectedType: e.target.value })
    }
  }

  async setSelectedDate(date, mode) {
    this.setState({
      [mode]: date,
    });
  }

  KeyPress(e) {
    if(e.key === 'Enter') {
      this.ConfirmSearch();
    }
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 2000);
  };

  // WindowFunction (DataOrder) {
  //   return DataOrder
  // }

  render() {
    // const Objectlength = Object.values(this.state.DisplayObject).length
    return (
      <div onScroll={(e) => this.handleScroll(e)} className='StartBackground'>
        <div id={'Top'}/>
        <Header />
        <div className="row" style={{ marginBottom: 30, display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '20px' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} 
            show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <div className="dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white', flexDirection: null, height: null, fontWeight: 'bold', padding: 0 }}>
            <div>{language[this.props.currentLanguage].FindNumber}</div>
            <div>{``}</div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            paddingBottom: 30,
            justifyContent: 'space-between',
            marginLeft: '4%',
            flexDirection: 'row',
          }}>
          <div className="dcc row" style={{ marginRight: 10 }}>
            <div style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].Date} : </div>
            <div style={{ marginLeft: 10 }}>
              <DatePicker
                onChange={(date) => this.setSelectedDate(date, 'SelectedDate')}
                dateFormat='dd-MMM-yyyy'
                placeholder="Date"
                // maxDate={new Date()}
                // minDate={new Date(this.state.FromDate)}
                selected={this.state.SelectedDate}
                value={this.state.SelectedDate}
                className={"form-control StyleDatePicker"}
              />
            </div>
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.state.username}</div>
          </div>
        </div>
        <div className='dcc' style={{ width: '100%', flexDirection: 'row', marginBottom: 20, borderRadius: 0 }}>
          <div className='dcc' style={{ flexDirection: 'row', width: 'auto', paddingTop: 0 }}>
            <div style={{ fontWeight: 'bold', width: 'auto', paddingRight: 5 }}>{language[this.props.currentLanguage].nombor}* </div>
            <Form.Control
              value={this.state.SearchNumber}
              style={{
                width: '60%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#303030',
              }}
              onKeyPress={(e) => this.KeyPress(e)}
              maxLength={6}
              ref={(input) => { this.NumberInput = input; }}
              type="text"
              onChange={(e) => this.handleSearchText(e, 'SearchNumber')}
            /></div>
          <div className='dcc' style={{ flexDirection: 'row', marginRight: 20, width: 'auto' }}>
            <div style={{ fontWeight: 'bold', width: 'auto', paddingRight: 5 }}>{language[this.props.currentLanguage].Games}* </div>
            <select className="form-control FindNumberOptionsLList"
              style={{ width: 'auto' }}
              onChange={(e) => this.SelectedOption(e, 'Games')}>
              <option value={'All'}>{`=${language[this.props.currentLanguage].AllDan}=`}</option>
              {((this.state.GameDisplay === 'MKTSWBD') ? this.state.Platform : this.state.Platform2 || []).map((item, idx) => {
                return (
                  <option key={idx} value={idx + 1}>{`${item}`}</option>
                );
              })}
            </select>
          </div>
          <div className='dcc' style={{ flexDirection: 'row', width: 'auto' }}>
            <div style={{ fontWeight: 'bold', width: 'auto', paddingRight: 5 }}>{language[this.props.currentLanguage].Type}* </div>
            <select className="form-control FindNumberOptionsLList"
              style={{ width: 'auto' }}
              onChange={(e) => this.SelectedOption(e, 'Type')}>
              <option value={'All'}>{`=${language[this.props.currentLanguage].AllDan}=`}</option>
              {(this.state.Type || []).map((item, idx) => {
                return (
                  <option key={idx} value={item}>{`${item}`}</option>
                );
              })}
            </select>
          </div>
          <div style={{ width: '15%', padding: 0 }}>
            <Button style={{ textAlign: 'center' , color: 'white', width: '65%', fontWeight: 'bold', marginBottom: 5 }}
              className="button2"
              variant="primary"
              onClick={() => this.ConfirmSearch()}>
              {language[this.props.currentLanguage].Search}
            </Button>
          </div>
          <Form.Check
            style={{ fontSize: 18, alignItems: 'center', display: 'flex', marginLeft: 5, fontWeight: 'bold' }}
            inline
            label={'千字和百字'}
            type="checkbox"
            checked={this.state.CheckBaiWord}
            onChange={(e) => this.setState({ CheckBaiWord: !this.state.CheckBaiWord})}
          />
        </div>
        {this.state.loading === true ? (
          <div style={{ flexDirection: 'column', width: '100%' }}>
            <div>
              <img src={Loading3} alt="Logo" className="logoimages" style={{ marginBottom: 30, marginTop: 100, color: 'black' }} />
              <div className="loadingMessage" style={{ color: 'black' }}>{language[this.props.currentLanguage].Loading}</div>
            </div>
          </div>
        ) : (
          <>
            {Object.values(this.state.DisplayObject).length > 0 && 
        <div style={{ width: '100%'}}>
          <div style={{ width:'95%', textAlign: 'left', paddingLeft: '4%', marginBottom: 20}}>
            <div style={{ border: `1px solid blue`, color: 'white', backgroundColor: '#003466', textAlign: 'center', fontWeight: 'bold', width: (Object.values(this.state.DisplayObject).length >= 4) ? '100%' : (Object.values(this.state.DisplayObject).length === 1) ? '25%' : (Object.values(this.state.DisplayObject).length === 3) ? '75%' : `calc(100% / ${Object.values(this.state.DisplayObject).length})`}}>{'Summary'}</div>
            <div style={{ width: '100%', flexDirection: 'row', display: 'flex', overflowX: 'auto', flexWrap: 'wrap' }}>
              {Object.keys(this.state.DisplayObject).map((item, index) => {
                return <div key={index} style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% / 4)'}}>
                  <div className='dcc scrollhost' style={{ backgroundColor: Platformcolor(item, ''), flexDirection: 'row', color: WordColor(item), width: '100%', marginBottom: 1, maxHeight: 300, overflowY: 'scroll' }} index={index}>
                    <div className='dcc' style={{ width: 70, fontWeight: 'bold' }}>
                      {(PlatformChange(item) === 'null') ? '' : PlatformChange(item)}
                    </div>
                    <div className='dcc' style={{ width: 70, fontWeight: 'bold', flexDirection: 'column', marginTop: 'auto'}}>
                      {Object.keys(this.state.DisplayObject[item]).map((values, idx) => {
                        return <div key={idx}>{Object.keys(this.state.DisplayObject[item])[idx]}</div>
                      })}
                    </div>
                    <div className='dcc' style={{ width: 120, fontWeight: 'bold', flexDirection: 'column', marginTop: 'auto' }}>
                      {Object.keys(this.state.DisplayObject[item]).map((values, idx) => {
                        return <div key={idx}>{(this.state.DisplayObject[item][values] === null) ? '0.00' : (this.state.DisplayObject[item][values]).toFixed(2) || '0.00'}</div>
                      })}
                    </div>
                  </div>
                  <div style={{ fontWeight: 'bold',  backgroundColor: Platformcolor(item, ''), color: WordColor(item), width: 'auto' }}> {`Total : ${(this.state[`Amount${item}`] === null) ? '0.00' : Number(this.state[`Amount${item}`]).toFixed(2) || '0.00'}`}</div></div>
              })}
            </div>
            <div style={{ fontWeight: 'bold', marginRight: 10, textAlign: 'right' }}> {`Total : ${(this.state.TotalAmount === null) ? '0.00' : (this.state.TotalAmount).toFixed(2) || '0.00'}`}</div>
          </div>
          <div className='dcc'>
            <div style={{ width: '95%', overflowX: 'auto' }}>
              <div style={{ width: '100%', flexDirection: 'column' }}>
                <div className='dcc' style={{ flexDirection: 'row', backgroundColor: '#003466', textAlign: 'center', color: 'white' }}>
                  <div style={{ width: '20%' }}>{'Account Name'}</div>
                  <div style={{ width: '20%'  }}>{'Account Login'}</div>
                  <div style={{ width: '60%' }}>{'Details'}</div>
                </div>
                {this.state.ObjectDetails.map((item, index) => {
                  return <div style={{ flexDirection: 'row', display: 'flex' }} key={index}>
                    <div style={{ width: '20%' ,border: `1px solid black` }}>{item.AccountName}</div>
                    <div className='column' style={{ width: '20%' , flexDirection: 'column', border: `1px solid black`}}>
                      <div style={{ fontWeight: 'bold'}}>{item.AccountLoginName}</div>
                      {item.Upperlines.map((Upline, idd) => {
                        return <div key={idd}>{`Upline : ${Upline}`}</div>
                      })}
                    </div>
                    <div className='column' style={{ width: '60%', flexDirection: 'column', border: `1px solid black` }}>
                      {item.Details.map((Datas, indx) => {
                        return <div className='dcc' style={{ flexDirection: 'row', width: '50%', borderBottom: `1px solid #D0D0D0`}} key={indx}>
                          <div style={{ width: '20%', fontWeight: 'bold', cursor: 'not-allowed' }}>{PlatformChange(Datas.platform)}</div>
                          <div style={{ width: '20%', fontWeight: 'bold', cursor: 'not-allowed' }}>{Datas.type}</div>
                          <div style={{ width: '30%', color: 'purple', fontWeight: 'bold', cursor: 'not-allowed'  }}>{(Datas.amount === null) ? '0.00' : (Datas.amount).toFixed(2) || '0.00'}</div>
                          <div style={{ color: 'purple'}}>{Datas.Oriword}</div>
                          <div className='button2' style={{ width: '30%', fontWeight: 'bold', color: 'blue', cursor: 'pointer', borderRadius: 10 }}>
                            {/* <em>[{Datas.orderID}]</em> */}
                            <WindowOpener url={`OpenWindowReceipt?OrderID=${Datas.orderID}&UserID=${Datas.UserID}&Username=${item.AccountName}`} width={350} height={450}><em>[{Datas.orderID}]</em></WindowOpener>
                          </div>
                        </div>
                      })}
                      <div style={{width: 100}}>---总---</div>
                      {['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((platform) => item.Total[platform].T !== 0 && <div style={{display: 'flex'}}>
                        <div style={{ width: 70, fontWeight: 'bold', cursor: 'not-allowed' }}>{PlatformChange(platform)}</div>
                        <div style={{ width: 80, fontWeight: 'bold', cursor: 'not-allowed' }}>B: {Number(item.Total[platform]['B']).toFixed(2)}</div>
                        <div style={{ width: 80, fontWeight: 'bold', cursor: 'not-allowed' }}>S: {Number(item.Total[platform]['S']).toFixed(2)}</div>
                        <div style={{ width: 80, fontWeight: 'bold', cursor: 'not-allowed' }}>SA: {Number(item.Total[platform]['SA']).toFixed(2)}</div>
                      </div>)}
                    </div>
                  </div>
                })}
                <div style={{ padding: 20 }}></div>
              </div>
            </div>
          </div>
        </div>
            }
          </>
        )}  
        {this.state.is_visible && 
          <div className="dcc" style={{ paddingBottom: 30 }}>
            <div class="scrollDesign iconpopup button2"  
              onClick={() => this.scrollToTop()}>
              {/* ↑ ⇈ */}
              <div  className="icon MoveUpArrow"> ⇧ </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
// export default Home;

function mapStateToProps(state) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
    // setDate: (Date) => {
    //   dispatch({
    //     type: 'SELECTED_DATE',
    //     payload: Date,
    //   });
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FindNumber);
