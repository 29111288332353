import '../App.css';
import React from 'react';
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import Header2 from '../Component/Header';
import DatePicker from 'react-datepicker';
// import Moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import Loading3 from '../Images/loading-3.gif';
import { connect } from 'react-redux';
// import { language } from '../language';

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      Maxdate: new Date(),
      loading: false,
      Status: 'Close',
      Username: this.props.username,
    }
  }

  async ConfirmSearch() {
    this.setState({ loading: true })
  }

  async SelectedDate(date) {
    this.setState({ date, loading: true })
  }

  async MaxDate(Maxdate) {
    this.setState({ Maxdate, loading: true }, ()=>{
      this.ConfirmSearch()
    })
  }

  render() {
    return(
      <div style={{ width: '100%', backgroundColor: '#EFEFEF', minHeight: 950, flexDirection: 'column' }}>
        <Header2 />
        <div className="row" style={{ marginBottom: 30, display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '20px' }}>
          <div className = "dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white' }}>
            <FaHome size="25px" color="white" className="logohome" style={{ marginRight: 10 }}/>报告
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 50}}>
          <DatePicker
            onChange = {(date) => this.SelectedDate(date) + this.setState({Status: 'Open'})}
            dateFormat = 'dd-MMM-yyyy'
            placeholder="Date"
            maxDate={new Date()}
            // popperPlacement="top-right"
            selected={this.state.date}
          />
          <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>至</div>
          <DatePicker
            style={{backgroundColor: 'black'}}
            onChange = {(Maxdate) => this.MaxDate(Maxdate) + this.setState({Status: 'Close'})}
            dateFormat = 'dd-MMM-yyyy'
            placeholder="Date"
            maxDate={new Date()}
            minDate={new Date(this.state.date)}
            readOnly={this.state.Status === 'Open' ? false : true}
            selected={this.state.Maxdate}
          />
          <button className="dcc dateButton" style={{ marginLeft: 20, width: '5%' }} onClick={() => this.ConfirmSearch()}>
            <div style={{ justifyContent: 'space-evenly'}}>
              {'显示'}
            </div>
          </button>
        </div>
        {this.state.loading ? (
          // <div className="column divLoader">
          //   <img style={{ marginTop: 50, width: 200, height: 200}} src={MarioJump}/>
          //   <div>Loading... Please wait a moment</div>
          // </div>
          <div style={{ flexDirection: 'column', width: '100%'}}>
            <div>
              {/* <img src={MarioJump} alt="Logo" className="logoimages" style={{ marginBottom: 30 }}/> */}
              {/* <img src={Loading} alt="Logo" className="logoimages" style={{ marginBottom: 30, marginTop: 50 }}/> */}
              <img src={Loading3} alt="Logo" className="logoimages" style={{ marginBottom: 30, marginTop: 50 }}/>
              <div className="loadingMessage"> Loading ... Please Wait a moment</div>
            </div>
          </div>
        ) : (
          <div className="dcc">Testing Table</div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(Report);