import '../App.css';
import React from 'react';
import { language } from '../language';
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap';
// import { isMobile } from 'react-device-detect';
import { Platformcolor } from '../static/PlatformColor';

class EditRNPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      GamePlatform: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      GamePlatform2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'],
      GDisplay: '',
      tableShowLimit: {
        0: [],
        1: [],
        2: ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'],
        3: ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'],
        4: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF'],
        5: ['5D'],
        6: ['6D'],
      },
      tableWidth: {
        2: 700,
        3: 700,
        4: 800,
        5: 300,
        6: 300,
      },
    }
  } 

  async componentDidMount() {
    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({GDisplay})
  }

  textAllChange(red, game, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = JSON.parse(JSON.stringify(this.props.editRedNumberData))
      const keys = Object.keys(gameObject.Limit.Default)
      for (let i = 0; i < keys.length; i += 1) {
        if (red) {
          gameObject.RedLimit[game][keys[i]] = Number(value)
        } else {
          gameObject.Limit[game][keys[i]] = Number(value)
        }
      }
      this.props.dataChange('editRedNumberData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  textChange(red, game, subfield, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = JSON.parse(JSON.stringify(this.props.editRedNumberData))
      if (red) {
        gameObject.RedLimit[game][subfield] = Number(value)
      } else {
        gameObject.Limit[game][subfield] = Number(value)
      }
      this.props.dataChange('editRedNumberData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  gameChange(game) {
    const tempDetails = JSON.parse(JSON.stringify(this.props.editRedNumberData))
    let newGamesArray = Object.keys(tempDetails.Limit)
    if (newGamesArray.includes(game)) {
      delete tempDetails.Limit[game]
      delete tempDetails.RedLimit[game]
    } 
    else {
      tempDetails.Limit[game] = tempDetails.Limit.Default
      tempDetails.RedLimit[game] = tempDetails.RedLimit.Default
    }
    this.props.dataChange('editRedNumberData', tempDetails)
  }

  // renderBackgroundColor(game) {
  //   if (game === 'Default') return 'lightsteelblue'
  //   else if (game === 'M') return '#fff23e'
  //   else if ((this.state.GDisplay === 'MPTSWBK') ? game === 'P' : game === 'K') return '#414bb2'
  //   else if (game === 'T') return '#ff431b'
  //   else if (game === 'S') return '#32c6f4'
  //   else if (game === 'W') return 'green'
  //   else if (game === 'B') return '#fc0404'
  //   else if ( (this.state.GDisplay === 'MPTSWBK') ? game === 'K' : game === 'D') return '#f8c100'
  //   else if (game === '8') return '#6d100e'
  //   else return null
  // }

  PlatformChange(platform) {
    if (platform === '1') return 'M'
    else if (platform === '2') return (this.state.GDisplay === 'MKTSWBD') ? 'K': 'P'
    else if (platform === '3') return 'T'
    else if (platform === '4') return 'S'
    else if (platform === '5') return 'W'
    else if (platform === '6') return 'B'
    else if (platform === '7') return (this.state.GDisplay === 'MKTSWBD') ? 'D' : 'K'
    else if (platform === '8') return '8'
    else if (platform === '9') return '9'
    return 'Default'
  }

  render() {
    return (
      <div>
        <div style={{width: 'calc(100vw)'}}>
          <div style={{alignItems: 'center', width: '90%', marginLeft: 'auto'}}>
            <div className="TitleUsername" style={{ width: '50%',  fontSize: '18px'}}> {language[this.props.currentLanguage].FollowingGameSettingSameAsDefault} </div>
            {this.props.editRedNumberData.Limit && <div style={{ display: 'flex'}}>
              {((this.state.GDisplay === 'MPTSWBK') ? this.state.GamePlatform2 : this.state.GamePlatform).map((item, index) => {
                return <Form.Check
                  style={{ fontSize: 19, alignItems: 'center', display: 'flex' }}
                  inline
                  label={item}
                  type="checkbox"
                  checked={!this.props.editRedNumberData.Limit[index + 1]}
                  onChange={(e) => this.gameChange(`${index + 1}`)}
                />
              })}
            </div>}
          </div>
          <div style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width:  this.state.tableWidth[JSON.parse(JSON.stringify(this.props.editRedNumberData)).Word.length],  paddingTop: 10, borderRadius: 10,color: '#000', backgroundColor: Platformcolor('Default', '')}}>
            <div style={{ margin: '0px 10px', width: 70, fontSize: 19}}><b>{'Default'}</b></div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
              <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
              <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editRedNumberData.Limit['Default'].all} onChange={(e) => this.textAllChange(false, 'Default', e.target.value)}/>
            </div>
            {this.state.tableShowLimit[JSON.parse(JSON.stringify(this.props.editRedNumberData)).Word.length].map((item) => {
              return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editRedNumberData.Limit['Default'][item]} onChange={(e) => this.textChange(false, 'Default', item, e.target.value)}/>
              </div>
            })}
          </div>
          {Object.keys(this.props.editRedNumberData.Limit).filter((item) => item !== 'Default').map((game) => {
            return <div style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: this.state.tableWidth[JSON.parse(JSON.stringify(this.props.editRedNumberData)).Word.length], paddingTop: 10, borderRadius: 10,color: game === 'Default' || game === '1' || game === '4' || game === '7' || game === '2' || game === 'B' || game === '3' ? '#000' : '#fff', backgroundColor: Platformcolor(game, '')}}>
              <div style={{ margin: '0px 10px', width: 70, fontSize: 19}}><b>{this.PlatformChange(game)}</b></div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editRedNumberData.Limit[game].all} onChange={(e) => this.textAllChange(false, game, e.target.value)}/>
              </div>
              {this.state.tableShowLimit[JSON.parse(JSON.stringify(this.props.editRedNumberData)).Word.length].map((item) => {
                return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                  <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editRedNumberData.Limit[game][item]} onChange={(e) => this.textChange(false, game, item, e.target.value)}/>
                </div>
              })}
            </div>
          })}
          <div style={{ backgroundColor: 'red', padding: 10, width: 'calc(100vw)'}}>
            <div style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: this.state.tableWidth[JSON.parse(JSON.stringify(this.props.editRedNumberData)).Word.length], paddingTop: 10, borderRadius: 10, color: '#000', backgroundColor: Platformcolor('Default', '')}}>
              <div style={{ margin: '0px 10px', width: 70, fontSize: 19}}><b>{'Default'}</b></div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editRedNumberData.RedLimit['Default'].all} onChange={(e) => this.textAllChange(true, 'Default', e.target.value)}/>
              </div>
              {this.state.tableShowLimit[JSON.parse(JSON.stringify(this.props.editRedNumberData)).Word.length].map((item) => {
                return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                  <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editRedNumberData.RedLimit['Default'][item]} onChange={(e) => this.textChange(true, 'Default', item, e.target.value)}/>
                </div>
              })}
            </div>
            {Object.keys(this.props.editRedNumberData.Limit).filter((item) => item !== 'Default').map((game) => {
              return <div style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: this.state.tableWidth[JSON.parse(JSON.stringify(this.props.editRedNumberData)).Word.length], paddingTop: 10, borderRadius: 10, color: game === 'Default' || game === '1' || game === '4' || game === '7' || game === '2' || game === 'B' || game === '3' ? '#000' : '#fff', backgroundColor: Platformcolor(game, '')}}>
                <div style={{ margin: '0px 10px', width: 70, fontSize: 19}}><b>{this.PlatformChange(game)}</b></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                  <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editRedNumberData.RedLimit[game].all} onChange={(e) => this.textAllChange(true, game, e.target.value)}/>
                </div>
                {this.state.tableShowLimit[JSON.parse(JSON.stringify(this.props.editRedNumberData)).Word.length].map((item) => {
                  return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                    <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editRedNumberData.RedLimit[game][item]} onChange={(e) => this.textChange(true, game, item, e.target.value)}/>
                  </div>
                })}
              </div>
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(EditRNPackage);