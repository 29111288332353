import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';

class Virtual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      VirtualCommission: {},
    }
  }

  componentDidMount(){
    const Objects = { 'B': '', 'S': '', 'SA': '', 'SB': '', 'SC': '', 'SD': '', 'SE': '', 'SF': '', '5D': '', '6D': '',
      'CA': '','CB': '','CC': '','CD': '','CE': '','CF': '','PA': '','PB': '','PC': '','PD': '','PE': '','PF': '',};

    this.setState({VirtualCommission: Object.keys(this.props.formData).length === 0 ? 
      JSON.parse(JSON.stringify( Objects)) : 
      (this.props.formData.VirtualJSON === null) ? JSON.parse(JSON.stringify( Objects)) 
        : this.props.formData.VirtualJSON})
    
    // this.setState({VirtualCommission: this.props.formData.VirtualJSON})
  }

  VirtualAllChange( value) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value
      .replace(/\D+/g, '.') // replace non digit with comma
      .replace(/^,|,$/g, '') // trim the comma
     
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');

    const stateObject = this.state.VirtualCommission
    const keys = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D','CA','CB','CC','CD','CE','CF', 'PA','PB','PC','PD','PE','PF',]
    for (let i = 0; i < keys.length; i += 1) {
      stateObject[keys[i]] = JSON.stringify(Number(output))
    }
    stateObject['all'] = JSON.stringify(Number(output))
    this.setState({ VirtualCommission: JSON.parse(JSON.stringify(stateObject))},()=>{
      this.props.propsSetState({ 
        VirtualCommission: this.state.VirtualCommission, 
      })
    })
    
  }

  VirtualIndividualChange(keys, value) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value
      .replace(/\D+/g, '.') // replace non digit with comma
      .replace(/^,|,$/g, '') // trim the comma
     
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');

    const stateObject = this.state.VirtualCommission
    stateObject[keys] = JSON.stringify(Number(output))
    stateObject['all'] = 0
    this.setState({ VirtualCommission: JSON.parse(JSON.stringify(stateObject))},()=>{
      this.props.propsSetState({ 
        VirtualCommission: this.state.VirtualCommission, 
      })
    })
    
  }

  render() {
    return (
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.VirtualModal}
        contentClassName="br23">
        <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
          <GrFormClose
            style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
            onClick={() => {
              this.props.propsSetState({ 
                VirtualModal: false,
                VirtualCommission: {},
                // VirtualActiveState: JSON.parse(JSON.stringify(this.props.oriFormData.VirtualActive)),
                // VirtualPercentState: JSON.parse(JSON.stringify(this.props.oriFormData.VirtualPercent)), 
              },this.setState({VirtualCommission: {}}))
            }}
            size="35px"
            color="#000"
          />
          <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
            <b className="dcc" style={{ padding: 20, fontSize: 23}}>
              {'Virtual'}
            </b>
            <div className="dcc" style={{  marginBottom: '3%', flexDirection: 'column'}}>
              <div style={{ display: 'flex', alignItems: 'center', width:'100%'}}>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}/>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', fontWeight: 'bold'}}>简约 </div>
                  <Form.Control style={{ width: 70, textAlign: 'center',  fontWeight: 'bold'}} type="text"  
                    value={(this.state.VirtualCommission === null || this.state.VirtualCommission === undefined) ? '' : this.state.VirtualCommission['all']}
                    onChange={(e) => this.VirtualAllChange( e.target.value)}/>
                  { (this.props.AdminModal && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) && <div style={{ paddingBottom: 38}}/> }
                </div>
                {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D','CA'].map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold'}}>{item}</div>
                    <Form.Control style={{ width: 70, textAlign: 'center',  fontWeight: 'bold' }} 
                      type="text" 
                      value={(this.state.VirtualCommission === null || this.state.VirtualCommission === undefined) ? '' : this.state.VirtualCommission[item]}
                      onChange={(e) => this.VirtualIndividualChange( item, e.target.value)}
                    />
                    {(item !== '5D' && item !== '6D') ? ( (this.state.activated && this.props.AdminModal && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) && <Form.Control style={{ width: 70, textAlign: 'center', backgroundColor: '#C3f3fb', fontWeight: 'bold', }} disabled type="text" />) : ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                  </div>
                })}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width:'100%'}}>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}/>
                {['CB','CC','CD','CE','CF', 'PA','PB','PC','PD','PE','PF',].map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold'}}>{item}</div>
                    <Form.Control style={{ width: 70, textAlign: 'center',  fontWeight: 'bold' }} 
                      type="text" 
                      value={(this.state.VirtualCommission === null || this.state.VirtualCommission === undefined) ? '' : this.state.VirtualCommission[item]}
                      onChange={(e) => this.VirtualIndividualChange( item, e.target.value)}
                    />
                    {(item !== '5D' && item !== '6D') ? ( (this.state.activated && this.props.AdminModal && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) && <Form.Control style={{ width: 70, textAlign: 'center', backgroundColor: '#C3f3fb', fontWeight: 'bold', }} disabled type="text" />) : ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                  </div>
                })}
              </div>
            </div>
            <div className="dcc" style={{  marginBottom: '3%'}}>
              <div className="dcc" style={{  width:  '55%', display: 'flex',}}>
                {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div style={{ margin: '0px 10px', fontWeight: 'bold', width: (isMobile) ? 40 : ''}}>{'%'}</div>
                  <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="number"
                    onChange={(e) => this.props.ModalDataChange('VirtualPercentState', e.target.value)} 
                    value={ this.props.VirtualPercentState || ''} 
                    placeholder={language[this.props.currentLanguage].Percent}
                  />
                </div> */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, width: 260}}>
                  <div style={{ margin: '0px 10px', width: 60, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Active}:</div>
                  <Form.Check
                    style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                    inline
                    type="checkbox"
                    checked={((this.props.VirtualActiveState || 0)=== 1)}
                    onChange={(e) => this.props.propsSetState({ VirtualActiveState: (!this.props.VirtualActiveState === true) ? 1 : 0 })} 
                  />
                </div>
              </div>
            </div>
            <div className="dcc">
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" 
                onClick={() => this.props.propsSetState({ 
                  VirtualModal: false,
                  VirtualActiveState: JSON.parse(JSON.stringify(this.props.oriFormData.VirtualActive)),
                  // VirtualPercentState: JSON.parse(JSON.stringify(this.props.oriFormData.VirtualPercent)),
                }, this.setState({VirtualCommission: {}}))}>
                {language[this.props.currentLanguage].Shutdown}
              </Button>
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" 
                onClick={() => {
                  this.props.propsSetState({ VirtualModal: false})
                  this.props.VirtualFunction(this.props.formData.ID)
                }}>{language[this.props.currentLanguage].Confirm}</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Virtual);