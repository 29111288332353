import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
import { connect } from 'react-redux';
import HRPackage from '../Component/HRPackage';
import CreateHRPackage from '../Component/CreateHRPackage';
import EWPackage from '../Component/EWPackage';
import { Tab, Form, Button, Table, Alert } from 'react-bootstrap';
import { language } from '../language';
import tickicons from '../Images/tick.png';
import moment from 'moment-timezone';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import ActiveMultiplePackage from '../Component/Modal/ActiveMultiplePackage';
import MataLock from '../Component/Modal/MataLock';
import StatusModal from '../Component/Modal/StatusModal';
import AutoAddWater from '../Component/Modal/AutoAddWater'
import ConfirmDelete from '../Component/Modal/ConfirmDelete';
import FreeJP from '../Component/Modal/FreeJP';
import VirtualModal from '../Component/Modal/Virtual';
import MiModal from '../Component/Modal/Mi';
import SelfFightModal from '../Component/Modal/SelfFightModal';
import { processPlatformPosition } from '../utility/processPlatform';
import HistoryWalletCashModal from '../Component/Modal/HistoryWalletCashModal'; 

const { updateUserBan, getUserBan, updateXingJinFormula, updateUserMobile, searchPackage, Virtual, jackpotSetting, AddWater, getUsersHR, updateUser, updateUserAll, activeMultiplePackage, getPackageNames, searchDownlineHR, createUser, getAgentUser, createOcPackage, UnlockUsers, DeleteUsers} = require('../Api');
var timer

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

const utilizeScroll2 = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class HR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      visibleForm: false,
      createvisibleForm: false,
      formData: {},
      CreateformData: {},
      activeKey: "",
      oriFormData: {},
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      parentLoading: false,
      packageNames: [],
      AllowSaveUsername: false,
      AllowUsername: '',
      OwnerDetail: [],
      FollowUpperline: [],
      SearchUser: '',
      PreviousBool: true,
      BackToUpperline: '',
      UpperlineUser: [],
      SetPackage: '',
      NewSetPackage: '',
      SetPackage2: '',
      NewSetPackage2: '',
      Mode: '',
      FormKey2: [],
      FormKey1: [],
      AutoCheck: false,
      GDisplay: '',
      NewOCPackage: [],
      OptionsPackage: [],
      SelectedPackage:[],
      OriginalpackageNames: [],
      Authority: {},
      MultiplyCheck: false,
      MultipleSetValue: 0,
      AlertModal: false,
      AccountAmount: 1,
      MataLockModal: false,
      StatusModal: false,
      StatusUser: 'self',
      StatusUserStatus: 'Active',
      StatusUserLock: false,
      OpenChangePassword: false,
      showNotificationpass: false,
      UserUsername: '',
      UserPassword: '',
      DeleteUSerID: '',
      PopScrollBackButton: false,
      fastDeleteUsers: false,
      SelectedDeleteUsers: [],
      AutoAddWaterModal: false,
      AddWaterStatusUser: 'self',
      AutoAddWaterPercent: 0,
      AutoAddWaterShowReal: 0,
      AutoAddWaterActive: 0,
      AutoAddWaterRefresh: false,
      JPFreeJPAmount: 0,
      JPFreeJPX10: 0,
      JPFreeJPActive: 0,
      FreeBTJPAmount: 0,
      JPFreeJPMultiply: 0,
      FreeJPModal: false,
      VirtualModal: false,
      MiModal: false,
      VirtualActiveState: 0,
      VirtualPercentState: 0,
      SetDetailValue: {Odd:{}, Commission:{}},
      modalShow: false,
      modalShowDetail: false,
      Agentlvl: '',
      OptionsPackageNumber: [],
      SelectedPackageNumber: {},
      UpdateNameBoolean: false,
      BackPackageNames: false,
      // allBetModeSelect: ['B', 'S', 'A1', 'A1C', 'A', 'C', 'A2', 'A3', 'D4', 'E4',],
      allBetModeSelect: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD','CE','CF', 'PA','PB','PC','PD','PE','PF',],
      allBetMode: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D','CA','CB',],
      allBetMode2: ['CC','CD','CE','CF', 'PA','PB','PC','PD','PE','PF',],
      allGameSelect: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9', '-'],
      allGameSelect2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9', '-'],
      GameBettingCustom: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      BooleanHideWater: true,
      WaterCommission: {},
      VirtualCommission: {},
      BooleanWallet: false,
      WalletHistoryModal: false,
      WalletHistoryArray: [],
      BooleanCheckAllPackage: false,
      UserID: this.props.userID,
      optionsUsername: '',
      SelectedUser: '',
      SearchUSersModal: false,
      SearchedID : [],
      UplineGame: '',
      AX3: 0,
      Sennotadd: 0,
      AbleChange: 0,
      SelfFightModal: false,
      BanresultArray: {
        "Ban4Da": false ,
        "Ban6Da": false ,
        "Ban12Da": false ,
        "Ban24Da": false ,
      },
      FightArrayNumb: [''],
      allUpperlineData: [],
    }
    this.dataChange = this.dataChange.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.toggleEditForm = this.toggleEditForm.bind(this)
    this.updateAll = this.updateAll.bind(this)
    this.resetPackages = this.resetPackages.bind(this)
    this.FocusHere = utilizeScroll();
    this.CloseEWPackage = this.CloseEWPackage.bind(this)
    this.ClickCreateNewUser = this.ClickCreateNewUser.bind(this)
    this.ShowDownline =  this.ShowDownline.bind(this)
    this.SetPackageGame = this.SetPackageGame.bind(this)
    this.EditData = this.EditData.bind(this)
    this.RefrehPackage = this.RefrehPackage.bind(this)
    this.ActiveNotification = this.ActiveNotification.bind(this)
    this.MultiplyCheckFunction = this.MultiplyCheckFunction.bind(this)
    this.SelectedMultiple = this.SelectedMultiple.bind(this)
    this.SubmitMultiplePackage = this.SubmitMultiplePackage.bind(this)
    this.SubmitMultiplePackageAlert = this.SubmitMultiplePackageAlert.bind(this)
    this.UpdateSearchPackage = this.UpdateSearchPackage.bind(this)
    this.ApiRefresh = this.ApiRefresh.bind(this)
    this.elScroll = utilizeScroll2();
    this.toggleSetEditForm = this.toggleSetEditForm.bind(this);
    this.toggleCloseForm = this.toggleCloseForm.bind(this);
    this.propsSetState = this.propsSetState.bind(this);
    this.ModalDataChange = this.ModalDataChange.bind(this);
    this.updateUserAddWater = this.updateUserAddWater.bind(this);
    this.ConfirmAccount = this.ConfirmAccount.bind(this);
    this.FreeJackPortFunction = this.FreeJackPortFunction.bind(this);
    this.VirtualFunction = this.VirtualFunction.bind(this);
    this.MiFunction =  this.MiFunction.bind(this);
    this.updateUserF = this.updateUserF.bind(this);
    this.CopyPackage = this.CopyPackage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.SelfFightFunction = this.SelfFightFunction.bind(this);
  }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
      UserID: this.props.Role === 'admin' || this.props.Role === 'superAdmin' ? 'C' : (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID, 
    }, () => {
      if (this.state.UserID !== this.props.ViewUserTable) {
        this.ShowDownline(this.props.ViewUserTable)
      } else {
        this.ApiRefresh();
      }
      // this.GetAllDownlineUser();
    })
    this.setPlatform9();
  }

  setPlatform9() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if (UserAuthorities.GameBetting.includes('9')) {
      let allGameSelect = ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9', '-']
      let allGameSelect2 = ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9', '-']
      this.setState({ allGameSelect, allGameSelect2 })
    }
  }

  async ApiRefresh(RefreshID, RefreshUpperlineID, status, packagenorefresh) {
    let GDisplay = localStorage.getItem('GameDisplay');
    let users = []
    let updateformdata = []
    if (RefreshUpperlineID !== undefined){
      users = await getUsersHR(this.props.username, this.props.token, (RefreshID) ? RefreshID : this.state.UserID)
      updateformdata = await getUsersHR(this.props.username, this.props.token, (RefreshUpperlineID) ? RefreshUpperlineID : this.state.UserID)
    } else {
      users = await getUsersHR(this.props.username, this.props.token, (RefreshID) ? RefreshID : (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? 'C' : this.state.UserID)
    }
    let getUpperlineUser = await getAgentUser( this.state.UserID, this.props.token, this.props.username)
    for(let i = 0; i < users.usersResult.length; i += 1) {
      users.usersResult[i].FilteredKeyIn1 = JSON.parse(users.usersResult[i].KeyIn1)
    }
    let selfProfitReport = {};
    let RefreshOwnerProfile = (this.state.Agentlvl === 'Down') ? JSON.parse(JSON.stringify(this.state.users)) : JSON.parse(JSON.stringify(this.state.OwnerDetail));
    // let UppdatedOwner = JSON.parse(JSON.stringify(this.state.OwnerDetail))
    let UppdatedUser = JSON.parse(JSON.stringify(users.usersResult))
    
    let StoreUser = [];

    let NewArrayNumber = []
    for (let i = -20; i <= 20; i += 0.25) {
      const filterPackage = {
        value: i, label: i
      }
      NewArrayNumber.push(filterPackage)
    }

    if (RefreshID !== undefined) {
      users.usersResult.forEach((item) => {
        if (item.ID ===  RefreshID ) {
          RefreshOwnerProfile.forEach((RefItem, RefInd) =>{

            if (this.state.Agentlvl === 'Down' && (RefItem.ID === RefreshID )) {
              RefreshOwnerProfile[RefInd] = item;
            } 
            else if (this.state.Agentlvl === 'Up' && (RefItem.ID === RefreshID )) {
              RefreshOwnerProfile[RefInd] = item;
            } 
            else if (this.state.Agentlvl === 'CreateAcc' && (RefItem.ID === RefreshID )) {
              RefreshOwnerProfile[RefInd] = item;
              delete UppdatedUser[RefInd]
            }
          })
      
        }
      });
      let filterDonwline = []
      if(status === 'Downline deleted'){
       
        users.usersResult.forEach((item)=>{
          if (RefreshID !== item.ID){
            filterDonwline.push(item)
          }
        })
      }
      // UppdatedUser.filter((item) => item === 'empty')
      var filtered = UppdatedUser.filter(x => x !== undefined);
      
      let RefreshUserPackage = users.usersResult.find((item) => item.ID === RefreshID)
      if (Object.keys(RefreshUserPackage).length !== 0 && this.state.Agentlvl !== 'CreateAcc'){
        const formdataID = RefreshUserPackage.ID
        let packageNames = await getPackageNames(this.props.username, formdataID, this.props.token)
        
        let NewArray = []
        packageNames.forEach((item)=>{
          const filterPackage = {
            value: item.Name, label: item.Name
          }
          NewArray.push(filterPackage)
        })

        packageNames = packageNames.sort(function(a,b){
          if ((a.childPackage === null) === (b.childPackage === null)) {
            return a.Name.localeCompare(b.Name);
          }
          return (a.childPackage === null) - (b.childPackage === null)
        })

        const RedPercentFilter = JSON.parse(JSON.stringify(RefreshUserPackage))
        
        const e = JSON.stringify(RedPercentFilter.RedPercent)
          .replace(/\D+/g, '') // replace non digit with comma
          .replace(/^,|,$/g, '') // trim the comma
    
        var output = e.split('');
        output.unshift('-');
        output = output.join('');
        RedPercentFilter.RedPercent = output
        RedPercentFilter.AutoAddWaterJSON = JSON.parse(RedPercentFilter.AutoAddWaterJSON)
        RedPercentFilter.VirtualJSON = JSON.parse(RedPercentFilter.VirtualJSON)
        
        if (RedPercentFilter.GameBetting !== '123456789') {
          if (RedPercentFilter.GameDisplay === 'MKTSWBD') {
            RedPercentFilter.GameBettingCustom = RedPercentFilter.GameBetting.replace('1', 'M').replace('2', 'K').replace('3', 'T').replace('4', 'S').replace('5', 'W').replace('6', 'B').replace('7', 'D').replaceAll('-', '-').split('')
          } else if (RedPercentFilter.GameDisplay === 'MPTSWBK') {
            RedPercentFilter.GameBettingCustom = RedPercentFilter.GameBetting.replace('1', 'M').replace('2', 'P').replace('3', 'T').replace('4', 'S').replace('5', 'W').replace('6', 'B').replace('7', 'K').replaceAll('-', '-').split('')
          }
          RedPercentFilter.GameBetting = 'Custom'
        }
        this.setState({ 
          formData: RedPercentFilter, 
          allUpperlineData: users.allUpperlineData,
          // UplineGame: RedPercentFilter.GameBetting,
          oriFormData: RedPercentFilter, 
          activeKey: (this.state.activeKey === 'eatWord') ? this.state.activeKey : (packagenorefresh === 'Refresh') ? this.state.activeKey  :  '', 
          packageNames: JSON.parse(JSON.stringify(packageNames)), 
          MultiplyCheck: false,
          parentLoading: false, 
          OptionsPackage: NewArray,
          OriginalpackageNames: JSON.parse(JSON.stringify(packageNames)),
        }, ()=>{
          if (this.state.Agentlvl === 'Up') {
            this.setState({ OwnerDetail: JSON.parse(JSON.stringify(RefreshOwnerProfile)), })
          } 
          if (this.state.Agentlvl === 'Down' && status !== 'Downline deleted') {
            this.setState({ users: JSON.parse(JSON.stringify(RefreshOwnerProfile)), })
          } 
          if (this.state.Agentlvl === 'Down' && status === 'Downline deleted') {
            this.setState({ users: JSON.parse(JSON.stringify(filterDonwline)), })
          } 
          if (this.state.AutoAddWaterRefresh === true){
            this.setState({
              AutoAddWaterShowReal: RefreshUserPackage.AutoAddWaterShowReal,
              AutoAddWaterActive: RefreshUserPackage.AutoAddWaterActive,
              AutoAddWaterPercent: RefreshUserPackage.AutoAddWaterPercent,
              AX3: RefreshUserPackage.AX3,
              Sennotadd:  RefreshUserPackage.SenNotAdd,
              AutoAddWaterRefresh: false,
            })
          }
        })
      } else {
        if (this.state.Agentlvl === 'CreateAcc') {
          this.setState({ OwnerDetail: JSON.parse(JSON.stringify(RefreshOwnerProfile)), users: JSON.parse(JSON.stringify(filtered)),
            Agentlvl: '',
          })
        }
      }
      
    } else if ( RefreshUpperlineID !== undefined){
      let RefreshUserPackage = updateformdata.find((item) => item.ID === RefreshUpperlineID)
      users.usersResult.forEach((item) => {
        if (item.ID === ((RefreshID) ? RefreshID : this.state.UserID)) {
          selfProfitReport = item;
          return true;
        } else if (item.ID === 'C' && (this.props.Role === 'admin' || this.props.Role === 'superAdmin' )) {
          selfProfitReport = item;
          return true;
        } else {
          StoreUser.push(item)
          return false;
        }
      });
      StoreUser.sort((a, b) => (a.Username > b.Username) ? 1 : -1)
      if (selfProfitReport.ID === 'C') {
        const sortingArr = ['PT','SK','SS','ST','SY','JJ','EKI82']
        let tempArray1 = []
        const tempArray2 = []
        for (let i = 0; i < StoreUser.length; i += 1) {
          if (sortingArr.includes(StoreUser[i].Username)) {
            tempArray1.push(StoreUser[i])
          } else {
            tempArray2.push(StoreUser[i])
          }
        }
        tempArray1 = tempArray1.sort((a, b) => sortingArr.indexOf(a.Username) - sortingArr.indexOf(b.Username));
        StoreUser = tempArray1.concat(tempArray2)
      }
      this.setState({ 
        formData: RefreshUserPackage, 
        allUpperlineData: users.allUpperlineData,
        // UplineGame: RefreshUserPackage.GameBetting,
        oriFormData: RefreshUserPackage, 
        OptionsPackageNumber: NewArrayNumber, 
        users: StoreUser, 
        OwnerDetail: [selfProfitReport], 
        FollowUpperline: [selfProfitReport], 
        UpperlineUser: getUpperlineUser, GDisplay 
      })
    } else {
      users.usersResult.forEach((item) => {
        if (item.ID === ((RefreshID) ? RefreshID : this.state.UserID)) {
          selfProfitReport = item;
          return true;
        } else if (item.ID === 'C' && (this.props.Role === 'admin' || this.props.Role === 'superAdmin' )) {
          selfProfitReport = item;
          return true;
        } else {
          StoreUser.push(item)
          return false;
        }
      });
      
      StoreUser = StoreUser.sort((a, b) => (a.Username > b.Username) ? 1 : -1)
      if (selfProfitReport.ID === 'C') {
        const sortingArr = ['PT','SK','SS','ST','SY','JJ','EKI82']
        let tempArray1 = []
        const tempArray2 = []
        for (let i = 0; i < StoreUser.length; i += 1) {
          if (sortingArr.includes(StoreUser[i].Username)) {
            tempArray1.push(StoreUser[i])
          } else {
            tempArray2.push(StoreUser[i])
          }
        }
        tempArray1 = tempArray1.sort((a, b) => sortingArr.indexOf(a.Username) - sortingArr.indexOf(b.Username));
        StoreUser = tempArray1.concat(tempArray2)
      }
      this.setState({ 
        allUpperlineData: users.allUpperlineData,
        OptionsPackageNumber: NewArrayNumber, 
        users: StoreUser, 
        OwnerDetail: [selfProfitReport], 
        FollowUpperline: [selfProfitReport], 
        UpperlineUser: getUpperlineUser, GDisplay 
      })
    }
  }

  async ShowDownline(UserID) {
    let DownlineResult = await getUsersHR( this.props.username,  this.props.token, UserID, )
    // console.log('DownlineResult', DownlineResult)
    let NewArrayNumber = []
    for (let i = -20; i <= 20; i += 0.25) {
      const filterPackage = {
        value: i, label: i
      }
      NewArrayNumber.push(filterPackage)
    }
    
    for(let i = 0; i < DownlineResult.usersResult.length; i += 1) {
      DownlineResult.usersResult[i].FilteredKeyIn1 = JSON.parse(DownlineResult.usersResult[i].KeyIn1)
    }

    let selfProfitReport = {};
    let StoreUser = [];
    DownlineResult.usersResult.forEach((item) => {
      if (item.ID === UserID) {
        selfProfitReport = item;
        return true;
      } else if (item.ID === 'C' && (this.props.Role === 'admin' || this.props.Role === 'superAdmin' )) {
        selfProfitReport = item;
        return true;
      } else {
        StoreUser.push(item)
        return false;
      }
    });
    StoreUser.sort((a, b) => (a.Username > b.Username) ? 1 : -1)
    let getUpperlineUser = await getAgentUser( this.state.UserID, this.props.token, this.props.username)
    const BooleanBackPrevvious = (selfProfitReport.ID === "C" && selfProfitReport.Name ===  "Company") ? true : false 
    this.props.setHRTableView(UserID)
    this.setState({ Agentlvl: '',users: StoreUser, allUpperlineData: DownlineResult.allUpperlineData,
      OwnerDetail: [selfProfitReport], PreviousBool: BooleanBackPrevvious, FollowUpperline: [selfProfitReport], 
      BackToUpperline: selfProfitReport.Upperline,   
      createvisibleForm: false,   visibleForm: false, UpperlineUser:getUpperlineUser, OptionsPackageNumber: NewArrayNumber,  })
    this.handleClose();
  }

  async BackToOriginalForm(){
    let users = await getUsersHR(this.props.username, this.props.token, this.state.UserID)
    for(let i = 0; i < users.usersResult.length; i += 1) {
      users.usersResult[i].FilteredKeyIn1 = JSON.parse(users.usersResult[i].KeyIn1)
    }
    let selfProfitReport = {};
    let StoreUser = [];
    (users.usersResult).forEach((item) => {
      if (item.ID === this.state.UserID) {
        selfProfitReport = item;
        return true;
      } else if (item.ID === 'C' && (this.props.Role === 'admin' || this.props.Role === 'superAdmin' )) {
        selfProfitReport = item;
        return true;
      } else {
        StoreUser.push(item)
        return false;
      }
    });
    StoreUser.sort((a, b) => (a.Username > b.Username) ? 1 : -1)
    if (selfProfitReport.ID === 'C') {
      const sortingArr = ['PT','SK','SS','ST','SY','JJ','EKI82']
      let tempArray1 = []
      const tempArray2 = []
      for (let i = 0; i < StoreUser.length; i += 1) {
        if (sortingArr.includes(StoreUser[i].Username)) {
          tempArray1.push(StoreUser[i])
        } else {
          tempArray2.push(StoreUser[i])
        }
      }
      tempArray1 = tempArray1.sort((a, b) => sortingArr.indexOf(a.Username) - sortingArr.indexOf(b.Username));
      StoreUser = tempArray1.concat(tempArray2)
    }
    this.props.setHRTableView(this.state.UserID)
    // this.GetAllDownlineUser();
    this.setState({ users: StoreUser, OwnerDetail: [selfProfitReport], SearchUser: '', PreviousBool: true, allUpperlineData: users.allUpperlineData, })
  }

  async BackToPreviousForm(){
    const UserID = (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? 'C' : this.state.UserID
    const UppelineID = (this.state.BackToUpperline === null) ?  this.state.UserID : (this.state.BackToUpperline === '') ? this.state.UserID : this.state.BackToUpperline
    this.props.setHRTableView(UppelineID)
    let users = await getUsersHR(this.props.username, this.props.token, UppelineID)
    for(let i = 0; i < users.usersResult.length; i += 1) {
      users.usersResult[i].FilteredKeyIn1 = JSON.parse(users.usersResult[i].KeyIn1)
    }
    let StoreUser = users.usersResult.slice(1)
    if (users.usersResult[0].ID === 'C') {
      const sortingArr = ['PT','SK','SS','ST','SY','JJ','EKI82']
      let tempArray1 = []
      const tempArray2 = []
      for (let i = 0; i < StoreUser.length; i += 1) {
        if (sortingArr.includes(StoreUser[i].Username)) {
          tempArray1.push(StoreUser[i])
        } else {
          tempArray2.push(StoreUser[i])
        }
      }
      tempArray1 = tempArray1.sort((a, b) => sortingArr.indexOf(a.Username) - sortingArr.indexOf(b.Username));
      StoreUser = tempArray1.concat(tempArray2)
    }
    if (users.usersResult[0].ID === UserID){
      this.setState({ users: StoreUser, OwnerDetail: [users.usersResult[0]], PreviousBool: true, BackToUpperline: users.usersResult[0].Upperline, 
        visibleForm: false, activeKey: '',  formData: {}, oriFormData: {}, parentLoading: false,  Agentlvl: '', Mode: '', allUpperlineData: users.allUpperlineData, })
    } else {
      this.setState({ users: StoreUser, OwnerDetail: [users.usersResult[0]], PreviousBool: false, BackToUpperline: users.usersResult[0].Upperline,
        visibleForm: false, activeKey: '',  formData: {}, oriFormData: {}, parentLoading: false,  Agentlvl: '', Mode: '',   allUpperlineData: users.allUpperlineData, })
    }
    
  }

  async EditData(e, EditStatus){
    if (this.state.Mode === 'Create User') {
      if (EditStatus === 'Upperline'){
        let DownlineResult = await getUsersHR( this.props.username,  this.props.token, e.target.value, )
        if (DownlineResult.length !== 0){
          const createForm = {
            ActivePackageID: null,
            DailyLimit: DownlineResult[0].DailyLimit || 0, 
            EwPackage: {"Default": {"B": {"max": "0", "amount": "0", "percent": "0"}, "S": {"max": "0", "amount": "0", "percent": "0"}, "5D": {"max": "0", "amount": "0", "percent": "0"}, "6D": {"max": "0", "amount": "0", "percent": "0"}, "CA": {"max": 0, "amount": 0, "percent": "0"}, "CB": {"max": 0, "amount": 0, "percent": "0"}, "CC": {"max": 0, "amount": 0, "percent": "0"}, "CD": {"max": 0, "amount": 0, "percent": "0"}, "CE": {"max": 0, "amount": 0, "percent": "0"}, "CF": {"max": 0, "amount": 0, "percent": "0"}, "PA": {"max": 0, "amount": 0, "percent": "0"}, "PB": {"max": 0, "amount": 0, "percent": "0"}, "PC": {"max": 0, "amount": 0, "percent": "0"}, "PD": {"max": 0, "amount": 0, "percent": "0"}, "PE": {"max": 0, "amount": 0, "percent": "0"}, "PF": {"max": 0, "amount": 0, "percent": "0"}, "SA": {"max": 0, "amount": 0, "percent": "0"}, "SB": {"max": 0, "amount": 0, "percent": "0"}, "SC": {"max": 0, "amount": 0, "percent": "0"}, "SD": {"max": 0, "amount": 0, "percent": "0"}, "SE": {"max": 0, "amount": 0, "percent": "0"}, "SF": {"max": 0, "amount": 0, "percent": "0"}, "all": {"max": 0, "amount": 0, "percent": "0"}}}, 
            FilteredKeyIn1: DownlineResult[0].FilteredKeyIn1 || ['B', 'S', 'CF', 'CA', 'SA', 'SF'], 
            KeyIn1: DownlineResult[0].KeyIn1 || this.state.FollowUpperline[0].KeyIn1,
            KeyIn2: DownlineResult[0].KeyIn2 || this.state.FollowUpperline[0].KeyIn2,
            LastOrderID: "",
            LastUpdateDateTime: new Date(),
            LoginAttempt: 0,
            MultiplyDivide: DownlineResult[0].MultiplyDivide || "M",
            NextDownlineID: "",
            OcPackage: null,
            Password: this.state.CreateformData.Password || '',
            Name: this.state.CreateformData.Name || '',
            Role: DownlineResult[0].Role || "player",
            Status: DownlineResult[0].Status || "Active",
            GameDisplay: DownlineResult[0].GameDisplay || "MKTSWBD",
            Username: this.state.CreateformData.Username || '',
            Upperline: DownlineResult[0].ID,
            EwF: "-",
            Tong: 0,
            AbleRed: 0,
            JinNan: DownlineResult[0].JinNan,
            RedPercent: 0,
            OneStarBao: 1,
            Gamebettingcustom: '123456789',
            GameBettingCustomArray: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
            GameBetting: DownlineResult[0].GameBetting,
          }

          this.setState({
            CreateformData : JSON.parse(JSON.stringify(createForm)),
          })
        }
      } else {
        this.dataChange(EditStatus, e.target.value, undefined ,'CreateformData')
      }
    } else {
      if (EditStatus === 'EwF' || EditStatus === 'Role' || EditStatus === 'GameDisplay') {
        this.updateUserF(this.state.formData.ID, EditStatus, e.target.value)
      } else {
        this.dataChange(EditStatus, e.target.value, undefined ,'formData')
      }
    }
  }

  async toggleEditForm(formData, Updated, Level) {
    // CreateformData
    const RedPercentFilter = JSON.parse(JSON.stringify(formData))
    this.setState({ UplineGame: RedPercentFilter.GameBetting})
    const e = JSON.stringify(RedPercentFilter.RedPercent)
      .replace(/\D+/g, '') // replace non digit with comma
      .replace(/^,|,$/g, '') // trim the comma

    var output = e.split('');
    output.unshift('-');
    output = output.join('');
    RedPercentFilter.RedPercent = output
    if (RedPercentFilter.GameBetting !== '123456789') {
      if (formData.GameDisplay === 'MKTSWBD') {
        this.setState({ GameBettingCustom: RedPercentFilter.GameBetting.replace('1', 'M').replace('2', 'K').replace('3', 'T').replace('4', 'S').replace('5', 'W').replace('6', 'B').replace('7', 'D').replaceAll('-', '-').split('')})
      } else if (formData.GameDisplay === 'MPTSWBK') {
        this.setState({ GameBettingCustom: RedPercentFilter.GameBetting.replace('1', 'M').replace('2', 'P').replace('3', 'T').replace('4', 'S').replace('5', 'W').replace('6', 'B').replace('7', 'K').replaceAll('-', '-').split('')})
      }
      RedPercentFilter.GameBetting = 'Custom'
    }
    if (RedPercentFilter.AutoAddWaterJSON) {
      RedPercentFilter.AutoAddWaterJSON = JSON.parse(RedPercentFilter.AutoAddWaterJSON)
    }
    if (RedPercentFilter.VirtualJSON) {
      RedPercentFilter.VirtualJSON = JSON.parse(RedPercentFilter.VirtualJSON)
    }
    if (Updated === 'Create User Done'){
      const checkdataPackage = JSON.parse(JSON.stringify(formData));
      if ((checkdataPackage.FilteredKeyIn1).length > 0 ){
        this.setState({SetPackage: 'Custom', SetPackage2: 'Custom' })
      }
      this.setState({
        visibleForm: true,
        formData: JSON.parse(JSON.stringify(formData)),
        oriFormData: JSON.parse(JSON.stringify(formData)),
        FormKey1: JSON.parse(formData.KeyIn1),
        FormKey2:  JSON.parse(formData.KeyIn2),
        NewSetPackage: '',
        NewSetPackage2: '',
        activeKey: (formData.Ew === 1) ? "eatWord" : "NoEatWord",
        parentLoading: true,
        createvisibleForm: false,
        UpdateNameBoolean: false,
        Mode: '', 
        AbleChange: formData.AbleChange,
      }, async () => {
        const formdataID = (this.state.formData.ID !== null) ? this.state.formData.ID : '';
        let packageNames = await getPackageNames(this.props.username, formdataID, this.props.token)
        let NewArray = []
        packageNames.forEach((item)=>{
          const filterPackage = {
            value: item.Name, label: item.Name
          }
          NewArray.push(filterPackage)
        })

        packageNames = packageNames.sort(function(a,b){
          if ((a.childPackage === null) === (b.childPackage === null)) {
            return a.Name.localeCompare(b.Name);
          }
          return (a.childPackage === null) - (b.childPackage === null)
        })
        this.setState({ packageNames, parentLoading: false, OptionsPackage: NewArray,OriginalpackageNames: packageNames })
      })
    } else {
      if (this.state.visibleForm && (JSON.parse(JSON.stringify(formData.ID)) === this.state.formData.ID)) {
        this.setState({ 
          visibleForm: false,
          activeKey: '',
          formData: {},
          oriFormData: {},
          parentLoading: false,
          Mode: '',
          Agentlvl: '',
        })
      } else if (this.state.visibleForm && (JSON.parse(JSON.stringify(formData.ID)) !== this.state.formData.ID)) {
        
        const checkdataPackage = JSON.parse(JSON.stringify(formData));
        if ( checkdataPackage.FilteredKeyIn1 !== null && checkdataPackage.FilteredKeyIn1.length > 0){
          this.setState({SetPackage: 'Custom',SetPackage2: 'Custom' })
        }
        this.setState({ 
          visibleForm: true,
          Agentlvl: Level,
          AutoAddWaterShowReal: JSON.parse(JSON.stringify(formData.AutoAddWaterShowReal)),
          AX3: JSON.parse(JSON.stringify(formData.AX3)),
          Sennotadd:  JSON.parse(JSON.stringify(formData.SenNotAdd)),
          JPFreeJPAmount: JSON.parse(JSON.stringify(formData.FreeJPAmount)),
          JPFreeJPX10: JSON.parse(JSON.stringify(formData.FreeJPX10)),
          FreeBTJPAmount: JSON.parse(JSON.stringify(formData.FreeBTJPAmount)),
          JPFreeJPActive: JSON.parse(JSON.stringify(formData.FreeJPActive)),
          JPFreeJPMultiply: JSON.parse(JSON.stringify(formData.FreeJPMultiply)),
          formData: RedPercentFilter,
          oriFormData: RedPercentFilter,
          FormKey2: JSON.parse(formData.KeyIn2),
          FormKey1: JSON.parse(formData.KeyIn1),
          activeKey: (formData.Ew === 1) ? "eatWord" : "NoEatWord",
          parentLoading: true,
          createvisibleForm: false,
          Mode: 'Edit',
          NewSetPackage: '',
          NewSetPackage2: '',
          UpdateNameBoolean: false,
          AbleChange: formData.AbleChange,
        }, async () => {
          this.FocusHere.executeScroll();
          const formdataID = (this.state.formData.ID !== null) ? this.state.formData.ID : '';
          let packageNames = await getPackageNames(this.props.username, formdataID, this.props.token)
          let NewArray = []
          packageNames.forEach((item)=>{
            const filterPackage = {
              value: item.Name, label: item.Name
            }
            NewArray.push(filterPackage)
          })

          packageNames = packageNames.sort(function(a,b){
            if ((a.childPackage === null) === (b.childPackage === null)) {
              return a.Name.localeCompare(b.Name);
            }
            return (a.childPackage === null) - (b.childPackage === null)
          })
          this.setState({ packageNames, parentLoading: false, OptionsPackage: NewArray,OriginalpackageNames: packageNames, MultiplyCheck: false })
        })
      } else {
        const checkdataPackage = JSON.parse(JSON.stringify(formData));
        if ( checkdataPackage.FilteredKeyIn1 !== null && checkdataPackage.FilteredKeyIn1.length > 0){
          this.setState({SetPackage: 'Custom',SetPackage2: 'Custom' })
        }
        this.setState({ 
          visibleForm: true,
          Agentlvl: Level,
          AutoAddWaterShowReal: JSON.parse(JSON.stringify(formData.AutoAddWaterShowReal)),
          AX3: JSON.parse(JSON.stringify(formData.AX3)),
          Sennotadd:  JSON.parse(JSON.stringify(formData.SenNotAdd)),
          JPFreeJPAmount: JSON.parse(JSON.stringify(formData.FreeJPAmount)),
          JPFreeJPX10: JSON.parse(JSON.stringify(formData.FreeJPX10)),
          FreeBTJPAmount: JSON.parse(JSON.stringify(formData.FreeBTJPAmount)),
          JPFreeJPActive: JSON.parse(JSON.stringify(formData.FreeJPActive)),
          JPFreeJPMultiply: JSON.parse(JSON.stringify(formData.FreeJPMultiply)),
          formData: RedPercentFilter,
          oriFormData: RedPercentFilter,
          FormKey2: JSON.parse(formData.KeyIn2),
          FormKey1: JSON.parse(formData.KeyIn1),
          activeKey: (formData.Ew === 1) ? "eatWord" : "NoEatWord",
          parentLoading: true,
          createvisibleForm: false,
          Mode: 'Edit',
          NewSetPackage: '',
          NewSetPackage2: '',
          UpdateNameBoolean: false,
          AbleChange: formData.AbleChange,
        }, async () => {
          this.FocusHere.executeScroll();
          const formdataID = (this.state.formData.ID !== null) ? this.state.formData.ID : '';
          let packageNames = await getPackageNames(this.props.username, formdataID, this.props.token)
          let NewArray = []
          packageNames.forEach((item)=>{
            const filterPackage = {
              value: item.Name, label: item.Name
            }
            NewArray.push(filterPackage)
          })

          packageNames = packageNames.sort(function(a,b){
            if ((a.childPackage === null) === (b.childPackage === null)) {
              return a.Name.localeCompare(b.Name);
            }
            return (a.childPackage === null) - (b.childPackage === null)
          })
          this.setState({ packageNames, parentLoading: false, OptionsPackage: NewArray,OriginalpackageNames: packageNames, MultiplyCheck: false })
        })
      }
    }
  }

  async RefrehPackage(state){
  
    const formdataID = (this.state.formData.ID !== null) ? this.state.formData.ID : '';
    let packageNames = await getPackageNames(this.props.username, formdataID, this.props.token)
    packageNames = packageNames.sort(function(a,b){
      if ((a.childPackage === null) === (b.childPackage === null)) {
        return a.Name.localeCompare(b.Name);
      }
      return (a.childPackage === null) - (b.childPackage === null)
    })
    
    if(state === 'createPackage') {
      const ReturnPackageID = packageNames.filter(item  => item.PackageID === JSON.stringify(this.state.NewOCPackage.PackageID));
      this.setState({ packageNames, parentLoading: false, activeKey: ReturnPackageID[0].PackageID,OriginalpackageNames: packageNames })
    } else {
      this.setState({ packageNames, parentLoading: false, activeKey: '',OriginalpackageNames: packageNames })
    }
   
  }

  async OpenNewForm(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if(UserAuthorities.CreateUser === 1) {
      const createForm = {
        ActivePackageID: null,
        DailyLimit: this.state.OwnerDetail[0].DailyLimit || 0, 
        EwPackage: {"Default": {"B": {"max": "0", "amount": "0", "percent": "0"}, "S": {"max": "0", "amount": "0", "percent": "0"}, "5D": {"max": "0", "amount": "0", "percent": "0"}, "6D": {"max": "0", "amount": "0", "percent": "0"}, "CA": {"max": 0, "amount": 0, "percent": "0"}, "CB": {"max": 0, "amount": 0, "percent": "0"}, "CC": {"max": 0, "amount": 0, "percent": "0"}, "CD": {"max": 0, "amount": 0, "percent": "0"}, "CE": {"max": 0, "amount": 0, "percent": "0"}, "CF": {"max": 0, "amount": 0, "percent": "0"}, "PA": {"max": 0, "amount": 0, "percent": "0"}, "PB": {"max": 0, "amount": 0, "percent": "0"}, "PC": {"max": 0, "amount": 0, "percent": "0"}, "PD": {"max": 0, "amount": 0, "percent": "0"}, "PE": {"max": 0, "amount": 0, "percent": "0"}, "PF": {"max": 0, "amount": 0, "percent": "0"}, "SA": {"max": 0, "amount": 0, "percent": "0"}, "SB": {"max": 0, "amount": 0, "percent": "0"}, "SC": {"max": 0, "amount": 0, "percent": "0"}, "SD": {"max": 0, "amount": 0, "percent": "0"}, "SE": {"max": 0, "amount": 0, "percent": "0"}, "SF": {"max": 0, "amount": 0, "percent": "0"}, "all": {"max": 0, "amount": 0, "percent": "0"}}}, 
        FilteredKeyIn1: this.state.OwnerDetail[0].FilteredKeyIn1 || ['B', 'S', 'CF', 'CA', 'SA', 'SF'], 
        KeyIn1: this.state.OwnerDetail[0].KeyIn1 || this.state.FollowUpperline[0].KeyIn1,
        KeyIn2: this.state.OwnerDetail[0].KeyIn2 || this.state.FollowUpperline[0].KeyIn2,
        LastOrderID: "",
        LastUpdateDateTime: new Date(),
        LoginAttempt: 0,
        MultiplyDivide: this.state.OwnerDetail[0].MultiplyDivide || "M",
        NextDownlineID: "",
        OcPackage: null,
        Password: "",
        Name: '',
        Role: this.state.OwnerDetail[0].Role || "player",
        Status: this.state.OwnerDetail[0].Status || "Active",
        GameDisplay: this.state.OwnerDetail[0].GameDisplay || "MKTSWBD",
        Username: "",
        Upperline: this.state.OwnerDetail[0].ID,
        EwF: "-",
        Tong: 0,
        AbleRed: 0,
        JinNan: this.state.OwnerDetail[0].JinNan,
        RedPercent: 0,
        OneStarBao: 1,
        Gamebettingcustom: this.state.OwnerDetail[0].GameBetting,
        GameBettingCustomArray: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
        GameBetting: this.state.OwnerDetail[0].GameBetting,
        AbleChange: this.state.OwnerDetail[0].AbleChange,
        Chai: this.state.OwnerDetail[0].Chai,
      }
      this.setState({ UplineGame: this.state.OwnerDetail[0].GameBetting })
      if (this.state.visibleForm && this.state.Mode === 'Create User') {
        this.setState({ 
          visibleForm: false,
          formData: {},
          oriFormData: {},
          FormKey2: [],
          FormKey1: [],
          parentLoading: false,
          Mode: '',
          Agentlvl: '',
        })
      } else {
        this.setState({ 
          visibleForm: true,
          parentLoading: true,
          activeKey: "NoEatWord",
          AllowSaveUsername: false,
          CreateformData : JSON.parse(JSON.stringify(createForm)),
          oriFormData: JSON.parse(JSON.stringify(createForm)),
          FormKey1: JSON.parse(createForm.KeyIn1),
          FormKey2: JSON.parse(createForm.KeyIn2),
          NewSetPackage: (this.state.FollowUpperline[0].KeyIn1 === "[\"B\", \"S\", \"C\", \"A\", \"A1\", \"A1C\"]" ) ? 'SetA' : (this.state.FollowUpperline[0].KeyIn1 === "[\"A1\", \"A2\", \"A3\", \"A1C\", \"D4\", \"E4\"]" ) ? 'SetB' : 'Custom',
          NewSetPackage2: (this.state.FollowUpperline[0].KeyIn2 === "[\"B\", \"S\", \"C\", \"A\", \"A1\", \"A1C\"]" ) ? 'SetA' : (this.state.FollowUpperline[0].KeyIn2 === "[\"A1\", \"A2\", \"A3\", \"A1C\", \"D4\", \"E4\"]" ) ? 'SetB' : 'Custom',
          Mode: 'Create User',
          SetPackage: '',
          SetPackage2: '',
          Agentlvl: 'CreateAcc',
          AbleChange: createForm.AbleChange,
        }, async()=>{
          this.FocusHere.executeScroll();
          this.setState({ packageNames: [], parentLoading: false })
        })
      }
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].DontHaveAutho)
      });
    }
  }

  async resetPackages() {
    this.setState({ packageNames: [] })
    let packageNames = await getPackageNames(this.props.username, this.state.formData.ID, this.props.token)
    packageNames = packageNames.sort(function(a,b){
      if ((a.childPackage === null) === (b.childPackage === null)) {
        return a.Name.localeCompare(b.Name);
      }
      return (a.childPackage === null) - (b.childPackage === null)
    })
    const tempActiveKey = this.state.activeKey.slice()
    this.setState({ packageNames, parentLoading: false, activeKey: '', OriginalpackageNames: packageNames }, () => {
      this.setState({ activeKey: tempActiveKey })
    })
  }

  resetForm() {
    this.setState({ 
      formData: JSON.parse(JSON.stringify(this.state.oriFormData)),
    })
  }

  dataChange(field, value, idx, Mode) {
    if (field === 'KeyIn1') {
    
      const currentKeyInArray = this.state.FormKey1
     
      currentKeyInArray[idx] = (value === '-') ? value : (value.toUpperCase()).replace(/[^BSCADEPF1234]/g, '')
    
      this.setState({ 
        [Mode]: {
          ...this.state[Mode],
          KeyIn1: JSON.stringify(currentKeyInArray),
          FormKey1: currentKeyInArray,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, 'KeyIn1', JSON.stringify(this.state.formData.FormKey1))
      })
    }else if(field === 'Username') {
      if (/[\u3400-\u9FBF]+/.test(value)) {
        this.openNotification(language[this.props.currentLanguage].NotAllowChinese)
        this.setState({ notificationType: 'error', loading: false });
      } else {
        this.setState({
          [Mode]: {
            ...this.state[Mode],
            [field]: value,
          },
        })
      }
      // return false;
    } else if(field === 'Password') {
      if (/[\u3400-\u9FBF]+/.test(value)) {
        this.openNotification(language[this.props.currentLanguage].NotAllowChinese)
        this.setState({ notificationType: 'error', loading: false });
      } else {
        this.setState({
          [Mode]: {
            ...this.state[Mode],
            [field]: value,
          },
        })
      }
      // return false;
    } else if(field === 'KeyIn2') {
      const currentKeyInArray = this.state.FormKey2
      currentKeyInArray[idx] = (value === '-') ? value :(value.toUpperCase()).replace(/[^BSCADEPF1234]/g, '')
      this.setState({ 
        [Mode]: {
          ...this.state[Mode],
          KeyIn2: JSON.stringify(currentKeyInArray),
          FormKey2: currentKeyInArray,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, 'KeyIn2', JSON.stringify(this.state.formData.FormKey2))
      })
    }  else if (field === 'EwPackage') {
      let currentPackages = JSON.parse(JSON.stringify(this.state.formData.EwPackage))
      currentPackages = value
      this.setState({ 
        formData: {
          ...this.state.formData,
          EwPackage: currentPackages,
        },
      })
    }  else if (field === 'Packages') {
      const currentPackages = this.state[Mode].Packages
      currentPackages[idx] = value
      this.setState({ 
        [Mode]: {
          ...this.state[Mode],
          Packages: currentPackages,
        },
      })
    } else if (field === 'DailyLimit') {
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: value.replace(/[^0-9]/g, ''),
        },
      })
    } else if (field === 'AbleRed' && Mode === 'CreateformData'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      })
    } else if (field === 'JinNan' && Mode === 'CreateformData'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      })
    } else if (field === 'AbleRed'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, RedNumb)
      })
    } else if (field === 'AbleSGD'){
      const SGDNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: SGDNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, SGDNumb)
      })
    } else if (field === 'AbleTHAI'){
      const THAINumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: THAINumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, THAINumb)
      })
    }  else if (field === 'Able101Import'){
      const AbleNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: AbleNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, AbleNumb)
      })
    }   else if (field === 'AbleDading'){
      const AbleNumbdading = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: AbleNumbdading,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, AbleNumbdading)
      })
    } 
    else if (field === 'JinNan' && Mode === 'CreateformData'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      })
    }  else if (field === 'JinNan' && Mode !== 'CreateformData'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, RedNumb)
      })
    } else if (field === 'OneStarBao' && Mode === 'CreateformData'){
      const TongNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: TongNumb,
        },
      })
    } else if (field === 'OneStarBao' && Mode === 'formData'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, RedNumb)
      })
    } else if (field === 'CashWalletActive' && Mode === 'formData'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, RedNumb)
      })
    } else if (field === 'ShowError' && Mode === 'formData'){
      const ShowErrorBoolean = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: ShowErrorBoolean,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, ShowErrorBoolean)
      })
    } else if (field === 'Tong' && Mode === 'CreateformData'){
      const TongNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: TongNumb,
        },
      })
    } else if (field === 'Tong'){
      const TongNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: TongNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, TongNumb)
      })
    } else if (field === 'Chai' && Mode !== 'CreateformData'){
      const Boolean = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: Boolean,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, Boolean)
      })
    } else if (field === 'Chai' && Mode === 'CreateformData'){
      const Boolean = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: Boolean,
        },
      })
    }   else if (field === 'FreeJPActive'){
      const TongNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: TongNumb,
        },
      })
    } else if (field === 'RedPercent'){
      const e = value
        .replace(/\D+/g, '')
        .replace(/^,|,$/g, '') 
 
      var output = e.split('');
      output.unshift('-');
      output = output.join('');
    
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: output,
        },
      })
    } else if (field === 'Status' && Mode !== 'CreateformData'){
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: value,
        },
      })
      this.updateUserF(this.state.formData.ID, field, value)
    } else if (field === 'MultiplyDivide' && Mode !== 'CreateformData'){
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: value,
        },
      })
      this.updateUserF(this.state.formData.ID, field, value)
    }  else if (field === 'CashWallet' && Mode !== 'CreateformData'){
      const e = value.replace(/[^0-9.]/g, '')
    
      let output = e.split('.');
      output = output.shift() + (output.length ? '.' + output.join('') : '');
    
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: output,
        },
      })
    }  else {
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: value,
        },
      })
    }
  }

  dataCreate(field, value, idx) {
    if (field === 'KeyIn1') {
      const currentKeyInArray = this.state.CreateformData.FilteredKeyIn1
      currentKeyInArray[idx] = (value.toUpperCase()).replace(/[^PBSCADEF1234]/g, '')
      this.setState({ 
        CreateformData: {
          ...this.state.CreateformData,
          KeyIn1: JSON.stringify(currentKeyInArray),
        },
        FormKey1: currentKeyInArray,
      })
    }
    else if(field === 'KeyIn2') {
      const currentKeyInArray = this.state.FormKey2
      currentKeyInArray[idx] = (value.toUpperCase()).replace(/[^PBSCADEF1234]/g, '')
      this.setState({ 
        CreateformData: {
          ...this.state.CreateformData,
          KeyIn2: JSON.stringify(currentKeyInArray),
        },
        FormKey2: currentKeyInArray,
      })
    } else if (field === 'DailyLimit') {
      this.setState({
        CreateformData: {
          ...this.state.CreateformData,
          [field]: value.replace(/[^0-9]/g, ''),
        },
      })
    } else {
      this.setState({
        CreateformData: {
          ...this.state.CreateformData,
          [field]: value,
        },
      })
    }
  }


  async updateUserF(userID, field, value, action) {
    if (userID !== null ) {
      if(value === '') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(`${field} 不能为空`)
        });
      } else {
        const result = await updateUser(this.props.username, this.props.token, userID, field, value, action)
        if (result.message && result.message === '成功更新') {
          // this.setState({ UplineGame: value })
          this.ApiRefresh(userID)
          if (field === 'MataLock'){
            this.setState({ 
              StatusUser: 'self',
              StatusUserLock: false,
            })
          }
          if (field === 'Status'){
            this.setState({ 
              StatusUser: 'self',
              StatusUserStatus: 'Active',
            })
          }
          if (field === 'EwF'){
            this.dataChange(field, value, undefined ,'formData')
          }
          if (field === 'Username'){
            this.setState({ 
              UpdateNameBoolean: true
            })
          }
          this.setState({ notificationType: 'success'}, async () => {
            this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
            this.setState({ AllowSaveUsername: false, AllowUsername: ''})
          });
        } else if (result.message === 'Username Updated'){
          this.setState({ notificationType: 'success',}, async () => {
            this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
            this.ApiRefresh(userID)
            this.setState({ AllowSaveUsername: false, AllowUsername: ''})
          });
        } else if (result.message === 'Upperline no ninth game'){
          this.setState({ notificationType: 'error',}, async () => {
            this.openNotification(language[this.props.currentLanguage].NoGamenine)
          });
        } else if (result.error === 'Non-admin cannot cancel') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].NonAdminXcancel)
          });
        } else if (result.error === 'Game Closed, cannot update package') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].GameClosePackage)
          });
        } else if (result.error === 'Upperline not same package') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].UpperlineNotSamePackage)
          });
        } else if (result.error === 'Downline not same package') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].DownlineNotSamePackage)
          });
        } else if (result.message === 'Unable active due to have pending order') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].UnableActiveDueToPendig)
          });
        } else if (result.error === 'Unable to update cash wallet') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].Unableupdatecashwallet)
          });
        } else {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].InvalidUser)
          });
        }
      }
    }
  }

  onKeyEnter(event, userID, field, value) {
    if (event.key === 'Enter') {
      if (this.state.Mode !== 'Create User'){
        if (field === 'Username') {
          if (this.state.Mode === 'Edit' && field === 'Username') {
            if (value.includes(`"`) || value.includes(`'`)){
              this.setState({ notificationType: 'error' }, () => {
                this.openNotificationMessageCheck(language[this.props.currentLanguage].CannotAcceptSymbol)
              });
            } else {
              this.updateUserF(userID, field, value)
            }
          }
          if (this.state.AllowSaveUsername === true) {
            if( this.state.AllowUsername === value){
              this.updateUserF(userID, field, value)
            } else {
              this.setState({ notificationType: 'error' }, () => {
                this.openNotification(language[this.props.currentLanguage].PleaseCheckAgain)
              });
            }
          }
        } else if (field === 'Password') {
          if (this.state.Mode === 'Edit' && field === 'Password') {
            
            if (value.length > 3){
              if (value.includes(`"`) || value.includes(`'`)){
                this.setState({ notificationType: 'error' }, () => {
                  this.openNotificationMessageCheck(language[this.props.currentLanguage].CannotAcceptSymbol)
                });
              } else {
                this.updateUserF(userID, field, value)
              }
            } else {
              this.setState({ notificationType: 'error' }, () => {
                this.openNotificationMessageCheck(language[this.props.currentLanguage].Password6digit)
              });
            }
          }
        } else if (field === 'Name') {
          if (this.state.Mode === 'Edit' && field === 'Name') {
            if (value.includes(`"`) || value.includes(`'`)){
              this.setState({ notificationType: 'error' }, () => {
                this.openNotificationMessageCheck(language[this.props.currentLanguage].CannotAcceptSymbol)
              });
            } else {
              this.updateUserF(userID, field, value)
            }
          }
        } else {
          const e = JSON.stringify(value)
            .replace(/\D+/g, '') // replace non digit with comma
            .replace(/^,|,$/g, '') // trim the comma
          const Numberfilter = Number(e)
          if (field === 'RedPercent'){
            if (Numberfilter > 100 ){
              this.setState({ notificationType: 'error' }, () => {
                this.openNotification(`不能超过 100% = ${Numberfilter}`)
              });
            } else {
              this.updateUserF(userID, field, Numberfilter)
            }
          } else {
            this.updateUserF(userID, field, Numberfilter)
          }
        } 
      } 
    }
  }

  onKeyEnterDelete(e) {
    if (e.charCode === 13) {
      document.getElementById("ChangePassButton").click()
    }
  }

  async CloseEWPackage(item){
    this.setState({ 
      activeKey: '',
    })
  }

  async updateAll(mode) {
    if (mode === 'EwPackage') {
      const copiedObject = typeof this.state.formData.EwPackage === 'string' ? JSON.parse(this.state.formData.EwPackage) : this.state.formData.EwPackage

      const newKeyArr = Object.keys(copiedObject.Default).slice(0,-1);
      const newKeyArrall = Object.keys(copiedObject.Default);
      const defaultArray = ['Default', 'M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9']
      const defaultMode = newKeyArr
      let MessageError = ''
      for (let i = 0; i < defaultArray.length; i+= 1) {
        if (copiedObject[defaultArray[i]]) {
          for (let j = 0; j < newKeyArrall.length; j+= 1) {
            if (copiedObject[defaultArray[i]][newKeyArrall[j]]) {
              if ( Number(copiedObject[defaultArray[i]][newKeyArrall[j]].percent || 0) > 100 ) {
                MessageError += `${defaultArray[i]} : ${newKeyArrall[j]} 吃字不能超过 100% : ${copiedObject[defaultArray[i]][newKeyArrall[j]].percent }, `
              } else if ( newKeyArrall[j] !== 'all' && (Number(copiedObject[defaultArray[i]][newKeyArrall[j]].amount || 0) > Number(copiedObject[defaultArray[i]][newKeyArrall[j]].max || 0) )) {
                MessageError += `${defaultArray[i]} : ${newKeyArrall[j]} 不能超过上线 : ${Number(copiedObject[defaultArray[i]][newKeyArrall[j]].amount) }, `
              }
            }
          }
        }
      }
      if (MessageError !== ''){
        this.setState({notificationType: 'error'}, ()=>{
          this.openNotificationAlert(MessageError)
        })
      } else {
        for (let i = 0; i < defaultArray.length; i+= 1) {
          if (copiedObject[defaultArray[i]]) {
            for (let j = 0; j < defaultMode.length; j+= 1) {
              if (copiedObject[defaultArray[i]][defaultMode[j]]) {
                if (copiedObject[defaultArray[i]][defaultMode[j]].amount === '') {
                  copiedObject[defaultArray[i]][defaultMode[j]].amount = '0'
                }
                if (copiedObject[defaultArray[i]][defaultMode[j]].percent === '') {
                  copiedObject[defaultArray[i]][defaultMode[j]].percent = '0'
                }
              }
            }
          }
        }
        this.setState({
          formData: {
            ...this.state.formData,
            EwPackage: copiedObject,
          }
        }, async () => {
          const objectToUpdate = {
            DailyLimit: this.state.formData.DailyLimit,
            EwPackage: this.state.formData.EwPackage,
            ID: this.state.formData.ID,
            KeyIn1: this.state.formData.KeyIn1,
            MultiplyDivide: this.state.formData.MultiplyDivide,
            Password: this.state.formData.Password,
            Role: this.state.formData.Role,
            Status: this.state.formData.Status,
          }
          const filterPassword = objectToUpdate.Password.replaceAll('*', '')
          if (filterPassword === ''){
            delete objectToUpdate.Password
          }
          const result = await updateUserAll(this.props.username, this.props.token, objectToUpdate)
      
          if (result.message && result.message === '成功更新') {
            this.setState({ notificationType: 'success' }, () => {
              this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
            });
            this.ApiRefresh(this.state.formData.ID);
          } else if (result.message && result.message === 'Update successful') {
            this.setState({ notificationType: 'success' }, () => {
              this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
            });
            this.ApiRefresh(this.state.formData.ID);
          } else if (result.message && result.message === 'Fail, Daily Limit Greater Than Upline Daily Limit') {
            this.setState({ notificationType: 'error' }, () => {
              this.openNotificationMessageCheck(language[this.props.currentLanguage].FailDailyLimit)
            });
          } else if(result.error === 'ewpackage error'){
            this.setState({ notificationType: 'error' }, () => {
              this.openNotificationMessageCheck('不能超过吃字上线')
            });
          } else if (result.message === 'Unable active due to have pending order') {
            this.setState({ notificationType: 'error' }, () => {
              this.openNotification(language[this.props.currentLanguage].UnableActiveDueToPendig)
            });
          } else {
            this.setState({ notificationType: 'error' }, () => {
              this.openNotificationMessageCheck(result.error)
            });
          }
        })
      }
    } else if (mode === 'OcPackage') {
      const objectToUpdate = {
        DailyLimit: this.state.formData.DailyLimit,
        ID: this.state.formData.ID,
        KeyIn1: this.state.formData.KeyIn1,
        MultiplyDivide: this.state.formData.MultiplyDivide,
        Password: this.state.formData.Password,
        Role: this.state.formData.Role,
        Status: this.state.formData.Status,
      }
      const filterPassword = objectToUpdate.Password.replaceAll('*', '')
      if (filterPassword === ''){
        delete objectToUpdate.Password
      }
      const result = await updateUserAll(this.props.username, this.props.token, objectToUpdate)
      if (result.message && result.message === '成功更新') {
        this.setState({ notificationType: 'success' }, () => {
          this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
        });
      } else if (result.message && result.message === 'Update successful') {
        this.setState({ notificationType: 'success' }, () => {
          this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
        });
        this.ApiRefresh(this.state.formData.ID);
      } else if (result.message && result.message === 'Fail, Daily Limit Greater Than Upline Daily Limit') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotificationMessageCheck(language[this.props.currentLanguage].FailDailyLimit)
        });
      } else if (result.message === 'Unable active due to have pending order') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(language[this.props.currentLanguage].UnableActiveDueToPendig)
        });
      } else {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotificationMessageCheck(result.error)
        });
      }
    }
  }

  SetPackageGame(SetPackage, Key){
    if(SetPackage === "SetA"){
      const SetAPackage = ['B', 'S', 'CF', 'CA', 'SA', 'SF']
      if (Key === 'KeyIn1') {
        this.setState({ 
          formData: {
            ...this.state.formData,
            [`${Key}`]:  JSON.stringify(SetAPackage),
            FilteredKeyIn1: SetAPackage,
          },
          SetPackage
        }, ()=>{
          this.updateUserF(this.state.formData.ID, 'KeyIn1', JSON.stringify(this.state.formData.FilteredKeyIn1))
        })
      } else {
        this.setState({ 
          formData: {
            ...this.state.formData,
            [`${Key}`]:  JSON.stringify(SetAPackage),
          },
          SetPackage2: SetPackage,
        }, ()=>{
          this.updateUserF(this.state.formData.ID, 'KeyIn2', this.state.formData.KeyIn2)
        })
      }
    } else if (SetPackage === "SetB"){
      const SetBPackage = ['SA', 'SB', 'SC', 'SD', 'SE', 'SF']
      if (Key === 'KeyIn1') {
        this.setState({ 
          formData: {
            ...this.state.formData,
            [`${Key}`]: JSON.stringify(SetBPackage),
            FilteredKeyIn1: SetBPackage,
          },
          SetPackage
        }, ()=>{
          this.updateUserF(this.state.formData.ID, 'KeyIn1', JSON.stringify(this.state.formData.FilteredKeyIn1))
        })
      } else {
        this.setState({ 
          formData: {
            ...this.state.formData,
            [`${Key}`]:  JSON.stringify(SetBPackage),
          },
          SetPackage2: SetPackage,
        }, ()=>{
          this.updateUserF(this.state.formData.ID, 'KeyIn2', this.state.formData.KeyIn2)
        })
      }
    } else {
      if (Key === 'KeyIn1') {
        this.setState({SetPackage})
      } else {
        this.setState({SetPackage2: SetPackage})
      }
    }
  }

  SetNewPackageGame(SetPackage, Key) {
    if(SetPackage === "SetA"){
      const SetAPackage = ['B', 'S', 'CF', 'CA', 'SA', 'SF']
      if (Key === 'KeyIn1') {
        this.setState({ 
          CreateformData: {
            ...this.state.CreateformData,
            [`${Key}`]: JSON.stringify(SetAPackage),
          },
          NewSetPackage: SetPackage,
        })
      } else {
        this.setState({ 
          CreateformData: {
            ...this.state.CreateformData,
            [`${Key}`]:  JSON.stringify(SetAPackage),
          },
          NewSetPackage2: SetPackage,
        })
      }
    } else if (SetPackage === "SetB"){
      const SetBPackage = ['SA', 'SB', 'SC', 'SD', 'SE', 'SF']
      if (Key === 'KeyIn1') {
        this.setState({ 
          CreateformData: {
            ...this.state.CreateformData,
            [`${Key}`]: JSON.stringify(SetBPackage),
          },
          NewSetPackage: SetPackage,
        })
      } else {
        this.setState({ 
          CreateformData: {
            ...this.state.CreateformData,
            [`${Key}`]:  JSON.stringify(SetBPackage),
          },
          NewSetPackage2: SetPackage,
        })
      }
    } else {
      if (Key === 'KeyIn1') {
        this.setState({NewSetPackage: SetPackage})
      } else {
        this.setState({NewSetPackage2: SetPackage})
      }
    }
  }

  async CreateNewOCPackage(CopyPackageComm, CopyOdd){
    let CopyedPackage = {
      Commission: '',
      Odd: '',
      PackageID: '',
    }
    const CreateOCPackeageResult = await createOcPackage(this.props.username, this.props.token);
    if (CopyPackageComm === '') {
      this.setState({ NewOCPackage: CreateOCPackeageResult,  activeKey: 'CreatePackage' })
    } else {
      CopyedPackage.Commission = CopyPackageComm
      CopyedPackage.Odd = CopyOdd
      CopyedPackage.PackageID = CreateOCPackeageResult.PackageID
      this.setState({ NewOCPackage: CopyedPackage, activeKey: 'CreatePackage'})
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 2000);
  };

  openNotificationAlert(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 5000);
  };

  openNotificationCreateUserMessage(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 2000);
  };


  openNotificationMessageCheck(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
      });
    }, 5000);
  };

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.SearchUser !== '') {
      const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.state.UserID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotificationMessageCheck(language[this.props.currentLanguage].InvalidDownlineIDorUsername)
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  async ClickCreateNewUser(){
    const FormData = this.state.CreateformData
    if ((FormData.Username || '') === ''){
      this.setState({ notificationType: 'error', AllowSaveUsername: false }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PleaseInsertUsername)
      });
    } else if (FormData.Username.includes(`"`) || FormData.Username.includes(`'`)){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].CannotAcceptSymbol)
      });
    } else if ((FormData.Password || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsInsertPassword)
      });
    } else if (FormData.Password.includes(`"`) || FormData.Password.includes(`'`)){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].CannotAcceptSymbol)
      });
    }  else if (FormData.Password.length < 4){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].Password6digit)
      });
    } else if ((FormData.Role || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsChooseUserRole)
      });
    } else if((FormData.Name || '') === '') {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsinsertName)
      });
    } else if (FormData.Name.includes(`"`) || FormData.Name.includes(`'`)){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].CannotAcceptSymbol)
      });
    }   else if ((FormData.KeyIn1 || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsInsertFormat)
      });
    }  else if ((FormData.KeyIn2 || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsChooseSituation)
      });
    } else if ((FormData.Status || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsInsertFormat)
      });
    } else if (Number(FormData.DailyLimit) < 0 || FormData.DailyLimit === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsInsertDailyLimit)
      });
    }  else if ((FormData.MultiplyDivide || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsChoosemultiplydivide)
      });
    } else if ((FormData.Upperline || '-') === '-'){
      this.setState({ notificationType: 'error'}, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsChooseUpperline)
      });
    } else {
      const valueKey = JSON.parse(FormData.KeyIn1 || []);
      const uniqueNamesArray = valueKey.filter((v,i,a)=>a.findIndex(t=>(t === v && t===v))===i)
      for (let i = 0; i < 6; i += 1) {
                   
        if ((uniqueNamesArray[i] || '') === '' ){
          uniqueNamesArray[i] = ''
        }
      }
      this.setState({ 
        CreateformData: {
          ...this.state.CreateformData,
          KeyIn1: JSON.stringify(uniqueNamesArray),
          FilteredKeyIn1: uniqueNamesArray,
          AbleChange: this.state.AbleChange,
        },
      }, async ()=> {
        const CreateNewUserresult = await createUser(this.state.CreateformData, Number(this.state.AccountAmount), this.props.token, this.props.username)

        if (CreateNewUserresult.error === 'Invalid format of multiNumber *(Must be number)*') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].InvalidformatofmultiNumber);
          });
        }
        if (CreateNewUserresult.error === 'Duplicate Username') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].UserDuplicated);
          });
        }
        if (CreateNewUserresult.message === 'Successful created user') {
          this.setState({ notificationType: 'success', AllowSaveUsername: false, AccountAmount: 1 }, () => {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].SuccessfulCreateUser);
            this.ApiRefresh(this.state.OwnerDetail[0].ID);
            
            this.toggleEditForm(CreateNewUserresult.userObject , 'Create User Done');
          });
        }
        if (CreateNewUserresult.error === 'Fail, Daily Limit Greater Than Upline Daily Limit') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].FailDailyLimit);
          });
        }
      })
    }
  }

  async updateAllUserInformation(){
    const CheckRole = ( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' ))
    const FormData =  JSON.parse(JSON.stringify(this.state.formData));
    if ((FormData.Username || '') === ''){
      this.setState({ notificationType: 'error', AllowSaveUsername: false }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PleaseInsertUsername)
      });
    } else if ((FormData.Password || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsInsertPassword)
      });
    } else if (FormData.Password.length < 4){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].Password6digit)
      });
    } else if ((FormData.Role || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsChooseUserRole)
      });
    } else if((FormData.Name || '') === '') {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsinsertName)
      });
    }  else if ((FormData.KeyIn1 || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsInsertFormat)
      });
    }  else if ((FormData.KeyIn2 || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsChooseSituation)
      });
    } else if ((FormData.Status || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsInsertFormat)
      });
    } else if (Number(FormData.DailyLimit) < 0 || FormData.DailyLimit === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsInsertDailyLimit)
      });
    }  else if ((FormData.MultiplyDivide || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsChoosemultiplydivide)
      });
    } else if ((FormData.Upperline || '-') === '-'){
      this.setState({ notificationType: 'error'}, () => {
        this.openNotificationMessageCheck(language[this.props.currentLanguage].PlsChooseUpperline)
      });
    } else {
      const CheckPassword = FormData.Password
      const filterPassword = CheckPassword.replaceAll('*', '')
      const ObjectUpdate = (CheckRole) ? {
        ID: FormData.ID,
        Username: FormData.Username,
        Password: FormData.Password,
      }:{
        Name: FormData.Name,
        Username: FormData.Username,
        Password: FormData.Password,
        DailyLimit: FormData.DailyLimit,
        RedPercent: FormData.RedPercent,
        ID: FormData.ID,
        CashWallet: FormData.CashWallet,
      }
      if (filterPassword === ''){
        delete ObjectUpdate.Password
      }
      
      const updateUserMobileResult = await updateUserMobile( ObjectUpdate, this.props.username, this.props.token)
      if (updateUserMobileResult.message === "Update successful"){
        this.setState({ notificationType: 'success' }, () => {
          this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
          this.ApiRefresh(ObjectUpdate.ID);
        });
      } else if (updateUserMobileResult.message && updateUserMobileResult.message === 'Fail, Daily Limit Greater Than Upline Daily Limit') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotificationMessageCheck(language[this.props.currentLanguage].FailDailyLimit)
        });
      } else if(updateUserMobileResult.error === 'ewpackage error'){
        this.setState({ notificationType: 'error' }, () => {
          this.openNotificationMessageCheck('不能超过吃字上线')
        });
      } else if (updateUserMobileResult.error === 'Unable to update cash wallet') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(language[this.props.currentLanguage].Unableupdatecashwallet)
        });
      } else {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotificationMessageCheck(updateUserMobileResult.borderColor);
        });
      }
    }
  }

  async DeleteAccount(DeleteUSerID, type) {
    let DeleteUserID = ''
    if (type === 'Multi') {
      DeleteUserID = DeleteUSerID
    } else {
      DeleteUserID = [DeleteUSerID]
    }
    this.setState({ OpenChangePassword: true, DeleteUSerID: DeleteUserID })
  }

  async ConfirmAccount(value) {
    if (this.state.UserPassword === '') {
      this.openNotification(`${language[this.props.currentLanguage].CurrentPasswordEmpty}`);
      this.setState({ showNotificationpass: 'error', showNotification: 'error', notificationType: 'error' })
    } else {
      const r = (window.confirm(language[this.props.currentLanguage].DeleteUSerConfirmation));
      if (r === true) {
        const deleteUser = await DeleteUsers(this.state.DeleteUSerID, this.state.UserPassword, this.props.token, this.props.username)

        if (deleteUser.message === 'Successfuly Removed') {
          this.openNotificationMessageCheck(language[this.props.currentLanguage].SuccessDeleteUser)
          // const tempData = this.state.formData.Upperline
          this.setState({  PreviousBool:  true, formData: {}, notificationType: 'success', visibleForm: false, OpenChangePassword: false, showNotificationpass: 'success', UserPassword: '', fastDeleteUsers: false, SelectedDeleteUsers: [] }, ()=> {
            // this.ApiRefresh(tempData);
            this.BackToOriginalForm();
          });
        } else {
          if (deleteUser.error === 'Got Downline') {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].GotDownlineCantDelete)
            this.setState({ notificationType: 'error', OpenChangePassword: false, showNotificationpass: 'error', UserPassword: '' })
          } else if (deleteUser.error === 'Betting Date Error') {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].DeleteuserFaillastBet)
            this.setState({ notificationType: 'error', OpenChangePassword: false, showNotificationpass: 'error', UserPassword: '' })
          } else if (deleteUser.error === 'wrong Password') {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].PasswordWrong)
            this.setState({ notificationType: 'error', showNotificationpass: 'error' })
          } else if (deleteUser.error === 'Got Report') {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].DeleteuserFaillastreport)
            this.setState({ notificationType: 'error', OpenChangePassword: false, showNotificationpass: 'error', UserPassword: '' })
          }
        }
      } else {
        return null;
      }
    }
  }

  handleChange = OptionsPackage => {
    this.setState({ SelectedPackage: OptionsPackage }, ()=>{
      let NewArray = []
      if ((this.state.SelectedPackage).length === 0 ){
        this.setState({ packageNames: JSON.parse(JSON.stringify(this.state.OriginalpackageNames))})
      } else {
        this.state.SelectedPackage.forEach((CheckItem) =>{
          this.state.OriginalpackageNames.forEach((OriCheckItem) =>{
            if (CheckItem.value === OriCheckItem.Name){
              NewArray.push(OriCheckItem)
            }
          })
        })
        this.setState({ packageNames: JSON.parse(JSON.stringify(NewArray)), BackPackageNames: false})
      }
    
    });
  };

  ActiveNotification(message){
    if (message === 'successful'){
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(language[this.props.currentLanguage].Success)
      });
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(message)
      });
    }

  }

  MultiplyCheckFunction( status){
    if (status !== undefined){
      this.setState({ MultipleSetValue: status.value})
    } else {
      let filterPackage = this.state.packageNames
      this.state.packageNames.forEach((item, index) =>{
        if (item.BooleanMultipleCheck === true){
          filterPackage[index].BooleanMultipleCheck = false
        }
      })
      this.setState({ MultiplyCheck: !this.state.MultiplyCheck, packageNames: JSON.parse(JSON.stringify( filterPackage )), MultipleSetValue: 0})
    }
  }

  ButtonChoosePackage(item, index){
    let BooleanPackage = this.state.packageNames
    BooleanPackage[index] = {...this.state.packageNames[index], BooleanMultipleCheck: false}
   
    this.setState({ activeKey: item.PackageID, packageNames: JSON.parse(JSON.stringify(BooleanPackage)) }, ()=>{
      this.CheckSelectedMultiple()
    })
  }

  CheckSelectedMultiple(){
    const CheckBoolean = []
    this.state.packageNames.forEach((item, index) =>{
      if ((item.BooleanMultipleCheck || false) === false){
        CheckBoolean.push(false)
      } else {
        CheckBoolean.push(true)
      }
    })
    if (CheckBoolean.includes(true)){
      this.setState({ MultiplyCheck: true})
    } else {
      this.setState({ MultiplyCheck: false, SelectedPackageNumber: {value: 0, label: 0}})
    }
  }

  CheckAllPackage(){
    let BooleanPackage = this.state.packageNames
    BooleanPackage.forEach((item, index) =>{
      if (item.BooleanMultipleCheck !== undefined){
        if (this.state.BooleanCheckAllPackage === true) {
          BooleanPackage[index].BooleanMultipleCheck = false
        } else {
          BooleanPackage[index].BooleanMultipleCheck = true
        }
      } else {
        BooleanPackage[index] = {...this.state.packageNames[index], BooleanMultipleCheck: true}
      }
    })
    this.setState({ BooleanCheckAllPackage: !this.state.BooleanCheckAllPackage,packageNames: JSON.parse(JSON.stringify(BooleanPackage)) }, ()=>{
      this.CheckSelectedMultiple();
    })
  }

  SelectedMultiple(item, index, status){
    
    if (this.state.MultiplyCheck){
      if (  item.BooleanMultipleCheck === undefined){
        let BooleanPackage = this.state.packageNames
        BooleanPackage[index] = {...this.state.packageNames[index], BooleanMultipleCheck: true}
        this.setState({ packageNames: JSON.parse(JSON.stringify(BooleanPackage)) }, ()=>{
          this.CheckSelectedMultiple();
        })
      } else  if ( item.BooleanMultipleCheck === true ){
        let BooleanPackage = this.state.packageNames
        BooleanPackage[index].BooleanMultipleCheck = false
        this.setState({ packageNames: JSON.parse(JSON.stringify(BooleanPackage)) }, ()=>{
          this.CheckSelectedMultiple();
        })
      } else {
        let BooleanPackage = this.state.packageNames
        BooleanPackage[index].BooleanMultipleCheck = true
        this.setState({ packageNames: JSON.parse(JSON.stringify(BooleanPackage)) }, ()=>{
          this.CheckSelectedMultiple();
        })
      }
    } else {
    
      let filterPackage = this.state.packageNames
      if ((item.BooleanMultipleCheck || false) === false){
        filterPackage[index] = {...this.state.packageNames[index], BooleanMultipleCheck: true}
      } else {
        this.state.packageNames.forEach((item, index) =>{
          if (item.BooleanMultipleCheck === true){
            filterPackage[index].BooleanMultipleCheck = false
          }
        })
      }
      this.setState({ activeKey: item.PackageID, MultiplyCheck: !this.state.MultiplyCheck, packageNames: JSON.parse(JSON.stringify( filterPackage )), MultipleSetValue: 0, SelectedPackageNumber: {value: 0, label: 0}}, ()=>{
        if (  item.BooleanMultipleCheck === undefined){
          let BooleanPackage = this.state.packageNames
          BooleanPackage[index] = {...this.state.packageNames[index], BooleanMultipleCheck: true}
          this.setState({ packageNames: JSON.parse(JSON.stringify(BooleanPackage)) })
        }
      })
    }
  }

  SubmitMultiplePackageAlert(){
    this.setState({AlertModal: true})
  }

  async SubmitMultiplePackage(SelectedType){
    if (this.state.MultiplyCheck){
      let filterpackage = []
      this.state.packageNames.forEach((item)=>{
        if (item.BooleanMultipleCheck !== undefined && item.BooleanMultipleCheck === true){
          filterpackage.push(item)
        }
      })
      const FinalPackageStartSubmit = {
        Value: this.state.MultipleSetValue,
        Package: filterpackage,
      }

      if(SelectedType === 'Active') {
        if (Array.isArray(filterpackage) && (filterpackage.length) !== 0) {
          const activeMultiplePackageresult = await activeMultiplePackage(FinalPackageStartSubmit, this.state.formData.ID, this.props.username, this.props.token)
  
          if (activeMultiplePackageresult.error){
            this.setState({ notificationType: 'error' }, () => {
              this.openNotification(`${language[this.props.currentLanguage].Unabletoupdate}`)
            });
          } else if (activeMultiplePackageresult.message){
            this.setState({ notificationType: 'success', BooleanCheckAllPackage: false }, () => {
              this.openNotification(language[this.props.currentLanguage].SuccessActivePackage)
              this.ApiRefresh(this.state.formData.ID);
            });
          }
        } else {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(`${language[this.props.currentLanguage].NoPackage}`)
          });
        }
      }
      this.resetPackages()
    }
  }

  async OpenAccount(SelectedID) {
    const UnlockUser = await UnlockUsers(SelectedID, this.props.username, this.props.token)
    if(UnlockUser) {
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(language[this.props.currentLanguage].SuccessOpenAccount)
        this.ApiRefresh();
      });
    }
  }


  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  
  FastDeleteUsers() {
    this.setState({ fastDeleteUsers: !this.state.fastDeleteUsers, SelectedDeleteUsers: [] })
  }

  SelectedDeleteUsers(users, e) {
    let SelectedDeleteUsers = this.state.SelectedDeleteUsers
    if(SelectedDeleteUsers.includes(users)) {
      SelectedDeleteUsers = SelectedDeleteUsers.filter(function (el) {
        return el !== users
      });
    } else {
      SelectedDeleteUsers.push(users)
    }
    this.setState({ SelectedDeleteUsers })
  }

  async updateUserAddWater(formID) {
    const DataAddWater = {
      Status: this.state.AddWaterStatusUser,
      ID: formID,
      AutoAddWaterShowReal : (this.state.AutoAddWaterShowReal || 0),
      AX3: (this.state.AX3 || 0),
      SenNotAdd: (this.state.Sennotadd || 0),
      AutoAddWaterJSON: Object.keys(this.state.WaterCommission).length === 0 ? this.state.formData.AutoAddWaterJSON : this.state.WaterCommission,
    }
    const AddWaterResult = await AddWater(DataAddWater, this.props.username, this.props.token)
    if(AddWaterResult.message === 'Successful Updated') {
      this.setState({ notificationType: 'success', AddWaterStatusUser: 'self' }, () => {
        this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
        this.ApiRefresh(formID);
      });
    } else if (AddWaterResult.error === 'Over Percentage') {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].CannotOverPercen)
      });
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(AddWaterResult.error)
      });
    }
  }
  
  async FreeJackPortFunction(formID){
    const userJackpotObject = {
      subAction: this.state.AddWaterStatusUser,
      selectedID: formID,
      FreeBTJPAmount: (this.state.FreeBTJPAmount || 0),
      freeJPActive: (this.state.JPFreeJPActive || 0),
      freeJPAmount : (this.state.JPFreeJPAmount || 0),
      freeJPMultiply : (this.state.JPFreeJPMultiply || 0),
      freeJPX10 : (this.state.JPFreeJPX10 || 0),
    }
    const jackpotSettingResult = await jackpotSetting(userJackpotObject, this.props.username, this.props.token)
    if(jackpotSettingResult.message === 'Success') {
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
        this.ApiRefresh(formID);
      });
    }else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(jackpotSettingResult.error)
      });
    }
  }

  async VirtualFunction(formID) {
    const VirtualObject = {
      selectedID: formID,
      VirtualJSON: this.state.VirtualCommission,
      Active: Number(this.state.VirtualActiveState || 0),
      // Percent : Number(this.state.VirtualPercentState || 0),
    }
    
    const VirtualResult = await Virtual(VirtualObject, this.props.username, this.props.token )
    
    if(VirtualResult.message === 'Successful Updated') {
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
        this.ApiRefresh(formID);
      });
    } else if (VirtualResult.error === 'Over Percentage') {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].CannotOverPercen2)
      });
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(VirtualResult.error)
      });
    }
  }

  async MiFunction(MiFunction){
    const updateXingJinFormulaResult = await updateXingJinFormula(MiFunction, this.state.formData.ID, this.props.username, this.props.token )

    if(updateXingJinFormulaResult.message === 'Update Success') {
      this.setState({ notificationType: 'success'}, () => {
        this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
        this.ApiRefresh( this.state.formData.ID);
      });
    }
  }
  
  ModalDataChange(Field, Value){
    if ( (Field === 'VirtualPercentState' ) && (Value.length > 2) ){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].minimum4Digit2)
      });
    } else if ( (Field === 'JPFreeJPMultiply' || Field === 'AutoAddWaterPercent' ) && (Value.length > 3) ){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].minimum4Digit)
      });
    } else {
      this.setState({ [Field]: Value })
    }
  }

  async toggleSetEditForm(formData) {
    this.setState({activeKeys: ''})
  }

  toggleCloseForm(){
    this.setState({activeKey: ''})
  }

  async DetailFucntion(event, GameKey){
    if (event.key === '+') {
      this[GameKey].focus();
      event.preventDefault();
    }
  }

  async onChangeDetail(event, item, Key, Package){
    const e = event.target.value
      .replace(/\D+/g, '.')
      .replace(/^,|,$/g, '')
 
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');

    if (Package === 'Odd'){
      this.setState({ SetDetailValue: JSON.parse(JSON.stringify( 
        (item === '5D' || item === '6D')  ? 
          {
            ...this.state.SetDetailValue,
            [Package]: {
              ...this.state.SetDetailValue[Package],
              [item]: {
                ...this.state.SetDetailValue[Package][item],
                [item === '5D' ? '5D1' : item === '6D' ? '6D' : item]: output
              },
            }
          } : (item === 'CA' || item === 'PA') ? {
            ...this.state.SetDetailValue,
            [Package]: {
              ...this.state.SetDetailValue[Package],
              [item]: output,
            }
          } : {
            ...this.state.SetDetailValue,
            [Package]: {
              ...this.state.SetDetailValue[Package],
              [Key]: {
                ...this.state.SetDetailValue[Package][Key],
                [item]: output
              },
            }
          }
      ))
      })
    } else {
      this.setState({ SetDetailValue: JSON.parse(JSON.stringify( {
        ...this.state.SetDetailValue,
        [Package]: {
          ...this.state.SetDetailValue[Package],
          [Key]: {
            ...this.state.SetDetailValue[Package][Key],
            [item]: output
          },
        }
      }))
      })
    }
  }

  async SubmitDetail(){
    let NewArray = JSON.parse(JSON.stringify(this.state.SetDetailValue))
    
    const Package = Object.keys(NewArray)
    
    Package.forEach((PackageKey) =>{
      const CallObjecKey = Object.keys(NewArray[PackageKey])
      if ((JSON.stringify(NewArray[PackageKey]) === '{}')){
        delete NewArray[PackageKey]
      }
      CallObjecKey.forEach((ObjectKey) =>{
        const CallObject = Object.keys(NewArray[PackageKey][ObjectKey])
        CallObject.forEach((item) =>{
          if ( NewArray[PackageKey][ObjectKey][item] === ''){
            delete NewArray[PackageKey][ObjectKey][item]
            if ((JSON.stringify(NewArray[PackageKey][ObjectKey]) === '{}')){
              delete NewArray[PackageKey][ObjectKey]
            }
          }
        })
        
      })

    })
    const PackageDone = JSON.parse(JSON.stringify(NewArray))
    let searchPackageResult = await searchPackage( this.state.formData.ID, PackageDone, this.props.username, this.props.token)
    if (searchPackageResult.message === 'No Data'){
      this.setState({notificationType: 'error', SetDetailValue: {Odd:{}, Commission:{}}}, ()=>{
        this.openNotification(searchPackageResult.message)
      })
    } else if (searchPackageResult.message){
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(searchPackageResult.message)
      })
    } else {
      this.setState({packageNames: searchPackageResult, modalShowDetail: false, modalShow: true, BackPackageNames: true, SetDetailValue: {Odd:{}, Commission:{}}})
    }
  }

  handleChangePackageNumber = OptionsPackageNumber => {
    this.setState({ SelectedPackageNumber: OptionsPackageNumber }, ()=>{
      this.MultiplyCheckFunction(OptionsPackageNumber)
    })
  }

  UpdateSearchPackage(PackageItem, BooleanBack){
    this.setState({packageNames: PackageItem, BackPackageNames: BooleanBack})
  }

  propsSetState(obj, userID) {
    if(userID) {
      this.setState(obj)
      this.FreeJackPortFunction(userID)
    } else {
      this.setState(obj)
    }
  }

  updateGameBetting(field, value) {
    if (this.state.Mode !== 'Create User'){
      this.setState({ formData: {
        ...this.state.formData,
        [field]: value,
      }})
      if (value === 'Custom') {
        this.setState({ GameBettingCustom: this.state.formData.GameDisplay === 'MKTSWBD' ? ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'] : ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9']})
      } else {
        this.updateUserF(this.state.formData.ID, field, value)
      }
    } else {
      const newObject = this.state.CreateformData
      newObject[field] = value
      if (value === 'Custom') {
        newObject['GameBettingCustom'] = this.state.CreateformData.GameDisplay === 'MKTSWBD' ? (this.state.Authority.GameBetting.includes('9')) ? ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9',] : ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9', '-'] :  (this.state.Authority.GameBetting.includes('9')) ? ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9',] : ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9','-']
        newObject['GameBettingCustomArray'] = this.state.CreateformData.GameDisplay === 'MKTSWBD' ? (this.state.Authority.GameBetting.includes('9')) ? ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9',] : ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9','-'] :  (this.state.Authority.GameBetting.includes('9')) ? ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9',] : ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8','9', '-']
      } else {
        newObject['GameBettingCustom'] = []
      }
      this.setState({ CreateformData: newObject})
    }
  }

  updateGameBettingCustom(inx, value) {
    if (this.state.Mode !== 'Create User'){
      // if (!this.state.Authority.GameBetting.includes('9') && value === '9') {
      //   this.setState({notificationType: 'error'}, ()=>{
      //     this.openNotification(language[this.props.currentLanguage].NoGamenine);
      //   })
      // } else {
      const gameBettingCopy = JSON.parse(JSON.stringify(this.state.GameBettingCustom))
      gameBettingCopy[inx] = value
      for (let i = 0; i < 9; i += 1) {
        if (i === inx) {
          //ignore
        } else if (gameBettingCopy[i] === value) {
          gameBettingCopy[i] = '-'
        }
      }
      this.setState({ GameBettingCustom : gameBettingCopy })
      if (this.state.formData.GameDisplay === 'MKTSWBD') {
        this.updateUserF(this.state.formData.ID, 'GameBetting', gameBettingCopy.join('').replace('M', '1').replace('K', '2').replace('T', '3').replace('S', '4').replace('W', '5').replace('B', '6').replace('D', '7').replaceAll('-', '-'))
      } else if (this.state.formData.GameDisplay === 'MPTSWBK') {
        this.updateUserF(this.state.formData.ID, 'GameBetting', gameBettingCopy.join('').replace('M', '1').replace('P', '2').replace('T', '3').replace('S', '4').replace('W', '5').replace('B', '6').replace('K', '7').replaceAll('-', '-'))
      }
      // }
    } else {
      const gameBettingCopy = JSON.parse(JSON.stringify(this.state.CreateformData.GameBettingCustomArray))
     
      gameBettingCopy[inx] = value
      for (let i = 0; i < 9; i += 1) {
        if (i === inx) {
          //ignore
        } else if (gameBettingCopy[i] === value) {
          gameBettingCopy[i] = '-'
        }
      }
      this.setState({ CreateformData: {
        ...this.state.CreateformData,
        Gamebettingcustom: this.state.CreateformData.GameDisplay === 'MKTSWBD' ? gameBettingCopy.join('').replace('M', '1').replace('K', '2').replace('T', '3').replace('S', '4').replace('W', '5').replace('B', '6').replace('D', '7').replaceAll('-', '-') : gameBettingCopy.join('').replace('M', '1').replace('P', '2').replace('T', '3').replace('S', '4').replace('W', '5').replace('B', '6').replace('K', '7').replaceAll('-', '-'),
        GameBettingCustomArray: gameBettingCopy,
      }})
    }
  }

  CopyPackage(CopyPackageComm, CopyOdd) {
    this.CreateNewOCPackage(CopyPackageComm, CopyOdd);
  }

  handleClose() {
    this.setState({
      SearchUSersModal: false, SearchedID: [] 
    })
  }

  PlatformChange(platform) {
    const GDisplay = localStorage.getItem('GameDisplay');
    let ProcessPlat = processPlatformPosition('123456789' ,GDisplay , platform, '0')
    return ProcessPlat.join('')
  }

  OpenFastRegister() {
    this.props.history.push('/FastRegister')
  }

  async selfFightCallAPI() {
    const getUserBanresult = await getUserBan( this.state.formData.ID, this.props.username, this.props.token )
    // console.log('getUserBanresult', getUserBanresult)
    let boolean = false;
    if(getUserBanresult[0] && getUserBanresult[0].Message === 'New') {
      const result = window.confirm(`${language[this.props.currentLanguage].createselfeatword}`);
      if (result) {
        boolean = true;
      } else {
        boolean = false;
      }
      if(boolean === true) {
        const updateresult = getUserBanresult[0]
        const result = JSON.parse(JSON.stringify(this.state.BanresultArray))
        result['Ban4Da'] = updateresult['Ban4Da'] === 0 ? false : true;
        result['Ban6Da'] = updateresult['Ban6Da'] === 0 ? false : true;
        result['Ban12Da'] = updateresult['Ban12Da'] === 0 ? false : true;
        result['Ban24Da'] = updateresult['Ban24Da'] === 0 ? false : true;
        this.setState({
          BanresultArray: result,
          FightArrayNumb: JSON.parse(updateresult['BanWord']),
          SelfFightModal: true,
        })
      }
    } else {
    
      if(getUserBanresult.message === 'No record'){
        this.setState({notificationType: 'error'}, ()=>{
          this.openNotificationAlert(language[this.props.currentLanguage].norecord)
        })
      } else {
        const updateresult = getUserBanresult[0]
        const result = JSON.parse(JSON.stringify(this.state.BanresultArray))
        result['Ban4Da'] = updateresult['Ban4Da'] === 0 ? false : true;
        result['Ban6Da'] = updateresult['Ban6Da'] === 0 ? false : true;
        result['Ban12Da'] = updateresult['Ban12Da'] === 0 ? false : true;
        result['Ban24Da'] = updateresult['Ban24Da'] === 0 ? false : true;
        this.setState({
          BanresultArray: result,
          FightArrayNumb: JSON.parse(updateresult['BanWord']),
          SelfFightModal: true,
        })
      }
    }
  }

  async SelfFightFunction(Status, CheckStatus, ID){
    const updateUserBanresult =  await updateUserBan(this.state.formData.ID, Status, CheckStatus, this.props.username, this.props.token)
   
    if(updateUserBanresult.message === "Update success") {
      this.setState({ notificationType: 'success'}, () => {
        this.openNotification(language[this.props.currentLanguage].UpdateSuccess)
      });
    } else {
      this.setState({ notificationType: 'error'}, () => {
        this.openNotification(updateUserBanresult.error)
      });
    }

  }

  render() {
    // { node: '14.15.1', npm: '6.14.8' },
    // current: { node: 'v18.18.2', npm: '9.8.1' }
    // console.log('this.state.formData', this.state.formData.AbleDading)
    const animatedComponents = makeAnimated();
    return(
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', backgroundColor: '#EFEFEF', height: '100vh',overflowY: 'auto', flexDirection: 'column' }}> 
        <Modal centered show={this.state.SearchUSersModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {`选择寻找用户`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(this.state.SearchedID.length === 0 ? (
              <div style={{ fontWeight: 'bold', fontSize: '20px'}}>{language[this.props.currentLanguage].NoUserFound}</div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className='dcc' style={{ maxHeight: '75vh', overflowY: 'scroll', display: 'flex', flexDirection: 'row', whiteSpace: 'pre-wrap', flexWrap: 'wrap', padding: 2 }}>
                  {this.state.SearchedID.map((items, index) => {
                    return(
                      // <div>
                      //   <div className='dcc' key={index} style={{ border: '0.5px solid lightgrey', borderRadius: 5, padding: 5, cursor: 'pointer', marginBottom: 1, fontWeight: 'bold' }} onClick={() => this.ShowDownline(items.ID)}>
                      //     {items.Username}
                      //   </div>
                      // </div>
                      <button key={index} className='dcc buttonUSerList' style={{ display: 'flex', flexDirection: 'column'}}
                        onClick={() => this.ShowDownline(items.ID)}>
                        <div>{`${items.Username}`}</div>
                        <div>{`${items.Role}`}</div>
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </Modal.Body>
        </Modal>
        <div ref={this.elScroll.elRef} >
          <Header2 />
        </div>
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width: '100%'}} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div style={{ padding: 10}}>
          <div style={{ textAlign: 'left', marginBottom: 5}}>
            <Button  className='button2' variant="primary" onClick={() => this.OpenFastRegister()} >{language[this.props.currentLanguage].fastRegister}</Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, flexDirection:  'row' }}>
            <div style={{ marginTop:  null }}>
              {(this.props.Role === 'player' ) ? (
                <></>
              ):(
                <Button  className='button2' variant="primary" onClick={() => this.OpenNewForm()} >{language[this.props.currentLanguage].CreateAcc}</Button>
              )}
              <Button  className='button2' variant="primary" style={{ marginRight: 10, marginLeft: 10}} onClick={()=> this.BackToOriginalForm()}>{language[this.props.currentLanguage].Back}</Button>
              {this.state.PreviousBool !== true && 
              <Button  className='button2' variant="primary" style={{ marginRight: 10}} onClick={()=> this.BackToPreviousForm()}>{language[this.props.currentLanguage].BackUpperline}</Button>
              }
            </div>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <div style={{ margin: '0px 10px',  fontWeight: 'bold'}}>{language[this.props.currentLanguage].SearchAccount}</div>
              <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
              <Button  className='button2' variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
            </div>
            <div style={{ marginTop: null }}>
              {(this.state.fastDeleteUsers && this.state.SelectedDeleteUsers.length > 0) &&
                <Button  className='button2' variant="danger" style={{ marginRight: 10}} onClick={()=> this.DeleteAccount(this.state.SelectedDeleteUsers, 'Multi')}>{language[this.props.currentLanguage].DeleteallUsers}</Button>
              }
              {(this.props.Role !== 'player' && this.props.Role !== 'agent') &&<Button  className='button2' variant={ (this.state.fastDeleteUsers === true) ? 'warning' : 'danger'} style={{ marginRight: 10, backgroundColor: (this.state.fastDeleteUsers === true) ? 'orange' : 'red' }} 
                onClick={()=> this.FastDeleteUsers()}>{language[this.props.currentLanguage].DeleteFast}</Button>}
            </div>
          </div>
          <div style={{ paddingBottom: 5, flexWrap: 'wrap', display: 'flex', alignItems: 'center', paddingLeft: 10}}>
            {this.state.allUpperlineData.map((item, index) => 
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <Button variant="link" style={{ fontSize: 20 }} onClick={() => this.ShowDownline(item.ID)}>{item.Username}</Button>
                {index !== this.state.allUpperlineData.length - 1 && <div style={{ fontSize: 20 }}>-</div>}
              </div>
            )}
          </div>
          <Table responsive="sm" striped bordered hover>
            <thead>
              <tr>
                {/* <th><div style={{ width: 50 }}>ID</div></th> */}
                <th><div style={{ minWidth: 180 }}>{language[this.props.currentLanguage].Action}</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].Name}</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].USerLoginAcc}</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].Password}</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].Role}</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].Status}</div></th>
                <th><div style={{ width: 80 }}>{language[this.props.currentLanguage].ActivePackage}</div></th>
                <th><div style={{ width: 80 }}>个人销售额极限/期</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].MD}</div></th>
                <th><div style={{ width: 100, minWidth: 180 }}>{language[this.props.currentLanguage].InputMethod}</div></th>
                {/* <th><div style={{ width: 80 }}>{language[this.props.currentLanguage].LatestOdd}</div></th> */}
                <th><div style={{ width: 80, minWidth: 100 }}>{language[this.props.currentLanguage].ChangeDate}</div></th>
              </tr>
            </thead>
            <tbody>
              {this.state.OwnerDetail.map((Owners, indexs) => {
                return <tr key={indexs}>
                  <td style={{ verticalAlign: 'middle'}}>
                    <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                      <div style={{ marginRight: 10 }} >
                        <Button  className='button2' variant="primary" style={{ width: '100%' }} onClick={() => this.toggleEditForm(Owners, '' ,'Up')}>{language[this.props.currentLanguage].Edit}</Button>
                      </div>
                      {(Owners.LoginAttempt >= 3) && 
                        <div>
                          <Button  className='button2' style={{  width: '100%', backgroundColor: 'red' }} onClick={() => this.OpenAccount(Owners.ID)}>{language[this.props.currentLanguage].Open}</Button>
                        </div>}
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'middle', fontWeight: 'bold'}}>{Owners.Name}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: 'bold'}}>{Owners.Username}</td>
                  <td style={{ verticalAlign: 'middle'}}>{Owners.Password}</td>
                  {/* {( (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? true : ( ((this.state.OwnerDetail).length > 0 ? this.state.OwnerDetail[0].Username : '') === this.props.username ) ? true : false) ? <td style={{ verticalAlign: 'middle'}}>{Owners.Password}</td> : <td/>} */}
                  <td style={{ verticalAlign: 'middle'}}>{Owners.Role}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: 'bold' , color: Owners.Status === 'Active' ? 'green' : Owners.Status === 'Inactive' ? 'red' : Owners.Status === 'Demo' ? 'orange' : ''}}>{Owners.Status}</td>
                  <td style={{ verticalAlign: 'middle'}}>
                    {Owners.ActivePackageIDName}
                    <div style={{flexDireaction: 'column',backgroundColor: '#ffff66' }}>
                      <div>{((Owners.ActivePackageComm_MG_B || '' ) !== '') ? `MG B: ${Owners.ActivePackageComm_MG_B}` : ''}</div>
                    </div>
                    <div style={{flexDireaction: 'column',backgroundColor: '#8a4949', color: 'white' }}>
                      <div>{((Owners.ActivePackageComm_BT_B || '' ) !== '') ? `BT B: ${Owners.ActivePackageComm_BT_B}` : ''}</div>
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'middle'}}>{Owners.DailyLimit}</td>
                  <td style={{ verticalAlign: 'middle'}}>{Owners.MultiplyDivide}</td>
                  <td style={{ verticalAlign: 'middle'}}>
                    <div>{(Owners.KeyIn1 !== 'null') ? JSON.parse(Owners.KeyIn1).join(', ') : '-'}</div>
                    <div>{(Owners.KeyIn2 !== 'null') ? JSON.parse(Owners.KeyIn2).join(', ') : '-'}</div>
                  </td>
                  {/* <td style={{ verticalAlign: 'middle'}}>{Owners.LastOrderID}</td> */}
                  <td style={{ verticalAlign: 'middle'}}>{moment(Owners.LastUpdateDateTime).format('YYYY-MM-DD')}</td>
                </tr>
              })}
              {(this.props.Role !== 'player') && <tr>
                <td colSpan="14" style={{ backgroundColor: 'grey', color: 'white'}}>
                  {(this.props.Role === 'admin') ? `${language[this.props.currentLanguage].AllUser}` : `${language[this.props.currentLanguage].Downline}`}
                </td>
              </tr>}
              {(this.state.Authority.AcctDisplay === 1) ?
                <>
                  {this.state.users.map((item, index) => {
                    return <tr key={index}>
                      <td style={{ verticalAlign: 'middle'}}>
                        <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                          {(this.state.Authority.EditUser === 1) &&
                      <div style={{ marginRight: 10 }} >
                        <Button className='button2' variant="primary" disabled={(this.state.Authority.EditUser === 0) ? true : false} style={{ width: '100%' }} onClick={() => this.toggleEditForm(item, '', 'Down')}>{language[this.props.currentLanguage].Edit}</Button>
                      </div>
                          }
                          <div style={{ marginRight: (item.LoginAttempt >= 3) ? 10 : 0 }}>
                            <Button  className='button2' variant="primary" style={{  width: '100%' }} onClick={() => this.ShowDownline(item.ID)}>{language[this.props.currentLanguage].Downline} {` (${item.totalDownlineCount})`}</Button>
                          </div>
                          {this.state.fastDeleteUsers && 
                            <div>
                              <Form.Check
                                style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                                type="checkbox"
                                onChange={(e) => this.SelectedDeleteUsers(item.ID, e.target.value)}
                              />
                            </div>
                          }
                          {(item.LoginAttempt >= 3) && 
                          <div>
                            <Button  className='button2' style={{  width: '100%', backgroundColor: 'red' }} onClick={() => this.OpenAccount(item.ID)}>{language[this.props.currentLanguage].Open}</Button>
                          </div>}
                        </div>
                      </td>
                      <td style={{ verticalAlign: 'middle', fontWeight: 'bold'}}>{item.Name}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: 'bold'}}>{item.Username}{` (${item.totalDownlineCount})`}</td>
                      <td style={{ verticalAlign: 'middle'}}>{`${item.Password}`}</td>
                      {/* { (( (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? true : ( item.Username === this.props.username ) ? true : false)) ? <td style={{ verticalAlign: 'middle'}}>{`${item.Password}`}</td> : <td/>} */}
                      <td style={{ verticalAlign: 'middle'}}>{item.Role}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: 'bold', color: item.Status === 'Active' ? 'green' : item.Status === 'Inactive' ? 'red' : item.Status === 'Demo' ? 'orange' : ''}}>{item.Status}</td>
                      <td style={{ verticalAlign: 'middle'}}>
                        <div>{item.ActivePackageIDName}</div>
                        <div className="wrap" style={{flexDireaction: 'column',backgroundColor: '#ffff66' }}>
                          <div>{((item.ActivePackageComm_MG_B || '') !== '') ? `MG B: ${item.ActivePackageComm_MG_B}` : ''}</div>
                        </div>
                        <div style={{flexDireaction: 'column',backgroundColor: '#8a4949', color: 'white' }}>
                          <div>{((item.ActivePackageComm_BT_B || '') !== '') ? `MG B: ${item.ActivePackageComm_BT_B}` : ''}</div>
                        </div>
                      </td>
                      <td style={{ verticalAlign: 'middle'}}>{item.DailyLimit}</td>
                      <td style={{ verticalAlign: 'middle'}}>{item.MultiplyDivide}</td>
                      <td style={{ verticalAlign: 'middle'}}>
                        <div>{(item.KeyIn1 === "null" || item.KeyIn1 === null ) ? "-" : JSON.parse(item.KeyIn1).join(', ')}</div>
                        <div>{(item.KeyIn1 === "null" || item.KeyIn1 === null ) ? "-" : JSON.parse(item.KeyIn2).join(', ')}</div>
                      </td>
                      {/* <td style={{ verticalAlign: 'middle'}}>{item.LastOrderID}</td> */}
                      <td style={{ verticalAlign: 'middle'}}>{moment(item.LastUpdateDateTime).format('YYYY-MM-DD')}</td>
                    </tr>
                  })}
                </>
                :
                <><td colSpan="14" style={{ color: 'red', fontWeight: 'bold', padding: 5, textAlign: 'left' }}> * {language[this.props.currentLanguage].CantViewAccount} </td></>
              }
            </tbody>
          </Table>
          {(this.state.visibleForm === true) ? (
            <div style={{ display: 'flex', border: '1px solid #000', borderRadius: 20, visibility: this.state.visibleForm ? 'visible' : 'hidden', overflowX: 'auto'}}>
              <div style={{ borderRight: '1px solid #000'}}>
                <div style={{backgroundColor: '#FCEEA7'}}>
                  <h4>{language[this.props.currentLanguage].AboutAccount}</h4>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>ID</div>
                    <div style={{ margin: '0px 10px'}}>{this.state.formData.ID || 'New Users'}</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Upperline}</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                      <div style={{ margin: '0px 10px'}}>
                        {this.state.formData.UpperlineName || '-'}
                      </div>
                    ) : (
                      <>
                        {(this.state.Mode !== 'Create User') && <div style={{ margin: '0px 10px'}}>
                          {this.state.formData.UpperlineName || '-'}
                        </div>}
                        {(this.state.Mode === 'Create User') && <Form.Control as="select" style={{ width: 200, marginRight: 10}} onChange={(e) => this.EditData(e, 'Upperline')} 
                          disabled={(this.props.Role === 'player') ? true : false} 
                          value={this.state.CreateformData.Upperline}>
                          {(this.state.Mode === 'Create User') ? 
                            (this.state.UpperlineUser).filter((item) => item.Username !== this.state.formData.Username).map((item)=>(
                              <option value={item.ID}>{item.Username}</option>
                            ))
                            : (this.state.PreviousBool === false) ? 
                              (this.state.UpperlineUser).filter((item) => item.Username !== this.state.formData.Username).map((item)=>(
                                <option value={item.ID}>{item.Username}</option>
                              ))
                              :  (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ?
                                (this.state.UpperlineUser).filter((item) => item.Username !== this.state.formData.Username).map((item)=>(
                                  <option value={item.ID}>{item.Username}</option>
                                ))
                                :
                                (this.state.UpperlineUser).filter((item) => item.Username !== this.state.formData.Username).map((item)=>(
                                  <option value={item.ID}>{item.Username}</option>
                                ))
                          }
                        </Form.Control>}
                      </>
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Name}</div>
                    <Form.Control style={{ width: 200, marginRight: 10}} type="text" 
                      onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('Name', e.target.value, undefined ,'CreateformData') : this.dataChange('Name', e.target.value, undefined ,'formData')} 
                      value={(this.state.Mode === 'Create User') ? this.state.CreateformData.Name : this.state.formData.Name} 
                      placeholder={language[this.props.currentLanguage].Name}
                      onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'Name', this.state.formData.Name)}/>
                  </div>
                  {this.state.Mode === 'Create User' && 
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <div style={{ margin: '0px 10px', width: 80}}>{`${language[this.props.currentLanguage].AccountAmount}`}</div>
                        <Form.Control as="select" style={{ width: 200, marginRight: 10}} onChange={(e) => this.setState({ AccountAmount : e.target.value})} 
                          disabled={false} 
                          value={this.state.AccountAmount} >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </Form.Control>
                      </div>
                    </>
                  }
                  {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].USerLoginAcc}</div>
                      <Form.Control readOnly style={{ width: 200, marginRight: 10}} type="text" value={this.state.formData.Username}
                        placeholder="ID / Username" />
                    </div>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].SignIn}</div>
                      {(this.state.AllowSaveUsername === false) ? (
                        <Form.Control  
                          style={{ width: 200, marginRight: 10}} 
                          type="text" 
                          onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('Username', e.target.value, undefined ,'CreateformData') : this.dataChange('Username', e.target.value, undefined ,'formData')} 
                          onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'Username', this.state.formData.Username)} 
                          value={(this.state.Mode === 'Create User') ?  this.state.CreateformData.Username : this.state.formData.Username} 
                          placeholder={`ID/${language[this.props.currentLanguage].usernameLanguage}`} />
                      ) : (
                        <Form.Control  readOnly style={{ width: 200, marginRight: 10}} type="text" value={this.state.formData.Username} placeholder="ID / Username" />
                      )}
                      <Alert className='dcc' style={{  width: 'auto',}}show={this.state.UpdateNameBoolean} variant={'success'}>
                        <Alert.Heading>{language[this.props.currentLanguage].Success}</Alert.Heading>
                      </Alert>
                    </div>
                  )}
                  {/* {this.props.Role === 'superAdmin' &&  */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Password}</div>
                    <Form.Control style={{ width: 200, marginRight: 10}} type="text"
                      onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('Password', e.target.value, undefined ,'CreateformData') : this.dataChange('Password', e.target.value, undefined ,'formData')} 
                      value={(this.state.Mode === 'Create User') ? this.state.CreateformData.Password : this.state.formData.Password} 
                      placeholder={language[this.props.currentLanguage].Password}
                      onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'Password', this.state.formData.Password)}/>
                  </div>
                  {/* } */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Identity}</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                      <Form.Control readOnly style={{ width: 200, marginRight: 10}} type="text"  value={ this.state.formData.Role} placeholder={`ID / ${language[this.props.currentLanguage].usernameLanguage}`} />
                    ) : (
                      <Form.Control as="select" style={{ width: 200, marginRight: 10}} onChange={(e) => this.EditData(e, 'Role')}
                        disabled={(this.props.Role === 'player' ) ? true : false} 
                        value={(this.state.Mode === 'Create User') ? this.state.CreateformData.Role :   this.state.formData.Role} >
                        { (this.state.Mode === 'Create User') ?
                          (this.props.Role === 'admin') ? 
                            <>
                              <option value="admin">admin</option>
                              <option value="agent">agent</option>
                              <option value="player">player</option>
                            </>
                            : this.props.Role === 'agent' ? 
                              <>
                                <option value="agent">agent</option>
                                <option value="player">player</option>
                              </> 
                              : this.props.Role === 'superAdmin'? 
                                <>
                                  <option value="admin">admin</option>
                                  <option value="agent">agent</option>
                                  <option value="player">player</option>
                                </>
                                :
                                <>
                                </>
                          : this.props.Role === 'admin' ? 
                            <>
                              <option value="admin">admin</option>
                              <option value="agent">agent</option>
                              <option value="player">player</option>
                            </>
                            : this.props.Role === 'agent' ? 
                              <>
                                <option value="agent">agent</option>
                                <option value="player">player</option>
                              </> 
                              : this.props.Role === 'superAdmin'? 
                                <>
                                  <option value="superAdmin">superAdmin</option>
                                  <option value="admin">admin</option>
                                  <option value="agent">agent</option>
                                  <option value="player">player</option>
                                </>
                                :
                                <>
                                </>
                        }
                      </Form.Control>
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Personalsaleslimit}</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                      <Form.Control readOnly style={{ width: 200, marginRight: 10}}  value={this.state.formData.DailyLimit} type="text" />
                    ) : ( 
                      <Form.Control style={{ width: 200, marginRight: 10}} 
                        disabled={(this.props.Role === 'player') ? true : false} 
                        onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('DailyLimit', e.target.value, undefined ,'CreateformData') : this.dataChange('DailyLimit', e.target.value, undefined ,'formData')} 
                        onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'DailyLimit', this.state.formData.DailyLimit)} 
                        value={(this.state.Mode === 'Create User') ?  this.state.CreateformData.DailyLimit : this.state.formData.DailyLimit} 
                      />
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].CashWallet}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Form.Check
                        disabled={  ((this.state.UserID === this.state.OwnerDetail[0].ID) ?  this.state.OwnerDetail[0].ID === this.state.formData.Upperline : false) ? false : true} 
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                        inline
                        type="checkbox"
                        // onChange={(e) => this.setState({ BooleanWallet: !this.state.BooleanWallet})}
                        onChange={(e) => this.dataChange('CashWalletActive', !this.state.formData.CashWalletActive, undefined ,'formData')} 
                        checked={(this.state.formData.CashWalletActive=== 1) ? true : false}
                      />
                      {(this.state.formData.CashWalletActive === 1)&&
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Control style={{ width: 200, marginRight: 5}}
                          onChange={(e) =>  this.dataChange('CashWallet', e.target.value, undefined ,'formData')} 
                          onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'CashWallet', this.state.formData.CashWallet)} 
                          value={this.state.formData.CashWallet}
                          disabled={  ((this.state.UserID === this.state.OwnerDetail[0].ID) ?  this.state.OwnerDetail[0].ID === this.state.formData.Upperline : false) ? false : true} 
                        />
                        <Button  className='button2' variant="primary" 
                          disabled={  ((this.state.UserID === this.state.OwnerDetail[0].ID) ?  this.state.OwnerDetail[0].ID === this.state.formData.Upperline : false) ? false : true} 
                          onClick={() => this.setState({ WalletHistoryModal: true})} >
                          {language[this.props.currentLanguage].History}
                        </Button>
                      </div>
                      }
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].FightingFormat}</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                      <Form.Control readOnly style={{ width: 200, marginRight: 10}} type="text"
                        value={(this.state.Mode === 'Create User') ? this.state.CreateformData.EwF : (this.state.formData.EwF === "null") ? "-" : (this.state.formData.EwF || '-') } />
                    ) : (
                      <Form.Control as="select" style={{ width: 200, marginRight: 10}} onChange={(e) => this.EditData(e, 'EwF')} 
                        disabled={(this.props.Role === 'player' ) ? true : false} 
                        value={(this.state.Mode === 'Create User') ? this.state.CreateformData.EwF :   this.state.formData.EwF} >
                        {(this.state.Mode === 'Create User') ? 
                          <>
                            <option value="-">-</option>
                          </> 
                          : ((this.state.Mode === 'Edit') && (this.state.formData.Upperline === 'C')) ? 
                            <>
                              <option value="-">-</option>
                              <option value="LLC">{language[this.props.currentLanguage].LLC}</option>
                              <option value="FDG">{language[this.props.currentLanguage].FDG}</option>
                            </> 
                            : ( (this.state.Mode === 'Edit') && (this.state.formData.UpperlineEwF === '-') ) ? 
                              <>
                                <option value="-">-</option>
                              </> 
                              : ( (this.state.Mode === 'Edit') && (this.state.formData.UpperlineEwF !== '-') ) ? 
                                <>
                                  <option value="-">-</option>
                                  {this.state.formData.UpperlineEwF === 'FDG' && <option value={`${this.state.formData.UpperlineEwF}`}>{language[this.props.currentLanguage].FDG}</option>}
                                  {this.state.formData.UpperlineEwF === 'LLC' && <option value={`${this.state.formData.UpperlineEwF}`}>{language[this.props.currentLanguage].LLC}</option>}
                                </> 
                         
                                : 
                                <>
                                  <option value="-">-</option>
                                </>

                        }
                      </Form.Control>
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: (this.state.Mode !== 'Create User') ? 10 : 0}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Situation}</div>
                    <Form.Control as="select" style={{ width: 200, marginRight: 10}} onChange={(e) => this.EditData(e, 'Status')}
                      disabled={(this.props.Role === 'player' ) ? true : false} 
                      value={(this.state.Mode === 'Create User') ? this.state.CreateformData.Status :  this.state.formData.Status}>
                      <option value="Active">{language[this.props.currentLanguage].Active}</option>
                      <option value="Inactive">{language[this.props.currentLanguage].ShutDownDeactive}</option>
                      <option value="Demo">{language[this.props.currentLanguage].Demo}</option>
                    </Form.Control>
                    {(this.state.Mode !== 'Create User' && this.props.Role !== 'player') && <Button className='button2' variant="primary" 
                      onClick={() => this.setState({ StatusModal: true})} >
                      {language[this.props.currentLanguage].ShowAll}
                    </Button>}
                  </div>
                  {((this.state.Mode !== 'Create User')) && <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].MataLock}</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' )) ? (
                      <Form.Check
                        disabled={ true } 
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                        inline
                        type="checkbox"
                        checked={this.state.formData.MataLock && this.state.formData.MataLock === 1 ? true : false}
                      />
                    ) : (
                      <div style={{  display: 'flex', flexDirection: 'row' }}>
                        <Form.Check
                          disabled={ true } 
                          style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                          inline
                          type="checkbox"
                          checked={this.state.formData.MataLock && this.state.formData.MataLock === 1 ? true : false}
                        />
                        {(this.props.Role !== 'player') && <Button  className='button2' variant="primary" 
                          onClick={() => this.setState({ MataLockModal: true})} >
                          {language[this.props.currentLanguage].MataLock}
                        </Button>}
                      </div>
                    )}
                  </div>}
                </div>
                <div style={{backgroundColor: '#ADD8E6'}}>
                  <h4>{language[this.props.currentLanguage].AboutBetting}</h4>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].LatestOdd}</div>
                    <div style={{ margin: '0px 10px'}}>{this.state.formData.LastOrderID}</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Multiplydivide}</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                      <Form.Control readOnly style={{ width: 200, marginRight: 10}} value={this.state.formData.MultiplyDivide} type="text" />
                    ) : (
                      <Form.Control as="select" style={{ width: 200, marginRight: 10}} onChange={(e) => this.EditData(e, 'MultiplyDivide')}
                        disabled={(this.props.Role === 'player' ) ? true : this.props.Role !== 'admin' && this.props.Role !== 'superAdmin' && this.state.AbleChange === 0 ? true : false} 
                        value={(this.state.Mode === 'Create User') ? this.state.CreateformData.MultiplyDivide :   this.state.formData.MultiplyDivide} >
                        <option value="">-</option>
                        <option value="M">{language[this.props.currentLanguage].Multiply}</option>
                        <option value="D">{language[this.props.currentLanguage].Divide}</option>
                      </Form.Control>
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{`${language[this.props.currentLanguage].GameDisplay}`}</div>
                    <Form.Control as="select" style={{ width: 200, marginRight: 10}} onChange={(e) => {this.EditData(e, 'GameDisplay')}} 
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' ) || this.state.AbleChange === 0) ? true : false} 
                      value={(this.state.Mode === 'Create User') ? this.state.CreateformData.GameDisplay :   this.state.formData.GameDisplay} >
                      <option value="MKTSWBD">M(K)TSWB(D)</option>
                      <option value="MPTSWBK">M(P)TSWB(K)</option>
                    </Form.Control>
                  </div>
                  {this.state.Mode === 'Create User' && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].GameBetting}</div>
                    <Form.Control 
                      as="select"
                      style={{ width: 200, marginRight: 10}} 
                      disabled={this.props.Role !== 'admin' && this.props.Role !== 'superAdmin' && this.state.AbleChange === 0 ? true : false} 
                      onChange={(e) => this.updateGameBetting('GameBetting', e.target.value)}
                      // value={this.state.formData.GameBetting === '12345678' ? '12345678' : 'Custom'} >
                      value={this.state.CreateformData.GameBetting === 'Custom' ? 'Custom' : this.state.CreateformData.GameBetting} >
                      {/* <option value="12345678">{this.state.formData.GameDisplay === 'MKTSWBD'? 'MKTSWBD8' : 'MPTSWBK8' }</option> */}
                      <option value={this.state.UplineGame}>{this.PlatformChange(this.state.UplineGame)}</option>
                      <option value="Custom">{language[this.props.currentLanguage].Edit}</option>
                    </Form.Control>
                  </div>}
                  {this.state.Mode !== 'Create User' && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].GameBetting}</div>
                    <Form.Control 
                      as="select" 
                      disabled={this.props.Role !== 'admin' && this.props.Role !== 'superAdmin' && this.state.AbleChange === 0}
                      style={{ width: 200, marginRight: 10}} 
                      onChange={(e) => this.updateGameBetting('GameBetting', e.target.value)} 
                      value={this.state.formData.GameBetting === 'Custom' ? 'Custom' : this.state.formData.GameBetting}>
                      <option value={this.state.UplineGame}>{this.PlatformChange(this.state.UplineGame)}</option>
                      <option value="Custom">{language[this.props.currentLanguage].Custom}</option>
                    </Form.Control>
                  </div>}
                  {((this.state.CreateformData.GameBetting === 'Custom') && (this.state.Mode === 'Create User')) && 
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingLeft: 10}}>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((inx) => <Form.Control 
                        as="select"
                        // disabled={(((inx === 8 && !this.state.Authority.GameBetting.includes('9'))) ? true : false)}
                        style={{ width: 45, marginRight: 2, padding: 0}} 
                        value={ this.state.CreateformData.GameBettingCustomArray[inx] }
                        onChange={(e) => this.updateGameBettingCustom(inx, e.target.value)} 
                      >
                        {this.state.CreateformData.GameDisplay === 'MKTSWBD'  ? this.state.allGameSelect.map((item) => 
                          <option value={item}>{item}</option>
                        ) : this.state.allGameSelect2.map((item) => 
                          <option value={item}>{item}</option>
                        )}
                      </Form.Control>)}
                    </div>
                  }
                  {(this.state.formData.GameBetting === 'Custom' && this.state.Mode !== 'Create User') && 
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingLeft: 10}}>
                      {/* asdasdasdsad */}
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((inx) => <Form.Control 
                        as="select" 
                        // disabled={(this.state.GameBettingCustom[inx] === '9' && !this.state.Authority.GameBetting.includes('9') ? true : false)}
                        style={{ width: 45, marginRight: 2, padding: 0}} 
                        value={ this.state.GameBettingCustom[inx] || '-'}
                        onChange={(e) => this.updateGameBettingCustom(inx, e.target.value)} 
                        disabled={this.props.Role !== 'admin' && this.props.Role !== 'superAdmin' && this.state.AbleChange === 0}
                      >
                        {this.state.formData.GameDisplay === 'MKTSWBD' ? ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9','-'].map((item) => 
                          <option value={item}>{item}</option>
                        ) :  ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8',  '9','-'].map((item) => 
                          <option value={item}>{item}</option>
                        )}
                      </Form.Control>)}
                    </div>
                  }
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].InputMethod}</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                      <Form.Control readOnly style={{ width: 200, marginRight: 10}} type="text"
                        value={(this.state.Mode === 'Create User') ? this.state.NewSetPackage :  (this.state.SetPackage === 'SetA') ? 'B, S, C, A, SA, SF' : (this.state.SetPackage === 'SetB') ? 'SA, SB, SC, SD, SE, SF' : this.state.SetPackage} />
                    ) : (
                      <>
                        {(this.props.Role !== 'player') ? <Form.Control disabled={(this.state.Authority.PackageInput === 0) ? true : false} as="select" style={{ width: 200, marginRight: 10}} 
                          onChange={(e) => (this.state.Mode === 'Create User') ? this.SetNewPackageGame(e.target.value, 'KeyIn1') :  this.SetPackageGame(e.target.value, 'KeyIn1')} 
                          value={(this.state.Mode === 'Create User') ? this.state.NewSetPackage : this.state.SetPackage} >
                          <option value="">-</option>
                          <option value="SetA">B, S, C, A, SA, SF</option>
                          <option value="SetB">SA, SB, SC, SD, SE, SF</option>
                          <option value="Custom">{language[this.props.currentLanguage].Custom}</option>
                        </Form.Control> : <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[0]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[1]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[2]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[3]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[4]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[5]}/>
                        </div>}
                      </>
                    )}
                  </div>
                  {(this.state.NewSetPackage === "Custom") ? (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingLeft: 10}}>
                      {[0, 1, 2, 3, 4, 5].map((inx) => <Form.Control 
                        disabled={(this.state.Authority.PackageInput === 0) ? true : false} 
                        as="select" 
                        style={{ width: 55, marginRight: 10, padding: 0}} 
                        value={this.state.FormKey1 && this.state.FormKey1[inx]}
                        onChange={(e) => {
                          this.dataCreate('KeyIn1', e.target.value, inx)
                        }} 
                      >
                        {this.state.allBetModeSelect.map((item) => {
                          if (item === 'CA') {
                            return <option value={item}>{'A'}</option>
                          } else if (item === 'CF') {
                            return <option value={item}>{'C'}</option>
                          } else {
                            return <option value={item}>{item}</option>
                          }
                        })}
                        <option value="-">-</option>
                      </Form.Control>)}
                    </div>
                  ):(
                    <></>
                  )}
                  {(this.state.SetPackage === "Custom" && ((this.props.Role !== 'player' ))) ? (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                          <Form.Control disabled style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[0]}/>
                          <Form.Control disabled style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[1]}/>
                          <Form.Control disabled style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[2]}/>
                          <Form.Control disabled style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[3]}/>
                          <Form.Control disabled style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[4]}/>
                          <Form.Control disabled style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey1 && this.state.FormKey1[5]}/>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingLeft: 10}}>
                          {[0, 1, 2, 3, 4, 5].map((inx) => <Form.Control 
                            disabled={(this.state.Authority.PackageInput === 0) ? true : false} 
                            as="select" 
                            style={{ width: 55, marginRight: 10, padding: 0}} 
                            value={this.state.FormKey1 && this.state.FormKey1[inx]}
                            onChange={(e) => this.dataChange('KeyIn1', e.target.value, inx,'formData')} 
                          >
                            {this.state.allBetModeSelect.map((item) => {
                              if (item === 'CA') {
                                return <option value={item}>{'A'}</option>
                              } else if (item === 'CF') {
                                return <option value={item}>{'C'}</option>
                              } else {
                                return <option value={item}>{item}</option>
                              }
                            })}
                            <option value="-">-</option>
                          </Form.Control>)}
                        </div>
                      )}
                    </div>
                  ):(
                    <></>
                  )}
                  <div className='dcc' style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: 10, fontWeight: 'bold', fontSize: 21, color: 'red'}}>
                    <div>CA=A</div>
                    <div>CF=ABC</div>
                    <div>SA=A1</div>
                    <div>SF=A1C</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].InputMethod}**</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                      <Form.Control readOnly style={{ width: 200, marginRight: 10}} type="text"
                        value={(this.state.Mode === 'Create User') ? this.state.NewSetPackage2 :  (this.state.SetPackage2 === 'SetA') ? 'B, S, C, A, SA, SF' : (this.state.SetPackage2 === 'SetB') ? 'SA, SB, SC, SD, SE, SF' : this.state.SetPackage2} />
                    ) : (
                      <>
                        {(this.props.Role !== 'player') ?  <Form.Control as="select" style={{ width: 200, marginRight: 10}} disabled={(this.state.Authority.PackageInput === 0 || (this.props.Role !== 'admin' && this.props.Role !== 'superAdmin' && this.state.AbleChange === 0)) ? true : false}
                          onChange={(e) => (this.state.Mode === 'Create User') ? this.SetNewPackageGame(e.target.value, 'KeyIn2') :  this.SetPackageGame(e.target.value, 'KeyIn2')} 
                          value={(this.state.Mode === 'Create User') ? this.state.NewSetPackage2 : this.state.SetPackage2} >
                          <option value="">-</option>
                          <option value="SetA">B, S, C, A, SA, SF</option>
                          <option value="SetB">SA, SB, SC, SD, SE, SF</option>
                          <option value="Custom">{language[this.props.currentLanguage].Custom}</option>
                        </Form.Control> : <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[0]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[1]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[2]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[3]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[4]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[5]}/>
                        </div>}
                      </>
                    )}
                  </div>
                  {(this.state.NewSetPackage2 === "Custom") ? (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingLeft: 10}}>
                      {[0, 1, 2, 3, 4, 5].map((inx) => <Form.Control 
                        disabled={(this.state.Authority.PackageInput === 0 || (this.props.Role !== 'admin' && this.props.Role !== 'superAdmin' && this.state.AbleChange === 0)) ? true : false}
                        as="select" 
                        style={{ width: 55, marginRight: 10, padding: 0}} 
                        value={this.state.FormKey2 && this.state.FormKey2[inx]}
                        onChange={(e) => this.dataCreate('KeyIn2', e.target.value, inx)}
                      >
                        {this.state.allBetModeSelect.map((item) => {
                          if (item === 'CA') {
                            return <option value={item}>{'A'}</option>
                          } else if (item === 'CF') {
                            return <option value={item}>{'C'}</option>
                          } else {
                            return <option value={item}>{item}</option>
                          }
                        })}
                        <option value="-">-</option>
                      </Form.Control>)}
                    </div>
                  ):(
                    <></>
                  )}
                  {(this.state.SetPackage2 === "Custom" && (this.props.Role !== 'player')) ? (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[0]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[1]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[2]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[3]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[4]}/>
                          <Form.Control readOnly style={{ width: 55, marginRight: 10, textAlign: 'center'}} maxLength="3" type="text" value={this.state.FormKey2 && this.state.FormKey2[5]}/>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingLeft: 10}}>
                          {[0, 1, 2, 3, 4, 5].map((inx) => <Form.Control 
                            disabled={(this.state.Authority.PackageInput === 0 || (this.props.Role !== 'admin' && this.props.Role !== 'superAdmin' && this.state.AbleChange === 0)) ? true : false}
                            as="select" 
                            style={{ width: 55, marginRight: 10, padding: 0}} 
                            value={this.state.FormKey2 && this.state.FormKey2[inx]}
                            onChange={(e) => this.dataChange('KeyIn2', e.target.value, inx,'formData')}
                          >
                            {this.state.allBetModeSelect.map((item) => {
                              if (item === 'CA') {
                                return <option value={item}>{'A'}</option>
                              } else if (item === 'CF') {
                                return <option value={item}>{'C'}</option>
                              } else {
                                return <option value={item}>{item}</option>
                              }
                            })}
                            <option value="-">-</option>
                          </Form.Control>)}
                        </div>
                      )}
                    </div>
                  ):(
                    <></>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{'#MKT'}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={(this.state.Mode === 'Create User') ?  (this.state.CreateformData.JinNan === 1) : (this.state.formData.JinNan === 1)}
                      onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('JinNan', !this.state.CreateformData.JinNan, undefined ,'CreateformData') : this.dataChange('JinNan', !this.state.formData.JinNan, undefined ,'formData')} 
                    />
                  </div>
                  <div ref={this.FocusHere.elRef} style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].StarAndAll}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' ) || (this.props.Role !== 'admin' && this.props.Role !== 'superAdmin' && this.state.AbleChange === 0)) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={(this.state.Mode === 'Create User') ?  (this.state.CreateformData.OneStarBao === 1) : (this.state.formData.OneStarBao === 1)}
                      onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('OneStarBao', !this.state.CreateformData.OneStarBao, undefined ,'CreateformData') : this.dataChange('OneStarBao', !this.state.formData.OneStarBao, undefined ,'formData')} 
                    />
                  </div>
                  <hr/>
                  <div>
                    {(this.state.Mode !== 'Create User') && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].AddCommissionOneSide}</div>
                      <div style={{  display: 'flex', flexDirection: 'row' }}>
                        {<Button  className='button2' variant="primary" 
                          disabled={(this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) ? true : false} 
                          onClick={() => this.setState({ AutoAddWaterModal: true})} >
                          {'Setups'}
                        </Button>}
                      </div>
                    </div>}
                    {this.state.formData.AutoAddWaterJSON && <div style={{display: 'flex', flexDirection: 'row', paddingBottom: 10}}>
                      {this.state.allBetMode.map((item) => 
                        <div style={{ display: 'flex', flexDirection: 'column', width: 35}}>
                          <div style={{border: '0.5px solid black', backgroundColor: 'white'}}>{item}</div>
                          <div style={{border: '0.5px solid black', backgroundColor: 'white'}}>{this.state.formData.AutoAddWaterJSON[item]}</div>
                        </div>
                      )}
                    </div>}
                    {this.state.formData.AutoAddWaterJSON && <div style={{display: 'flex', flexDirection: 'row', paddingBottom: 10}}>
                      {this.state.allBetMode2.map((item) => 
                        <div style={{ display: 'flex', flexDirection: 'column', width: 35}}>
                          <div style={{border: '0.5px solid black', backgroundColor: 'white'}}>{item}</div>
                          <div style={{border: '0.5px solid black', backgroundColor: 'white'}}>{this.state.formData.AutoAddWaterJSON[item]}</div>
                        </div>
                      )}
                    </div>}
                    {(this.state.Mode !== 'Create User') && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', width: 100}}>{`${language[this.props.currentLanguage].AddCommissionOneSide}${language[this.props.currentLanguage].ShowReal}`}</div>
                      <Form.Check
                        disabled={true} 
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                        inline
                        type="checkbox"
                      />
                    </div>}
                  </div>
                  <div  style={{ display: 'flex', alignItems: 'center'}}>
                    {(this.state.Mode !== 'Create User') && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingBottom: this.state.formData.VirtualJSON ? 0 : 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].AddCommissionSingle}</div>
                      <div style={{  display: 'flex', flexDirection: 'row' }}>
                        <Button variant="primary"  className='button2'
                          onClick={() => this.setState({ VirtualModal: true})} >
                          {'Setup'}
                        </Button>
                      </div>
                    </div>}
                  </div>
                  {this.state.formData.VirtualJSON && <div style={{display: 'flex', flexDirection: 'row', paddingBottom: 10}}>
                    {this.state.allBetMode.map((item) => 
                      <div style={{ display: 'flex', flexDirection: 'column', width: 35}}>
                        <div style={{border: '0.5px solid black', backgroundColor: 'white'}}>{item}</div>
                        <div style={{border: '0.5px solid black', backgroundColor: 'white'}}>{this.state.formData.VirtualJSON[item]}</div>
                      </div>
                    )}
                  </div>}
                  {this.state.formData.VirtualJSON && <div style={{display: 'flex', flexDirection: 'row', paddingBottom: 10}}>
                    {this.state.allBetMode2.map((item) => 
                      <div style={{ display: 'flex', flexDirection: 'column', width: 35}}>
                        <div style={{border: '0.5px solid black', backgroundColor: 'white'}}>{item}</div>
                        <div style={{border: '0.5px solid black', backgroundColor: 'white'}}>{this.state.formData.VirtualJSON[item]}</div>
                      </div>
                    )}
                  </div>}
                  {(this.state.Mode !== 'Create User') && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div   style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].DisplayError}</div>
                    <Form.Check
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={ (this.state.formData.ShowError || 0 === 1)}
                      onChange={(e) => this.dataChange('ShowError', !this.state.formData.ShowError, undefined ,'formData')} 
                    />
                  </div>
                  }
                  <div  style={{ display: 'flex', alignItems: 'center'}}>
                    {(this.state.Mode !== 'Create User') && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingBottom: this.state.formData.VirtualJSON ? 0 : 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{'米#'}</div>
                      <div style={{  display: 'flex', flexDirection: 'row' }}>
                        <Button variant="primary"  className='button2'
                          onClick={() => this.setState({ MiModal: true})} >
                          {'Setup'}
                        </Button>
                      </div>
                    </div>}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Tong}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={(this.state.Mode === 'Create User') ?  (this.state.CreateformData.Tong === 1) : (this.state.formData.Tong === 1)}
                      onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('Tong', !this.state.CreateformData.Tong, undefined ,'CreateformData') : this.dataChange('Tong', !this.state.formData.Tong, undefined ,'formData')} 
                    />
                  </div>
                  <div  style={{ display: 'flex', alignItems: 'center', paddingBottom: 10}}>
                    {(this.state.Mode !== 'Create User') && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, paddingBottom: this.state.formData.VirtualJSON ? 0 : 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].BypassWord}</div>
                      <div style={{  display: 'flex', flexDirection: 'row' }}>
                        <Button variant="primary"  className='button2'
                          onClick={() => this.selfFightCallAPI()} >
                          {'Setup'}
                        </Button>
                      </div>
                    </div>}
                  </div>
                  {(this.state.Mode !== 'Create User')&&<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div   style={{ margin: '0px 10px', width: 80}}>{'SGD新币汇率计算'}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && (this.props.Role !== 'admin' && this.props.Role !== 'superAdmin') )) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={(this.state.formData.AbleSGD === 1)}
                      onChange={(e) => this.dataChange('AbleSGD', !this.state.formData.AbleSGD, undefined ,'formData')} 
                    />
                  </div>}
                  {(this.state.Mode !== 'Create User')&&<div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10}}>
                    <div   style={{ margin: '0px 10px', width: 80}}>{'THB泰币汇率计算'}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && (this.props.Role !== 'admin'  && this.props.Role !== 'superAdmin' ))) ? true : false} 
                      style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={(this.state.formData.AbleTHAI === 1)}
                      onChange={(e) => this.dataChange('AbleTHAI', !this.state.formData.AbleTHAI, undefined ,'formData')} 
                    />
                  </div>}
                </div>
                {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') && <div style={{backgroundColor: '#CBC3E3'}}>
                  <h4>{language[this.props.currentLanguage].AdminOnly}</h4>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div   style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].RedReceipt}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={(this.state.Mode === 'Create User') ?  (this.state.CreateformData.AbleRed === 1) : (this.state.formData.AbleRed === 1)}
                      onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('AbleRed', !this.state.CreateformData.AbleRed, undefined ,'CreateformData') : this.dataChange('AbleRed', !this.state.formData.AbleRed, undefined ,'formData')} 
                    />
                  </div>
                  {/* {(this.state.Mode !== 'Create User')&&<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div   style={{ margin: '0px 10px', width: 80}}>{'SGD新币汇率计算'}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={(this.state.formData.AbleSGD === 1)}
                      onChange={(e) => this.dataChange('AbleSGD', !this.state.formData.AbleSGD, undefined ,'formData')} 
                    />
                  </div>} */}
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].RedMoney}</div>
                    {( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? (
                      <Form.Control readOnly style={{ width: 200, marginRight: 10}}  value={this.state.formData.RedPercent} type="text" />
                    ) : (
                      <Form.Control style={{ width: 200, marginRight: 10}} 
                        disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? true : false}
                        maxLength={4} 
                        onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('RedPercent', e.target.value, undefined ,'CreateformData') : this.dataChange('RedPercent', e.target.value, undefined ,'formData')} 
                        onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'RedPercent', this.state.formData.RedPercent)}
                        value={(this.state.Mode === 'Create User') ?  this.state.CreateformData.RedPercent : this.state.formData.RedPercent}
                      />
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Chai}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={(this.state.Mode === 'Create User') ?  (this.state.CreateformData.Chai === 1) : (this.state.formData.Chai === 1)}
                      onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('Chai', !this.state.CreateformData.Chai, undefined ,'CreateformData') : this.dataChange('Chai', !this.state.formData.Chai, undefined ,'formData')} 
                    />
                  </div>
                  {(this.state.Mode !== 'Create User') &&<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Allow101orders}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={ this.state.formData.Able101Import === 1 }
                      onChange={(e) =>  this.dataChange('Able101Import', !this.state.formData.Able101Import, undefined ,'formData')} 
                    />
                  </div>}
                  {(this.state.Mode !== 'Create User') &&<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                    <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Dading}</div>
                    <Form.Check
                      disabled={( (this.state.formData.ID === this.state.UserID  && this.props.Role === 'player' ) || (this.state.formData.ID === this.state.UserID  && this.props.Role === 'agent' )) ? true : false} 
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                      inline
                      type="checkbox"
                      checked={ this.state.formData.AbleDading === 1 }
                      onChange={(e) =>  this.dataChange('AbleDading', !this.state.formData.AbleDading, undefined ,'formData')} 
                    />
                  </div>}
                  <hr/>
                  {(this.state.Mode !== 'Create User') && <div> 
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 0}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{`FreeJP${language[this.props.currentLanguage].Active}`}</div>
                      <Form.Check
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                        inline
                        disabled={true} 
                        type="checkbox"
                        checked={((this.state.formData.FreeJPActive || 0)=== 1)}
                      />
                      <div style={{  display: 'flex', flexDirection: 'row' }}>
                        <Button variant="primary" 
                          className='button2'
                          onClick={() => this.setState({ FreeJPModal: true})} >
                          {'Setup'}
                        </Button>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{'BTJP'}{language[this.props.currentLanguage].Amount}:</div>
                      <Form.Control style={{ width: 120, marginRight: 10, fontWeight: 'bold'}} type="number" 
                        disabled={true}
                        value={ this.state.formData.FreeBTJPAmount || ''} 
                        placeholder={`BTJP${language[this.props.currentLanguage].FreeJPAmount}`}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginTop: 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{language[this.props.currentLanguage].Amount}:</div>
                      <Form.Control style={{ width: 120, marginRight: 10, fontWeight: 'bold'}} type="number" 
                        disabled={true}
                        value={ this.state.formData.FreeJPAmount || ''} 
                        placeholder={language[this.props.currentLanguage].FreeJPAmount}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{`JPX10:`}</div>
                      <Form.Control style={{ width: 120, marginRight: 10, fontWeight: 'bold'}} type="number" 
                        disabled={true} 
                        value={ this.state.formData.FreeJPX10 || ''} 
                        placeholder={'JP X10'}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', width: 80}}>{`${language[this.props.currentLanguage].Multiply}`}</div>
                      <Form.Control style={{ width: 120, marginRight: 10,fontWeight: 'bold'}} type="number" 
                        disabled={true} 
                        value={ this.state.formData.FreeJPMultiply || ''} 
                        placeholder={language[this.props.currentLanguage].FreeJPMultiply}
                      />
                    </div>
                    <hr/>
                  </div>}
                </div>}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  {(this.state.Mode !== 'Create User') && <Button    
                    className='button2' style={{ marginLeft: '70%'}} variant="primary" onClick={() => this.updateAllUserInformation()} >
                    {language[this.props.currentLanguage].Update}
                  </Button>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  {(this.state.Mode === 'Create User') 
                    ? <Button  className='button2' style={{ marginLeft: '70%'}} variant="primary" onClick={() => this.ClickCreateNewUser()} >{language[this.props.currentLanguage].Create}</Button> 
                    : <>{this.state.formData.ID !== ((this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? 'C' : this.state.UserID) && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')  && 
                    <Button  className='button2' style={{ marginLeft: '70%'}} variant="danger"
                      onClick={() => this.DeleteAccount(this.state.formData.ID)} >{language[this.props.currentLanguage].Delete}</Button> }</>
                  }
                </div>
              </div>
              <HistoryWalletCashModal UserID={this.state.formData.ID} WalletHistoryModal={this.state.WalletHistoryModal} propsSetState={this.propsSetState}/>

              <ActiveMultiplePackage AlertModal={this.state.AlertModal} propsSetState={this.propsSetState} SubmitMultiplePackage={this.SubmitMultiplePackage}/>

              <MataLock MataLockModal={this.state.MataLockModal} StatusUser={this.state.StatusUser} StatusUserLock={this.state.StatusUserLock} formData={this.state.formData} updateUserF={this.updateUserF} propsSetState={this.propsSetState}/>
              <StatusModal StatusModal={this.state.StatusModal} StatusUser={this.state.StatusUser} StatusUserStatus={this.state.StatusUserStatus} formData={this.state.formData} updateUserF={this.updateUserF} propsSetState={this.propsSetState}/>
              {this.state.AutoAddWaterModal === true && 
                <AutoAddWater AutoAddWaterModal={this.state.AutoAddWaterModal} propsSetState={this.propsSetState} AddWaterStatusUser={this.state.AddWaterStatusUser} AutoAddWaterActive={this.state.AutoAddWaterActive} AutoAddWaterPercent={this.state.AutoAddWaterPercent} ModalDataChange={this.ModalDataChange} AutoAddWaterShowReal={this.state.AutoAddWaterShowReal} oriFormData={this.state.oriFormData} updateUserAddWater={this.updateUserAddWater} formData={this.state.formData} AX3={this.state.AX3} Sennotadd={this.state.Sennotadd}/>
              }
              <FreeJP FreeJPModal={this.state.FreeJPModal} propsSetState={this.propsSetState} oriFormData={this.state.oriFormData} AddWaterStatusUser={this.state.AddWaterStatusUser} JPFreeJPAmount={this.state.JPFreeJPAmount} JPFreeJPActive={this.state.JPFreeJPActive} FreeBTJPAmount={this.state.FreeBTJPAmount} JPFreeJPX10={this.state.JPFreeJPX10} ModalDataChange={this.ModalDataChange} JPFreeJPMultiply={this.state.JPFreeJPMultiply} formData={this.state.formData} FreeJackPortFunction={this.FreeJackPortFunction}/>
              {this.state.VirtualModal === true && 
                <VirtualModal VirtualModal={this.state.VirtualModal} oriFormData={this.state.oriFormData} formData={this.state.formData} propsSetState={this.propsSetState} ModalDataChange={this.ModalDataChange} VirtualPercentState={this.state.VirtualPercentState} VirtualActiveState={this.state.VirtualActiveState} VirtualFunction={this.VirtualFunction}/>
              }
              {this.state.MiModal === true &&
                <MiModal MiModal={this.state.MiModal} oriFormData={this.state.oriFormData} formData={this.state.formData} propsSetState={this.propsSetState}   MiFunction={this.MiFunction}/>
              }
              {this.state.SelfFightModal === true &&
                <SelfFightModal SelfFightModal={this.state.SelfFightModal} BanresultArray={this.state.BanresultArray} FightArrayNumb={this.state.FightArrayNumb}  formData={this.state.formData} propsSetState={this.propsSetState}   SelfFightFunction={this.SelfFightFunction}/>
              }
              <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered={false}
                show={this.state.modalShow}
                contentClassName={ (this.state.BooleanHideWater) ? "setBoostrap" : ''}
              >
                <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
                  <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
                    <GrFormClose
                      style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ modalShow: false, packageNames: this.state.OriginalpackageNames, BackPackageNames: false, })
                      }}
                      size="35px"
                      color="#000"
                    />
                    <div style={{flexDirection: 'row', width: 'auto', }}>
                      <Tab.Container id="left-tabs-example" activeKey={this.state.activeKeys} mountOnEnter unmountOnExit>
                        <Button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', marginLeft: (this.state.BooleanHideWater) ? '23%' : '5%' , marginTop: 1}} 
                          disabled={this.state.disabled}
                          onClick={() => this.setState({ BooleanHideWater: !this.state.BooleanHideWater})} 
                          className="btn my-cusomized-button button2" >
                          {this.state.BooleanHideWater ? 'Hide' : 'UnHide'}
                        </Button>
                        <div className="dcc" style={{ width: '100%', marginBottom: 0, padding: '1%' }}>
                          <div style={{fontSize: "21px", width: 200,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].SearchPackageName}</div>
                        </div>
                        <Alert className="dcc" style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width:  '70%', left: 250}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                        </Alert>
                        <div style={{flexDireaction: 'row', display: 'flex' }}>
                          {(this.state.BooleanHideWater)&&<div>
                            <div style={{flexDirection: 'row', width: 'auto', }}>
                              <div className="dcc" style={{ width: '100%', marginBottom: 0 }}>
                                <div style={{fontSize: "21px", width: 200,  textAlign: 'center', fontWeight: 'bold'}}>{`${language[this.props.currentLanguage].Details}/${language[this.props.currentLanguage].Find}`}</div>
                              </div>
                              <div className="dcc" style={{ marginBottom: 10, marginTop: 10}}>
                                <div style={{ margin: '0px 10px', fontWeight: 'bold'}}>水钱</div>
                              </div>
                              <div className="dcc" style={{ display: 'flex', flexDirection: 'column',}}>
                                <div style={{ display: 'flex', flexDirection: 'row',}}>
                                  {['B', 'S', 'SA', 'CA', ].map((item, index) => {
                                    return(
                                      <div index={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                                        <div className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                        <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, 'Commission', 'Commission')}
                                          value={(this.state.SetDetailValue.Commission && this.state.SetDetailValue.Commission.Commission) === undefined ? '' : this.state.SetDetailValue.Commission.Commission[item]}
                                          onKeyPress={(e) => this.DetailFucntion(e, (item === 'B') ? 'InputS' : (item === 'S') ? 'InputSA' : (item === 'SA') ? 'InputCA' : 'InputCF')}  
                                          ref={(input) => { this[`Input${item}`] = input;}} />
                                        {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') &&<Form.Control style={{ width: 80, textAlign: 'center', marginBottom: '10%',  backgroundColor: '#C3f3fb'}} type="text" onChange={(e) => this.onChangeDetail(e, item, 'HolyWater', 'Commission')}
                                          value={(this.state.SetDetailValue.Commission && this.state.SetDetailValue.Commission.HolyWater) === undefined ? '' : this.state.SetDetailValue.Commission.HolyWater[item]}
                                          onKeyPress={(e) => this.DetailFucntion(e, (item === 'B') ? 'HolyInputS' : (item === 'S') ? 'HolyInputSA' : (item === 'SA') ? 'HolyInputCA' : 'HolyInputCF')}
                                          ref={(input) => { this[`HolyInput${item}`] = input;}} />}
                                      </div>
                                    );
                                  })}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row',}}>
                                  {['CF', 'PA', '5D', '6D', ].map((item, index) => {
                                    return(
                                      <div index={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                                        <div className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                        <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, 'Commission', 'Commission')}
                                          value={(this.state.SetDetailValue.Commission && this.state.SetDetailValue.Commission.Commission) === undefined ? '' : this.state.SetDetailValue.Commission.Commission[item]}
                                          onKeyPress={(e) => this.DetailFucntion(e, (item === 'CF') ? 'InputPA' : (item === 'PA') ? 'Input5D' : (item === '5D') ? 'Input6D' : 'HolyInputB')}  
                                          ref={(input) => { this[`Input${item}`] = input;}} />
                                        {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') && (item !== '5D' && item !== '6D')&&<Form.Control style={{ width: 80, textAlign: 'center', marginBottom: '10%',  backgroundColor: '#C3f3fb'}} type="text" onChange={(e) => this.onChangeDetail(e, item, 'HolyWater', 'Commission')}
                                          value={(this.state.SetDetailValue.Commission && this.state.SetDetailValue.Commission.HolyWater) === undefined ? '' : this.state.SetDetailValue.Commission.HolyWater[item]}
                                          onKeyPress={(e) => this.DetailFucntion(e, (item === 'CF') ? 'HolyInputPA' : 'Odd1STB')}
                                          ref={(input) => { this[`HolyInput${item}`] = input;}} />}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <hr />
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, overflowX: 'auto', marginTop: 5}}>
                                {/* <div style={{ margin: '0px 10px', width: 50, fontWeight: 'bold'}}>赔率</div> */}
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 10}}>
                                  <div className="dcc" style={{ marginBottom: 10}}>
                                    <div style={{ margin: '0px 10px', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Odds}</div>
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <div style={{ margin: '0px 10px', fontSize: 30}}>1</div>
                                    <div style={{ display: 'flex', flexDirection: 'column',}}>
                                      <div style={{ display: 'flex', flexDirection: 'row',}}>
                                        {['B', 'S', 'SA',].map((item) => {
                                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                            <div className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '1ST', 'Odd')}
                                              value={(this.state.SetDetailValue.Odd && this.state.SetDetailValue.Odd['1ST']) === undefined ? '' : this.state.SetDetailValue.Odd['1ST'][item]}
                                              onKeyPress={(e) => this.DetailFucntion(e,(item === 'B') ? 'Odd1STS' :(item === 'S') ? 'Odd1STSA' : 'Odd1STCA')} 
                                              ref={(input) => { this[`Odd1ST${item}`] = input;}}
                                            />
                                          </div>
                                        })}
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'row',}}>
                                        {['CA', 'PA', '5D', ].map((item) => {
                                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                            <div className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '1ST', 'Odd')}
                                              value={(this.state.SetDetailValue.Odd && this.state.SetDetailValue.Odd[item]) === undefined ? '' : (item === '5D') ? this.state.SetDetailValue.Odd[item]['5D1']: this.state.SetDetailValue.Odd[item]}
                                              onKeyPress={(e) => this.DetailFucntion(e,(item === 'CA') ? 'Odd1STPA' :(item === 'PA') ? 'Odd1ST5D' : 'Odd1ST6D')}
                                              ref={(input) => { this[`Odd1ST${item}`] = input;}}
                                            />
                                          </div>
                                        })}
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'row',}}>
                                        {['6D'].map((item) => {
                                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                            <div className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '1ST', 'Odd')}
                                              value={(this.state.SetDetailValue.Odd && this.state.SetDetailValue.Odd[item]) === undefined ? '' : this.state.SetDetailValue.Odd[item][item]}
                                              onKeyPress={(e) => this.DetailFucntion(e,  'Odd2NDB')}
                                              ref={(input) => { this[`Odd1ST${item}`] = input;}}
                                            />
                                          </div>
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <div style={{ margin: '0px 10px', fontSize: 30}}>2</div>
                                    {['B', 'S',].map((item) => {
                                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                        <div  className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                        <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '2ND', 'Odd')}
                                          value={(this.state.SetDetailValue.Odd && this.state.SetDetailValue.Odd['2ND']) === undefined ? '' : this.state.SetDetailValue.Odd['2ND'][item]}
                                          onKeyPress={(e) => this.DetailFucntion(e, (item === 'B') ? 'Odd2NDS' : 'Odd3RDB')}
                                          ref={(input) => { this[`Odd2ND${item}`] = input;}}
                                        />
                                      </div>
                                    })}
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <div style={{ margin: '0px 10px', fontSize: 30}}>3</div>
                                    {['B', 'S', ].map((item) => {
                                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                        <div  className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                        <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '3RD', 'Odd')}
                                          value={(this.state.SetDetailValue.Odd && this.state.SetDetailValue.Odd['3RD']) === undefined ? '' : this.state.SetDetailValue.Odd['3RD'][item]}
                                          onKeyPress={(e) => this.DetailFucntion(e, (item === 'B') ? 'Odd3RDS' : 'Odd4THB')}
                                          ref={(input) => { this[`Odd3RD${item}`] = input;}}
                                        />
                                      </div>
                                    })}
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <div style={{ margin: '0px 10px', fontSize: 30}}>4</div>
                                    {['B',].map((item) => {
                                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                        <div   className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                        <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '4TH', 'Odd')}
                                          value={(this.state.SetDetailValue.Odd && this.state.SetDetailValue.Odd['4TH']) === undefined ? '' : this.state.SetDetailValue.Odd['4TH'][item]}
                                          onKeyPress={(e) => this.DetailFucntion(e, 'Odd5THB')}
                                          ref={(input) => { this[`Odd4TH${item}`] = input;}}
                                        />
                                      </div>
                                    })}
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                    <div style={{ margin: '0px 10px', fontSize: 30}}>5</div>
                                    {['B',].map((item) => {
                                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                        <div  className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                                        <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '5TH', 'Odd')}
                                          value={(this.state.SetDetailValue.Odd && this.state.SetDetailValue.Odd['5TH']) === undefined ? '' : this.state.SetDetailValue.Odd['5TH'][item]}
                                          onKeyPress={(e) => this.DetailFucntion(e, 'InputB')}
                                          ref={(input) => { this[`Odd5TH${item}`] = input;}}
                                        />
                                      </div>
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 45, flexDirection: 'row' }}>
				        <Button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: '33%' }} 
                                  disabled={this.state.disabled}
                                  onClick={() => this.SubmitDetail()} 
                                  className="btn my-cusomized-button button2" >
                                  {language[this.props.currentLanguage].Submit}
                                </Button>
                              </div>
                            </div>
                          </div>}

                          <div style={{ width: '100%'}}>
                            <div style={{ marginLeft: '5%',width: '90%'}}>
                              <Select 
                                options={this.state.OptionsPackage}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                value={this.state.SelectedPackage}
                                onChange={this.handleChange}
                                placeholder={language[this.props.currentLanguage].SeacrhPackage}
                              >
                              </Select>
                            </div>
                            <div  style={{ marginLeft: '5%', flexDirection: 'row', display: 'flex', paddingTop: 10  }}>
                              { this.state.BackPackageNames && <Button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: '10%' }} 
                                disabled={this.state.disabled}
                                onClick={() => this.setState({packageNames: this.state.OriginalpackageNames, SearchValue: '', BackPackageNames: false})} 
                                className="btn my-cusomized-button" >
                                {language[this.props.currentLanguage].Back}
                              </Button>}
                            </div>
                            <div className="dcc" style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width:   '100%', marginBottom:  30, justifyContent: null, paddingTop: 10  }}>
                              {this.state.packageNames.map((item)=>(
                                <Button key={item.PackageID} style={{  margin: 5, display: 'flex', justifyContent: 'center', width: 'calc(100% / 10)' }} variant="primary" onClick={() =>  this.setState({ activeKeys: item.PackageID })}>
                                  <div className="wrap">
                                    {item.Name}
                                    {item.childPackage && <img src={tickicons} alt="tick" style={{ marginLeft: 10, width: 25, height: 25, borderRadius: 25}}/>}
                                  </div>
                                </Button>
                              ))}
                            </div>
                            <div style={{ display: 'flex', padding:  10, border: '1px solid #000', borderRadius: 20, overflowX: 'auto' }}>
                              <Tab.Content>
                                <div  style={{ display: 'flex',}}>
                                  {this.state.packageNames && this.state.packageNames.map((item, idx) => {
                                    return <Tab.Pane eventKey={ item.PackageID } key={ item.PackageID}>
                                      <HRPackage ApiRefresh={this.ApiRefresh} resetPackages={this.resetPackages}  targetUserID={this.state.formData.ID} packageDetails={item} idx={idx} toggleSetEditForm={this.toggleSetEditForm} AdminModal={true} UpdateSearchPackage={this.UpdateSearchPackage} CopyPackage={this.CopyPackage}/>
                                    </Tab.Pane>
                                  })} 
                                </div>
                              </Tab.Content>
                            </div>
                          </div>
                        </div>
                      </Tab.Container>
                      <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 45, flexDirection: 'row' }}>
                        <Button 
                          style={{ fontSize: "18px", textAlign: 'center', color: 'white', width: '33%' }}
                          variant="danger" 
                          className='button2'
                          onClick={() => this.setState({modalShow: false})} 
                        >
                          {language[this.props.currentLanguage].Shutdown}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
        
              <Tab.Container id="left-tabs-example" activeKey={this.state.activeKey} mountOnEnter unmountOnExit>
                <div style={{ display: 'flex', paddingLeft: 2}}>
                  <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', height: ((this.state.packageNames).length < 2 ) ? 400 : 600, width: 145}}>
                      <div style={{ backgroundColor: '#964B00', padding: 5, display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                        <span style={{ color: '#fff'}}>{language[this.props.currentLanguage].ActivePackage}</span>
                        {
                          (this.state.formData.Username === this.props.username) ? <Form.Control as="select"   defaultValue={this.state.formData.ActivePackageID}
                            value={this.state.formData.ActivePackageID}>
                            <option value={'NULL'}>{''}</option>
                            {this.state.packageNames && this.state.packageNames.map((item, idx) => {
                              return (item.childPackage) ? <option key={idx} value={item.childPackage}>{item.Name}</option> : <></>
                            })}
                          </Form.Control>
                            : 
                            <Form.Control as="select" onChange={(e) => {
                              this.dataChange('ActivePackageID', e.target.value, undefined ,'formData')
                              this.updateUserF(this.state.formData.ID, 'ActivePackageID', e.target.value)
                            }}  defaultValue={this.state.formData.ActivePackageID}
                            value={this.state.formData.ActivePackageID}>
                              <option value={'NULL'}>{''}</option>
                              {this.state.packageNames && this.state.packageNames.map((item, idx) => {
                                return  (item.childPackage) ?  <option value={item.childPackage}>{item.Name}</option> : <></>
                              })}
                            </Form.Control>
                        }
                      </div>
                      {(((this.state.formData.EwF === '-') ? false : (this.state.formData.EwF === 'null') ? false : true) && this.state.formData.Ew === 1)  && <Button className="button2" disabled={(this.state.Authority.DisplayablePackage === 1) ? false : true} style={{ marginBottom: 20, display: 'flex', justifyContent: 'center'}} variant={this.state.activeKey === 'eatWord' ? 'info' : 'outline-info'} onClick={() => this.setState({ activeKey: "eatWord" }, 
                        ()=>{ (this.state.MultiplyCheck) && (this.MultiplyCheckFunction())})}>
                        <div>{language[this.props.currentLanguage].FightingSetting}</div>
                      </Button>}
                      {(this.props.username !== this.state.formData.Username) && (this.state.Mode !== 'Create User') && <Button  className='button2' style={{  borderWidth: 2,borderColor: "black", fontWeight: 'bold'}} variant="light" onClick={() => this.setState({modalShow: true})}>{language[this.props.currentLanguage].SearchAllPackage}</Button>}
                      <div style={{ paddingTop: '15%'}}/>
                      {(this.state.MultiplyCheck) && <>
                        <Select
                          options={this.state.OptionsPackageNumber}
                          value={this.state.SelectedPackageNumber}
                          onChange={this.handleChangePackageNumber}
                        >
                        </Select>
                        {<Button  className='button2' 
                          variant={(this.state.MultiplyCheck) ? "warning" : "primary" } 
                          style={{ fontWeight: 'bold',  marginBottom: '5%', marginTop: '10%'}} 
                          disabled={((this.props.username === this.state.formData.Username) && this.props.Role === 'player') || ((this.props.username === this.state.formData.Username) && this.props.Role === 'agent')}  
                          onClick={() => this.SubmitMultiplePackageAlert()}>{language[this.props.currentLanguage].Proceed}
                        </Button>}
                      </>}
                      <Button  style={{ fontWeight: 'bold', marginBottom: '15%', display: 'flex', justifyContent: 'center', width:  '100%' }} variant={ (this.state.BooleanCheckAllPackage) ? 'info' :'primary'} onClick={() => this.CheckAllPackage()}>
                        {language[this.props.currentLanguage].CheckAll}
                        {(this.state.BooleanCheckAllPackage) && <img src={tickicons} alt="tick" style={{ marginLeft: 10, width: 25, height: 25, borderRadius: 25}}/>}
                      </Button>
                      {this.state.packageNames && this.state.packageNames.map((item, index) => {
                        return <div style={{ flexDirection: 'row', flexWrap: 'wrap',  display: 'flex',}}>
                          <Button key={item.PackageID} style={{  marginBottom: 5, display: 'flex', justifyContent: 'center', width:  (this.props.username !== this.state.formData.Username && (this.props.Role === 'player' ? false : true)) ? '79%' : '100%' }} variant={(item.BooleanMultipleCheck && (this.state.activeKey === item.PackageID ) ) ? 'warning' : (this.state.activeKey === item.PackageID) ? 'primary' : (item.BooleanMultipleCheck) ? 'warning' : 'outline-secondary'} onClick={() => this.ButtonChoosePackage( item, index )}>
                            <div className="wrap">
                              {item.Name}
                              {item.childPackage && <img src={tickicons} alt="tick" style={{ marginLeft: 10, width: 25, height: 25, borderRadius: 25}}/>}
                            </div>
                          </Button>
                          {(this.props.username !== this.state.formData.Username && (this.props.Role === 'player' ? false : true)) && <Form.Check
                            style={{ fontSize: 20, alignItems: 'center', display: 'flex', marginLeft: 1.5, marginRight: 0 }}
                            inline
                            type="checkbox"
                            checked={(item.BooleanMultipleCheck) ?  true : false}
                            disabled={(this.state.formData.ID === 'C') ? true : false}
                            onChange={() => this.SelectedMultiple( item, index )} 
                          />}
                        </div>
                      })}
                    </div>
                    <div style={{ padding: 5, display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                      {((this.props.Role === 'admin' || this.props.Role === 'superAdmin') && this.state.formData.ID === 'C' ) && (this.state.Mode !== 'Create User') && <Button  className='button2' style={{ marginBottom: 20, display: 'flex', justifyContent: 'center'}} onClick={() => this.CreateNewOCPackage('', '') }>
                        <div>{language[this.props.currentLanguage].AddPackage}</div>
                      </Button>}
                    </div>
                  </div>
                  {(this.state.Authority.DisplayablePackage === 1) ? 
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <Tab.Content>
                          {(this.state.NewOCPackage.PackageID !== '' ) && <Tab.Pane eventKey="CreatePackage">
                            <CreateHRPackage NewPackage={JSON.parse(JSON.stringify(this.state.NewOCPackage))} CloseEWPackage={this.CloseEWPackage} toggleEditForm={this.toggleEditForm} RefrehPackage={this.RefrehPackage}/>
                          </Tab.Pane>}
                          {(((this.state.formData.EwF === '-') ? false : (this.state.formData.EwF === 'null') ? false : true) && this.state.formData.Ew === 1) && <Tab.Pane eventKey="eatWord">
                            <EWPackage OwnerUserID={this.state.formData.ID} OwnerUsername={this.state.formData.Username} EwPackage={JSON.parse(JSON.stringify(this.state.formData.EwPackage))} oriFormData={this.state.oriFormData.EwPackage} CloseEWPackage={this.CloseEWPackage} ActiveNotification={this.ActiveNotification} targetUserID={this.state.formData.ID} dataChange={this.dataChange} update={this.updateAll}/>
                          </Tab.Pane>}
                          {this.state.packageNames && this.state.packageNames.map((item, idx) => {
                            return <Tab.Pane eventKey={ item.PackageID} key={item.PackageID}>
                              <HRPackage ApiRefresh={this.ApiRefresh} toggleCloseForm={this.toggleCloseForm} OwnerUsername={this.state.formData.Username} packageDetails={item} targetUserID={this.state.formData.ID} toggleEditForm={this.toggleEditForm} resetForm={this.resetForm} resetPackages={this.resetPackages} idx={idx} dataChange={this.dataChange} update={this.updateAll} MultiplyCheckFunction={this.MultiplyCheckFunction} MultiplyCheck={this.state.MultiplyCheck} ActiveNotification={this.ActiveNotification} SubmitMultiplePackageAlert={this.SubmitMultiplePackageAlert}  CopyPackage={this.CopyPackage}/>
                            </Tab.Pane>
                          })}
                        </Tab.Content>
                      </div>
                    </>
                    : 
                    <>
                      <div style={{ color: 'red', fontWeight: 'bold', paddingLeft: 20, fontSize: '18px'}}> {` * ${language[this.props.currentLanguage].PackSettingAutho}`}</div>
                    </>}
                </div>
              </Tab.Container>
            </div>
          ):(
            <></>
          )}
          <ConfirmDelete OpenChangePassword={this.state.OpenChangePassword} propsSetState={this.propsSetState} showNotification={this.state.showNotification} showNotificationpass={this.state.showNotificationpass} notificationMessage={this.state.notificationMessage} ShowPasswordNew={this.state.ShowPasswordNew} UserPassword={this.state.UserPassword} ConfirmAccount={this.ConfirmAccount} onKeyEnterDelete={this.onKeyEnterDelete}/>
        </div>
        {(this.state.PopScrollBackButton)&&<div className={"scroll-to-top iconpopup button2"}
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold', cursor: 'pointer' }}>
          ▴
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
    ViewUserTable: state.ViewUserTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHRTableView: (ViewUserTable) => {
      dispatch({ type: 'HRTABLE_VIEW', payload: ViewUserTable });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HR);