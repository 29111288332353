import '../App.css';
import JSEncrypt from 'jsencrypt';
import React from 'react';
import { GoSignIn } from '@react-icons/all-files/go/GoSignIn';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import Loading3 from '../Images/loading2.gif';
// import Modal from 'react-bootstrap/Modal';
// import MoGuHeader from '../Images/Header_D2.png';
import { connect } from 'react-redux';
import { language } from '../language';
import { Button, Form } from 'react-bootstrap';
const { getPublicKey, getLogin, getCapchaCode } = require('../Api');
// import { BsEyeSlash } from '@react-icons/all-files/bs/BsEyeSlash';
// import { FaRegEye } from '@react-icons/all-files/fa/FaRegEye';
// import Footer from '../Component/Footer';
// import Header from '../Component/Header';

// var timer

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Password: '',
      Role: 'admin',
      loading: false,
      ShowPassword: false,
      Captcha: false,
      LoginStatus: '',
      confirmCaptcha: '',
      secretcode: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.NumberOnly = this.NumberOnly.bind(this);
  }

  async componentDidMount() {
    this.UsernameInput && this.UsernameInput.focus();
    this.getCapcha();
  }

  async getCapcha() {
    const CapchaView = await getCapchaCode();
    if (CapchaView) {
      this.setState({ secretcode: CapchaView.secretCode })
    }
  }

  async handleSubmit() {
    this.setState({ loading: true });
    if (this.state.Username === '') {
      alert(`${language[this.props.currentLanguage].PleaseInsertUsername}`);
      this.props.setLoginStatus('', '', '', '', '');
      this.setState({ loading: false})
    } else if (this.state.Password === '') {
      alert(`${language[this.props.currentLanguage].Passwordcannotempty}`);
      this.setState({ loading: false})
      this.props.setLoginStatus('', '', '', '', '');
    }
    else {
      const PublicKey = await getPublicKey();
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(PublicKey.publicKey);
      const EnPassword = encrypt.encrypt(this.state.Password);

      const LoginStatus = await getLogin(
        this.state.Username,
        EnPassword,
        'website',
        this.state.secretcode, 
        this.state.confirmCaptcha,
      );
      console.log('LoginStatus', LoginStatus)
      if (LoginStatus.error === 'Invalid Password') {
        alert(`${language[this.props.currentLanguage].InvalidPassword}`);
        this.setState({ loading: false })
        this.props.setLoginStatus('', '', '', '', '');
        if (this.PasswordInput) this.PasswordInput.focus();
      } else if (LoginStatus.error === 'Account Has Been Locked, Please Find Admin to Unlock') {
        alert(`${language[this.props.currentLanguage].AccountHasBeenLocked}`);
        this.setState({ loading: false })
        this.props.setLoginStatus('', '', '', '', '');
        this.UsernameInput && this.UsernameInput.focus();
      } else if (LoginStatus.error === 'Invalid Account') {
        alert(`${language[this.props.currentLanguage].InvalidAcc} !`);
        this.setState({ loading: false })
        this.props.setLoginStatus('', '', '', '', '');
        this.UsernameInput && this.UsernameInput.focus();
      } 
      else if (this.state.confirmCaptcha  === '') {
        alert(`Empty Captcha !!`);
        this.setState({ loading: false }, () => {
          this.CapchaInput.focus();
        })
        this.props.setLoginStatus('', '', '', '', '');
        
      }
      else if (LoginStatus.error === 'Wrong Captcha') {
        alert(`${language[this.props.currentLanguage].WrongCapcha} !`);
        this.setState({ loading: false }, () => {
          this.CapchaInput.focus();
        })
        this.props.setLoginStatus('', '', '', '', '');
      }  else if (LoginStatus.error) {
        alert(`${LoginStatus.error}`);
        this.setState({ loading: false })
        this.props.setLoginStatus('', '', '', '', '');
      } else {
        localStorage.setItem('Username', LoginStatus.username)
        localStorage.setItem('Role', LoginStatus.userRole)
        localStorage.setItem('Token', LoginStatus.token)
        localStorage.setItem('userID', LoginStatus.ID)
        localStorage.setItem('isSub', LoginStatus.isSub)
        localStorage.setItem('GameDisplay', LoginStatus.gameDisplay)
        localStorage.setItem('LoginStatus', LoginStatus.FirstTimeLogin)
        localStorage.setItem('Min_Date', new Date())
        localStorage.setItem('Max_Date', new Date())
        localStorage.setItem('SELECTED_DATE', new Date())
        
        const localStorageT = [];
        const UserAuthority = {
          ...localStorageT,
          RebuyOrder: LoginStatus.RebuyOrder,
          CreateUser: LoginStatus.CreateUser,
          EditOrder: LoginStatus.EditOrder,
          EditUser: LoginStatus.EditUser,
          EditBanNumber: LoginStatus.EditBanNumber,
          EditGame: LoginStatus.EditGame,
          Report: LoginStatus.Report,
          SalesReport: LoginStatus.SalesReport,
          isSub: LoginStatus.isSub,
          Able101Import: LoginStatus.Able101Import,
          AbleDading: LoginStatus.AbleDading,
          Tong: LoginStatus.Tong,
          Bet: LoginStatus.Bet,
          DisplayOrder: LoginStatus.DisplayOrder,
          ViewDownlineReport: LoginStatus.ViewDownlineReport,
          PhoneSetting: LoginStatus.PhoneSetting,
          RebuyTimeoutOrder: LoginStatus.RebuyTimeoutOrder,
          CancelOrRevertTimeoutOrder: LoginStatus.CancelOrRevertTimeoutOrder,
          DisplayablePackage: LoginStatus.DisplayablePackage,
          CompileReport: LoginStatus.CompileReport,
          EditTimeoutOrder: LoginStatus.EditTimeoutOrder,
          PackageInput: LoginStatus.PackageInput,
          PackageSetting: LoginStatus.PackageSetting,
          setting: LoginStatus.setting,
          SalesControl: LoginStatus.SalesControl,
          SystemSetting: LoginStatus.SystemSetting,
          AcctDisplay: LoginStatus.AcctDisplay,
          SetGameResult: LoginStatus.SetGameResult,
          SetOpenDate: LoginStatus.SetOpenDate,
          AdminSetup: LoginStatus.AdminSetup,
          AbleOrderAfterSeven: LoginStatus.AbleOrderAfterSeven,
          IgnoreOrderLimit: LoginStatus.IgnoreOrderLimit,
          AbleRed: LoginStatus.AbleRed,
          OneStarBao: LoginStatus.OneStarBao,
          GameBetting: LoginStatus.GameBetting,
          PinOrOutWord: LoginStatus.PinOrOutWord,
          CancelOrRevertOrder: LoginStatus.CancelOrRevertOrder,
          AbleChange: LoginStatus.AbleChange,
          ActingAs: (LoginStatus.ActingAs === undefined) ? '' : LoginStatus.ActingAs,
        };

        localStorage.setItem('UserAuthority', JSON.stringify(UserAuthority));
        this.props.setAuthority(
          JSON.stringify(UserAuthority),
        );
        const SaveTableID = (LoginStatus.isSub === 1) ? LoginStatus.ActingAs : LoginStatus.ID
        this.props.setHRTableView(SaveTableID);
        this.props.setLoginStatus(
          LoginStatus.username,
          LoginStatus.token,
          LoginStatus.userRole,
          LoginStatus.ID,
          LoginStatus.isSub,
        );
        window.location.href = '/Home';
        this.setState({ loading: false })
        this.props.history.push('/Home')
      }
    }
  }

  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  NumberOnly(value, field) {
    const username = (value.toUpperCase())
    this.setState({ [field]: username });
  }

  async onKeyEnter(e) {
    if (e.charCode === 13) {
      document.getElementById("searchButton").click()
    }
  }

  render() {
    return (
      <div className="dcc backgroundImageLogin" style={{ width: '100%', height: '100vh' }}>
        {this.state.loading ? (
          <div style={{ flexDirection: 'column', width: '100%', marginTop: -150 }}>
            <div>
              <img src={Loading3} alt="Logo" className="logoimages" style={{ marginBottom: 30, marginTop: 50 }} />
              <div className="loadingMessage" style={{ color: 'white' }}> Loading ... Please Wait a moment</div>
            </div>
          </div>
        )
        //  isMobile ? (
        //   <Form className="dcc" style={{ border: `1px solid black`, flexDirection: 'column',  backgroundColor: '#343A40', padding: 20 }}>
        //     <Form.Label style={{ width: '100%', height: 'auto', fontWeight: 'bold', color: 'white',backgroundColor: 'black', fontSize: 100, padding: 35 }}>
        //       MOGU
        //     </Form.Label>
        //     <Form.Group className="dcc" style={{ width: '100%', height: 'auto', marginBottom: 25, flexDirection: 'column' }}>
        //       <Form.Label style={{ width: '100%', color: 'white', fontWeight: 'bold', fontSize: 100 }}>用户名</Form.Label>
        //       <Form.Control
        //         className="LoginInputText"
        //         type="text"
        //         value={this.state.Username}
        //         onChange={(e) => this.NumberOnly(e.target.value, 'Username')}
        //         ref={(input) => { this.UsernameInput = input; }}
        //         placeholder={'用户名'}
        //         style={{ fontWeight: 'bold', height: 200, marginLeft: '5%', marginRight: '5%', fontSize: 100}}
        //       />
        //       <div style={{ width: '10%'}}/> 
        //     </Form.Group>
        //     <Form.Group className="dcc" style={{ width: '100%', height: 'auto', flexDirection: 'column', marginBottom: 100}}>
        //       <Form.Label style={{ width: '100%', color: 'white', fontWeight: 'bold', fontSize: 100 }}>密码</Form.Label>
        //       <Form.Group className="dcc" style={{ width: '100%', height: 'auto', flexDirection: 'row', marginLeft: '5%', marginRight: '5%'}}>
        //         <Form.Control
        //           className="LoginInputText"
        //           type={(this.state.ShowPassword === true) ? "Text" : "Password"}
        //           value={this.state.Password}
        //           onChange={(e) => this.handleChange(e.target.value, 'Password')}
        //           placeholder={'密码'}
        //           ref={(input) => { this.PasswordInput = input; }}
        //           onKeyPress={(e) => this.onKeyEnter(e)}
        //           style={{ fontWeight: 'bold', height: 200, fontSize: 100}}
        //         />
        //         {this.state.ShowPassword === false
        //           ? <FaEyeSlash style={{ textAlign: 'center' }} size="200px" color="white" onClick={()=> this.setState({ShowPassword: true })}/>
        //           : <FaEye style={{ textAlign: 'center' }} size="200px" color="white" onClick={()=> this.setState({ShowPassword: false })}/> 
        //         }
        //       </Form.Group>
        //     </Form.Group>
        //     <Form.Group className="dcc" style={{ width: '100%', height: 'auto', flexDirection: 'row', alignItems: 'center'}}>
        //       <div style={{ cursor: 'pointer', fontSize: 200, color: 'white', lineHeight: 1}} onClick={() => this.getCapcha()}>{'⟳'}</div>
        //       <div style={{ borderRadius: 2, cursor: 'pointer', marginRight: 5}} onClick={() => this.getCapcha()}><img src={`https://ace-bt.s3.ap-southeast-1.amazonaws.com/capcha/${this.state.secretcode}.png`} alt="Logo" style={{ width: 500, height: 200 }} /></div>
        //       <Form.Control
        //         type="text"
        //         value={this.state.confirmCaptcha}
        //         onChange={(e) => this.setState({ confirmCaptcha: e.target.value })}
        //         placeholder={'输入capcha'}
        //         ref={(input) => { this.CapchaInput = input; }}
        //         onKeyPress={(e) => this.onKeyEnter(e)}
        //         style={{ fontWeight: 'bold', textAlign: 'center', height: 200, fontSize: 100, width: 800 }}
        //       />
        //     </Form.Group>
        //     <Form.Group style={{ width: '100%' }}>
        //       <Button  className="ButtonLogin" style={{ flexDirection: 'row', width: 600, height: 200 }} id="searchButton" onClick={this.handleSubmit}>
        //         <p1 style={{ fontSize: 100, fontWeight: 'bold', marginRight: 5, color: 'white' }}>
        //            登入
        //         </p1>
        //         <GoSignIn style={{ textAlign: 'center' }} size="100px" color="lightblue" />
        //       </Button >
        //     </Form.Group>
        //   </Form>
        // ) 
          : (
            <Form className="dcc" style={{ width: '70%', border: `1px solid black`, flexDirection: 'column',  backgroundColor: '#343A40' }}>
              <Form.Label style={{ width: '100%', height: 'auto', fontWeight: 'bold', color: 'white',backgroundColor: 'black',  fontSize: '30px', padding: 35 }}>
              MOGU
              </Form.Label>
              <Form.Group className="dcc" style={{ width: '80%', height: 'auto', marginBottom: 25, paddingTop: '8%', flexDirection: 'row' }}>
                <Form.Label style={{ width: '10%', color: 'white', fontWeight: 'bold' }}>用户名</Form.Label>
                <Form.Group className="dcc" style={{ width: '100%', height: 'auto', flexDirection: 'row'}}>
                  <Form.Control
                    className="LoginInputText"
                    type="text"
                    value={this.state.Username}
                    onChange={(e) => this.NumberOnly(e.target.value, 'Username')}
                    ref={(input) => { this.UsernameInput = input; }}
                    placeholder={'用户名'}
                    style={{ fontWeight: 'bold', marginRight: null, marginLeft: ''}}
                  />
                  <div style={{ width: '10%'}}/> 
                </Form.Group>
              </Form.Group>
              <Form.Group className="dcc" style={{ width: '80%', height: 'auto', flexDirection: 'row'}}>
                <Form.Label style={{ width: '10%', color: 'white', fontWeight: 'bold' }}>密码</Form.Label>
                <Form.Group className="dcc" style={{ width: '100%', height: 'auto', flexDirection: 'row'}}>
                  <Form.Control
                    className="LoginInputText"
                    type={(this.state.ShowPassword === true) ? "Text" : "Password"}
                    value={this.state.Password}
                    onChange={(e) => this.handleChange(e.target.value, 'Password')}
                    placeholder={'密码'}
                    ref={(input) => { this.PasswordInput = input; }}
                    onKeyPress={(e) => this.onKeyEnter(e)}
                    style={{ fontWeight: 'bold', marginRight: null,  marginLeft: ''}}
                  />
                  {this.state.ShowPassword === false
                    ? <FaEyeSlash style={{ textAlign: 'center', width: '10%' }} size="25px" color="white" onClick={()=> this.setState({ShowPassword: true })}/>
                    : <FaEye style={{ textAlign: 'center', width: '10%' }} size="25px" color="white" onClick={()=> this.setState({ShowPassword: false })}/> 
                  }
                </Form.Group>
              </Form.Group>
              <Form.Group className="dcc" style={{ width: '80%', height: 'auto'}}>
                <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10}}>
                  <>
                    <div style={{ padding: 0, textAlign: 'center', width: 270, fontSize: '25px', fontWeight: 'bold', display: 'flex', flexDirection: 'row', color: 'white' }}>
                      <div style={{ display: 'flex', textAlign: 'end', cursor: 'pointer', fontSize: '30px'}} onClick={() => this.getCapcha()}>{'⟳'}</div>
                      <div style={{ width: '80%', backgroundColor: '#EDEDED', borderRadius: 2}}><img src={`https://ace-bt.s3.ap-southeast-1.amazonaws.com/capcha/${this.state.secretcode}.png`} alt="Logo" style={{ width: '100%', height: 100 }} /></div>
                    </div>
                  </>
                  <>
                    <Form.Control
                      type="text"
                      value={this.state.confirmCaptcha}
                      onChange={(e) => this.setState({ confirmCaptcha: e.target.value })}
                      placeholder={'输入capcha'}
                      ref={(input) => { this.CapchaInput = input; }}
                      onKeyPress={(e) => this.onKeyEnter(e)}
                      style={{ fontWeight: 'bold', textAlign: 'center', width: 180, height: 54, fontSize: '18px'}}
                    />
                  </>
                </div>
              </Form.Group>
              <Form.Group style={{ width: '100%', paddingBottom: '8%' }}>
                <Button  className="ButtonLogin" style={{ flexDirection: 'row' }} id="searchButton" onClick={this.handleSubmit}>
                  <p1 style={{ fontSize: '18px', fontWeight: 'bold', marginRight: 5, color: 'white' }}>
                  登入
                  </p1>
                  <GoSignIn style={{ textAlign: 'center' }} size="25px" color="lightblue" />
                </Button >
              </Form.Group>
              {/* <Card.Footer style={{fontWeight: 'bold', color: 'white', backgroundColor: 'black', width: '100%' }}>2 days ago</Card.Footer> */}
            </Form>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role, userID, isSub) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
          userID: userID,
          isSub: isSub,
        },
      });
    },
    setAuthority: (UserAuthority) => {
      dispatch({
        type: 'AUTHO_RITY',
        payload: {
          UserAuthority: UserAuthority,
        },
      });
    },
    setHRTableView: (ViewUserTable) => {
      dispatch({ type: 'HRTABLE_VIEW', payload: ViewUserTable });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
    setBooleanReturn: (Boolean) => {
      dispatch({
        type: 'BACK_URL',
        payload: {
          BooleanBack: Boolean,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
