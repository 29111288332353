import '../App.css';
import React from 'react';
import Header from '../Component/Header';
import { connect } from 'react-redux'
import Alert from 'react-bootstrap/Alert';
import { language } from '../language';
import Loading3 from '../Images/loading-3.gif';
import Moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import Moment from 'moment-timezone';
import { Form, Button } from 'react-bootstrap';
const { multipleSearchTextFNC } = require('../Api');

var timer


//
//                       _oo0oo_
//                      o8888888o
//                      88" . "88
//                      (| -_- |)
//                      0\  =  /0
//                    ___/`---'\___
//                  .' \\|     |// '.
//                 / \\|||  :  |||// \
//                / _||||| -:- |||||- \
//               |   | \\\  -  /// |   |
//               | \_|  ''\---/''  |_/ |
//               \  .-\__  '-'  ___/-. /
//             ___'. .'  /--.--\  `. .'___
//          ."" '<  `.___\_<|>_/___.' >' "".
//         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//         \  \ `_.   \_ __\ /__ _/   .-` /  /
//     =====`-.____`.___ \_____/___.-`___.-'=====
//                       `=---='
//
//
//     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//               佛祖保佑         永无BUG

class MultiNumberSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedDate: new Date(),
      username: this.props.username,
      UserID: this.props.userID,
      token: this.props.token,
      SearchNumber: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      loading: false,
      is_visible: false,
      DisplayReceipt: [],
    }
  }

  async componentDidMount() {
    this.NumberInput.focus();
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  async ConfirmSearch() {
    const SearchNumber = this.state.SearchNumber
    const SplitSearchNumber = SearchNumber.split(' ')
    const SelectedDate = Moment(this.state.SelectedDate).format('YYYY-MM-DD')
    // console.log('SplitSearchNumber', SplitSearchNumber)
    // console.log(SelectedDate)
    if (this.state.SearchNumber === '') {
      this.openNotification('CannotbeEmpty')
      this.setState({ notificationType: 'Error' });
    } else if (SplitSearchNumber.length === 1) {
      this.openNotification('InsertMorethan1')
      this.setState({ notificationType: 'Error' });
    } else {
      const getNumberReceipt = await multipleSearchTextFNC(SearchNumber, SelectedDate, this.props.username, this.props.token)
      // console.log('getNumberReceipt', getNumberReceipt)
      if (getNumberReceipt.message === 'Success') {
        this.openNotification('Success')
        this.setState({ notificationType: 'Success', DisplayReceipt: getNumberReceipt.result  });
      } else {
        this.openNotification('NoDataFound')
        this.setState({ notificationType: 'Error', DisplayReceipt: [] });
      }
    }
  }

  async handleSearchText(e, field) {
    const value = (e.target.value).replace(/[^0-9 ]/g, '')
    this.setState({ [field]: value })
  }

  async setSelectedDate(date, mode) {
    this.setState({
      [mode]: date,
    });
  }

  KeyPress(e) {
    if(e.key === 'Enter') {
      this.ConfirmSearch();
    }
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 2000);
  };

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} className='StartBackground'>
        <div id={'Top'}/>
        <Header />
        <div className="row" style={{ marginBottom: 30, display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '20px' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} 
            show={this.state.showNotification}
            variant={this.state.notificationType === 'Error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
          <div className="dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white', flexDirection: null, height: null, fontWeight: 'bold', padding: 0 }}>
            <div>{`${language[this.props.currentLanguage].MultiNumberSearch}`}</div>
            <div>{``}</div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            paddingBottom: 10,
            justifyContent: 'space-between',
            marginLeft: '4%',
            flexDirection: 'row',
          }}>
          <div className="dcc row" style={{ marginRight: 10 }}>
            <div className='dcc' style={{ flexDirection: 'row', width: 'auto', paddingTop: 0 }}>
              {this.state.DisplayReceipt.length > 0 &&
            <div style={{ fontSize: '17px', fontWeight: 'bold' }}>{`(${this.state.DisplayReceipt.length})`}</div> 
              }
              <div style={{ fontWeight: 'bold', width: 'auto', paddingRight: 5 }}>{language[this.props.currentLanguage].nombor}* </div>
              <Form.Control
                value={this.state.SearchNumber}
                style={{
                  width: '60%',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#303030',
                }}
                onKeyPress={(e) => this.KeyPress(e)}
                ref={(input) => { this.NumberInput = input; }}
                type="text"
                onChange={(e) => this.handleSearchText(e, 'SearchNumber')}
              /></div>
            <div className='dcc' style={{ flexDirection: 'row', width: 'auto', paddingTop: 0, paddingRight: 5 }}>
              <div style={{ fontWeight: 'bold', width: 80 }}>{language[this.props.currentLanguage].Date} </div>
              <DatePicker
                dateFormat = 'dd-MMM-yyyy'
                selected={this.state.SelectedDate}
                onChange = {(date) => this.setSelectedDate(date, 'SelectedDate')}
                className={"form-control StyleDatePicker"}
              // withPortal
              /></div>
            <div>
              <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold', width: '100%' }}
                className="button2"
                variant="primary"
                onClick={() => this.ConfirmSearch()}>
                {language[this.props.currentLanguage].Search}
              </Button>
            </div>
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.state.username}</div>
          </div>
        </div>
        {this.state.loading === true ? (
          <div style={{ flexDirection: 'column', width: '100%' }}>
            <div>
              <img src={Loading3} alt="Logo" className="logoimages" style={{ marginBottom: 30, marginTop: 100, color: 'black' }} />
              <div className="loadingMessage" style={{ color: 'black' }}>{language[this.props.currentLanguage].Loading}</div>
            </div>
          </div>
        ) : (
          <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
            {this.state.DisplayReceipt.map((items, index) => {
              return(
                <div key={index} className='scrollhost' style={{ width: '90%', backgroundColor: 'white', borderRadius: 10, marginBottom: 10, textAlign: 'left', padding: 10, overflowY: 'auto', whiteSpace: 'pre-wrap' }}>
                  {items}
                </div>
              );
            })}
          </div>
        )}  
        {this.state.is_visible && 
          <div className="dcc" style={{ paddingBottom: 30 }}>
            <div class="scrollDesign iconpopup button2"  
              onClick={() => this.scrollToTop()}>
              {/* ↑ ⇈ */}
              <div  className="icon MoveUpArrow"> ⇧ </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
// export default Home;

function mapStateToProps(state) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
    // setDate: (Date) => {
    //   dispatch({
    //     type: 'SELECTED_DATE',
    //     payload: Date,
    //   });
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiNumberSearch);
