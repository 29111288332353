import '../App.css';
import { connect } from 'react-redux'
import { language } from '../language';
import React from 'react';
import Header from '../Component/Header';
import Moment from 'moment-timezone';
import VIPAddRNPackage from '../Component/AddVIPPackage';
import EditVIPPackage from '../Component/EditVIPPackage';
import 'react-datepicker/dist/react-datepicker.css';
import { Alert, Tab, Form, Button, Table } from 'react-bootstrap';
// import { isMobile } from 'react-device-detect';
const { getAllUserID, createVIPMin, getVIPMinimum, deleteVIPMin, updateVIPMin } = require('../Api');
var timer

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class VIP_Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideAddForm: true,
      hideEditForm: true,
      notificationType: 'success',
      showNotification: false,
      notificationMessage: '',
      Username: this.props.username,
      Role: this.props.Role,
      Token: this.props.token,
      userID: this.props.userID,
      VipNumberApiResult: [],
      OriginalVipData: [],
      allUserIDResult:[],
      VIP_USER: '',
      SearchUsername: '',
      LPXNumber: '',
      showType: 'LPX',
      AddVIPData: {
        Limit: {Default: { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }},
        RedLimit: {Default: { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }},
      },
      editLPX: '',
      editVIPData: {
        Limit: {Default: { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }},
        RedLimit: {Default: { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }},
      },
      PopScrollBackButton: false,
    }
    this.dataChange = this.dataChange.bind(this);
    this.modeChange = this.modeChange.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.addRedNumberF = this.addRedNumberF.bind(this);
    this.getAllUserIDAPI = this.getAllUserIDAPI.bind(this);
    this.getExistBannedNumberAPI = this.getExistBannedNumberAPI.bind(this);
    this.toggleAddRedNumberForm = this.toggleAddRedNumberForm.bind(this);
    this.toggleDeletedRedNumberForm = this.toggleDeletedRedNumberForm.bind(this);
    this.RefreshAPI = this.RefreshAPI.bind(this);
    this.updateVIPMinF = this.updateVIPMinF.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ userID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID }, () => {
      this.getExistBannedNumberAPI();
      this.getAllUserIDAPI();
    })
  }

  closeForm() {
    this.setState({
      hideAddForm: true, 
      hideEditForm: true, 
      AddVIPData: {
        Limit: {Default: { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }},
        RedLimit: {Default: { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }},
      },
      editVIPData: {
        Limit: {Default: { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }},
        RedLimit: {Default: { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }},
      },
      editLPX: '',
      LPXNumber: '',
      VIP_USER: '',
    })
  }

  async getAllUserIDAPI() {
    let allUserIDResult = await getAllUserID(
      this.state.Username,
      this.state.Token,
    )
    this.setState({ allUserIDResult })
  }

  async getExistBannedNumberAPI() {
    let getVIPMinimumResult = await getVIPMinimum(
      this.state.Token,
      this.state.Username,
      this.state.showType,
    )
    this.setState({ 
      VipNumberApiResult: getVIPMinimumResult,
      OriginalVipData: getVIPMinimumResult,
    })
  }

  dataChange(field, value) {
    if (field === 'LPX') {
      this.setState({ 
        LPXNumber: Number(value)
      })
    } else if (field === 'AddVIPData') {
      this.setState({ 
        AddVIPData: value
      })
    } else if (field === 'editVIPData') {
      this.setState({ 
        editVIPData: value
      })
    } else if (field === 'editLPX') {
      this.setState({ 
        editLPX: value
      })
    }
  }

  RefreshAPI(){
    this.getExistBannedNumberAPI();
    this.getAllUserIDAPI();
  }

  async addRedNumberF(status) {
    const UserId = this.state.userID
    if (status === 'LPX') {
      if (this.state.VIP_USER === '') {
        alert('Please choose User ID');
      } else if (this.state.LPXNumber === '' || this.state.LPXNumber === 0) {
        alert(`${language[this.props.currentLanguage].NoBlank}`);
      } else {
        let addVIPAPIResult = await createVIPMin(
          this.state.VIP_USER,
          'LPX',
          this.state.LPXNumber,
          UserId,
          this.state.Token,
          this.state.Username,
        )
        if (addVIPAPIResult.message === 'Successfully Created') {
          alert(`${language[this.props.currentLanguage].SuccessfullyCreated}`);
          this.getExistBannedNumberAPI();
          this.closeForm()
        } else if (addVIPAPIResult.error === 'Duplicate User'){
          alert(`${language[this.props.currentLanguage].DuplicatedUSer}`);
        } else if (addVIPAPIResult.error){
          alert(`${language[this.props.currentLanguage].DatabaseError}`);
        }
      }
    } else {
      if (this.state.VIP_USER === '') {
        alert('Please choose User ID');
      } else {
        const allPlatform = Object.keys(this.state.AddVIPData.Limit)
        let foundError = false
        for (let i = 0; i < allPlatform.length; i += 1) {
          const betModeToLoop = allPlatform[i] === '3' || allPlatform[i] === '8' || allPlatform[i] === 'Default'? ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF', '5D', '6D'] : ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF']
          for (let j = 0; j < betModeToLoop.length; j += 1) {
            if (this.state.AddVIPData.Limit[allPlatform[i]][betModeToLoop[j]] === '') {
              foundError = true
            }
            if (this.state.AddVIPData.RedLimit[allPlatform[i]][betModeToLoop[j]] === '') {
              foundError = true
            }
          }
        }
        if (foundError) {
          alert(`${language[this.props.currentLanguage].NoBlank}`);
          return
        }
        const addVIPAPIResult = await createVIPMin(
          this.state.VIP_USER,
          this.state.showType,
          this.state.AddVIPData,
          UserId,
          this.state.Token,
          this.state.Username,
        )
        if (addVIPAPIResult.message === 'Successfully Created') {
          alert(`${language[this.props.currentLanguage].AddedSuccess}`);
          this.getExistBannedNumberAPI();
          this.closeForm()
        } else if (addVIPAPIResult.error === 'Duplicate User'){
          alert(`${language[this.props.currentLanguage].DuplicatedUSer}`);
        } else if (addVIPAPIResult.error){
          alert(`${language[this.props.currentLanguage].DatabaseError}`);
        }
      }
    }
  }

  modeChange(modeValue, field) {
    this.setState({ [field]: modeValue })
  }

  toggleAddRedNumberForm() {
    if (this.state.hideAddForm) {
      this.setState({
        hideAddForm: false,
      })
    } else {
      this.closeForm()
    }
  }

  toggleEditRedNumberForm(selectedvipNumberData) {
    if (this.state.hideEditForm) {
      this.setState({
        editVIPData: JSON.parse(JSON.stringify(selectedvipNumberData)),
        editLPX: selectedvipNumberData.LPX
      }, () => {
        this.setState({
          hideEditForm: false,
        }, () => {
          this.Vipusers.focus();
        })
      })
    } else {
      this.closeForm()
    }
  }

  async toggleDeletedRedNumberForm(ID_Deleted){
    const r = (window.confirm(`${language[this.props.currentLanguage].DeletedAlert}`));
    if (r === true) {
      const selectedRNLimit = await deleteVIPMin(ID_Deleted.ID, this.state.Token, this.state.Username)
      if (selectedRNLimit.message === 'Successfully Delete') {
        this.setState({ notificationType: 'success' }, () => {
          this.openNotification(`${language[this.props.currentLanguage].SuccessDeletedVIP}`)
        });
        this.closeForm()
        this.getExistBannedNumberAPI();
      }
    }
  }

  async toggleSearchRedNumber() {
    let VipNumberApiResult = this.state.OriginalVipData.filter((item) => item.Username.includes(this.state.SearchUsername))
    if (VipNumberApiResult && VipNumberApiResult.length === 0 ){
      this.setState({ notificationType: 'error', VipNumberApiResult: JSON.parse(JSON.stringify(this.state.OriginalVipData)), SearchUsername: '' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].NoDataFound}`)
      });
    } else {
      this.setState({ VipNumberApiResult })
    }
  }

  async EnterShortSearch(e){
    if (e.key === 'Enter') {
      this.toggleSearchRedNumber();
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
      });
    }, 1500);
  };

  toggleShowType(type) {
    this.closeForm();
    this.setState({ showType: type }, () => {
      this.getExistBannedNumberAPI();
    })
  }

  editVIPUser(value) {
    const tempObject = JSON.parse(JSON.stringify(this.state.editVIPData))
    tempObject.UserID = value
    this.setState({ editVIPData: tempObject})
  }

  async updateVIPMinF() {
    const objectToUpdate = {
      UserID: this.state.editVIPData.UserID,
      Limit: this.state.editVIPData.Limit,
      RedLimit: this.state.editVIPData.RedLimit,
      Mode: this.state.editVIPData.Mode,
      selectedTableID: this.state.editVIPData.ID,
      LPX: this.state.editLPX,
    }
    const updateVIPMinResult = await updateVIPMin(objectToUpdate, this.state.Token, this.state.Username)
    if (updateVIPMinResult.message === 'Successfully Updated') {
      alert(`${language[this.props.currentLanguage].AddedSuccess}`);
      this.getExistBannedNumberAPI();
      this.closeForm()
    } else if (updateVIPMinResult.error === 'Duplicate User'){
      alert(`${language[this.props.currentLanguage].DuplicatedUSer}`);
    } else if (updateVIPMinResult.error){
      alert(`${language[this.props.currentLanguage].DatabaseError}`);
    }
  }

  // handleScroll = (event) => {
  //   var node = event.target;
  //   const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
  //   if (bottom) {
  //     this.setState({PopScrollBackButton: bottom})
  //   } else {
  //     this.setState({PopScrollBackButton: bottom})
  //   }
  // }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({ PopScrollBackButton: true });
    } else {
      this.setState({ PopScrollBackButton: false });
    }
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ backgroundColor: '#EFEFEF',  width: '100%', height: '100vh', flexDirection: 'column', overflowY: 'auto' }}> 
        <Header />
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div style={{ padding: 5 }}>
          <div ref={this.elScroll.elRef}  style={{ width: '25%', fontSize: '20px', marginLeft: 'auto', marginRight: 'auto' }} className="form-control">
            <b> {language[this.props.currentLanguage].ExtraLimit} </b>
          </div>
          <div  className="dcc" style={{ display: 'flex', marginTop: 10, marginBottom: 10, fontSize: '18px', fontWeight: 'bold', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' , marginBottom: null}}>
              <div className='MenubarTitle' style={{ margin: '0px 10px', width: null}}> {language[this.props.currentLanguage].usernameLanguage}: </div>
              <Form.Control style={{ width: 200, marginRight: 3, textAlign: 'center', color: 'red', fontWeight: 'bold' }}  type="text" 
                value={this.state.SearchUsername} 
                onChange={(e) => this.setState({ SearchUsername: e.target.value})}
                placeholder={language[this.props.currentLanguage].usernameLanguage}
                onKeyPress={(e) => this.EnterShortSearch(e)}
              />
              <Button variant="primary" className='TextFontSize' style={{ width: 80, fontWeight: 'bold' }} onClick={() => this.toggleSearchRedNumber()}> {language[this.props.currentLanguage].Search} </Button>
            </div>
            <Button className='TextFontSize' variant="primary" onClick={() => this.toggleAddRedNumberForm()}>
              <b>{language[this.props.currentLanguage].AddVIP}</b>
            </Button>
            <div style={{ fontWeight: 'bold', display: 'flex' }} className="VIPUserWidth" >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div className='MenubarTitle' style={{ margin: '0px 10px'}}>LPX</div>
                <input
                  style={{ width: 25, height: 25}}
                  checked={this.state.showType === 'LPX'}
                  onChange={(e) => this.toggleShowType('LPX')}
                  type="checkbox"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div className='MenubarTitle' style={{ margin: '0px 10px'}}>VIP MIN</div>
                <input
                  style={{ width: 25, height: 25}}
                  checked={this.state.showType === 'All Word'}
                  onChange={(e) => this.toggleShowType('All Word')}
                  type="checkbox"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div className='MenubarTitle' style={{ margin: '0px 10px'}}>{language[this.props.currentLanguage].VIPLIMIT}</div>
                <input
                  style={{ width: 25, height: 25}}
                  checked={this.state.showType === 'All Limit'}
                  onChange={(e) => this.toggleShowType('All Limit')}
                  type="checkbox"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div className='MenubarTitle' style={{ margin: '0px 10px'}}>{`个组每日限额`}</div>
                <input
                  style={{ width: 25, height: 25}}
                  checked={this.state.showType === 'Group Limit'}
                  onChange={(e) => this.toggleShowType('Group Limit')}
                  type="checkbox"
                />
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', marginBottom: 10, padding: 10, border: '1px solid #000', borderRadius: 5, overflowX: 'auto' }} hidden={this.state.hideAddForm ? true : false}>
            <div style={{ borderRight: '1px solid #000', marginRight: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ margin: '0px 10px', fontSize: '20px', width: '100%' }}><b>{language[this.props.currentLanguage].AddRedNumber}</b></div>
              </div>
              <hr/>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10,}}>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'User ID'}</div>
                <Form.Control as="select" className="form-control" style={{ width: 150, marginRight: 10 }} onChange={(e) => this.modeChange(e.target.value, 'VIP_USER')} value={this.state.VIP_USER}>
                  <option value=''> {'-'} </option>
                  {this.state.allUserIDResult.map((item, index) =>(
                    <option key={index} value={item.ID}>{item.Username}</option> 
                  ))}
                </Form.Control>
              </div>
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'Mode'}</div>
                <div style={{ width: 150, marginRight: 10}}>{this.state.showType === 'LPX' ? 'LPX' : this.state.showType === 'All Word' ? 'VIP MIN' : this.state.showType === 'Group Limit' ? 'GROUP LIMIT' :  'VIP LIMIT'}</div>
              </div>
            </div>
            <VIPAddRNPackage showType={this.state.showType} dataChange={this.dataChange} AddVIPData={this.state.AddVIPData} closeForm={this.closeForm} addRedNumberF={this.addRedNumberF} LPXNumber={this.state.LPXNumber}/>
          </div>
          {this.state.VipNumberApiResult &&
            <Table responsive="sm" striped bordered hover>
              <thead style={{ backgroundColor: 'lightsteelblue' }}>
                <tr>
                  <th><b> <div className='MenubarTitle' style={{ width: 80}} >{language[this.props.currentLanguage].usernameLanguage}</div> </b></th>
                  <th className='MenubarTitle' style={{ minWidth: 80 }}><b> {language[this.props.currentLanguage].Limit} </b></th>
                  <th className='MenubarTitle' style={{ minWidth: 80 }}><b> {language[this.props.currentLanguage].DateTime} </b></th>
                  <th className='MenubarTitle' style={{ minWidth: 150 }}><b> {language[this.props.currentLanguage].Action} </b></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.VipNumberApiResult.map((vipNumberItem, index) => {
                    return <tr key={index}>
                      <td className='TextFontSize' style={{ verticalAlign: 'middle'}}>{vipNumberItem.Username}</td>
                      {(vipNumberItem.Mode === 'All Word' || vipNumberItem.Mode === 'All Limit' || vipNumberItem.Mode === 'Group Limit') && <td style={{ verticalAlign: 'middle'}}>
                        <Table style={{ marginBottom: 1 }}>
                          <thead>
                            <tr className='TextFontSize'>
                              {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF', '5D', '6D'].map((item) => {
                                return <td key={item} > {item} </td>
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='TextFontSize'>
                              {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF', '5D', '6D'].map((item) => {
                                return <td key={item} >{ vipNumberItem.Limit.Default[item]}</td>
                              })}
                            </tr>
                            <tr className='TextFontSize'>
                              {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF', '5D', '6D'].map((item) => {
                                return <td key={item} style={{background: '#FF5C5C', color: 'white'}}>{ vipNumberItem.RedLimit.Default[item]}</td>
                              })}
                            </tr>
                          </tbody>
                        </Table>
                      </td>}
                      {(vipNumberItem.LPX !== null) && <td style={{ verticalAlign: 'middle'}}>{vipNumberItem.LPX}</td>}
                      <td style={{ verticalAlign: 'middle'}}>{Moment(vipNumberItem.DateTime).format('DD/MM/YYYY h:mm A')}</td>
                      <td style={{ verticalAlign: 'middle'}}>
                        <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                          <div>
                            <Button variant="primary" className='TextFontSize' style={{ width: '100%', marginRight: 5 }} onClick={() => this.toggleEditRedNumberForm(vipNumberItem)}>{language[this.props.currentLanguage].Edit}</Button>
                          </div>
                          <div>
                            <Button variant="danger" className='TextFontSize' style={{ width:  '100%' }} onClick={() => this.toggleDeletedRedNumberForm(vipNumberItem)}>{language[this.props.currentLanguage].Delete}</Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </Table>
          }
          <div style={{ display: 'flex', marginBottom: 10, padding: 10, border: '1px solid #000', borderRadius: 5, overflowX: 'auto'}} hidden={this.state.hideEditForm ? true : false}>
            <div style={{ borderRight: '1px solid #000', marginRight: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ margin: '0px 10px', fontSize: '20px', width: '100%' }}><b>{language[this.props.currentLanguage].UserDetail}</b></div>
              </div>
              <hr />
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>Package ID</b></div>
                <Form.Control style={{ width: 100, marginRight: 10, color: 'red'}} type="text" value={this.state.editVIPData.ID} readOnly/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>User ID</b></div>
                <Form.Control as="select" className="form-control" style={{ width: 100, marginRight: 10, color: 'red'}} onChange={(e) => this.editVIPUser(e.target.value)} ref={(input) => { this.Vipusers = input; }} value={this.state.editVIPData.UserID}>
                  <option value=''> {'-'} </option>
                  {this.state.allUserIDResult.map((item, index) =>(
                    <option key={index} value={item.ID}>{item.Username}</option> 
                  ))}
                </Form.Control>
              </div>
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{'Mode'}</div>
                <div style={{ width: 150, marginRight: 10}}>{this.state.showType === 'LPX' ? 'LPX' : this.state.showType === 'All Word' ? 'VIP MIN' : this.state.showType === 'Group Limit' ? 'GROUP LIMIT' : 'VIP LIMIT'}</div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
              <Tab.Content>
                <EditVIPPackage showType={this.state.showType} editVIPData={ this.state.editVIPData } dataChange={this.dataChange} closeForm={this.closeForm} editLPX={this.state.editLPX} updateVIPMinF={this.updateVIPMinF} />
              </Tab.Content>
            </div>
          </div>
        </div>
        {(this.state.PopScrollBackButton)&&<div className={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
          ^
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(VIP_Player);

