import '../App.css';
import React from 'react';
import Header from '../Component/Header';
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux'
import { language } from '../language';
// import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
import { Button, Card, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
// import updown from '../Images/updown1.png';
import 'react-datepicker/dist/react-datepicker.css';
import { Platformcolor } from '../static/PlatformColor';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import { saveAs } from "file-saver";
const { getLastDadingObj, getLastDadingTime, updateDadingConfiguration, getDadingObjWithID, dadingIDListFNC, convertExportObjFNC } = require('../Api');
var timer

//
//                       _oo0oo_
//                      o8888888o
//                      88" . "88
//                      (| -_- |)
//                      0\  =  /0
//                    ___/`---'\___
//                  .' \\|     |// '.
//                 / \\|||  :  |||// \
//                / _||||| -:- |||||- \
//               |   | \\\  -  /// |   |
//               | \_|  ''\---/''  |_/ |
//               \  .-\__  '-'  ___/-. /
//             ___'. .'  /--.--\  `. .'___
//          ."" '<  `.___\_<|>_/___.' >' "".
//         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//         \  \ `_.   \_ __\ /__ _/   .-` /  /
//     =====`-.____`.___ \_____/___.-`___.-'=====
//                       `=---='
//
//
//     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//               佛祖保佑         永无BUG

class Dading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      UserID: this.props.userID,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      SelectedDate: new Date(),
      Authority: '',
      Run: false,
      date: new Date(),
      AdvertisementModal: false,
      ImageNews: '',
      background: '#3C96F4',
      backgroundclose: 'white',
      downliadDading: '',
      LastDadingTime: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      getLastDadingTime: {},
      SelectedValuetoAll: 0,
      getKey1: [],
      getKey2: [],
      Platform: [],
      SelectedChangePlat: [],
      Key1Options: ['B', 'S', 'CD', 'CA', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', ''],
      Value1: ['B', 'S', 'CD', 'CA', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF'],
      Value2: ['B', 'S', 'CD', 'CA', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D'],
      is_visible: false,
      ShowUpdate: false,
      SearchTest: '',
      OpenUpdateModal: false,
      TodayDadingArray: [],
      TotalAmountDading: 0,
      TotalAmount101Dading: 0,
      platformShow: [],
      loading: false,
      // BigAmount: false,
      OpenModalUserList: false,
      UserListData: [],
      autodownload: true,
      dading101loading: false,
    }
    this.closeDeleteUserListModal = this.closeDeleteUserListModal.bind(this);
  }

  async componentDidMount() {
    this.PlatformSho();
    // call getLastDadintTime API to call all the data i need.
    this.callgetLastDadingTime();
  }

  async PlatformSho() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
    this.setState({ 
      platformShow: (UserAuthorities.GameBetting.includes('09')) ? ['1', '2', '3', '4','5','6','7','8', '9'] : ['1', '2', '3', '4','5','6','7','8', '9'],
      Platform: (UserAuthorities.GameBetting.includes('09')) ? ['1', '2', '3', '4','5','6','7','8', '9'] : ['1', '2', '3', '4','5','6','7','8', '9']
    })
  }

  async callgetLastDadingTime() {
    const SelectedDate = this.state.SelectedDate
    const LastDadingTime = await getLastDadingTime(this.props.username, Moment(SelectedDate).format('YYYY-MM-DD'), this.props.token);
    console.log('LastDadingTime', LastDadingTime)
    if (LastDadingTime) {
      this.setState({ getLastDadingTime: LastDadingTime.lastDadingLimit, getKey1: LastDadingTime.lastDadingKeyin1, getKey2: LastDadingTime.lastDadingKeyin2, LastDadingTime: LastDadingTime.lastDadingDateTime, TodayDadingArray: LastDadingTime.todayDadingArray }, ()=> {
        this.CalculateTotalDading(this.state.TodayDadingArray);
      })
    }
  }

  CalculateTotalDading(TodayDadingArray) {
    var DadingArray = TodayDadingArray.filter(function (el) {
      return el.Status !== 'Cancelled'
    });
    const tempObject = {
      TotalAmount: 0,
    }
    const tempObject101 = {
      TotalAmount: 0,
    }
    Object.values(DadingArray).forEach((item) => {
      tempObject.TotalAmount += Number(item.Amount)
      tempObject101.TotalAmount += Number(item.Amount101)
    })
    this.setState({ TotalAmountDading:tempObject, TotalAmount101Dading: tempObject101 })
  }

  textChange(e, game, gameKey) {
    let getLastDadingTime = this.state.getLastDadingTime

    getLastDadingTime[game][gameKey] = e.target.value.replace(/[^0-9 ]/g, "")
    this.setState({ getLastDadingTime })
  }

  handleChangeKey(e, Keys, index){
    let getKey1 = this.state.getKey1
    let getKey2 = this.state.getKey2
    if(Keys === 'Key1') {
      getKey1[index] = e.target.value.replace(/[^1-4BSCADE ]/g, "")
    } else {
      getKey2[index] = e.target.value.replace(/[^1-44BSCADE ]/g, "")
    }
    this.setState({ getKey1, getKey2 })
  }

  async exportDading() {
    this.setState({loading: true})
    const Platform = this.state.Platform
    const exportDading = await getLastDadingObj(this.props.username, Platform, this.props.token);
    if(exportDading) {
      this.setState({ downliadDading: exportDading.lastDadingObj }, ()=> {
        if (this.state.autodownload === true) {
          this.exportFile([this.state.downliadDading])
        }
        // this.exportFile([this.state.downliadDading])
        this.callgetLastDadingTime();
        this.setState({loading: false})
      })
    }
  }

  async ClearTime() {
    const ClearCun = await updateDadingConfiguration(this.props.username, '', '', '', this.props.token, true);
    if(ClearCun.error === 'Invalid credentials!') {
      this.openNotification('databaseError')
      this.setState({ notificationType: 'error'});
    } else {
      if (ClearCun.message === 'Update Successful'){
        this.openNotification('SuccessClearTime')
        this.setState({ notificationType: true });
        this.callgetLastDadingTime();
      }
    }
  }

  exportFile(downliadDading) {
    let DisplayData = downliadDading
    var blob = new Blob([DisplayData], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `Dading ${Moment(this.state.getLastDadingTime).format('DD/MM/YYYY')} ${Moment(new Date()).format('HH:mm:ss')}.txt`);
  }

  openNotificationSuccess(message) {
    this.setState({ showNotification: true });
    // this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  ReturnPlatform(game) {
    if (game === '1') return 'M'
    else if (game === '2') return 'K'
    else if (game === '3') return 'T'
    else if (game === '4') return 'S'
    else if (game === '5') return 'W'
    else if (game === '6') return 'B'
    else if (game === '7') return 'D'
    else if (game === '8') return '8'
    else if (game === '9') return '9'
    else return null
  }

  gameChangeDetail(game){
    let Platform = this.state.Platform
    if(this.state.Platform.includes(game)) {
      Platform = Platform.filter(function (el) {
        return el !== game
      });
    } else {
      Platform.push(game)
    }
    this.setState({ Platform })
  }

  async Update() {
    const updateKey1 = this.state.getKey1
    const updateKey2 = this.state.getKey2
    const getLastDadingTime = this.state.getLastDadingTime

    let resultToReturn = updateKey1.some((element, index) => {
      return updateKey1.indexOf(element) !== index
    })
    let resultToReturn2 = updateKey2.some((element, index) => {
      return updateKey2.indexOf(element) !== index
    })

    if(resultToReturn === true) {
      this.openNotification('Key1Duplicated')
      this.setState({ notificationType: 'error'});
    } else if (resultToReturn2 === true) {
      this.openNotification('Key2Duplicated')
      this.setState({ notificationType: 'error'});
    } else {
      const UpdateDatingConfi = await updateDadingConfiguration(this.props.username, updateKey1, updateKey2, getLastDadingTime, this.props.token)
      if(UpdateDatingConfi.error === 'database error') {
        this.openNotification('databaseError')
        this.setState({ notificationType: 'error'});
      } else if (UpdateDatingConfi.error === 'No Update') {
        this.openNotification('NoUpdateDetected')
        this.setState({ notificationType: 'error'});
      } else {
        if (UpdateDatingConfi.message === 'Update Successful') {
          this.openNotification('UpdateSuccess')
          this.setState({ notificationType: 'true' }, ()=> {
            // Refresh Back All the data
            this.callgetLastDadingTime();
          });
          timer = setTimeout(() => {
            this.setState({ ShowUpdate: false, OpenUpdateModal: false, SelectedValuetoAll: 0, SelectedChangePlat: [] });
          }, 1500);
        }
      }
    }
  }

  handleScroll = (event) => {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  async ClickFunctions(id, Selected) {
    if (Selected === 'Delete') {
      const r = (window.confirm(language[this.props.currentLanguage].ConfirmDeletedading));
      if (r === true) {
        const DeleteDading = await getDadingObjWithID(this.props.username, id, this.props.token, true)
        if(DeleteDading) {
          this.openNotification('DeleteSuccessful')
          this.setState({ notificationType: 'true' }, ()=> {
            this.callgetLastDadingTime();
          })
        }
      } else {
        return null;
      }
    } else if (Selected === 'Download') {
      const r = (window.confirm(language[this.props.currentLanguage].ConfirmDownloaddading));
      if (r === true) {
        const DownloadDading = await getDadingObjWithID(this.props.username, id, this.props.token)
        if(DownloadDading) {
          this.openNotification('Success')
          this.setState({ notificationType: 'true' }, ()=> {
            this.exportFile(DownloadDading.ExportText);
            this.callgetLastDadingTime();
          })
        }
      } else {
        return null;
      }
    }
    if (Selected === '101') {
      this.setState({ dading101loading: true })
      const call101 = await convertExportObjFNC(id, this.props.token, this.props.username)
      this.convertToDBF(call101);
    }
  }

  convertToDBF(base64Data) {
    const binaryData = atob(base64Data);
    // Convert binary data to a Uint8Array
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
    // Create a download link for the user
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'TEMP.DBF';
    downloadLink.click();
    this.setState({ dading101loading: false })
  }

  SearchTesting(e, Mode) {
    this.setState({ [Mode]: e.target.value })
  }

  async setSelectedDate(date, mode) {
    this.setState({
      [mode]: date,
    }, ()=> {
      this.callgetLastDadingTime();
    });
  }

  ChangeAllData(game) {
    let SelectedChangePlat = this.state.SelectedChangePlat
    if(this.state.SelectedChangePlat.includes(game)) {
      SelectedChangePlat = SelectedChangePlat.filter(function (el) {
        return el !== game
      });
    } else {
      SelectedChangePlat.push(game)
    }
    this.setState({ SelectedChangePlat })
  }

  ChangeAllDataChange(e) {
    this.setState({ SelectedValuetoAll: e.target.value.replace(/[^0-9 ]/g, "")})
  }

  async onKeyEnter(e) {
    if (e.charCode === 13) {
      document.getElementById("searchButton").click()
    }
  }

  AllChangeValue(allValues) {
    let getLastDadingTime = this.state.getLastDadingTime
    const SelectedChangePlat = this.state.SelectedChangePlat
    SelectedChangePlat.forEach((game) => {
      Object.keys(getLastDadingTime[game]).forEach((keys) => {
        getLastDadingTime[game][keys] = allValues
      })
    })
    this.setState({ getLastDadingTime }, ()=> {
      this.Update();
    })
  }

  closeDeleteUserListModal() {
    this.setState({ OpenModalUserList: false, UserListData: [] })
  }

  async CallUserList() {
    const UserLists = await dadingIDListFNC(this.props.token, this.props.username)
    if (UserLists) {
      this.setState({ UserListData: UserLists }, () => {
        this.setState({ OpenModalUserList: true })
      })
    }
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', backgroundColor: '#EFEFEF', height: '100vh', flexDirection: 'column', overflowY: 'auto'}}>
        <Header />
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left:  250 }}
          show={this.state.showNotification}
          variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div id={'Top'} className="row TitleBettingVersion" style={{ marginLeft: 50, marginRight: 50 }}>
          <div className="dcc jackpotborder" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].Dading}</div>
        </div>
        <div style={{ width: '15%', marginBottom: 15, marginLeft: 50, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ width: '25%'}}>{`${language[this.props.currentLanguage].Date} :`}</div>
          <DatePicker
            onChange={(date) => this.setSelectedDate(date, 'SelectedDate')}
            dateFormat='dd-MMM-yyyy'
            placeholder="Date"
            maxDate={new Date()}
            // minDate={new Date(this.state.MinDate)}
            selected={this.state.SelectedDate}
            value={this.state.SelectedDate}
            className={"form-control StyleDatePicker"}
            // withPortal
          />
        </div>
        <div className='dcc'>
          <div className="dcc" style={{ width: '95%', marginBottom: 20, flexDirection:  'row' }}>
            {this.props.Role !== 'player' && 
            <>
              <div className='button2' onClick={()=> this.setState({ ShowUpdate: !this.state.ShowUpdate, OpenUpdateModal: true }, ()=> { this.callgetLastDadingTime(); })}
                style={{ cursor: 'pointer', padding: 5, border: `1px solid black`, paddingLeft: '1.5%', paddingRight: '1.5%', borderRadius: 6, fontWeight: 'bold' }}>
                {language[this.props.currentLanguage].Update}
              </div>
              <div className='dcc' style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ fontWeight: 'bold', marginRight: 20 }}>{language[this.props.currentLanguage].DownloadDading} {language[this.props.currentLanguage].Platform} : </div>
                <div className='dcc row' style={{ width: 'auto'}}>
                  {this.state.platformShow && this.state.platformShow.map((platforms, indexs) => {
                    return(
                      <Form.Check key={indexs}
                        style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                        inline
                        label={this.ReturnPlatform(platforms)}
                        type="checkbox"
                        checked={this.state.Platform.includes(platforms)}
                        onChange={(e) => this.gameChangeDetail(platforms, e.target.value)}
                      />
                    );
                  })}
                </div>
              </div>
              <div className='button2' onClick={()=> this.CallUserList()}
                style={{ cursor: 'pointer', padding: 5, border: `1px solid black`, paddingLeft: '1.5%', paddingRight: '1.5%', borderRadius: 6, fontWeight: 'bold' }}>
                {'UserList'}
              </div>
            </>}
            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div>Key: </div>
              <div>
                <Form.Control
                  value={this.state.SearchTest}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#303030',
                  }}
                  type="text"
                  onChange={(e) => this.SearchTesting(e, 'SearchTest')}
                />
              </div>
            </div> */}
          </div>
        </div>
        {/* <div style={{ fontSize: '18px', marginRight: 10, fontWeight: 'bold', position: 'absolute', left: 50 }}>{`ID: ${this.props.username}`}</div> */}
        <div className="dcc" style={{ width: 'calc(100% / 1)', marginBottom: 30 }}>
          <Card style={{ width: '80%' }}>
            <Card.Header className='dcc' style={{ flexDirection: 'row' }}>
              <div className='dcc' style={{ flexDirection: 'row' }}>
                <Button className='button2' 
                  style={{ textAlign: 'center', backgroundColor: 'red', color: 'white', width: 100, fontWeight: 'bold', position: 'absolute', left: 5 }}
                  variant="danger" onClick={() => this.ClearTime()}>清存
                </Button>
                <b style={{ color: 'red', marginRight: 10 }}>{language[this.props.currentLanguage].DadingHistory}</b>
                {/* <div style={{ display: 'flex', flexDirection: 'row'}}><div>自動下載</div></div> */}
                {this.state.loading ? <>
                  <img style={{ width: 50, height: 50}} 
                    src={'https://i.imgur.com/GLdqYB2.gif'}
                    alt=""/>
                </> : <>
                  <div style={{ position: 'absolute', right: 100, width: 250 }}>
                    <Form.Check
                      style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                      inline
                      label={`${language[this.props.currentLanguage].autodownload}`}
                      type="checkbox"
                      checked={this.state.autodownload}
                      onChange={() => this.setState({ autodownload: !this.state.autodownload})}
                    />
                  </div>
                  <Button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold', position: 'absolute', right: 5 }}
                    onClick={() => this.exportDading()}
                    className="btn my-cusomized-button button2">
                    {`${language[this.props.currentLanguage].DownloadDading}`}
                  </Button></>}
              </div>
            </Card.Header>
            <div style={{ position: 'absolute', left: 10, top: 50, fontWeight: 'bold' }}>{`ID : ${this.props.username}`}</div>
            {/* <div style={{ position: 'absolute', left: 5, top: 50, fontWeight: 'bold' }}>{`自動下載`}</div> */}
            <div style={{ position: 'absolute', right: 10, top: 50, fontWeight: 'bold' }}>{Moment(this.state.LastDadingTime).format('DD-MM-YYYY hh:mm:ss')}</div>
            <Card.Body className="dcc" style={{ width: '100%', flexWrap: 'wrap', overflowX: 'auto', flexDirection: 'column'}}>
              {/* {this.state.ShowUpdate === true && 
              <>
                <div style={{ flexDirection: 'column', display: 'flex', marginBottom: (isMobile) ? 10 : 10, width: (isMobile) ? '100%' : '60%' }}>
                  <div className='dcc' style={{ flexDirection: (isMobile) ? 'column' : 'row', width: (isMobile) ? '100%' : 'auto', paddingTop: (isMobile) ? 10 : 0}}>
                    <div style={{ fontWeight: 'bold', width: (isMobile) ? 'auto' : '10%' }}>Key1 : </div>
                    <div style={{ display: 'flex' }}>
                      {this.state.getKey1 && this.state.getKey1.map((keys1, keysindex) => {
                        return(
                        // <Form.Control key={keysindex}
                        //   value={keys1}
                        //   style={{
                        //     width: (isMobile) ? '100%' : '10%',
                        //     textAlign: 'center',
                        //     fontWeight: 'bold',
                        //     color: '#303030',
                        //   }}
                        //   type="text"
                        //   onChange={(e) => this.handleChangeKey(e, 'Key1', keysindex)}
                        // />
                          <select className="form-control"
                            style={{ textAlign: 'center', fontSize: '18px', width: (isMobile) ? '100%' : '20%', fontWeight: 'bold' }}
                            onChange={(e) => this.handleChangeKey(e, 'Key1', keysindex)}>
                            <option value={keys1}>{`${keys1}`}</option>
                            {(this.state.Key1Options || []).map((item, idx) => {
                              return (
                                <option index={idx} value={item}>{`${item}`}</option>
                              );
                            })}
                          </select>
                        );
                      })}
                    </div>
                  </div>
                  <div className='dcc' style={{ flexDirection: (isMobile) ? 'column' : 'row', width: (isMobile) ? '100%' : 'auto', paddingTop: (isMobile) ? 10 : 0 }}>
                    <div style={{ fontWeight: 'bold', width: (isMobile) ? 'auto' : '10%' }}>Key2 : </div>
                    <div style={{ display: 'flex' }}>
                      {this.state.getKey2 && this.state.getKey2.map((keys2, keys2index) => {
                        return(
                        // <Form.Control key={keys2index}
                        //   value={keys2}
                        //   style={{
                        //     width: (isMobile) ? '100%' : '10%',
                        //     textAlign: 'center',
                        //     fontWeight: 'bold',
                        //     color: '#303030',
                        //   }}
                        //   type="text"
                        //   onChange={(e) => this.handleChangeKey(e, 'Key2', keys2index)}
                        // />
                          <select className="form-control"
                            style={{ textAlign: 'center', fontSize: '18px', width: (isMobile) ? '100%' : '20%', fontWeight: 'bold' }}
                            onChange={(e) => this.handleChangeKey(e, 'Key2', keys2index)}>
                            <option value={keys2}>{`${keys2}`}</option>
                            {(this.state.Key1Options || []).map((item, idx) => {
                              return (
                                <option index={idx} value={item}>{`${item}`}</option>
                              );
                            })}
                          </select>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {['1', '2', '3', '4', '5', '6', '7', '8'].map((game, index) => {
                  return(
                    <div key={index}
                      style={{ marginBottom: 25, display: 'flex', alignItems: 'center', width: '1050px', paddingTop: 10, borderRadius: 10, color: 'black', backgroundColor: Platformcolor(game, '') }}>
                      <div style={{ margin: '0px 10px', width: 70, fontSize: 20}}><b>{this.ReturnPlatform(game)}</b></div>
                      {(game === '3' || game === '8' ? this.state.Value2 : this.state.Value1).map((itemkey) => {
                        return <div key={itemkey} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                          <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{itemkey}</b></div>
                          <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='5' type="text" value={(JSON.stringify(this.state.getLastDadingTime) === '{}') ? '' : this.state.getLastDadingTime[game][itemkey]} onChange={(e) => this.textChange(e, game, itemkey)}/>
                        </div>
                      })}
                    </div>
                  );
                })}
                <div style={{ width: '50%', marginBottom: 10}}>
                  <div className="dcc button"
                    style={{ backgroundColor: this.state.background, fontSize: (isMobile) ? 16 : 22,
                      borderBottom: '1px solid #cfd9de', verticalAlign: 'middle', fontWeight: 'bold', padding: 13, cursor: 'pointer', borderRadius: 5}} 
                    onMouseEnter={() => {
                      this.setState({ background: '#00AFE2' });
                    }}
                    onMouseLeave={() => {
                      this.setState({ background: '#3C96F4' });
                    }}
                    variant="success" onClick={() => this.Update()}>
                    <span>
                   Update
                    </span>
                  </div>
                </div>
              </>
              } */}
              <div style={{ width: '90%', marginTop: 10}}>
                <Table responsive="sm" bordered hover>
                  <thead style={{ backgroundColor: 'lightsteelblue' }}>
                    <tr>
                      <th style={{ minWidth: null }}>{'id'}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].Actionby}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].ForwardOn}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].ForwardType}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].Status}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].Numbers}</th>
                      <th style={{ minWidth: null }}>101 {language[this.props.currentLanguage].Numbers}</th>
                      <th style={{ minWidth: null }}>{'下传'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.TodayDadingArray.length > 0 ? (
                      <>
                        {(this.state.TodayDadingArray && this.state.TodayDadingArray).map((items, index) => {
                          return(
                            <tr key={index} style={{ fontWeight: 'bold'}}>
                              <td style={{ cursor: 'not-allowed'}}>{items.ID}</td>
                              <td style={{ cursor: 'not-allowed'}}>{(items.Status === 'Cancelled') ? items.DeleteAdminName : items.AdminName}</td>
                              <td style={{ cursor: 'not-allowed'}}>{Moment(items.Datetime).format('YYYY-MM-DD HH:mm:ss')}</td>
                              <td style={{ cursor: 'not-allowed'}}>{(items.Platform === '["1", "2", "3", "4", "5", "6", "7", "8", "9"]') ? `${language[this.props.currentLanguage].AllDading}` : items.Platform}</td>
                              <td style={{ cursor: 'not-allowed', color: (items.Status === 'Cancelled') ? 'red' : 'black'}}>{items.Status}</td>
                              <td style={{ cursor: 'not-allowed'}}>{(items.Amount).toFixed(2)}</td>
                              <td style={{ cursor: 'not-allowed'}}>{(items.Amount101).toFixed(2)}</td>
                              <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                <div onClick={()=> this.ClickFunctions(items.ID, 'Download')} style={{ cursor: 'pointer' }}>{language[this.props.currentLanguage].Download}</div>
                                <div onClick={()=> this.ClickFunctions(items.ID, '101')} style={{ cursor: 'pointer', color: 'black' }}>{(this.state.dading101loading) ? 'loading...' : `101`}</div>
                                <Link 
                                  to={`/DadingShowAll?id=${items.ID}&DadingDate=${this.state.SelectedDate}&Type=${'Dading'}`}
                                  target="_blank" rel="noopener noreferrer">
                                  {'统计'}
                                </Link>
                                <div onClick={()=> this.ClickFunctions(items.ID, 'Delete')} style={{ cursor: 'pointer', color: 'red' }}>{language[this.props.currentLanguage].Delete}</div>
                              </td>
                            </tr>
                          );
                        })}
                        <tr style={{ backgroundColor: 'lightgray'}}>
                          <td colSpan="5" style={{ cursor: 'not-allowed', fontWeight: 'bold' }}>{`${language[this.props.currentLanguage].Total} :`}</td>
                          <td style={{ cursor: 'not-allowed', fontWeight: 'bold' }}>{`${Number(this.state.TotalAmountDading.TotalAmount).toFixed(2)}`}</td>
                          <td style={{ cursor: 'not-allowed', fontWeight: 'bold' }}>{`${Number(this.state.TotalAmount101Dading.TotalAmount).toFixed(2)}`}</td>
                          <td style={{ cursor: 'not-allowed', fontWeight: 'bold' }}>
                            <Link 
                              to={`/DadingShowAll?id=${'all'}&DadingDate=${this.state.SelectedDate}&Type=${'Dading'}`}
                              target="_blank" rel="noopener noreferrer">
                              {'总合统计'}
                            </Link>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan="7" style={{ cursor: 'not-allowed', fontWeight: 'bold' }}>{'No Data'}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
        {this.state.is_visible && 
            <div className="dcc" style={{ paddingBottom: 30 }}>
              <div class="scrollDesign iconpopup button2"  
                onClick={() => this.scrollToTop()}>
                {/* ↑ ⇈ */}
                <div  className="icon MoveUpArrow">⇧</div>
              </div>
            </div>
        }
        <Modal centered show={this.state.OpenModalUserList} onHide={this.closeDeleteUserListModal}>
          <Modal.Header closeButton>
            <Modal.Title>检查运行着的单</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.UserListData.length === 0 && 
            <div>No UserList</div>
            }
            {this.state.UserListData.length > 0 && 
            <div style={{ maxHeight: '75vh',  overflowY: 'scroll' }}>
              {this.state.UserListData.map((items, idddx) => {
                return(
                  <div>{`${idddx+1}: ${items.ID}`}</div>
                );
              })}
            </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.closeDeleteUserListModal()}>关闭</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          // style={{ width: '100%'}}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.OpenUpdateModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 0, height: 'auto', width: 'auto'}}>
            <div style={{ width: '100%' }}>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top: 15, cursor: 'pointer', backgroundColor: this.state.backgroundclose, borderRadius: 20 }}
                onClick={() => {
                  this.setState({ OpenUpdateModal: false, SelectedChangePlat: [] })
                }}
                onMouseEnter={() => {
                  this.setState({ backgroundclose: 'white' });
                }}
                onMouseLeave={() => {
                  this.setState({ backgroundclose: '#cfd9de' });
                }}
                size="35px"
                color="#000"
              />
              <Form.Group className="column">
                <div className={'dcc'} style={{ width: '100%', flexDirection: 'column', overflowX: 'auto'}}>
                  <div style={{ flexDirection: 'column', display: 'flex', marginBottom:  10, width: '60%', marginTop: 50 }}>
                    <div className={'dcc'} style={{ flexDirection: 'row', width: 'auto', paddingTop: 0}}>
                      <div style={{ fontWeight: 'bold', width: '10%' }}>Key1 : </div>
                      <div style={{ display: 'flex' }}>
                        {this.state.getKey1 && this.state.getKey1.map((keys1, keysindex) => {
                          return(
                            <select className="form-control" key={keysindex}
                              style={{ textAlign: 'center', fontSize: '17px', width: '20%', fontWeight: 'bold' }}
                              onChange={(e) => this.handleChangeKey(e, 'Key1', keysindex)}>
                              <option value={keys1}>{`${keys1}`}</option>
                              {(this.state.Key1Options || []).map((item, idx) => {
                                return (
                                  <option key={idx} value={item}>{`${item}`}</option>
                                );
                              })}
                            </select>
                          );
                        })}
                      </div>
                    </div>
                    <div className={'dcc'} style={{ flexDirection: 'row', width: 'auto', paddingTop: 0 }}>
                      <div style={{ fontWeight: 'bold', width: '10%' }}>Key2 : </div>
                      <div style={{ display: 'flex' }}>
                        {this.state.getKey2 && this.state.getKey2.map((keys2, keys2index) => {
                          return(
                            <select className="form-control" key={keys2index}
                              style={{ textAlign: 'center', fontSize: '17px', width: '20%', fontWeight: 'bold' }}
                              onChange={(e) => this.handleChangeKey(e, 'Key2', keys2index)}>
                              <option value={keys2}>{`${keys2}`}</option>
                              {(this.state.Key1Options || []).map((item, idx) => {
                                return (
                                  <option key={idx} value={item}>{`${item}`}</option>
                                );
                              })}
                            </select>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} 
                    show={this.state.showNotification}
                    variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                    <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                  </Alert>
                  {this.state.SelectedChangePlat.length > 0 && 
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                      <Form.Control style={{ width: 70, textAlign: 'center', marginRight: 5 }} maxLength='5' type="text" value={this.state.SelectedValuetoAll} onKeyPress={(e) => this.onKeyEnter(e)} onChange={(e)=> this.ChangeAllDataChange(e)} />
                      <div><button id="searchButton" className="button2" style={{ borderRadius: 5 }} onClick={() => this.AllChangeValue(this.state.SelectedValuetoAll)}>{'确定'}</button></div>
                    </div>
                  }
                  {this.state.platformShow.map((game, index) => {
                    return(
                      <div key={index}
                        style={{ marginBottom: 25, display: 'flex', alignItems: 'center', width: '1050px', paddingTop: 10, borderRadius: 10, color: 'black', backgroundColor: Platformcolor(game, '') }}>
                        <div style={{ margin: '0px 10px', width: 60, fontSize: 18 }}><b>{this.ReturnPlatform(game)}</b></div>
                        <div style={{ margin: '0px 10px', width: 40, fontSize: 18 }}>
                          <Form.Check
                            style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                            inline
                            type="checkbox"
                            checked={this.state.SelectedChangePlat.includes(game)}
                            onChange={(e) => this.ChangeAllData(game)}
                          />
                        </div>
                        {(game === '3' || game === '8' || game === '9' ? this.state.Value2 : this.state.Value1).map((itemkey, inddd) => {
                          return <div key={inddd} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{itemkey}</b></div>
                            <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='5' type="text" value={Object.keys(this.state.getLastDadingTime).length === 0 ? '' : this.state.getLastDadingTime[game][itemkey]} onChange={(e) => this.textChange(e, game, itemkey)}/>
                          </div>
                        })}
                      </div>
                    );
                  })}
                  <div style={{ width: '50%', marginBottom: 10}}>
                    <div className="dcc button"
                      style={{ backgroundColor: this.state.background, fontSize: 20,
                        borderBottom: '1px solid #cfd9de', verticalAlign: 'middle', fontWeight: 'bold', padding: 13, cursor: 'pointer', borderRadius: 5}} 
                      onMouseEnter={() => {
                        this.setState({ background: '#00AFE2' });
                      }}
                      onMouseLeave={() => {
                        this.setState({ background: '#3C96F4' });
                      }}
                      variant="success" onClick={() => this.Update()}>
                      <span>
                        {language[this.props.currentLanguage].Update}
                      </span>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
// export default Home;

function mapStateToProps(state) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dading);
