import '../App.css';
import React from 'react';
// import Footer from '../Component/Footer';
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import { Form, Button } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
import Header from '../Component/Header';
// import { isMobile } from 'react-device-detect';
import {  Alert } from 'react-bootstrap';
import {ReturnBetValidation} from '../static/ReturnBetValidation';
import { CreateBetOrder, verifyUserOrderIDStatus, getUserKeyIn, getMyselfF, searchDownlineHR } from '../Api';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import { language } from '../language';
import Loading3 from '../Images/loading-3.gif';
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
// import Select from 'react-select';
var timer
var timer2
var rechecktimer
// import { language } from '../language';
// import { findPermutations } from '../utility/Permutation'
// import { isCompositeComponent } from 'react-dom/test-utils';
// import Dropzone from 'react-dropzone';

class NewBet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      FileName: '',
      FileResultWord: '',
      FinalResult: [],
      hightlight: false,
      disabled: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      SelectedUser: '',
      optionsUsername: '',
      getUsers: [],
      userID: this.props.userID,
      loading: false,
      ShowReceipt: false,
      ReceiptDisplay: '',
      Balance: 0,
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: '',
      // files: [],
      // Base64: '',
    }
    this.UploadFiletxt = this.UploadFiletxt.bind(this);
    this.ConfirmSaveBet = this.ConfirmSaveBet.bind(this);
    this.CleanData = this.CleanData.bind(this);
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.SelectedUser = this.SelectedUser.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ userID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID}, () => {
      if ((UserAuthorities && UserAuthorities.Bet) === 0) {
        window.location.href='/Home'; 
      }
      this.getDownline();
    })
  }

  async getDownline() {
    const Username = this.props.username;
    const token = this.props.token

    // const DownlineUsername = await getMyDownline(UserID, token, Username)
    const getMyself = await getMyselfF(this.props.userID, token, Username)
    if(getMyself) {
      this.setState({ userID: getMyself[0].ID, SelectedUser: {value: getMyself[0].ID, label: getMyself[0].Username}, SearchUser: getMyself[0].Username }, () => {
        this.SelectedUser(this.state.SelectedUser)
      })
    }
  }

  async SelectedUser(e) {
    const GetCash = await getUserKeyIn(e.value, this.props.token, this.props.username)
    this.setState({ userID: e.value, SelectedUser: e, Balance: GetCash[0].CashWallet, SearchUser: e.label  })
    this.handleCloseSearchModal();
  }

  async UploadFiletxt(event) {
    if (event !== undefined) {
      const files = event.target.files;
      event.preventDefault()
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.setState({ FileResultWord: text })
        // this.setState({ FileResultWord: text }, ()=> {
        //   const Array = this.state.FileResultWord.split('\n')
        //   let Arraynew = []
        //   for(var i=0; i < Array.length; i++) {
        //     const RemoveR = Array[i].replace("\r", "").replace("\r", "")
        //     Arraynew.push(RemoveR)
        //   }
        //   this.setState({ FileResultWord: Arraynew.join('\n') })
        // })
      };
      if (event.target.files) {
        reader.readAsText(event.target.files[0])
        this.setState({ FileName: files[0].name })
      } 
    }
  }

  async recheckBet(UserID, UserOrderID) {
    rechecktimer = setInterval(async () => { 
      const recheckresult = await verifyUserOrderIDStatus(UserID, UserOrderID, this.props.token, this.props.username)
      if (recheckresult.message === 'Loading') {
        // this.openNotification('Success')
      } else {
        clearInterval(rechecktimer);
        this.openNotification('Success')
        this.setState({ loading: false })
      }
    }, 5000);
  }
  async ConfirmSaveBet() {
    this.setState({loading: true});
    const UserID = this.state.userID
    let originalText = this.state.FileResultWord;
    const Array = this.state.FileResultWord.split('\n')
    let Arraynew = []

    for(var i=0; i < Array.length; i++) {
      const RemoveR = Array[i].replace("\r", "").replace("\r", "")
      Arraynew.push(RemoveR)
    }
    originalText = Arraynew.join('\n')

    this.setState({ disabled: true}, async()=>{
      if((originalText).length === 0){
        this.setState({ notificationType: 'error', loading: false }, ()=>{
          this.openNotification('Cannotemptytext')
        })
      } else if (/[a-zA-CE-Z!@$%^&()_‘+=\]{};':"\\|,<>?]+/.test(originalText)) {
        this.openNotification('FormatError')
        this.setState({ notificationType: 'error', loading: false });
      } else if (/[\u3400-\u9FBF]+/.test(originalText)) {
        this.openNotification('NotAllowChinese')
        this.setState({ notificationType: 'error', loading: false });
      } else {
        const OrderResult = await CreateBetOrder(UserID, originalText , this.props.token, this.props.username)
        if(OrderResult.error) {
          this.openNotification(ReturnBetValidation(OrderResult.error), (OrderResult.balance !== undefined) ? OrderResult.balance : '')
          this.setState({ notificationType: 'error', loading: false });
        } else {
          clearTimeout(timer2)
          timer2 = setTimeout(() => {
            this.SelectedUser(this.state.SelectedUser)
          }, 1000);
          // this.SelectedUser(this.state.SelectedUser)
          this.setState({ loading: OrderResult.finished ? false : true, ReceiptDisplay: OrderResult.receipt, ShowReceipt: true }, ()=> {
            this.ConvertResultRed(this.state.ReceiptDisplay)
            if (!OrderResult.finished) {
              this.recheckBet(OrderResult.UserID, OrderResult.UserOrderID)
            } else {
              this.openNotification('Success')
            }
          })
          this.CleanData()
        }
      }
    })
  }

  async ConvertResultRed(Receipt) {
    let Word = Receipt
    let returnWord = [];

    if (Word.includes('<red>')) {
      const WordArray = Word.split('<red>').join('</red>').split('</red>');
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{color: 'red'}}>
              {WordArray[i]}
            </span>,
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({ReceiptDisplay: returnWord});
    } else {
      this.setState({ReceiptDisplay: Word});
    }
  }

  async CleanData() {
    this.setState({ FileName: '', FileResultWord: '' }, () => {
      this.UploadFiletxt()
    })
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.state.disabled) return;
  
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    if (event !== undefined) {
      const files = event.dataTransfer.files;
      event.preventDefault()
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.setState({ FileResultWord: text })
      };
      if (event.dataTransfer.files) {
        reader.readAsText(event.dataTransfer.files[0])
        this.setState({ FileName: files[0].name,hightlight: false })
      }
    }
  }
  
  openFileDialog() {
    if (this.state.disabled) return
    this.fileInputRef.current.click()
  }

  openNotification(message, NotEnough) {
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]} ${(NotEnough !== undefined && NotEnough !== '') ? `${language[this.props.currentLanguage].LeftCashWallet}:${NotEnough || ''}` : ``}` }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        disabled: false,
      });
    }, 5000);
  };

  ChangeReceipt(event) {
    const PressNumber = event.target.value.replace(/[+]/g, '#')
    this.setState({ FileResultWord: PressNumber })
  }

  // onDrop2 = (files) => {
  //   this.setState({files}, ()=> {
  //     if(this.state.files.length > 0) {
  //       this.getBase642(this.state.files[0])
  //     }
  //   })
  // }

  // getBase642(files){
  //   var reader = new FileReader();
  //   reader.readAsDataURL(files);
  //   reader.onload = () => {
  //     this.setState({ Base64: reader.result})
  //   };
  //   reader.onerror = function (error) {
  //   };
  // }

  // changeHandler = (event) => {
  //   this.getBase642(event.target.files[0])
  // }

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.SearchUser !== '') {
      const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.props.userID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification('InvalidDownlineIDorUsername')
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID : [],
    })
  }

  render() {
    return (
      <div className='StartBackground'>
        <Header />
        <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="row" style={{ marginBottom: 0, display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '20px' }}>
          <div className="dcc jackpotborder" style={{ justifyContent: 'space-between', width: '100%'}}>
            <div style={{width: '33.3%'}}></div>
            <div style={{width: '33.3%'}} className='dcc'><FaHome size="25px" color="white" className="logohome" style={{ marginRight: 10 }} /> {`${language[this.props.currentLanguage].Betting} (${this.state.SelectedUser.label})`}</div>
            <div style={{ width: '33.3%', textAlign: 'right', paddingRight: 10, fontWeight: 'bold' }}>{`${language[this.props.currentLanguage].Balance} : $ ${this.state.Balance}`}</div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
            width: '95%',
            marginBottom: 0,
          }}>
          <div className="TitleDate">{language[this.props.currentLanguage].Date}: {this.state.TodayDate}</div>
          {/* {isMobile && 
            <div className="TitleDate">{language[this.props.currentLanguage].Balance}: {`$ ${this.state.Balance}`}</div>
          } */}
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.state.username} </div>
          </div>
        </div>
        {/* <div className="dcc TextFontSize" style={{ marginBottom: 10 }}> 
          <div style={{ width: (isMobile) ? '80%' :'35%'}}>
            <Select
              isSearchable={this.state.optionsUsername.length === 0 ? false : true}
              options={this.state.optionsUsername}
              value={this.state.SelectedUser}
              onChange={(e) => this.SelectedUser(e)}
            >
            </Select>
          </div>
        </div> */}
        <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
          <div style={{ margin: '0px 10px',  fontWeight: 'bold'}}>{language[this.props.currentLanguage].SearchAccount}</div>
          <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
          <Button  className='button2' variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
          <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getDownline()}>{'Self'}</Button>
        </div>
        <div className="dcc" style={{ flexDirection: 'row' }}>
          {this.state.loading ? (
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div>
                <img src={Loading3} alt="Logo" className="loadingimages" style={{ marginBottom: 30, marginTop: 50 }} />
                <div className="loadingMessage">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
              </div>
            </div>
          ) : (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ backgroundColor: '#EFEFEF', border: `1px solid #d8d8d8`, width: '65%', marginBottom: 50 }}>
                <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 15 }}>
                  <textarea
                    className="form-control"
                    style={{ textAlign: 'center', width: '95%', height: 350, fontWeight: 'bold' }}
                    value={this.state.FileResultWord}
                    readOnly={false}
                    onChange={(e) => this.ChangeReceipt(e)}
                  />
                </div>
                <div 
                  // type='file'
                  className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
                  onDragOver={this.onDragOver}
                  onDragLeave={this.onDragLeave}
                  onDrop={this.onDrop}
                  onClick={this.openFileDialog}
                  style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                >
                  <span>Upload Files</span>
                </div>
                <input
                  key={this.state.FileName}
                  onChange={(event) => {
                    this.UploadFiletxt(event)
                  }}
                  onClick={(event) => event.target.value = ''}
                  multiple={false}
                  ref={this.fileInputRef}
                  type='file'
                  hidden
                />
                <div style={{ marginBottom: 20, fontWeight: 'bold' }}>{language[this.props.currentLanguage].FileName} : {this.state.FileName || ''}</div>
                <div style={{ width: '100%', paddingBottom: 40 }}>
                  <button style={{ textAlign: 'center', backgroundColor: '#EE3E13', color: 'white', width: 200, fontWeight: 'bold', marginRight: 20 }}
                    disabled={this.state.disabled}
                    onClick={() => this.CleanData()}
                    className="btn my-cusomized-button button2 MenubarTitle">
                    {language[this.props.currentLanguage].ClearAll}
                  </button>
                  <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold' }}
                    disabled={this.state.disabled}
                    onClick={() => this.ConfirmSaveBet()}
                    className="btn my-cusomized-button button2 MenubarTitle">
                    {language[this.props.currentLanguage].ConfirmTicket}
                  </button>
                </div>
              </div>
              {/* <div>
                <Dropzone onDrop={this.onDrop2}>
                  {({getRootProps, getInputProps}) => (
                    <section className="container">
                      <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                      <aside>
                        <h4>Files</h4>
                        <ul>{files}</ul>
                      </aside>
                    </section>
                  )}
                </Dropzone>
              </div>
              <input type="file" name="file" onChange={this.changeHandler} /> */}
              {this.state.ShowReceipt === true && 
              <div className="column" style={{ backgroundColor: 'white', width: '20%', borderColor: 'black', borderRadius: 5, marginLeft: 10 }}>
                <div className="dcc row" style={{ marginBottom: 30, fontWeight: 'bold', fontSize: '20px' }}>
                  <div>{`${(this.state.SuccessFullCancel === true) ? 
                    language[this.props.currentLanguage].CancelSuccessfulldisplay
                    : language[this.props.currentLanguage].SuccessOrderBet }`}</div>
                  <div>
                    <GrFormClose
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ ShowReceipt: false, ReceiptDisplay: '' })
                      }}
                      size="35px"
                      color="#000"
                    />
                  </div>
                </div>
                <div className="column" style={{ marginBottom: 30, fontSize: '16px', whiteSpace: 'pre-wrap', fontWeight: 'bold', textAlign: 'start', marginLeft: 10 }}>
                  {this.state.ReceiptDisplay}
                </div>
                {/* <button style={{ backgroundColor: '#3C96F4', color: 'white', width: 'auto', fontWeight: 'bold', marginBottom: 30 }}
                  onClick={() => this.CancelBetAPI()}
                  className="btn my-cusomized-button" >
                  {language[this.props.currentLanguage].CancelTicket}
                </button> */}
              </div>
              }
            </div>
          )}
          {/* <div>
          <div 
            type='file'
           className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
           onDragOver={this.onDragOver}
           onDragLeave={this.onDragLeave}
           onDrop={this.onDrop}
           onClick={this.openFileDialog}
           style={{ cursor: this.state.disabled ? "default" : "pointer" }}
           >
          <span>Upload Files</span>
          </div>
            <button
              onClick={() => this.fileInputRef.current.click()}
              style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '95%', fontWeight: 'bold', height: 50 }}
            >
              {'UPLOAD FILE'}
            </button>
            <input
              key={this.state.FileName}
              onChange={(event) => {
                this.UploadFiletxt(event)
              }}
              multiple={false}
              ref={this.fileInputRef}
              type='file'
              hidden
            />
          </div> */}
        
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBet);
