export function PrizeConverter(Prize) {
  if (Prize === 'P1') return '1ST'
  else if (Prize === 'P2') return '2ND'
  else if (Prize === 'P3') return '3RD'
  else if (Prize === '1') return '4th'
  else if (Prize === '2') return '4th'
  else if (Prize === '3') return '4th'
  else if (Prize === '4') return '4th'
  else if (Prize === '5') return '4th'
  else if (Prize === '6') return '4th'
  else if (Prize === '7') return '4th'
  else if (Prize === '8') return '4th'
  else if (Prize === '9') return '4th'
  else if (Prize === '10') return '4th'
  else if (Prize === '11') return '4th'
  else if (Prize === '12') return '4th'
  else if (Prize === '13') return '4th'
  else if (Prize === '14') return '5th'
  else if (Prize === '15') return '5th'
  else if (Prize === '16') return '5th'
  else if (Prize === '17') return '5th'
  else if (Prize === '18') return '5th'
  else if (Prize === '19') return '5th'
  else if (Prize === '20') return '5th'
  else if (Prize === '21') return '5th'
  else if (Prize === '22') return '5th'
  else if (Prize === '23') return '5th'
  return Prize
}