import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Button, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import HRPackage from '../Component/HRPackage';
import { language } from '../language';
import { Alert, Spinner } from 'react-bootstrap'
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import tickicons from '../Images/tick.png';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Platformcolor } from '../static/PlatformColor';
var timer
const { getPackageNames, searchPackage, reloadEatWord } = require('../Api');


class EWPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Games: ['Default'],
      OriginalGames: ['Default'],
      Upperlines: [],
      EwPackage: {Default: { all: '', B: '', S: '', A1: '', A1C: '', A: '', C: '', A2: '', A3: '', D4: '', E4: '', "5D": '', "6D": '' }},
      OriEwPackage: {Default: { all: '', B: '', S: '', A1: '', A1C: '', A: '', C: '', A2: '', A3: '', D4: '', E4: '', "5D": '', "6D": '' }},
      modalShow: false,
      packageNames: [],
      activated: true,
      GamePlatform: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      GamePlatform2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'],
      GDisplay: '',
      SearchValue: '',
      OriginalpackageNames: [],
      BackPackageNames: false,
      modalShowDetail: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      OptionsPackage: [],
      SelectedPackage:[],
      SetDetailValue: {Odd:{}, Commission:{}},
      Authority: {},
      loading: false,
    }
    this.toggleSetEditForm = this.toggleSetEditForm.bind(this);
  }
  

  async componentDidMount() {
    this.APIRefresh();
    this.CheckPlatform9();
  }


  async componentDidUpdate(prevProps) {
    if (
      this.props.OwnerUserID !== prevProps.OwnerUserID  &&
     this.props.OwnerUserID 
    ) {
      this.APIRefresh();
    }
  }

  CheckPlatform9() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if (UserAuthorities.GameBetting.includes('9')) {
      let GamePlatform =  ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9']
      let GamePlatform2 =  ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9']
      this.setState({ GamePlatform, GamePlatform2 })
    }
  }

  async APIRefresh(){
    this.SetUsersAuthorities();
    const GDisplay = localStorage.getItem('GameDisplay');
    let packageNames = await getPackageNames(this.props.username, this.props.targetUserID, this.props.token)
    
    packageNames = packageNames.sort(function(a,b){
      if ((a.childPackage === null) === (b.childPackage === null)) {
        return a.Name.localeCompare(b.Name);
      }
      return (a.childPackage === null) - (b.childPackage === null)
    })
    let NewArray = []
    packageNames.forEach((item)=>{
      const filterPackage = {
        value: item.Name, label: item.Name
      }
      NewArray.push(filterPackage)
    })
    this.setState({packageNames, OriginalpackageNames: packageNames,  GDisplay, OptionsPackage: NewArray})
    if (this.props.EwPackage) {
      const ewJSON = typeof this.props.EwPackage === 'string' ? JSON.parse(this.props.EwPackage) : this.props.EwPackage
      const ewKeysArray = Object.keys(ewJSON)
      const defaultArray = ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const arrangedArray = ewKeysArray.sort((a,b) => {
        return defaultArray.indexOf(a) - defaultArray.indexOf(b);
      });
    
      if (this.props.UpperlinePercentage){
        const UpperlineArray =   (this.props.UpperlinePercentage.length > 0) ? JSON.parse(this.props.UpperlinePercentage) : [];
        const RetriveKey = Object.keys(UpperlineArray);
        const ewKeysArray = Object.keys(ewJSON)
        const FinalResultKey = [...RetriveKey, ...ewKeysArray];
        const defaultArray = ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        const FilterarrTwo = FinalResultKey.filter((item, index) => FinalResultKey.indexOf(item) === index);
        const FinalResultArrayKey = FilterarrTwo.sort((a,b) => {
          return defaultArray.indexOf(a) - defaultArray.indexOf(b);
        });
        FinalResultArrayKey.forEach((item) => {
          if (ewJSON[item] === undefined) {
            ewJSON[item] = { A: {amount: 0, percent: 0},
              B: {amount: 0, percent: 0},
              C: {amount: 0, percent: 0},
              S: {amount: 0, percent: 0},
              '5D': {amount: 0, percent: 0},
              '6D': {amount: 0, percent: 0},
              A1: {amount: 0, percent: 0},
              A1C: {amount: 0, percent: 0},
              A2: {amount: 0, percent: 0},
              A3: {amount: 0, percent: 0},
              D4: {amount: 0, percent: 0},
              E4: {amount: 0, percent: 0},
              all: "",
            }
          }
        }) 
        this.setState({ 
          Games: JSON.parse(JSON.stringify(FinalResultArrayKey)),
          OriginalGames: JSON.parse(JSON.stringify(FinalResultArrayKey)),
          EwPackage: JSON.parse(JSON.stringify(ewJSON)),
          OriEwPackage: JSON.parse(JSON.stringify(ewJSON)),
          Upperlines: (this.props.UpperlinePercentage.length > 0) ? JSON.parse(this.props.UpperlinePercentage) : [],
        })
      } else {
        this.setState({
          Games: JSON.parse(JSON.stringify(arrangedArray)),
          OriginalGames: JSON.parse(JSON.stringify(arrangedArray)),
          EwPackage: JSON.parse(JSON.stringify(ewJSON)),
          OriEwPackage: JSON.parse(JSON.stringify(ewJSON)),
          Upperlines: [],
        })
      }
    } else {
      const ewJSON = JSON.parse(this.props.EwPackage) || []
      const UpperlineArray =   ((this.props.UpperlinePercentage || []).length > 0) ? JSON.parse(this.props.UpperlinePercentage) : [];
      const RetriveKey = Object.keys(UpperlineArray);
      const ewKeysArray = Object.keys(ewJSON)
      const FinalResultKey = [...RetriveKey, ...ewKeysArray];
      const defaultArray = ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const FilterarrTwo = FinalResultKey.filter((item, index) => FinalResultKey.indexOf(item) === index);
      const FinalResultArrayKey = FilterarrTwo.sort((a,b) => {
        return defaultArray.indexOf(a) - defaultArray.indexOf(b);
      });
    
      FinalResultArrayKey.forEach((item) => {
        if (ewJSON[item] === undefined) {
          ewJSON[item] = { A: {amount: 0, percent: 0},
            B: {amount: 0, percent: 0},
            C: {amount: 0, percent: 0},
            S: {amount: 0, percent: 0},
            '5D': {amount: 0, percent: 0},
            '6D': {amount: 0, percent: 0},
            A1: {amount: 0, percent: 0},
            A1C: {amount: 0, percent: 0},
            A2: {amount: 0, percent: 0},
            A3: {amount: 0, percent: 0},
            D4: {amount: 0, percent: 0},
            E4: {amount: 0, percent: 0},
            all: "",
          }
        }
      }) 
    
      if (this.props.UpperlinePercentage){
        this.setState({
          Games: JSON.parse(JSON.stringify(FinalResultArrayKey)),
          OriginalGames: JSON.parse(JSON.stringify(FinalResultArrayKey)),
          EwPackage: JSON.parse(JSON.stringify(ewJSON)),
          OriEwPackage: JSON.parse(JSON.stringify(ewJSON)),
          Upperlines: (this.props.UpperlinePercentage.length > 0) ? JSON.parse(this.props.UpperlinePercentage) : [],
        })
      } else {
        this.setState({
          Upperlines: [],
        })
      }
    }
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  resetForm(){
    this.setState({ 
      EwPackage: JSON.parse(JSON.stringify(this.state.OriEwPackage)),
      Games: JSON.parse(JSON.stringify(this.state.OriginalGames)),
    })
  }

  textAllChange(game, value, status) {
    const e = value.replace(/[^0-9.]/g, '')
    
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');
    // const e = value
    //   .replace(/\D+/g, '.') // replace non digit with comma
    //   .replace(/^,|,$/g, '') // trim the comma
    // const checkNum = e.split('')
    // let output = e.split('.');
    // if (checkNum[0] === '.') {
    //   output = output.shift() + (output.length ? '.' + output.join('') : '');
    //   output = parseFloat(output)
    // } else if (checkNum[0] === '0') {
    //   output = output.shift() + (output.length ? '.' + output.join('') : '');
    //   output = Number(output)
    // } else {
    //   output = output.shift() + (output.length ? '.' + output.join('') : '');
    // }
    let keys = ['all', 'B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D', 'CA','CB','CC','CD','CE','CF', 'PA','PB','PC','PD','PE','PF']
    if (status === 'Quantity') {
      const stateObject = JSON.parse(JSON.stringify(this.state.EwPackage))
      if (!stateObject[game]) stateObject[game] = {}
      for (let i = 0; i < keys.length; i += 1) {
        if (stateObject[game][keys[i]] === undefined || stateObject[game][keys[i]] === '') {
          stateObject[game][keys[i]] = {amount: output, max: 0, percent: 0}
        } else {
          stateObject[game][keys[i]].amount = output
        }
      }
      this.setState({ EwPackage: JSON.parse(JSON.stringify(stateObject)) })
      this.props.dataChange('EwPackage', stateObject, undefined ,'formData')
    } else if (status === 'Max') {
      const stateObject = JSON.parse(JSON.stringify(this.state.EwPackage))
      if (!stateObject[game]) stateObject[game] = {}
      for (let i = 0; i < keys.length; i += 1) {
        if (stateObject[game][keys[i]] === undefined || stateObject[game][keys[i]] === '') {
          stateObject[game][keys[i]] = {amount: 0, max: output, percent: 0}
        } else {
          stateObject[game][keys[i]].max = output
        }
      }
      this.setState({ EwPackage: JSON.parse(JSON.stringify(stateObject)) })
      this.props.dataChange('EwPackage', stateObject, undefined ,'formData')
    } else {
      const stateObject = JSON.parse(JSON.stringify(this.state.EwPackage))
      if (!stateObject[game]) stateObject[game] = {}
      for (let i = 0; i < keys.length; i += 1) {
        if (stateObject[game][keys[i]] === undefined || stateObject[game][keys[i]] === '') {
          stateObject[game][keys[i]] = {amount: 0, max: 0, percent: output}
        } else {
          stateObject[game][keys[i]].percent =  output
        }
      }
      this.setState({ EwPackage: JSON.parse(JSON.stringify(stateObject)) })
      this.props.dataChange('EwPackage', JSON.parse(JSON.stringify(stateObject)), undefined ,'formData')
    }
  }

  textChange(game, subfield, value, status) {

    // const e = value
    //   .replace(/\D+/g, '.') // replace non digit with comma
    //   .replace(/^,|,$/g, '') // trim the comma
    // const checkNum = e.split('')
    // let output = e.split('.');

    const e = value.replace(/[^0-9.]/g, '')
    
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');
    
    // if (checkNum[0] === '.'){
    //   output = output.shift() + (output.length ? '.' + output.join('') : '');
    //   output = parseFloat(output)
    // } else if (checkNum[0] === '0'){
    //   output = output.shift() + (output.length ? '.' + output.join('') : '');
    //   output = Number(output)
    // } else {
    //   output = output.shift() + (output.length ? '.' + output.join('') : '');
    // }

    this.setState({ EwPackage: JSON.parse(JSON.stringify({
      ...this.state.EwPackage,
      [game]: {
        ...this.state.EwPackage[game],
        [subfield]: {
          amount: JSON.parse(JSON.stringify(status === 'Quantity' ?  output : (this.state.EwPackage[game][subfield].amount ))),
          percent: JSON.parse(JSON.stringify(status === 'Limit' ? output : (this.state.EwPackage[game][subfield].percent ))),
          max: JSON.parse(JSON.stringify(status === 'Max' ?  output : (this.state.EwPackage[game][subfield].max )))
        },
        all: {
          amount: 0,
          percent: 0,
          max: 0
        }
      }
    }))}, () => {
      this.props.dataChange('EwPackage', this.state.EwPackage, undefined ,'formData')
    })
  }

  gameChange(game, boolean) {

    // if(game === '8' && boolean === false) {
    //   const r = (window.confirm(language[this.props.currentLanguage].State8));
    //   if (r === true) {
    //     this.gameChangerun(game);
    //   } else {
    //     return null;
    //   }
    // } else {
    this.gameChangerun(game);
    // }


  }

  gameChangerun(game){
    let newArray = this.state.Games
    let newPackage = this.state.EwPackage
    if (this.state.Games.includes(game)) {
      newArray.splice(this.state.Games.indexOf(game), 1);
      delete newPackage[game]
    } else {
      newArray.push(game);
      newPackage[game] = newPackage.Default
    }
    const defaultArray = ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const arrangedArray = newArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    this.setState({ Games: JSON.parse(JSON.stringify(arrangedArray)), EwPackage: JSON.parse(JSON.stringify(newPackage))})

    this.props.dataChange('EwPackage', JSON.parse(JSON.stringify(newPackage)))
  }

  // renderBackgroundColor(game) {
  //   if (game === '1') return '#fff23e'
  //   else if (game === '2') return '#fff23e'
  //   else if (game === '3') return '#ff431b'
  //   else if (game === '4') return '#32c6f4'
  //   else if (game === '5') return 'green'
  //   else if (game === '6') return '#fc0404'
  //   else if (game === '7') return '#fff23e'
  //   else if (game === '8') return '#6d100e'
  //   else return null
  // }

  renderGameName(game) {
    if (game === 'Default') return 'Default'
    else if (game === '1') return 'M'
    else if (game === '2') return this.state.GDisplay === 'MPTSWBK' ? 'P' : 'K'
    else if (game === '3') return 'T'
    else if (game === '4') return 'S'
    else if (game === '5') return 'W'
    else if (game === '6') return 'B'
    else if (game === '7') return this.state.GDisplay === 'MPTSWBK' ? 'K' : 'D'
    else if (game === '8') return '8'
    else if (game === '9') return '9'
    else return null
  }

  async toggleSetEditForm() {
    this.setState({activeKey: ''})
  }

  async SeacrchFucntion(event){
    if (event.key === 'Enter') {
      event.preventDefault();
      let NewArray = []
      this.state.packageNames.forEach((item)=>{
        if ( this.state.SearchValue === item.Name ){
          NewArray.push(item)
        }
      })
      this.setState({packageNames: NewArray, BackPackageNames: true })
    }
  }

  async DetailFucntion(event, GameKey){
    if (event.key === '+') {
      event.preventDefault();
      if (GameKey === 'B') {
        this.InputS.focus();
      } else if(GameKey === 'S') {
        this.InputA1.focus();
      } else if(GameKey === 'A1') {
        this.InputA1C.focus();
      } else if(GameKey === 'A1C') {
        this.InputA.focus();
      } else if(GameKey === 'A') {
        this.InputC.focus();
      } else if(GameKey === 'C') {
        this.InputA2.focus();
      } else if(GameKey === 'A2') {
        this.InputA3.focus();
      } else if(GameKey === 'A3') {
        this.InputD4.focus();
      } else if(GameKey === 'D4') {
        this.InputE4.focus();
      } else if(GameKey === 'E4') {
        this.Input5D.focus();
      } else if(GameKey === '5D') {
        this.Input6D.focus();
      } else if(GameKey === '6D') {
        this.HolyInputB.focus();
      } else if(GameKey === 'HolyB') {
        this.HolyInputS.focus();
      } else if(GameKey === 'HolyS') {
        this.HolyInputA1.focus();
      } else if(GameKey === 'HolyA1') {
        this.HolyInputA1C.focus();
      } else if(GameKey === 'HolyA1C') {
        this.HolyInputA.focus();
      } else if(GameKey === 'HolyA') {
        this.HolyInputC.focus();
      } else if(GameKey === 'HolyC') {
        this.HolyInputA2.focus();
      }  else if(GameKey === 'HolyA2') {
        this.HolyInputA3.focus();
      }  else if(GameKey === 'HolyA3') {
        this.HolyInputD4.focus();
      }  else if(GameKey === 'HolyD4') {
        this.HolyInputE4.focus();
      }  else if(GameKey === 'HolyE4') {
        this.HolyInput5D.focus();
      }  else if(GameKey === 'Holy5D') {
        this.HolyInput6D.focus();
      }  else {
        this.InputB.focus();
      }
    }
  }

  async onChangeDetail(event, item, Key, Package){
    const e = event.target.value
      .replace(/\D+/g, '.')
      .replace(/^,|,$/g, '')
 
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');

    if (Package === 'Odd'){
      this.setState({ SetDetailValue: JSON.parse(JSON.stringify( {
        ...this.state.SetDetailValue,
        [Package]: {
          ...this.state.SetDetailValue[Package],
          [Key]: {
            ...this.state.SetDetailValue[Package][Key],
            [item]: output
          },
        }
      }))
      })
    } else {
      this.setState({ SetDetailValue: JSON.parse(JSON.stringify( {
        ...this.state.SetDetailValue,
        [Package]: {
          ...this.state.SetDetailValue[Package],
          [Key]: {
            ...this.state.SetDetailValue[Package][Key],
            [item]: output
          },
        }
      }))
      })
    }
  }

  async SubmitDetail(){
    let NewArray = JSON.parse(JSON.stringify(this.state.SetDetailValue))
    const Package = Object.keys(NewArray)
    
    Package.forEach((PackageKey) =>{
      const CallObjecKey = Object.keys(NewArray[PackageKey])
      if ((JSON.stringify(NewArray[PackageKey]) === '{}')){
        delete NewArray[PackageKey]
      }
      CallObjecKey.forEach((ObjectKey) =>{
        const CallObject = Object.keys(NewArray[PackageKey][ObjectKey])
        CallObject.forEach((item) =>{
          if ( NewArray[PackageKey][ObjectKey][item] === ''){
            delete NewArray[PackageKey][ObjectKey][item]
            if ((JSON.stringify(NewArray[PackageKey][ObjectKey]) === '{}')){
              delete NewArray[PackageKey][ObjectKey]
            }
          }
        })
        
      })

    })
    const PackageDone = JSON.parse(JSON.stringify(NewArray))
    let searchPackageResult = await searchPackage( this.props.OwnerUserID, PackageDone, this.props.username, this.props.token)
    if (searchPackageResult.message){
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(searchPackageResult.message)
      })
    } else {
      this.setState({packageNames: searchPackageResult, modalShowDetail: false, modalShow: true, BackPackageNames: true, SetDetailValue: {Odd:{}, Commission:{}}})
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success'
      });
    }, 5000);
  };


  handleChange = OptionsPackage => {
    this.setState({ SelectedPackage: OptionsPackage }, ()=>{
      let NewArray = []
      if ((this.state.SelectedPackage).length === 0 ){
        this.setState({ packageNames: JSON.parse(JSON.stringify(this.state.OriginalpackageNames))})
      } else {
        this.state.SelectedPackage.forEach((CheckItem) =>{
          this.state.OriginalpackageNames.forEach((OriCheckItem) =>{
            if (CheckItem.value === OriCheckItem.Name){
              NewArray.push(OriCheckItem)
            }
          })
        })
        this.setState({ packageNames: JSON.parse(JSON.stringify(NewArray)), BackPackageNames: false})
      }
    });
  };

  async ReloadPackage(){
    this.setState({ loading: true}, async ()=>{
      const userID =  this.props.targetUserID
      const ReloadResult = await reloadEatWord( userID, this.props.username, this.props.token )

      if (ReloadResult.message === 'successful'){
        this.setState({loading: false}, ()=>{
          this.props.ActiveNotification(ReloadResult.message)
        })
      } else {
        this.setState({notificationType: 'error'}, ()=>{
          this.openNotification(ReloadResult.error)
        })
      }
    })
  }

  render() {
    const { OptionsPackage } = this.state;
    const animatedComponents = makeAnimated();
    if (this.state.loading) {
      return (
        <Spinner style={{ margin: 10}} animation="border" />
      )
    }
    return(
      <div>
        <div style={{width: 'calc(100vw - 655px)'}}>
          <div style={{ marginLeft: 20, alignItems: 'center', marginBottom: 10, fontSize: 24}}>
            <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].FollowingGameSettingSameAsDefault}</div>
            <div style={{  display: 'flex' }}>
              {((this.state.GDisplay === 'MPTSWBK') ? this.state.GamePlatform2 : this.state.GamePlatform).map((item, index) => {
                return <Form.Check
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                  inline
                  label={item}
                  type="checkbox"
                  checked={!this.state.Games.includes((index + 1).toString())}
                  onChange={(e) => this.gameChange((index + 1).toString(), !this.state.Games.includes((index + 1).toString()))}
                />
              })}
            </div>
          </div>
          {this.state.Games.map((game) => {
            return <div 
            // className={(game === '1') ? 'BackgroundColorM' : game === '2' ? 'BackgroundColorK' :  (game === '3') ? 'BackgroundColorT' : 
            //   (game === '4') ? 'BackgroundColorS' : (game === '5') ? 'BackgroundColorW' : (game === '6') ? 'BackgroundColorB' :  
            //     game === '7' ? 'BackgroundColorD' : (game === '8') ? 'BackgroundColor8' :   ''} 
              style={{  backgroundColor: Platformcolor(game, ''),display: 'flex', alignItems: 'center', width:'1150px', paddingTop: 10, borderRadius: 10, color: ((game === '3') ? '#fff' : (game === '8') ? '#fff' : '#000')}}>
              <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{this.renderGameName(game)}</div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', fontWeight: 'bold'}}>说明</div>
                    {(this.props.Keys === 'NoEatWord') ? (
                      <></>
                    ) : (
                      <Form.Control style={{ width: 90, textAlign: 'center', color: 'black', fontWeight: 'bold'}} type="text" value="吃字%" disabled></Form.Control>
                    )}
                    {(this.props.Keys === 'NoEatWord') ? (
                      <></>
                    ):(
                      <div style={{ width: '100%'}}>
                        <Form.Control style={{ width: 90, textAlign: 'center', color: 'black', fontWeight: 'bold'}} type="text" value="吃字" disabled></Form.Control>
                        <Form.Control style={{ width: 90, textAlign: 'center', color: 'black', fontWeight: 'bold'}} type="text" value="最大吃字" disabled></Form.Control>
                      </div>
                    )}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Simplicity}</div>
                    {(this.props.Keys === 'NoEatWord') ? (
                      <></>
                    ) : (
                      <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1 || this.props.OwnerUsername === this.props.username) ? true : false}  style={{ width: 70, textAlign: 'center', backgroundColor: '#fac8f3', fontWeight: 'bold'}} type="text" value={this.state.EwPackage[game]['all'] && this.state.EwPackage[game]['all'].percent} onChange={(e) => this.textAllChange(game, e.target.value, 'Percent')}/>
                    )}
                    {(this.props.Keys === 'NoEatWord') ? (
                      <></>
                    ):(
                      <div style={{ width: '100%'}}>
                        <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1) ? true : false}  style={{ width: 70, textAlign: 'center', backgroundColor: '#fac8f3', fontWeight: 'bold'}} type="text" value={this.state.EwPackage[game]['all'] && this.state.EwPackage[game]['all'].amount} onChange={(e) => this.textAllChange(game, e.target.value, 'Quantity')}/>
                        <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1 || this.props.OwnerUsername === this.props.username) ? true : false}  style={{ width: 70, textAlign: 'center', backgroundColor: '#fac8f3', fontWeight: 'bold'}} type="text" value={this.state.EwPackage[game]['all'] && this.state.EwPackage[game]['all'].max} onChange={(e) => this.textAllChange(game, e.target.value, 'Max')}/>
                      </div>
                    )}
                  </div>
                  {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D'].map((item) => {
                    return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', fontWeight: 'bold'}}>{item}</div>
                      {(this.props.Keys === 'NoEatWord') ? (
                        <></>
                      ):(
                        <div style={{ width: '100%'}}>
                          <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1 || this.props.OwnerUsername === this.props.username) ? true : false} style={{ width: 70, textAlign: 'center', fontWeight: 'bold'}} type="text" value={this.state.EwPackage[game][item] && this.state.EwPackage[game][item].percent} onChange={(e) => this.textChange(game, item, e.target.value, 'Limit')}/>
                          <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1) ? true : false}
                            style={{ width: 70, textAlign: 'center', fontWeight: 'bold', 
                              backgroundColor: (this.state.OriEwPackage[game] && Number(this.state.OriEwPackage[game][item] && this.state.OriEwPackage[game][item].amount) !== Number(this.state.EwPackage[game][item] && this.state.EwPackage[game][item].amount)) ? 'yellowgreen' : '' }} 
                            type="text" value={this.state.EwPackage[game][item] && this.state.EwPackage[game][item].amount} onChange={(e) => this.textChange(game, item, e.target.value, 'Quantity')}/>
                          <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1 || this.props.OwnerUsername === this.props.username) ? true : false} 
                            style={{ width: 70, textAlign: 'center', fontWeight: 'bold', 
                              backgroundColor: (this.state.OriEwPackage[game] && Number(this.state.OriEwPackage[game][item] && this.state.OriEwPackage[game][item].max) !== Number(this.state.EwPackage[game][item] && this.state.EwPackage[game][item].max)) ? 'yellowgreen' : ''}} 
                            type="text" value={this.state.EwPackage[game][item] && this.state.EwPackage[game][item].max} onChange={(e) => this.textChange(game, item, e.target.value, 'Max')}/>
                        </div>
                      )}
                    </div>
                  })}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',  marginBottom: 10}}>
                  {['CA','CB','CC','CD','CE','CF', 'PA','PB','PC','PD','PE','PF', ].map((item) => {
                    return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', fontWeight: 'bold'}}>{item}</div>
                      {(this.props.Keys === 'NoEatWord') ? (
                        <></>
                      ):(
                        <div style={{ width: '100%'}}>
                          <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1 || this.props.OwnerUsername === this.props.username) ? true : false} style={{ width: 70, textAlign: 'center', fontWeight: 'bold'}} type="text" value={this.state.EwPackage[game][item] && this.state.EwPackage[game][item].percent} onChange={(e) => this.textChange(game, item, e.target.value, 'Limit')}/>
                          <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1) ? true : false} 
                            style={{ width: 70, textAlign: 'center', fontWeight: 'bold', 
                              backgroundColor: (this.state.OriEwPackage[game] && Number(this.state.OriEwPackage[game][item] && this.state.OriEwPackage[game][item].amount) !== Number(this.state.EwPackage[game][item] && this.state.EwPackage[game][item].amount)) ? 'yellowgreen' : '' 
                            }} type="text" 
                            value={this.state.EwPackage[game][item] && this.state.EwPackage[game][item].amount} 
                            onChange={(e) => this.textChange(game, item, e.target.value, 'Quantity')}/>
                          <Form.Control disabled={this.props.OwnerUserID === 'C'} readOnly={(this.state.Authority.PackageSetting !== 1 || this.props.OwnerUsername === this.props.username) ? true : false} 
                            style={{ width: 70, textAlign: 'center', fontWeight: 'bold', 
                              backgroundColor: (this.state.OriEwPackage[game] && Number(this.state.OriEwPackage[game][item] && this.state.OriEwPackage[game][item].max) !== Number(this.state.EwPackage[game][item] && this.state.EwPackage[game][item].max)) ? 'yellowgreen' : ''}} type="text" 
                            value={this.state.EwPackage[game][item] && this.state.EwPackage[game][item].max} 
                            onChange={(e) => this.textChange(game, item, e.target.value, 'Max')}/>
                        </div>
                      )}
                    </div>
                  })}
                </div>
              </div>
            </div>
          })}
        </div>
        {(this.props.Keys === 'NoEatWord') ? (
          <></>
        ):(
          <div style={{ display: 'flex', justifyContent: 'center', width: ''}}>
            <Button  className='button2' style={{ margin: 5, width: '33%'}} variant="danger" onClick={() => this.props.CloseEWPackage('-')}>{language[this.props.currentLanguage].Shutdown}</Button>
            <Button className='button2' disabled={(this.state.Authority.PackageSetting !== 1 || this.props.OwnerUserID === 'C' ) ? true : false} style={{ margin: 5, width: '33%'}} variant="warning" onClick={() => this.resetForm()}>{language[this.props.currentLanguage].Reset}</Button>
            <Button className='button2' disabled={(this.state.Authority.PackageSetting !== 1 || this.props.OwnerUserID === 'C' ) ? true : false} style={{ margin: 5, width: '33%'}} variant="success" onClick={() => this.props.update('EwPackage')}>{language[this.props.currentLanguage].Update}</Button>
            {/* {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? (
              <>
                {(this.props.Role === 'admin' || this.props.Role === 'superAdmin' )&&<Button  className='button2' style={{ margin: 5, width: '33%', marginLeft: '5%', borderWidth: 2,borderColor: "black", fontWeight: 'bold'}} variant="light" onClick={() => this.ReloadPackage()}>{'Reload'}</Button>}
              </>
            ):(
              <></>
            )} */}
          </div>
        )}

        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modalShow}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
            <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
                onClick={() => {
                  this.setState({ modalShow: false, packageNames: this.state.OriginalpackageNames, BackPackageNames: false, })
                }}
                size="35px"
                color="#000"
              />
              <div style={{flexDirection: 'row', width: 'auto', }}>
                <Tab.Container id="left-tabs-example" activeKey={this.state.activeKey} mountOnEnter unmountOnExit>
                  <div className="dcc" style={{ width: '100%', marginBottom: 0 }}>
                    <div style={{fontSize: "21px", width: 200,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].SearchPackageName}</div>
                  </div>
                  {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') && <Button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: '10%', marginLeft: '5%', marginBottom: '1%' }} 
                    disabled={this.state.disabled}
                    onClick={() => this.setState({modalShow: false, modalShowDetail: true})} 
                    className="btn my-cusomized-button button2" >
                    {`${language[this.props.currentLanguage].Details}`}
                  </Button>}
                  <div style={{ marginLeft: '5%',width: '90%'}}>
                    <Select 
                      options={OptionsPackage}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      value={this.state.SelectedPackage}
                      onChange={this.handleChange}
                      placeholder={language[this.props.currentLanguage].SeacrhPackage}
                    >
                    </Select>
                  </div>
                  <div  style={{ marginLeft: '5%', flexDirection: 'row', display: 'flex', paddingTop: 10  }}>
                    { this.state.BackPackageNames && <Button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: '10%' }} 
                      disabled={this.state.disabled}
                      onClick={() => this.setState({packageNames: this.state.OriginalpackageNames, SearchValue: '', BackPackageNames: false})} 
                      className="btn my-cusomized-button" >
                      {language[this.props.currentLanguage].Back}
                    </Button>}
                  </div>
                  <div className="dcc" style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%', marginBottom:  30, justifyContent: null, paddingTop: 10  }}>
                    {this.state.packageNames.map((item)=>(
                      <Button  className='button2' style={{ margin: 5, width: 'calc(100% / 10)', fontWeight: 'bold'}} variant="primary" onClick={() =>  this.setState({ activeKey: item.PackageID })}>
                        {item.Name}
                        {item.childPackage && <img src={tickicons} alt="tick" style={{ marginLeft: 10, width: 25, height: 25, borderRadius: 25}}/>}
                      </Button>
                    ))}
                  </div>
                  <div style={{ display: 'flex', padding: 10, border: '1px solid #000', borderRadius: 20, overflowX: 'auto', width: '100%' }}>
                    <Tab.Content>
                      <div  style={{ display: 'flex',}}>
                        {this.state.packageNames && this.state.packageNames.map((item, idx) => {
                          return <Tab.Pane eventKey={ item.PackageID } key={ item.PackageID}>
                            <HRPackage targetUserID={this.props.OwnerUserID} packageDetails={item} idx={idx} toggleSetEditForm={this.toggleSetEditForm} AdminModal={true}/>
                          </Tab.Pane>
                        })} 
                      </div>
                    </Tab.Content>
                  </div>
                </Tab.Container>
                <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 45, flexDirection: 'row' }}>
                  <Button 
                    style={{ fontSize: "18px", textAlign: 'center', color: 'white', width: '33%' }}
                    variant="danger" 
                    className='button2'
                    onClick={() => this.setState({modalShow: false})} 
                  >
                    {language[this.props.currentLanguage].Shutdown}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modalShowDetail}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
            <div className="dcc" style={{ justifyContent: 'center' }}>
              <Alert style={{ zIndex: 99, position: 'fixed', top: 100, right: 580}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
              </Alert>
            </div>
            <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
                onClick={() => {
                  this.setState({ modalShowDetail: false, modalShow: true, SetDetailValue: {Odd:{}, Commission:{}} })
                }}
                size="35px"
                color="#000"
              />
              <div style={{flexDirection: 'row', width:  'auto', }}>
                <Tab.Container id="left-tabs-example" activeKey={this.state.activeKey} mountOnEnter unmountOnExit>
                  <div className="dcc" style={{ width: '100%', marginBottom: 0 }}>
                    <div style={{fontSize: "21px", width: 200,  textAlign: 'center', fontWeight: 'bold'}}>{`${language[this.props.currentLanguage].Details}`}</div>
                  </div>
                  <div className="dcc row">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                      <div className='dcc' style={{ padding: 5, borderRadius: 5, width: 80}}/>
                      <div className='dcc' style={{ padding: 5, borderRadius: 5, width: 80, fontWeight: 'bold'}}>{'水'}</div>
                      <div className='dcc' style={{ padding: 5, borderRadius: 5, width: 80, fontWeight: 'bold'}}>{'HolyWater'}</div>
                    </div>
                  
                    {['B', 'S', 'A1', 'A1C', 'A', 'C', 'A2', 'A3', 'D4', 'E4', '5D', '6D'].map((item, index) => {
                      return(
                        <div index={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                          <div className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                          <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, 'Commission', 'Commission')}
                            value={(this.state.SetDetailValue.Commission && this.state.SetDetailValue.Commission.Commission) === undefined ? '' : this.state.SetDetailValue.Commission.Commission[item]}
                            onKeyPress={(e) => this.DetailFucntion(e, item)}   ref={(input) => { this[`Input${item}`] = input;}} />
                          <Form.Control style={{ width: 80, textAlign: 'center', marginBottom: '10%',  backgroundColor: '#C3f3fb'}} type="text" onChange={(e) => this.onChangeDetail(e, item, 'HolyWater', 'Commission')}
                            value={(this.state.SetDetailValue.Commission && this.state.SetDetailValue.Commission.HolyWater) === undefined ? '' : this.state.SetDetailValue.Commission.HolyWater[item]}
                            onKeyPress={(e) => this.DetailFucntion(e, `Holy${item}`)}   ref={(input) => { this[`HolyInput${item}`] = input;}} />
                        </div>
                      );
                    })}
                    <div/>
                  </div>
                  <hr />
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, overflowX: 'auto'}}>
                    <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Odds}</div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 10}}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <div style={{ margin: '0px 10px', fontSize: 30}}>1</div>
                        {['B', 'S', 'A1', 'A1C', 'A', 'C'].map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '1ST', 'Odd')}
                              value={this.state.SetDetailValue[item]}
                              onKeyPress={(e) => this.DetailFucntion(e, item)} 
                            />
                          </div>
                        })}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <div style={{ margin: '0px 10px', fontSize: 30}}>2</div>
                        {['B', 'S', 'A2'].map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div  className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '2ND', 'Odd')}
                              value={this.state.SetDetailValue[item]}
                              onKeyPress={(e) => this.DetailFucntion(e, item)} 
                            />
                          </div>
                        })}
                        <div style={{ margin: '0px 10px', fontSize: 30}}>4</div>
                        {['B', 'D4'].map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div   className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '4TH', 'Odd')}
                              value={this.state.SetDetailValue[item]}
                              onKeyPress={(e) => this.DetailFucntion(e, item)} 
                            />
                          </div>
                        })}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <div style={{ margin: '0px 10px', fontSize: 30}}>3</div>
                        {['B', 'S', 'A3'].map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div  className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '3RD', 'Odd')}
                              value={this.state.SetDetailValue[item]}
                              onKeyPress={(e) => this.DetailFucntion(e, item)}
                            />
                          </div>
                        })}
                        <div style={{ margin: '0px 10px', fontSize: 30}}>5</div>
                        {['B', 'E4'].map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div  className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '5TH', 'Odd')}
                              value={this.state.SetDetailValue[item]}
                              onKeyPress={(e) => this.DetailFucntion(e, item)}
                            />
                          </div>
                        })}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 10}}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <div style={{ margin: '0px 10px', fontSize: 30}}>5D</div>
                        {['5D1', '5D2', '5D3', '5D4', '5D5', '5D6'].map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div  className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '5D', 'Odd')}
                              value={this.state.SetDetailValue[item]}
                              onKeyPress={(e) => this.DetailFucntion(e, item)}
                            />
                          </div>
                        })}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                        <div style={{ margin: '0px 10px', fontSize: 30}}>6D</div>
                        {['6D', '6D2', '6D3', '6D4', '6D5'].map((item) => {
                          return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                            <div  className='dcc' style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                            <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.onChangeDetail(e, item, '6D', 'Odd')}
                              value={this.state.SetDetailValue[item]}
                              onKeyPress={(e) => this.DetailFucntion(e, item)}
                            />
                          </div>
                        })}
                      </div>
                
                    </div>
                  </div>
                </Tab.Container>
                <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 45, flexDirection: 'row' }}>
				        <Button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: '33%' }} 
                    disabled={this.state.disabled}
                    onClick={() => this.SubmitDetail()} 
                    className="btn my-cusomized-button button2" >
                    {language[this.props.currentLanguage].Submit}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(EWPackage);