import '../App.css';
import React from 'react';
import { connect } from 'react-redux'
import Header2 from '../Component/Header';
import { Button, Card, Form } from 'react-bootstrap';
import { language } from '../language';

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: localStorage.getItem('Username'),
      Min_Date: localStorage.getItem('Min_Date'),
      Max_Date: localStorage.getItem('Max_Date'),
      isSub:  '',
      Authority: '',
    }
    this.AmountFunction = this.AmountFunction.bind(this);
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if ((UserAuthorities && UserAuthorities.setting) === 0) {
      window.location.href='/Home'; 
    }
    const CallAuthority = this.props.UserAuthority
    const ToParseAuthority = JSON.parse(CallAuthority)
    this.setState({ isSub: ToParseAuthority.isSub, Authority: ToParseAuthority })
  }

  async AmountFunction( e ){
    const value = e.target.value
      .replace(/\D+/g, '.') // replace non digit with comma
      .replace(/^,|,$/g, '')
    this.setState({ Amount: value})
  }

  async GoToHR() {
    this.props.history.push('/HR')
  }

  async GoToRedNumber() {
    this.props.history.push('/RedNumber')
  }

  async GoToVIP() {
    this.props.history.push('/VIPuser')
  }

  async GoToAddGame() {
    this.props.history.push('/AddGame')
  }

  async GoSubAcc(){
    window.location.href='/SubAcc'; 
  }

  GoToNewsManage() {
    this.props.history.push('/UpdateLatestNews')
  }

  GoGameSetting() {
    this.props.history.push('/GameSetting')
  } 
  
  render() {
    return(
      <div style={{ width: '100%', backgroundColor: '#EFEFEF', minHeight: 950, flexDirection: 'column' }}>
        {/* <Header /> */}
        <Header2 />
        <div className="dcc " style={{ width: 'calc(100% / 1)', marginBottom: 20 }}>
          <Card style={{ width: '80%'}}>
            <Card.Header as="h5"  style={{fontWeight: 'bold',}}>{language[this.props.currentLanguage].setting}</Card.Header>
            <Card.Body>
              <Card.Title  style={{fontWeight: 'bold',}}>{language[this.props.currentLanguage].Language}</Card.Title>
              <Card.Text className='dcc'>
                <Form.Control as="select" className="form-control DropdownListBoxV button2" value={this.state.SelectedExample} onChange={(e) => this.props.setLanguage(e.target.value)} 
                  style={{ fontWeight: 'bold',textAlign: 'center', fontSize: '18px', width: '50%' }}>
                  <option value="cn" selected={this.props.currentLanguage === 'cn'}>{language[this.props.currentLanguage].TranslateLanguageCN}</option>
                  <option value="en" selected={this.props.currentLanguage === 'en'}>{language[this.props.currentLanguage].TranslateLanguageEN}</option>
                  <option value="Malay" selected={this.props.currentLanguage === 'Malay'}>{language[this.props.currentLanguage].TranslateLanguageBM}</option>
                </Form.Control>
              </Card.Text>
              {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') && <>
                {this.state.Authority.EditBanNumber === 1 && 
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{fontWeight: 'bold', fontSize: "18px", width: '30%'}}>{language[this.props.currentLanguage].RedNumberManagement}</div>
                <Button className="button2 btn my-cusomized-button" style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '250px', fontWeight: 'bold',marginLeft: 10 }}
                  disabled={this.state.disabled}
                  onClick={() => this.GoToRedNumber()}>
                  {language[this.props.currentLanguage].Proceed} {language[this.props.currentLanguage].RedNumberManagement}
                </Button>
              </div>
                }
              </>}
              <div style={{ marginBottom: 30,  padding:  null }}>
                <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                  <div style={{fontWeight: 'bold', fontSize: "18px",width: '30%'}}>{language[this.props.currentLanguage].HumanResourceManagement}</div>
                  <Button className="button2 btn my-cusomized-button" style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '250px', fontWeight: 'bold',marginLeft: 10 }}
                    disabled={this.state.disabled}
                    onClick={() => this.GoToHR()}>
                    {language[this.props.currentLanguage].Proceed} {language[this.props.currentLanguage].HumanResourceManagement}
                  </Button>
                </div>
                {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') && 
            <>
              {this.state.Authority.SystemSetting === 1 && 
            <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
              <div style={{fontWeight: 'bold', fontSize: "18px", width: '30%'}}>{language[this.props.currentLanguage].NewsManage}</div>
              <Button className="button2 btn my-cusomized-button" style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '250px', fontWeight: 'bold',marginLeft: 10 }}
                disabled={this.state.disabled}
                onClick={() => this.GoToNewsManage()}>
                {language[this.props.currentLanguage].Proceed} {language[this.props.currentLanguage].NewsManage}
              </Button>
            </div>
              }
              {this.state.Authority.EditBanNumber === 1 && 
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{fontWeight: 'bold', fontSize: "18px",width:  '30%'}}>VIP {language[this.props.currentLanguage].Manage}</div>
                <Button className="button2 btn my-cusomized-button" style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '250px', fontWeight: 'bold',marginLeft: 10 }}
                  disabled={this.state.disabled}
                  onClick={() => this.GoToVIP()}>
                  {language[this.props.currentLanguage].Proceed} VIP {language[this.props.currentLanguage].Manage}
                </Button>
              </div>
              }
              {this.state.Authority.SetOpenDate === 1 && 
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{fontWeight: 'bold', fontSize: "18px",width:  '30%'}}>{language[this.props.currentLanguage].GameSetting}</div>
                <Button className="button2 btn my-cusomized-button" style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '250px', fontWeight: 'bold',marginLeft: 10 }}
                  disabled={this.state.disabled}
                  onClick={() => this.GoGameSetting()}>
                  {language[this.props.currentLanguage].Proceed} {language[this.props.currentLanguage].GameSetting}
                </Button>
              </div>
              }
              {(this.state.Authority.SetOpenDate === 1 && this.state.Authority.SystemSetting === 1) && 
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{fontWeight: 'bold', fontSize: "18px", width: '30%'}}>{language[this.props.currentLanguage].Addgame}</div>
                <Button className="button2 btn my-cusomized-button" style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '250px', fontWeight: 'bold',marginLeft: 10 }}
                  disabled={this.state.disabled}
                  onClick={() => this.GoToAddGame()}>
                  {language[this.props.currentLanguage].Proceed} {language[this.props.currentLanguage].Addgame}
                </Button>
              </div>
              }
            </>
                }
                {(this.state.isSub === 0 && this.state.Authority.AcctDisplay === 1) && <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                  <div style={{fontWeight: 'bold', fontSize: "18px",  width: '30%' }}>{language[this.props.currentLanguage].CreateSubAccount}</div>
                  <Button className="button2 btn my-cusomized-button" style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '250px', fontWeight: 'bold',marginLeft: 10 }}
                    disabled={this.state.disabled}
                    onClick={() => this.GoSubAcc()}>
                    {language[this.props.currentLanguage].ProceedToSubAccount}
                  </Button>
                </div>}
              </div>
            </Card.Body>
          </Card>
        </div>
        {/* <div className="dcc" style={{flexDirection: 'column'}}>
          {(this.props.Role !== 'player') && <div style={{ width: '80%', marginBottom: 30,  borderRadius: 20, border: (isMobile) ? '1px solid #000' : '2px solid #000', padding: (isMobile) ? 5 : null }}>
            <div className="dcc" style={{ width: '100%', marginBottom: 30, paddingTop: 15 }}>
              <div style={{fontWeight: 'bold', fontSize: "18px", width: 200}}>{language[this.props.currentLanguage].HumanResourceManagement}</div>
              <Button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 300, fontWeight: 'bold',marginLeft: 100 }}
                disabled={this.state.disabled}
                onClick={() => this.GoToHR()}
                class="btn my-cusomized-button">
                {language[this.props.currentLanguage].Proceed} {language[this.props.currentLanguage].HumanResourceManagement}
              </Button>
            </div>
            {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') && 
            <>
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{fontWeight: 'bold', fontSize: "18px", width: 200}}>{language[this.props.currentLanguage].RedNumberManagement}</div>
                <Button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 300, fontWeight: 'bold',marginLeft: 100 }}
                  disabled={this.state.disabled}
                  onClick={() => this.GoToRedNumber()}
                  class="btn my-cusomized-button">
                  {language[this.props.currentLanguage].Proceed} {language[this.props.currentLanguage].RedNumberManagement}
                </Button>
              </div>
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{fontWeight: 'bold', fontSize: "18px", width: 200}}>VIP {language[this.props.currentLanguage].Manage}</div>
                <Button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 300, fontWeight: 'bold',marginLeft: 100 }}
                  disabled={this.state.disabled}
                  onClick={() => this.GoToVIP()}
                  class="btn my-cusomized-button">
                  {language[this.props.currentLanguage].Proceed} VIP {language[this.props.currentLanguage].Manage}
                </Button>
              </div>
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{fontWeight: 'bold', fontSize: "18px", width: 200}}>{language[this.props.currentLanguage].Addgame}</div>
                <Button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 300, fontWeight: 'bold',marginLeft: 100 }}
                  disabled={this.state.disabled}
                  onClick={() => this.GoToAddGame()}
                  class="btn my-cusomized-button">
                  {language[this.props.currentLanguage].Proceed} {language[this.props.currentLanguage].Addgame}
                </Button>
              </div>
            </>
            }
            {( this.state.isSub === 0) && <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
              <div style={{fontWeight: 'bold', fontSize: "18px", width: (isMobile) ? 250 :200}}>{language[this.props.currentLanguage].CreateSubAccount}</div>
              <Button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 300, fontWeight: 'bold',marginLeft: 100 }}
                disabled={this.state.disabled}
                onClick={() => this.GoSubAcc()}
                class="btn my-cusomized-button">
                {language[this.props.currentLanguage].ProceedToSubAccount}
              </Button>
            </div>}

          </div>}
        </div> */}
      </div>
    );
  }
}

// export default Setting;

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date,
    Min_Date: state.Min_Date,
    isSub: state.isSub,
    Role: state.Role,
    UserAuthority: state.UserAuthority,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
