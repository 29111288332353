import '../App.css';
import React from 'react';
import { language } from '../language';
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import { Platformcolor } from '../static/PlatformColor';
// import { isMobile } from 'react-device-detect';

class AddVIPPackageUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Games: ['Default'],
      GamePlatform: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      GamePlatform2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'],
      GDisplay: '',
    }
  }

  componentDidMount() {
    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({ GDisplay });
  }

  textAllChange(red, game, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = JSON.parse(JSON.stringify(this.props.AddVIPData))
      const keys = Object.keys(gameObject[red].Default)
      for (let i = 0; i < keys.length; i += 1) {
        gameObject[red][game][keys[i]] = Number(value)
      }
      this.props.dataChange('AddVIPData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  textChange(red, game, subfield, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = JSON.parse(JSON.stringify(this.props.AddVIPData))
      gameObject[red][game][subfield] = Number(value)
      gameObject[red][game].all = ''
      this.props.dataChange('AddVIPData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  textChangeLPX(value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      this.props.dataChange('LPX', value)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  gameChange(game) {
    let newGameLimitPackage = JSON.parse(JSON.stringify(this.props.AddVIPData))
    if (newGameLimitPackage.Limit[game]) {
      delete newGameLimitPackage.RedLimit[game]
      delete newGameLimitPackage.Limit[game]
    } else {
      newGameLimitPackage.RedLimit[game] = { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }
      newGameLimitPackage.Limit[game] = { all: '', B: '', S: '', SA: '', SB: '', SC: '', SD: '', SE: '', SF: '', CA: '', CB: '', CC: '', CD: '', CE: '', CF: '', PA: '', PB: '', PP: '', PD: '', PE: '', PF: '', "5D": '', "6D": '' }
    }
    this.props.dataChange('AddVIPData', newGameLimitPackage)
  }

  // renderBackgroundColor(game) {
  //   if (game === 'Default') return 'lightsteelblue'
  //   else if (game === 'M') return '#fff23e'
  //   else if ((this.state.GDisplay === 'MPTSWBK') ? game === 'P' : game === 'K') return '#414bb2'
  //   else if (game === 'T') return '#ff431b'
  //   else if (game === 'S') return '#32c6f4'
  //   else if (game === 'W') return 'green'
  //   else if (game === 'B') return '#fc0404'
  //   else if ( (this.state.GDisplay === 'MPTSWBK') ? game === 'K' : game === 'D') return '#f8c100'
  //   else if (game === '8') return '#6d100e'
  //   else return null
  // }

  render() {
    return(
      <div>
        {(this.props.showType === 'All Word' || this.props.showType === 'All Limit' || this.props.showType === 'Group Limit') && <div>
          <div style={{width: 'calc(100vw - 655px)'}}>
            <div style={{ marginLeft: 20, alignItems: 'center', marginBottom: 5, fontSize: 24}}>
              <div className="TitleUsername" style={{ width: '50%',  fontSize: '20px'}}> {language[this.props.currentLanguage].FollowingGameSettingSameAsDefault} </div>
              <div style={{ display: 'flex'}}>
                {((this.state.GDisplay === 'MPTSWBK') ? this.state.GamePlatform2 : this.state.GamePlatform).map((item, index) => {
                  return <Form.Check
                    key={item}
                    style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                    inline
                    label={item}
                    type="checkbox"
                    checked={!this.props.AddVIPData.Limit[index + 1]}
                    onChange={(e) => this.gameChange(index + 1)}
                  />
                })}
              </div>
            </div>
            {Object.keys(this.props.AddVIPData.Limit).map((game) => {
              return <div
                key={game} style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: '1090px', paddingTop: 10, borderRadius: 10, color: game === 'Default' || game === '1' || game === '4' || game === '7' || game === '2' || game === 'B' || game === '3' ? '#000' : '#fff', backgroundColor: Platformcolor(game, '')}}>
                <div style={{ margin: '0px 10px', width: 70, fontSize: 20}}><b>{game}</b></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                  <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.AddVIPData.Limit[game].all} onChange={(e) => this.textAllChange('Limit', game, e.target.value)}/>
                </div>
                {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF', '5D', '6D'].map((item) => {
                  return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                    <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.AddVIPData.Limit[game][item]} onChange={(e) => this.textChange('Limit', game, item, e.target.value)}/>
                  </div>
                })}
              </div>
            })}
            <div style={{ padding: 10, background: 'red', width: 1110}}>
              {Object.keys(this.props.AddVIPData.RedLimit).map((game) => {
                return <div
                  key={game} style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: 1090, paddingTop: 10, borderRadius: 10, color: game === 'Default' || game === '1' || game === '4' || game === '7' || game === '2' || game === 'B' || game === '3' ? '#000' : '#fff', backgroundColor: Platformcolor(game, '')}}>
                  <div style={{ margin: '0px 10px', width: 70, fontSize: 20}}><b>{game}</b></div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                    <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.AddVIPData.RedLimit[game].all} onChange={(e) => this.textAllChange('RedLimit', game, e.target.value)}/>
                  </div>
                  {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF', '5D', '6D'].map((item) => {
                    return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                      <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.AddVIPData.RedLimit[game][item]} onChange={(e) => this.textChange('RedLimit', game, item, e.target.value)}/>
                    </div>
                  })}
                </div>
              })}
            </div>
          </div>
          <hr/>
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button className='TextFontSize' style={{ marginLeft: 10, width: null}} variant="danger" disabled={this.props.AddDisable } onClick={() => this.props.closeForm()} onChange={() => this.props.modeChange('Ban', 'selectedMode')}>
              {language[this.props.currentLanguage].Close}
            </Button>
            <Button className='TextFontSize' style={{ marginLeft: 10, width: null}} variant="success" disabled={this.props.AddDisable }  onClick={() => this.props.addRedNumberF()}>{language[this.props.currentLanguage].Add}</Button>
          </div>
        </div>}
        {(this.props.showType === 'LPX') && <div>
          <div>
            <div style={{ justifyContent: 'center', display: 'flex' }} >
              <div style={{fontSize: "18px", width: 100, fontWeight: 'bold'}}>{'乘率'} :</div>
              <Form.Control
                className="LoginInputText"
                type="text"
                value={this.props.LPXNumber}
                onChange={(e) => this.textChangeLPX(e.target.value)}
                style={{ marginRight: null}}
              />
            </div>
          </div>
          <hr/>
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button className='TextFontSize' style={{ marginLeft: 10, width: null}} variant="danger" disabled={this.props.AddDisable } onClick={() => this.props.closeForm()} onChange={() => this.props.modeChange('Ban', 'selectedMode')}>
              {language[this.props.currentLanguage].Close}
            </Button>
            <Button className='TextFontSize' style={{ marginLeft: 10, width: null}} variant="success" disabled={this.props.AddDisable }  onClick={() => this.props.addRedNumberF('LPX')}>{language[this.props.currentLanguage].Add}</Button>
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(AddVIPPackageUser);