import React, {Component, Fragment} from 'react';
import '../App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert';
// import Button from 'react-bootstrap/Button';
import Header2 from '../Component/Header';
import Modal from 'react-bootstrap/Modal';
import { ImArrowLeft, ImArrowRight } from 'react-icons/im';
// import CheckNumber from '../Page/CheckNumber';
import { Link } from 'react-router-dom';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import { language } from '../language';
// import { Multiselect } from 'multiselect-react-dropdown';
// import { GrFormClose } from 'react-icons/gr';
var timer

class GamesUserReport extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      Search_Username: '',
      modalShow: true,
      Min_Date: Moment(localStorage.getItem('Min_Date')).toDate(),
      Max_Date: Moment(localStorage.getItem('Max_Date')).toDate(),
      Available: false,
      page: 1,
      countperpage: 12,
      countpagemax: 1,
      countpagemin: 1,
      GenerateDate: '',
      notificationType: 'success',
      showNotification: false,
      notificationMessage: '',
    }
  }

  async componentDidMount() {
    if ( ( Moment(this.state.Max_Date).format('YYYY-MM-DD')) > Moment(this.state.Min_Date).format('YYYY-MM-DD')){
      this.openNotification('FormDateError')
      this.setState({ notificationType: 'error' });
    } else {
    }
  }

  async setSelectedDate(Min_Date) {
    this.setState({ Min_Date }, ()=>{
      this.props.setMinDate(Min_Date);
    })
  }

  async MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date }, ()=>{
      this.props.setMaxDate(Max_Date)
    })
  }

  openNotification(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}${Words || ''}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };
  
  render() {
    return (
      <Fragment>
        {/* <Alert show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
    <Alert.Heading>{this.state.AlertMessage}</Alert.Heading>
  </Alert> */}
        <Header2 />
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width:  '70%', left: 250 }} show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc row">
          <div className="dcc" style={{ marginTop: 20, marginBottom: 20, width: 'auto'}}>
            <div className={  "row"} style={{ marginTop: 20, marginBottom: 20, width:  'auto'}}>
              <div className="dcc" style={{ marginBottom: '2%'}}>
                <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>From</div>
                <DatePicker
                  onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  // popperPlacement="top-right"
                  selected={this.state.Max_Date}
                  className={"form-control StyleDatePicker"}
                  // withPortal
                />
                <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>To</div>
                <DatePicker
                  onChange = {(date) => this.setSelectedDate(date)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  minDate={new Date(this.state.Max_Date)}
                  // popperPlacement="top-right"
                  selected={this.state.Min_Date}
                  className={"form-control StyleDatePicker"}
                  // withPortal
                />
              </div>
              <input
                style={{ fontSize: "18px", textAlign: 'center', marginLeft: 30, width: 'auto'}} 
                value={this.state.CompanyName}
                class="form-control "
                readOnly={false}
                onChange={e => this.setState({ Search_Username: e.target.value })}
                placeholder={'登入/名称'} 
              />
            </div>
          </div>
          {this.state.loading  === true ? ( 
            <div class="divLoader">
              <img alt= "" style={{ marginTop:  50, width: 200, height:200, marginRight: 200}} src={'https://i.imgur.com/GLdqYB2.gif'}/>
            </div>
          ) : (
            <div style= {{ width: '100%'}}>
              <Table responsive="sm" style={{ backgroundColor: 'white'}}>
                <thead>
                  <tr>
                    <th><div style={{ width: 35, marginLeft: 20}}></div>登入</th>
                    <th><div style={{ width: 50}}>名称</div></th>
                    <th><div style={{ width: 50}}>来</div></th>
                    <th><div style={{ width: 60}}>下线吃</div></th>
                    <th><div style={{ width: 70}}>下线出字</div></th>
                    <th><div style={{ width: 70}}>下线佣金</div></th>
                    <th><div style={{ width: 70}}>下线中奖</div></th>
                    <th><div style={{ width: 70}}>下线中净</div></th>
                    <th><div style={{ width: 60}}>自己吃</div></th>
                    <th><div style={{ width: 70}}>自己佣金</div></th>
                    <th><div style={{ width: 70}}>自己中奖</div></th>
                    <th><div style={{ width: 60}}>自己净</div></th>
                    <th><div style={{ width: 70}}>出给上线</div></th>
                    <th><div style={{ width: 70}}>出给上佣</div></th>
                    <th><div style={{ width: 80}}>出给上中奖</div></th>
                    <th><div style={{ width: 80}}>出给上线净</div></th>
                    <th><div style={{ width: 70}}>佣金赚</div></th>
                    <th><div style={{ width: 70}}>奖金赚</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: '#ebf4fc'}}><div style={{ padding: 5}}>{'1'}</div></td>
                    <td><div style={{ padding: 5}}>{'2'}</div></td>
                    <td style={{ backgroundColor: '#ebf4fc'}} >
                      {'3'}
                    </td>
                    <td style={{ backgroundColor: '#'}} ><div style={{ padding: 5}}>{'4'}</div></td>
                    <td style={{ backgroundColor: '#ebf4fc'}} ><div style={{ padding: 5}}>{'5'}</div></td>
                    <td style={{ backgroundColor: '#'}} ><div style={{ padding: 5}}>{'6'}</div></td>
                    <td style={{ backgroundColor: '#ebf4fc'}} ><div style={{ padding: 5}}>{'7'}</div></td>
                    <td style={{ backgroundColor: '#'}} ><div style={{ padding: 5}}>{'8'}</div></td>
                    <td style={{ backgroundColor: '#ebf4fc'}} ><div style={{ padding: 5}}>{'9'}</div></td>
                    <td style={{ backgroundColor: '#'}} ><div style={{ padding: 5}}>{'10'}</div></td>
                    <td style={{ backgroundColor: '#ebf4fc'}} >
                      <div style={{ padding: 5}}> 
                        <Link to={{ pathname: "/CheckNumber"}}  style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }}>
                          {'11'}
                        </Link >
                      </div>
                    </td>
                    <td style={{ backgroundColor: '#'}} ><div style={{ padding: 5}}>{'12'}</div></td>
                    <td style={{ backgroundColor: '#ebf4fc'}} ><div style={{ padding: 5}}>{'13'}</div></td>
                    <td style={{ backgroundColor: '#'}} ><div style={{ padding: 5}}>{'14'}</div></td>
                    <td style={{ backgroundColor: '#ebf4fc'}} ><div style={{ padding: 5}}>{'15'}</div></td>
                    <td style={{ backgroundColor: '#'}} ><div style={{ padding: 5}}>{'16'}</div></td>
                    <td style={{  backgroundColor: '#ebf4fc' }}><div style={{ padding: 5}}>{'17'}</div></td>
                    <td><div style={{ padding: 5}}>{'18'}</div></td>
                  </tr>
                </tbody>
              </Table>
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.modalShow}
                contentClassName="br23"
              >
                <Modal.Body className="dcc" style={{ padding: 10, height: 'auto' }}>
                  <div style={{flexDirection: 'row', width: 'auto'}}>
                    {/* <Alert show={this.state.showNotificationError} variant={this.state.notificationTypeError === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.AlertMessageError}</Alert.Heading>
          </Alert> */}
                    {this.state.Editloading  === true ? ( 
                      <div class="divLoader">
                        <img alt= "" style={{ marginTop: 50, width: 200, height: 200, marginRight:  0}} src={'https://i.imgur.com/GLdqYB2.gif'}/>
                      </div>
                    ) : ( 
                    //       <div className="dcc">
                    //             <div className="row " style={{ marginTop: 20, marginBottom: 20, width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    //             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: (isMobile) ? '75%' : '100%', fontSize: 22, fontWeight: 'bold', padding: 10  }}>
                    //               我的报告
                    // </div>
                    // <div className="dcc" style={{ marginTop: 20, marginBottom: 20, width: (isMobile) ? 'auto' :'80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    //         <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>From</div>
                    //           <DatePicker
                    //               onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                    //               dateFormat = 'dd-MMM-yyyy'
                    //               placeholder="Date"
                    //               maxDate={new Date()}
                    //               popperPlacement="top-right"
                    //               selected={this.state.Max_Date}
                    //               className={(isMobile) ? "form-control StyleDatePickerMobile" : "form-control StyleDatePicker"}
                    //               withPortal
                    //             />
                    //           <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>To</div>
                    //           <DatePicker
                    //             onChange = {(date) => this.setSelectedDate(date)}
                    //             dateFormat = 'dd-MMM-yyyy'
                    //             placeholder="Date"
                    //             maxDate={new Date()}
                    //             popperPlacement="top-right"
                    //             selected={this.state.Min_Date}
                    //             className={(isMobile) ? "form-control StyleDatePickerMobile" :"form-control StyleDatePicker"}
                    //             withPortal
                    //           />
                    //     </div>
                    //   </div>
                    //       </div>
                      <div>
                        <div className="dcc" style={{ width: '100%', marginBottom: 0 }}>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:  '100%', fontSize: 22, fontWeight: 'bold', padding: 10  }}>
                            我的报告
                          </div>
                        </div>
                        <div className="dcc" style={{ marginBottom: 20, width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <div style={{fontSize: "18px", width: 50,  textAlign: 'center', marginLeft:  '20%'}}>From</div>
                          <DatePicker
                            onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                            dateFormat = 'dd-MMM-yyyy'
                            placeholder="Date"
                            maxDate={new Date()}
                            // popperPlacement="top-right"
                            selected={this.state.Max_Date}
                            className={"form-control StyleDatePicker"}
                            // withPortal
                          />
                          <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>To</div>
                          <DatePicker
                            onChange = {(date) => this.setSelectedDate(date)}
                            dateFormat = 'dd-MMM-yyyy'
                            placeholder="Date"
                            maxDate={new Date()}
                            minDate={new Date(this.state.Max_Date)}
                            // popperPlacement="top-right"
                            selected={this.state.Min_Date}
                            className={"form-control StyleDatePicker"}
                            // withPortal
                          />
                        </div>
                      </div>
                    )}
                    <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                      <div>
                        <button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: 300 }} 
                          disabled={this.state.disabled}
                          onClick={() => this.setState({modalShow: false, Available: true, GenerateDate: moment.tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss') })} 
                          class="btn my-cusomized-button" >
                          提交
                        </button>
			                </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <div style={{ marginBottom: 0, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                {this.state.page > this.state.countpagemin ? (
                  <button  style={{ textAlign: 'center',backgroundColor: '#1e5e8a', color: 'white', width: 100, borderColor: '#1e5e8a' }} 
                    class="btn my-cusomized-button" 
                    onClick={() =>
                      this.setState(
                        {
                          page: this.state.page - 1,
                        },
                        () => {
                          this.changePage(this.state.page);
                        },
                      )
                    }>
                    <ImArrowLeft size="16px" color="white" />
                  </button>
                ) : (
                  <div style={{ width: 100 }} />
                )}
                <div >{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <button
                    style={{ textAlign: 'center', backgroundColor: '#1e5e8a', color: 'white', width: 100, borderColor: '#1e5e8a' }}
                    class="btn my-cusomized-button" 
                    onClick={() =>
                      this.setState(
                        {
                          page: this.state.page + 1,
                        },
                        () => {
                          this.changePage();
                        },
                      )
                    }>
                    <ImArrowRight size="16px" color="white" />
                  </button>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
          )}
        </div>
      </Fragment>    
    )
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GamesUserReport);