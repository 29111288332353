import '../App.css';
import React from 'react';
import { language } from '../language';
import Alert from 'react-bootstrap/Alert'
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Modal from 'react-bootstrap/Modal';
import { Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'
import Header from '../Component/Header';
import CheckMark from '../Images/CheckMark.png';
import {ReturnBetValidation} from '../static/ReturnBetValidation';
// import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Platformcolor } from '../static/PlatformColor';
import {ReturnCancelValidation} from '../static/ReturnCancelValidation';
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
const { CreateBetOrder, getKeyInFormat, CancelBet, getUserKeyIn, searchDownlineHR, getMyselfF } = require('../Api');
var timer
var timer2

class BetBoxVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      token: this.props.token,
      UserID: this.props.userID,
      userID: this.props.userID,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      BetNumbers: [],
      KeyinFormat: '',
      DDate: '请选择日期 (D#)',
      KeyinType: '1',
      PressCount: 0,
      ClaimMagnumStatus: false,
      PlatformSelected: [],
      PrevPlatformSelected: [],
      platform: '',
      PlatformLength: '',
      TestingChecke: false,
      isChecked: '',
      allChecked: false,
      IBox: false,
      Key2: false,
      Mode: '-',
      AllMagnum: false,
      AllChangeKey2: false,
      Key1IBox: [],
      Key2IBox: [],
      PlatformSelectAll: {
        1: false,
        2: false,
        3: false, 
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        // 9: false,
      },
      ModeSelectAll: {
        1: false,
        Mode: '',
      },
      IBoxSelectAll: {
        1: false,
        Mode: '',
      },
      KeyBetAll: {
        0: false,
        1: false,
        2: false, 
        3: false,
        4: false,
        5: false,
      },
      KeyBetAllPassValues: {
        0: '',
        1: '',
        2: '', 
        3: '',
        4: '',
        5: '',
      },
      modalShow: false,
      ModalDate: [{ Date: '' }],
      DisplayDate: [],
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Receipt: '',
      ShowReceipt: false,
      key3D: [],
      key2D: [],
      FiveD: false,
      SixD: false,
      getUsers: [],
      UserIDforCancel: '',
      OriTextforCancel: '',
      RoleforCancel: '',
      ServerIDforCancel: '',
      SuccessFullCancel: false,
      five6D: false,
      PhoneNumber: '',
      GamesDisplay:'',
      ReceiptDisplay: '',
      optionsUsername: '',
      SelectedUser: '',
      DisabledClick: false,
      Gametype: ['none', 'IBox', 'Box', '{0-9}123', '123{0-9}', '{123}4', '1{234}', '*#'],
      Gametype56: ['none',  'Box',],
      // Game3Type: ['none', 'IBox', 'Box', '{0-9}123', '123{0-9}', '{123}4', '1{234}'],
      checkEmpty: '',
      JinNanCheck: '',
      CopyReceipt: '',
      ButtonBackgroundblue: '#3C96F4',
      ButtonBackgroundRed: '#EE3E13',
      is_visible: false,
      OneStar: '',
      PlatformShow: [],
      DisabledCancel: false,
      Balance: 0,
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: '',
      getUserFormula: {},
    }
    this.handleBetNumbers = this.handleBetNumbers.bind(this);
    this.CheckedAllPlatform = this.CheckedAllPlatform.bind(this);
    this.CheckPlatform = this.CheckPlatform.bind(this);
    this.AllKey2 = this.AllKey2.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.ConvertToOriginalWord = this.ConvertToOriginalWord.bind(this);
    this.StartBetting = this.StartBetting.bind(this);
    this.PassToAPI = this.PassToAPI.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
    this.SelectedUser = this.SelectedUser.bind(this);
  }

  async componentDidMount() {
    this.showPlatform();
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ userID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID })
    if ((UserAuthorities && UserAuthorities.Bet) === 0) {
      window.location.href='/Home'; 
    } else {
      this.setState({ OneStar: UserAuthorities.OneStarBao }, ()=> {
        this.StartBetting('');
        this.getDownline();
      })
    }
  }

  showPlatform() {
    const GameBetting = JSON.parse(this.props.UserAuthority).GameBetting
    this.setState({ PlatformShow: GameBetting.split('') })
  }

  async StartBetting(END) {
    const DateLoop = this.GetModalDate();
    const GDisplay = localStorage.getItem('GameDisplay');

    this.setState({
      GamesDisplay: GDisplay,
    })

    let KeyinFunctions = ''
    if (END !== '') {
      const UserKeyIn = await getUserKeyIn(this.state.UserID, this.props.token, this.props.username)
      KeyinFunctions = UserKeyIn[0]
    } else {
      KeyinFunctions = await getKeyInFormat(this.state.username, this.state.token);
    }

    // Balance: KeyinFunctions.CashWallet, 
    if (KeyinFunctions) {
      this.setState({ KeyinFormat: KeyinFunctions || [], ModalDate: DateLoop, DDate: `日期: D# ${DateLoop.DateLoop[0].Date}`  || [], getUserFormula: KeyinFunctions.XingJinFormula}, () => {
        const KeyInResult = this.state.KeyinFormat;
        const CurrentBtFormula = (this.state.AllChangeKey2 === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
        const CurrentBtFormulaLength = CurrentBtFormula.length;
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormula[i]] = ""
        }
        this.setState(prevWordState => ({
          BetNumbers: [
            ...prevWordState.BetNumbers,
            {
              ...prevWordState.BetNumbers,
              Text: "", Bet: { ...object }, Platform: [], SelectedDate: `D#`, IBox: false, Mode: '-', Key2: false, input56D: false, five6D: false, Follow: '',
            },
            {
              ...prevWordState.BetNumbers,
              Text: "", Bet: { ...object }, Platform: [], SelectedDate: '', IBox: false, Mode: '-', Key2: false, input56D: false, five6D: false, Follow: '',
            },
          ]
        }));
        const BtKey1Formula = KeyInResult.KeyIn1 || [];
        const BtKey2Formula = KeyInResult.KeyIn2 || [];
        const BtKey1FormulaLength = BtKey1Formula.length;
        var Key1Box = [];
        var Key2Box = [];
        let Key3Formula = ''
        for (let i = 0; i < BtKey1FormulaLength; i++) {
          Key1Box.push((`(IB)${BtKey1Formula[i]}`).slice(0))
          Key2Box.push((`(IB)${BtKey2Formula[i]}`).slice(0))
        }
        // if(BtKey1Formula.includes('CA1') && BtKey1Formula.includes('CC')) {
        //   Key3Formula = BtKey1Formula.filter(function(item) {
        //     return ['CA1', 'CA2', 'CA3', 'CD', 'CE', 'CC'].includes(item); 
        //   })
        // } else {
        let keyInFormula = BtKey1Formula.filter((item) => item === 'A' || item === 'C')
        if (keyInFormula.length === 0) {
          Key3Formula = ['A', 'C']
        } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
          Key3Formula = ['A']
        } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
          Key3Formula = ['C']
        } else {
          Key3Formula = ['A', 'C']
        }
        // Key3Formula = ['A', 'C']
        const Key2Formula = ['PA', 'PF']
        // }
        this.setState({Key1IBox: Key1Box || [], Key2IBox: Key2Box || [], key3D: Key3Formula, key2D: Key2Formula}, ()=> {
          this[`InputBetNumber${'0'}`].focus();
        })
      })
    }
  }

  async getDownline() {
    const UserID = this.state.userID;

    // const DownlineUsername = await getMyDownline(UserID, token, Username)
    const getMyself = await getMyselfF(UserID, this.props.token, this.props.username)
    if(getMyself) {
      this.setState({ UserID: getMyself[0].ID, SelectedUser: {value: getMyself[0].ID, label: getMyself[0].Username}, JinNanCheck: getMyself[0].JinNan, SearchUser: getMyself[0].Username, getUserFormula: getMyself[0].XingJinFormula }, () => {
        this.getTargetUserKeyIn(this.state.userID);
      })
    }
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  SelectedUser(e) {
    this.setState({ UserID: e.value, SelectedUser: e, SearchUser: e.label }, () => {
      this.getTargetUserKeyIn(this.state.UserID);
      this.handleCloseSearchModal();
    })
  }

  async getTargetUserKeyIn(TargetUserID) {
    const UserKeyIn = await getUserKeyIn(TargetUserID, this.props.token, this.props.username)
    // console.log('UserKeyIn', UserKeyIn)
    if(UserKeyIn) {
      // const testingplat = '123564987'
      // PlatformShow: (UserKeyIn[0].GameBetting).split(''),
      this.setState({ KeyinFormat: UserKeyIn[0] || [], JinNanCheck: UserKeyIn[0].JinNan, OneStar: UserKeyIn[0].OneStarBao, PlatformShow: (UserKeyIn[0].GameBetting).split(''), Balance: UserKeyIn[0].CashWallet, getUserFormula: UserKeyIn[0].XingJinFormula }, () => {
        let ZeroPlat = []
        this.state.PlatformShow.forEach((item, index) => {
          if(item === '-') {
            ZeroPlat.push((index+1).toString())
          }
        })
        if(ZeroPlat.length > 0) {
          let BetNumbers = this.state.BetNumbers
          for (var i=0; i < BetNumbers.length; i++) {
            for (var a=0; a< ZeroPlat.length; a++) {
              const ZeroPlotDetect =  ZeroPlat[a]
              BetNumbers[i].Platform = BetNumbers[i].Platform.filter(item => item !== ZeroPlotDetect)
            }
          }
        }
        const KeyInResult = this.state.KeyinFormat;
        const BtKey1Formula = KeyInResult.KeyIn1 || [];
        const BtKey2Formula = KeyInResult.KeyIn2 || [];
        const BtKey1FormulaLength = BtKey1Formula.length;
        var Key1Box = [];
        var Key2Box = [];
        let Key3Formula = []
        for (let i = 0; i < BtKey1FormulaLength; i++) {
          Key1Box.push((`(IB)${BtKey1Formula[i]}`).slice(0))
          Key2Box.push((`(IB)${BtKey2Formula[i]}`).slice(0))
        }
        // if(BtKey1Formula.includes('CA1') && BtKey1Formula.includes('CC')) {
        //   Key3Formula = BtKey1Formula.filter(function(item) {
        //     return ['CA1', 'CA2', 'CA3', 'CD', 'CE', 'CC'].includes(item); 
        //   })
        // } else {
        //   Key3Formula = ['CA1', 'CA2', 'CA3', 'CD', 'CE', 'CC']
        // }
        let keyInFormula = BtKey1Formula.filter((item) => item === 'A' || item === 'C')
        if (keyInFormula.length === 0) {
          Key3Formula = ['A', 'C']
        } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
          Key3Formula = ['A']
        } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
          Key3Formula = ['C']
        } else {
          Key3Formula = ['A', 'C']
        }
        // Key3Formula = ['A', 'C']
        const Key2Formula = ['PA', 'PF']
        this.updateWordsValuebySelectedUser(this.state.BetNumbers);
        this.setState({Key1IBox: Key1Box || [], Key2IBox: Key2Box || [], key3D: Key3Formula, key2D: Key2Formula })
      });
    }
  }

  async updateWordsValuebySelectedUser(BetNumbers) {
    const KeyinFormat = this.state.KeyinFormat
    const Key1Input = KeyinFormat.KeyIn1
    const Key2Input = KeyinFormat.KeyIn2
    // let Key3Formula = ''
    
    // if(Key1Input.includes('A') && Key1Input.includes('C')) {
    //   Key3Formula = Key1Input.filter(function(item) {
    //     return ['A', 'C'].includes(item); 
    //   })
    // } else {
    //   Key3Formula = Key2Input.filter(function(item) {
    //     return ['A', 'C'].includes(item); 
    //   })
    // }

    // this.setState({ key3D: Key3Formula }, ()=> {
    // })

    if(BetNumbers.length !== 0) {
      for(var i=0; i<BetNumbers.length; i++) {
        const tempObject = {}
        if(BetNumbers[i].Key2 === false) {
          const Bet = BetNumbers[i].Bet
          for(var j=0; j<Object.keys(Bet).length; j++) {
            tempObject[Key1Input[j]] = Object.values(Bet)[j]
          }
          BetNumbers[i].Bet = JSON.parse(JSON.stringify(tempObject))
        } else if(BetNumbers[i].Key2 === true) {
          const Bet = BetNumbers[i].Bet
          for(var e=0; e<Object.keys(Bet).length; e++) {
            tempObject[Key2Input[e]] = Object.values(Bet)[e]
          }
          BetNumbers[i].Bet = JSON.parse(JSON.stringify(tempObject))
        }
      }
    }
  }

  GetModalDate() {
    const DateLoop = [];
    const TodayDate = Moment().format('YYMMDD');
    if(Moment().day() === 0) {
      DateLoop.push({
        Date: Moment().format('DDMM(ddd)'),
        Number: 7,
        CheckMark: false,
      });
    } else {
      if (Moment().format('HH:mm') >= '19:15') {
        for (let i = 1; i < 8; i++) {
          // const Date = Moment().day(i);
          const Date = Moment(Moment()).add(i, 'days');
          if (Moment(Date).format('YYMMDD') >= TodayDate) {
            DateLoop.push({
              Date: Moment(Date).format('DDMM(ddd)'),
              Number: Moment(Date).isoWeekday(),
              CheckMark: false,
            });
          }
        }
      } else {
        for (let i = 1; i < 8; i++) {
          const Date = Moment().day(i);
          if (Moment(Date).format('YYMMDD') >= TodayDate) {
            DateLoop.push({
              Date: Moment(Date).format('DDMM(ddd)'),
              Number: Moment(Date).isoWeekday(),
              CheckMark: false,
            });
          }
        }
      }
    }
    return { DateLoop };
  }

  async SelectDateFunction(Index) {
    const toProcessDateData = this.state.ModalDate;
    if (toProcessDateData.DateLoop[Index.Index].CheckMark === false) {
      toProcessDateData.DateLoop[Index.Index].CheckMark = true;
    } else {
      toProcessDateData.DateLoop[Index.Index].CheckMark = false;
    }
    this.setState({ ModalDate: toProcessDateData });
  }

  onPressConfirmSelectDate() {
    const { DisplayDate } = this.state;
    const BetNumbers = [...this.state.BetNumbers]
    const toProcessDateData = this.state.ModalDate;
    let DateData = '';
    let DateDisplay = '';
    for (let i = 0; i < toProcessDateData.DateLoop.length; i++) {
      if (toProcessDateData.DateLoop[i].CheckMark === true) {
        DateData += toProcessDateData.DateLoop[i].Number;
        DateDisplay += toProcessDateData.DateLoop[i].Date;
      }
    }
    if (DateDisplay !== ''){
      BetNumbers[0] = { ...BetNumbers[0], SelectedDate: `D#*${DateData}` }
      DisplayDate[0] = `日期： D# ${DateDisplay}`
    } else {
      DisplayDate[0] = `请选择日期 (D#)`
      BetNumbers[0] = { ...BetNumbers[0], SelectedDate: '' }
      this.setState({selectedDate: []})
    }
    this.setState({ modalShow: false, DDate: DisplayDate[0], BetNumbers: BetNumbers  });
  }

  onpressLongTerm() {
    const toProcessDateData = this.state.ModalDate;
    const BetNumbers = [...this.state.BetNumbers]

    for(var i = 0; i < toProcessDateData.DateLoop.length; i++) {
      if(toProcessDateData.DateLoop[i].CheckMark === true) {
        toProcessDateData.DateLoop[i].CheckMark = false
      }
    }

    BetNumbers[0] = { ...BetNumbers[0], SelectedDate: `D#*` }
    this.setState({ modalShow: false, DDate: '日期： D#AUTO', BetNumbers: BetNumbers, ModalDate: toProcessDateData });
  }

  async ConfirmSaveBet() {
    const BetNumbers = this.state.BetNumbers;
    const BetNumberslength = (BetNumbers[BetNumbers.length - 1].Text === '') ? BetNumbers.length - 1 : BetNumbers.length;
    const PlatformCheck = [];
    const SelectedDateCheck = [];
    const EmptyBetN = [];
    const BetNLength = [];
    const KeyNumberValuesCheck = [];
    const BetNLengthArray = [];
    const fiveDErrorArray = [];
    const SixDErrorArray = [];
    let where = '';
    let insertgame = '';
    let platformGame = '';

    for(var i = 0; i < BetNumberslength; i++) {
      BetNLengthArray.push((BetNumbers[i].Text).length)
      const ObjectValueBet = Object.values(BetNumbers[i].Bet);
      if(!BetNumbers[i].Platform.length) {
        PlatformCheck.push('Fail')
        platformGame += `${i + 1},`
      } else {
        PlatformCheck.push('Completed')
      }
      if(BetNLengthArray.includes(0)) {
        EmptyBetN.push('Fail')
        insertgame += `${i + 1},`
      } else if (BetNLengthArray.includes(1)) {
        BetNLength.push('Fail')
      } else {
        BetNLength.push('Completed')
        EmptyBetN.push('Completed')
      }
      if(ObjectValueBet[0] === '' && ObjectValueBet[1] === '' 
            && ObjectValueBet[2] === '' && ObjectValueBet[3] === ''
            && ObjectValueBet[4] === '' && ObjectValueBet[5] === '') {
        KeyNumberValuesCheck.push('Fail')
        where += `${i + 1},`
      } 
      else {
        KeyNumberValuesCheck.push('Completed')
      }
      if(((BetNumbers[i].Text).length === 5 && BetNumbers[i].Mode !== '-') 
      || ((BetNumbers[i].Text).length === 5 && BetNumbers[i].IBox === true)) {
        fiveDErrorArray.push('Fail')
      } else {
        fiveDErrorArray.push('Completed')
      }
      if(((BetNumbers[i].Text).length === 6 && BetNumbers[i].Mode !== '-')
      || ((BetNumbers[i].Text).length === 6 && BetNumbers[i].IBox === true)) {
        SixDErrorArray.push('Fail')
      } else {
        SixDErrorArray.push('Completed')
      }
    }

    if(BetNumbers[0].SelectedDate === "") {
      SelectedDateCheck.push('Fail');
    } else {
      SelectedDateCheck.push('Completed');
    }

    if(SelectedDateCheck.includes('Fail') === true) {
      this.openNotification('PleaseSelectDate')
      this.setState({ notificationType: 'error' });
    } else if(EmptyBetN.includes('Fail')) {
      this.openNotification('pleaseinputallyourgames', insertgame)
      this.setState({ notificationType: 'error' });
    } else if(KeyNumberValuesCheck.includes('Fail')) {
      this.openNotification('SomeKeyValueNotKeyin', where)
      this.setState({ notificationType: 'error' });
    } else if(PlatformCheck.includes('Fail') === true) {
      this.openNotification('PlatFormError', platformGame)
      this.setState({ notificationType: 'error' });
    } else if(BetNLength.includes('Fail')) {
      this.openNotification('minimum4Word')
      this.setState({ notificationType: 'error' });
    } 
    // else if(fiveDErrorArray.includes('Fail') === true) {
    //   this.openNotification('donotacceptModein5D', fiveDError)
    //   this.setState({ notificationType: 'error' });
    // } else if(SixDErrorArray.includes('Fail') === true) {
    //   this.openNotification('donotacceptModein6D', SixDError)
    //   this.setState({ notificationType: 'error' });
    // } 

    ///////////////// addd up
    // else if (Over7pm === false) {
    //   this.openNotification('ExceededHoursNormal')
    //   this.setState({ notificationType: 'error', loading: false });
    // } 
    else {
      for(let a=0; a<BetNumbers.length; a++ ) {
        if(BetNumbers[a].Text.length === 3) {
          const key3 = (BetNumbers[a].Key2 === false) ? this.state.key3D : ['CA','CB', 'CC', 'CD', 'CE', 'CF']
          const tempObject = {}
          // const FilterBetNumbers = Object.values(BetNumbers[a].Bet).filter(item => item !== '');
          for(let i=0; i<key3.length; i++) {
            tempObject[key3[i]] = Object.values(BetNumbers[a].Bet)[i] || ''
          }
          BetNumbers[a] = { ...BetNumbers[a], Bet: { ...tempObject } }
          this.setState({ BetNumbers })
        }
        if(BetNumbers[a].Text.length === 2) {
          const key3 = (BetNumbers[a].Key2 === false) ? this.state.key2D : ['PA', 'PB', 'PC', 'PD', 'PE', 'PF']
          const tempObject = {}
          // const FilterBetNumbers = Object.values(BetNumbers[a].Bet).filter(item => item !== '');
          for(let i=0; i<key3.length; i++) {
            tempObject[key3[i]] = Object.values(BetNumbers[a].Bet)[i] || ''
          }
          BetNumbers[a] = { ...BetNumbers[a], Bet: { ...tempObject } }
          this.setState({ BetNumbers })
        }
      }

      this.ConvertToOriginalWord(BetNumbers);
    }
  }

  async ConvertToOriginalWord(BetNumbers) {
    const OriginalWord = [];
    const BetNumberslength = BetNumbers.length;
    const PhoneNumber = '';

    for(var i=0; i<BetNumberslength; i++) {
      if(BetNumbers[i].SelectedDate !== '') {
        OriginalWord.push(`${BetNumbers[i].SelectedDate}\n`)
      }
      if(BetNumbers[i].Text !== '') {
        const Mode = BetNumbers[i].Mode
        const Text = BetNumbers[i].Text
        const IBox = BetNumbers[i].IBox
        const Platform = BetNumbers[i].Platform
        const PrevPlatform = BetNumbers[i - 1 < 0 ? 0 : i - 1].Platform || []
        const BetKeyValueKeys = Object.values(BetNumbers[i].Bet)
        const Star = this.state.OneStar
        const CheckKey2 = BetNumbers[i].Key2
        // Generate Mode with Text Format
        const TextModeFilter = (Mode === 'Box' ? ((Star === 1) ? '*' : '**') : Mode === '{0-9}123' ? '#' : Mode === '123{0-9}' ? '##' 
          : IBox === true ? ((Star === 1) ? '**' : '*') : '')
                    + (Mode === '1{234}' ? `${Text.substring(0,1)}*${Text.substring(1,5)}`
                      : Mode === '{123}4' ? ((Text.split('').length === 3) ? `${Text.substring(0,2)}*${Text.substring(2,4)}` 
                        : `${Text.substring(0,3)}*${Text.substring(3,5)}`): Text)
                    + (BetNumbers[i].Key2 === true ? `**` : '')

        // Generate Bet KeyValues with Originalword Format
        const KeyBetValues = (BetKeyValueKeys[1] === '' && BetKeyValueKeys[2] === '' && BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ? 
          '#' + BetKeyValueKeys[0] : ( BetKeyValueKeys[2] === '' && BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ?
            '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] : ( BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ? 
              '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2]  : (BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ?
                '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3] : ( BetKeyValueKeys[5] === '') ?
                  '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3]+ '#' + BetKeyValueKeys[4] :
                  '#' + BetKeyValueKeys[0] + '#'  + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3] + '#' + BetKeyValueKeys[4] + '#' + BetKeyValueKeys[5]

        const KeyBetValues3 = '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1]
        //formula 1
        const KeyBetFormat = '*#' + BetKeyValueKeys[0]
        const Key56Format = '#' + BetKeyValueKeys[0]

        // Push into OriginalWord Array
        if(this.state.JinNanCheck === 1) {
          OriginalWord.push(
            `${(OriginalWord.length === 1 || (Platform.sort()).join('') !== (PrevPlatform.sort()).join('')) 
              ? '#' + (Platform.sort()).join('') + '\n'
              : ''}`)
        } else {
          OriginalWord.push(
            `${(OriginalWord.length === 1 || (Platform.sort()).join('') !== (PrevPlatform.sort()).join('')) 
              ? (Platform.sort()).join('') + '\n'
              : ''}`)
        }
        // Push into OriginalWord Array
        OriginalWord.push(`${TextModeFilter}${((Text.length === 3 || Text.length === 2) && Mode !== '*#' && CheckKey2 === false) ? KeyBetValues3 : ((Text.length === 3 || Text.length === 2) && Mode !== '*#' && CheckKey2 === true) ? KeyBetValues : (Text.length === 4 && Mode !== '*#') ? KeyBetValues : (Text.length > 4 && Mode !== '*#') ? Key56Format : (Text.length === 4 && Mode === '*#') ? KeyBetFormat :  KeyBetValues}\n`)
      }
    }
    if(PhoneNumber !== '') {
      OriginalWord.push(PhoneNumber)
    }
    this.PassToAPI(OriginalWord);
  }

  async PassToAPI(OriginalWord) {
    const UserID = this.state.UserID;
    const OriWord = OriginalWord.join('')
    this.setState({ DisabledClick: true })
    // console.log('OriWord', OriWord)
    const BetOrder = await CreateBetOrder(UserID, OriWord, this.props.token, this.props.username)
    if(BetOrder.error) {
      this.openNotification(ReturnBetValidation(BetOrder.error), '', '', (BetOrder.balance !== undefined) ? BetOrder.balance : '' )
      this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    } else {
      clearTimeout(timer2)
      timer2 = setTimeout(() => {
        this.SelectedUser(this.state.SelectedUser, '')
      }, 1000);
      this.openNotification('SuccessOrderBet')
      this.setState({
        DisabledClick: false,
        ShowReceipt: true,
        notificationType: 'success',
        SuccessFullCancel: false,
        Receipt: BetOrder.receipt,
        UserIDforCancel: BetOrder.UserID,
        OriTextforCancel: BetOrder.OriginalText,
        RoleforCancel: BetOrder.Role,
        ServerIDforCancel: BetOrder.ServerID,
        CopyReceipt: '',
      }, () => {
        this.ConvertResultRed(this.state.Receipt)
        this.CleanData();
      });
    }
  }

  async CancelBetAPI() {
    this.setState({ DisabledCancel: true })
    const ServerID = this.state.ServerIDforCancel
        
    const CancelBets = await CancelBet(ServerID, this.props.username, this.props.token)
    if(CancelBets.error) {
      this.openNotification(ReturnCancelValidation(CancelBets.error))
      this.setState({ DisabledCancel: false, notificationType: 'error' })
    } else {
      if(CancelBets.message === 'Cancel Successful') {
        this.setState({
          UserIDforCancel: '',
          SuccessFullCancel: true,
          OriTextforCancel: '',
          RoleforCancel: '',
          // ServerIDforCancel: '',
          Receipt: CancelBets.CancelReceipt,
          DisabledCancel: false,
        }, ()=> {
          this.ConvertResultRed(this.state.Receipt)
        });
      }
    }
  }

  CheckPlatform(e, item, idx) {
    const BetNumbers = JSON.parse(JSON.stringify(this.state.BetNumbers));
    const BetNumberLength = BetNumbers.length
    const target = e.target;

    if(item === 'All') {
      if(target.checked) {
        for(var i = 0; i < BetNumberLength; i ++) {
          !BetNumbers[i].Platform.includes(e.target.name) && BetNumbers[i].Platform.push(e.target.name)
        }
        this.setState(prevState => ({
          PlatformSelectAll: {
            ...prevState.PlatformSelectAll,   
            [target.name]: true
          }
        }))
      } else {
        for(var a = 0; a < BetNumberLength; a ++) {
          const Insertedplatform = BetNumbers[a].Platform;
          var index = Insertedplatform.indexOf(e.target.name);
          delete Insertedplatform[index];
    
          const FilterPlatform = Insertedplatform.filter(x => x !== null)
          // const JoinFilter = (FilterPlatform).join('')
    
          BetNumbers[a] = { ...BetNumbers[a], Platform: FilterPlatform}
        }
        this.setState(prevState => ({
          PlatformSelectAll: {
            ...prevState.PlatformSelectAll,
            [target.name]: false
          }
        }))
      }
      this.setState({ BetNumbers: BetNumbers})
    } else {
      if(target.checked) {
        !BetNumbers[idx].Platform.includes(item) && BetNumbers[idx].Platform.push(item)
        this.setState({ BetNumbers: BetNumbers })
      } else {
        const Insertedplatform = BetNumbers[idx].Platform;
        var inde = Insertedplatform.indexOf(item);
        delete Insertedplatform[inde];    
        const FilterPlatform = Insertedplatform.filter(x => x !== null)  
        BetNumbers[idx] = { ...BetNumbers[idx], Platform: FilterPlatform }
        this.setState(prevState => ({
          PlatformSelectAll: {
            ...prevState.PlatformSelectAll,   
            [target.name]: false
          }
        }))
        this.setState({ BetNumbers: BetNumbers })
      }
      // this[`InputBetNumber${idx}`].focus();
    }
  }

    handleRemoveNumbersChange = idx => () => {
      var BetNum = this.state.BetNumbers;
      var toRemove = idx;
      var index = BetNum.indexOf(toRemove);
      if (index > -1) {
        BetNum.splice(index, 1);
      }
      this.setState({
        BetNumbers: this.state.BetNumbers.filter((s, sidx) => idx !== sidx)
      }, () => {
        this.openNotification('SuccessDeleted', idx+1, BetNum[idx].Text)
        this.setState({ notificationType: 'success' });
      });
    };

    async CheckedAllPlatform(event) {
      let checked = event.target.checked;
      const itemName = event.target.name

      this.setState(prevState => {
        let { allChecked, isChecked } = prevState;
        if (itemName === 'checkAllMagnum') {
          allChecked = checked;
          isChecked = checked;
        } else {
          isChecked = !checked;
        }
        return { allChecked, isChecked };
      })
    }

    async CheckBoxKeyPressEvent(e, idx) {
      if (e.key === 'Enter') {
        const BetNum = this.state.BetNumbers[idx];
        if (BetNum.Text === '') {
          this.openNotification('pleaseinputyourgames')
          this.setState({ notificationType: 'error' });
          this[`InputBetNumber${idx}`].focus();
        } else {
          this.handleAddBetLine(idx);
        }
      }
    }

    async KeyPressEvent(e, idx, Selected, Status) {
      if (Status === 'Edit') {
        const KeyInResult = this.state.KeyinFormat;
        const SelectedBetNumbers = this.state.BetNumbers[idx];
        const BetNumbersKey2 = SelectedBetNumbers.Key2;
        const CurrentBtFormula = (BetNumbersKey2 === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
        const Keyvalue = CurrentBtFormula.map((item, index) => {
          if (Selected === item) {
            return index;
          }
          return undefined
        })
        const finalKeyvalue = Keyvalue.filter(x => x !== undefined)

        if (finalKeyvalue[0] === 0) {
          this.setState({ PressCount: 1 })
        } else if (finalKeyvalue[0] === 1) {
          this.setState({ PressCount: 2 })
        } else if (finalKeyvalue[0] === 2) {
          this.setState({ PressCount: 3 })
        } else if (finalKeyvalue[0] === 3) {
          this.setState({ PressCount: 4 })
        } else if (finalKeyvalue[0] === 4) {
          this.setState({ PressCount: 5 })
        } else if (finalKeyvalue[0] === 5) {
          this.setState({ PressCount: 6 })
        } else {
          this.setState({ PressCount: 0 })
        }
      } else {
        const BetNumbers = this.state.BetNumbers
        const BetNumberLength = BetNumbers.length
        const BetNLengthArray = [];
        // let ObjectValueBet = ''

        for(var i=0; i<BetNumberLength; i++) {
          BetNLengthArray.push((BetNumbers[i].Text).length)
        }
        if (e.key === 'Enter') {
          if (BetNLengthArray.includes(0) && BetNLengthArray.length === 2) {
            if((BetNumbers[0].Text).length === 0 || idx !== 0) {
              this.openNotification('pleaseinputyourgames')
              this.setState({ notificationType: 'error' });
            }
            this[(idx === 0) ? `InputBetNumber${idx + 1}` : `InputBetNumber${idx}`].focus();
          } 
          else if (BetNLengthArray.includes(0)) {
            if((BetNumbers[0].Text).length === 0 || idx !== 0) {
              this.openNotification('pleaseinputyourgames')
              this.setState({ notificationType: 'error' });
            }
            this[`InputBetNumber${idx}`].focus();
          }  else if(BetNLengthArray.includes(1)) {
            this.openNotification('minimum4Word')
            this.setState({ notificationType: 'error' });
          } else {
            this.setState({ PlatformSelected: [], PlatformLength: ''})
            e.preventDefault();
            this.setState({ PressCount: 0 })
            this.handleAddBetLine(idx);
          }
        }
        if (e.key === '+') {
          const SelectedBetNumbers = this.state.BetNumbers[idx];
          if(SelectedBetNumbers.five6D === true) {
            if(Selected === '0') {
              this[`InputBet1${idx}`].focus();
            } else if(Selected === '1') {
              this[`InputBetNumber${idx}`].focus();
            }
          } else if((SelectedBetNumbers.five6D === false && SelectedBetNumbers.input56D === true && SelectedBetNumbers.Key2 === false)) {
            if(Selected === '0') {
              this[`InputBet1${idx}`].focus();
            } else if(Selected === '1') {
              this[`InputBet2${idx}`].focus();
            } else if(Selected === '2') {
              this[`InputBetNumber${idx}`].focus();
            }
          }  else if((SelectedBetNumbers.TwoD === true && SelectedBetNumbers.Key2 === false)) {
            if(Selected === '0') {
              this[`InputBet1${idx}`].focus();
            } else if(Selected === '1') {
              this[`InputBet2${idx}`].focus();
            } else if(Selected === '2') {
              this[`InputBetNumber${idx}`].focus();
            }
          } else {
            if(Selected === '0') {
              this[`InputBet1${idx}`].focus();
            } else if(Selected === '1') {
              this[`InputBet2${idx}`].focus();
            } else if(Selected === '2') {
              this[`InputBet3${idx}`].focus();
            } else if(Selected === '3') {
              this[`InputBet4${idx}`].focus();
            } else if(Selected === '4') {
              this[`InputBet5${idx}`].focus();
            } else if(Selected === '5') {
              this[`InputBet6${idx}`].focus();
            } else if(Selected === '6') {
              this[`InputBetNumber${idx}`].focus();
            }
          }
          e.preventDefault();
        }
        if(e.key === ' ') {
          e.preventDefault();
          this.ConfirmSaveBet();
        }
        if(e.key === 'q') {
          e.preventDefault();
          this.CleanData();
          this.setState({ Receipt: '', ShowReceipt: false})
        }
      }
    }

    handleBetNumbers(idx, evt, item) {
      let inputstr = '';
      if(evt.target.name === 'Text') {
        inputstr = (evt.target.value).replace(/[^0-9*]/g, '');
      } else {
        inputstr = (evt.target.value).replace(/[^0-9.*]/g, '');
      }
      var Replacevalue = inputstr.replace(/[*]/g, '.');
      const num = Replacevalue.replace(/\./,"#").replace(/\./g,"").replace(/#/,".")

      const KeyInResult = this.state.KeyinFormat;
      const BetNumbers = this.state.BetNumbers;
      const SelectedBetnum = this.state.BetNumbers[idx];
      const BetNumLength = BetNumbers.length
      const IBoxCheck = SelectedBetnum.IBox
      const Key2Check = SelectedBetnum.Key2;
      const Key1IBox = this.state.Key1IBox || [];
      const Key2IBox = this.state.Key2IBox || [];

      if(Key2Check === false) {
        const CurrentBtFormulas = (IBoxCheck === true) ? Key1IBox 
          : KeyInResult.KeyIn1 || [];
        const CurrentBtFormulaLength = CurrentBtFormulas.length;
        const Key2FormatBetValues = Object.values(BetNumbers[idx].Bet)
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormulas[i]] = Key2FormatBetValues[i]
        }
        BetNumbers[idx] = { ...BetNumbers[idx], Bet: { ...object }}
      } else {
        const CurrentBtFormulas = (IBoxCheck === true) ? Key2IBox 
          : KeyInResult.KeyIn2 || [];
        const CurrentBtFormulaLength = CurrentBtFormulas.length;
        const Key2FormatBetValues = Object.values(BetNumbers[idx].Bet)
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormulas[i]] = Key2FormatBetValues[i]
        }
        BetNumbers[idx] = { ...BetNumbers[idx], Bet: { ...object }}
      }
      if(IBoxCheck === false) {
        const CurrentBtFormulas = (Key2Check === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
        const CurrentBtFormulaLength = CurrentBtFormulas.length;
        const Key2FormatBetValues = Object.values(BetNumbers[idx].Bet)
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormulas[i]] = Key2FormatBetValues[i]
        }
        BetNumbers[idx] = { ...BetNumbers[idx], Bet: { ...object }}
      }

      const CurrentBtFormula = (Key2Check === true && IBoxCheck === false) ? KeyInResult.KeyIn2
        : (Key2Check === false && IBoxCheck === true) ? Key1IBox
          : (Key2Check === true && IBoxCheck === true) ? Key2IBox
            : (Key2Check === false && IBoxCheck === false) ? KeyInResult.KeyIn1
              : KeyInResult.KeyIn1 || [];

      if(item === 'All') {
        if(evt.target.checked) {
          const targetname = evt.target.name;
          const BetNumberChange = Object.values(BetNumbers[idx].Bet)[targetname];
          for(var i = 0; i < BetNumLength; i++){
            if(BetNumbers[i].IBox === true) {
              const CurrentBtFormulas = (BetNumbers[i].Key2 === true) ? Key2IBox : Key1IBox || [];
              const CurrentBtFormulaLength = CurrentBtFormulas.length;
              const Key2FormatBetValues = Object.values(BetNumbers[i].Bet)
              const object = {}
              for (let a = 0; a < CurrentBtFormulaLength; a++) {
                object[CurrentBtFormulas[a]] = Key2FormatBetValues[a]
              }
              BetNumbers[i] = { ...BetNumbers[i], Bet: { ...object }}
              this.setState({ BetNumbers })
            }
            if((BetNumbers[i].Text).split('').length !== 5 && (BetNumbers[i].Text).split('').length !== 6) {
              const BetValuesToChange = {
                ...BetNumbers[i].Bet,
                [Object.keys(BetNumbers[i].Bet)[targetname]]: BetNumberChange || '',
              }
              BetNumbers[i] = { ...BetNumbers[i], Bet: { ...BetValuesToChange }}
              // this.setState({ BetNumbers })
            }
          }
          this.setState(prevState => ({
            KeyBetAll: {
              ...prevState.KeyBetAll,   
              [evt.target.name]: true,
            }, 
            KeyBetAllPassValues: {
              ...prevState.KeyBetAllPassValues,
              [evt.target.name]: BetNumberChange,
            }
          }))
        } else {
          this.setState(prevState => ({
            KeyBetAll: {
              ...prevState.KeyBetAll,   
              [evt.target.name]: false,
            },
            KeyBetAllPassValues: {
              ...prevState.KeyBetAllPassValues,
              [evt.target.name]: '',
            }
          }))
        }
        this[`InputBetNumber${idx}`].focus();
      } else {
        const Key2 = this.state.Key2
        // const IBox = this.state.IBox
        // const ModeSelectAll = this.state.ModeSelectAll
        const KeyBetAllPassValues = this.state.KeyBetAllPassValues
        let datatableProps = {}
 
        const newBettingNumbers = this.state.BetNumbers.map((Betting, sidx) => {

          datatableProps =  { ...Betting.Bet, [CurrentBtFormula[this.state.PressCount - 1]]: num || '' };
          const KeyInResult = this.state.KeyinFormat;
          const CurrentBtFormula2 = (Key2 === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
          const CurrentBtFormulaLength = CurrentBtFormula2.length;
          const object = {}
          for (let i = 0; i < CurrentBtFormulaLength; i++) {
            object[CurrentBtFormula2[i]] = KeyBetAllPassValues[i]
          }

          if (this.state.PressCount === 0) {
            if (idx !== sidx) return Betting;
            if(num.length === 2)  return ({
              ...Betting, Text: num, input56D: false, five6D: false, Mode: '-', IBox: false, TwoD: true,
              // IBox: (IBox === true) ? true : false ,
              Bet: { ...datatableProps }
            });
            if(num.length === 3)  return ({
              ...Betting, Text: num, input56D: true, five6D: false, TwoD: false,
              // IBox: (IBox === true) ? true : false ,
              Bet: { ...datatableProps }
            });
            if (num.length === 5 || num.length === 6) return ({
              ...Betting, Text: num, input56D: true, IBox: false, Key2: false, Mode: '-', five6D: true, TwoD: false,
              Bet: { ...datatableProps, S: '', CA1: '', CC: '', A1: '', AC: ''}
            }); 
            if (Key2 === false)
              return ({
                ...Betting, Text: num, input56D: false, five6D: false, TwoD: false,
                Key2: (Key2 === true) ? true : false,
                // IBox: (Betting.IBox === true) ? true : false,
                // Mode: (ModeSelectAll['1'] === true ? ModeSelectAll.Mode : '-'),
                Bet: { ...object },
              });
            if (Key2 === true)
              return ({
                ...Betting, Text: num, input56D: false, five6D: false, TwoD: false,
                Key2: (Key2 === true) ? true : false,
                // IBox: (Betting.IBox === true) ? true : false, 
                // Mode: (ModeSelectAll['1'] === true ? ModeSelectAll.Mode : '-'),
                Bet: { ...object },
              });
            return ({
              ...Betting, Text: num, input56D: false, five6D: false, TwoD: false,
              Key2: (Key2 === true) ? true : false,
              // IBox: (Betting.IBox === true) ? true : false, 
              // Mode: (ModeSelectAll['1'] === true ? ModeSelectAll.Mode : '-'),
              Bet: { ...datatableProps  },
            });
          }

          if (this.state.PressCount && this.state.PressCount > 0) {
            if (idx !== sidx) return Betting;
            if ((Betting.Text).split('').length === 3) return ({ ...Betting, Bet: { ...datatableProps }})
            return ({ ...Betting, Bet: { ...datatableProps }})
          } else if (this.state.PressCount > 6) {
            return Betting;
          };
          return Betting;
        });
        this.setState({ BetNumbers: newBettingNumbers }, () => {
          if (this.state.BetNumbers[idx].Mode === '*#' && this.state.BetNumbers[idx].Text.length === 3) {
            let BetNumbers = this.state.BetNumbers
            BetNumbers[idx].Mode = '-'
            this.setState({ BetNumbers: BetNumbers });
          }
        });
        this.setState(prevState => ({
          KeyBetAll: {
            ...prevState.KeyBetAll,   
            [evt.target.name]: false,
          },
          KeyBetAllPassValues: {
            ...prevState.KeyBetAllPassValues,
            [evt.target.name]: '',
          }
        }))
      }
    };

    async handleAddBetLine(idx) {
      const PF = [];
      const PlatformSelectAll = this.state.PlatformSelectAll
      const KeyBetAllPassValues = this.state.KeyBetAllPassValues
      if (PlatformSelectAll[1] === true) {
        PF.push(Object.keys(PlatformSelectAll)[0])
      }
      if (PlatformSelectAll[2] === true) {
        PF.push(Object.keys(PlatformSelectAll)[1])
      }
      if (PlatformSelectAll[3] === true) {
        PF.push(Object.keys(PlatformSelectAll)[2])
      }
      if (PlatformSelectAll[4] === true) {
        PF.push(Object.keys(PlatformSelectAll)[3])
      }
      if (PlatformSelectAll[5] === true) {
        PF.push(Object.keys(PlatformSelectAll)[4])
      }
      if (PlatformSelectAll[6] === true) {
        PF.push(Object.keys(PlatformSelectAll)[5])
      }
      if (PlatformSelectAll[7] === true) {
        PF.push(Object.keys(PlatformSelectAll)[6])
      }
      if (PlatformSelectAll[8] === true) {
        PF.push(Object.keys(PlatformSelectAll)[7])
      }
      // if (PlatformSelectAll[9] === true) {
      //   PF.push(Object.keys(PlatformSelectAll)[8])
      // }
      if (this.state.Key2 === true) {
        const KeyInResult = this.state.KeyinFormat;
        const CurrentBtFormula = KeyInResult.KeyIn2 || [];
        const CurrentBtFormulaLength = CurrentBtFormula.length;
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormula[i]] = KeyBetAllPassValues[i]
        }
        this.setState(({
          BetNumbers: [
            ...this.state.BetNumbers,
            {
              Text: '',
              Bet: {
                ...object,
              },
              Platform: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.Platform : PF,
              SelectedDate: '',
              IBox: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.IBox : (this.state.IBoxSelectAll[1] === true) ? true : false,
              Key2: true,
              Mode: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.Mode : (this.state.ModeSelectAll['Mode'] !== '' ? this.state.ModeSelectAll['Mode'] : ''),
              input56D: false,
              five6D: false,
              Follow: (this.state.CopyReceipt !== '') ? 'disable' : '',
            },
          ],
          IBox: (this.state.IBoxSelectAll[1] === true) ? true : false,
        }), ()=> {
          this.copyBetValue(idx);
        });
      } else {
        const KeyInResult = this.state.KeyinFormat;
        const CurrentBtFormula = KeyInResult.KeyIn1 || [];
        const CurrentBtFormulaLength = CurrentBtFormula.length;
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormula[i]] = KeyBetAllPassValues[i]
        }
        this.setState(({
          BetNumbers: [
            ...this.state.BetNumbers,
            {
              Text: '',
              Bet: {
                ...object,
              },
              Platform: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.Platform : PF,
              SelectedDate: '',
              IBox: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.IBox : (this.state.IBoxSelectAll[1] === true) ? true : false,
              Key2: false,
              Mode: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.Mode : (this.state.ModeSelectAll['Mode'] !== '' ? this.state.ModeSelectAll['Mode'] : ''),
              input56D: false,
              five6D: false,
              Follow: (this.state.CopyReceipt !== '') ? 'disable' : '',
            },
          ],
          IBox: (this.state.IBoxSelectAll[1] === true) ? true : false,
        }), ()=> {
          this.copyBetValue(idx);
        });
      }
    };

    async copyBetValue(idx) {
      let BetArayObject = [...this.state.BetNumbers]
      const KeyInResult = this.state.KeyinFormat;
      const SelectedBetnum = this.state.BetNumbers[idx];
      const IBoxCheck = SelectedBetnum.IBox
      const Key2Check = SelectedBetnum.Key2;
      const Key1IBox = this.state.Key1IBox || [];
      const Key2IBox = this.state.Key2IBox || [];
      const CurrentBtFormula = (Key2Check === true && IBoxCheck === false) ? KeyInResult.KeyIn2
        : (Key2Check === false && IBoxCheck === true) ? Key1IBox
          : (Key2Check === true && IBoxCheck === true) ? Key2IBox
            : (Key2Check === false && IBoxCheck === false) ? KeyInResult.KeyIn1
              : KeyInResult.KeyIn1 || [];
      const tempObject = {}
      let CheckEmptyArray = []

      for(var v = 0; v < 6; v++) {
        if(this.state.BetNumbers[idx].Bet[CurrentBtFormula[v]] !== '') {
          CheckEmptyArray.push('NotEmpty')
        }
      }
      if(CheckEmptyArray.includes('NotEmpty')) {
        //ignore
      } else {
        if(this.state.BetNumbers[idx - 1] === undefined) {
          this.openNotification('pleaseinputyourgames')
          this.setState({ notificationType: 'error' });
        } else {
          if (this.state.BetNumbers[idx].Text.length > 4) {
            tempObject[CurrentBtFormula[0]] = Object.values(this.state.BetNumbers[idx - 1].Bet)[0]
            tempObject[CurrentBtFormula[1]] = ''
            tempObject[CurrentBtFormula[2]] = ''
            tempObject[CurrentBtFormula[3]] = ''
            tempObject[CurrentBtFormula[4]] = ''
            tempObject[CurrentBtFormula[5]] = ''
          } else if ((this.state.BetNumbers[idx].Text.length === 2 || this.state.BetNumbers[idx].Text.length === 3) && this.state.BetNumbers[idx].Key2 === false) {
            tempObject[CurrentBtFormula[0]] = Object.values(this.state.BetNumbers[idx - 1].Bet)[0]
            tempObject[CurrentBtFormula[1]] = Object.values(this.state.BetNumbers[idx - 1].Bet)[1]
            tempObject[CurrentBtFormula[2]] = ''
            tempObject[CurrentBtFormula[3]] = ''
            tempObject[CurrentBtFormula[4]] = ''
            tempObject[CurrentBtFormula[5]] = ''
          } else {
            for(var t = 0; t < 6; t++) {
              tempObject[CurrentBtFormula[t]] = Object.values(this.state.BetNumbers[idx - 1].Bet)[t]
            }
          }
          BetArayObject[idx] = { ...BetArayObject[idx], Bet: { ...tempObject }};

          this.setState({ BetNumbers: BetArayObject })
        }
      }
    }

    Key2Checked = idx => evt => {
      let checked = evt.target.checked;
      const BetNumbers = JSON.parse(JSON.stringify(this.state.BetNumbers));
      const BetNumberLength = BetNumbers.length
      if(evt.target.value === 'AllKey2') {
        if (checked) {
          for(var a = 0; a < BetNumberLength; a++) {
            if((BetNumbers[a].Text).split('').length !== 5 && (BetNumbers[a].Text).split('').length !== 6) {
              const KeyInResult = this.state.KeyinFormat;
              const CurrentBtFormula = (BetNumbers[a].IBox === true) ? this.state.Key2IBox : KeyInResult.KeyIn2 || [];
              const CurrentBtFormulaLength = CurrentBtFormula.length;
              const BetNumberSelectedText = BetNumbers[a].Text
              const CleanText = BetNumberSelectedText.replace(/[^0-9.]/g, '');
              const Key2FormatBetValues = Object.values(BetNumbers[a].Bet)
              const object = {}
              for (let i = 0; i < CurrentBtFormulaLength; i++) {
                object[CurrentBtFormula[i]] = Key2FormatBetValues[i]
              }
              BetNumbers[a] = { ...BetNumbers[a], Text: CleanText, Bet: { ...object }, Key2: true }
              this.setState({ BetNumbers: BetNumbers, Key2: true})
            }
          }
        } else {
          for(var b = 0; b < BetNumberLength; b++) {
            const KeyInResult = this.state.KeyinFormat;
            const CurrentBtFormula = (BetNumbers[b].IBox === true) ? this.state.Key1IBox : KeyInResult.KeyIn1 || [];
            const CurrentBtFormulaLength = CurrentBtFormula.length;
            const Key2FormatBetValues = Object.values(BetNumbers[b].Bet)
    
            const object = {}
            for (let i = 0; i < CurrentBtFormulaLength; i++) {
              object[CurrentBtFormula[i]] = Key2FormatBetValues[i]
            }
            BetNumbers[b] = { ...BetNumbers[b], Bet: { ...object }, Key2: false };
            this.setState({ BetNumbers: BetNumbers, Key2: false })
          }
        }
        this[`InputBetNumber${idx}`].focus();
      }
    }

    ControlType(e, idx) {
      let BetNumbers = this.state.BetNumbers
      let CopyReceipt = ''
      if (BetNumbers[idx].Follow !== 'Follow') {
        BetNumbers[idx].Follow = 'Follow'
        CopyReceipt = BetNumbers[idx]
        for (var i = idx + 1; i < BetNumbers.length; i++) {
          BetNumbers[i].Follow = 'disable'
          // BetNumbers[i].Bet = CopyReceipt.Bet
          BetNumbers[i].IBox = (BetNumbers[i].Text.length > 4) ? false : CopyReceipt.IBox
          // // BetNumbers[i].Key2 = CopyReceipt.Key2
          if (CopyReceipt.Mode === 'Box' || CopyReceipt.Mode === 'IBox') {
            BetNumbers[i].Mode = (BetNumbers[i].Text.length > 4) ? '-' : CopyReceipt.Mode
          } else if (CopyReceipt.Mode === '*#') {
            BetNumbers[i].Mode = (BetNumbers[i].Text.length === 4) ? CopyReceipt.Mode : BetNumbers[i].Mode
          } else {
            BetNumbers[i].Mode = (BetNumbers[i].Text.length > 4 || BetNumbers[i].Text.length === 2) ? BetNumbers[i].Mode : CopyReceipt.Mode
          }
          BetNumbers[i].Platform = CopyReceipt.Platform
        }
        this.setState({ BetNumbers, CopyReceipt })
      } else {
        BetNumbers[idx].Follow = ''
        for (var a = idx + 1; a < BetNumbers.length; a++) {
          BetNumbers[a].Follow = ''
        }
        this.setState({ BetNumbers, CopyReceipt: '' })
      }
    }
    // IBoxChecked = idx => evt => {
    //   let checked = evt.target.checked;
    //   if (checked) {
    //     this.setState({ IBox: true }, () => {
    //       let IBoxFormat = [...this.state.BetNumbers]
    //       const CurrentKey = IBoxFormat[idx].Key2;
    //       const KeyInResult = this.state.KeyinFormat;
    //       const CurrentBtFormula = (CurrentKey === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1|| [];
    //       const CurrentBtFormulaLength = CurrentBtFormula.length;
    //       const BetNumberSelectedText = IBoxFormat[idx].Text
    //       const CleanText = BetNumberSelectedText.replace(/[^0-9.]/g, '');
    //       const IBoxFormatBetValues = Object.values(IBoxFormat[idx].Bet)
    //       const object = {}

    //       for (let i = 0; i < CurrentBtFormulaLength; i++) {
    //         object[`(IB)${CurrentBtFormula[i]}`] = IBoxFormatBetValues[i]
    //       }
    //       IBoxFormat[idx] = { ...IBoxFormat[idx], Text: CleanText, Bet: { ...object }, IBox: true, Mode: '-' }
    //       this.setState({ BetNumbers: IBoxFormat, IBox: false })
    //       this.setState(prevState => ({
    //         ModeSelectAll: {
    //           ...prevState.ModeSelectAll,   
    //           1: false,
    //           Mode: '',
    //         }
    //       }))
    //       this[`InputBetNumber${idx}`].focus();
    //     })
    //   } else {
    //     this.setState({ IBox: false }, () => {
    //       let IBoxFormat = [...this.state.BetNumbers]
    //       const CurrentKey = IBoxFormat[idx].Key2;
    //       const KeyInResult = this.state.KeyinFormat;
    //       const CurrentBtFormula = (CurrentKey === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1|| [];
    //       const CurrentBtFormulaLength = CurrentBtFormula.length;
    //       const IBoxFormatBetValues = Object.values(IBoxFormat[idx].Bet)

    //       const object = {}
    //       for (let i = 0; i < CurrentBtFormulaLength; i++) {
    //         object[CurrentBtFormula[i]] = IBoxFormatBetValues[i]
    //       }
    //       IBoxFormat[idx] = { ...IBoxFormat[idx], Bet: { ...object }, IBox: false };
    //       this.setState({ BetNumbers: IBoxFormat })
    //       this.setState(prevState => ({
    //         ModeSelectAll: {
    //           ...prevState.ModeSelectAll,
    //           1: false,
    //           Mode: '',
    //         }
    //       }))
    //     })
    //     this[`InputBetNumber${idx}`].focus();
    //   }
    // }

    async AllKey2(event){
      let checked = event.target.checked;
      if(checked) {
        this.setState({ AllChangeKey2: true })
      } else {
        this.setState({AllChangeKey2: false})
      }
    }
    
    async SelectedMode(e, idx, Type) {
      const BetNumbers = JSON.parse(JSON.stringify(this.state.BetNumbers));
      const BetNumberLength = BetNumbers.length
      const SelectedMode = e.target.value;
      if(Type === 'All') {
        if(e.target.checked) {
          const BetNumberSelectedText = BetNumbers[idx].Mode
          const BetNumberSelectedIBox = BetNumbers[idx].IBox
          for(var i = 0; i < BetNumberLength; i ++) {
            //formula 1
            if (BetNumberSelectedText === '*#') {
              if((BetNumbers[i].Text).split('').length !== 5 && (BetNumbers[i].Text).split('').length !== 6 && (BetNumbers[i].Text).split('').length !== 3 && (BetNumbers[i].Text).split('').length !== 2) {
                BetNumbers[i] = { ...BetNumbers[i], Mode: BetNumberSelectedText, IBox: BetNumberSelectedIBox }
              }
            } else if (BetNumberSelectedText === 'IBox' || BetNumberSelectedText === 'Box') {
              if((BetNumbers[i].Text).split('').length !== 5 && (BetNumbers[i].Text).split('').length !== 6) {
                BetNumbers[i] = { ...BetNumbers[i], Mode: BetNumberSelectedText, IBox: BetNumberSelectedIBox }
              }
            } else if (BetNumberSelectedText === '-') {
              BetNumbers[i] = { ...BetNumbers[i], Mode: BetNumberSelectedText, IBox: BetNumberSelectedIBox }
            } else {
              if((BetNumbers[i].Text).split('').length !== 5 && (BetNumbers[i].Text).split('').length !== 6 && (BetNumbers[i].Text).split('').length !== 2 && BetNumberSelectedText !== '-') {
                BetNumbers[i] = { ...BetNumbers[i], Mode: BetNumberSelectedText, IBox: BetNumberSelectedIBox }
              }
            }
          }
          if(BetNumberSelectedIBox === true) {
            this.setState(prevState => ({
              IBoxSelectAll: {
                ...prevState.IBoxSelectAll,
                1: true,
                Mode: BetNumberSelectedText,
              },
            }))
            this.setState(prevState => ({
              ModeSelectAll: {
                ...prevState.ModeSelectAll,   
                1: true,
                Mode: BetNumberSelectedText,
              },
            }))
          } else {
            this.setState(prevState => ({
              ModeSelectAll: {
                ...prevState.ModeSelectAll,   
                1: true,
                Mode: BetNumberSelectedText,
              },
            }))
          }
        } else {
          this.setState(prevState => ({
            ModeSelectAll: {
              ...prevState.ModeSelectAll,   
              1: false,
              Mode: '',
            },
            IBoxSelectAll: {
              ...prevState.IBoxSelectAll,
              1: false,
              Mode: '',
            },
          }))
        }
        this.setState({ BetNumbers: BetNumbers})
        // this[`InputBetNumber${idx}`].focus();
      } else {
        const BetNumbers = [...this.state.BetNumbers];
        const BetNumberSelectedText = BetNumbers[idx].Text
        const CleanText = BetNumberSelectedText.replace(/[^0-9.]/g, '');
        if(SelectedMode === 'Box') {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: 'Box', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if (SelectedMode === '{0-9}123' && CleanText.length !== 2) {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '{0-9}123', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if (SelectedMode === '123{0-9}' && CleanText.length !== 2) {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '123{0-9}', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if (SelectedMode === '{123}4' && CleanText.length !== 2) {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '{123}4', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if (SelectedMode === '1{234}' && CleanText.length !== 2) {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '1{234}', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if(SelectedMode === 'IBox') {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: 'IBox', IBox: true }
          this.setState({ BetNumbers: BetNumbers, IBox: true})
        }
        //formula 1
        else if(SelectedMode === '*#' && BetNumbers[idx].Text.length === 4) {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '*#', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        }
        else {
          BetNumbers[idx] = { ...BetNumbers[idx], Text: CleanText, Mode: '-', IBox: false }
          this.setState({ BetNumbers: BetNumbers })
          this.setState(prevState => ({
            ModeSelectAll: {
              ...prevState.ModeSelectAll,   
              1: false,
              Mode: '',
            },
            IBoxSelectAll: {
              ...prevState.IBoxSelectAll,
              1: false,
              Mode: '',
            },
          }))
        }
        this[`InputBetNumber${idx}`].focus();
      }
    }

    async OpenDateModal() {
      this.GetModalDate();
      this.setState({ modalShow: true })
    }

    onpressPeriod(Num) {
      const { DisplayDate } = this.state;
      const BetNumbers = [...this.state.BetNumbers]
      const toProcessDateData = this.state.ModalDate;
  
      for(var j = 0; j < toProcessDateData.DateLoop.length; j++) {
        if(toProcessDateData.DateLoop[j].CheckMark === true) {
          toProcessDateData.DateLoop[j].CheckMark = false
        }
      }
      let newLineWord = '';
      // let NextDate = [];
      // for (var i = 0; i < Num; i++) {
      //   const CreatedDate = Moment(new Date().setDate(new Date().getDate() + (i + 1))).format('DDMM(ddd)');
      //   NextDate.push(CreatedDate);
      // }
      newLineWord = 'D#' + Num;

      DisplayDate[0] = `日期： ${newLineWord}`
      BetNumbers[0] = { ...BetNumbers[0], SelectedDate: `D#${Num}` }

      this.setState({ modalShow: false, DDate: DisplayDate[0], BetNumbers: BetNumbers, ModalDate: toProcessDateData });
      this.setState({modalShow: false});
    }

    openNotification(message, Line, Words, NotEnough) {
      this.setState({ showNotification: true });
      if(NotEnough === undefined) {
        this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]} ${(Line !== undefined) ? `Line:${Line || ''}` : ``} ${(Words !== undefined) ? `, ${language[this.props.currentLanguage].BetNumber}:${Words || ''}` : ``}` });
      } else {
        this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]} ${(NotEnough !== undefined && NotEnough !== '') ? `${language[this.props.currentLanguage].LeftCashWallet}:${NotEnough || ''}` : ``}` });
      }
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.setState({ showNotification: false });
        this.setState({ notificationMessage: '' });
      }, 5000);
    };
    
    async CleanData() {
      this.setState({
        BetNumbers: [],
        PhoneNumber: '',
        // UserID: this.props.userID,
        Key2: false, 
        IBox: false,
        AllChangeKey2: false,
        DDate: '请选择日期 (D#)',
        DisplayDate: [],
        PlatformSelectAll: {
          1: false,
          2: false,
          3: false, 
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          // 9: false,
        },
        ModeSelectAll: {
          1: false,
          Mode: '',
        },
        IBoxSelectAll: {
          1: false,
          Mode: '',
        },
        KeyBetAll: {
          0: false,
          1: false,
          2: false, 
          3: false,
          4: false,
          5: false,
        },
        KeyBetAllPassValues: {
          0: '',
          1: '',
          2: '', 
          3: '',
          4: '',
          5: '',
        },
      }, () => {
        this.StartBetting('Selected');
      })
    }

    async ClearReceipt() {
      this.setState({ Receipt: ''})
    }

    async ConvertResultRed(Receipt) {
      let Word = Receipt
      let returnWord = [];
  
      if (Word.includes('<red>')) {
        const WordArray = Word.split('<red>').join('</red>').split('</red>');
        for (let i = 0; i < WordArray.length; i += 1) {
          if (i === 0) {
            returnWord.push(<span key={i}>{WordArray[i]}</span>);
          } else if (i % 2 === 1) {
            returnWord.push(
              <span key={i} style={{color: 'red'}}>
                {WordArray[i]}
              </span>,
            );
          } else if (i % 2 === 0) {
            returnWord.push(<span key={i}>{WordArray[i]}</span>);
          }
        }
        this.setState({ReceiptDisplay: returnWord});
      } else {
        this.setState({ReceiptDisplay: Word});
      }
    }

    CopyToC(TextResult) {
      const DownloadWord = TextResult.split('<red>').join('</red>').split('</red>');
      navigator.clipboard.writeText(DownloadWord.join(''))
      this.openNotification('SuccessCopied')
      this.setState({ notificationType: true });
    }

    PlatformChange(platform) {
      if (platform === '1') return 'M'
      else if (platform === '2') return (this.state.GamesDisplay === 'MKTSWBD') ? 'K': 'P'
      else if (platform === '3') return 'T'
      else if (platform === '4') return 'S'
      else if (platform === '5') return 'W'
      else if (platform === '6') return 'B'
      else if (platform === '7') return (this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K'
      else if (platform === '8') return '8'
      else if (platform === '9') return '9'
      return 'X'
    }

    laihui() {
      let BetNumbers = this.state.BetNumbers
      const FilterObject = BetNumbers.filter(function (values) {
        return values.Text !== ''
      });
      const LastObject = FilterObject[FilterObject.length - 1]

      if(LastObject !== undefined && LastObject.Text.length > 2) {
        if(BetNumbers.length === 2 && BetNumbers[1].Text === '') {
          BetNumbers[1].Text = LastObject.Text.split("").reverse().join("");
          BetNumbers[1].Bet = LastObject.Bet;
          BetNumbers[1].Follow = LastObject.Follow;
          BetNumbers[1].IBox = LastObject.IBox;
          BetNumbers[1].Key2 = LastObject.Key2;
          BetNumbers[1].Mode = LastObject.Mode;
          BetNumbers[1].Platform = LastObject.Platform;
          BetNumbers[1].SelectedDate = "";
          BetNumbers[1].five6D = LastObject.five6D;
          BetNumbers[1].input56D = LastObject.input56D;
          this.setState({ BetNumbers })
        } else if (BetNumbers.length > 2 && BetNumbers[BetNumbers.length - 1].Text === '') {
          BetNumbers[BetNumbers.length - 1].Text = LastObject.Text.split("").reverse().join("");
          BetNumbers[BetNumbers.length - 1].Bet = LastObject.Bet;
          BetNumbers[BetNumbers.length - 1].Follow = LastObject.Follow;
          BetNumbers[BetNumbers.length - 1].IBox = LastObject.IBox;
          BetNumbers[BetNumbers.length - 1].Key2 = LastObject.Key2;
          BetNumbers[BetNumbers.length - 1].Mode = LastObject.Mode;
          BetNumbers[BetNumbers.length - 1].Platform = LastObject.Platform;
          BetNumbers[BetNumbers.length - 1].SelectedDate = "";
          BetNumbers[BetNumbers.length - 1].five6D = LastObject.five6D;
          BetNumbers[BetNumbers.length - 1].input56D = LastObject.input56D;
          this.setState({ BetNumbers })
        } else {
          this.setState(({
            BetNumbers: [
              ...this.state.BetNumbers,
              {
                Text: LastObject.Text.split("").reverse().join(""),
                Platform: LastObject.Platform,
                Bet: JSON.parse(JSON.stringify(LastObject.Bet)),
                Follow: LastObject.Follow,
                IBox: LastObject.IBox,
                Key2: LastObject.Key2,
                Mode: LastObject.Mode,
                SelectedDate: "",
                five6D: LastObject.five6D,
                input56D: LastObject.input56D,
              },
            ],
          }));
        }
      }
    }

    ShotCutKeySearchFunction(event){
      if (event.key === 'Enter') {
        this.SearchFunction();
      }
    }
  
    async SearchFunction(){
      this.setState({ SearchUSersModal: true })
      if (this.state.SearchUser !== '') {
        const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.props.userID, this.props.token, this.props.username)
        if(Searchresult.message === 'Invalid Downline ID or Username') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification('InvalidDownlineIDorUsername')
          });
        } else {
          Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
          this.setState({ SearchedID: Searchresult })
        }
      }
    }
  
    handleCloseSearchModal() {
      this.setState({
        SearchUSersModal: false,
        SearchedID : [],
      })
    }

    ReturnDisplayFormat(KeyinFormat) {
      const getUserFormula = (this.state.getUserFormula === null) ? [] : this.state.getUserFormula
      let result = []
      var getformula = Object.values(getUserFormula).filter(function (el) {
        return el.Num === KeyinFormat.toString()
      });
      if (getformula.length > 0) { 
        const Keyin = Object.keys(getformula[0])
        const Key3Formulas = Keyin.filter(function(item) {
          return !['Num'].includes(item); 
        })
        Key3Formulas.forEach((formula) => {
          result.push(`${formula}: ${getformula[0][formula]}`)
        })
      }
      return (getUserFormula.length === 0) ? '' : result.join(',')
    }

    // returnPlatformIcon(platform) {
    //   if(platform === '1') { return MagnumLogo } else if (platform === '2') { return DamacaiLogo} else if (platform === '3')
    //   {return TotoLogo} else if (platform === '4') { return Sg4Logo } else if(platform === '5') { return SarawakLogo} else if(platform === '6') { return Sabah88Logo }
    //   else if(platform === '7') {return SansakanLogo} else if(platform === '8') { return GDLogo} else if(platform === '9') {return NineLogo} 
    // }

    render() {
      const KeyInResult = this.state.KeyinFormat;
      const CurrentBtFormula = (this.state.Key2 === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
      return (
      // <div className="App backgroundImage4" style={{ paddingTop: 25, minHeight: 950 }}>
        <div onScroll={(e) => this.handleScroll(e)} className='StartBackground'>
          <div id={'Top'}/>
          <Header />
          <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
          <div className="row TitleBettingVersion" style={{ display: 'flex', marginLeft: 50, marginRight: 50 }}>
            <div className="dcc jackpotborder TextFontSize" style={{ justifyContent: 'space-between', width: '100%'}}>
              <div style={{width: '33.3%'}}></div>
              <div style={{width: '33.3%'}} className='dcc'><FaHome size="25px" color="white" className="logohome" style={{ marginRight: 10 }} /> {`${language[this.props.currentLanguage].BetBoxVersion} (${this.state.SelectedUser.label})`}</div>
              <div style={{ width: '33.3%', textAlign: 'right', paddingRight: 10, fontWeight: 'bold' }}>{`${language[this.props.currentLanguage].Balance} : $ ${this.state.Balance}`}</div>
            </div>
          </div>
          <div className='dcc'>
            <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} show={this.state.showNotification}
              variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
              <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
            </Alert>
          </div>
          <div className="dcc mobileHeader"
            style={{
              paddingBottom: 20,
              justifyContent: 'space-between',
              marginLeft: '4%',
              flexDirection: 'row',
            }}>
            <div className="TitleDate">{language[this.props.currentLanguage].Date}: {this.state.TodayDate}</div>
            <div className="dcc" style={{ marginRight: 20 }}>
              <div className="TitleUsername TextFontSize"> ID - {this.props.username} </div>
            </div>
          </div>
          <div style={{ borderRadius: 0, padding: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
              <div style={{ margin: '0px 10px',  fontWeight: 'bold'}}>{language[this.props.currentLanguage].SearchAccount}</div>
              <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
              <Button  className='button2' variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
              <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getDownline()}>{'Self'}</Button>
            </div>
            <div className="dcc">
              <div className="dcc" style={{ width: '99%', paddingBottom: 20, justifyContent: 'space-between', flexDirection: 'row' }}>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 550, fontWeight: 'bold', marginBottom: 0 }}
                  disabled={this.state.disabled}
                  onClick={() => this.OpenDateModal()}
                  className="btn my-cusomized-button button2 TextFontSize">
                  {this.state.DDate}
                  {' '}
                </button>
                <div className='dcc' style={{ flexDirection: 'row', width: 'auto' }}>
                  <button style={{ backgroundColor: this.state.ButtonBackgroundRed, marginRight: 10 }}
                    disabled={(this.state.DisabledClick === true) ? true : false}
                    onClick={() => this.CleanData() && this.setState({ Receipt: '', ShowReceipt: false, CopyReceipt: ''})}
                    onMouseEnter={()=> {this.setState({ ButtonBackgroundRed: '#CE3A15' })}}
                    onMouseLeave={()=> {this.setState({ ButtonBackgroundRed: '#EE3E13' })}}
                    className="btn my-cusomized-button BoxversionButton button2 TextFontSize">
                    {language[this.props.currentLanguage].ClearAll} (Q)
                  </button>
                  <button style={{ backgroundColor: this.state.ButtonBackgroundblue }}
                    disabled={(this.state.DisabledClick === true) ? true : false}
                    onClick={() => this.ConfirmSaveBet()}
                    onMouseEnter={()=> {this.setState({ ButtonBackgroundblue: '#1576DA' })}}
                    onMouseLeave={()=> {this.setState({ ButtonBackgroundblue: '#3C96F4' })}}
                    className="btn my-cusomized-button BoxversionButton button2 TextFontSize">
                    {language[this.props.currentLanguage].ConfirmTicket} (SpaceBar)
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal transparent={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.modalShow}
            contentClassName="br23">
            <Modal.Body className="dcc" style={{ padding: 10, height: 500 }}>
              <GrFormClose
                className='ButtonCloseModal'
                onClick={() => {
                  this.setState({ modalShow: false })
                }}
                size="35px"
                color="#000"
              />
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{ flexDirection: 'column' }}>
                  <div className="dcc TextFontSize" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].Date}</div>
                  <div className="dcc" style={{ width: '100%', flexWrap: 'wrap', flexDirection: 'column' }}>
                    <div className="dcc" style={{ marginBottom: 15, flexDirection: 'column', width: '100%' }}>
                      {(this.state.ModalDate.DateLoop || []).map((item, idx) => {
                        return (
                          <button
                            key={idx}
                            className="DateSelectButton DateModalButton"
                            style={{ width: '100%' }}
                            onClick={() => this.SelectDateFunction({ Index: idx })}>
                            <div className="dcc row" style={{ justifyContent: 'space-evenly' }}>
                              <div>{item.Date}</div>
                              {item.CheckMark && (
                                <div>
                                  <img src={CheckMark}
                                    alt="Logo"
                                    style={{ width: 40, height: 40 }}
                                  />
                                </div>
                              )}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                    <div className="column" style={{ textAlign: 'center' }}>
                      <div style={{ marginBottom: 5 }}>
                        <button style={{ width: 200, marginRight: 30 }}
                          disabled={this.state.disabled}
                          onClick={(e) => this.onpressLongTerm(e)}
                          className="btn my-cusomized-button BoxversionDateButton">
                          {language[this.props.currentLanguage].Auto}
                        </button>
                        <button style={{ width: 200}}
                          disabled={this.state.disabled}
                          onClick={() => this.onPressConfirmSelectDate()}
                          className="btn my-cusomized-button BoxversionDateButton">
                          {language[this.props.currentLanguage].Confirm}
                        </button>
                      </div>
                      <div>
                        <button style={{ width: 200, marginRight: 30 }}
                          disabled={this.state.disabled}
                          onClick={(e) => this.onpressPeriod(2)}
                          className="btn my-cusomized-button BoxversionDateButton">
                          {language[this.props.currentLanguage].secondPeriod}
                        </button>
                        <button style={{ width: 200 }}
                          disabled={this.state.disabled}
                          onClick={() => this.onpressPeriod(3)}
                          className="btn my-cusomized-button BoxversionDateButton" >
                          {language[this.props.currentLanguage].ThreePeriod}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div className={`row`}> 
            <div style={{ width: '83%', paddingBottom: 40, overflowX: 'auto' }}>
              <Table responsive="sm" style={{ backgroundColor: 'white' }}>
                <thead>
                  <tr>
                    <th><div>#</div></th>
                    <th><div className='BoxversionPlatFont'>{language[this.props.currentLanguage].BetNumber}</div></th>
                    {[0,1,2,3,4,5].map((Formula, forid) => {
                      return(
                        <th key={forid}><div className='BoxversionPlatFont'>{CurrentBtFormula[Formula]}</div></th>
                      )
                    })}
                    {this.state.PlatformShow && this.state.PlatformShow.map((platform, inde) => (
                      <th key={inde} className="BoxversionPlatFont" style={{ backgroundColor: Platformcolor(platform, ''), color: (platform === '8') ? 'white':'black' }}><div>{this.PlatformChange(platform)}</div></th>
                    )
                    )}
                    <th><div className="dcc BoxversionPlatFont">{language[this.props.currentLanguage].Type}</div></th>
                    <th><div></div></th>
                    <th className='dcc'>
                      <div>
                        <button className="LaihuiButtonBoxVersion button2 BoxversionPlatFont" onClick={()=> this.laihui()}>来回</button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <thead style={{ backgroundColor: '#D5D2D2' }}>
                  <tr>
                    <td><div></div></td>
                    <td>
                      <div className="dcc BoxversionPlatFont" style={{ fontWeight: 'bold' }}>
                                        Key2：
                        <input
                          checked={this.state.Key2}
                          className="BoxversionCheckboxSize"
                          onChange={this.Key2Checked(0)}
                          value={'AllKey2'}
                          readOnly={false}
                          type="checkbox"
                        />
                      </div>
                    </td>
                    {[0,1,2,3,4,5].map((valuesSelect, valid) => {
                      return(
                        <td key={valid}>
                          <div>
                            <input
                              className="BoxversionCheckboxSize"
                              onChange={(e) => this.handleBetNumbers(0, e, 'All')}
                              readOnly={false}
                              checked={this.state.KeyBetAll[valuesSelect]}
                              name={`${valuesSelect}`}
                              type="checkbox"
                            />
                          </div>
                        </td>
                      );
                    })}
                    {['1','2','3','4','5','6', '7', '8', '9'].map((plat,plid) => (
                      <td key={plid} style={{ backgroundColor: Platformcolor(this.state.PlatformShow[plid], '') }}><div><input
                        readOnly={false}
                        disabled={(this.state.PlatformShow[plid] === '-') ? true : false}
                        onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                        className="BoxversionCheckboxSize"
                        checked={this.state.PlatformSelectAll[plat]}
                        name={plat}
                        type="checkbox"
                      /></div></td>
                    ))}
                    <td><div className="dcc"> <input
                      onChange={(e) => this.SelectedMode(e, 0, 'All')}
                      checked={this.state.ModeSelectAll[1]}
                      className="BoxversionCheckboxSize"
                      value={'All'}
                      readOnly={false}
                      type="checkbox"
                    /></div></td>
                    <td><div></div></td>
                    <th><div></div></th>
                  </tr>
                </thead>
                {this.state.BetNumbers.map((Betting, idx) => (
                  <tbody key={idx}>
                    <tr style={{ backgroundColor: (Betting.IBox === true) ? 'lightblue' : 'white' }}>
                      <td><div style={{ width: 20, fontWeight: 'bold' }}>{idx + 1}</div></td>
                      <td><div style={{ width: 80 }}>
                        <input
                          autoFocus
                          value={(Betting.Text).replace(/[^0-9]/g, '')} name={'Text'} maxLength={6}
                          className="form-control BoxversionInputNumber"
                          style={{ color: 'black' }}
                          ref={(input) => { this[`InputBetNumber${idx}`] = input; }}
                          onChange={(e) => this.handleBetNumbers(idx, e)}
                          onKeyPress={(e) => this.KeyPressEvent(e, idx, '0')}
                          onFocus={(e) => this.KeyPressEvent(e, idx, 'Text', 'Edit')}
                        /></div></td>
                      <td><div style={{ width: 50 }}>
                        <div style={{ marginRight: 5 }}>
                          <input
                            value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[0] : KeyInResult.KeyIn1[0]]}
                            className="form-control BoxversionInputValue" name={'0'}
                            // style={{
                            //   width: (isMobile) ? 60 : 85,
                            // }}
                            ref={(input) => { this[`InputBet1${idx}`] = input; }}
                            onChange={(e) => this.handleBetNumbers(idx, e)}
                            onKeyPress={(e) => this.KeyPressEvent(e, idx, '1')}
                            onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[0] : KeyInResult.KeyIn1[0], 'Edit')}
                            placeholder={(this.state.BetNumbers[idx].Key2 === true && Betting.input56D === false && Betting.TwoD === false)
                              ? KeyInResult.KeyIn2[0] : Betting.five6D === true ? '5/6D' 
                                : (this.state.BetNumbers[idx].Mode === '*#') ? 'format' : (Betting.input56D === true && this.state.Key2 === false) ? this.state.key3D[0] : (Betting.input56D === true && this.state.Key2 === true) ? 'CA' 
                                  : (Betting.TwoD === true) ? 'PA' : KeyInResult.KeyIn1[0]}
                          />
                        </div></div></td>
                      {Betting.Mode === '*#' && <>
                        <td colSpan="5" style={{ fontWeight: 'bold', fontSize: '18px'}}>
                          {`${this.ReturnDisplayFormat(Betting.Bet[KeyInResult.KeyIn1[0]])}`}
                        </td>
                      </>}
                      {Betting.Mode !== '*#' && 
                      <>
                        <td><div style={{ width: 50 }}>
                          <input
                            value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[1] : KeyInResult.KeyIn1[1]]}
                            className="form-control BoxversionInputValue" name={'1'}
                            // style={{ width: (isMobile) ? 60 : 85 }}
                            ref={(input) => { this[`InputBet2${idx}`] = input; }}
                            onChange={(e) => this.handleBetNumbers(idx, e)}
                            onKeyPress={(e) => this.KeyPressEvent(e, idx, '2')}
                            onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[1] : KeyInResult.KeyIn1[1], 'Edit')}
                            placeholder={(this.state.BetNumbers[idx].Key2 === true && Betting.input56D === false && Betting.TwoD === false)
                              ? KeyInResult.KeyIn2[1] : (Betting.five6D === true && Betting.input56D === true) === true ? '' : (Betting.input56D === true && this.state.Key2 === false && this.state.key3D.length !== 1) ? 'C' : (Betting.input56D === true && this.state.Key2 === false && this.state.key3D.length === 1) ? '' : (Betting.input56D === true && this.state.Key2 === true) ? 'CB'
                                : (Betting.TwoD === true && this.state.Key2 === false) ? 'PC' : (Betting.TwoD === true && this.state.Key2 === true) ? 'PB' : (this.state.BetNumbers[idx].Mode === '*#') ? '' : KeyInResult.KeyIn1[1]}
                            disabled={(Betting.Text).split('').length > 4 || (this.state.BetNumbers[idx].Mode === '*#' || ((Betting.Text).split('').length === 3 && this.state.key3D.length === 1)) ? true : false}
                          /></div></td>
                        <td><div style={{ width: 50 }} hidden={(this.state.Key2 === false && (Betting.input56D === true || Betting.TwoD === true)) ? true : false}>
                          <input
                            value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[2] : KeyInResult.KeyIn1[2]]}
                            className="form-control BoxversionInputValue" name={'2'}
                            // style={{ width: (isMobile) ? 60 : 85 }}
                            ref={(input) => { this[`InputBet3${idx}`] = input; }}
                            onChange={(e) => this.handleBetNumbers(idx, e)}
                            onKeyPress={(e) => this.KeyPressEvent(e, idx, '3')}
                            onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[2] : KeyInResult.KeyIn1[2], 'Edit')}
                            placeholder={(this.state.BetNumbers[idx].Key2 === true && Betting.input56D === false && Betting.TwoD === false)
                              ? KeyInResult.KeyIn2[2] :  (Betting.five6D === true && Betting.input56D === true) === true ? '' : (Betting.input56D === true && this.state.Key2 === true) ? 'CC' : (Betting.TwoD === true && this.state.Key2 === true) ? 'PC' : (this.state.BetNumbers[idx].Mode === '*#') ? '' : KeyInResult.KeyIn1[2]}
                            disabled={(Betting.Text).split('').length > 4 || (this.state.BetNumbers[idx].Mode === '*#') ? true : false}
                          /></div></td>
                        <td><div style={{ width: 50 }} hidden={(this.state.Key2 === false && (Betting.input56D === true || Betting.TwoD === true)) ? true : false}>
                          <input
                            value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[3] : KeyInResult.KeyIn1[3]]}
                            className="form-control BoxversionInputValue" name={'3'}
                            // style={{ width: (isMobile) ? 60 : 85 }}
                            ref={(input) => { this[`InputBet4${idx}`] = input; }}
                            onChange={(e) => this.handleBetNumbers(idx, e)}
                            onKeyPress={(e) => this.KeyPressEvent(e, idx, '4')}
                            onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[3] : KeyInResult.KeyIn1[3], 'Edit')}
                            placeholder={(this.state.BetNumbers[idx].Key2 === true && Betting.input56D === false && Betting.TwoD === false)
                              ? KeyInResult.KeyIn2[3] : (Betting.five6D === true && Betting.input56D === true) === true ? '' : (Betting.input56D === true && this.state.Key2 === true) ? 'CD' :(Betting.TwoD === true && this.state.Key2 === true) ? 'PD' : (this.state.BetNumbers[idx].Mode === '*#') ? '' : KeyInResult.KeyIn1[3]}
                            disabled={(Betting.Text).split('').length > 4 || (this.state.BetNumbers[idx].Mode === '*#') ? true : false}
                          /></div></td>
                        <td><div style={{ width: 50 }} hidden={(this.state.Key2 === false && (Betting.input56D === true || Betting.TwoD === true)) ? true : false}>
                          <input
                            value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[4] : KeyInResult.KeyIn1[4]]}
                            className="form-control BoxversionInputValue" name={'4'}
                            // style={{ width: (isMobile) ? 60 : 85 }}
                            ref={(input) => { this[`InputBet5${idx}`] = input; }}
                            onChange={(e) => this.handleBetNumbers(idx, e)}
                            onKeyPress={(e) => this.KeyPressEvent(e, idx, '5')}
                            onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[4] : KeyInResult.KeyIn1[4], 'Edit')}
                            placeholder={(this.state.BetNumbers[idx].Key2 === true && Betting.input56D === false && Betting.TwoD === false)
                              ? KeyInResult.KeyIn2[4] : (Betting.five6D === true && Betting.input56D === true) === true ? '' : (Betting.input56D === true && this.state.Key2 === true) ? 'CE' :(Betting.TwoD === true && this.state.Key2 === true) ? 'PE' :  (this.state.BetNumbers[idx].Mode === '*#') ? '' : KeyInResult.KeyIn1[4]}
                            disabled={(Betting.Text).split('').length > 4 || (this.state.BetNumbers[idx].Mode === '*#') ? true : false}
                          /></div></td>
                        <td><div style={{ width: 50 }} hidden={(this.state.Key2 === false && (Betting.input56D === true || Betting.TwoD === true)) ? true : false}>
                          <input
                            value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[5] : KeyInResult.KeyIn1[5]]}
                            className="form-control BoxversionInputValue" name={'5'}
                            // style={{ width: (isMobile) ? 60 : 85 }}
                            ref={(input) => { this[`InputBet6${idx}`] = input; }}
                            onChange={(e) => this.handleBetNumbers(idx, e)}
                            onKeyPress={(e) => this.KeyPressEvent(e, idx, '6')}
                            onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[5] : KeyInResult.KeyIn1[5], 'Edit')}
                            placeholder={(this.state.BetNumbers[idx].Key2 === true && Betting.input56D === false && Betting.TwoD === false)
                              ? KeyInResult.KeyIn2[5] : (Betting.five6D === true && Betting.input56D === true) === true ? '' : (Betting.input56D === true && this.state.Key2 === true) ? 'CF' : (Betting.TwoD === true && this.state.Key2 === true) ? 'PF' : (this.state.BetNumbers[idx].Mode === '*#') ? '' : KeyInResult.KeyIn1[5]}
                            disabled={(Betting.Text).split('').length > 4 || (this.state.BetNumbers[idx].Mode === '*#') ? true : false}
                          /></div></td>
                      </>
                      }
                      {['1','2','3','4','5','6', '7', '8', '9'].map((item, idxs) => {
                        return <td key={idxs} style={{ backgroundColor: Platformcolor(this.state.PlatformShow[idxs], '')}}>
                          <div>
                            <input
                              type="checkbox"
                              className='BoxversionCheckboxSize'
                              style={{ marginTop: 12}}
                              disabled={(this.state.PlatformShow[idxs] === '-') ? true : false}
                              checked={Betting.Platform.includes(item)}
                              id = {item}
                              name={item}
                              onChange={(e) => this.CheckPlatform(e, item, idx)}
                              onKeyPress={(e) => this.CheckBoxKeyPressEvent(e, idx)}
                            />
                          </div>
                        </td>
                      })}
                      <td>
                        <div>
                          <div className="dcc">
                            <select className="form-control DropdownListBoxV"
                              value={Betting.Mode}
                              // disabled={Betting.five6D} Gametype56
                              onChange={(e) => this.SelectedMode(e, idx)}>
                              {((Betting.five6D) ? this.state.Gametype56 : this.state.Gametype ).map((GameType, idd) => {
                                return(
                                  <option key={idd} value={GameType}>{GameType}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button 
                            className='button2 BoxversionPlatFont'
                            style={{
                              width: 'auto',
                              backgroundColor: (Betting.Follow === 'Follow' || Betting.Follow === 'End') ? '#3c96f4' : '',
                              color: (Betting.Follow === 'Follow' || Betting.Follow === 'End') ? 'white' : 'black',
                            }}
                            onClick={(e) => this.ControlType(e, idx)}
                            value={'ControlType'}
                            type="button">
                            {(Betting.Follow === 'Follow'|| Betting.Follow === '' || Betting.Follow === 'disable') ? `Follow` : `End`}
                          </button>
                        </div>
                      </td>
                      <td style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: 5 }}>
                          {idx ?
                            <button
                              disabled={(Betting.Follow === 'Follow') ? true : false}
                              style={{ color: 'white' }}
                              type="button"
                              onClick={this.handleRemoveNumbersChange(idx)}
                              className="ReduceButton button2">
                                                  -
                            </button>
                            :
                            null
                          }
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
              <div className="dcc" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].PressEnterAddNewLine}</div>
            </div>
            <div style={{ backgroundColor: 'white', width: '15%', borderRadius: 5, marginLeft: 10}}>
              {this.state.ShowReceipt === true &&
                    <>
                      <div className="dcc row TextFontSize" style={{ marginBottom: 30, fontWeight: 'bold' }}>
                        <div>{`${(this.state.SuccessFullCancel === true) ? 
                          language[this.props.currentLanguage].CancelSuccessfulldisplay 
                          : language[this.props.currentLanguage].SuccessOrderBet }`}</div>
                        <div>
                          <GrFormClose
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({ ShowReceipt: false, Receipt: '' })
                            }}
                            size="35px"
                            color="#000"
                          />
                        </div>
                      </div>
                      <div className='DisplayReceiptValue'>
                        {this.state.ReceiptDisplay}
                      </div>
                      <button style={{ marginRight: 5 }}
                        className="btn my-cusomized-button button2 BoxVersionReceiptButton"
                        onClick={() => {this.CopyToC(this.state.Receipt)}}>{language[this.props.currentLanguage].CopyReceipt}</button>
                      <button
                        onClick={() => this.CancelBetAPI()}
                        disabled={this.state.DisabledCancel}
                        className="btn my-cusomized-button BoxVersionReceiptButton">
                        {language[this.props.currentLanguage].CancelTicket}
                      </button>
                    </>
              }
            </div>
            {this.state.is_visible && 
              <div className="dcc" style={{ paddingBottom: 30 }}>
                <div class="scrollDesign iconpopup button2"  
                  onClick={() => this.scrollToTop()}>
                  {/* ↑ ⇈ */}
                  <div  className="icon MoveUpArrow"> ⇧ </div>
                </div>
              </div>
            }
          </div>
        </div>
      );
    }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BetBoxVersion);
