import '../App.css';
import React, {Fragment}  from 'react';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'react-bootstrap';
import Header2 from '../Component/Header';
import Moment from 'moment-timezone';
import { characterList} from '../static';
import Modal from 'react-bootstrap/Modal';
import errorsign from '../Images/errorsign.gif';
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom';
// import ssdatajson from './ssdata.json'
// import ptdatajson from './ptdata.json'
// import jjdatajson from './jjdata.json'
import Table from 'react-bootstrap/Table'
// import data from '../static/testdata.json';
import { findPermutations } from '../utility/Permutation';
const { getDataByLink, forceUpdateResult, updateFirebaseResult, geti82Data, getTimeJSON, pauseOpenResult, resumeOpenResult, getHistoryNumber } = require('../Api');
var timer
var opentimer

class GameResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: {},
      totalSales: 0,
      totalSalesss: 0,
      totalSalespt: 0,
      totalSalesjj: 0,
      totalSales82: 0,
      totalWin: 0,
      totalWinPercent: 0,
      GenerateTime: [],
      OpenModalGenerateTime: false,
      word: {
        p1: '',
        p2: '',
        p3: '',
        s1: '',
        s2: '',
        s3: '',
        s4: '',
        s5: '',
        s6: '',
        s7: '',
        s8: '',
        s9: '',
        s10: '',
        s11: '',
        s12: '',
        s13: '',
        c1: '',
        c2: '',
        c3: '',
        c4: '',
        c5: '',
        c6: '',
        c7: '',
        c8: '',
        c9: '',
        c10: '',
        '5D1': '',
        '5D2': '',
        '5D3': '',
        '6D1': '',
        '7D1': '',
        p1OriPosition: '',
        p2OriPosition: '',
        p3OriPosition: '',
      },
      displayWord: {
        p1: '',
        p2: '',
        p3: '',
        s1: '',
        s2: '',
        s3: '',
        s4: '',
        s5: '',
        s6: '',
        s7: '',
        s8: '',
        s9: '',
        s10: '',
        s11: '',
        s12: '',
        s13: '',
        c1: '',
        c2: '',
        c3: '',
        c4: '',
        c5: '',
        c6: '',
        c7: '',
        c8: '',
        c9: '',
        c10: '',
      },
      redbackground: {
        p1: false,
        p2: false,
        p3: false,
        s1: false,
        s2: false,
        s3: false,
        s4: false,
        s5: false,
        s6: false,
        s7: false,
        s8: false,
        s9: false,
        s10: false,
        s11: false,
        s12: false,
        s13: false,
        c1: false,
        c2: false,
        c3: false,
        c4: false,
        c5: false,
        c6: false,
        c7: false,
        c8: false,
        c9: false,
        c10: false,
        '5D1': false,
        '5D2': false,
        '5D3': false,
        '6D1': false,
        '7D1': false,
      },
      prize: {
        p1: 0,
        p2: 0,
        p3: 0,
        s1: 0,
        s2: 0,
        s3: 0,
        s4: 0,
        s5: 0,
        s6: 0,
        s7: 0,
        s8: 0,
        s9: 0,
        s10: 0,
        s11: 0,
        s12: 0,
        s13: 0,
        c1: 0,
        c2: 0,
        c3: 0,
        c4: 0,
        c5: 0,
        c6: 0,
        c7: 0,
        c8: 0,
        c9: 0,
        c10: 0,
        '5D1': 0,
        '5D2': 0,
        '5D3': 0,
        '5D4': 0,
        '5D5': 0,
        '5D6': 0,
        '6D1': 0,
        '6D2': 0,
        '6D3': 0,
        '6D4': 0,
        '6D5': 0,
      },
      odd: {
        B1: 2750,
        B2: 1100,
        B3: 550,
        B4: 220,
        B5: 66,
        S1: 3850,
        S2: 2200,
        S3: 1100,
        SA: 6600,
        SB: 6600,
        SC: 6600,
        SD: 660,
        SE: 660,
        SF: 2200,
        CA: 660,
        CB: 660,
        CC: 660,
        CD: 66,
        CE: 66,
        CF: 220,
        PA: 66,
        PB: 66,
        PC: 66,
        PD: 6.6,
        PE: 6.6,
        PF: 22,
        '5D1': 21000,
        '5D2': 8400,
        '5D3': 5250,
        '5D4': 840,
        '5D5': 31.5,
        '5D6': 8.4,
        '6D1': 157500,
        '6D2': 5250,
        '6D3': 525,
        '6D4': 52.5,
        '6D5': 5.25,
      },
      subtotal: {
        p1: 0,
        p2: 0,
        p3: 0,
        s1: 0,
        s2: 0,
        s3: 0,
        s4: 0,
        s5: 0,
        s6: 0,
        s7: 0,
        s8: 0,
        s9: 0,
        s10: 0,
        s11: 0,
        s12: 0,
        s13: 0,
        c1: 0,
        c2: 0,
        c3: 0,
        c4: 0,
        c5: 0,
        c6: 0,
        c7: 0,
        c8: 0,
        c9: 0,
        c10: 0,
        '5D1': 0,
        '5D2': 0,
        '5D3': 0,
        '5D4': 0,
        '5D5': 0,
        '5D6': 0,
        '6D1': 0,
        '6D2': 0,
        '6D3': 0,
        '6D4': 0,
        '6D5': 0,
      },
      subtotaldetail: {
        p1B: 0,
        p1S: 0,
        p1SA: 0,
        p1SF: 0,
        p1IBB: 0,
        p1IBS: 0,
        p1IBSA: 0,
        p1IBSF: 0,
        p1CA: 0,
        p1CF: 0,
        p1PA: 0,
        p1PF: 0,
        p2B: 0,
        p2S: 0,
        p2SB: 0,
        p2SF: 0,
        p2IBB: 0,
        p2IBS: 0,
        p2IBSB: 0,
        p2IBSF: 0,
        p2CB: 0,
        p2CF: 0,
        p2PB: 0,
        p2PF: 0,
        p3B: 0,
        p3S: 0,
        p3SC: 0,
        p3SF: 0,
        p3IBB: 0,
        p3IBS: 0,
        p3IBSC: 0,
        p3IBSF: 0,
        p3CC: 0,
        p3CF: 0,
        p3PC: 0,
        p3PF: 0,
        s1B: 0,
        s1SD: 0,
        s1IBB: 0,
        s1IBSD: 0,
        s1CD: 0,
        s1PD: 0,
        s2B: 0,
        s2SD: 0,
        s2IBB: 0,
        s2IBSD: 0,
        s2CD: 0,
        s2PD: 0,
        s3B: 0,
        s3SD: 0,
        s3IBB: 0,
        s3IBSD: 0,
        s3CD: 0,
        s3PD: 0,
        s4B: 0,
        s4SD: 0,
        s4IBB: 0,
        s4IBSD: 0,
        s4CD: 0,
        s4PD: 0,
        s5B: 0,
        s5SD: 0,
        s5IBB: 0,
        s5IBSD: 0,
        s5CD: 0,
        s5PD: 0,
        s6B: 0,
        s6SD: 0,
        s6IBB: 0,
        s6IBSD: 0,
        s6CD: 0,
        s6PD: 0,
        s7B: 0,
        s7SD: 0,
        s7IBB: 0,
        s7IBSD: 0,
        s7CD: 0,
        s7PD: 0,
        s8B: 0,
        s8SD: 0,
        s8IBB: 0,
        s8IBSD: 0,
        s8CD: 0,
        s8PD: 0,
        s9B: 0,
        s9SD: 0,
        s9IBB: 0,
        s9IBSD: 0,
        s9CD: 0,
        s9PD: 0,
        s10B: 0,
        s10SD: 0,
        s10IBB: 0,
        s10IBSD: 0,
        s10CD: 0,
        s10PD: 0,
        s11B: 0,
        s11SD: 0,
        s11IBB: 0,
        s11IBSD: 0,
        s11CD: 0,
        s11PD: 0,
        s12B: 0,
        s12SD: 0,
        s12IBB: 0,
        s12IBSD: 0,
        s12CD: 0,
        s12PD: 0,
        s13B: 0,
        s13SD: 0,
        s13IBB: 0,
        s13IBSD: 0,
        s13CD: 0,
        s13PD: 0,
        c1B: 0,
        c1SE: 0,
        c1IBB: 0,
        c1IBSE: 0,
        c1CE: 0,
        c1PE: 0,
        c2B: 0,
        c2SE: 0,
        c2IBB: 0,
        c2IBSE: 0,
        c2CE: 0,
        c2PE: 0,
        c3B: 0,
        c3SE: 0,
        c3IBB: 0,
        c3IBSE: 0,
        c3CE: 0,
        c3PE: 0,
        c4B: 0,
        c4SE: 0,
        c4IBB: 0,
        c4IBSE: 0,
        c4CE: 0,
        c4PE: 0,
        c5B: 0,
        c5SE: 0,
        c5IBB: 0,
        c5IBSE: 0,
        c5CE: 0,
        c5PE: 0,
        c6B: 0,
        c6SE: 0,
        c6IBB: 0,
        c6IBSE: 0,
        c6CE: 0,
        c6PE: 0,
        c7B: 0,
        c7SE: 0,
        c7IBB: 0,
        c7IBSE: 0,
        c7CE: 0,
        c7PE: 0,
        c8B: 0,
        c8SE: 0,
        c8IBB: 0,
        c8IBSE: 0,
        c8CE: 0,
        c8PE: 0,
        c9B: 0,
        c9SE: 0,
        c9IBB: 0,
        c9IBSE: 0,
        c9CE: 0,
        c9PE: 0,
        c10B: 0,
        c10SE: 0,
        c10IBB: 0,
        c10IBSE: 0,
        c10CE: 0,
        c10PE: 0,
        '5D1': 0,
        '5D2': 0,
        '5D3': 0,
        '5D4': 0,
        '5D5': 0,
        '5D6': 0,
        '6D1': 0,
        '6D2': 0,
        '6D3': 0,
        '6D4': 0,
        '6D5': 0,
      },
      alreadyOpen: {
        p1: false,
        p2: false,
        p3: false,
        s1: false,
        s2: false,
        s3: false,
        s4: false,
        s5: false,
        s6: false,
        s7: false,
        s8: false,
        s9: false,
        s10: false,
        s11: false,
        s12: false,
        s13: false,
        c1: false,
        c2: false,
        c3: false,
        c4: false,
        c5: false,
        c6: false,
        c7: false,
        c8: false,
        c9: false,
        c10: false,
        '5D1': false,
        '5D2': false,
        '5D3': false,
        '6D1': false,
        '7D1': false,
        p1OriPosition: false,
        p2OriPosition: false,
        p3OriPosition: false,
      },
      continue: true,
      shuffledAll4D: [],
      shuffledAll4DIndex: 0,
      shuffledAll5D: [],
      shuffledAll5DIndex: 0,
      shuffledAll6D: [],
      shuffledAll6DIndex: 0,
      DisplayErrorMessage: '',
      DisplayError: false,
      MessageType: '',
      Selectedgame: '8',
      includeI82: true,
      pullingProgress: '起始',
      OpenTime: null,
      minimumPercent: '25',
      maximumPercent: '35',
      currentSecond: 0,
      wordBettingModal: false,
      wordBettingToShow: {
        B: [],
        S: [],
        SA: [],
        SB: [],
        SC: [],
        SD: [],
        SE: [],
        SF: [],
        CA: [],
        CB: [],
        CC: [],
        CD: [],
        CE: [],
        CF: [],
        PA: [],
        PB: [],
        PC: [],
        PD: [],
        PE: [],
        PF: [],
        '5D': [],
        '6D': [],
      },
      alreadyCalculateWordBetting: false,
      wordBettingSortingAsc: true,
      betmodeToRepeat: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D'],
      pausing: false,
      toCopyWord: '',
      historyData: {},
    }
    this.closeGenerateTimeModal = this.closeGenerateTimeModal.bind(this);
    this.closeWordBettingModal = this.closeWordBettingModal.bind(this);
  }
  
  async componentDidMount() {
    let all4D = []
    let all5D = []
    let all6D = []
    for (let i = 0; i <= 9999; i += 1) {
      const wordToUse = i.toString()
      if (wordToUse.length === 1) {
        all4D.push(`000${wordToUse}`)
      } else if (wordToUse.length === 2) {
        all4D.push(`00${wordToUse}`)
      } else if (wordToUse.length === 3) {
        all4D.push(`0${wordToUse}`)
      } else {
        all4D.push(`${wordToUse}`)
      }
    }
    for (let i = 0; i <= 99999; i += 1) {
      const wordToUse = i.toString()
      if (wordToUse.length === 1) {
        all5D.push(`0000${wordToUse}`)
      } else if (wordToUse.length === 2) {
        all5D.push(`000${wordToUse}`)
      } else if (wordToUse.length === 3) {
        all5D.push(`00${wordToUse}`)
      } else if (wordToUse.length === 4) {
        all5D.push(`0${wordToUse}`)
      } else {
        all5D.push(`${wordToUse}`)
      }
    }
    for (let i = 0; i <= 999999; i += 1) {
      const wordToUse = i.toString()
      if (wordToUse.length === 1) {
        all6D.push(`00000${wordToUse}`)
      } else if (wordToUse.length === 2) {
        all6D.push(`0000${wordToUse}`)
      } else if (wordToUse.length === 3) {
        all6D.push(`000${wordToUse}`)
      } else if (wordToUse.length === 4) {
        all6D.push(`00${wordToUse}`)
      } else if (wordToUse.length === 5) {
        all6D.push(`0${wordToUse}`)
      } else {
        all6D.push(`${wordToUse}`)
      }
    }
    let shuffledAll4D = all4D.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
    let shuffledAll5D = all5D.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
    let shuffledAll6D = all6D.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
    const timeJSON = await getTimeJSON(this.props.username, this.props.token, '8')

    this.setState({
      shuffledAll4D,
      shuffledAll4DIndex: 0,
      shuffledAll5D,
      shuffledAll5DIndex: 0,
      shuffledAll6D,
      shuffledAll6DIndex: 0,
      GenerateTime: timeJSON.timeJSON,
      OpenTime: timeJSON.OpenTime || null,
    })
    if (timeJSON.p1OriPosition) {
      const tempword = {
        p1: timeJSON[timeJSON.p1OriPosition],
        p2: timeJSON[timeJSON.p2OriPosition],
        p3: timeJSON[timeJSON.p3OriPosition],
        s1: timeJSON.s1,
        s2: timeJSON.s2,
        s3: timeJSON.s3,
        s4: timeJSON.s4,
        s5: timeJSON.s5,
        s6: timeJSON.s6,
        s7: timeJSON.s7,
        s8: timeJSON.s8,
        s9: timeJSON.s9,
        s10: timeJSON.s10,
        s11: timeJSON.s11,
        s12: timeJSON.s12,
        s13: timeJSON.s13,
        c1: timeJSON.c1,
        c2: timeJSON.c2,
        c3: timeJSON.c3,
        c4: timeJSON.c4,
        c5: timeJSON.c5,
        c6: timeJSON.c6,
        c7: timeJSON.c7,
        c8: timeJSON.c8,
        c9: timeJSON.c9,
        c10: timeJSON.c10,
        '5D1': timeJSON['5D1'],
        '5D2': timeJSON['5D2'],
        '5D3': timeJSON['5D3'],
        '6D1': timeJSON['6D1'],
        '7D1': timeJSON['7D1'],
        p1OriPosition: timeJSON.p1OriPosition,
        p2OriPosition: timeJSON.p2OriPosition,
        p3OriPosition: timeJSON.p3OriPosition,
      }
      tempword[timeJSON.p1OriPosition] = '----'
      tempword[timeJSON.p2OriPosition] = '----'
      tempword[timeJSON.p3OriPosition] = '----'
      this.setState({word: tempword})
    }
    if (timeJSON.OpenTime && timeJSON.p1OriPosition) {
      const startTime = Moment(timeJSON.OpenTime).tz('Asia/Kuala_Lumpur')
      const end = Moment()
      const duration = Moment.duration(end.diff(startTime));
      const seconds = duration.asSeconds();
      const tempredbackground = JSON.parse(JSON.stringify(this.state.redbackground))
      for (let i = 0; i < timeJSON.timeJSON.length; i += 1) {
        if (timeJSON.timeJSON[i].Time <= seconds) {
          tempredbackground[timeJSON.timeJSON[i].Position] = true
        }
      }
      this.setState({ redbackground: tempredbackground, currentSecond: parseInt(seconds)})
      opentimer = setInterval(() => {
        const currentSecond = this.state.currentSecond + 1
        const objecttored = this.state.GenerateTime.find((item => item.Time === currentSecond))
        if (objecttored) {
          this.setState({ redbackground: {...this.state.redbackground, [objecttored.Position]: true}, currentSecond: parseInt(currentSecond) })
        } else {
          this.setState({ currentSecond: parseInt(currentSecond) })
        }
        if ((objecttored && objecttored.Position === '7D1') || currentSecond >= 1200) {
          this.setState({ currentSecond: 0 })
          clearInterval(opentimer)
        }
      }, 1000)
    }
  }
  
  
  convertssdatatoresult (ssdata, ptdata, jjdata, i82data) {
    let sumss = 0
    let sumpt = 0
    let sumjj = 0
    let sum82 = 0
    let sumall = 0
    const result = {}
    for (let k = 0; k < 3; k += 1) {
      let dataToCount = {}
      let sumToUse = 0
      if (k === 0) {
        dataToCount = ssdata
      } else if (k === 1) {
        dataToCount = ptdata
      } else if (k === 2) {
        dataToCount = jjdata
      }

      for (let i = 0; i < dataToCount.length; i += 1) {
        sumToUse += Number(dataToCount[i].sum)
        if (!result[dataToCount[i].betNumber]) {
          result[dataToCount[i].betNumber] = {
            B: 0,
            S: 0,
            SA: 0,
            SB: 0,
            SC: 0,
            SD: 0,
            SE: 0,
            SF: 0,
            IBB: 0,
            IBS: 0,
            IBSA: 0,
            IBSB: 0,
            IBSC: 0,
            IBSD: 0,
            IBSE: 0,
            IBSF: 0,
            CA: 0,
            CB: 0,
            CC: 0,
            CD: 0,
            CE: 0,
            CF: 0,
            PA: 0,
            PB: 0,
            PC: 0,
            PD: 0,
            PE: 0,
            PF: 0,
            '5D': 0,
            '6D': 0,
          }
        }
        if (dataToCount[i].gameRelationId === '100') {
          result[dataToCount[i].betNumber].B += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '200') {
          result[dataToCount[i].betNumber].S += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '300') {
          result[dataToCount[i].betNumber].SA += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '400') {
          result[dataToCount[i].betNumber].SF += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '500') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].B += costForEachPermutation
            result[allPermutation[j]].IBB += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '600') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].S += costForEachPermutation
            result[allPermutation[j]].IBS += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '700') {
          const wordToRecord = dataToCount[i].betNumber.length === 3 ? dataToCount[i].betNumber : dataToCount[i].betNumber.substring(1, 4)
          if (!result[wordToRecord]) {
            result[wordToRecord] = {
              B: 0,
              S: 0,
              SA: 0,
              SB: 0,
              SC: 0,
              SD: 0,
              SE: 0,
              SF: 0,
              IBB: 0,
              IBS: 0,
              IBSA: 0,
              IBSB: 0,
              IBSC: 0,
              IBSD: 0,
              IBSE: 0,
              IBSF: 0,
              CA: 0,
              CB: 0,
              CC: 0,
              CD: 0,
              CE: 0,
              CF: 0,
              PA: 0,
              PB: 0,
              PC: 0,
              PD: 0,
              PE: 0,
              PF: 0,
              '5D': 0,
              '6D': 0,
            }
          }
          result[wordToRecord].CA += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '900') {
          const wordToRecord = dataToCount[i].betNumber.length === 3 ? dataToCount[i].betNumber : dataToCount[i].betNumber.substring(1, 4)
          if (!result[wordToRecord]) {
            result[wordToRecord] = {
              B: 0,
              S: 0,
              SA: 0,
              SB: 0,
              SC: 0,
              SD: 0,
              SE: 0,
              SF: 0,
              IBB: 0,
              IBS: 0,
              IBSA: 0,
              IBSB: 0,
              IBSC: 0,
              IBSD: 0,
              IBSE: 0,
              IBSF: 0,
              CA: 0,
              CB: 0,
              CC: 0,
              CD: 0,
              CE: 0,
              CF: 0,
              PA: 0,
              PB: 0,
              PC: 0,
              PD: 0,
              PE: 0,
              PF: 0,
              '5D': 0,
              '6D': 0,
            }
          }
          result[wordToRecord].CF += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '1200') {
          const wordToRecord = dataToCount[i].betNumber.length === 2 ? dataToCount[i].betNumber : dataToCount[i].betNumber.substring(2, 4)
          if (!result[wordToRecord]) {
            result[wordToRecord] = {
              B: 0,
              S: 0,
              SA: 0,
              SB: 0,
              SC: 0,
              SD: 0,
              SE: 0,
              SF: 0,
              IBB: 0,
              IBS: 0,
              IBSA: 0,
              IBSB: 0,
              IBSC: 0,
              IBSD: 0,
              IBSE: 0,
              IBSF: 0,
              CA: 0,
              CB: 0,
              CC: 0,
              CD: 0,
              CE: 0,
              CF: 0,
              PA: 0,
              PB: 0,
              PC: 0,
              PD: 0,
              PE: 0,
              PF: 0,
              '5D': 0,
              '6D': 0,
            }
          }
          result[wordToRecord].PA += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '1300') {
          const wordToRecord = dataToCount[i].betNumber.length === 2 ? dataToCount[i].betNumber : dataToCount[i].betNumber.substring(2, 4)
          if (!result[wordToRecord]) {
            result[wordToRecord] = {
              B: 0,
              S: 0,
              SA: 0,
              SB: 0,
              SC: 0,
              SD: 0,
              SE: 0,
              SF: 0,
              IBB: 0,
              IBS: 0,
              IBSA: 0,
              IBSB: 0,
              IBSC: 0,
              IBSD: 0,
              IBSE: 0,
              IBSF: 0,
              CA: 0,
              CB: 0,
              CC: 0,
              CD: 0,
              CE: 0,
              CF: 0,
              PA: 0,
              PB: 0,
              PC: 0,
              PD: 0,
              PE: 0,
              PF: 0,
              '5D': 0,
              '6D': 0,
            }
          }
          result[wordToRecord].PF += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '1400') {
          result[dataToCount[i].betNumber].SB += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '1800') {
          result[dataToCount[i].betNumber].SC += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '2200') {
          result[dataToCount[i].betNumber].SD += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '2300') {
          result[dataToCount[i].betNumber].SE += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '2410') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SA += costForEachPermutation
            result[allPermutation[j]].IBSA += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2420') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SB += costForEachPermutation
            result[allPermutation[j]].IBSB += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2430') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SC += costForEachPermutation
            result[allPermutation[j]].IBSC += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2440') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SF += costForEachPermutation
            result[allPermutation[j]].IBSF += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2450') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SD += costForEachPermutation
            result[allPermutation[j]].IBSD += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '2460') {
          const allPermutation = findPermutations(dataToCount[i].betNumber);
          const costForEachPermutation = Number(dataToCount[i].sum) / allPermutation.length
          for (let j = 0; j < allPermutation.length; j += 1) {
            if (!result[allPermutation[j]]) {
              result[allPermutation[j]] = {
                B: 0,
                S: 0,
                SA: 0,
                SB: 0,
                SC: 0,
                SD: 0,
                SE: 0,
                SF: 0,
                IBB: 0,
                IBS: 0,
                IBSA: 0,
                IBSB: 0,
                IBSC: 0,
                IBSD: 0,
                IBSE: 0,
                IBSF: 0,
                CA: 0,
                CB: 0,
                CC: 0,
                CD: 0,
                CE: 0,
                CF: 0,
                PA: 0,
                PB: 0,
                PC: 0,
                PD: 0,
                PE: 0,
                PF: 0,
                '5D': 0,
                '6D': 0,
              }
            }
            result[allPermutation[j]].SE += costForEachPermutation
            result[allPermutation[j]].IBSE += costForEachPermutation
          }
        } else if (dataToCount[i].gameRelationId === '5000') {
          result[dataToCount[i].betNumber]['5D'] += Number(dataToCount[i].sum)
        } else if (dataToCount[i].gameRelationId === '6000') {
          result[dataToCount[i].betNumber]['6D'] += Number(dataToCount[i].sum)
        } 
      }
      if (k === 0) {
        sumss = sumToUse
        sumall += sumToUse
      } else if (k === 1) {
        sumpt = sumToUse
        sumall += sumToUse
      } else if (k === 2) {
        sumjj = sumToUse
        sumall += sumToUse
      }
    }
    if (i82data) {
      let sumToUse = 0
      for (let i = 0; i < i82data.length; i += 1) {
        if (!result[i82data[i].Word]) {
          result[i82data[i].Word] = {
            B: 0,
            S: 0,
            SA: 0,
            SB: 0,
            SC: 0,
            SD: 0,
            SE: 0,
            SF: 0,
            IBB: 0,
            IBS: 0,
            IBSA: 0,
            IBSB: 0,
            IBSC: 0,
            IBSD: 0,
            IBSE: 0,
            IBSF: 0,
            CA: 0,
            CB: 0,
            CC: 0,
            CD: 0,
            CE: 0,
            CF: 0,
            PA: 0,
            PB: 0,
            PC: 0,
            PD: 0,
            PE: 0,
            PF: 0,
            '5D': 0,
            '6D': 0,
          }
        }
        if (i82data[i].B) { result[i82data[i].Word].B += i82data[i].B; sumToUse += i82data[i].B }
        if (i82data[i].S) { result[i82data[i].Word].S += i82data[i].S; sumToUse += i82data[i].S }
        if (i82data[i].SA) { result[i82data[i].Word].SA += i82data[i].SA; sumToUse += i82data[i].SA }
        if (i82data[i].SB) { result[i82data[i].Word].SB += i82data[i].SB; sumToUse += i82data[i].SB }
        if (i82data[i].SC) { result[i82data[i].Word].SC += i82data[i].SC; sumToUse += i82data[i].SC }
        if (i82data[i].SD) { result[i82data[i].Word].SD += i82data[i].SD; sumToUse += i82data[i].SD }
        if (i82data[i].SE) { result[i82data[i].Word].SE += i82data[i].SE; sumToUse += i82data[i].SE }
        if (i82data[i].SF) { result[i82data[i].Word].SF += i82data[i].SF; sumToUse += i82data[i].SF }
        if (i82data[i].CA) { result[i82data[i].Word].CA += i82data[i].CA; sumToUse += i82data[i].CA }
        if (i82data[i].CB) { result[i82data[i].Word].CB += i82data[i].CB; sumToUse += i82data[i].CB }
        if (i82data[i].CC) { result[i82data[i].Word].CC += i82data[i].CC; sumToUse += i82data[i].CC }
        if (i82data[i].CD) { result[i82data[i].Word].CD += i82data[i].CD; sumToUse += i82data[i].CD }
        if (i82data[i].CE) { result[i82data[i].Word].CE += i82data[i].CE; sumToUse += i82data[i].CE }
        if (i82data[i].CF) { result[i82data[i].Word].CF += i82data[i].CF; sumToUse += i82data[i].CF }
        if (i82data[i].PA) { result[i82data[i].Word].PA += i82data[i].PA; sumToUse += i82data[i].PA }
        if (i82data[i].PB) { result[i82data[i].Word].PB += i82data[i].PB; sumToUse += i82data[i].PB }
        if (i82data[i].PC) { result[i82data[i].Word].PC += i82data[i].PC; sumToUse += i82data[i].PC }
        if (i82data[i].PD) { result[i82data[i].Word].PD += i82data[i].PD; sumToUse += i82data[i].PD }
        if (i82data[i].PE) { result[i82data[i].Word].PE += i82data[i].PE; sumToUse += i82data[i].PE }
        if (i82data[i].PF) { result[i82data[i].Word].PF += i82data[i].PF; sumToUse += i82data[i].PF }
        if (i82data[i]['5D']) { result[i82data[i].Word]['5D'] += i82data[i]['5D']; sumToUse += i82data[i]['5D'] }
        if (i82data[i]['6D']) { result[i82data[i].Word]['6D'] += i82data[i]['6D']; sumToUse += i82data[i]['6D'] }
      }
      sum82 = sumToUse
      sumall += sumToUse
    }
    this.setState({ result, totalSalesss: sumss.toFixed(2), totalSalespt: sumpt.toFixed(2), totalSalesjj: sumjj.toFixed(2), totalSales82: sum82.toFixed(2), totalSales: sumall.toFixed(2) })
  }
  async pullResult(type) {
    this.setState({ Selectedgame: type, pullingProgress: '拉 SS 中' })
    const ssdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://91.121.33.26/index.php?r=api/emhosrapmfqe80i3j&gh8gevcvakhpjev6=89morkzr1gyqxfod`)
    this.setState({ pullingProgress: '拉 PT 中'})
    const ptdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://54.38.221.165/index.php?r=api/whqtj6mhit7iptp6&casg8jpysqii321l=ioshwpxe1l60nevj`)
    this.setState({ pullingProgress: '拉 JJ 中'})
    const jjdata = await getDataByLink(`${window.location.protocol === 'https:' ? 'https' : 'http'}://51.68.194.199/index.php?r=api/0ytfznmwr6aj9oco&fxxl0sig30460wth=rll0hpimhc3twm8n`)
    this.setState({ pullingProgress: '拉 82 中'})
    const i82data = await geti82Data(this.props.username, this.props.token)
    this.setState({ pullingProgress: '拉完了'})
    this.convertssdatatoresult(ssdata, ptdata, jjdata, i82data)
    // for testing
    // this.setState({ Selectedgame: type })
    // this.convertssdatatoresult(ssdatajson, ptdatajson, jjdatajson)
  }

  calculateResult(wordtemp, updateHistoryData) { 
    let prizeTemp = {p1: 0, p2: 0, p3: 0, s1: 0, s2: 0, s3: 0, s4: 0, s5: 0, s6: 0, s7: 0, s8: 0, s9: 0, s10: 0, s11: 0, s12: 0, s13: 0, c1: 0, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0, c7: 0, c8: 0, c9: 0, c10: 0, '5D1': 0, '5D2': 0, '5D3': 0, '5D4': 0, '5D5': 0, '5D6': 0, '6D1': 0, '6D2': 0, '6D3': 0, '6D4': 0, '6D5': 0}
    let subTotalTemp = {p1: 0, p2: 0, p3: 0, s1: 0, s2: 0, s3: 0, s4: 0, s5: 0, s6: 0, s7: 0, s8: 0, s9: 0, s10: 0, s11: 0, s12: 0, s13: 0, c1: 0, c2: 0, c3: 0, c4: 0, c5: 0, c6: 0, c7: 0, c8: 0, c9: 0, c10: 0, '5D1': 0, '5D2': 0, '5D3': 0, '5D4': 0, '5D5': 0, '5D6': 0, '6D1': 0, '6D2': 0, '6D3': 0, '6D4': 0, '6D5': 0}
    const result = this.state.result
    const keys = Object.keys(result)
    const keys5D = keys.filter((item) => item.length === 5)
    const keys6D = keys.filter((item) => item.length === 6)
    const odd = this.state.odd

    let word = JSON.parse(JSON.stringify(wordtemp || this.state.word))
    if (wordtemp) {
      word.p1 = word[word.p1OriPosition]
      word[word.p1OriPosition] = ''
      word.p2 = word[word.p2OriPosition]
      word[word.p2OriPosition] = ''
      word.p3 = word[word.p3OriPosition]
      word[word.p3OriPosition] = ''
    }
    
    if (result[word.p1]) {
      prizeTemp.p1 += result[word.p1].B * odd.B1 + result[word.p1].S * odd.S1 + result[word.p1].SA * odd.SA + result[word.p1].SF * odd.SF
      subTotalTemp.p1 += result[word.p1].B + result[word.p1].S + result[word.p1].SA + result[word.p1].SF
    }
    if (result[word.p1.substring(1, 4)]) {
      prizeTemp.p1 += result[word.p1.substring(1, 4)].CA * odd.CA + result[word.p1.substring(1, 4)].CF * odd.CF
      subTotalTemp.p1 += result[word.p1.substring(1, 4)].CA + result[word.p1.substring(1, 4)].CF
    }
    if (result[word.p1.substring(2, 4)]) {
      prizeTemp.p1 += result[word.p1.substring(2, 4)].PA * odd.PA + result[word.p1.substring(2, 4)].PF * odd.PF
      subTotalTemp.p1 += result[word.p1.substring(2, 4)].PA + result[word.p1.substring(2, 4)].PF
    }
    if (result[word.p2]) {
      prizeTemp.p2 += result[word.p2].B * odd.B2 + result[word.p2].S * odd.S2 + result[word.p2].SB * odd.SB + result[word.p2].SF * odd.SF
      subTotalTemp.p2 += result[word.p2].B + result[word.p2].S + result[word.p2].SB + result[word.p2].SF
    }
    if (result[word.p2.substring(1, 4)]) {
      prizeTemp.p2 += result[word.p2.substring(1, 4)].CB * odd.CB + result[word.p2.substring(1, 4)].CF * odd.CF
      subTotalTemp.p2 += result[word.p2.substring(1, 4)].CB + result[word.p2.substring(1, 4)].CF
    }
    if (result[word.p2.substring(2, 4)]) {
      prizeTemp.p2 += result[word.p2.substring(2, 4)].PB * odd.PB + result[word.p2.substring(2, 4)].PF * odd.PF
      subTotalTemp.p2 += result[word.p2.substring(2, 4)].PB + result[word.p2.substring(2, 4)].PF
    }
    if (result[word.p3]) {
      prizeTemp.p3 += result[word.p3].B * odd.B3 + result[word.p3].S * odd.S3 + result[word.p3].SC * odd.SC + result[word.p3].SF * odd.SF
      subTotalTemp.p3 += result[word.p3].B + result[word.p3].S + result[word.p3].SC + result[word.p3].SF
    }
    if (result[word.p3.substring(1, 4)]) {
      prizeTemp.p3 += result[word.p3.substring(1, 4)].CC * odd.CC + result[word.p3.substring(1, 4)].CF * odd.CF
      subTotalTemp.p3 += result[word.p3.substring(1, 4)].CC + result[word.p3.substring(1, 4)].CF
    }
    if (result[word.p3.substring(2, 4)]) {
      prizeTemp.p3 += result[word.p3.substring(2, 4)].PC * odd.PC + result[word.p3.substring(2, 4)].PF * odd.PF
      subTotalTemp.p3 += result[word.p3.substring(2, 4)].PC + result[word.p3.substring(2, 4)].PF
    }
    let loopToRepeat = ['s1', 's2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10', 's11', 's12', 's13']
    for (let i = 0; i < loopToRepeat.length; i += 1) {
      if (result[word[loopToRepeat[i]]]) {
        prizeTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]]].B * odd.B4 + result[word[loopToRepeat[i]]].SD * odd.SD
        subTotalTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]]].B + result[word[loopToRepeat[i]]].SD
      }
      if (result[word[loopToRepeat[i].substring(1, 4)]]) {
        prizeTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]].substring(1, 4)].CD * odd.CD
        subTotalTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]].substring(1, 4)].CD
      }
      if (result[word[loopToRepeat[i].substring(2, 4)]]) {
        prizeTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]].substring(2, 4)].PD * odd.PD
        subTotalTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]].substring(2, 4)].PD
      }
    }
    loopToRepeat = ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10', 'c11', 'c12', 'c13']
    for (let i = 0; i < loopToRepeat.length; i += 1) {
      if (result[word[loopToRepeat[i]]]) {
        prizeTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]]].B * odd.B5 + result[word[loopToRepeat[i]]].SE * odd.SE
        subTotalTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]]].B + result[word[loopToRepeat[i]]].SE
      }
      if (result[word[loopToRepeat[i].substring(1, 4)]]) {
        prizeTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]].substring(1, 4)].CE * odd.CE
        subTotalTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]].substring(1, 4)].CE
      }
      if (result[word[loopToRepeat[i].substring(2, 4)]]) {
        prizeTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]].substring(2, 4)].PE * odd.PE
        subTotalTemp[loopToRepeat[i]] += result[word[loopToRepeat[i]].substring(2, 4)].PE
      }
    }
    let alreadyCalculate = []
    if (result[word['5D1']]) {
      prizeTemp['5D1'] += result[word['5D1']]['5D'] * odd['5D1']
      subTotalTemp['5D1'] += result[word['5D1']]['5D']
      alreadyCalculate.push(word['5D1'])
    }
    if (result[word['5D2']]) {
      prizeTemp['5D2'] += result[word['5D2']]['5D'] * odd['5D2']
      subTotalTemp['5D2'] += result[word['5D2']]['5D']
    }
    if (result[word['5D3']]) {
      prizeTemp['5D3'] += result[word['5D3']]['5D'] * odd['5D3']
      subTotalTemp['5D3'] += result[word['5D3']]['5D']
    }
    const keys5Dget5D4 = keys5D.filter((item) => item.substring(1, 5) === word['5D1'].substring(1, 5))
    for (let i = 0; i < keys5Dget5D4.length; i += 1) {
      if (alreadyCalculate.includes(keys5Dget5D4[i])) {
        // ignore
      } else {
        prizeTemp['5D4'] += result[keys5Dget5D4[i]]['5D'] * odd['5D4']
        subTotalTemp['5D4'] += result[keys5Dget5D4[i]]['5D']
        alreadyCalculate.push(keys5Dget5D4[i])
      }
    }
    const keys5Dget5D5 = keys5D.filter((item) => item.substring(2, 5) === word['5D1'].substring(2, 5))
    for (let i = 0; i < keys5Dget5D5.length; i += 1) {
      if (alreadyCalculate.includes(keys5Dget5D5[i])) {
        // ignore
      } else {
        prizeTemp['5D5'] += result[keys5Dget5D5[i]]['5D'] * odd['5D5']
        subTotalTemp['5D5'] += result[keys5Dget5D5[i]]['5D']
        alreadyCalculate.push(keys5Dget5D5[i])
      }
    }
    const keys5Dget5D6 = keys5D.filter((item) => item.substring(3, 5) === word['5D1'].substring(3, 5))
    for (let i = 0; i < keys5Dget5D6.length; i += 1) {
      if (alreadyCalculate.includes(keys5Dget5D6[i])) {
        // ignore
      } else {
        prizeTemp['5D6'] += result[keys5Dget5D6[i]]['5D'] * odd['5D6']
        subTotalTemp['5D6'] += result[keys5Dget5D6[i]]['5D']
        alreadyCalculate.push(keys5Dget5D6[i])
      }
    }
    alreadyCalculate = []
    if (result[word['6D1']]) {
      prizeTemp['6D1'] += result[word['6D1']]['6D'] * odd['6D1']
      subTotalTemp['6D1'] += result[word['6D1']]['6D']
      alreadyCalculate.push(word['6D1'])
    }
    const keys6Dget6D2 = keys6D.filter((item) => item.substring(0, 5) === word['6D1'].substring(0, 5) || item.substring(1, 6) === word['6D1'].substring(1, 6))
    for (let i = 0; i < keys6Dget6D2.length; i += 1) {
      if (alreadyCalculate.includes(keys6Dget6D2[i])) {
        // ignore
      } else {
        prizeTemp['6D2'] += result[keys6Dget6D2[i]]['6D'] * odd['6D2']
        subTotalTemp['6D2'] += result[keys6Dget6D2[i]]['6D']
        alreadyCalculate.push(keys6Dget6D2[i])
      }
    }
    const keys6Dget6D3 = keys6D.filter((item) => item.substring(0, 4) === word['6D1'].substring(0, 4) || item.substring(2, 6) === word['6D1'].substring(2, 6))
    for (let i = 0; i < keys6Dget6D3.length; i += 1) {
      if (alreadyCalculate.includes(keys6Dget6D3[i])) {
        // ignore
      } else {
        prizeTemp['6D3'] += result[keys6Dget6D3[i]]['6D'] * odd['6D3']
        subTotalTemp['6D3'] += result[keys6Dget6D3[i]]['6D']
        alreadyCalculate.push(keys6Dget6D3[i])
      }
    }
    const keys6Dget6D4 = keys6D.filter((item) => item.substring(0, 3) === word['6D1'].substring(0, 3) || item.substring(3, 6) === word['6D1'].substring(3, 6))
    for (let i = 0; i < keys6Dget6D4.length; i += 1) {
      if (alreadyCalculate.includes(keys6Dget6D4[i])) {
        // ignore
      } else {
        prizeTemp['6D4'] += result[keys6Dget6D4[i]]['6D'] * odd['6D4']
        subTotalTemp['6D4'] += result[keys6Dget6D4[i]]['6D']
        alreadyCalculate.push(keys6Dget6D4[i])
      }
    }
    const keys6Dget6D5 = keys6D.filter((item) => item.substring(0, 2) === word['6D1'].substring(0, 2) || item.substring(4, 6) === word['6D1'].substring(4, 6))
    for (let i = 0; i < keys6Dget6D5.length; i += 1) {
      if (alreadyCalculate.includes(keys6Dget6D5[i])) {
        // ignore
      } else {
        prizeTemp['6D5'] += result[keys6Dget6D5[i]]['6D'] * odd['6D5']
        subTotalTemp['6D5'] += result[keys6Dget6D5[i]]['6D']
        alreadyCalculate.push(keys6Dget6D5[i])
      }
    }
    const totalWin = Number((prizeTemp.p1 + prizeTemp.p2 + prizeTemp.p3 + prizeTemp.s1 + prizeTemp.s2 + prizeTemp.s3 + prizeTemp.s4 + prizeTemp.s5 + prizeTemp.s6 + prizeTemp.s7 + prizeTemp.s8 + prizeTemp.s9 + prizeTemp.s10 + prizeTemp.s11 + prizeTemp.s12 + prizeTemp.s13 + prizeTemp.c1 + prizeTemp.c2 + prizeTemp.c3 + prizeTemp.c4 + prizeTemp.c5 + prizeTemp.c6 + prizeTemp.c7 + prizeTemp.c8 + prizeTemp.c9 + prizeTemp.c10 + prizeTemp['5D1'] + prizeTemp['5D2'] + prizeTemp['5D3'] + prizeTemp['5D4'] + prizeTemp['5D5'] + prizeTemp['5D6'] + prizeTemp['6D1'] + prizeTemp['6D2'] + prizeTemp['6D3'] + prizeTemp['6D4'] + prizeTemp['6D5']).toFixed(2))
    const totalWinPercent = Number((totalWin / this.state.totalSales * 100).toFixed(2))
    const objecttemp = { 
      prize: {
        p1: Number(prizeTemp.p1.toFixed(2)),
        p2: Number(prizeTemp.p2.toFixed(2)),
        p3: Number(prizeTemp.p3.toFixed(2)),
        s1: Number(prizeTemp.s1.toFixed(2)),
        s2: Number(prizeTemp.s2.toFixed(2)),
        s3: Number(prizeTemp.s3.toFixed(2)),
        s4: Number(prizeTemp.s4.toFixed(2)),
        s5: Number(prizeTemp.s5.toFixed(2)),
        s6: Number(prizeTemp.s6.toFixed(2)),
        s7: Number(prizeTemp.s7.toFixed(2)),
        s8: Number(prizeTemp.s8.toFixed(2)),
        s9: Number(prizeTemp.s9.toFixed(2)),
        s10: Number(prizeTemp.s10.toFixed(2)),
        s11: Number(prizeTemp.s11.toFixed(2)),
        s12: Number(prizeTemp.s12.toFixed(2)),
        s13: Number(prizeTemp.s13.toFixed(2)),
        c1: Number(prizeTemp.c1.toFixed(2)),
        c2: Number(prizeTemp.c2.toFixed(2)),
        c3: Number(prizeTemp.c3.toFixed(2)),
        c4: Number(prizeTemp.c4.toFixed(2)),
        c5: Number(prizeTemp.c5.toFixed(2)),
        c6: Number(prizeTemp.c6.toFixed(2)),
        c7: Number(prizeTemp.c7.toFixed(2)),
        c8: Number(prizeTemp.c8.toFixed(2)),
        c9: Number(prizeTemp.c9.toFixed(2)),
        c10: Number(prizeTemp.c10.toFixed(2)),
        '5D1': Number(prizeTemp['5D1'].toFixed(2)),
        '5D2': Number(prizeTemp['5D2'].toFixed(2)),
        '5D3': Number(prizeTemp['5D3'].toFixed(2)),
        '5D4': Number(prizeTemp['5D4'].toFixed(2)),
        '5D5': Number(prizeTemp['5D5'].toFixed(2)),
        '5D6': Number(prizeTemp['5D6'].toFixed(2)),
        '6D1': Number(prizeTemp['6D1'].toFixed(2)),
        '6D2': Number(prizeTemp['6D2'].toFixed(2)),
        '6D3': Number(prizeTemp['6D3'].toFixed(2)),
        '6D4': Number(prizeTemp['6D4'].toFixed(2)),
        '6D5': Number(prizeTemp['6D5'].toFixed(2)),
      },
      subtotal: {
        p1: Number(subTotalTemp.p1.toFixed(2)),
        p2: Number(subTotalTemp.p2.toFixed(2)),
        p3: Number(subTotalTemp.p3.toFixed(2)),
        s1: Number(subTotalTemp.s1.toFixed(2)),
        s2: Number(subTotalTemp.s2.toFixed(2)),
        s3: Number(subTotalTemp.s3.toFixed(2)),
        s4: Number(subTotalTemp.s4.toFixed(2)),
        s5: Number(subTotalTemp.s5.toFixed(2)),
        s6: Number(subTotalTemp.s6.toFixed(2)),
        s7: Number(subTotalTemp.s7.toFixed(2)),
        s8: Number(subTotalTemp.s8.toFixed(2)),
        s9: Number(subTotalTemp.s9.toFixed(2)),
        s10: Number(subTotalTemp.s10.toFixed(2)),
        s11: Number(subTotalTemp.s11.toFixed(2)),
        s12: Number(subTotalTemp.s12.toFixed(2)),
        s13: Number(subTotalTemp.s13.toFixed(2)),
        c1: Number(subTotalTemp.c1.toFixed(2)),
        c2: Number(subTotalTemp.c2.toFixed(2)),
        c3: Number(subTotalTemp.c3.toFixed(2)),
        c4: Number(subTotalTemp.c4.toFixed(2)),
        c5: Number(subTotalTemp.c5.toFixed(2)),
        c6: Number(subTotalTemp.c6.toFixed(2)),
        c7: Number(subTotalTemp.c7.toFixed(2)),
        c8: Number(subTotalTemp.c8.toFixed(2)),
        c9: Number(subTotalTemp.c9.toFixed(2)),
        c10: Number(subTotalTemp.c10.toFixed(2)),
        '5D1': Number(subTotalTemp['5D1'].toFixed(2)),
        '5D2': Number(subTotalTemp['5D2'].toFixed(2)),
        '5D3': Number(subTotalTemp['5D3'].toFixed(2)),
        '5D4': Number(subTotalTemp['5D4'].toFixed(2)),
        '5D5': Number(subTotalTemp['5D5'].toFixed(2)),
        '5D6': Number(subTotalTemp['5D6'].toFixed(2)),
        '6D1': Number(subTotalTemp['6D1'].toFixed(2)),
        '6D2': Number(subTotalTemp['6D2'].toFixed(2)),
        '6D3': Number(subTotalTemp['6D3'].toFixed(2)),
        '6D4': Number(subTotalTemp['6D4'].toFixed(2)),
        '6D5': Number(subTotalTemp['6D5'].toFixed(2)),
      },
      totalWin,
      totalWinPercent,
      subtotaldetail: {
        p1B: result[word.p1] ? (result[word.p1].B - result[word.p1].IBB).toFixed(2) : 0,
        p1S: result[word.p1] ? (result[word.p1].S - result[word.p1].IBS).toFixed(2) : 0,
        p1SA: result[word.p1] ? (result[word.p1].SA - result[word.p1].IBSA).toFixed(2) : 0,
        p1SF: result[word.p1] ? (result[word.p1].SF - result[word.p1].IBSF).toFixed(2) : 0,
        p1IBB: result[word.p1] ? result[word.p1].IBB.toFixed(2) : 0,
        p1IBS: result[word.p1] ? result[word.p1].IBS.toFixed(2) : 0,
        p1IBSA: result[word.p1] ? result[word.p1].IBSA.toFixed(2) : 0,
        p1IBSF: result[word.p1] ? result[word.p1].IBSF.toFixed(2) : 0,
        p1CA: result[word.p1.substring(1, 4)] ? result[word.p1.substring(1, 4)].CA.toFixed(2) : 0,
        p1CF: result[word.p1.substring(1, 4)] ? result[word.p1.substring(1, 4)].CF.toFixed(2) : 0,
        p1PA: result[word.p1.substring(2, 4)] ? result[word.p1.substring(2, 4)].PA.toFixed(2) : 0,
        p1PF: result[word.p1.substring(2, 4)] ? result[word.p1.substring(2, 4)].PF.toFixed(2) : 0,

        p2B: result[word.p2] ? (result[word.p2].B - result[word.p2].IBB).toFixed(2) : 0,
        p2S: result[word.p2] ? (result[word.p2].S - result[word.p2].IBS).toFixed(2) : 0,
        p2SB: result[word.p2] ? (result[word.p2].SB - result[word.p2].IBSB).toFixed(2) : 0,
        p2SF: result[word.p2] ? (result[word.p2].SF - result[word.p2].IBSF).toFixed(2) : 0,
        p2IBB: result[word.p2] ? result[word.p2].IBB.toFixed(2) : 0,
        p2IBS: result[word.p2] ? result[word.p2].IBS.toFixed(2) : 0,
        p2IBSB: result[word.p2] ? result[word.p2].IBSB.toFixed(2) : 0,
        p2IBSF: result[word.p2] ? result[word.p2].IBSF.toFixed(2) : 0,
        p2CB: result[word.p2.substring(1, 4)] ? result[word.p2.substring(1, 4)].CB.toFixed(2) : 0,
        p2CF: result[word.p2.substring(1, 4)] ? result[word.p2.substring(1, 4)].CF.toFixed(2) : 0,
        p2PB: result[word.p2.substring(2, 4)] ? result[word.p2.substring(2, 4)].PB.toFixed(2) : 0,
        p2PF: result[word.p2.substring(2, 4)] ? result[word.p2.substring(2, 4)].PF.toFixed(2) : 0,

        p3B: result[word.p3] ? (result[word.p3].B - result[word.p3].IBB).toFixed(2) : 0,
        p3S: result[word.p3] ? (result[word.p3].S - result[word.p3].IBS).toFixed(2) : 0,
        p3SC: result[word.p3] ? (result[word.p3].SC - result[word.p3].IBSC).toFixed(2) : 0,
        p3SF: result[word.p3] ? (result[word.p3].SF - result[word.p3].IBSF).toFixed(2) : 0,
        p3IBB: result[word.p3] ? result[word.p3].IBB.toFixed(2) : 0,
        p3IBS: result[word.p3] ? result[word.p3].IBS.toFixed(2) : 0,
        p3IBSC: result[word.p3] ? result[word.p3].IBSC.toFixed(2) : 0,
        p3IBSF: result[word.p3] ? result[word.p3].IBSF.toFixed(2) : 0,
        p3CC: result[word.p3.substring(1, 4)] ? result[word.p3.substring(1, 4)].CC.toFixed(2) : 0,
        p3CF: result[word.p3.substring(1, 4)] ? result[word.p3.substring(1, 4)].CF.toFixed(2) : 0,
        p3PC: result[word.p3.substring(2, 4)] ? result[word.p3.substring(2, 4)].PA.toFixed(2) : 0,
        p3PF: result[word.p3.substring(2, 4)] ? result[word.p3.substring(2, 4)].PF.toFixed(2) : 0,

        s1B: result[word.s1] ? (result[word.s1].B - result[word.s1].IBB).toFixed(2) : 0,
        s1SD: result[word.s1] ? (result[word.s1].SD - result[word.s1].IBSD).toFixed(2) : 0,
        s1IBB: result[word.s1] ? result[word.s1].IBB.toFixed(2) : 0,
        s1IBSD: result[word.s1] ? result[word.s1].IBSD.toFixed(2) : 0,
        s1CD: result[word.s1.substring(1, 4)] ? result[word.s1.substring(1, 4)].CD.toFixed(2) : 0,
        s1PD: result[word.s1.substring(2, 4)] ? result[word.s1.substring(2, 4)].PD.toFixed(2) : 0,

        s2B: result[word.s2] ? (result[word.s2].B - result[word.s2].IBB).toFixed(2) : 0,
        s2SD: result[word.s2] ? (result[word.s2].SD - result[word.s2].IBSD).toFixed(2) : 0,
        s2IBB: result[word.s2] ? result[word.s2].IBB.toFixed(2) : 0,
        s2IBSD: result[word.s2] ? result[word.s2].IBSD.toFixed(2) : 0,
        s2CD: result[word.s2.substring(1, 4)] ? result[word.s2.substring(1, 4)].CD.toFixed(2) : 0,
        s2PD: result[word.s2.substring(2, 4)] ? result[word.s2.substring(2, 4)].PD.toFixed(2) : 0,

        s3B: result[word.s3] ? (result[word.s3].B - result[word.s3].IBB).toFixed(2) : 0,
        s3SD: result[word.s3] ? (result[word.s3].SD - result[word.s3].IBSD).toFixed(2) : 0,
        s3IBB: result[word.s3] ? result[word.s3].IBB.toFixed(2) : 0,
        s3IBSD: result[word.s3] ? result[word.s3].IBSD.toFixed(2) : 0,
        s3CD: result[word.s3.substring(1, 4)] ? result[word.s3.substring(1, 4)].CD.toFixed(2) : 0,
        s3PD: result[word.s3.substring(2, 4)] ? result[word.s3.substring(2, 4)].PD.toFixed(2) : 0,

        s4B: result[word.s4] ? (result[word.s4].B - result[word.s4].IBB).toFixed(2) : 0,
        s4SD: result[word.s4] ? (result[word.s4].SD - result[word.s4].IBSD).toFixed(2) : 0,
        s4IBB: result[word.s4] ? result[word.s4].IBB.toFixed(2) : 0,
        s4IBSD: result[word.s4] ? result[word.s4].IBSD.toFixed(2) : 0,
        s4CD: result[word.s4.substring(1, 4)] ? result[word.s4.substring(1, 4)].CD.toFixed(2) : 0,
        s4PD: result[word.s4.substring(2, 4)] ? result[word.s4.substring(2, 4)].PD.toFixed(2) : 0,

        s5B: result[word.s5] ? (result[word.s5].B - result[word.s5].IBB).toFixed(2) : 0,
        s5SD: result[word.s5] ? (result[word.s5].SD - result[word.s5].IBSD).toFixed(2) : 0,
        s5IBB: result[word.s5] ? result[word.s5].IBB.toFixed(2) : 0,
        s5IBSD: result[word.s5] ? result[word.s5].IBSD.toFixed(2) : 0,
        s5CD: result[word.s5.substring(1, 4)] ? result[word.s5.substring(1, 4)].CD.toFixed(2) : 0,
        s5PD: result[word.s5.substring(2, 4)] ? result[word.s5.substring(2, 4)].PD.toFixed(2) : 0,
        
        s6B: result[word.s6] ? (result[word.s6].B - result[word.s6].IBB).toFixed(2) : 0,
        s6SD: result[word.s6] ? (result[word.s6].SD - result[word.s6].IBSD).toFixed(2) : 0,
        s6IBB: result[word.s6] ? result[word.s6].IBB.toFixed(2) : 0,
        s6IBSD: result[word.s6] ? result[word.s6].IBSD.toFixed(2) : 0,
        s6CD: result[word.s6.substring(1, 4)] ? result[word.s6.substring(1, 4)].CD.toFixed(2) : 0,
        s6PD: result[word.s6.substring(2, 4)] ? result[word.s6.substring(2, 4)].PD.toFixed(2) : 0,

        s7B: result[word.s7] ? (result[word.s7].B - result[word.s7].IBB).toFixed(2) : 0,
        s7SD: result[word.s7] ? (result[word.s7].SD - result[word.s7].IBSD).toFixed(2) : 0,
        s7IBB: result[word.s7] ? result[word.s7].IBB.toFixed(2) : 0,
        s7IBSD: result[word.s7] ? result[word.s7].IBSD.toFixed(2) : 0,
        s7CD: result[word.s7.substring(1, 4)] ? result[word.s7.substring(1, 4)].CD.toFixed(2) : 0,
        s7PD: result[word.s7.substring(2, 4)] ? result[word.s7.substring(2, 4)].PD.toFixed(2) : 0,

        s8B: result[word.s8] ? (result[word.s8].B - result[word.s8].IBB).toFixed(2) : 0,
        s8SD: result[word.s8] ? (result[word.s8].SD - result[word.s8].IBSD).toFixed(2) : 0,
        s8IBB: result[word.s8] ? result[word.s8].IBB.toFixed(2) : 0,
        s8IBSD: result[word.s8] ? result[word.s8].IBSD.toFixed(2) : 0,
        s8CD: result[word.s8.substring(1, 4)] ? result[word.s8.substring(1, 4)].CD.toFixed(2) : 0,
        s8PD: result[word.s8.substring(2, 4)] ? result[word.s8.substring(2, 4)].PD.toFixed(2) : 0,

        s9B: result[word.s9] ? (result[word.s9].B - result[word.s9].IBB).toFixed(2) : 0,
        s9SD: result[word.s9] ? (result[word.s9].SD - result[word.s9].IBSD).toFixed(2) : 0,
        s9IBB: result[word.s9] ? result[word.s9].IBB.toFixed(2) : 0,
        s9IBSD: result[word.s9] ? result[word.s9].IBSD.toFixed(2) : 0,
        s9CD: result[word.s9.substring(1, 4)] ? result[word.s9.substring(1, 4)].CD.toFixed(2) : 0,
        s9PD: result[word.s9.substring(2, 4)] ? result[word.s9.substring(2, 4)].PD.toFixed(2) : 0,

        s10B: result[word.s10] ? (result[word.s10].B - result[word.s10].IBB).toFixed(2) : 0,
        s10SD: result[word.s10] ? (result[word.s10].SD - result[word.s10].IBSD).toFixed(2) : 0,
        s10IBB: result[word.s10] ? result[word.s10].IBB.toFixed(2) : 0,
        s10IBSD: result[word.s10] ? result[word.s10].IBSD.toFixed(2) : 0,
        s10CD: result[word.s10.substring(1, 4)] ? result[word.s10.substring(1, 4)].CD.toFixed(2) : 0,
        s10PD: result[word.s10.substring(2, 4)] ? result[word.s10.substring(2, 4)].PD.toFixed(2) : 0,

        s11B: result[word.s11] ? (result[word.s11].B - result[word.s11].IBB).toFixed(2) : 0,
        s11SD: result[word.s11] ? (result[word.s11].SD - result[word.s11].IBSD).toFixed(2) : 0,
        s11IBB: result[word.s11] ? result[word.s11].IBB.toFixed(2) : 0,
        s11IBSD: result[word.s11] ? result[word.s11].IBSD.toFixed(2) : 0,
        s11CD: result[word.s11.substring(1, 4)] ? result[word.s11.substring(1, 4)].CD.toFixed(2) : 0,
        s11PD: result[word.s11.substring(2, 4)] ? result[word.s11.substring(2, 4)].PD.toFixed(2) : 0,

        s12B: result[word.s12] ? (result[word.s12].B - result[word.s12].IBB).toFixed(2) : 0,
        s12SD: result[word.s12] ? (result[word.s12].SD - result[word.s12].IBSD).toFixed(2) : 0,
        s12IBB: result[word.s12] ? result[word.s12].IBB.toFixed(2) : 0,
        s12IBSD: result[word.s12] ? result[word.s12].IBSD.toFixed(2) : 0,
        s12CD: result[word.s12.substring(1, 4)] ? result[word.s12.substring(1, 4)].CD.toFixed(2) : 0,
        s12PD: result[word.s12.substring(2, 4)] ? result[word.s12.substring(2, 4)].PD.toFixed(2) : 0,

        s13B: result[word.s13] ? (result[word.s13].B - result[word.s13].IBB).toFixed(2) : 0,
        s13SD: result[word.s13] ? (result[word.s13].SD - result[word.s13].IBSD).toFixed(2) : 0,
        s13IBB: result[word.s13] ? result[word.s13].IBB.toFixed(2) : 0,
        s13IBSD: result[word.s13] ? result[word.s13].IBSD.toFixed(2) : 0,
        s13CD: result[word.s13.substring(1, 4)] ? result[word.s13.substring(1, 4)].CD.toFixed(2) : 0,
        s13PD: result[word.s13.substring(2, 4)] ? result[word.s13.substring(2, 4)].PD.toFixed(2) : 0,

        c1B: result[word.c1] ? (result[word.c1].B - result[word.c1].IBB).toFixed(2) : 0,
        c1SE: result[word.c1] ? (result[word.c1].SE - result[word.c1].IBSE).toFixed(2) : 0,
        c1IBB: result[word.c1] ? result[word.c1].IBB.toFixed(2) : 0,
        c1IBSE: result[word.c1] ? result[word.c1].IBSE.toFixed(2) : 0,
        c1CE: result[word.c1.substring(1, 4)] ? result[word.c1.substring(1, 4)].CE.toFixed(2) : 0,
        c1PE: result[word.c1.substring(2, 4)] ? result[word.c1.substring(2, 4)].PE.toFixed(2) : 0,

        c2B: result[word.c2] ? (result[word.c2].B - result[word.c2].IBB).toFixed(2) : 0,
        c2SE: result[word.c2] ? (result[word.c2].SE - result[word.c2].IBSE).toFixed(2) : 0,
        c2IBB: result[word.c2] ? result[word.c2].IBB.toFixed(2) : 0,
        c2IBSE: result[word.c2] ? result[word.c2].IBSE.toFixed(2) : 0,
        c2CE: result[word.c2.substring(1, 4)] ? result[word.c2.substring(1, 4)].CE.toFixed(2) : 0,
        c2PE: result[word.c2.substring(2, 4)] ? result[word.c2.substring(2, 4)].PE.toFixed(2) : 0,

        c3B: result[word.c3] ? (result[word.c3].B - result[word.c3].IBB).toFixed(2) : 0,
        c3SE: result[word.c3] ? (result[word.c3].SE - result[word.c3].IBSE).toFixed(2) : 0,
        c3IBB: result[word.c3] ? result[word.c3].IBB.toFixed(2) : 0,
        c3IBSE: result[word.c3] ? result[word.c3].IBSE.toFixed(2) : 0,
        c3CE: result[word.c3.substring(1, 4)] ? result[word.c3.substring(1, 4)].CE.toFixed(2) : 0,
        c3PE: result[word.c3.substring(2, 4)] ? result[word.c3.substring(2, 4)].PE.toFixed(2) : 0,

        c4B: result[word.c4] ? (result[word.c4].B - result[word.c4].IBB).toFixed(2) : 0,
        c4SE: result[word.c4] ? (result[word.c4].SE - result[word.c4].IBSE).toFixed(2) : 0,
        c4IBB: result[word.c4] ? result[word.c4].IBB.toFixed(2) : 0,
        c4IBSE: result[word.c4] ? result[word.c4].IBSE.toFixed(2) : 0,
        c4CE: result[word.c4.substring(1, 4)] ? result[word.c4.substring(1, 4)].CE.toFixed(2) : 0,
        c4PE: result[word.c4.substring(2, 4)] ? result[word.c4.substring(2, 4)].PE.toFixed(2) : 0,

        c5B: result[word.c5] ? (result[word.c5].B - result[word.c5].IBB).toFixed(2) : 0,
        c5SE: result[word.c5] ? (result[word.c5].SE - result[word.c5].IBSE).toFixed(2) : 0,
        c5IBB: result[word.c5] ? result[word.c5].IBB.toFixed(2) : 0,
        c5IBSE: result[word.c5] ? result[word.c5].IBSE.toFixed(2) : 0,
        c5CE: result[word.c5.substring(1, 4)] ? result[word.c5.substring(1, 4)].CE.toFixed(2) : 0,
        c5PE: result[word.c5.substring(2, 4)] ? result[word.c5.substring(2, 4)].PE.toFixed(2) : 0,

        c6B: result[word.c6] ? (result[word.c6].B - result[word.c6].IBB).toFixed(2) : 0,
        c6SE: result[word.c6] ? (result[word.c6].SE - result[word.c6].IBSE).toFixed(2) : 0,
        c6IBB: result[word.c6] ? result[word.c6].IBB.toFixed(2) : 0,
        c6IBSE: result[word.c6] ? result[word.c6].IBSE.toFixed(2) : 0,
        c6CE: result[word.c6.substring(1, 4)] ? result[word.c6.substring(1, 4)].CE.toFixed(2) : 0,
        c6PE: result[word.c6.substring(2, 4)] ? result[word.c6.substring(2, 4)].PE.toFixed(2) : 0,

        c7B: result[word.c7] ? (result[word.c7].B - result[word.c7].IBB).toFixed(2) : 0,
        c7SE: result[word.c7] ? (result[word.c7].SE - result[word.c7].IBSE).toFixed(2) : 0,
        c7IBB: result[word.c7] ? result[word.c7].IBB.toFixed(2) : 0,
        c7IBSE: result[word.c7] ? result[word.c7].IBSE.toFixed(2) : 0,
        c7CE: result[word.c7.substring(1, 4)] ? result[word.c7.substring(1, 4)].CE.toFixed(2) : 0,
        c7PE: result[word.c7.substring(2, 4)] ? result[word.c7.substring(2, 4)].PE.toFixed(2) : 0,

        c8B: result[word.c8] ? (result[word.c8].B - result[word.c8].IBB).toFixed(2) : 0,
        c8SE: result[word.c8] ? (result[word.c8].SE - result[word.c8].IBSE).toFixed(2) : 0,
        c8IBB: result[word.c8] ? result[word.c8].IBB.toFixed(2) : 0,
        c8IBSE: result[word.c8] ? result[word.c8].IBSE.toFixed(2) : 0,
        c8CE: result[word.c8.substring(1, 4)] ? result[word.c8.substring(1, 4)].CE.toFixed(2) : 0,
        c8PE: result[word.c8.substring(2, 4)] ? result[word.c8.substring(2, 4)].PE.toFixed(2) : 0,

        c9B: result[word.c9] ? (result[word.c9].B - result[word.c9].IBB).toFixed(2) : 0,
        c9SE: result[word.c9] ? (result[word.c9].SE - result[word.c9].IBSE).toFixed(2) : 0,
        c9IBB: result[word.c9] ? result[word.c9].IBB.toFixed(2) : 0,
        c9IBSE: result[word.c9] ? result[word.c9].IBSE.toFixed(2) : 0,
        c9CE: result[word.c9.substring(1, 4)] ? result[word.c9.substring(1, 4)].CE.toFixed(2) : 0,
        c9PE: result[word.c9.substring(2, 4)] ? result[word.c9.substring(2, 4)].PE.toFixed(2) : 0,

        c10B: result[word.c10] ? (result[word.c10].B - result[word.c10].IBB).toFixed(2) : 0,
        c10SE: result[word.c10] ? (result[word.c10].SE - result[word.c10].IBSE).toFixed(2) : 0,
        c10IBB: result[word.c10] ? result[word.c10].IBB.toFixed(2) : 0,
        c10IBSE: result[word.c10] ? result[word.c10].IBSE.toFixed(2) : 0,
        c10CE: result[word.c10.substring(1, 4)] ? result[word.c10.substring(1, 4)].CE.toFixed(2) : 0,
        c10PE: result[word.c10.substring(2, 4)] ? result[word.c10.substring(2, 4)].PE.toFixed(2) : 0,
        '5D1': 0,
        '5D2': 0,
        '5D3': 0,
        '5D4': 0,
        '5D5': 0,
        '5D6': 0,
        '6D1': 0,
        '6D2': 0,
        '6D3': 0,
        '6D4': 0,
        '6D5': 0,
      },
      displayWord: {
        p1: word.p1,
        p2: word.p2,
        p3: word.p3,
        s1: word.s1,
        s2: word.s2,
        s3: word.s3,
        s4: word.s4,
        s5: word.s5,
        s6: word.s6,
        s7: word.s7,
        s8: word.s8,
        s9: word.s9,
        s10: word.s10,
        s11: word.s11,
        s12: word.s12,
        s13: word.s13,
        c1: word.c1,
        c2: word.c2,
        c3: word.c3,
        c4: word.c4,
        c5: word.c5,
        c6: word.c6,
        c7: word.c7,
        c8: word.c8,
        c9: word.c9,
        c10: word.c10,
      }
    }
    objecttemp.displayWord[word.p1OriPosition] = '----'
    objecttemp.displayWord[word.p2OriPosition] = '----'
    objecttemp.displayWord[word.p3OriPosition] = '----'
    
    const subtotaldetailkeys = Object.keys(objecttemp.subtotaldetail)
    for (let i = 0; i < subtotaldetailkeys.length; i += 1) {
      if (objecttemp.subtotaldetail[subtotaldetailkeys[i]] === '0.00') {
        objecttemp.subtotaldetail[subtotaldetailkeys[i]] = 0
      }
    }
    if (wordtemp) {
      return objecttemp
    } else {
      this.setState({ ...objecttemp })
    }
    if (updateHistoryData) {
      const wordArray = [`${word.p1}`, `${word.p2}`, `${word.p3}`, `${word.s1}`, `${word.s2}`, `${word.s3}`, `${word.s4}`, `${word.s5}`, `${word.s6}`, `${word.s7}`, `${word.s8}`, `${word.s9}`, `${word.s10}`, `${word.s11}`, `${word.s12}`, `${word.s13}`, `${word.c1}`, `${word.c2}`, `${word.c3}`, `${word.c4}`, `${word.c5}`, `${word.c6}`, `${word.c7}`, `${word.c8}`, `${word.c9}`, `${word.c10}`, `${word['5D1']}`, `${word['5D2']}`, `${word['5D3']}`, `${word['6D1']}`, `${word['7D1']}`]
      this.getHistoryNumberFunction(wordArray)
    }
  }

  async getHistoryNumberFunction(wordArray) {
    const wordArrayToUse = wordArray.filter((item) => item !== '----')
    const historyData = await getHistoryNumber(this.props.username, this.props.token, wordArrayToUse)
    this.setState({ historyData })
  }

  getRandomInt() {
    return Math.floor(Math.random() * 9);
  }

  generateNumber() {
    const shuffledAll4D = this.state.shuffledAll4D
    const shuffledAll5D = this.state.shuffledAll5D
    const shuffledAll6D = this.state.shuffledAll6D
    let shuffledAll4DIndex = this.state.shuffledAll4DIndex
    let shuffledAll5DIndex = this.state.shuffledAll5DIndex
    let shuffledAll6DIndex = this.state.shuffledAll6DIndex
    let randomPosition = ['s2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10', 's11', 's12', 's13'].map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
    let wordtemp = {
      p1: '',
      p2: '',
      p3: '',
      s1: shuffledAll4D[shuffledAll4DIndex],
      s2: shuffledAll4D[shuffledAll4DIndex + 1],
      s3: shuffledAll4D[shuffledAll4DIndex + 2],
      s4: shuffledAll4D[shuffledAll4DIndex + 3],
      s5: shuffledAll4D[shuffledAll4DIndex + 4],
      s6: shuffledAll4D[shuffledAll4DIndex + 5],
      s7: shuffledAll4D[shuffledAll4DIndex + 6],
      s8: shuffledAll4D[shuffledAll4DIndex + 7],
      s9: shuffledAll4D[shuffledAll4DIndex + 8],
      s10: shuffledAll4D[shuffledAll4DIndex + 9],
      s11: shuffledAll4D[shuffledAll4DIndex + 10],
      s12: shuffledAll4D[shuffledAll4DIndex + 11],
      s13: shuffledAll4D[shuffledAll4DIndex + 12],
      c1: shuffledAll4D[shuffledAll4DIndex + 13],
      c2: shuffledAll4D[shuffledAll4DIndex + 14],
      c3: shuffledAll4D[shuffledAll4DIndex + 15],
      c4: shuffledAll4D[shuffledAll4DIndex + 16],
      c5: shuffledAll4D[shuffledAll4DIndex + 17],
      c6: shuffledAll4D[shuffledAll4DIndex + 18],
      c7: shuffledAll4D[shuffledAll4DIndex + 19],
      c8: shuffledAll4D[shuffledAll4DIndex + 20],
      c9: shuffledAll4D[shuffledAll4DIndex + 21],
      c10: shuffledAll4D[shuffledAll4DIndex + 22],
      '5D1': shuffledAll5D[shuffledAll5DIndex + 0],
      '5D2': shuffledAll5D[shuffledAll5DIndex + 1],
      '5D3': shuffledAll5D[shuffledAll5DIndex + 2],
      '6D1': shuffledAll6D[shuffledAll6DIndex],
      '7D1': shuffledAll6D[shuffledAll6DIndex] + this.getRandomInt(),
      p1OriPosition: randomPosition[0],
      p2OriPosition: randomPosition[1],
      p3OriPosition: randomPosition[2],
    }
    shuffledAll4DIndex += 26
    shuffledAll5DIndex += 3
    shuffledAll6DIndex += 1
    let resultObject = this.calculateResult(wordtemp)
    if (resultObject.totalWinPercent === 0) {
      wordtemp.p1 = wordtemp[wordtemp.p1OriPosition]
      wordtemp.p2 = wordtemp[wordtemp.p2OriPosition]
      wordtemp.p3 = wordtemp[wordtemp.p3OriPosition]
      this.setState({...resultObject, word: wordtemp})
      return
    }
    let indexToFilter = [this.translatePosition(wordtemp.p1OriPosition), this.translatePosition(wordtemp.p2OriPosition), this.translatePosition(wordtemp.p3OriPosition)]
    while (resultObject.totalWinPercent < Number(this.state.minimumPercent) || resultObject.totalWinPercent > Number(this.state.maximumPercent)) {
      if (shuffledAll4DIndex > 9000) {
        this.AlertMesage('exceed all number yet cannot get', 'Error')
        break;
      }
      const prize = resultObject.prize
      const allPrizeKeys = Object.keys(prize).filter((item, index) => index <= 25 && !indexToFilter.includes(index))
      const allPrizeValue = Object.values(prize).filter((item, index) => index <= 25 && !indexToFilter.includes(index))
      let highestValue
      if (resultObject.totalWinPercent >= this.state.maximumPercent) {
        highestValue = Math.max(...allPrizeValue)
      } else {
        highestValue = Math.min(...allPrizeValue)
      }
      let highestIndex = allPrizeValue.indexOf(highestValue)
      let positionToChange = allPrizeKeys[highestIndex]
      if (positionToChange === 'p1') {
        positionToChange = wordtemp.p1OriPosition
      } else if (positionToChange === 'p2') {
        positionToChange = wordtemp.p2OriPosition
      } else if (positionToChange === 'p3') {
        positionToChange = wordtemp.p3OriPosition
      }
      wordtemp = {
        ...wordtemp,
        [positionToChange]: shuffledAll4D[shuffledAll4DIndex]
      }
      shuffledAll4DIndex += 1
      resultObject = this.calculateResult(wordtemp)
    }
    wordtemp.p1 = wordtemp[wordtemp.p1OriPosition]
    wordtemp.p2 = wordtemp[wordtemp.p2OriPosition]
    wordtemp.p3 = wordtemp[wordtemp.p3OriPosition]
    this.setState({...resultObject, word: wordtemp})
    const wordArray = [`${wordtemp.p1}`, `${wordtemp.p2}`, `${wordtemp.p3}`, `${wordtemp.s1}`, `${wordtemp.s2}`, `${wordtemp.s3}`, `${wordtemp.s4}`, `${wordtemp.s5}`, `${wordtemp.s6}`, `${wordtemp.s7}`, `${wordtemp.s8}`, `${wordtemp.s9}`, `${wordtemp.s10}`, `${wordtemp.s11}`, `${wordtemp.s12}`, `${wordtemp.s13}`, `${wordtemp.c1}`, `${wordtemp.c2}`, `${wordtemp.c3}`, `${wordtemp.c4}`, `${wordtemp.c5}`, `${wordtemp.c6}`, `${wordtemp.c7}`, `${wordtemp.c8}`, `${wordtemp.c9}`, `${wordtemp.c10}`, `${wordtemp['5D1']}`, `${wordtemp['5D2']}`, `${wordtemp['5D3']}`, `${wordtemp['6D1']}`, `${wordtemp['7D1']}`]
    this.getHistoryNumberFunction(wordArray)
  }

  changePosition(position) {
    if (position === '5D1' || position === '5D2' || position === '5D3') {
      this.setState({ 
        word: {
          ...this.state.word,
          [position]: this.state.shuffledAll5D[this.state.shuffledAll5DIndex + 1]
        },
        shuffledAll5DIndex: this.state.shuffledAll5DIndex + 1
      }, () => this.calculateResult())
    } else if (position === '6D1') {
      this.setState({ 
        word: {
          ...this.state.word,
          [position]: this.state.shuffledAll6D[this.state.shuffledAll6DIndex + 1],
          '7D1': `${this.state.shuffledAll6D[this.state.shuffledAll6DIndex + 1]}${this.state.word['7D1'].substring(6, 7)}`
        },
        shuffledAll6DIndex: this.state.shuffledAll6DIndex + 1
      }, () => this.calculateResult())
    } else {
      this.setState({ 
        word: {
          ...this.state.word,
          [position]: this.state.shuffledAll4D[this.state.shuffledAll4DIndex + 1]
        },
        shuffledAll4DIndex: this.state.shuffledAll4DIndex + 1
      }, () => this.calculateResult())
    }
  }

  // generatecalculateNumber(first) {
  //   if (this.state.GenerateTime.length === 0 || first !== '') {
  //     let GenerateTime = []
  //     const ArrayGame = ['s1', 's2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10', 's11', 's12', 's13', 'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10']
  //     ArrayGame.sort( ()=>Math.random()-0.5 ).forEach((items) => {
  //       GenerateTime.push({Time: '', Position: items })
  //     })
  //     for (var i = 0; i < GenerateTime.length; i++) {
  //       if(GenerateTime[0].Time === '') {
  //         GenerateTime[0].Time = Math.floor(Math.random() * 5) + 5
  //       } else if (GenerateTime[i].Time === ''){
  //         GenerateTime[i].Time = Math.floor(Math.random() * 10) + GenerateTime[i - 1].Time + 25
  //         // GenerateTime[i].Time = Math.floor(Math.random() * 1) + GenerateTime[i - 1].Time + 2
  //       }
  //     }
  //     ['p3', 'p2', 'p1'].forEach((item) => {
  //       GenerateTime.push({Time: Math.floor(Math.random() * 10) + GenerateTime[GenerateTime.length - 1].Time + 25, Position: item })
  //       // GenerateTime.push({Time: Math.floor(Math.random() * 1) + GenerateTime[GenerateTime.length - 1].Time + 2, Position: item })
  //     })
  //     if (this.state.Selectedgame === '8') {
  //       const FiveD = ['5D3', '5D2', '5D1', '6D1', '7D1']
  //       for (var a = 0; a < FiveD.length; a ++) {
  //         GenerateTime.push({Time: Math.floor(Math.random() * 10) + GenerateTime[GenerateTime.length - 1].Time + 25, Position: FiveD[a] })
  //         // GenerateTime.push({Time: Math.floor(Math.random() * 1) + GenerateTime[GenerateTime.length - 1].Time + 2, Position: FiveD[a] })
  //       }
  //     }
  //     this.setState({ GenerateTime }, () => {
  //       if (first === '') {
  //         this.setState({ OpenModalGenerateTime: true})
  //       }
  //     })
  //   } else {
  //     this.setState({ OpenModalGenerateTime: true })
  //   }
  // }

  async StartCAlculate() {
    // let GenerateTime = this.state.GenerateTime
    // GenerateTime.forEach(function(tag) {
    //   tag.Position = tag.Position.toUpperCase();
    // });
    // console.log('this.state.Selectedgame', this.state.Selectedgame)
    // console.log(this.state.word)
    const r = (window.confirm('您确定要开采吗?'));
    if (r === true) {
      await updateFirebaseResult(this.state.Selectedgame, this.props.username, this.props.token);
      opentimer = setInterval(() => {
        const currentSecond = this.state.currentSecond + 1
        const objecttored = this.state.GenerateTime.find((item => item.Time === currentSecond))
        if (objecttored) {
          this.setState({ redbackground: {...this.state.redbackground, [objecttored.Position]: true}, currentSecond: parseInt(currentSecond) })
        } else {
          this.setState({ currentSecond: parseInt(currentSecond) })
        }
        if ((objecttored && objecttored.Position === '7D1') || currentSecond >= 1200) {
          this.setState({ currentSecond: 0 })
          clearInterval(opentimer)
        }
      }, 1000)
      this.setState({ pausing: true })
    } else {
      return null;
    }
  }

  closeGenerateTimeModal() {
    this.setState({ OpenModalGenerateTime: false })
  }

  closeWordBettingModal() {
    this.setState({ wordBettingModal: false })
  }

  openWordBettingModal() {
    if (this.state.alreadyCalculateWordBetting === false) {
      const tempWord = JSON.parse(JSON.stringify(this.state.result))
      const betmodeToRepeat = this.state.betmodeToRepeat
      const wordBettingToShow = {
        B: [],
        S: [],
        SA: [],
        SB: [],
        SC: [],
        SD: [],
        SE: [],
        SF: [],
        CA: [],
        CB: [],
        CC: [],
        CD: [],
        CE: [],
        CF: [],
        PA: [],
        PB: [],
        PC: [],
        PD: [],
        PE: [],
        PF: [],
        '5D': [],
        '6D': [],
      }
      const wordArray = Object.keys(tempWord)
      for (let i = 0; i < wordArray.length; i += 1) {
        for (let j = 0; j < betmodeToRepeat.length; j += 1) {
          if (tempWord[wordArray[i]][betmodeToRepeat[j]] !== 0) {
            wordBettingToShow[betmodeToRepeat[j]].push({ w: wordArray[i], a: tempWord[wordArray[i]][betmodeToRepeat[j]]})
          }
        }
      }
      for (let i = 0; i < betmodeToRepeat.length; i += 1) {
        if (this.state.wordBettingSortingAsc) {
          wordBettingToShow[betmodeToRepeat[i]].sort((j1, j2) => j1.a - j2.a);
        } else {
          wordBettingToShow[betmodeToRepeat[i]].sort((j1, j2) => j1.a - j2.a);
        }
      }
      this.setState({ wordBettingModal: true, wordBettingToShow, alreadyCalculateWordBetting: true })
    } else {
      this.setState({ wordBettingModal: true })
    }
  }

  changeWordBettingSort() {
    const betmodeToRepeat = this.state.betmodeToRepeat
    const wordBettingToShow = JSON.parse(JSON.stringify(this.state.wordBettingToShow))
    for (let i = 0; i < betmodeToRepeat.length; i += 1) {
      if (this.state.wordBettingSortingAsc) {
        wordBettingToShow[betmodeToRepeat[i]].sort((j1, j2) => j2.a - j1.a);
      } else {
        wordBettingToShow[betmodeToRepeat[i]].sort((j1, j2) => j1.a - j2.a);
      }
    }
    this.setState({ wordBettingToShow, wordBettingSortingAsc: !this.state.wordBettingSortingAsc})
  }

  translatePosition(position) {
    if (position === 's1') {
      return 3
    } else if (position === 's2') {
      return 4
    } else if (position === 's3') {
      return 5
    } else if (position === 's4') {
      return 6
    } else if (position === 's5') {
      return 7
    } else if (position === 's6') {
      return 8
    } else if (position === 's7') {
      return 9
    } else if (position === 's8') {
      return 10
    } else if (position === 's9') {
      return 11
    } else if (position === 's10') {
      return 12
    } else if (position === 's11') {
      return 13
    } else if (position === 's12') {
      return 14
    } else if (position === 's13') {
      return 15
    } else if (position === 3) {
      return 's1'
    } else if (position === 4) {
      return 's2'
    } else if (position === 5) {
      return 's3'
    } else if (position === 6) {
      return 's4'
    } else if (position === 7) {
      return 's5'
    } else if (position === 8) {
      return 's6'
    } else if (position === 9) {
      return 's7'
    } else if (position === 10) {
      return 's8'
    } else if (position === 11) {
      return 's9'
    } else if (position === 12) {
      return 's10'
    } else if (position === 13) {
      return 's11'
    } else if (position === 14) {
      return 's12'
    } else if (position === 15) {
      return 's13'
    }
  }

  translatePositionAlphabet(position) {
    if (position === 'A') {
      return 's1'
    } else if (position === 'B') {
      return 's2'
    } else if (position === 'C') {
      return 's3'
    } else if (position === 'D') {
      return 's4'
    } else if (position === 'E') {
      return 's5'
    } else if (position === 'F') {
      return 's6'
    } else if (position === 'G') {
      return 's7'
    } else if (position === 'H') {
      return 's8'
    } else if (position === 'I') {
      return 's9'
    } else if (position === 'J') {
      return 's10'
    } else if (position === 'K') {
      return 's11'
    } else if (position === 'L') {
      return 's12'
    } else if (position === 'M') {
      return 's13'
    } 
  }

  AlertMesage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayError: false,
        DisplayErrorMessage: '',
        MessageType: '',
      });
    }, 5000);
  }

  async ForceUpdate() {
    const word = this.state.word
    let TempObj = {}
    TempObj.P1 = ''
    TempObj.P2 = ''
    TempObj.P3 = ''
    TempObj.C1 = word.c1
    TempObj.C2 = word.c2
    TempObj.C3 = word.c3
    TempObj.C4 = word.c4
    TempObj.C5 = word.c5
    TempObj.C6 = word.c6
    TempObj.C7 = word.c7
    TempObj.C8 = word.c8
    TempObj.C9 = word.c9
    TempObj.C10 = word.c10
    TempObj.S1 = word.s1
    TempObj.S2 = word.s2
    TempObj.S3 = word.s3
    TempObj.S4 = word.s4
    TempObj.S5 = word.s5
    TempObj.S6 = word.s6
    TempObj.S7 = word.s7
    TempObj.S8 = word.s8
    TempObj.S9 = word.s9
    TempObj.S10 = word.s10
    TempObj.S11 = word.s11
    TempObj.S12 = word.s12
    TempObj.S13 = word.s13
    TempObj.P1OriPosition = word.p1OriPosition.toUpperCase();
    TempObj.P2OriPosition = word.p2OriPosition.toUpperCase();
    TempObj.P3OriPosition = word.p3OriPosition.toUpperCase();
    TempObj['5D1'] = word['5D1']
    TempObj['5D2'] = word['5D2']
    TempObj['5D3'] = word['5D3']
    TempObj['6D1'] = word['6D1']
    TempObj['7D1'] = word['7D1']
    if (TempObj.P1OriPosition) {
      TempObj[word.p1OriPosition] = word.p1
    }
    if (TempObj.P2OriPosition) {
      TempObj[word.p2OriPosition] = word.p2
    }
    if (TempObj.P3OriPosition) {
      TempObj[word.p3OriPosition] = word.p3
    }
    const UpdateResult = await forceUpdateResult(TempObj, this.state.Selectedgame, this.props.username, this.props.token)
    if (UpdateResult.message === 'Success Update') {
      this.AlertMesage('成功更新', 'Success');
    }
  }

  changeAlphabet(number, input) {
    if (this.state.word[this.translatePositionAlphabet(input.toUpperCase())]) {
      const tempword = JSON.parse(JSON.stringify(this.state.word))
      this.setState({ 
        word: {
          ...this.state.word, 
          [`${tempword[`p${number}OriPosition`]}`]: tempword[`p${number}`],
          [`p${number}OriPosition`]: `${this.translatePositionAlphabet(input.toUpperCase())}`, 
          [`p${number}`]: `${this.state.word[this.translatePositionAlphabet(input)]}`, 
        }
      })
    } else {
      this.setState({ 
        word: {
          ...this.state.word, 
          [`p${number}OriPosition`]: `${this.translatePositionAlphabet(input.toUpperCase())}`, 
          [`p${number}`]: `${this.state.word[this.translatePositionAlphabet(input.toUpperCase())]}`, 
        }
      })
    }
  }
  
  async pauseOpenResult() {
    const UpdateResult = await pauseOpenResult(this.props.username, this.props.token, this.state.Selectedgame, this.state.currentSecond)
    if (UpdateResult.message === 'success') {
      this.AlertMesage('成功更新', 'Success');
      clearInterval(opentimer)
    }
  }

  async resumeOpenResult() {
    const r = (window.confirm('您确定要开采吗?'));
    if (r === true) {
      const UpdateResult = await resumeOpenResult(this.props.username, this.props.token, this.state.Selectedgame, this.state.currentSecond)
      if (UpdateResult.message === 'success') {
        this.AlertMesage('成功更新', 'Success');
        opentimer = setInterval(() => {
          const currentSecond = this.state.currentSecond + 1
          const objecttored = this.state.GenerateTime.find((item => item.Time === currentSecond))
          if (objecttored) {
            this.setState({ redbackground: {...this.state.redbackground, [objecttored.Position]: true}, currentSecond: parseInt(currentSecond) })
          } else {
            this.setState({ currentSecond: parseInt(currentSecond) })
          }
          if ((objecttored && objecttored.Position === '7D1') || currentSecond >= 1200) {
            this.setState({ currentSecond: 0 })
            clearInterval(opentimer)
          }
        }, 1000)
      }
    } else {
      return null;
    }
  }

  CopyToC() {
    const postitionToRepeat = ['p1', 'p2', 'p3', 's1', 's2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10', 's11', 's12', 's13', 'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10', '5D1', '5D2', '5D3', '6D1']
    let textToCopy = ''
    for (let i = 0; i < postitionToRepeat.length; i += 1) {
      if (this.state.word[postitionToRepeat[i]] !== '' && postitionToRepeat[i] !== this.state.word.p1OriPosition && postitionToRepeat[i] !== this.state.word.p2OriPosition && postitionToRepeat[i] !== this.state.word.p3OriPosition) {
        textToCopy += this.state.word[postitionToRepeat[i]]
      }
    }
    this.setState({ toCopyWord: textToCopy })
  }

  render() {
    return(
      <div style={{ width: '100%', flexDirection: 'column', overflowY: 'auto' }}> 
        <div style={{ marginBottom: '2%'}}>
          <Header2 />
        </div>
        <Modal centered fullscreen="xl-down" size="xl" show={this.state.wordBettingModal} onHide={this.closeWordBettingModal}>
          <Modal.Header closeButton>
            <Modal.Title>字数<Button onClick={() => this.changeWordBettingSort()}>{this.state.wordBettingSortingAsc ? '▲' : '▼'}</Button></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{display: 'flex', flexWrap:'wrap'}}>
              {this.state.betmodeToRepeat.map((betmode) => 
                <Table style={{display: 'flex', flexDirection: 'column', height: 200, margin: 10, width: 200}}>
                  <tbody style={{ flexWrap: 'wrap', display: 'flex',  justifyContent : 'space-between', overflowY: 'scroll', maxHeight:'150px', flexDirection: 'row' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20}}>{betmode}</div>
                    <div style={{ width: '100%', flexDirection: 'row', display: 'flex', backgroundColor: '#52D017', fontWeight: 'bold', justifyContent: 'space-evenly'}}>
                      <div>字</div>
                      <div>下注</div>
                    </div>
                    {this.state.wordBettingToShow[betmode].map((item, index) => <div style={{ width: '100%', flexDirection: 'row', display: 'flex', backgroundColor: (index%2) ? 'white' : '#D3D3D3', fontWeight: 'bold', justifyContent: 'space-evenly'}}>
                      <div style={{}}>{item.w}</div>
                      <div>{item.a.toFixed(2)}</div>
                    </div>)}
                  </tbody>
                </Table>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => this.closeWordBettingModal()}>关闭</Button>
          </Modal.Footer>
        </Modal>
        <Modal centered size="lg" show={this.state.OpenModalGenerateTime} onHide={this.closeGenerateTimeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Generate Time</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ maxHeight: '75vh',  overflowY: 'scroll' }}>
              <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex'}}>
                  <div className="resultbottom" style={{width:'calc(100%)'}}>
                    {'时间(s)'}
                  </div>
                  <div  className="resultbottom" style={{width:'calc(100%)'}}>
                    {'位置'}
                  </div>
                </div>
              </div>
              {this.state.GenerateTime.map((item) => 
                <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                  <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex'}}>
                    <div  className="resultbottom" style={{width:'calc(100%)', color: 'blue'}}>
                      {item.Time}
                    </div>
                    <div  className="resultbottom" style={{width:'calc(100%)'}}>
                      {item.Position.toUpperCase()}
                    </div>
                    {(item.Position === 'p1' || item.Position === 'p2' || item.Position === 'p3') && 
                    <div  className="resultbottom" style={{width:'calc(100%)'}} >
                      {this.state.word[`${item.Position}OriPosition`]}
                    </div>}
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/*<Button variant="primary" onClick={() => this.generatecalculateNumber('Go')}>Refresh</Button>
            <Button variant="primary" onClick={() => this.StartCAlculate()}>开始开采</Button>*/}
            <Button variant="danger" onClick={() => this.closeGenerateTimeModal()}>关闭</Button>
          </Modal.Footer>
        </Modal>
        {this.state.DisplayError === true && 
          <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row' }}>
            <img src={errorsign} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
            <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F'}}>{` ${this.state.DisplayErrorMessage}`}</div>
          </div>}
        <div style={{ display: 'flex', flex: 1, width: '100%' }}>
          <div style={{ width: '50%'}}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
              <div  style={{ flex: "1 1 30%", border: "1px solid black", padding: 10}}>
                <Fragment>
                  <div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                      <p>{`${this.state.word[`p1`]} ${this.state.word[`p2`]} ${this.state.word[`p3`]}`}</p>
                    </div>
                    <div style={{ display: 'flex',  color: 'white' }}>
                      {['1st Prize 首獎', '2nd Prize 二獎', '3rd Prize 三獎'].map((item, normalindex) => {
                        return <div key={normalindex} className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>{item}</div>
                      })}
                    </div>
                    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                      {['1', '2', '3'].map((item, index) => {
                        return <div key={index} style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
                          <input maxLength="4" value={this.state.word[`p${item}`]} onChange={(e) => this.setState({ word: {...this.state.word, [`p${item}`]: e.target.value}})} className="resulttop" style={{width:'80%', backgroundColor: this.state.redbackground[`p${item}`] ? 'red' : 'white'}} type="text" />
                          <input disabled maxLength="1" value={characterList[this.state.word[`p${item}OriPosition`].replace('s','')]} onChange={(e) => this.changeAlphabet(item, e.target.value)} className="resulttop" style={{width:'20%', color: '#52D017'}} type="text" />
                        </div>
                      })}
                    </div>
                    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>Special 特別獎</div>
                    <div style={{ display: 'flex',marginTop:3,  color: 'white', flexWrap: 'wrap', justifyContent: 'center' }}>
                      {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'].map((item, indexs) =>{
                        return <div key={indexs} style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
                          <span style={{ color: '#52D017', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>{characterList[item]}</span>
                          <input maxLength="4" value={(this.state.word['p1OriPosition'] === `s${item}` || this.state.word['p2OriPosition'] === `s${item}` || this.state.word['p3OriPosition'] === `s${item}`) ? '----' :this.state.word[`s${item}`]} onChange={(e) => this.setState({ word: {...this.state.word, [`s${item}`]: e.target.value}})}  className="resultbottom" style={{width:'calc(100%)', backgroundColor: this.state.redbackground[`s${item}`] ? 'red' : 'white'}} type="text"/>
                        </div>
                      })}
                    </div>
                    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>
                      Consolation 安慰獎
                    </div>
                    <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                      {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((item, indexc) =>{
                        return <div key={indexc} style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
                          <span style={{ color: '#52D017', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>{characterList[indexc+14]}</span>
                          <input  maxLength="4" value={this.state.word[`c${item}`]} onChange={(e) => this.setState({ word: {...this.state.word, [`c${item}`]: e.target.value}})} className="resultbottom" style={{width:'calc(100%)', backgroundColor: this.state.redbackground[`c${item}`] ? 'red' : 'white'}} type="text" />
                        </div>
                      })}
                    </div>
                    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>5D</div>
                    <div style={{ display: 'flex',  color: 'white' }}>
                      {['1st Prize 首獎', '2nd Prize 二獎', '3rd Prize 三獎'].map((item, index5Prize) => {
                        return <div key={index5Prize} className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>{item}</div>
                      })}
                    </div>
                    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                      {['5D1', '5D2', '5D3'].map((item, index5) => {
                        return <input maxLength="5" value={this.state.word[`${item}`]} onChange={(e) => this.setState({ word: {...this.state.word, [`${item}`]: e.target.value}})}  className="resultbottom" style={{width:'calc(100%)', backgroundColor: this.state.redbackground[`${item}`] ? 'red' : 'white'}} type="text"/>
                      })}
                    </div>
                    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>6D</div>
                    <div style={{ display: 'flex',  color: 'white' }}>
                      <div className="border"style={{ width: 'calc(100%)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
                    </div>
                    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                      <div style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                        <input maxLength="6" value={this.state.word[`6D1`]} onChange={(e) => this.setState({ word: {...this.state.word, [`6D1`]: e.target.value}})} className="resultbottom" style={{width:'calc(100%)', backgroundColor: this.state.redbackground[`6D1`] ? 'red' : 'white'}} type="text"/>
                      </div>
                    </div>
                    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>6D + 1</div>
                    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
                      <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                        <input maxLength="8" value={this.state.word[`7D1`]}  onChange={(e) => this.setState({ word: {...this.state.word, [`7D1`]: e.target.value}})} className="resultbottom" style={{width:'calc(100%)', backgroundColor: this.state.redbackground[`7D1`] ? 'red' : 'white'}} type="text"/>
                      </div>
                    </div>
                  </div>
                </Fragment> 
              </div> 
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ textAlign: 'left', marginLeft: 10 }}>1ST</div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '10%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                  <div>{this.state.displayWord.p1}</div>
                  <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.p1] && `${this.state.historyData[this.state.displayWord.p1].last} - ${this.state.historyData[this.state.displayWord.p1].time}`}</div>
                </div>
                <div style={{ width: '10%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p1B}/{this.state.subtotaldetail.p1IBB}</div></div>
                <div style={{ width: '10%' }}>=S=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p1S}/{this.state.subtotaldetail.p1IBS}</div></div>
                <div style={{ width: '10%' }}>=SA=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p1SA}/{this.state.subtotaldetail.p1IBSA}</div></div>
                <div style={{ width: '10%' }}>=SF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p1SF}/{this.state.subtotaldetail.p1IBSF}</div></div>
                <div style={{ width: '10%' }}>=CA=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p1CA}</div></div>
                <div style={{ width: '10%' }}>=CF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p1CF}</div></div>
                <div style={{ width: '10%' }}>=PA=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p1PA}</div></div>
                <div style={{ width: '10%' }}>=PF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p1PF}</div></div>
              </div>
              <hr
                style={{
                  background: 'black',
                  color: 'black',
                  borderColor: 'black',
                  width: '100%'
                }}
              />
              <div style={{ textAlign: 'left', marginLeft: 10 }}>2ND</div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '10%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                  <div>{this.state.displayWord.p2}</div>
                  <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.p2] && `${this.state.historyData[this.state.displayWord.p2].last} - ${this.state.historyData[this.state.displayWord.p2].time}`}</div>
                </div>
                <div style={{ width: '10%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p2B}/{this.state.subtotaldetail.p2IBB}</div></div>
                <div style={{ width: '10%' }}>=S=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p2S}/{this.state.subtotaldetail.p2IBS}</div></div>
                <div style={{ width: '10%' }}>=SB=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p2SB}/{this.state.subtotaldetail.p2IBSB}</div></div>
                <div style={{ width: '10%' }}>=SF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p2SF}/{this.state.subtotaldetail.p2IBSF}</div></div>
                <div style={{ width: '10%' }}>=CB=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p2CB}</div></div>
                <div style={{ width: '10%' }}>=CF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p2CF}</div></div>
                <div style={{ width: '10%' }}>=PB=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p2PB}</div></div>
                <div style={{ width: '10%' }}>=PF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p2PF}</div></div>
              </div>
              <hr
                style={{
                  background: 'black',
                  color: 'black',
                  borderColor: 'black',
                  width: '100%'
                }}
              />
              <div style={{ textAlign: 'left', marginLeft: 10 }}>3RD</div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '10%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                  <div>{this.state.displayWord.p3}</div>
                  <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.p3] && `${this.state.historyData[this.state.displayWord.p3].last} - ${this.state.historyData[this.state.displayWord.p3].time}`}</div>
                </div>
                <div style={{ width: '10%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p3B}/{this.state.subtotaldetail.p3IBB}</div></div>
                <div style={{ width: '10%' }}>=S=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p3S}/{this.state.subtotaldetail.p3IBS}</div></div>
                <div style={{ width: '10%' }}>=SB=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p3SC}/{this.state.subtotaldetail.p3IBSC}</div></div>
                <div style={{ width: '10%' }}>=SF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p3SF}/{this.state.subtotaldetail.p3IBSF}</div></div>
                <div style={{ width: '10%' }}>=CC=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p3CC}</div></div>
                <div style={{ width: '10%' }}>=CF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p3CF}</div></div>
                <div style={{ width: '10%' }}>=PC=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p3PC}</div></div>
                <div style={{ width: '10%' }}>=PF=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.p3PF}</div></div>
              </div>
              <hr
                style={{
                  background: 'black',
                  color: 'black',
                  borderColor: 'black',
                  width: '100%'
                }}
              />
              <div style={{ textAlign: 'left', marginLeft: 10 }}>4TH</div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s1}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s1] && `${this.state.historyData[this.state.displayWord.s1].last} - ${this.state.historyData[this.state.displayWord.s1].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s1B}/{this.state.subtotaldetail.s1IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s1SD}/{this.state.subtotaldetail.s1IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s1CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s1PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s2}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s2] && `${this.state.historyData[this.state.displayWord.s2].last} - ${this.state.historyData[this.state.displayWord.s2].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s2B}/{this.state.subtotaldetail.s2IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s2SD}/{this.state.subtotaldetail.s2IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s2CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s2PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s3}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s3] && `${this.state.historyData[this.state.displayWord.s3].last} - ${this.state.historyData[this.state.displayWord.s3].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s3B}/{this.state.subtotaldetail.s3IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s3SD}/{this.state.subtotaldetail.s3IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s3CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s3PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s4}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s4] && `${this.state.historyData[this.state.displayWord.s4].last} - ${this.state.historyData[this.state.displayWord.s4].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s4B}/{this.state.subtotaldetail.s4IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s4SD}/{this.state.subtotaldetail.s4IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s4CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s4PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s5}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s5] && `${this.state.historyData[this.state.displayWord.s5].last} - ${this.state.historyData[this.state.displayWord.s5].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s5B}/{this.state.subtotaldetail.s5IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s5SD}/{this.state.subtotaldetail.s5IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s5CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s5PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s6}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s6] && `${this.state.historyData[this.state.displayWord.s6].last} - ${this.state.historyData[this.state.displayWord.s6].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s6B}/{this.state.subtotaldetail.s6IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s6SD}/{this.state.subtotaldetail.s6IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s6CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s6PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s7}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s7] && `${this.state.historyData[this.state.displayWord.s7].last} - ${this.state.historyData[this.state.displayWord.s7].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s7B}/{this.state.subtotaldetail.s7IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s7SD}/{this.state.subtotaldetail.s7IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s7CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s7PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s8}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s8] && `${this.state.historyData[this.state.displayWord.s8].last} - ${this.state.historyData[this.state.displayWord.s8].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s8B}/{this.state.subtotaldetail.s8IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s8SD}/{this.state.subtotaldetail.s8IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s8CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s8PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s9}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s9] && `${this.state.historyData[this.state.displayWord.s9].last} - ${this.state.historyData[this.state.displayWord.s9].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s9B}/{this.state.subtotaldetail.s9IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s9SD}/{this.state.subtotaldetail.s9IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s9CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s9PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s10}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s10] && `${this.state.historyData[this.state.displayWord.s10].last} - ${this.state.historyData[this.state.displayWord.s10].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s10B}/{this.state.subtotaldetail.s10IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s10SD}/{this.state.subtotaldetail.s10IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s10CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s10PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s11}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s11] && `${this.state.historyData[this.state.displayWord.s11].last} - ${this.state.historyData[this.state.displayWord.s11].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s11B}/{this.state.subtotaldetail.s11IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s11SD}/{this.state.subtotaldetail.s11IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s11CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s11PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s12}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s12] && `${this.state.historyData[this.state.displayWord.s12].last} - ${this.state.historyData[this.state.displayWord.s12].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s12B}/{this.state.subtotaldetail.s12IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s12SD}/{this.state.subtotaldetail.s12IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s12CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s12PD}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.s13}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.s13] && `${this.state.historyData[this.state.displayWord.s13].last} - ${this.state.historyData[this.state.displayWord.s13].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s13B}/{this.state.subtotaldetail.s13IBB}</div></div>
                  <div style={{ width: '20%' }}>=SD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s13SD}/{this.state.subtotaldetail.s13IBSD}</div></div>
                  <div style={{ width: '20%' }}>=CD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s13CD}</div></div>
                  <div style={{ width: '20%' }}>=PD=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.s13PD}</div></div>
                </div>
              </div>
              <hr
                style={{
                  background: 'black',
                  color: 'black',
                  borderColor: 'black',
                  width: '100%'
                }}
              />
              <div style={{ textAlign: 'left', marginLeft: 10 }}>5TH</div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c1}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c1] && `${this.state.historyData[this.state.displayWord.c1].last} - ${this.state.historyData[this.state.displayWord.c1].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c1B}/{this.state.subtotaldetail.c1IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c1SE}/{this.state.subtotaldetail.c1IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c1CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c1PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c2}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c2] && `${this.state.historyData[this.state.displayWord.c2].last} - ${this.state.historyData[this.state.displayWord.c2].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c2B}/{this.state.subtotaldetail.c2IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c2SE}/{this.state.subtotaldetail.c2IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c2CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c2PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c3}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c3] && `${this.state.historyData[this.state.displayWord.c3].last} - ${this.state.historyData[this.state.displayWord.c3].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c3B}/{this.state.subtotaldetail.c3IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c3SE}/{this.state.subtotaldetail.c3IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c3CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c3PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c4}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c4] && `${this.state.historyData[this.state.displayWord.c4].last} - ${this.state.historyData[this.state.displayWord.c4].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c4B}/{this.state.subtotaldetail.c4IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c4SE}/{this.state.subtotaldetail.c4IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c4CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c4PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c5}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c5] && `${this.state.historyData[this.state.displayWord.c5].last} - ${this.state.historyData[this.state.displayWord.c5].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c5B}/{this.state.subtotaldetail.c5IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c5SE}/{this.state.subtotaldetail.c5IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c5CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c5PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c6}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c6] && `${this.state.historyData[this.state.displayWord.c6].last} - ${this.state.historyData[this.state.displayWord.c6].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c6B}/{this.state.subtotaldetail.c6IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c6SE}/{this.state.subtotaldetail.c6IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c6CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c6PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c7}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c7] && `${this.state.historyData[this.state.displayWord.c7].last} - ${this.state.historyData[this.state.displayWord.c7].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c7B}/{this.state.subtotaldetail.c7IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c7SE}/{this.state.subtotaldetail.c7IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c7CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c7PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c8}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c8] && `${this.state.historyData[this.state.displayWord.c8].last} - ${this.state.historyData[this.state.displayWord.c8].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c8B}/{this.state.subtotaldetail.c8IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c8SE}/{this.state.subtotaldetail.c8IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c8CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c8PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c9}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c9] && `${this.state.historyData[this.state.displayWord.c9].last} - ${this.state.historyData[this.state.displayWord.c9].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c9B}/{this.state.subtotaldetail.c9IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c9SE}/{this.state.subtotaldetail.c9IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c9CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c9PE}</div></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '20%', fontSize: 24, flexDirection: 'column', fontWeight: 'bold', display: 'flex', marginLeft: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <div>{this.state.displayWord.c10}</div>
                    <div style={{ fontSize: 12, fontWeight: 'normal'}}>{this.state.historyData[this.state.displayWord.c10] && `${this.state.historyData[this.state.displayWord.c10].last} - ${this.state.historyData[this.state.displayWord.c10].time}`}</div>
                  </div>
                  <div style={{ width: '20%' }}>=B=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c10B}/{this.state.subtotaldetail.c10IBB}</div></div>
                  <div style={{ width: '20%' }}>=SE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c10SE}/{this.state.subtotaldetail.c10IBSE}</div></div>
                  <div style={{ width: '20%' }}>=CE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c10CE}</div></div>
                  <div style={{ width: '20%' }}>=PE=<div style={{ fontWeight: 'bold'}}>{this.state.subtotaldetail.c10PE}</div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="dcc" style={{ width: '50%'}}>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
              <div  style={{ flex: "1", border: "1px solid black", padding: 10}}>
                <Fragment>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <div style={{ display: 'flex' }}>
                        <Button variant="primary" onClick={() => this.pullResult('8')} style={{ fontSize: 20, width: 100, fontWeight: 'bold', margin: 10 }}>
                          {'拉8'}
                        </Button>
                        <Link 
                          to={`/testasd2`}
                          target="_blank" rel="noopener noreferrer" style={{ color: 'white', backgroundColor: '#007bff', width: 100, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {'查字'}
                        </Link>
                      </div>
                      <div style={{ fontSize: 20 }}>
                        进度：{this.state.pullingProgress} | 时间：<input style={{ width: 100 }} value={Number(this.state.currentSecond)} onChange={(e) => this.setState({ currentSecond: e.target.value })} type="text" />
                      </div>
                      <div>
                        <input style={{ width: 50 }} maxLength="2" value={this.state.minimumPercent} onChange={(e) => this.setState({ minimumPercent: e.target.value })} type="text" />
                        <input style={{ width: 50 }} maxLength="2" value={this.state.maximumPercent} onChange={(e) => this.setState({ maximumPercent: e.target.value })} type="text" />
                      </div>
                    </div>
                    <div style={{ display: 'flex'}}>
                      <Button variant="primary" onClick={() => this.generateNumber()} style={{ fontSize: 20, width: '20%', height: 75, fontWeight: 'bold', margin: 10 }}>
                        {'自动生成'}
                      </Button>
                      <Button variant="primary" onClick={() => {
                        this.calculateResult(null, true)
                        this.ForceUpdate()
                      }} style={{ fontSize: 20, width: '20%', height: 75, fontWeight: 'bold', margin: 10 }}>
                        {'计算/重计算'}
                      </Button>
                      <Button variant="success" onClick={() => this.StartCAlculate('')} disabled={this.state.pausing} style={{ fontSize: 20, width: '20%', height: 75, fontWeight: 'bold', margin: 10 }}>
                        {'开始开彩'}
                      </Button>
                    </div>
                    <hr/>
                    <div style={{ display: 'flex'}}>
                      <Button variant="primary" onClick={() => this.setState({ OpenModalGenerateTime: true})} style={{ fontSize: 20, width: '20%', fontWeight: 'bold', margin: 10 }}>
                        {'查看时间'}
                      </Button>
                      <Button variant="danger" onClick={() => this.pauseOpenResult()} style={{ fontSize: 20, width: '20%', fontWeight: 'bold', margin: 10 }}>
                        {'紧急暂停'}
                      </Button>
                      <Button variant="success" onClick={() => this.resumeOpenResult()} style={{ fontSize: 20, width: '20%', fontWeight: 'bold', margin: 10 }}>
                        {'恢复开彩'}
                      </Button>
                      {/* <Button variant="warning" onClick={() => this.CopyToC()} style={{ fontSize: 20, width: '20%', fontWeight: 'bold', margin: 10 }}>
                        {'拷贝i59开彩'}
                      </Button>
                      <div style={{ display: 'flex', wordBreak: 'break-all', overflowWrap: 'break-all', width: 200 }}>
                        <p>{this.state.toCopyWord}</p>
                      </div> */}
                      {/* <div style={{ display: 'flex', wordBreak: 'break-all', overflowWrap: 'break-all', width: 200 }}>
                        <p>{`${this.state.word[`p1`]} ${this.state.word[`p2`]} ${this.state.word[`p3`]}`}</p>
                      </div> */}
                    </div>
                    <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                      <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex'}}>
                        {(this.state.Selectedgame === '3J' || this.state.Selectedgame === '9J') ?
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {`${this.state.Selectedgame} 总数`}
                          </div>
                          : <>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {`ss 总数`}
                            </div>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {`pt 总数`}
                            </div>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {`jj 总数`}
                            </div>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {`82 总数`}
                            </div>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {`总数`}
                            </div>
                          </>
                        }
                        <div  className="resultbottom" style={{width:'calc(100%)'}} >
                          {/* {this.state.totalSales} */}
                          {'总赔数'}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                      <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex'}}>
                        {(this.state.Selectedgame === '3J' || this.state.Selectedgame === '9J') ?
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.totalSales}
                          </div>
                          : <>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {this.state.totalSalesss}
                            </div>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {this.state.totalSalespt}
                            </div>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {this.state.totalSalesjj}
                            </div>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {this.state.totalSales82}
                            </div>
                            <div className="resultbottom" style={{width:'calc(100%)'}} >
                              {this.state.totalSales}
                            </div>
                          </>
                        }
                        <div  className="resultbottom" style={{width:'calc(100%)'}} >
                          {`${this.state.totalWin} ( ${this.state.totalWinPercent || '0'}% )`}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                      <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex'}}>
                        <div  className="resultbottom" style={{width:'calc(100%)'}} >
                          {'位置'}
                        </div>
                        <div className="resultbottom" style={{width:'calc(100%)'}} >
                          {'字'}
                        </div>
                        <div className="resultbottom" style={{width:'calc(100%)'}} >
                          {'买数'}
                        </div>
                        <div className="resultbottom" style={{width:'calc(100%)'}} >
                          {'中奖数'}
                        </div>
                        <div className="resultbottom" style={{width:'calc(100%)'}} >
                          {'行动'}
                        </div>
                      </div>
                    </div>
                    {['1', '2', '3'].map((item) => 
                      <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: '#E1FCDD'}}>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {`P${item}  ( ${this.state.word[`p${item}OriPosition`]} )`}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {`${this.state.displayWord[`p${item}`]}`}
                          </div>
                          {/* <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.word[`p${item}OriPosition`]}
                          </div> */}
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.subtotal[`p${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.prize[`p${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            <Button variant="primary" onClick={() => this.changePosition(`p${item}`)} style={{ fontSize: 20, fontWeight: 'bold', margin: 10 }}>
                              {'生成'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'].map((item) => 
                      <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: '#FFF3FB'}}>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            S{item}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.displayWord[`s${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.subtotal[`s${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.prize[`s${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            <Button variant="primary" onClick={() => this.changePosition(`s${item}`)} style={{ fontSize: 20, fontWeight: 'bold', margin: 10 }}>
                              {'生成'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((item) => 
                      <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: '#E9FEFF'}}>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            C{item}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.displayWord[`c${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.subtotal[`c${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.prize[`c${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            <Button variant="primary" onClick={() => this.changePosition(`c${item}`)} style={{ fontSize: 20, fontWeight: 'bold', margin: 10 }}>
                              {'生成'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {['5D1', '5D2', '5D3', '5D4', '5D5', '5D6'].map((item) => 
                      <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: '#FDFFE9'}}>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {item}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {item === '5D4' ? this.state.word[`5D1`].substring(1, 5) : item === '5D5' ? this.state.word[`5D1`].substring(2, 5) : item === '5D6' ? this.state.word[`5D1`].substring(3, 5) : this.state.word[`${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.subtotal[`${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {this.state.prize[`${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {(item === '5D1' || item === '5D2' || item === '5D3') && <Button variant="primary" onClick={() => this.changePosition(`${item}`)} style={{ fontSize: 20, fontWeight: 'bold', margin: 10 }}>
                              {'生成'}
                            </Button>}
                          </div>
                        </div>
                      </div>
                    )}
                    {['6D1', '6D2', '6D3', '6D4', '6D5'].map((item) => 
                      <div style={{ display: 'flex', marginTop:3, color: 'white',  flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: '#FFF6E9'}}>
                          <div  className="resultbottom" style={{width:'calc(100%)'}}>
                            {item}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}}>
                            {item === '6D2' ? `${this.state.word[`6D1`].substring(0, 5)}/${this.state.word[`6D1`].substring(1, 6)}` : item === '6D3' ? `${this.state.word[`6D1`].substring(0, 4)}/${this.state.word[`6D1`].substring(2, 6)}` : item === '6D4' ? `${this.state.word[`6D1`].substring(0, 3)}/${this.state.word[`6D1`].substring(3, 6)}` : item === '6D5' ? `${this.state.word[`6D1`].substring(0, 2)}/${this.state.word[`6D1`].substring(4, 6)}` : this.state.word[`${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}}>
                            {this.state.subtotal[`${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}}>
                            {this.state.prize[`${item}`]}
                          </div>
                          <div  className="resultbottom" style={{width:'calc(100%)'}} >
                            {item === '6D1' && <Button variant="primary" onClick={() => this.changePosition(`${item}`)} style={{ fontSize: 20, fontWeight: 'bold', margin: 10 }}>
                              {'生成'}
                            </Button>}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment> 
              </div> 
            </div>
          </div>
        </div>
        {(this.state.PopScrollBackButton)&&<div class={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
          ^
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GameResult);