import '../App.css';
import React from 'react';
import Header from '../Component/Header';
import { connect } from 'react-redux'
import { language } from '../language';
import Magnum from '../Component/4DCard/Magnum';
import Damacai from '../Component/4DCard/Damacai';
import Toto from '../Component/4DCard/Toto';
import JackpotToto from '../Component/4DCard/JackpotToto';
import JackpotNine from '../Component/4DCard/JackpotNine';
// import BT4 from '../Component/4DCard/BT4';
import GD from '../Component/4DCard/Gd';
import JackpotGd from '../Component/4DCard/JackpotGd';
import Sandakan from '../Component/4DCard/Sdk';
import Sabah from '../Component/4DCard/Sabah';
import Sarawak from '../Component/4DCard/Swk';
import Sg4 from '../Component/4DCard/Sg4';
import Nine from '../Component/4DCard/Nine';
import { FaArrowCircleLeft } from '@react-icons/all-files/fa/FaArrowCircleLeft';
import { FaArrowCircleRight } from '@react-icons/all-files/fa/FaArrowCircleRight';
// import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MagnumLogo from '../Images/Cards/magnumlogo.png';
import TotoLogo from '../Images/Cards/toto.png';
import DamacaiLogo from '../Images/Cards/damacaimobile.png';
// import BT4Logo from '../Images/Cards/bt4DG1mobile.png';
import GDLogo from '../Images/Cards/GrandDragonLotto.png';
import Sg4Logo from '../Images/Cards/singaporePool.png';
import SarawakLogo from '../Images/Cards/sarawak.png';
import NINELogo from '../Images/Cards/9Lottologo.png';
import Sabah88Logo from '../Images/Cards/sabah88.png';
import SansakanLogo from '../Images/Cards/sandakan.png';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import ViewRSP_4D from '../Images/Menu/ViewRSP_4D.png';
const { get4DReseult } = require('../Api');

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class ViewResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      date: new Date(),
      curTime: Moment(new Date()).format('YYYYMMDD'),
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showGD: true,
      showNINE: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      dmc: {},
      mag: {},
      toto: {},
      gd: {},
      nine: {},
      bt4: {},
      sg4: {},
      sdk: {},
      sbh: {},
      swk: {},
      Username: this.props.username,
      token: this.props.token,
      Senddmc: '',
      Sendmag: '',
      Sendtoto: '',
      Sendbt4: '',
      Sendgd: '',
      Sendnine: '',
      Sendsg4: '',
      Sendsdk: '',
      Sendsbh: '',
      Sendswk: '',
      PopScrollBackButton: false,
    }
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.CallResult = this.CallResult.bind(this);
    this.REsultSend = this.REsultSend.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount() {
    this.CallResult();
  }

  async CallResult() {
    const data = await get4DReseult(
      Moment(new Date()).format('YYYY-MM-DD'),
      // '2022-01-22',
      this.props.token,
      this.props.username
    )
    if (data) {
      this.setState({
        dmc: data['2'] || {}, mag: data['1'] || {}, toto: data['3'] || {}, sg4: data['4'] || {}, gd: data['8'] || {}
        , sdk: data['7'] || {}, sbh: data['6'] || {}, swk: data['5'] || {}, nine: data['9'] || {},
      }, () => {
        this.REsultSend();
      })
    } else {
      this.setState({ dmc: {}, mag: {}, toto: {}, gd: {}, sg4: {}, sdk: {}, sbh: {}, swk: {}, nine: {} })
    }
  }

  async setSelectedDate(date) {
    this.setState({ date })
    const Todaydate = Moment(date).format('YYYY-MM-DD')
    const data = await get4DReseult(Todaydate, this.props.token, this.props.username)
    if (data) {
      this.setState({
        dmc: data['2'] || {}, mag: data['1'] || {}, toto: data['3'] || {}, sg4: data['4'] || {}, gd: data['8'] || {}
        , sdk: data['7'] || {}, sbh: data['6'] || {}, swk: data['5'] || {}, nine: data['9'] || {},
      }, () => {
        this.REsultSend();
      })
    } else {
      this.setState({ dmc: {}, mag: {}, toto: {}, gd: {}, sg4: {}, sdk: {}, sbh: {}, swk: {}, nine: {} })
    }
  }

  async changeYesterday() {
    const yesterday = Moment(this.state.date).subtract(1, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeTomorrow() {
    const yesterday = Moment(this.state.date).add(1, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  toggleResult(platform) {
    this.setState({
      [`show${platform}`]: !this.state[`show${platform}`],
    })
  }

  openWhatsApp = () => {
  }

  async REsultSend() {
    ['mag', 'dmc', 'toto', 'gd', 'sg4', 'sdk', 'sbh', 'swk'].forEach((item) => {
      this.setState({
        [`Send${item}`]: 
        `\n ${item === 'mag' ? '=M=' : item === 'dmc' ? '=K=' :  item === 'toto' ? '=T=' : item === 'gd' ? '=8=' : item === 'sg4' ? '=S=' : item === 'sdk' ? '=D=' : item === 'sbh' ? '=B=' : item === 'nine' ? '=9=' : '=W='} \n 1) ${this.state[item].P1 || 'XXXX'} \n 2) ${this.state[item].P2 || 'XXXX'} \n 3) ${this.state[item].P3 || 'XXXX'} ` 
        +  `\n 4) \n ${this.state[item][1] || 'XXXX'} ${this.state[item][2] || 'XXXX'} ${this.state[item][3] || 'XXXX'} ${this.state[item][4] || 'XXXX'} ${this.state[item][5] || 'XXXX'}`
        + `\n ${this.state[item][6] || 'XXXX'} ${this.state[item][7] || 'XXXX'} ${this.state[item][8] || 'XXXX'} ${this.state[item][9] || 'XXXX'} ${this.state[item][10] || 'XXXX'} \n`
        + ` ${this.state[item][11] || 'XXXX'} ${this.state[item][12] || 'XXXX'} ${this.state[item][13] || 'XXXX'} \n`
        + `5) \n ${this.state[item][14] || 'XXXX'} ${this.state[item][15] || 'XXXX'} ${this.state[item][16] || 'XXXX'} ${this.state[item][17] || 'XXXX'} ${this.state[item][18] || 'XXXX'}`
        + `\n ${this.state[item][19] || 'XXXX'} ${this.state[item][20] || 'XXXX'} ${this.state[item][21] || 'XXXX'} ${this.state[item][22] || 'XXXX'} ${this.state[item][23] || 'XXXX'}`,
      })
    })
  }

  handleScroll = (event) => {    
    var node = event.target;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    if (bottom) {
      this.setState({PopScrollBackButton: bottom})
    } else {
      this.setState({PopScrollBackButton: bottom})  
    }
  }

  render() {
    const ANR = 'Check More Result : https://ace4d.live/';
    const shareUrl = `${ANR}\n\nDate: ${Moment(this.state.date).format('MMDD')}${this.state.Sendmag}\n${this.state.Senddmc}\n${this.state.Sendtoto}\n${this.state.Sendsg4}\n${this.state.Sendbt4}\n${this.state.Sendsdk}\n${this.state.Sendsbh}\n${this.state.Sendswk}`;
    const currentDateFormat = Moment(this.state.date).format('YYYYMMDD')
    const numberOfCurrentDay = Number(Moment(this.state.date).tz('Asia/Kuala_Lumpur').format('d'))
    const yesterdayDay = numberOfCurrentDay - 1
    const tomorrowDay = numberOfCurrentDay + 1
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', backgroundColor: 'lightsteelblue', overflowX: 'auto', height: '100vh', flexDirection: 'column' }}>
        <div ref={this.elScroll.elRef} ></div>
        <Header />
        <div className="row" style={{ marginBottom: 15, display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '20px' }}>
          <div className="dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            <div>
              <img src={ViewRSP_4D} alt="img"
                style={{
                  height: 35,
                  width: 35,
                  marginRight: 5,
                }}
              />
            </div>
            <div style={{ fontSize: '25px', marginRight: 5 }}>{language[this.props.currentLanguage].FourDResult}</div>
          </div>
        </div>
        <div className="dcc mobileHeader" style={{ justifyContent: 'space-between', marginLeft: '4%', flexDirection: 'row' }}>
          <div className="TitleDate">日期: {this.state.TodayDate}</div>
          <div className="dcc" style={{ marginRight: 60 }}>
            <button className="dcc datePickerButton button2" onClick={() => this.changeYesterday()}>
              <FaArrowCircleLeft size="16px" color="#000" />
              <div className="mobiledatefont" style={{ marginLeft: 10 }}>
                {language[this.props.currentLanguage].daysOfWeek[yesterdayDay === -1 ? 6 : yesterdayDay]}
              </div>
            </button>
            <DatePicker
              onChange={(date) => this.setSelectedDate(date)}
              dateFormat='dd-MMM-yyyy'
              placeholder="Date"
              maxDate={new Date()}
              selected={this.state.date}
              // popperPlacement="top-right"
              // withPortal
            />
            <button className="dcc datePickerButton button2" onClick={() => this.changeTomorrow()}>
              <div className="mobiledatefont" style={{ marginRight: 10 }}>
                {language[this.props.currentLanguage].daysOfWeek[tomorrowDay === 7 ? 0 : tomorrowDay]}
              </div>
              <FaArrowCircleRight size="16px" color="#000" />
            </button>
          </div>
        </div>
        <div className={`d-flex justify-content-center px-3`}>
          <div className="dcc row" style={{ justifyContent: 'center' }} align="left">
            <img src={MagnumLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showMAG && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('MAG')} />
            <img src={DamacaiLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showDMC && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('DMC')} />
            <img src={TotoLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showTOTO && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('TOTO')} />
            <img src={Sg4Logo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSG && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SG')} />
            <img src={GDLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showGD && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('GD')} />
            <img src={SansakanLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSTC && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('STC')} />
            <img src={Sabah88Logo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSBH && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SBH')} />
            <img src={SarawakLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSWK && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SWK')} />
            <img src={NINELogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showNINE ? 'button2 activeResult' : 'activeResultnormal'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('NINE')} />
          </div>
        </div>
        <div className="d-flex justify-content-center px-3">
          <div className={`row`} style={{ width: '80%', marginTop: 30 }}>
            {
              (this.state.showMAG && (this.state.mag.P1 || currentDateFormat >= this.state.curTime)) && <Magnum mag={this.state.mag} date={this.state.date} />
            }
            {
              (this.state.showDMC && (this.state.dmc.P1 || currentDateFormat >= this.state.curTime)) && <Damacai dmc={this.state.dmc} date={this.state.date} />
            }
            {
              (this.state.showTOTO && (this.state.toto.P1 || currentDateFormat >= this.state.curTime)) && <Toto toto={this.state.toto} date={this.state.date} />
            }
            {
              (this.state.showSG && (this.state.sg4.P1 || currentDateFormat >= this.state.curTime)) && <Sg4 sg4={this.state.sg4} date={this.state.date} />
            }
            {/* {
              (this.state.showBT4 && (this.state.bt4.P1 || currentDateFormat >= this.state.curTime)) && <BT4 bt4={this.state.bt4} date={this.state.date} />
            } */}
            {
              (this.state.showSTC && (this.state.sdk.P1 || currentDateFormat >= this.state.curTime)) && <Sandakan sdk={this.state.sdk} date={this.state.date} />
            }
            {
              (this.state.showSBH && (this.state.sbh.P1 || currentDateFormat >= this.state.curTime)) && <Sabah sbh={this.state.sbh} date={this.state.date} />
            }
            {
              (this.state.showSWK && (this.state.swk.P1 || currentDateFormat >= this.state.curTime)) && <Sarawak swk={this.state.swk} date={this.state.date} />
            }
            {
              (this.state.showGD && (this.state.gd.P1 || currentDateFormat >= this.state.curTime)) && <GD gd={this.state.gd} date={this.state.date} />
            }
            {
              (this.state.showNINE && (this.state.nine.P1 || currentDateFormat >= this.state.curTime)) && <Nine nine={this.state.nine} date={this.state.date} />
            }

          </div>
        </div>
        <div className="row" style={{ marginBottom: 30, display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '20px' }}>
          <div className="dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            <div style={{ fontSize: '25px', marginRight: 5 }}>5/6D Result</div>
          </div>
        </div>
        <div className="d-flex justify-content-center px-3">
          <div className={`row`} style={{ width: '80%', marginTop: 30 }}>
            {
              (this.state.showTOTO && (this.state.toto.P1 || currentDateFormat >= this.state.curTime)) && <JackpotToto toto={this.state.toto} date={this.state.date} />
            }
            {
              (this.state.showGD && (this.state.gd.P1 || currentDateFormat >= this.state.curTime)) && <JackpotGd gd={this.state.gd} date={this.state.date} />
            }
            {
              (this.state.showNINE && (this.state.nine.P1 || currentDateFormat >= this.state.curTime)) && <JackpotNine nine={this.state.nine} date={this.state.date} />
            }
          </div>
        </div>
        <div className="dcc" style={{ fontWeight: 'bold', fontSize: '20px' }}>{'Share Result To'}</div>
        <div className="dcc" style={{ paddingBottom: 55 }}>
          <div style={{ marginRight: 10 }}>
            <WhatsappShareButton
              url={shareUrl}>
              <WhatsappIcon size={60} round={true} />
            </WhatsappShareButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <FacebookShareButton
              url={shareUrl}>
              <FacebookIcon size={60} round={true} />
            </FacebookShareButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <LineShareButton
              url={shareUrl}>
              <LineIcon size={60} round={true} />
            </LineShareButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <EmailShareButton
              url={shareUrl}>
              <EmailIcon size={60} round={true} />
            </EmailShareButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <TwitterShareButton
              url={shareUrl}>
              <TwitterIcon size={60} round={true} />
            </TwitterShareButton>
          </div>
        </div>
        {(this.state.PopScrollBackButton)&&<div className="scroll-to-top iconpopup button2"  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold', cursor: 'pointer' }}>
          ^
          </div>
        </div>}
      </div >
    );
  }
}

// export default ViewResult;

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewResult);
