import '../App.css';
import React from 'react';
import { language } from '../language';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Header2 from '../Component/Header';
import Table from 'react-bootstrap/Table';
import Moment from 'moment-timezone';
// import Loading from '../Images/loading.gif';
import { Alert } from 'react-bootstrap';
import { GamePlatformNumb1, GamePlatformNumb2 } from '../static';
const { getGame, createGame, updateGame, updateGameStatus  } = require('../Api');
// api deleteGame getConfiguration
var timer

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class AddRNPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Games: ['Default'],
      GamesDetail: ['Default'],
      GamesAvailable : [],
      // GameLimitPackage: {Default: { all: '', B: '', S: '', A1: '', A1C: '', A: '', C: '', A2: '', A3: '', D4: '', E4: '', "5D": '', "6D": '' }},
      // OriGameLimitPackage: {Default: { all: '', B: '', S: '', A1: '', A1C: '', A: '', C: '', A2: '', A3: '', D4: '', E4: '', "5D": '', "6D": '' }},
      redNumberData: {},
      diable: false,
      SelectedDate: new Date(),
      DetailBoolean: false,
      ChooseIDGameBoolean: false,
      selectedID: '',
      CreateGameApiResult: [],
      notificationMessage: '',
      notificationType: 'success',
      showNotification: false,
      AddDisable: false,
      UpdateBoolean: false,
      GameID: '',
      GamePlatform: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      GamePlatform2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'],
      BooleanAll: false,
      GDisplay: '',
      newArray:[
        {
          GameID: 1234,
          ALL: false, 
          M: false, 
          K: false,  
          T: false, 
          S: false, 
          W: false, 
          B: false, 
          D: false, 
          8: false,
          9: false 
        },
        {
          GameID: 1235,
          ALL: false, 
          M: false, 
          K: false,  
          T: false, 
          S: false, 
          W: false, 
          B: false, 
          D: false, 
          8: false,
          9: false 
        },
        {
          GameID: 1236,
          ALL: false, 
          M: false, 
          K: false,  
          T: false, 
          S: false, 
          W: false, 
          B: false, 
          D: false, 
          8: false,
          9: false 
        },
      ],
      PressCount: 0,
      Authority: {},
      PopScrollBackButton: false,
    }
    // this.addGameF = this.addGameF.bind(this);
    this.addUpdateF = this.addUpdateF.bind(this);
    this.DetailHide =  this.DetailHide.bind(this);
    this.FocusHere = utilizeScroll();
  }


  async componentDidMount() {
    this.CallAPI();
    this.SetUsersAuthorities();
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  async CallAPI(){
    const GDisplay = localStorage.getItem('GameDisplay');
    const ArrayGame = await getGame( this.props.token, new Date(), this.props.username)
    if(ArrayGame.message === "No Data"){
      this.setState({CreateGameApiResult: []})
    } else {
      this.setState({ GDisplay, CreateGameApiResult: JSON.parse(JSON.stringify(ArrayGame))})
    }
  }

  gameChangeDetail(game) {
    let newGamesArray = this.state.GamesDetail
    let newGameLimitPackage = this.state.GameLimitPackage
    if (this.state.GamesDetail.includes(game)) {
      newGamesArray.splice(this.state.GamesDetail.indexOf(game), 1);
      delete newGameLimitPackage[game]
    } else {
      newGamesArray.push(game);
      newGameLimitPackage[game] = newGameLimitPackage.Default
    }
    const defaultArray = (this.state.GDisplay === 'MPTSWBK') ? ['Default','M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'] : ['Default', 'M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'];
    const arrangedArray = newGamesArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    this.setState({ GamesDetail: JSON.parse(JSON.stringify(arrangedArray))})
  }

  AvailablegameChangeDetail(game){
    let newGamesAvailableArray = this.state.GamesAvailable;

    if (game === 'ALL') {
      if (this.state.GamesAvailable.includes(game)) {
        newGamesAvailableArray = []
      } else {
        newGamesAvailableArray = (this.state.GDisplay === 'MPTSWBK') ? ['ALL', 'M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'] : ['ALL', 'M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9']
      }
    } else {
      if (this.state.GamesAvailable.includes(game)) {
        newGamesAvailableArray.splice(this.state.GamesAvailable.indexOf(game), 1);
      } else {
        newGamesAvailableArray.push(game);
      }
    }
    // this.state.GamesAvailable
    const defaultArray = (this.state.GDisplay === 'MPTSWBK') ? ['Default','M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'] : ['Default', 'M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'];
    const arrangedArrayAvailable = newGamesAvailableArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    this.setState({ GamesAvailable: JSON.parse(JSON.stringify(arrangedArrayAvailable))})
  }

  CheckAllGameAvailable(){
    if (!this.state.BooleanAll === false ){
      this.setState({BooleanAll: !this.state.BooleanAll, GamesAvailable: JSON.parse(JSON.stringify( [] ))})
    } else {
      this.setState({BooleanAll: !this.state.BooleanAll, GamesAvailable: JSON.parse(JSON.stringify( [1,2,3,4,5,6,7,8,9] ))})
    }
  }

  // renderBackgroundColor(game) {
  //   if (game === 'Default') return 'lightsteelblue'
  //   else if (game === 'M') return '#fff23e'
  //   else if ((this.state.GDisplay === 'MPTSWBK') ? game === 'P' : game === 'K') return '#414bb2'
  //   else if (game === 'T') return '#ff431b'
  //   else if (game === 'S') return '#32c6f4'
  //   else if (game === 'W') return 'green'
  //   else if (game === 'B') return '#fc0404'
  //   else if ( (this.state.GDisplay === 'MPTSWBK') ? game === 'K' : game === 'D') return '#f8c100'
  //   else if (game === '8') return '#6d100e'
  //   else return null
  // }

  SelectedDate(Date){
    this.setState({SelectedDate: Date })
  }

  modeChange(modeValue, field) {
    this.setState({ [field]: modeValue })
    if (modeValue === '') {
      this.setState({
        ChooseIDGameBoolean: false,
      })
    } else {
      this.setState({
        ChooseIDGameBoolean: true,
      })
    }
  }

  DetailHide(Status, Item){
    if (this.state.DetailBoolean !== false ) {
      this.setState({
        DetailBoolean: false,
        UpdateBoolean: false, 
        GamesDetail: ['Default'], 
        GamesAvailable : [],
      })
    } else {
      if(Status === 'Create' && this.state.CreateGameApiResult.length === 0) {
        console.log('j')
        this.setState({ DetailBoolean: true,UpdateBoolean: false, SelectedDate: new Date() }, ()=>{
          this.FocusHere.executeScroll();
        })
      }
      if(Status === 'Create' && this.state.CreateGameApiResult.length > 0) {
        const array = this.state.CreateGameApiResult
        const LastArray = array[array.length - 1];
        this.setState({ 
          DetailBoolean: true, 
          UpdateBoolean: false,
          SelectedDate: Moment(LastArray['Date']).add(1, 'd'),
          GamesDetail: ['Default'], 
          NewGameID: LastArray.ID + 1,
        }, ()=>{
          this.FocusHere.executeScroll();
        })
      }
      if(Status === 'Update' && this.state.CreateGameApiResult.length > 0) {
        const newArray = [];
        [1,2,3,4,5,6,7,8,9].forEach((itemkey) => {
          if((Item[itemkey] === undefined ? 0 : Item[itemkey])!== 0) {
            newArray.push(itemkey)
          }
        })
        this.setState({ 
          UpdateBoolean: true, 
          DetailBoolean: true, 
          GamesAvailable: JSON.parse(JSON.stringify(newArray)),
          SelectedDate: Moment(Item.Date),
          NewGameID: Item.ID ,
        },()=> {
          this.FocusHere.executeScroll();
        })
      }
    }
  }

  async addGameF() {
    this.setState({ AddDisable: true}, async ()=>{
      const ArrayCreateGame = await createGame(
        Moment(this.state.SelectedDate).format('YYYY-MM-DD'),
        this.state.GamesAvailable,
        this.props.username, 
        this.props.token)
      if (ArrayCreateGame.message === "Game created successful" ) {
        this.setState({ notificationType: 'success',  AddDisable: false, SelectedDate: Moment(this.state.SelectedDate).add(1, 'd'), NewGameID: this.state.NewGameID + 1}, () => {
          this.openNotification(`${language[this.props.currentLanguage].Gamecreatedsuccessful}`)
        });
      } else {
        this.setState({ notificationType: 'error',  AddDisable: false }, () => {
          this.openNotification(`${language[this.props.currentLanguage].Unsuccess}`)
        });
      }
    })
  }

  async addUpdateF(){
    const ArrayUpdateGame = await updateGame(
      this.state.SelectedDate,
      this.state.GamesAvailable,
      this.state.NewGameID,
      this.props.username, 
      this.props.token )
    if (ArrayUpdateGame.message === "Game update successful" ){
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].GameUpdateSuccessful}`)
      });
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].Unsuccess}`)
      });
    }
    
  }

  async toggleDeletedCreateGameForm(ID_Deleted, status){
    const StatusResult = (status === 1) ? 0 : 1 ;
    this.setState({ AddDisable: true }, async ()=> {
      const r = (window.confirm( (status === 1) ? `${language[this.props.currentLanguage].ActiveGame}` : `${language[this.props.currentLanguage].RestoreGame}`));
      if (r === true) {
        const deleteGameResult = await updateGameStatus(ID_Deleted, StatusResult, this.props.username, this.props.token)
        if (deleteGameResult.message === 'Game Status Had Been Updated') {
          this.setState({ notificationType: 'success', AddDisable: false });
          this.openNotification(`GameStatusHadBeenUpdated`)
        }
      } else {
        this.setState({ AddDisable: false });
      }
    })
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
      });
      this.CallAPI();
    }, 1500);
  };

  onKeyEnter(event) {
    if (event.key === 'Enter') {
      const KeyPress = this.state.PressCount +1
      this.setState({ PressCount: KeyPress}, ()=>{
        if (this.state.PressCount === 1){
          if(this.state.UpdateBoolean !== true){
            this.addGameF();
          } else {
            this.addUpdateF();
          }
        }
      })
    }
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  render() {
    return(
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowY: 'auto' }}> 
        <div>
          <Header2 />
        </div>
        <div className="row" >
          <div className="redNumberTitle" style={{ width: '25%', fontSize: '20px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1%' }}/>
          <div className="redNumberTitle form-control" style={{ width: '25%', fontSize: '20px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1%' }}>
            <b> {language[this.props.currentLanguage].Addgame} </b>
          </div>
          <div className="redNumberTitle" style={{ width: '25%', fontSize: '20px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1%' }}>
            <Button  className='button2' variant="success" disabled={this.state.AddDisable }  onClick={() => this.DetailHide('Create')}>{language[this.props.currentLanguage].CreateGame}</Button>
          </div>
        </div>
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div style={{ border: '1px solid #000', marginBottom: 5, marginTop: '1%', overflowX: 'auto'}}>
          <div>
            {this.state.CreateGameApiResult && this.state.CreateGameApiResult.length > 0 ? (
              <Table responsive="sm" striped bordered hover>
                <thead style={{ backgroundColor: 'lightsteelblue' }}>
                  <tr>
                    <th> ID </th>
                    <th><b> {language[this.props.currentLanguage].DateTime} </b></th>
                    <th><b> {language[this.props.currentLanguage].Limit} </b></th>
                    <th style={{ minWidth:  null }}><b> {language[this.props.currentLanguage].Action} </b></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.CreateGameApiResult.map((GameITEM, index) => {
                      return <tr key={index} style={{ backgroundColor: (GameITEM.Active === 0) ? '#ffcccb' : 'white'}}>
                        <td style={{ verticalAlign: 'middle'}}>{GameITEM.ID}</td>
                        <td style={{ verticalAlign: 'middle'}}>{Moment(GameITEM.Date).format('DD/MM/YYYY')}, {language[this.props.currentLanguage][`Day${Moment(GameITEM.Date).day()}`]}</td>
                        <td style={{ verticalAlign: 'middle'}}>
                          <Table style={{ marginBottom: 1 }}>
                            <thead>
                              <tr>
                                {[1,2,3,4,5,6,7,8,9].map((itemkey, ind) => (
                                  ( (GameITEM[itemkey] === undefined ? 0 : GameITEM[itemkey]) !== 0) ? <td key={ind}> {(this.state.GDisplay === 'MPTSWBK') ? `${GamePlatformNumb2[itemkey]}` : `${GamePlatformNumb1[itemkey]}`} </td> : <></> 
                                ))}
                              </tr>
                            </thead>
                          </Table>
                        </td>
                        <td style={{ verticalAlign: 'middle'}}>
                          {this.state.Authority.EditGame === 1 ? (
                            <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                              <div>
                                <Button  className='button2' variant="primary" style={{ width: '100%', marginRight: 5 }} disabled={this.state.AddDisable } onClick={() => this.DetailHide('Update', GameITEM)}>{language[this.props.currentLanguage].Edit}</Button>
                              </div>
                            </div>
                          ) : (
                            <div className="dcc" style={{ flexDirection: 'row', width: '100%', color: 'red', fontWeight: 'bold'}}>
                              {language[this.props.currentLanguage].DontHaveAutho}
                            </div>
                          )}
                        </td>
                      </tr>
                    })
                  } 
                </tbody>
              </Table>
            ) : (
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div>
                  <div className="loadingMessage"> {language[this.props.currentLanguage].NOGAME} </div>
                </div>
              </div>
            )}
          </div>
          <hr/>
        </div>
        <div style={{ display: 'flex', padding: 10, border: '1px solid #000', overflowX: 'auto', marginBottom: '1.5%'}}   hidden={this.state.DetailBoolean ? false : true}>
          <div style={{width: 'calc(100vw - 655px)'}}>
            <div style={{  justifyContent: '', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, width: '100%', marginBottom: '2%'}} >
              <div style={{ fontSize: 24, fontWeight: 'bold'}}>
                {`${language[this.props.currentLanguage].GameID} :`}
              </div>
              <div>
                <div style={{ fontSize: 24, fontWeight: 'bold', width: 150}}>
                  {this.state.NewGameID}
                </div>
              </div>
            </div>
            <div style={{  justifyContent: '', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, width: '100%', marginBottom: '2%'}} >
              <div style={{ fontSize: 24, fontWeight: 'bold'}}>
                {`${language[this.props.currentLanguage].Date} :`}
              </div>
              <div>
                <div style={{ fontSize: 24, fontWeight: 'bold' , width: 350}}>
                  {Moment(this.state.SelectedDate).format('DD/MM/YYYY h:mm A')}
                </div>
              </div>
            </div>
            <div style={{ justifyContent:  '', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, width: '100%', marginBottom: '2%'}} >
              <div style={{ fontSize: 24, fontWeight: 'bold',marginRight: 10,}}>
                {`${language[this.props.currentLanguage].Platform} :`}
              </div>
              <div style={{ display: 'flex'}}>
                <Form.Check
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                  inline
                  type="checkbox"
                  label={'ALL'}
                  checked={this.state.BooleanAll}
                  onChange={(e) => this.CheckAllGameAvailable()}
                />
              </div>
              <div style={{ display: 'flex'}}>
                {[1,2,3,4,5,6,7,8,9].map((item, index) => {
                  return <Form.Check key={index}
                    style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                    inline
                    label={(this.state.GDisplay === 'MPTSWBK') ? GamePlatformNumb2[item] : GamePlatformNumb1[item]}
                    type="checkbox"
                    checked={this.state.GamesAvailable.includes(item)}
                    onChange={(e) => this.AvailablegameChangeDetail(item, e.target.value)}
                  />
                })}
              </div>
            </div>
            <div className='dcc' style={{  justifyContent: '', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, width:'100%', marginBottom: '2%'}} >
              <div>
                <Button  className='button2' style={{ marginLeft: 10, width:  null}} variant="danger" disabled={this.state.AddDisable } onClick={() => this.DetailHide('Close')} onChange={() => this.props.modeChange('Ban', 'selectedMode')}>
                  {language[this.props.currentLanguage].Close}
                </Button>
              </div>
              <div>
                {(this.state.UpdateBoolean !== true)?(
                  <Button  className='button2' style={{ marginLeft: 10, width: null}} variant="success" disabled={this.state.AddDisable }  onClick={() => this.addGameF()}>{language[this.props.currentLanguage].Add}</Button>
                ):(
                  <Button  className='button2' style={{ marginLeft: 10, width: null}} variant="success" disabled={this.state.AddDisable }  onClick={() => this.addUpdateF()}>{language[this.props.currentLanguage].Update}</Button>
                )}
              </div>
            </div>
          </div>
          <div ref={this.FocusHere.elRef}/>
          <hr/>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(AddRNPackage);