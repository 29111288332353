import '../App.css';
import React from 'react';
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import Header from '../Component/Header';
import { connect } from 'react-redux'
import { language } from '../language';
// import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
import DisplayPackage from '../Component/DisplayPackage';
import { Button, Card, Form } from 'react-bootstrap';
import Androidlogo from '../Images/Androidlogo.png';
import LogoMogu from '../Images/LogoMoguRemove.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import JSEncrypt from 'jsencrypt';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
const { homeDisplayPackage, getBackupWords, getPublicKey, chgPwd, displaySpecialUser, getInformation, getPackageBackupF, getBackupFightingF, dadingIDListFNC, getBackupJackpot } = require('../Api');
const { exportBackupExcel, exportPackageBackupExcel, exportBackupJackpotTxt } = require('../jsonToXlsx');
var timer

//
//                       _oo0oo_
//                      o8888888o
//                      88" . "88
//                      (| -_- |)
//                      0\  =  /0
//                    ___/`---'\___
//                  .' \\|     |// '.
//                 / \\|||  :  |||// \
//                / _||||| -:- |||||- \
//               |   | \\\  -  /// |   |
//               | \_|  ''\---/''  |_/ |
//               \  .-\__  '-'  ___/-. /
//             ___'. .'  /--.--\  `. .'___
//          ."" '<  `.___\_<|>_/___.' >' "".
//         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//         \  \ `_.   \_ __\ /__ _/   .-` /  /
//     =====`-.____`.___ \_____/___.-`___.-'=====
//                       `=---='
//
//
//     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//               佛祖保佑         永无BUG
const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      UserID: this.props.userID,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      SelectedDate: Moment(localStorage.getItem('SELECTED_DATE')).toDate(),
      SelectedFightingDate: new Date(),
      Authority: '',
      DisplayPakege: false,
      getPackagename: [],
      SelectedPackageID: '',
      Run: false,
      date: new Date(),
      ChangePasswordModal: false,
      CShowPassword: false,
      ShowPassword: false,
      RShowPassword: false,
      CPassword: '',
      NPassword: '',
      RPassword: '',
      showNotification: false,
      SpecialUsers: '',
      AdvertisementModal: false,
      NewOption: [],
      ImageNews: '',
      background: '#eb5f5f',
      backgroundclose: 'white',
      downliadDading: '',
      LastDadingTime: '',
      PopScrollBackButton: false,
      Word: 'C',
      GamesDisplay: '',
      DownFightingModal: false,
      loading: false,
      problemModalUserList: false,
      problemListData: [],
    }
    this.elScroll = utilizeScroll();
    this.closeDeleteProblemListModal = this.closeDeleteProblemListModal.bind(this)
  }

  async componentDidMount() {
    this.SetUsersAuthorities();
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
    if (this.props.Role === 'superAdmin') {
      this.getdisplaySpecialUser();
    }
    const GDisplay = localStorage.getItem('GameDisplay');

    this.setState({
      GamesDisplay: GDisplay,
    })
    // this.getNextAlphebet();
    // localStorage.setItem('LoginStatus', LoginStatus.FirstTimeLogin)
  }

  // getNextAlphebet() {
  //   const str = this.state.Word
  //   if (str === 'Z') {
  //     this.setState({
  //       Word: '1'
  //     })
  //   } else if (str === '9'){
  //     this.setState({
  //       Word: 'AA'
  //     })
  //   } else {
  //     const nextLetter = String.fromCharCode(str.charCodeAt(str.length - 1) + 1)
  //     this.setState({ Word: nextLetter })
  //   }
  // }

  async getdisplaySpecialUser() {
    const getSpecialUsers = await displaySpecialUser(this.props.username, this.props.token)
    if (getSpecialUsers.message === 'No Special User') {
      this.setState({ SpecialUsers: '' })
    } else {
      this.setState({ SpecialUsers: getSpecialUsers })
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  async SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    const LoginStatus = localStorage.getItem('LoginStatus');
    if (Number(LoginStatus) === 0) {
      this.setState({ ChangePasswordModal: true })
    } 
    this.setState({
      Authority: UserAuthorities,
    })
    const resultInformation = await getInformation('latest',this.props.username, this.props.token)
    console.log('resultInformation', resultInformation)
    this.setState({NewOption: resultInformation})
  }

  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  async ShowPackage() {
    if(this.state.DisplayPakege === false) {
      // let getPackagename = await getPackageNames(this.props.username, this.props.userID, this.props.token)
      let getPackagename = await homeDisplayPackage(this.props.username, this.props.token, this.props.userID)
      if(getPackagename.message === 'User No Package') {
        this.setState({ getPackagename: [], DisplayPakege: true })
      } else {
        getPackagename = getPackagename.sort(function(a,b){
          if ((a.childPackage === null) === (b.childPackage === null)) {
            return a.Name.localeCompare(b.Name);
          }
          return (a.childPackage === null) - (b.childPackage === null)
        })
        this.setState({ getPackagename, DisplayPakege: true })
      }
    } else {
      this.setState({ getPackagename: [], DisplayPakege: false, Run: false, SelectedPackageID: '' })
    }
  }

  OpenDisplayPack(PackageID) {
    this.setState({ Run: false }, ()=> {
      this.setState({ SelectedPackageID: PackageID, Run: true })
    })
  }

  // async setSelectedDate(date, mode) {
  //   this.setState({
  //     [mode]: date,
  //   }, ()=> {
  //     this.props.setDate(this.state[mode])
  //   });
  // }

  async GoToBet() {
    this.props.history.push('/betting')
  }

  GoToReceipt() {
    this.props.history.push('/ViewReceipt')
  }

  GoToResult() {
    this.props.history.push('/ViewResult')
  }

  GoToReport() {
    this.props.history.push('/DownlineReport')
  }

  GoToSetting() {
    this.props.history.push('/Setting')
  }

  GoToHitSale() {
    this.props.history.push('/Hit_Sales')
  }

  async exportBackup(Type) {
    const GDisplay = localStorage.getItem('GameDisplay');
    if (Type === 'Backup') {
      this.setState({ loading: true})
      const wordsList = await getBackupWords(this.props.username, this.props.token)
      await exportBackupExcel( wordsList, this.props.currentLanguage, 'wordsList', this.state.GamesDisplay)
      this.setState({ loading: false})
    } else if(Type === 'Fighting') {
      this.setState({ DownFightingModal: !this.state.DownFightingModal})
    } else if (Type === 'Jackpot') {
      this.setState({ loading: true})
      const wordsList = await getBackupJackpot(this.props.username, this.props.token)
      await exportBackupJackpotTxt( wordsList)
      this.setState({ loading: false})
    } else {
      const GetPackage = await getPackageBackupF(this.props.Role, this.props.username, this.props.token);
      if(GetPackage.Message === 'Success') {
        console.log('ssss')
        await exportPackageBackupExcel(GetPackage.Package , this.props.currentLanguage, {Username: this.props.username, UserID: this.props.userID}, GDisplay)
      }
    } 
  }

  async DownloadFightingBackup() {
    const SelectedFightingDate = Moment(this.state.SelectedFightingDate).format('YYYY-MM-DD')
    const FightingBackup = await getBackupFightingF(this.props.username, SelectedFightingDate, this.props.token)
    await exportBackupExcel(FightingBackup, this.props.currentLanguage, 'fighting', this.state.GamesDisplay)
    if (FightingBackup) {
      this.setState({ DownFightingModal: !this.state.DownFightingModal })
    }
  }

  async setSelectedDate(date, mode) {
    this.setState({
      [mode]: date
    });
  }

  async ChangePassword(){
    if ((this.state.CPassword).length === 0){
      this.setState({ notificationType: 'error', notificationMessage: language[this.props.currentLanguage].CurrentPasswordEmpty }, () => {
        this.openNotification()
      });
    } else if ((this.state.NPassword).length === 0){
      this.setState({ notificationType: 'error', notificationMessage: language[this.props.currentLanguage].NewPasswordEmpty }, () => {
        this.openNotification()
      });
    } else if ((this.state.RPassword).length === 0){
      this.setState({ notificationType: 'error', notificationMessage: language[this.props.currentLanguage].RepeatPasswordEmpty }, () => {
        this.openNotification()
      });
    } else if ( this.state.NPassword !== this.state.RPassword){
      this.setState({ notificationType: 'error', notificationMessage: language[this.props.currentLanguage].NRPasswordDifferent }, () => {
        this.openNotification()
      });
    } else {
      const PublicKey = await getPublicKey();
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(PublicKey.publicKey);
      let CnPassword = encrypt.encrypt(this.state.CPassword);
      let NnPassword = encrypt.encrypt(this.state.NPassword);

      const PasswordResult = await chgPwd(this.props.userID, CnPassword, NnPassword, this.props.username, this.props.token)
      if (PasswordResult.message === 'Password Changed Successfully'){
        this.setState({notificationType: 'success', notificationMessage: language[this.props.currentLanguage].PasswordChangedSuccessfully}, ()=>{
          this.openNotificationSuccess(PasswordResult.message)
          localStorage.setItem('LoginStatus', 1)
        })
      } else {
        this.setState({ notificationType: 'error', notificationMessage: PasswordResult.error }, () => {
        });
      }
    }
  }

  openNotificationSuccess(message) {
    this.setState({ showNotification: true });
    // this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '', ChangePasswordModal: false });
    }, 500);
  };

  openNotification(message) {
    this.setState({ showNotification: true });
    // this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  handleScroll = (event) => {    
    var node = event.target;
    const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    if (bottom) {
      this.setState({PopScrollBackButton: bottom})
    } else {
      this.setState({PopScrollBackButton: bottom}) 
    }
  }

  closeDeleteProblemListModal() {
    this.setState({ problemModalUserList: false, problemListData: [] })
  }

  async CallProblemList() {
    const UserLists = await dadingIDListFNC(this.props.token, this.props.username)
    if (UserLists) {
      this.setState({ problemListData: UserLists }, () => {
        this.setState({ problemModalUserList: true })
      })
    }
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} className='StartBackground'>
        <div ref={this.elScroll.elRef} ></div>
        <Header />
        <Modal centered show={this.state.problemModalUserList} onHide={this.closeDeleteProblemListModal}>
          <Modal.Header closeButton>
            <Modal.Title>检查运行着的单</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.problemListData.length === 0 && 
            <div>No Problem Dan</div>
            }
            {this.state.problemListData.length > 0 && 
            <div style={{ maxHeight: '75vh',  overflowY: 'scroll' }}>
              {this.state.problemListData.map((items, idddx) => {
                return(
                  <div>{`${idddx+1}: ${items.ID} - ${Moment(items.Datetime).format('YYYY-MM-DD HH:mm:ss')}`}</div>
                );
              })}
            </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.closeDeleteProblemListModal()}>关闭</Button>
          </Modal.Footer>
        </Modal>
        <div className='dcc' style={{ width: '100%', marginBottom: 25, marginTop: 15 }}>
          <div style={{ width: '82%', display: 'flex', flexDirection: 'row'}}>
            <div className='HomePageTitleSize' style={{ width: '29%', marginRight: 5, backgroundColor: '#ADD8E6', padding: 18}}>
              <div className='HomePageTitle'><FaHome size="25px" color="black" className="logohome" style={{ marginRight: 10, }} />{language[this.props.currentLanguage].Home}</div>
              <div className='HomePageTitle'>{language[this.props.currentLanguage].Date}: {this.state.TodayDate}</div>
              <div className='HomePageTitle'>{language[this.props.currentLanguage].Time}: {this.state.date.toLocaleTimeString()}</div>
              <div className='HomePageTitle'>ID: {this.state.username}</div>
            </div>
            <div className='HomePageTitleSize' style={{ width: '70%', backgroundColor: '#FCEEA7', textAlign: 'right', padding: 18, fontWeight: 'bold' }}>
              <div style={{ marginBottom: 5, padding: 2 }}>{language[this.props.currentLanguage].ClaseTime} : {language[this.props.currentLanguage].CloseTimePlatform1} <input style={{ textAlign: 'center', width: '22%' }} readOnly={true} value={'7.00 pm'}/></div>
              <div style={{ marginBottom: 5, padding: 2 }}>{language[this.props.currentLanguage].CloseTimePlatform2} <input style={{ textAlign: 'center', width: '22%' }} readOnly={true} value={'6.25 pm'}/></div>
              <div style={{ padding: 2, marginBottom: 5 }}>{language[this.props.currentLanguage].GrandDragon} <input style={{ textAlign: 'center', width: '22%' }} readOnly={true} value={'7.00 pm'}/></div>
              <div style={{ padding: 2 }}>9Lotto(9) <input style={{ textAlign: 'center', width: '22%' }} readOnly={true} value={'7.30 pm'}/></div>
            </div>
          </div>
        </div>
        <div className='dcc' style={{width: '100%', marginBottom: (this.state.DisplayPakege === true) ? 5 : 30 }}>
          <div style={{ width: '82%', display: 'flex', flexDirection: 'row'}}>
            <div className='HomePageTitleSize' style={{ width: '29%', marginRight: 5, backgroundColor: '#FCEEA7', padding: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].ChangeLanguage}:  </div>
              <div><Form.Control  as="select" className="form-control button2" value={this.state.SelectedExample} onChange={(e) => this.props.setLanguage(e.target.value)} 
                style={{ textAlign: 'center', fontSize: '16px'}}>
                <option value="cn" selected={this.props.currentLanguage === 'cn'}>{language[this.props.currentLanguage].TranslateLanguageCN}</option>
                <option value="en" selected={this.props.currentLanguage === 'en'}>{language[this.props.currentLanguage].TranslateLanguageEN}</option>
                <option value="Malay" selected={this.props.currentLanguage === 'Malay'}>{language[this.props.currentLanguage].TranslateLanguageBM}</option>
              </Form.Control></div>
            </div>
            <div className='dcc' style={{ width: '70%', backgroundColor: '#ADD8E6', textAlign: 'right', padding: 15, fontWeight: 'bold' }}>
              <div className='dcc' style={{ width: '50%'}}>
                <Button style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', width: 200, fontWeight: 'bold', fontSize: '15px'}}
                  onClick={() => this.ShowPackage()}
                  className="btn my-cusomized-button button2">
                  {language[this.props.currentLanguage].CheckPackage}
                </Button>
              </div>
              <div className='dcc' style={{ width: '50%' }}>
                {this.state.loading ? <>
                  <img style={{ width: 50, height: 50}} 
                    src={'https://i.imgur.com/GLdqYB2.gif'}
                    alt=""/>
                </> : <>
                  {this.props.Role === 'superAdmin' && <Button style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', width: 'auto', fontWeight: 'bold', fontSize: '15px' }}
                    onClick={() => this.exportBackup('Jackpot')}
                    className="btn my-cusomized-button button2">
                    {`${language[this.props.currentLanguage].Download} Jackpot`}
                  </Button>}
                  <Button style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', width: 'auto', fontWeight: 'bold', fontSize: '15px' }}
                    onClick={() => this.exportBackup('Fighting')}
                    className="btn my-cusomized-button button2">
                    {`${language[this.props.currentLanguage].Download} ${language[this.props.currentLanguage].Fighting}`}
                  </Button>
                  <Button style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', width: 'auto', fontWeight: 'bold', fontSize: '15px' }}
                    onClick={() => this.exportBackup('Backup')}
                    className="btn my-cusomized-button button2">
                    {`${language[this.props.currentLanguage].Download} Backup`}
                  </Button>
                  {this.props.Role === 'superAdmin' && 
                  <Button style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', width: 'auto', fontWeight: 'bold', fontSize: '15px' }}
                    onClick={() => this.exportBackup('Package')}
                    className="btn my-cusomized-button button2">
                    {`${language[this.props.currentLanguage].Download} Package`}
                  </Button>}
                  {(this.props.Role === 'superAdmin' || this.props.Role === 'admin') && 
                  <Button style={{ textAlign: 'center', backgroundColor: 'white', color: 'black', width: 'auto', fontWeight: 'bold', fontSize: '15px' }}
                    onClick={() => this.CallProblemList()}
                    className="btn my-cusomized-button button2">
                    {`检查运行单`}
                  </Button>}
                </>}
              </div>
            </div>
          </div>
        </div>
        {this.state.DisplayPakege === true && 
        <Form className="dcc" style={{ marginBottom: 20 }}>
          <Form.Group className="dcc" style={{ width: '80%', border: '2px solid #d8d8d8', borderRadius: 5, flexDirection: 'column', backgroundColor: 'white', padding: 10 }}>
            <div className='dcc' style={{ flexDirection: 'column', width: '100%'}}>
              <div style={{ fontWeight: 'bold', color: (this.state.getPackagename.length === 0) ? 'red' : 'black'}}>{(this.state.getPackagename.length === 0) ? `${language[this.props.currentLanguage].NoPackageFound}` :`${language[this.props.currentLanguage].CurrentexistPackage}`}</div>
              <div className='dcc row' style={{ width: '100%' }}>
                {this.state.getPackagename && this.state.getPackagename.map((item) => {
                  return <Button style={{ marginBottom: 5, justifyContent: 'center', margin: 5 }}
                    variant={(this.state.SelectedPackageID === item.PackageID ) ? 'info' : 'outline-dark'}
                    onClick={() => this.OpenDisplayPack(item.PackageID)}>
                    <div>{item.Name}</div>
                  </Button>
                })}
              </div>
              <div style={{ width: '100%' }}>
                {this.state.Run === true && 
              <div style={{ width: '100%' }}>
                <DisplayPackage style={{ width: '100%' }} SelectedPackageID={this.state.SelectedPackageID} targetUserID={this.props.userID} />
              </div>
                }
              </div>
            </div>
          </Form.Group>
        </Form>
        }
        {this.state.SpecialUsers.length > 0 &&
        <div className='dcc' style={{ width: '100%', marginBottom: 30 }}>
          <div style={{ width: '82%', display: 'flex', flexDirection: 'column', backgroundColor: '#ADD8E6', borderRadius: 5}}>
            <div style={{ width: '100%', color: 'red', fontWeight: 'bold', padding: 15 }}>{'Special Users'}</div>
            <div className="dcc" style={{ width: '100%', flexWrap: 'wrap'}}>
              {this.state.SpecialUsers.map((items, index) => {
                return(
                  <div key={index} style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', paddingLeft: 15, paddingRight: 15, paddingBottom: 10, width: '20%' }}>
                    <div style={{ color: 'green', fontWeight: 'bold', border: '1px solid #d8d8d8', width: '100%', backgroundColor: 'white' }}>{items.Username}</div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                      <div className='HomeSpecialUsertext'>{language[this.props.currentLanguage].OverLimitHome}</div>
                      <div className='HomeSpecialUsertext'>{language[this.props.currentLanguage].Over7Home}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                      <div style={{ textAlign: 'center', width: '50%' }}>{(items.IgnoreOrderLimit === 1) ? '✅' : '❌'}</div>
                      <div style={{ textAlign: 'center', width: '50%' }}>{(items.AbleOrderAfterSeven === 1) ? '✅' : '❌'}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        }
        <div className='dcc' style={{width: '100%', marginBottom: 20 }}>
          <div style={{ width: '82%', display: 'flex', flexDirection: 'row'}}>
            <div className="HomePageTitleSize" style={{ width: '59%', marginRight: 5, backgroundColor: '#FCEEA7', padding: 15, alignItems: 'center', display: 'flex' }}>
              <div style={{ color: 'black', fontWeight: 'bold', padding: 2, fontFamily: 'cursive', textAlign: 'left', width: '50%', display: 'flex', fontSize: '17px'}}>
                <div>{language[this.props.currentLanguage].ClickTodownload}</div>
                <div style={{ fontWeight: 'bold', cursor: 'pointer'}} onClick={()=> window.open("https://s.i82.biz/mogu.apk")}>{'MOGU'}</div>
              </div>
              <div style={{ width: '40%'}}>
                <img src={Androidlogo} alt="img"
                  onClick={()=> window.open("https://s.i82.biz/mogu.apk")}
                  style={{
                    cursor: 'pointer',
                    height: 180,
                    width: 200,
                    marginRight: 5,
                  }}
                />
              </div>
            </div>
            <div className='HomePageTitleSize' style={{ width: '40%', backgroundColor: 'black', textAlign: 'right', padding: 15, fontWeight: 'bold' }}>
              <div style={{ display: 'flex', flexDirection: 'row', color: 'white'}}>
                <div className='dcc' style={{ width: '40%'}}>
                  <img src={LogoMogu} alt="img"
                    style={{
                      height: 190,
                      width: 300,
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', color: 'white', width: '57%', padding: 10 }}>
                  <div style={{ color: 'lightblue', fontFamily: 'cursive', marginBottom: 25 }}>
                    {`Here is our supporting Ace4D Website.`}
                  </div>
                  <div style={{ fontFamily: 'cursive', cursor: 'pointer', fontSize: '24px' }} onClick={()=> window.open("https://ace4d.live/")}>{'https://ace4d.live/'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.DownFightingModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
            <div style={{ width: '100%'}}>
              <Form className="dcc" style={{ marginBottom: 10 }}>
                <Form.Group className="column" style={{ width: '80%',  backgroundColor: 'white' }}>
                  <Card.Title className='dcc' style={{ padding: 15, backgroundColor: '#003986', color: 'white'}}><b> {`${language[this.props.currentLanguage].Download}${language[this.props.currentLanguage].Fighting}`}</b></Card.Title>
                  <div className="row dcc" style={{ color: 'black', fontWeight: 'bold', fontSize: '16px', paddingBottom: 10,paddingTop: 10, paddingLeft: 20, alignItems: 'center'}}>
                    <div style={{ width: '15%', textAlign: 'start' }}>{language[this.props.currentLanguage].PleaseSelectedDate}:  </div>
                    <div className="dcc" style={{ width: '70%'}}>
                      {/* <Form.Label style={{ width: '40%' , fontWeight: 'bold' }}>{this.props.username}</Form.Label> */}
                      <DatePicker
                        onChange={(date) => this.setSelectedDate(date, 'SelectedFightingDate')}
                        placeholder="Date"
                        // maxDate={new Date()}
                        selected={this.state.SelectedFightingDate}
                        value={this.state.SelectedFightingDate}
                        className={"form-control StyleDatePicker"}
                        // withPortal
                      />
                    </div>
                  </div>
                  <div className="dcc" style={{ marginBottom: '2%'}}>
                    <Button style={{ margin: 5, width: '20%'}} variant="success" onClick={() => this.DownloadFightingBackup()}>{`${language[this.props.currentLanguage].Download}${language[this.props.currentLanguage].Fighting}`}</Button>
                    <Button style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.setState({ DownFightingModal: !this.state.DownFightingModal})}>{language[this.props.currentLanguage].Close}</Button>
                  </div> 
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          // style={{ width: '100%'}}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.ChangePasswordModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto', }}>
            <div style={{ width: '100%'}}>
              <Form className="dcc" style={{ marginBottom: 10 }}>
                <Form.Group className="column" style={{ width: '80%',  backgroundColor: 'white' }}>
                  <Alert style={{ width: '100%'}} show={this.state.showNotification}
                    variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                    <Alert.Heading className="dcc" style={{ fontSize: 20}}><b>{this.state.notificationMessage}</b></Alert.Heading>
                  </Alert>
                  <Card.Title className='dcc' style={{ padding: 15, backgroundColor: '#003986', color: 'white'}}><b> {language[this.props.currentLanguage].FirstTimeChangePassword}</b></Card.Title>
                  <div className="row dcc" style={{ color: 'black', fontWeight: 'bold', fontSize: '20px', paddingBottom: 10,paddingTop: 10, paddingLeft: 20, alignItems: 'center'}}>
                    <div style={{ width: '15%', textAlign: 'start' }}>{language[this.props.currentLanguage].usernameLanguage}:  </div>
                    <div className="dcc" style={{ width: '70%'}}>
                      <Form.Label style={{ width: '40%' , fontWeight: 'bold' }}>{this.props.username}</Form.Label>
                    </div>
                  </div>
                  <div className="row dcc" style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', paddingBottom: 10, paddingLeft: 20, alignItems: 'center'}}>
                    <div style={{ width: '15%', textAlign: 'start' }}>{language[this.props.currentLanguage].CurrentPassword}:  </div>
                    <div className="dcc" style={{ width: '70%', flexDirection: 'row', flexWrap: 'wrap'}}>
                      <Form.Control
                        className="LoginInputText"
                        type={(this.state.CShowPassword === true) ? "Text" : "Password"}
                        value={this.state.CPassword}
                        onChange={(e) => this.handleChange(e.target.value, 'CPassword')}
                        placeholder={language[this.props.currentLanguage].CurrentPassword}
                        // onKeyPress={(e) => this.onKeyEnter(e)}
                        style={{ fontWeight: 'bold', height: '30%', width: '80%'}}
                      />
                      {this.state.CShowPassword === false
                        ? <FaEyeSlash style={{ textAlign: 'center', width: '10%',marginLeft: 5  }} size={"25px"} color="black" onClick={()=> this.setState({CShowPassword: true })}/>
                        : <FaEye style={{ textAlign: 'center', width: '10%' }} size="25px" color="black" onClick={()=> this.setState({CShowPassword: false })}/> 
                      }
                    </div>
                  </div>
                  <div className="row dcc" style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', paddingBottom: 10, paddingLeft: 20, alignItems: 'center'}}>
                    <div style={{ width: '15%', textAlign: 'start' }}>{language[this.props.currentLanguage].NewPassword}:  </div>
                    <div className="dcc" style={{ width: '70%', flexDirection: 'row', flexWrap: 'wrap'}}>
                      <Form.Control
                        className="LoginInputText"
                        type={(this.state.ShowPassword === true) ? "Text" : "Password"}
                        value={this.state.NPassword}
                        onChange={(e) => this.handleChange(e.target.value, 'NPassword')}
                        placeholder={language[this.props.currentLanguage].NewPassword}
                        // onKeyPress={(e) => this.onKeyEnter(e)}
                        style={{ fontWeight: 'bold', height: '30%', width: '80%'}}
                      />
                      {this.state.ShowPassword === false
                        ? <FaEyeSlash style={{ textAlign: 'center', width: '10%',marginLeft: 5  }} size={"25px"} color="black" onClick={()=> this.setState({ShowPassword: true })}/>
                        : <FaEye style={{ textAlign: 'center', width: '10%' }} size="25px" color="black" onClick={()=> this.setState({ShowPassword: false })}/> 
                      }
                    </div>
                  </div>
                  <div className="row dcc" style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', paddingBottom: 10, paddingLeft: 20, alignItems: 'center'}}>
                    <div style={{ width: '15%', textAlign: 'start' }}>{language[this.props.currentLanguage].RepeatPassword}:  </div>
                    <div className="dcc" style={{ width: '70%', flexDirection: 'row', flexWrap: 'wrap'}}>
                      <Form.Control
                        className="LoginInputText"
                        type={(this.state.RShowPassword === true) ? "Text" : "Password"}
                        value={this.state.RPassword}
                        onChange={(e) => this.handleChange(e.target.value, 'RPassword')}
                        placeholder={language[this.props.currentLanguage].RepeatPassword}
                        // onKeyPress={(e) => this.onKeyEnter(e)}
                        style={{ fontWeight: 'bold', height: '30%', width: '80%'}}
                      />
                      {this.state.RShowPassword === false
                        ? <FaEyeSlash style={{ textAlign: 'center', width: '10%',marginLeft: 5  }} size={"25px"} color="black" onClick={()=> this.setState({RShowPassword: true })}/>
                        : <FaEye style={{ textAlign: 'center', width: '10%' }} size="25px" color="black" onClick={()=> this.setState({RShowPassword: false })}/> 
                      }
                    </div>
                  </div>
                  <div className="dcc" style={{ marginBottom: '2%'}}>
                    <Button style={{ margin: 5, width: '20%'}} variant="success" onClick={() => this.ChangePassword()}>{language[this.props.currentLanguage].Submit}</Button>
                  </div> 
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.AdvertisementModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 0, height: 'auto', width: 'auto', }}>
            <div style={{ width: '100%', }}>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top: 15, cursor: 'pointer', backgroundColor: this.state.backgroundclose, borderRadius: 20 }}
                onClick={() => {
                  this.setState({ AdvertisementModal: false,ImageNews: '' })
                }}
                onMouseEnter={() => {
                  this.setState({ backgroundclose: 'white' });
                }}
                onMouseLeave={() => {
                  this.setState({ backgroundclose: '#cfd9de' });
                }}
                size="35px"
                color="#000"
              />
              <Form.Group className="column" >
                <div className='dcc' style={{ padding: 15, backgroundColor: '#003986', color: 'white', fontSize: 18, borderTopLeftRadius: 5, borderTopRightRadius: 5, marginTop: 10}}><b> {language[this.props.currentLanguage].News}</b></div>
                <div className='dcc' style={{ flexDirection: 'row', flexWrap: 'wrap',  border: '1px solid #cfd9de',}}>
                  <div className='containers' style={{ flexDirection: 'column', borderRight: '1px solid #cfd9de', flexWrap: 'wrap', width: '30%',  overflowY: 'auto',}}>
                    {this.state.NewOption.map((item, index)=>{
                      return<div className="dcc button" key={index} 
                        style={{ backgroundColor: this.state.background, fontSize: 20,
                          borderBottom: '1px solid #cfd9de', verticalAlign: 'middle', fontWeight: 'bold', padding: 13, cursor: 'pointer'}} 
                        onMouseEnter={() => {
                          this.setState({ background: '#e28743' });
                        }}
                        onMouseLeave={() => {
                          this.setState({ background: '#eb5f5f' });
                        }}
                        variant="success" onClick={() => this.setState({ ImageNews: index})}>
                        <span>
                          {Moment(item.DateTime).format('DD/MM/YYYY')}
                        </span>
                      </div>
                      // return<Button key={item.PackageID} style={{ marginBottom: 5, display: 'flex', padding: 13, justifyContent: 'center', width: '100%'}}>{item.Information}</Button>
                    })
                    }
                  </div>
                  <div style={{ flexDirection: 'column', flexWrap: 'wrap',width: '70%', overflowY: 'auto' }}>
                    <div className='dcc' style={{marginBottom: '5%', }}>
                      {(this.state.NewOption && this.state.NewOption[this.state.ImageNews] && this.state.NewOption[this.state.ImageNews].Image)&&
                      <div className='dcc' style={{ height: '300px', width: '300px'}}>
                        <img 
                          src={this.state.NewOption[this.state.ImageNews].Image}
                          alt="new"
                          style={{  maxHeight: '100%', maxWidth: '100%', objectFit: 'contain'}}
                        />
                      </div>}
                    </div> 
                    {(this.state.ImageNews !== '')&&<div style={{  flexDirection: 'row', height: 'auto', marginBottom: '5%', }}>
                      <div style={{ width: '100%', padding: 2, textAlign: 'center', fontWeight: 'bold' }}>
                        {this.state.NewOption && this.state.NewOption[this.state.ImageNews] && this.state.NewOption[this.state.ImageNews].Information}
                      </div>
                    </div>}
                  </div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
        </Modal>
        <div className="darkMode-wrap button2" style={{ fontSize: 50 }} 
          onClick={() => this.setState({AdvertisementModal: true})}
        >
          ✉
          <div style={{ position: 'absolute', bottom: 35, left: 50, fontSize: 25,
            backgroundColor: 'red', width: 40, borderRadius: 40, fontWeight: 'bold' }}>
            {this.state.NewOption.length}
          </div>
        </div>
        {/* {(this.state.PopScrollBackButton)&&<div class="darkMode2-wrap button2" style={{ fontWeight: 'bold',fontSize: 40, width: 80, padding: 10 }} 
          onClick={this.elScroll.executeScroll}
        >
          ^
        </div>} */}
        {(this.state.PopScrollBackButton)&&
        <div className={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div className="icon" style={{ fontSize: 25, fontWeight: 'bold', cursor: 'pointer' }}>
          ^
          </div>
        </div>}
      </div>
    );
  }
}
// export default Home;

function mapStateToProps(state) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
