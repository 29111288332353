import '../App.css';
import React from 'react';
import { connect } from 'react-redux'
import { language } from '../language';
import Form from 'react-bootstrap/Form'
import { Button, Spinner,Alert } from 'react-bootstrap'
import { HolyWater } from '../utility/CountHolyWater'
import { Platformcolor } from '../static/PlatformColor';
import { GamePlatformNumb1, GamePlatformNumb2 } from '../static';
// api getPackageDetails
const { activePackage, deactivatePackage, updatePackage } = require('../Api');
var timer

class HR_Package extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Games: [],
      OriginalGames: [],
      ParentCommission: {},
      ParentOdd: {},
      Commission: {},
      Odd: {},
      OriCommission: {},
      OriOdd: {},
      all: '',
      loading: true,
      activated: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      // CountCommissionResultOdd: {},
      CountCommissionResult: {},
      ShowSetup: false,
      SetGameValue: {
        B: '',
        S: '',
        A1: '',
        A1C: '',
        A: '',
        C: '',
        A2: '',
        A3: '',
        D4: '',
        E4: '',
      },
      SelectedGame: [],
      TotalResultGame: 0,
      GamePlatform: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      GDisplay: '',
      GameName: '',
    }
    this.UploadFiletxt = this.UploadFiletxt.bind(this);
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.fileInputRef = React.createRef();
  }

  async componentDidMount() {
    const GDisplay = localStorage.getItem('GameDisplay');
    const PackgeOC = this.props.NewPackage;
    if (Object.keys(PackgeOC).length > 0) {
      const CommissionJSON = JSON.parse(JSON.stringify(PackgeOC.Commission))
      
      const OddJSON = JSON.parse(JSON.stringify(PackgeOC.Odd))
      const result = Object.keys(CommissionJSON)
      const resultF = result.sort((a, b) => a.localeCompare(b))
      if (result.includes('Default')) {
        resultF.unshift('Default')
        resultF.pop()
      }
      this.setState({OriginalGames: JSON.parse(JSON.stringify(resultF)), Games: JSON.parse(JSON.stringify(resultF)), 
        loading: false, GDisplay, OriCommission:  JSON.parse(JSON.stringify(CommissionJSON)), 
        Commission: CommissionJSON , OriOdd: JSON.parse(JSON.stringify(OddJSON)), Odd: OddJSON ,
        CountCommissionResult: JSON.parse(JSON.stringify(CommissionJSON))}, ()=>{
        this.RunHolyWaterGame();
      })
    }
  }

  RunHolyWaterGame(){
    const KeyArrayGame = Object.keys(this.state.Commission);
    const FieldKeyArrayGame = Object.keys(this.state.Commission.Default);
    const HolyArray = {
      Commision: this.state.Commission,
      Odd: this.state.Odd,
      Games: KeyArrayGame,
      Fields: FieldKeyArrayGame,
    }
    
    const HolyResult = HolyWater(HolyArray);
    if (HolyResult){
      this.setState({ CountCommissionResult: JSON.parse(JSON.stringify(HolyResult))})
      // let TotalResult = 0;
      // const Keys = Object.keys(this.state.CountCommissionResult.Default);
      // KeyArrayGame.forEach((GamesKey) =>{
      //   Keys.forEach((ItemKey) =>{
      //     TotalResult += Number(this.state.CountCommissionResult[GamesKey][ItemKey])
      //   })
      // })
      // this.setState({TotalResultGame: TotalResult})
    }
  }

  commissionChange(game, subfield, value) {
    const stateObject = this.state.Commission

    
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    // const e = value
    //   .replace(/\D+/g, '.') // replace non digit with comma
    //   .replace(/^,|,$/g, '') // trim the comma
    
    // var output = e.split('.');
    // output = output.shift() + (output.length ? '.' + output.join('') : '');

    // const value = e.target.value
    
    const evt = value.replace(/[^0-9.]/g, '')
    
    var output = evt.split('.');

    if(output[0].length > 2){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].waternotmore2)
      });
    } else {
      output = output.shift() + (output.length ? '.' + output.join('') : '');
      if(subfield === 'SA'){
        stateObject[game]['SB'] = output
        stateObject[game]['SC'] = output
        stateObject[game]['SD'] = output
        stateObject[game]['SE'] = output
        stateObject[game]['SF'] = output
      } else if (subfield === 'CA'){
        stateObject[game]['CB'] = output
        stateObject[game]['CC'] = output
        stateObject[game]['CD'] = output
        stateObject[game]['CE'] = output
      }else if (subfield === 'PA'){
        stateObject[game]['PB'] = output
        stateObject[game]['PC'] = output
        stateObject[game]['PD'] = output
        stateObject[game]['PE'] = output
        stateObject[game]['PF'] = output
      }
      
      stateObject[game][subfield] = output
      this.setState({ Commission: JSON.parse(JSON.stringify(stateObject))}, ()=>{
        this.RunHolyWaterGame();
      })
    }
  }

  oddChange(game, field, subfield, e) {
    const value = e.target.value
    
    const evt = value.replace(/[^0-9.]/g, '')
    
    var output = evt.split('.');
 

    const stateObject = this.state.Odd
    const commision = this.state.Commission
    let checkvalue = false
    let ErrorMessage = '';

    let errorfield = ( subfield !== 'SF' &&(field === '1ST' || subfield === '5D1' || subfield === '6D' || subfield === 'CA' ||   subfield === 'PA'  || subfield === 'CF1') && subfield !== 'AC') ? '奖金 1' : 
      (field === '2ND' || subfield === '5D2' || subfield === '6D2' || subfield === 'CB' ||   subfield === 'PB'  || subfield === 'CF2') ? '奖金 2' : 
        (field === '3RD' || subfield === '5D3' || subfield === '6D3' || subfield === 'CC' ||   subfield === 'PC'  || subfield === 'CF3') ? '奖金 3' :
          (field === '4TH' || subfield === '5D4' || subfield === '6D4' || subfield === 'CD' ||   subfield === 'PD') ? '特别' : 
            (field === '5TH' || subfield === '5D5' || subfield === '6D5' || subfield === 'CE' ||   subfield === 'PE') ? '安慰奖' : '奖金 6';

    let errorsubfield = subfield.includes('B') ? '大' : subfield !== 'SA' && subfield !== 'SF' && subfield.includes('S') ? '小' : 
      ((subfield.includes('A')) && (subfield !== 'PD' && subfield !== 'PC' && subfield !== 'PB' && subfield !== 'PA' && subfield !== 'CA' && subfield !== 'CB' && subfield !== 'CC')) ? '单A' :
        (subfield !== 'CF1' && subfield !== 'CF2' && subfield !== 'CF3' && subfield !== 'PC' && subfield.includes('C')) ? '干字(A)' : subfield.includes('CF') ? 'CF(ABC)' : subfield.includes('P') ? '百字' : 
          (subfield !== '6D5' && subfield.includes('5')) ? '5D' : 
            subfield.includes('6') ? '6D' : subfield === 'SF' ? '单A' : subfield;

    if((subfield === 'B' || subfield === 'S' || subfield === 'SA' || subfield === 'SB' || subfield === 'SC'
        || subfield === '2B'  || subfield === '2S' || subfield === '3B' || subfield === '3S' || subfield === '4B'
        || subfield === 'SD' || subfield === '5B' || subfield === '5D2' || subfield === '5D3' || subfield === '6D2')){
      if(output[0].length > 4){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan4}\n\n`
      } else {
        checkvalue = true
      }
    }
    if((subfield === 'A' || subfield === 'SE' || subfield === 'SF' ||
      subfield === 'CA' || subfield === 'CB' || subfield === 'CC' || 
      subfield === '5D4' || subfield === '6D3' || subfield === 'CD' )){
      if(output[0].length > 3){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan3}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '6D'){
      if(output[0].length > 6){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan6}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D1'){
      if(output[0].length > 5){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan5}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D5' || subfield === '6D4' || subfield === 'CE' || subfield === 'CF'
    || subfield === 'PC' || subfield === 'PA' || subfield === 'PB' || subfield === 'PF'){
      if(output[0].length > 2){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan2}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D6' || subfield === '6D5' || subfield === 'PD' || subfield === 'PE'){
      if(output[0].length > 1){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan1}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === 'CF1' || subfield === 'CF2' || subfield === 'CF3' ) {
      if(output[0].length > 3){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan1}\n\n`
      } else {
        checkvalue = true
      }
    }

 
    output = output.shift() + (output.length ? '.' + output.join('') : '');
    
    const Math = value.replace(/[^0-9+-]/g, '')
    // var outputs = Math.split('+');
    // var temp = "This is a string+--.";
    var count = (Math.match(/[+-]/g) || []).length;

    if(checkvalue === true){
      if ( field === "P"){
        stateObject[game][subfield] = output
        stateObject[game]['PB'] = output
        stateObject[game]['PC'] = output
        stateObject[game]['PD'] = (output/10).toFixed(2)
        stateObject[game]['PE'] = (output/10).toFixed(2)
        stateObject[game]['PF'] = (output/3).toFixed(2)
      }  else if ( subfield === 'PF'){
        stateObject[game][subfield] = output
      }  else if ( subfield === "CF1"){
        stateObject[game][subfield] = output
        stateObject[game]['CF2'] = output
        stateObject[game]['CF3'] = output
      } 
      else if ( subfield === "CF2"){
        stateObject[game]['CF2'] = output
      } 
      else if ( subfield === "CF3"){
        stateObject[game]['CF3'] = output
      } 
      else if ( stateObject[game][field] === undefined){
        if (count > 1){
          this.openNotification(`${language[this.props.currentLanguage].Onlyaddoneplusorminus}`)
        } else {
          stateObject[game][field] = { Amount: Math }
        }
      } else if ( subfield === "SA"){
        stateObject[game][field][subfield] = output
        stateObject[game]['2ND']['SB'] = output
        stateObject[game]['3RD']['SC'] = output
        stateObject[game]['4TH']['SD'] = (output/10).toFixed(2)
        stateObject[game]['5TH']['SE'] = (output/10).toFixed(2)
        stateObject[game]['1ST']['SF'] = (output/3).toFixed(2)
      } else if ( subfield === "SD"){
        stateObject[game][field]['SD'] = output
      } else if ( subfield === "PC"){
        stateObject[game][field]['PC'] = output
      }  else if ( subfield === "CA"){
        stateObject[game][subfield] = output
        stateObject[game]['CB'] = output
        stateObject[game]['CC'] = output
        stateObject[game]['CD'] = (output/10).toFixed(2)
        stateObject[game]['CE'] = (output/10).toFixed(2)
      } else {
        stateObject[game][field][subfield] = output
      }
      
      this.setState({ Odd: JSON.parse(JSON.stringify(stateObject)), Commision: JSON.parse(JSON.stringify(commision))}, ()=>{
        this.RunHolyWaterGame();
      })
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${ErrorMessage}`)
      });
    }

    // else if ( field === "Water"){
    //   if (count > 1){
    //     this.openNotification(`${language[this.props.currentLanguage].Onlyaddoneplusorminus}`)
    //   } else {
    //     stateObject[game][field] = { Amount: Math }
    //   }
    // } 
    // else if( field === 'Setup') {
    //   stateObject[game][field] = { Amount: output }
    //   const Number = stateObject[game][field].Amount
    //   const Arrayfield = ['1ST', '2ND', '3RD', '4TH', '5D', '5TH', '6D']
    //   Arrayfield.forEach((item) => {
    //     for( var a = 0; a < Object.keys(stateObject[game][item]).length; a ++) {
    //       stateObject[game][item][Object.keys(stateObject[game][item])[a]] = Number
    //     }
    //   })

    //   const ArrayCommision = ['B', 'S', 'A1', 'AC', 'A', 'C', 'A2', 'A3', 'D4', 'E4', '5D', '6D']
    //   ArrayCommision.forEach((item) => {
    //     commision[game][item] = Number
    //   })
    // } 
  }

  async KeyChange(game, field, e) {
    const stateObject = this.state.Odd
    const Commisions = this.state.Commission
    const Amount =  (stateObject[game][field] && stateObject[game][field].Amount) || ''
    if(e.key === 'Enter') {
      if (Amount.includes('-')) {
        const Number = Amount.substring(Amount.indexOf('-') + 1)
        // const Arrayfield = ['1ST', '2ND', '3RD', '4TH', '5D', '5TH', '6D']
        // Arrayfield.forEach((item) => {
        //   for( var a = 0; a < Object.keys(stateObject[game][item]).length; a ++) {
        //     stateObject[game][item][Object.keys(stateObject[game][item])[a]] 
        //     = (Object.values(stateObject[game][item])[a] > 0) ? Object.values(stateObject[game][item])[a] - Number : 0
        //   }
        // })
        const ArrayCommision = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
        ArrayCommision.forEach((item) => {
          Commisions[game][item] = (Commisions[game][item] > 0) ? Commisions[game][item] - Number : 0
        })
      } else {
        if(Amount === '') {
          // ignore
        } else {
          const Number = Amount
          // const Arrayfield = ['1ST', '2ND', '3RD', '4TH', '5D', '5TH', '6D']
          // Arrayfield.forEach((item) => {
          //   for( var a = 0; a < Object.keys(stateObject[game][item]).length; a ++) {
          //     stateObject[game][item][Object.keys(stateObject[game][item])[a]] = parseInt((Object.values(stateObject[game][item])[a] || 0)) + parseInt(Number)
          //   }
          // })
          const ArrayCommision = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
          ArrayCommision.forEach((item) => {
            Commisions[game][item] = (parseInt(Commisions[game][item])) + parseInt(Number)
          })
        }
      }
      // Odd: stateObject,
      this.setState({ Commision: Commisions }, ()=>{
        if (field === 'Water'){
          // const KeyArrayGame = Object.keys(this.state.Commission);
          // const FieldKeyArrayGame = Object.keys(this.state.Commission.Default);
          // const HolyArray = {
          //   Commision: this.state.Commission,
          //   Odd: this.state.Odd,
          //   Games: KeyArrayGame,
          //   Fields: FieldKeyArrayGame,
          // }
          // const HolyResult = HolyWater(HolyArray);
          // if (HolyResult){
          //   this.setState({ CountCommissionResult: JSON.parse(JSON.stringify(HolyResult))})
          // }
          this.RunHolyWaterGame();
        }
      })
    }
  }

  resetForm() {
    const stateObject = this.state.OriCommission
    const stateOddObject = this.state.OriOdd
    const keys = Object.keys(stateObject)
    for (let i = 0; i < keys.length; i += 1) {
      stateObject[keys[i]]['all'] = ''
      stateOddObject[keys[i]]['CF1'] = ''
      stateOddObject[keys[i]]['CF2'] = ''
      stateOddObject[keys[i]]['CF3'] = ''
    }
    this.setState({ 
      Commission: JSON.parse(JSON.stringify(stateObject)),
      Odd: JSON.parse(JSON.stringify(stateOddObject)),
      Games: JSON.parse(JSON.stringify(this.state.OriginalGames)),
    }, () => {
      this.RunHolyWaterGame();
    })
  }

  commissionAllChange(game, value) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value
      .replace(/\D+/g, '.') // replace non digit with comma
      .replace(/^,|,$/g, '') // trim the comma
     
    var output = e.split('.');
    // output = output.shift() + (output.length ? '.' + output.join('') : '');
    if(output[0].length > 2){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].waternotmore2)
      });
    } else {
      output = output.shift() + (output.length ? '.' + output.join('') : '');
      const stateObject = this.state.Commission
      const keys = Object.keys(stateObject[game])
      for (let i = 0; i < keys.length; i += 1) {
        stateObject[game][keys[i]] = output
      }
      // stateObject[game]['AC'] = (Number(output) /3).toFixed(2)
      // stateObject[game]['AD'] = (Number(output)/10).toFixed(2)
      // stateObject[game]['AE'] = (Number(output)/10).toFixed(2)
      // stateObject[game]['CC'] = (Number(output) /3).toFixed(2)
      // stateObject[game]['CD'] = (Number(output)/10).toFixed(2)
      // stateObject[game]['CE'] = (Number(output)/10).toFixed(2)
      stateObject[game]['all'] = output
      this.setState({ Commission: JSON.parse(JSON.stringify(stateObject))}, ()=>{
        this.RunHolyWaterGame();
      })
    }
  }

  async activatePackage() {
    this.setState({ loading: true })
    const activePackageResult = await activePackage(this.props.username, this.props.token, this.props.packageDetails.PackageID, this.props.targetUserID)
    if (activePackageResult.message === '成功更新') {
      this.props.resetPackages()
      this.setState({ loading: false })
    }
  }

  async deactivatePackage() {
    this.setState({ loading: true })
    const activePackageResult = await deactivatePackage(this.props.username, this.props.token, this.props.packageDetails.childPackage, this.props.targetUserID)
    if (activePackageResult.message === '成功更新') {
      this.props.resetPackages()
      this.setState({ loading: false })
    } else {
      if (activePackageResult.error) {
        this.setState({ loading: false }, ()=>{
          this.props.ActiveNotification(language[this.props.currentLanguage].DownlinegotPackage)
        })
      }
    }
  }
  
  renderBackgroundColor(game) {
    if (game === 'M') return '#fff23e'
    else if ((this.state.GDisplay === 'MPTSWBK') ? game === 'P' : game === 'K') return '#414bb2'
    else if (game === 'T') return '#ff431b'
    else if (game === 'S') return '#32c6f4'
    else if (game === 'W') return 'green'
    else if (game === 'B') return '#fc0404'
    else if ( (this.state.GDisplay === 'MPTSWBK') ? game === 'K' : game === 'D') return '#f8c100'
    else if (game === '8') return '#6d100e'
    else return null
  }

  gameChange(game, boolean, state) {
    let newArray = this.state.Games
    let newCommission = JSON.parse(JSON.stringify(this.state.Commission))
    let newCountCommissionResult = JSON.parse(JSON.stringify(this.state.CountCommissionResult))
    let newOdd = JSON.parse(JSON.stringify(this.state.Odd))
    // let newOddResult = JSON.parse(JSON.stringify(this.state.CountCommissionResultOdd))
    if (this.state.Games.includes(game)) {
      // if(game === '8' && boolean === false && state === 'ButtonFunction') {
      //   const r = (window.confirm(language[this.props.currentLanguage].State8));
      //   if (r === true) {
      //     newArray.splice(this.state.Games.indexOf(game), 1);
      //     delete newCommission[game]
      //     delete newCountCommissionResult[game]
      //     delete newOdd[game]
      //   } else {
      //     return null;
      //   }
      // } else { 
      newArray.splice(this.state.Games.indexOf(game), 1);
      delete newCommission[game]
      delete newCountCommissionResult[game]
      delete newOdd[game]
      // }
      // delete newOddResult[game]
    } else {
      newArray.push(game);
      newCommission[game] = newCommission.Default
      newCountCommissionResult[game] = newCountCommissionResult.Default
      newOdd[game] = newOdd.Default
      // newOddResult[game] = newOddResult.Default
    }
    const defaultArray =  ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const arrangedArray = newArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    // CountCommissionResultOdd: JSON.parse(JSON.stringify(newOddResult))
    this.setState({ Games: JSON.parse(JSON.stringify(arrangedArray)), Commission: JSON.parse(JSON.stringify(newCommission)), Odd: JSON.parse(JSON.stringify(newOdd)),  CountCommissionResult: JSON.parse(JSON.stringify(newCountCommissionResult))})
  }

  async saveOcPackage() {
    let ODD = JSON.parse(JSON.stringify(this.state.Odd))
    let Commissions = JSON.parse(JSON.stringify(this.state.Commission))
    // let MessageOdd = '';
    // let MessageComm = '';
    let MessageArray = '';
    let BooleanMessage = false;
  
    // this.state.Games.forEach((item) =>{
    //   const Arrayfield = ['1ST', '2ND', '3RD', '4TH', '5D', '5TH', '6D']
    //   const GameCommissionsKey = Object.keys(Commissions[item])
    //   if (item){
    //     GameCommissionsKey.forEach((Key) =>{
    //       if ((Number(Commissions[item][Key]) === 0 || Commissions[item][Key] === 0 || Commissions[item][Key] === '' ) && Key !== 'all' ){
    //         MessageOdd+= `${Key},`
    //         BooleanMessage = true
    //       }
    //     })
    
    //     Arrayfield.forEach((Champ) =>{
    //       if ((Object.values(ODD[item][Champ])).includes('0') || ((Object.values(ODD[item][Champ])).includes(0)) || (Object.values(ODD[item][Champ])).includes('') ){
    //         MessageComm += `${Champ},`
    //         BooleanMessage = true
    //       }
    //     })
    //     if ( BooleanMessage === true) {
    //       MessageArray += `${item} 不能接受零数字 水钱 :${((MessageOdd).length > 0 ) ? MessageOdd : 'Empty'} ${language[this.props.currentLanguage].Odds}: ${((MessageComm).length > 0 ) ? MessageComm : 'Empty'}`
    //       MessageOdd = '';
    //       MessageComm = '';
    //     }
    //   }
    // })

    if (this.state.GameName === ''){
      BooleanMessage = true
      MessageArray = `${language[this.props.currentLanguage].PackageNameEmpty}`;
    }

    if ( BooleanMessage === false) {
      const CreateNewOCPackage = {
        PackageID: this.props.NewPackage.PackageID,
        Commission: Commissions,
        Odd: ODD,
        Name: this.state.GameName,
      }
      const SubmitGamePackageResult = await updatePackage( CreateNewOCPackage, this.props.username, this.props.token,)
      if (SubmitGamePackageResult.message === 'Update Successful') {
        this.setState({notificationType: 'success'}, ()=>{
          this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`)
          this.props.RefrehPackage('createPackage');
        })
      } else if (SubmitGamePackageResult.message === 'Package name duplicated!') {
        this.setState({notificationType: 'error'}, ()=>{
          this.openNotification(`${language[this.props.currentLanguage].Duplicatepackagename}`)
        })
      }  else {
        this.setState({notificationType: 'error'}, ()=>{
          this.openNotification(`${SubmitGamePackageResult.error}`)
        })
      }
    } else {
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(MessageArray)
        MessageArray = '';
        // MessageOdd = '';
        // MessageComm = '';
        BooleanMessage = false
      })
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success'
      });
    }, 5000);
  };

  async SelectedGameChange(game, e) {
    let SelectedGame = this.state.SelectedGame
    if(e.target.checked) {
      SelectedGame.push(game)
      this.setState({SelectedGame})
    } else {
      const filterGame = SelectedGame.filter(games => games !== game);
      this.setState({SelectedGame: filterGame})
    }
  }

  PlatformChange(platform) {
    if (platform === '1') return 'M'
    else if (platform === '2') return (this.state.GDisplay === 'MKTSWBD') ? 'K': 'P'
    else if (platform === '3') return 'T'
    else if (platform === '4') return 'S'
    else if (platform === '5') return 'W'
    else if (platform === '6') return 'B'
    else if (platform === '7') return (this.state.GDisplay === 'MKTSWBD') ? 'D' : 'K'
    else if (platform === '8') return '8'
    return 'Default'
  }

  async ChangeGameValues(item, e) {
    let GameValues = this.state.SetGameValue
    GameValues[item] = e.target.value
    this.setState({ SetGameValue: GameValues})
  }

  async ConfirmChange() {
    const valuestoChange = this.state.SetGameValue
    const Odd = this.state.Odd
    const Commission = this.state.Commission
    const SelectedGame = this.state.SelectedGame

    for(var i=0; i < Object.keys(valuestoChange).length; i++) {
      if(Object.values(valuestoChange)[i] !== '') {
        const valuekey = Object.keys(valuestoChange)[i]
        const valueObject = Object.values(valuestoChange)[i]
        SelectedGame.forEach((selectgame) => {
          ['1ST', '2ND', '3RD', '4TH', '5TH'].forEach((item) => {
            Odd[selectgame][item][valuekey] = Number(valueObject)
          })
          Commission[selectgame][valuekey] = Number(valueObject)
        })
      }
    }
    this.setState({ Odd, ShowSetup: false, Commission }, ()=> {
      this.RunHolyWaterGame();
    })
  }

  async CreateKey(e, item, game){
    if (e.key === '+') {
      this['Input'+ game +item].focus();
      e.preventDefault();
    }
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.state.disabled) return;
  
    this.setState({ hightlight: true });
  }
  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    if (event !== undefined) {
      event.preventDefault()
      const reader = new FileReader()
      let filetype = ''
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.analyzeTextAndInput(text, filetype)
      };
      if (event.dataTransfer.files) {
        filetype = event.target.files[0].type
        reader.readAsText(event.dataTransfer.files[0])
      //   this.setState({ FileName: files[0].name,hightlight: false })
      }
    }
  }
  
  openFileDialog() {
    if (this.state.disabled) return
    this.fileInputRef.current.click()
  }

  async UploadFiletxt(event) {
    if (event !== undefined) {
      event.preventDefault()
      const reader = new FileReader()
      let filetype = ''
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.analyzeTextAndInput(text, filetype)
      };
      if (event.target.files) {
        filetype = event.target.files[0].type
        reader.readAsText(event.target.files[0])
      } 
    }
  }

  analyzeTextAndInput(text, filetype) {
    let allLineArray = []
    const commissionCopy = JSON.parse(JSON.stringify(this.state.Commission))
    const oddCopy = JSON.parse(JSON.stringify(this.state.Odd))
    if (filetype === 'text/csv') {
      allLineArray = text.split('\n');
      allLineArray = allLineArray.filter((item) => item !== '\r' && item !== '')
      allLineArray = allLineArray.map((item) => {
        const itemSplit = item.split(',')
        return {
          type: itemSplit[0],
          platform: itemSplit[1],
          mode: itemSplit[2],
          value: itemSplit[3]
        }
      })
      for (let i = 0; i < allLineArray.length; i += 1) {
        let platform = allLineArray[i].platform
        if (allLineArray[i].platform === '*') {
          platform = 'Default'
        }
        if (allLineArray[i].type === 'C') {
          if (!commissionCopy[platform]) {
            commissionCopy[platform] = {
              B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
            }
            this.gameChange(platform)
          }
          commissionCopy[platform][allLineArray[i].mode] = allLineArray[i].value.replace('\r', '')
         
          if(allLineArray[i].mode === 'SA'){
            commissionCopy[platform]['SB'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['SC'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['SD'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['SE'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['SF'] = allLineArray[i].value.replace('\r', '')
          } else if (allLineArray[i].mode === 'CA'){
            commissionCopy[platform]['CB'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['CC'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['CD'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['CE'] = allLineArray[i].value.replace('\r', '')
          }else if (allLineArray[i].mode === 'PA'){
            commissionCopy[platform]['PB'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['PC'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['PD'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['PE'] = allLineArray[i].value.replace('\r', '')
            commissionCopy[platform]['PF'] = allLineArray[i].value.replace('\r', '')
          }
          // CA => CB, CC, CD, CE
          // PA => PB, PC, PD, PE, PF
          // SA => SB, SC, SD, SE, SF
        } else if (allLineArray[i].type === 'O') {
          if (!oddCopy[platform]) {
            oddCopy[platform] = {
              '1ST': {SA: "", SF: "", B: "", S: ""},
              '2ND': {SB: "", B: "", S: ""},
              '3RD': {SC: "", B: "", S: ""},
              '4TH': {B: "", SD: ""},
              CA: '',
              CB: '',
              CC: '',
              CD: '',
              CE: '',
              CF1: '',
              CF2: '',
              CF3: '',
              PA: '',
              PB: '',
              PC: '',
              PD: '',
              PE: '',
              PF: '',
              '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
              '5TH': {B: "", SE: ""},
              '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
            }
          }
          if (allLineArray[i].mode === '1B') {
            oddCopy[platform]['1ST'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '1S') {
            oddCopy[platform]['1ST'].S = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SA') {
            oddCopy[platform]['1ST'].SA = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SF') {
            oddCopy[platform]['1ST'].SF = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '2B') {
            oddCopy[platform]['2ND'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '2S') {
            oddCopy[platform]['2ND'].S = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SB') {
            oddCopy[platform]['2ND'].SB = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '3B') {
            oddCopy[platform]['3RD'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '3S') {
            oddCopy[platform]['3RD'].S = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SC') {
            oddCopy[platform]['3RD'].SC = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '4B') {
            oddCopy[platform]['4TH'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SD') {
            oddCopy[platform]['4TH'].SD = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5B') {
            oddCopy[platform]['5TH'].B = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'SE') {
            oddCopy[platform]['5TH'].SE = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PA') {
            oddCopy[platform].PA = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PB') {
            oddCopy[platform].PB = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PC') {
            oddCopy[platform].PC = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PD') {
            oddCopy[platform].PD = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PE') {
            oddCopy[platform].PE = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'PF') {
            oddCopy[platform].PF = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CA') {
            oddCopy[platform].CA = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CB') {
            oddCopy[platform].CB = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CC') {
            oddCopy[platform].CC = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CD') {
            oddCopy[platform].CD = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CE') {
            oddCopy[platform].CE = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CF1') {
            oddCopy[platform].CF1 = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CF2') {
            oddCopy[platform].CF2 = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === 'CF3') {
            oddCopy[platform].CF3 = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D1') {
            oddCopy[platform]['5D']['5D1'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D2') {
            oddCopy[platform]['5D']['5D2'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D3') {
            oddCopy[platform]['5D']['5D3'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D4') {
            oddCopy[platform]['5D']['5D4'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D5') {
            oddCopy[platform]['5D']['5D5'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '5D6') {
            oddCopy[platform]['5D']['5D6'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D1') {
            oddCopy[platform]['6D']['6D'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D2') {
            oddCopy[platform]['6D']['6D2'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D3') {
            oddCopy[platform]['6D']['6D3'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D4') {
            oddCopy[platform]['6D']['6D4'] = allLineArray[i].value.replace('\r', '')
          }
          if (allLineArray[i].mode === '6D5') {
            oddCopy[platform]['6D']['6D5'] = allLineArray[i].value.replace('\r', '')
          }
        }
      }
    } else if (filetype === 'text/plain') {
      const allPart = text.split('===\r\n')
      for (let i = 0; i < allPart.length; i += 1) {
        const allLineArray = allPart[i].split('\n')
        let platform = allLineArray[0].replace('\r', '')
        if (allLineArray[0].replace('\r', '') === '1234567') {
          platform = 'Default'
        }
        let waterText = allLineArray[1].substring(1, allLineArray[1].length)
        let waterTextArray = waterText.split('*')
        if (!commissionCopy[platform]) {
          commissionCopy[platform] = {
            B: "", S: "", SA: "", SB: "", SC: "", SD: "", SE: "", SF: "", CA: "", CB: "", CC: "", CD: "", CE: "", CF: "", PA: "", PB: "", PC: "", PD: "", PE: "", PF: "", '5D': "", '6D': ""
          }
          this.gameChange(platform)
        }
        if (!oddCopy[platform]) {
          oddCopy[platform] = {
            '1ST': {SA: "", SF: "", B: "", S: ""},
            '2ND': {SB: "", B: "", S: ""},
            '3RD': {SC: "", B: "", S: ""},
            '4TH': {B: "", SD: ""},
            CA: '',
            CB: '',
            CC: '',
            CD: '',
            CE: '',
            CF1: '',
            CF2: '',
            CF3: '',
            PA: '',
            PB: '',
            PC: '',
            PD: '',
            PE: '',
            PF: '',
            '5D': {'5D1': "", '5D2': "", '5D3': "", '5D4': "", '5D5': "", '5D6': ""},
            '5TH': {B: "", SE: ""},
            '6D': {'6D': "", '6D2': "", '6D3': "", '6D4': "", '6D5': ""},
          }
        }
        if (waterTextArray.length === 1) {
          const massagedText = waterTextArray[0].replace('\r', '')
          commissionCopy[platform].B = massagedText
          commissionCopy[platform].S = massagedText
          
          commissionCopy[platform].SA = massagedText
          commissionCopy[platform].SB = massagedText
          commissionCopy[platform].SC = massagedText
          commissionCopy[platform].SD = massagedText
          commissionCopy[platform].SE = massagedText
          commissionCopy[platform].SF = massagedText

          commissionCopy[platform].CA = massagedText
          commissionCopy[platform].CB = massagedText
          commissionCopy[platform].CC = massagedText
          commissionCopy[platform].CD = massagedText
          commissionCopy[platform].CE = massagedText
          commissionCopy[platform].CF = massagedText
          
          commissionCopy[platform].PA = massagedText
          commissionCopy[platform].PB = massagedText
          commissionCopy[platform].PC = massagedText
          commissionCopy[platform].PD = massagedText
          commissionCopy[platform].PE = massagedText
          commissionCopy[platform].PF = massagedText

          commissionCopy[platform]['5D'] = massagedText
          commissionCopy[platform]['6D'] = massagedText
        } else {
          commissionCopy[platform].B = waterTextArray[0].replace('\r', '')
          commissionCopy[platform].S = waterTextArray[1].replace('\r', '')

          commissionCopy[platform].SA = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SB = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SC = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SD = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SE = waterTextArray[2].replace('\r', '')
          commissionCopy[platform].SF = waterTextArray[2].replace('\r', '')

          commissionCopy[platform].CA = waterTextArray[3].replace('\r', '')
          commissionCopy[platform].CB = waterTextArray[3].replace('\r', '')
          commissionCopy[platform].CC = waterTextArray[3].replace('\r', '')
          commissionCopy[platform].CD = waterTextArray[3].replace('\r', '')
          commissionCopy[platform].CE = waterTextArray[3].replace('\r', '')

          commissionCopy[platform].CF = waterTextArray[4].replace('\r', '')

          commissionCopy[platform].PA = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PB = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PC = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PD = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PE = waterTextArray[5].replace('\r', '')
          commissionCopy[platform].PF = waterTextArray[5].replace('\r', '')

          commissionCopy[platform]['5D'] = waterTextArray[6].replace('\r', '')
          commissionCopy[platform]['6D'] = waterTextArray[7].replace('\r', '')
        }
        const firstText = allLineArray[2].substring(1, allLineArray[2].length)
        const firstTextArray = firstText.split('*')
        oddCopy[platform]['1ST'].B = firstTextArray[0].replace('\r', '')
        oddCopy[platform]['2ND'].B = firstTextArray[1].replace('\r', '')
        oddCopy[platform]['3RD'].B = firstTextArray[2].replace('\r', '')
        oddCopy[platform]['4TH'].B = firstTextArray[3].replace('\r', '')
        oddCopy[platform]['5TH'].B = firstTextArray[4].replace('\r', '')

        const secondText = allLineArray[3].substring(1, allLineArray[3].length)
        const secondTextArray = secondText.split('*')
        oddCopy[platform]['1ST'].S = secondTextArray[0].replace('\r', '')
        oddCopy[platform]['2ND'].S = secondTextArray[1].replace('\r', '')
        oddCopy[platform]['3RD'].S = secondTextArray[2].replace('\r', '')
        
        const thirdText = allLineArray[4].substring(1, allLineArray[4].length)
        const thirdTextArray = thirdText.split('*')
        oddCopy[platform]['1ST'].SA = thirdTextArray[0].replace('\r', '')
        oddCopy[platform]['2ND'].SB = thirdTextArray[1].replace('\r', '')
        oddCopy[platform]['3RD'].SC = thirdTextArray[2].replace('\r', '')
        oddCopy[platform]['4TH'].SD = thirdTextArray[3].replace('\r', '')
        oddCopy[platform]['5TH'].SE = thirdTextArray[4].replace('\r', '')
        oddCopy[platform]['1ST'].SF = thirdTextArray[5].replace('\r', '')

        const fourthText = allLineArray[5].substring(1, allLineArray[5].length)
        const fourthTextArray = fourthText.split('*')
        oddCopy[platform].CA = fourthTextArray[0].replace('\r', '')
        oddCopy[platform].CB = fourthTextArray[1].replace('\r', '')
        oddCopy[platform].CC = fourthTextArray[2].replace('\r', '')
        oddCopy[platform].CD = fourthTextArray[3].replace('\r', '')
        oddCopy[platform].CE = fourthTextArray[4].replace('\r', '')


        const fifthText = allLineArray[6].substring(1, allLineArray[6].length)
        const fifthTextArray = fifthText.split('*')
        oddCopy[platform].CF1 = fifthTextArray[0].replace('\r', '')
        oddCopy[platform].CF2 = fifthTextArray[1].replace('\r', '')
        oddCopy[platform].CF3 = fifthTextArray[2].replace('\r', '')


        const sixthText = allLineArray[7].substring(1, allLineArray[7].length)
        const sixthArray = sixthText.split('*')
        oddCopy[platform].PA = sixthArray[0].replace('\r', '')
        oddCopy[platform].PB = sixthArray[1].replace('\r', '')
        oddCopy[platform].PC = sixthArray[2].replace('\r', '')
        oddCopy[platform].PD = sixthArray[3].replace('\r', '')
        oddCopy[platform].PE = sixthArray[4].replace('\r', '')
        oddCopy[platform].PF = sixthArray[5].replace('\r', '')

        const fivedText = allLineArray[8].substring(1, allLineArray[8].length)
        const fivedArray = fivedText.split('*')
        oddCopy[platform]['5D']['5D1'] = fivedArray[0].replace('\r', '')
        oddCopy[platform]['5D']['5D2'] = fivedArray[1].replace('\r', '')
        oddCopy[platform]['5D']['5D3'] = fivedArray[2].replace('\r', '')
        oddCopy[platform]['5D']['5D4'] = fivedArray[3].replace('\r', '')
        oddCopy[platform]['5D']['5D5'] = fivedArray[4].replace('\r', '')
        oddCopy[platform]['5D']['5D6'] = fivedArray[5].replace('\r', '')

        const sixdText = allLineArray[9].substring(1, allLineArray[9].length)
        const sixdArray = sixdText.split('*')
        oddCopy[platform]['6D']['6D'] = sixdArray[0].replace('\r', '')
        oddCopy[platform]['6D']['6D2'] = sixdArray[1].replace('\r', '')
        oddCopy[platform]['6D']['6D3'] = sixdArray[2].replace('\r', '')
        oddCopy[platform]['6D']['6D4'] = sixdArray[3].replace('\r', '')
        oddCopy[platform]['6D']['6D5'] = sixdArray[4].replace('\r', '')
      }
    }
    this.setState({ Commission: commissionCopy, Odd: oddCopy})
  }

  render() {
    if (this.state.loading) {
      return (
        <Spinner style={{ margin: 10}} animation="border" />
      )
    }
    return(
      <div >
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert className="dcc" style={{  width: '70%', zIndex: 99, position: 'fixed', top: 100, left: 150}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div style={{ marginLeft: 20, display: 'flex', alignItems: 'center', marginBottom: 10, fontSize: 24, width: '100%'}}>
          <div style={{ marginRight: 10 , width: 220 }}>{language[this.props.currentLanguage].FollowingGameSettingSameAsDefault}</div>
          {this.state.GamePlatform.map((item) => {
            return <Form.Check
              style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
              inline
              label={(this.state.GDisplay === 'MPTSWBK') ? GamePlatformNumb2[item] :  GamePlatformNumb1[item]}
              type="checkbox"
              checked={!this.state.Games.includes(item)}
              onChange={(e) => this.gameChange(item,  !this.state.Games.includes(item), 'ButtonFunction')}
            />
          })}
          <div 
            className={`DropzonePackage ${this.state.hightlight ? "Highlight" : ""}`}
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
            onClick={this.openFileDialog}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <span>Upload Files</span>
          </div>
          <input
            key={this.state.FileName}
            onChange={(event) => {
              this.UploadFiletxt(event)
            }}
            onClick={(event) => event.target.value = ''}
            multiple={false}
            ref={this.fileInputRef}
            type='file'
            hidden
          />
              
        </div>
        {this.state.Games.map((game) => {
          return <div
            style={{  backgroundColor: Platformcolor(game, ''), width: '', borderRadius: 10, paddingTop: 10,  color: ((game === '3') ? '#fff'  : (game === '8') ? '#fff' : '#000'), paddingBottom: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '1000px', fontSize: 20, justifyContent: 'center', marginBottom: 10}}>
              {(game === 'Default') && <div className='row'>
                <div style={{ width: 'auto', fontWeight: 'bold', paddingRight: 10}}>{language[this.props.currentLanguage].Package} :</div>
                <Form.Control style={{ width: (JSON.stringify(this.state.GameName).length > 10) ?  60 + JSON.stringify(this.state.GameName).length * 8 : 150 , textAlign: 'center'}} 
                  type="text" value={this.state.GameName}
                  onKeyPress={(e) => this.CreateKey(e, 'All', game)}
                  ref={(input) => { this[`Input${game}Name`] = input;}}
                  onChange={(e) => this.setState({ GameName: e.target.value})}/>
              </div>}
              <div style={{ width: 150, fontWeight: 'bold'}}>ID: {this.props.NewPackage.PackageID}</div>
              <div style={{ marginLeft: 20, width: 150, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Games}: {this.PlatformChange(game)}</div>
            </div>
            <div  style={{ display: 'flex',  width:'100%'}}>
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                <div style={{ width: 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                {([ `${language[this.props.currentLanguage].Simplicity}`, '大', '小', '单A', '干字(A)', 'CF(ABC)' ,'百字','5D', '6D']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                    <Form.Control disabled
                      style={{ width:  (this.state.Commission[game] && this.state.Commission[game][
                        (item === '大') ? 'B' : (item === '小') ? 'S' : (item === '单A') ? 'SA' : (item === 'CF(ABC)') ? 'CF' : 
                          (item === '干字(A)') ? 'CA' :  (item === '百字') ? 'PA' : (item === '5D' || item === '6D' ) ? item : 'all'
                      ]) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][ (item === '大') ? 'B' : (item === '小') ? 'S' : (item === '单A') ? 'SA' :  (item === 'CF(ABC)') ? 'CF' : 
                          (item === '干字(A)') ? 'CA' : (item === '百字') ? 'PA' :  (item === '5D' || item === '6D' ) ? item : 'all']).length * 8)) : 80, 
                      textAlign: 'center',fontWeight: 'bold', backgroundColor: 'white'}} 
                      type="text" value={item}/>
                  </div>
                })}
              </div>
            </div>
            <div  style={{ display: 'flex',  width:'100%', flexDirection: 'column', marginBottom: 5}}>
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ width: 80, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Money}</div>
                </div>
                   
                <Form.Control style={{  marginRight: 5, display: 'flex', width: (this.state.Commission[game]['all'] === undefined || this.state.Commission[game]['all'] === '') ? 80 : 70 + JSON.stringify(this.state.Commission[game]['all']).length * 8, textAlign: 'center',fontWeight: 'bold'}} 
                  type="text" value={this.state.Commission[game]['all']}
                  onKeyPress={(e) => this.CreateKey(e, 'B', game)}
                  ref={(input) => { this[`Input${game}All`] = input;}}
                  onChange={(e) => this.commissionAllChange(game, e.target.value)}/>
                {(['B', 'S', 'SA', 'CA', 'CF' , 'PA', '5D', '6D']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <Form.Control 
                      style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][item]).length * 8)) : 80, textAlign: 'center',fontWeight: 'bold'}} 
                      type="text" 
                      ref={(input) => { this[`Input${game}${item}`] = input;}}
                      onKeyPress={(e) => this.CreateKey(e, (item === 'B') ? 'S' : (item === 'S') ? 'SA' : (item === 'SA') ? 'CA' : 
                        (item === 'CA') ? 'CF' : (item === 'CF') ? 'PA' :  (item === 'PA') ? '5D' : 
                          (item === '5D') ? '6D' : '1B', game)}
                      value={this.state.Commission[game][item]}
                      onChange={(e) => this.commissionChange(game, item, e.target.value)}
                    />
                  </div>
                })}
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,}}>
                <div style={{ width: 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                <div style={{ width: (this.state.Commission[game] && this.state.Commission[game]['all']) ? 
                  ( 70  + (JSON.stringify(this.state.Commission[game]['all']).length * 8)) : 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                {(['B', 'S', 'SA', 'CA','CF', 'PA', '5D1', '6D']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    {(item !== '5D1' && item !== '6D') ? <Form.Control disabled
                      style={{ backgroundColor: '#C3f3fb',  width:  (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][item]).length * 8)) : 80, 
                      textAlign: 'center',fontWeight: 'bold', 
                      color: ((/[-]/).test(this.state.CountCommissionResult[game][item])) ? 'red' : 'black' }} 
                      type="text" value={this.state.CountCommissionResult[game][item] || 0}/> : 
                      ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                  </div>
                })}
              </div>
            </div>
            <div >
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                  <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 1'}</div>
                </div>
                {([ 'all', 'B', 'S', 'SA', 'CA','CF1' ,'PA', '5D1', '6D']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                    {(item === 'all') ? 
                      <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                      :<Form.Control 
                        onChange={(e) => this.oddChange(game, 
                          (item === 'B' || item === 'S' || item === 'SA' || item === 'CA') ? '1ST' : 
                            (item === 'PA') ? 'P' : 
                              (item === '5D1') ? '5D' : 
                                item , item, e)}
                        ref={(input) => { this[`Input${game}1${item}`] = input;}}
                        onKeyPress={(e) => this.CreateKey(e, (item === 'B') ? '1S' : (item === 'S') ? '1SA' : (item === 'SA') ? '1CA' : 
                          (item === 'CA') ? '1CF1' :  (item === 'CF1') ? '1PA' :  (item === 'PA') ? '15D1' : 
                            (item === '5D1') ? '16D' : '22B', game)}
                        style={{ width: (this.state.Commission[game] && this.state.Commission[game][(item === '5D1') ? '5D' : (item === 'CF1') ? 'CF' :(item === 'PA') ? 'PA' : item]) ? 
                          ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' :(item === 'CF1') ? 'CF' :  (item === 'PA') ? 'PA' :item]).length * 8)) : 80, textAlign: 'center',fontWeight: 'bold'}} 
                        value={(item === 'B' || item === 'S' || item === 'SA') ? this.state.Odd[game]['1ST'][item] 
                          : (item === 'PA'  || item === 'CA' || item === 'CF1') ? this.state.Odd[game][item] 
                            : (item === '5D1') ? this.state.Odd[game]['5D'][item] 
                              :  this.state.Odd[game]['6D'][item]  } 
                        type="text" />}
                  </div>
                })}
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                  <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 2'}</div>
                </div>
                {([ 'all', '2B', '2S', 'SB', 'CB', 'CF2','PB', '5D2', '6D2']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                    {(item === 'all') ? 
                      <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                      :<Form.Control 
                        onChange={(e) => this.oddChange(game, 
                          (item === '2B' || item === '2S') ? '2ND' : 
                            (item === '5D2') ? '5D' :
                              (item === '5D2') ? '5D' : '6D', 
                          (item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item, e)}
                        readOnly={(item === 'SB' || item === 'CB' || item === 'PB')}
                        style={{ width: (this.state.Commission[game] && this.state.Commission[game][
                          (item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                            (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' :  (item === 'CF2') ? 'CF' : 
                              (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']
                        ) ? 
                          ( 70  + (JSON.stringify(this.state.Commission[game][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                            (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' : (item === 'CF2') ? 'CF' : 
                              (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)) : 80,
                        textAlign: 'center',fontWeight: 'bold'}} 
                        ref={(input) => { this[`Input${game}2${item}`] = input;}}
                        onKeyPress={(e) => this.CreateKey(e, (item === '2B') ? '22S' : (item === '2S') ? '2CF2' :  
                          (item === 'CF2') ? '25D2' :
                            (item === '5D2') ? '26D2' : '33B', game)}
                        value={(item === '2B' || item === '2S' || item === 'SB') ? this.state.Odd[game]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                          : (item === 'PB' || item === 'CB' || item === 'CF2') ? this.state.Odd[game][item] 
                            : (item === '5D2') ? this.state.Odd[game]['5D'][item] 
                              : this.state.Odd[game]['6D'][item] }
                        type="text" />}
                  </div>
                })}
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                  <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 3'}</div>
                </div>
                {([ 'all', '3B', '3S', 'SC', 'CC', 'CF3','PC', '5D3', '6D3']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                    {(item === 'all') ? 
                      <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                      :<Form.Control 
                        onChange={(e) => this.oddChange(game, 
                          (item === '3B' || item === '3S') ? '3RD' : 
                            (item === 'CF3') ? 'CF3' :(item === '5D3') ? '5D' : '6D', 
                          (item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item, e)}
                        ref={(input) => { this[`Input${game}3${item}`] = input;}}
                        onKeyPress={(e) => this.CreateKey(e, (item === '3B') ? '33S' : (item === '3S') ? '3CF3' :  
                          (item === 'CF3') ? '35D3' :  (item === '5D3') ? '36D3' : '4B', game)}
                        readOnly={(item === 'SC' || item === 'CC' || item === 'PC' )}
                        value={(item === '3B' || item === '3S' || item === 'SC') ? this.state.Odd[game]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                          : (item === 'PC' || item === 'CC' || item === 'CF3') ? this.state.Odd[game][item] 
                            : (item === '5D3') ? this.state.Odd[game]['5D'][item] 
                              : this.state.Odd[game]['6D'][item] }
                        style={{ width: (this.state.Commission[game] && this.state.Commission[game][
                          (item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                            (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                              (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']
                        ) ? 
                          ( 70  + (JSON.stringify(this.state.Commission[game][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                            (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' :  (item === 'CF3') ? 'CF' :
                              (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)) : 80,
                        textAlign: 'center',fontWeight: 'bold'}} 
                        type="text" />}
                  </div>
                })}
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                  <div style={{ width: 80, fontWeight: 'bold'}}>{'特别'}</div>
                </div>
                {([ 'all', 'B', 'empty', 'SD', 'CD',  'emptyC', 'PD', '5D4', '6D4']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                    {(item === 'all' || item === 'empty' || item === 'emptyC') ? 
                      <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][
                        (item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']
                      ) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' :  'all']).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                      : <Form.Control 
                        onChange={(e) => this.oddChange(game, 
                          (item === 'B') ? '4TH' : 
                            (item === '5D4') ? '5D' : 
                              '6D', item, e)}
                        onKeyPress={(e) => this.CreateKey(e, (item === 'B') ? '45D4' : (item === '5D4') ? '46D4' : '5B', game)}
                        ref={(input) => { this[`Input${game}4${item}`] = input;}}
                        readOnly={(item === 'SD' || item === 'CD' || item === 'PD')}
                        style={{ width: (this.state.Commission[game] && this.state.Commission[game][
                          (item === 'B') ? 'B' : (item === '') ? 'S' : 
                            (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                              (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']
                        ) ? 
                          ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                            (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                              (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)) : 80,
                        textAlign: 'center',fontWeight: 'bold'}} 
                        value={(item === 'B' || item === 'SD' ) ? this.state.Odd[game]['4TH'][item] 
                          : (item === 'PD' || item === 'CD') ? this.state.Odd[game][item] 
                            : (item === '5D4') ? this.state.Odd[game]['5D'][item] 
                              : this.state.Odd[game]['6D'][item] }
                        type="text" />}
                  </div>
                })}
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                  <div style={{ width: 80, fontWeight: 'bold'}}>{'安慰奖'}</div>
                </div>
                {([ 'all', 'B', 'empty', 'SE', 'CE',  'emptyC', 'PE', '5D5', '6D5']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                    {(item === 'all' || item === 'empty'|| item === 'emptyC') ? 
                      <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF'  : 'all']) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                      :<Form.Control 
                        onChange={(e) => this.oddChange(game, 
                          (item === 'B') ? '5TH' : 
                            (item === '5D5') ? '5D' : 
                              '6D', item, e)}
                        ref={(input) => { this[`Input${game}5${item}`] = input;}}
                        onKeyPress={(e) => this.CreateKey(e, (item === 'B') ? '55D5' : (item === '5D5') ? '56D5' : '6SF', game)}
                        readOnly={(item === 'SE' || item === 'CE' || item === 'PE')}
                        style={{ width: (this.state.Commission[game] && this.state.Commission[game][
                          (item === 'B') ? 'B' : 
                            (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                              (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']
                        ) ? 
                          ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : 
                            (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                              (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)) : 80,
                        textAlign: 'center',fontWeight: 'bold'}} 
                        value={(item === 'B' || item === 'SE') ? this.state.Odd[game]['5TH'][item] 
                          : (item === 'CE' || item === 'PE') ? this.state.Odd[game][item] 
                            : (item === '5D5') ? this.state.Odd[game]['5D'][item] 
                              : this.state.Odd[game]['6D'][item] }
                        type="text" /> }
                  </div>
                })}
              </div>
              <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 5}}>
                  <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 6'}</div>
                </div>
                {/* cc */}
                {([ 'all',  'empty', 'empty2', 'SF', 'emptyD',  'emptyC', 'PF', '5D6', 'empty3']).map((item) => {
                  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                    {(item === 'all' || item === 'empty'|| item === 'empty2' || item === 'emptyD' || item === 'emptyC' || item === 'empty3'  ) ? 
                      <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][(item === 'empty') ? 'B' : 
                        (item === 'empty2') ? 'S' : (item === 'emptyD') ? 'CA' : (item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all']) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'B' :  (item === 'emptyD') ? 'CA' : 
                          (item === 'empty2') ? 'S' :(item === 'empty3') ? '6D' :(item === 'emptyC') ? 'CF' :  'all']).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold', }} />
                      :<Form.Control 
                        ref={(input) => { this[`Input${game}6${item}`] = input;}}
                        onKeyPress={(e) => this.CreateKey(e, (item === 'SF') ? '6PF' : (item === 'PF') ? '65D6' : 'All', game)}
                        onChange={(e) => this.oddChange(game, item === 'SF' ? '1ST' : item === 'PF' ? 'PF' : '5D', item, e)}
                        // readOnly={(item === 'AC'  || item === 'PC')}
                        style={{ width: (this.state.Commission[game] && this.state.Commission[game][
                          (item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                            (item === 'PF') ? 'PA' : '5D']
                        ) ? 
                          ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'SF') ? 'SA' :
                            (item === 'CD') ? 'CA' :(item === 'PF') ? 'PA' : '5D']).length * 8)) : 80,
                        textAlign: 'center',fontWeight: 'bold'}} 
                        value={(item === 'SF') ? this.state.Odd[game]['1ST'][item] 
                          : ( item === 'PF') ? this.state.Odd[game][item] 
                            : (item === '5D6') ? this.state.Odd[game]['5D'][item] 
                              : '' }
                        type="text" /> }
                  </div>
                })}
              </div>
            </div>
           
          </div>
        })}
        {/* {this.state.ShowSetup === true && 
        <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
          <div className="dcc row">
            {this.state.Games.map((item) => {
              return <Form.Check
                style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                inline
                label={item}
                // value={item}
                type="checkbox"
                checked={this.state.SelectedGame.includes(item)}
                onChange={(e) => this.SelectedGameChange(item, e)}
              />
            })}
          </div>
          <div className="dcc row">
            {['B', 'S', 'A1', 'AC', 'A', 'C', 'A2', 'A3', 'D4', 'E4'].map((item, index) => {
              return(
                <div index={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5, marginTop: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%'}}>{item}</div>
                  <Form.Control style={{ width: 80, textAlign: 'center'}} type="text" onChange={(e) => this.ChangeGameValues(item, e)}
                    onKeyPress={(e) => this.EnterPress(e, item)} ref={(input) => { this[`Input${item}`] = input; }}/>
                </div>
              );
            })}
          </div>
          <div className="dcc">
            <Button style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.setState({ ShowSetup: false})}>关闭</Button>
            <Button style={{ margin: 5, width: '20%'}} variant="success" onClick={() => this.ConfirmChange()}>确定</Button>
          </div>
        </div>
        }  */}
        <div style={{  width: '1000px', flexDirection: 'row', display: 'flex'}}>
          <Button className="button2" style={{ margin: 5, width: '33%', fontWeight: 'bold'}} variant="danger" onClick={() => this.props.CloseEWPackage()}>关闭</Button>
          <Button className="button2" style={{ margin: 5, width: '33%', fontWeight: 'bold'}} variant="warning" onClick={() => this.resetForm()}>重置</Button>
          <Button className="button2" style={{ margin: 5, width: '33%', fontWeight: 'bold'}} variant="success" onClick={() => this.saveOcPackage()}>更新</Button>
          {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? (
            (this.props.AdminModal) && <Form.Control style={{ width: 'auto', textAlign: 'center', marginLeft: '5%', fontSize: 22, fontWeight: 'bold', color: ((/[-]/).test(this.state.TotalResultGame)) ? 'red' : 'black'}} disabled type="text" value={`${'寻找全配套'}: ${this.state.TotalResultGame || 0}`}/>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(HR_Package);