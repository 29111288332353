import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
// ConsoleView
import { Form } from 'react-bootstrap';
import Moment from 'moment-timezone';
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { language } from '../language';
import Loading from '../Images/loading.gif';
import updown from '../Images/updown1.png';
const { accountReportDetail } = require('../Api');
// import { Link } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';  (isMobile) ?
var timer

function PlatformChange(platform) {
  if (platform === '1') return 'M'
  else if (platform === '2') return 'K'
  else if (platform === '3') return 'T'
  else if (platform === '4') return 'S'
  else if (platform === '5') return 'W'
  else if (platform === '6') return 'B'
  else if (platform === '7') return 'D'
  else if (platform === '8') return '8'
  else if (platform === '9') return '9'
  return 'null'
}

class DetailPersonaSales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: this.props.username,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      SelectedDate: Moment(localStorage.getItem('SELECTED_DATE')).toDate(),
      ChooseStatus: 300,
      GameArray: {},
      UseridReport: '', 
      ReportDetailBet: [],
      notificationType: 'success',
      showNotification: false,
      notificationMessage: '',
      ModeType: 'Detail',
      NotEmpty: true,
      loading: false,
      BigTotal: false, BigB: false, BigS: false, BigCA1: false, BigCC: false, BigA1: false, BigAC: false, BigA2: false,
      BigA3: false, BigAD: false, BigAE: false, Big5D: false,
      Big6D: false,
    }
  }

  async componentDidMount() {
    this.CallApi();
  }

  async CallApi() {
    const SelectedDate = Moment(this.state.SelectedDate).format('YYYY-MM-DD')
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
    if (searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      searchState = JSON.parse(searchState)

      this.setState({ UseridReport: searchState.Username, loading: true })

      const SearchUSerID = (searchState.id.includes('SELF')) ? searchState.id.substring(0, searchState.id.length - 5) : searchState.id
      const AccountReportDetail = await accountReportDetail(SelectedDate, SearchUSerID, this.props.username, this.props.token)
      if(AccountReportDetail.message === 'No Data For Selected Date') {
        this.setState({ NotEmpty: true, ReportDetailBet: [], loading: false })
      } else if(AccountReportDetail.error === 'database error') {
        this.setState({ NotEmpty: true, ReportDetailBet: [], loading: false })
      } else {
        var FilterOutZero = AccountReportDetail.filter(function (el) {
          return el.Amount !== 0
        });
        this.setState({ ReportDetailBet: FilterOutZero, NotEmpty: false, loading: false })
      }
    } else {
      this.setState({ UseridReport: 'No User', ReportDetailBet: [], NotEmpty: true, loading: false })
    }
  }
  
  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  async setSelectedDate(SelectedDate) {
    this.setState({ SelectedDate }, ()=>{
      this.props.setDate(SelectedDate)
      this.CallApi();
    })
  }

  BigToSmall(items) {
    this.setState({ loading: true })
    const ReportDetails = this.state.ReportDetailBet
    if(this.state[`Big${items}`] === false) {
      ReportDetails.sort((a, b) => (a[items] > b[items]) ? -1 : 1)
      this.setState({ [`Big${items}`]: true })
    } else {
      ReportDetails.sort((a, b) => (a[items] > b[items]) ? 1 : -1)
      this.setState({ [`Big${items}`]: false })
    }
    this.setState({ ReportDetails, loading: false })
  }

  render() {
    return (
      <div style={{ marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', overflowX: 'auto' }}>
        <Header2 />
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width:  '70%', left:  250 }} show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 20,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft:'3.5%',
            flexDirection: 'row',
            width: '95%'
          }}>
          <div style={{ justifyContent: 'center', display: 'flex' }} >
            <div style={{fontSize: "18px", width: 100, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Date} :</div>
            <DatePicker
              onChange = {(Date) => this.setSelectedDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              maxDate={new Date()}
              // popperPlacement="top-right"
              selected={this.state.SelectedDate}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.props.username} </div>
          </div>
        </div>
        <div style={{ marginBottom: 10, marginTop: 10,marginLeft:  '2.2%', display: 'flex', flexDirection: 'row'}}>
          <div style={{ display: 'flex', width: '25%'}}> 
            <div style={{fontSize: "18px", width: '50%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Mode} :</div>
            <Form.Control
              as="select"
              type="text"
              value={this.state.ModeType}
              onChange={(e) => this.setState({ ModeType: e.target.value})}
              placeholder={'Amount'}
              style={{ fontWeight: 'bold', width: '100%', marginRight:  null, textAlign: 'center'}}
            >
              <option value="Detail">{language[this.props.currentLanguage].Details}</option>
              <option value="Simple">{language[this.props.currentLanguage].Simple}</option>
            </Form.Control>
          </div>
        </div>
        <div style={{ marginLeft: '3.5%', fontWeight: 'bold', fontSize: '18px', marginTop: 10 }}>
          {language[this.props.currentLanguage].SelectedUsername} {this.state.UseridReport}
        </div>
        <div style={{ overflowX: 'scroll'}}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{language[this.props.currentLanguage].UserID}</th>
                <th>{language[this.props.currentLanguage].Status}</th>
                <th>{language[this.props.currentLanguage].Games}</th>
                <th>{language[this.props.currentLanguage].Number}</th>
                <th><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  {language[this.props.currentLanguage].Total}
                  <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('Total')}>
                    <img src={updown} alt="img" className='button2'
                      style={{
                        height: 15,
                        width: 15,
                      }}
                    />
                  </div>
                </div></th>
                {(this.state.ModeType !== 'Simple') && 
              ['B','S','CA', 'CC', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF'].map((item, index) => {
                return(
                  <th key={index}><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{ paddingRight: 5 }}>{item}</div>
                    <div style={{ cursor: 'pointer'}} onClick={() => this.BigToSmall(item)}>
                      <img src={updown} alt="img"
                        className='button2'
                        style={{
                          height: 15,
                          width: 15,
                        }}
                      />
                    </div></div></th>
                )
              })
                }
              </tr>
            </thead>
            <tbody>
              {this.state.loading === true ? (
                <tr>
                  <td colSpan="30" style={{ backgroundColor: 'white' }}>
                    <img src={Loading} alt="Logo" className="loadingreceiptimages" style={{ marginBottom: 30 }} />
                    <div className="loadingMessageBet">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
                  </td>
                </tr>
              ) : (
                <>
                  {this.state.NotEmpty === false ? (
                    <>
                      {(this.state.ReportDetailBet || []).map((item, idn) => {
                        return(
                          <tr key={idn}>
                            <td>{item.Username}</td>
                            <td>{item.Status}</td>
                            <td>{PlatformChange(item.Platform)}</td>
                            <td>{item.Word}</td>
                            <td>{Number.parseFloat(item.Amount).toFixed(2)}</td>
                            {(this.state.ModeType !== 'Simple') && 
      ['B','S','CA', 'CC', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF'].map((values, idx) => {
        return(
          <td key={idx}>{(item[values] === null) ? '0.00' : Number.parseFloat(item[values]).toFixed(2)}</td>
        )
      })
                            }
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <tr><td colSpan="30" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '28px'}}>{language[this.props.currentLanguage].NoData}</td></tr>
                    </>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div >
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPersonaSales);