export function ReturnCancelValidation(ValidateMessage) {
  if(ValidateMessage === 'database error') {
    return 'databaseError'
  } else if(ValidateMessage === 'Exceed Date') {
    return 'CancelExceededDate'
  } else if(ValidateMessage === 'OverTimeLimit') {
    return 'ExceededHours'
  } else if(ValidateMessage === 'DontHaveAutho') {
    return 'DontHaveAutho'
  } else if(ValidateMessage === 'AlreadyCancalled') {
    return 'AlreadyCancalled'
  } else if(ValidateMessage === 'Order not finished yet, please wait for awhile') {
    return 'OrderNotYetFinish'
  } else if(ValidateMessage === 'Ticket expired') {
    return 'CannotCancelpastticket'
  }
}