import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import { Button, Form, Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
var timer

class Virtual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MiArray: {},
      MiArrayNumb: [],
      Array: ['B', 'S', 'CA', 'CF',],
      notificationType: 'success',
      setTimer: 6000,
      notificationMessage: '',
      showNotification: false,
      DefaultModal: false,
      DefaultnotificationType: 'success',
      DefaultnotificationMessage: '',
      DefaultshowNotification: false,
      KDobject: { 
        0: {CheckAll: false},
        1: { B: '0.25', S: '0.25', CA: '0.25', CF: '0.25',Num: '1', Check: false},
        2: { B: '0.5', S: '0.5', CA: '0.5', CF: '0.5', Num: '2', Check: false},
        3: { B: '1.00', S: '1.00', CA: '0.5', CF: '0.5', Num: '3', Check: false},
        4: { B: '1.00', S: '1.00', CA: '1.00', CF: '1.00', Num: '4', Check: false},
        5: { B: '2.00', S: '1.00', CA: '1.00', CF: '1.00', Num: '5', Check: false},
        6: { B: '2.00', S: '2.00', CA: '1.00', CF: '1.00', Num: '6', Check: false},
        7: { B: '2.00', S: '2.00', CA: '2.00', CF: '2.00', Num: '8', Check: false},
        8: { B: '3.00', S: '3.00', CA: '2.00', CF: '2.00', Num: '10', Check: false},
        9: { B: '5.00', S: '5.00', CA: '5.00', CF: '5.00', Num: '20', Check: false},
        10: { B: '10.00', S: '10.00', CA: '5.00', CF: '5.00', Num: '30', Check: false},
        11: { B: '10.00', S: '10.00', CA: '10.00', CF: '10.00', Num: '40', Check: false},
        12: { B: '20.00', S: '10.00', CA: '10.00', CF: '10.00', Num: '50', Check: false},
        13: { B: '20.00', S: '20.00', CA: '10.00', CF: '10.00', Num: '60', Check: false},
        14: { B: '20.00', S: '20.00', CA: '20.00', CF: '20.00', Num: '80', Check: false},
        15: { B: '30.00', S: '30.00', CA: '20.00', CF: '20.00', Num: '100', Check: false},
      },
      PNobject: { 
        0: {CheckAll: false},
        1: { B: '0.25', S: '0.25', CA: '0.25', CF: '0.25',Num: '1', Check: false},
        2: { B: '0.5', S: '0.5', CA: '0.5', CF: '0.5', Num: '2', Check: false},
        3: { B: '1.00', S: '1.00', CA: '0.5', CF: '0.5', Num: '3', Check: false},
        4: { B: '1.00', S: '1.00', CA: '1.00', CF: '1.00', Num: '4', Check: false},
        5: { B: '2.00', S: '2.00', CA: '0.50', CF: '0.50', Num: '5', Check: false},
        6: { B: '2.00', S: '2.00', CA: '1.00', CF: '1.00', Num: '6', Check: false},
        7: { B: '2.00', S: '2.00', CA: '2.00', CF: '2.00', Num: '8', Check: false},
        8: { B: '4.00', S: '4.00', CA: '1.00', CF: '1.00', Num: '10', Check: false},
        9: { B: '8.00', S: '8.00', CA: '2.00', CF: '2.00', Num: '20', Check: false},
        10: { B: '10.00', S: '10.00', CA: '5.00', CF: '5.00', Num: '30', Check: false},
        11: { B: '10.00', S: '10.00', CA: '10.00', CF: '10.00', Num: '40', Check: false},
        12: { B: '20.00', S: '20.00', CA: '5.00', CF: '5.00', Num: '50', Check: false},
        13: { B: '40.00', S: '40.00', CA: '10.00', CF: '10.00', Num: '100', Check: false},
      }
    }
    this.dataChangeKD = this.dataChangeKD.bind(this);
    this.dataChangePN = this.dataChangePN.bind(this);
  }

  componentDidMount(){
    let MiArrayNumb = []
    for (let i = 0; i < 20; i += 1) {
      MiArrayNumb.push(i)
    }
    const copyXingjing =  JSON.parse(this.props.formData.XingJinFormula)
    this.setState({ MiArrayNumb, MiArray: (this.props.formData.XingJinFormula === null) ? {} : JSON.parse(JSON.stringify(copyXingjing))})
    
    // const Objects = { 'B': '', 'S': '', 'A1': '', 'A1C': '', 'A': '', 'C': '', 'A2': '', 'A3': '', 'D4': '', 'E4': '', '5D': '', '6D': ''}
    // this.setState({VirtualCommission: Object.keys(this.props.formData).length === 0 ? 
    //   JSON.parse(JSON.stringify( Objects)) : 
    //   (this.props.formData.VirtualJSON === null) ? JSON.parse(JSON.stringify( Objects)) 
    //     : this.props.formData.VirtualJSON})
  }


  MiIndexChange(key, value,) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value.replace(/[^0-9.]/g, '')
     
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');

    let stateObject = JSON.parse(JSON.stringify(this.state.MiArray)); 
    
    if (stateObject[key] === undefined){
      stateObject[key] = {B: '0', S: '0', CA: '0', CF: '0', Num: output}
      this.setState({MiArray: stateObject})
    } else {
      stateObject[key]['Num'] = output
      this.setState({MiArray: stateObject})
    }
  }


  MiIndividualChange(keys, value, ObjectKey) {
    let IDkey = keys
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value.replace(/[^0-9.]/g, '')
     
    var output = e.split('.');
    output = output.shift() + (output.length ? '.' + output.join('') : '');

    let stateObject = JSON.parse(JSON.stringify(this.state.MiArray)); 
    
    if (stateObject[keys] === undefined){
      stateObject[keys] = {...stateObject[keys],  Num: `${IDkey}`,[ObjectKey]: output}
      this.setState({MiArray: stateObject})
    } else {
      stateObject[keys][ObjectKey] = output
      this.setState({MiArray: stateObject})
    }
  }

  dataChangeKD(Boolean, index){
    let filterobject = JSON.parse(JSON.stringify(this.state.KDobject));
    if(index === 0){
      filterobject[index]['CheckAll'] = Boolean
      for(var i = 1; i <= 15; i++) {
        // let indexs = JSON.stringify(i)
        filterobject[i]['Check'] = Boolean
      }
    } else {
      filterobject[0]['CheckAll'] = false
      filterobject[index]['Check'] = Boolean
    }
    this.setState({ KDobject: filterobject})
  }

  dataChangePN(Boolean, index){
    let filterobject = JSON.parse(JSON.stringify(this.state.PNobject));
    filterobject[index]['CheckAll'] = Boolean
    if(index === 0){
      filterobject[index]['CheckAll'] = Boolean
      for(var i = 1; i <= 13; i++) {
        // let indexs = JSON.stringify(i)
        filterobject[i]['Check'] = Boolean
      }
    }  else {
      filterobject[0]['CheckAll'] = false
      filterobject[index]['Check'] = Boolean
    }
    this.setState({ PNobject: filterobject})
  }
  // async setDefault(){
  //   const SetDefault = {'1': { B: '0.25', S: '0.25', CA: '0.30', CF: '0.25',Num: '1'},
  //     '2': { B: '0.50', S: '0.30', CA: '0.60', CF: '0.50', Num: '2'},
  //     '3': { B: '1.00', S: '1.00', CA: '0.60', CF: '0.50', Num: '3'},
  //     '4': { B: '1.00', S: '1.00', CA: '1.20', CF: '1.00', Num: '4'}}
  //   this.setState({MiArray: JSON.parse(JSON.stringify(SetDefault))});
  // }

  SubmitMi(){
    let stateObjectCopy = JSON.parse(JSON.stringify(this.state.MiArray)); 
    let stateObject = JSON.parse(JSON.stringify(stateObjectCopy)); 
    let AlertMessage= ''
    let AlertMessageDuplicate = ''
    const ObjectKey = Object.keys(stateObjectCopy)
  
    for(let i = 0; i < ObjectKey.length; i++) {
      for(let s = 0; s < this.state.Array.length; s++) {
        if((stateObjectCopy[ObjectKey[i]]['Num'] || '') === ''){
          delete stateObject[ObjectKey[i]] 
        }
      }
    }
    const ObjectKeycheck = Object.keys(stateObject)
    let filterobjectresult = []
    for(let i = 0; i < ObjectKeycheck.length; i++) {
      for(let s = 0; s < this.state.Array.length; s++) {
        if((stateObject[ObjectKeycheck[i]][this.state.Array[s]] || '') === ''){
          AlertMessage += `${ObjectKeycheck[i]}: ${this.state.Array[s]}, `
        }
      }
      filterobjectresult.push(stateObject[ObjectKeycheck[i]]['Num'])
    }

    // let filterobjectresult = []
    // for(let i = 0; i < ObjectKeycheck.length; i++) {
    //   filterobjectresult.push(stateObject[ObjectKeycheck[i]]['Num'])
    // }

    const uniqueElements = new Set(filterobjectresult);

    for(let i = 0; i < filterobjectresult.length; i++) {
      if (uniqueElements.has(filterobjectresult[i])) {
        uniqueElements.delete(filterobjectresult[i]);
      } else {
        AlertMessageDuplicate += `${language[this.props.currentLanguage].DuplicateNumber}${filterobjectresult[i]}, `
      }
    }

    

    if(AlertMessage !== ''){
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(`${language[this.props.currentLanguage].Cannotemptynumber}  ${AlertMessage}`)
      })
    } else if(AlertMessageDuplicate !== ''){
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(`${AlertMessageDuplicate}`)
      })
    } else {
      this.props.propsSetState({ MiModal: false})
      this.props.MiFunction(stateObject)
      this.setState({ MiArray: {} })
    }
    
  }

  SetDefault(){
    let copyKD = JSON.parse(JSON.stringify(this.state.KDobject))
    let copyPN = JSON.parse(JSON.stringify(this.state.PNobject))
    let filterobjectresult = {}
    for(let i = 1; i < Object.keys(copyKD).length; i++) {
      if (copyKD[i]['Check'] === true) {
        filterobjectresult[Object.keys(filterobjectresult).length + 1] = {
          "B": copyKD[i].B,
          "S": copyKD[i].S,
          "CA": copyKD[i].CA,
          "CF": copyKD[i].CF,
          "Num": copyKD[i].Num,
        }
      }
    }
  
    for(let i = 1; i < Object.keys(copyPN).length; i++) {
      let Boolean = []
      if (copyPN[i]['Check'] === true) {
        if (copyPN[i]['Num'] === '1' || copyPN[i]['Num'] === '2' || copyPN[i]['Num'] === '3'  
        || copyPN[i]['Num'] === '4' || copyPN[i]['Num'] === '6' || copyPN[i]['Num'] === '8') {
          for(let f = 0; f < Object.keys(filterobjectresult).length; f++) {
            if ( filterobjectresult[f + 1]['Num'] === copyPN[i]['Num']) {
              Boolean.push(true)
            }  else {
              Boolean.push(false)
            }
      
          }
          if (!Boolean.includes(true) === true) {
            filterobjectresult[Object.keys(filterobjectresult).length + 1] = {
              "B": copyPN[i].B,
              "S": copyPN[i].S,
              "CA": copyPN[i].CA,
              "CF": copyPN[i].CF,
              "Num": copyPN[i].Num,
            }
          }
      
        } else {
          filterobjectresult[Object.keys(filterobjectresult).length + 1] = {
            "B": copyPN[i].B,
            "S": copyPN[i].S,
            "CA": copyPN[i].CA,
            "CF": copyPN[i].CF,
            "Num": copyPN[i].Num,
          }
        }
      }
    }
    
    if (Object.keys(filterobjectresult).length >= 20 ){
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(`${language[this.props.currentLanguage].Below20}`)
      })
    }  else {
      this.setState({MiArray: JSON.parse(JSON.stringify(filterobjectresult))}, ()=>{
        this.DefaultOff();
      });
    }
  }

  DefaultOff(){
    this.setState({ DefaultModal: false, 
      KDobject:{ 
        0: {CheckAll: false},
        1: { B: '0.25', S: '0.25', CA: '0.25', CF: '0.25',Num: '1', Check: false},
        2: { B: '0.5', S: '0.5', CA: '0.5', CF: '0.5', Num: '2', Check: false},
        3: { B: '1.00', S: '1.00', CA: '0.5', CF: '0.5', Num: '3', Check: false},
        4: { B: '1.00', S: '1.00', CA: '1.00', CF: '1.00', Num: '4', Check: false},
        5: { B: '2.00', S: '1.00', CA: '1.00', CF: '1.00', Num: '5', Check: false},
        6: { B: '2.00', S: '2.00', CA: '1.00', CF: '1.00', Num: '6', Check: false},
        7: { B: '2.00', S: '2.00', CA: '2.00', CF: '2.00', Num: '8', Check: false},
        8: { B: '3.00', S: '3.00', CA: '2.00', CF: '2.00', Num: '10', Check: false},
        9: { B: '5.00', S: '5.00', CA: '5.00', CF: '5.00', Num: '20', Check: false},
        10: { B: '10.00', S: '10.00', CA: '5.00', CF: '5.00', Num: '30', Check: false},
        11: { B: '10.00', S: '10.00', CA: '10.00', CF: '10.00', Num: '40', Check: false},
        12: { B: '20.00', S: '10.00', CA: '10.00', CF: '10.00', Num: '50', Check: false},
        13: { B: '20.00', S: '20.00', CA: '10.00', CF: '10.00', Num: '60', Check: false},
        14: { B: '20.00', S: '20.00', CA: '20.00', CF: '20.00', Num: '80', Check: false},
        15: { B: '30.00', S: '30.00', CA: '20.00', CF: '20.00', Num: '100', Check: false},
      },
      PNobject: { 
        0: {CheckAll: false},
        1: { B: '0.25', S: '0.25', CA: '0.25', CF: '0.25',Num: '1', Check: false},
        2: { B: '0.5', S: '0.5', CA: '0.5', CF: '0.5', Num: '2', Check: false},
        3: { B: '1.00', S: '1.00', CA: '0.5', CF: '0.5', Num: '3', Check: false},
        4: { B: '1.00', S: '1.00', CA: '1.00', CF: '1.00', Num: '4', Check: false},
        5: { B: '2.00', S: '2.00', CA: '0.50', CF: '0.50', Num: '5', Check: false},
        6: { B: '2.00', S: '2.00', CA: '1.00', CF: '1.00', Num: '6', Check: false},
        7: { B: '2.00', S: '2.00', CA: '2.00', CF: '2.00', Num: '8', Check: false},
        8: { B: '4.00', S: '4.00', CA: '1.00', CF: '1.00', Num: '10', Check: false},
        9: { B: '8.00', S: '8.00', CA: '2.00', CF: '2.00', Num: '20', Check: false},
        10: { B: '10.00', S: '10.00', CA: '5.00', CF: '5.00', Num: '30', Check: false},
        11: { B: '10.00', S: '10.00', CA: '10.00', CF: '10.00', Num: '40', Check: false},
        12: { B: '20.00', S: '20.00', CA: '5.00', CF: '5.00', Num: '50', Check: false},
        13: { B: '40.00', S: '40.00', CA: '10.00', CF: '10.00', Num: '100', Check: false},
      }
    })
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        setTimer: 6000,
      });
    }, this.state.setTimer);
  };

  render() {
    return (
      <>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.DefaultModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
            <GrFormClose
              style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
              onClick={() => {
                this.setState({ DefaultModal: false })
              }}
              size="35px"
              color="#000"
            />
            <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
              <div className="dcc" style={{ justifyContent: 'center' }}>
                <Alert className="dcc" style={{ zIndex: 99, position: 'fixed',  top: 80, width: '50%', right: ''}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                </Alert>
              </div>
              <b className="dcc" style={{ padding: 20, fontSize: 23}}>
              </b>
              <div className="dcc" style={{  marginBottom: '3%',}}>
                <div style={{ display: 'flex', alignItems: 'center', width:'100%', justifyContent:'center'}}>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px', marginTop: 2 }}>
                    <div style={{  border: '1px solid #000', borderRadius: 5,   width: '100%',  fontWeight: 'bold', marginBottom: 5, display: 'flex', flexDirection: 'row', alignItems: 'center',}}>
                      <div style={{ fontWeight: 'bold'}}>{'KD'}</div>
                      <Form.Check
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex', padding: 8, }}
                        inline
                        type="checkbox"
                        onChange={(e) => this.dataChangeKD(!this.state.KDobject[0]['CheckAll'], 0 )} 
                        checked={this.state.KDobject[0]['CheckAll']}
                      />
                    </div>
                    {Object.keys(this.state.KDobject).filter(num => num !== '0').map((item) => {
                      return <Form.Check
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex', padding: 9,  justifyContent:'center' }}
                        inline
                        type="checkbox"
                        onChange={(e) => this.dataChangeKD(!this.state.KDobject[item]['Check'], item )} 
                        checked={this.state.KDobject[item]['Check']}
                      />
                    })}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px', marginTop: 0 }}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold', marginBottom: 5}}>{'NUM'}</div>
                    {Object.keys(this.state.KDobject).filter(num => num !== '0').map((item) => {
                      return <Form.Control style={{ width: 85, textAlign: 'center',  fontWeight: 'bold' }} 
                        type="text" value={this.state.KDobject[item]['Num']} 
                        disabled
                      />
                    })}
                  </div>
                  {this.state.Array.map((itemAlphabet) => {
                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold', marginBottom: 5}}>{itemAlphabet}</div>

                      {Object.keys(this.state.KDobject).filter(num => num !== '0').map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                          <Form.Control style={{ width: 85, textAlign: 'center',  fontWeight: 'bold' }} 
                            type="text" 
                            value={this.state.KDobject[item][itemAlphabet]}
                            disabled
                          />
                        </div>})}
                    </div>
                  })}
                </div>
              </div>
              <div className="dcc" style={{  marginBottom: '3%'}}>
                <div style={{ display: 'flex', alignItems: 'center', width:'100%', justifyContent:'center'}}>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px', marginTop: 2 }}>
                    <div style={{  border: '1px solid #000', borderRadius: 5,   width: '100%',  fontWeight: 'bold', marginBottom: 5, display: 'flex', flexDirection: 'row', alignItems: 'center',}}>
                      <div style={{ fontWeight: 'bold'}}>{'PN'}</div>
                      <Form.Check
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex', padding: 8, }}
                        inline
                        type="checkbox"
                        onChange={(e) => this.dataChangePN(!this.state.PNobject[0]['CheckAll'], 0 )} 
                        checked={this.state.PNobject[0]['CheckAll']}
                      />
                    </div>
                    {Object.keys(this.state.PNobject).filter(num => num !== '0').map((item) => {
                      return <Form.Check
                        style={{ fontSize: 20, alignItems: 'center', display: 'flex', padding: 9,  justifyContent:'center' }}
                        inline
                        type="checkbox"
                        onChange={(e) => this.dataChangePN(!this.state.PNobject[item]['Check'], item )} 
                        checked={this.state.PNobject[item]['Check']}
                      />
                    })}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px', marginTop: 5 }}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold', marginBottom: 5}}>{'NUM'}</div>
                    {Object.keys(this.state.PNobject).filter(num => num !== '0').map((item) => {
                      return <Form.Control style={{ width: 85, textAlign: 'center',  fontWeight: 'bold' }} 
                        type="text" value={this.state.PNobject[item]['Num']} 
                        disabled
                      />
                    })}
                  </div>
                  {this.state.Array.map((itemAlphabet) => {
                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold', marginBottom: 5}}>{itemAlphabet}</div>

                      {Object.keys(this.state.PNobject).filter(num => num !== '0').map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                          <Form.Control style={{ width: 85, textAlign: 'center',  fontWeight: 'bold' }} 
                            type="text" 
                            value={this.state.PNobject[item][itemAlphabet]}
                            disabled
                          />
                        </div>})}
                    </div>
                  })}
                </div>
              </div>
              <div className="dcc">
                <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" 
                  onClick={() => {
                    // this.setState({ DefaultModal: false, })
                    this.DefaultOff();
                  }}>{language[this.props.currentLanguage].Shutdown}</Button>
                <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" 
                  onClick={() => {
                    this.SetDefault();
                  }}>{language[this.props.currentLanguage].Confirm}</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.MiModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
            <GrFormClose
              style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
              onClick={() => {
                this.setState({ MiArray: {} })
                this.props.propsSetState({ 
                  MiModal: false,
                })
              }}
              size="35px"
              color="#000"
            />
            <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
              <div className="dcc" style={{ justifyContent: 'center' }}>
                <Alert className="dcc" style={{ zIndex: 99, position: 'fixed',  top: 80, width: '50%', right: ''}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                </Alert>
              </div>
              <b className="dcc" style={{ padding: 20, fontSize: 23}}>
                {'米#'}
              </b>
              <div style={{ flex: 1, marginLeft: '5%'}}>
                <Button  className='button2' variant="primary" 
                  onClick={() => this.setState({DefaultModal: true})}
                  // onClick={() => this.setDefault()} 
                >
                  {'Default'}
                </Button>
              </div>
              <div className="dcc" style={{  marginBottom: '3%'}}>
                <div style={{ display: 'flex', alignItems: 'center', width:'100%', justifyContent:'center'}}>
                  <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}/>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px', marginTop: 45 }}>
                    {this.state.MiArrayNumb.map((itemNumb) => {
                      return <Form.Control style={{ width: 85, textAlign: 'center',  fontWeight: 'bold' }} 
                        type="text" value={(this.state.MiArray[itemNumb + 1]) ?  this.state.MiArray[itemNumb + 1]['Num'] || '' : ''} 
                        onChange={(e) => this.MiIndexChange( itemNumb + 1, e.target.value )}
                      
                      />
                    })}
                  </div>
                  {this.state.Array.map((itemAlphabet) => {
                    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%',  fontWeight: 'bold', marginBottom: 5}}>{itemAlphabet}</div>

                      {this.state.MiArrayNumb.map((itemNumb) => {
                        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                          <Form.Control style={{ width: 85, textAlign: 'center',  fontWeight: 'bold' }} 
                            type="text" 
                            value={(this.state.MiArray[itemNumb + 1]) ? this.state.MiArray[itemNumb + 1][itemAlphabet] || '' : ''}
                            onChange={(e) => this.MiIndividualChange( itemNumb + 1, e.target.value, itemAlphabet)}
                          />
                        </div>})}
                    </div>
                  })}
                </div>
              </div>
              <div className="dcc">
                <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" 
                  onClick={() => {
                    this.setState({ MiArray: {} })
                    this.props.propsSetState({ MiModal: false})
                  }}>{language[this.props.currentLanguage].Shutdown}</Button>
                <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" 
                  onClick={() => {
                    this.SubmitMi();
                  }}>{language[this.props.currentLanguage].Confirm}</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Virtual);