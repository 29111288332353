import React, {Component, Fragment} from 'react';
import '../App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { language } from '../language';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import Header2 from '../Component/Header';
// import updown from '../Images/updown1.png';
import Modal from 'react-bootstrap/Modal';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form'
import { GamePlatformNumb1 } from '../static';
// import { Multiselect } from 'multiselect-react-dropdown';
// import { GrFormClose } from 'react-icons/gr';
import Button from 'react-bootstrap/Button';
// import { ImArrowLeft, ImArrowRight } from 'react-icons/im';
// import moment from 'moment-timezone';
import Alert from 'react-bootstrap/Alert';
const { strikeReport } = require('../Api');

var timer

class GamesReport extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      Search_Username: '',
      modalShow: true,
      Min_Date: Moment(localStorage.getItem('Min_Date')).toDate(),
      Max_Date: Moment(localStorage.getItem('Max_Date')).toDate(),
      Available: false,
      page: 1,
      countperpage: 12,
      countpagemax: 1,
      countpagemin: 1,
      PlayerReportDetails: [],
      PlayerRepor: [],
      TotalAmount: [],
      notificationType: 'success',
      showNotification: false,
      notificationMessage: '',
      CallID: '',
      Games: [1],
      ModeNumber: '',
      DetailsMode: false,
      EasyMode: false,
      RedMode: false,
    }
  }

  async componentDidMount() {
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)

    if(searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      searchState = JSON.parse(searchState)

      this.setState({ CallID: searchState.id, Games: searchState.Game.split(','), ModeNumber: searchState.Mode, DetailsMode: (searchState.Mode === '0') ? true : false, RedMode: (searchState.Mode === '1') ? true : false, EasyMode: (searchState.Mode === '2') ? true : false  }, ()=> {
        this.getStrikeRecords();
      })
    }
    if ( ( Moment(this.state.Max_Date).format('YYYY-MM-DD')) > Moment(this.state.Min_Date).format('YYYY-MM-DD')){
      this.openNotification('FormDateError')
      this.setState({ notificationType: 'error' });
    } else {
      this.CountTotal();
    }
  }

  async getStrikeRecords() {
    const SearchID = this.state.CallID
    const Game = this.state.Games
    const ModeNumber = this.state.ModeNumber
    const strikeReportDetails = await strikeReport(Moment(this.state.Max_Date).format('YYYY-MM-DD'), Moment(this.state.Min_Date).format('YYYY-MM-DD'), SearchID, Number(ModeNumber), Game, this.props.username, this.props.token)

    if(strikeReportDetails.error === 'Invalid referenceUser') {
      this.openNotification('invalidUsername')
      this.setState({ notificationType: 'error' });
    } else {
      const SelfValues = strikeReportDetails[SearchID] || ''
      this.setState({PlayerReportDetails: SelfValues.sales || [], PlayerRepor: SelfValues['winArray'] || []}, ()=> {
        this.CountTotal();
      })
    }
  }

  async CountTotal() {
    const Objectotal = {
      TotalReport: 0, TotalComm: 0, TotalWin: 0, TotalJin: 0,
    }
    Object.values(this.state.PlayerReportDetails).forEach((item) => {
      Objectotal.TotalReport += Number(item.Upline)
      Objectotal.TotalComm += Number(item.UplineWater)
      Objectotal.TotalWin += Number(item.UplineWin)
      Objectotal.TotalJin += Number(item.UplineProfit)
    })
    this.setState({ TotalAmount: Objectotal })
  }

  async setSelectedDate(Min_Date) {
    this.setState({ Min_Date }, ()=>{
      this.props.setMinDate(Min_Date);
    })
  }

  async MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date }, ()=>{
      this.props.setMaxDate(Max_Date)
    })
  }

  openNotification(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}${Words || ''}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  ModeFunction(ModeName){
    if(ModeName === 'Detail'){
      this.setState({ ModeNumber: 0, DetailsMode: !this.state.DetailsMode, RedMode: false, EasyMode: false }, ()=>{
        this.getStrikeRecords()
      })
    } else if(ModeName === 'Red'){
      this.setState({ ModeNumber: 1, DetailsMode: false, RedMode: !this.state.RedMode, EasyMode: false }, ()=>{
        this.getStrikeRecords()
      })
    } else {
      this.setState({ ModeNumber: 2, DetailsMode: false, RedMode: false, EasyMode: !this.state.EasyMode }, ()=>{
        this.getStrikeRecords()
      })
    }
  }

  GameReport(Key){
    this.setState({ Games: [Number(Key)]}, ()=>{
      this.getStrikeRecords()
    })
  }

  // BigToSmall(items) {
  //   let PlayerReportDetails = this.state.PlayerReportDetails
  //   const ConvertDetails = Object.values(PlayerReportDetails)
  //   if(items === 'Date') {
  //     if(this.state.BigBandS === false || this.state.BigBandS === '') {
  //       ConvertDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
  //       this.setState({ BigBandS: true })
  //     } else {
  //       ConvertDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
  //       this.setState({ BigBandS: false })
  //     }
  //   } else {
  //     if(this.state[`Big${items}`] === false || this.state[`Big${items}`] === '') {
  //       ConvertDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
  //       this.setState({ [`Big${items}`]: true })
  //     } else {
  //       ConvertDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
  //       this.setState({ [`Big${items}`]: false })
  //     }
  //   }
  //   Object.keys(PlayerReportDetails).forEach((dates, index) => {
  //     // PlayerReportDetails[dates] = ConvertDetails[index]
  //   })
  // }

  render() {
    return (
      <Fragment>
        {/* <Alert show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
    <Alert.Heading>{this.state.AlertMessage}</Alert.Heading>
  </Alert> */}
        <Header2 />
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width:  '70%', left:  250 }} show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc row">
          <div className={  "row"} style={{ marginTop: 20, marginBottom: 20, width: 'auto'}}>
            <div style={{ flexDirection: 'column'}}>
              <div className="dcc resultP1P2P3" style={{ marginBottom:  20, width: 'auto', fontWeight: 'bold', padding: 20}}>
                 Strike Records
              </div>
              <div style={{width: '100%' }}>
                <div className="dcc" style={{ marginBottom: '3%'}}>
                  <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>From</div>
                  <DatePicker
                    onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    maxDate={new Date()}
                    // popperPlacement="top-right"
                    selected={this.state.Max_Date}
                    className={"form-control StyleDatePicker"}
                    // withPortal
                  />
                  <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>To</div>
                  <DatePicker
                    onChange = {(date) => this.setSelectedDate(date)}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    maxDate={new Date()}
                    minDate={new Date(this.state.Max_Date)}
                    // popperPlacement="top-right"
                    selected={this.state.Min_Date}
                    className={ "form-control StyleDatePicker"}
                    // withPortal
                  />
                  <Button variant="primary" style={{ marginLeft: 5}} onClick={() => this.getStrikeRecords()} >{language[this.props.currentLanguage].Confirm}</Button>
                </div>
              </div>
              <div className="dcc row" style={{ marginBottom: 20 }}>
                <div className="dcc row" style={{ paddingRight: '5%'}}>
                  <div style={{ fontWeight: 'bold', marginRight: 5 }}>{language[this.props.currentLanguage].DetailedReport}</div>
                  <input
                    checked={this.state.DetailsMode}
                    value={this.state.DetailsMode}
                    style={{ width: 17 , height: 17}}
                    onChange={() => this.ModeFunction('Detail') }
                    readOnly={false}
                    type="checkbox"
                  />
                </div>
                <div className="dcc row" style={{ paddingRight: '5%'}}>
                  <div style={{ fontWeight: 'bold', marginRight: 5 }}>{language[this.props.currentLanguage].Red}</div>
                  <input
                    checked={this.state.RedMode}
                    value={this.state.RedMode}
                    style={{ width: 17, height: 17}}
                    onChange={() => this.ModeFunction('Red') }
                    readOnly={false}
                    type="checkbox"
                  />
                </div>
                <div className="dcc row" style={{ paddingRight: '5%'}}>
                  <div style={{ fontWeight: 'bold', marginRight: 5 }}>{language[this.props.currentLanguage].EasyReport}</div>
                  <input
                    checked={this.state.EasyMode}
                    value={this.state.EasyMode}
                    style={{ width: 17, height: 17}}
                    onChange={() => this.setState({ ModeNumber: 2, EasyMode: (this.state.EasyMode === true) ? false : true }, ()=> {
                      this.setState({ DetailsMode: false, RedMode: false })
                    })}
                    readOnly={false}
                    type="checkbox"
                  />
                </div>  
              </div>
              <div className="dcc" style={{display: 'flex', paddingBottom: 5}}>
                {[1,2,3,4,5,6,7,8].map((item, index) => {
                  return <Form.Check
                    style={{ fontSize: 20, alignItems: 'center', display: 'flex', padding: 5 }}
                    inline
                    label={GamePlatformNumb1[item]}
                    type="checkbox"
                    checked={this.state.Games.includes(item)}
                    onChange={(e) => this.GameReport(item)}
                  />
                })}
              </div>
            </div>
          </div>
          {this.state.loading  === true ? (
            <div class="divLoader">
              <img src={'https://i.imgur.com/GLdqYB2.gif'} alt="img"
                style={{ marginTop: 50, width: 200, height: 200, marginRight: 200}}
              />
            </div>
          ) : (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
              <div style={{ width: '100%', display: 'flex', flexDirection:  'row'}}>
                <div className="TablePadding" style={{ width:  '40%'}}>
                  <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
                    <thead>
                      <tr>
                        <th><div style={{ wmarginLeft: 0}}>{language[this.props.currentLanguage].Date}</div></th>
                        <th><div>{language[this.props.currentLanguage].SalesReport}</div></th>
                        <th><div>{language[this.props.currentLanguage].Commission}</div></th>
                        <th><div>{language[this.props.currentLanguage].Win}</div></th>
                        <th><div>净</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.PlayerReportDetails.length === 0 
                        ?
                        <>
                          <tr style={{ width: '100%', backgroundColor: '#C0C0C0', fontWeight: 'bold', fontSize: '20px'}}>
                            <td colSpan="5"> No Data</td>
                          </tr>
                        </>
                        :
                        <>
                          {Object.values(this.state.PlayerReportDetails).map((item, idx) => {
                            return(
                              <tr index={idx}>
                                <td><div>{Moment(Object.keys(this.state.PlayerReportDetails)[idx]).format('DD/MM')}</div></td>
                                <td><div>{item.Upline}</div></td>
                                <td ><div>{item.UplineWater}</div></td>
                                <td><div>{item.UplineWin}</div></td>
                                <td><div>{item.UplineProfit}</div></td>
                              </tr>
                            );
                          })}
                          <tr style={{ backgroundColor: '#ebf4fc'}}>
                            <td><div style={{ fontWeight: 'bold' }}>{'Total (RM)'}</div></td>
                            <td><div>{`${Number(this.state.TotalAmount.TotalReport).toFixed(2)}`}</div></td>
                            <td><div>{`${Number(this.state.TotalAmount.TotalComm).toFixed(2)}`}</div></td>
                            <td><div>{`${Number(this.state.TotalAmount.TotalWin).toFixed(2)}`}</div></td>
                            <td><div>{`${Number(this.state.TotalAmount.TotalJin).toFixed(2)}`}</div></td>
                          </tr>
                        </>
                      }
                    </tbody>
                  </Table>
                </div>
                <div style={{  paddingLeft:  0, marginRight: 5, width:  '60%' }}>
                  <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
                    <thead>
                      <tr>
                        <th><div style={{ wmarginLeft: 0}}></div>Date</th>
                        <th><div>{language[this.props.currentLanguage].Receipt} #</div></th>
                        <th><div>Prize</div></th>
                        <th><div>GR</div></th>
                        <th><div>{language[this.props.currentLanguage].nombor}</div></th>
                        <th><div>{language[this.props.currentLanguage].Bet}</div></th>
                        <th><div>{language[this.props.currentLanguage].Win}</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.PlayerRepor.length === 0 
                        ? 
                        <>
                          <tr style={{ width: '100%', backgroundColor: '#C0C0C0', fontWeight: 'bold', fontSize: '20px'}}>
                            <td colSpan="7"> No Data</td>
                          </tr>
                        </>
                        :
                        <>
                          {this.state.PlayerRepor.map((item, idc) => {
                            return(
                              <tr index={idc} style={{ backgroundColor: '#ebf4fc'}}>
                                <td><div>{Moment(item.Date).format('DD/MM')}</div></td>
                                <td><Link  to={{ pathname: "", data: `${item.OrderID}` }} 
                                >{`[#${item.OrderID}]`}</Link></td>
                                <td><div>{item.Position}</div></td>
                                <td><div>{item.BetMode}</div></td>
                                <td><div>{item.Word}</div></td>
                                <td><div>{item.Bet}</div></td>
                                <td><div>{item.Strike}</div></td>
                              </tr>
                            );
                          })}
                        </>
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="dcc row" style={{ width: '100%', marginTop: 50, marginBottom: 10 }}>
                <div style={{ marginRight: 20, fontWeight: 'bold'}}>{`Total Sales : RM ${Number(this.state.TotalAmount.TotalReport).toFixed(2)}`}</div>
                <div style={{ marginRight: 20, fontWeight: 'bold'}}>{`Total Comm : RM ${Number(this.state.TotalAmount.TotalComm).toFixed(2)}`}</div>
                <div style={{ marginRight: 20, fontWeight: 'bold'}}>{`Total Win : RM ${Number(this.state.TotalAmount.TotalWin).toFixed(2)}`}</div>
                <div style={{ fontWeight: 'bold' }}>{`Total Net : RM ${Number(this.state.TotalAmount.TotalJin).toFixed(2)}`}</div>
              </div>
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.modalShow}
                contentClassName="br23"
              >
                <Modal.Body className="dcc" style={{ padding: 10, height: 'auto' }}>
                  <div style={{flexDirection: 'row', width: 'auto'}}>
                    {/* <Alert show={this.state.showNotificationError} variant={this.state.notificationTypeError === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.AlertMessageError}</Alert.Heading>
          </Alert> */}
                    <div className="dcc" style={{ width: '100%', marginBottom: 0 }}>
                      <div style={{fontSize: "18px", width: 200,  textAlign: 'center'}}>Account Report</div>
                    </div>
                    <div className="dcc" style={{ marginTop: 20, marginBottom: 20, width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>From</div>
                      <DatePicker
                        onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                        dateFormat = 'dd-MMM-yyyy'
                        placeholder="Date"
                        maxDate={new Date()}
                        // popperPlacement="top-right"
                        selected={this.state.Max_Date}
                        className={ "form-control StyleDatePicker"}
                        // withPortal
                      />
                      <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>To</div>
                      <DatePicker
                        onChange = {(date) => this.setSelectedDate(date)}
                        dateFormat = 'dd-MMM-yyyy'
                        placeholder="Date"
                        maxDate={new Date()}
                        minDate={new Date(this.state.Max_Date)}
                        // popperPlacement="top-right"
                        selected={this.state.Min_Date}
                        className={"form-control StyleDatePicker"}
                        // withPortal
                      />
                    </div>
                    <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
			        <div>
				        <button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: 300 }} 
                          disabled={this.state.disabled}
                          onClick={() => this.setState({modalShow: false, Available: true})} 
                          class="btn my-cusomized-button" >
                  提交
                        </button>
			        </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {/* <div style={{ marginBottom: 0, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                {this.state.page > this.state.countpagemin ? (
                  <button  style={{ textAlign: 'center',backgroundColor: '#1e5e8a', color: 'white', width: 100, borderColor: '#1e5e8a' }} 
                    class="btn my-cusomized-button" 
                    onClick={() =>
                      this.setState(
                        {
                          page: this.state.page - 1,
                        },
                        () => {
                          this.changePage(this.state.page);
                        },
                      )
                    }>
                    <ImArrowLeft size="16px" color="white" />
                  </button>
                ) : (
                  <div style={{ width: 100 }} />
                )}
                <div >{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <button
                    style={{ textAlign: 'center', backgroundColor: '#1e5e8a', color: 'white', width: 100, borderColor: '#1e5e8a' }}
                    class="btn my-cusomized-button" 
                    onClick={() =>
                      this.setState(
                        {
                          page: this.state.page + 1,
                        },
                        () => {
                          this.changePage();
                        },
                      )
                    }>
                    <ImArrowRight size="16px" color="white" />
                  </button>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div> */}
            </div>
          )}
        </div>
      </Fragment>    
    )
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GamesReport);