import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
// import { Form } from 'react-bootstrap';
// import moment from 'moment-timezone';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
// import { Navbar, Nav, Container, DropdownButton, Dropdown } from 'react-bootstrap';
// import {
//   EmailShareButton,
//   EmailIcon,
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   LineShareButton,
//   LineIcon,
//   WhatsappShareButton,
//   WhatsappIcon,
// } from "react-share";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { RiArrowDropDownFill } from 'react-icons/ri';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from 'react-bootstrap/Button';
import { language } from '../language';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Moment from 'moment-timezone';
import { Alert } from 'react-bootstrap';
var timer
const { getSalesReport } = require('../Api');

class Page2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: this.props.username,
      TodayDate: new Date(),
      SearchValue: '',
      TextResult: '',
      showShare: true,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      ChooseStatus: 300,
      Search_Username: '',
      modalShow: false,
      Max_Date: Moment(new Date()).toDate(),
      SelectedDate: Moment(localStorage.getItem('SELECTED_DATE')).toDate(),
      ArrayData: [],
      KeyArray: [],
      notificationMessage: '',
      notificationType: 'success',
      showNotification: false,
      ShowUserAccID: [],
      userID: this.props.userID,
      BackToPreviousData: false,
      BackToNormalUrl: false,
      TotalGameValue: []
    }
    this.SelectedOptionStatus = this.SelectedOptionStatus.bind(this);
  }

  toggleResult(platform) {
    this.setState({
      [`show${platform}`]: !this.state[`show${platform}`],
    })
  }

  async componentDidMount() {
    this.DisplayData();
  }

  async DisplayData(){
    const CallApiData = {
      userID: this.state.userID,
      fromDate: Moment(this.state.Max_Date).format('YYYY-MM-DD'),
      toDate: Moment(this.state.SelectedDate).format('YYYY-MM-DD'),
    }
    const ArrayDataDisplay = await getSalesReport( CallApiData, this.props.username, this.props.token)
    if (ArrayDataDisplay.message === "No Data") {
      this.setState({ArrayData: [], KeyArray: [], TotalGameValue: []})
      this.setState({ notificationType: 'error', userID: this.props.userID }, () => {
        this.openNotification('NO DATA')
        // this.DisplayData();
      });
    } else {
      const KeysArray = Object.keys(ArrayDataDisplay)
      var carIndex = KeysArray.indexOf("totalObject");
      KeysArray.splice(carIndex, 1);
      this.setState({ArrayData: ArrayDataDisplay, KeyArray: KeysArray, TotalGameValue: ArrayDataDisplay.totalObject})
    }
  }

  async setSelectedDate(SelectedDate) {
    this.setState({ SelectedDate }, ()=>{
      this.props.setMinDate(SelectedDate);
      this.DisplayData();
    })
  }

  async MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date }, ()=>{
      this.props.setMaxDate(Max_Date)
      this.DisplayData();
    })
  }
  
  async componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.location
    ) {
      const { status } = this.props.location
      if( status === 'water'){
        this.setState({BackToNormalUrl: true})
      }
      if( status === 'ShowAllSalesReport'){
        this.setState({BackToNormalUrl: false})
      }
    }
  }

  SelectedOptionStatus(event) {
    this.setState({ChooseStatus: event.target.value});
  }

  async Downloadfile(){
  }

  // async onKeyEnter(event) {
  //   if (event.key === 'Enter') {
  //     const SearchIDArrayDataDisplay = await searchUser(  this.state.Search_Username , this.props.username, this.props.token)
  //     if (SearchIDArrayDataDisplay.message === 'No Downline'){
  //       this.setState({ notificationType: 'error', userID: this.props.userID }, () => {
  //         this.openNotification('NO DATA')
  //       });
  //     } else {
  //       this.setState({modalShow: true, ShowUserAccID: SearchIDArrayDataDisplay,  BackToPreviousData: true})
  //     }
  //   }
  // }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success'
      });
      // window.location.reload();
    }, 1500);
  };

  CheckopenNotification(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}${Words || ''}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  render() {
    return (
      // <div style={{ width: '100%', backgroundColor: '#EFEFEF', minHeight: 950, flexDirection: 'column' }}>
      <div style={{ marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'space-between', minHeight: 350, flexDirection: 'column' }}>
        <Header2 />
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 20,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft:  '3.5%',
            flexDirection:'row',
          }}>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.modalShow}
            contentClassName="br23"
          >
            <Modal.Body className="dcc" style={{ padding: 10, height: 'auto' }}>
              <GrFormClose
                className='CloseButtonModal'
                onClick={() => {
                  this.setState({ modalShow: false,BackToPreviousData: false })
                }}
                size="35px"
                color="#000"
              />
              <div style={{flexDirection: 'row', width:'100%'}}>
                <div className="dcc" style={{ width: '100%', marginBottom: 0 }}>
                  <div style={{fontSize: "18px", width: 200,  textAlign: 'center'}}>User Account</div>
                </div>
                <Table responsive="sm" striped bordered hover style={{ width: '100%'}}>
                  <thead style={{ backgroundColor: 'lightsteelblue' }}>
                    <tr>
                      <th> ID </th>
                      <th> Username </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.ShowUserAccID.map((item, index) => (
                      <tr  onClick={() => this.setState({ userID: item.ID, modalShow: false} , ()=>{
                        this.DisplayData()
                      })}>
                        <td style={{ verticalAlign: 'middle'}}>{item.ID}</td>
                        <td style={{ verticalAlign: 'middle'}}>{item.Username}</td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          </Modal>
          {/* <div className="dcc" style={{ marginTop: 20, marginBottom: 20, width: (isMobile) ? 350 : '100%'}}>
            <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>From</div>
                <DatePicker
                    onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    maxDate={new Date()}
                    popperPlacement="top-right"
                    selected={this.state.Max_Date}
                    className={(isMobile) ? "form-control StyleDatePickerMobile" : "form-control StyleDatePicker"}
                    withPortal
                  />
                <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>To</div>
                <DatePicker
                  onChange = {(date) => this.setSelectedDate(date)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  popperPlacement="top-right"
                  selected={this.state.Min_Date}
                  className={(isMobile) ? "form-control StyleDatePickerMobile" :"form-control StyleDatePicker"}
                  withPortal
                />
          </div>
          <div className="dcc">
          <input
              style={{ fontSize: "18px", textAlign: 'center', marginLeft: (isMobile) ? 0 : 30, width: (isMobile) ? '60%' : 300, marginBottom: (isMobile) ? '5%' : null }} 
              value={this.state.Search_Username}
              class="form-control"
              readOnly={false}
              onChange={e => this.setState({ Search_Username: e.target.value })}
              onKeyPress={(e) => this.onKeyEnter(e)}
              placeholder={'登入/名称'} 
          /></div>
          <div className="dcc" style={{ marginRight: (isMobile) ? null :20 }}>
            <div className="TitleUsername"> ID - {this.state.Username} </div>
          </div> */}
          {/* <div style={{ flexDirection: 'row', width: '100%'}}>
            <div className="dcc" style={{ width: '30%'}}>
              <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>From</div>
                  <DatePicker
                      onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                      dateFormat = 'dd-MMM-yyyy'
                      placeholder="Date"
                      maxDate={new Date()}
                      popperPlacement="top-right"
                      selected={this.state.Max_Date}
                      className={(isMobile) ? "form-control StyleDatePickerMobile" : "form-control StyleDatePicker"}
                      withPortal
                    />
                  <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>To</div>
                  <DatePicker
                    onChange = {(date) => this.setSelectedDate(date)}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    maxDate={new Date()}
                    popperPlacement="top-right"
                    selected={this.state.Min_Date}
                    className={(isMobile) ? "form-control StyleDatePickerMobile" :"form-control StyleDatePicker"}
                    withPortal
                  />
            </div>
            <div  style={{ width: '30%'}}>
              <input
                  style={{ fontSize: "18px", textAlign: 'center', marginLeft: (isMobile) ? 0 : 30, width: (isMobile) ? '60%' : 300, marginBottom: (isMobile) ? '5%' : null }} 
                  value={this.state.Search_Username}
                  class="form-control"
                  readOnly={false}
                  onChange={e => this.setState({ Search_Username: e.target.value })}
                  onKeyPress={(e) => this.onKeyEnter(e)}
                  placeholder={'登入/名称'} 
              />
            </div>
            <div style={{ width: '30%'}}>
              <div className="TitleUsername"> ID - {this.state.Username} </div>
            </div>
          </div> */}



          <div style={{ justifyContent: 'center', display: 'flex' }} >
            <div style={{fontSize: "18px", width: 100, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Date} :</div>
            <DatePicker
              onChange = {(Date) => this.setSelectedDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              maxDate={new Date()}
              // popperPlacement="top-right"
              selected={this.state.SelectedDate}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <div className="dcc" style={{ marginRight:   20 }}>
            <div className="TitleUsername"> ID - {this.props.username} </div>
          </div>

        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: '1%',
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft:  '1.5%',
            flexDirection: 'row',
            width: '80%'
          }}>
          <div className="TitleUsername" style={{ width: '30%',}}> {`${language[this.props.currentLanguage].Displayall}`} (来) : </div>
          {(this.state.BackToPreviousData === true) ? (
            <Button style={{ width: '25%'}} variant="success" disabled={this.props.AddDisable }  onClick={() => this.setState({BackToPreviousData: false, userID: this.props.userID}, ()=>{
              this.DisplayData();
            })}>
              {'Back Previous'}
            </Button>
          ) : (
            <></>
          )}
          {(this.state.BackToNormalUrl === true) ? (
          // <Button style={{ width: (isMobile) ? 100 : '25%'}} variant="success" disabled={this.props.AddDisable }  onClick={() => this.setState({BackToPreviousData: false, userID: this.props.userID}, ()=>{
          //   this.DisplayData();
          // })}>
          //   {'Back'}
          // </Button>
            <Link to={{ pathname: "/ShowAllSalesReport", data:'ShowAllSalesReport', status: 'ShowAllSalesReport'}}  style={{ width: 'auto', flexDirection: 'column',borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }}>
              <div onClick>{'Back'}</div>
            </Link >
          ) : (
            <></>
          )}
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection:  'row',
          }}>
          <div style={{ width: '90%', marginBottom: '4%'}}>
           
     
           
            <Table responsive="sm" striped bordered hover style={{ width: '80%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
              <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                <tr>
                  {/* ['M','K','T','S','W','B','8'], */}
                  { Object.keys(this.state.TotalGameValue).map((item)=>{
                    return   <th style={{ 
                      fontWeight: 'bold',
                      minWidth: 100,
                      backgroundColor: 
                      (item === '1') ? '#fff23e' : 
                        (item === '2') ? '#414bb2' : 
                          (item === '3') ? 'red': 
                            (item === '4') ? '#32c6f4':
                              (item === '5') ? '#008000':
                                (item === '6') ? '#fc0404': 
                                  (item === '7') ? '#f8c100': 
                                    '#6d100e', 
                      color: (item === '1') ? 'black' : (item === '7') ? 'black' : (item === '4') ? 'black' : 'white'}} >
                      {(item === '1') ? `${language[this.props.currentLanguage].magnum}` : 
                        (item === '2') ? `${language[this.props.currentLanguage].damacai}` : 
                          (item === '3') ? `${language[this.props.currentLanguage].toto}`: 
                            (item === '4') ? `${language[this.props.currentLanguage].singapore}`:
                              (item === '5') ? `${language[this.props.currentLanguage].sarawak}`:
                                (item === '6') ? `${language[this.props.currentLanguage].sabah}`: 
                                  (item === '7') ? `${language[this.props.currentLanguage].sandakan}`: 
                                    (item === '9') ? `Lotto 9`: 
                                      `${language[this.props.currentLanguage].GrandDragon}` }
                    </th>
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Object.keys(this.state.TotalGameValue).map((item, index) => (
                    <th>
                      {(this.state.TotalGameValue[item] ) ?  
                        this.state.TotalGameValue[item]['total'] : 
                        0 
                      }
                    </th>
                  ))}
                </tr>
              </tbody>
            </Table>
      
          </div>
        </div>
        <div className= "dcc " style= {{ width:  '90%',  flexDirection: 'column', justifyContent: 'center'}}>
          <div style= {{ width: '90%'}} >
            { this.state.KeyArray.map((item) => (
   
              <Accordion>
                <Card style={{ border: 'none', marginBottom: 20 }}>
                  <Card.Header style={{ backgroundColor: '#3c7ca7', borderRadius: 0 }}>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{ width: '100%', textAlign: 'left', color: 'white' }}>
                      <span>
                        <span style={{ marginLeft: 10, color: 'white', fontWeight: 'bold' }}>{(item === '1') ? `${language[this.props.currentLanguage].magnum}` : 
                          (item === '2') ? `${language[this.props.currentLanguage].damacai}` : 
                            (item === '3') ? `${language[this.props.currentLanguage].toto}`: 
                              (item === '4') ? `${language[this.props.currentLanguage].singapore}`:
                                (item === '5') ? `${language[this.props.currentLanguage].sarawak}`:
                                  (item === '6') ? `${language[this.props.currentLanguage].sabah}`: 
                                    (item === '7') ? `${language[this.props.currentLanguage].sandakan}`: 
                                      (item === '9') ? `Lotto 9`: 
                                        `${language[this.props.currentLanguage].GrandDragon}` }</span>
                      </span>
                      <RiArrowDropDownFill
                        style={{ position: 'absolute', right: 20, top: 0, cursor: 'pointer', color: 'white' }}
                        size="35px"
                      />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="introductionCard">
                        <div className="introductionCardBody">
                          <div style={{ overflowX: 'auto'}}>
                            {/* <div style={{ overflowY: 'scroll', height:'300px'}}> */}
                            <Table striped bordered hover style={{ width: '100%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white', }}>
                              {/* <div style={{ overflowY: 'scroll', height:'300px'}}> */}
                              <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                                <tr>
                                  {['B', 'S', 'A1', 'A1C', 'A'].map((KeyValue, index) => (
                                    (this.state.ArrayData[item][KeyValue].length > 1) &&<th keys={index}  style={{ width: 'calc(100% / 5)'}}>
                                      <div style={{ width: '100%'}}>
                                        {KeyValue}
                                      </div>
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {['B', 'S', 'A1', 'A1C', 'A'].map((KeyValue, index) => (
                                    (this.state.ArrayData[item][KeyValue].length > 1) &&<th keys={index}  style={{ width: 'calc(100% / 5)'}}>
                                      {/* <div style={{ overflowY: 'scroll', height:'300px'}}> */}
                                      <div className="row" style={{ width: '100%',  overflowY: 'scroll', height: (this.state.ArrayData[item][KeyValue].length > 0) ?'300px' : ''}}>
                                        <div style={{ width: '50% '}}>
                                          {this.state.ArrayData[item][KeyValue].map((number) => (
                                            <Link to={{ pathname: "/water", data:`${number.A}`, status: 'water'}}  style={{ width: 'auto', flexDirection: 'column',borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }}>
                                              <div>{number.W}</div>
                                            </Link >
                                          ))}
                                        </div>
                                        <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                          {this.state.ArrayData[item][KeyValue].map((number) => (
                                            <div style={{flexDirection: 'column'}}><div>{number.A}</div></div>
                                          ))}
                                        </div>
                                      </div>
                                      {/* </div> */}
                                    </th>
                                  ))} 
                                </tr>
                              </tbody>
                            </Table>
                            {/* </div> */}
                            <div style={{ marginTop: '2%'}}>
                              <Table striped bordered hover style={{ width: '100%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
                                <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                                  <tr>
                                    {['C', 'A2', 'A3', 'D4', 'E4'].map((KeyValue, index) => (
                                      // (Object.keys(this.state.ArrayData[item][KeyValue]).length !== 0 && Object.values(this.state.ArrayData[item][KeyValue]).length !== 0) ? (
                                      (this.state.ArrayData[item][KeyValue].length > 1) &&<th keys={index}  style={{ width: 'calc(100% / 5)'}}>
                                        <div style={{ width: '100%'}}>
                                          {KeyValue}
                                        </div>
                                      </th>
                                      // ) : (
                                      //   <></>
                                      // )
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {['C', 'A2', 'A3', 'D4', 'E4'].map((KeyValue, index) => (
                                      //  (Object.keys(this.state.ArrayData[item][KeyValue]).length !== 0 && Object.values(this.state.ArrayData[item][KeyValue]).length !== 0) ? (
                                      (this.state.ArrayData[item][KeyValue].length > 1) &&<th keys={index}  style={{ width: 'calc(100% / 5)'}}>
                                        {/* <div style={{ overflowY: 'scroll', height:'300px'}}> */}
                                        <div className="row" style={{ width: '100%', overflowY: 'scroll', height:(this.state.ArrayData[item][KeyValue].length > 0) ?'300px' : ''}}>
                                          <div style={{ width: '50% '}}>
                                            {this.state.ArrayData[item][KeyValue].map((number) => (
                                              <Link to={{ pathname: "/water", data:`${number.A}`}}  style={{ width: 'auto', flexDirection: 'column',borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }}>
                                                <div>{number.W}</div>
                                              </Link >
                                            ))}
                                          </div>
                                          <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                            {this.state.ArrayData[item][KeyValue].map((number) => (
                                              <div style={{flexDirection: 'column'}}><div>{number.A}</div></div>
                                            ))}
                                          </div>
                                        </div>
                                        {/* </div> */}
                                      </th>
                                    ))}
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div style={{ marginTop: '2%'}}>
                              <Table striped bordered hover style={{ width: '100%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
                                <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                                  <tr>
                                    {['5D', '6D'].map((KeyValue, index) => (
                                      (this.state.ArrayData[item][KeyValue].length > 1) && <th keys={index}  style={{ width: 'calc(100% / 5)'}}>
                                        <div style={{ width: '100%'}}>
                                          {KeyValue}
                                        </div>
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {['5D', '6D'].map((KeyValue, index) => (
                                      (this.state.ArrayData[item][KeyValue].length > 1) && <th keys={index}  style={{ width: 'calc(100% / 5)'}}>
                                        {/* <div style={{ overflowY: 'scroll', height:'300px'}}> */}
                                        <div className="row" style={{ width: '100%', overflowY: 'scroll', height:(this.state.ArrayData[item][KeyValue].length > 0) ?'300px' : ''}}>
                                          <div style={{ width: '50% '}}>
                                            {this.state.ArrayData[item][KeyValue].map((number) => (
                                              <Link to={{ pathname: "/water", data:`${number.A}`}}  style={{ width: 'auto', flexDirection: 'column',borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }}>
                                                <div>{number.W}</div>
                                              </Link >
                                            ))}
                                          </div>
                                          <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                            {this.state.ArrayData[item][KeyValue].map((number) => (
                                              <div style={{flexDirection: 'column'}}><div>{number.A}</div></div>
                                            ))}
                                          </div>
                                        </div>
                                        {/* </div> */}
                                      </th>
                                    ))}
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}  
          </div>
        </div>
      </div >
    );
  }
}
// export default Page2;

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Page2);