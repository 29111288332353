import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import { Button, Alert, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';

class ConfirmDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  render() {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.OpenChangePassword}
        contentClassName="br23">
        <Modal.Body className="dcc" style={{ padding: 10, width: 'auto' }}>
          <div style={{flexDirection: 'row', width: '100%', backgroundColor: 'white'}}>
            <GrFormClose
              className='CloseButtonModal'
              onClick={() => {
                this.props.propsSetState({ OpenChangePassword: false, UserUsername: '', UserPassword: '' })
              }}
              size="35px"
              color="#000"
            /> 
            <div className='dcc ConfirmDeleteContainer'>
              <div className='ConfirmDeleteContainer2'> {language[this.props.currentLanguage].ConfirmAccount}</div>
              <Alert style={{ zIndex: 99, width: '80%', left: 0 }} show={this.props.showNotification} variant={this.props.showNotificationpass === 'error' ? 'danger' : 'success'}>
                <Alert.Heading>{this.props.notificationMessage}</Alert.Heading>
              </Alert>
              <div className='dcc ConfirmDTextCon' style={{ width: '80%' }}>
                <div style={{ width: '20%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Password} :</div>
                <div className='dcc' style={{ width: '60%', flexDirection: 'row' }}>
                  <Form.Control className='ConfirmDeleteText'
                    type={(this.props.ShowPasswordNew === true) ? 'text' : 'password'} maxLength='6' onKeyPress={(e) => this.props.onKeyEnterDelete(e)}
                    value={this.props.UserPassword} onChange={(e)=> this.props.propsSetState({ UserPassword: e.target.value }) }/>
                  {this.props.ShowPasswordNew === false
                    ? <FaEyeSlash style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.props.propsSetState({ShowPasswordNew: true })}/>
                    : <FaEye style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.props.propsSetState({ShowPasswordNew: false })}/> 
                  }
                </div>
              </div>
              <div className='dcc ConfirmDButtonContainer'>
                <div style={{ width: 'auto'}}>
                  <Button variant="primary" id="ChangePassButton" onClick={() => this.props.ConfirmAccount('ConfirmAccount')}>{language[this.props.currentLanguage].ConfirmDeleteUsers}</Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDelete);