import '../App.css';
import React from 'react';
// import Footer from '../Component/Footer';
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
// import Modal from 'react-bootstrap/Modal';
import Header from '../Component/Header';
import {  Alert, Button, Table,Form } from 'react-bootstrap';
// CreateBetOrder API
import { getInformation, updateInformation, deleteInformation, insertInformation } from '../Api';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import { language } from '../language';
// import Loading3 from '../Images/loading-3.gif';
// import Dropzone from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';
// import CreateNews from '../Page/CreateNews';
var timer
// import { language } from '../language';
// import { findPermutations } from '../utility/Permutation'
// import { isCompositeComponent } from 'react-dom/test-utils';
// import Dropzone from 'react-dropzone';
const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class LatestNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY'),
      FileName: '',
      FileResultWord: '',
      FinalResult: [],
      hightlight: false,
      disabled: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      optionsUsername: '',
      getUsers: [],
      userID: this.props.userID,
      loading: false,
      ShowReceipt: false,
      ReceiptDisplay: '',
      files: [],
      base64URL: '',
      NewsOption: [],
      OpenModal: false,
      CreateStatus: '',
      FileLinkWord: '',
      ReFileLinkWord: '',
      ReFileResultWord: '',
      PackageID: '',
      StatusModal: '',
      AlertModal: false,
      RecordDeletedNews: '',
      PopScrollBackButton: false,
    }
    this.UploadFiletxt = this.UploadFiletxt.bind(this);
    this.ConfirmSaveBet = this.ConfirmSaveBet.bind(this);
    this.CleanData = this.CleanData.bind(this);
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount(){
    this.APIRefresh()
  }
  

  async APIRefresh(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if ((UserAuthorities && UserAuthorities.Bet) === 0) {
      window.location.href='/Home'; 
    }
    // this.getDownline();
    this.getNewsInfor();
  }

  async getNewsInfor() {
    const resultInformation = await getInformation('all',this.props.username, this.props.token)
    this.setState({NewsOption: resultInformation})
  }

  // async getDownline() {
  //   const UserID = this.props.userID;
  //   const Username = this.props.username;
  //   const token = this.props.token
  //   let tempArray = []
  //   let tempObject = {}

  //   const DownlineUsername = await getMyDownline(UserID, token, Username)
  //   DownlineUsername.forEach((item) => {
  //     tempObject = {
  //       ...tempObject,
  //       value: item.ID,
  //       label: item.Username,
  //     }
  //     tempArray.push(tempObject)
  //   })
  //   if(DownlineUsername) {
  //     this.setState({ getUsers: DownlineUsername, userID: DownlineUsername[0].ID, SelectedUser: tempArray[0], optionsUsername: tempArray })
  //   }
  // }

  async UploadFiletxt(event) {
    if (event !== undefined) {
      const files = event.target.files;
      event.preventDefault()
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.setState({ FileResultWord: /^[0-9#*-.\b]+$/.test(text) })
        // this.setState({ FileResultWord: text }, ()=> {
        //   const Array = this.state.FileResultWord.split('\n')
        //   let Arraynew = []
        //   for(var i=0; i < Array.length; i++) {
        //     const RemoveR = Array[i].replace("\r", "").replace("\r", "")
        //     Arraynew.push(RemoveR)
        //   }
        //   this.setState({ FileResultWord: Arraynew.join('\n') })
        // })
      };
      if (event.target.files) {
        reader.readAsText(event.target.files[0])
        this.setState({ FileName: files[0].name })
      } 
    }
  }

  async ConfirmSaveBet( Status ) {
    // this.setState({loading: true});
    // const UserID = this.state.userID
    // let originalText = this.state.FileResultWord;
    if (Status === 'Edit'){
      // if (this.state.FileLinkWord === ''){
      //   this.setState({notificationType: 'error'}, ()=>{
      //     this.openNotification('Cannotemptytext')
      //   })
      // } else 
      if (this.state.FileResultWord === ''){
        this.setState({notificationType: 'error'}, ()=>{
          this.openNotification('Cannotemptytext')
        })
      } else {
        const updateInformationResult = await updateInformation(this.state.PackageID, this.state.FileResultWord,this.state.FileLinkWord, this.props.username, this.props.token,)
        if (updateInformationResult.message === 'Info Updated'){
          this.setState({OpenModal: false}, ()=>{
            this.openNotification('InfoUpdated')
          })
        }
      }
    } else {
      if (this.state.FileResultWord === ''){
        this.setState({notificationType: 'error'}, ()=>{
          this.openNotification('Cannotemptytext')
        })
      } else {
        const insertInformationResult = await insertInformation(this.state.FileLinkWord,  this.state.FileResultWord,this.props.username, this.props.token,)
        if (insertInformationResult.message === 'New Info Added'){
          this.setState({OpenModal: false, }, () => {
            this.openNotification('NewInfoAdded')
            this.APIRefresh();
          })
        }
      }
    }
  }

  async ConvertResultRed(Receipt) {
    let Word = Receipt
    let returnWord = [];

    if (Word.includes('<red>')) {
      const WordArray = Word.split('<red>').join('</red>').split('</red>');
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{color: 'red'}}>
              {WordArray[i]}
            </span>,
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({ReceiptDisplay: returnWord});
    } else {
      this.setState({ReceiptDisplay: Word});
    }
  }

  async CleanData(statusReset) {
    if (statusReset === 'Reset'){
      this.setState({ FileLinkWord: this.state.ReFileLinkWord, FileResultWord: this.state.ReFileResultWord })
    }else {
      this.setState({ FileLinkWord: '', FileResultWord: '' }, () => {
      // this.UploadFiletxt()
      })
    }
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.state.disabled) return;
  
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }


  onDrop = (event) => {
    if (!event.length > 1) {
      if (event && event[0]) {
        let img = event;
        this.getBase64(img)
          .then(result => {
            img["base64"] = result;
            this.setState({
              base64URL: result,
              img
            });
          })
      }
      this.setState({hightlight: false, files: event,})
 
    } else {
      const newImagesPromises = []
      for (let i = 0; i < event.length; i++) {
        let img = event;
        this.getBase64(event[i]).then(result => {
          img["base64"] = result;
          newImagesPromises.push(result)
        // this.setState({
        //   base64URL: result,
        //   img
        // });
        })
      }
      this.setState({hightlight: false, files: event, base64URL: newImagesPromises, })
    }
  };
  getBase64 = file => {
    return new Promise(resolve => {
      // let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  openFileDialog() {
    if (this.state.disabled) return
    this.fileInputRef.current.click()
  }

  openNotification(message) {
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        FileLinkWord: '',
        FileResultWord: '',
        notificationType: 'success',
        disabled: false,
      });
      this.APIRefresh();
      // this.CleanData()
    }, 3000);
  };

  toggleEditNewsForm(item){
    this.setState({FileLinkWord: item.Image,  FileResultWord: item.Information, 
      ReFileLinkWord: item.Image,  ReFileResultWord: item.Information, 
      TodayDate: Moment(item.DateTime).format('DD/MM/YYYY'), PackageID: item.ID,
      OpenModal: true, StatusModal: 'Edit',
    })
  }

  ChangeReceipt(event, status) {
    const FilterValue = event.target.value
    // const PressNumber2 = PressNumber.replace(/[^0-9D.*#+-^]/g, '')
    if (status === 'Imagelink'){
      this.setState({ FileLinkWord: FilterValue })
    } else {
      this.setState({ FileResultWord: FilterValue })
    }
  }

  async DeletedNews(StatusID){
    const deleteInformationREsult = await deleteInformation(StatusID, this.props.username, this.props.token,)
    if (deleteInformationREsult.message === 'Info Deleted'){
      this.setState({OpenModal: false}, ()=>{
        this.openNotification('SuccessDeletedVIP')
        this.APIRefresh();
      })
    }
  }

  
  // handleScroll = (event) => {    
  //   var node = event.target;
  //   const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
  //   if (bottom) {
  //     this.setState({PopScrollBackButton: bottom})
  //   } else {
  //     this.setState({PopScrollBackButton: bottom})
  //   }
  // }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  render() {
    // const files = (this.state.files && this.state.files).map(file => (
    //   <li key={file.name}>
    //     {file.name} - {file.size} bytes
    //   </li>
    // ));
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', backgroundColor: '#EFEFEF', height: '100vh', flexDirection: 'column', overflowY: 'auto' }}>
        <Header />
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div   ref={this.elScroll.elRef} className="row" style={{ marginBottom: 30, display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '20px' }}>
          <div className="dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white' }}>
            <FaHome size="25px" color="white" className="logohome" style={{ marginRight: 10 }} />
            {language[this.props.currentLanguage].NewsManage}
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 20,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft:  '3.5%',
            flexDirection: 'row',
            width: '95%',
            marginBottom: 0,
          }}>
          <div className="TitleDate">{language[this.props.currentLanguage].Date}: {this.state.TodayDate}</div>
          <div className="dcc" style={{ marginRight:  20 }}>
            <div className="TitleUsername"> ID - {this.state.username} </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'right', marginBottom: 10, marginTop: '1%',marginRight: '10%' }}>
          <Button  className='button2' variant="primary" onClick={() => this.setState({ StatusModal: 'Create', TodayDate: Moment(new Date()).format('DD/MM/YYYY'), OpenModal: !this.state.OpenModal })} >{language[this.props.currentLanguage].CreateNews}</Button>
        </div>
        <div style={{  padding: 5 }}>
          <Table responsive="sm" striped bordered hover>
            <thead style={{ backgroundColor: 'lightsteelblue' }}>
              <tr>
                <th><b> {'ID. '} </b></th>
                <th><b> {language[this.props.currentLanguage].Date} </b></th>
                <th style={{ minWidth: null }}><b> {language[this.props.currentLanguage].Information} </b></th>
                <th style={{ minWidth: 150 }}><b> {language[this.props.currentLanguage].Action} </b></th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.NewsOption.map((NewsOptionItem, index) => {
                  return <tr key={index}>
                    <td style={{ verticalAlign: 'middle'}}>{NewsOptionItem.ID}</td>
                    <td style={{ verticalAlign: 'middle'}}>{Moment(NewsOptionItem.DateTime).format('DD/MM/YYYY')}</td>
                    <td style={{ verticalAlign: 'middle'}}>{NewsOptionItem.Information}</td>
                    <td style={{ verticalAlign: 'middle'}}>
                      <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                        <div>
                          <Button variant="primary" style={{ width: '100%', marginRight: 5 }} disabled={this.state.AddDisable } onClick={() => this.toggleEditNewsForm(NewsOptionItem)}>{language[this.props.currentLanguage].Edit}</Button>
                        </div>
                        <div>
                          <Button variant="danger" style={{ width:  '100%' }} disabled={this.state.AddDisable } onClick={() => this.setState({AlertModal: true, RecordDeletedNews: NewsOptionItem.ID})}>{language[this.props.currentLanguage].Delete}</Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </Table>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.OpenModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, width: 'auto' }}>
            <div style={{flexDirection: 'row', width:  '100%', backgroundColor: 'white'}}>
              <GrFormClose
                className='CloseButtonModal'
                onClick={() => {
                  this.setState({ OpenModal: false, FileLinkWord: '', FileResultWord: ''})
                }}
                size="35px"
                color="#000"
              />
              <div className='dcc' style={{width: '100%', borderRadius: 10, overflowX: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                <div style={{ width: '100%', marginBottom: 20, fontWeight: 'bold',fontSize: '20px' }}> 
                  {(this.state.StatusModal === 'Create') ? 
                    language[this.props.currentLanguage].Create :
                    language[this.props.currentLanguage].Edit 
                  }
                </div>
                <Alert style={{ zIndex: 99, width:  '80%', left: 0 }}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                </Alert>
                <div style={{ width: '100%', display: 'flex', flexDirection:  'row', justifyContent: 'center' }}>
                  <div style={{   width: '90%', marginBottom: 50 }}>
                    {(this.state.StatusModal === 'Edit') && <div className='dcc' style={{ flexDirection: 'row', flex: 1, marginBottom: 10}}>
                      <b style={{ width:  '10%'}}>{'ID :'}</b>
                      <Form.Control style={{ width: '80%', marginLeft: 5}}
                        disabled={true}
                        value={this.state.PackageID} 
                      />
                    </div>}
                    <div className='dcc' style={{ flexDirection: 'row', flex: 1, marginBottom: 10}}>
                      <b style={{ width:  '10%'}}>{`${language[this.props.currentLanguage].Date} :`}</b>
                      <Form.Control style={{ width: '80%', marginLeft: 5}}
                        disabled={true}
                        value={this.state.TodayDate} 
                      />
                    </div>
                    <div className='dcc' style={{ flexDirection: 'row', flex: 1,}}>
                      <b style={{ width:  '10%'}}>{`${language[this.props.currentLanguage].Link} :`}</b>
                      <Form.Control style={{ width: '80%', marginLeft: 5}}
                        onChange={(e) => this.ChangeReceipt(e, 'Imagelink')}
                        value={ this.state.FileLinkWord} 
                      />
                    </div>
                    {/* <div className="dcc" style={{ width: '100%', marginTop: 30, marginBottom: 30, justifyContent: 'center' }}>
                      <div style={{ width: '100%', height: '100%',flexDirection: "column", marginLeft: (isMobile) ? 0 : 0}} 
                        className={`DropzoneNews ${this.state.hightlight ? "Highlight" : ""}`} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} >
                        <Dropzone onDrop={this.onDrop} accept="image/*" >
                          {({getRootProps, getInputProps}) => (
                            <section className="container">
                              <div {...getRootProps({className: 'containerOndrop'})} >
                                <input {...getInputProps()} />
                                <p>将一些文件拖放到此处，或单击以选择文件</p>
                              </div>
                              <aside>
                                <h4 className="dcc" >文件</h4>
                                <ul>{files}</ul>
                              </aside>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div> */}
                    <div className="dcc" style={{ width: '100%',  marginTop: 15 }}>
                      <textarea
                        class="form-control"
                        style={{ textAlign: 'center', width: '95%',  fontWeight: 'bold', height: 150 }}
                        value={this.state.FileResultWord}
                        readOnly={false}
                        onChange={(e) => this.ChangeReceipt(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', flexDirection: 'row', textAlign: 'center', paddingBottom: 20 }}>
                  <div style={{ width: 'auto'}}>
                    {(this.state.StatusModal === 'Create')&&<Button  className='button2' variant="danger" style={{ textAlign: 'center', color: 'white', width:  200, fontWeight: 'bold', marginRight: 20 }}
                      disabled={this.state.disabled}
                      onClick={() => this.CleanData()}
                      class="btn my-cusomized-button">
                      {language[this.props.currentLanguage].ClearAll}
                    </Button>}
                    {(this.state.StatusModal === 'Edit')&&<Button  className='button2' variant="danger" style={{ textAlign: 'center', color: 'white', width:  200, fontWeight: 'bold', marginRight: 20 }}
                      disabled={this.state.disabled}
                      onClick={() => this.CleanData('Reset')}
                      class="btn my-cusomized-button">
                      {language[this.props.currentLanguage].Reset}
                    </Button>}
                    <Button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold', marginRight: 20 }}
                      disabled={this.state.disabled}
                      className='button2'
                      onClick={() => this.ConfirmSaveBet(this.state.StatusModal)}
                      class="btn my-cusomized-button">
                      {language[this.props.currentLanguage].SubmitNews}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          // style={{ width: '100%'}}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.AlertModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto', }}>
            <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
              <b className="dcc" style={{ padding: 20, fontSize: 23}}>
                {language[this.props.currentLanguage].DeletedNews}
              </b>
              <div className="dcc">
                <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.setState({ AlertModal: false, RecordDeletedNews: ''})}>
                  {language[this.props.currentLanguage].Shutdown}
                </Button>
                <Button   className='button2' style={{ margin: 5, width: '20%'}} variant="success" onClick={() => this.setState({ AlertModal: false}, ()=>{
                  this.DeletedNews(this.state.RecordDeletedNews)
                })}>{language[this.props.currentLanguage].Confirm}</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* <Tab.Container>
          <CreateNews CreateStatus={ this.state.CreateStatus}/>
        </Tab.Container> */}
        {(this.state.PopScrollBackButton)&&<div class={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
          ^
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LatestNews);
