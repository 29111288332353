import '../App.css';
import React from 'react';
import Header from '../Component/Header';
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux'
import { language } from '../language';
// import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
import { Card, Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
// import updown from '../Images/updown1.png';
import 'react-datepicker/dist/react-datepicker.css';
import Alert from 'react-bootstrap/Alert';
// import Modal from 'react-bootstrap/Modal';
const {
  exportJAckPortListtoExcel,
} = require('../jsonToXlsx');
const { getJackpostList, ReturnReceiptOnly, searchJackpotListFNC } = require('../Api');
var timer

//
//                       _oo0oo_
//                      o8888888o
//                      88" . "88
//                      (| -_- |)
//                      0\  =  /0
//                    ___/`---'\___
//                  .' \\|     |// '.
//                 / \\|||  :  |||// \
//                / _||||| -:- |||||- \
//               |   | \\\  -  /// |   |
//               | \_|  ''\---/''  |_/ |
//               \  .-\__  '-'  ___/-. /
//             ___'. .'  /--.--\  `. .'___
//          ."" '<  `.___\_<|>_/___.' >' "".
//         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//         \  \ `_.   \_ __\ /__ _/   .-` /  /
//     =====`-.____`.___ \_____/___.-`___.-'=====
//                       `=---='
//
//
//     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//               佛祖保佑         永无BUG

class SixDJackPotList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      UserID: this.props.userID,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      SelectedDate: new Date(),
      Authority: '',
      Run: false,
      date: new Date(),
      ImageNews: '',
      background: '#3C96F4',
      backgroundclose: 'white',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Platform: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      is_visible: false,
      TodayJackpotArray: [],
      TotalAmountDading: 0,
      AllJackpot: [],
      FilterUserName: '',
      Filterstatus: '',
      SelectFilterUSername: 'All',
      SelectedFilterStatus: 'All',
      showReceiptModal: false,
      ShowReceipt: '',
      filterNumber: '',
      page: 1,
      countperpage: 20,
      countpagemax: 1,
      countpagemin: 1,
    }
  }

  async componentDidMount() {
    this.SetUsersAuthorities();
    this.callgetJackpostList();
  }

  async callgetJackpostList() {
    const SelectedDate = this.state.SelectedDate
    const JackpostList = await getJackpostList(this.props.username, Moment(SelectedDate).format('YYYY-MM-DD'), this.state.page,  this.state.countperpage, this.props.token);
    if (JackpostList) {
      this.setState({ TodayJackpotArray: JackpostList.jackpotResult, AllJackpot: JackpostList.jackpotResult, filterNumber: '' }, ()=> {
        this.getFilter(this.state.AllJackpot);
      })
    }
    if (JackpostList.jackpotResult.length > 0) {
      this.setState({
        countpagemax: Math.ceil(
          JackpostList.jackpotResult[0].FullCount / this.state.countperpage,
        ),
      });
    } else {
      this.setState({
        countpagemax: 1,
        page: 1,
      });
    }
  }

  getFilter(AllJackpot) {
    let UsernameArray = []
    let StatusArray = [] 
    AllJackpot.forEach((items) => {
      UsernameArray.push(items.Username)
      StatusArray.push(items.Win)
    })
    UsernameArray = [...new Set(UsernameArray)];
    StatusArray = [...new Set(StatusArray)];

    this.setState({ FilterUserName: UsernameArray, Filterstatus: StatusArray })
  }

  FilterList(e, mode) {
    const AllJackpot = this.state.AllJackpot
    let SelectFilterUSername = this.state.SelectFilterUSername
    let SelectedFilterStatus = this.state.SelectedFilterStatus
    let FilterResult = []
    if (mode === 'SelectFilterUSername') {
      SelectFilterUSername = e.target.value
      this.setState({ SelectFilterUSername, filterNumber: '' })
    }
    if (mode === 'SelectedFilterStatus') {
      SelectedFilterStatus = e.target.value
      this.setState({ SelectedFilterStatus, filterNumber: '' })
    }
    if(SelectFilterUSername !== 'All' && SelectedFilterStatus === 'All') {
      FilterResult = AllJackpot.filter(function (value) {
        return value.Username === SelectFilterUSername
      });
    }
    if (SelectFilterUSername === 'All' && SelectedFilterStatus !== 'All') {
      FilterResult = AllJackpot.filter(function (value) {
        return value.Win === SelectedFilterStatus
      });
    }
    if (SelectFilterUSername !== 'All' && SelectedFilterStatus !== 'All') {
      var FilterNameFirst = AllJackpot.filter(function (value) {
        return value.Username === SelectFilterUSername
      });
      FilterResult = FilterNameFirst.filter(function (value) {
        return value.Win === SelectedFilterStatus
      });
    }
    if (SelectFilterUSername === 'All' && SelectedFilterStatus === 'All') {
      FilterResult = AllJackpot
    }
    this.setState({ TodayJackpotArray: FilterResult, filterNumber: '' })
  }

  CalculateTotalDading(TodayJackpotArray) {
    const tempObject = {
      TotalAmount: 0,
    }
    Object.values(TodayJackpotArray).forEach((item) => {
      tempObject.TotalAmount += Number(item.Amount)
    })
    this.setState({ TodayJackpotArray:tempObject })
  }

  async SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  async exportList() {
    const TodayJackpotArray = this.state.TodayJackpotArray
    const JackportListDate = Moment(this.state.SelectedDate).format('YYYY-MM-DD HH:mm:ss')
    if (TodayJackpotArray.length === 0) {
      this.openNotification('NoData')
      this.setState({ notificationType: 'error'});
    } else {
      await exportJAckPortListtoExcel(TodayJackpotArray, this.props.username, JackportListDate, this.props.currentLanguage)
      this.openNotification('successExportList')
      this.setState({ notificationType: true });
    }
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 2000);
  };

  ReturnPlatform(game) {
    if (game === 1) return 'M'
    else if (game === 2) return 'K'
    else if (game === 3) return 'T'
    else if (game === 4) return 'S'
    else if (game === 5) return 'W'
    else if (game === 6) return 'B'
    else if (game === 7) return 'D'
    else if (game === 8) return '8'
    else if (game === 9) return '9'
    else return game
  }

  handleScroll = (event) => {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  async setSelectedDate(date, mode) {
    this.setState({
      [mode]: date,
    }, ()=> {
      this.callgetJackpostList();
    });
  }

  async ShowSelectedReceipt(serverID) {
    const ReturnReceipt = await ReturnReceiptOnly(serverID, this.props.token, this.props.username)
    if(ReturnReceipt) {
      this.setState({ ShowReceipt: (ReturnReceipt.error === 'No data') ? 'No Data Detected' : ReturnReceipt.Receipt, showReceiptModal: true }, () => {
        console.log('showReceiptModal', this.state.ShowReceipt)
      })
    }
  }

  async SearchNumberFunctions(e) {
    const filterNumber = this.state.filterNumber
    const SelectedDate = this.state.SelectedDate
    if(e.key === 'Enter') {
      const Search = await searchJackpotListFNC(Moment(SelectedDate).format('YYYY-MM-DD'), this.props.username, this.state.page,  this.state.countperpage, filterNumber, this.props.token)
      console.log('Search', Search)
      if (Search) {
        this.openNotification('Success')
        this.setState({ notificationType: 'Success'});
        this.setState({ TodayJackpotArray: Search.jackpotResult, AllJackpot: Search.jackpotResult, filterNumber: '' }, ()=> {
          this.getFilter(this.state.AllJackpot);
        })
      }
      if (Search.jackpotResult.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            Search.jackpotResult[0].FullCount / this.state.countperpage,
          ),
        });
      } else {
        this.setState({
          countpagemax: 1,
          page: 1,
        });
      }
      // FilterResult = AllJackpot.filter(function (value) {
      //   return value.Number.includes(filterNumber)
      // });
      // this.setState({ TodayJackpotArray: FilterResult })
    }
  }

  changePage() {
    this.setState({ DisplayReceipts: [] }, () => {
      this.callgetJackpostList();
    });
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', backgroundColor: '#EFEFEF', height: '100vh', flexDirection: 'column', overflowY: 'auto'}}>
        <Header />
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} 
          show={this.state.showNotification}
          variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div style={{ width: '100%', marginBottom: 15, marginLeft: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
          <div style={{ width: '50%', marginBottom: 15, marginLeft: 40, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 'auto', fontWeight: 'bold', paddingRight: 5, minWidth: 100 }}>{language[this.props.currentLanguage].Date} :</div>
              <DatePicker
                onChange={(date) => this.setSelectedDate(date, 'SelectedDate')}
                dateFormat='dd-MMM-yyyy'
                placeholder="Date"
                // maxDate={new Date()}
                selected={this.state.SelectedDate}
                value={this.state.SelectedDate}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
            </div>
            <div className='dcc' style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
              <Form.Control 
                placeholder='Search Number'
                maxLength={8}
                value={this.state.filterNumber}
                onChange={(e) => this.setState({ filterNumber: e.target.value })}
                onKeyDown={(e) => this.SearchNumberFunctions(e)}
              />
            </div>
            <Button className="button2" variant="primary" style={{ marginLeft: 5, fontWeight: 'bold', width: null , marginTop: 0}} onClick={() => this.SearchNumberFunctions({ key: 'Enter' })} >{language[this.props.currentLanguage].Confirm}</Button>
          </div>
        </div>
        {this.state.AllJackpot.length > 0 &&
            <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', overflowY: 'auto' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: 20, width: 'auto' }}>
                <div style={{ width: 90, fontWeight: 'bold', textAlign: 'left', paddingLeft: 'none'}}>{language[this.props.currentLanguage].usernameLanguage}</div>
                <select className="form-control"
                  style={{ textAlign: 'center', fontSize: '18px', width: '100%', fontWeight: 'bold' }}
                  onChange={(e) => this.FilterList(e, 'SelectFilterUSername')}>
                  <option value={'All'}>{`All`}</option>
                  {(this.state.FilterUserName || []).map((item, idx) => {
                    return (
                      <option index={idx} value={item}>{`${item}`}</option>
                    );
                  })}
                </select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'auto' }}>
                <div style={{ width: 90, fontWeight: 'bold', textAlign: 'left', paddingLeft: 'none' }}>{language[this.props.currentLanguage].Win}</div>
                <select className="form-control"
                  style={{ textAlign: 'center', fontSize: '16px', width: '100%', fontWeight: 'bold' }}
                  onChange={(e) => this.FilterList(e, 'SelectedFilterStatus')}>
                  <option value={'All'}>{`All`}</option>
                  {(this.state.Filterstatus || []).map((item, idx) => {
                    return (
                      <option index={idx} value={item}>{`${item}`}</option>
                    );
                  })}
                </select>
              </div>
              <div></div>
            </div>
        }
        <div id={'Top'} className="dcc" style={{ width: 'calc(100% / 1)', marginBottom: 30, marginTop: 20 }}>
          <Card style={{ width: '80%' }}>
            <Card.Header className='dcc' style={{ flexDirection: 'row' }}>
              <div className='dcc' style={{ flexDirection: 'row' }}>
                {/* <div className='dcc' style={{ width: '15%', display: 'flex', flexDirection: 'row', position: 'absolute', left: 5 }}>
                  <div style={{ width: '25%'}}>{'Date :'}</div>
                  <DatePicker
                    onChange={(date) => this.setSelectedDate(date, 'SelectedDate')}
                    dateFormat='dd-MMM-yyyy'
                    placeholder="Date"
                    maxDate={new Date()}
                    // minDate={new Date(this.state.MinDate)}
                    selected={this.state.SelectedDate}
                    value={this.state.SelectedDate}
                    className={(isMobile) ? "form-control StyleDatePickerMobile" :"form-control StyleDatePicker"}
                    withPortal
                  />
                </div> */}
                <b style={{ fontSize: '18px' }}>{language[this.props.currentLanguage].FreeJackpotList}</b>
                <div className="dcc button button2"
                  style={{ backgroundColor: this.state.background, fontSize: 18, position: 'absolute', right: 5, 
                    borderBottom: '1px solid #cfd9de', verticalAlign: 'middle', fontWeight: 'bold', padding: 5, cursor: 'pointer', borderRadius: 5, width: 200 }} 
                  onMouseEnter={() => {
                    this.setState({ background: '#00AFE2' });
                  }}
                  onMouseLeave={() => {
                    this.setState({ background: '#3C96F4' });
                  }}
                  variant="success" onClick={() => this.exportList()}>
                  <span>
                        下载列表
                  </span>
                </div>
              </div>
            </Card.Header>
            <div style={{ position: 'absolute', right: 10, top: 50, fontWeight: 'bold', padding: 10 }}>{`ID: ${this.props.username}`}</div>
            <Card.Body className="dcc" style={{ width: '100%', flexWrap: 'wrap', overflowX: 'auto', flexDirection: 'column'}}>
              <div style={{ width: '90%', marginTop: 15 }}>
                <Table responsive="sm" striped bordered hover>
                  <thead style={{ backgroundColor: 'lightsteelblue' }}>
                    <tr>
                      <th style={{ minWidth: null }}>{'(S)ID'}</th>
                      <th style={{ minWidth: null }}>{'(#)ID'}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].usernameLanguage}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].Number}</th>
                      <th style={{ minWidth: null }}>{'Multiply'}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].Platform}</th>
                      <th style={{ minWidth: null }}>{language[this.props.currentLanguage].Win}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.TodayJackpotArray.length > 0 ? (
                      <>
                        {(this.state.TodayJackpotArray && this.state.TodayJackpotArray).map((items, index) => {
                          return(
                            <tr key={index} style={{ fontWeight: 'bold'}}>
                              <td className="button2" style={{ cursor: 'pointer', color: '#37B3FF'}} onClick={()=> this.ShowSelectedReceipt(items.SID)}>{items.SID}</td>
                              <td style={{ cursor: 'not-allowed' }}>{items.NID}</td>
                              <td style={{ cursor: 'not-allowed' }}>{items.Username}</td>
                              <td style={{ cursor: 'not-allowed' }}>{items.Number}</td>
                              <td style={{ cursor: 'not-allowed' }}>{items.Multiply}</td>
                              <td style={{ cursor: 'not-allowed' }}>{this.ReturnPlatform(items.Platform)}</td>
                              <td style={{ cursor: 'not-allowed', color: (items.Win === 'Win') ? '#18C600' : (items.Win === 'Lose') ? 'red' : 'black'}}>{items.Win}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan="7" style={{ cursor: 'not-allowed', fontWeight: 'bold' }}>{'No Data'}</td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="7" style={{ cursor: 'not-allowed', fontWeight: 'bold' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' , width: '100%' }}>
                          {this.state.page > this.state.countpagemin ? (
                            <div style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() => this.setState({ page: this.state.page - 1, }, () => { this.changePage(this.state.page); },)
                              }>
                              {'<<'}
                            </div>
                          ) : (
                            <div style={{ width: 100}}/>
                          )}
                          <div style={{ paddingLeft: 10, paddingRight: 10, width: '100%' }}>{this.state.page}</div>
                          {this.state.page < this.state.countpagemax ? (
                            <div
                              style={{ cursor: 'pointer', fontWeight: 'bold' }}
                              onClick={() =>
                                this.setState({ page: this.state.page + 1 }, () => { this.changePage(); },
                                )
                              }>
                              {'>>'}
                            </div>
                          ) : (
                            <div style={{ width: 100}}/>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
        {this.state.is_visible && 
            <div className="dcc" style={{ paddingBottom: 30 }}>
              <div class="scrollDesign iconpopup button2"  
                onClick={() => this.scrollToTop()}>
                {/* ↑ ⇈ */}
                <div  className="icon MoveUpArrow">⇧</div>
              </div>
            </div>
        }
        <Modal
          // style={{ width: '100%'}}
        //   size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showReceiptModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 0, height: 'auto', width: '100%' }}>
            <div>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top: 15, cursor: 'pointer', backgroundColor: this.state.backgroundclose, borderRadius: 20 }}
                onClick={() => {
                  this.setState({ showReceiptModal: false, ShowReceipt: '' })
                }}
                onMouseEnter={() => {
                  this.setState({ backgroundclose: 'white' });
                }}
                onMouseLeave={() => {
                  this.setState({ backgroundclose: '#cfd9de' });
                }}
                size="35px"
                color="#000"
              />
              <Form.Group>
                <div style={{ width: '100%', flexDirection: 'column', overflowX: 'auto', marginTop: 25, whiteSpace: 'pre-wrap', fontWeight: 'bold', padding: 20 }}>
                  <div className="dcc jackpotborder" style={{ width: 'auto', color: 'white', minWidth: 100 }}> {language[this.props.currentLanguage].Receipt}</div>
                  <div style={{ width: '100%', marginTop: 10 }}>{this.state.ShowReceipt}</div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
// export default Home;

function mapStateToProps(state) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SixDJackPotList);
