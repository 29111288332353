import React, {Component, Fragment} from 'react';
import '../App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert';
import { language } from '../language';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { RiArrowDropDownFill } from 'react-icons/ri';
import Header2 from '../Component/Header';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment-timezone';
import Form from 'react-bootstrap/Form';
import { GamePlatformNumb1 } from '../static';
const { strikeReport } = require('../Api');
// import { Multiselect } from 'multiselect-react-dropdown';
// import { GrFormClose } from 'react-icons/gr';
var timer

class GamesIndividual extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      Search_Username: '',
      modalShow: true,
      Min_Date: Moment(localStorage.getItem('Min_Date')).toDate(),
      Max_Date: Moment(localStorage.getItem('Max_Date')).toDate(),
      Available: false,
      notificationMessage: '',
      notificationType: 'success',
      showNotification: false,
      TotalObject: {},
      DetailsMode: false, 
      EasyMode: false,
      RedMode: false,
      ModeNumber: 0,
      Games: [1],
      reportDetailGameArray: {},
      DownlineReport: [],
      selfProfitReport: [],
      TotalAmount: {},
    }
  }

  async componentDidMount() {
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
    searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
    if (searchState !== '') {
      searchState = JSON.parse(searchState)
      this.setState({
        ModeNumber: searchState.TypeMode,
        DetailsMode: searchState.TypeMode === 0 ? true : false, 
        EasyMode: searchState.TypeMode === 2 ? true : false, 
        RedMode: searchState.TypeMode === 1 ? true : false, 
        Games: searchState.TypePlatform.split(',')
      }, ()=>{
        this.getReport();
      })
    }
    
    // const updateRedNumberResult = await strikeReport(this.props.username, this.props.token, redNumberID, field, value)
    // const reportDetailGameArray = await strikeReport ( Moment(this.state.Max_Date).format('YYYY-MM-DD'), Moment(this.state.Min_Date).format('YYYY-MM-DD'), this.state.UseridReport, searchState.type,this.props.username, this.props.token)
    const TotalObject= { 
      TotalSelf:{
        TotalSales: 0,
        TotalCommision: 0,
        TotalWin: 0,
        TotalLai: 0,
      }, 
      TotalDownline:{
        TotalSales: 0,
        TotalCommision: 0,
        TotalWin: 0,
        TotalLai: 0,
      }, 
    };

    // if( this.state.UpperlineData.length !== 0){
    // for (let i = 0; i < OriginalText.length; i++) {
    //   TotalObject.TotalSales += Number(bla),
    //   TotalObject.TotalCommision += Number(bla),
    //   TotalObject.TotalWin += Number(bla),
    //   TotalObject.TotalLai += Number(bla),
    // }
    // }

    this.setState({ TotalObject });
  }

  async getReport(){
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
    
    if (searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      searchState = JSON.parse(searchState)
      const reportDetailGameArray = await strikeReport(Moment(this.state.Max_Date).format('YYYY-MM-DD'), Moment(this.state.Min_Date).format('YYYY-MM-DD'), searchState.id, this.state.ModeNumber, this.state.Games,this.props.username, this.props.token)
      let selfProfitReport = [];
      const filtered = Object.keys(reportDetailGameArray).filter((item) => {
        if (item !== searchState.id) {
          return true;
        } else {
          selfProfitReport = item;
          return false;
        }
      });
      let  NewArray = {}
      let UplineTotal = 0
      let UplineProfitTotal = 0
      let UplineWaterTotal = 0
      let UplineWinTotal = 0
      Object.keys(reportDetailGameArray).forEach((U_ID)=>{
        Object.keys(reportDetailGameArray[U_ID].sales).forEach((Date) =>{
         
          UplineTotal += Number(reportDetailGameArray[U_ID].sales[Date].Upline);
          UplineProfitTotal += Number(reportDetailGameArray[U_ID].sales[Date].UplineProfit);
          UplineWaterTotal += Number(reportDetailGameArray[U_ID].sales[Date].UplineWater);
          UplineWinTotal += Number(reportDetailGameArray[U_ID].sales[Date].UplineWin);
          const UplineTotals = { 
            [U_ID]:{
              Upline: UplineTotal,
              UplineProfit: UplineProfitTotal,
              UplineWater: UplineWaterTotal,
              UplineWin: UplineWinTotal,
            }
          }
          NewArray = {...NewArray, ...UplineTotals}
          UplineTotal = 0
          UplineProfitTotal = 0
          UplineWaterTotal = 0
          UplineWinTotal = 0
        })
      })
      this.setState({ reportDetailGameArray, selfProfitReport: [selfProfitReport], DownlineReport:  filtered, TotalAmount: NewArray })
    }
    // const reportDetailGameArray = await strikeReport ( Moment(this.state.Max_Date).format('YYYY-MM-DD'), Moment(this.state.Min_Date).format('YYYY-MM-DD'), this.state.UseridReport, this.state.ModeNumber, this.state.Games,this.props.username, this.props.token)
  }

  async CallAPIData() {
    this.setState({modalShow: false, Available: true})
    if ( ( Moment(this.state.Max_Date).format('YYYY-MM-DD')) > Moment(this.state.Min_Date).format('YYYY-MM-DD')){
      this.openNotification(`${language[this.props.currentLanguage].FormDateError}`)
      this.setState({ notificationType: 'error' });
    } else {
    }
  }

  async setSelectedDate(Min_Date) {
    this.setState({ Min_Date }, ()=>{
      this.props.setMinDate(Min_Date);
    })
  }

  async MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date }, ()=>{
      this.props.setMaxDate(Max_Date)
    })
  }

  openNotification(message, Words) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}${Words || ''}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  ModeFunction(ModeName){
    if(ModeName === 'Detail'){
      this.setState({ ModeNumber: 0, DetailsMode: !this.state.DetailsMode, RedMode: false, EasyMode: false }, ()=>{
        this.getReport()
      })
    } else if(ModeName === 'Red'){
      this.setState({ ModeNumber: 1, DetailsMode: false, RedMode: !this.state.RedMode, EasyMode: false }, ()=>{
        this.getReport()
      })
    } else {
      this.setState({ ModeNumber: 2, DetailsMode: false, RedMode: false, EasyMode: !this.state.EasyMode }, ()=>{
        this.getReport()
      })
    }
  }

  GameReport(Key){
    this.setState({ Games: [Key]}, ()=>{
      this.getReport()
    })
  }
  
  render() {
    return (
      <Fragment>
        <div style={{ marginBottom: '2%'}}>
          <Header2 />
        </div>
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc row">
          <div style={{ flexDireaction:"column" }}>
            <div className={ "row"} style={{marginBottom: 20, width:  'auto'}}>
              <div className="dcc" style={{ marginBottom: '2%'}}>
                <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>From</div>
                <DatePicker
                  onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  // popperPlacement="top-right"
                  selected={this.state.Max_Date}
                  className={"form-control StyleDatePicker"}
                  // withPortal
                />
                <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>To</div>
                <DatePicker
                  onChange = {(date) => this.setSelectedDate(date)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  minDate={new Date(this.state.Max_Date)}
                  // popperPlacement="top-right"
                  selected={this.state.Min_Date}
                  className={ "form-control StyleDatePicker"}
                  // withPortal
                />
              </div>
            </div>
            <div className={ " dcc row"} style={{ width: '100%', paddingBottom: '3%'}}>
              <div style={{ fontWeight: 'bold', marginRight: 5, width: 100 }}>{language[this.props.currentLanguage].DetailedReport}</div>
              <input
                checked={this.state.DetailsMode}
                value={this.state.DetailsMode}
                style={{ width: 17 , height: 17}}
                onChange={() => this.ModeFunction('Detail') }
                readOnly={false}
                type="checkbox"
              />
              <div style={{ fontWeight: 'bold', marginRight: 5, width: 100  }}>{language[this.props.currentLanguage].Red}</div>
              <input
                checked={this.state.RedMode}
                value={this.state.RedMode}
                style={{ width: 17, height: 17}}
                onChange={() => this.ModeFunction('Red') }
                readOnly={false}
                type="checkbox"
              />
              <div style={{ fontWeight: 'bold', marginRight: 5, width: 100  }}>{language[this.props.currentLanguage].EasyReport}</div>
              <input
                checked={this.state.EasyMode}
                value={this.state.EasyMode}
                style={{ width: 17, height: 17}}
                onChange={() => this.ModeFunction('Simple') }
                readOnly={false}
                type="checkbox"
              />
            </div>
            <div className="dcc" style={{display: 'flex'}}>
              {[1,2,3,4,5,6,7,8].map((item, index) => {
                return <Form.Check
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex', padding: 5 }}
                  inline
                  label={GamePlatformNumb1[item]}
                  type="checkbox"
                  checked={this.state.Games.includes(item)}
                  onChange={(e) => this.GameReport(item)}
                />
              })}
            </div>
          </div>
          {this.state.loading  === true ? ( 
            <div class="divLoader">
              <img alt="" style={{ marginTop: 50, width: 200, height: 200, marginRight: 200}} src={'https://i.imgur.com/GLdqYB2.gif'}/>
            </div>
          ) : (
            <>
              {((Object.keys(this.state.reportDetailGameArray)).length > 0)&&<>
                {( this.state.selfProfitReport).map((item, index) =>{
                  return <div key={item} style= {{ width: '100%',flexDirection: 'row', display: 'flex', flexWrap: 'wrap',  overflowX: 'auto'}}>
                    <div style={{ flex: "1 1 30%"}}>
                ID
                      <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
                        <thead>
                          <tr>
                            <th>{language[this.props.currentLanguage].Date}</th>
                            <th>{language[this.props.currentLanguage].SalesReport }</th>
                            <th>{language[this.props.currentLanguage].Commission}</th>
                            <th>{language[this.props.currentLanguage].Win}</th>
                            <th>{language[this.props.currentLanguage].WINLOST}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(this.state.reportDetailGameArray[item].sales).map((itemDate) =>{
                            return <tr style={{ backgroundColor: 'white'}} >
                              <td >{itemDate}</td>
                              <td>{this.state.reportDetailGameArray[item].sales[itemDate].Upline}</td>
                              <td>{this.state.reportDetailGameArray[item].sales[itemDate].UplineWater}</td>
                              <td>{this.state.reportDetailGameArray[item].sales[itemDate].UplineWin}</td>
                              <td>{this.state.reportDetailGameArray[item].sales[itemDate].UplineProfit}</td>
                            </tr>
                          })}
                          <tr style={{ backgroundColor: '#ebf4fc'}} >
                            <td>{`${language[this.props.currentLanguage].Total} :`}</td>
                            <td>{this.state.TotalAmount[item].Upline}</td>
                            <td>{this.state.TotalAmount[item].UplineWater}</td>
                            <td>{this.state.TotalAmount[item].UplineWin}</td>
                            <td>{this.state.TotalAmount[item].UplineProfit}</td>
                          </tr>
                        </tbody>
                      
                      </Table>
                    </div>

                    <div style={{ paddingLeft: '1%', paddingRight: '1%',flex: "1 1 30%",}}>
              Strike Record
                      <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
                        <thead>
                          <tr>
                            <th>{language[this.props.currentLanguage].Date}</th>
                            <th>{language[this.props.currentLanguage].Receipt + '#'}</th>
                            <th>{language[this.props.currentLanguage].Prize}</th>
                            <th>GR</th>
                            <th>{language[this.props.currentLanguage].nombor}</th>
                            <th>{language[this.props.currentLanguage].Bet}</th>
                            <th>{language[this.props.currentLanguage].Win}</th>
                          </tr>
                        </thead>
                        { ((this.state.reportDetailGameArray[item].winArray).length === 0) ?  
                          <tbody>
                            <tr style={{ width: '100%', backgroundColor: '#C0C0C0', fontWeight: 'bold', fontSize: '20px'}}>
                              <td colSpan="7">{language[this.props.currentLanguage].NoData}</td>
                            </tr>
                          </tbody>
                          : 
                          <tbody>
                            { this.state.reportDetailGameArray[item].winArray.map((item) =>{
                              return <tr style={{ backgroundColor: '#ebf4fc'}}>
                                <td>{Moment(item.Date).format('YYYY-MM-DD')}</td>
                                <td><Link>{`[#${item.OrderID}]`}</Link></td>
                                <td>{item.Position}</td>
                                <td>{item.BetMode}</td>
                                <td>{item.Word}</td>
                                <td>{item.Bet}</td>
                                <td>{item.Strike}</td>
                              </tr>
                            })
                            }
                          </tbody>
                        }
                      </Table>
                    </div>
                  </div>
                })}
              </>}
            </>
          )}
          <div style= {{ width: '100%', backgroundColor: 'grey', fontSize: "18px", color: 'white', padding: 5, marginBottom: '1%' }}>{language[this.props.currentLanguage].Downline}</div>
          {this.state.loading  === true ? ( 
            <div class="divLoader">
              <img alt="" style={{ marginTop: 50, width:  200, height: 200, marginRight: 200}} src={'https://i.imgur.com/GLdqYB2.gif'}/>
            </div>
          ) : (
            this.state.DownlineReport.map((item, index) =>{
              return <div style= {{ width: '98%'}} >
                <Accordion >
                  <Card style={{ border: 'none', marginBottom: 20 }}>
                    <Card.Header style={{ backgroundColor: '#3c7ca7', borderRadius: 0 }}>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{ width: '100%', textAlign: 'left', color: 'white' }}>
                        <span>
                          <span style={{ marginLeft: 10, color: 'white', fontWeight: 'bold', fontSize: 18 }}>{this.state.reportDetailGameArray[item].Name}</span>
                        </span>
                        <RiArrowDropDownFill
                          style={{ position: 'absolute', right: 20, top: 0, cursor: 'pointer', color: 'white' }}
                          size="35px"
                        />
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div style= {{ width: '100%',flexDirection: 'row', display: 'flex', flexWrap: 'wrap',  overflowX: 'auto'}}>
                          <div style={{ flex: "1 1 30%"}}>
                          ID
                            <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
                              <thead>
                                <tr>
                                  <th>{language[this.props.currentLanguage].Date}</th>
                                  <th>{language[this.props.currentLanguage].SalesReport }</th>
                                  <th>{language[this.props.currentLanguage].Commission}</th>
                                  <th>{language[this.props.currentLanguage].Win}</th>
                                  <th>{language[this.props.currentLanguage].WINLOST}</th>
                                </tr>
                              </thead>
                              { ((this.state.reportDetailGameArray[item].sales).length === 0) ?  
                                <tbody>
                                  <tr style={{ width: '100%', backgroundColor: '#C0C0C0', fontWeight: 'bold', fontSize: '20px'}}>
                                    <td colSpan="7">{language[this.props.currentLanguage].NoData}</td>
                                  </tr>
                                </tbody>
                                : 
                                <tbody>
                                  {Object.keys(this.state.reportDetailGameArray[item].sales).map((itemDate) =>{
                                    return <tr style={{ backgroundColor: 'white'}} >
                                      <td >{itemDate}</td>
                                      <td>{this.state.reportDetailGameArray[item].sales[itemDate].Upline}</td>
                                      <td>{this.state.reportDetailGameArray[item].sales[itemDate].UplineWater}</td>
                                      <td>{this.state.reportDetailGameArray[item].sales[itemDate].UplineWin}</td>
                                      <td>{this.state.reportDetailGameArray[item].sales[itemDate].UplineProfit}</td>
                                    </tr>
                                  })}
                                  <tr style={{ backgroundColor: '#ebf4fc'}} >
                                    <td>{`${language[this.props.currentLanguage].Total} :`}</td>
                                    <td>{this.state.TotalAmount[item].Upline}</td>
                                    <td>{this.state.TotalAmount[item].UplineWater}</td>
                                    <td>{this.state.TotalAmount[item].UplineWin}</td>
                                    <td>{this.state.TotalAmount[item].UplineProfit}</td>
                                  </tr>
                                </tbody>
                              }
                            </Table>
                          </div>
                          <div style={{  paddingLeft: '1%', paddingRight: '1%',flex: "1 1 30%"}}>
                          Strike Record
                            <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
                              <thead>
                                <tr>
                                  <th>{language[this.props.currentLanguage].Date}</th>
                                  <th>{language[this.props.currentLanguage].Receipt + '#'}</th>
                                  <th>{language[this.props.currentLanguage].Prize}</th>
                                  <th>GR</th>
                                  <th>{language[this.props.currentLanguage].nombor}</th>
                                  <th>{language[this.props.currentLanguage].Bet}</th>
                                  <th>{language[this.props.currentLanguage].Win}</th>
                                </tr>
                              </thead>
                              { ((this.state.reportDetailGameArray[item].winArray).length === 0) ?  
                                <tbody>
                                  <tr style={{ width: '100%', backgroundColor: '#C0C0C0', fontWeight: 'bold', fontSize: '20px'}}>
                                    <td colSpan="7">{language[this.props.currentLanguage].NoData}</td>
                                  </tr>
                                </tbody>
                                : 
                                <tbody>
                                  { this.state.reportDetailGameArray[item].winArray.map((item) =>{
                                    return <tr style={{ backgroundColor: '#ebf4fc'}}>
                                      <td>{Moment(item.Date).format('YYYY-MM-DD')}</td>
                                      <td><Link>{`[#${item.OrderID}]`}</Link> </td>
                                      <td>{item.Position}</td>
                                      <td>{item.BetMode}</td>
                                      <td>{item.Word}</td>
                                      <td>{item.Bet}</td>
                                      <td>{item.Strike}</td>
                                    </tr>
                                  })
                                  }
                                </tbody>
                              }
                            </Table>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            })
          )}
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modalShow}
          contentClassName="br23"
        >
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto' }}>
            <div style={{flexDirection: 'row', width: 'auto'}}>
              {/* <Alert show={this.state.showNotificationError} variant={this.state.notificationTypeError === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.AlertMessageError}</Alert.Heading>
          </Alert> */}
              <div className="dcc" style={{ width: '100%', marginBottom: 0 }}>
                <div style={{fontSize: "18px", width: 200,  textAlign: 'center'}}>{language[this.props.currentLanguage].AgentReport}</div>
              </div>
              <div className="dcc" style={{ marginTop: 20, marginBottom: 20, width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{fontSize: "18px", width: 50,  textAlign: 'center', paddingRight: 10}}>From</div>
                <DatePicker
                  onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  // popperPlacement="top-right"
                  selected={this.state.Max_Date}
                  className={ "form-control StyleDatePicker"}
                  // withPortal
                />
                <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>To</div>
                <DatePicker
                  onChange = {(date) => this.setSelectedDate(date)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  minDate={new Date(this.state.Max_Date)}
                  // popperPlacement="top-right"
                  selected={this.state.Min_Date}
                  className={"form-control StyleDatePicker"}
                  // withPortal
                />
              </div>
              {/* <div className="row" style={{ width: '100%', marginBottom: (isMobile) ? 20 : 30 }}>
                <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>报告类别 :</div>
			        <div>
                  <select class="form-control" value={this.state.ChooseStatus} onChange={this.SelectedOptionStatus} style={{ textAlign: 'center', marginRight:  (isMobile) ? 0 : 20, width: (isMobile) ? '100%' : 250, fontSize: '15px', height: (isMobile) ? 35  : 45 }}>
                    <option value="username">A) Traditional - 201    </option>
                  </select>
                  <div style={{ marginTop: '5%' }}>
                    <select class="form-control" value={this.state.ChooseStatus} onChange={this.SelectedOptionStatus} style={{ textAlign: 'center', marginRight:  (isMobile) ? 0 : 20, width: (isMobile) ? '100%' : 250, fontSize: '15px', height: (isMobile) ? 35  : 45 }}>
                      <option value="username">username downline    </option>
                      <option value="500">500    </option>
                      <option value="1000">1000    </option>
                    </select>
			          </div>
			        </div>
              </div> */}
              {/* <div className="row" style={{ width: '100%', marginBottom: (isMobile) ? 20 : 30 }}>
                <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>要求 :</div>
			        <div>
                  <input
                    style={{ textAlign: 'center', marginRight:  (isMobile) ? 0 : 20, width: (isMobile) ? 'auto' : 'auto', fontSize: '15px', height: (isMobile) ? 'auto'  : 'auto' }}
                    checked={this.state.ClaimState} 
                    onChange={(e) => { 
                      if (e.target.checked) {
                        this.setState({ClaimState: true, ClaimStatenum: 1})
                      } else {
                        this.setState({ClaimState: false, ClaimStatenum: 0})
                      }
                    }}
                    readOnly={false}
                    type="checkbox"
                    placeholder={'要求'}
                  />
			        </div>
              </div> */}
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
			        <div>
				        <button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: 300 }} 
                    disabled={this.state.disabled}
                    onClick={() => this.CallAPIData()} 
                    class="btn my-cusomized-button" >
                  提交
                  </button>
			        </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>    
    )
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
    username: state.username,
    token: state.token,
    userID: state.userID,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GamesIndividual);