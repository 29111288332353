const language = {
  en: {
    daysOfWeek: [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ],
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'fourth',
    fifth: 'fifth',
    sixth: 'sixth',
    special: 'Special',
    consolation: 'consolation',
    EmptySelected: 'Error ⛔: Empty Selected !!',
    america: 'America',
    damacai: 'Damacai',
    CheckPackage: 'View Packages',
    JEstimated: 'Jackpot Estimated Amount',
    NoGameThatDay: 'Error ⛔: Selected date haven Add Game yet.',
    UserRedLowerThanCommission: 'Error ⛔: User Red Lower Than Commission',
    CommissionNegative: 'Error ⛔: Negative Commission, please contact Upline',
    Jackpot: 'Jackpot',
    GdToto: 'Grand Dragon Lotto',
    sabah: 'Sabah88',
    sandakan: 'Sandakan',
    singapore: 'Singapore',
    sarawak: 'Sarawak Cashsweep',
    CannotRestore: 'Error ⛔: please contact Admin to restore ticket.',
    toto: 'SportsToto',
    MultiNumberSearch: 'Multi Number Search',
    magnum: 'Magnum',
    self: 'Self',
    SecondReport: 'Second Report',
    FirstReport: 'First Report',
    EmptyFinalarray: 'Error ⛔:  Not Bet Edited !!',
    successExportList: 'Success Export Jackpot File.',
    SuccessClearTime: 'Successfull ClearTime',
    ViewSGDHistory: 'View SGD Rate History',
    CloseSGDHistory: 'Close SGD Rate History',
    NoUpdateDetected: 'Error ⛔: No Update Detected',
    SuccessUpdateRate: 'Successful Updated SGD Rate.',
    SuccessUpdate: 'Successful Updated SGD or Thai Rate.',
    SelectedUsername: 'Selected User: ',
    SelectedUsernameno: 'Selected User',
    FourDResult: '4D Results',
    ClaseTime: 'Closing Time',
    CloseTimePlatform1: 'Magnum (M) Damacai (K) Toto(T) Sarawak(W) Sabah(B) Sandakan(D)',
    CloseTimePlatform2: 'Singapore(S)',
    GrandDragon: 'Grand Dragon(8)',
    Simple: 'Simple',
    Limit: 'Limit',
    DateTime: 'DateTime',
    CurrentSgdRate: 'Current SGD Rate :',
    CurrentThaiRate: 'Current THAI Rate :',
    PleaseSelectedDate: 'Download fighting date',
    TodaySalesReport: 'Today Sales Report',
    SgdRate: 'SGD Rate',
    ThaiRate: 'THAI Rate',
    OverLimitHome: 'OrderLimit',
    AlreadyCancalled: 'Error ⛔: This Ticket Already Been Cancelled',
    Over7Home: 'AbleOrder',
    FreeJackpotList: 'Free Jackpot List',
    UserExportExcel: 'User Export Excel',
    Time: 'Time',
    OverNumberLimit: 'Error ⛔: Over Number Limit',
    Mode: 'Mode',
    SuccessOpenAccount: 'Success Unlocked Account',
    PleaseSelectedPackage: 'Error ⛔: Please Select 1 Package',
    SendSMSGroup: 'Send SMS Group',
    GenerateExcel: 'Generate Excel',
    HideZero: 'Hide 0',
    SendMessage: 'Send Message',
    ConfirmSendMessage: 'Confirm Send Message to',
    ToPhone: 'Phones ?',
    Message: 'Message',
    Phone: 'Phone',
    RedNumber: 'SPR',
    SalesReport: 'Sales Report',
    SalesTypeReport: 'Sales Report Details',
    eatSalesReport: 'Sales Report Fighting',
    OrderNotYetFinish: 'Error ⛔: Order not finished yet, please wait for awhile',
    Red: 'SPR',
    ErrorMessageAllAccountData: 'Error ⛔: Sila Maksudkan Semua Account Details',
    Fighting: 'Fighting',
    DisplayMode: 'Display',
    Number: 'Number',
    NoResult: 'No Result',
    Report: 'Report',
    SubmitNews: 'Create News',
    ReportforHeader: 'Report',
    setting: 'Setting',
    HR: 'Members',
    IBox: 'IBox',
    Home: 'Home',
    NoRedNumberFound: 'Error ⛔: No Data Found',
    RedNumberList: 'Red Number List',
    ErrorDueToCancelled: 'Error ⛔: Due To Receipt Alredy Been Cancelled.',
    NoPackageFound: 'No Package Found', 
    CurrentexistPackage: 'Current Existed Package',
    SearchRedNumber: 'Search Red Number',
    MultiplePackageMessage: 'Please choose to activate or cancel the selected package',
    SelectedActive: 'Active Selected Packages',
    SelectedCancel: 'Cancel Selected Packages',
    NotAllowChinese: 'Error ⛔: Chinese word Detected', 
    ErrorEmptyRate: 'Error ⛔: Empty Current SGD Rate.',
    Search: 'Search',
    Action: 'Action',
    Actionby: 'Actionby',
    Balance: 'Balance',
    ForwardOn: 'Forward On', 
    ForwardType: 'Forward Type',
    Date: 'Date',
    LastBetDate: 'Last Bet Date',
    DadingHistory: 'Dading Records',
    Edit: 'Edit',
    Delete: 'Delete',
    MultiDelete: 'Multi Delete',
    Loading: 'Loading... Please Wait A Moment',
    Logout: 'Logout',
    AddRedNumber: 'Add Red Number',
    Platform: 'Platform',
    Ban: 'Ban',
    DeleteallUsers: 'Confirm Delete Users',
    DeleteFast: 'Delete Users',
    LimitGame: 'Limit Game',
    Games: 'Game',
    LimitPersonal: 'Limit Personal',
    AccountInactive: 'Account Inactive',
    ExceedDAilyLimit: '⛔ Exceed Personal Daily Limit',
    ExceedEwPackage: '⛔ Exceed Personal EwPackage',
    ExceedPackageAmount : '⛔ Exceed Package Amount',
    RepeatOrder: 'Error ⛔: Double Ticket',
    System: 'System',
    UserNobleSGD: 'Error ⛔: User No Able SGD',
    NotEnoughWallet: 'Error ⛔: Not enough cash wallet',
    LeftCashWallet: 'Balance Left',
    Virtual: 'Virtual',
    CannotCancelpastticket: 'Error ⛔: Unable to delete, due to ticket already passed',
    Default: 'Default',
    ApplicableTo: 'Applicable To',
    RedNumberDetail: 'Red Number Detail',
    Layer: 'Layer',
    ShowAll: 'Show All',
    All: 'All',
    DeleteallLongTermButton: 'Delete All Long Term',
    BetAllLongterm: 'Order All Long Term Ticket',
    Add: 'Add',
    Reset: 'Reset',
    Key1Duplicated: 'Error ⛔: Key1 Duplicated Detected', 
    Key2Duplicated: 'Error ⛔: Key2 Duplicated Detected', 
    Close: 'Close',
    Simplicity: 'Simplicity',
    BackToUpline: 'Back To Upline',
    LimitTo: 'Limit To',
    AllUser: 'All User',
    AllLongTermBeenOrder: 'all long term have been order', 
    AllLongTermDelete: 'all long term have been delete successful',
    NumberOnly: '⛔ Please insert number only, no character allow',
    Option: 'Option ',
    DeletedLine: '⛔ Unable to delete this line',
    ConfirmDeletedLine: 'Are you sure you want to delete this line ?',
    EditAuthority: 'Edit Authority',
    HintAuthority: '*Hint: if you not sure, please do not tick it.*',
    ClearAllData: 'Are you sure you want to clear all the data ?',
    FourDigits: '⛔ Only Work At 2D, 3D and 4D',
    FDigits: 'Error ⛔: 4D Not Allow to bet here.',
    FiveDigits: 'Error ⛔: 5D Not Allow to bet here.',
    SixDigits: 'Error ⛔: 6D Not Allow to bet here.',
    ThreeDigits: 'Error ⛔: 6D Not Allow to bet here.',
    NoBlank: 'Please Fill In, Don\'t Leave It Blank',
    DuplicateRedNumber: 'Duplicate Number or User',
    DuplicatedUSer: 'Error ⛔: Duplicated User',
    MKTGameShutDown: 'MKTSWBD ShutDown',
    Invalidword: 'Error ⛔: Selected Receipt Invalid Word ID.',
    DrawDate: 'Draw Date',
    BettDate: 'Bet Date',
    InvalidformatofmultiNumber: 'Error ⛔: Invalid Format of MultiNumber *(Must be number)*',
    UserID: 'UserID',
    AccountSales: 'Sales Account',
    BakcToSalesReport: 'Back To Account Sales',
    AddedSuccess: 'Red Number Added',
    DatabaseError: '⛔ Database Error',
    LwMoney: 'Less Money',
    GotDownlineCantDelete: 'Delete Fails ⛔: Downline Been detected under selected user',
    UpdateSuccess: 'Update Successfully',
    DeleteuserFaillastBet: 'Delete Fails ⛔: Selected Account have bet within a month',
    DeleteuserFaillastreport: 'Delete Fails ⛔: Selected Account have report within a month',
    DeletedAlert: 'Are you sure, you want to delete this sub account',
    DeletedGame: 'Are you sure, you want to delete this game',
    ActiveGame: 'Are you sure, you want to Active this game',
    RestoreGame: 'Are you sure, you want to restore this game',
    DeletedNews: 'Are your sure, you want to delete this news',
    PleaseSelectDate: 'Error ⛔: Please Select Date.',
    PleaseSelectGame: 'Error ⛔: Please Select Games.',
    AccountAmount: 'Open Acc Amount',
    PlatFormError: 'Error ⛔: Platfrom Error',
    Success: 'Success!!',
    pleaseinputyourgames: 'Error ⛔: Please Insert Betting Number',
    SuccessDeleted: 'Successful Deleted Number: ',
    minimum4Word: 'Error ⛔: Number Bet must be minimum 2 digit',
    minimum4Digit: 'Error ⛔: must be minimum 2 digit',
    ErrorMin2Digit: 'Error ⛔: must be minimum 2 digit, there are numbers less than 2 digit presence',
    DifferenceLength: 'Error ⛔: all the numbers length should be same with the first number input',
    minimum4Digit2: 'Error ⛔: must be minimum 2 digit',
    SomeKeyValueNotKeyin: 'Error ⛔: Please Insert all your Bet Amount',
    KeyinAmountError: 'Error ⛔: Please enter your bet amount',
    pleaseinputallyourgames: 'Error ⛔: Please insert all your betting numbers',
    SuccessOrderBet: 'Success Order Bet',
    NoDataFound: 'Error ⛔: No Data Found',
    Nomorefront: 'Error ⛔: this is the latest bet order, no more bet order in front',
    LastOrder: 'Error ⛔: no more bet order behind',
    singaporeGameClose: 'Singapore Game Close',
    AllGameClose: 'Error ⛔: All Game Close',
    databaseError: 'Error ⛔: database error',
    CancelSuccessful: 'Bet Ticket Cancelled Successfull',
    EmptyServerID: 'Error ⛔: Please Select Ticket',
    ExceededHoursNormal: 'Error ⛔: exceeded Time Limit, Functions cannot be use after 7 o clock',
    ExceededHours: 'Error ⛔: exceeded Time Limit, receipt cannot be cancelled after 7 o clock',
    AuthoExceededHours: 'Error ⛔: you dont have the authority to recall ticket after 7 o clock',
    AuthoExceededHoursRebuy: 'Error ⛔: you dont have the authority to rebuy ticket after 7 o clock',
    ExceededDate: 'Failed To Withdraw: due to the betting date already out of date',
    ExceedDate: 'Error ⛔: due to the betting date already out of date',
    ExceedDateExist: 'Error ⛔: there is some out of date betting on this receipt',
    CancelExceededDate: 'Failed to Cancel: due to the betting date already out of date',
    Addgame: 'Add Game',
    NoPackage: 'Error ⛔: No package, bet not accepted',
    RecallSuccessful: 'Success: Withdraw Ticket Success',
    Downline: 'Downline',
    PlatformError: 'Error ⛔: Platform not Select',
    BetEmptyError: 'Error ⛔: There is a problem with the bet number',
    WrongFormat: 'Error Format ⛔: StartBet Should not be bigger than EndBet Number.',
    Line: 'Line : ',
    BEmpty: 'Error ⛔: No bet number entered',
    FormatError: 'Error ⛔: Bet Format Error.',
    FiveDFormatError: 'Error ⛔: 5D Bet Format Error',
    SixDFormatError: 'Error ⛔: 6D Bet Format Error',
    ThreeFormatError: 'Error ⛔: 3D Bet Format Error',
    FourFormatError: 'Error ⛔: 4D Bet Format Error',
    TwoFormatError: 'Error ⛔: 2D Bet Format Error',
    SuccessCopied: 'Successful Copied',
    CopySuccess: 'Successful Copied SPR',
    CreateVIP: 'Create VIP User',
    EditVIP: 'Edit VIP User',
    SearchUser: 'Search User',
    AddVIP: 'Add VIP',
    UserDetail: 'User Detail',
    FormDateError: '⛔ fromDate bigger than toDate', 
    WaterOddsExceedError: '⛔ Odds/Waters Cannot exceed the limit!!',
    DisplayOrder: 'Display Order',
    CancelOrRevertOrder: 'Cancel Or Revert Order',
    EditOrder: 'Edit Order',
    RebuyOrder: 'Rebuy Order',
    AcctDisplay: 'AcctDisplay',
    CreateUser: 'Create User',
    DisplayablePackage: 'Displayable Package',
    EditBanNumber: 'Edit BanNumber',
    SalesControl: 'Sales Control',
    PackageSetting: 'Package Setting',
    PackageInput: 'Package Input',
    PhoneSetting: 'Phone Setting',
    EditGame: 'Edit Game',
    CancelOrRevertTimeoutOrder: 'Cancel Or Revert Timeout Order',
    EditTimeoutOrder: 'Edit Timeout Order',
    RebuyTimeoutOrder: 'Rebuy Timeout Order',
    PinOrOutWord: 'Pin Or OutWord',
    SystemSetting: 'System Setting',
    SetOpenDate: 'Set Open Date',
    AdminAbleEditAfterSeven: 'Admin Able Edit AfterSeven',
    SetGameResult: 'Set Game Result',
    CompileReport: 'Compile Report',
    EditUser: 'Edit User',
    admin: 'Admin',
    invalidUsername: '⛔ Invalid Username',
    DontHaveAutho: '⛔ You Do Not Have Authority For This Function.',
    PackSettingAutho: '⛔ you do not have Authority for Package Setting',
    UserNotAbleRed: '⛔ User Do not able to bet Red',
    CancelSuccessfulldisplay: 'Successfull Cancel',
    donotacceptModein5D: '⛔ 5D cannot accept any mode and IBox',
    donotacceptModein6D: '⛔ 6D cannot accept any mode and IBox',
    ClickQandSpaceBar: '*Press Q to Clear Data, Press SpaceBar to Confirm Ticket*',
    PhoneNumber: 'Phone Number: ',
    Commission: 'Commission',
    Win: 'Win',
    Receipt: 'Receipt',
    nombor: 'Number',
    ServerID: 'ServerID',
    Bet: 'Bet',
    usernameLanguage: 'Username',
    Name: 'Name',
    Come: 'Come',
    ReportLai: 'Come',
    NewCome: 'New Come',
    secondPeriod: '2 Period',
    ThreePeriod: '3 Period',
    BetSuccess: 'Bet Success !',
    UnableThoseDate: '⛔ Unable to buy those date',
    UnableBetThisPlat: 'Error ⛔: There are games that you are not allowed to bet on',
    DownlineEat: 'Downline Eat',
    ReportEat: 'Downline Eat',
    selfBet: 'Self Bet',
    ReportSelfBet: 'Self Bet',
    selfWater: 'Self Commissions',
    ReportSelfWater: 'Self Commissions',
    selfWin: 'Self Win',
    ReportSelfWin: 'Self Win',
    downlineBet: 'Downline Bet',
    ReportDownlineBet: 'Downline Bet',
    downlineWin: 'Downline Win',
    ReportDownlineWin: 'Downline Win',
    totalBet: 'Total Bet',
    ReportTotalBet: 'Total Bet',
    TotalWater: 'Total Commission',
    ReportTotalWater: 'Total Commission',
    totalWin: 'Total Win',
    ReportTotalWin: 'Total Win',
    totalProfit: 'Total Profit',
    ReportTotalProfit: 'Total Profit',
    fastRegister: 'Member(Fast)',
    totalNet: 'Total Net',
    Share: 'Share',
    ReportShares: 'Share',
    shares: 'Share',
    SharesWater: 'Share Commission',
    ReportSharesWater: 'Share Commission',
    Water: 'Commission',
    sharesWin: 'Share Win',
    SharesWin: 'Share Win',
    ReportSharesWin: 'Share Win',
    ShareProfit: 'Share Profit',
    ReportSharesProfit: 'Share Profit',
    Giveupline: 'Give Upine',
    ReportUpline: 'Give Upine',
    uplineWater: 'Upline Commission',
    ReportUplineWater: 'Upline Commission',
    uplineWin: 'Upline Win',
    ReportUplineWin: 'Upline Win',
    uplineProfit: 'Upline Profit',
    ReportUplineProfit: 'Upline Profit',
    WaterRate: 'Commission Net',
    ReportWaterProfit: 'Commission Net',
    Update: 'Update',
    EditGameLimit: 'Edit Default Game Limit',
    Active: 'Active',
    Deactive: 'Deactive',
    Total: 'Total',
    SearchAccount: 'Search Account',
    NoDadingData: 'Error ⛔: Empty Dading',
    USerLoginAcc: 'User Acc',
    Password: 'Password',
    Role: 'Role',
    DadingConvert: 'Dading Convert',
    SetGameLimit: 'Set Game Limit',
    ActivePackage: 'Active Package',
    ChangeDate: 'Changed Date',
    MD: 'M/D',
    Status: 'Status',
    Probation: 'Probation',
    PersonalSalesLimit: 'Personal sales limit/period',
    autodownload: 'autodownload',
    CashWallet: 'Cash Wallet',
    ClickTodownload: 'Click Here to download APK Version >>',
    ManageAdminAuthority: 'Manage Authority',
    GameResult: 'Game Result',
    Download: 'Download',
    DownloadDading: 'Download Dading',
    AllRedNumbers: 'All SPR',
    Numbers: 'Number',
    NOGAME: 'NO GAME',
    SuccessDeleteUser: 'Successful Delete User',
    Dading: 'Dading',
    AllDading: 'All',
    ConfirmDeletedading: 'Are you sure you want to delete this Dading ?',
    ConfirmDownloaddading: 'Are you sure you want to Download this Dading ?',
    DeleteUSerConfirmation: 'Are you sure you want to delete this account ?', 
    CreateGame: 'Create Games',
    DetailedReport: 'Detailed Report',
    EasyReport: 'Easy Report',
    Confirm : 'Confirm',
    ConfirmAccount: 'Confirm Account',
    AccountDoesnotMatch: 'Error ⛔: Username Doesnt not match', 
    ConfirmDeleteUsers: 'Confirm Delete User',
    DateCantbeBet: 'Error ⛔: Selected date cant be Bet',
    EmptyBet: 'Error ⛔: Empty Bet Detected',
    Warning: '⛔ Warning',
    Only4DWithAmount: 'Only 4D With Amount',
    Multiply: 'Multiply',
    Divide: 'Divide',
    THAI: 'THAI',
    ExportReport: 'Export Excel',
    Managepackage: 'Manage Package',
    EditReceipt: 'Edit Receipt',
    SuccessEditReceipt: 'Success Edit Receipts',
    EditAll: 'Edit All',
    PressEnterAddNewLine: 'Press Enter Key to Add a New Line',
    BetSimple: 'Bet(Simple Ver)',
    BetBoxVersion: 'Bet(Box Ver)',
    ChooseDate: 'Choose Date',
    Special: 'Special',
    BetNumber: 'Bet Number',
    ClearAll: 'Clear All',
    AddTo: 'Add',
    Auto: 'AUTO',
    ConfirmTicket: 'Bet',
    ConfirmRegister: 'Create Account',
    CurrentFormat: 'Current Format',
    Betting: 'Bet',
    Type: 'Type',
    WithdrawTicket: 'Withdraw Ticket',
    CancelTicket: 'Cancel Ticket',
    CopyReceipt: 'Copy Receipt',
    Rebuy: 'Rebuy',
    EditAndRebuy: 'Edit and Rebuy',
    BrowseTicket: 'Browse Receipt',
    BrowseLongTermTicket: 'Browse LongTerm Receipt',
    LongTermTicket: 'LongTerm',
    Account: 'Account',
    ConfirmDate: 'Confirm Date',
    OnlyBTandTOTOallow: 'Error ⛔: Only BT and TOTO is allow to Bet 5D and 6D',
    NormalDan: 'Normal Order',
    Normal: 'Normal',
    SGD:'SGD',
    RedDan: 'Red Order',
    AllDan: 'All',
    CreateAdmin: 'Create Admin Account',
    ViewDownlineReport: 'Downline Report',
    Avaiable: 'Avaiable',
    NotAvailable: 'Not Available',
    BeforeEdit: 'Before Edit',
    AfterEdit: 'After Edit',
    AllowCheckResult: 'Allow Users To Check 4D Result',
    Welcome: 'Welcome',
    ManageCreateAcc: 'Manage/Create Account',
    ReceiptManage: 'Manage Receipt/Ticket',
    ReceiptReport: 'View Report',
    CanBet: 'Allow to Bet, ',
    threeDifferenceLayout: '3 difference Layout',
    SimpleVer: 'Simple Ver',
    FastVer: 'Fast Ver',
    BoxVer: 'Box Ver',
    EmptyFindNumber: 'Error: Please insert the Number.',
    kuapingtai: 'Cross-platform',
    SuccessfullyCreatedActingUser: 'Successfully Created ActingUser',
    PleaseInsertUsername: '⛔ Please Insert Username',
    Passwordcannotempty: '⛔ Password Cannot Empty',
    PasswordWrong: '⛔ Wrong password', 
    Usernamecannotempty: '⛔ Username Cannot Empty',
    ActingAccount: 'Acting Account',
    DeleteSuccessful: 'Delete Successful',
    NoActingAccount: 'No Acting Account',
    FindNumber: 'Find Number',
    PlsClickInquire: '⛔ Please Click Inquire',
    PlsInsertPassword: '⛔ Please Insert Password',
    PlsChooseUserRole: '⛔ Please Choose User Role',
    PlsinsertName: '⛔ Please Insert Name',
    PlsInsertFormat: '⛔ Please Insert Format',
    PlsChooseSituation: '⛔ Please Choose Situation',
    PlsChooseProbation: '⛔ Please Choose User Probation',
    PlsInsertDailyLimit: '⛔ Please Insert DailyLimit',
    DailyLimit: 'Daily Limit',
    PlsChoosemultiplydivide: '⛔ Please Choose User Multiply Or Divide',
    PlsChooseUpperline: '⛔ Please Choose Upperline',
    InvalidKey: '⛔ Invalid Key',
    SuccessfulCreateUser: 'Successful Create User',
    SuccessRebuyReceipt: 'Successful Rebuy Ticket',
    InvalidDownlineIDorUsername: 'Invalid Downline ID Or Username',
    PleaseInsertUsernameOrID: '⛔ Please Insert Username Or ID',
    PleaseCheckAgain: 'Please Check Again',
    Upperline: 'Upperline',
    Custom: 'Custom',
    SignIn: 'Sign In',
    Identity: 'Identity',
    InputMethod: 'Input Method',
    Situation: 'Situation',
    Personalsaleslimit: 'Personal Sales Limit',
    Multiplydivide: 'Multiply/Divide',
    PrinterModel: 'Printer Model',
    PrinterAddress: 'Printer Address',
    ExtraLimit: 'Extra Limit',
    InvalidUser: 'Invalid User',
    Shutdown: 'Shut Down',
    ShutdownSingapore: 'Shut Down Singapore',
    SuccessfulUpdateConfigurationShutdown: 'Successful Update Configuration Shutdown',
    Number4digit: '⛔ Number Must Be 4 Digits', 
    Number7digit: '⛔ Number Must Be 7 Digits', 
    Number6digit: '⛔ Number Must Be 6 Digits', 
    Number5digit: '⛔ Number Must Be 5 Digits', 
    Positionmustbe1character: '⛔ Position Must Be 1 Character',
    Null: 'Null',
    GameDisplay: 'Game Display',
    Gamecreatedsuccessful: 'Game Created Successful',
    Unsuccess: 'Unsuccess',
    GameUpdateSuccessful: 'Game Update Successful',
    GameStatusHadBeenUpdated: 'Game Status Had Been Updated',
    GameID: 'GameID',
    Details: 'Details',
    PakageDetails: 'filter',
    zongtu: 'Details',
    FollowingGameSettingSameAsDefault:'The Following Game Settings Are The Same As Defaults',
    NotAllowOnFormular: '⛔ IBox Cannot be use on Formula',
    NoData: 'No Data',
    Back: 'Back',
    AgentReport: 'Agent Report',
    SelfProfit: 'Self Profit',
    WINLOST: 'WIN/LOST',
    Prize: 'Prize',
    EditSubAccount: 'Edit Sub Account',
    Permission: 'Permission',
    CreateSubAccount: 'Create Sub Account',
    BackUpperline: 'Back Upline',
    CreateAcc: 'Create New Account',
    LatestOdd: 'Latest Odd',
    Odd: 'Odd',
    Inquire: 'Inquire',
    AddPackage: 'Add Package',
    CopyPackage: 'Copy Package',
    Package: 'Package',
    NoUserFound: 'No Users Found',
    Money: 'Money',
    RedMoney: 'Red Money',
    AllBet: 'All Bets',
    HideBet: 'Hide Bets',
    AllKeys: 'All Keys',
    HideKeys: 'Hide Keys',
    EditedBetHistory: 'Ticket Edit History',
    SearchAllPackage: 'Search All Package',
    SearchPackageName: 'Search Package Name',
    CantViewAccount: '⛔ cant view accounts due to you dont have the Authority for this function',
    LoadingPleaseWaitAMoment: 'Loading ... Please Wait a moment',
    Areyousureyouwanttoremovethisbet: 'Are you sure you want to remove this bet',
    Areyousuretorepurchasethisorder: 'Are you sure to repurchase this order ?',
    OrderID: 'OrderID',
    OriginalText: 'Original Text:',
    Successfulrepurchasereceipt: 'Successful Repurchase Receipt',
    ErrorPlatform: 'Error: Platform got Problem', 
    FileName: 'File Name',
    BoxVersion: 'BoxVersion',
    RestoreSuccess: 'restore successfully',
    ShowLongTermOrder: 'Long-Term Orders',
    Document: 'Bet (Document Ver)',
    UploadCSV: 'Bet(CSV)',
    SimpleReport: 'Simple Report',
    FileType: 'FileType',
    AccSalesList: 'Acc Sales List',
    Sale: 'Sale',
    Manage: 'Manage',
    Comm: 'Comm',
    Nett: 'Nett',
    ChangeLanguage: 'Change Language',
    TranslateLanguageCN: '华语',
    TranslateLanguageEN: 'English',
    TranslateLanguageBM: 'Bahasa Melayu',
    Language: 'Language',
    HumanResourceManagement: 'members Management',
    Proceed: 'Proceed',
    RedNumberManagement: 'Red Number Management',
    ProceedToSubAccount: 'Proceed To Sub-Account',
    SuccessDeletedVIP: 'Successfully Delete',
    Onlyaddoneplusorminus: 'Can Only One Plus Or Minus',
    PackageNameEmpty: 'Package Name Empty',
    Submit: 'Submit',
    AccountHasBeenLocked: 'Account Has Been Locked, Please Find Admin to Unlock',
    InvalidAcc: '⛔ Invalid Account',
    InvalidPassword: '⛔ Invalid Password',
    Cannotemptytext: '⛔ Cannot Empty Text',
    Cannotemptynumber: '⛔ Cannot Empty Number',
    WinNet: 'Win Net',
    ReportWinProfit: 'Win Net',
    WinProfit: 'Win Profit',
    Displayall: 'Display All',
    must4digit: '⛔ must 4 digit,',
    EditChanged: 'Edit Changed',
    SeacrhPackage: 'Search Package',
    Company: 'Company',
    Tong: 'Tong',
    Open: 'Open',
    SuccessfullyCreated: 'Successfully Created',
    CannotRecalculate: '⛔ Your account cannot recalculate grades',
    GameHavenStart: 'Game Haven Start Yet.',
    RedWhite: 'All',
    ShutDownDeactive: 'Inactive', 
    RedReceipt: 'Red Receipt',
    UserNoAbleTHAI: '⛔ User Do not able to bet Thai',
    SGDThaiWrongSetting: '⛔ SGD Thai Wrong Setting',
    NonAdminXcancel: '⛔ Non-admin cannot cancel',
    White: 'Normal',
    GameClosePackage: 'Game Closed, cannot update package',
    UpperlineNotSamePackage: 'Upperline not same package',
    FastVersion: 'Express Version',
    VeryBig: 'Big',
    VerySmall: 'Small',
    GameSetting: 'Game Setting',
    DownlinegotPackage: 'Downline Got Package',
    Packagegotorders: 'Package Got Orders',
    ActiveMultiplePackage: 'Are you sure, you want to active all this packages !! ',
    FirstTimeChangePassword: 'First Time Login Must Change Password User.',
    CurrentPassword: 'Current Password',
    ChangePassword: 'Change Password',
    CurrentPasswordNotMatch: '⛔ Current Password Not Correct',
    CurrentPasswordEmpty: '⛔ Cannot Empty In Current Password',
    NewPassword: 'New Password',
    AbleOrderAfterSeven: 'AbleOrderAfterSeven',
    IgnoreOrderLimit: 'IgnoreOrderLimit',
    NewPasswordEmpty: '⛔ Cannot Empty In New Password',
    RepeatPassword: 'Repeat New Password',
    Notenoughcashwallet: 'Not enough cash wallet',
    RepeatPasswordEmpty: '⛔ Cannot Empty In Repeat Password',
    PasswordChangedSuccessfully: 'Password Changed Successfully',
    NRPasswordDifferent: '⛔ New Password And Repeat Password Did Not Match !!',
    LLC: 'LLC',
    FDG: 'FDG',
    FailDailyLimit: '⛔ Fail, Daily Limit Greater Than Upline Daily Limit',
    PackageHigher: '⛔ This package is higher than the online package',
    Create: 'Create',
    DownlineNotSamePackage: 'Downline not same package',
    SuccessActivePackage: 'Successful Activated Selected Package',
    Unabletoupdate: 'Unable to update',
    MataLock: 'Mata Lock',
    // self: 'Self',
    downline: 'Self And Downline',
    Singular: 'Display Singular',
    NoGamenine: '⛔ Fail: dont have permission for Game 9',
    Show: 'Show',
    Hide: 'Hide',
    News: 'NEWS',
    CreateNews: 'Create News',
    Information: 'Information',
    InfoUpdated: 'Information Updated',
    NewInfoAdded: 'New Info Added',
    NewsManage: 'News Manage',
    Link: 'Link',
    SetupSuccess: 'Setup Successful',
    DemoCannotBet: 'Demo cannot bet long term',
    SGDCannotBet: 'SGD cannot bet long term',
    ThaiCannotBet: 'Thai cannot bet long term',
    UserAction: 'User Action',
    Page: 'Page',
    Table: 'Table',
    FromData: 'Before Action',
    ToData: 'After Action',
    BettingFastVersion: 'Bet(Express Version)',
    BettingBoxVersion: 'Bet(BoxVersion)',
    BettingRed: 'Bet(Red Number)',
    BettingSgd: 'Bet(SGD)',
    BettingThai: 'Bet(Thai)',
    SubAcc: 'Sub Account',
    BiggerThan: 'bigger than',
    ZhonglaiCalculate: 'Lai Calculate Report',
    LimitEveryone: 'LimitEveryone',
    LimitBSA: 'LimitBSA',
    VIPLIMIT: 'VIP LIMIT',
    UserDuplicated: 'Error ⛔: Duplicate Username',
    CannotOverPercen: 'Cannot Over 100%',
    CannotOverPercen2: 'Cannot Over 20%',
    Amount: 'Amount',
    OrderTime: 'Order Time',
    ShowReal: 'Show Real',
    VIP: 'VIP',
    Percent: 'Percent',
    DownlinePercent: 'Downline Percent',
    Code: 'Code',
    TotalAmount: 'Amount',
    SMSReport: 'SMS Report',
    phoneNumber: 'Phone Number',
    AdminSetup: 'Admin Setup',
    Username: 'Username',
    StarAndAll: '**IBox',
    SmalltoBig: 'Small to Big',
    bigtoSmall: 'Big to Small',
    FollowUserName: 'Follow Username',
    FollowName: 'Follow Name',
    Setup: 'Setup',
    Demo: 'Demo',
    AdminOnly: 'Admin Only',
    GameBetting: 'Game Betting',
    AboutAccount: 'Account',
    Find: 'Find',
    Password6digit: 'Password must have 4 digit',
    FightingFormat : 'Fighting Format',
    AddCommissionOneSide : 'Add Commission One Side',
    AddCommissionSingle : 'Add Commission Single',
    AutomaticallyAddWater : 'Automatically Add Commission',
    FightingSetting: 'Fighting Setting',
    ActiveP: 'Active Package',
    Odds: 'Odds',
    HolyWater: 'HolyWater',
    From: 'From',
    to: 'to',
    CancelPackage: 'Cancel Package',
    DisplayError: 'Reject Format',
    EditPackage: 'Edit Package',
    History: 'History',
    UnableSetupHigherUpline: 'Unable to setup because amount higher than upline',
    Duplicatepackagename: 'Duplicate Package Name',
    packageName: 'Package Name',
    PackageID: 'Package ID',
    CheckAll: 'Check All',
    Day1: 'Monday',
    Day2: 'Tuesday',
    Day3: 'Wednesday',
    Day4: 'Thursday',
    Day5: 'Friday',
    Day6: 'Saturday',
    Day0: 'Sunday',
    UnableActiveDueToPendig: 'Error ⛔: Unable active due to have pending order',
    CannotAcceptSymbol: `⛔ Special symbols ( " ' ) cannot be accepted`,
    AboutBetting: 'Betting',
    Unableupdatecashwallet: 'Upline Not Enough Daily Limit',
    CannotMoreThan6: 'Cannot more than 6',
    CannotMoreThan5: 'Cannot more than 5',
    CannotMoreThan4: 'Cannot more than 4',
    CannotMoreThan3: 'Cannot more than 3',
    CannotMoreThan2: 'Cannot more than 2',
    CannotMoreThan1: 'Cannot more than 1',
    CannotbeEmpty: 'Error ⛔: Cannot be Empty',
    InsertMorethan1: 'Error ⛔: Please Enter more than 1 word',
    WrongCapcha: 'Wrong Capcha',
    SenNotAdd: 'Sen Not Add',
    Ezmode: 'Simple',
    waternotmore2: 'Cannot more then 2 digit',
    complexmode: 'Complex',
    Chai: 'Chai',
    BuChai: 'Bu Chai',
    Below20: 'Can only choose below 20.',
    DuplicateNumber: 'Duplicate Number ',
    PrintAddress: 'Print Address',
    less12digit: 'Cannot less than 12 digit',
    Selffightrednumber: 'Self NO Fight Red Number',
    Selffightrednumber2: 'Fill in or tick the words not to eat, and the words not to eat will be counted from the set time',
    fight4: 'Fight 4',
    fight6: 'Fight 6',
    fight12: 'Fight 12',
    fight24: 'Fight 24',
    norecord: 'No Record',
    createselfeatword: 'Create self NO fight red number, do you want to proceed ?',
    BypassWord: 'Bypass Word',
    State8: 'Checking 8 sides will give you the same odds as MKT, do you agree?',
    Enter1box: 'Only can be entered one, which is (S)ID, (#)ID and Bet Number !',
    acceptDBF: 'Accept .DBF file',
    nofile: 'NO FILE',
    onezeroone: '101 转 D#',
    fileempty: 'Empty File',
    copy: 'Copy',
    plsinsert: 'Please Insert (#)ID',
    plsinsertN: 'Please Insert Number',
    Allow101orders: 'Allow 101 Orders',
  },
  cn: {
    daysOfWeek: [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六',
    ],
    first: '首奖',
    second: '二奖',
    third: '三奖',
    fourth: '四奖',
    fifth: '五奖',
    sixth: '六奖',
    special: '特别奖',
    consolation: '安慰奖',
    EmptySelected: '错误 ⛔: 请选择删除红字!!',
    america: '美国',
    damacai: '大马彩',
    CheckPackage: '查看配套',
    JEstimated: '预计下期万字积宝奖金',
    NoGameThatDay: '错误 ⛔: 您选择的日期还没添加游戏',
    UserRedLowerThanCommission: '错误 ⛔: 红字水钱少于0',
    CommissionNegative: '错误 ⛔: 水钱负数请联络上线',
    Jackpot: '积宝',
    GdToto: '豪龙彩票',
    sabah: '沙巴万字',
    sandakan: '山打根赛马场',
    singapore: '新加坡',
    sarawak: '砂拉越大万',
    CannotRestore: '错误 ⛔: 请联络admin 恢复/restore此单.',
    toto: '多多',
    MultiNumberSearch: '多重号码寻找',
    magnum: '万能',
    self: '自己',
    SecondReport: '第二报告',
    FirstReport: '第一报告',
    EmptyFinalarray: '错误 ⛔: 没发现更改!!',
    successExportList: '成功导出6D文件.',
    SuccessClearTime: '成功清存',
    ViewSGDHistory: '查看SGD汇率历史',
    CloseSGDHistory: '关闭SGD汇率历史',
    NoUpdateDetected: '错误 ⛔: 没侦测到任何更新',
    SuccessUpdateRate: '成功更新当前SGD汇率.',
    SuccessUpdate: '成功更新当前SGD或THAI汇率.',
    SelectedUsername: '已选择用户: ',
    SelectedUsernameno: '已选择用户',
    FourDResult: '开采成绩',
    ClaseTime: '关闭时间',
    CloseTimePlatform1: '万能(M) 大马彩(K) 多多(T) 砂捞越(W) 沙巴(B) 山打根(D)',
    CloseTimePlatform2: '新加坡(S)',
    GrandDragon: '豪龙(8)',
    Simple: '简易',
    Limit: '限制',
    PleaseSelectedDate: '请选择下载吃字日期',
    TodaySalesReport: '今日下单销售报告',
    CurrentSgdRate: '当前SGD汇率 :',
    CurrentThaiRate: '当前THAI汇率 :',
    SgdRate: 'SGD汇率',
    ThaiRate: 'THAI汇率',
    OverLimitHome: '超额设置',
    AlreadyCancalled: '错误 ⛔: 此单据已被取消',
    Over7Home: '逾时下单',
    FreeJackpotList: '免费6D列表',
    UserExportExcel: '导出Excel用户',
    DateTime: '日期/时间',
    Time: '时间',
    OverNumberLimit: '错误 ⛔: 一张单只限4000字',
    Mode: '模式',
    SuccessOpenAccount: '成功开启账户',
    PleaseSelectedPackage: '错误 ⛔: 请选择配套',
    SendSMSGroup: '简略发送SMS群',
    GenerateExcel: '生成Excel',
    HideZero: '隐藏 0',
    SendMessage: '发送信息',
    ConfirmSendMessage: '确定要发送信息给这',
    ToPhone: '些电话号码吗？',
    Message: '信息',
    Phone: '电话号码',
    RedNumber: 'SPR',
    SalesReport: '销售报表',
    SalesTypeReport: '销售类别报表',
    eatSalesReport: '吃字报表',
    OrderNotYetFinish: '错误 ⛔: 还没进完单,请稍等片刻',
    Red: 'SPR',
    ErrorMessageAllAccountData: '错误 ⛔: 请输入完整账户质料',
    Fighting: '吃字',
    DisplayMode: '显示',
    Number: '字',
    NoResult: '无记录',
    Report: '报告',
    ReportforHeader: '结算报告',
    SubmitNews: '创造消息',
    setting: '设置',
    HR: '人员',
    IBox: '全保',
    Home: '首页',
    NoRedNumberFound: '错误 ⛔: 找不到相关数据',
    RedNumberList: '红字列表',
    ErrorDueToCancelled: '错误 ⛔: 被取消的单不可用此功能.',
    NoPackageFound: '没有配套', 
    CurrentexistPackage: '现有配套',
    SearchRedNumber: '寻找红字',
    MultiplePackageMessage: '请选择激活或取消已选择配套',
    SelectedActive: '激活选择配套',
    SelectedCancel: '取消选择配套',
    NotAllowChinese: '错误 ⛔: 下注格式存在中文字', 
    ErrorEmptyRate: '错误 ⛔: 当前SGD汇率是空的.',
    Search: '寻找',
    Action: '行动',
    Actionby: '行动',
    Balance: '余额',
    ForwardOn: '发送日期', 
    ForwardType: '发送种类',
    Date: '日期',
    LastBetDate: '最后下注日期',
    DadingHistory: '打钉记录',
    Edit: '更改',
    Delete: '删除',
    MultiDelete: '多重删除',
    Loading: '加载中... 请稍等',
    Logout: '登出',
    AddRedNumber: '添加红字',
    Platform: '平台',
    Ban: '禁止',
    DeleteallUsers: '删除用户',
    DeleteFast: '快速删除用户',
    LimitGame: '限制游戏',
    Games: '游戏',
    LimitPersonal: '限制个人',
    AccountInactive: '无效用户, 请联络上线',
    ExceedDAilyLimit: '⛔ 超出个人限制',
    ExceedEwPackage: '⛔ 超出Ew配套',
    ExceedPackageAmount : '⛔ 超出配套数量',
    RepeatOrder: '错误 ⛔: 双下单',
    System: '系统',
    UserNobleSGD: '错误 ⛔: 不接受SGD',
    NotEnoughWallet: '错误 ⛔: 不够余额',
    LeftCashWallet: '您的余额剩下',
    Virtual: '虚拟',
    CannotCancelpastticket: '错误 ⛔: 单据已过期, 不允许删除',
    Default: '默认',  
    ApplicableTo: '适用于',
    RedNumberDetail: '红字详情',
    Layer: '层数',
    ShowAll: '全部',
    All: '全部',
    Add: '添加',
    DeleteallLongTermButton: '删去所有长期单',
    BetAllLongterm: '下注所有长期单',
    Reset: '重置',
    Key1Duplicated: '错误 ⛔: Key1 存在重复的 key', 
    Key2Duplicated: '错误 ⛔: Key2 存在重复的 key',
    Close: '关闭',
    Simplicity: '简约',
    BackToUpline: '返回上线',
    LimitTo: '限制于',
    AllUser: '全用户',
    secondPeriod: '2 期',
    ThreePeriod: '3 期',
    BetSuccess: '下注成功',
    UnableThoseDate: '⛔ 购买日期有问题',
    UnableBetThisPlat: '错误 ⛔: 此单存在您不被允许下注的游戏',
    AllLongTermBeenOrder: '全长期单以下注', 
    AllLongTermDelete: '全长期单以删除',
    NumberOnly: '⛔ 请输入号码，不接受字符',
    Option: '选项 ',
    DeletedLine: '⛔ 无法删除此行',
    ConfirmDeletedLine: '您确定要删除此行吗 ?',
    EditAuthority: '更改权限',
    HintAuthority: '*提示: 如果您不确定, 请不要打勾.*',
    ClearAllData: '您确定要清除所有数据吗 ?',
    FourDigits: '⛔ 只有2D,3D和4D的情况下才可以使用',
    FDigits: '错误 ⛔: 在4D的情况下不允许在此处下注',
    FiveDigits: '错误 ⛔: 在5D的情况下不允许在此处下注',
    SixDigits: '错误 ⛔: 在6D的情况下不允许在此处下注',
    ThreeDigits: '错误 ⛔: 在3D的情况下不允许在此处下注',
    NoBlank: '请输入详情，不可放空',
    DuplicateRedNumber: '错误 ⛔: 重复号码或用户',
    DuplicatedUSer: '错误 ⛔: 重复用户',
    MKTGameShutDown: 'MKTSXXX已关闭',
    Invalidword: '错误 ⛔: 选择单据无效word.',
    DrawDate: '开采日期',
    BettDate: '下注日期',
    InvalidformatofmultiNumber: '错误 ⛔: 户口数量的格式有问题',
    UserID: '用户身份',
    AccountSales: '户口销售',
    BakcToSalesReport: '回到户口销售',
    AddedSuccess: '红字已添加成功',
    DatabaseError: '⛔ 数据库错误',
    LwMoney: '少水钱',
    GotDownlineCantDelete: '删除失败 ⛔: 选择的用户存在下线',
    UpdateSuccess: '成功更新',
    DeleteuserFaillastBet: '删除失败 ⛔: 选择的用户在30天内曾经下过单',
    DeleteuserFaillastreport: '删除失败 ⛔: 选择的用户还存在30天内的报告',
    DeletedAlert: '您确定要删除这个子账号吗',
    DeletedGame: '您确定要删除这个游戏吗',
    ActiveGame: '您确定要活跃这个游戏吗',
    RestoreGame: '您确定要恢复此游戏吗', 
    DeletedNews: '您确定，您要删除信息',
    PleaseSelectDate: '错误 ⛔: 未选择日期',
    PleaseSelectGame: '错误 ⛔: 未选择游戏',
    AccountAmount: '户口数量',
    PlatFormError: '错误 ⛔: 游戏错误',
    Success: '成功!!',
    pleaseinputyourgames: '错误 ⛔：请输入下注号码',
    SuccessDeleted: '成功删除下注号码: ',
    minimum4Word: '错误 ⛔: 投注号码必须最少为 2 位数字',
    minimum4Digit: '错误 ⛔: 必须至少为 2 位数',
    ErrorMin2Digit: '错误 ⛔: 号码必须至少为 2 位数，存在低于2位数的号码',
    DifferenceLength: '错误 ⛔: 所有输入的号码长度必须和第一个输入的号码长度一样',
    minimum4Digit2: '错误 ⛔: 必须至少为 2 位数',
    SomeKeyValueNotKeyin: '错误 ⛔：请输入您每一个投注的金额',
    KeyinAmountError: '错误 ⛔：请输入您的投注金额',
    pleaseinputallyourgames: '错误 ⛔：请输入所有下注号码',
    SuccessOrderBet: '成功下单',
    NoDataFound: '错误 ⛔: 找不到相关单据',
    Nomorefront: '错误 ⛔: 这是最新的单据了, 前面已经没有单据了',
    LastOrder: '错误 ⛔: 后面没有单据了',
    singaporeGameClose: '错误 ⛔: 新加波没开',
    AllGameClose: '错误 ⛔: 所有游戏没开',
    databaseError: '错误 ⛔: 数据库发生错误',
    CancelSuccessful: '成功: 单据取消成功',
    EmptyServerID: '错误 ⛔: 请选择单据',
    ExceededHours: '错误 ⛔: 超出时间，7点后不可取消单据',
    ExceededHoursNormal: '错误 ⛔: 超出时间，7点后不可使用此功能',
    AuthoExceededHours: '错误 ⛔: 您没有在7点后撤回单据的权限',
    AuthoExceededHoursRebuy: '错误 ⛔: 您没有在7点后重买单据的权限',
    ExceededDate: '撤回失败: 由于单据下注日期已无效',
    ExceedDate: '失败: 由于单据下注日期已无效',
    ExceedDateExist: '失败: 此单据存在过期下注',
    CancelExceededDate: '取消失败: 由于单据下注日期已无效',
    Addgame: '添加游戏',
    NoPackage: '错误 ⛔: 没有配套, 不接受下注',
    RecallSuccessful: '成功: 成功撤回单据',
    Downline: '下线',
    PlatformError: '错误 ⛔：未选择平台',
    BetEmptyError: '错误 ⛔: 下注号码出现问题',
    WrongFormat: '错误格式 ⛔: StartBet 不该大于 EndBet 下注号码.',
    Line: 'Line : ',
    BEmpty: '错误 ⛔: 未输入下注号码',
    FormatError: '错误 ⛔: 下注格式错误',
    FiveDFormatError: '错误 ⛔: 5D下注格式错误',
    SixDFormatError: '错误 ⛔: 6D下注格式错误',
    ThreeFormatError: '错误 ⛔: 3D下注格式错误',
    FourFormatError: '错误 ⛔: 4D下注格式错误',
    TwoFormatError: '错误 ⛔: 2D下注格式错误',
    SuccessCopied: '成功复制单据',
    CopySuccess: '成功复制红字',
    CreateVIP: '创建VIP用户',
    EditVIP: '编辑 VIP 用户',
    SearchUser: '搜索用户',
    AddVIP: '添加贵宾',
    UserDetail: '用户详情',
    FormDateError: '⛔ fromDate 大于 toDate',
    WaterOddsExceedError: '⛔ 更改赔率/水钱不可超过极限!!',
    DisplayOrder: '單頁 - 顯示',
    CancelOrRevertOrder: '單頁 - 取消 / 還原',
    EditOrder: '單頁 - 更改',
    RebuyOrder: '單頁 - 重買',
    AcctDisplay: '戶口 - 顯示',
    CreateUser: '戶口 - 創新',
    DisplayablePackage: '戶口 - 可顯示配套',
    EditBanNumber: '紅字 - 更改',
    SalesControl: '销售控制 - 更改',
    PackageSetting: '設置 - 配套',
    PackageInput: '設置 - 輸入法',
    PhoneSetting: '手機設定',
    EditGame: '编辑游戏',
    CancelOrRevertTimeoutOrder: '超時單頁 - 取消/還原',
    EditTimeoutOrder: '超時單頁 - 更改',
    RebuyTimeoutOrder: '超時單頁 - 重買',
    PinOrOutWord: '打釘/出字',
    SystemSetting: '設置 - 系統',
    SetOpenDate: '設置開彩期',
    AdminAbleEditAfterSeven: 'Admin逾时改单',
    SetGameResult: '設置開彩成績',
    CompileReport: 'Compile Report',
    EditUser: '戶口 - 更改',
    admin: '管理员',
    invalidUsername: '⛔ 用户不存在',
    DontHaveAutho: '⛔ 您没有此功能的权限',
    PackSettingAutho: '⛔ 您没有設置配套的权限',
    UserNotAbleRed: '⛔ 您没有下注红单的权限',
    CancelSuccessfulldisplay: '成功取消此单',
    donotacceptModein5D: '⛔ 5D不支持选择总类与全保',
    donotacceptModein6D: '⛔ 6D不支持选择总类与全保',
    ClickQandSpaceBar: '*按Q键清空, 按SpaceBar键发送单据*',
    PhoneNumber: '电话: ',
    Commission: '佣金',
    Win: '中奖',
    Receipt: '单页',
    nombor: '号码',
    ServerID: '母机 ID',
    Bet: '下注',
    usernameLanguage: '用户名',
    Name: '名称',
    Come: '总来',
    NewCome: '新总来',
    ReportLai: '总来',
    DownlineEat: '下线吃',
    ReportEat: '下线吃',
    selfBet: '自己下注',
    ReportSelfBet: '自己下注',
    selfWater: '自己水钱',
    ReportSelfWater: '自己水钱',
    selfWin: '自己中奖',
    ReportSelfWin: '自己中奖',
    downlineBet: '下线下注',
    ReportDownlineBet: '下线下注',
    downlineWin: '下线中奖',
    ReportDownlineWin: '下线中奖',
    totalBet: '总下注',
    ReportTotalBet: '总下注',
    TotalWater: '总水钱',
    ReportTotalWater: '总水钱',
    totalWin: '总中奖',
    ReportTotalWin: '总中奖',
    totalProfit: '总输赢',
    ReportTotalProfit: '总输赢',
    fastRegister: '人员（快速版）',
    totalNet: '总净',
    Share: '股份',
    ReportShares: '股份',
    shares: '股份',
    SharesWater: '股份水钱',
    ReportSharesWater: '股份水钱',
    Water: '水钱',
    sharesWin: '股份中奖',
    SharesWin: '股份中奖',
    ReportSharesWin: '股份中奖',
    ShareProfit: '股份输赢',
    ReportSharesProfit: '股份输赢',
    Giveupline: '出给上线',
    ReportUpline: '出给上线',
    uplineWater: '上线水钱',
    ReportUplineWater: '上线水钱',
    uplineWin: '上线中奖',
    ReportUplineWin: '上线中奖',
    uplineProfit: '上线输赢',
    ReportUplineProfit: '上线输赢',
    WaterRate: '水钱净赚',
    ReportWaterProfit: '水钱净赚',
    Update: '更新',
    EditGameLimit: '更改默认游戏下注限制',
    Active: '活跃',
    Deactive: '不活跃',
    Total: '总共',
    NoDadingData: '错误 ⛔: 空白打钉',
    SearchAccount: '寻找户口',
    USerLoginAcc: '登入',
    Password: '密码',
    Role: '身份',
    DadingConvert: '打钉转变',
    SetGameLimit: '设置游戏极限',
    ActivePackage: '启用配套',
    ChangeDate: '更改日期',
    MD: '乘/除',
    Status: '状况',
    Probation: '试用期',
    Demo: '试用',
    autodownload: '自動下載',
    PersonalSalesLimit: '个人销售额极限/期',
    CashWallet: '现金钱包',
    ClickTodownload: '点击这里下载APK版本 >>',
    ManageAdminAuthority: '管理用户权限',
    GameResult: '开彩',
    Download: '下载',
    DownloadDading: '下载打钉',
    AllRedNumbers: '全部红字',
    Numbers: '数目',
    NOGAME: '没有游戏',
    SuccessDeleteUser: '成功删除用户户口',
    Dading: '打钉',
    AllDading: '全部',
    ConfirmDeletedading: '您确定要删除打钉吗?',
    ConfirmDownloaddading: '您确定要下载此打钉吗 ?',
    DeleteUSerConfirmation: '您确定要删除此帐户吗 ?', 
    CreateGame: '创建游戏',
    DetailedReport: '详细模式',
    EasyReport: '简易模式',
    Confirm : '确定',
    ConfirmAccount: '确定用户',
    AccountDoesnotMatch: '错误 ⛔: 用户不匹配', 
    ConfirmDeleteUsers: '确定删除用户',
    DateCantbeBet: '错误 ⛔: 此日期无法下注', 
    EmptyBet: '错误 ⛔: 没有任何下注',
    Warning: '警告 ⛔',
    Only4DWithAmount: 'Only 4D With Amount',
    Multiply: '乘',
    Divide: '除',
    THAI: 'THAI',
    ExportReport: '导出报告',
    Managepackage: '管理报告',
    EditReceipt: '更改单据',
    SuccessEditReceipt: '成功更改单据',
    EditAll: '更改全部',
    PressEnterAddNewLine: '按Enter键增加新的一行',
    BetSimple: '投注(简易版)',
    BetBoxVersion: '下注(格子版)',
    ChooseDate: '请选择日期',
    Special: '特别',
    BetNumber: '下注号码',
    ClearAll: '清空',
    AddTo: '加入',
    Auto: '长期',
    ConfirmTicket: '发送',
    ConfirmRegister: '注册账户',
    CurrentFormat: '当前格式',
    Betting: '投注',
    Type: '种类',
    WithdrawTicket: '撤回',
    CancelTicket: '取消',
    CopyReceipt: '复制单据',
    Rebuy: '重买',
    EditAndRebuy: '改后重买',
    BrowseTicket: '游览单据',
    BrowseLongTermTicket: '游览长期单据',
    LongTermTicket: '长期单据',
    Account: '账号',
    ConfirmDate: '确定日期',
    OnlyBTandTOTOallow: '错误 ⛔: 只有TOTO和BT可以下注5D和6D',
    NormalDan: '普通单',
    Normal: '普通',
    SGD:'SGD',
    RedDan: '红单',
    AllDan: '全部',
    CreateAdmin: 'Admin戶口 - 創新',
    ViewDownlineReport: '下线模式',
    Avaiable: '可用的',
    NotAvailable: '无法使用',
    BeforeEdit: '改前',
    AfterEdit: '改后',
    AllowCheckResult: '可检查开采成绩',
    Welcome: '欢迎',
    ManageCreateAcc: '管理/创新下线', 
    ReceiptManage: '单据管理',
    ReceiptReport: '单页预付报告',
    CanBet: '可以下注, ',
    threeDifferenceLayout: '三种界面',
    SimpleVer: '简易版',
    FastVer: '快速版',
    BoxVer: '格子版',
    EmptyFindNumber: '错误 ⛔: 请输入要查询的号码.',
    kuapingtai: '跨平台',
    SuccessfullyCreatedActingUser: '成功创建代理用户',
    PleaseInsertUsername: '⛔ 请输入用户名', 
    Passwordcannotempty: '⛔ 密码不能为空',
    PasswordWrong: '⛔ 密码错误', 
    Usernamecannotempty: '⛔ 用户名不能为空',
    ActingAccount: '代理账户',
    DeleteSuccessful: '删除成功',
    NoActingAccount: '没有代理帐户',
    FindNumber: '寻找号码', 
    PlsClickInquire: '⛔ 请点击查询',
    PlsInsertPassword: '⛔ 请输入密码',
    PlsChooseUserRole: '⛔ 请选择用户角色',
    PlsinsertName: '⛔ 请输入姓名',
    PlsInsertFormat: '⛔ 请插入格式',
    PlsChooseSituation: '⛔ 请选择状况',
    PlsChooseProbation: '⛔ 请选择用户试用',
    PlsInsertDailyLimit: '⛔ 请输入每日限额',
    DailyLimit: '每日限额',
    PlsChoosemultiplydivide: '⛔ 请选择乘除',
    PlsChooseUpperline: '⛔ 请选择上线',
    InvalidKey: '⛔ 无效的密钥',
    SuccessfulCreateUser: '成功创建用户',
    SuccessRebuyReceipt: '成功重买此单据',
    InvalidDownlineIDorUsername: '无效的下线 ID 或用户名',
    PleaseInsertUsernameOrID: '请输入用户名或 ID',
    PleaseCheckAgain: '请再次检查',
    Upperline: '上线',
    Custom: '定制',
    SignIn: '登入',
    Identity: '级别',
    InputMethod: '输入法',
    Situation: '状况',
    Personalsaleslimit: '个人销售额',
    Multiplydivide: '乘/除',
    PrinterModel: '打印机型号',
    PrinterAddress: '打印机地址',
    ExtraLimit: '额外限制',
    InvalidUser: '无效的用户',
    Shutdown: '关闭',
    ShutdownSingapore: '关闭新加坡',
    SuccessfulUpdateConfigurationShutdown: '成功更新配置关闭',
    Number4digit: '⛔ 号码必须是 4 位数字', 
    Number7digit: '⛔ 号码必须是 7 位数字', 
    Number6digit: '⛔ 号码必须是 6 位数字', 
    Number5digit: '⛔ 号码必须是 5 位数字',
    Positionmustbe1character: '⛔ 位置必须是 1 个字符',
    Null: '空',
    GameDisplay: '游戏字母',
    Gamecreatedsuccessful: '游戏创建成功',
    Unsuccess: '失败',
    GameUpdateSuccessful: '游戏更新成功',
    GameStatusHadBeenUpdated: '游戏状态已更新',
    GameID: '游戏ID',
    Details: '过滤',
    PakageDetails: '过滤/寻找',
    zongtu: '详细',
    FollowingGameSettingSameAsDefault:'以下游戏设定同默认',
    NotAllowOnFormular: '⛔ IBox不允许使用于Formula',
    NoData: '没有数据',
    Back: '返回',
    AgentReport: '代理报告',
    SelfProfit: '输赢',
    WINLOST: '输/赢',
    Prize: '奖',
    EditSubAccount: '编辑子账号',
    Permission: '允许',
    CreateSubAccount: '创建子账户',
    BackUpperline: '回到上线',
    CreateAcc: '创新户口',
    LatestOdd: '最新单数',
    Odd: '单数',
    Inquire: '查询',
    AddPackage: '增加配套',
    CopyPackage: '复制配套',
    Package: '配套',
    NoUserFound: '没相关用户',
    Money: '水钱',
    RedMoney: '红水钱',
    AllBet: '全部下注',
    HideBet: '隐藏下注',
    HideKeys: '隐藏 Key',
    AllKeys: '全部 Key',
    EditedBetHistory: '单据更改记录',
    SearchAllPackage: '寻找配套',
    SearchPackageName: '寻找配套名称',
    CantViewAccount: '⛔ 由于您没有此功能的权限，因此无法查看帐户',
    LoadingPleaseWaitAMoment: '加载中...请稍等',
    Areyousureyouwanttoremovethisbet: '您确定要除去此下注吗',
    Areyousuretorepurchasethisorder: '是否确定重买此单 ?',
    OrderID: '订单 ID',
    OriginalText: '原文:',
    Successfulrepurchasereceipt: '成功重买单据',
    ErrorPlatform: '错误 ⛔: 游戏存在问题', 
    FileName: '文件名',
    BoxVersion: '格子版',
    RestoreSuccess: '恢复成功',
    ShowLongTermOrder: '显示长期单',
    Document: '投注(上传文件版)',
    UploadCSV: '投注(CSV)',
    FileType: '文件',
    SimpleReport: '简单报告',
    AccSalesList: '帐户销售列表',
    Sale: '销售',
    Comm: '水',
    Nett: '赢',
    Manage: '管理',
    ChangeLanguage: '更换语言',
    TranslateLanguageCN: '华语',
    TranslateLanguageEN: 'English',
    TranslateLanguageBM: 'Bahasa Melayu',
    Language: '语言',
    HumanResourceManagement: '人员管理',
    Proceed: '确定',
    RedNumberManagement: '红字管理',
    ProceedToSubAccount: '前往子帐户',
    SuccessDeletedVIP: '删除成功',
    Onlyaddoneplusorminus: '只能加减一个',
    PackageNameEmpty: '包名空',
    Submit: '提交',
    AccountHasBeenLocked: '请联络上线',
    InvalidAcc: '⛔ 无效账户',
    InvalidPassword: '⛔ 无效的密码',
    Cannotemptytext: '⛔ 不能为空文本',
    Cannotemptynumber: '⛔ 不能为空号码',
    WinNet: '奖金净赚',
    ReportWinProfit: '奖金净赚',
    WinProfit: '奖金',
    Displayall: '显示全部',
    must4digit: '⛔ 必须 4 位数,',
    EditChanged: '更改已改',
    SeacrhPackage: '寻找配套',
    Tong: '桶股份计算',
    Company: '公司',
    Open: '开',
    SuccessfullyCreated: '成功创建',
    CannotRecalculate: '⛔ 你的帐户不能从新计算成绩',
    GameHavenStart: '今天游戏还没开始。',
    RedWhite: '全部',
    ShutDownDeactive: '不活跃', 
    RedReceipt: '红单',
    UserNoAbleTHAI: '⛔ 您没有下注Thai的权限',
    SGDThaiWrongSetting: '⛔ SGD Thai 设置错误',
    NonAdminXcancel: '⛔ 只有admin或superAdmin可以删除',
    GameClosePackage: '游戏关闭，无法更新配套',
    White: '普通单',
    UpperlineNotSamePackage: '上线不一样的配套',
    FastVersion: '快速版',
    VeryBig: '最大',
    VerySmall: '最小',
    GameSetting: '游戏设定',
    DownlineNotSamePackage: '下线不一样的配套',
    ActiveMultiplePackage: '你确定，你想激活这个配套！！',
    FirstTimeChangePassword: '首次登录必须更改密码用户。',
    CurrentPassword: '当前密码',
    CurrentPasswordNotMatch: '⛔ 当前密码不正确',
    ChangePassword: '更换密码',
    CurrentPasswordEmpty: '⛔ 当前密码不能为空',
    NewPassword: '新密码',
    AbleOrderAfterSeven: '逾时下单',
    IgnoreOrderLimit: '超额设置',
    NewPasswordEmpty: '⛔ 新密码不能为空',
    RepeatPassword: '重复新密码',
    Notenoughcashwallet: '现金钱包不足',
    RepeatPasswordEmpty: '⛔ 重复新密码不能为空',
    PasswordChangedSuccessfully: '密码修改成功',
    NRPasswordDifferent: '⛔ 新密码和重复新密码 不匹配',
    LLC: '层层吃',
    FDG: '分蛋糕',
    FailDailyLimit: '⛔ 失败，每日限额大于上线每日限额',
    PackageHigher: '⛔ 这个配套高于上线配套',
    Create: '创建',
    SuccessActivePackage: '成功激活所选配套',
    Unabletoupdate: '无法更新',
    MataLock: '锁机',
    // self: '仅自己',
    downline: '自己和全部下线',
    DownlinegotPackage: '下线有配套',
    Packagegotorders: '配套有下单',
    Singular: '单页数量',
    NoGamenine: '⛔ 失败: 您没有9号游戏的权限',
    Show: '显示',
    Hide: '隐藏',
    News: '消息',
    NewsManage: '消息管理',
    Link: '链接',
    DemoCannotBet: 'Demo户口不允许下长单',
    SGDCannotBet: 'SGD 户口不允许下长单',
    ThaiCannotBet: 'Thai 户口不允许下长单',
    SetupSuccess: '安装成功',
    UserAction: '用户记录',
    Page: '页面',
    CreateNews: '创建消息',
    Information: '信息',
    InfoUpdated: '信息更新',
    NewInfoAdded: '添加了新信息',
    Table: '表',
    BettingFastVersion: '投注(快速版)',
    BettingBoxVersion: '投注(格子版)',
    BettingRed: '投注(SPR)',
    BettingSgd: '投注(SGD)',
    BettingThai: '投注(THAI)',
    SubAcc: '子账户',
    FromData: '行动前',
    ToData: '行动后',
    BiggerThan: '大于',
    ZhonglaiCalculate: '总来计算报告',
    LimitEveryone: '一枪限制',
    LimitBSA: 'BSA超标限制',
    VIPLIMIT: '个体数额控制',
    UserDuplicated: '错误 ⛔: 重复用户名',
    CannotOverPercen: '不能超过 100%',
    CannotOverPercen2: '不能超过 20%',
    OrderTime: '下单时间',
    ShowReal: '真实',
    Percent: '百分比',
    DownlinePercent: '下线百分比',
    Amount: '数量',
    TotalAmount: '数目',
    VIP: 'VIP',
    Code: '代号',
    SMSReport: 'SMS报告',
    phoneNumber: '手机号码',
    AdminSetup: '人员 Setup',
    Username: '用户名',
    SmalltoBig: '小到大',
    bigtoSmall: '大到小',
    FollowUserName: '跟随用户名',
    FollowName: '跟随名称',
    StarAndAll: '**全保',
    Setup: '一次性更改所有下线配套',
    AdminOnly: 'Admin用途',
    GameBetting: '游戏排列',
    AboutAccount: '账户',
    Find: '寻找',
    Password6digit: '密码必须有 4 位数字',
    FightingFormat : '吃字方式',
    AddCommissionOneSide : '单面加水',
    AddCommissionSingle : '单后加水',
    AutomaticallyAddWater : '自动加水',
    FightingSetting: '吃字设置',
    ActiveP: '启动此配套',
    Odds: '赔率',
    HolyWater: '剩水',
    From: '从',
    to: '至',
    CancelPackage: '取消此配套',
    DisplayError: '退字列表',
    EditPackage: '更改配套',
    History: '记录',
    UnableSetupHigherUpline: '无法设置，因为金额高于上线',
    Duplicatepackagename: '配套名称重复',
    packageName: '配套名',
    PackageID: '配套ID',
    CheckAll: '全选',
    Day1: '星期一',
    Day2: '星期二',
    Day3: '星期三',
    Day4: '星期四',
    Day5: '星期五',
    Day6: '星期六',
    Day0: '星期日',
    UnableActiveDueToPendig: '错误 ⛔: 由于有待处理的订单而无法激活',
    CannotAcceptSymbol: `⛔ 不能接受特殊符号 ( " ' )`,
    AboutBetting: '下单',
    Unableupdatecashwallet: '上线限额不足',
    CannotMoreThan6: '不能超过 6 位数',
    CannotMoreThan5: '不能超过 5 位数',
    CannotMoreThan4: '不能超过 4 位数',
    CannotMoreThan3: '不能超过 3 位数',
    CannotMoreThan2: '不能超过 2 位数',
    CannotMoreThan1: '不能超过 1 位数',
    CannotbeEmpty: '错误 ⛔: 請輸入尋查號碼',
    InsertMorethan1: '错误 ⛔: 請輸入多過一個下注號碼',
    WrongCapcha: '错误Capcha',
    SenNotAdd: '毛钱不加水',
    Ezmode: '简单',
    waternotmore2: '不能超过 2 位数',
    complexmode: '复杂',
    Chai: '拆 ',
    BuChai: '不拆',
    Below20: '只能选择20以下.',
    DuplicateNumber: '重复号码 ',
    PrintAddress: '打印地址',
    less12digit: '不能少于12位数',
    Selffightrednumber: '封字',
    Selffightrednumber2: '填写或打勾不要吃的字，不要吃的字从设置时间开始计算',
    fight4: '4打',
    fight6: '6打',
    fight12: '12打',
    fight24: '24打',
    norecord: '没有记录',
    createselfeatword: '你想继续吗, 创建自己吃红字 ?',
    BypassWord: '封字',
    State8: '打勾8边将会和MKT 同赔率，同意吗?',
    Enter1box: '只能输入1个，即(S)ID、(#)ID和投注号码 !',
    acceptDBF: '接受 .DBF file',
    nofile: '没有文件',
    onezeroone: '101 转 D#',
    fileempty: '文件不能为空',
    copy: '复制',
    plsinsert: '请插入 (#)ID',
    plsinsertN: '请插入号码',
    Allow101orders: '允许 101 进单',
  },
  Malay: {
    daysOfWeek: [
      'Ahad',
      'Isnin',
      'Sel',
      'Rabu',
      'Khamis',
      'Jum',
      'Sabtu',
    ],
    JEstimated: 'Jackpot Estimated Amount',
    CheckPackage: 'Lihat Pakej',
    NoGameThatDay: 'Tarikh yang anda pilih belum ada pemainan lagi',
    UserRedLowerThanCommission: 'Error ⛔: Nombor Merah Lebih Rendah Daripada Komisen',
    CommissionNegative: 'Error ⛔: Komisen nagative, contact upperline',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'fourth',
    fifth: 'fifth',
    sixth: 'sixth',
    special: 'Special',
    consolation: 'consolation',
    EmptySelected: 'Error ⛔: Empty Selected !!',
    america: 'Amerika',
    damacai: 'Damacai',
    Jackpot: 'Jackpot',
    GdToto: 'Grand Dragon Lotto',
    sabah: 'Sabah88',
    sandakan: 'Sandakan',
    singapore: 'Singapore',
    sarawak: 'Sarawak Cashsweep',
    CannotRestore: 'Error ⛔: please contact Admin to restore ticket.',
    toto: 'SportsToto',
    MultiNumberSearch: 'Multi Number Search',
    magnum: 'Magnum',
    SuccessClearTime: 'Successfull ClearTime',
    self: 'Self',
    SecondReport: 'Second Report',
    FirstReport: 'First Report',
    EmptyFinalarray: 'rror ⛔: No Edit Detectd!!',
    successExportList: 'Success Export Jackpot File.',
    ViewSGDHistory: 'View SGD Rate History',
    CloseSGDHistory: 'Close SGD Rate History',
    NoUpdateDetected: 'Error ⛔: No Update Detected',
    SuccessUpdateRate: 'Successful Updated SGD Rate.',
    SuccessUpdate: 'Successful Updated SGD or Thai Rate.',
    SelectedUsername: 'Selected User : ',
    SelectedUsernameno: 'Selected User',
    FourDResult: '4D hasil',
    ClaseTime: 'Closing Time',
    CloseTimePlatform1: 'Magnum (M) Damacai (K) Toto(T) Sarawak(W) Sabah(B) Sandakan(D)',
    CloseTimePlatform2: 'Singapore(S)',
    GrandDragon: 'Grand Dragon(8)',
    Simple: 'Simple',
    Limit: 'Had',
    CurrentSgdRate: 'Current SGD Rate :',
    CurrentThaiRate: 'Current THAI Rate :',
    PleaseSelectedDate: 'Download fighting date',
    TodaySalesReport: 'Today Sales Report',
    SgdRate: 'SGD Rate',
    ThaiRate: 'THAI Rate',
    OverLimitHome: 'OrderLimit',
    AlreadyCancalled: 'Error ⛔: Tiket Ini Telah Dibatalkan',
    Over7Home: 'AbleOrder',
    FreeJackpotList: 'Percuma 6D senarai',
    UserExportExcel: 'User Export Excel',
    DateTime: 'Tarikh/Masa',
    Time: 'Masa',
    OverNumberLimit: 'Error ⛔:Over Number Limit',
    Mode: 'Mod',
    SuccessOpenAccount: 'Berjaya unlocked Account',
    PleaseSelectedPackage: 'Error ⛔: Please Select 1 Package',
    SendSMSGroup: 'Send SMS Group',
    GenerateExcel: 'Generate Excel',
    HideZero: 'Hide 0',
    SendMessage: 'Send Message',
    ConfirmSendMessage: 'Confirm Send Message to',
    ToPhone: 'Phones ?',
    Message: 'Message',
    Phone: 'Phone',
    RedNumber: 'SPR',
    SalesReport: 'Sales Report',
    SalesTypeReport: 'Sales Report Details',
    eatSalesReport: 'Sales Report Fighting',
    OrderNotYetFinish: 'Error ⛔: Order not finished yet, please wait for awhile',
    Red: 'SPR',
    ErrorMessageAllAccountData: 'Error ⛔: Please Enter all the Account Details',
    Fighting: 'Fighting',
    DisplayMode: 'Display',
    Number: 'Nombor',
    NoResult: 'Tiada Recods',
    Report: 'lapor',
    ReportforHeader: 'laporan',
    SubmitNews: 'Create News',
    setting: 'Tetapan',
    HR: 'Personel',
    IBox: 'IBox',
    Home: 'Home',
    NoRedNumberFound: 'Error ⛔:  Tiada ada Data',
    RedNumberList: 'Senarai Nombor Merah',
    ErrorDueToCancelled: 'Error ⛔: Kerana Ticket Telah Digantungkan.',
    NoPackageFound: 'Tiada ada Package',
    CurrentexistPackage: 'Pakej Sedia Ada',
    SearchRedNumber: 'Cari Nombor Merah',
    MultiplePackageMessage: 'Sila pilih untuk mengaktifkan atau membatalkan pakej yang dipilih',
    SelectedActive: 'Aktifkan Pakej Pilih',
    SelectedCancel: 'batakan pakej pilih',
    NotAllowChinese: 'Error ⛔: perkataan cina dikesan', 
    ErrorEmptyRate: 'Error ⛔: Empty Current SGD Rate.',
    Search: 'Cari',
    Action: 'Tindakan',
    ForwardOn: 'Forward On', 
    ForwardType: 'Forward Type',
    Actionby: 'Tindakan',
    Balance: 'Balance',
    Date: 'Tarikh',
    LastBetDate: 'Tarikh Pertaruhan Terakhir',
    DadingHistory: 'Dading Recod',
    Edit: 'Mengedit',
    Delete: 'Padam',
    MultiDelete: 'Multi Delete',
    Loading: 'Memuatkan... Sila Tunggu Sebentar',
    Logout: 'Log Keluar',
    AddRedNumber: 'Tambah Nombor Merah',
    Platform: 'Pelantar',
    Ban: 'Larangan',
    DeleteallUsers: 'Confirm Delete Users',
    DeleteFast: 'Delete Users',
    LimitGame: 'Had Permainan',
    Games: 'Game',
    LimitPersonal: 'Had Peribadi',
    AccountInactive: 'Account Inactive',
    ExceedDAilyLimit: '⛔ Exceed Personal Daily Limit',
    ExceedEwPackage: '⛔ Exceed Personal EwPackage',
    ExceedPackageAmount : '⛔ Exceed Package Amount',
    RepeatOrder: 'Error ⛔: Double Ticket',
    System: 'Sistem',
    Virtual: 'Virtual',
    UserNobleSGD: 'Error ⛔: User No Able SGD',
    NotEnoughWallet: 'Error ⛔: Tak Cukup cash wallet',
    LeftCashWallet: 'Balance Left',
    CannotCancelpastticket: 'Error ⛔: Tidak dapat memadam, Ticket Sudah Tamat',
    Default: 'Lalai',
    ApplicableTo: 'Terpakai Untuk',
    RedNumberDetail: 'Perincian Nombor Merah',
    Layer: 'Lapisan',
    ShowAll: 'Show All',
    All: 'All',
    Add: 'Tambah',
    DeleteallLongTermButton: 'Padam Semua Long Term',
    BetAllLongterm: 'order Semua Long Term Tiket',
    Reset: 'Tetapkan Semula',
    Key1Duplicated: 'Error ⛔: Key1 Duplicated Detected', 
    Key2Duplicated: 'Error ⛔: Key2 Duplicated Detected',
    Close: 'Tutup',
    Simplicity: 'Kesederhanaan',
    BackToUpline: 'kembali kepada upline',
    LimitTo: 'Hadkan Kepada',
    AllUser: 'Semua Pengguna',
    NumberOnly: '⛔ Sila masukkan nombor, watak tidak diterima',
    AllLongTermBeenOrder: 'Semua Long Term Tiket telah order', 
    AllLongTermDelete: 'Semua Long Term Tiket telah padam',
    Option: 'pilihan ',
    DeletedLine: '⛔ tidak dapat memadamkan baris ini',
    ConfirmDeletedLine: 'Adakah anda pasti mahu memadamkan baris ini ?',
    EditAuthority: 'sunting Pihak Berkuasa',
    HintAuthority: '*Petua: Jika anda tidak pasti, sila jangan tanda.*',
    ClearAllData: 'Adakah anda pasti mahu membersihkan data ?',
    FourDigits: '⛔ Hanya Berfungsi Pada 2D, 3D dan 4D',
    FDigits: 'Error ⛔: 4D tiada pertaruhan dibenarkan di sini',
    FiveDigits: 'Error ⛔:  5D tiada pertaruhan dibenarkan di sini',
    SixDigits: 'Error ⛔:  6D tiada pertaruhan dibenarkan di sini',
    ThreeDigits: 'Error ⛔:  3D tiada pertaruhan dibenarkan di sini',
    NoBlank: 'Sila Masukkan Butiran, Tidak Boleh Kosong',
    DuplicateRedNumber: 'Error ⛔: Nombor pendua atau pengguna',
    DuplicatedUSer: 'Error ⛔: Pengguna pendua',
    MKTGameShutDown: 'MKTSWBD ShutDown',
    Invalidword: 'Error ⛔: Selected Receipt invalid Word.',
    DrawDate: 'Draw Date',
    BettDate: 'Bet Date',
    InvalidformatofmultiNumber: 'Error ⛔: Invalid Format of MultiNumber *(Must be number)*',
    UserID: 'ID Pengguna',
    AccountSales: 'Account Sales',
    BakcToSalesReport: 'Balik Account Sales',
    AddedSuccess: 'Nomboor Merah Berjaya Ditambahkan',
    DatabaseError: '⛔ Kesalahan Pangkalan Data',
    LwMoney: 'Kurang Duit',
    GotDownlineCantDelete: 'Gagal Padam ⛔: pengguna yang dipilih ada Downline',
    UpdateSuccess: 'Kemas Kini Berjaya',
    DeleteuserFaillastBet: 'Gagal Padam ⛔: pengguna yand dipilih ada Bet Dalam 30 hari',
    DeleteuserFaillastreport: 'Gagal Padam ⛔: pengguna yang dipilih ada report Dalam 30 hari',
    DeletedAlert: 'Adakah anda pasti, anda mahu memadamkan sub akaun ini !',
    PleaseSelectDate: 'Error ⛔: Tiada Tarikh Dipilih',
    PleaseSelectGame: 'Error ⛔: Tiada game Dipilih',
    AccountAmount: 'Buka Acct Amount',
    PlatFormError: 'Error ⛔: Platform Error',
    Success: 'Success!!',
    pleaseinputyourgames: 'Error ⛔: Sila masukkan nombor betting',
    SuccessDeleted: 'nombor terpadam yang berjaya: ',
    minimum4Word: 'Error ⛔: Pertaruhan Nombor mestilah minimum 2 digit',
    minimum4Digit: 'Error ⛔: Nombor mestilah minimum 2 digit',
    ErrorMin2Digit: 'Error ⛔: Nombor mestilah minimum 2 digit',
    DifferenceLength: 'Error ⛔: Nombors length mestilah sama dengan nombor length yang pertama',
    minimum4Digit2: 'Error ⛔: Nombor mestilah minimum 2 digit',
    SomeKeyValueNotKeyin: 'Error ⛔: Sila masukkan semua jumlah pertaruhan anda',
    KeyinAmountError: 'Error ⛔: Sila masukkan jumlah pertaruhan anda',
    pleaseinputallyourgames: 'Error ⛔: Sila masukkan semua nombor pertaruhan',
    SuccessOrderBet: 'Taruhan Kejayaan',
    NoDataFound: 'Error ⛔: Tidak dapat mencari dokumen yang berkaitan',
    Nomorefront: 'Error ⛔: this is the latest bet order, no more bet order in front',
    LastOrder: 'Error ⛔: no more bet order behind',
    singaporeGameClose: 'game singapura dekat',
    AllGameClose: 'Semua Game Dekat',
    databaseError: 'Error ⛔: database Error',
    CancelSuccessful: 'Ticket berjaya dibatalkan',
    EmptyServerID: 'Error ⛔: Sila Pilih Tiket',
    ExceededHoursNormal: 'Error ⛔: melepasi Had Masa, tidak dapan menguna function ini selepas jam 7',
    ExceededHours: 'Error ⛔: melepasi Had Masa, resit tidak dapat dibatalkan selepas jam 7',
    AuthoExceededHours: 'Error ⛔: Anda tidak mempunyai kebenaran untuk menarik balik tiket selepas jam 7 malam',
    AuthoExceededHoursRebuy: 'Error ⛔: Anda tidak mempunyai kebenaran untuk Rebuy tiket selepas jam 7 malam',
    ExceededDate: 'Failed to Withdraw: Tarikh pertaruhan Telah tidak sah',
    ExceedDate: 'Error ⛔: Tarikh pertaruhan Telah tidak sah',
    ExceedDateExist: 'Error ⛔: ada beberapa peraturan telah tidak sah di ticket ini',
    CancelExceededDate: 'Failed to Cancel: Tarikh pertaruhan Telah tidak sah',
    Addgame: 'Tambah Permainan',
    DeletedGame: 'Adakah Anda Pasti Mahu Memadamkan Permainan Ini',
    ActiveGame: 'Adakah Anda Pasti Mahu diactivekan Permainan Ini',
    RestoreGame: 'Adakah anda pasti, anda mahu memulihkan permainan ini', 
    DeletedNews: 'Adakah anda pasti mahu memadamkan maklumat',
    NoPackage: 'Error ⛔: Tiada Ada Package, tidak menerima pertaruhan',
    RecallSuccessful: 'Berjaya: Berjaya menarik balik Ticket',
    Downline: 'Downline',
    PlatformError: 'Error ⛔:Tiada platform dipilih',
    BetEmptyError: 'Error(T.T) : Terdapat masalah dengan nombor pertaruhan',
    WrongFormat: 'Error Format  ⛔: StartBet tidak boleh lebih besar daripada nombor pertaruhan EndBet.',
    Line: 'Line : ',
    BEmpty: 'Error ⛔: Tiada ada Bet.',
    FormatError: 'Error ⛔: nombor pertaruhan Ralat Format',
    FiveDFormatError: 'Error ⛔: 5D nombor pertaruhan Ralat Format',
    SixDFormatError: 'Error ⛔: 6D nombor pertaruhan Ralat Format',
    ThreeFormatError: 'Error ⛔: 3D nombor pertaruhan Ralat Format',
    FourFormatError: 'Error ⛔: 4D nombor pertaruhan Ralat Format',
    TwoFormatError: 'Error ⛔: 2D nombor pertaruhan Ralat Format',
    SuccessCopied: 'Berjaya menyalin Ticket',
    CopySuccess: 'Berjaya menyalin SPR',
    CreateVIP: 'Buat Pengguna VIP',
    EditVIP: 'Edit Pengguna VIP',
    SearchUser: 'Cari Pengguna',
    AddVIP: 'Tambah VIP',
    UserDetail: 'Butiran Pengguna',
    FormDateError: '⛔ fromDate lebih besar daripada toDate',
    WaterOddsExceedError: '⛔ Odds/Waters tidak boleh melebihi had!!',
    DisplayOrder: 'Display Order',
    CancelOrRevertOrder: 'Cancel Or Revert Order',
    EditOrder: 'Edit Order',
    RebuyOrder: 'Rebuy Order',
    AcctDisplay: 'AcctDisplay',
    EditUser: 'Edit User',
    CreateUser: 'Create User',
    DisplayablePackage: 'Displayable Package',
    EditBanNumber: 'Edit BanNumber',
    SalesControl: 'Kawalan Jualan',
    PackageSetting: 'Package Setting',
    PackageInput: 'Package Input',
    PhoneSetting: 'Phone Setting',
    EditGame: 'Edit Game',
    CancelOrRevertTimeoutOrder: 'Cancel Or Revert Timeout Order',
    EditTimeoutOrder: 'Edit Timeout Order',
    RebuyTimeoutOrder: 'Rebuy Timeout Order',
    PinOrOutWord: 'Pin Or OutWord',
    SystemSetting: 'System Setting',
    SetOpenDate: 'Set Open Date',
    AdminAbleEditAfterSeven: 'Admin Able Edit AfterSeven',
    SetGameResult: 'Set Game Result',
    CompileReport: 'Compile Report',
    admin: 'Admin',
    invalidUsername: '⛔ Nama pengguna tidak sah',
    DontHaveAutho: '⛔ Anda tidak mempunyai kebenaran untuk fungsi ini',
    PackSettingAutho: '⛔ Anda tidak mempunyai kebenaran untuk Package Setting',
    CancelSuccessfulldisplay: 'berjaya membatalkan',
    UserNotAbleRed: '⛔ Anda tidak mempunyai kebenaran untuk pertaruhan Red Ticket',
    donotacceptModein5D: '⛔ 5D tidak menyokong pemilihan kategori dan IBox',
    donotacceptModein6D: '⛔ 6D tidak menyokong pemilihan kategori dan IBox',
    ClickQandSpaceBar: '*Tekan Q untuk mengosongkan, tekan SpaceBar untuk Confirm Tiket*',
    PhoneNumber: 'telefon: ',
    Commission: 'Commission',
    Win: 'Menang',
    Receipt: 'tiket',
    nombor: 'Nombor',
    ServerID: 'ServerID',
    Bet: 'pertaruhan',
    usernameLanguage: 'Nama Pengguna',
    Name: 'Nama',
    Come: 'mari',
    NewCome: 'Mari Baru',
    ReportLai: 'mari',
    secondPeriod: '2 Tempoh',
    ThreePeriod: '3 Tempoh',
    BetSuccess: 'Bet Success !',
    UnableThoseDate: '⛔ Tidak dapat membeli tarikh tersebut',
    UnableBetThisPlat: 'Error ⛔: Terdapat permainan yang anda tidak dibenarkan untuk bertaruh',
    DownlineEat: 'Downline Eat',
    ReportEat: 'Downline Eat',
    selfBet: 'Pertaruhan Diri',
    ReportSelfBet: 'Pertaruhan Diri',
    selfWater: 'Sendiri Keuntungan',
    ReportSelfWater: 'Sendiri Keuntungan',
    selfWin: 'Menang Diri',
    ReportSelfWin: 'Menang Diri',
    downlineBet: 'Taruhan Downline',
    ReportDownlineBet: 'Taruhan Downline',
    downlineWin: 'Menang Downline',
    ReportDownlineWin: 'Menang Downline',
    totalBet: 'Jumlah Taruhan',
    ReportTotalBet: 'Jumlah Taruhan',
    TotalWater: 'jumlah Keuntungan',
    ReportTotalWater: 'jumlah Keuntungan',
    totalWin: 'Jumlah Menang',
    ReportTotalWin: 'Jumlah Menang',
    totalProfit: 'Jumlah Keuntungan',
    ReportTotalProfit: 'Jumlah Keuntungan',
    fastRegister: 'Personel(Cepat)',
    totalNet: 'Jumlah Net',
    Share: 'Kongsi',
    ReportShares: 'Kongsi',
    shares: 'Kongsi',
    SharesWater: 'Kongsi Komisen',
    ReportSharesWater: 'Kongsi Komisen',
    Water: 'Komisen',
    sharesWin: 'Kongsi Menang',
    SharesWin: 'Kongsi Menang',
    ReportSharesWin: 'Kongsi Menang',
    ShareProfit: 'Kongsi Keuntungan',
    ReportSharesProfit: 'Kongsi Keuntungan',
    Giveupline: 'Bagi Upline',
    ReportUpline: 'Bagi Upline',
    uplineWater: 'Komisen Upline',
    ReportUplineWater: 'Komisen Upline',
    uplineWin: 'Upline Menang',
    ReportUplineWin: 'Upline Menang',
    uplineProfit: 'Keuntungan Upline',
    ReportUplineProfit: 'Keuntungan Upline',
    WaterRate: 'Keuntungan jaring',
    ReportWaterProfit: 'Keuntungan jaring',
    Update: 'mengemas kini',
    EditGameLimit: 'Tukar had pertaruhan Default permainan',
    Active: 'Aktif',
    Deactive: 'Deaktif',
    Total: 'jumlah',
    NoDadingData: 'Error ⛔: kosong Dading',
    SearchAccount: 'Akaun Carian',
    USerLoginAcc: 'Akaun pengguna',
    Password: 'kata laluan',
    Role: 'Role',
    DadingConvert: 'Dading Convert',
    SetGameLimit: 'Set Game Limit',
    ActivePackage: 'Pakej Aktif',
    ChangeDate: 'tarikh dikemaskini',
    MD: 'M/D',
    Status: 'Status',
    Probation: 'Percubaan',
    PersonalSalesLimit: 'Personal sales limit/period',
    autodownload: 'autodownload',
    CashWallet: 'Dompet Tunai',
    ClickTodownload: 'Klik Sini untuk memuat turun APK Version >>',
    ManageAdminAuthority: 'Urus kebenaran',
    GameResult: 'Keputusan Tetap',
    Download: 'Download',
    DownloadDading: 'Download Dading',
    AllRedNumbers: 'Semua SPR',
    Numbers: 'Number',
    NOGAME: 'TIADA PERMAINAN',
    CreateGame: 'Cipta Permainan',
    SuccessDeleteUser: 'Berjaya Padam Pengguna Account',
    Dading: 'Dading',
    AllDading: 'All',
    ConfirmDeletedading: 'Adakah anda pasti mahu memadamkan Dading ini ?',
    ConfirmDownloaddading: 'Adakah anda pasti mahu Download Dading ini ?',
    DeleteUSerConfirmation: 'Adakah anda pasti mahu memadamkan akaun ini ?', 
    DetailedReport: 'Laporan Terperinci',
    EasyReport: 'Laporan Mudah',
    Confirm : 'Sahkan', 
    ConfirmAccount: 'Confirm Account',
    AccountDoesnotMatch: 'Error ⛔: Username Doesnt not match', 
    ConfirmDeleteUsers: 'Confirm delete Acct',
    DateCantbeBet: 'Error ⛔: Tidak boleh bertaruh pada tarikh ini',
    EmptyBet: 'Error ⛔: Pertaruhan Kosong Dikesan',
    Warning: '⛔ Amaran',
    Only4DWithAmount: 'Only 4D With Amount',
    Multiply: 'Darab',
    Divide: 'Bahagi',
    THAI: 'THAI',
    ExportReport: 'Export Excel',
    Managepackage: 'Menguruskan Pakej',
    EditReceipt: 'menukar Tikets',
    SuccessEditReceipt: 'Berjaya menukar Tikets',
    EditAll: 'Tukar semua',
    PressEnterAddNewLine: 'Tekan Enter untuk menambah baris baharu',
    BetSimple: 'Bet(Versi ringkas)',
    BetBoxVersion: 'Bet(Versi Kotak)',
    ChooseDate: 'Sila pilih tarikh',
    Special: 'Special',
    BetNumber: 'Bet Nombor',
    ClearAll: 'kosongkan',
    AddTo: 'Tambah',
    Auto: 'AUTO',
    ConfirmTicket: 'Bet',
    ConfirmRegister: 'Create Account',
    CurrentFormat: 'Format Semasa',
    Betting: 'Bet',
    Type: 'Jenis',
    WithdrawTicket: 'Withdraw Ticket',
    CancelTicket: 'Batalkan Tiket',
    CopyReceipt: 'Salinan Tiket',
    Rebuy: 'Beli semula',
    EditAndRebuy: 'Beli semula selepas Edit',
    BrowseTicket: 'Browse resit',
    BrowseLongTermTicket: 'Browse Jangka-panjang',
    LongTermTicket: 'Jangka-panjang',
    Account: 'Akaun',
    ConfirmDate: 'sahkan tarikh',
    OnlyBTandTOTOallow: 'Error ⛔: hanya TOTO dan BT Boleh bet 5D dan 6D',
    NormalDan: 'Order Biasa',
    Normal: 'Biasa',
    SGD:'SGD',
    RedDan: 'Order Merah',
    AllDan: 'Semua',
    CreateAdmin: 'Create Admin Account',
    ViewDownlineReport: 'Downline Laporan',
    Avaiable: 'Tersedia',
    NotAvailable: 'Tidak Tersedia',
    BeforeEdit: 'Sebelum Edit',
    AfterEdit: 'Selepas Edit',
    AllowCheckResult: 'Benarkan Pengguna menyemak result',
    Welcome: 'selamat datang',
    ManageCreateAcc: 'mengurus/buat akaun',
    ReceiptManage: 'mengurus Receipt/Ticket',
    ReceiptReport: 'Report',
    CanBet: 'benarkan untuk bertaruh, ',
    threeDifferenceLayout: '3 beza Layout',
    SimpleVer: 'Simple Ver',
    FastVer: 'Fast Ver',
    BoxVer: 'Box Ver',
    EmptyFindNumber: 'Error ⛔: Sila masukkan nombor untuk pertanyaan.',
    kuapingtai: 'Merentas platform',
    SuccessfullyCreatedActingUser: 'Berjaya Mencipta ActingUser',
    PleaseInsertUsername: '⛔ Sila Masukkan Nama Pengguna', 
    Passwordcannotempty: '⛔ Kata Laluan Tidak Boleh Kosong',
    PasswordWrong: '⛔ password Salah', 
    Usernamecannotempty: '⛔ Nama Pengguna Tidak Boleh Kosong',
    ActingAccount: 'Akaun Pemangkuan',
    DeleteSuccessful: 'Padam Berjaya',
    NoActingAccount: 'Tiada Akaun Pemangkuan',
    FindNumber: 'Cari Nombor',
    PlsClickInquire: '⛔ Sila Klik Tanya',
    PlsInsertPassword: '⛔ Sila Masukkan Kata Laluan',
    PlsChooseUserRole: '⛔ Sila Pilih Peranan Pengguna',
    PlsinsertName: '⛔ Sila Masukkan Nama',
    PlsInsertFormat: '⛔ Sila Masukkan Format',
    PlsChooseSituation: '⛔ Sila Pilih Situasi',
    PlsChooseProbation: '⛔ Sila Pilih Percubaan Pengguna',
    PlsInsertDailyLimit: '⛔ Sila Masukkan Had Harian',
    DailyLimit: 'had harian',
    PlsChoosemultiplydivide: '⛔ Sila Pilih Bahagi Darab',
    PlsChooseUpperline: '⛔ Sila Pilih Garis Atas',
    InvalidKey: '⛔ Kunci Tidak Sah',
    SuccessfulCreateUser: 'Pengguna Berjaya Dicipta',
    SuccessRebuyReceipt: 'berjaya beli Tiket semula ',
    InvalidDownlineIDorUsername: 'ID Downline Atau Nama Pengguna Tidak Sah',
    PleaseInsertUsernameOrID: 'Sila Masukkan Nama Pengguna atau ID',
    PleaseCheckAgain: 'Sila Semak Semula',
    Upperline: 'Garis Atas',
    Custom: 'Dibuat Khas',
    SignIn: 'Log Masuk',
    Identity: 'Identiti',
    InputMethod: 'Kaedah Input',
    Situation: 'Situasi',
    Personalsaleslimit: 'Had Jualan Peribadi',
    Multiplydivide: 'Darab/Bahagi',
    PrinterModel: 'Model Pencetak',
    PrinterAddress: 'Alamat Pencetak',
    ExtraLimit: 'Had Tambahan',
    InvalidUser: 'Pengguna Tidak Sah',
    Shutdown: 'Menutup',
    ShutdownSingapore: 'Tutup Singapura',
    SuccessfulUpdateConfigurationShutdown: 'Berjaya Penutupan Konfigurasi Kemas Kini',
    Number4digit: '⛔ Nombor Mestilah 4 Digit', 
    Number7digit: '⛔ Nombor Mestilah 7 Digit', 
    Number6digit: '⛔ Nombor Mestilah 6 Digit', 
    Number5digit: '⛔ Nombor Mestilah 5 Digit',
    Positionmustbe1character: '⛔ Kedudukan Mestilah 1 Aksara',
    Null: 'Null',
    GameDisplay: 'Paparan Permainan',
    Gamecreatedsuccessful: 'Permainan Yang Dicipta Berjaya',
    Unsuccess: 'Tidak Berjaya',
    GameUpdateSuccessful: 'Kemas Kini Permainan Berjaya',
    GameStatusHadBeenUpdated: 'Status Permainan Telah Dikemas Kini',
    GameID: 'GameID',
    Details: 'Butiran',
    PakageDetails: 'filter',
    zongtu: 'Details',
    FollowingGameSettingSameAsDefault:'Tetapan Permainan Berikut Adalah Sama Dengan Tetapan Default',
    NotAllowOnFormular: '⛔ IBox Cannot be use on Formula',
    NoData: 'Tiada Data',
    Back: 'Balik',
    AgentReport: 'Laporan Agen',
    SelfProfit: 'Self Profit',
    WINLOST: 'MENANG/KALAH',
    Prize: 'Hadiah',
    EditSubAccount: 'Edit Sub Akaun',
    Permission: 'Kebenaran',
    CreateSubAccount: 'Buat Sub Akaun',
    BackUpperline: 'Back Upline',
    CreateAcc: 'Buat Akaun Baharu',
    LatestOdd: 'Ganjil Terkini',
    Odd: 'Ganjil',
    Inquire: 'Menyiasat',
    AddPackage: 'Tambah Pakej',
    CopyPackage: 'Copy Pakej',
    Package: 'Pakej',
    NoUserFound: 'No Users Found',
    Money: 'Wang',
    RedMoney: 'Wang Merah',
    AllBet: 'Semua Bet',
    HideBet: 'sembunyikan Bet',
    AllKeys: 'Semua Key',
    HideKeys: 'sembunyikan Key',
    EditedBetHistory: 'Sejarah disunting',
    SearchAllPackage: 'Cari Semua Pakej',
    SearchPackageName: 'Cari Nama Pakej',
    CantViewAccount: '⛔ tidak dapat melihat akaun kerana anda tidak mempunyai Kuasa untuk fungsi ini',
    LoadingPleaseWaitAMoment: 'Loading... Sila Tunggu sebentar',
    Areyousureyouwanttoremovethisbet: 'Adakah anda pasti mahu mengeluarkan pertaruhan ini',
    Areyousuretorepurchasethisorder: 'Adakah anda pasti akan membeli semula pesanan ini ?',
    OrderID: 'ID Pesanan',
    OriginalText: 'Teks Asal:',
    Successfulrepurchasereceipt: 'Berjaya Membeli Semula Receipt',
    ErrorPlatform: 'Error ⛔: Masalah dengan permainan',
    FileName: 'Nama Fail',
    BoxVersion: 'Versi Kotak',
    RestoreSuccess: 'memulihkan dengan jayanya',
    ShowLongTermOrder: 'Long-Term Orders',
    Document: 'Bet(Versi Dokumen)',
    UploadCSV: 'Bet(CSV)',
    SimpleReport: 'Laporan Mudah',
    FileType: 'FileType',
    AccSalesList: 'Senarai Akaun Jualan',
    Sale: 'Sale',
    Manage: 'Mengurus',
    Comm: 'Comm',
    Nett: 'Nett',
    ChangeLanguage: 'Tukar Bahasa',
    TranslateLanguageCN: '华语',
    TranslateLanguageEN: 'English',
    TranslateLanguageBM: 'Bahasa Melayu',
    Language: 'Bahasa',
    HumanResourceManagement: 'Pengurusan member',
    Proceed: 'Teruskan',
    RedNumberManagement: 'Nombor Merah Mengurus',
    ProceedToSubAccount: 'Teruskan Ke Sub-Akaun',
    SuccessDeletedVIP: 'Berjaya Padam',
    Onlyaddoneplusorminus: 'Hanya Boleh Menambah Atau Menolak Satu',
    PackageNameEmpty: 'Nama Pakej Kosong',
    Submit: 'Serahkan',
    AccountHasBeenLocked: 'Akaun Telah Dikunci, Sila Cari Pentadbir untuk Membuka Kunci',
    InvalidAcc: '⛔ Akaun Tidak Sah',
    InvalidPassword: '⛔ Kata Laluan Tidak Sah',
    Cannotemptytext: '⛔ Tidak Boleh Mengosongkan Teks',
    Cannotemptynumber: '⛔ Tidak Boleh Kosongkan Nombor',
    WinNet: 'Win Net',
    ReportWinProfit: 'Win Net',
    WinProfit: 'Win Profit',
    Displayall: 'Paparkan Semua',
    must4digit: '⛔ mesti 4 digit,',
    EditChanged: 'Edit Changed',
    SeacrhPackage: 'Cari Pakej',
    GameHavenStart: 'Permainan belum bermula.',
    RedWhite: 'Semua',
    White: 'Normal',
    Open: 'Buka',
    SuccessfullyCreated: 'Berjaya Dicipta',
    CannotRecalculate: '⛔ Akaun anda tidak boleh mengira semula gred',
    ShutDownDeactive: 'Tidak Aktif',
    RedReceipt: 'Resit Merah', 
    UserNoAbleTHAI: '⛔ User Do not able to bet Thai',
    SGDThaiWrongSetting: '⛔ SGD Thai Wrong Setting',
    Tong: 'Tong',
    NonAdminXcancel: '⛔ Bukan admin tidak boleh membatalkan',
    GameClosePackage: 'Permainan ditutup dan pakej tidak boleh dikemas kini',
    Company: 'Syarikat',
    UpperlineNotSamePackage: 'Upline bukan pakej yang sama',
    FastVersion: 'Versi Ekspres',
    BettingFastVersion: 'Bet(Versi Ekspres)',
    BettingBoxVersion: 'Bet(Versi Kotak)',
    BettingRed: 'Bet(Nombor Merah)',
    BettingSgd: 'Bet(SGD)',
    BettingThai: 'Bet(THAI)',
    VeryBig: 'Besar',
    VerySmall: 'Kecil',
    GameSetting: 'Seting Permainan',
    DownlinegotPackage: 'Downline Mendapat Pakej',
    Packagegotorders: 'Pakej Mendapat Pesanan',
    ActiveMultiplePackage: 'Adakah anda pasti, anda ingin mengaktifkan pakej ini !! ',
    FirstTimeChangePassword: 'Log Masuk Pertama Mesti Tukar Kata Laluan Pengguna.',
    CurrentPassword: 'Kata Laluan Terkini',
    CurrentPasswordNotMatch: '⛔ Kata Laluan Terkini tidak betul',
    ChangePassword: 'Tukar Kata Laluan',
    CurrentPasswordEmpty: '⛔ Tidak Boleh Kosongkan dalam Kata Laluan Terkini',
    NewPassword: 'Kata Laluan Baharu',
    NewPasswordEmpty: '⛔ Tidak Boleh Kosongkan dalam Kata Laluan Baharu',
    AbleOrderAfterSeven: 'AbleOrderAfterSeven',
    IgnoreOrderLimit: 'IgnoreOrderLimit',
    RepeatPassword: 'Ulang Kata Laluan Baharu',
    Notenoughcashwallet: 'Tunai tidak cukup',
    RepeatPasswordEmpty: 'T⛔ idak Boleh Kosongkan dalam Ulang Kata Laluan Baharu',
    PasswordChangedSuccessfully: 'Kata Laluan Berjaya Ditukar',
    NRPasswordDifferent: '⛔ Kata Laluan Baharu Dan Ulang Kata Laluan Baharu Tidak Padan !!',
    LLC: 'LLC',
    FDG: 'FDG',
    FailDailyLimit: '⛔ Gagal, Had Harian Lebih Besar Daripada Had Harian Upline',
    PackageHigher: '⛔ Pakej ini lebih tinggi daripada pakej dalam talian',
    Create: 'Cipta',
    DownlineNotSamePackage: 'Downline bukan pakej yang sama',
    SuccessActivePackage: 'Pakej Terpilih Diaktifkan Berjaya',
    Unabletoupdate: 'Tidak dapat mengemas kini',
    MataLock: 'Mata Lock',
    // self: 'Sendiri',
    downline: 'Downline Dan Sendiri',
    Singular: 'Paparan Tunggal',
    NoGamenine: '⛔ Gagal: Tidak ada kebenaran Platform 9',
    Hide: 'Sembunyi',
    Show: 'Tunjuk',
    News: 'BERITA',
    CreateNews: 'Cipta Berita',
    Information: 'Maklumat',
    InfoUpdated: 'Maklumat Dikemaskini',
    NewInfoAdded: 'Maklumat Baharu Ditambah',
    NewsManage: 'Pengurusan Mesej',
    Link: 'Link',
    SetupSuccess: 'Persediaan Berjaya',
    DemoCannotBet: 'Demo tak boleh bet long term',
    SGDCannotBet: 'SGD cannot bet long term',
    ThaiCannotBet: 'Thai cannot bet long term',
    UserAction: 'Rekod Pengguna',
    Page: 'Page',
    Table: 'Jadual',
    FromData: 'Sebelum Aksi',
    ToData: 'Selepas Aksi',
    SubAcc: 'Sub Akaun',
    BiggerThan: 'lebih daripada',
    ZhonglaiCalculate: 'Lai Calculate Report',
    LimitEveryone: 'hadkan semua orang',
    LimitBSA: 'hadkan BSA',
    VIPLIMIT: 'hadkan VIP',
    UserDuplicated: 'Error ⛔: Duplicate Username',
    CannotOverPercen: 'Tidak Boleh Lebih 100%',
    CannotOverPercen2: 'Tidak Boleh Lebih 20%',
    OrderTime: 'Masa Order',
    ShowReal: 'Jelas',
    Percent: 'Peratus',
    DownlinePercent: 'Downline Peratus',
    Amount: 'Jumlah',
    TotalAmount: 'Jumlah',
    VIP: 'VIP',
    Code: 'Kod',
    SMSReport: 'Laporan SMS',
    phoneNumber: 'Nombor Telefon',
    Username: 'Name Pengguna',
    AdminSetup: 'Persediaan Admin',
    StarAndAll: '**IBox',
    SmalltoBig: 'Small to Big',
    bigtoSmall: 'Big to Small',
    FollowUserName: 'Follow Username',
    FollowName: 'Follow Name',
    Setup: 'Setup',
    Demo: 'Demo',
    AdminOnly: 'Admin Saja',
    GameBetting: 'Aturan Permainan',
    AboutAccount: 'Akaun',
    Find: 'Cari',
    Password6digit: 'Kata laluan mesti mempunyai 4 digit',
    FightingFormat : 'Format Pertempuran',
    AddCommissionOneSide : 'Tambah Komisen Satu Sebelah',
    AddCommissionSingle : 'Tambah Komisen Single',
    FightingSetting: 'Tetapan Pergaduhan',
    ActiveP: 'Aktifkan Pakej',
    Odds: 'Odds',
    AutomaticallyAddWater : 'Tambah Komisen Automatik',
    HolyWater: 'HolyWater',
    From: 'From',
    to: 'to',
    CancelPackage: 'Batalkan Pakej',
    DisplayError: 'Format Tolak',
    EditPackage: 'Edit Pakej',
    History: 'Rekod',
    UnableSetupHigherUpline: 'Tidak dapat menyediakan kerana jumlahnya lebih tinggi daripada upline',
    Duplicatepackagename: 'Nama Pakej Salinan',
    packageName: 'Package Name',
    PackageID: 'Package ID',
    CheckAll: 'Semak Semua',
    Day1: 'Isnin',
    Day2: 'Selasa',
    Day3: 'Rabu',
    Day4: 'Khamis',
    Day5: 'Jumaat',
    Day6: 'Sabtu',
    Day0: 'Ahad',
    UnableActiveDueToPendig: 'Error ⛔: Tidak dapat aktif kerana pesanan belum selesai',
    CannotAcceptSymbol: `⛔ Simbol khas ( " ' ) tidak boleh diterima`,
    AboutBetting: 'Permainan',
    Unableupdatecashwallet: 'Tidak Cukup Had',
    CannotMoreThan6: 'Tidak boleh melebihi 6 digit',
    CannotMoreThan5: 'Tidak boleh melebihi 5 digit',
    CannotMoreThan4: 'Tidak boleh melebihi 4 digit',
    CannotMoreThan3: 'Tidak boleh melebihi 3 digit',
    CannotMoreThan2: 'Tidak boleh melebihi 2 digit',
    CannotMoreThan1: 'Tidak boleh melebihi 1 digit',
    CannotbeEmpty: 'Error ⛔: Tidak boleh kosong',
    InsertMorethan1: 'Error ⛔: Sila masukkan lebih daripada 1 Nombor',
    WrongCapcha: 'Wrong Capcha',
    SenNotAdd: 'Sen Tidak Tambah',
    Ezmode: 'Mudah',
    waternotmore2: 'Tidak boleh lebih 2 digit',
    complexmode: 'Susah',
    Chai: 'Chai',
    BuChai: 'BuChai',
    Below20: '20 dibawah boleh pilih. ',
    DuplicateNumber: 'Nombor Pendua ',
    PrintAddress: 'Alamat Cetak',
    less12digit: 'Tidak boleh kurang daripada 12 digit',
    Selffightrednumber: 'Nombor Merah TAK Lawan Diri',
    Selffightrednumber2: 'Isikan atau semak perkataan yang anda tidak mahu makan. Perkataan yang anda tidak mahu makan akan dikira dari masa yang ditetapkan.',
    fight4: 'Gaduh 4',
    fight6: 'Gaduh 6',
    fight12: 'Gaduh 12',
    fight24: 'Gaduh 24',
    norecord: 'Tiada Catatan',
    createselfeatword: 'Cipta nombor merah TAK lawan diri, adakah anda mahu meneruskan?',
    BypassWord: 'Bypass Word',
    State8: 'Menyemak 8 bahagian akan memberi anda peluang yang sama seperti MKT, adakah anda bersetuju?',
    Enter1box: 'Hanya satu yang boleh dimasukkan iaitu (S)ID, (#)ID dan nombor pertaruhan !',
    acceptDBF: 'Diterima .DBF file',
    nofile: 'Tiada Fail',
    onezeroone: '101 转 D#',
    fileempty: 'Fail Kosong',
    copy: 'Salinan',
    plsinsert: 'Sila Masukkan (#)ID',
    plsinsertN: 'Sila Masukkan Nombor',
    Allow101orders: 'Benarkan 101 Pesanan',
  },
}

exports.language = language
