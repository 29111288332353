import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { isMobile } from 'react-device-detect';
// import { Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { connect } from 'react-redux'
// import Receipt from '../Images/Receipt.png';
import Lotto9 from '../Images/Cards/9Lottologo.png';
import MagnumLogo from '../Images/Cards/magnumlogo.png';
import TotoLogo from '../Images/Cards/toto.png';
import DamacaiLogo from '../Images/Cards/damacaimobile.png';
import GDLogo from '../Images/Cards/GrandDragonLotto.png';
import Sg4Logo from '../Images/Cards/singaporePool.png';
import SarawakLogo from '../Images/Cards/sarawak.png';
import Sabah88Logo from '../Images/Cards/sabah88.png';
import SansakanLogo from '../Images/Cards/sandakan.png';
import Moment from 'moment-timezone';
import Table from 'react-bootstrap/Table';
import { language } from '../language';
import updown from '../Images/updown1.png';
import Select from 'react-select';
import { Form, Button } from 'react-bootstrap';
import { Platformcolor } from '../static/PlatformColor';
// API wordsReport, reportDetail, getSalesReport,
const { todaySalesReportFNC, secondReportUserListFNC } = require('../Api');
const utilizeScroll = () => {

  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  return { executeScroll, elRef };
};

class TodaySalesReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: this.props.userID,
      Username: this.props.username,
      TodayDate: moment(new Date()).format('DD/MM/YYYY (ddd)'),
      Min_Date: Moment(new Date()).toDate(),
      Max_Date: Moment(new Date()).toDate(),
      SelectedDate: Moment(new Date()).toDate(),
      SearchValue: '',
      TextResult: '',
      ChooseStatus: 300, 
      ScrollViewNumb: 6600,
      GameArray: {},
      UseridReport: '', 
      MinimumAmount: '-',
      MinimumType: '-',
      Loading: false,
      MinimumShowStatus: 'Hide',
      ObjectKeys: ['1','2','3','4','5','6','7','8','9'],
      PopScrollBackButton: false,
      BigB: null, 
      BigS: null,
      BigA1: null,
      BigA2: null,
      BigA3: null,
      BigBandS: null,
      SelectedMode: 'mySelf',
      SelectedDownline: '',
      optionsUsername: '',
      SelectedUser: '',
      LongTermSelected: true,
    }
    this.SelectedOptionStatus = this.SelectedOptionStatus.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if (UserAuthorities.GameBetting.includes('9')) {
      let ObjectKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
      // '9'
      this.setState({ ObjectKeys: ObjectKeys })
    }
    this.setState({ UserID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID } , () => {
      this.CallAPI()
    })
  }

  async CallAPI(){
    const UserId = this.state.UserID
    this.setState({ UseridReport: UserId}, async()=>{
      const SelectedDate = Moment(this.state.SelectedDate).format('YYYY-MM-DD')
      // const GameArray = await getAllSales(CallApiData, this.state.MinimumAmount, this.state.MinimumType, this.state.ChooseStatus, this.props.username, this.props.token)
      const GameArray = await todaySalesReportFNC(SelectedDate, this.state.SelectedMode, this.state.SelectedDownline, this.state.MinimumAmount, this.state.MinimumType, this.state.ChooseStatus, this.props.username, this.state.LongTermSelected ,this.props.token)
      console.log('GameArray', GameArray)
      if(GameArray.message === 'No Data'){
        this.setState({GameArray: JSON.parse(JSON.stringify( GameArray.message)), Loading: true})
      } else if (GameArray.error) {
        this.setState({GameArray: JSON.parse(JSON.stringify(GameArray.error)), Loading: true})
      } else {
        let AddBoolean = GameArray
        // console.log('AddBoolean => ', AddBoolean)
        this.state.ObjectKeys.forEach((item) =>{
          // GameArray[item].length !== 0
          // console.log('GameArray[item].length---', GameArray[item].length === 0)
          if (GameArray[item].length === 0 ){
            AddBoolean[item] = {...GameArray[item], ...{GameBoolean: false }}
          } else {
            AddBoolean[item] = {...GameArray[item], ...{GameBoolean: true }}
          }
        })
        this.setState({GameArray: JSON.parse(JSON.stringify(AddBoolean)), Loading: true})
      }
    })
  }

  toggleResult(platform) {
    let EditBoolean = this.state.GameArray
    EditBoolean[platform] = {...EditBoolean[platform], ...{GameBoolean: !(EditBoolean[platform].GameBoolean)}}
    this.setState({GameArray: JSON.parse(JSON.stringify( EditBoolean))})
  }

  textChange(value){
    if (/^[0-9-]+$/.test(value) || value === '') {
      this.setState({ MinimumAmount: JSON.parse(JSON.stringify(value))}, ()=> {
      })
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  onKeyEnter(event){
    if (event.key === 'Enter') {
      this.CallAPI();
    }
  }

  SelectedOptionStatus(event) {
    this.setState({ChooseStatus: event.target.value}, ()=>{
      this.CallAPI();
    });
  }

  async setSelectedDate(SelectedDate) {
    this.setState({ SelectedDate }, ()=>{
      this.CallAPI();
    })
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  BigToSmall(items) {
    let ReportDetailsFilter = this.state.GameArray
    console.log('ReportDetailsFilter', ReportDetailsFilter)
    const ArrayNumber = ['1','2','3','4','5','6','7','8', '9']
    const BooleanBigorSmall = (items === 'B + S') ? 'BigBandS' : `Big${items}`
    let AnswerBoolean = (this.state[`${BooleanBigorSmall}`] === null)  ? false : !this.state[`${BooleanBigorSmall}`]
    ArrayNumber.forEach((status)=>{
      let ReportDetails = this.state.GameArray[status]
 
      const FilterUnwantedBoolean = Object.keys(this.state.GameArray[status]).filter((item) =>  item !== 'GameBoolean')

      const Filters = []
      FilterUnwantedBoolean.forEach((item) => Filters.push(this.state.GameArray[status][item]))
      if(items === 'B + S') {
        if(AnswerBoolean === false) {
          Filters.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
        } else {
          Filters.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
        }
      } else {
        if(AnswerBoolean === false) {
          Filters.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
        } else {
          Filters.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
        }
      }
      for (let i = 0; i < Filters.length; i += 1) {
        ReportDetails[i] = Filters[i]
      }
  
      ReportDetailsFilter[status] = ReportDetails
    })
    this.setState({ GameArray: JSON.parse(JSON.stringify(ReportDetailsFilter)), [`${BooleanBigorSmall}`]: AnswerBoolean })
  }

  async CallDownlineUsers() {
    let tempObject = {}
    let tempArray = []
    const DownlineUsername = await secondReportUserListFNC(this.props.username, this.props.userID, this.props.token)
    DownlineUsername.forEach((item) => {
      tempObject = {
        ...tempObject,
        value: item.ID,
        label: item.Username,
      }
      tempArray.push(tempObject)
    })
    if(DownlineUsername) {
      this.setState({ SelectedDownline: tempArray[0].value || '', SelectedUser: tempArray[0], optionsUsername: tempArray })
    }
  }
  async SelectedDown(e) {
    this.setState({ SelectedDownline: e.value, SelectedUser: e})
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowY: 'auto' }}> 
        <Header2 />
        <div className="dcc mobileHeader"
          ref={this.elScroll.elRef}
          style={{
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft:  '3.5%',
            flexDirection: 'row',
            width: '95%'
          }}>
          <div style={{ justifyContent: 'center', display: 'flex' }} >
            <div style={{fontSize: "16px", width: 100, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Date} :</div>
            <DatePicker
              onChange = {(Date) => this.setSelectedDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              // maxDate={new Date()}
              // popperPlacement="top-right"
              selected={this.state.SelectedDate}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
            <Form.Check
              style={{ fontSize: 18, alignItems: 'center', paddingLeft: 10, width: 400 }}
              inline
              label={language[this.props.currentLanguage].LongTermTicket}
              type="checkbox"
              checked={this.state.LongTermSelected}
              onChange={() => this.setState({ LongTermSelected: !this.state.LongTermSelected })}
            />
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.props.username} </div>
          </div>
        </div>
        <div style={{ marginTop: 10,marginLeft:'3.5%', display: 'flex', flexDirection: 'row'}} >
          <div style={{ display: 'flex', width: '25%'}}> 
            <div style={{fontSize: "16px", width: '50%', fontWeight: 'bold'}}>{'Mode'} :</div>
            <div className={'dcc'} style={{ width: '100%'}}>
              <Form.Control
                as="select"
                // className="LoginInputText"
                type="text"
                value={this.state.SelectedMode}
                onChange={(e) => this.setState({ SelectedMode: e.target.value}, ()=>{
                  if (this.state.SelectedMode === 'SelectedDownline') {
                    this.CallDownlineUsers();
                  } else {
                    this.setState({ SelectedDownline: '' })
                  }
                })}
                placeholder={'Amount'}
                style={{ fontWeight: 'bold', width: '100%', marginRight: null, textAlign: 'center'}}
              >
                <option value="mySelf">自己</option>
                <option value="Downline">所有下线</option>
                <option value="SelectedDownline">选择下线</option>
              </Form.Control>
            </div>
          </div>
          {this.state.SelectedMode === 'SelectedDownline' &&
          <div style={{ display: 'flex', width: 'auto'}}> 
            <div style={{fontSize: "16px", width: '50%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Downline} :</div>
            <div style={{ width: 400 }}>
              {/* <Form.Control
                as="select"
                type="text"
                value={this.state.SelectedDownline}
                onChange={(e) => this.setState({ SelectedDownline: e.target.value}, ()=>{
                // this.CallAPI();
                })}
                style={{ fontWeight: 'bold', width:  (isMobile) ? '80%' : '100%', marginRight: (isMobile) ? 10 : null, textAlign: 'center'}}
              >
                <option value="Hide">{language[this.props.currentLanguage].Hide}</option>
                <option value="Show">{language[this.props.currentLanguage].Show}</option>
              </Form.Control> */}
              <Select
                options={this.state.optionsUsername}
                value={this.state.SelectedUser}
                onChange={(e) => this.SelectedDown(e)}
              >
              </Select>
            </div>
          </div> 
          }
          <Button className="button2" variant="primary" style={{ marginLeft: 5, fontWeight: 'bold', width: null , marginTop: 0}} onClick={() => this.CallAPI()} >{language[this.props.currentLanguage].Search}</Button>
        </div>
        <div style={{ marginTop: 10,marginLeft: '3.5%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
          <div style={{ display: 'flex', width:  '30%'}}> 
            <div style={{fontSize: "16px", width: 150, fontWeight: 'bold', marginLeft: ''}}>{'Minimum Filter'} :</div>
            <div className='dcc' style={{ width: '100%'}}>
              <Form.Control
                type="text"
                value={this.state.MinimumAmount}
                onChange={(e) => this.textChange( e.target.value)}
                onKeyPress={(e) => this.onKeyEnter(e)}
                placeholder={'Amount'}
                style={{ textAlign: 'center',fontWeight: 'bold', width: '50%', marginRight: null}}
              />
            </div>
          </div>
          <div style={{ display:'flex', width: '25%'}}> 
            <div style={{fontSize: "16px", width: '50%', fontWeight: 'bold'}}>{'Type'} :</div>
            <div className={'dcc'} style={{ width: '100%'}}>
              <Form.Control
                as="select"
                // className="LoginInputText"
                type="text"
                value={this.state.MinimumType}
                onChange={(e) => this.setState({ MinimumType: e.target.value}, ()=>{
                  this.CallAPI();
                })}
                placeholder={'Amount'}
                style={{ fontWeight: 'bold', width: '100%', marginRight: null, textAlign: 'center'}}
              >
                <option value="-">-</option>
                <option value="B + S">B + S</option>
                <option value="B">B</option>
                <option value="S">S</option>
                <option value="SA">SA</option>
                <option value="SB">SB</option>
                <option value="SC">SC</option>
              </Form.Control>
            </div>
          </div>
          <div style={{ display: 'flex', width: '25%'}}> 
            <div style={{fontSize: "16px", width: '50%', fontWeight: 'bold', minWidth: 100 }}>{language[this.props.currentLanguage].Singular} :</div>
            <div className={'dcc'} style={{ width: '100%'}}>
              <Form.Control
                as="select"
                type="text"
                value={this.state.MinimumShowStatus}
                onChange={(e) => this.setState({ MinimumShowStatus: e.target.value}, ()=>{
                  // this.CallAPI();
                })}
                style={{ fontWeight: 'bold', width: '100%', marginRight: null, textAlign: 'center'}}
              >
                <option value="Hide">{language[this.props.currentLanguage].Hide}</option>
                <option value="Show">{language[this.props.currentLanguage].Show}</option>
              </Form.Control>
            </div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 10,
            paddingBottom: 10,
            justifyContent: 'space-between',
            marginLeft: '4%',
            flexDirection: 'row',
          }}>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>显示 B,S,SA,SB,SC 销售报告 (净)</div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '4%',
            flexDirection: 'row',
          }}>
          <Form.Control as='select' className="form-control" value={this.state.ChooseStatus} onChange={this.SelectedOptionStatus} style={{ textAlign: 'center', marginRight: 20, width:  '10%', fontSize: '15px',}}>
            <option value={300}>{"300"}</option>
            <option value={500}>{"500"}</option>
            <option value={1000}>{"1000"}</option>
            <option value={10000}>{"10000"}</option>
          </Form.Control>
        </div>
        <div style={{ marginTop: 10,marginLeft:'1.5%', display: 'flex' }} >
          <div style={{fontSize: "16px", width: 150, fontWeight: 'bold'}}>{'Total'} :</div>
          <div className="TitleUsername">{Number(this.state.GameArray['TotalAmount'] || 0).toFixed(0)} </div>
        </div>
        {(this.state.Loading && this.state.GameArray !== 'No Data') &&<div className={`d-flex justify-content-center px-3`} style={{ marginBottom: 15}}>
          <div className="dcc row" style={{ justifyContent: 'center' }} align="left">
            { this.state.ObjectKeys.map((item, idd) =>{ 
              return <img key={idd} src={ (item === '1') ? MagnumLogo : (item === '2') ? DamacaiLogo : (item === '3') ? TotoLogo : (item === '4') ? Sg4Logo : (item === '5') ? SarawakLogo : (item === '6') ? Sabah88Logo : (item === '7') ? SansakanLogo : (item === '9') ? Lotto9 : GDLogo } 
                alt="Logo" className={`mainPageToggleLogo ${this.state.GameArray[item].GameBoolean && 'activeResult button2'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult(item)} />
            })}
          </div>
        </div>}
        {(this.state.Loading && this.state.GameArray !== 'No Data') && <Table responsive="sm" style={{ backgroundColor: 'white' }}>
          <div className="column" style={{ justifyContent: 'center', display: 'flex' }} >
            {  this.state.ObjectKeys.map((item, idxx) =>{
              return (this.state.GameArray[item].GameBoolean) && <div key={idxx} style={{ paddingBottom: 40, marginRight: 20 }}>
                <Table>
                  <thead>
                    <tr style={{
                      fontWeight: 'bold',
                      backgroundColor: Platformcolor(item, ''),
                      color: (item === '1') ? 'black' : (item === '7') ? 'black' : (item === '4') ? 'black' : (item === '9') ? 'black' : 'white'}} >
                      <th colSpan={3} ><div  style= {{ fontSize: '15px'}}>{(item === '1') ? `${language[this.props.currentLanguage].magnum}` : 
                        (item === '2') ? `${language[this.props.currentLanguage].damacai}` : 
                          (item === '3') ? `${language[this.props.currentLanguage].toto}`: 
                            (item === '4') ? `${language[this.props.currentLanguage].singapore}`:
                              (item === '5') ? `${language[this.props.currentLanguage].sarawak}`:
                                (item === '6') ? `${language[this.props.currentLanguage].sabah}`: 
                                  (item === '7') ? `${language[this.props.currentLanguage].sandakan}`: 
                                    (item === '9') ? `Lotto 9` :
                                      `${language[this.props.currentLanguage].GrandDragon}`}</div></th>
                      <th colSpan={(this.state.MinimumShowStatus === 'Show') ? 5 : 4}>
                        <div style= {{ fontSize: '15px'}}>
                          {Number(this.state.GameArray['Amount' + item] || 0).toFixed(0)}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  
                  <thead>
                    <tr>
                      <th style={{ paddingRight: 5, paddingLeft: 5 }}><div>Number</div></th>
                      <th style={{ paddingRight: 5, paddingLeft: 5 }}>
                        <div style={{ width: (Object.keys(this.state.GameArray[item]).length > 1) ? 'auto' : 80, minWidth: 80,display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} > 
                        B + S
                          <div style={{ cursor: 'pointer'}} onClick={() => this.BigToSmall('B + S')}>
                            { (this.state.BigBandS === null) ?
                              <img src={updown} alt="img"
                                className='button2'
                                style={{
                                  height: 15,
                                  width: 15,
                                }}
                              /> : (this.state.BigBandS === false) ? 
                                '▲'
                                : '▼'
                            }
                          </div>
                        </div>
                      </th>
                      <th style={{ backgroundColor: '#fff23e', paddingRight: 5, paddingLeft: 5 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        B
                          <div style={{ cursor: 'pointer'}} onClick={() => this.BigToSmall('B')}>
                            { (this.state.BigB === null) ?
                              <img src={updown} alt="img"
                                className='button2'
                                style={{
                                  height: 15,
                                  width: 15,
                                }}
                              /> : (this.state.BigB === false) ? 
                                '▲'
                                : '▼'
                            }
                          </div>
                        </div>
                      </th>
                      <th style={{ paddingRight: 5, paddingLeft: 5 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        S
                          <div style={{ cursor: 'pointer'}} onClick={() => this.BigToSmall('S')}>
                            { (this.state.BigS === null) ?
                              <img src={updown} alt="img"
                                className='button2'
                                style={{
                                  height: 15,
                                  width: 15,
                                }}
                              /> : (this.state.BigS === false) ? 
                                '▲'
                                : '▼'
                            }
                          </div>
                        </div>
                      </th>
                      <th style={{ backgroundColor: '#ff431b', paddingRight: 5, paddingLeft: 5 }}>
                        <div style={{ color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        SA
                          <div style={{ cursor: 'pointer'}} onClick={() => this.BigToSmall('SA')}>
                            { (this.state.BigA1 === null) ?
                              <img src={updown} alt="img"
                                className='button2'
                                style={{
                                  height: 15,
                                  width: 15,
                                }}
                              /> : (this.state.BigA1 === false) ? 
                                <div style={{ color: 'black'}}>▲</div>
                                : <div style={{ color: 'black'}}>▼</div>
                            }
                          </div>
                        </div>
                      </th>
                      <th style={{ backgroundColor: '#32c6f4', paddingRight: 5, paddingLeft: 5  }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        SB
                          <div style={{ cursor: 'pointer'}} onClick={() => this.BigToSmall('SB')}>
                            { (this.state.BigA2 === null) ?
                              <img src={updown} alt="img"
                                className='button2'
                                style={{
                                  height: 15,
                                  width: 15,
                                }}
                              /> : (this.state.BigA2 === false) ? 
                                '▲'
                                : '▼'
                            }
                          </div>
                        </div>
                      </th>
                      <th style={{ backgroundColor: '#008000', paddingRight: 5, paddingLeft: 5  }}>
                        <div style={{ color: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        SC
                          <div style={{ cursor: 'pointer'}} onClick={() => this.BigToSmall('SC')}>
                            { (this.state.BigA3 === null) ?
                              <img src={updown} alt="img"
                                className='button2'
                                style={{
                                  height: 15,
                                  width: 15,
                                }}
                              /> : (this.state.BigA3 === false) ? 
                                <div style={{ color: 'black'}}>▲</div>
                                : <div style={{ color: 'black'}}>▼</div>
                            }
                          </div>
                        </div>
                      </th>
                      {(this.state.MinimumShowStatus === 'Show')&&<th style={{  paddingRight: 5, paddingLeft: 5, minWidth: 100 }}><div>{language[this.props.currentLanguage].Singular}</div></th>}
                    </tr>
                  </thead>
                  <tbody>
                    { Object.keys(this.state.GameArray[item]).map((NumberItem, idn) =>{
                      return <tr key={idn}>
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') && <th style={{ backgroundColor: '#ebf4fc', color: 'black', paddingRight: 5, paddingLeft: 5}} >{this.state.GameArray[item][NumberItem]['Word'] }</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['B + S']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ backgroundColor: '#ebf4fc', paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['B']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['S']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ backgroundColor: '#ebf4fc', paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SA']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SB']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ backgroundColor: '#ebf4fc', paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SC']).toFixed(0)}</th>}
                        {(this.state.MinimumShowStatus === 'Show')&& (NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['TotalOrderCount']).toFixed(0)}</th>}
                      </tr>
                    })}
                  </tbody>
                </Table>
              </div>
            })
            }
          </div>
        </Table>}
        {(this.state.GameArray.message === 'No Data') && <div className= "dcc">
          <div className="TitleUsername" style={{ width: '30%'}}>
            {language[this.props.currentLanguage].GameHavenStart}
          </div>
        </div>}
        {(this.state.PopScrollBackButton)&&<div className={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
          ^
          </div>
        </div>}
      </div >
    );
  }
}
// export default Page2;

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    UserAuthority: state.UserAuthority,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TodaySalesReport);