import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
// import { isMobile } from 'react-device-detect';
// ConsoleView
import Alert from 'react-bootstrap/Alert'
import { language } from '../language';
import { Form, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import { connect } from 'react-redux'
import {ReturnBetValidation} from '../static/ReturnBetValidation';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loading3 from '../Images/loading-3.gif';
import Loading from '../Images/loading.gif';
import EditBetReceiptComponent from '../Component/EditBetReceipt'
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
// import Select from 'react-select';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LineShareButton,
  LineIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import {ReturnCancelValidation} from '../static/ReturnCancelValidation';
const { CancelBet, getAutoReceipt, CreateBetOrder, getMyselfF, searchDownlineHR } = require('../Api');
// import { language } from '../language';

var timer

class LongTermReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: this.props.userID,
      UserID: this.props.userID,
      username: this.props.username,
      token: this.props.token,
      Role: this.props.Role,
      TodayDate: moment(new Date()).format('DD/MM/YYYY (ddd)'),
      MinDate: moment(new Date()).format('YYYY-MM-DD'),
      MaxDate: moment(new Date()).format('YYYY-MM-DD'),
      SelectedDate: moment(localStorage.getItem('SELECTED_DATE')).toDate(),
      SearchValue: '',
      TextResult: '',
      showShare: false,
      BetReceipt: [],
      OriginalTextResult: '',
      UserOrderID: '',
      OrderID: '',
      SearchID: '',
      ServerID: '',
      ID: '',
      getUsers: [],
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Status: 'Close',
      ServerIDForCancel: '',
      RecallBack : false,
      lineData: [
        {
          originalWord: '',
          translatedWord: '',
          Mode: '',
        }
      ],
      loading: false,
      BettingDate: [],
      Receipt: '',
      ReceiptDisplay: '',
      DateValidated: true,
      CheckStatus: '',
      Red: '',
      Singapore: '',
      Thailand: '',
      optionsUsername: '',
      SelectedUser: '',
      loadingforBet: false,
      searchState: '',
      ShowEditPage: false,
      is_visible: false,
      DisabledCancel: false,
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: '',
    }
    this.GetByServerID = this.GetByServerID.bind(this);
    this.SelectedUser = this.SelectedUser.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
  }

  // async setSelectedDate(date, mode) {
  //   this.setState({
  //     [mode]: date, ShowEditPage: false,
  //   }, ()=> {
  //     this.props.setDate(this.state[mode])
  //     this.GetBetReceipt();
  //   });
  // }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ UserID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID }, () => {
      if ((UserAuthorities && UserAuthorities.Bet) === 0) {
        window.location.href='/Home'; 
      } else {
        this.getDownline();
        this.SetUsersAuthorities();
      }
    })
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  async getDownline() {
    const UserID = this.state.UserID;
    const Username = this.state.username;
    const token = this.state.token
    // const DownlineUsername = await getMyDownline(UserID, token, Username)
    const getMyself = await getMyselfF(UserID, token, Username)
    if(getMyself) {
      this.setState({ userID: getMyself[0].ID, SelectedUser: {value: getMyself[0].ID, label: getMyself[0].Username}, SearchUser: '' }, ()=> {
        this.FirstStartRun();
      })
    }
  }

  async GetBetReceipt() {
    const username = this.state.username
    const UserID = this.state.userID
    const token = this.state.token
    // const SelectedDate = moment(this.state.SelectedDate).format('YYYY-MM-DD')

    const BetReceipt = await getAutoReceipt(
      UserID,
      // this.state.MinDate,
      // SelectedDate,
      '', 
      '',
      token, 
      username,
    )
    if(BetReceipt.error === 'No Data') {
      this.openNotification('NoDataFound')
      this.setState({ notificationType: 'error' });
      this.setState({
        BetReceipt: [], OriginalTextResult: '', TextResult: '', UserOrderID: '', BettingDate: [], SelectedExample: '', ReceiptDisplay: '',
      })
    } else if(BetReceipt.error === 'DontHaveAutho') {
      this.openNotification('DontHaveAutho')
      this.setState({ notificationType: 'error' });
      this.setState({
        BetReceipt: [], OriginalTextResult: '', TextResult: '', UserOrderID: '', BettingDate: [], ReceiptDisplay: '', SelectedExample: '',
      })
    } else {
      const SortBetReceipt = BetReceipt.sort((a, b) => (a.ID > b.ID) ? -1 : 1)
      this.setState({ BetReceipt: SortBetReceipt, SelectedExample: '' }, ()=> {
        this.DisplayLatestReceipt();
      })
    }
  }

  async FirstStartRun() {
    const BetReceipt = await getAutoReceipt(
      this.state.userID,
      // this.state.MinDate,
      // moment(this.state.SelectedDate).format('YYYY-MM-DD'),
      '', 
      '',
      this.state.token, 
      this.state.username,
    )
    if(BetReceipt.error === 'No Data') {
      this.setState({
        BetReceipt: [], OriginalTextResult: '', TextResult: '', UserOrderID: '', BettingDate: [], SelectedExample: '', ReceiptDisplay: '',
      })
    } else if(BetReceipt.error === 'DontHaveAutho') {
      this.openNotification('DontHaveAutho')
      this.setState({ notificationType: 'error' });
      this.setState({
        BetReceipt: [], OriginalTextResult: '', TextResult: '', UserOrderID: '', BettingDate: [], ReceiptDisplay: '', SelectedExample: '',
      })
    } else {
      const SortBetReceipt = BetReceipt.sort((a, b) => (a.ID > b.ID) ? -1 : 1)
      this.setState({ BetReceipt: SortBetReceipt, SelectedExample: '' }, ()=> {
        this.DisplayLatestReceipt();
      })
    }
  }

  async DisplayLatestReceipt() {
    const BetReceipt = this.state.BetReceipt
    if(BetReceipt.length - 1 >= 0) {
      this.GetByServerID(BetReceipt[0].LatestOrderNo);
    }
  }

  async CancelBetAPI() {
    this.setState({ loading: true })
    // const Hours = moment().hours();
    const ServerId = this.state.ServerIDForCancel

    if(ServerId === '') {
      this.openNotification('EmptyServerID')
      this.setState({ notificationType: 'error', loading: false });
    } else {
      this.CanCelAPI();
    }
  }

  async CanCelAPI() {
    const username = this.state.username
    const token = this.state.token
    const ServerId = this.state.ServerIDForCancel
    if (this.state.Authority.isSub === 1 && this.state.Authority.CancelOrRevertOrder === 0) {
      this.openNotification('DontHaveAutho')
      this.setState({ notificationType: 'error', loading: false, DisabledCancel: false });
    } else {
      const CancelReceipt = await CancelBet(ServerId, username, token)
      if(CancelReceipt.error) {
        this.openNotification(ReturnCancelValidation(CancelReceipt.error))
        this.setState({ notificationType: 'error', loading: false, DisabledCancel: false });
      } else {
        if(CancelReceipt.message === 'Cancel Successful') {
          this.openNotification('CancelSuccessful')
          this.setState({ notificationType: 'success', OriginalTextResult: '', TextResult: '', loading: false, DisabledCancel: false }, () => {
            timer = setTimeout(() => {
              this.SelectedUser(this.state.SelectedUser);
            }, 1500);
          });
        }
      }
    }
    // if(CancelReceipt.error === 'database error') {
    //   this.openNotification('databaseError')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else if (CancelReceipt.error === 'Exceed Date') {
    //   this.openNotification('CancelExceededDate')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else if(CancelReceipt.error === 'OverTimeLimit') {
    //   this.openNotification('ExceededHours')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else if(CancelReceipt.error === 'DontHaveAutho') {
    //   this.openNotification('DontHaveAutho')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else if(CancelReceipt.error === 'AlreadyCancalled') {
    //   this.openNotification('AlreadyCancalled')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else {
    //   if(CancelReceipt.message === 'Cancel Successful') {
    //     this.openNotification('CancelSuccessful')
    //     this.setState({ notificationType: 'success', OriginalTextResult: '', TextResult: '', loading: false }, () => {
    //       timer = setTimeout(() => {
    //         this.SelectedUser(this.state.SelectedUser);
    //       }, 1500);
    //       // this.GetByServerID(this.state.ServerIDForCancel);
    //     });
    //   }
    // }
  }

  async EditReceipt() {
    this.setState({ loading: true })
    const TimeOutAuthority = this.state.Authority.CancelOrRevertTimeoutOrder;

    if(TimeOutAuthority === 1) {
      this.RecallBackAction();
    } else {
      // if(moment().hours() >= 19) {
      //   this.openNotification('AuthoExceededHours')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else {
      this.RecallBackAction();
      // }
    }
    // const SerVerID = this.state.ServerIDForCancel

    // if(SerVerID === '') {
    //   this.openNotification('EmptyServerID')
    //   this.setState({ notificationType: 'error' });
    // } else {
    //   const RecallBet = await PostRecallBet(SerVerID, this.props.token, this.props.username)
    //   if(RecallBet.error === 'database error') {
    //     this.openNotification('databaseError')
    //     this.setState({ notificationType: 'error' });
    //   } else if (RecallBet.error === 'OverTimeLimit') {
    //     this.openNotification('ExceededHours')
    //     this.setState({ notificationType: 'error' });
    //   } else if (RecallBet.error === 'Exceed Date') {
    //     this.openNotification('ExceededDate')
    //     this.setState({ notificationType: 'error' });
    //   } else if(RecallBet.error === 'Invalid ServerID') {
    //     alert('Invalid ServerID')
    //   } else if (RecallBet.error === 'DontHaveAutho') {
    //     this.openNotification('DontHaveAutho')
    //     this.setState({ notificationType: 'error' });
    //   } else {
    //     if(RecallBet.message === 'Successful') {
    //       this.openNotification('RecallSuccessful')
    //       this.setState({ notificationType: 'success', OriginalTextResult: '', TextResult: '', ReceiptDisplay: '' }, () => {
    //         this.GetBetReceipt();
    //         this.GetByServerID(this.state.ServerIDForCancel);
    //       });
    //     }
    //   }
    // }
  }

  async RecallBackAction() {
    const Red = this.state.Red
    const Singapore = this.state.Singapore
    const Thailand = this.state.Thailand
    const SerVerID = this.state.ServerIDForCancel

    if(SerVerID === '') {
      this.openNotification('EmptyServerID')
      this.setState({ notificationType: 'error', loading: false });
    } else {
      const Recall = await CreateBetOrder(this.state.ID, this.state.OriginalTextResult, this.props.token, this.props.username, Red, '', Singapore, Thailand)
      if(Recall.error) {
        this.openNotification(ReturnBetValidation(Recall.error))
        this.setState({ notificationType: 'error', loading: false });
      } else {
        this.openNotification('RecallSuccessful')
        this.setState({ notificationType: 'success', OriginalTextResult: '', TextResult: '', ReceiptDisplay: '', RecallButton: false, loading: false }, () => {
          // clearTimeout(timer)
          timer = setTimeout(() => {
            this.GetBetReceipt();
          }, 500);
        });
      }
      // if (Recall.error === 'User No Package') {
      //   this.openNotification('NoPackage')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'No Date to Bet') {
      //   this.openNotification('DateCantbeBet')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === '5D6D in wrong game') {
      //   this.openNotification('OnlyBTandTOTOallow')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Not enough date') {
      //   this.openNotification('UnableThoseDate')
      //   this.setState({ notificationType: 'error', loading: false });
      // }  else if(Recall.error === 'Date error') {
      //   this.openNotification('UnableThoseDate')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'S Game ShutDown') {
      //   this.openNotification('singaporeGameClose')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'All Game ShutDown') {
      //   this.openNotification('AllGameClose')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'MKTSWBD ShutDown') {
      //   this.openNotification('MKTGameShutDown')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'No Game on that day') {
      //   this.openNotification('NoGameThatDay')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Account Inactive') {
      //   this.openNotification('AccountInactive')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Exceed Personal Daily Limit') {
      //   this.openNotification('ExceedDAilyLimit')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Repeat Order') {
      //   this.openNotification('RepeatOrder')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Over Number Limit') {
      //   this.openNotification('OverNumberLimit')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else if(Recall.error === 'Format Error') {
      //   this.openNotification('FormatError')
      //   this.setState({ notificationType: 'error', loading: false });
      // } else {
      //   this.openNotification('RecallSuccessful')
      //   this.setState({ notificationType: 'success', OriginalTextResult: '', TextResult: '', ReceiptDisplay: '', RecallButton: false, loading: false }, () => {
      //     // clearTimeout(timer)
      //     timer = setTimeout(() => {
      //       this.GetBetReceipt();
      //     }, 500);
      //   });
      // }
    }
  }

  async SelectedUsername(e) {
    this.setState({ userID: e.target.value, OriginalTextResult: '', TextResult: '', ReceiptDisplay: ''}, () => {
      this.GetBetReceipt();
    })
  }

  async SelectedOrder(e, BetReceipt) {
    const username = this.state.username
    const UserID = this.state.userID
    const token = this.state.token
    const Index = e.target.value;
    // const SelectedDate = moment(this.state.SelectedDate).format('YYYY-MM-DD');

    if(Index === '-') {
      this.setState({
        OriginalTextResult: '',
        TextResult: '',
        UserOrderID: '',
        ServerID: '',
        ServerIDForCancel: '',
        OrderID: '',
        BettingDate: [],
        ReceiptDisplay: '',
        Receipt: '',
        SelectedExample: '',
        Red: '',
        Singapore: '',
        Thailand: '',
        ShowEditPage: false,
      })
    } else {
      // const getServerID = BetReceipt[Index].ID

      // this.setState({
      //   OriginalTextResult: BetReceipt[Index].OriginalText,
      //   TextResult: (BetReceipt[Index].Status === 'Cancelled') ? BetReceipt[Index].AfterText : BetReceipt[Index].ReceiptText,
      //   OrderID: BetReceipt[Index].UserOrderID,
      //   ID: BetReceipt[Index].UserID,
      //   UserOrderID: '',
      //   ServerID: '',
      //   ServerIDForCancel: getServerID,
      //   RecallButton: (BetReceipt[Index].Status === 'Cancelled') ? true : false,
      //   BettingDate: BetReceipt[Index].BettingDate,
      // })
      const BetReceipt = await getAutoReceipt(
        UserID,
        // this.state.MinDate,
        // SelectedDate,
        '',
        Index,
        token, 
        username,
      )
      if(BetReceipt.error === 'No Data') {
        this.openNotification('NoDataFound')
        this.setState({ notificationType: 'error' });
        this.setState({
          OriginalTextResult: '', TextResult: '', ServerID: '', OrderID: '', loading: false, ReceiptDisplay: '', Receipt: '', SelectedExample: '', Red: '', ShowEditPage: false, Singapore: '', Thailand: '',
        })
      } else if(BetReceipt.error === 'DontHaveAutho') {
        this.openNotification('DontHaveAutho')
        this.setState({ notificationType: 'error', loading: false });
        this.setState({
          OriginalTextResult: '', TextResult: '', UserOrderID: '', OrderID: '', ReceiptDisplay: '', Receipt: '', SelectedExample: '',Red: '', ShowEditPage: false, Singapore: '', Thailand: '',
        })
      } else {
        this.setState({
          TextResult: BetReceipt.List.ReceiptText,
          OriginalTextResult: BetReceipt.List.OriginalText,
          UserOrderID: '', ServerIDForCancel: BetReceipt.List.ID,
          OrderID: BetReceipt.List.UserOrderID,
          ID: BetReceipt.List.UserID,
          Red: (BetReceipt.List.Red === 1) ? true : '',
          Singapore: (BetReceipt.List.SGD === 1) ? true : '',
          Thailand: (BetReceipt.List.THAI === 1) ? true : '',
          RecallButton: (BetReceipt.List.Status === 'Cancelled') ? true : false,
          BettingDate: BetReceipt.Date,
          CheckStatus: BetReceipt.List.Status,
          SelectedExample: Index,
          loading: false,
          Receipt: '',
          ReceiptDisplay: '',
          ShowEditPage: false,
        }, ()=> {
          this.ConvertResultRed(this.state.TextResult);
          this.SetDateValidation(this.state.BettingDate);
        })
      }
    }
  }

  SetDateValidation(BettingDate) {
    const TodayDate = moment(new Date()).format('YYYY-MM-DD')
    const tempArray = []
    const AfterFilter = BettingDate.filter(function(item, pos) {
      return BettingDate.indexOf(item) === pos;
    })
    AfterFilter.forEach((item) => {
      if(TodayDate > item) {
        tempArray.push('NotAllow')
      }
    })
    if(tempArray.includes('NotAllow')) {
      this.setState({ DateValidated: false })
    } else {
      this.setState({ DateValidated: true })
    }
  }

  async handleSearchText(e, field) {
    const value = (e.target.value).replace(/[^0-9]/g, '')
    this.setState({ [field]: value })
  }

  async SearchByOrderID(e, Searchtype) {
    const username = this.state.username
    const UserID = this.state.userID
    const token = this.state.token
    // const SelectedDate = moment(this.state.SelectedDate).format('YYYY-MM-DD');

    if(e.key === 'Enter') {
      this.setState({ loading: true })
      if (Searchtype === 'UserOrderID') {
        if(e.target.value === '') {
          this.openNotification('NoDataFound')
          this.setState({ notificationType: 'error', OriginalTextResult: '', TextResult: '', loading: false, BettingDate: [], Receipt: '', ReceiptDisplay: '', SelectedExample: '', ShowEditPage: false });
        } else {
          const BetReceipt = await getAutoReceipt(
            UserID,
            // this.state.MinDate,
            // SelectedDate,
            this.state.UserOrderID,
            '',
            token, 
            username,
          )
          if(BetReceipt.error === 'No Data') {
            this.openNotification('NoDataFound')
            this.setState({ notificationType: 'error' });
            this.setState({
              OriginalTextResult: '', TextResult: '', UserOrderID: '', OrderID: '', loading: false, Receipt: '', ReceiptDisplay: '', SelectedExample: '', Red: '', ShowEditPage: false, Singapore: '', Thailand: '',
            })
          } else if(BetReceipt.error === 'DontHaveAutho') {
            this.openNotification('DontHaveAutho')
            this.setState({ notificationType: 'error', loading: false });
            this.setState({
              OriginalTextResult: '', TextResult: '', UserOrderID: '', OrderID: '', Receipt: '', ReceiptDisplay: '', SelectedExample: '', Red: '', ShowEditPage: false, Singapore: '', Thailand: '',
            })
          } else {
            this.setState({
              TextResult: BetReceipt.List.ReceiptText,
              OriginalTextResult: BetReceipt.List.OriginalText, ServerID: '',
              ServerIDForCancel: BetReceipt.List.ID,
              SelectedExample: BetReceipt.List.ID,
              OrderID: BetReceipt.List.UserOrderID,
              ID: BetReceipt.List.UserID,
              Red: (BetReceipt.List.Red === 1) ? true : '',
              Singapore: (BetReceipt.List.SGD === 1) ? true : '',
              Thailand: (BetReceipt.List.THAI === 1) ? true : '',
              RecallButton: (BetReceipt.List.Status === 'Cancelled') ? true : false,
              BettingDate: BetReceipt.Date,
              CheckStatus: BetReceipt.List.Status,
              loading: false,
              Receipt: '',
              ReceiptDisplay: '',
              ShowEditPage: false,
            }, ()=> {
              this.ConvertResultRed(this.state.TextResult);
              this.SetDateValidation(this.state.BettingDate);
            })
          }
        }
      }
      if (Searchtype === 'ServerID') {
        if(e.target.value === '') {
          this.openNotification('NoDataFound')
          this.setState({ notificationType: 'error', OriginalTextResult: '', TextResult: '', loading: false, BettingDate: [], Receipt: '', ReceiptDisplay: '', ShowEditPage: false });
        } else {
          this.GetByServerID(this.state.ServerID);
          this.setState({ Receipt: '' })
          // const BetReceipt = await getAutoReceipt(
          //   UserID,
          //   this.state.MinDate,
          //   this.state.MaxDate,
          //   '',
          //   this.state.ServerID,
          //   token, 
          //   username,
          // )
          // if(BetReceipt.error === 'No Data') {
          //   this.openNotification('NoDataFound')
          //   this.setState({ notificationType: 'error' });
          //   this.setState({
          //     OriginalTextResult: '', TextResult: '', ServerID: '', OrderID: '', loading: false, Receipt: '',
          //   })
          // } else if(BetReceipt.error === 'DontHaveAutho') {
          //   this.openNotification('DontHaveAutho')
          //   this.setState({ notificationType: 'error', loading: false });
          //   this.setState({
          //     OriginalTextResult: '', TextResult: '', UserOrderID: '', OrderID: '', Receipt: '',
          //   })
          // } else {
          //   this.setState({
          //     TextResult: (BetReceipt.List.Status === 'Cancelled') ? BetReceipt.List.AfterText : BetReceipt.List.ReceiptText,
          //     OriginalTextResult: BetReceipt.List.OriginalText,
          //     UserOrderID: '', ServerIDForCancel: BetReceipt.List.ID,
          //     OrderID: BetReceipt.List.UserOrderID,
          //     ID: BetReceipt.List.UserID,
          //     RecallButton: (BetReceipt.List.Status === 'Cancelled') ? true : false,
          //     BettingDate: BetReceipt.Date,
          //     loading: false,
          //     Receipt: '',
          //   })
          // }
        }
      }
    }
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  async getReorderBet() {
    const ReOrderTimeOut = this.state.Authority.RebuyTimeoutOrder

    if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
      if(ReOrderTimeOut === 1) {
        this.EditReorderAutho();
      } else {
        // if(moment().hours() >= 19) {
        //   this.openNotification('AuthoExceededHoursRebuy')
        //   this.setState({ notificationType: 'error' });
        // } else {
        this.EditReorderAutho();
        // }
      }
    } else {
      // if(moment().hours() >= 19) {
      //   this.openNotification('ExceededHoursNormal')
      //   this.setState({ notificationType: 'error' });
      // } else {
      this.EditReorderAutho();
      // }
    }
  }

  async EditReorderAutho() {
    const SelectedUsers = this.state.SelectedUser
    const Singapore = this.state.Singapore
    const Thailand = this.state.Thailand
    const TextResult = this.state.OriginalTextResult
    if(TextResult === '') {
      this.openNotification('EmptyServerID')
      this.setState({ notificationType: 'error' });
    } else {
      let OriginalText = TextResult.split('\n');
      let LineData = this.state.lineData;
      let AmountCopy = '';
      for (let i = 0; i < OriginalText.length; i++) {
        if ((LineData[i] || '') !== '') {
          LineData[0].originalWord = OriginalText[i];
        } else {
          let OriWord = OriginalText[i]
          if (OriWord.startsWith('##')) {
            OriWord = OriWord.replace('##', '');
          } else if (OriWord.startsWith('#')) {
            OriWord = OriWord.replace('#', '');
          }
          const OriWordArray = OriWord.split('#');
          if (OriWordArray.length > 2 && OriWordArray[0] !== '') {
            AmountCopy = OriginalText[i].substring(
              OriginalText[i].indexOf('#') + 1,
            );
            OriWord = OriginalText[i];
          } else if (OriWordArray.length === 2 && OriWordArray[1] === '') {
            OriWord = OriginalText[i] + AmountCopy;
          } else {
            OriWord = OriginalText[i];
          }
          LineData.push({
            originalWord: OriWord,
            translatedWord: '',
            Mode: '',
          });
        }
      }
      if(this.state.Red !== '') {
        this.props.history.push({
          pathname: '/bettingRed',
          ReOrderBet: LineData,
          SelectedUser: SelectedUsers,
        })
      } else if(Singapore !== '') {
        this.props.history.push({
          pathname: '/BettingSgd',
          ReOrderBet: LineData,
          SelectedUser: SelectedUsers,
        })
      } else if(Thailand !== '') {
        this.props.history.push({
          pathname: '/BettingThai',
          ReOrderBet: LineData,
          SelectedUser: SelectedUsers,
        })
      } else {
        this.props.history.push({
          pathname: '/betting',
          ReOrderBet: LineData,
          SelectedUser: SelectedUsers,
        })
      }
    }
  }

  async ReorderTeicket() {
    const ReOrderTimeOut = this.state.Authority.RebuyTimeoutOrder

    if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
      if(ReOrderTimeOut === 1) {
        this.RebuyOrderAutho();
      } else {
        // if(moment().hours() >= 19) {
        //   this.openNotification('AuthoExceededHoursRebuy')
        //   this.setState({ notificationType: 'error' });
        // } else {
        this.RebuyOrderAutho();
        // }
      }
    } else {
      // if(moment().hours() >= 19) {
      //   this.openNotification('ExceededHoursNormal')
      //   this.setState({ notificationType: 'error' });
      // } else {
      this.RebuyOrderAutho();
      // }
    }
  }

  async RebuyOrderAutho() {
    const SelectedUsers = this.state.SelectedUser
    const Red = this.state.Red
    const Singapore = this.state.Singapore
    const Thailand = this.state.Thailand
    const OriginalTextResult = this.state.OriginalTextResult
    const userID = this.state.ID
    const BettingDate = this.state.BettingDate || ['']
    const AfterFilter = BettingDate.filter(function(item, pos) {
      return BettingDate.indexOf(item) === pos;
    })

    if(OriginalTextResult === '') {
      this.openNotification('EmptyServerID')
      this.setState({ notificationType: 'error' });
    } else if(AfterFilter.length > 1) {
      let OriginalText = OriginalTextResult.split('\n');
      let LineData = this.state.lineData;
      for (let i = 0; i < OriginalText.length; i++) {
        if ((LineData[i] || '') !== '') {
          LineData[i].originalWord = OriginalText[i];
        } else {
          LineData.push({
            originalWord: OriginalText[i],
            translatedWord: '',
            Mode: '',
          });
        }
      }
      if(this.state.Red !== '') {
        this.props.history.push({
          pathname: '/bettingRed',
          ReOrderBet: LineData,
          SelectedUser: SelectedUsers,
        })
      } else if (Singapore !== '') {
        this.props.history.push({
          pathname: '/BettingSgd',
          ReOrderBet: LineData,
          SelectedUser: SelectedUsers,
        })
      }  else if(Thailand !== '') {
        this.props.history.push({
          pathname: '/BettingThai',
          ReOrderBet: LineData,
          SelectedUser: SelectedUsers,
        })
      } else {
        this.props.history.push({
          pathname: '/betting',
          ReOrderBet: LineData,
          SelectedUser: SelectedUsers,
        })
      }
    } else {
      const r = (window.confirm('是否确定重买此单?'));
      if (r === true) {
        this.setState({ loadingforBet: true })
        const Rebuy = await CreateBetOrder(userID, OriginalTextResult, this.state.token, this.state.username, Red, true, Singapore, Thailand)
        if(Rebuy.error) {
          this.openNotification(ReturnBetValidation(Rebuy.error))
          this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
        } else {
          if(Rebuy) {
            this.openNotification('SuccessRebuyReceipt')
            this.setState({ Receipt: Rebuy.receipt, loadingforBet: false, notificationType: 'success' }, ()=> {
              this.ConvertResultRed(this.state.Receipt)
            })
            // clearTimeout(timer)
            timer = setTimeout(() => {
              this.setState({ Receipt: '' })
            }, 8000);
            timer = setTimeout(() => {
              this.GetBetReceipt();
            }, 500);
          }
        }
        // if (Rebuy.error === 'User No Package') {
        //   this.openNotification('NoPackage')
        //   this.setState({ notificationType: 'error', loadingforBet: false });
        // } else if(Rebuy.error === 'No Date to Bet') {
        //   this.openNotification('DateCantbeBet')
        //   this.setState({ notificationType: 'error', loadingforBet: false });
        // } else if(Rebuy.error === '5D6D in wrong game') {
        //   this.openNotification('OnlyBTandTOTOallow')
        //   this.setState({ notificationType: 'error', loadingforBet: false });
        // } else if(Rebuy.error === 'Not enough date') {
        //   this.openNotification('UnableThoseDate')
        //   this.setState({ notificationType: 'error', loadingforBet: false });
        // }  else if(Rebuy.error === 'Date error') {
        //   this.openNotification('UnableThoseDate')
        //   this.setState({ notificationType: 'error', loadingforBet: false });
        // } else if(Rebuy.error === 'S Game ShutDown') {
        //   this.openNotification('singaporeGameClose')
        //   this.setState({ notificationType: 'error', loadingforBet: false });
        // } else if(Rebuy.error === 'All Game ShutDown') {
        //   this.openNotification('AllGameClose')
        //   this.setState({ notificationType: 'error', loadingforBet: false });
        // } else if(Rebuy.error === 'MKTSWBD ShutDown') {
        //   this.openNotification('MKTGameShutDown')
        //   this.setState({ notificationType: 'error', loadingforBet: false });
        // } else if(Rebuy.error === 'No Game on that day') {
        //   this.openNotification('NoGameThatDay')
        //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
        // } else if(Rebuy.error === 'Account Inactive') {
        //   this.openNotification('AccountInactive')
        //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
        // } else if(Rebuy.error === 'Exceed Personal Daily Limit') {
        //   this.openNotification('ExceedDAilyLimit')
        //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
        // } else if(Rebuy.error === 'Repeat Order') {
        //   this.openNotification('RepeatOrder')
        //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
        // } else if(Rebuy.error === 'Over Number Limit') {
        //   this.openNotification('OverNumberLimit')
        //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
        // } else if(Rebuy.error === 'Format Error') {
        //   this.openNotification('FormatError')
        //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
        // } else {
        //   if(Rebuy) {
        //     this.openNotification('SuccessRebuyReceipt')
        //     this.setState({ Receipt: Rebuy.receipt, loadingforBet: false, notificationType: 'success' }, ()=> {
        //       this.ConvertResultRed(this.state.Receipt)
        //     })
        //     // clearTimeout(timer)
        //     timer = setTimeout(() => {
        //       this.setState({ Receipt: '' })
        //     }, 8000);
        //     timer = setTimeout(() => {
        //       this.GetBetReceipt();
        //     }, 500);
        //   }
        // }
      } else {
        return null;
      }
    }
  }

  async GetByServerID(ServerID) {
    const username = this.state.username
    const UserID = this.state.userID
    const token = this.state.token
    // const SelectedDate = moment(this.state.SelectedDate).format('YYYY-MM-DD');
    const BetReceipt = await getAutoReceipt(
      UserID,
      '',
      ServerID,
      token, 
      username,
    )
    if(BetReceipt.error === 'No Data') {
      this.openNotification('NoDataFound')
      this.setState({ notificationType: 'error' });
      this.setState({
        OriginalTextResult: '', TextResult: '', ServerID: '', OrderID: '', loading: false, Receipt: '', ReceiptDisplay: '', SelectedExample: '',Red: '', ShowEditPage: false, Singapore: '', Thailand: '',
      })
    } else if(BetReceipt.error === 'DontHaveAutho') {
      this.openNotification('DontHaveAutho')
      this.setState({ notificationType: 'error', loading: false });
      this.setState({
        OriginalTextResult: '', TextResult: '', UserOrderID: '', OrderID: '', Receipt: '', ReceiptDisplay: '',  SelectedExample: '', Red: '', ShowEditPage: false, Singapore: '', Thailand: '',
      })
    } else {
      this.setState({
        TextResult: BetReceipt.List.ReceiptText,
        OriginalTextResult: BetReceipt.List.OriginalText,
        UserOrderID: '', ServerIDForCancel: BetReceipt.List.ID,
        OrderID: BetReceipt.List.UserOrderID,
        SelectedExample: BetReceipt.List.ID,
        ID: BetReceipt.List.UserID,
        Red: (BetReceipt.List.Red === 1) ? true : '',
        Singapore: (BetReceipt.List.SGD === 1) ? true : '',
        Thailand: (BetReceipt.List.THAI === 1) ? true : '',
        RecallButton: (BetReceipt.List.Status === 'Cancelled') ? true : false,
        BettingDate: BetReceipt.Date,
        CheckStatus: BetReceipt.List.Status,
        loading: false,
        // ShowEditPage: false,
        // Receipt: '',
        // ReceiptDisplay: '',
      }, ()=> {
        this.ConvertResultRed(this.state.TextResult);
        this.SetDateValidation(this.state.BettingDate);
      })
    }
  }

  async ConvertResultRed(Receipt) {
    let Word = Receipt
    let returnWord = [];

    if (Word.includes('<red>')) {
      const WordArray = Word.split('<red>').join('</red>').split('</red>');
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{color: 'red'}}>
              {WordArray[i]}
            </span>,
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({ReceiptDisplay: returnWord});
    } else {
      this.setState({ReceiptDisplay: Word});
    }
  }

  ErrorMessage(Type) {
    if(Type === '1') {
      this.openNotification('DontHaveAutho')
      this.setState({ notificationType: 'error'});
    } else if(Type === '2'){
      this.openNotification('ExceedDate')
      this.setState({ notificationType: 'error'});
    } else if(Type === '4') {
      this.openNotification('ExceededHoursNormal')
      this.setState({ notificationType: 'error'});
    } else {
      this.openNotification('ErrorDueToCancelled')
      this.setState({ notificationType: 'error'});
    }
  }

  EditBetReceipt(ServerID, OrderID) {
    let TempObject = {}
    TempObject = {
      OrderID: OrderID,
      id: JSON.stringify(ServerID),
      type: 'OrderID',
    }
    if (this.state.ShowEditPage === false) {
      this.setState({ searchState: TempObject, ShowEditPage: true })
    } else {
      this.setState({ ShowEditPage: false, searchState: '' }, () => {
        this.setState({ searchState: TempObject, ShowEditPage: true })
      })
    }
  }

  CloseEditPage() {
    this.setState({ ShowEditPage: false, searchState: ''}, ()=> {
      this.GetBetReceipt();
    })
  }

  SelectedUser(e) {
    this.setState({ userID: e.value, OriginalTextResult: '', TextResult: '', ReceiptDisplay: '', SelectedUser: e, ServerIDForCancel: '', Red: '', ShowEditPage: false, Singapore: '', Thailand: '' }, () => {
      this.GetBetReceipt();
      this.handleCloseSearchModal();
    })
  }

  CopyToC(TextResult) {
    const DownloadWord = TextResult.split('<red>').join('</red>').split('</red>');
    navigator.clipboard.writeText(DownloadWord.join(''))
    this.openNotification('SuccessCopied')
    this.setState({ notificationType: true });
  }

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    const UserID = (this.state.Authority.isSub === 1) ? this.state.Authority.ActingAs : this.props.userID
    if (this.state.SearchUser !== '') {
      const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, UserID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification('InvalidDownlineIDorUsername')
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID : [],
    })
  }

  render() {
    const shareUrl = `Receipt\n\n${this.state.TextResult}`;
    return (
      <div onScroll={(e) => this.handleScroll(e)} className='StartBackground'>
        {/* <div style={{ width: '100%', backgroundColor: 'lightsteelblue', minHeight: 950, flexDirection: 'column' }}> */}
        <div id={'Top'}/>
        <Header2 />
        <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
        <div className={`dcc mobileHeader`}
          style={{
            justifyContent: 'space-between',
            marginLeft: '4%',
            flexDirection: 'row',
          }}>
          {/* <div className="dcc" style={{ fontWeight: 'bold', flexDirection: (isMobile) ? 'column' : 'row' }}>
            <div className="dcc row" style={{ marginRight: (isMobile) ? 0 : 10 }}>
              <div>{language[this.props.currentLanguage].Date} : </div>
              <div style={{ marginLeft: 10 }}>
                <DatePicker
                  onChange={(date) => this.setSelectedDate(date, 'SelectedDate')}
                  dateFormat='dd-MMM-yyyy'
                  placeholder="Date"
                  maxDate={new Date()}
                  // minDate={new Date(this.state.MinDate)}
                  selected={this.state.SelectedDate}
                  value={this.state.SelectedDate}
                  className={(isMobile) ? "form-control StyleDatePickerMobile" :"form-control StyleDatePicker"}
                  withPortal
                />
              </div>
            </div>
          </div> */}
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.state.username}</div>
          </div>
        </div>
        <div className="resultP1P2P3" style={{ fontWeight: 'bold', marginBottom: 10 }}>{`${language[this.props.currentLanguage].BrowseLongTermTicket} (${this.state.SelectedUser.label})`}</div>
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc" style={{ flexDirection: 'column' }}>
          <div className={`row`}  style={{ width: '100%'}}>
            <div style={{ width: '20%' }}></div>
            <div className="column" style={{ backgroundColor: 'white', width: '60%', borderRadius: 5, marginBottom: 10 }}>
              <div className="row SelecteduserContainer">
                <div className="dcc row" style={{ width: 'auto' }}>
                  <div className='MenubarTitle' style={{ fontWeight: 'bold', marginRight: 20, width: 'none'  }}>{language[this.props.currentLanguage].Account}</div>
                  {/* <div style={{ width: (isMobile) ? '60%' : 220 }}>
                    <Select
                      className='TextFontSize'
                      isSearchable={this.state.optionsUsername.length === 0 ? false : true}
                      options={this.state.optionsUsername}
                      value={this.state.SelectedUser}
                      onChange={(e) => this.SelectedUser(e)}
                    >
                    </Select>
                  </div> */}
                  <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
                    <Form.Control style={{ width: 150, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
                    <Button  className='button2' variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
                    <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getDownline()}>{'Self'}</Button>
                  </div>
                </div>
                <div className="dcc row" style={{ width: 'auto' }}>
                  <div className='MenubarTitle' style={{ fontWeight: 'bold', width: 'none', marginRight: 20 }}>{`${language[this.props.currentLanguage].Receipt}(${this.state.BetReceipt.length})`}</div>
                  <div style={{ width: '60%' }}>
                    <select className="form-control TextFontSize" value={this.state.SelectedExample}
                      style={{ textAlign: 'center', width: 220, fontWeight: 'bold' }}
                      onChange={(e) => this.SelectedOrder(e, this.state.BetReceipt)}>
                      <option value={'-'}>{(!this.state.BetReceipt.length ? '-' : `${language[this.props.currentLanguage].LongTermTicket}`)}</option>
                      {(this.state.BetReceipt || []).map((item, idx) => {
                        return (
                          <option index={idx} value={item.LatestOrderNo}>{`#${item.LatestUserNo}`}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="dcc row" style={{ width: '100%', justifyContent: 'space-evenly', marginBottom: 20 }}>
                <div className="ViewReceiptFont">{'(#)ID :'}</div>
                <Form.Control
                  value={this.state.UserOrderID}
                  className='ReceiptHistorySearch TextFontSize'
                  style={{ width: '75%' }}
                  type="text"
                  onChange={(e) => this.handleSearchText(e, 'UserOrderID')}
                  onKeyPress={(e) => this.SearchByOrderID(e, 'UserOrderID')}
                />
              </div>
              <div className="dcc row" style={{ width: '100%', justifyContent: 'space-evenly', marginBottom: 10 }}>
                <div className="ViewReceiptFont">{'(S)ID :'}</div>
                <Form.Control
                  value={this.state.ServerID}
                  className='ReceiptHistorySearch TextFontSize'
                  style={{  width: '75%' }}
                  type="text"
                  onChange={(e) => this.handleSearchText(e, 'ServerID')}
                  onKeyPress={(e) => this.SearchByOrderID(e, 'ServerID')}
                />
              </div>
              <div className="dcc" style={{ width: '100%', marginBottom: 10 }}>
                <div style={{width: '60%', justifyContent: 'start', fontWeight: 'bold'}}>{(this.state.Red !== '') ? `${language[this.props.currentLanguage].Receipt}(${language[this.props.currentLanguage].Red}): ` : `${language[this.props.currentLanguage].Receipt}: `}</div>
                <div style={{width: '35%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].OriginalText}</div>
              </div>
              <div className="dcc" style={{ width: '100%', marginBottom: 20 }}>
                {this.state.loading ? (
                  <div style={{ flexDirection: 'column'}}>
                    <div>
                      <img src={Loading3} alt="Logo" className="loadingreceiptimages" style={{ marginBottom: 30 }} />
                    </div>
                  </div>
                ) : (
                  <>
                    {/* <textarea
                      class="form-control"
                      style={{ textAlign: 'start', width: '60%', height: 350, fontWeight: 'bold', backgroundColor: '#EFEFEF' }}
                      value={this.state.TextResult}
                      readOnly={true}
                    /> */}
                    <div 
                      style={{ whiteSpace: 'pre-wrap' }}
                      className='DisplayReceiptContainer MenubarTitle'
                      readOnly={true}>
                      {this.state.ReceiptDisplay}
                    </div>
                    <textarea
                      className="form-control MenubarTitle"
                      style={{ textAlign: 'start', width: '35%', height: 350, fontWeight: 'bold', backgroundColor: '#EFEFEF' }}
                      value={this.state.OriginalTextResult}
                      readOnly={true}
                    />
                  </>
                )}
              </div>
              <div style={{ width: '100%', display:'flex', flexDirection: 'row', marginBottom: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '50%', marginBottom: 0, marginRight: 0 }}>
                  <div style={{ width: '100%'}}>
                    {/* {this.state.RecallButton === true ? (
                      <button style={{ backgroundColor: '#EE3E13', color: 'white', fontWeight: 'bold', marginRight: 20, cursor: (this.props.Role === 'agent' || this.props.Role === 'Player') ? 'not-allowed' : null }}
                        disabled={(this.props.Role === 'agent') ? true : (this.props.Role === 'player') ? true : false}
                        onClick={() => this.RecallBetReceipt()}
                        className="btn my-cusomized-button ReceiptButton">
                        {language[this.props.currentLanguage].WithdrawTicket}
                      </button>
                    ) : ( */}
                    <button style={{ backgroundColor: '#EE3E13'}}
                      disabled={this.state.DisabledCancel}
                      onClick={() => this.CancelBetAPI()}
                      className="btn ViewReceiptFunctionsButton button2 MenubarTitle">
                      {language[this.props.currentLanguage].CancelTicket}
                    </button>
                    {/* )} */}
                  </div>
                  <div style={{ width: '100%'}}>
                    <button style={{ backgroundColor: '#3C96F4'}}
                      disabled={this.state.disabledEditRebuy}
                      onClick={() => this.getReorderBet()}
                      className="btn ViewReceiptFunctionsButton button2 MenubarTitle">
                      {language[this.props.currentLanguage].EditAndRebuy}
                    </button>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '50%', marginRight: 5 }}>
                  <div style={{ width: '100%'}}>
                    <button style={{ backgroundColor: '#3C96F4'}}
                      disabled={this.state.loadingforBet}
                      onClick={() => this.ReorderTeicket()}
                      className="btn ViewReceiptFunctionsButton button2 MenubarTitle">
                      {language[this.props.currentLanguage].Rebuy}
                    </button>
                  </div>
                  <div style={{ width: '100%'}}>
                    <button style={{ backgroundColor: '#3C96F4', cursor: 'copy' }}
                      className="btn ViewReceiptFunctionsButton button2 MenubarTitle" onClick={() => {this.CopyToC(this.state.TextResult)}}>{language[this.props.currentLanguage].CopyReceipt}</button>
                  </div>
                  {this.state.OriginalTextResult !== '' && this.state.Authority.EditOrder !== 0 && (this.props.Role !== 'player' && this.props.Role !== 'agent') &&
                <button style={{ backgroundColor: '#3C96F4', color: 'white', marginLeft: 5, fontWeight: 'bold', minWidth: 70  }}
                  className="btn MenubarTitle">
                  {(this.props.Role === 'agent') ? 
                    <div>{language[this.props.currentLanguage].Edit}</div> 
                    : (this.props.Role === 'player') ? 
                      <div>{language[this.props.currentLanguage].Edit}</div> 
                      : (this.state.Authority.EditOrder === 0) ?
                        <div onClick={() => this.ErrorMessage('1')}>{language[this.props.currentLanguage].Edit}</div> 
                        : (this.state.DateValidated === false) ?  
                          <div onClick={() => this.ErrorMessage('2')}>{language[this.props.currentLanguage].Edit}</div>
                          : (this.state.CheckStatus === 'Cancelled') ? 
                            <div onClick={() => this.ErrorMessage('3')}>{language[this.props.currentLanguage].Edit}</div> 
                            :
                            <>
                              {(this.state.Authority.EditTimeoutOrder === 1) ? 
                                <div onClick={() => this.EditBetReceipt(this.state.ServerIDForCancel, this.state.UserOrderID)}>{language[this.props.currentLanguage].Edit}</div>
                                : <>
                                  <div onClick={() => this.EditBetReceipt(this.state.ServerIDForCancel, this.state.UserOrderID)}>{language[this.props.currentLanguage].Edit}</div>
                                </>
                              }
                            </>
                  }
                </button>
                  }
                </div>
              </div>
              {/* <div className="" style={{ width: '100%', marginBottom: 20, overflowX: 'auto' }}>
                <button style={{ textAlign: 'center', backgroundColor: '#EE3E13', color: 'white', width: (isMobile) ? 'auto' : 200, fontWeight: 'bold', marginRight: 20, minWidth: 100  }}
                  disabled={this.state.disabled}
                  onClick={() => this.CancelBetAPI()}
                  class="btn my-cusomized-button button2">
                  {language[this.props.currentLanguage].CancelTicket}
                </button>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? 'auto' : 200, fontWeight: 'bold', marginRight: 20, minWidth: 100  }}
                  disabled={this.state.disabled}
                  onClick={() => this.getReorderBet()}
                  class="btn my-cusomized-button button2">
                  {language[this.props.currentLanguage].EditAndRebuy}
                </button>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? 'auto' : 200, fontWeight: 'bold', marginRight: 20, minWidth: 100  }}
                  disabled={this.state.disabled}
                  onClick={() => this.ReorderTeicket()}
                  class="btn my-cusomized-button button2">
                  {language[this.props.currentLanguage].Rebuy}
                </button>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? 'auto' : 200, fontWeight: 'bold', minWidth: 100 }}
                  class="btn my-cusomized-button button2" onClick={() => {this.CopyToC(this.state.TextResult)}}>{language[this.props.currentLanguage].CopyReceipt}</button>
                {(this.state.OriginalTextResult !== '') &&
                <button style={{ backgroundColor: '#3C96F4', color: 'white', marginLeft: 20, fontWeight: 'bold', minWidth: 70  }}
                  disabled={(this.props.Role === 'agent') ? true : (this.props.Role === 'player') ? true : (this.state.Authority.EditOrder === 0) ? true : false}
                  readOnly={(this.state.Authority.EditOrder === 0) ? true : false}
                  className="btn">
                  {(this.props.Role === 'agent') ? 
                    <div>{language[this.props.currentLanguage].Edit}</div> 
                    : (this.props.Role === 'player') ? 
                      <div>{language[this.props.currentLanguage].Edit}</div> 
                      : (this.state.Authority.EditOrder === 0) ?
                        <div onClick={() => this.ErrorMessage('1')}>{language[this.props.currentLanguage].Edit}</div> 
                        : (this.state.DateValidated === false) ?  
                          <div onClick={() => this.ErrorMessage('2')}>{language[this.props.currentLanguage].Edit}</div>
                          : (this.state.CheckStatus === 'Cancelled') ? 
                            <div onClick={() => this.ErrorMessage('3')}>{language[this.props.currentLanguage].Edit}</div> 
                            :
                            <>
                              {(this.state.Authority.EditTimeoutOrder === 1) ? 
                                <div onClick={() => this.EditBetReceipt(this.state.ServerIDForCancel, this.state.UserOrderID)}>{language[this.props.currentLanguage].Edit}</div>
                                : <>
                                  {(moment().hours() >= 19) ? 
                                    <div onClick={() => this.ErrorMessage('4')}>{language[this.props.currentLanguage].Edit}</div>
                                    :
                                    <div onClick={() => this.EditBetReceipt(this.state.ServerIDForCancel, this.state.UserOrderID)}>{language[this.props.currentLanguage].Edit}</div>
                                  }
                                </>
                              }
                            </>
                  }
                </button>
                }
              </div> */}
            </div>
            <div style={{ width: 300 }}>
              {this.state.loadingforBet === true ? (
                <div style={{ flexDirection: 'column', width: 'auto' }}>
                  <div>
                    <img src={Loading} alt="Logo" className="loadingimagesbet" style={{ marginBottom: 30, marginTop: 50 }} />
                    <div className="loadingMessageBet">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
                  </div>
                </div>
              ) : (
                <>
                  {this.state.Receipt !== '' && 
                <div className="column" style={{ backgroundColor: 'white', width: 300, borderColor: 'black', borderRadius: 5, marginLeft: 10 }}>
                  <div className="dcc row" style={{ marginBottom: 30, fontWeight: 'bold', fontSize: '20px' }}>
                    <div>{`${language[this.props.currentLanguage].Successfulrepurchasereceipt}`}</div>
                    <div>
                      <GrFormClose
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.setState({ Receipt: '' })
                        }}
                        size="35px"
                        color="#000"
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: 30, fontSize: '16px', whiteSpace: 'pre-wrap', fontWeight: 'bold', textAlign: 'start', marginLeft: 10 }}>
                    {this.state.ReceiptDisplay}
                  </div>
                  <button style={{ backgroundColor: '#3C96F4', color: 'white', width: 'auto', fontWeight: 'bold', marginBottom: 30 }}
                    className="btn ReceiptButton button2" onClick={() => {this.CopyToC(this.state.Receipt)}}>{language[this.props.currentLanguage].CopyReceipt}</button>
                </div>
                  }
                </>
              )}
            </div>
          </div>
          {this.state.ShowEditPage === true && 
          <div style={{ width: '100%'}}>
            <div><button style={{ backgroundColor: '#3C96F4', color: 'white', width: 'auto', fontWeight: 'bold', marginBottom: 10 }} onClick={() => { this.CloseEditPage() }}
              className="btn ReceiptButton">
              {'Done Edit'}
            </button></div>
            <EditBetReceiptComponent searchState={this.state.searchState} GetByServerID={this.GetByServerID}/>
          </div>
          }
          <div className="dcc" style={{ fontWeight: 'bold', fontSize: '20px' }} hidden={this.state.showShare}>{'Share Result To'}</div>
          <div className="dcc" style={{ paddingBottom: 30 }} hidden={this.state.showShare}>
            <div style={{ marginRight: 10 }}>
              <WhatsappShareButton
                url={shareUrl}>
                <WhatsappIcon size={60} round={true} />
              </WhatsappShareButton>
            </div>
            <div style={{ marginRight: 10 }}>
              <FacebookShareButton
                url={shareUrl}>
                <FacebookIcon size={60} round={true} />
              </FacebookShareButton>
            </div>
            <div style={{ marginRight: 10 }}>
              <LineShareButton
                url={shareUrl}>
                <LineIcon size={60} round={true} />
              </LineShareButton>
            </div>
            <div style={{ marginRight: 10 }}>
              <EmailShareButton
                url={shareUrl}>
                <EmailIcon size={60} round={true} />
              </EmailShareButton>
            </div>
            <div style={{ marginRight: 10 }}>
              <TwitterShareButton
                url={shareUrl}>
                <TwitterIcon size={60} round={true} />
              </TwitterShareButton>
            </div>
          </div>
          {this.state.is_visible && 
            <div className="dcc" style={{ paddingBottom: 30 }}>
              <div className="scrollDesign iconpopup button2"  
                onClick={() => this.scrollToTop()}>
                {/* ↑ ⇈ */}
                <div  className="icon MoveUpArrow"> ⇧ </div>
              </div>
            </div>
          }
        </div>
      </div >
    );
  }
}
// export default ViewReceipt;

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    userID: state.userID,
    UserAuthority: state.UserAuthority,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LongTermReceipt);