import React, {Component, Fragment} from 'react';
import '../App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import moment from 'moment-timezone';
import Table from 'react-bootstrap/Table'
// import Alert from 'react-bootstrap/Alert';
// import {isMobile} from 'react-device-detect';
// import Button from 'react-bootstrap/Button';
import Header2 from '../Component/Header';
import Modal from 'react-bootstrap/Modal';
import { ImArrowLeft, ImArrowRight } from 'react-icons/im';
// import { Multiselect } from 'multiselect-react-dropdown';
// import { GrFormClose } from 'react-icons/gr';
// var timer

export default class Games extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      Search_Username: '',
      modalShow: true,
      Maxdate:  new Date(),
      date:  new Date(),
      Available: false,
      page: 1,
      countperpage: 12,
      countpagemax: 1,
      Editloading: false,
      countpagemin: 1,
    }
  }

  async componentDidMount() {
  }

  async setSelectedDate(date) {
    this.setState({ date }, ()=>{
    })
  }

  async MaxsetSelectedDate(Maxdate) {
    this.setState({ Maxdate}, ()=>{
    })
  }

  
  render() {
    return (
      <Fragment>
        {/* <Alert show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
    <Alert.Heading>{this.state.AlertMessage}</Alert.Heading>
  </Alert> */}
        <Header2 />
        <div className="dcc row">
          <div style={{ marginTop: 20, marginBottom: 20, width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto'}}>
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>From</div>
              <DatePicker
                onChange = {(date) => this.setSelectedDate(date)}
                dateFormat = 'dd-MMM-yyyy'
                placeholder="Date"
                maxDate={new Date()}
                // popperPlacement="top-right"
                selected={this.state.date}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>To</div>
              <DatePicker
                onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                dateFormat = 'dd-MMM-yyyy'
                placeholder="Date"
                maxDate={new Date()}
                // popperPlacement="top-right"
                selected={this.state.Maxdate}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
            </div>
            <input
              style={{ fontSize: "18px", textAlign: 'center', marginLeft: 30, width: 300 }} 
              value={this.state.CompanyName}
              className="form-control"
              readOnly={false}
              onChange={e => this.setState({ Search_Username: e.target.value })}
              placeholder={'登入/名称'} 
            />
          </div>
          {this.state.loading  === true ? ( 
            <div className="divLoader">
              <img style={{ marginTop: 50, width: 200, height: 200, marginRight: 200}} 
                src={'https://i.imgur.com/GLdqYB2.gif'}
                alt=""/>
            </div>
          ) : (
            <div>
              <div style={{ border: '2px solid lightgray' }}>
                <Table responsive="sm" style={{ backgroundColor: 'white'}}>
                  <thead>
                    <tr>
                      <th><div style={{ width: 35, marginLeft: 20}}></div>登入</th>
                      <th><div style={{ width: 50}}>名你</div></th>
                      <th><div style={{ width: 50}}>来</div></th>
                      <th><div style={{ width: 60}}>下线吃</div></th>
                      <th><div style={{ width: 70}}>下线出字</div></th>
                      <th><div style={{ width: 70}}>下线佣金</div></th>
                      <th><div style={{ width: 70}}>下线中奖</div></th>
                      <th><div style={{ width: 70}}>下线中净</div></th>
                      <th><div style={{ width: 60}}>自己吃</div></th>
                      <th><div style={{ width: 70}}>自己佣金</div></th>
                      <th><div style={{ width: 70}}>自己中奖</div></th>
                      <th><div style={{ width: 60}}>自己净</div></th>
                      <th><div style={{ width: 70}}>出给上线</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><div style={{ padding: 5}}>{'1'}</div></td>
                      <td><div style={{ padding: 5}}>{'2'}</div></td>
                      <td style={{ backgroundColor: '#afd7e3'}} ><div style={{ padding: 5}}>{'3'}</div></td>
                      <td style={{ backgroundColor: '#afd7e3'}} ><div style={{ padding: 5}}>{'4'}</div></td>
                      <td style={{ backgroundColor: '#94eaa0'}} ><div style={{ padding: 5}}>{'5'}</div></td>
                      <td style={{ backgroundColor: '#94eaa0'}} ><div style={{ padding: 5}}>{'6'}</div></td>
                      <td style={{ backgroundColor: '#94eaa0'}} ><div style={{ padding: 5}}>{'7'}</div></td>
                      <td style={{ backgroundColor: '#94eaa0'}} ><div style={{ padding: 5}}>{'8'}</div></td>
                      <td style={{ backgroundColor: '#fbfb05'}} ><div style={{ padding: 5}}>{'9'}</div></td>
                      <td style={{ backgroundColor: '#fbfb05'}} ><div style={{ padding: 5}}>{'10'}</div></td>
                      <td style={{ backgroundColor: '#fbfb05'}} ><div style={{ padding: 5}}>{'11'}</div></td>
                      <td style={{ backgroundColor: '#fbfb05'}} ><div style={{ padding: 5}}>{'12'}</div></td>
                      <td style={{ backgroundColor: '#e4a494'}} ><div style={{ padding: 5}}>{'13'}</div></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.modalShow}
                contentClassName="br23"
              >
                <Modal.Body className="dcc" style={{ padding: 10, height: 'auto' }}>
                  <div style={{flexDirection: 'row', width: 'auto'}}>
                    {/* <Alert show={this.state.showNotificationError} variant={this.state.notificationTypeError === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.AlertMessageError}</Alert.Heading>
          </Alert> */}
                    {this.state.Editloading  === true ? ( 
                      <div className="divLoader">
                        <img style={{ marginTop: 50, width: 200, height: 200, marginRight: 0}} 
                          src={'https://i.imgur.com/GLdqYB2.gif'}
                          alt=""
                        />
                      </div>
                    ) : ( 
                      <div className="dcc">
                        <div style={{ marginTop: 20, marginBottom: 20, width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 'auto'}}>
                            <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>From</div>
                            <DatePicker
                              onChange = {(date) => this.setSelectedDate(date)}
                              dateFormat = 'dd-MMM-yyyy'
                              placeholder="Date"
                              maxDate={new Date()}
                              // popperPlacement="top-right"
                              selected={this.state.date}
                              className={"form-control StyleDatePicker"}
                              // withPortal
                            />
                            <div style={{fontSize: "18px", width: 100,  textAlign: 'center'}}>To</div>
                            <DatePicker
                              onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                              dateFormat = 'dd-MMM-yyyy'
                              placeholder="Date"
                              maxDate={new Date()}
                              // popperPlacement="top-right"
                              selected={this.state.Maxdate}
                              className={"form-control StyleDatePicker"}
                              // withPortal
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                      <div>
                        <button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: 300 }} 
                          disabled={this.state.disabled}
                          onClick={() => this.setState({modalShow: false, Available: true})} 
                          className="btn my-cusomized-button" >
                          提交
                        </button>
			                </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <div style={{ marginBottom: 0, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                {this.state.page > this.state.countpagemin ? (
                  <button  style={{ textAlign: 'center',backgroundColor: '#1e5e8a', color: 'white', width: 100, borderColor: '#1e5e8a' }} 
                    className="btn my-cusomized-button" 
                    onClick={() =>
                      this.setState(
                        {
                          page: this.state.page - 1,
                        },
                        () => {
                          this.changePage(this.state.page);
                        },
                      )
                    }>
                    <ImArrowLeft size="16px" color="white" />
                  </button>
                ) : (
                  <div style={{ width: 100 }} />
                )}
                <div >{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <button
                    style={{ textAlign: 'center', backgroundColor: '#1e5e8a', color: 'white', width: 100, borderColor: '#1e5e8a' }}
                    className="btn my-cusomized-button" 
                    onClick={() =>
                      this.setState(
                        {
                          page: this.state.page + 1,
                        },
                        () => {
                          this.changePage();
                        },
                      )
                    }>
                    <ImArrowRight size="16px" color="white" />
                  </button>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
          )}
        </div>
      </Fragment>    
    )
  }
}