import '../App.css';
import React from 'react';
import { language } from '../language';
import { connect } from 'react-redux'
import { Alert, Button,  } from 'react-bootstrap';
// import Switch from "react-switch";
const { AdminUpdateAutho } = require('../Api');
var timer

class EditAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRNID: this.props.AdminDetails.ID,
      Games: [],
      AdminDetails: {},
      OriAdminDetails: {},
      redNumberData: {},
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      EditOrder: 0,
      EditGame: 0,
      CreateUser: 0,
      EditUser: 0,
      RebuyOrder: 0,
      DisplayOrder: 0,
      CancelOrRevertOrder: 0,
      SalesReport: 0,
      AcctDisplay: 0,
      DisplayablePackage: 0,
      EditBanNumber: 0,
      PackageSetting: 0,
      PackageInput: 0,
      PhoneSetting: 0,
      CancelOrRevertTimeoutOrder: 0,
      EditTimeoutOrder: 0,
      RebuyTimeoutOrder: 0,
      PinOrOutWord: 0,
      SystemSetting: 0,
      SetOpenDate: 0,
      SetGameResult: 0,
      CompileReport: 0,
      AdminSetup: 0,
      Report: 0,
      AdminAbleEditAfterSeven: 0,
      EditItem: [],
      AdminItem: [],
      EditAutho: false,
      EditAdminAutho: false,
      SelectAllAuth: '',
      SelectAllAdminAuth: '',
    }
    this.closeAddForm = this.closeAddForm.bind(this);
    this.AdminChange = this.AdminChange.bind(this);
  }  

  async componentDidMount() {
    this.AddEditItems();
  }

  async AddEditItems() {
    const UserRole = this.props.USerRole;
    const Details = this.props.AdminDetails;
    let Edits = []
    let AdminEdits= []

    // IgnoreOrderLimit
    // AbleOrderAfterSeven
    if(UserRole === 'agent' || UserRole === 'player') {
      Edits = [
        { DisplayOrder: Details[0].DisplayOrder },{CancelOrRevertOrder: Details[0].CancelOrRevertOrder},
        { RebuyOrder: Details[0].RebuyOrder },{ SalesReport: Details[0].SalesReport },
        {AcctDisplay: Details[0].AcctDisplay},{ EditUser: Details[0].EditUser },
        { CreateUser: Details[0].CreateUser },{ DisplayablePackage: Details[0].DisplayablePackage },
        { Report: Details[0].Report },{ PackageSetting: Details[0].PackageSetting },
        { PackageInput: Details[0].PackageInput },{ PhoneSetting: Details[0].PhoneSetting },
        { AbleOrderAfterSeven: Details[0].AbleOrderAfterSeven },{ IgnoreOrderLimit: Details[0].IgnoreOrderLimit },
      ]
    } else {
      Edits = [
        { DisplayOrder: Details[0].DisplayOrder },{CancelOrRevertOrder: Details[0].CancelOrRevertOrder},
        { EditOrder: Details[0].EditOrder },{ RebuyOrder: Details[0].RebuyOrder },
        { SalesReport: Details[0].SalesReport },{AcctDisplay: Details[0].AcctDisplay},
        { EditUser: Details[0].EditUser },{ CreateUser: Details[0].CreateUser },
        { DisplayablePackage: Details[0].DisplayablePackage },{EditBanNumber: Details[0].EditBanNumber},
        { Report: Details[0].Report },{ PackageSetting: Details[0].PackageSetting },
        { PackageInput: Details[0].PackageInput },{ PhoneSetting: Details[0].PhoneSetting },
        { EditGame: Details[0].EditGame },{ AbleOrderAfterSeven: Details[0].AbleOrderAfterSeven },{ IgnoreOrderLimit: Details[0].IgnoreOrderLimit },
      ] 
    }
    // Edits = [
    //   { DisplayOrder: Details[0].DisplayOrder },{CancelOrRevertOrder: Details[0].CancelOrRevertOrder},
    //   { EditOrder: Details[0].EditOrder },{ RebuyOrder: Details[0].RebuyOrder },
    //   { SalesReport: Details[0].SalesReport },{AcctDisplay: Details[0].AcctDisplay},
    //   { EditUser: Details[0].EditUser },{ CreateUser: Details[0].CreateUser },
    //   { DisplayablePackage: Details[0].DisplayablePackage },{EditBanNumber: Details[0].EditBanNumber},
    //   { Report: Details[0].Report },{ PackageSetting: Details[0].PackageSetting },
    //   { PackageInput: Details[0].PackageInput },{ PhoneSetting: Details[0].PhoneSetting },
    //   { EditGame: Details[0].EditGame },
    // ] 

    AdminEdits = [
      { CancelOrRevertTimeoutOrder: Details[0].CancelOrRevertTimeoutOrder },{EditTimeoutOrder: Details[0].EditTimeoutOrder},
      { RebuyTimeoutOrder: Details[0].RebuyTimeoutOrder },{ PinOrOutWord: Details[0].PinOrOutWord },
      { SystemSetting: Details[0].SystemSetting },{SetOpenDate: Details[0].SetOpenDate},
      { SetGameResult: Details[0].SetGameResult },{ CompileReport: Details[0].CompileReport }, { AdminAbleEditAfterSeven: Details[0].AdminAbleEditAfterSeven },
      { AdminSetup: Details[0].AdminSetup },
    ]

    this.setState({ 
      EditItem: Edits || [], AdminItem: AdminEdits || [], EditGame: Details[0].EditGame, EditOrder: Details[0].EditOrder, EditUser: Details[0].EditUser,
      CreateUser: Details[0].CreateUser, DisplayOrder: Details[0].DisplayOrder, CancelOrRevertOrder: Details[0].CancelOrRevertOrder,
      RebuyOrder: Details[0].RebuyOrder, SalesReport: Details[0].SalesReport, AcctDisplay: Details[0].AcctDisplay,
      DisplayablePackage: Details[0].DisplayablePackage, EditBanNumber: Details[0].EditBanNumber, Report: Details[0].Report,
      PackageSetting: Details[0].PackageSetting, PackageInput: Details[0].PackageInput, PhoneSetting: Details[0].PhoneSetting,
      CancelOrRevertTimeoutOrder: Details[0].CancelOrRevertTimeoutOrder, EditTimeoutOrder: Details[0].EditTimeoutOrder,
      RebuyTimeoutOrder: Details[0].RebuyTimeoutOrder, PinOrOutWord: Details[0].PinOrOutWord, SystemSetting: Details[0].SystemSetting,
      SetOpenDate: Details[0].SetOpenDate, SetGameResult: Details[0].SetGameResult, CompileReport: Details[0].CompileReport, 
      AbleOrderAfterSeven: Details[0].AbleOrderAfterSeven, IgnoreOrderLimit: Details[0].IgnoreOrderLimit, AdminAbleEditAfterSeven: Details[0].AdminAbleEditAfterSeven,
      AdminSetup: Details[0].AdminSetup,
    }, ()=> {
      this.checkCheckedAll();
      this.checkCheckedAdminAll();
    })
  }

  async checkCheckedAll() {
    const usersArray = [
      'DisplayOrder', 'CancelOrRevertOrder', 'EditOrder','RebuyOrder', 'SalesReport', 'AcctDisplay', 'EditUser', 'CreateUser',
      'DisplayablePackage', 'EditBanNumber', 'Report', 'PackageSetting', 'PackageInput', 'PhoneSetting', 'EditGame', 'AbleOrderAfterSeven', 'IgnoreOrderLimit',
    ]
    const Halo = []
    for(var c = 0; c < usersArray.length; c++) {
      if(this.state[usersArray[c]]=== 1) {
        Halo.push('yes')
      } else {
        Halo.push('No')
      }
    }
    if(Halo.includes('No')) {
      this.setState({ SelectAllAuth: false})
    } else {
      this.setState({ SelectAllAuth: true})
    }
  }

  async checkCheckedAdminAll() {
    const AdminArray = [
      'CancelOrRevertTimeoutOrder', 'EditTimeoutOrder', 'RebuyTimeoutOrder', 'PinOrOutWord', 'SystemSetting', 'SetOpenDate',
      'SetGameResult', 'CompileReport', 'AdminAbleEditAfterSeven', 'AdminSetup',
    ]
    const HaloAdmin = []

    for(var d = 0; d < AdminArray.length; d++) {
      if(this.state[AdminArray[d]]=== 1) {
        HaloAdmin.push('yes')
      } else {
        HaloAdmin.push('No')
      }
    }
    if(HaloAdmin.includes('No')) {
      this.setState({ SelectAllAdminAuth: false})
    } else {
      this.setState({ SelectAllAdminAuth: true})
    }
  }

  closeAddForm(){
    this.props.EditForm();
  }

  async AdminChange(item) {
    if(this.state[item] === true) {
      this.setState({ [item]: false})
    } else {
      this.setState({[item]: true })
    }
  }

  async updateAdminPower() {
    const UpdateUserID = this.props.AdminDetails[0].ID
    let ArrayResult = ['EditOrder', 'EditGame', 'CreateUser', 'EditUser', 'RebuyOrder', 'DisplayOrder', 'CancelOrRevertOrder','SalesReport',
      'AcctDisplay', 'DisplayablePackage', 'EditBanNumber', 'PackageSetting', 'PackageInput', 'Report', 'PhoneSetting', 'CancelOrRevertTimeoutOrder',
      'EditTimeoutOrder', 'RebuyTimeoutOrder', 'PinOrOutWord', 'SystemSetting', 'SetOpenDate', 'SetGameResult', 'CompileReport', 'AbleOrderAfterSeven', 'IgnoreOrderLimit', 'AdminAbleEditAfterSeven'
      ,'AdminSetup']
    let PushUpdateArray = []

    for(var i = 0; i < ArrayResult.length; i++) {
      if(this.state[ArrayResult[i]] === 1) {
        PushUpdateArray.push(`${ArrayResult[i]}`)
      }
    }

    const UpdateAutho = await AdminUpdateAutho(
      UpdateUserID,
      PushUpdateArray,
      this.props.token,
      this.props.username
    )
    if(UpdateAutho.error === 'Invalid User ID') {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`invalidUsername`)
      });
    } else {
      if(UpdateAutho.error) {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(`databaseError`)
        });
      } else if(UpdateAutho.message === 'User\'s Authority Updated'){
        this.setState({ notificationType: 'success' }, () => {
          this.openNotification(`UpdateSuccess`)
        });
      }
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
      // window.location.reload();
      this.props.ApiRefresh();
      this.props.EditForm();
    }, 1500);
  };

  openNotificationError(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
      // window.location.reload();
    }, 5000);
  };

  toggleResult(SelectedAutho) {
    if (this.state[SelectedAutho] === 1) {
      this.setState({
        [SelectedAutho]: 0,
      }, ()=> {
        this.checkCheckedAll();
        this.checkCheckedAdminAll();
      })
    }else {
      this.setState({ [SelectedAutho] : 1})
    }
  }

  async CheckedAllBox(event, type) {
    const usersArray = [
      'DisplayOrder', 'CancelOrRevertOrder', 'EditOrder','RebuyOrder', 'SalesReport', 'AcctDisplay', 'EditUser', 'CreateUser',
      'DisplayablePackage', 'EditBanNumber', 'Report', 'PackageSetting', 'PackageInput', 'PhoneSetting', 'EditGame', 'AbleOrderAfterSeven', 'IgnoreOrderLimit',
    ]
    const AdminArray = [
      'CancelOrRevertTimeoutOrder', 'EditTimeoutOrder', 'RebuyTimeoutOrder', 'PinOrOutWord', 'SystemSetting', 'SetOpenDate',
      'SetGameResult', 'CompileReport', 'AdminSetup',
    ]

    this.setState({ [type]: event.target.checked }, ()=> {
      if(type === 'SelectAllAuth') {
        for(let a = 0; a < usersArray.length; a++) {
          if(this.state[type] === true) {
            this.setState({ [usersArray[a]]: 1})
          } else {
            this.setState({ [usersArray[a]]: 0})
          }
        }
      }
      if(type === 'SelectAllAdminAuth') {
        for(let a = 0; a < AdminArray.length; a++) {
          if(this.state[type] === true) {
            this.setState({ [AdminArray[a]]: 1})
          } else {
            this.setState({ [AdminArray[a]]: 0})
          }
        }
      }
    })
  }

  render() {
    return (
      <div>
        <div className="dcc" style={{ width: '100%', overflowX: 'auto' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100 }} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div>
          <b style={{ fontSize: '20px' }}>{''}</b>
        </div>
        <hr/>
        <div style={{width: '100%', overflowX: 'auto'}}>
          <div className="TitleUsername" style={{ color: 'red', fontWeight: 'bold', width:  '25%', marginBottom:  0}}> 
            {language[this.props.currentLanguage].HintAuthority}
          </div>
          <div className="dcc column" style={{  alignItems: 'center', width: '100%', marginLeft:  'auto', paddingBottom: 30, textAlign: 'none' }}>
            <div className="TitleUsername" style={{ width:'30%',  fontSize: '20px'}}> 
              {language[this.props.currentLanguage].EditAuthority}
              <input
                readOnly={false}
                onChange={(e) => this.CheckedAllBox(e, 'SelectAllAuth')}
                value={'1'}
                style={{ width: 18, height: 18, marginLeft: 10}}
                checked={this.state.SelectAllAuth}
                name="1"
                type="checkbox"
              />
            
            </div>
          </div>
        </div>
        <div style={{ marginBottom: 10, fontWeight: 'bold', width: '100%' , marginLeft: '9%' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}>
            {(this.state.EditItem || []).map((item, index) => {
              return(
                <div className={'row'} index={index} style={{ width: 'calc(100% / 3)', padding: '8px', position: 'relative', fontWeight: 'bold'}}>
                  <div>
                    <input
                      style={{ textAlign: 'center', marginLeft: 5, marginRight: 5, width: 15, height: 15 }} 
                      checked={(this.state[Object.keys(item)[0]] === 1) ? true : false} 
                      onChange={(e) => { 
                        if (e.target.checked) {
                          this.setState({[Object.keys(item)[0]]: 1})
                        } else {
                          this.setState({[Object.keys(item)[0]]: 0}, ()=> {
                            this.checkCheckedAll();
                          })
                        }
                      }}
                      readOnly={false}
                      type="checkbox"
                      placeholder={'SetUser'}
                    />
                  </div>
                  <div style={{ width:  '40%'}} onClick={() => this.toggleResult(Object.keys(item)[0], 'SelectAllAuth')}>
                    {language[this.props.currentLanguage][Object.keys(item)[0]]}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <hr/>
        {(this.props.AdminDetails[0].Role === 'admin' || this.props.AdminDetails[0].Role === 'superAdmin') && 
          <div style={{ width:  '100%', backgroundColor: '#9FE2FE', borderRadius: 10, padding: 5, marginBottom: 10 }}>
            <div style={{ fontWeight: 'bold', marginBottom: 10, paddingTop: 10, fontSize: '20px', textAlign:  'none' }}>
              {language[this.props.currentLanguage].admin}
              <input
                readOnly={false}
                onChange={(e) => this.CheckedAllBox(e, 'SelectAllAdminAuth')}
                value={'1'}
                style={{ width: 18, height: 18, marginLeft: 10}}
                checked={this.state.SelectAllAdminAuth}
                name="1"
                type="checkbox"
              />
            </div>
            <div style={{ marginBottom: 10, fontWeight: 'bold', width: '100%' , marginLeft: '9%'}}>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}>
                {(this.state.AdminItem || []).map((item, idx) => {
                  return(
                    <div className="row" index={idx} 
                      style={{ width: 'calc(100% / 3)', padding: '8px', position: 'relative', fontWeight: 'bold'}}>
                      <div>
                        <input
                          style={{ textAlign: 'center', marginLeft: 5, marginRight: 5, width: 15, height: 15 }} 
                          checked={(this.state[Object.keys(item)[0]] === 1) ? true : false} 
                          onChange={(e) => { 
                            if (e.target.checked) {
                              this.setState({[Object.keys(item)[0]]: 1})
                            } else {
                              this.setState({[Object.keys(item)[0]]: 0}, ()=> {
                                this.checkCheckedAdminAll();
                              })
                            }
                          }}
                          readOnly={false}
                          type="checkbox"
                          placeholder={'SetUser'}
                        />
                      </div>
                      <div style={{ width: '40%'}} onClick={() => this.toggleResult(Object.keys(item)[0], 'SelectAllAdminAuth')}>
                        {language[this.props.currentLanguage][Object.keys(item)[0]]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Button style={{ marginLeft: 10, width:  null}} variant="danger" disabled={this.props.AddDisable } onClick={() => this.closeAddForm()} >{language[this.props.currentLanguage].Close}</Button>
          <Button style={{ marginLeft: 10, width: null}} variant="success" disabled={this.props.AddDisable } onClick={() => this.updateAdminPower()} >{language[this.props.currentLanguage].Edit}</Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    userID: state.userID,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(EditAdmin);