import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';

class MataLock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.MataLockModal}
        contentClassName="br23">
        <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
          <GrFormClose
            style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
            onClick={() => {
              this.props.propsSetState({ MataLockModal: false })
            }}
            size="35px"
            color="#000"
          />
          <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
            <b className="dcc" style={{ padding: 20, fontSize: 23}}>
              {language[this.props.currentLanguage].MataLock}
            </b>
            <div className="dcc" style={{  marginBottom: '3%'}}>
              <Form.Control as="select" style={{ width: 200, marginRight: '4%', fontWeight: 'bold'}} onChange={(e) => this.props.propsSetState({ StatusUser: e.target.value})}
                value={this.props.StatusUser}  
              >
                <option value="self">{language[this.props.currentLanguage].self}</option>
                <option value="downlines"> {language[this.props.currentLanguage].downline}</option>
              </Form.Control>
              <Form.Control as="select" style={{ width: 200, marginRight: 0, fontWeight: 'bold'}} onChange={(e) => this.props.propsSetState({ StatusUserLock: e.target.value})} value={this.props.StatusUserLock} >
                <option value='nolock'>no lock (开锁)</option>
                <option value='lock'>lock (锁机)</option>
              </Form.Control>
            </div>
            <div className="dcc">
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.props.propsSetState({ MataLockModal: false})}>{language[this.props.currentLanguage].Shutdown}</Button>
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" onClick={() => {
                this.props.propsSetState({ MataLockModal: false})
                this.props.updateUserF(this.props.formData.ID, 'MataLock',  this.props.StatusUserLock === 'lock' ? true : false, this.props.StatusUser)
              }}>{language[this.props.currentLanguage].Confirm}</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MataLock);