import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom';
// import { Redirect } from "react-router";
// import { language } from '../language';

class Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: this.props.username,
    }
  }

  async EditReceiptPage() {
    this.props.history.push('/ViewReceipt')
  }
  async AllReceiptPage() {
    this.props.history.push('/CancelReceipt')
  }
  async SearchReceiptpage() {
    this.props.history.push('/SearchReceipt')
  }
  async LongReceiptpage() {
    this.props.history.push('/LongTermReceipt')
  }

  render() {
    return (
      <div style={{ width: '100%', backgroundColor: '#EFEFEF', minHeight: 950, flexDirection: 'column' }}>
        {/* <Header /> */}
        <Header2 />
        <div style={{ fontWeight: 'bold', fontSize: '28px', marginBottom: 10, marginTop: 50 }}>{'单据管理'}</div>
        <div className="dcc" style={{ flexDirection: 'column', marginTop: 50, width: '100%' }}>
          <div className="dcc" style={{ width: '100%', marginBottom: 20 }}>
            <select class="form-control" value={this.state.SelectedExample}
              style={{ textAlign: 'center', marginLeft: 20, fontSize: '18px', width: '20%' }}>
              <option value="cn" selected={this.props.currentLanguage === true}>游览单据</option>
              <option value="en" selected={this.props.currentLanguage === false}>显示取消单据</option>
              <option value="en" selected={this.props.currentLanguage === false}>寻找下注号码</option>
              <option value="en" selected={this.props.currentLanguage === false}>寻找下注号码</option>
              <option value="en" selected={this.props.currentLanguage === false}>显示长期单据</option>
            </select>
          </div>
          <>
            <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '30%', fontWeight: 'bold', marginBottom: 10 }}
              disabled={this.state.disabled}
              onClick={() => this.EditReceiptPage()}
              class="btn my-cusomized-button">
              {'游览单据'}
            </button>
            {/* {this.RedirectEditReceipt()} */}
          </>
          <>
            <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '30%', fontWeight: 'bold', marginBottom: 10 }}
              disabled={this.state.disabled}
              onClick={() => this.AllReceiptPage()}
              class="btn my-cusomized-button">
              {'显示取消单据'}
            </button>
            {/* {this.RedirectAllReceipt()} */}
          </>
          <>
            <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '30%', fontWeight: 'bold', marginBottom: 10 }}
              disabled={this.state.disabled}
              onClick={() => this.SearchReceiptpage()}
              class="btn my-cusomized-button">
              {'寻找下注号码'}
            </button>
            {/* {this.RedirectSearchReceipt()} */}
          </>
          <>
            <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '30%', fontWeight: 'bold', marginBottom: 10 }}
              disabled={this.state.disabled}
              onClick={() => this.LongReceiptpage()}
              class="btn my-cusomized-button">
              {'显示长期单据'}
            </button>
            {/* {this.renderRedirect()} */}
          </>
        </div>
      </div>
    );
  }
}
// export default ViewReceipt;

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(Receipt);