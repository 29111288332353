export function processPlatformPosition(CurrentPFLine, displayPlatform, OriWord, JinNan) {
  let processWordtoReturn = [];
  let PFConvertArray = [];
  let covertCurrentPFLine = CurrentPFLine.split('');
  for (var x = 0; x < covertCurrentPFLine.length; x++) {
    PFConvertArray.push(
      covertCurrentPFLine[x]
        .replace(/1/g, 'M')
        .replace(
          /2/g,
          displayPlatform === 'MKTSWBD'
            ? 'K'
            : 'P',
        )
        .replace(/3/g, 'T')
        .replace(/4/g, 'S')
        .replace(/5/g, 'W')
        .replace(/6/g, 'B')
        .replace(
          /7/g,
          displayPlatform === 'MKTSWBD'
            ? 'D'
            : 'K',
        )
        .replace(/8/g, '8')
        .replace(/9/g, '9')
        .replace(/0/g, '<ERR>')
        .replace(/-/g, '<ERR>')
        .replace(/#/g, '#')
        .replace(/[*]/g, '<ERR>'),
    );
  }
  if (JinNan === '1') {
    for (var i = 0; i < OriWord.length; i++) {
      if ((OriWord[i] >= 1 && OriWord[i] <= 9) || OriWord[i] === '#') {
        processWordtoReturn.push(PFConvertArray[OriWord[i] - 1]);
      } else {
        processWordtoReturn.push('<ERR>');
      }
    }
  } else {
    for (var a = 0; a < OriWord.length; a++) {
      if (OriWord[a] >= 1 && OriWord[a] <= 9) {
        processWordtoReturn.push(PFConvertArray[OriWord[a] - 1]);
      } else {
        processWordtoReturn.push('<ERR>');
      }
    }
  }
  return processWordtoReturn;
}

// export function processPlatformPosition(CurrentPFLine, displayPlatform, OriWord, JinNan) {
//   let processWordtoReturn = [];
//   let PFConvertArray = [];
//   let covertCurrentPFLine = CurrentPFLine.split('');
//   for (var x = 0; x < covertCurrentPFLine.length; x++) {
//     PFConvertArray.push(
//       covertCurrentPFLine[x]
//         .replace(/1/g, 'M')
//         .replace(
//           /2/g,
//           displayPlatform === 'MKTSWBD'
//             ? 'K'
//             : 'P',
//         )
//         .replace(/3/g, 'T')
//         .replace(/4/g, 'S')
//         .replace(/5/g, 'W')
//         .replace(/6/g, 'B')
//         .replace(
//           /7/g,
//           displayPlatform === 'MKTSWBD'
//             ? 'D'
//             : 'K',
//         )
//         
//         .replace(/9/g, '<ERR>')
//         .replace(/0/g, '<ERR>')
//         .replace(/#/g, '#')
//         .replace(/[*]/g, '<ERR>'),
//     );
//   }
//   if (JinNan === '1') {
//     for (var i = 0; i < OriWord.length; i++) {
//       if ((OriWord[i] >= 1 && OriWord[i] <= 8) || OriWord[i] === '#') {
//         processWordtoReturn.push(PFConvertArray[OriWord[i] - 1]);
//       } else {
//         processWordtoReturn.push('<ERR>');
//       }
//     }
//   } else {
//     for (var a = 0; a < OriWord.length; a++) {
//       if (OriWord[a] >= 1 && OriWord[a] <= 8) {
//         processWordtoReturn.push(PFConvertArray[OriWord[a] - 1]);
//       } else {
//         processWordtoReturn.push('<ERR>');
//       }
//     }
//   }
  
//   return processWordtoReturn;
// }