import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { connect } from 'react-redux'
// import Receipt from '../Images/Receipt.png';
import MagnumLogo from '../Images/Cards/magnumlogo.png';
import TotoLogo from '../Images/Cards/toto.png';
import DamacaiLogo from '../Images/Cards/damacaimobile.png';
import BT4Logo from '../Images/Cards/bt4DG1mobile.png';
import Sg4Logo from '../Images/Cards/singaporePool.png';
import SarawakLogo from '../Images/Cards/sarawak.png';
import Sabah88Logo from '../Images/Cards/sabah88.png';
import SansakanLogo from '../Images/Cards/sandakan.png';
import Lotto9Logo from '../Images/Cards/9Lottologo.png';
import Moment from 'moment-timezone';
import Table from 'react-bootstrap/Table';
import { language } from '../language';
import { Platformcolor } from '../static/PlatformColor';
const { dadingReport } = require('../Api');

// import { language } from '../language';
const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class DadingShowAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: this.props.username,
      TodayDate: moment(new Date()).format('DD/MM/YYYY (ddd)'),
      Min_Date: Moment(new Date()).toDate(),
      Max_Date: Moment(new Date()).toDate(),
      SelectedDate: Moment(new Date()).toDate(),
      SearchValue: '',
      TextResult: '',
      showShare: true,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      ChooseStatus: 300, 
      ScrollViewNumb: 6600,
      GameArray: {},
      UseridReport: '', 
      MinimumAmount: '-',
      MinimumType: '-',
      Loading: false,
      MinimumShowStatus: 'Hide',
      ObjectKeys: ['1','2','3','4','5','6','7','8', '9'],
      PopScrollBackButton: false,
      searchState: '',
    }
    this.SelectedOptionStatus = this.SelectedOptionStatus.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount(){
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
    searchState = `${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}`
    if (searchState !== '') {
      searchState = JSON.parse(`{"${searchState}"}` || {})
      // this.setState({SelectedDate: searchState.DadingDate}, () => {
      //   this.CallAPI(searchState, this.state.SelectedDate)
      // })
      this.setState({ SelectedDate: Moment(searchState.DadingDate).toDate() }, ()=> {
        this.CallAPI(searchState.id, searchState.DadingDate)
      })
    }
  }

  async CallAPI(searchState, selectedDate){
    // console.log('searchState', searchState)
    // console.log('selectedDate', selectedDate)
    this.setState({ UseridReport: this.props.userID}, async()=>{
      const GameArray = await dadingReport(moment(selectedDate).format('YYYY-MM-DD'), searchState, this.props.username, this.props.token);
      if(GameArray.message === 'No dading record') {
        this.setState({GameArray: JSON.parse(JSON.stringify('No Data')), Loading: true})
      } else {
        if(GameArray){
          let AddBoolean = GameArray
          this.state.ObjectKeys.forEach((item) =>{
            if (GameArray[item].length !== 0){
              AddBoolean[item] = {...GameArray[item], ...{GameBoolean: true}}
            }
          })
          this.setState({GameArray: JSON.parse(JSON.stringify(AddBoolean)), Loading: true})
        }
      }
    })
  }

  toggleResult(platform) {
    let EditBoolean = this.state.GameArray
    EditBoolean[platform] = {...EditBoolean[platform], ...{GameBoolean: !(EditBoolean[platform].GameBoolean)}}
    this.setState({GameArray: JSON.parse(JSON.stringify( EditBoolean))})
  }

  textChange(value){
    if (/^[0-9-]+$/.test(value) || value === '') {
      this.setState({ MinimumAmount: JSON.parse(JSON.stringify(value))}, ()=> {
      })
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  onKeyEnter(event){
    if (event.key === 'Enter') {
      this.CallAPI('');
    }
  }

  SelectedOptionStatus(event) {
    this.setState({ChooseStatus: event.target.value}, ()=>{
      this.CallAPI();
    });
  }

  async setSelectedDate(SelectedDate) {
    this.setState({ SelectedDate }, ()=>{
      this.CallAPI('all', SelectedDate)
    })
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowY: 'auto' }}> 
        <Header2 />
        <div className="dcc mobileHeader"
          ref={this.elScroll.elRef}
          style={{
            marginTop: 20,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft:  '3.5%',
            flexDirection: 'row',
            width: '95%'
          }}>
          <div style={{ justifyContent: 'center', display: 'flex' }} >
            <div style={{fontSize: "18px", width: 100, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Date} :</div>
            <DatePicker
              onChange = {(Date) => this.setSelectedDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              // maxDate={new Date()}
              // popperPlacement="top-right"
              selected={this.state.SelectedDate}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.props.username} </div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 10,
            paddingBottom: 10,
            justifyContent: 'space-between',
            marginLeft: '4%',
            flexDirection: 'row',
          }}>
        </div>
        {/* <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: (isMobile) ? '12%' : '4%',
            flexDirection: 'row',
          }}>
          <Form.Control as='select' class="form-control" value={this.state.ChooseStatus} onChange={this.SelectedOptionStatus} style={{ textAlign: 'center', marginRight:  (isMobile) ? 0 : 20, width: (isMobile) ? '24%' : '10%', fontSize: '15px',}}>
            <option value={300}>{"300"}</option>
            <option value={500}>{"500"}</option>
            <option value={1000}>{"1000"}</option>
            <option value={10000}>{"10000"}</option>
          </Form.Control>
        </div> */}
        <div style={{ marginTop: 10,marginLeft: '1.5%', display: 'flex' }}>
          <div style={{fontSize: "18px", width: 150, fontWeight: 'bold'}}>{'Total'} :</div>
          <div className="TitleUsername">{Number(this.state.GameArray['TotalAmount'] || 0).toFixed(0)} </div>
        </div>
        {(this.state.Loading && this.state.GameArray !== 'No Data') &&<div className={`d-flex justify-content-center px-3 `} style={{ marginBottom: 15}}>
          <div className="dcc row" style={{ justifyContent: 'center' }} align="left">
            { this.state.ObjectKeys.map((item) =>{ 
              return <img src={ (item === '1') ? MagnumLogo : (item === '2') ? DamacaiLogo : (item === '3') ? TotoLogo : (item === '4') ? Sg4Logo : (item === '5') ? SarawakLogo : (item === '6') ? Sabah88Logo : (item === '7') ? SansakanLogo : (item === '9') ? Lotto9Logo :  BT4Logo } 
                alt="Logo" className={`mainPageToggleLogo ${this.state.GameArray[item].GameBoolean && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult(item)} />
            })}
          </div>
        </div>}
        {(this.state.GameArray === 'No Data' && this.state.Loading === true) && 
        <div style={{ width: '100%', marginTop: 50, fontWeight: 'bold', fontSize: '30px' }}>
          {language[this.props.currentLanguage].NoData}
        </div>
        }
        {(this.state.Loading && this.state.GameArray !== 'No Data') && <Table responsive="sm" style={{ backgroundColor: 'white' }}>
          <div className="column" style={{ justifyContent: 'center', display: 'flex' }} >
            {  this.state.ObjectKeys.map((item) =>{
              return (this.state.GameArray[item].GameBoolean) && <div style={{ paddingBottom: 40, marginRight: 20 }}>
                <Table>
                  <thead>
                    <tr style={{
                      fontWeight: 'bold',
                      backgroundColor: Platformcolor(item, ''),
                      color: (item === '1') ? 'black' : (item === '7') ? 'black' : (item === '4') ? 'black' : 'white'}} >
                      <th colSpan={3} ><div  style= {{ fontSize: '15px'}}>{(item === '1') ? `${language[this.props.currentLanguage].magnum}` : 
                        (item === '2') ? `${language[this.props.currentLanguage].damacai}` : 
                          (item === '3') ? `${language[this.props.currentLanguage].toto}`: 
                            (item === '4') ? `${language[this.props.currentLanguage].singapore}`:
                              (item === '5') ? `${language[this.props.currentLanguage].sarawak}`:
                                (item === '6') ? `${language[this.props.currentLanguage].sabah}`: 
                                  (item === '7') ? `${language[this.props.currentLanguage].sandakan}`: 
                                    (item === '9') ? `Lotto 9`: 
                                      `${language[this.props.currentLanguage].GrandDragon}` }</div></th>
                      <th  colSpan={(this.state.MinimumShowStatus === 'Show') ? 5 : 4}  >
                        <div style= {{ fontSize: '15px'}}>
                          {Number(this.state.GameArray['Amount' + item] || 0).toFixed(0)}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  
                  <thead>
                    <tr>
                      <th style={{ paddingRight: 5, paddingLeft: 5 }}><div>Number</div></th>
                      <th style={{ backgroundColor: '#fff23e', paddingRight: 5, paddingLeft: 5 }}><div>B</div></th>
                      <th style={{ paddingRight: 5, paddingLeft: 5 }}><div>S</div></th>
                      <th style={{ backgroundColor: '#ff431b', paddingRight: 5, paddingLeft: 5 }}><div style={{ color: 'white' }}>SA</div></th>
                      <th style={{ backgroundColor: '#32c6f4', paddingRight: 5, paddingLeft: 5  }}><div>SB</div></th>
                      <th style={{ backgroundColor: '#008000', paddingRight: 5, paddingLeft: 5  }}><div style={{ color: 'white' }}>SC</div></th>
                      {(this.state.MinimumShowStatus === 'Show')&&<th style={{  paddingRight: 5, paddingLeft: 5  }}><div>{language[this.props.currentLanguage].Singular}</div></th>}
                    </tr>
                  </thead>
                  <tbody>
                    { Object.keys(this.state.GameArray[item]).map((NumberItem) =>{
                      return <tr>
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') && <th style={{ backgroundColor: '#ebf4fc', color: 'black', paddingRight: 5, paddingLeft: 5}} >{this.state.GameArray[item][NumberItem]['Word'] }</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ backgroundColor: '#ebf4fc', paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['B']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['S']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ backgroundColor: '#ebf4fc', paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SA']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SB']).toFixed(0)}</th>}
                        {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ backgroundColor: '#ebf4fc', paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SC']).toFixed(0)}</th>}
                        {(this.state.MinimumShowStatus === 'Show')&& (NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['TotalOrderCount']).toFixed(0)}</th>}
                      </tr>
                    })}
                  </tbody>
                </Table>
              </div>
            })
            }
          </div>
        </Table>}
        {(this.state.GameArray.message === 'No Data') && <div className= "dcc">
          <div className="TitleUsername" style={{ width: '30%'}}>
            {language[this.props.currentLanguage].GameHavenStart}
          </div>
        </div>}
        {(this.state.PopScrollBackButton)&&<div class={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
          ^
          </div>
        </div>}
      </div >
    );
  }
}
// export default Page2;

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DadingShowAll);