export function Platformcolor(platform, GDisplay) {
  if(GDisplay === '') {
    if (platform === 'Default') return 'lightsteelblue'
    else if (platform === '1') return '#f0e876'
    else if (platform === '2') return '#8c94f0'
    else if (platform === '3') return '#f55e5e'
    else if (platform === '4') return '#68d6f8'
    else if (platform === '5') return '#3c943c'
    else if (platform === '6') return '#f58065'
    else if (platform === '7') return '#f1cd4a'
    else if (platform === '8') return '#c1272d'
    else if (platform === '9') return '#ffc107'
    return 'lightgray'
  } else {
    if (platform === 'Default') return 'lightsteelblue'
    else if (platform === '1' || platform === 'M') return '#f0e876'
    else if (platform === '2' || (GDisplay === 'MPTSWBK') ? platform === 'P' : platform === 'K') return '#8c94f0'
    else if (platform === '3' || platform === 'T') return '#f55e5e'
    else if (platform === '4' || platform === 'S') return '#68d6f8'
    else if (platform === '5' || platform === 'W') return '#3c943c'
    else if (platform === '6' || platform === 'B') return '#f58065'
    else if (platform === '7' || (GDisplay === 'MPTSWBK') ? platform === 'K' : platform === 'D') return '#f1cd4a'
    else if (platform === '8') return '#c1272d'
    else if (platform === '9') return '#ffc107'
    return 'lightgray'
  }
}