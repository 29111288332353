import '../App.css';
import React from 'react';
import { connect } from 'react-redux'
import Header2 from '../Component/Header';
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker';
import Moment from 'moment-timezone';
import Loading from '../Images/loading.gif';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { language } from '../language';
import { exportfirsttoExcel } from '../jsonToXlsx';
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
const { secondReportUserListFNC, firstReportFNC, getMyselfF, searchDownlineHR } = require('../Api');

var timer

class FirstReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: localStorage.getItem('Username'),
      Min_Date: Moment().toDate(),
      Max_Date: Moment().toDate(),
      isSub:  '',
      UserID: '',
      DownlineID: 'All',
      userID: this.props.userID,
      username: this.props.username,
      token: this.props.token,
      Role: this.props.Role,
      getUsers: [],
      optionsUsername: '',
      SelectedUser: '',
      optionsDownlineUsername: '',
      SelectedDownlineUser: '',
      SelfData: {},
      DownlineData: {},
      GetSecondReport: [],
      CloseAll: "0",
      ShowAllCCC: 'Close All',
      GamesDisplay: '',
      loading: false,
      SelectedType: [
        { Name: 'Normal', check: false},{ Name: 'Red', check: false},
        { Name: 'SGD', check: false}, { Name: 'THAI', check: false}, { Name: 'All', check: true}
      ],
      ViewType: 'All',
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Authority: '',
    }
    this.SelectedUser = this.SelectedUser.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
  }
    
  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ userID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID }, () => {
      this.getDownline('');
    })
    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({GamesDisplay: GDisplay, Authority: UserAuthorities }, () => {
      // console.log('Authority', this.state.Authority.Tong)
    })
  }
    
  async setSelectedDate(date, dateType) {
    if(dateType === 'Min_Date') {
      this.setState({ Max_Date: date })
    }
    this.setState({ [dateType]: date })
  }
    
  async getDownline(Type) {
    const UserID = this.state.userID;
    // const DownlineUsername = await secondReportUserListFNC(Username, UserID, token)
    const getMyself = await getMyselfF(UserID, this.props.token, this.props.username)
    if(getMyself) {
      this.setState({ UserID: getMyself[0].ID || '', SelectedUser: {value: getMyself[0].ID, label: getMyself[0].Username}, SearchUser: getMyself[0].Username, DownlineID: 'All' }, ()=> {
        this.SelectedAgent(this.state.SelectedUser, 'First')
      })
    }
    if(Type !== '') {
      this.SelectedAgent(this.state.SelectedUser, 'First')
      this.CallDataApi();
    }
  }
    
  async SelectedAgent(e, Type) {
    if (Type === 'First') {
      if(e.value !== 'all') {
        this.setState({ UserID: e.value, SelectedUser: e})
        this.getSelectedDownline(e.value);
      }
    } else {
      this.setState({ DownlineID: e.value, SelectedDownlineUser: e}, () => {
        this.CallDataApi();
      })
    }
  }
    
  async getSelectedDownline(SelectedUser) {
    const token = this.state.token
    let tempArray = []
    let tempObject = {}
    const SelectedDownlineUsername = await secondReportUserListFNC(this.props.username,SelectedUser, token)
    // console.log('SelectedDownlineUsername', SelectedDownlineUsername)
    SelectedDownlineUsername.forEach((item) => {
      tempObject = {
        ...tempObject,
        value: item.ID,
        label: item.Username,
      }
      tempArray.push(tempObject)
    })
    tempArray.unshift({value: 'All', label: `${language[this.props.currentLanguage].All}`})
    if(SelectedDownlineUsername) {
      this.setState({ DownlineID: tempArray[0].value, SelectedDownlineUser: tempArray[0], optionsDownlineUsername: tempArray })
    }
  }
    
  async CallDataApi() {
    this.setState({ loading: true })
    const ViewType = this.state.ViewType
    const SelectedID = this.state.UserID
    const DownlineID = this.state.DownlineID
    const MinDate = Moment(this.state.Min_Date).format('YYYY-MM-DD')
    const MaxDate = Moment(this.state.Max_Date).format('YYYY-MM-DD')
    // console.log('SelectedID', SelectedID)
    const GetData = await firstReportFNC(MinDate, MaxDate, SelectedID, DownlineID, ViewType, this.state.Authority.isSub,this.props.username, this.props.token)
    if(GetData.error){
      this.setState({ notificationType: 'error', loading: false }, () => {
        this.openNotificationAPI(GetData.error)
      });
    } else {
      this.setState({ DownlineData: GetData, loading: false })
    }
  }

  TotalResult(SelectedValue, Type) {
    let TotalSale = {
      Sale: 0,
    }
    Object.values(SelectedValue).forEach((item) => {
      TotalSale.Sale += Number(item[Type])
    })
    return TotalSale.Sale.toFixed(2)
  }
    
  CloseAccordian() {
    if(this.state.CloseAll === '0') {
      this.setState({CloseAll: '1'})
    } else {
      this.setState({CloseAll: '0'})
    }
  }
    
  exportXlsx() {
    exportfirsttoExcel(this.state.Min_Date, this.state.Max_Date, this.state.DownlineData, this.props.currentLanguage)
  }

  CheckedType(Selected, ind) {
    let SelectedType = this.state.SelectedType
    for (var i=0; i<SelectedType.length; i++) {
      SelectedType[i].check = false
    }
    SelectedType[ind].check = true

    this.setState({ SelectedType, ViewType: Selected.Name }, ()=> {
      this.CallDataApi();
    })
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  openNotificationAPI(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };


  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.SearchUser !== '') {
      const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.props.userID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification('InvalidDownlineIDorUsername')
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID : [],
    })
  }
  
  SelectedUser(e) {
    this.SelectedAgent(e, 'First')
    this.setState({ SearchUser: e.label })
    this.handleCloseSearchModal();
  }
  
  render() {
    return(
      <div className='StartBackground'>
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} show={this.state.showNotification}
          variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <Header2 />
        <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
        <div>{language[this.props.currentLanguage].FirstReport}</div>
        <div style={{ marginBottom: 10, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{fontSize: "16px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>From</div>
            <DatePicker
              dateFormat = 'dd-MMM-yyyy'
              selected={this.state.Min_Date}
              onChange = {(date) => this.setSelectedDate(date, 'Min_Date')}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
            <div style={{fontSize: "16px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>To</div>
            <DatePicker
              dateFormat = 'dd-MMM-yyyy'
              selected={this.state.Max_Date}
              onChange = {(date) => this.setSelectedDate(date, 'Max_Date')}
              className={ "form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold', marginRight: 10 }}
            disabled={(this.state.loading === true) ? true : false}
            className="button2"
            variant="primary"
            onClick={() => this.exportXlsx()}>
            XLSX
          </Button>
        </div>
        {(this.state.Authority.isSub === 0 || (this.state.Authority.isSub === 1 && this.state.Authority.Tong === 0)) ? <>
          <div style={{ marginBottom: 10, width: '100%', display: 'flex', flexDirection:'row' }}>
            <div style={{fontSize: "16px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>代理 :</div>
            {/* <div style={{ width: (isMobile) ? '100%' : 220 }}>
            <Select
              options={this.state.optionsUsername}
              value={this.state.SelectedUser}
              onChange={(e) => this.SelectedAgent(e, 'First')}
            >
            </Select>
          </div> */}
            <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
              <Form.Control style={{ width:  150, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
              <Button className='button2' variant="primary" disabled={(this.state.loading === true) ? true : false} onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
              <Button className='button2' variant="primary" style={{ marginLeft: 5 }} disabled={(this.state.loading === true) ? true : false} onClick={() => this.getDownline('self')}>{'Self'}</Button>
            </div>
            {this.state.optionsDownlineUsername.length > 0 && 
          <div style={{ width: 220, marginLeft: 5 }}>
            <Select
              options={this.state.optionsDownlineUsername}
              value={this.state.SelectedDownlineUser}
              onChange={(e) => this.SelectedAgent(e, 'Second')}
            >
            </Select>
          </div>
            }
            <div style={{ width: 'auto', marginLeft: 10 }}>
              <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold' }}
                disabled={(this.state.loading === true) ? true : false}
                className="button2"
                variant="primary"
                onClick={() => this.CallDataApi()}>
                {language[this.props.currentLanguage].Search}
              </Button>
            </div>
          </div>
        </>: 
          <>
            <div style={{ marginBottom: 10, width: '100%', display: 'flex', flexDirection:  'row' }}>
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>代理</div>
              <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
                <Form.Control disabled style={{ width:  150, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
              </div>
              <div style={{ width: 'auto', marginLeft: 10 }}>
                <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold' }}
                  disabled={(this.state.loading === true) ? true : false}
                  className="button2"
                  variant="primary"
                  onClick={() => this.CallDataApi()}>
                  {language[this.props.currentLanguage].Search}
                </Button>
              </div>
            </div>
          </>}
        <div style={{ marginBottom: 10, width: '100%', display: 'flex', flexDirection:  'row' }}>
          <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>筛选</div>
          <>
            {this.state.SelectedType.map((items, idd) => {
              return(
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }} key={idd}>
                  {/* <div>{items.Name}</div> */}
                  <Form.Check
                    style={{ fontSize: 18, alignItems: 'center' }}
                    inline
                    label={language[this.props.currentLanguage][items.Name]}
                    type="checkbox"
                    checked={items.check}
                    onChange={(e) => this.CheckedType(items, idd)}
                  />
                </div>
              )
            })}
          </>
        </div>
        <div className='dcc' style={{ width: '100%', flexDirection: 'column', marginTop: 10 }}>
          {this.state.loading ? (
            <div className="divLoader" style={{ display: 'flex', flexDirection: 'column'}}>
              <img src={Loading} alt="Logo" className="loadingreceiptimages" style={{ marginBottom: 30 }} />
              <div className="loadingMessageBet">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
            </div>
          ) : (
            <>
              {Object.keys(this.state.DownlineData).map((items, index) => {
                return(
                  <div key={index} style={{ display: 'flex', flexDirection: 'column', width: '97%', backgroundColor: 'white', marginBottom: 30, overflowY: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: 'bold' }}>
                      <div className='FirstReportTable'>{`${Moment(this.state.Min_Date).format('DD/MM/YYYY')} to ${Moment(this.state.Max_Date).format('DD/MM/YYYY')}`}</div>
                      <div className='FirstReportTable'>{language[this.props.currentLanguage].Sale}</div>
                      <div className='FirstReportTable'>{language[this.props.currentLanguage].Comm}</div>
                      <div className='FirstReportTable'>{language[this.props.currentLanguage].Win}</div>
                      <div className='FirstReportTable'>{language[this.props.currentLanguage].Nett}</div>
                    </div>
                    {Object.keys(this.state.DownlineData[items]).filter(Details => Details.includes('-SELF')).map((SelfData, ind) => {
                      return(
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%'}} key={ind}>
                          <div className='FirstReportTable'>{SelfData}</div>
                          <div className='FirstReportTable'>{(this.state.ViewType === 'SGD') ? `${Number(this.state.DownlineData[items][SelfData].Sale).toFixed(2)} (${Number(this.state.DownlineData[items][SelfData].sgdSale).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.DownlineData[items][SelfData].Sale).toFixed(2)} (${Number(this.state.DownlineData[items][SelfData].thaiSale).toFixed(2)})` : Number(this.state.DownlineData[items][SelfData].Sale).toFixed(2)}</div>
                          <div className='FirstReportTable'>{(this.state.ViewType === 'SGD') ? `${Number(this.state.DownlineData[items][SelfData].Comm).toFixed(2)} (${Number(this.state.DownlineData[items][SelfData].sgdComm).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.DownlineData[items][SelfData].Comm).toFixed(2)} (${Number(this.state.DownlineData[items][SelfData].thaiComm).toFixed(2)})` : Number(this.state.DownlineData[items][SelfData].Comm).toFixed(2)}</div>
                          <div className='FirstReportTable'>{(this.state.ViewType === 'SGD') ? `${Number(this.state.DownlineData[items][SelfData].Win).toFixed(2)} (${Number(this.state.DownlineData[items][SelfData].sgdWin).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.DownlineData[items][SelfData].Win).toFixed(2)} (${Number(this.state.DownlineData[items][SelfData].thaiWin).toFixed(2)})` : Number(this.state.DownlineData[items][SelfData].Win).toFixed(2)}</div>
                          <div className='FirstReportTable'>{(this.state.ViewType === 'SGD') ? `${Number(this.state.DownlineData[items][SelfData].Nett).toFixed(2)} (${Number(this.state.DownlineData[items][SelfData].sgdNett).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.DownlineData[items][SelfData].Nett).toFixed(2)} (${Number(this.state.DownlineData[items][SelfData].thaiNett).toFixed(2)})` : Number(this.state.DownlineData[items][SelfData].Nett).toFixed(2)}</div>
                        </div>
                      )
                    })}
                    {Object.keys(this.state.DownlineData[items]).filter(Details => !Details.includes('-SELF')).map((DownlineData, idx) => {
                      return(
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%'}} key={idx}>
                          <div className='FirstReportTable'>{DownlineData}</div>
                          <div className='FirstReportTable'>{(this.state.ViewType === 'SGD') ? `${Number(this.state.DownlineData[items][DownlineData].Sale).toFixed(2)} (${Number(this.state.DownlineData[items][DownlineData].sgdSale).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.DownlineData[items][DownlineData].Sale).toFixed(2)} (${Number(this.state.DownlineData[items][DownlineData].thaiSale).toFixed(2)})` : Number(this.state.DownlineData[items][DownlineData].Sale).toFixed(2)}</div>
                          <div className='FirstReportTable'>{(this.state.ViewType === 'SGD') ? `${Number(this.state.DownlineData[items][DownlineData].Comm).toFixed(2)} (${Number(this.state.DownlineData[items][DownlineData].sgdComm).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.DownlineData[items][DownlineData].Comm).toFixed(2)} (${Number(this.state.DownlineData[items][DownlineData].thaiComm).toFixed(2)})` : Number(this.state.DownlineData[items][DownlineData].Comm).toFixed(2)}</div>
                          <div className='FirstReportTable'>{(this.state.ViewType === 'SGD') ? `${Number(this.state.DownlineData[items][DownlineData].Win).toFixed(2)} (${Number(this.state.DownlineData[items][DownlineData].sgdWin).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.DownlineData[items][DownlineData].Win).toFixed(2)} (${Number(this.state.DownlineData[items][DownlineData].thaiWin).toFixed(2)})` : Number(this.state.DownlineData[items][DownlineData].Win).toFixed(2)}</div>
                          <div className='FirstReportTable'>{(this.state.ViewType === 'SGD') ? `${Number(this.state.DownlineData[items][DownlineData].Nett).toFixed(2)} (${Number(this.state.DownlineData[items][DownlineData].sgdNett).toFixed(2)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.DownlineData[items][DownlineData].Nett).toFixed(2)} (${Number(this.state.DownlineData[items][DownlineData].thaiNett).toFixed(2)})` : Number(this.state.DownlineData[items][DownlineData].Nett).toFixed(2)}</div>
                        </div>
                      )
                    })}
                    <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', color: 'white'}}>
                      <div className='FirstReportTotalTable'>{'Total'}</div>
                      <div className='FirstReportTotalTable'>{(this.state.ViewType === 'SGD') ? `${this.TotalResult(this.state.DownlineData[items], 'Sale')} (${this.TotalResult(this.state.DownlineData[items], 'sgdSale')})` : (this.state.ViewType === 'THAI') ? `${this.TotalResult(this.state.DownlineData[items], 'Sale')} (${this.TotalResult(this.state.DownlineData[items], 'thaiSale')})` : this.TotalResult(this.state.DownlineData[items], 'Sale')}</div>
                      <div className='FirstReportTotalTable'>{(this.state.ViewType === 'SGD') ? `${this.TotalResult(this.state.DownlineData[items], 'Comm')} (${this.TotalResult(this.state.DownlineData[items], 'sgdComm')})` : (this.state.ViewType === 'THAI') ? `${this.TotalResult(this.state.DownlineData[items], 'Comm')} (${this.TotalResult(this.state.DownlineData[items], 'thaiComm')})` : this.TotalResult(this.state.DownlineData[items], 'Comm')}</div>
                      <div className='FirstReportTotalTable'>{(this.state.ViewType === 'SGD') ? `${this.TotalResult(this.state.DownlineData[items], 'Win')} (${this.TotalResult(this.state.DownlineData[items], 'sgdWin')})` : (this.state.ViewType === 'THAI') ? `${this.TotalResult(this.state.DownlineData[items], 'Win')} (${this.TotalResult(this.state.DownlineData[items], 'thaiWin')})` : this.TotalResult(this.state.DownlineData[items], 'Win')}</div>
                      <div className='FirstReportTotalTable'>{(this.state.ViewType === 'SGD') ? `${this.TotalResult(this.state.DownlineData[items], 'Nett')} (${this.TotalResult(this.state.DownlineData[items], 'sgdNett')})` : (this.state.ViewType === 'THAI') ? `${this.TotalResult(this.state.DownlineData[items], 'Nett')} (${this.TotalResult(this.state.DownlineData[items], 'thaiNett')})` : this.TotalResult(this.state.DownlineData[items], 'Nett')}</div>
                    </div>
                  </div>
                )
              })}
              
              {Object.keys(this.state.DownlineData).length === 0 && 
          <div style={{ width: '97%'}}>{'No Data'}</div>
              }
            </>
          )}
        </div>
      </div>
    );
  }
}

// export default Setting;

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstReport)
