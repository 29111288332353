exports.characterList = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
  8: 'H',
  9: 'I',
  10: 'J',
  11: 'K',
  12: 'L',
  13: 'M',
  14: 'N',
  15: 'O',
  16: 'P',
  17: 'Q',
  18: 'R',
  19: 'S',
  20: 'T',
  21: 'U',
  22: 'V',
  23: 'W',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  N: 'N',
  O: 'O',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  U: 'U',
  V: 'V',
  W: 'W',
  a: 'A',
  b: 'B',
  c: 'C',
  d: 'D',
  e: 'E',
  f: 'F',
  g: 'G',
  h: 'H',
  i: 'I',
  j: 'J',
  k: 'K',
  l: 'L',
  m: 'M',
  n: 'N',
  o: 'O',
  p: 'P',
  q: 'Q',
  r: 'R',
  s: 'S',
  t: 'T',
  u: 'U',
  v: 'V',
  w: 'W',
}

exports.characterList2 = {
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P1OriPosition: 'P1OriPosition',
  P2OriPosition: 'P2OriPosition',
  P3OriPosition: 'P3OriPosition',
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
  8: 'H',
  9: 'I',
  10: 'J',
  11: 'K',
  12: 'L',
  13: 'M',
  14: 'N',
  15: 'O',
  16: 'P',
  17: 'Q',
  18: 'R',
  19: 'S',
  20: 'T',
  21: 'U',
  22: 'V',
  23: 'W',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  AF: 'AF',
  HM: 'HM',
  a: 'A',
  b: 'B',
  c: 'C',
  d: 'D',
  e: 'E',
  f: 'F',
  g: 'G',
  h: 'H',
  i: 'I',
  j: 'J',
  k: 'K',
  l: 'L',
  m: 'M',
  '5D1': '5D1',
  '5D2': '5D2',
  '5D3': '5D3',
  '6D1': '6D1',
  '7D1': '7D1',
}
  
exports.AlphabetToNumb = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 10,
  K: 11,
  L: 12,
  M: 13,
  N: 14,
  O: 15,
  P: 16,
  Q: 17,
  R: 18,
  S: 19,
  T: 20,
  U: 21,
  V: 22,
  W: 23,
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P1OriPosition: 'P1OriPosition',
  P2OriPosition: 'P2OriPosition',
  P3OriPosition: 'P3OriPosition',
}

exports.GamePlatformNumb1 = {
  Default: 'Default',
  1: 'M',
  2: 'K',
  3: 'T',
  4: 'S',
  5: 'W',
  6: 'B',
  7: 'D',
  8: 8,
  9: 9,
  0: 0,
}

exports.GamePlatformNumb2 = {
  Default: 'Default',
  1: 'M',
  2: 'P',
  3: 'T',
  4: 'S',
  5: 'W',
  6: 'B',
  7: 'K',
  8: 8,
  9: 9,
  0: 0,
}

exports.GamePlatformAlphabet1 = {
  Default: 'Default',
  M: 1,
  K: 2,
  T: 3,
  S: 4,
  W: 5,
  B: 6,
  D: 7,
  8: 8,
  9: 9,
  0: 0,
}

exports.GamePlatformAlphabet2 = {
  Default: 'Default',
  M: 1,
  P: 2,
  T: 3,
  S: 4,
  W: 5,
  B: 6,
  K: 7,
  8: 8,
  9: 9,
}

exports.availablePosition = [
  'P1',
  'P2',
  'P3',
  'P1OriPosition',
  'P2OriPosition',
  'P3OriPosition',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
]
exports.availableStatusField = [
  'Status',
  'StatusA',
  'StatusB',
  'StatusC',
  'StatusD',
  'StatusE',
  'StatusF',
  'StatusG',
  'StatusH',
  'StatusI',
  'StatusJ',
  'StatusK',
  'StatusL',
  'StatusM',
  'StatusAF',
  'StatusHM',
]
  
exports.receivedField = [
  'ReceivedA',
  'ReceivedB',
  'ReceivedC',
  'ReceivedD',
  'ReceivedE',
  'ReceivedF',
  'ReceivedG',
  'ReceivedH',
  'ReceivedI',
  'ReceivedJ',
  'ReceivedK',
  'ReceivedL',
  'ReceivedM',
  'ReceivedAF',
  'ReceivedHM',
]