import '../App.css';
import React from 'react';
// import Footer from '../Component/Footer';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
// import Modal from 'react-bootstrap/Modal';
// import { isMobile } from 'react-device-detect';
import {ReturnBetValidation} from '../static/ReturnBetValidation';
import {  Alert } from 'react-bootstrap';
import { CreateBetOrder, getMyDownline } from '../Api';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import { language } from '../language';
import Loading3 from '../Images/loading-3.gif';
import Dropzone from 'react-dropzone';
var timer
// import { language } from '../language';
// import { findPermutations } from '../utility/Permutation'
// import { isCompositeComponent } from 'react-dom/test-utils';
// import Dropzone from 'react-dropzone';

class CreateNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      FileName: '',
      FileResultWord: '',
      FinalResult: [],
      hightlight: false,
      disabled: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      optionsUsername: '',
      getUsers: [],
      userID: this.props.userID,
      loading: false,
      ShowReceipt: false,
      ReceiptDisplay: '',
      files: [],
      base64URL: '',
    }
    this.UploadFiletxt = this.UploadFiletxt.bind(this);
    this.ConfirmSaveBet = this.ConfirmSaveBet.bind(this);
    this.CleanData = this.CleanData.bind(this);
    this.fileInputRef = React.createRef();


    this.openFileDialog = this.openFileDialog.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if ((UserAuthorities && UserAuthorities.Bet) === 0) {
      window.location.href='/Home'; 
    }
    this.getDownline();
  }

  async getDownline() {
    const UserID = this.props.userID;
    const Username = this.props.username;
    const token = this.props.token
    let tempArray = []
    let tempObject = {}

    const DownlineUsername = await getMyDownline(UserID, token, Username)
    DownlineUsername.forEach((item) => {
      tempObject = {
        ...tempObject,
        value: item.ID,
        label: item.Username,
      }
      tempArray.push(tempObject)
    })
    if(DownlineUsername) {
      this.setState({ getUsers: DownlineUsername, userID: DownlineUsername[0].ID, SelectedUser: tempArray[0], optionsUsername: tempArray })
    }
  }

  async UploadFiletxt(event) {
    if (event !== undefined) {
      const files = event.target.files;
      event.preventDefault()
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.setState({ FileResultWord: /^[0-9#*-.\b]+$/.test(text) })
        // this.setState({ FileResultWord: text }, ()=> {
        //   const Array = this.state.FileResultWord.split('\n')
        //   let Arraynew = []
        //   for(var i=0; i < Array.length; i++) {
        //     const RemoveR = Array[i].replace("\r", "").replace("\r", "")
        //     Arraynew.push(RemoveR)
        //   }
        //   this.setState({ FileResultWord: Arraynew.join('\n') })
        // })
      };
      if (event.target.files) {
        reader.readAsText(event.target.files[0])
        this.setState({ FileName: files[0].name })
      } 
    }
  }

  async ConfirmSaveBet() {
    this.setState({loading: true});
    // const UserAuthorities = JSON.parse(this.props.UserAuthority)
    const UserID = this.state.userID
    let originalText = this.state.FileResultWord;
    // const Array = this.state.FileResultWord.split('\n')
    // let Arraynew = []
    // let Over7pm = true;

    // const Timenow = Moment().hours()
    // if (Timenow >= 19) {
    //   if (UserAuthorities.AbleOrderAfterSeven === 0) {
    //     Over7pm = false
    //   } else {
    //     Over7pm = true
    //   }
    // }

    // for(var i=0; i < Array.length; i++) {
    //   const RemoveR = Array[i].replace("\r", "").replace("\r", "")
    //   Arraynew.push(RemoveR)
    // }
    // originalText = Arraynew.join('\n')
    this.setState({ disabled: true}, async()=>{
      if((originalText).length === 0){
        this.setState({ notificationType: 'error', loading: false }, ()=>{
          this.openNotification('Cannotemptytext')
        })
      } else if (this.state.base64URL === ''){
        this.setState({ notificationType: 'error', loading: false }, ()=>{
          this.openNotification('NoPackage')
        })
      } else {
        const OrderResult = await CreateBetOrder(UserID, originalText , this.props.token, this.props.username)
        if(OrderResult.error) {
          this.openNotification(ReturnBetValidation(OrderResult.error))
          this.setState({ notificationType: 'error', loading: false });
        } else {
          this.openNotification('Success')
          this.setState({ loading: false, ReceiptDisplay: OrderResult.receipt, ShowReceipt: true }, ()=> {
            this.ConvertResultRed(this.state.ReceiptDisplay)
          })
          // timer = setTimeout(() => {
          //   this.setState({ ShowReceipt: false, ReceiptDisplay: ''})
          // }, 8000);
          this.CleanData()
        }
        // if (OrderResult.error === 'User No Package') {
        //   this.openNotification('NoPackage')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'No Date to Bet') {
        //   this.openNotification('DateCantbeBet')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === '5D6D in wrong game') {
        //   this.openNotification('OnlyBTandTOTOallow')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'Not enough date') {
        //   this.openNotification('UnableThoseDate')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'Date error') {
        //   this.openNotification('UnableThoseDate')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'S Game ShutDown') {
        //   this.openNotification('singaporeGameClose')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'All Game ShutDown') {
        //   this.openNotification('AllGameClose')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'MKTSWBD ShutDown') {
        //   this.openNotification('MKTGameShutDown')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'No Game on that day') {
        //   this.openNotification('NoGameThatDay')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'Platform error') {
        //   this.openNotification('ErrorPlatform')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'Account Inactive') {
        //   this.openNotification('AccountInactive')
        //   this.setState({ notificationType: 'error', loading: false});
        // } else if(OrderResult.error === 'Exceed Personal Daily Limit') {
        //   this.openNotification('ExceedDAilyLimit')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'Repeat Order') {
        //   this.openNotification('RepeatOrder')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else if(OrderResult.error === 'Format Error') {
        //   this.openNotification('FormatError')
        //   this.setState({ notificationType: 'error', loading: false });
        // } else {
        //   this.openNotification('Success')
        //   this.setState({ loading: false, ReceiptDisplay: OrderResult.receipt, ShowReceipt: true }, ()=> {
        //     this.ConvertResultRed(this.state.ReceiptDisplay)
        //   })
        //   this.CleanData()
        // }
      }
    })
  }

  async ConvertResultRed(Receipt) {
    let Word = Receipt
    let returnWord = [];

    if (Word.includes('<red>')) {
      const WordArray = Word.split('<red>').join('</red>').split('</red>');
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{color: 'red'}}>
              {WordArray[i]}
            </span>,
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({ReceiptDisplay: returnWord});
    } else {
      this.setState({ReceiptDisplay: Word});
    }
  }

  async CleanData() {
    this.setState({ base64URL: '', FileResultWord: '', files: [] }, () => {
      this.UploadFiletxt()
    })
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.state.disabled) return;
  
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }


  onDrop = (event) => {
    if (!event.length > 1) {
      if (event && event[0]) {
        let img = event;
        this.getBase64(img)
          .then(result => {
            img["base64"] = result;
            this.setState({
              base64URL: result,
              img
            });
          })
      }
      this.setState({hightlight: false, files: event,})
 
    } else {
      const newImagesPromises = []
      for (let i = 0; i < event.length; i++) {
        let img = event;
        this.getBase64(event[i]).then(result => {
          img["base64"] = result;
          newImagesPromises.push(result)
        // this.setState({
        //   base64URL: result,
        //   img
        // });
        })
      }
      this.setState({hightlight: false, files: event, base64URL: newImagesPromises, })
    }
  };
  getBase64 = file => {
    return new Promise(resolve => {
      // let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  
  openFileDialog() {
    if (this.state.disabled) return
    this.fileInputRef.current.click()
  }

  openNotification(message) {
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        disabled: false,
      });
      // this.CleanData()
    }, 5000);
  };

  ChangeReceipt(event) {
    const PressNumber = event.target.value.replace(/[+]/g, '#')
    // const PressNumber2 = PressNumber.replace(/[^0-9D.*#+-^]/g, '')
    this.setState({ FileResultWord: PressNumber })
  }

  render() {
    const files = (this.state.files && this.state.files).map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));
    return (
      <div>
        {(this.props.CreateStatus === 'Create') && <div className='StartBackground'>
          <div className="dcc" style={{ justifyContent: 'center' }}>
            <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
              <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
            </Alert>
          </div>
          <div className="dcc" style={{ flexDirection: 'row' }}>
            {this.state.loading ? (
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div>
                  <img src={Loading3} alt="Logo" className="loadingimages" style={{ marginBottom: 30, marginTop: 50 }} />
                  <div className="loadingMessage">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
                </div>
              </div>
            ) : (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div style={{ backgroundColor: '#EFEFEF', border: `1px solid #d8d8d8`, width: '65%', marginBottom: 50 }}>
                  <div className="dcc" style={{ width: '100%', marginTop: 30, marginBottom: 30, justifyContent: 'center' }}>
                    <div style={{ width: '100%', height: '100%',flexDirection: "column", marginLeft: 0}} 
                      className={`DropzoneNews ${this.state.hightlight ? "Highlight" : ""}`} onDragOver={this.onDragOver} onDragLeave={this.onDragLeave} >
                      <Dropzone onDrop={this.onDrop} accept="image/*" >
                        {({getRootProps, getInputProps}) => (
                          <section className="container">
                            <div {...getRootProps({className: 'containerOndrop'})} >
                              <input {...getInputProps()} />
                              <p>将一些文件拖放到此处，或单击以选择文件</p>
                            </div>
                            <aside>
                              <h4 className="dcc" >文件</h4>
                              <ul>{files}</ul>
                            </aside>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 15 }}>
                    <textarea
                      class="form-control"
                      style={{ textAlign: 'center', width: '95%',  fontWeight: 'bold' }}
                      value={this.state.FileResultWord}
                      readOnly={false}
                      onChange={(e) => this.ChangeReceipt(e)}
                    />
                  </div>
                  <div style={{ width: '100%', paddingBottom: 40 }}>
                    <button style={{ textAlign: 'center', backgroundColor: '#EE3E13', color: 'white', width: 200, fontWeight: 'bold', marginRight: 20 }}
                      disabled={this.state.disabled}
                      onClick={() => this.CleanData()}
                      class="btn my-cusomized-button">
                      {language[this.props.currentLanguage].ClearAll}
                    </button>
                    <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold' }}
                      disabled={this.state.disabled}
                      onClick={() => this.ConfirmSaveBet()}
                      class="btn my-cusomized-button">
                      {language[this.props.currentLanguage].ConfirmTicket}
                    </button>
                  </div>
                </div>
                {this.state.ShowReceipt === true && 
              <div className="column" style={{ backgroundColor: 'white', width: '20%', borderColor: 'black', borderRadius: 5, marginLeft: 10 }}>
                <div className="dcc row" style={{ marginBottom: 30, fontWeight: 'bold', fontSize: '20px' }}>
                  <div>{`${(this.state.SuccessFullCancel === true) ? 
                    language[this.props.currentLanguage].CancelSuccessfulldisplay
                    : language[this.props.currentLanguage].SuccessOrderBet }`}</div>
                  <div>
                    <GrFormClose
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ ShowReceipt: false, ReceiptDisplay: '' })
                      }}
                      size="35px"
                      color="#000"
                    />
                  </div>
                </div>
                <div className="column" style={{ marginBottom: 30, fontSize: '16px', whiteSpace: 'pre-wrap', fontWeight: 'bold', textAlign: 'start', marginLeft: 10 }}>
                  {this.state.ReceiptDisplay}
                </div>
              </div>
                }
              </div>
            )}
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNews);
