const base = window.location.protocol === 'https:' ? 'https://s.hd88.site/' : 'http://217.182.213.202/';
const bettingBase = window.location.protocol === 'https:' ? 'https://s.hd88.site/' : 'http://217.182.213.202/';
const calculateBase = window.location.protocol === 'https:' ? 'https://s.hd88.site/' : 'http://217.182.213.202/';

// const base = 'http://localhost:8003/';
// const bettingBase = 'http://localhost:8003/';
// const calculateBase = 'http://localhost:8003/';

const key = 'hC2XdjZh0Wg47Wqm';

async function massageRes(res, resolve) {
  const result = await res.json()
  // console.log('result==>', result)
  if (result.error && result.error === 'Invalid credentials!') {
    localStorage.removeItem('Username');
    localStorage.removeItem('Role');
    localStorage.removeItem('Token');
    localStorage.removeItem('currentLanguage');
    localStorage.removeItem('userID');
    localStorage.removeItem('isSub');
    localStorage.removeItem('UserAuthority');
    localStorage.removeItem('LoginStatus');
    window.location.href = '/Login';
    resolve()
  } else {
    resolve(result)
  }
}

function getDataByDate(Username, Date, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Date }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function createUserEzFNC(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/createUserEz`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getServerTimeFNC(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getServerTime`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function createUserEzPostFNC(Username, newUserObj, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/createUserEzPost`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, newUserObj }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function updateUserMobile(updateUserObject, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateUserMobile`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ updateUserObject, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getSMSGroupFNC(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getSMSGroup`, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function postSMSGroupFNC(message, phone, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/postSMSGroup`, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message, phone, Username }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function cashWalletHistory(Username, selectedID, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/cashWalletHistory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, selectedID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function dingConverter(Username, KeyIn1, KeyIn2, MultiplyDivide, jinNan, originalText, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/dingConverter`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, KeyIn1, KeyIn2, MultiplyDivide, jinNan, originalText }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function dingConverterCSV(Username, KeyIn1, KeyIn2, MultiplyDivide, jinNan, originalText, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/dingConverterCSV`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, KeyIn1, KeyIn2, MultiplyDivide, jinNan, originalText }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getDadingObjWithID(Username, dadingID, token, deleteBool) {
  return new Promise((resolve) => {
    fetch(`${base}main/getDadingObjWithID`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, dadingID, deleteBool }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function dadingReport(date, ID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}report/dadingReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ date, ID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getLaiReportF(fromDate, toDate, NewfromDate, NewtoDate, UserID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}report/getLaiReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate, NewfromDate, NewtoDate, UserID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getJackpostList(Username, Date, page, countPerPage, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getJackpostList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Date, page, countPerPage }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function searchJackpotListFNC(Date, Username, page, countPerPage, numberKeyword, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/searchJackpotList`, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Date, Username, page, countPerPage, numberKeyword }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function uploadbetDetails(Username, bettingDateMode, bettingDate, PackageID, words, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/bet`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        bettingDateMode,
        bettingDate,
        PackageID,
        words,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getPublicKey(publicKey) {
  return new Promise((resolve) => {
    fetch(`${base}public/pbkey`, {
      method: 'post',
      headers: {
        Authorization: key,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        publicKey,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getLogin(Username, Password, loginMethod, secretCode, captchaNumber) {
  return new Promise((resolve) => {
    fetch(`${base}public/login`, {
      method: 'post',
      headers: {
        Authorization: key,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        Password,
        loginMethod, 
        secretCode, 
        captchaNumber
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}


function getCapchaCode() {
  return new Promise((resolve) => {
    fetch(`${base}public/getSecretCode`, {
      method: 'post',
      headers: {
        Authorization: key,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    }).then((res) => {
      massageRes(res, resolve)
    })
  });
}

function getRedNumber(Username, Role, token, userID, showType) {
  return new Promise((resolve) => {
    fetch(`${base}main/getBanned`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        Role,
        userID,
        showType,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getKeyInFormat(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getKeyIn`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getUsers(Username, token, TargetViewID) {
  return new Promise((resolve) => {
    fetch(`${base}main/getUsers`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        TargetViewID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getUsersHR(Username, token, TargetViewID) {
  return new Promise((resolve) => {
    fetch(`${base}HR/getUsersHR`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        TargetViewID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getPackageNames(Username, TargetChildViewID, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getPackageNames`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        TargetChildViewID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function homeDisplayPackage(Username, token, userID) {
  return new Promise((resolve) => {
    fetch(`${base}main/homeDisplayPackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        userID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function homeSelectedPackageDetail(Username, token, packageID) {
  return new Promise((resolve) => {
    fetch(`${base}main/homeSelectedPackageDetail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        packageID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getOrderOriginalText(UserID, UserOrderID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getOrderOriginalText`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID,
        UserOrderID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getPackageDetails(Username, token, PackageID, ChildPackageID) {
  return new Promise((resolve) => {
    fetch(`${base}main/getPackageDetails`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        PackageID,
        ChildPackageID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function activePackage(Username, token, PackageID, TargetUserID) {
  return new Promise((resolve) => {
    fetch(`${base}main/activePackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        PackageID,
        TargetUserID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function deactivatePackage(Username, token, PackageID, TargetUserID) {
  return new Promise((resolve) => {
    fetch(`${base}main/deactivatePackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        PackageID,
        TargetUserID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function editAdminPackage(PackageID, Commission, Odd, Username, token,) {
  return new Promise((resolve) => {
    fetch(`${base}main/editAdminPackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        PackageID,
        Commission,
        Odd,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateRNPackage(Username, token, PackageID, Commission, Odd, UserID) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateRNPackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        PackageID,
        Commission,
        Odd,
        UserID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateUser(Username, token, targetUserID, targetField, targetValue, subAction) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        targetUserID,
        targetField,
        targetValue,
        subAction,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

// function updateUpperline(Username, token, userID, newUpperlineID) {
//   return new Promise((resolve) => {
//     fetch(`${base}HR/changeUpperline`, {
//       method: 'post',
//       headers: {
//         Authorization: token,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         Username,
//         userID,
//         newUpperlineID,
//       }),
//     }).then((res) => {
//       massageRes(res, resolve)
//     });
//   });
// }

function updateUserAll(Username, token, objectToUpdate) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateUserAll`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        objectToUpdate,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function addRedNumber(redNumber, mode, userIDOBject, limit, adminID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/addBanNumber`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        redNumber, mode, userIDOBject, limit, adminID, Username
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function getSGDRateFNC(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/getSGDRate`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function getTHAIRateFNC(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/getTHAIRate`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function updateSGDRateFNC(type, newSGDRate, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/updateSGDRate`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type, newSGDRate, Username
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function BetNumberDetails(Username, originalText, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/bet`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        originalText,
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function searchBanNumber(redNumber, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/searchBanNumber`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        redNumber,
        Username,
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function getAllUserID(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/allUserID`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function getSelectedRedNumberDetail(selectedBanID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/banNumberDetail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selectedBanID,
        Username
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
} 

function updateRedNumber(Username, Token, selectedRedNumberID, targetedField, targetedValue) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateRedNumber`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        selectedRedNumberID,
        targetedField,
        targetedValue
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function updateRedNumberAll(Username, Token, objectToUpdate) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateRedNumberAll`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        objectToUpdate
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function verifyUsername(Username, checkUsername ,Token) {
  return new Promise((resolve) => {
    fetch(`${base}main/verifyUsername`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        checkUsername
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function removeBanNumber(selectedBanID, Username  ,Token) {
  return new Promise((resolve) => {
    fetch(`${base}main/removeBanNumber`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selectedBanID,
        Username 
      }),
    }).then((res) => {
      resolve(res.json());
    });
  });
}

function CreateBetOrder(UserID, originalText, token, Username, red, rebuy, sgd, thai, csv) {
  return new Promise((resolve) => {
    fetch(`${bettingBase}main/createBetOrder`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserID, originalText, Username, red, rebuy, sgd, thai, csv }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function verifyUserOrderIDStatus(UserID, UserOrderID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/verifyUserOrderIDStatus`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        UserID, 
        UserOrderID, 
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getBetReceipt(UserID, MinDate , MaxDate, OrderID, ServerID, ReceiptOptions, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getBetReceipt`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserID, MinDate , MaxDate, OrderID, ServerID, ReceiptOptions, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function CancelBet(orderID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/CancelReceipt`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ orderID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getMyDownline(userID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getDownline`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getMyselfF(userID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getMyself`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getAutoReceipt(UserID, OrderID, ServerID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getAuto`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserID, OrderID, ServerID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function dadingIDListFNC(token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/dadingIDList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getGame(token, todayDate, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getGames`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ todayDate, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function createGame( Date, Platform, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/createGame`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  Date, Platform, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}


function getReportColumnFNC(listOfUsername, startDate, endDate, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getReportColumn`, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ listOfUsername,  startDate, endDate, Username }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function getReportColumnV2FNC(serverPlatform, listOfUsernameArray, startDate, endDate, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getReportColumnV2`, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ serverPlatform, listOfUsernameArray, startDate, endDate, Username }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function updateGame( Date, Platform, selectedID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateGame`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  Date, Platform, selectedID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function displaySpecialUser(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}admin/displaySpecialUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function deleteGame( selectedID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/deleteGame`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getSalesReport( referenceUser, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getSalesReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ referenceUser, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function searchUser( userID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/searchDownline`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getEditedRecords( UserID, Page, CountPerPage, SelectedDate, 
  ServerID, OrderID, BetNumber,DownlineID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getEditRecord`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserID, Page, CountPerPage, SelectedDate,
        ServerID, OrderID, BetNumber,DownlineID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function searchDownlineHR( searchKeyword, Role, userID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/searchDownlineHR`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ searchKeyword, Role, userID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function ActionUserRecordUserListF(searchKeyword, Role, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/ActionUserRecordUserList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({searchKeyword, Role, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function createUser( userObject, multiNumber, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/createUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userObject, multiNumber, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function DeleteUsers( DeletedUserID, Password, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/DeleteUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ DeletedUserID, Password, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function PostRecallBet(ServerID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/RecallCancelTicket`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ServerID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function get4DReseult(Date, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}game/showGameResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Date, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

// function searchUsersDownlineHR(targetedUserID, token, Username) {
//   return new Promise((resolve) => {
//     fetch(`${base}main/searchUsersDownlineHR`, {
//       method: 'post',
//       headers: {
//         Authorization: token,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ targetedUserID, Username }),
//     }).then((res) => {
//       massageRes(res, resolve)
//     });
//   });
// }

function getAgentUser(userID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getAgentUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getReports(fromDate, toDate, referenceUser,platform, redMode, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate, referenceUser,platform, redMode, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}


function createVIPMin( selectedUserID, Mode, modeValue, AdminID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}vipMin/createVIPMin`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedUserID, Mode, modeValue, AdminID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}


function getVIPMinimum(token, Username, Type) {
  return new Promise((resolve) => {
    fetch(`${base}vipMin/getVIPMinimum`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Type }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function deleteVIPMin( selectedID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}vipMin/deleteVIPMin`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getUserKeyIn(targetedUserID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getUserKeyIn`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ targetedUserID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function executeProgress(Username, token) {
  return new Promise((resolve) => {
    fetch(`https://moguserver.ace4dv2.live/main/progressBar`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateVIPMin( objectToUpdate, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}vipMin/updateVIPMin`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ objectToUpdate, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getallAdmin( UserID, page, counterpage, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}admin/getAllAdmin`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserID, page, counterpage, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function superAdminSearch( searchKeyword, UserID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}admin/superAdminSearch`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ searchKeyword, UserID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function SearchAdminAll( searchKeyword, UserID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}admin/searchAdmin`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ searchKeyword, UserID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function AdminUpdateAutho( userID, userAuthority, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}admin/adminUpdateAuthority`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, userAuthority, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function DemoGame( Date, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getResultV2`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Date, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function convertExportObjFNC( selectedID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/convertExportObj`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getLastDadingObj(Username, Platform, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}main/getLastDadingObj`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Platform }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getLastDadingTime(Username, Date, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getLastDadingTime`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Date }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateDadingConfiguration(Username, Keyin1, Keyin2, Limit, token, ClearTime) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateDadingConfiguration`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Keyin1, Keyin2, Limit, ClearTime }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getEditOrder( Username, orderID, token, dateType) {
  return new Promise((resolve) => {
    fetch(`${base}main/getEditOrder`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, orderID, dateType }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function PostEditOrder( Username, orderID, editWordList, cancelDate, token, dateType) {
  return new Promise((resolve) => {
    fetch(`${base}main/postEditOrder`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, orderID, editWordList, cancelDate, dateType }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function firstReportFNC(fromDate, toDate, selectedParentID, selectedChildID, viewType, isSub, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}report/firstReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate, selectedParentID, selectedChildID, viewType, isSub, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function UnlockUsers(lockedUserID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}admin/unlockUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ lockedUserID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getTodayGame( minDate, maxDate, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}game/getTodayGame`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ minDate, maxDate, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateGameResult(  resultObject ,token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateGameResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  resultObject, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function secondReportUserListFNC(Username, selectedUserID, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/secondReportUserList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, selectedUserID }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getGameResult(idOfGames, game, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}game/getGameResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  idOfGames, game, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getResultV2(  Date, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/getResultV2`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  Date, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getrestoreDemoOrder(OrderID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/restoreDemoOrder`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ OrderID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function wordsReport(fromDate, toDate,referenceUserID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}report/wordsReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  fromDate,toDate, referenceUserID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getConfiguration(token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/getConfiguration`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function showSGDHistoryFNC(formDate, toDate, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/showSGDHistory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ formDate, toDate, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateOpenClose(openOrClose, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/updateShutdown`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ openOrClose, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateSingleShutdownFNC(selectedPlatform, isShutdown, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/updateSingleShutdown`, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ selectedPlatform, isShutdown, Username }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function UpdateConfiguration(limitObject, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/updateConfiguration`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ limitObject, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateGameStatus(  gameID, status, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}game/updateGameStatus`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  gameID, status, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function calculateReport(Date, Platform, Username, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}main/calculateReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  Date, Platform,Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getSearchReport( fromDate, toDate, SearchUser, redMode, platform, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/SearchReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate, SearchUser, redMode, platform, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function reportDetail( fromDate, toDate, referenceUser, type, redMode, Username, token, Platform) {
  return new Promise((resolve) => {
    fetch(`${base}report/reportDetail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate, toDate, referenceUser, type, redMode, Username, Platform }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function createOcPackage( Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}HR/createOcPackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getPackageBackupF(Role, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getPackageBackup`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Role, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updatePackage( packageObject, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}HR/savePackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ packageObject, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function searchPackage( targetUserID,searchObject, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}HR/searchPackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ targetUserID,searchObject, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function strikeReport( fromDate,toDate,referenceUser,redMode,platform, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}strike/strikeReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fromDate,toDate,referenceUser,redMode,platform, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function ReturnReceiptOnly(OrderID, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/ReturnReceiptOnly`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ OrderID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function addActingUser( actingUserID,actingUsername,actingPassword,actingAuthority, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}actingUser/addActingUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ actingUserID,actingUsername,actingPassword,actingAuthority, Username}),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function accountReport(selectedDate, viewAs, searchKeyword, viewType, Username, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}report/accountReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selectedDate, viewAs, searchKeyword, viewType, Username
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function accountReportDetail(selectedDate, viewAs, Username, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}report/accountReportDetail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selectedDate, viewAs, Username
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function FindNumbers(userID, number, game, type, includeBai, toDate, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/findNumber`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, number, game, type, includeBai, toDate, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function multipleSearchTextFNC(multipleSearchText, SelectedDate, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/multipleSearchText`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({multipleSearchText, SelectedDate, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function actingAcctList( userID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}actingUser/actingAcctList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateActAcctDetail( actingAccountID, actingPassword, actingAuthority, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}actingUser/updateActAcctDetail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ actingAccountID, actingPassword, actingAuthority, Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function deleteActingAct( actingAccountID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}actingUser/deleteActingAct`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ actingAccountID,  Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}


function updateShutdown( shutdownTrueFalse, sShutdownTrueFalse,eightShutdownTrueFalse, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/updateShutdown`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ shutdownTrueFalse, sShutdownTrueFalse, eightShutdownTrueFalse, Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateAllresult( idOfGames, gameDetails, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}game/updateAll`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ idOfGames, gameDetails,  Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function autoUpdate( objToUpdate, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}game/autoUpdate`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ objToUpdate,  Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getCurrentResult( game, gameID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}game/getCurrentResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ game, gameID, Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getAllSales( referenceUser,filterAmount, maxAmount, inbetweenType, filterType, filterMaxLine, Username, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}report/getAllSales`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ referenceUser,filterAmount, maxAmount, inbetweenType, filterType, filterMaxLine, Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function todaySalesReportFNC(selectedDate, mode, referenceID, filterAmount, filterType,  filterMaxLine, Username, includeLongTerm, token) {
  return new Promise((resolve) => {
    fetch(`${base}report/todaySalesReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedDate, mode, referenceID, filterAmount, filterType,  filterMaxLine, Username, includeLongTerm }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getAllSalesFight( referenceUser,filterAmount, filterType,  filterMaxLine, Username, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}report/getAllSalesFight`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ referenceUser,filterAmount, filterType,  filterMaxLine, Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getprocessLongTermOrder(Password, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}autoUpdate/processLongTermOrder`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Password,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getdeleteLongTermOrder(Password, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}autoUpdate/deleteLongTermOrder`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Password,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateConfigurationLimit( limitObject, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/updateConfigurationLimit`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ limitObject, Username, }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function reloadEatWord(   userID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}HR/reloadEatWord`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  userID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getBackupWords(Username, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}main/getBackupWords`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getBackupJackpot(Username, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}main/getBackupJackpot`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getBackupFightingF(Username, SelectedDate, token) {
  return new Promise((resolve) => {
    fetch(`${calculateBase}main/getBackupFighting`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username, SelectedDate,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function chgPwd(userID, currentPwd, newPwd ,Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/chgPwd`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userID,
        currentPwd,
        newPwd,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getsecondReport(fromDate, toDate, agent, Username, viewType, token) {
  return new Promise((resolve) => {
    fetch(`${base}report/secondReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({fromDate, toDate, agent, Username, viewType}),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function activeMultiplePackage(packageList, userID ,Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}HR/activeMultiplePackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        packageList,
        userID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getInformation(Mode ,Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getInformation`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Mode,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateInformation(infoID, infoMsg, infoImgLink, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}admin/updateInformation`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        infoID,
        infoMsg,
        infoImgLink,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function deleteInformation(infoID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}admin/deleteInformation`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        infoID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function insertInformation(infoImgLink, infoMsg, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}admin/insertInformation`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        infoMsg,
        infoImgLink,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function setupPackage(packageID, packageSetUpObject, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}HR/setupPackage`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        packageID,
        packageSetUpObject,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function UserActionRecord(FromDate, ToDate, SelectedStatus, 
  SelectedPage, page, CountPerPage,
  userID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/UserActionRecord`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        FromDate,
        ToDate,
        SelectedStatus,
        SelectedPage,
        page,
        CountPerPage,
        userID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function UserIDList( userID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/UserIDList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function AddWater(Satus ,Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/AddWater`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Satus,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function jackpotSetting(userJackpotObject ,Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}HR/jackpotSetting`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userJackpotObject,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function Virtual(Status ,Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/Virtual`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Status,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getSMSReport(selectedDate, viewAs, searchKeyword, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}report/SMSReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selectedDate,
        viewAs,
        searchKeyword,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getSMSReportDetail(selectedDate, viewAs, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}report/SMSReportDetail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selectedDate,
        viewAs,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}
function validateReport(Username, token, selectedDate, platform) {
  return new Promise((resolve) => {
    fetch(`${base}configuration/validateReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        selectedDate,
        platform,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function exportYongReport(fromDate, toDate, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/exportYongReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fromDate, 
        toDate, 
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function updateXingJinFormula (xingJinFormula ,userID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateXingJinFormula `, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        xingJinFormula,
        userID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getUserBan(selectedUserID,Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getUserBan `, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Username,
        selectedUserID,
      }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function updateUserBan(selectedUserID, banWordArray,  daObject, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateUserBan `, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        banWordArray,
        daObject,
        Username,
        selectedUserID,
      }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

// textCallApi

function TEMP(dwjahdkawd ,FD ,Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/TEMP `, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dwjahdkawd,
        FD,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve);
    });
  });
}

function forceUpdateResult(resultObject, game, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}game/forceUpdateResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ resultObject, game, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getDataByLink(link) {
  return new Promise((resolve) => {
    try {
      fetch(link, {
        method: 'GET',
        // headers: {
        //   "Access-Control-Allow-Headers": "Authorization",
        //   "Access-Control-Allow-Origin": "*",
        //   "Content-Type": "application/json",
        // },
      }).then((res) => {
        massageRes(res, resolve);
      });
    }catch(rejected) {
      console.log(rejected);
    };
  });
}

function updateFirebaseResult(game, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}game/updateFirebaseResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ game, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function geti82Data(Username, token) {
  return new Promise((resolve) => {
    fetch(`http://57.128.43.245/game/geti82Data`, {
    // fetch(`http://localhost:8003/game/geti82Data`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, hiddenAlgorithm: 'l9MT18T6EGF3cfrA' }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getTimeJSON(Username, token, game) {
  return new Promise((resolve) => {
    fetch(`${base}game/getTimeJSON`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, game }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function pauseOpenResult(Username, token, game) {
  return new Promise((resolve) => {
    fetch(`${base}game/pauseUpdateFirebaseResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, game }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function resumeOpenResult(Username, token, game, resumeTime) {
  return new Promise((resolve) => {
    fetch(`${base}game/resumeUpdateFirebaseResult`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, game, resumeTime }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getHistoryNumber(Username, token, wordArray) {
  return new Promise((resolve) => {
    fetch(`${base}game/getHistoryNumber`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, wordArray }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}


export { 
  updateUserBan,
  removeBanNumber,
  verifyUsername,
  addRedNumber,
  getDataByDate,
  createUserEzFNC,
  getServerTimeFNC,
  createUserEzPostFNC,
  updateUserMobile,
  getSMSGroupFNC,
  postSMSGroupFNC,
  uploadbetDetails,
  getLogin,
  getCapchaCode,
  getPublicKey,
  getRedNumber,
  getKeyInFormat,
  getUsers,
  getUsersHR,
  getPackageNames,
  firstReportFNC,
  getOrderOriginalText,
  getPackageDetails,
  getsecondReport,
  homeDisplayPackage,
  dingConverter,
  dingConverterCSV,
  getDadingObjWithID,
  dadingReport,
  getLaiReportF,
  getJackpostList,
  homeSelectedPackageDetail,
  updateUser,
  // updateUpperline,
  updateUserAll,
  getSGDRateFNC,
  getTHAIRateFNC,
  updateSGDRateFNC,
  BetNumberDetails,
  searchBanNumber,
  getAllUserID,
  getrestoreDemoOrder,
  activePackage,
  deactivatePackage,
  updateRNPackage,
  editAdminPackage,
  getSelectedRedNumberDetail,
  updateRedNumber,
  updateRedNumberAll,
  ReturnReceiptOnly,
  CreateBetOrder,
  getBetReceipt,
  getMyDownline,
  getMyselfF,
  getAutoReceipt,
  dadingIDListFNC,
  CancelBet,
  getGame,
  createGame,
  getReportColumnFNC,
  getReportColumnV2FNC,
  updateGame,
  deleteGame,
  getSalesReport,
  searchUser,
  getEditedRecords,
  searchDownlineHR,
  ActionUserRecordUserListF,
  createUser,
  DeleteUsers,
  PostRecallBet,
  get4DReseult,
  // searchUsersDownlineHR,
  getAgentUser,
  getReports,
  accountReport,
  accountReportDetail,
  createVIPMin,
  getVIPMinimum,
  updateVIPMin,
  deleteVIPMin,
  getUserKeyIn,
  executeProgress,
  getallAdmin,
  UnlockUsers,
  SearchAdminAll,
  todaySalesReportFNC,
  getEditOrder,
  PostEditOrder,
  AdminUpdateAutho,
  FindNumbers,
  multipleSearchTextFNC,
  DemoGame,
  convertExportObjFNC,
  getLastDadingObj,
  getLastDadingTime,
  updateDadingConfiguration,
  displaySpecialUser,
  getTodayGame,
  updateGameResult,
  getGameResult,
  getResultV2,
  wordsReport,
  getConfiguration,
  showSGDHistoryFNC,
  updateOpenClose,
  updateSingleShutdownFNC,
  UpdateConfiguration,
  updateGameStatus,
  secondReportUserListFNC,
  calculateReport,
  getSearchReport,
  reportDetail,
  createOcPackage,
  getPackageBackupF,
  updatePackage,
  searchPackage,
  strikeReport,
  addActingUser,
  getprocessLongTermOrder,
  getdeleteLongTermOrder,
  actingAcctList,
  updateActAcctDetail,
  deleteActingAct,
  updateShutdown,
  updateAllresult,
  autoUpdate,
  getCurrentResult,
  getAllSales,
  getAllSalesFight,
  reloadEatWord,
  updateConfigurationLimit,
  getBackupWords,
  getBackupFightingF,
  chgPwd,
  activeMultiplePackage,
  getInformation,
  exportYongReport,
  updateInformation,
  deleteInformation,
  insertInformation,
  setupPackage,
  UserActionRecord,
  UserIDList,
  AddWater,
  jackpotSetting,
  Virtual,
  getSMSReport,
  getSMSReportDetail,
  verifyUserOrderIDStatus,
  cashWalletHistory,
  validateReport,
  updateXingJinFormula,
  searchJackpotListFNC,
  getBackupJackpot,
  getUserBan,
  TEMP,
  superAdminSearch,
  forceUpdateResult,
  getDataByLink,
  updateFirebaseResult,
  geti82Data,
  getTimeJSON,
  pauseOpenResult,
  resumeOpenResult,
  getHistoryNumber,
};
