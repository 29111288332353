import '../App.css';
import React from 'react';
// import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Header from '../Component/Header';
// import { isMobile } from 'react-device-detect';
import {  Alert } from 'react-bootstrap';
// import Modal from 'react-bootstrap/Modal';
import { saveAs } from "file-saver";
import { TEMP } from '../Api';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import { language } from '../language';
import Loading3 from '../Images/loading-3.gif';
var timer

class DadingConvert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      FileName: '',
      FileResultWord: '',
      FinalResult: [],
      hightlight: false,
      disabled: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      userID: this.props.userID,
      loading: false,
      ShowReceipt: false,
      ReceiptDisplay: '',
      AfterConvert: '',
      // getKey1: ["B", "S", "A1", "CA1", "CC", "AC"],
      // getKey2: ["A1", "A2", "A3", "AC", "AD", "AE"],
      // Key1Options: ['B', 'S', 'CC', 'CA1', 'A1', 'AC', 'A2', 'A3', 'AD', 'AE'],
      demodata: {
        "parsedBody": [
          {
            "mg": true,
            "kd": false,
            "tt": false,
            "sg": false,
            "bb": false,
            "stc": false,
            "sw": false,
            "num": "1234",
            "big": 100,
            "small": 100,
            "c": 0,
            "a": 0,
            "a1": 0
          },
          {
            "mg": true,
            "kd": false,
            "tt": false,
            "sg": false,
            "bb": false,
            "stc": false,
            "sw": false,
            "num": "1234",
            "big": 0,
            "small": 0,
            "c": 0,
            "a": 0,
            "a1": 100
          },
          {
            "mg": false,
            "kd": true,
            "tt": false,
            "sg": false,
            "bb": false,
            "stc": false,
            "sw": false,
            "num": "1234",
            "big": 100,
            "small": 100,
            "c": 0,
            "a": 0,
            "a1": 0
          },
          {
            "mg": false,
            "kd": true,
            "tt": false,
            "sg": false,
            "bb": false,
            "stc": false,
            "sw": false,
            "num": "1234",
            "big": 0,
            "small": 0,
            "c": 0,
            "a": 0,
            "a1": 100
          },
          {
            "mg": false,
            "kd": false,
            "tt": true,
            "sg": false,
            "bb": false,
            "stc": false,
            "sw": false,
            "num": "1234",
            "big": 100,
            "small": 100,
            "c": 0,
            "a": 0,
            "a1": 0
          },
          {
            "mg": false,
            "kd": false,
            "tt": true,
            "sg": false,
            "bb": false,
            "stc": false,
            "sw": false,
            "num": "1234",
            "big": 0,
            "small": 0,
            "c": 0,
            "a": 0,
            "a1": 100
          }
        ]
      },
      getKey1: ["B", "S", "SA", "CA", "CF", "SF"],
      getKey2: ["SA", "SB", "SC", "SF", "SD", "SE"],
      Key1Options: ['B', 'S', 'CA', 'CF', 'SA', 'SB', 'SC', 'SD', "SE", "SF"],
      MultiplyDivide: 'M',
      JinNan: false,
      DisplayTotalAmount: '',
      background: '#3C96F4',
      base64URL: '',
    //   DisplayConfirmModal: false,
    }
    this.UploadFiletxt = this.UploadFiletxt.bind(this);
    this.CleanData = this.CleanData.bind(this);
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.GenerateAPIPHP = this.GenerateAPIPHP.bind(this);
    this.CopyText= this.CopyText.bind(this);
  }



  async componentDidMount(){
  
    // const receiveticket = this.state.demodata['parsedBody'];
    // const Gamekey = ["mg", "kd", "tt", "sg",]
    // const GameBet = ["num","big", "small", "c", "a", "a1"]

    // let storeword = {
    //   first: { value: 'D#' }, 
    // };

    // receiveticket.forEach((item, index) => {
    //   Gamekey.forEach((game) => {
    //     GameBet.forEach((gamebet) => {
    //       if(item[game] === true) {
    //         if(storeword[game] === undefined) {
    //           console.log('index', index)
    //           let store = (gamebet === "num") ? `${receiveticket[index][gamebet]}` : `#${receiveticket[index][gamebet]}`
    //           storeword = {
    //             ...storeword,
    //             [game]: {
    //               [index]: store
    //             },
    //           };
    //         } else if (storeword[game][index] === undefined) {
    //           console.log('index2', index, gamebet)
    //           let store =  (gamebet === "num") ?  (storeword[game][index] === undefined ? '' : `${storeword[game][index]}`) : (storeword[game][index] === undefined ? '' :`#${storeword[game][index]}`)
          
    //           store += (gamebet === "num") ? `${item[gamebet]}` :`#${item[gamebet]}`;
    //           storeword[game] = {
    //             ...storeword[game],
    //             [index]: store
    //           };
    //         } 
    //         else {
    //           let store = storeword[game][index]
    //           store += (gamebet === "num") ? `${item[gamebet]}` : `#${item[gamebet]}`;
    //           storeword[game][index] = store
    //         }
    //       }
    //     })
    //   })
    // })

    // const KeyGame = Object.keys(storeword)
    // const KeyValue = Object.values(storeword)
    // console.log('tel',  storeword)
    // let tickerresultconvert = ''

    // for(var i = 0; i < KeyGame.length; i++) {
    //   const game = KeyGame[i] !== 'first' ? KeyGame[i] === 'mg' ? '1' : KeyGame[i] === 'kd' ? '2' : KeyGame[i] === 'tt' ? '3' : '4' : '';
    //   // if(KeyValue[i].value) {
    //   //   tickerresultconvert += `${KeyValue[i].value}\n\n`
    //   // }

    //   const value = Object.values(storeword[KeyGame[i]])
    //   // console.log(value)
    //   for(var k = 0; k < value.length; k++) {
    //     // console.log(value[k], [KeyGame[i]])
    //     if(storeword[KeyGame[i]] === 'first') {
    //       tickerresultconvert += `${KeyValue[i].value}\n`
    //     } else {
    //       if(tickerresultconvert.includes(`${game}\n`)) {
    //         tickerresultconvert += `${value[k]}\n`
    //       } else {
    //         tickerresultconvert += `${game }\n${value[k]}\n`
    //       }
    //     }
    //   }
    // }
    // console.log('tickerresultconvert', tickerresultconvert)
    // this.setState({ AfterConvert: tickerresultconvert})
    // KeyGame.forEach((item) => {

    // })



    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if ((UserAuthorities && UserAuthorities.Bet) === 0) {
      window.location.href='/Home'; 
    }
  }

  async UploadFiletxt(event) {
    if (event !== undefined) {
      const files = event.target.files;
      event.preventDefault()
      this.getBase64(files[0])
        .then(result => {
        // img["base64"] = result;
          this.setState({
            base64URL: result,
          });
        })
      
      const reader = new FileReader()
      // reader.onload = async (e) => {
      //   const text = (e.target.result)
      //   this.setState({ FileResultWord: text })
      // };
      if (event.target.files) {
        reader.readAsText(event.target.files[0])
        this.setState({ FileName: files[0].name })
      } 
    }
  }

 
  async GenerateAPIPHP() {
    this.setState({generateLoading: true})
    const filephp = this.state.base64URL;
    const token = 'awd5135a1864684rvdwa1';
    if(filephp.length !== 0 ) {
      const result = await TEMP(token, filephp, this.props.username, this.props.token);
      // console.log('result', result);
      if(result) {
        const receiveticket = result['parsedBody'];
        const Gamekey = ["mg", "kd", "tt", "sg",]
        const GameBet = ["num","big", "small", "c", "a", "a1"]

        let storeword = {
          first: { value: 'D#' }, 
        };
    
        receiveticket.forEach((item, index) => {
          Gamekey.forEach((game) => {
            GameBet.forEach((gamebet) => {
              if(item[game] === true) {
                if(storeword[game] === undefined) {
                  // console.log('index', index)
                  let store = (gamebet === "num") ? `${receiveticket[index][gamebet]}` : `#${receiveticket[index][gamebet]}`
                  storeword = {
                    ...storeword,
                    [game]: {
                      [index]: store
                    },
                  };
                } else if (storeword[game][index] === undefined) {
                  // console.log('index2', index, gamebet)
                  let store =  (gamebet === "num") ?  (storeword[game][index] === undefined ? '' : `${storeword[game][index]}`) : (storeword[game][index] === undefined ? '' :`#${storeword[game][index]}`)
              
                  store += (gamebet === "num") ? `${item[gamebet]}` :`#${item[gamebet]}`;
                  storeword[game] = {
                    ...storeword[game],
                    [index]: store
                  };
                } 
                else {
                  let store = storeword[game][index]
                  store += (gamebet === "num") ? `${item[gamebet]}` : `#${item[gamebet]}`;
                  storeword[game][index] = store
                }
              }
            })
          })
        })
    
        
        const KeyGame = Object.keys(storeword)
        const KeyValue = Object.values(storeword)
     
        let tickerresultconvert = ''
    
        for(var i = 0; i < KeyGame.length; i++) {
          const game = KeyGame[i] !== 'first' ? KeyGame[i] === 'mg' ? '1' : KeyGame[i] === 'kd' ? '2' : KeyGame[i] === 'tt' ? '3' : '4' : '';
    
          const value = Object.values(storeword[KeyGame[i]])

          for(var k = 0; k < value.length; k++) {
            if(storeword[KeyGame[i]] === 'first') {
              tickerresultconvert += `${KeyValue[i].value}\n`
            } else {
              if(tickerresultconvert.includes(`${game}\n`)) {
                tickerresultconvert += `${value[k]}\n`
              } else {
                tickerresultconvert += `${game }\n${value[k]}\n`
              }
            }
          }
        }
        this.setState({ AfterConvert: tickerresultconvert})
      }
    }  else {
      this.setState({ notificationType: 'error',generateLoading: false }, ()=>{
        this.openNotification('nofile')
      })
    }
  }


  async CleanData() {
    this.setState({ FileName: '', base64URL: '', AfterConvert: '',}, () => {
      navigator.clipboard.writeText('')
      // this.UploadFiletxt()
    })
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.state.disabled) return;
  
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    if (event !== undefined) {
      const files = event.dataTransfer.files;
      event.preventDefault()
      const reader = new FileReader()
      reader.onload = async (e) => {
        const text = (e.target.result)
        this.setState({ FileResultWord: text })
      };

      this.getBase64(files[0])
        .then(result => {
          // img["base64"] = result;
          this.setState({
            base64URL: result,
          });
        })

      if (event.dataTransfer.files) {
        reader.readAsText(event.dataTransfer.files[0])
        // this.setState({ filesphp: files})
        this.setState({ FileName: files[0].name,hightlight: false })
      }
    }
  }
  
  openFileDialog() {
    if (this.state.disabled) return
    this.fileInputRef.current.click()
  }

  openNotification(message) {
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        disabled: false,
      });
      // this.CleanData()
    }, 5000);
  };

  ChangeReceipt(event) {
    const PressNumber = event.target.value.replace(/[+]/g, '#')
    this.setState({ FileResultWord: PressNumber })
  }

  handleChangeKey(e, Keys, index){
    let getKey1 = this.state.getKey1
    let getKey2 = this.state.getKey2
    if(Keys === 'Key1') {
      getKey1[index] = e.target.value.replace(/[^1-4BSCADE ]/g, "")
    } else {
      getKey2[index] = e.target.value.replace(/[^1-44BSCADE ]/g, "")
    }
    this.setState({ getKey1, getKey2 })
  }

  async exportDading() {
    const AfterConvert = this.state.AfterConvert
    if (AfterConvert === '') {
      this.openNotification('NoDadingData')
      this.setState({ notificationType: 'error' });
    } else {
      var blob = new Blob([AfterConvert], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `Convert Dading ${Moment(new Date()).format('HH:mm:ss').replace('_', ':')}.txt`);
    }
  }

  ChangeFunctions(event, Mode) {
    this.setState({ [Mode]: event.target.value})
  }

  getBase64 = file => {
    return new Promise(resolve => {
      // let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  async CopyText() {
    if(this.state.AfterConvert.length !== 0 ){
      this.setState({ notificationType: 'success' }, ()=>{
        navigator.clipboard.writeText(this.state.AfterConvert)
        this.openNotification('SuccessCopied')
      });
    } else {
      this.setState({ notificationType: 'error' }, ()=>{
        this.openNotification('fileempty')
      });
    }
  }

  render() {
    return (
      <div className='StartBackground'>
        <Header />
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="row" style={{ display: 'flex', marginLeft: 50, marginRight: 50, fontSize: '18px' }}>
          <div className="dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>{'101 转 D#'}</div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
            width: '95%',
            marginBottom: 0,
          }}>
          <div className="TitleDate">{language[this.props.currentLanguage].Date}: {this.state.TodayDate}</div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.state.username} </div>
          </div>
        </div>
        <div className="dcc" style={{ flexDirection: 'row' }}>
          {this.state.loading ? (
            <div style={{ flexDirection: 'column', width: '100%' }}>
              <div>
                <img src={Loading3} alt="Logo" className="loadingimages" style={{ marginBottom: 30, marginTop: 50 }} />
                <div className="loadingMessage">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
              </div>
            </div>
          ) : (
            <div className='row' style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ backgroundColor: '#EFEFEF', border: `1px solid #d8d8d8`, width:'50%', marginBottom: 50 }}>
                {/* <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, marginTop: 10, overflowX: 'auto' }}>
                  <div className={'dcc'} style={{ flexDirection: 'row', width: '', paddingTop: 0, textAlign: 'left'}}>
                    <div style={{ fontWeight: 'bold', width:  '10%' }}>Key1 : </div>
                    <div style={{ display: 'flex', width: '80vh', textAlign: 'left' }}>
                      {this.state.getKey1 && this.state.getKey1.map((keys1, keysindex) => {
                        return(
                          <select className="form-control" key={keysindex}
                            style={{ textAlign: 'center', fontSize: '18px', width: '80%', fontWeight: 'bold' }}
                            onChange={(e) => this.handleChangeKey(e, 'Key1', keysindex)}>
                            <option value={keys1}>{`${keys1}`}</option>
                            {(this.state.Key1Options || []).map((item, idx) => {
                              return (
                                <option key={idx} value={item}>{`${item}`}</option>
                              );
                            })}
                          </select>
                        );
                      })}
                    </div>
                  </div>
                  <div className={'dcc'} style={{ flexDirection:  'row', width: 'auto', paddingTop:  0, textAlign: 'left' }}>
                    <div style={{ fontWeight: 'bold', width: '10%' }}>Key2 : </div>
                    <div style={{ display: 'flex', width: '80vh' }}>
                      {this.state.getKey2 && this.state.getKey2.map((keys2, keys2index) => {
                        return(
                          <select className="form-control" key={keys2index}
                            style={{ textAlign: 'center', fontSize: '18px', width: '80%', fontWeight: 'bold' }}
                            onChange={(e) => this.handleChangeKey(e, 'Key2', keys2index)}>
                            <option value={keys2}>{`${keys2}`}</option>
                            {(this.state.Key1Options || []).map((item, idx) => {
                              return (
                                <option key={idx} value={item}>{`${item}`}</option>
                              );
                            })}
                          </select>
                        );
                      })}
                    </div>
                  </div>
                </div> */}
                {/* <div className='dcc' style={{ flexDirection: 'row', width: '100%', textAlign: 'center'}}>
                  <div style={{ display: 'flex', flexDirection:'row', width: '50%' }}>
                    <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                      <div style={{ fontWeight: 'bold', width: 'auto' }}>{`${language[this.props.currentLanguage].MD} : `}</div>
                      <select className="form-control"
                        style={{ textAlign: 'center', fontSize: '18px', width: 'auto', fontWeight: 'bold' }}
                        onChange={(e) => this.ChangeFunctions(e, 'MultiplyDivide')}>
                        <option value={'M'}>{'M'}</option>
                        <option value={'D'}>{'D'}</option>
                      </select>
                    </div>
                    <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                      <div style={{ fontWeight: 'bold', width: 'auto' }}>{'JinNan : '}</div>
                      <select className="form-control"
                        style={{ textAlign: 'center', fontSize: '18px', width: 'auto', fontWeight: 'bold' }}
                        onChange={(e) => this.ChangeFunctions(e, 'JinNan')}>
                        <option value={false}>{'false'}</option>
                        <option value={true}>{'true'}</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                {/* <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 15 }}>
                  <textarea
                    className="form-control"
                    style={{ textAlign: 'center', width: '95%', height: 350, fontWeight: 'bold' }}
                    value={this.state.FileResultWord}
                    readOnly={false}
                    onChange={(e) => this.ChangeReceipt(e)}
                  />
                </div> */}
                <div 
                  // type='file'
                  className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
                  onDragOver={this.onDragOver}
                  onDragLeave={this.onDragLeave}
                  onDrop={this.onDrop}
                  onClick={this.openFileDialog}
                  style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                >
                  <span>Upload Files</span>
                </div>
                <input
                  key={this.state.FileName}
                  onChange={(event) => {
                    this.UploadFiletxt(event)
                  }}
                  multiple={false}
                  ref={this.fileInputRef}
                  type='file'
                  hidden
                />
                <div style={{ marginBottom: 20, fontWeight: 'bold' }}>{language[this.props.currentLanguage].FileName} : {this.state.FileName || ''}</div>
                <div style={{ width: '100%', paddingBottom: 40 }}>
                  <button style={{ textAlign: 'center', backgroundColor: '#EE3E13', color: 'white', width: 200, fontWeight: 'bold', marginRight: 20 }}
                    disabled={this.state.disabled}
                    onClick={() => this.CleanData()}
                    className="btn my-cusomized-button button2">
                    {language[this.props.currentLanguage].ClearAll}
                  </button>
                  <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold' }}
                    disabled={this.state.disabled}
                    onClick={() => this.GenerateAPIPHP()}
                    className="btn my-cusomized-button button2">
                    {language[this.props.currentLanguage].ConfirmTicket}
                  </button>
                </div>
              </div>
              <div style={{ fontSize: '28px', fontWeight: 'bold', paddingLeft: 10, paddingRight: 10 }}>⇋</div>
              <div style={{ width: '40%' }}>
                {/* <div style={{ width: '100%', overflowX: 'auto'}}>
                  <Table responsive="sm" bordered hover>
                    <thead style={{ backgroundColor: 'lightsteelblue' }}>
                      <tr>
                        {['all', 'B', 'S', 'CF', 'CA', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D'].map((titleitems, idx) => {
                          return(
                            <th key={idx} style={{ width: 'auto' }}>{titleitems}</th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {['all', 'B', 'S', 'CF', 'CA', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', '5D', '6D'].map((items, index) => {
                          return(
                            <td key={index} style={{ width: 'auto' }}>{(this.state.DisplayTotalAmount === '') ? '0.00' : Number(this.state.DisplayTotalAmount[items]).toFixed(2)}</td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </Table>
                </div> */}
                <div style={{ backgroundColor: 'white', border: `1px solid #d8d8d8`, width: '100%', height: 550, whiteSpace: 'pre-wrap', padding: 10, fontWeight: 'bold', overflowX: 'auto', textAlign: 'left' }}>
                  {this.state.AfterConvert}
                </div>
                <div style={{ width: '100%', marginBottom: 50 }}>
                  <div className="dcc button"
                    style={{ backgroundColor: this.state.background, fontSize: 18,
                      borderBottom: '1px solid #cfd9de', verticalAlign: 'middle', fontWeight: 'bold', padding: 13, cursor: 'pointer', borderRadius: 5 }} 
                    onMouseEnter={() => {
                      this.setState({ background: '#00AFE2' });
                    }}
                    onMouseLeave={() => {
                      this.setState({ background: '#3C96F4' });
                    }}
                    variant="success" onClick={() => this.CopyText()}>
                    <span>
                      {/* {language[this.props.currentLanguage].DownloadDading} */}
                      {language[this.props.currentLanguage].copy}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <Modal
          // style={{ width: '100%'}}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.DisplayConfirmModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: (isMobile) ? null :0, height: 'auto', width: 'auto'}}>
            <div>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top: (isMobile) ? 30 : 15, cursor: 'pointer', backgroundColor: this.state.backgroundclose, borderRadius: 20 }}
                onClick={() => {
                  this.setState({ DisplayConfirmModal: false })
                }}
                onMouseEnter={() => {
                  this.setState({ backgroundclose: 'white' });
                }}
                onMouseLeave={() => {
                  this.setState({ backgroundclose: '#cfd9de' });
                }}
                size="35px"
                color="#000"
              />
              <Form.Group>
                <div style={{ width: '100%', flexDirection: 'column', overflowX: 'auto', marginTop: 25 }}>
                  <div style={{ width: '100%', fontSize: '25px', fontWeight: 'bold', marginBottom: 20 }}>{'Confirm Dading'}</div>
                  <div style={{ width: '100%'}}>
                    <Table responsive="sm" bordered hover>
                      <thead style={{ backgroundColor: 'lightsteelblue' }}>
                        <tr>
                          {['B', 'S', 'C', 'A', 'A1', 'A1C', 'A2', 'A3', 'D4', 'E4', '5D', '6D', 'all'].map((titleitems, idx) => {
                            return(
                              <th key={idx} style={{ width: 'auto' }}>{titleitems}</th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {['B', 'S', 'C', 'A', 'A1', 'A1C', 'A2', 'A3', 'D4', 'E4', '5D', '6D', 'all'].map((items, index) => {
                            return(
                              <td key={index} style={{ width: 'auto' }}>{this.state.DisplayTotalAmount[items] || 0}</td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Form.Group>
            </div>
          </Modal.Body>
        </Modal> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DadingConvert);
