import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import { Button, Form, Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
var timer

class Virtual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MiArray: {},
      FightArrayNumb: [''],
      Array: {
        "Ban4Da": false ,
        "Ban6Da": false ,
        "Ban12Da": false ,
        "Ban24Da": false ,
      },
      notificationType: 'success',
      setTimer: 6000,
      notificationMessage: '',
      showNotification: false,
      SelectedLine: 0,
    }
  }

  componentDidMount(){
    this.setState({
      FightArrayNumb: this.props.FightArrayNumb.length === 0 ? [''] : this.props.FightArrayNumb,
      Array: this.props.BanresultArray,
      SelectedLine:  this.props.FightArrayNumb.length === 0 ? 0 : this.props.FightArrayNumb.length - 1,
    }, ()=>{
      setTimeout(() => {
        const SelectedWord = document.getElementById('BetText')
        const length = SelectedWord.value.length
        SelectedWord.focus()
        SelectedWord.setSelectionRange(length, length)
      }, 20);
    })
  }


  SelectLineFunction(idx) {
    let Line = idx.Line;
    this.setState({
      SelectedLine: Line,
    });
  }
  PressNumberFunction(value , idx) {
    const PressValidation = value.replace(/[^0-9D]/g, '')
    const edit = JSON.parse(JSON.stringify(this.state.FightArrayNumb))
    edit[idx] = PressValidation
    this.setState({ FightArrayNumb: edit})
  }

  NextLineButtonFunction(e, idx, Hint) {
   
    if (e.key === 'Enter' || e.code === 'ArrowDown') {
      let addOneNewLine = this.state.FightArrayNumb;
      if (
        this.state.FightArrayNumb.length <= this.state.SelectedLine + 1 
      ) {
        if(this.state.FightArrayNumb.length >= 100) {
          //null
        } else {
          addOneNewLine.push('');
          this.setState({  SelectedLine: this.state.SelectedLine + 1, FightArrayNumb: addOneNewLine }, ()=>{
            setTimeout(() => {
              const SelectedWord = document.getElementById('BetText')
              const length = SelectedWord.value.length
              SelectedWord.focus()
              SelectedWord.setSelectionRange(length, length)
            }, 20);
          })
        }
      }       
      if (Hint === 'Nextline') {
        if(this.state.FightArrayNumb.length >= 100) {
          this.setState(
            {
              SelectedLine: this.state.FightArrayNumb.length - 1 === this.state.SelectedLine? 
                this.state.SelectedLine : 
                this.state.SelectedLine + 1,
            });
        } else {
          this.setState(
            {
              SelectedLine: this.state.SelectedLine + 1,
            });
        }
      }
    }
    if(e.code === 'ArrowUp') {
      if (this.state.SelectedLine > 0) {
        this.setState({
          SelectedLine: this.state.SelectedLine - 1,
        }, ()=> {
          setTimeout(() => {
            const SelectedWord = document.getElementById('BetText')
            const length = SelectedWord.value.length
            SelectedWord.focus()
            SelectedWord.setSelectionRange(length, length)
          }, 20);
        });
      }
    }
  
  }

  checkboxselffight(value, status) {
    const edit = JSON.parse(JSON.stringify(this.state.Array))
    edit[status] = value;
    this.setState({Array:edit })
  }


  Submit(){
    let stateObjectCopy = JSON.parse(JSON.stringify(this.state.FightArrayNumb)); 
    let stateObject = JSON.parse(JSON.stringify(stateObjectCopy)); 
    let AlertMessage= ''
    let AlertMessageDuplicate = ''
    const ObjectKey = Object.keys(stateObjectCopy)
  
    for(let i = 0; i < ObjectKey.length; i++) {
      if(stateObjectCopy[ObjectKey[i]].length < 4 && stateObjectCopy[ObjectKey[i]].length !== 0 ){
        AlertMessage += `${i + 1}: ${ stateObject[ObjectKey[i]]}, `
      }
    }
 
    for(let i = 0; i < ObjectKey.length; i++) {
      if((stateObjectCopy[ObjectKey[i]] || '') === ''){
        delete stateObject[ObjectKey[i]] 
      }
    }
    stateObject = stateObject.filter( Boolean );

    const uniqueElements = new Set(Object.values(stateObject));

    for(let i = 0; i < Object.keys(stateObject).length; i++) {
      if (uniqueElements.has(stateObject[i])) {
        uniqueElements.delete(stateObject[i]);
      } else {
        AlertMessageDuplicate += `${language[this.props.currentLanguage].DuplicateNumber}${stateObject[i]}, `
      }
    }

    

    if(AlertMessage !== ''){
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(`${language[this.props.currentLanguage].Cannotemptynumber}  ${AlertMessage}`)
      })
    } else if(AlertMessageDuplicate !== ''){
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(`${AlertMessageDuplicate}`)
      })
    } else {
      this.props.propsSetState({ SelfFightModal: false})
      this.props.SelfFightFunction(stateObject, this.state.Array, this.props.formData.ID)
      this.setState({   FightArrayNumb: [''],
        Array: {
          "Ban4Da": false ,
          "Ban6Da": false ,
          "Ban12Da": false ,
          "Ban24Da": false ,
        },})
    }
    
  }


  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        setTimer: 6000,
      });
    }, this.state.setTimer);
  };
  

  render() {
    return (
      <>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.SelfFightModal}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
            <GrFormClose
              style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
              onClick={() => {
                this.setState({  FightArrayNumb: [''],
                  Array: {
                    "Ban4Da": false ,
                    "Ban6Da": false ,
                    "Ban12Da": false ,
                    "Ban24Da": false ,
                  }, })
                this.props.propsSetState({ 
                  SelfFightModal: false,
                })
              }}
              size="35px"
              color="#000"
            />
            <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
              <div className="dcc" style={{ justifyContent: 'center' }}>
                <Alert className="dcc" style={{ zIndex: 99, position: 'fixed',  top: 80, width: '50%', right: ''}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                </Alert>
              </div>
              <b className="dcc" style={{ padding: 20, fontSize: 21}}>
                {language[this.props.currentLanguage].Selffightrednumber}
              </b>
              <b className="dcc" style={{ padding: 20, fontSize: 21}}>
                {language[this.props.currentLanguage].Selffightrednumber2}
              </b>
              <div className="dcc" style={{  marginBottom: '3%'}}>
                <div style={{ display: 'flex', alignItems: 'center', width:'100%', justifyContent:'center', flexDirection: 'column',}}>
                  {/* <div style={{ margin: '0px 10px', width: 70, fontWeight: 'bold'}}/> */}
                  <div style={{ display: 'flex', alignItems: 'center', width:'100%', justifyContent:'center', flexDirection: 'row', marginBottom: 5}}>
                    {Object.keys(this.state.Array).map((itemAlphabet) => {
                      return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}}>
                        <div style={{ padding: 8, border: '1px solid #000', borderRadius: 5,  display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                          <div  style={{fontWeight: 'bold',  margin: '0px 10px', width: 35}}>{(itemAlphabet === 'Ban4Da') ? 
                            language[this.props.currentLanguage].fight4 : (itemAlphabet === 'Ban6Da') ? language[this.props.currentLanguage].fight6 
                              : (itemAlphabet === 'Ban12Da') ? language[this.props.currentLanguage].fight12 : language[this.props.currentLanguage].fight24
                          }</div>
                          <Form.Check
                            style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                            inline
                            type="checkbox"
                            checked={ (this.state.Array[itemAlphabet])}
                            onChange={(e) => this.checkboxselffight(!this.state.Array[itemAlphabet], itemAlphabet)} 
                          />
                        </div>
                      </div>
                    })}
                  </div>
                  <div>
                    {this.state.FightArrayNumb.map((itemNumb, idx) => {
                      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        {this.state.SelectedLine === idx ?
                          <Form.Control style={{ width: 400, textAlign: 'center',  fontWeight: 'bold',  }} 
                            type="text"
                            autoFocus={true}
                            autocomplete="off"
                            id="BetText"
                            key={idx}
                            maxLength={4}
                            value={itemNumb}
                            onClick={() => this.SelectLineFunction({ Line: idx })}
                            onKeyDown={(e) => this.NextLineButtonFunction(e, idx, 'Nextline')}
                            onChange={(e) => this.PressNumberFunction(e.target.value, idx)}
                            onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                          />
                          :
                          <button
                            key={idx}
                            name={`field-${idx}`}
                            type="button"
                            onClick={() => this.SelectLineFunction({ Line: idx })}
                            onKeyDown={(e) => this.NextLineButtonFunction(e, idx, 'Nextline')}
                            onChange={(e) => this.PressNumberFunction(e.target.value, idx)}
                            className="form-control BettingResultButtonCSS"
                          >
                            <div style={{
                              color: 'black',
                              fontSize: '17px', padding: 1.5,
                            }}>
                              {itemNumb}
                            </div>
                          </button>
                        }
                      </div>})}
                  </div>
                </div>
              </div>
              <div className="dcc">
                <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" 
                  onClick={() => {
                    this.setState({  FightArrayNumb: [''],
                      Array: {
                        "Ban4Da": false ,
                        "Ban6Da": false ,
                        "Ban12Da": false ,
                        "Ban24Da": false ,
                      }, })
                    this.props.propsSetState({ SelfFightModal: false})
                  }}>{language[this.props.currentLanguage].Shutdown}</Button>
                <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" 
                  onClick={() => {
                    this.Submit();
                  }}>{language[this.props.currentLanguage].Confirm}</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Virtual);