import '../App.css';
import React from 'react';
import { language } from '../language';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Button, Tab } from 'react-bootstrap';
import Header2 from '../Component/Header';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Table from 'react-bootstrap/Table';
// import Moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
const { updateActAcctDetail, verifyUsername, addActingUser,actingAcctList,deleteActingAct  } = require('../Api');
var timer

class SubAccPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationMessage: '',
      notificationType: 'success',
      showNotification: false,
      AddDisable: false,
      Bet: 0,
      ViewDownlineReport: 0,
      setting: 0,
      DisplayOrder: 0,
      CancelOrRevertOrder: 0,
      EditOrder: 0,
      RebuyOrder: 0,
      SalesReport: 0,
      AcctDisplay: 0,
      EditUser: 0,
      CreateUser: 0,
      DisplayablePackage: 0,
      EditBanNumber: 0,
      SalesControl: 0,
      Report: 0,
      PackageSetting: 0,
      PackageInput: 0,
      PhoneSetting: 0,
      EditItem: [],
      modalShow: false,
      SubUsername: '',
      SubPassowrd: '',
      ModeSubAcc: '',
      AllowSaveUsername: false,
      ActingAccountArray: [],
      SelectAllAuth: false,
      CheckUserNumber: 0,
      SubAccID: '',
    }
    // this.addUpdateF = this.addUpdateF.bind(this);
  }

  async componentDidMount() {
    // const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.CallAPI();
    let Edits = []
    if(this.props.Role === 'agent' || this.props.Role  === 'player') {
      Edits = [
        { Bet: 0 }, { ViewDownlineReport: 0}, { setting: 0},
        { DisplayOrder: 0 },{ RebuyOrder: 0 },
        { SalesReport: 0 },{AcctDisplay: 0},
        { EditUser: 0 },{ CreateUser: 0 },
        { DisplayablePackage: 0 },{ Report: 0 }, { PhoneSetting: 0 },
      ]
    } else {
      Edits = [
        { Bet: 0 }, { ViewDownlineReport: 0}, { setting: 0},
        { DisplayOrder: 0 },{CancelOrRevertOrder: 0},
        { EditOrder: 0 },{ RebuyOrder: 0 },
        { SalesReport: 0 },{AcctDisplay: 0},
        { EditUser: 0 },{ CreateUser: 0 },
        { DisplayablePackage: 0 },{EditBanNumber: 0},
        { SalesControl: 0 }, { Report: 0 },{ PackageSetting: 0 },
        { PackageInput: 0 },{ PhoneSetting: 0 },
      ]
    }
    this.setState({ EditItem: Edits })
  }

  async checkCheckedAll() {
    const usersArray = [ 'Bet',  'ViewDownlineReport',  'setting',
      'DisplayOrder', 'CancelOrRevertOrder', 'EditOrder','RebuyOrder', 'SalesReport', 'AcctDisplay', 'EditUser', 'CreateUser',
      'DisplayablePackage', 'EditBanNumber', 'Report', 'PackageSetting', 'PackageInput', 'PhoneSetting', 'SalesControl',
    ]
    const Halo = []
    for(var c = 0; c < usersArray.length; c++) {
      if(this.state[usersArray[c]]=== 1) {
        Halo.push('yes')
      } else {
        Halo.push('No')
      }
    }
    if(Halo.includes('No')) {
      this.setState({ SelectAllAuth: false})
    } else {
      this.setState({ SelectAllAuth: true})
    }
  }

  async SubmitSubAccDetail(){
    let ArrayResult = [  'Bet',  'ViewDownlineReport',  'setting',
      'DisplayOrder', 'CancelOrRevertOrder', 'EditOrder','RebuyOrder', 'SalesReport', 'AcctDisplay', 'EditUser', 'CreateUser',
      'DisplayablePackage', 'EditBanNumber', 'Report', 'PackageSetting', 'PackageInput', 'PhoneSetting', 'SalesControl',
    ]
    let PushUpdateArray = []

    for(var i = 0; i < ArrayResult.length; i++) {
      if(this.state[ArrayResult[i]] === 1) {
        PushUpdateArray.push(`${ArrayResult[i]}`)
      }
    }

    if (this.state.SubUsername === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationError(language[this.props.currentLanguage].Usernamecannotempty)
      });
    } else if (this.state.SubPassowrd === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationError(language[this.props.currentLanguage].Passwordcannotempty)
      });
    } else {
      if (this.state.ModeSubAcc === 'Edit') {
        const updateActAcctDetailResult = await updateActAcctDetail( this.state.SubAccID,  this.state.SubPassowrd, PushUpdateArray, this.props.username,  this.props.token)
        if (updateActAcctDetailResult.message === 'Update Successful') {
          this.setState({ notificationType: 'success' }, () => {
            this.openNotificationSuccess(language[this.props.currentLanguage].UpdateSuccess)
          });
        } else {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotificationError(language[this.props.currentLanguage].databaseError)
          });
        }
      } else {
        const AddActingUserResult = await addActingUser( this.props.userID, this.state.SubUsername, this.state.SubPassowrd, PushUpdateArray, this.props.username,  this.props.token)
        if (AddActingUserResult.message === 'Successfully Created ActingUser') {
          this.setState({ notificationType: 'success' }, () => {
            this.openNotificationSuccess(language[this.props.currentLanguage].SuccessfullyCreatedActingUser)
          });
        } else {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotificationError(language[this.props.currentLanguage].databaseError)
          });
        }
      }
    }
  }

  toggleResult(SelectedAutho) {
    if (this.state[SelectedAutho] === 1) {
      this.setState({
        [SelectedAutho]: 0,
      }, ()=> {
        this.checkCheckedAll();
      })
    }else {
      this.setState({ [SelectedAutho] : 1})
    }
  }

  async CallAPI(){
    const actingAcctListResult = await actingAcctList(this.props.userID, this.props.username,  this.props.token)
    if (actingAcctListResult.message === 'No Acting Account'){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationError(language[this.props.currentLanguage].NoActingAccount)
      });
    } else {
      this.setState({ ActingAccountArray: actingAcctListResult})
    }
  }

  async ClearModule(){
    let EditItem = []

    // EditItem = [
    //   { Bet: 0}, { ViewDownlineReport: 0}, { setting: 0},
    //   { DisplayOrder: 0 },{CancelOrRevertOrder: 0},
    //   { EditOrder: 0 },{ RebuyOrder: 0 },
    //   { SalesReport: 0 },{AcctDisplay: 0},
    //   { EditUser: 0 },{ CreateUser: 0 },
    //   { DisplayablePackage: 0 },{EditBanNumber: 0},
    //   { SalesControl: 0 }, { Report: 0 },{ PackageSetting: 0 },
    //   { PackageInput: 0 },{ PhoneSetting: 0 },
    // ]

    if(this.props.Role === 'agent' || this.props.Role  === 'player') {
      EditItem = [
        { Bet: 0 }, { ViewDownlineReport: 0}, { setting: 0},
        { DisplayOrder: 0 },{ RebuyOrder: 0 },
        { SalesReport: 0 },{AcctDisplay: 0},
        { EditUser: 0 },{ CreateUser: 0 },
        { DisplayablePackage: 0 },{ Report: 0 }, { PhoneSetting: 0 },
      ]
    } else {
      EditItem = [
        { Bet: 0 }, { ViewDownlineReport: 0}, { setting: 0},
        { DisplayOrder: 0 },{CancelOrRevertOrder: 0},
        { EditOrder: 0 },{ RebuyOrder: 0 },
        { SalesReport: 0 },{AcctDisplay: 0},
        { EditUser: 0 },{ CreateUser: 0 },
        { DisplayablePackage: 0 },{EditBanNumber: 0},
        { SalesControl: 0 }, { Report: 0 },{ PackageSetting: 0 },
        { PackageInput: 0 },{ PhoneSetting: 0 },
      ]
    }

    this.setState({  modalShow: false, ModeSubAcc: '',
      EditItem, SubPassowrd: '',SubUsername: '', AllowSaveUsername: false, 
      Bet: 0, ViewDownlineReport: 0, setting: 0,
      DisplayOrder: 0,CancelOrRevertOrder: 0,EditOrder: 0,
      RebuyOrder: 0,SalesReport: 0,AcctDisplay: 0,
      EditUser: 0,CreateUser: 0,DisplayablePackage: 0,
      EditBanNumber: 0,SalesControl: 0,Report: 0,
      PackageSetting: 0,PackageInput: 0, PhoneSetting: 0, CheckUserNumber: 0 })
  }

  async EditActingAccountDetail(PackageItem) {
    const EditItem = [
      { Bet: PackageItem.Bet}, { ViewDownlineReport: PackageItem.ViewDownlineReport}, { setting: PackageItem.Setting},
      { DisplayOrder: PackageItem.DisplayOrder },{CancelOrRevertOrder: PackageItem.CancelOrRevertOrder},
      { EditOrder: PackageItem.EditOrder },{ RebuyOrder: PackageItem.RebuyOrder },
      { SalesReport: PackageItem.SalesReport },{AcctDisplay: PackageItem.AcctDisplay},
      { EditUser: PackageItem.EditUser },{ CreateUser: PackageItem.CreateUser },
      { DisplayablePackage: PackageItem.DisplayablePackage },{EditBanNumber: PackageItem.EditBanNumber},
      { SalesControl: PackageItem.SalesControl }, { Report: PackageItem.Report },{ PackageSetting: PackageItem.PackageSetting },
      { PackageInput: PackageItem.PackageInput },{ PhoneSetting: PackageItem.PhoneSetting },
    ]
    this.setState({ EditItem, SubUsername: PackageItem.Username, SubPassowrd: PackageItem.Password, SubAccID: PackageItem.UserID,
      Bet: PackageItem.Bet,
      ViewDownlineReport: PackageItem.ViewDownlineReport,
      setting: PackageItem.Setting,
      DisplayOrder: PackageItem.DisplayOrder,
      CancelOrRevertOrder: PackageItem.CancelOrRevertOrder,
      EditOrder: PackageItem.EditOrder,
      RebuyOrder: PackageItem.RebuyOrder,
      SalesReport: PackageItem.SalesReport,
      AcctDisplay: PackageItem.AcctDisplay,
      EditUser: PackageItem.EditUser,
      CreateUser: PackageItem.CreateUser,
      DisplayablePackage: PackageItem.DisplayablePackage,
      EditBanNumber: PackageItem.EditBanNumber,
      SalesControl: PackageItem.SalesControl,
      Report: PackageItem.Report,
      PackageSetting: PackageItem.PackageSetting,
      PackageInput: PackageItem.PackageInput,
      PhoneSetting: PackageItem.PhoneSetting,
    
    },()=>{
      this.setState({modalShow: true, ModeSubAcc: 'Edit', AllowSaveUsername: true})
    })
   
  }

  openNotificationError(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
      });
    }, 1500);
  };

  openNotificationSuccess(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        AddDisable: false, 
        AllowSaveUsername: false
      }, ()=>{
        this.ClearModule();
        this.CallAPI();
      });
    }, 1500);
  };

  async CheckedAllBox(event, type, Status) {
    const usersArray = [  'Bet',  'ViewDownlineReport',  'setting',
      'DisplayOrder', 'CancelOrRevertOrder', 'EditOrder','RebuyOrder', 'SalesReport', 'AcctDisplay', 'EditUser', 'CreateUser',
      'DisplayablePackage', 'EditBanNumber', 'Report', 'PackageSetting', 'PackageInput', 'PhoneSetting', 'SalesControl',
    ]
    if (Status === 'Click') {
      this.setState({ [type]: event }, ()=> {
        if(type === 'SelectAllAuth') {
          for(let a = 0; a < usersArray.length; a++) {
            if(this.state[type] === true) {
              this.setState({ [usersArray[a]]: 1})
            } else {
              this.setState({ [usersArray[a]]: 0})
            }
          }
        }
      })
    } else {
      this.setState({ [type]: event.target.checked }, ()=> {
        if(type === 'SelectAllAuth') {
          for(let a = 0; a < usersArray.length; a++) {
            if(this.state[type] === true) {
              this.setState({ [usersArray[a]]: 1})
            } else {
              this.setState({ [usersArray[a]]: 0})
            }
          }
        }
      })
    }
  }

  async CheckingUsername(CheckName){
    if (this.state.CheckUserNumber !== 1){
      if(CheckName !== '') {
        const CheckingResultUsername =  await verifyUsername(this.props.username, CheckName, this.props.token)
        if (CheckingResultUsername.message !== 'Not Available') {
          this.setState({ notificationType: 'success', AllowSaveUsername: true, CheckUserNumber: 1  }, () => {
            this.openNotificationError(language[this.props.currentLanguage].Avaiable)
          });
        } else {
          this.setState({ notificationType: 'error', AllowSaveUsername: false, CheckUserNumber: 0 }, () => {
            this.openNotificationError(language[this.props.currentLanguage].NotAvailable)
          });
        }
      } else {
        this.setState({ notificationType: 'error', AllowSaveUsername: false, CheckUserNumber: 0 }, () => {
          this.openNotificationError(language[this.props.currentLanguage].PleaseInsertUsername)
        });
      }
    }
  }

  async SubAccDeletedForm(ID_Deleted){
    //Alert Message Deleted Function 
    this.setState({ AddDisable: true, AllowSaveUsername: true }, async ()=> {
      const r = (window.confirm(`${language[this.props.currentLanguage].DeletedAlert}`));
      if (r === true) {
        const deleteActingActResult =  await deleteActingAct( ID_Deleted, this.props.username,  this.props.token)
        if (deleteActingActResult.message === 'Delete Successful') {
          this.setState({ notificationType: 'success' }, () => {
            this.openNotificationSuccess(language[this.props.currentLanguage].DeleteSuccessful)
          });
        }
      } else {
        this.setState({ AddDisable: false, AllowSaveUsername: false });
      }
    })
  }


  render() {
    return(
      <div>
        <div>
          <Header2 />
        </div>
        <div className="row" >
          <div className="redNumberTitle" style={{ width: '25%', fontSize: '20px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1%' }}/>
          <div className="redNumberTitle form-control" style={{ width: '25%', fontSize: '20px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1%' }}>
            <b> {language[this.props.currentLanguage].ActingAccount} </b>
          </div>
          <div className="redNumberTitle" style={{ width: '25%', fontSize: '20px', marginLeft: 'auto', marginRight: 'auto', marginTop: '1%' }}>
            <Button className="button2" variant="success" disabled={this.state.AddDisable }  onClick={() => this.setState({modalShow: true})}>{language[this.props.currentLanguage].CreateSubAccount}</Button>
          </div>
        </div>
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div  className="dcc">
          <div  style={{ marginTop: '1%', overflowX: 'auto', width: '90%'}}>
            {this.state.ActingAccountArray && this.state.ActingAccountArray.length > 0 ? (
              <Table responsive="sm" striped bordered hover>
                <thead style={{ backgroundColor: 'lightsteelblue' }}>
                  <tr>
                    <th> ID </th>
                    <th><b> {language[this.props.currentLanguage].usernameLanguage} </b></th>
                    <th><b> {language[this.props.currentLanguage].Action} </b></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.ActingAccountArray.map((item)=>{
                    return <tr>
                      <td style={{ verticalAlign: 'middle', width: '20%'}}>{item.UserID}</td>
                      <td style={{ verticalAlign: 'middle', width: '30%'}}>{item.Username}</td>
                      <td style={{ verticalAlign: 'middle', width: '20%'}}>
                        <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                          <div>
                            <Button className="button2" variant="primary" style={{ width: '100%', marginRight: 5 }} disabled={this.state.AddDisable } onClick={() => this.EditActingAccountDetail(item)}>{language[this.props.currentLanguage].Edit}</Button>
                          </div>
                          <div>
                            <Button className="button2" variant="danger"  style={{ width:  '100%' }} disabled={this.state.AddDisable } onClick={() => this.SubAccDeletedForm(item.UserID)}>
                              {language[this.props.currentLanguage].Delete}
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>  
                  })}
                </tbody>
              </Table>
            ) : (
              <div style={{ flexDirection: 'column', width: '100%' }}>
                <div>
                  <div className="loadingMessage"> {language[this.props.currentLanguage].NoActingAccount} </div>
                </div>
              </div>
            )} 
          </div>
        </div>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modalShow}
          contentClassName="br23">
          <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
            <div className="dcc" style={{ justifyContent: 'center' }}>
              <Alert style={{ zIndex: 99, position: 'fixed', top: 100, right: 580}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
              </Alert>
            </div>
            <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
              <GrFormClose
                style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
                onClick={() => { this.ClearModule()
                }}
                size="35px"
                color="#000"
              />
              {/* <div style={{flexDirection: 'row', width:(isMobile) ? 400 : 'auto', }}> */}
              <Tab.Container id="left-tabs-example" activeKey={this.state.activeKey} mountOnEnter unmountOnExit>
                <div className="dcc" style={{ width: '100%', marginTop: 10 }}>
                  <div style={{fontSize: "21px", width: 200,  textAlign: 'center', fontWeight: 'bold'}}>{(this.state.ModeSubAcc === 'Edit') ? `${language[this.props.currentLanguage].EditSubAccount}`:`${language[this.props.currentLanguage].CreateSubAccount}`}</div>
                </div>
                <hr />
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, overflowX: 'auto'}}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 10}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', fontSize: 20, fontWeight: 'bold', width: '10%'}}>{`${language[this.props.currentLanguage].usernameLanguage} :`}</div>
                      <Form.Control style={{ width: '35%', textAlign: 'center'}} type="text"
                        onChange={(e) => this.setState({ SubUsername: e.target.value})}
                        value={this.state.SubUsername}
                        disabled= {this.state.AllowSaveUsername}
                      />
                      {(this.state.ModeSubAcc !== 'Edit')&&<Button className="button2" variant="primary" style={{ marginLeft: 20}}
                        onClick={() => this.CheckingUsername(this.state.SubUsername) } >
                        {language[this.props.currentLanguage].Inquire}
                      </Button>}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', fontSize: 20, fontWeight: 'bold', width: '10%'}}>{`${language[this.props.currentLanguage].Password} :`}</div>
                      <Form.Control style={{ width: '35%', textAlign: 'center'}}
                        onChange={(e) => this.setState({ SubPassowrd: e.target.value}, ()=>{
                          if (this.state.ModeSubAcc !== 'Edit'){
                            this.CheckingUsername(this.state.SubUsername)
                          }
                        })}
                        value={this.state.SubPassowrd}
                        maxLength={7}
                        type="Password"
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                      <div style={{ margin: '0px 10px', fontSize: 20, fontWeight: 'bold', width: '50%'}}>{`${language[this.props.currentLanguage].Permission} :`}</div>
                    </div>
                    <div style={{  marginBottom: 10}}>
                      <div>
                        <b style={{ fontSize: '20px' }}>{''}</b>
                      </div>
                      <hr/>
                      <div style={{ marginBottom: 10, fontWeight: 'bold', width: '90%' , marginLeft: '10%'}}>
                        <div style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}>
                          <div className={'row'} style={{ width: 'calc(100% / 3)', padding: '8px', position: 'relative', fontWeight: 'bold'}}>
                            <div>
                              <input
                                className="button2"
                                style={{ textAlign: 'center', marginLeft: 5, marginRight: 5, width: 15, height: 15 }} 
                                checked={this.state.SelectAllAuth} 
                                onChange={(e) => this.CheckedAllBox(e, 'SelectAllAuth')}
                                readOnly={false}
                                type="checkbox"
                                placeholder={'SetUser'}
                              />
                            </div>
                            <div style={{ width: '40%'}} onClick={(e) =>  this.CheckedAllBox(!this.state.SelectAllAuth, 'SelectAllAuth', 'Click')}>
                              {language[this.props.currentLanguage].AllDan}
                            </div>
                          </div>
                        </div>
                        <div style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}>
                          {(this.state.EditItem || []).map((item, index) => {
                            return(
                              <div className={'row'} index={index} style={{ width: 'calc(100% / 3)', padding: '8px', position: 'relative', fontWeight: 'bold'}}>
                                <div>
                                  <input
                                    className="button2"
                                    style={{ textAlign: 'center', marginLeft: 5, marginRight: 5, width: 15, height: 15 }} 
                                    checked={(this.state[Object.keys(item)[0]] === 1) ? true : false} 
                                    onChange={(e) => { 
                                      if (e.target.checked) {
                                        this.setState({[Object.keys(item)[0]]: 1})
                                      } else {
                                        this.setState({[Object.keys(item)[0]]: 0}, ()=> {
                                          this.checkCheckedAll();
                                        })
                                      }
                                    }}
                                    readOnly={false}
                                    type="checkbox"
                                    placeholder={'SetUser'}
                                  />
                                </div>
                                <div style={{ width: '40%'}} onClick={() => this.toggleResult(Object.keys(item)[0], 'SelectAllAuth')}>
                                  {language[this.props.currentLanguage][Object.keys(item)[0]]}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Container>
              <div className="dcc" style={{ width: '100%', marginBottom: 30, marginTop: 45, flexDirection: 'row' }}>
				        <Button className="button2" style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: '33%' }} 
                  disabled={this.state.disabled}
                  onClick={() => this.SubmitSubAccDetail()} 
                  class="btn my-cusomized-button" >
                  {language[this.props.currentLanguage].Submit}
                </Button>
              </div>
              {/* </div> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    userID: state.userID,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(SubAccPackage);