import React, { Component } from 'react';
import Sg4icon from '../../Images/Cards/sg4.png'
import Moment from 'moment-timezone';
import { language } from '../../language';
import { connect } from 'react-redux';
// import { isMobile } from 'react-device-detect';
import { characterList } from '../../static';

class Sg4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: '',
    }
  }

  render() {
    const Special = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const consolation = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="singaporeborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={Sg4icon} alt="Logo" className="logoiconimages" />
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'black' }}><b>{language[this.props.currentLanguage].singapore} 4D</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'black' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{this.props.sg4.drawNo || '---/--'}</div>
              {/* <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>222/12</div> */}
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="dcc bordersingaporeclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].first}
          </div>
          <div className="dcc bordersingaporeclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].second}
          </div>
          <div className="dcc bordersingaporeclass" style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].third}
          </div>
        </div>
        <div className="numberborder" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="resultP1P2P3" style={{ width: 'calc(100% / 3)' }}>{this.props.sg4.P1 || '----'} </div>
            <div className="resultP1P2P3" style={{ width: 'calc(100% / 3)' }}>{this.props.sg4.P2 || '----'} </div>
            <div className="resultP1P2P3" style={{ width: 'calc(100% / 3)' }}>{this.props.sg4.P3 || '----'} </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10, fontSize: '20px' }}>
          <div className="dcc bordersingaporeclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'black' }}>
            {language[this.props.currentLanguage].special}
          </div>
        </div>

        <div className="numberborderdspecon" style={{ marginBottom: 15, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              Special
                ? Special.map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)', position: 'relative', padding: '8px', color: 'black', textDecoration: 'none' }}>
                  <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '15px' }}>{characterList[item]}</span>
                  {this.props.sg4[item] || '----'}
                </div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>

        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="dcc bordersingaporeclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].consolation}
          </div>
        </div>

        <div className="numberborderdspecon" style={{ marginBottom: 30, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              consolation
                ? consolation.map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)', position: 'relative', padding: '8px', color: 'black', textDecoration: 'none' }}>
                  <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '15px' }}>{characterList[item]}</span>
                  {this.props.sg4[item] || '----'}
                </div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>

      </div>
    );
  }
}

// export default Sg4;

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(Sg4)
