import React from 'react';
import Header2 from '../Component/Header';
import DatePicker from 'react-datepicker';
import Moment from 'moment-timezone';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Loading from '../Images/loading.gif';
import { connect } from 'react-redux';
import { language } from '../language';
const { getSMSReportDetail } = require('../Api');

class SMSReportDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showNotification: false,
      notificationType: 'success',
      notificationMessage: '',
      UseridReport: '',
      ShowTable: false,
      NotEmpty: true,
      ReportDetailBet: [],
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      SelectedDate: Moment(localStorage.getItem('SELECTED_DATE')).toDate(),
    }
  }

  async componentDidMount() {
    this.getSMSReportDetailAPI();
  }

  async setSelectedDate(SelectedDate) {
    this.setState({ SelectedDate }, ()=>{
      this.props.setDate(SelectedDate)
      this.getSMSReportDetailAPI();
    })
  }

  async getSMSReportDetailAPI() {
    const SelectedDate = Moment(this.state.SelectedDate).format('YYYY-MM-DD')
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
    if (searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      searchState = JSON.parse(searchState)

      this.setState({ UseridReport: searchState.Username, loading: true })

      const SearchUSerID = (searchState.id.includes('SELF')) ? searchState.id.substring(0, searchState.id.length - 5) : searchState.id
      const AccountReportDetail = await getSMSReportDetail(SelectedDate, SearchUSerID, this.props.token, this.props.username)
      if(AccountReportDetail.message === 'No Data For Selected Date') {
        this.setState({ NotEmpty: true, ReportDetailBet: [], loading: false })
      } else {
        var FilterOutZero = AccountReportDetail.filter(function (el) {
          return el.Amount !== 0
        });
        this.setState({ ReportDetailBet: FilterOutZero, NotEmpty: false, loading: false })
      }
      
    } else {
      this.setState({ UseridReport: 'No User', ReportDetailBet: [], NotEmpty: true, loading: false })
    }
  }

  render() {
    return(
      <div style={{ marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', overflowX: 'auto' }}>
        <Header2 />
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width:  '70%', left:  250 }} show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 20,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft:  '3.5%',
            flexDirection: 'row',
            width: '95%'
          }}>
          <div style={{ justifyContent: 'center', display: 'flex' }} >
            <div style={{fontSize: "18px", width: 100, fontWeight: 'bold' }}>{language[this.props.currentLanguage].Date} :</div>
            <DatePicker
              onChange = {(Date) => this.setSelectedDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              // popperPlacement="top-right"
              selected={this.state.SelectedDate}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <div className="dcc" style={{ marginRight:   20 }}>
            <div className="TitleUsername"> ID - {this.props.username} </div>
          </div>
        </div>
        <div style={{ marginLeft: '3.5%', fontWeight: 'bold', fontSize: '18px', marginTop: 10, marginBottom: 10 }}>{language[this.props.currentLanguage].SelectedUsername} {this.state.UseridReport}</div>
        <div style={{ width: '100%', overflowY: 'auto'}}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ minWidth: null }}>{language[this.props.currentLanguage].UserID}</th>
                <th style={{ minWidth:  null }}>{language[this.props.currentLanguage].Username}</th>
                <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].phoneNumber}</th>
                <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].OrderID}</th>
                <th style={{ minWidth:  100 }}>{language[this.props.currentLanguage].Receipt}</th>
                <th style={{ minWidth:  100 }}>{language[this.props.currentLanguage].DateTime}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.loading === true ? (
                <tr>
                  <td colSpan="17" style={{ backgroundColor: 'white' }}>
                    <img src={Loading} alt="Logo" className="loadingreceiptimages" style={{ marginBottom: 30 }} />
                    <div className="loadingMessageBet">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
                  </td>
                </tr>
              ) : (
                <>
                  {this.state.NotEmpty === false ? (
                    <>
                      {(this.state.ReportDetailBet || []).map((item, idn) => {
                        return(
                          <tr key={idn}>
                            <td>{item.UserID}</td>
                            <td>{item.Username}</td>
                            <td>{item.PhoneNumber}</td>
                            <td>{item.OrderID}</td>
                            <td style={{ whiteSpace: 'pre-wrap', textAlign: 'left'}}>{item.Receipt}</td>
                            <td>{Moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <tr><td colSpan="17" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '28px'}}>{language[this.props.currentLanguage].NoData}</td></tr>
                    </>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSReportDetail);