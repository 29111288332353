import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
// import { isMobile } from 'react-device-detect';
// import { Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { connect } from 'react-redux'
// import Receipt from '../Images/Receipt.png';
// import { Navbar, Nav, Container, DropdownButton, Dropdown } from 'react-bootstrap';
// import Accordion from 'react-bootstrap/Accordion';
// import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
// import { language } from '../language';

class ViewReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: this.props.username,
      TodayDate: moment(new Date()).format('DD/MM/YYYY (ddd)'),
      SearchValue: '',
      TextResult: '',
      showShare: true,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      ChooseStatus: 300,
    }
    this.SelectedOptionStatus = this.SelectedOptionStatus.bind(this);
  }

  toggleResult(platform) {
    this.setState({
      [`show${platform}`]: !this.state[`show${platform}`],
    })
  }

  SelectedOptionStatus(event) {
    this.setState({ChooseStatus: event.target.value});
  }

  render() {
    return (
      // <div style={{ width: '100%', backgroundColor: '#EFEFEF', minHeight: 950, flexDirection: 'column' }}>
      <div style={{ marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'space-between', minHeight: 350, flexDirection: 'column' }}>
        {/* <div style={{ width: '100%', backgroundColor: 'lightsteelblue', minHeight: 950, flexDirection: 'column' }}> */}
        {/* <Header /> */}
        <Header2 />
        <div className="dcc mobileHeader"
          style={{
            marginTop: 20,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
            width: '95%'
          }}>
          <div className="TitleDate">日期：{this.state.TodayDate}</div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.props.username} </div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 20,
            paddingBottom: 20,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
          }}>
          <div style={{ fontWeight: 'bold', fontSize: 20 }}>户口销售列出</div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            justifyContent: 'space-between',
            marginLeft: '2%',
            flexDirection: 'row',
          }}>
          <div className={"TitleUsername"} style={{ width: '15%'}}> Accounts of {this.state.Username}</div>
          <div className={"TitleUsername"} style={{ width: '20%', flexDirection: null }}> 
            <div>{'Total Accounts:'}</div>
            <div> {'0'}</div>
          </div>
          <div className={"TitleUsername"} style={{ width: '20%', flexDirection: null}}> 
            <div>{'Total Group Sales:'}</div> 
            <div>{'0.00'}</div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
          }}>
          <div style={{ width: '100%'}}>
            <Table responsive="sm" striped bordered hover style={{ width: 1000, marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
              <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                <tr>
                  <th/>
                  <th>{'Level'}</th>
                  <th style={{ width: 'auto' }}>{'登入'}</th>
                  <th style={{ width: 'auto' }}>{'名称'}</th>
                  <th style={{ width: 'auto' }}>{'状况'}</th>
                  <th style={{ width: 'auto' }}>{'Group Sales (include PS)'}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        {/* <div className= "dcc " style= {{ width: '90%',  flexDirection: 'column', justifyContent: 'center'}}>
          <div className="TitleUsername" style={{ width: '30%'}}>
              还没有吃到的字 或 已经全部下传。
          </div>
        </div> */}
      </div >
    );
  }
}
// export default ViewReceipt;

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(ViewReceipt);