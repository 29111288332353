import '../App.css';
import React from 'react';
import { connect } from 'react-redux'
import Header2 from '../Component/Header';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-datepicker';
import Moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Modal, Form, Alert} from 'react-bootstrap';
// import { isMobile } from 'react-device-detect';
import { language } from '../language';
import { getLaiCalculateExcel } from '../jsonToXlsx'
const { getLaiReportF, searchDownlineHR } = require('../Api');

var timer

class ZhonglaiCalculate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Min_Date: Moment().toDate(),
      Max_Date: Moment().toDate(),
      NewMin_Date: Moment().toDate(),
      NewMax_Date: Moment().toDate(),
      userID: this.props.userID,
      UserID: this.props.userID,
      username: this.props.username,
      token: this.props.token,
      Role: this.props.Role,
      DisplayLaiReport: [{},{}],
      DisplayNewLaiReport: [],
      FinalAnswerSelf: [],
      FinalAnswerDownline: [],
      Loading: false,
      SearchUser: '',
      SearchUSersModal: false,
      SearchedID: [],
      Authority: {},
    }
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if (this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
      this.setState({ userID: 'C' })
    }
    this.setState({ Authority: UserAuthorities })
  }

  async setSelectedDate(date, dateType) {
    this.setState({ [dateType]: date })
  }

  async CallDataApi(Type, RefreshID) {
    let SearchUserID = ''
    if (RefreshID === undefined) {
      if (this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
        SearchUserID = 'C'
      }
    } else {
      SearchUserID = RefreshID
    }
    this.setState({ Loading: true })
    const NewMinDate = Moment(this.state.NewMin_Date).format('YYYY-MM-DD')
    const NewMaxDate = Moment(this.state.NewMax_Date).format('YYYY-MM-DD')
    const MinDate = Moment(this.state.Min_Date).format('YYYY-MM-DD')
    const MaxDate = Moment(this.state.Max_Date).format('YYYY-MM-DD')
    const getZhonglai = await getLaiReportF(MinDate, MaxDate, NewMinDate, NewMaxDate, SearchUserID, this.props.username, this.props.token)
    let SelfFinalResult = {}
    let SotreFinalDownlineAnswer = []
    if (getZhonglai) {
      let SelectedUSer = 'C'
      if (RefreshID !== undefined) {
        SelectedUSer = RefreshID
      }
      getZhonglai.FinalAnswer
        .forEach((item) => {
          if (item.UserID === SelectedUSer) {
            SelfFinalResult = item;
            return true;
          } else {
            SotreFinalDownlineAnswer.push(item)
            return false;
          }
        });
      SotreFinalDownlineAnswer.sort((a, b) => (a.Name > b.Name) ? 1 : -1)
      if (JSON.stringify(SelfFinalResult) !== '{}') {
        this.setState({ FinalAnswerSelf: [SelfFinalResult] })
      } else {
        this.setState({ FinalAnswerSelf: [] })
      }
      this.setState({
        FinalAnswerDownline: SotreFinalDownlineAnswer,
        Loading: false,
      })
      this.handleClose();
    }
  }

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.SearchUser !== '') {
      const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.state.UserID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(language[this.props.currentLanguage].InvalidDownlineIDorUsername)
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleClose() {
    this.setState({
      SearchUSersModal: false, SearchedID: [] 
    })
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 2000);
  };

  exportXlsx() {
    if (this.state.Authority.isSub === 0 || (this.state.Authority.isSub === 1 && this.state.Authority.Tong === 0)) {
      getLaiCalculateExcel(this.state.Min_Date, this.state.Max_Date, this.state.NewMin_Date, this.state.NewMax_Date, this.state.FinalAnswerSelf, this.state.FinalAnswerDownline, this.props.currentLanguage)
    } else {
      getLaiCalculateExcel(this.state.Min_Date, this.state.Max_Date, this.state.NewMin_Date, this.state.NewMax_Date, this.state.FinalAnswerSelf, [], this.props.currentLanguage)
    } 
  }
  
  render() {
    return(
      <div className='StartBackground'>
        <Header2 />
        <Modal centered show={this.state.SearchUSersModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {`选择寻找用户`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(this.state.SearchedID.length === 0 ? (
              <div style={{ fontWeight: 'bold', fontSize: '20px'}}>{language[this.props.currentLanguage].NoUserFound}</div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className='dcc' style={{ maxHeight: '75vh', overflowY: 'scroll', display: 'flex', flexDirection: 'row', whiteSpace: 'pre-wrap', flexWrap: 'wrap', padding: 2 }}>
                  {this.state.SearchedID.map((items, index) => {
                    return(
                      <button key={index} className='dcc buttonUSerList' style={{ display: 'flex', flexDirection: 'column'}}
                        onClick={() => this.CallDataApi('' ,items.ID)}>
                        <div>{` ${items.Username}`}</div>
                        <div>{`${items.Role}`}</div>
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </Modal.Body>
        </Modal>
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width: '100%'}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div style={{ fontWeight: 'bold', fontSize: '20px'}}>{language[this.props.currentLanguage].ZhonglaiCalculate}</div>
        <div style={{ marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <div className='dcc' style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].From}</div>
              <DatePicker
                dateFormat = 'dd-MMM-yyyy'
                selected={this.state.Min_Date}
                onChange = {(date) => this.setSelectedDate(date, 'Min_Date')}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].to}</div>
              <DatePicker
                dateFormat = 'dd-MMM-yyyy'
                selected={this.state.Max_Date}
                onChange = {(date) => this.setSelectedDate(date, 'Max_Date')}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
            </div>
            <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold', marginLeft: 5, width: 'auto' }}
              className="button2"
              variant="primary"
              onClick={() => this.CallDataApi('Lai')}>
              {language[this.props.currentLanguage].Confirm}
            </Button>
          </div>
          <div className='dcc' style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].From}</div>
              <DatePicker
                dateFormat = 'dd-MMM-yyyy'
                selected={this.state.NewMin_Date}
                onChange = {(date) => this.setSelectedDate(date, 'NewMin_Date')}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].to}</div>
              <DatePicker
                dateFormat = 'dd-MMM-yyyy'
                selected={this.state.NewMax_Date}
                onChange = {(date) => this.setSelectedDate(date, 'NewMax_Date')}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
            </div>
            <Button style={{ textAlign: 'center' , color: 'white', fontWeight: 'bold', marginLeft: 5, width:'auto' }}
              className="button2"
              variant="primary"
              onClick={() => this.CallDataApi('NewLai')}>
              {language[this.props.currentLanguage].Confirm}
            </Button>
          </div>
        </div>
        {(this.state.DisplayLaiReport.length > 0 || this.state.DisplayNewLaiReport.length > 0) && 
        <div style={{ padding: 10 }}>
          {this.state.FinalAnswerSelf.length > 0 && 
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, flexDirection: 'row' }}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <div style={{ margin: '0px 10px',  fontWeight: 'bold'}}>{language[this.props.currentLanguage].SearchAccount}</div>
              <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
              <Button  className='button2' variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
            </div>
            <div>
              <Button className='button2' variant="primary" style={{marginRight: 5 }}
                onClick={() => this.CallDataApi('', (this.state.FinalAnswerSelf[0].Name === 'Company') ? 'C': this.state.FinalAnswerSelf[0].Upperline)}>
                {language[this.props.currentLanguage].BackToUpline}
              </Button>
              <Button className='button2' variant="primary" style={{marginRight: 5 }} onClick={() => this.CallDataApi('' ,'C')}>{language[this.props.currentLanguage].Back}</Button>
              <Button className='button2' variant="primary" onClick={() => this.exportXlsx()}>{'XLSX'}</Button>
            </div>
          </div>
          }
          {this.state.Loading && 
          <div style={{ fontWeight: 'bold', fontSize: '20px', paddingTop: 10}}>
            {language[this.props.currentLanguage].Loading}
          </div>}
          {this.state.Loading === false && 
          <Table responsive="sm" striped bordered hover>
            <thead>
              <tr>
                <th><div style={{ minWidth: 180 }}>{language[this.props.currentLanguage].Username}</div></th>
                <th><div style={{ minWidth: 180 }}>{language[this.props.currentLanguage].Come}</div></th>
                <th><div style={{ minWidth: 180 }}>{language[this.props.currentLanguage].Username}</div></th>
                <th><div style={{ minWidth: 180 }}>{language[this.props.currentLanguage].NewCome}</div></th>
                <th><div style={{ minWidth: 50}}>{'+/-'}</div></th>
                <th><div style={{ minWidth: 50 }}>{'%'}</div></th>
                <th><div style={{ minWidth: 180 }}>{language[this.props.currentLanguage].Action}</div></th>
              </tr>
            </thead>
            <tbody>
              {this.state.FinalAnswerSelf.length > 0 ? (
                <>
                  {this.state.FinalAnswerSelf.map((items, index) => {
                    return(
                      <tr key={index}>
                        <td><div style={{ minWidth: 180 }}>{items.Name}</div></td>
                        <td><div style={{ minWidth: 180 }}>{Number(items.Lai).toFixed(2)}</div></td>
                        <td><div style={{ minWidth: 180 }}>{items.DownUsername}</div></td>
                        <td><div style={{ minWidth: 180 }}>{Number(items.NewLai).toFixed(2)}</div></td>
                        <td><div style={{ minWidth: 50, color: (items.TotalPlusMinus < 0 ? 'red' : (items.TotalPlusMinus === 0) ? 'black' : '#00995F') }}>{Number(items.TotalPlusMinus).toFixed(2)}</div></td>
                        <td><div style={{ minWidth: 50, color: (items.TotalPlusMinus < 0 ? 'red' : (items.TotalPlusMinus === 0) ? 'black' : '#00995F') }}>{`${Number(items.TotalPersent).toFixed(2)} %`}</div></td>
                        <td><div style={{ minWidth: 180 }}>{''}</div></td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="7" style={{ color: 'black', fontWeight: 'bold', fontSize: '20px' }}>
                    {`${language[this.props.currentLanguage].NoData}`}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="7" style={{ backgroundColor: 'grey', color: 'white', fontWeight: 'bold'}}>
                  {`${language[this.props.currentLanguage].Downline}`}
                </td>
              </tr>
              {(this.state.Authority.isSub === 0 || (this.state.Authority.isSub === 1 && this.state.Authority.Tong === 0)) &&
              <>
                {this.state.FinalAnswerDownline.length > 0 &&
            <>
              {this.state.FinalAnswerDownline.map((item, ind) => {
                return(
                  <tr key={ind}>
                    <td><div style={{ minWidth: 180 }}>{item.Name}</div></td>
                    <td><div style={{ minWidth: 180 }}>{Number(item.Lai).toFixed(2)}</div></td>
                    <td><div style={{ minWidth: 180 }}>{item.DownUsername}</div></td>
                    <td><div style={{ minWidth: 180 }}>{Number(item.NewLai).toFixed(2)}</div></td>
                    <td><div style={{ minWidth: 50, color: (item.TotalPlusMinus < 0 ? 'red' : (item.TotalPlusMinus === 0) ? 'black' : '#00995F') }}>{Number(item.TotalPlusMinus).toFixed(2)}</div></td>
                    <td><div style={{ minWidth: 50, color: (item.TotalPlusMinus < 0 ? 'red' : (item.TotalPlusMinus === 0) ? 'black' : '#00995F')}}>{`${Number(item.TotalPersent).toFixed(2)} %`}</div></td>
                    <td><div style={{ minWidth: 180 }}><Button  className='button2' variant="primary" style={{  width: '35%' }} onClick={() => this.CallDataApi('' ,item.UserID)}>{language[this.props.currentLanguage].Downline}</Button></div></td>
                  </tr>
                );
              })}
            </>
                }
              </>
              }
            </tbody>
          </Table>
          }
        </div>
        }
        {(this.state.DisplayLaiReport.length === 0 && this.state.DisplayNewLaiReport.length === 0) && 
          <div className='dcc' style={{ display: 'flex', fontWeight: 'bold', fontSize: '20px'}}>
            <div>{'No Data'}</div>
          </div>
        }
      </div>
    );
  }
}

// export default Setting;

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZhonglaiCalculate)
