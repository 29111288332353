import React, {Component, Fragment} from 'react';
import '../App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import moment from 'moment-timezone';
import Table from 'react-bootstrap/Table';
import { language } from '../language';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Header2 from '../Component/Header';
import Modal from 'react-bootstrap/Modal';
// import { ImArrowLeft, ImArrowRight } from 'react-icons/im';
// import CheckNumber from '../Page/CheckNumber';
import WaterDetailsModal from '../Component/Modal/WaterDetailsModal';
import { FiRefreshCcw } from '@react-icons/all-files/fi/FiRefreshCcw';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment-timezone';
import { GamePlatformNumb1 } from '../static';
import moment from 'moment-timezone';
import MagnumLogo from '../Images/Cards/magnumlogo.png';
import TotoLogo from '../Images/Cards/toto.png';
import DamacaiLogo from '../Images/Cards/damacaimobile.png';
import Lotto9Logo from '../Images/Cards/9Lottologo.png';
import GDLogo from '../Images/Cards/GrandDragonLotto.png';
import Sg4Logo from '../Images/Cards/singaporePool.png';
import SarawakLogo from '../Images/Cards/sarawak.png';
import Sabah88Logo from '../Images/Cards/sabah88.png';
import SansakanLogo from '../Images/Cards/sandakan.png';
import updown from '../Images/updown1.png';
const {
  exportReportExcel, getYongExcel,
} = require('../jsonToXlsx');
const { getReports, getSearchReport, reportDetail, exportYongReport } = require('../Api');
var timer
// wordsReport API
class Games extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      Search_Username: '',
      modalShow: false,
      Min_Date: Moment().toDate(),
      Max_Date: Moment().toDate(),
      minDate: Moment().format('YYYY-MM-DD'),
      maxDate: Moment().format('YYYY-MM-DD'),
      Available: false,
      page: 1,
      countperpage: 12,
      countpagemax: 1,
      countpagemin: 1,
      ReportDetails: [],
      OriginalReportDetails: [],
      showNotification: false,
      notificationMessage: '',
      DetailsMode: true, 
      RedMode: 'all',
      notificationType: 'success',
      SelfReport: [],
      OriginalSelfReport: [],
      TotalAmount: '',
      BooleanReport: '',
      SearchUsername: '',
      ShowTable: false,
      ReportUserID: '',
      OriUserID: '',
      GamePlatform: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      GamePlatform2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'],
      Games: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      GamesDisplay: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      Authority: {},
      ShowBakcAllButton: false,
      completed: null,
      allPlatform: {1: 'z', 2: 'z', 3: 'z', 4: 'z', 5: 'z', 6: 'z', 7: 'z', 8: 'z', 9: 'z'},
      showAllPlatform: false,
      TitlesValue: ['Lai', 'Eat', 'SelfBet', 'SelfWater','SelfWin', 'DownlineBet', 'DownlineWin', 'TotalBet', 'TotalWater', 'TotalWin', 'TotalProfit', 'Shares', 'SharesWater', 'SharesWin', 'SharesProfit', 'Upline', 'UplineWater', 'UplineWin', 'UplineProfit', 'WaterProfit', 'WinProfit'],
      TongDetails: [],
      tongEwDetails: [],
      CalculatePercent: '0',
      OpenWaterDetailsModal: false,
      WaterDetails: [],
      allUpperlineData: [],
      FollowMe: 'Username',
      UnableClick: false,
    }
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.CloseWaterModal = this.CloseWaterModal.bind(this);
    this.MaxsetSelectedDate = this.MaxsetSelectedDate.bind(this);
  }

  async componentDidMount() {
    this.SetBigValue();
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if (UserAuthorities.GameBetting.includes('9')) {
      let ArrayGame = [1, 2, 3, 4, 5, 6, 7, 8, 9]
      let GamePlatform = ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9']
      let GamePlatform2 = ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9']
      this.setState({ Games: ArrayGame, GamePlatform, GamePlatform2, GamesDisplay: ArrayGame })
    }
    this.setState({ 
      ReportUserID: this.props.Role === 'admin' || this.props.Role === 'superAdmin' ? 'C' : (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID,
      OriUserID: this.props.Role === 'admin' || this.props.Role === 'superAdmin' ? 'C' : (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID, 
      Authority: UserAuthorities
    }, ()=>{
      this.getReport();
    })
  }

  async ExportApi() {
    const minDate = this.state.maxDate
    const maxDate = this.state.minDate
    const getExportData = await exportYongReport(minDate, maxDate, this.props.username, this.props.token)
    getYongExcel(getExportData.data, getExportData.allDateArray)
  }

  SetBigValue() {
    const TitlesValue = this.state.TitlesValue
    TitlesValue.forEach((items) => {
      this.setState({ [`Big${items}`]: '' })
    })
  }

  async getReport() {
    this.setState({ UnableClick: true })
    const ReportDetails = await getReports(
      this.state.maxDate,
      this.state.minDate,
      this.state.ReportUserID,
      this.state.Games || [],
      this.state.RedMode,
      this.props.token,
      this.props.username,
    )
    console.log('ReportDetails', ReportDetails)
    if (ReportDetails.message === 'No Data') {
      this.setState({ ReportDetails : '', ShowTable: false, UnableClick: false })
    } else if(ReportDetails.error === 'fromDate bigger than toDate') {
      this.openNotification('FormDateError')
      this.setState({ notificationType: 'error', UnableClick: false });
    } else {
      const ReportArrayDetail =  Object.values(ReportDetails.userDetails);
      // const ReportArrayDetailDownline =  ReportArrayDetail.slice(1) || [];
      if((ReportArrayDetail).length === 0) {
        this.setState({ShowTable: false, UnableClick: false })
      } else {
        let selfProfitReport = {};
        const filtered = Object.values(ReportDetails.userDetails).filter((item) => {
          if (item.UserID !== this.state.ReportUserID) {
            return true;
          } else {
            selfProfitReport = item;
            return false;
          }
        });
        const TempReportDetails = JSON.parse(JSON.stringify(filtered))
        this.calculateTotal(TempReportDetails);
        let tongDetailsArray = ReportDetails.tongDetails
        if (tongDetailsArray.length > 0) {
          tongDetailsArray = tongDetailsArray.sort((a, b) => a.username.localeCompare(b.username))
        }
        this.setState({ ReportDetails: filtered, SelfReport: [selfProfitReport], ShowTable: true, completed: ReportDetails.completed, allPlatform: ReportDetails.allPlatform, showAllPlatform: true, TongDetails: tongDetailsArray, tongEwDetails: ReportDetails.tongEwDetails, allUpperlineData: ReportDetails.allUpperlineData }, ()=> {
          if (Object.keys(this.state.SelfReport[0]).length > 0) {
            this.setState({ ShowBakcAllButton: true, UnableClick: false })
          } else {
            this.setState({ ShowBakcAllButton: false, UnableClick: false })
          }
        })
        if ((selfProfitReport && selfProfitReport.UserID) === (this.state.Authority.isSub === 1) ? this.state.Authority.ActingAs :  this.props.userID) {
          this.setState({ OriginalReportDetails: filtered, OriginalSelfReport: [selfProfitReport], UnableClick: false })
        }
      }
    }
    this.SetBigValue();
  }

  async ChangeUpperline(ReportUserID) {
    this.setState({ReportUserID}, ()=>{
      this.getReport();
    })
  }

  async BackUpperline(ReportUserID) {
    if (ReportUserID !== "null") {
      this.setState({ReportUserID}, ()=>{
        this.getReport();
      })
    }
  }

  async ExportExcelFile() {
    await exportReportExcel(this.state.SelfReport, this.state.ReportDetails, this.state.maxDate, this.state.minDate, this.props.currentLanguage, 'Report')
  }

  async BackToOriginalUpperline() {
    if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
      this.setState({ReportUserID: (this.state.Authority.isSub === 1) ? this.state.Authority.ActingAs : this.props.userID}, ()=>{
        this.getReport();
      })
    } else {
      this.setState({ ReportDetails: this.state.OriginalReportDetails, SelfReport: this.state.OriginalSelfReport })
    }
  }

  async calculateTotal(ReportDetails) {
    const tempObject = {
      Lai: 0,
      Eat: 0,
      SelfBet: 0,
      SelfWater: 0,
      SelfWin: 0,
      DownlineBet: 0,
      DownlineWin: 0,
      TotalBet: 0,
      TotalWater: 0,
      TotalWin: 0,
      TotalProfit: 0,
      Shares: 0,
      SharesWater: 0,
      SharesWin: 0,
      SharesProfit: 0,
      Upline: 0,
      UplineWater: 0,
      UplineWin: 0,
      UplineProfit: 0,
      WaterProfit: 0,
      WinProfit: 0,
    }
    if (this.state.RedMode === 'sgd') {
      tempObject.sgdLai = 0
      tempObject.sgdEat = 0
      tempObject.sgdSelfBet = 0
      tempObject.sgdSelfWater = 0
      tempObject.sgdSelfWin = 0
      tempObject.sgdDownlineBet = 0
      tempObject.sgdDownlineWin = 0
      tempObject.sgdTotalBet = 0
      tempObject.sgdTotalWater = 0
      tempObject.sgdTotalWin = 0
      tempObject.sgdTotalProfit = 0
      tempObject.sgdShares = 0
      tempObject.sgdSharesWater = 0
      tempObject.sgdSharesWin = 0
      tempObject.sgdSharesProfit = 0
      tempObject.sgdUpline = 0
      tempObject.sgdUplineWater = 0
      tempObject.sgdUplineWin = 0
      tempObject.sgdUplineProfit = 0
      tempObject.sgdWaterProfit = 0
      tempObject.sgdWinProfit = 0
    }

    if (this.state.RedMode === 'thai') {
      tempObject.thaiLai = 0
      tempObject.thaiEat = 0
      tempObject.thaiSelfBet = 0
      tempObject.thaiSelfWater = 0
      tempObject.thaiSelfWin = 0
      tempObject.thaiDownlineBet = 0
      tempObject.thaiDownlineWin = 0
      tempObject.thaiTotalBet = 0
      tempObject.thaiTotalWater = 0
      tempObject.thaiTotalWin = 0
      tempObject.thaiTotalProfit = 0
      tempObject.thaiShares = 0
      tempObject.thaiSharesWater = 0
      tempObject.thaiSharesWin = 0
      tempObject.thaiSharesProfit = 0
      tempObject.thaiUpline = 0
      tempObject.thaiUplineWater = 0
      tempObject.thaiUplineWin = 0
      tempObject.thaiUplineProfit = 0
      tempObject.thaiWaterProfit = 0
      tempObject.thaiWinProfit = 0
    }

    Object.values(ReportDetails).forEach((item) => {
      tempObject.Lai += Number(item.Lai)
      tempObject.Eat += Number(item.Eat)
      tempObject.SelfBet += Number(item.SelfBet)
      tempObject.SelfWater += Number(item.SelfWater)
      tempObject.SelfWin += Number(item.SelfWin)
      tempObject.DownlineBet += Number(item.DownlineBet)
      tempObject.DownlineWin += Number(item.DownlineWin)
      tempObject.TotalBet += Number(item.TotalBet)
      tempObject.TotalWater += Number(item.TotalWater)
      tempObject.TotalWin += Number(item.TotalWin)
      tempObject.TotalProfit += Number(item.TotalProfit)
      tempObject.Shares += Number(item.Shares)
      tempObject.SharesWater += Number(item.SharesWater)
      tempObject.SharesWin += Number(item.SharesWin)
      tempObject.SharesProfit += Number(item.SharesProfit)
      tempObject.Upline += Number(item.Upline)
      tempObject.UplineWater += Number(item.UplineWater)
      tempObject.UplineWin += Number(item.UplineWin)
      tempObject.UplineProfit += Number(item.UplineProfit)
      tempObject.WaterProfit += Number(item.WaterProfit)
      tempObject.WinProfit += Number(item.WinProfit)
      if (this.state.RedMode === 'sgd') {
        tempObject.sgdLai += Number(item.sgdLai)
        tempObject.sgdEat += Number(item.sgdEat)
        tempObject.sgdSelfBet += Number(item.sgdSelfBet)
        tempObject.sgdSelfWater += Number(item.sgdSelfWater)
        tempObject.sgdSelfWin += Number(item.sgdSelfWin)
        tempObject.sgdDownlineBet += Number(item.sgdDownlineBet)
        tempObject.sgdDownlineWin += Number(item.sgdDownlineWin)
        tempObject.sgdTotalBet += Number(item.sgdTotalBet)
        tempObject.sgdTotalWater += Number(item.sgdTotalWater)
        tempObject.sgdTotalWin += Number(item.sgdTotalWin)
        tempObject.sgdTotalProfit += Number(item.sgdTotalProfit)
        tempObject.sgdShares += Number(item.sgdShares)
        tempObject.sgdSharesWater += Number(item.sgdSharesWater)
        tempObject.sgdSharesWin += Number(item.sgdSharesWin)
        tempObject.sgdSharesProfit += Number(item.sgdSharesProfit)
        tempObject.sgdUpline += Number(item.sgdUpline)
        tempObject.sgdUplineWater += Number(item.sgdUplineWater)
        tempObject.sgdUplineWin += Number(item.sgdUplineWin)
        tempObject.sgdUplineProfit += Number(item.sgdUplineProfit)
        tempObject.sgdWaterProfit += Number(item.sgdWaterProfit)
        tempObject.sgdWinProfit += Number(item.sgdWinProfit)
      }
      if (this.state.RedMode === 'thai') {
        tempObject.thaiLai += Number(item.thaiLai)
        tempObject.thaiEat += Number(item.thaiEat)
        tempObject.thaiSelfBet += Number(item.thaiSelfBet)
        tempObject.thaiSelfWater += Number(item.thaiSelfWater)
        tempObject.thaiSelfWin += Number(item.thaiSelfWin)
        tempObject.thaiDownlineBet += Number(item.thaiDownlineBet)
        tempObject.thaiDownlineWin += Number(item.thaiDownlineWin)
        tempObject.thaiTotalBet += Number(item.thaiTotalBet)
        tempObject.thaiTotalWater += Number(item.thaiTotalWater)
        tempObject.thaiTotalWin += Number(item.thaiTotalWin)
        tempObject.thaiTotalProfit += Number(item.thaiTotalProfit)
        tempObject.thaiShares += Number(item.thaiShares)
        tempObject.thaiSharesWater += Number(item.thaiSharesWater)
        tempObject.thaiSharesWin += Number(item.thaiSharesWin)
        tempObject.thaiSharesProfit += Number(item.thaiSharesProfit)
        tempObject.thaiUpline += Number(item.thaiUpline)
        tempObject.thaiUplineWater += Number(item.thaiUplineWater)
        tempObject.thaiUplineWin += Number(item.thaiUplineWin)
        tempObject.thaiUplineProfit += Number(item.thaiUplineProfit)
        tempObject.thaiWaterProfit += Number(item.thaiWaterProfit)
        tempObject.thaiWinProfit += Number(item.thaiWinProfit)
      }
    })
    this.setState({ TotalAmount:tempObject })
  }

  async SearchFunction(e) {
    const SearchUsername = this.state.SearchUsername
    if(e.key === 'Enter') {
      if(SearchUsername === '') {
        if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
          this.setState({ReportUserID: (this.state.Authority.isSub === 1) ? this.state.Authority.ActingAs :  this.props.userID}, ()=>{
            this.getReport();
          })
        } else {
          this.setState({ ReportDetails: this.state.OriginalReportDetails, SelfReport: this.state.OriginalSelfReport })
        }
      } else {
        if (this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.ViewDownlineReport) === 1) {
          const SearchUsers = await getSearchReport(
            this.state.maxDate,
            this.state.minDate,
            SearchUsername,
            this.state.RedMode,
            this.state.Games,
            this.props.username,
            this.props.token,
          )
          if(SearchUsers.message === 'No Data') {
            this.setState({ notificationType: 'error' }, ()=> {
              this.openNotification('invalidUsername')
              this.setState({ ReportDetails: this.state.OriginalReportDetails, SelfReport: this.state.OriginalSelfReport })
            });
          } else if(SearchUsers.error === 'Invalid SearchUser') {
            this.setState({ notificationType: 'error' }, ()=> {
              this.openNotification('invalidUsername')
              this.getReport();
            });
          } else {
            const DownlineReports = SearchUsers.slice(1) || [];
            this.setState({ SelfReport: [Object.values(SearchUsers)[0]], ReportDetails: DownlineReports, ShowTable: true}, ()=> {
              if(Object.keys(this.state.SelfReport[0]).length > 0) {
                this.setState({ ShowBakcAllButton: true })
              } else {
                this.setState({ ShowBakcAllButton: false })
              }
            })
          }
        }
      }
    }
  }

  async setSelectedDate(Min_Date) {
    this.setState({ Min_Date, minDate: moment(Min_Date).format('YYYY-MM-DD') }, ()=>{
      this.props.setMinDate(Min_Date);
    })
  }

  async MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date, maxDate: moment(Max_Date).format('YYYY-MM-DD'), Min_Date: Max_Date, minDate: moment(Max_Date).format('YYYY-MM-DD') }, ()=>{
      this.props.setMaxDate(Max_Date)
    })
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  GameReport(Key){
    const CheckGame = (this.state.Games).includes(Key)
    let array = [...this.state.Games];

    if(CheckGame){
      var index = array.indexOf(Key)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({Games: array, ReportDetails: []}, ()=>{
          this.getReport()
        });
      }
    } else {
      array.push(Key)
      this.setState({Games: array, ReportDetails: []}, ()=>{
        this.getReport()
      });
    }
  }

  changeRedMode(value) {
    this.setState({RedMode: value}, () => {
      this.getReport()
    })
  }

  changeFollow(value) {
    this.setState({FollowMe: value})
  }

  BigToSmall(items) {
    const ReportDetails = this.state.ReportDetails

    if(this.state[`Big${items}`] === false || this.state[`Big${items}`] === '') {
      ReportDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
      this.setState({ [`Big${items}`]: true })
    } else {
      ReportDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
      this.setState({ [`Big${items}`]: false })
    }
    this.setState({ ReportDetails })
  }

  async OpenDetailsWater(SelectedView, Type) {
    const getData = await reportDetail(
      moment(this.state.Min_Date).format('YYYY-MM-DD'),
      moment(this.state.Max_Date).format('YYYY-MM-DD'),
      SelectedView.UserID,
      Type,
      this.state.RedMode,
      this.props.username,
      this.props.token,
      this.state.Games,
    )
    if(getData) {
      this.setState({ OpenWaterDetailsModal: true, WaterDetails: Object.values(getData) })
    }
  }

  CloseWaterModal(SelectedView) {
    this.setState({ OpenWaterDetailsModal: false })
  }

  render() {
    // console.log('allUpperlineData', this.state.allUpperlineData)
    return (
      <Fragment>
        <div>
          <Header2 />
        </div>
        {this.state.UnableClick && 
        <>
          <div className='dcc' style={{ fontWeight: 'bold', fontSize: 30, height: '80vh'}}>{language[this.props.currentLanguage].Loading}</div>
        </>}
        {!this.state.UnableClick && 
        <>
          <div className="dcc row" style={{ fontSize: '14px' }}>
            <div className={ "row"} style={{ width: 'auto' }}>
              <div style={{ justifyContent: 'center', display: 'flex', width: '100%',  alignItems: 'center' }}>
                {this.state.showAllPlatform && <div style={{ fontSize: 20, marginRight: 10}}>今天已开采成绩</div>}
                {this.state.allPlatform[1] !== 'z' && <img src={MagnumLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[1] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.allPlatform[2] !== 'z' && <img src={DamacaiLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[2] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.allPlatform[3] !== 'z' && <img src={TotoLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[3] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.allPlatform[4] !== 'z' && <img src={Sg4Logo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[4] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.allPlatform[5] !== 'z' && <img src={SarawakLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[5] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.allPlatform[6] !== 'z' && <img src={Sabah88Logo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[6] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.allPlatform[7] !== 'z' && <img src={SansakanLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[7] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.allPlatform[8] !== 'z' && <img src={GDLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[8] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.allPlatform[9] !== 'z' && <img src={Lotto9Logo} alt="Logo" className={`mainPageToggleLogo ${this.state.allPlatform[9] !== 'c' && 'activeResult'}`} style={{ marginRight: 15 }} />}
                {this.state.completed !== null && <div style={{ display: 'flex', paddingLeft: 20, fontSize: 24, color: 'red'}}>
                  {this.state.completed ? '计算完毕' : '未完整计算'}
                </div>}
              </div>
              <div className="dcc" style={{  width: '100%', display: 'flex' }}>
                <div className='dcc' style={{ justifyContent: 'center', display: 'flex'}}>
                  <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>From</div>
                  <DatePicker
                    onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    // maxDate={new Date()}
                    selected={this.state.Max_Date}
                    className={"form-control StyleDatePicker"}
                  // withPortal
                  />
                  <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>To</div>
                  <DatePicker
                    onChange = {(date) => this.setSelectedDate(date)}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    // maxDate={new Date()}
                    // popperPlacement="top-right"
                    selected={this.state.Min_Date}
                    minDate={new Date(this.state.Max_Date)}
                    className={"form-control StyleDatePicker"}
                  // withPortal
                  />
                  <Button className="button2" disabled={this.state.UnableClick} variant="primary" style={{ marginLeft: 5, fontWeight: 'bold', width: null , marginTop: 0, minWidth: '80px' }} onClick={() => this.getReport()} >{language[this.props.currentLanguage].Confirm}</Button>
                  {this.state.Authority.isSub === 1 && this.state.Authority.Tong === 1 ? <></> : <Form.Control
                    style={{ fontSize: "18px", textAlign: 'center', marginLeft: 30, width: 'auto', fontWeight: 'bold' }} 
                    value={this.state.SearchUsername}
                    className="form-control "
                    readOnly={false}
                    maxLength={50}
                    onChange={e => this.setState({ SearchUsername: e.target.value })}
                    onKeyPress={e => this.SearchFunction(e)}
                    placeholder={'用户名'} 
                  />}
                  <FiRefreshCcw
                    style={{ cursor: 'pointer', marginLeft: 10, width: 'auto' }}
                    onClick={() => {
                      this.BackToOriginalUpperline();
                      this.setState({ SearchUsername: '' })
                    }}
                    size="100px"
                    color="black"
                  />
                  {(this.props.Role === 'admin' || this.props.Role === 'superAdmin') && <Button className="button2" variant="primary" style={{ marginLeft: 10, fontWeight: 'bold', width: null , marginTop: 0}} onClick={() => this.ExportApi()} >{'专属Export'}</Button>}
                </div>
              </div>
            </div>
            {this.state.loading  === true ? ( 
              <div className="divLoader">
                <img alt="" style={{ marginTop: 50, width: 200, height:200, marginRight: 200}} src={'https://i.imgur.com/GLdqYB2.gif'}/>
              </div>
            ) : (
              <div style= {{ width: '100%'}}>
                <div className="dcc">
                  <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} show={this.state.showNotification}
                    variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                    <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                  </Alert>
                </div>
                <WaterDetailsModal CloseWaterModal={this.CloseWaterModal} RedMode={this.state.RedMode} OpenWaterDetailsModal={this.state.OpenWaterDetailsModal} WaterDetails={this.state.WaterDetails}/>
                <div className="dcc row" style={{  flexDirection: 'row'}}>
                  <div className="dcc" style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: 20,fontWeight: 'bold', marginRight: 5, width: 100 }}>{language[this.props.currentLanguage].DetailedReport}</div>
                    <input
                      checked={this.state.DetailsMode}
                      style={{ width: 17 , height: 17}}
                      onChange={() => this.setState({ DetailsMode: true })}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                  <div className="dcc" style={{ flexWrap: 'wrap', marginBottom: null}}>
                    <div style={{ fontSize: 20,fontWeight: 'bold', marginRight: 5, width: 100 }}>{language[this.props.currentLanguage].EasyReport}</div>
                    <input
                      checked={!this.state.DetailsMode}
                      style={{ width: 17, height: 17}}
                      onChange={() => this.setState({ DetailsMode: false })}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                  {(this.state.SelfReport.length !== 0) && <Button className="button2" disabled={this.state.UnableClick} style={{ width: 100, fontWeight: 'bold', marginLeft: 10, marginRight: 10}} onClick={() => this.ExportExcelFile()} >{language[this.props.currentLanguage].ExportReport}</Button>
                  }
                  {this.state.SelfReport.length !== 0 && this.state.SelfReport[0].UserID !== this.state.OriUserID && <Button className="button2" style={{ width: 100, marginLeft: 10, marginRight: 10, marginTop: 0 }} onClick={() => this.BackUpperline(this.state.SelfReport && this.state.SelfReport[0].Upperline)} >{`${language[this.props.currentLanguage].BackToUpline}`}</Button>
                  }
                </div>
                <div className="dcc" style={{ paddingBottom: 0, flexWrap: 'wrap'}}>
                  <div className="dcc" style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: 20,fontWeight: 'bold', padding: 5 }}>{language[this.props.currentLanguage].RedWhite}</div>
                    <input
                      checked={this.state.RedMode === 'all'}
                      style={{ width: 17, height: 17}}
                      onChange={() => this.changeRedMode('all')}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                  <div className="dcc" style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: 20,fontWeight: 'bold', padding: 5 }}>{language[this.props.currentLanguage].White}</div>
                    <input
                      checked={this.state.RedMode === 'white'}
                      style={{ width: 17, height: 17}}
                      onChange={() => this.changeRedMode('white')}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                  <div className="dcc" style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: 20,fontWeight: 'bold', padding: 5 }}>{language[this.props.currentLanguage].Red}</div>
                    <input
                      checked={this.state.RedMode === 'red'}
                      style={{ width: 17, height: 17}}
                      onChange={() => this.changeRedMode('red')}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                  <div className="dcc" style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: 20,fontWeight: 'bold', padding: 5 }}>SGD</div>
                    <input
                      checked={this.state.RedMode === 'sgd'}
                      style={{ width: 17, height: 17}}
                      onChange={() => this.changeRedMode('sgd')}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                  <div className="dcc" style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: 18,fontWeight: 'bold', padding: 5 }}>THAI</div>
                    <input
                      checked={this.state.RedMode === 'thai'}
                      style={{ width: 17, height: 17}}
                      onChange={() => this.changeRedMode('thai')}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                </div>
                <div className="dcc" style={{  flexWrap: 'wrap'}}>
                  <div className="dcc" style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: 20,fontWeight: 'bold', padding: 5 }}>{language[this.props.currentLanguage].FollowUserName}</div>
                    <input
                      checked={this.state.FollowMe === 'Username'}
                      style={{ width: 17, height: 17}}
                      onChange={() => this.changeFollow('Username')}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                  <div className="dcc" style={{ flexWrap: 'wrap'}}>
                    <div style={{ fontSize: 20,fontWeight: 'bold', padding: 5 }}>{language[this.props.currentLanguage].FollowName}</div>
                    <input
                      checked={this.state.FollowMe === 'Name'}
                      style={{ width: 17, height: 17}}
                      onChange={() => this.changeFollow('Name')}
                      readOnly={false}
                      type="checkbox"
                    />
                  </div>
                </div>
                <div className="dcc" style={{  flexWrap: 'wrap'}}>
                  {this.state.GamesDisplay.map((item, index) => {
                    return <Form.Check
                      key={index}
                      style={{ fontSize: 20, alignItems: 'center', display: 'flex', padding: 5, fontWeight: 'bold' }}
                      inline
                      label={GamePlatformNumb1[item]}
                      type="checkbox"
                      checked={this.state.Games.includes(item)}
                      onChange={(e) => this.GameReport(item)}
                    />
                  })}
                </div>
                <div style={{ paddingBottom: 5, flexWrap: 'wrap', display: 'flex', alignItems: 'center', paddingLeft: 10}}>
                  {this.state.allUpperlineData.map((item, index) => 
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                      <Button variant="link" style={{ fontSize: 20 }} onClick={() => this.ChangeUpperline(item.ID)}>{item.Username}</Button>
                      {index !== this.state.allUpperlineData.length - 1 && <div style={{ fontSize: 20 }}>-</div>}
                    </div>
                  )}
                </div>
                {(this.state.DetailsMode === true) && 
            <div style={{ fontSize: '14px' }}>
              <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white' }}>
                <thead>
                  <tr>
                    <th><div style={{ marginLeft: 0, minWidth: 80}}>
                      {language[this.props.currentLanguage].usernameLanguage}</div></th>
                    <th><div style={{ minWidth: 75 }}>{language[this.props.currentLanguage].Name}</div></th>
                    {this.state.TitlesValue.map((Titles, idxs) => {
                      return(
                        <th key={idxs}>
                          <div className="dcc" onClick={() => this.BigToSmall(Titles)} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minWidth: 100, cursor: 'pointer'}}>
                            {language[this.props.currentLanguage][`Report${Titles}`]}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.state.ShowTable === true ? (
                    <>
                      {this.state.SelfReport.map((item, idx) => {
                        return (
                          <tr key={idx} index={idx}>
                            <td>
                              <div>
                                <div>{item.Username}</div>
                              </div>
                            </td>
                            <td>
                              <div>
                                {this.state.minDate === this.state.maxDate ?
                                  <Link 
                                    to={(this.props.Role === 'player') ? `/AccountReport?id=${item.UserID}&Game=${this.state.Games}&Mode=${this.state.RedMode}` : `/IndividualDownlineReport?id=${item.UserID}&TypePlatform=${this.state.Games}&TypeMode=${this.state.RedMode}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {item.Name}
                                  </Link>
                                  : 
                                  <Link 
                                    to={(this.props.Role === 'player') ? `/AccountReport?id=${item.UserID}&Game=${this.state.Games}&Mode=${this.state.RedMode}` : `/IndividualDownlineReport?id=${item.UserID}&TypePlatform=${this.state.Games}&TypeMode=${this.state.RedMode}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {item.Name}
                                  </Link>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#DDDDDD'}}>
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.Lai} (${item.sgdLai})` : 
                                  this.state.RedMode === 'thai' ? `${item.Lai} (${Number(item.thaiLai).toFixed(2)})` :item.Lai}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#DDDDDD'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.Eat} (${item.sgdEat})` :
                                  this.state.RedMode === 'thai' ? `${item.Eat} (${Number(item.thaiEat).toFixed(2)})` : item.Eat}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#ccbaf8'}} >
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=selfBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.SelfBet} (${item.sgdSelfBet ? item.sgdSelfBet : 0})` : 
                                      this.state.RedMode === 'thai' ? `${item.SelfBet} (${item.thaiSelfBet ? Number(item.thaiSelfBet).toFixed(2) : 0})` :item.SelfBet}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.SelfBet} (${item.sgdSelfBet ? item.sgdSelfBet : 0})` : 
                                    this.state.RedMode === 'thai' ? `${item.SelfBet} (${item.thaiSelfBet ? Number(item.thaiSelfBet).toFixed(2) : 0})` :item.SelfBet}</div>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#ccbaf8'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.SelfWater} (${item.sgdSelfWater})` : 
                                  this.state.RedMode === 'thai' ? `${item.SelfWater} (${Number(item.thaiSelfWater).toFixed(2)})` : item.SelfWater}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#ccbaf8'}} >
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=selfWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {item.SelfWin}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.SelfWin} (${item.sgdSelfWin})` : 
                                    this.state.RedMode === 'thai' ? `${item.SelfWin} (${item.thaiSelfWin})` :item.SelfWin}</div>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#D2D7FF'}} >
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=downlineBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.DownlineBet} (${item.sgdDownlineBet})` : 
                                      this.state.RedMode === 'thai' ? `${item.DownlineBet} (${item.thaiDownlineBet})` : item.DownlineBet}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.DownlineBet} (${item.sgdDownlineBet})` : 
                                    this.state.RedMode === 'thai' ? `${item.DownlineBet} (${item.thaiDownlineBet})` :item.DownlineBet}</div>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#D2D7FF'}} >
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=downlineWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.DownlineWin} (${item.sgdDownlineWin})` : 
                                      this.state.RedMode === 'thai' ? `${item.DownlineWin} (${item.thaiDownlineWin})` :item.DownlineWin}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.DownlineWin} (${item.sgdDownlineWin})` : 
                                    this.state.RedMode === 'thai' ? `${item.DownlineWin} (${item.thaiDownlineWin})` : item.DownlineWin}</div>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#90ED92'}} >
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=totalBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.TotalBet} (${item.sgdTotalBet})` : 
                                      this.state.RedMode === 'thai' ? `${item.TotalBet} (${item.thaiTotalBet})` :item.TotalBet}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.TotalBet} (${item.sgdTotalBet})` : 
                                    this.state.RedMode === 'thai' ? `${item.TotalBet} (${item.thaiTotalBet})` : item.TotalBet}</div>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#90ED92'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.TotalWater} (${item.sgdTotalWater})` : 
                                  this.state.RedMode === 'thai' ? `${item.TotalWater} (${item.thaiTotalWater})` : item.TotalWater}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#90ED92'}} >
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=totalWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.TotalWin} (${item.sgdTotalWin})` : 
                                      this.state.RedMode === 'thai' ? `${item.TotalWin} (${item.thaiTotalWin})` :item.TotalWin}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.TotalWin} (${item.sgdTotalWin})` : 
                                    this.state.RedMode === 'thai' ? `${item.TotalWin} (${item.thaiTotalWin})` : item.TotalWin}</div>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#90ED92'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.TotalProfit} (${item.sgdTotalProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.TotalProfit} (${item.thaiTotalProfit})` : item.TotalProfit}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#ffac1c'}} >
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=shares&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.Shares} (${item.sgdShares})` : 
                                      this.state.RedMode === 'thai' ? `${item.Shares} (${item.thaiShares})` : item.Shares}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.Shares} (${item.sgdShares})` : 
                                    this.state.RedMode === 'thai' ? `${item.Shares} (${item.thaiShares})` : item.Shares}</div>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#ffac1c'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.SharesWater} (${item.sgdSharesWater})` : 
                                  this.state.RedMode === 'thai' ? `${item.SharesWater} (${item.thaiSharesWater})` : item.SharesWater}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#ffac1c'}} >
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=sharesWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.SharesWin} (${item.sgdSharesWin})` : 
                                      this.state.RedMode === 'thai' ? `${item.SharesWin} (${item.thaiSharesWin})` : item.SharesWin} 
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.SharesWin} (${item.sgdSharesWin})` : 
                                    this.state.RedMode === 'thai' ? `${item.SharesWin} (${item.thaiSharesWin})` : item.SharesWin}</div>
                                }
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#ffac1c'}}><div>
                              <div>{this.state.RedMode === 'sgd' ? `${item.SharesProfit} (${item.sgdSharesProfit})` : 
                                this.state.RedMode === 'thai' ? `${item.SharesProfit} (${item.thaiSharesProfit})` :item.SharesProfit}</div>
                            </div></td>
                            <td style={{ backgroundColor: '#FFBFCB'}}><div>
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=upperlineBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : 
                                      this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` :item.Upline}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : 
                                    this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` :item.Upline}</div>
                                }
                              </div>
                            </div></td>
                            <td style={{ backgroundColor: '#FFBFCB'}}><div>
                              <div>{this.state.RedMode === 'sgd' ? `${item.UplineWater} (${item.sgdUplineWater})` : 
                                this.state.RedMode === 'thai' ? `${item.UplineWater} (${item.thaiUplineWater})` : item.UplineWater}</div>
                            </div></td>
                            <td style={{ backgroundColor: '#FFBFCB'}}><div>
                              <div>
                                {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                  <Link 
                                    to={`/SimpleReport?id=${item.UserID}&type=upperlineWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                    {this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : 
                                      this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` :item.UplineWin}
                                  </Link>
                                  : 
                                  <div>{this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : 
                                    this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` : item.UplineWin}</div>
                                }
                              </div>
                            </div></td>
                            <td style={{ backgroundColor: '#FFBFCB'}}><div>
                              <div>{this.state.RedMode === 'sgd' ? `${item.UplineProfit} (${item.sgdUplineProfit})` : 
                                this.state.RedMode === 'thai' ? `${item.UplineProfit} (${item.thaiUplineProfit})` :item.UplineProfit}</div>
                            </div></td>
                            <td className="button2"><div>
                              {this.state.Authority.isSub === 1 ? (
                                <div>{this.state.RedMode === 'sgd' ? `${item.WaterProfit} (${item.sgdWaterProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.WaterProfit} (${item.thaiWaterProfit})` : item.WaterProfit}</div>
                              ) : (
                                <div style={{ cursor: 'pointer' }} onClick={() => this.OpenDetailsWater(item, 'waterProfit')}>{this.state.RedMode === 'sgd' ? `${item.WaterProfit} (${item.sgdWaterProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.WaterProfit} (${item.thaiWaterProfit})` :item.WaterProfit}</div>
                              )}
                            </div></td>
                            <td className="button2"><div>
                              {this.state.Authority.isSub === 1 ? (
                                <div>{this.state.RedMode === 'sgd' ? `${item.WinProfit} (${item.sgdWinProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.WinProfit} (${item.thaiWinProfit})` :item.WinProfit}</div>
                              ) : (
                                <div style={{ cursor: 'pointer' }} onClick={() => this.OpenDetailsWater(item, 'winProfit')}>{this.state.RedMode === 'sgd' ? `${item.WinProfit} (${item.sgdWinProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.WinProfit} (${item.thaiWinProfit})` : item.WinProfit}</div>
                              )}
                            </div></td>
                          </tr>
                        );
                      })}
                      {this.state.ReportDetails.length > 0 &&
                         <tr><td colSpan="23" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '20px'}}>{language[this.props.currentLanguage].Downline}</td></tr>
                      }
                      {<>
                        {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.ViewDownlineReport) === 1) &&<>
                          {Object.values(this.state.ReportDetails.filter((item) => Number(item.Lai) !== 0) || {}).sort((a, b) => (a[this.state.FollowMe] > b[this.state.FollowMe]) ? 1 : -1).map((item, idx) => {
                            return (
                              <tr key={idx} index={idx}>
                                <td>
                                  <div>
                                    {this.state.Authority.isSub === 1 && this.state.Authority.Tong === 1 ? (
                                      <>{item.Username}</>
                                    ) : (
                                      <><Button className="button2" style={{ width: 100}} onClick={() => this.ChangeUpperline(item.UserID)}>{item.Username}</Button></>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{item.Username}</>
                                    ) : (
                                      <><Link 
                                        to={(this.props.Role === 'player') ? `/AccountReport?id=${item.UserID}&Game=${this.state.Games}&Mode=${this.state.RedMode}` : `/IndividualDownlineReport?id=${item.UserID}&TypePlatform=${this.state.Games}&TypeMode=${this.state.RedMode}`}
                                        target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                        {item.Name}
                                      </Link></>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#DDDDDD'}}>
                                  <div>
                                    <div>{this.state.RedMode === 'sgd' ? `${item.Lai} (${item.sgdLai})` : this.state.RedMode === 'thai' ? `${item.Lai} (${item.thaiLai})` :  item.Lai}</div>
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#DDDDDD'}} >
                                  <div>
                                    <div>{this.state.RedMode === 'sgd' ? `${item.Eat} (${item.sgdEat})` : this.state.RedMode === 'thai' ? `${item.Eat} (${item.thaiEat})` : item.Eat}</div>
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#ccbaf8'}} >
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.SelfBet} (${item.sgdSelfBet})` : this.state.RedMode === 'thai' ? `${item.SelfBet} (${item.thaiSelfBet})` : item.SelfBet}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=selfBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.SelfBet} (${item.sgdSelfBet})` : this.state.RedMode === 'thai' ? `${item.SelfBet} (${item.thaiSelfBet})` : item.SelfBet}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.SelfBet} (${item.sgdSelfBet})` : this.state.RedMode === 'thai' ? `${item.SelfBet} (${item.thaiSelfBet})` : item.SelfBet}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#ccbaf8'}} >
                                  <div>
                                    <div>{this.state.RedMode === 'sgd' ? `${item.SelfWater} (${item.sgdSelfWater})` :  this.state.RedMode === 'thai' ? `${item.SelfWater} (${item.thaiSelfWater})` : item.SelfWater}</div>
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#ccbaf8'}} >
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.SelfWin} (${item.sgdSelfWin})` : this.state.RedMode === 'thai' ? `${item.SelfWin} (${item.thaiSelfWin})` :  item.SelfWin}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=selfWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.SelfWin} (${item.sgdSelfWin})` : this.state.RedMode === 'thai' ? `${item.SelfWin} (${item.thaiSelfWin})` : item.SelfWin}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.SelfWin} (${item.sgdSelfWin})` : this.state.RedMode === 'thai' ? `${item.SelfWin} (${item.thaiSelfWin})` : item.SelfWin}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#D2D7FF'}} >
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.DownlineBet} (${item.sgdDownlineBet})` : this.state.RedMode === 'thai' ? `${item.DownlineBet} (${item.thaiDownlineBet})` : item.DownlineBet}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=downlineBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.DownlineBet} (${item.sgdDownlineBet})` : 
                                              this.state.RedMode === 'thai' ? `${item.DownlineBet} (${item.thaiDownlineBet})` : item.DownlineBet}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.DownlineBet} (${item.sgdDownlineBet})` : 
                                            this.state.RedMode === 'thai' ? `${item.DownlineBet} (${item.thaiDownlineBet})` :item.DownlineBet}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#D2D7FF'}} >
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.DownlineWin} (${item.sgdDownlineWin})` : 
                                        this.state.RedMode === 'thai' ? `${item.DownlineWin} (${item.thaiDownlineWin})` :item.DownlineWin}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=downlineWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.DownlineWin} (${item.sgdDownlineWin})` : 
                                              this.state.RedMode === 'thai' ? `${item.DownlineWin} (${item.thaiDownlineWin})` : item.DownlineWin}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.DownlineWin} (${item.sgdDownlineWin})` : 
                                            this.state.RedMode === 'thai' ? `${item.DownlineWin} (${item.thaiDownlineWin})` : item.DownlineWin}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#90ED92'}} >
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.TotalBet} (${item.sgdTotalBet})` : 
                                        this.state.RedMode === 'thai' ? `${item.TotalBet} (${item.thaiTotalBet})` :item.TotalBet}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=totalBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.TotalBet} (${item.sgdTotalBet})` : 
                                              this.state.RedMode === 'thai' ? `${item.TotalBet} (${item.thaiTotalBet})` : item.TotalBet}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.TotalBet} (${item.sgdTotalBet})` : 
                                            this.state.RedMode === 'thai' ? `${item.TotalBet} (${item.thaiTotalBet})` : item.TotalBet}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#90ED92'}} >
                                  <div>
                                    <div>{this.state.RedMode === 'sgd' ? `${item.TotalWater} (${item.sgdTotalWater})` : 
                                      this.state.RedMode === 'thai' ? `${item.TotalWater} (${item.thaiTotalWater})` : item.TotalWater}</div>
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#90ED92'}} >
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.TotalWin} (${item.sgdTotalWin})` : 
                                        this.state.RedMode === 'thai' ? `${item.TotalWin} (${item.thaiTotalWin})` :item.TotalWin}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=totalWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.TotalWin} (${item.sgdTotalWin})` : 
                                              this.state.RedMode === 'thai' ? `${item.TotalWin} (${item.thaiTotalWin})` : item.TotalWin}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.TotalWin} (${item.sgdTotalWin})` : 
                                            this.state.RedMode === 'thai' ? `${item.TotalWin} (${item.thaiTotalWin})` :item.TotalWin}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#90ED92'}} >
                                  <div>
                                    <div>{this.state.RedMode === 'sgd' ? `${item.TotalProfit} (${item.sgdTotalProfit})` : 
                                      this.state.RedMode === 'thai' ? `${item.TotalProfit} (${item.thaiTotalProfit})` :item.TotalProfit}</div>
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#ffac1c'}} >
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.Shares} (${item.sgdShares})` : 
                                        this.state.RedMode === 'thai' ? `${item.Shares} (${item.thaiShares})` : item.Shares}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=shares&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.Shares} (${item.sgdShares})` : 
                                              this.state.RedMode === 'thai' ? `${item.Shares} (${item.thaiShares})` :item.Shares}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.Shares} (${item.sgdShares})` : 
                                            this.state.RedMode === 'thai' ? `${item.Shares} (${item.thaiShares})` : item.Shares}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#ffac1c'}} >
                                  <div>
                                    <div>{this.state.RedMode === 'sgd' ? `${item.SharesWater} (${item.sgdSharesWater})` : 
                                      this.state.RedMode === 'thai' ? `${item.SharesWater} (${item.thaiSharesWater})` :item.SharesWater}</div>
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#ffac1c'}} >
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.SharesWin} (${item.sgdSharesWin})` : 
                                        this.state.RedMode === 'thai' ? `${item.SharesWin} (${item.thaiSharesWin})` : item.SharesWin}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=sharesWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.SharesWin} (${item.sgdSharesWin})` : 
                                              this.state.RedMode === 'thai' ? `${item.SharesWin} (${item.thaiSharesWin})` :item.SharesWin}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.SharesWin} (${item.sgdSharesWin})` : 
                                            this.state.RedMode === 'thai' ? `${item.SharesWin} (${item.thaiSharesWin})` :item.SharesWin}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td style={{ backgroundColor: '#ffac1c'}}><div>
                                  <div>{this.state.RedMode === 'sgd' ? `${item.SharesProfit} (${item.sgdSharesProfit})` : 
                                    this.state.RedMode === 'thai' ? `${item.SharesProfit} (${item.thaiSharesProfit})` :item.SharesProfit}</div>
                                </div></td>
                                <td style={{ backgroundColor: '#FFBFCB'}}><div>
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : 
                                        this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` :item.Upline}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=upperlineBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : 
                                              this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` :item.Upline}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : 
                                            this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` :item.Upline}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </div></td>
                                <td style={{ backgroundColor: '#FFBFCB'}}><div>
                                  <div>{this.state.RedMode === 'sgd' ? `${item.UplineWater} (${item.sgdUplineWater})` : 
                                    this.state.RedMode === 'thai' ? `${item.UplineWater} (${item.thaiUplineWater})` :item.UplineWater}</div>
                                </div></td>
                                <td style={{ backgroundColor: '#FFBFCB'}}><div>
                                  <div>
                                    {this.state.Authority.isSub === 1 ? (
                                      <>{this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : 
                                        this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` :item.UplineWin}{item.UplineWin}</>
                                    ) : (
                                      <>
                                        {this.state.minDate === this.state.maxDate ?
                                          <Link 
                                            to={`/SimpleReport?id=${item.UserID}&type=upperlineWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                            target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                            {this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : 
                                              this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` :item.UplineWin}
                                          </Link>
                                          : 
                                          <div>{this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : 
                                            this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` : item.UplineWin}</div>
                                        }
                                      </>
                                    )}
                                  </div>
                                </div></td>
                                <td style={{ backgroundColor: '#FFBFCB'}}><div>
                                  <div>{this.state.RedMode === 'sgd' ? `${item.UplineProfit} (${item.sgdUplineProfit})` : 
                                    this.state.RedMode === 'thai' ? `${item.UplineProfit} (${item.thaiUplineProfit})` :item.UplineProfit}</div>
                                </div></td>
                                <td><div>
                                  <div>{this.state.RedMode === 'sgd' ? `${item.WaterProfit} (${item.sgdWaterProfit})` : 
                                    this.state.RedMode === 'thai' ? `${item.WaterProfit} (${item.thaiWaterProfit})` :item.WaterProfit}</div>
                                </div></td>
                                <td><div>
                                  <div>{this.state.RedMode === 'sgd' ? `${item.WinProfit} (${item.sgdWinProfit})` : 
                                    this.state.RedMode === 'thai' ? `${item.WinProfit} (${item.thaiWinProfit})` :item.WinProfit}</div>
                                </div></td>
                              </tr>
                            );
                          })}

                          {(this.state.ReportDetails || []).length > 0 && 
                        <>
                          <td colSpan="2" style={{ backgroundColor: 'black'}}>
                            <div style={{ color: 'white', fontWeight: 'bold'}}>
                              {language[this.props.currentLanguage].Total} 
                            </div>
                          </td>
                          {this.state.RedMode === 'sgd' ? this.state.TitlesValue.map((item, idx) => {
                            return(
                              <td  key={idx} style={{ backgroundColor: '#D0D0D0'}}>
                                <div style={{ fontWeight: 'bold'}}>
                                  {this.state.TotalAmount[item].toFixed(2)} ({this.state.TotalAmount[`sgd${item}`] && this.state.TotalAmount[`sgd${item}`].toFixed(2)})
                                </div>
                              </td>
                            );
                          }) : this.state.RedMode === 'thai' ? this.state.TitlesValue.map((item, idx) => {
                            return(
                              <td  key={idx} style={{ backgroundColor: '#D0D0D0'}}>
                                <div style={{ fontWeight: 'bold'}}>
                                  {this.state.TotalAmount[item].toFixed(2)} ({this.state.TotalAmount[`thai${item}`] && this.state.TotalAmount[`thai${item}`].toFixed(2)})
                                </div>
                              </td>
                            );
                          }) : Object.values(this.state.TotalAmount || {}).map((item, idx) => {
                            return(
                              <td  key={idx} style={{ backgroundColor: '#D0D0D0'}}>
                                <div style={{ fontWeight: 'bold'}}>
                                  {item.toFixed(2)}
                                </div>
                              </td>
                            );
                          })}
                        </>
                          }
                        </>}
                      </>}
                    </>
                  ) : (
                    <>
                      <tr><td colSpan="23" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '28px' }}>{language[this.props.currentLanguage].NoData}</td></tr>
                    </>
                  )}
                </tbody>
              </Table>
            </div>
                }
                {this.state.DetailsMode === false && 
            <Table responsive="sm" striped bordered hover style={{ backgroundColor: 'white'}}>
              <thead>
                <tr>
                  <th><div style={{marginLeft: 20, minWidth:  'auto'}}>{language[this.props.currentLanguage].usernameLanguage}</div></th>
                  <th><div  style={{ minWidth: 'auto' }} >{language[this.props.currentLanguage].Name}</div></th>
                  <th style={{ minWidth: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      {language[this.props.currentLanguage].Come}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('Lai')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ minWidth: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      {language[this.props.currentLanguage].DownlineEat}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('Eat')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ minWidth: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      {language[this.props.currentLanguage].Giveupline}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('Upline')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ minWidth:  'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      {language[this.props.currentLanguage].uplineWater}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('UplineWater')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ minWidth: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      {language[this.props.currentLanguage].uplineWin}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('UplineWin')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ minWidth: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      {language[this.props.currentLanguage].uplineProfit}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('UplineProfit')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ minWidth: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      {language[this.props.currentLanguage].WaterRate}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('WaterProfit')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ minWidth: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      {language[this.props.currentLanguage].ShareProfit}
                      <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('SharesProfit')}>
                        <img src={updown} alt="img" className='button2'
                          style={{
                            height: 15,
                            width: 15,
                          }}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.ShowTable === true ? (
                  <>
                    {this.state.SelfReport.map((item, idx) => {
                      return (
                        <tr key={idx} index={idx}>
                          <td>
                            <div>
                              <div>{item.Username}</div>
                            </div>
                          </td>
                          <td>
                            {this.state.minDate === this.state.maxDate ?
                              <Link 
                                to={(this.props.Role === 'player') ? `/AccountReport?id=${item.UserID}&Game=${this.state.Games}&Mode=${this.state.RedMode}` : `/IndividualDownlineReport?id=${item.UserID}&TypePlatform=${this.state.Games}&TypeMode=${this.state.RedMode}`}
                                target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                {item.Name}
                              </Link>
                              : 
                              <div>{item.Name}</div>
                            }
                          </td>
                          <td style={{ backgroundColor: '#DDDDDD'}}>
                            <div>
                              <div>{this.state.RedMode === 'sgd' ? `${item.Lai} (${item.sgdLai})` : this.state.RedMode === 'thai' ? `${item.Lai} (${item.thaiLai})` :  item.Lai}</div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#DDDDDD'}} >
                            <div>
                              <div>{this.state.RedMode === 'sgd' ? `${item.Eat} (${item.sgdEat})` : this.state.RedMode === 'thai' ? `${item.Eat} (${item.thaiEat})` : item.Eat}</div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#FFBFCB'}} >
                            <div>
                              {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                <Link 
                                  to={`/SimpleReport?id=${item.UserID}&type=upperlineBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                  target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                  {this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` : item.Upline}
                                </Link>
                                : 
                                <div>{this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` : item.Upline}</div>
                              }
                            </div>
                            {/* </div> */}
                          </td>
                          <td style={{ backgroundColor: '#FFBFCB'}} >
                            <div>
                              <div>{this.state.RedMode === 'sgd' ? `${item.UplineWater} (${item.sgdUplineWater})` : this.state.RedMode === 'thai' ? `${item.UplineWater} (${item.thaiUplineWater})` :  item.UplineWater}</div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#FFBFCB'}} >
                            <div>
                              {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                <Link 
                                  to={`/SimpleReport?id=${item.UserID}&type=upperlineWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                  target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                  {this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` : item.UplineWin}
                                </Link>
                                : 
                                <div>{this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` :item.UplineWin}</div>
                              }
                            </div>
                            {/* </div> */}
                          </td>
                          <td style={{ backgroundColor: '#FFBFCB'}} >
                            <div>
                              <div>{this.state.RedMode === 'sgd' ? `${item.UplineProfit} (${item.sgdUplineProfit})` : this.state.RedMode === 'thai' ? `${item.UplineProfit} (${item.thaiUplineProfit})` :item.UplineProfit}</div>
                            </div>
                          </td>
                          <td className="button2" style={{ backgroundColor: 'white', cursor: 'pointer'}} >
                            <div>
                              {this.state.Authority.isSub === 1 ? (
                                <div>{this.state.RedMode === 'sgd' ? `${item.WaterProfit} (${item.sgdWaterProfit})` : this.state.RedMode === 'thai' ? `${item.WaterProfit} (${item.thaiWaterProfit})` : item.WaterProfit}{item.WaterProfit}</div>
                              ) : (
                                <div onClick={() => this.OpenDetailsWater(item, 'waterProfit')}>{this.state.RedMode === 'sgd' ? `${item.sgdWaterProfit} (${item.WaterProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.thaiWaterProfit} (${item.WaterProfit})` : `${item.WaterProfit}`}</div>
                              )}
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#ffac1c'}} >
                            <div>
                              <div>{this.state.RedMode === 'sgd' ? `${item.SharesProfit} (${item.sgdSharesProfit})` : 
                                this.state.RedMode === 'thai' ? `${item.SharesProfit} (${item.thaiSharesProfit})` :item.SharesProfit}</div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.ReportDetails.length > 0 &&
                      <tr><td colSpan="10" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '20px'}}>{language[this.props.currentLanguage].Downline}</td></tr>
                    }
                    {(this.state.Authority.isSub === 0 || (this.state.Authority && this.state.Authority.ViewDownlineReport) === 1) &&<>
                      {Object.values(this.state.ReportDetails.filter((item) => Number(item.Lai) !== 0) || {}).sort((a, b) => (a[this.state.FollowMe] > b[this.state.FollowMe]) ? 1 : -1).map((item, idx) => {
                        return (
                          <tr key={idx} index={idx}>
                            <td>
                              <div>
                                {this.state.Authority.isSub === 1 ? (
                                  <>{item.Username}</>
                                ) : (
                                  <><Button className="button2" style={{ width: 100}} onClick={() => this.ChangeUpperline(item.UserID)}>{item.Username}</Button></>
                                )}
                              </div>
                            </td>
                            <td>
                              {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                <Link 
                                  to={(this.props.Role === 'player') ? `/AccountReport?id=${item.UserID}&Game=${this.state.Games}&Mode=${this.state.RedMode}` : `/IndividualDownlineReport?id=${item.UserID}&TypePlatform=${this.state.Games}&TypeMode=${this.state.RedMode}`}
                                  target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                  {item.Name}
                                </Link>
                                : 
                                <div>{item.Name}</div>
                              }
                            </td>
                            <td style={{ backgroundColor: '#DDDDDD'}}>
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.Lai} (${item.sgdLai})` : this.state.RedMode === 'thai' ? `${item.Lai} (${item.thaiLai})` : item.Lai}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#DDDDDD'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.Eat} (${item.sgdEat})` : this.state.RedMode === 'thai' ? `${item.Eat} (${item.thaiEat})` :  item.Eat}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#FFBFCB'}} >
                              <div>
                                <div>
                                  {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                    <Link 
                                      to={`/SimpleReport?id=${item.UserID}&type=upperlineBet&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                      target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                      {this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : 
                                        this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` : item.Upline}
                                    </Link>
                                    : 
                                    <div>{this.state.RedMode === 'sgd' ? `${item.Upline} (${item.sgdUpline})` : 
                                      this.state.RedMode === 'thai' ? `${item.Upline} (${item.thaiUpline})` : item.Upline}</div>
                                  }
                                </div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#FFBFCB'}} >
                              <div> 
                                <div>{this.state.RedMode === 'sgd' ? `${item.UplineWater} (${item.sgdUplineWater})` : 
                                  this.state.RedMode === 'thai' ? `${item.UplineWater} (${item.thaiUplineWater})` : item.UplineWater}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#FFBFCB'}} >
                              <div>
                                <div>
                                  {(this.state.minDate === this.state.maxDate && this.state.Authority.isSub !== 1) ?
                                    <Link 
                                      to={`/SimpleReport?id=${item.UserID}&type=upperlineWin&date=${this.state.maxDate}&RedMode=${this.state.RedMode}&TypePlatform=${this.state.Games}`}
                                      target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                      {this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : 
                                        this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` : item.UplineWin}
                                    </Link>
                                    : 
                                    <div>{this.state.RedMode === 'sgd' ? `${item.UplineWin} (${item.sgdUplineWin})` : 
                                      this.state.RedMode === 'thai' ? `${item.UplineWin} (${item.thaiUplineWin})` :item.UplineWin}</div>
                                  }
                                </div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#FFBFCB'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.UplineProfit} (${item.sgdUplineProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.UplineProfit} (${item.thaiUplineProfit})` : item.UplineProfit}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: 'white'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.WaterProfit} (${item.sgdWaterProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.WaterProfit} (${item.thaiWaterProfit})` : item.WaterProfit}</div>
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#ffac1c'}} >
                              <div>
                                <div>{this.state.RedMode === 'sgd' ? `${item.SharesProfit} (${item.sgdSharesProfit})` : 
                                  this.state.RedMode === 'thai' ? `${item.SharesProfit} (${item.thaiSharesProfit})` :item.SharesProfit}</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      {(this.state.ReportDetails || []).length > 0 &&
                      <>
                        <tr>
                          <td colSpan="2" style={{ backgroundColor: 'black'}}>
                            <div style={{ color: 'white', fontWeight: 'bold'}}>
                              {language[this.props.currentLanguage].Total}
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#D0D0D0'}}>
                            <div style={{ fontWeight: 'bold'}}>
                              <div className="ReportDetails" to={{ pathname: "/SimpleReport", data: "downlineLai" }} style={{ textDecoration: 'none' }}>
                                {this.state.RedMode === 'sgd' ? `${this.state.TotalAmount.Lai.toFixed(2)} (${this.state.TotalAmount.sgdLai.toFixed(2)})` : 
                                  this.state.RedMode === 'thai' ? `${this.state.TotalAmount.Lai.toFixed(2)} (${this.state.TotalAmount.thaiLai.toFixed(2)})` :this.state.TotalAmount.Lai.toFixed(2)}
                              </div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#D0D0D0'}} >
                            <div style={{ fontWeight: 'bold'}}>
                              <div className="ReportDetails" to={{ pathname: "/SimpleReport", data:"downlineEat"}} style={{ textDecoration: 'none' }}>
                                {this.state.RedMode === 'sgd' ? `${this.state.TotalAmount.Eat.toFixed(2)} (${this.state.TotalAmount.sgdEat.toFixed(2)})` : 
                                  this.state.RedMode === 'thai' ? `${this.state.TotalAmount.Eat.toFixed(2)} (${this.state.TotalAmount.thaiEat.toFixed(2)})` :this.state.TotalAmount.Eat.toFixed(2)}
                              </div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#D0D0D0'}} >
                            <div style={{ fontWeight: 'bold'}}>
                              <div className="ReportDetails" to={{ pathname: "/SimpleReport", data:"downlineRevenue"}} style={{ textDecoration: 'none' }}>
                                {this.state.RedMode === 'sgd' ? `${this.state.TotalAmount.Upline.toFixed(2)} (${this.state.TotalAmount.sgdUpline.toFixed(2)})` : 
                                  this.state.RedMode === 'thai' ? `${this.state.TotalAmount.Upline.toFixed(2)} (${this.state.TotalAmount.thaiUpline.toFixed(2)})` :this.state.TotalAmount.Upline.toFixed(2)}
                              </div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#D0D0D0'}} >
                            <div style={{ fontWeight: 'bold'}}>
                              <div className="ReportDetails" to={{ pathname: "/SimpleReport", data:"downlineWater"}} style={{ textDecoration: 'none' }}>
                                {this.state.RedMode === 'sgd' ? `${this.state.TotalAmount.UplineWater.toFixed(2)} (${this.state.TotalAmount.sgdUplineWater.toFixed(2)})` : 
                                  this.state.RedMode === 'thai' ? `${this.state.TotalAmount.UplineWater.toFixed(2)} (${this.state.TotalAmount.thaiUplineWater.toFixed(2)})` : this.state.TotalAmount.UplineWater.toFixed(2)}
                              </div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#D0D0D0'}} >
                            <div style={{ fontWeight: 'bold'}}>
                              <div className="ReportDetails" to={{ pathname: "/SimpleReport", data:"downlineWin"}} style={{ textDecoration: 'none' }}>
                                {this.state.RedMode === 'sgd' ? `${this.state.TotalAmount.UplineWin.toFixed(2)} (${this.state.TotalAmount.sgdUplineWin.toFixed(2)})` : 
                                  this.state.RedMode === 'thai' ? `${this.state.TotalAmount.UplineWin.toFixed(2)} (${this.state.TotalAmount.thaiUplineWin.toFixed(2)})` : this.state.TotalAmount.UplineWin.toFixed(2)}
                              </div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#D0D0D0'}} >
                            <div style={{ fontWeight: 'bold'}}>
                              <div className="ReportDetails" to={{ pathname: "/SimpleReport", data:"downlineProfit"}} style={{ textDecoration: 'none' }}>
                                {this.state.RedMode === 'sgd' ? `${this.state.TotalAmount.UplineProfit.toFixed(2)} (${this.state.TotalAmount.sgdUplineProfit.toFixed(2)})` : 
                                  this.state.RedMode === 'thai' ? `${this.state.TotalAmount.UplineProfit.toFixed(2)} (${this.state.TotalAmount.thaiUplineProfit.toFixed(2)})` : this.state.TotalAmount.UplineProfit.toFixed(2)}
                              </div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#D0D0D0'}} >
                            <div style={{ fontWeight: 'bold'}}>
                              <div className="ReportDetails" to={{ pathname: "/SimpleReport", data:"selfEat"}} style={{ textDecoration: 'none' }}>
                                {this.state.RedMode === 'sgd' ? `${this.state.TotalAmount.WaterProfit.toFixed(2)} (${this.state.TotalAmount.sgdWaterProfit.toFixed(2)})` : 
                                  this.state.RedMode === 'thai' ? `${this.state.TotalAmount.WaterProfit.toFixed(2)} (${this.state.TotalAmount.thaiWaterProfit.toFixed(2)})` : this.state.TotalAmount.WaterProfit.toFixed(2)}
                              </div>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#D0D0D0'}} >
                            <div style={{ fontWeight: 'bold'}}>
                              <div className="ReportDetails" to={{ pathname: "/SimpleReport", data:"selfEat"}} style={{ textDecoration: 'none' }}>
                                {this.state.RedMode === 'sgd' ? `${this.state.TotalAmount.SharesProfit.toFixed(2)} (${this.state.TotalAmount.sgdSharesProfit.toFixed(2)})` : 
                                  this.state.RedMode === 'thai' ? `${this.state.TotalAmount.SharesProfit.toFixed(2)} (${this.state.TotalAmount.thaiSharesProfit.toFixed(2)})` : this.state.TotalAmount.SharesProfit.toFixed(2)}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                      }
                    </>}
                  </>
                ) : (
                  <>
                    <tr><td colSpan="10" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '28px'}}>{language[this.props.currentLanguage].NoData}</td></tr>
                  </>
                )}
              </tbody>
            </Table>
                }
                {this.state.tongEwDetails.length > 0 && 
            <div style={{ width: '100%', overflowX: 'auto', marginBottom: 10 }}>
              <div style={{ fontWeight: 'bold', marginTop: 5, textAlign: 'left', paddingLeft: '2%', fontSize: '20px'}}>{'=Tong Report='}</div>
              <Table responsive="sm" bordered hover style={{ backgroundColor: 'white', width: '50%'}}>
                <thead style={{ backgroundColor: '#ffff00' }}>
                  <tr>
                    <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].Account}</th>
                    <th style={{ minWidth: 150 }}>{language[this.props.currentLanguage].Come}</th>
                    <th style={{ minWidth: 150 }}>{language[this.props.currentLanguage].Commission}</th>
                    <th style={{ minWidth: 150 }}>{language[this.props.currentLanguage].Win}</th>
                    <th style={{ minWidth: 150 }}>{language[this.props.currentLanguage].totalProfit}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tongEwDetails.map((tongitems, tongindex) => {
                    return(
                      <tr key={tongindex} style={{ backgroundColor: '#ffff00' }}>
                        <td style={{ fontWeight: 'bold' }}>{tongitems.username}</td>
                        <td style={{ fontWeight: 'bold' }}>{tongitems.lai}</td>
                        <td style={{ fontWeight: 'bold' }}>{tongitems.water}</td>
                        <td style={{ fontWeight: 'bold' }}>{tongitems.win}</td>
                        <td style={{ fontWeight: 'bold' }}>{tongitems.profit}</td>
                      </tr>
                    );
                  })}
                  <tr style={{ backgroundColor: '#dddddd' }}>
                    <td style={{ fontWeight: 'bold' }}>TOTAL</td>
                    <td style={{ fontWeight: 'bold' }}>{this.state.SelfReport[0].Shares}</td>
                    <td style={{ fontWeight: 'bold' }}>{this.state.SelfReport[0].SharesWater}</td>
                    <td style={{ fontWeight: 'bold' }}>{this.state.SelfReport[0].SharesWin}</td>
                    <td style={{ fontWeight: 'bold' }}>{this.state.SelfReport[0].SharesProfit}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
                }
                {this.state.TongDetails.length > 0 && 
            <div style={{ width: '100%', overflowX: 'auto', marginBottom: 10 }}>
              <Table responsive="sm" bordered hover style={{ backgroundColor: 'white', width: '65%'}}>
                <thead style={{ backgroundColor: 'lightgreen' }}>
                  <tr>
                    <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].Account}</th>
                    <th style={{ minWidth: 150 }}>{language[this.props.currentLanguage].Date}</th>
                    <th style={{ minWidth: 150 }}>{language[this.props.currentLanguage].Come}</th>
                    <th style={{ minWidth: 150 }}>{'Share(%)'}</th>
                    <th style={{ minWidth: 150 }}>{'Net($)'}</th>
                    <th style={{ minWidth: 180 }}>{'Calculate(%)'}
                      <input 
                        style={{ width: 90, textAlign: 'center', fontWeight: 'bold'}}
                        value={this.state.CalculatePercent}
                        type="Number"
                        maxLength={3}
                        // onKeyDown={(e) => this.ClickEnter(e)}
                        onChange={(e)=> this.setState({ CalculatePercent: e.target.value})}/>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.TongDetails.map((tongitems, tongindex) => {
                    return(
                      <tr key={tongindex}>
                        <td style={{ fontWeight: 'bold' }}>{tongitems.username}</td>
                        <td>
                          {tongitems.tongArray.map((arraylist, arrayind) => {
                            return(
                              <div key={arrayind} style={{ fontWeight: 'bold' }}>{arraylist.Date}</div>
                            );
                          })}
                          <div style={{ backgroundColor: 'orange', fontWeight: 'bold'}}>{language[this.props.currentLanguage].ReportLai}{': '}</div>
                        </td>
                        <td>
                          {tongitems.tongArray.map((arraylist, arrayind) => {
                            return(
                              <div key={arrayind} style={{ fontWeight: 'bold' }}>{arraylist.lai}</div>
                            );
                          })}
                          <div style={{ backgroundColor: 'orange', fontWeight: 'bold'}}>{tongitems.totalLai.toFixed(2)}</div>
                        </td>
                        <td>
                          {tongitems.tongArray.map((arraylist, arrayind) => {
                            return(
                              <div key={arrayind} style={{ fontWeight: 'bold' }}>{arraylist.share}</div>
                            );
                          })}
                          <div style={{ backgroundColor: 'orange', fontWeight: 'bold'}}>{language[this.props.currentLanguage].totalNet}{'$: '}</div>
                        </td>
                        <td>
                          {tongitems.tongArray.map((arraylist, arrayind) => {
                            return(
                              <div key={arrayind} style={{ fontWeight: 'bold' }}>{arraylist.net}</div>
                            );
                          })}
                          <div style={{ backgroundColor: 'orange', fontWeight: 'bold'}}>{tongitems.totalNet.toFixed(2)}</div>
                        </td>
                        <td>
                          {tongitems.tongArray.map((arraylist, arrayind) => {
                            return(
                              <div className='dcc' style={{ fontWeight: 'bold'}}>
                                {((arraylist.net / 100) * Number(this.state.CalculatePercent)).toFixed(2)}
                              </div>
                            );
                          })}
                          <div style={{ backgroundColor: 'orange', fontWeight: 'bold'}}>{((tongitems.totalNet / 100) * Number(this.state.CalculatePercent)).toFixed(2)}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
                }
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.state.modalShow}
                  contentClassName="br23"
                >
                  <Modal.Body className="dcc" style={{ padding: 10, height: 'auto' }}>
                    <div style={{flexDirection: 'row', width:'auto'}}>
                      {this.state.Editloading  === true ? ( 
                        <div className="divLoader">
                          <img alt=""  style={{ marginTop: 50, width: 200, height:200, marginRight:0}} src={'https://i.imgur.com/GLdqYB2.gif'}/>
                        </div>
                      ) : ( 
                        <div className="dcc" style={{ marginTop: 20, marginBottom: 20, width:'80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>From</div>
                          <DatePicker
                            onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                            dateFormat = 'dd-MMM-yyyy'
                            placeholder="Date"
                            maxDate={new Date()}
                            // popperPlacement="top-right"
                            selected={this.state.Max_Date}
                            className={"form-control StyleDatePicker"}
                            // withPortal
                          />
                          <div style={{fontSize: "18px", width: 50,  textAlign: 'center'}}>To</div>
                          <DatePicker
                            onChange = {(date) => this.setSelectedDate(date)}
                            dateFormat = 'dd-MMM-yyyy'
                            placeholder="Date"
                            maxDate={new Date()}
                            minDate={new Date(this.state.Max_Date)}
                            // popperPlacement="top-right"
                            selected={this.state.Min_Date}
                            className={"form-control StyleDatePicker"}
                            // withPortal
                          />
                        </div>
                      )}
                      <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                        <div>
                          <button style={{ fontSize: "18px", textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: 300 }} 
                            disabled={this.state.disabled}
                            onClick={() => this.setState({modalShow: false, Available: true}, ()=> {
                              this.getReport();
                            })} 
                            className="btn my-cusomized-button button2" >
                      提交
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            )}
          </div>
        </>
        }
      </Fragment>    
    )
  }
}


function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
    UserAuthority: state.UserAuthority,
    isSub: state.isSub,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Games);
