const initialState = {
  currentLanguage: 'cn',
  token: '',
  Role: '',
  username: '',
  userID: '',
  SelectedDate: '',
  Min_Date: '',
  Max_Date: '',
  isSub: '',
  UserAuthority: {},
  ViewUserTable: 1,
  BooleanBack: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
  case 'SET_LANGUAGE':
    // Language
    localStorage.setItem('currentLanguage', action.payload)
    return {
      ...state,
      currentLanguage: action.payload,
    };
  case 'HRTABLE_VIEW':
    // Language
    localStorage.setItem('ViewUserTable', action.payload)
    return {
      ...state,
      ViewUserTable: action.payload,
    };
  case 'SELECTED_DATE':
    localStorage.setItem('SELECTED_DATE', action.payload)
    return {
      ...state,
      SelectedDate: action.payload,
    };
  case 'MINSET_DATE':
    localStorage.setItem('Min_Date', action.payload)
    return {
      ...state,
      Min_Date: action.payload,
    };
  case 'MAXSET_DATE':
    localStorage.setItem('Max_Date', action.payload)
    return {
      ...state,
      Max_Date: action.payload,
    };
  case 'BACK_URL':
    return {
      ...state,
      BooleanBack: action.payload,
    };
  case 'AUTHO_RITY':
    return {
      ...state,
      UserAuthority: action.payload.UserAuthority,
    };
    // Login Details
  case 'LOGIN_STATUS':
    return {
      ...state,
      username: action.payload.username,
      Role: action.payload.Role,
      token: action.payload.token,
      userID: action.payload.userID,
      isSub: action.payload.isSub,
    };
  default:
    return state;
  }
}

export default reducer
