import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import { language } from '../../language';
import { language } from '../../language';
// import { isMobile } from 'react-device-detect';
import {  Table, Button,  Alert } from 'react-bootstrap';
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
const { cashWalletHistory } = require('../../Api');
var timer

class Virtual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cashWallet: [],
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      setTimer: 5000,
    }
  }

  async componentDidMount(){
    this.RuncashWalletHistory();
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.WalletHistoryModal !== prevProps.WalletHistoryModal &&
      this.props.WalletHistoryModal
    ) {
      this.RuncashWalletHistory();
    }
  }


  async RuncashWalletHistory(){
    const cashWalletHistoryResult = await cashWalletHistory(this.props.username, this.props.UserID ,this.props.token)
    if (cashWalletHistoryResult.error === 'Invalid selectedID') {
      // ignore
    } else if (cashWalletHistoryResult.message === 'No record') {
      this.setState({notificationType: 'error'}, ()=>{
        this.openNotification(cashWalletHistoryResult.message)
      })
    } else {
      this.setState({cashWallet: cashWalletHistoryResult})
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        setTimer: 5000,
      });
    }, this.state.setTimer);
  };

  render() {
    return (
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        style={{ paddingTop: (this.state.cashWallet).length === 0 ? 150 : 20}}
        show={this.props.WalletHistoryModal}
        contentClassName="br23">
        <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
          <GrFormClose
            style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
            onClick={() => {
              this.props.propsSetState({ 
                WalletHistoryModal: false,
              })
            }}
            size="35px"
            color="#000"
          />
          <div className="dcc" style={{ justifyContent: 'center' }}>
            <Alert className="dcc" style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width:  '70%', left: 250}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
              <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
            </Alert>
          </div>
          <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
            <b className="dcc" style={{ padding: 20, fontSize: 23}}>
              {language[this.props.currentLanguage].History}
            </b>
            {((this.state.cashWallet).length !== 0) && <div style={{ marginBottom: '3%', overflowY: 'auto', height: ((this.state.cashWallet).length < 6) ? 400 : 600,}}>
              <Table responsive="sm" striped bordered hover>
                <thead>
                  <tr>
                    <th><div style={{ width: 200 }}>{'No. '}</div></th>
                    <th><div style={{ width: 200 }}>{language[this.props.currentLanguage].DateTime}</div></th>
                    <th><div style={{ width: 200 }}>{language[this.props.currentLanguage].BeforeEdit}</div></th>
                    <th><div style={{ width: 200 }}>{language[this.props.currentLanguage].AfterEdit}</div></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.cashWallet.map((item, indexs) => {
                    return <tr key={indexs}>
                      <td style={{ verticalAlign: 'middle' }}>{indexs + 1}</td>
                      <td style={{ verticalAlign: 'middle' }}>{moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td style={{ verticalAlign: 'middle' }}>{item.Before}</td>
                      <td style={{ verticalAlign: 'middle' }}>{item.After}</td>
                    </tr>
                  })}
                </tbody>
              </Table>
            </div>}
            <div className="dcc">
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" 
                onClick={() => this.props.propsSetState({ 
                  WalletHistoryModal: false,
                })}>
                {language[this.props.currentLanguage].Shutdown}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

export default connect(mapStateToProps, null)(Virtual);
