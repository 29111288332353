import '../App.css';
import React from 'react';
import { connect } from 'react-redux'
import Header2 from '../Component/Header';
import Modal from 'react-bootstrap/Modal';
import Attentions from '../Images/Attention2.png';
import Successign from '../Images/Success1.gif';
import ErrorSign from '../Images/errorsign.gif';
import { Button, Card } from 'react-bootstrap';
import { language } from '../language';
const { getSMSGroupFNC, postSMSGroupFNC } = require('../Api');
var timer

class SendSMSGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayConfirmModal: false,
      Message: '',
      // Phone: ['0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '0123456789', '012333333333', '01345645645', '01452525698', '1234568488', '454545454545'],
      Phone: '',
      ShowMessage: '',
    }
  }

  async componentDidMount(){
    this.getMessage();
  }

  async getMessage() {
    const getMess = await getSMSGroupFNC(this.props.username, this.props.token)
    // console.log('getMess', getMess)
    if (getMess) {
      this.setState({ Message: getMess[0].message, Phone: getMess[0].phone })
    }
  }

  async SendMessageApi() {
    const PostMessage = await postSMSGroupFNC(this.state.Message, this.state.Phone, this.props.username, this.props.token)
    // console.log('PostMessage', PostMessage)
    if (PostMessage.message === 'Successful send') {
      this.setState({ displayConfirmModal: false }, () => {
        this.openNotification('SuccessFul Send')
      })
    }
    if (PostMessage.message === 'No valid phone number') {
      this.setState({ displayConfirmModal: false }, () => {
        this.openNotification('No valid phone number exist')
      })
    }
  }

  handleClose() {
    this.setState({
      displayConfirmModal: false,
    })
  }

  Changevalue(event, index) {
    // const Phone = this.state.Phone
    // const arrPhone = [...Phone];
    // arrPhone.splice(index, 0, event.target.value);
    // this.setState({ Phone: arrPhone }, () => {
    //   console.log('Phone', this.state.Phone)
    // })
    const Phone = Array.from(this.state.Phone);
    Phone[index] = event.target.value;
    this.setState({Phone: Phone});
  }

  openNotification(message) {
    this.setState({ ShowMessage: message });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        ShowMessage: '',
      });
    }, 3000);
  };
  
  render() {
    return(
      <div style={{ width: '100%', backgroundColor: '#EFEFEF', minHeight: 950, flexDirection: 'column' }}>
        {/* <Header /> */}
        <Header2 />
        {this.state.displayConfirmModal && 
        <Modal centered show={this.state.displayConfirmModal} size="modal-sm" onHide={() => this.handleClose()}>
          <Modal.Body className='ModalMEssage' style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', fontSize: '16px', backgroundImage: 'linear-gradient(to right, #815334, #8e6448, #A87453, #6f4e38)'}}>
            <div id="appear" style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 , alignItems: 'center', color: 'white' }}>
              <img src={Attentions} alt="Logo" className='AttentionsLogo' style={{ marginTop: 10, marginBottom: 10 }} />
              <div style={{ fontSize: '17px'}}>
                <div>{`${language[this.props.currentLanguage].ConfirmSendMessage} ${language[this.props.currentLanguage].ToPhone}`}</div>
                {/* <div className='dcc' style={{ marginLeft: 5, marginRight: 5, fontFamily: 'cursive', color: 'black'}}>{`ssss`}</div> */}
              </div>
            </div>
            <div style={{ marginTop: 15 }}>
              <Button variant="success" style={{ marginRight: 10, paddingLeft: 20, paddingRight: 20 }} onClick={() => this.SendMessageApi()}>{language[this.props.currentLanguage].SendMessage}</Button>
              <Button variant="danger" style={{ paddingLeft: 20, paddingRight: 20 }} onClick={() => this.handleClose()}>{`Cancel`}</Button>
            </div>
          </Modal.Body>
        </Modal>}
        <div>{language[this.props.currentLanguage].SendSMSGroup}</div>
        {this.state.ShowMessage === 'SuccessFul Send' && 
        <div className='dcc' style={{ color: '#77B43F', fontSize: '17px', fontWeight: 'bold' }}><img src={Successign} alt="Logo" className='SuccessSign' />{this.state.ShowMessage}</div>}
        {this.state.ShowMessage === 'No valid phone number exist' && 
        <div className='dcc' style={{ color: 'red', fontSize: '17px', fontWeight: 'bold' }}><img src={ErrorSign} alt="Logo" className='SuccessSign' />{this.state.ShowMessage}</div>}
        <div className='dcc' style={{ width: 'calc(100% / 1)', marginBottom: 20, display: 'flex', flexDirection: 'row', padding: 50 }}>
          <Card style={{ width: 'calc(100% / 3)', minHeight: '73vh'}}>
            <Card.Header as="h5" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].Message}</Card.Header>
            <Card.Body>
              <textarea
                class="form-control"
                style={{ width: '100%',  fontWeight: 'bold', height: '60vh' }}
                value={this.state.Message}
                readOnly={false}
                onChange={(e) => this.setState({ Message: e.target.value })}
              />
            </Card.Body>
          </Card>
          <Card style={{ width: 'calc(100% / 3)', minHeight: '73vh'}}>
            <Card.Header as="h5" style={{ fontWeight: 'bold' }}>{`${language[this.props.currentLanguage].Phone}`}</Card.Header>
            <Card.Body>
              {/* <div className='dcc scrollhost' style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', height: '60vh', maxHeight: '60vh', overflowY: 'auto' }}>
                {this.state.Phone.map((items, index) => {
                  return(
                    <div className='dcc' key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                      <Form.Control style={{ Width: 'auto', maxWidth: '160px', textAlign: 'center', margin: 4 }} type="text" value={items} onChange={(e) => this.Changevalue(e, index)}/>
                    </div>
                  );
                })}
              </div> */}
              <textarea
                class="form-control"
                style={{ width: '100%',  fontWeight: 'bold', height: '60vh' }}
                value={this.state.Phone}
                readOnly={false}
                onChange={(e) => this.setState({ Phone: e.target.value })}
              />
            </Card.Body>
          </Card>
          <div style={{ minHeight: '73vh', position: 'relative', paddingLeft: 20, width: 'auto' }}>
            <div className='SendButton' 
              style={{ border: '1px solid black', borderRadius: 10, padding: 10, position: 'absolute', bottom: 0, cursor: 'pointer' }} onClick={() => this.setState({ displayConfirmModal: true  })}>
              {language[this.props.currentLanguage].SendMessage}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default Setting;

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date,
    Min_Date: state.Min_Date,
    isSub: state.isSub,
    Role: state.Role,
    UserAuthority: state.UserAuthority,
    username: state.username,
    token: state.token,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendSMSGroup)
