import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
import { connect } from 'react-redux';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import EditAdmin from '../Component/EditAdmin';
import { FiRefreshCcw } from '@react-icons/all-files/fi/FiRefreshCcw';
import { Tab, Form, Button, Table, Alert } from 'react-bootstrap';
import { language } from '../language';
import { ImArrowLeft, ImArrowRight } from 'react-icons/im';
// import { isMobile } from 'react-device-detect';
import moment from 'moment-timezone';
import JSEncrypt from 'jsencrypt';
import Modal from 'react-bootstrap/Modal';
import { Platformcolor } from '../static/PlatformColor';
// api getPackageNames
const { getUsers, updateUser, verifyUsername, superAdminSearch, getallAdmin, getConfiguration, UpdateConfiguration, createUser, chgPwd, getPublicKey, DeleteUsers, UnlockUsers } = require('../Api');
var timer

class SuperAdminManagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      UserID: this.props.userID,
      visibleForm: false,
      createvisibleForm: false,
      formData: {},
      CreateformData: {},
      activeKey: "",
      oriFormData: {},
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      showNotificationpass: false,
      parentLoading: false,
      packageNames: [],
      AllowSaveUsername: false,
      AllowUsername: '',
      OwnerDetail: [],
      SearchUser: '',
      PreviousBool: true,
      BackToUpperline: '',
      SetPackage: '',
      NewSetPackage: '',
      Mode: '',
      hideEditForm: false,
      AdminuserDetails: [],
      page: 1,
      countperpage: 10,
      countpagemax: 1,
      countpagemin: 1,
      visibleSetGameLimit: false,
      GamesDetail: ['Default'],
      Selfs: [],
      GameLimitPackage: {Default: { all: '', B: '', S: '', SA: '', SD: '', CA: '', CD: '', SB: '', SC: '', SE: '', SF: '', "5D": '', "6D": '' }},
      Configuration: [],
      CreateAdminAccount: false,
      OpenChangePassword: false,
      CurrentPass: '',
      ConfirmPass: '',
      NewPass: '',
      ShowPassword: false,
      ShowPasswordCon: false,
      ShowPasswordNew: false,
      ShowPassWordCheck: false,
      OpenDeletePassword: false,
      showDeletepass: false,
      UserUsername: '',
      UserPassword: '',
      DeleteUSerID: '',
      CheckUsername: '',
      CheckPassword: '',
    }
    this.dataChange = this.dataChange.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.toggleEditForm = this.toggleEditForm.bind(this)
    // this.resetPackages = this.resetPackages.bind(this)
    this.CheckingUsername = this.CheckingUsername.bind(this)
    this.ClickCreateNewUser = this.ClickCreateNewUser.bind(this)
    this.CloseEWPackage = this.CloseEWPackage.bind(this)
    this.SetPackageGame = this.SetPackageGame.bind(this)
    this.EditData = this.EditData.bind(this)
    this.EditForm = this.EditForm.bind(this)
    this.ApiRefresh = this.ApiRefresh.bind(this)
  }

  async componentDidMount() {
    this.ApiRefresh();
    this.SetUsersAuthorities();
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  async callConfigurationData() {
    let ConfiArray = {}
    // {Default: { all: '', B: '', S: '', A1: '', A1C: '', A: '', C: '', A2: '', A3: '', D4: '', E4: '', "5D": '', "6D": '' }}
    const getGameLimitData = await getConfiguration(this.props.token, this.props.username)
    // console.log('getGameLimitData', getGameLimitData)
    let Array = []
    Array.push(Object.keys(getGameLimitData[0].DefaultLimit))
    if(getGameLimitData) {
      const GameLimit = getGameLimitData[0].DefaultLimit
      ConfiArray = {
        ...GameLimit,
      }
    }
    this.setState({ GameLimitPackage: ConfiArray, visibleSetGameLimit: true, hideEditForm: false, visibleForm: false, CreateAdminAccount: false })
  }

  async ApiRefresh() {
    const page = this.state.page
    const countperpage = this.state.countperpage

    const AdminDetails = await getallAdmin(this.state.UserID, page, countperpage ,this.props.token, this.props.username)

    let selfProfitReport = {};
    const filtered = Object.values(AdminDetails).filter((item) => {
      if (item.ID !== this.state.UserID) {
        return true;
      } else {
        selfProfitReport = item || '';
        return false;
      }
    });
    
    filtered.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
    this.setState({ users: filtered || [] })

    if(selfProfitReport.ID !== undefined){
      this.setState({ Selfs: [selfProfitReport] || [] })
    } else {
      this.setState({ Selfs: [] })
    }

    if (filtered.length > 0) {
      this.setState({
        countpagemax: Math.ceil(
          filtered[0].FullCount / this.state.countperpage,
        ),
      });
    } else {
      this.setState({
        countpagemax: 1,
        page: 1,
      });
    }
  }

  EditData(e, EditStatus){
    if (this.state.Mode === 'Create User') {
      this.dataChange(EditStatus, e.target.value, undefined ,'CreateformData')
    } else {
      this.dataChange(EditStatus, e.target.value, undefined ,'formData')
      this.updateUserF(this.state.formData.ID, EditStatus, e.target.value)
    }
  }

  async toggleEditForm(formData) {
    if (this.state.hideEditForm) {
      this.setState({ 
        hideEditForm: false,
        data: {},
      })
    } else {
      this.setState({ 
        hideEditForm: true,
        CreateAdminAccount: false,
        AdminuserDetails: [formData],
        data: JSON.parse(JSON.stringify(formData)),
        visibleForm: false,
        visibleSetGameLimit: false,
      }, ()=> {
        this.FocusHere.focus();
      })
    }
  }

  async OpenNewForm(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)

    if(UserAuthorities.CreateUser === 1) {
      const createForm = {
        ActivePackageID: null,
        DailyLimit: 0, 
        EwPackage: {"Default": {"CA": {"amount": "0", "percent": "0"}, "B": {"amount": "0", "percent": "0"}, "CD": {"amount": "0", "percent": "0"}, "S": {"amount": "0", "percent": "0"}, "5D": {"amount": "0", "percent": "0"}, "6D": {"amount": "0", "percent": "0"}, "SA": {"amount": "0", "percent": "0"}, "SB": {"amount": "0", "percent": "0"}, "SC": {"amount": "0", "percent": "0"}, "SE": {"amount": "0", "percent": "0"}, "SF": {"amount": "0", "percent": "0"}, "SD": {"amount": "0", "percent": "0"}, "all": ""}}, 
        FilteredKeyIn1:['B', 'S', 'CD', 'CA', 'SA', 'SD'], 
        KeyIn1: "[\"B\", \"S\", \"CD\", \"CA\", \"SA\", \"SD\"]",
        KeyIn2: "[\"SA\", \"SB\", \"SC\", \"SD\", \"SE\", \"SF\"]",
        LastOrderID: "",
        LastUpdateDateTime: new Date(),
        LoginAttempt: 0,
        MultiplyDivide: "M",
        NextDownlineID: "",
        OcPackage: null,
        Password: "",
        Name: '',
        PrinterAddress: "",
        PrinterModel: "",
        Role: "admin",
        Status: "Active",
        MataLock: 0,
        Username: "",
        GameDisplay: 'MKTSWBD',
        Upperline: 'C',
        EwF: "-",
        Tong: 0,
        AbleRed: 0,
        RedPercent: 0,
        JinNan: 0,
      }
      if (this.state.CreateAdminAccount) {
        this.setState({ 
          CreateAdminAccount: false,
          formData: {},
          oriFormData: {},
          FormKey2: [],
          FormKey1: [],
          parentLoading: false,
          Mode: ''
        })
      } else {
        this.setState({ 
          CreateAdminAccount: true,
          parentLoading: true,
          hideEditForm: false,
          visibleSetGameLimit: false,
          activeKey: "NoEatWord",
          AllowSaveUsername: false,
          CreateformData : JSON.parse(JSON.stringify(createForm)),
          oriFormData: JSON.parse(JSON.stringify(createForm)),
          FormKey1: JSON.parse(createForm.KeyIn1),
          FormKey2: JSON.parse(createForm.KeyIn2),
          Mode: 'Create User',
          SetPackage: '',
          SetPackage2: '',
        }, async()=>{
          this.FocusUsername.focus();
          this.setState({ packageNames: [], parentLoading: false })
        })
      }
    } else {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].DontHaveAutho}`)
      });
    }
  }

  // async resetPackages() {
  //   this.setState({ packageNames: [] })
  //   let packageNames = await getPackageNames(this.props.username, this.props.userID, this.state.formData.ID, this.props.token)
  //   packageNames = packageNames.sort(function(a,b){
  //     if ((a.childPackage === null) === (b.childPackage === null)) {
  //       return a.Name.localeCompare(b.Name);
  //     }
  //     return (a.childPackage === null) - (b.childPackage === null)
  //   })
  //   const tempActiveKey = this.state.activeKey.slice()
  //   this.setState({ packageNames, parentLoading: false, activeKey: '' }, () => {
  //     this.setState({ activeKey: tempActiveKey })
  //   })
  // }

  resetForm() {
    this.setState({ 
      formData: JSON.parse(JSON.stringify(this.state.oriFormData)),
    })
  }

  dataChange(field, value, idx, Mode) {
    if (field !== 'Username' && Mode === 'CreateformData' && this.state[Mode].Username !== ''){
      if (this.state.AutoCheck !== true){
        this.setState({AutoCheck: true }, ()=>{
          this.CheckingUsername(this.state.CreateformData.Username)
        })
      }
    }
    if (field === 'KeyIn1') {
      const currentKeyInArray = this.state.FormKey1
      currentKeyInArray[idx] = (value.toUpperCase()).replace(/[^PBSCADEF1234]/g, '')
      this.setState({ 
        [Mode]: {
          ...this.state[Mode],
          KeyIn1: JSON.stringify(currentKeyInArray),
          FormKey1: currentKeyInArray,
        },
      })
    } else if(field === 'KeyIn2') {
      const currentKeyInArray = this.state.FormKey2
      // console.log('currentKeyInArray', currentKeyInArray);
      currentKeyInArray[idx] = (value.toUpperCase()).replace(/[^PBSCADEF1234]/g, '')
      this.setState({ 
        [Mode]: {
          ...this.state[Mode],
          KeyIn2: JSON.stringify(currentKeyInArray),
          FormKey2: currentKeyInArray,
        },
      })
    }  else if (field === 'Packages') {
      const currentPackages = this.state[Mode].Packages
      currentPackages[idx] = value
      this.setState({ 
        [Mode]: {
          ...this.state[Mode],
          Packages: currentPackages,
        },
      })
    } else if (field === 'DailyLimit') {
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: value.replace(/[^0-9]/g, ''),
        },
      })
    } else if (field === 'PrinterAddress') {

      const e = value
        .replace(/\D+/g, '.') // replace non digit with comma
        .replace(/^,|,$/g, '') // trim the comma
    
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: e,
        },
      })
    } else if (field === 'AbleRed'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, RedNumb)
      })
    } else if (field === 'JinNan'){
      const RedNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: RedNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, RedNumb)
      })
    } else if (field === 'Tong'){
      const TongNumb = (value === false) ? 0 : 1
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: TongNumb,
        },
      }, ()=>{
        this.updateUserF(this.state.formData.ID, field, TongNumb)
      })
    } else if (field === 'RedPercent'){
      const e = value
        .replace(/\D+/g, '.') // replace non digit with comma
        .replace(/^,|,$/g, '') // trim the comma
 
      var output = e.split('.');
      output = output.shift() + (output.length ? '.' + output.join('') : '');
    
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: output,
        },
      })
    } else {
      this.setState({
        [Mode]: {
          ...this.state[Mode],
          [field]: value,
        },
      })
    }
  }

  dataCreate(field, value, idx) {
    if (field === 'KeyIn1') {
      const currentKeyInArray = this.state.CreateformData.FilteredKeyIn1
      currentKeyInArray[idx] = (value.toUpperCase()).replace(/[^PBSCADEF1234]/g, '')
      this.setState({ 
        CreateformData: {
          ...this.state.CreateformData,
          KeyIn1: JSON.stringify(currentKeyInArray),
          FilteredKeyIn1: currentKeyInArray,
        },
      })
    }
    else if (field === 'DailyLimit') {
      this.setState({
        CreateformData: {
          ...this.state.CreateformData,
          [field]: value.replace(/[^0-9]/g, ''),
        },
      })
    } else if (field === 'PrinterAddress') {

      const e = value
        .replace(/\D+/g, '.') // replace non digit with comma
        .replace(/^,|,$/g, '') // trim the comma
    
      this.setState({
        CreateformData: {
          ...this.state.CreateformData,
          [field]: e,
        },
      })
    } else {
      this.setState({
        CreateformData: {
          ...this.state.CreateformData,
          [field]: value,
        },
      })
    }
  }


  async updateUserF(userID, field, value) {
    if (userID !== null ) {
      if(value === '') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(`${field} 不能为空`)
        });
      } else {
        const result = await updateUser(this.props.username, this.props.token, userID, field, value)
        if (result.message && result.message === '成功更新') {
          this.setState({ notificationType: 'success' }, async () => {
            this.openNotification(`${language[this.props.currentLanguage].UpdateSuccess}`)
            let users = await getUsers(this.props.username, this.props.token, this.props.userID)
    
            for(let i = 0; i < users.length; i += 1) {
              users[i].FilteredKeyIn1 = JSON.parse(users[i].KeyIn1)
            }
            this.setState({ users, AllowSaveUsername: false, AllowUsername: '', OwnerDetail: [users[0]] || []})
          });
        } else if (result.error === 'Non-admin cannot cancel') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].NonAdminXcancel)
          });
        } else if (result.error === 'Game Closed, cannot update package') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].GameClosePackage)
          });
        } else if (result.error === 'Upperline not same package') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].UpperlineNotSamePackage)
          });
        } else if (result.error === 'Downline not same package') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].DownlineNotSamePackage)
          });
        } else if (result.message === 'Unable active due to have pending order') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(language[this.props.currentLanguage].UnableActiveDueToPendig)
          });
        }   else {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification(result.error)
          });
        }
      }
    }
  }

  onKeyEnter(event, userID, field, value) {
    if (event.key === 'Enter') {
      if (this.state.Mode !== 'Create User'){
        if (field === 'Username') {
          if (this.state.AllowSaveUsername === true) {
            if( this.state.AllowUsername === value){
              this.updateUserF(userID, field, value)
            } else {
              this.setState({ notificationType: 'error' }, () => {
                this.openNotification(language[this.props.currentLanguage].PleaseCheckAgain)
              });
            }
          } else {
            this.setState({ notificationType: 'error' }, () => {
              this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsClickInquire}`)
            });
          }
        } else if ( field === 'KeyIn1') {
          //// game format validation
          const valueKey = JSON.parse(value || []);
  
          const Check0 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[0]);
          const Check1 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[1]);
          const Check2 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[2]);
          const Check3 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[3]);
          const Check4 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[4]);
          const Check5 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[5]);
          if  (Check0.includes(true) === false || 
        Check1.includes(true) === false || 
        Check2.includes(true) === false || 
        Check3.includes(true) === false || 
        Check4.includes(true) === false || 
        Check5.includes(true) === false) {
            this.setState({ notificationType: 'error', AllowSaveUsername: false }, () => {
              this.openNotification('Invalid Key')
            });
          } else {
            const valueKey = JSON.parse(value || []);
            const uniqueNamesArray = valueKey.filter((v,i,a)=>a.findIndex(t=>(t === v && t===v))===i)
            for (let i = 0; i < 6; i += 1) {
           
              if ((uniqueNamesArray[i] || '') === '' ){
                uniqueNamesArray[i] = ''
              }
            }
            this.updateUserF(userID, field, JSON.stringify(uniqueNamesArray))
          }
        } else {
          this.updateUserF(userID, field, value)
        } 
      } 
    }
  }

  onKeyEnterDelete(e) {
    if (e.charCode === 13) {
      document.getElementById("ChangePassButton").click()
    }
  }

  async CheckingUsername(CheckName){
    if(CheckName !== '') {
      const CheckingResultUsername =  await verifyUsername(this.props.username, CheckName, this.props.token)
      if (CheckingResultUsername.message !== 'Not Available') {
        this.setState({ notificationType: 'success', AllowSaveUsername: true, AllowUsername: CheckName }, () => {
          if (this.state.Mode !== 'Create User') {
            this.updateUserF(this.state.formData.ID, 'Username', this.state.AllowUsername)
          }
          this.openNotificationMessageCheck(`${language[this.props.currentLanguage].Avaiable}`)
        });
      } else {
        this.setState({ notificationType: 'error', AllowSaveUsername: false }, () => {
          this.openNotificationMessageCheck(`${language[this.props.currentLanguage].NotAvailable}`)
        });
      }
    } else {
      this.setState({ notificationType: 'error', AllowSaveUsername: false }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsinsertName}`)
      });
    }
  }

  async CloseEWPackage(item){
    this.setState({ 
      activeKey: '',
    })
  }

  EditForm() {
    this.setState({
      hideEditForm: false
    })
  }

  SetPackageGame(SetPackage){
    if(SetPackage === "SetA"){
      const SetAPackage = ['B', 'S', 'CD', 'CA', 'SA', 'SD']
      this.setState({ 
        formData: {
          ...this.state.formData,
          KeyIn1: JSON.stringify(SetAPackage),
          FilteredKeyIn1: SetAPackage,
        },
        SetPackage
      }, ()=>{
        this.updateUserF(this.state.formData.ID, 'KeyIn1', JSON.stringify(this.state.formData.FilteredKeyIn1))
      })
    } else if (SetPackage === "SetB"){
      const SetBPackage = ['SA', 'SB', 'SC', 'SD', 'SE', 'SF']
      this.setState({ 
        formData: {
          ...this.state.formData,
          KeyIn1: JSON.stringify(SetBPackage),
          FilteredKeyIn1: SetBPackage,
        },
        SetPackage
      }, ()=>{
        this.updateUserF(this.state.formData.ID, 'KeyIn1', JSON.stringify(this.state.formData.FilteredKeyIn1))
      })
    } else {
      this.setState({SetPackage})
    }
  }

  SetNewPackageGame(SetPackage, Key){
    if(SetPackage === "SetA"){
      const SetAPackage = ['B', 'S', 'CD', 'CA', 'SA', 'SD']
      if (Key === 'KeyIn1') {
        this.setState({ 
          CreateformData: {
            ...this.state.CreateformData,
            [`${Key}`]: JSON.stringify(SetAPackage),
          },
          NewSetPackage: SetPackage
        })
      } else {
        this.setState({ 
          formData: {
            ...this.state.formData,
            [`${Key}`]:  JSON.stringify(SetAPackage),
          },
          NewSetPackage2: SetPackage,
        })
      }
    } else if (SetPackage === "SetB"){
      const SetBPackage = ['SA', 'SB', 'SC', 'SD', 'SE', 'SF']
      if (Key === 'KeyIn1') {
        this.setState({ 
          CreateformData: {
            ...this.state.CreateformData,
            [`${Key}`]: JSON.stringify(SetBPackage),
          },
          NewSetPackage: SetPackage
        })
      } else {
        this.setState({ 
          formData: {
            ...this.state.formData,
            [`${Key}`]:  JSON.stringify(SetBPackage),
          },
          NewSetPackage2: SetPackage,
        })
      }
    } else {
      if (Key === 'KeyIn1') {
        this.setState({NewSetPackage: SetPackage})
      } else {
        this.setState({NewSetPackage2: SetPackage})
      }
    }
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 2000);
  };

  openNotificationAlert(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 5000);
  };


  openNotificationMessageCheck(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 5000);
  };


  openNotificationCreateUserMessage(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 2000);
  };

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    if (this.state.SearchUser !== '') {
      const Searchresult = await superAdminSearch(this.state.SearchUser, this.props.userID, this.props.token, this.props.username)
      if ( Searchresult.message === 'No Admin'){
        this.setState({ notificationType: 'error' }, () => {
          this.openNotificationMessageCheck(`${language[this.props.currentLanguage].NoResult}`)
          this.setState({ users: [], SearchUser: '', hideEditForm: false, visibleForm:false }, ()=> {
            this.setState({ page: 1})
          })
        });
      } else {
        for(let i = 0; i < Searchresult.length; i += 1) {
          Searchresult[i].FilteredKeyIn1 = JSON.parse(Searchresult[i].KeyIn1)
        }
        this.setState({ users: Searchresult, hideEditForm: false, visibleForm: false })
      }
    } else {
      this.ApiRefresh();
    }
  }

  async ClickCreateNewUser(){
    const FormData = this.state.CreateformData
    if ((FormData.Username || '') === ''){
      this.setState({ notificationType: 'error', AllowSaveUsername: false }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PleaseInsertUsername}`)
      });
    } else if ((FormData.Password || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsInsertPassword}`)
      });
    } else if ((FormData.Role || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsChooseUserRole}`)
      });
    } else if((FormData.Name || '') === '') {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsinsertName}`)
      });
    }  else if ((FormData.KeyIn1 || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsInsertFormat}`)
      });
    }  else if ((FormData.KeyIn2 || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsInsertFormat}`)
      });
    } else if ((FormData.Status || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsChooseSituation}`)
      });
    }  else if (Number(FormData.DailyLimit) < 0 || FormData.DailyLimit === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsInsertDailyLimit}`)
      });
    }  else if ((FormData.MultiplyDivide || '') === ''){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsChoosemultiplydivide}`)
      });
    }
    else if ((FormData.Upperline || '-') === '-'){
      this.setState({ notificationType: 'error'}, () => {
        this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsChooseUpperline}`)
      });
    } else {
            
      if (this.state.AllowSaveUsername !== true) {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotificationMessageCheck(`${language[this.props.currentLanguage].PlsClickInquire}`)
        });
      } else {
              
        const valueKey = JSON.parse(FormData.KeyIn1 || []);
        const Check0 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[0]);
        const Check1 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[1]);
        const Check2 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[2]);
        const Check3 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[3]);
        const Check4 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === valueKey[4]);
        const Check5 = ['B','S','CD','CA','SA','SD','SB','SC','SE','SF'].map((item) => item === (valueKey[5] || ''));
        if  (Check0.includes(true) === false || 
                Check1.includes(true) === false || 
                Check2.includes(true) === false || 
                Check3.includes(true) === false || 
                Check4.includes(true) === false || 
                Check5.includes(true) === false) {
          this.setState({ notificationType: 'error', AllowSaveUsername: false }, () => {
            this.openNotificationMessageCheck(`${language[this.props.currentLanguage].InvalidKey}`)
          });
        } else {
          const valueKey = JSON.parse(FormData.KeyIn1 || []);
          const uniqueNamesArray = valueKey.filter((v,i,a)=>a.findIndex(t=>(t === v && t===v))===i)
          for (let i = 0; i < 6; i += 1) {
                   
            if ((uniqueNamesArray[i] || '') === '' ){
              uniqueNamesArray[i] = ''
            }
          }
          this.setState({
            CreateformData: {
              ...this.state.CreateformData,
              KeyIn1: JSON.stringify(uniqueNamesArray),
              FilteredKeyIn1: uniqueNamesArray,
            },
          }, async ()=> {
            const CreateNewUserresult = await createUser(this.state.CreateformData, 1, this.props.token, this.props.username)
            if (CreateNewUserresult.message === 'Successful created user') {
              this.setState({ notificationType: 'success', AllowSaveUsername: false, CreateAdminAccount: false, visibleSetGameLimit: false}, () => {
                this.openNotificationCreateUserMessage(`${language[this.props.currentLanguage].SuccessfulCreateUser}`);
                this.ApiRefresh();
              });
            }
          })
        }
      }
    }
  }

  // renderBackgroundColor(game) {
  //   if (game === 'Default') return 'lightsteelblue'
  //   else if (game === '1') return '#fff23e'
  //   else if (game === '2') return '#414bb2'
  //   else if (game === '3') return '#ff431b'
  //   else if (game === '4') return '#32c6f4'
  //   else if (game === '5') return 'green'
  //   else if (game === '6') return '#fc0404'
  //   else if (game === '7') return '#f8c100'
  //   else if (game === '8') return '#6d100e'
  //   else return null
  // }

  textAllChange(game, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = this.state.GameLimitPackage
    
      if (!gameObject[game]) gameObject[game] = {}
      const keys = ['all', 'B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
      for (let i = 0; i < keys.length; i += 1) {
        gameObject[game][keys[i]] = value
      }
      this.setState({ GameLimitPackage: gameObject})
      // this.props.dataChange('redNumberData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  async OpenSetGameLimit(){
    if(this.state.visibleSetGameLimit === true) {
      this.setState({ visibleSetGameLimit: false, CreateAdminAccount: false })
    } else {
      this.callConfigurationData();
    }
  }

  changePage() {
    this.setState({ users: [] }, () => {
      this.ApiRefresh();
    });
  }

  async DetailHide(Status){
    if(Status === 'Close') {
      this.setState({ visibleSetGameLimit: false })
    }
  }

  async addUpdateF() {
    const updateConfiResult = this.state.GameLimitPackage
    if(updateConfiResult.length === 0) {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].Null}`)
      });
    }
    const updateConfiguration = await UpdateConfiguration(updateConfiResult, this.props.token, this.props.username)
    if(updateConfiguration.error === 'database error') {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].Null}`)
      });
    } else {
      if(updateConfiguration.message === 'Configuration Updated') {
        this.setState({ notificationType: 'success', visibleSetGameLimit: false }, () => {
          this.openNotification(`${language[this.props.currentLanguage].Success}`);
          window.location.reload();
        });
      }
    }
  }

  gameChangeDetail(game) {
    let newGamesArray = this.state.GamesDetail
    let newGameLimitPackage = this.state.GameLimitPackage
    // console.log('newGameLimitPackage => ', newGameLimitPackage)
    // console.log('newGamesArray => ', newGamesArray)
    if (this.state.GamesDetail.includes(game)) {
      newGamesArray.splice(this.state.GamesDetail.indexOf(game), 1);
      // delete newGameLimitPackage[game]
    } else {
      newGamesArray.push(game);
      newGameLimitPackage[game] = { 
        all: '', B: newGameLimitPackage[game]['B'],
        S: newGameLimitPackage[game]['S'],
        SA: newGameLimitPackage[game]['SA'],
        SD: newGameLimitPackage[game]['SD'],
        CA: newGameLimitPackage[game]['CA'],
        CD: newGameLimitPackage[game]['CD'],
        SB: newGameLimitPackage[game]['SB'],
        SC: newGameLimitPackage[game]['SC'],
        SE: newGameLimitPackage[game]['SE'],
        SF: newGameLimitPackage[game]['SF'],
        "5D": newGameLimitPackage[game]['5D'],
        "6D": newGameLimitPackage[game]['6D']
      }
    }
    const defaultArray = ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const arrangedArray = newGamesArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    this.setState({ GamesDetail: arrangedArray, GameLimitPackage: newGameLimitPackage })
  }

  textChange(game, subfield, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = this.state.GameLimitPackage
      gameObject[game][subfield] = value
      // gameObject[game].all = ''
      this.setState({ GameLimitPackage: gameObject})
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  async ChangePassword(Type) {
    const data = this.state.data.Password
    const Currentpass = this.state.CurrentPass
    const NewPassword = this.state.NewPass
    const ConfirmPass = this.state.ConfirmPass
    if (Type === 'OpenChangePassWordModal') {
      this.setState({ OpenChangePassword: true })
    } else {
      if(Currentpass === '') {
        this.openNotification(`${language[this.props.currentLanguage].CurrentPasswordEmpty}`);
        this.setState({ showNotificationpass: 'error', showNotification: 'error' })
      } else if (NewPassword === '') {
        this.openNotification(`${language[this.props.currentLanguage].NewPasswordEmpty}`);
        this.setState({ showNotificationpass: 'error', showNotification: 'error' })
      } else if (ConfirmPass === '') {
        this.openNotification(`${language[this.props.currentLanguage].RepeatPasswordEmpty}`);
        this.setState({ showNotificationpass: 'error', showNotification: 'error' })
      } else if (NewPassword !== ConfirmPass) {
        this.openNotification(`${language[this.props.currentLanguage].NRPasswordDifferent}`);
        this.setState({ showNotificationpass: 'error', showNotification: 'error' })
      } else if (Currentpass !== data) {
        this.openNotification(`${language[this.props.currentLanguage].CurrentPasswordNotMatch}`);
        this.setState({ showNotificationpass: 'error', showNotification: 'error' })
      } else {
        const PublicKey = await getPublicKey();
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(PublicKey.publicKey);
        let encryptCPassword = encrypt.encrypt(Currentpass);
        let encryptNPassword = encrypt.encrypt(NewPassword);

        const ChangePassword = await chgPwd(this.props.userID, encryptCPassword, encryptNPassword, this.props.username, this.props.token)
        if(ChangePassword.message === 'Password Changed Successfully') {
          this.openNotification(`${language[this.props.currentLanguage].PasswordChangedSuccessfully}`);
          this.setState({ showNotificationpass: 'success', showNotification: 'success' }, ()=> {
            this.setState({ OpenChangePassword: false, CurrentPass: '', ConfirmPass: '', NewPass: '' })
            window.location.reload();
          })
        }
      }
    }
  }

  async DeleteUsers(Type) {
    // const CheckUsername = this.state.CheckUsername
    const CheckPassword = this.state.CheckPassword
    if(Type === 'OpenDeletePasswordModal') {
      this.setState({ OpenDeletePassword: true })
    } else {
      if (CheckPassword === '') {
        this.openNotification(`${language[this.props.currentLanguage].CurrentPasswordEmpty}`);
        this.setState({ showDeletepass: 'error', showNotification: 'error', notificationType: 'error' })
      } else {
        const DeleteUserID = [this.state.data.ID]
        const r = (window.confirm(language[this.props.currentLanguage].DeleteUSerConfirmation));
        if (r === true) {
          const deleteUser = await DeleteUsers(DeleteUserID, this.state.CheckPassword, this.props.token, this.props.username)
          if (deleteUser.message === 'Successfuly Removed') {
            this.openNotificationMessageCheck(language[this.props.currentLanguage].SuccessDeleteUser)
            this.setState({ notificationType: 'success', visibleForm: false, OpenDeletePassword: false, showDeletepass: 'success', CheckUsername: '', CheckPassword: '' }, ()=> {
              this.ApiRefresh();
              this.setState({ hideEditForm: false })
            });
          } else {
            if (deleteUser.error === 'Got Downline') {
              this.openNotificationMessageCheck(language[this.props.currentLanguage].GotDownlineCantDelete)
              this.setState({ notificationType: 'error', OpenDeletePassword: false, showDeletepass: 'error', CheckUsername: '', CheckPassword: '' })
            } else if (deleteUser.error === 'Betting Date Error') {
              this.openNotificationMessageCheck(language[this.props.currentLanguage].DeleteuserFaillastBet)
              this.setState({ notificationType: 'error', OpenDeletePassword: false, showDeletepass: 'error', CheckUsername: '', CheckPassword: '' })
            } else if (deleteUser.error === 'wrong Password') {
              this.openNotificationMessageCheck(language[this.props.currentLanguage].PasswordWrong)
              this.setState({ notificationType: 'error', OpenDeletePassword: 'error', showDeletepass: 'error'  })
            } else if (deleteUser.error === 'Got Report') {
              this.openNotificationMessageCheck(language[this.props.currentLanguage].DeleteuserFaillastreport)
              this.setState({ notificationType: 'error', OpenDeletePassword: false, showDeletepass: 'error', CheckUsername: '', CheckPassword: '' })
            }
          }
        } else {
          return null;
        }
      }
    }
  }

  PasswordC(event, mode) {
    this.setState({ [mode]: event })
  }

  onKeyConfirmPAssEnter(e) {
    if (e.charCode === 13) {
      document.getElementById("ChangePassButton").click()
    }
  } 

  async OpenAccount(SelectedID) {
    const UnlockUser = await UnlockUsers(SelectedID, this.props.username, this.props.token)
    if(UnlockUser) {
      this.setState({ notificationType: 'success' }, ()=> {
        this.openNotification(language[this.props.currentLanguage].SuccessOpenAccount)
        this.ApiRefresh();
      })
    }
  }


  render() {
    return(
      <div className='StartBackground'>
        <Header2 />
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width: '70%', left: 250}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div style={{ padding: 10}}>
          <div className="dcc row">
            <div className="redNumberTitle form-control" style={{ width: '25%', fontSize: '20px' }}>
              <b> {language[this.props.currentLanguage].ManageAdminAuthority} </b>
            </div>
            <div>
              <FiRefreshCcw
                style={{ cursor: 'pointer', marginLeft: 10 }}
                onClick={() => {
                  this.ApiRefresh();
                  this.setState({ SearchUser: '' })
                }}
                size="30px"
                color="black"
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, flexDirection: 'row' }}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <div style={{ margin: '0px 10px'}}>{language[this.props.currentLanguage].SearchAccount}</div>
              <Form.Control style={{ width: 200, marginRight: 10}} type="text" placeholder={`ID / ${language[this.props.currentLanguage].usernameLanguage}`} value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
              <Button variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
            </div>
            <div style={{ marginTop: null }} hidden={ this.state.PreviousBool }>
              <Button className="button2" variant="primary" style={{ marginRight: 10}} onClick={()=> this.BackToPreviousForm()}>Back</Button>
            </div>
            <div style={{ marginTop: null }}>
              <Button className="button2" variant="primary" style={{marginRight: 5}} onClick={() => this.OpenSetGameLimit()} >{language[this.props.currentLanguage].EditGameLimit}</Button>
              <Button className="button2" variant="primary" onClick={() => this.OpenNewForm()} >{language[this.props.currentLanguage].CreateAdmin}</Button>
            </div>
          </div>
          {/* Set Game Limit */}
          <Table responsive="sm" striped bordered hover>
            <thead>
              <tr>
                <th><div style={{ width: 50 }}>ID</div></th>
                <th><div style={{ width: 50, minWidth: 100 }}>{language[this.props.currentLanguage].USerLoginAcc}</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].Password}</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].Role}</div></th>
                <th><div style={{ width: 100, minWidth: null }}>{language[this.props.currentLanguage].InputMethod}</div></th>
                <th><div style={{ width: 80, minWidth: 100 }}>{language[this.props.currentLanguage].ActivePackage}</div></th>
                <th><div style={{ width: 80, minWidth: 100 }}>最新单数</div></th>
                <th><div style={{ minWidth: null }}>{language[this.props.currentLanguage].ChangeDate}</div></th>
                <th><div style={{ width: 50 }}>{language[this.props.currentLanguage].MD}</div></th>
                <th><div style={{  width: 50 }}>{language[this.props.currentLanguage].Status}</div></th>
                <th><div style={{  width: 80 }}>{language[this.props.currentLanguage].PersonalSalesLimit}</div></th>
                <th style={{ minWidth: 100 }}><div>{language[this.props.currentLanguage].Action}</div></th>
              </tr>
            </thead>
            <tbody>
              {this.state.Selfs.length > 0 && 
                <>
                  {this.state.Selfs.map((items, idx) => {
                    return [ <tr key={idx}>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.ID}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.Username}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.Password}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.Role}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>
                        <div>{(items.KeyIn1 !== 'null') ? JSON.parse(items.KeyIn1).join(', ') : '-'}</div>
                        <div>{(items.KeyIn2 !== 'null') ? JSON.parse(items.KeyIn2).join(', ') : '-'}</div>
                      </td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.ActivePackageID}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.LastOrderID}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{moment(items.LastUpdateDateTime).format('YYYY-MM-DD HH:mm:ss a')}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.MultiplyDivide}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.Status}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>{items.DailyLimit}</td>
                      <td style={{ verticalAlign: 'middle', fontWeight: (items.Role === 'admin') ? 'bold' : null}}>
                        <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                          <div style={{ marginRight: 10 }} >
                            <Button variant="primary" style={{ width: '100%' }} onClick={() => this.toggleEditForm(items)}>{language[this.props.currentLanguage].Edit}</Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    ]
                  })}
                </>
              }
              {this.state.Selfs.length > 0 && 
                <tr><td colSpan="13" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '20px'}}>{language[this.props.currentLanguage].Downline}</td></tr>
              }
              {this.state.users.map((item, index) => {
                return [ <tr key={index}>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.ID}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.Username}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.Password}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.Role}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>
                    <div>{(item.KeyIn1 !== 'null') ? JSON.parse(item.KeyIn1).join(', ') : '-'}</div>
                    <div>{(item.KeyIn2 !== 'null') ? JSON.parse(item.KeyIn2).join(', ') : '-'}</div>
                  </td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.ActivePackageID}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.LastOrderID}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{moment(item.LastUpdateDateTime).format('YYYY-MM-DD HH:mm:ss a')}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.MultiplyDivide}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.Status}</td>
                  {/* <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.Demo}</td> */}
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>{item.DailyLimit}</td>
                  <td style={{ verticalAlign: 'middle', fontWeight: (item.Role === 'admin') ? 'bold' : null}}>
                    <div className="dcc" style={{ flexDirection: 'row', width: '100%'}}>
                      <div style={{ marginRight: 10 }} >
                        <Button variant="primary" style={{ width: '100%' }} onClick={() => this.toggleEditForm(item)}>{language[this.props.currentLanguage].Edit}</Button>
                      </div>
                      {item.LoginAttempt === 3 && 
                      <div>
                        <Button style={{  width: '100%', backgroundColor: 'red' }} onClick={() => this.OpenAccount(item.ID)}>{language[this.props.currentLanguage].Open}</Button>
                      </div>
                      }
                    </div>
                  </td>
                </tr>
                ]
              })}
            </tbody>
          </Table>
          <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {this.state.page > this.state.countpagemin ? (
              <button  style={{ textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: 100, borderColor: '#1e5e8a' }} 
                className="btn my-cusomized-button button2" 
                onClick={() =>
                  this.setState(
                    {
                      page: this.state.page - 1,
                    },
                    () => {
                      this.changePage(this.state.page);
                    },
                  )
                }>
                <ImArrowLeft size="16px" color="white" />
              </button>
            ) : (
              <div style={{ width: 100 }} />
            )}
            <div style={{ fontWeight: 'bold', marginLeft: 100, marginRight: 100}}>{this.state.page}</div>
            {this.state.page < this.state.countpagemax ? (
              <button
                style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 100, borderColor: '#1e5e8a' }}
                className="btn my-cusomized-button button2" 
                onClick={() =>
                  this.setState(
                    {
                      page: this.state.page + 1,
                    },
                    () => {
                      this.changePage();
                    },
                  )
                }>
                <ImArrowRight size="16px" color="white" />
              </button>
            ) : (
              <div style={{ width: 100 }} />
            )}
          </div>
          {this.state.hideEditForm === true && 
          <div style={{ display: 'flex', marginBottom: 10, padding: 10, border: '1px solid #000', borderRadius: 5, }}>
            <div style={{ borderRight: '1px solid #000', marginRight: 10 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ margin: '0px 10px', fontSize: '20px', width: '100%' }}><b>{'Admin Details'}</b></div>
              </div>
              <hr />
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>User ID</b></div>
                <Form.Control style={{ width: 100, marginRight: 10, color: 'blue', fontWeight: 'bold'}} type="text" maxLength='4'
                  value={(this.state.data.ID || '')} readOnly={true}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>{language[this.props.currentLanguage].usernameLanguage}</b></div>
                <Form.Control style={{ width: 100, marginRight: 10, color: 'blue', fontWeight: 'bold'}} type="text" maxLength='4' 
                  value={(this.state.data.Username || '')} readOnly={true}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>{language[this.props.currentLanguage].Role}</b></div>
                <Form.Control style={{ width: 100, marginRight: 10, color: 'blue', fontWeight: 'bold'}} type="text" maxLength='4'
                  ref={(input) => { this.FocusHere = input; }}
                  value={(this.state.data.Role || '')} readOnly={true}/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 70}}><b>{language[this.props.currentLanguage].Password}</b></div>
                <Form.Control style={{ width: 100, marginRight: 10, color: 'blue', fontWeight: 'bold'}} type="text" maxLength='4'
                  ref={(input) => { this.FocusHere = input; }}
                  value={(this.state.data.Password || '')} readOnly={true}/>
              </div>
              {this.props.userID === this.state.data.ID &&
              <div style={{ display: 'flex', marginBottom: 10, textAlign: 'rigth' }}>
                <Button variant="primary" onClick={() => this.ChangePassword('OpenChangePassWordModal')}>{language[this.props.currentLanguage].ChangePassword}</Button>
              </div> 
              }
              {this.props.userID !== this.state.data.ID &&
              <div style={{ display: 'flex', marginBottom: 10, textAlign: 'rigth' }}>
                <Button className='button2' variant="danger" onClick={() => this.DeleteUsers('OpenDeletePasswordModal')}>{language[this.props.currentLanguage].Delete}</Button>
              </div> 
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
              <Tab.Content>
                { this.state.AdminuserDetails.map((AdminDetails, idx) => {
                  return <EditAdmin key={idx} AdminDetails={[AdminDetails]} 
                    data={ this.state.data } AddDisable={this.state.AddDisable}
                    USerRole={this.state.data.Role}
                    EditForm={this.EditForm} ApiRefresh={this.ApiRefresh} />
                })
                }
              </Tab.Content>
            </div>
          </div>
          }
          {this.state.CreateAdminAccount === true &&
            <div className="dcc" style={{ display: 'flex', marginBottom: 10, padding: 10, border: '1px solid #000', borderRadius: 5, overflowX: 'auto', width: '100%'}}>
              <div className="dcc" style={{ flexDirection: 'column', width: '80%' }}>
                <div style={{ fontWeight: 'bold', fontSize: '20px', paddingBottom: 20}}>{language[this.props.currentLanguage].CreateAdmin}</div>
                <div className="dcc" style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: 15 }}>
                  <div className="dcc" style={{ width: '100%', flexDirection: 'column'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '80%', marginBottom: 5 }}>
                      <div className="row" style={{ width: '100%', alignItems: 'center'}}>
                        <div style={{ width: '20%', fontWeight: 'bold' }}>{language[this.props.currentLanguage].USerLoginAcc} : </div>
                        <div className="row"><Form.Control ref={(input) => { this.FocusUsername = input; }}
                          style={{ width: 'auto', marginRight: 10}} 
                          type="text" 
                          onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('Username', e.target.value, undefined ,'CreateformData') : this.dataChange('Username', e.target.value, undefined ,'formData')} 
                          onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'Username', this.state.formData.Username)} 
                          value={(this.state.Mode === 'Create User') ?  this.state.CreateformData.Username : this.state.formData.Username} 
                          placeholder={`ID / ${language[this.props.currentLanguage].usernameLanguage}`} />
                        <Button variant="primary" 
                          onClick={() => (this.state.Mode === 'Create User') ? this.CheckingUsername(this.state.CreateformData.Username) : this.CheckingUsername(this.state.formData.Username)} >
                          查询
                        </Button>
                        </div>
                      </div>
                      <div className="row" style={{ width: '100%', alignItems: 'center'}}>
                        <div style={{ width: '20%', fontWeight: 'bold' }}>{language[this.props.currentLanguage].Password} : </div>
                        <Form.Control style={{ width: 'auto', marginRight: 10}} type="text" 
                          onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('Password', e.target.value, undefined ,'CreateformData') : this.dataChange('Password', e.target.value, undefined ,'formData')} 
                          value={(this.state.Mode === 'Create User') ? this.state.CreateformData.Password : this.state.formData.Password} 
                          placeholder={`${language[this.props.currentLanguage].Password}`}
                          onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'Password', this.state.formData.Password)}/>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '80%', marginBottom: 5 }}>
                      <div className="row" style={{ width: '100%', alignItems: 'center'}}>
                        <div style={{ width: '20%', fontWeight: 'bold' }}>{language[this.props.currentLanguage].Role} : </div>
                        <div style={{ width: '80%'}}><Form.Control as="select" style={{ width: 'auto', marginRight: 10}} onChange={(e) => this.EditData(e, 'Role')}
                          // disabled={(this.props.Role === 'player' || this.props.Role === 'agent') ? true : false} 
                          value={(this.state.Mode === 'Create User') ? this.state.CreateformData.Role :   this.state.formData.Role} >
                          {this.props.Role === 'admin' ? <option value="admin">admin</option> : <></>}
                          <option value="admin">admin</option>
                          <option value="superAdmin">superAdmin</option>
                        </Form.Control></div>
                      </div>
                      <div className="row" style={{ width: '100%', alignItems: 'center'}}>
                        <div style={{ width: '20%', fontWeight: 'bold' }}>{language[this.props.currentLanguage].Name} : </div>
                        <div> <Form.Control style={{ width: 'auto', marginRight: 10}} type="text" 
                          onChange={(e) => (this.state.Mode === 'Create User') ? this.dataChange('Name', e.target.value, undefined ,'CreateformData') : this.dataChange('Name', e.target.value, undefined ,'formData')} 
                          value={(this.state.Mode === 'Create User') ? this.state.CreateformData.Name : this.state.formData.Name} 
                          placeholder={`${language[this.props.currentLanguage].Name}`}
                          onKeyPress={(e) => this.onKeyEnter(e, this.state.formData.ID, 'Name', this.state.formData.Name)}/></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dcc" style={{ display: 'flex', alignItems: 'center', marginBottom: 10, width: '100%'}}>
                  {(this.state.Mode === 'Create User') 
                    ? <Button className='button2' style={{ width: 'auto' }} variant="primary" onClick={() => this.ClickCreateNewUser()} >Create</Button> 
                    : <></>
                  } 
                </div>
              </div>
            </div>
          }
          <Modal
            // style={{ width: '100%'}}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.visibleSetGameLimit}
            contentClassName="br23">
            <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
              <div style={{flexDirection: 'row', width:'100%', backgroundColor: 'white'}}>
                <GrFormClose
                  className='ButtonCloseModal'
                  onClick={() => {
                    this.setState({ visibleSetGameLimit: false })
                  }}
                  size="35px"
                  color="#000"
                />
                <div style={{width: '100%', border: `1px solid black`, borderRadius: 10, overflowX: 'auto' }}>
                  <div style={{ padding: 10 }}>
                    <div style={{  justifyContent: '', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, width:'100%', marginBottom: '2%'}} >
                      <div style={{ fontSize: 24, fontWeight: 'bold'}}>
                        {language[this.props.currentLanguage].EditGameLimit}
                      </div>
                    </div>
                    <div style={{ justifyContent: '', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, width:'100%'}} >
                      <div style={{ marginRight: 20}}>
                        <div style={{ fontSize: 24, fontWeight: 'bold'}}>
                          {language[this.props.currentLanguage].SetGameLimit}
                        </div>
                      </div>
                      <div>
                        <div style={{ display: 'flex'}}>
                          {['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((item, idd) => {
                            return <Form.Check key={idd}
                              style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                              inline
                              label={item}
                              type="checkbox"
                              checked={this.state.GamesDetail.includes(item)}
                              onChange={(e) => this.gameChangeDetail(item, e.target.value)}
                            />
                          })}
                        </div>
                        {this.state.GamesDetail.map((game, index) => {
                          return <div key={index} style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: '1065px', backgroundColor: Platformcolor(game, ''), paddingTop: 10, borderRadius: 10, color: game === 'Default' || game === '1' ? '#000' : '#fff'}}>
                            <div style={{ margin: '0px 10px', width: 70, fontSize: 20}}><b>{game}</b></div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                              <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                              <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.state.GameLimitPackage[game].all} onChange={(e) => this.textAllChange(game, e.target.value)} />
                            </div>
                            {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D'].map((item, ind) => {
                              return <div key={ind} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                                <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                                <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.state.GameLimitPackage[game][item]} onChange={(e) => this.textChange(game, item, e.target.value)}/>
                              </div>
                            })}
                          </div>
                        })}
                      </div>
                    </div>
                    <div className='dcc' style={{  justifyContent:  '', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, width:'100%', marginBottom: '2%'}} >
                      <div>
                        <Button style={{ marginLeft: 10, width: null}} variant="danger" disabled={this.state.AddDisable } onClick={() => this.DetailHide('Close')}>
                          {language[this.props.currentLanguage].Close}
                        </Button>
                      </div>
                      <div>
                        <Button style={{ marginLeft: 10, width: null}} variant="success" disabled={this.state.AddDisable } onClick={() => this.addUpdateF()}>{language[this.props.currentLanguage].Update}</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.OpenDeletePassword}
            contentClassName="br23">
            <Modal.Body className="dcc" style={{ padding: 10, width: 'auto' }}>
              <div style={{flexDirection: 'row', width:'100%', backgroundColor: 'white'}}>
                <GrFormClose
                  className='CloseButtonModal'
                  onClick={() => {
                    this.setState({ OpenDeletePassword: false, CheckUsername: '', CheckPassword: '' })
                  }}
                  size="35px"
                  color="#000"
                />
                <div className='dcc' style={{width: '100%', borderRadius: 10, overflowX: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                  <div style={{ width: '100%', marginBottom: 20, fontWeight: 'bold',fontSize: '20px' }}> {language[this.props.currentLanguage].ConfirmAccount}</div>
                  <Alert style={{ zIndex: 99, width: '80%', left: 0 }}show={this.state.showNotification} variant={this.state.showDeletepass === 'error' ? 'danger' : 'success'}>
                    <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                  </Alert>
                  {/* <div className='dcc' style={{ width: (isMobile) ? '100%' :'80%', flexDirection: 'row', marginBottom: 5 }}>
                    <div style={{ width: (isMobile) ? '35%' : '20%', fontWeight: 'bold' }}>{language[this.props.currentLanguage].usernameLanguage} :</div>
                    <div className='dcc' style={{ width: (isMobile) ? '65%' : '60%', flexDirection: 'row' }}>
                      <Form.Control style={{ width: '100%', marginRight: 10, fontWeight: 'bold', textAlign: 'center' }}
                        type={'text'}
                        value={this.state.CheckUsername} onChange={(e)=> this.PasswordC(e.target.value, 'CheckUsername')}/>
                    </div>
                  </div> */}
                  <div className='dcc' style={{ width: '80%', flexDirection: 'row', textAlign: 'center', marginBottom: 5}}>
                    <div style={{ width: '20%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Password} :</div>
                    <div className='dcc' style={{ width:  '60%', flexDirection: 'row' }}>
                      <Form.Control style={{ width: '100%', marginRight: 10, fontWeight: 'bold', textAlign: 'center' }}
                        type={(this.state.ShowPassWordCheck === true) ? 'text' : 'password'} maxLength='6' onKeyPress={(e) => this.onKeyEnterDelete(e)}
                        value={this.state.CheckPassword} onChange={(e)=> this.PasswordC(e.target.value, 'CheckPassword')}/>
                      {this.state.ShowPassWordCheck === false
                        ? <FaEyeSlash style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.setState({ShowPassWordCheck: true })}/>
                        : <FaEye style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.setState({ShowPassWordCheck: false })}/> 
                      }
                    </div>
                  </div>
                  <div className='dcc' style={{ width: '100%', flexDirection: 'row', textAlign: 'center', paddingBottom: 20 }}>
                    <div style={{ width: 'auto'}}>
                      <Button className="button2" variant="primary" id="ChangePassButton" onClick={() => this.DeleteUsers('ConfirmAccount')}>{language[this.props.currentLanguage].ConfirmDeleteUsers}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.OpenChangePassword}
            contentClassName="br23">
            <Modal.Body className="dcc" style={{ padding: 10, width: 'auto' }}>
              <div style={{flexDirection: 'row', width: '100%', backgroundColor: 'white'}}>
                <GrFormClose
                  className='CloseButtonModal'
                  onClick={() => {
                    this.setState({ OpenChangePassword: false, CurrentPass: '', ConfirmPass: '', NewPass: ''})
                  }}
                  size="35px"
                  color="#000"
                />
                <div className='dcc' style={{width: '100%', borderRadius: 10, overflowX: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                  <div style={{ width: '100%', marginBottom: 20, fontWeight: 'bold',fontSize: '20px' }}> {language[this.props.currentLanguage].ChangePassword}</div>
                  <Alert style={{ zIndex: 99, width: '80%', left: 0 }}show={this.state.showNotification} variant={this.state.showNotificationpass === 'error' ? 'danger' : 'success'}>
                    <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                  </Alert>
                  <div className='dcc' style={{ width: '80%', flexDirection: 'row', marginBottom: 5 }}>
                    <div style={{ width: '20%', fontWeight: 'bold' }}>{language[this.props.currentLanguage].CurrentPassword} :</div>
                    <div className='dcc' style={{ width: '60%', flexDirection: 'row' }}>
                      <Form.Control style={{ width: '100%', marginRight: 10, fontWeight: 'bold', textAlign: 'center' }}
                        type={(this.state.ShowPassword === true) ? 'text' : 'password'} maxLength='6'
                        value={this.state.CurrentPass} onChange={(e)=> this.PasswordC(e.target.value, 'CurrentPass')}/>
                      {this.state.ShowPassword === false
                        ? <FaEyeSlash style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.setState({ShowPassword: true })}/>
                        : <FaEye style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.setState({ShowPassword: false })}/> 
                      }
                    </div>
                  </div>
                  <div className='dcc' style={{ width: '80%', flexDirection: 'row', textAlign: 'center', marginBottom: 5}}>
                    <div style={{ width: '20%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].ChangePassword} :</div>
                    <div className='dcc' style={{ width: '60%', flexDirection: 'row' }}>
                      <Form.Control style={{ width: '100%', marginRight: 10, fontWeight: 'bold', textAlign: 'center' }}
                        type={(this.state.ShowPasswordNew === true) ? 'text' : 'password'} maxLength='6'
                        value={this.state.NewPass} onChange={(e)=> this.PasswordC(e.target.value, 'NewPass')}/>
                      {this.state.ShowPasswordNew === false
                        ? <FaEyeSlash style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.setState({ShowPasswordNew: true })}/>
                        : <FaEye style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.setState({ShowPasswordNew: false })}/> 
                      }
                    </div>
                  </div>
                  <div className='dcc' style={{ width: '80%', flexDirection: 'row', textAlign: 'center', marginBottom: 10}}>
                    <div style={{ width: '20%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].RepeatPassword} :</div>
                    <div className='dcc' style={{ width: '60%', flexDirection: 'row' }}>
                      <Form.Control style={{ width: '100%', marginRight: 10, fontWeight: 'bold', textAlign: 'center' }} onKeyPress={(e) => this.onKeyConfirmPAssEnter(e)}
                        type={(this.state.ShowPasswordCon === true) ? 'text' : 'password'} maxLength='6'
                        value={this.state.ConfirmPass} onChange={(e)=> this.PasswordC(e.target.value, 'ConfirmPass')}/>
                      {this.state.ShowPasswordCon === false
                        ? <FaEyeSlash style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.setState({ShowPasswordCon: true })}/>
                        : <FaEye style={{ textAlign: 'center', width: '10%' }} size="20px" color="black" onClick={()=> this.setState({ShowPasswordCon: false })}/> 
                      }
                    </div>
                  </div>
                  <div className='dcc' style={{ width: '100%', flexDirection: 'row', textAlign: 'center', paddingBottom: 20 }}>
                    <div style={{ width: 'auto'}}>
                      <Button variant="primary" id="ChangePassButton" onClick={() => this.ChangePassword('ChangePassWord')}>{language[this.props.currentLanguage].ChangePassword}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(SuperAdminManagePage);