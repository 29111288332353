import '../App.css';
import React from 'react';
import Header from '../Component/Header';
// import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux'
import { language } from '../language';
import Moment from 'moment-timezone';
import { Button, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import MagnumLogo from '../Images/Cards/magnumlogo.png';
import TotoLogo from '../Images/Cards/toto.png';
import DamacaiLogo from '../Images/Cards/damacaimobile.png';
// import Select from 'react-select';
import GDLogo from '../Images/Cards/GrandDragonLotto.png';
import Sg4Logo from '../Images/Cards/singaporePool.png';
import NINELogo from '../Images/Cards/9Lottologo.png';
import SarawakLogo from '../Images/Cards/sarawak.png';
import Sabah88Logo from '../Images/Cards/sabah88.png';
import SansakanLogo from '../Images/Cards/sandakan.png';
import tickicons from '../Images/tick.png';
import { GamePlatformNumb1, GamePlatformNumb2 } from '../static';
import { Platformcolor } from '../static/PlatformColor';
import { HolyWater } from '../utility/CountHolyWater'
import 'react-datepicker/dist/react-datepicker.css';
import { processPlatformPosition } from '../utility/processPlatform';
// import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Alert from 'react-bootstrap/Alert';
// import Modal from 'react-bootstrap/Modal';
const { createUserEzFNC, createUserEzPostFNC } = require('../Api');
var timer

//
//                       _oo0oo_
//                      o8888888o
//                      88" . "88
//                      (| -_- |)
//                      0\  =  /0
//                    ___/`---'\___
//                  .' \\|     |// '.
//                 / \\|||  :  |||// \
//                / _||||| -:- |||||- \
//               |   | \\\  -  /// |   |
//               | \_|  ''\---/''  |_/ |
//               \  .-\__  '-'  ___/-. /
//             ___'. .'  /--.--\  `. .'___
//          ."" '<  `.___\_<|>_/___.' >' "".
//         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
//         \  \ `_.   \_ __\ /__ _/   .-` /  /
//     =====`-.____`.___ \_____/___.-`___.-'=====
//                       `=---='
//
//
//     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//               佛祖保佑         永无BUG

class FastRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      UserID: this.props.userID,
      token: this.props.token,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      DisplayDailyLimit: '0',
      ButtonSend: '#3C96F4',
      FinalResult: {},
      CreateUser: { Username: '', Password: '', Name: '', dailyLimit: '', EatPercent: '', BTEatPercent: '' },
      EditedEwPackage: {},
      FinalPostObject: {},
      DisplayPackageObj: {},
      PackageList: [],
      ParentPackageList: [],
      activeKeys: '',
      Commission: {},
      Odd: {},
      KeyIn1: [],
      KeyIn2: [],
      // GameDisplay: [],
      // GameBetting: [],
      ParentCommission: {},
      ParentOdd: {},
      allBetModeSelect: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD','CE','CF', 'PA','PB','PC','PD','PE','PF'],
      GamePlatform: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      Games: [],
      SelectedPackageID: '',
      CountCommissionResult: {},
      AlertshowNotification: false,
      AlertnotificationMessage: '',
      AlertnotificationType: 'error',
      AbleChange: 0,
      MD: '',
      GameDisplay: '',
      UplineGameBetting: '',
      GameBetting: '',
      GameBettingCustom: false,
      BettingCustom: [],
      Keyin1CustomData: [],
      Keyin2CustomData: [],
      UplineKeyin1: [],
      UplineKeyin2: [],
      Keyin1Custom: false,
      Keyin2Custom: false,
      JinNan: 0,
    }
    this.SetPackage = this.SetPackage.bind(this);
    this.KeyChangeIndividual = this.KeyChangeIndividual.bind(this);
    this.commissionChange = this.commissionChange.bind(this);
    this.commissionAllChange = this.commissionAllChange.bind(this);
    this.oddChange = this.oddChange.bind(this);
  }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ AbleChange: UserAuthorities.AbleChange })
    this.getEZCreatDetails();
  }

  async getEZCreatDetails() {
    const getDetails = await createUserEzFNC(this.props.username, this.props.token)
    if(getDetails) {
      this.setState({  
        DisplayDailyLimit: getDetails.DailyLimit, 
        DisplayPackageObj: JSON.parse(getDetails.EwPackage), 
        PackageList: JSON.parse(JSON.stringify( getDetails.PackageDetail)), 
        UplineKeyin1: JSON.parse(getDetails.KeyIn1),
        KeyIn1: JSON.parse(getDetails.KeyIn1),
        Keyin1CustomData: JSON.parse(getDetails.KeyIn1),
        UplineKeyin2: JSON.parse(getDetails.KeyIn2),
        Keyin2CustomData: JSON.parse(getDetails.KeyIn2),
        KeyIn2: JSON.parse(getDetails.KeyIn2),
        MD: getDetails.MultiplyDivide,
        GameDisplay: getDetails.GameDisplay,
        UplineGameBetting: getDetails.GameBetting,
        GameBetting: getDetails.GameBetting,
        BettingCustom: this.PlatformConvert(getDetails.GameBetting).split(''),
        JinNan: getDetails.JinNan,
      }, () => {
        if (JSON.stringify(this.state.DisplayPackageObj) !== '{}') {
          this.SetEditEwPackage();
        }
      })
    }
  }

  // EW Package
  SetEditEwPackage(){
    let EditedEwPackage = this.state.EditedEwPackage
    const Platform = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'Default']
    const GameKeys = ['B', 'S', 'CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF']
    Platform.forEach((items) => {
      for(var i=0; i < GameKeys.length; i++) {
        EditedEwPackage[items] = {
          ...EditedEwPackage[items],
          [GameKeys[i]] : {
            "max": '0',
            "amount": '0',
            "percent": '0',
          }
        }
      }
    })
    this.setState({ EditedEwPackage })
  }

  CreateUserFunction(event, Type) {
    const ArrayPlatform = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'Default']
    const GameKeys = ['B', 'S', 'CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF']
    let CreateUser = this.state.CreateUser
    let EditedEwPackage = this.state.EditedEwPackage

    CreateUser[Type] = event.target.value

    if(Type === 'EatPercent') {
      ArrayPlatform.forEach((platform) => {
        const platforms = platform
        GameKeys.forEach((Keys) => {
          EditedEwPackage[platforms][Keys]['percent'] = event.target.value
        })
      })
    } else if (Type === 'BTEatPercent') {
      GameKeys.forEach((Keys) => {
        EditedEwPackage['8'][Keys]['percent'] = event.target.value
      })
    }
    this.setState({ CreateUser, EditedEwPackage })
  }

  ChangeValues(event, platform, SelectedGameKeys) {
    let EditedEwPackage = this.state.EditedEwPackage
    // const GameKeys = ['B', 'S', 'CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF']
    // if (Object.keys(EditedEwPackage).includes(platform) === false) {
    //   for(var i=0; i < GameKeys.length; i++) {
    //     EditedEwPackage[platform] = {
    //       ...EditedEwPackage[platform],
    //       [GameKeys[i]] : {
    //         "max": '0',
    //         "amount": '0',
    //         "percent": '0',
    //       }
    //     }
    //   }
    // }
    EditedEwPackage[platform][SelectedGameKeys]["max"] = event.target.value
    this.setState({ EditedEwPackage })
  }

  ChangeValuesAll(event, Platform) {
    let EditedEwPackage = this.state.EditedEwPackage
    const GameKeys = ['B', 'S', 'CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF']
    for(var a=0; a < GameKeys.length; a++) {
      // EditedEwPackage[Platform] = {
      //   ...EditedEwPackage[Platform],
      //   [GameKeys[a]] : {
      //     "max": event.target.value,
      //     "amount": '0',
      //     "percent": '0',
      //   }
      // }
      EditedEwPackage[Platform][GameKeys[a]]["max"] = event.target.value
    }
    this.setState({ EditedEwPackage })
  }

  ChangeValuesAllbyKeys(event, SelectedKey) {
    let EditedEwPackage = this.state.EditedEwPackage
    const PlatformAll = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    const PlatformTotoandBT = ['3', '8']
    if (SelectedKey !== '5D' || SelectedKey !== '6D') {
      for (var b=0; b < PlatformAll.length; b ++) {
        EditedEwPackage[PlatformAll[b]][SelectedKey]["max"] = event.target.value
      }
    } else {
      for (var a=0; a < PlatformTotoandBT.length; a ++) {
        EditedEwPackage[PlatformTotoandBT[a]][SelectedKey]["max"] = event.target.value
      }
    }

    this.setState({ EditedEwPackage })
  }

  ConfirmCreate() {
    let CreateUser = this.state.CreateUser
    if(CreateUser.Username === '' || CreateUser.Password === '' || CreateUser.Name === '') {
      this.openNotification('ErrorMessageAllAccountData')
      this.setState({ notificationType: 'error'});
    } else {
      this.PostNewAccount(CreateUser, this.state.EditedEwPackage);
    }
  }

  CheckedPlat(e, SelectedPlat) {
    this.setState({ [SelectedPlat]: e.target.checked })
  }
  CheckedPlatbyKeys(e, SelectedKeys) {
    this.setState({ [SelectedKeys]: e.target.checked })
  }

  async PostNewAccount(CreateUser, EditedEwPackage) {
    this.ValidatePackage(EditedEwPackage, CreateUser);
  }

  ValidatePackage(EditedEwPackage, CreateUser) {
    this.setState({ FinalResult: JSON.parse(JSON.stringify(EditedEwPackage)) }, () => {
      let FinalResult = this.state.FinalResult
      let MagArray = []
      let DAArray = []
      let TotoArray = []
      let SgArray = []
      let sarawak = []
      let sabahArray = []
      let sdkArray = []
      const GameKeys = ['B', 'S', 'CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF']
      for(var a=0; a<GameKeys.length; a++) {
        MagArray.push(FinalResult['1'][GameKeys[a]]['max'] === '0' || FinalResult['1'][GameKeys[a]]['max'] === '')
        DAArray.push(FinalResult['2'][GameKeys[a]]['max'] === '0' || FinalResult['2'][GameKeys[a]]['max'] === '')
        TotoArray.push(FinalResult['3'][GameKeys[a]]['max'] === '0' || FinalResult['3'][GameKeys[a]]['max'] === '')
        SgArray.push(FinalResult['4'][GameKeys[a]]['max'] === '0' || FinalResult['4'][GameKeys[a]]['max'] === '')
        sarawak.push(FinalResult['5'][GameKeys[a]]['max'] === '0' || FinalResult['5'][GameKeys[a]]['max'] === '')
        sabahArray.push(FinalResult['6'][GameKeys[a]]['max'] === '0' || FinalResult['6'][GameKeys[a]]['max'] === '')
        sdkArray.push(FinalResult['7'][GameKeys[a]]['max'] === '0' || FinalResult['7'][GameKeys[a]]['max'] === '')
        sdkArray.push(FinalResult['8'][GameKeys[a]]['max'] === '0' || FinalResult['8'][GameKeys[a]]['max'] === '')
        sdkArray.push(FinalResult['9'][GameKeys[a]]['max'] === '0' || FinalResult['9'][GameKeys[a]]['max'] === '')
      }
      if (!MagArray.includes(false)) { delete FinalResult['1'] }
      if (!DAArray.includes(false)) { delete FinalResult['2'] }
      if (!TotoArray.includes(false)) { delete FinalResult['3'] }
      if (!SgArray.includes(false)) { delete FinalResult['4'] }
      if (!sarawak.includes(false)) { delete FinalResult['5'] }
      if (!sabahArray.includes(false)) { delete FinalResult['6'] }
      if (!sdkArray.includes(false)) { delete FinalResult['7'] }
      if (!sdkArray.includes(false)) { delete FinalResult['8'] }
      if (!sdkArray.includes(false)) { delete FinalResult['9'] }
      
      this.setState({ FinalResult }, async() => {
        let Commission = this.state.Commission
        let Odd = this.state.Odd
        if (this.state.SelectedPackageID === '') {
          this.openNotification('PleaseSelectedPackage')
          this.setState({ notificationType: 'error'});
        } else {
          let FinalPostObject = {}
          FinalPostObject = {
            "username": CreateUser.Username,
            "name": CreateUser.Name,
            "password": CreateUser.Password,
            "dailyLimit": (CreateUser.dailyLimit === '') ? 0 : CreateUser.dailyLimit,
            "ewPackage": this.state.FinalResult,
            "selectedOcPackageID": this.state.SelectedPackageID,
            "OcPackage": {
              "Commission": Commission,
              "Odd": Odd,
            },
            "MultiplyDivide": this.state.MD,
            "GameDisplay": this.state.GameDisplay,
            "GameBetting": this.state.GameBetting,
            "KeyIn1": this.state.KeyIn1,
            "KeyIn2": this.state.KeyIn2,
            "JinNan": this.state.JinNan,
          }
          const r = (window.confirm('您确定要创造用户吗?'));
          if (r === true) {
            const PostAcount = await createUserEzPostFNC(this.props.username, FinalPostObject, this.props.token)
            if (PostAcount.error === 'Username duplicated') {
              this.openNotification('DuplicatedUSer')
              this.setState({ notificationType: 'error'});
            } else if (PostAcount.error === 'Daily limit exceed') {
              this.openNotification('ExceedDAilyLimit')
              this.setState({ notificationType: 'error'});
            } else if (PostAcount.error === 'EwPackage exceed') {
              this.openNotification('ExceedEwPackage')
              this.setState({ notificationType: 'error'});
            } else if (PostAcount.error === 'Package Amount Exceed') {
              this.openNotification('ExceedPackageAmount')
              this.setState({ notificationType: 'error'});
            } else if (PostAcount.message === 'Success create') {
              this.openNotification('SuccessOpenAccount')
              this.setState({ notificationType: 'success' }, ()=> {
                timer = setTimeout(() => {
                  // this.setState({ FinalResult: {}, SelectedPackageID: '', Commission: {}, Odd: {}  })
                  // this.getEZCreatDetails();
                  window.location.reload(false);
                }, 1000);
              });
            }
          } else {
            return null;
          }
        }
      })
    })
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };
  
  //Package Details
  SetPackage(state){
    // ParentCommission: {},
    //   ParentOdd: {},
    const PackageKey = this.state.PackageList[state]
    const result = Object.keys(JSON.parse(PackageKey.Commission))
    const defaultArray =  ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const arrangedArray = result.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });

    this.setState({ 
      ParentCommission: JSON.parse(PackageKey.Commission), ParentOdd: JSON.parse(PackageKey.Odd),
      activeKeys: state, Commission: JSON.parse(PackageKey.Commission), Odd: JSON.parse(PackageKey.Odd), Games: arrangedArray, SelectedPackageID: PackageKey.PackageID }, ()=> {
      if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
        for(let i = 0; i < result.length; i++) {
          const FieldKeyArrayGame = Object.keys(this.state.Commission[result[i]]);
          const HolyArray = {
            Commision: this.state.Commission,
            Odd: this.state.Odd,
            Games: [result[i]],
            Fields: FieldKeyArrayGame,
          }
          const HolyResult = HolyWater(HolyArray);
          this.setState({ CountCommissionResult: JSON.parse(JSON.stringify(HolyResult))})
        }
      }
    })
  }

  gameChange(game) {
    let newArray = this.state.Games
    let newCommission = JSON.parse(JSON.stringify(this.state.Commission))
    let newCountCommissionResult = JSON.parse(JSON.stringify(this.state.CountCommissionResult))
    let newOdd = JSON.parse(JSON.stringify(this.state.Odd))
    // let newOddResult = JSON.parse(JSON.stringify(this.state.CountCommissionResultOdd))
    if (this.state.Games.includes(game)) {
      newArray.splice(this.state.Games.indexOf(game), 1);
      delete newCommission[game]
      delete newCountCommissionResult[game]
      delete newOdd[game]
      // delete newOddResult[game]
    } else {
      newArray.push(game);
      newCommission[game] = newCommission.Default
      newCountCommissionResult[game] = newCountCommissionResult.Default
      newOdd[game] = newOdd.Default
      // newOddResult[game] = newOddResult.Default
    }
    const defaultArray =  ['Default', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const arrangedArray = newArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    // CountCommissionResultOdd: JSON.parse(JSON.stringify(newOddResult))
    this.setState({ Games: JSON.parse(JSON.stringify(arrangedArray)), Commission: JSON.parse(JSON.stringify(newCommission)), Odd: JSON.parse(JSON.stringify(newOdd)),  
      CountCommissionResult: JSON.parse(JSON.stringify(newCountCommissionResult))
    })
  }

  PlatformChange(platform) {
    if (platform === '1') return 'M'
    else if (platform === '2') return (this.state.GDisplay === 'MKTSWBD') ? 'K': 'P'
    else if (platform === '3') return 'T'
    else if (platform === '4') return 'S'
    else if (platform === '5') return 'W'
    else if (platform === '6') return 'B'
    else if (platform === '7') return (this.state.GDisplay === 'MKTSWBD') ? 'D' : 'K'
    else if (platform === '8') return '8'
    else if (platform === '9') return '9'
    return 'Default'
  }

  PlatformConvert(platform) {
    let ProcessPlat = processPlatformPosition('123456789' ,this.state.GDisplay, platform, '0')
    return ProcessPlat.join('')
  }

  oddChange(game, field, subfield, e) {
    
    const value = e.target.value
    const evt = value.replace(/[^0-9.]/g, '')
    
    var output = evt.split('.');
    let checkvalue = false
    let ErrorMessage = '';
    let errorfield = ( subfield !== 'SF' &&(field === '1ST' || subfield === '5D1' || subfield === '6D' || subfield === 'CA' ||   subfield === 'PA'  || subfield === 'CF1') && subfield !== 'AC') ? '奖金 1' : 
      (field === '2ND' || subfield === '5D2' || subfield === '6D2' || subfield === 'CB' ||   subfield === 'PB'  || subfield === 'CF2') ? '奖金 2' : 
        (field === '3RD' || subfield === '5D3' || subfield === '6D3' || subfield === 'CC' ||   subfield === 'PC'  || subfield === 'CF3') ? '奖金 3' :
          (field === '4TH' || subfield === '5D4' || subfield === '6D4' || subfield === 'CD' ||   subfield === 'PD') ? '特别' : 
            (field === '5TH' || subfield === '5D5' || subfield === '6D5' || subfield === 'CE' ||   subfield === 'PE') ? '安慰奖' : '奖金 6';

    let errorsubfield = subfield.includes('B') ? '大' : subfield !== 'SA' && subfield !== 'SF' && subfield.includes('S') ? '小' : 
      ((subfield.includes('A')) && (subfield !== 'PD' && subfield !== 'PC' && subfield !== 'PB' && subfield !== 'PA' && subfield !== 'CA' && subfield !== 'CB' && subfield !== 'CC')) ? '单A' :
        (subfield !== 'CF1' && subfield !== 'CF2' && subfield !== 'CF3' && subfield !== 'PC' && subfield.includes('C')) ? '干字(A)' : subfield.includes('CF') ? 'CF(ABC)' : subfield.includes('P') ? '百字' : 
          (subfield !== '6D5' && subfield.includes('5')) ? '5D' : 
            subfield.includes('6') ? '6D' : subfield === 'SF' ? '单A' : subfield;
          
    if((subfield === 'B' || subfield === 'S' || subfield === 'SA' || subfield === 'SB' || subfield === 'SC'
        || subfield === '2B'  || subfield === '2S' || subfield === '3B' || subfield === '3S' || subfield === '4B'
        || subfield === 'SF' || subfield === '5B' || subfield === '5D2' || subfield === '5D3' || subfield === '6D2')){
      if(output[0].length > 4){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan4}\n\n`
      } else {
        checkvalue = true
      }
    }
    if((subfield === 'A' || subfield === 'SE' || subfield === 'SD' ||
      subfield === 'CA' || subfield === 'CB' || subfield === 'CC' || 
      subfield === '5D4' || subfield === '6D3' || subfield === 'CD' )){
      if(output[0].length > 3){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan3}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '6D'){
      if(output[0].length > 6){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan6}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D1'){
      if(output[0].length > 5){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan5}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D5' || subfield === '6D4' || subfield === 'CE' || subfield === 'CF'
    || subfield === 'PF' || subfield === 'PA' || subfield === 'PB' || subfield === 'PC'){
      if(output[0].length > 2){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan2}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === '5D6' || subfield === '6D5' || subfield === 'PD' ||  subfield === 'PE'){
      if(output[0].length > 1){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield}, ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan1}\n\n`
      } else {
        checkvalue = true
      }
    }

    if(subfield === 'CF1' || subfield === 'CF2' || subfield === 'CF3' ) {
      if(output[0].length > 3){
        checkvalue = false
        ErrorMessage = `${this.PlatformChange(game)} ${errorfield} ${errorsubfield}, ${language[this.props.currentLanguage].CannotMoreThan1}\n\n`
      } else {
        checkvalue = true
      }
    }

    output = output.shift() + (output.length ? '.' + output.join('') : '');
    const Math = value.replace(/[^0-9.+-]/g, '')
    const Filteroutput = output
    // var outputs = Math.split('+');
    // var temp = "This is a string+--.";
    var count = (Math.match(/[+-]/g) || []).length;

    const stateObject = this.state.Odd
    const commision = this.state.Commission

    if ( stateObject[game][field] === undefined && field !== 'P'){
      if (count > 1){
        this.AlertopenNotification(`${language[this.props.currentLanguage].Onlyaddoneplusorminus}`)
      } else {
        stateObject[game][field] = { Amount: Math }
      }
    } 
    else if ( field === "Water" && field !== 'P'){
      if (count > 1){
        this.AlertopenNotification(`${language[this.props.currentLanguage].Onlyaddoneplusorminus}`)
      } else {
        stateObject[game][field] = { Amount: Math }
      }
    } 
    else if( field === 'Setup' && field !== 'P') {
      stateObject[game][field] = { Amount: Filteroutput }
      const Number = stateObject[game][field].Amount
      const Arrayfield = ['1ST', '2ND', '3RD', '4TH', '5D', '5TH', '6D']
      Arrayfield.forEach((item) => {
        for( var a = 0; a < Object.keys(stateObject[game][item]).length; a ++) {
          stateObject[game][item][Object.keys(stateObject[game][item])[a]] = Number
        }
      })

      const ArrayCommision = ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D']
      ArrayCommision.forEach((item) => {
        commision[game][item] = Number
      })
    } else {
      if(checkvalue === true){
        if ( field === 'P' && subfield !== "CA"){
          stateObject[game][subfield] = Filteroutput
          // stateObject[game][subfield] = output
          stateObject[game]['PB'] = output
          stateObject[game]['PC'] = output
          stateObject[game]['PD'] = (output/10).toFixed(2)
          stateObject[game]['PE'] = (output/10).toFixed(2)
          stateObject[game]['PF'] = (output/3).toFixed(2)
        } else if ( subfield === "SA"){
          stateObject[game][field][subfield] = output
          stateObject[game]['2ND']['SB'] = output
          stateObject[game]['3RD']['SC'] = output
          stateObject[game]['4TH']['SD'] = (output/10).toFixed(2)
          stateObject[game]['5TH']['SE'] = (output/10).toFixed(2)
          stateObject[game]['1ST']['SF'] = (output/3).toFixed(2)
        }  else if ( subfield === "CA"){
          stateObject[game][subfield] = output
          stateObject[game]['CB'] = output
          stateObject[game]['CC'] = output
          stateObject[game]['CD'] = (output/10).toFixed(2)
          stateObject[game]['CE'] = (output/10).toFixed(2)
        }  else if ( subfield === 'PF' ||  subfield === 'CF1' || subfield === 'CF2' || subfield === 'CF3'){
          stateObject[game][subfield] = Filteroutput
        } else {
          stateObject[game][field][subfield] = Filteroutput
        }
      } else {
        this.setState({ AlertnotificationType: 'error' }, () => {
          this.AlertopenNotification(`${ErrorMessage}`)
        });
      }
    }
    this.setState({ Odd: JSON.parse(JSON.stringify(stateObject)), Commision: JSON.parse(JSON.stringify(commision))}, ()=>{
      if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
        const FieldKeyArrayGame = Object.keys(this.state.Commission[game]);
        const HolyArray = {
          Commision: this.state.Commission,
          Odd: this.state.Odd,
          Games: [game],
          Fields: FieldKeyArrayGame,
        }
        const HolyResult = HolyWater(HolyArray);

        const UpdateCommissionWater = JSON.parse(JSON.stringify(this.state.CountCommissionResult))
        UpdateCommissionWater[game] = HolyResult[game]
        this.setState({ CountCommissionResult: UpdateCommissionWater})
      }
    })

  }

  commissionChange(game, subfield, value) {
 
    const stateObject = JSON.parse(JSON.stringify(this.state.Commission))
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value
      .replace(/\D+/g, '.') // replace non digit with comma
      .replace(/^,|,$/g, '') // trim the comma
  
    var output = e.split('.');
    // output = output.shift() + (output.length ? '.' + output.join('') : '');
    if(output[0].length > 2){
      this.setState({ AlertnotificationType: 'error' }, () => {
        this.AlertopenNotification(language[this.props.currentLanguage].waternotmore2)
      });
    } else {
      output = output.shift() + (output.length ? '.' + output.join('') : '');
      if(subfield === 'SA'){
        stateObject[game]['SB'] = output
        stateObject[game]['SC'] = output
        stateObject[game]['SD'] = output
        stateObject[game]['SE'] = output
        stateObject[game]['SF'] = output
      } else if (subfield === 'CA'){
        stateObject[game]['CB'] = output
        stateObject[game]['CC'] = output
        stateObject[game]['CD'] = output
        stateObject[game]['CE'] = output
      }else if (subfield === 'PA'){
        stateObject[game]['PB'] = output
        stateObject[game]['PC'] = output
        stateObject[game]['PD'] = output
        stateObject[game]['PE'] = output
        stateObject[game]['PF'] = output
      }
      stateObject[game][(subfield === 'P1' ? 'PA1' : subfield === 'P2' ? 'PA2' : subfield === 'P3' ? 'PA3' : subfield)] = output
   
      this.setState({ Commission: JSON.parse(JSON.stringify(stateObject))}, ()=>{
        // (( this.state.activated && this.props.AdminModal) === true ) && 
        if ((this.props.Role === 'admin' || this.props.Role === 'superAdmin')&&(subfield !== '5D' && subfield !== '6D')) {
          const HolyArray = {
            Commision: this.state.Commission,
            Odd: this.state.Odd,
            Games: [game],
            Fields: [subfield],
          }
          const HolyResult = HolyWater(HolyArray);
          // let TotalResult = 0;
          // const KeyArrayGame = Object.keys(this.state.CountCommissionResult);
          // const Keys = Object.keys(this.state.CountCommissionResult.Default);
          // KeyArrayGame.forEach((GamesKey) =>{
          //   Keys.forEach((ItemKey) =>{
          //     TotalResult += Number(this.state.CountCommissionResult[GamesKey][ItemKey])
          //   })
          // })
          // this.setState({TotalResultGame: TotalResult})
          if (HolyResult) {
            const stateObject = this.state.CountCommissionResult
            const ValueHolyWater = HolyResult[game][subfield]
            stateObject[game][subfield] = ValueHolyWater
            this.setState({ CountCommissionResult: JSON.parse(JSON.stringify(stateObject))})
          }
        }
      })
    }
  }

  KeyChangeIndividual(game, e, key){
    // if( this.props.AdminModal && e.key === 'Enter') {
    //   this.saveOcPackage();
    // } else if ( this.props.AdminModal === undefined && e.key === 'Enter'){
    //   this.saveOcPackage();
    // } else 
    if (e.key === '+') {
      this['Input'+ game +key].focus();
      e.preventDefault();
    }
  }

  commissionAllChange(game, value) {
    // prevent more then one dot other symbol or alphabet will be replace to dot.
    const e = value
      .replace(/\D+/g, '.') // replace non digit with comma
      .replace(/^,|,$/g, '') // trim the comma
     
    var output = e.split('.');
    // output = output.shift() + (output.length ? '.' + output.join('') : '');
    if(output[0].length > 2){
      this.setState({ AlertnotificationType: 'error' }, () => {
        this.AlertopenNotification(language[this.props.currentLanguage].waternotmore2)
      });
    } else {
      output = output.shift() + (output.length ? '.' + output.join('') : '');
      const stateObject = this.state.Commission
      const keys = Object.keys(stateObject[game])
      for (let i = 0; i < keys.length; i += 1) {
        stateObject[game][keys[i]] = output
      }
      stateObject[game]['all'] = output
      this.setState({ Commission: JSON.parse(JSON.stringify(stateObject))}, ()=>{
        if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
          const FieldKeyArrayGame = Object.keys(this.state.Commission[game]);
          const HolyArray = {
            Commision: this.state.Commission,
            Odd: this.state.Odd,
            Games: [game],
            Fields: FieldKeyArrayGame,
          }
          const HolyResult = HolyWater(HolyArray);

          const UpdateCommissionWater = JSON.parse(JSON.stringify(this.state.CountCommissionResult))
          UpdateCommissionWater[game] = HolyResult[game]
          this.setState({ CountCommissionResult: UpdateCommissionWater})
        }
      })
    }
  }


  AlertopenNotification(message) {
    this.setState({ AlertnotificationMessage: message }, () => {
      this.setState({ AlertshowNotification: true });
    });
    clearTimeout(timer)
    // timer = setTimeout(() => {
    //   this.setState({ 
    //     AlertshowNotification: false,
    //     AlertnotificationMessage: '',
    //     AlertnotificationType: 'success',
    //     setTimer: 5000,
    //   });
    // }, this.state.setTimer);
    timer = setTimeout(() => {
      this.setState({ 
        AlertshowNotification: false,
        AlertnotificationMessage: '',
        AlertnotificationType: 'success'
      });
    }, 5000);
  };

  ChangeAccountDetails(event, type) {
    this.setState({ [type]: event.target.value })
  }

  updateGameBetting(value) {
    if(value !== 'Custom') {
      this.setState({ GameBetting: value, GameBettingCustom: false })
    } else {
      this.setState({ GameBettingCustom: true })
    }
  }

  updateKeyin1(value) {
    if(value !== 'Custom') {
      this.setState({ KeyIn1: value.split(','), Keyin1Custom: false })
    } else {
      this.setState({ Keyin1Custom: true })
    }
  }

  updateKeyin2(value) {
    if(value !== 'Custom') {
      this.setState({ KeyIn2: value.split(','), Keyin2Custom: false })
    } else {
      this.setState({ Keyin2Custom: true })
    }
  }

  updateGameBettingCustom(index, value) {
    let BettingCustom = this.state.BettingCustom
    let GameBetting = this.state.GameBetting
    BettingCustom[index] = value
    for (let i = 0; i < BettingCustom.length; i += 1) {
      if (i === index) {
      //ignore
      } else if (BettingCustom[i] === value) {
        BettingCustom[i] = '-'
      }
    }
    if (this.state.GDisplay === 'MKTSWBD') {
      GameBetting = BettingCustom.join('').replace('M', '1').replace('K', '2').replace('T', '3').replace('S', '4').replace('W', '5').replace('B', '6').replace('D', '7').replaceAll('-', '-')
    } else {
      GameBetting = BettingCustom.join('').replace('M', '1').replace('P', '2').replace('T', '3').replace('S', '4').replace('W', '5').replace('B', '6').replace('K', '7').replaceAll('-', '-')
    }
    this.setState({ BettingCustom, GameBetting })
  }

  updateKeyin1Custom(type, value, index) {
    if (type === 'Keyin1') {
      let Keyin1CustomData = this.state.Keyin1CustomData
      let KeyIn1 = this.state.KeyIn1
      Keyin1CustomData[index] = value
      KeyIn1 = Keyin1CustomData
      this.setState({ Keyin1CustomData, KeyIn1 })
    }
    if (type === 'Keyin2') {
      let Keyin2CustomData = this.state.Keyin2CustomData
      let KeyIn2 = this.state.KeyIn2
      Keyin2CustomData[index] = value
      KeyIn2 = Keyin2CustomData
      this.setState({ Keyin2CustomData, KeyIn2 })
    }
  }


  render() {
    return (
      <div style={{ width: '100%', backgroundColor: '#EFEFEF', height: '100vh', flexDirection: 'column', overflowY: 'auto'}}>
        <Header />
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} 
            show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="row TitleBettingVersion" style={{ marginLeft: 50, marginRight: 50 }}>
          <div className="dcc jackpotborder" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].fastRegister}</div>
        </div>
        <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
          <div style={{ width: '95%', marginBottom: 20, display: 'flex', flexDirection: 'column'}}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
              <div style={{ width: '50%', display: 'flex', flexDirection: 'column'}}>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>{language[this.props.currentLanguage].USerLoginAcc}</div>
                  <div style={{ width: '60%'}}>
                    <Form.Control
                      value={this.state.CreateUser.Username}
                      className='ReceiptHistorySearch TextFontSize'
                      style={{ width: '70%' }}
                      type="text"
                      onChange={(e) => this.CreateUserFunction(e, 'Username')}
                    />
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>{language[this.props.currentLanguage].Password}</div>
                  <div style={{ width: '60%'}}>
                    <Form.Control
                      value={this.state.CreateUser.Password}
                      className='ReceiptHistorySearch TextFontSize'
                      style={{ width: '70%' }}
                      type="text"
                      onChange={(e) => this.CreateUserFunction(e, 'Password')}
                    />
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>{language[this.props.currentLanguage].Name}</div>
                  <div style={{ width: '60%'}}>
                    <Form.Control
                      value={this.state.CreateUser.Name}
                      className='ReceiptHistorySearch TextFontSize'
                      style={{ width: '70%' }}
                      type="text"
                      onChange={(e) => this.CreateUserFunction(e, 'Name')}
                    />
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>{language[this.props.currentLanguage].MD}</div>
                  <div style={{ width: '60%'}}>
                    <Form.Control as="select" style={{ width: '70%', textAlign: 'center' }} onChange={(e) => this.ChangeAccountDetails(e, 'MD')}
                      value={this.state.MD} disabled={this.state.AbleChange === 1 ? false : true}>
                      <option value="M">{language[this.props.currentLanguage].Multiply}</option>
                      <option value="D">{language[this.props.currentLanguage].Divide}</option>
                    </Form.Control>
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>{language[this.props.currentLanguage].GameDisplay}</div>
                  <div style={{ width: '60%'}}>
                    <Form.Control as="select" style={{ width: '70%', textAlign: 'center' }} onChange={(e) => this.ChangeAccountDetails(e, 'GameDisplay')}
                      value={this.state.GameDisplay} disabled={this.state.AbleChange === 1 ? false : true}>
                      <option value="MKTSWBD">M(K)TSWB(D)</option>
                      <option value="MPTSWBK">M(P)TSWB(K)</option>
                    </Form.Control>
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>#MKT</div>
                  <div style={{ width: '60%'}}>
                    <Form.Check
                      style={{ width: '70%', textAlign: 'center' }}
                      inline
                      type="checkbox"
                      checked={this.state.JinNan === 1}
                      onChange={(e) => {
                        this.setState({ JinNan: this.state.JinNan === 1 ? 0 : 1})
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: '50%', display: 'flex', flexDirection: 'column'}}>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>{language[this.props.currentLanguage].DailyLimit}</div>
                  <div style={{ width: '60%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.Control
                      value={this.state.CreateUser.dailyLimit}
                      className='ReceiptHistorySearch TextFontSize'
                      style={{ width: '70%' }}
                      type="number"
                      onChange={(e) => this.CreateUserFunction(e, 'dailyLimit')}
                    />
                    <div style={{ fontWeight: 'bold', fontSize: '18px', marginLeft: 5}}>{`/ ${this.state.DisplayDailyLimit}`}</div>
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>{`${language[this.props.currentLanguage].Fighting}%`}</div>
                  <div style={{ width: '60%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.Control
                      value={this.state.CreateUser.EatPercent}
                      className='ReceiptHistorySearch TextFontSize'
                      style={{ width: '70%' }}
                      type="number"
                      onChange={(e) => this.CreateUserFunction(e, 'EatPercent')}
                    />
                    {JSON.stringify(this.state.DisplayPackageObj) !== '{}' && 
                      <div style={{ fontWeight: 'bold', fontSize: '18px', marginLeft: 5}}>{`/ ${this.state.DisplayPackageObj['Default']['B'].percent}`}</div>
                    }
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%'}}>{`BT${language[this.props.currentLanguage].Fighting}%`}</div>
                  <div style={{ width: '60%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Form.Control
                      value={this.state.CreateUser.BTEatPercent}
                      className='ReceiptHistorySearch TextFontSize'
                      style={{ width: '70%' }}
                      type="number"
                      onChange={(e) => this.CreateUserFunction(e, 'BTEatPercent')}
                    />
                    {JSON.stringify(this.state.DisplayPackageObj) !== '{}' && 
                      <div style={{ fontWeight: 'bold', fontSize: '18px', marginLeft: 5}}>{`/ ${(Object.keys(this.state.DisplayPackageObj).includes('8')) ? this.state.DisplayPackageObj['8']['B'].percent : this.state.DisplayPackageObj['Default']['B'].percent }`}</div>
                    }
                  </div>
                </div>
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%' }}>{language[this.props.currentLanguage].GameBetting}</div>
                  <div style={{ width: '60%' }}>
                    <Form.Control as="select" disabled={this.state.AbleChange === 1 ? false : true} style={{ width: '70%', textAlign: 'center' }} 
                      onChange={(e) => this.updateGameBetting(e.target.value)}
                      value={this.state.GameBettingCustom === true ? 'Custom' : this.PlatformConvert(this.state.GameBetting)}>
                      <option value={this.state.UplineGameBetting}>{this.PlatformConvert(this.state.UplineGameBetting)}</option>
                      <option value="Custom">{language[this.props.currentLanguage].Custom}</option>
                    </Form.Control>
                  </div>
                </div>
                {this.state.GameBettingCustom && 
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((inx) => <Form.Control 
                    as="select" 
                    style={{ width: 45, marginRight: 2, padding: 0}} 
                    value={this.state.BettingCustom[inx] || '-'}
                    onChange={(e) => this.updateGameBettingCustom(inx, e.target.value)}
                  >
                    {this.state.GDisplay === 'MKTSWBD' ? ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9', '-'].map((item) => 
                      <option value={item}>{item}</option>
                    ) :  ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9', '-'].map((item) => 
                      <option value={item}>{item}</option>
                    )}
                  </Form.Control>)}
                </div>}
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%' }}>{language[this.props.currentLanguage].InputMethod}</div>
                  <div style={{ width: '60%' }}>
                    <Form.Control as="select" style={{ width: '70%', textAlign: 'center' }} 
                      onChange={(e) => this.updateKeyin1(e.target.value)}
                      value={this.state.Keyin1Custom === true ? 'Custom' : this.state.KeyIn1.join(',').replace('CA', 'A').replace('CF', 'C')}>
                      <option value={this.state.UplineKeyin1}>{this.state.UplineKeyin1.join(',').replace('CA', 'A').replace('CF', 'C')}</option>
                      <option value="Custom">{language[this.props.currentLanguage].Custom}</option>
                    </Form.Control>
                  </div>
                </div>
                {this.state.Keyin1Custom && 
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  {[0, 1, 2, 3, 4, 5].map((inx) => <Form.Control as="select" style={{ width: 55, marginRight: 10, padding: 0}} 
                    value={this.state.Keyin1CustomData[inx]}
                    onChange={(e) => this.updateKeyin1Custom('Keyin1', e.target.value, inx)}
                  >
                    {this.state.allBetModeSelect.map((item) => {
                      if (item === 'CA') {
                        return <option value={item}>{'A'}</option>
                      } else if (item === 'CF') {
                        return <option value={item}>{'C'}</option>
                      } else {
                        return <option value={item}>{item}</option>
                      }
                    })}
                  </Form.Control>)}
                </div>}
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  <div style={{ width: '20%' }}>{language[this.props.currentLanguage].InputMethod}2</div>
                  <div style={{ width: '60%' }}>
                    <Form.Control as="select" disabled={this.state.AbleChange === 1 ? false : true} style={{ width: '70%', textAlign: 'center' }} 
                      onChange={(e) => this.updateKeyin2(e.target.value)}
                      value={this.state.Keyin2Custom === true ? 'Custom' : this.state.KeyIn2.join(',').replace('CA', 'A').replace('CF', 'C')}>
                      <option value={this.state.UplineKeyin2}>{this.state.UplineKeyin2.join(',').replace('CA', 'A').replace('CF', 'C')}</option>
                      <option value="Custom">{language[this.props.currentLanguage].Custom}</option>
                    </Form.Control>
                  </div>
                </div>
                {this.state.Keyin2Custom && 
                <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 3 }}>
                  {[0, 1, 2, 3, 4, 5].map((inx) => <Form.Control as="select" style={{ width: 55, marginRight: 10, padding: 0}} 
                    value={this.state.Keyin2CustomData[inx]}
                    onChange={(e) => this.updateKeyin1Custom('Keyin2', e.target.value, inx)}
                  >
                    {this.state.allBetModeSelect.map((item) => {
                      if (item === 'CA') {
                        return <option value={item}>{'A'}</option>
                      } else if (item === 'CF') {
                        return <option value={item}>{'C'}</option>
                      } else {
                        return <option value={item}>{item}</option>
                      }
                    })}
                    {/* <option value="-">-</option> */}
                  </Form.Control>)}
                </div>}
              </div>
            </div>
          </div>
          {Object.keys(this.state.EditedEwPackage).includes('1') && 
          <div style={{ width: '95%', border: '1px solid gray', borderRadius: 2, marginBottom: 10, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 5, backgroundColor: '#003986', color: 'white' }}>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150 }}>{''}</div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.MagCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '1')}></input>
                }
                <input
                  checked={this.state.MagCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'MagCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{language[this.props.currentLanguage].magnum}</div><img src={MagnumLogo} style={{ width: 25 }} alt="Logo" />
              </div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.DaCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '2')}></input>
                }
                <input
                  checked={this.state.DaCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'DaCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{language[this.props.currentLanguage].damacai}</div><img src={DamacaiLogo} style={{ width: 25 }} alt="Logo" />
              </div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.TotoCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '3')}></input>
                }
                <input
                  checked={this.state.TotoCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'TotoCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{language[this.props.currentLanguage].toto}</div><img src={TotoLogo} style={{ width: 25 }} alt="Logo"/>
              </div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.SgCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '4')}></input>
                }
                <input
                  checked={this.state.SgCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'SgCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{language[this.props.currentLanguage].singapore}</div><img src={Sg4Logo} style={{ width: 25 }} alt="Logo"/>
              </div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.SarawakCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '5')}></input>
                }
                <input
                  checked={this.state.SarawakCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'SarawakCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{language[this.props.currentLanguage].sarawak}</div><img src={SarawakLogo} style={{ width: 25 }} alt="Logo"/>
              </div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.SabahCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '6')}></input>
                }
                <input
                  checked={this.state.SabahCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'SabahCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{language[this.props.currentLanguage].sabah}</div><img src={Sabah88Logo} style={{ width: 25 }} alt="Logo" />
              </div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.SdkCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '7')}></input>
                }
                <input
                  checked={this.state.SdkCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'SdkCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{language[this.props.currentLanguage].sandakan}</div><img src={SansakanLogo} style={{ width: 25 }} alt="Logo"/>
              </div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.BTCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '8')}></input>
                }
                <input
                  checked={this.state.BTCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'BTCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{language[this.props.currentLanguage].GrandDragon}</div><img src={GDLogo} style={{ width: 25 }} alt="Logo"/>
              </div>
              <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row', padding: 2 }}>
                {this.state.BTCheck === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAll(e, '9')}></input>
                }
                <input
                  checked={this.state.BTCheck}
                  className="BoxversionCheckboxSize"
                  onChange={(e) => this.CheckedPlat(e, 'BTCheck')}
                  type="checkbox"
                />
                <div style={{ marginRight: 5, marginLeft: 5 }}>{`9Lotto`}</div><img src={NINELogo} style={{ width: 25 }} alt="Logo"/>
              </div>
            </div>
            <div style={{ width: '100%', height: 'auto' }}>
              {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D'].map((items, index) => {
                return(
                  <div style={{ width: 'auto', display: 'flex', flexDirection: 'row', padding: 5, backgroundColor: (index%2) ? '#E6E6E6' : '#F5F5F5' }} key={index}>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', minWidth: 150, display: 'flex', flexDirection: 'row' }}>
                      {this.state[items] === true && 
                  <input type='number' style={{ width: '38%', textAlign: 'center'}} onChange={(e) => this.ChangeValuesAllbyKeys(e, items)}></input>
                      }
                      <input
                        checked={this.state[items]}
                        className="BoxversionCheckboxSize"
                        onChange={(e) => this.CheckedPlatbyKeys(e, items)}
                        type="checkbox"
                      />
                      <div>{items}</div>
                    </div>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      {items !== '5D' && items !== '6D' && 
                      <>
                        <input type='number' value={this.state.EditedEwPackage['1'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['1'] && this.state.EditedEwPackage['1'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '1', items)}></input>
                        <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('1') ? this.state.DisplayPackageObj['1'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                      </>}
                    </div>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      {items !== '5D' && items !== '6D' && 
                      <>
                        <input type='number' value={this.state.EditedEwPackage['2'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['2'] && this.state.EditedEwPackage['2'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '2', items)}></input>
                        <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('2') ? this.state.DisplayPackageObj['2'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                      </>}
                    </div>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      <input type='number' value={this.state.EditedEwPackage['3'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['3'] && this.state.EditedEwPackage['3'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '3', items)}></input>
                      <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('3') ? this.state.DisplayPackageObj['3'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                    </div>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      {items !== '5D' && items !== '6D' && 
                      <>
                        <input type='number' value={this.state.EditedEwPackage['4'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['4'] && this.state.EditedEwPackage['4'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '4', items)}></input>
                        <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('4') ? this.state.DisplayPackageObj['4'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                      </>}
                    </div>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      {items !== '5D' && items !== '6D' && 
                      <>
                        <input type='number' value={this.state.EditedEwPackage['5'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['5'] && this.state.EditedEwPackage['5'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '5', items)}></input>
                        <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('5') ? this.state.DisplayPackageObj['5'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                      </>}
                    </div>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      {items !== '5D' && items !== '6D' && 
                      <>
                        <input type='number' value={this.state.EditedEwPackage['6'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['6'] && this.state.EditedEwPackage['6'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '6', items)}></input>
                        <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('6') ? this.state.DisplayPackageObj['6'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                      </>}
                    </div>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      {(items !== '5D' && items !== '6D') && 
                      <>
                        <input type='number' value={this.state.EditedEwPackage['7'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['7'] && this.state.EditedEwPackage['7'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '7', items)}></input>
                        <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('7') ? this.state.DisplayPackageObj['7'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                      </>}
                    </div>
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      <input type='number' value={this.state.EditedEwPackage['8'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['8'] && this.state.EditedEwPackage['8'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '8', items)}></input>
                      <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('8') ? this.state.DisplayPackageObj['8'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                    </div>
                    {/* need change */}
                    <div className='dcc' style={{ width: 'calc(100% / 10)', display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                      <input type='number' value={this.state.EditedEwPackage['8'][items]['max']} style={{ width: '38%', textAlign: 'center', backgroundColor: this.state.EditedEwPackage['8'] && this.state.EditedEwPackage['8'][items].max !== '0' ? 'yellow' : null}} onChange={(e) => this.ChangeValues(e, '8', items)}></input>
                      <div style={{ fontSize: '18px', marginLeft: 5 }}>{`/${Object.keys(this.state.DisplayPackageObj).includes('8') ? this.state.DisplayPackageObj['8'][items].max : this.state.DisplayPackageObj['Default'][items].max }`}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          }
          <div style={{ width: '95%', border: '1px solid gray', borderRadius: 2}}>
            <div className="dcc" style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width:  '100%', marginBottom: 30, justifyContent: null, paddingTop: 10  }}>
              {this.state.PackageList.map((item, index)=>(
                <Button key={item.PackageID} style={{  margin: 5, display: 'flex', justifyContent: 'center', width: 'calc(100% / 10)' }} variant="primary" onClick={() =>  this.SetPackage(index)}>
                  <div className="wrap">
                    {item.Name}
                    {this.state.activeKeys === index && <img src={tickicons} alt="tick" style={{ marginLeft: 10, width: 25, height: 25, borderRadius: 25}}/>}
                  </div>
                </Button>
              ))}
            </div>
            <div className='dcc'  style={{ display: 'flex', flexDirection: 'column',  marginBottom: 20, }}>
              <div style={{ marginLeft: 20, display: 'flex', alignItems: 'center', marginBottom: 10, fontSize: 24, width: '100%'}}>
                <div style={{ marginRight: 10 , width: 220 }}>{language[this.props.currentLanguage].FollowingGameSettingSameAsDefault}</div>
                {this.state.GamePlatform.map((item) => {
                  return <Form.Check
                    disabled={this.state.activeKeys === '' ? true : false}
                    style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                    inline
                    label={(this.state.GDisplay === 'MPTSWBK') ? GamePlatformNumb2[item] :  GamePlatformNumb1[item]}
                    type="checkbox"
                    checked={!this.state.Games.includes(item)}
                    onChange={(e) => this.gameChange(item, e.target.value)}
                  />
                })}
              </div>
              {this.state.Games.map((game) => {
                return <div 
                  style={{  backgroundColor: Platformcolor(game, ''), width: '', borderRadius: 10, paddingTop: 10,  color: ((game === '3') ? '#fff'  : (game === '8') ? '#fff' : '#000'), paddingBottom: 10 }}>
                  <div className="dcc" style={{ justifyContent: 'center' }}>
                    <Alert className="dcc" style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width: '70%', left: 250}}show={this.state.AlertshowNotification} variant={this.state.AlertnotificationType === 'error' ? 'danger' : 'success'}>
                      <Alert.Heading>{this.state.AlertnotificationMessage}</Alert.Heading>
                    </Alert>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', width: '1000px', fontSize: 20, justifyContent: 'center', marginBottom: 10}}>
                    {(game === 'Default') && <div className='row'>
                      <div style={{ width: 'auto', fontWeight: 'bold', paddingRight: 10}}>{language[this.props.currentLanguage].Package} : {this.state.PackageList[this.state.activeKeys].Name}</div>
                      <div style={{ width: 150, fontWeight: 'bold'}}>ID: {this.state.PackageList[this.state.activeKeys].PackageID}</div>
                      <div style={{ marginLeft: 20, width: 150, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Games}: {this.PlatformChange(game)}</div>
                    </div>}
                  </div>
                  <div  style={{ display: 'flex',  width:'100%'}}>
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                      <div style={{ width: 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                      {([ `${language[this.props.currentLanguage].Simplicity}`, '大', '小', '单A', '干字(A)', 'CF(ABC)' ,'百字','5D', '6D']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                          <Form.Control disabled
                            style={{ width:  (this.state.Commission[game] && this.state.Commission[game][
                              (item === '大') ? 'B' : (item === '小') ? 'S' : (item === '单A') ? 'SA' : (item === 'CF(ABC)') ? 'CF' : 
                                (item === '干字(A)') ? 'CA' :  (item === '百字') ? 'PA' : (item === '5D' || item === '6D' ) ? item : 'all'
                            ]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][ (item === '大') ? 'B' : (item === '小') ? 'S' : (item === '单A') ? 'SA' :  (item === 'CF(ABC)') ? 'CF' : 
                                (item === '干字(A)') ? 'CA' : (item === '百字') ? 'PA' :  (item === '5D' || item === '6D' ) ? item : 'all']).length * 8)) : 80, 
                            textAlign: 'center',fontWeight: 'bold', backgroundColor: 'white'}} 
                            type="text" value={item}/>
                        </div>
                      })}
                    </div>
                  </div>
                  <div  style={{ display: 'flex',  width:'100%', flexDirection: 'column', marginBottom: 5}}>
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                        <div style={{ width: 80, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Money}</div>
                      </div>
                   
                      <Form.Control style={{  marginRight: 5, display: 'flex', width: (this.state.Commission[game]['all'] === undefined || this.state.Commission[game]['all'] === '') ? 80 : 70 + JSON.stringify(this.state.Commission[game]['all']).length * 8, textAlign: 'center',fontWeight: 'bold'}} 
                        type="text" value={this.state.Commission[game]['all']}
                        onKeyPress={(e) => this.KeyChangeIndividual( game,  e, 'B', )}
                        ref={(input) => { this[`Input${game}All`] = input;}}
                        onChange={(e) => this.commissionAllChange(game, e.target.value)}/>
                      {(['B', 'S', 'SA', 'CA', 'CF' , 'PA', '5D', '6D']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                          <Form.Control 
                            style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][item]).length * 8)) : 80, textAlign: 'center',fontWeight: 'bold'}} 
                            type="text" 
                            ref={(input) => { this[`Input${game}${item}`] = input;}}
                            onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? 'S' : (item === 'S') ? 'SA' : (item === 'SA') ? 'CA' : 
                              (item === 'CA') ? 'CF' : (item === 'CF') ? 'PA' :  (item === 'PA') ? '5D' : 
                                (item === '5D') ? '6D' : '1B',)}
                            value={this.state.Commission[game][item]}
                            onChange={(e) => this.commissionChange(game, item, e.target.value)}
                          />
                        </div>
                      })}
                    </div>
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5}}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,  width: 80, }}/>
                      <div style={{  marginRight: 5, display: 'flex', width: (this.state.Commission[game]['all'] === undefined || this.state.Commission[game]['all'] === '') ? 80 : 70 + JSON.stringify(this.state.Commission[game]['all']).length * 8, textAlign: 'center',fontWeight: 'bold'}} 
                      />
                      {(['B', 'S', 'SA', 'CA', 'CF' , 'PA', '5D', '6D']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                          <div
                            style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][item]).length * 8)) : 80, textAlign: 'center',fontWeight: 'bold'
                            ,color: game === 'Default' ? 'red' : 'auto'}}
                          >
                            {this.state.ParentCommission[game] ? this.state.ParentCommission[game][item] : this.state.ParentCommission['Default'][item]}
                          </div>
                        </div>
                      })}
                    </div>
                    {(this.props.Role === 'admin' || this.props.Role === 'superAdmin')&& <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,}}>
                      <div style={{ width: 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                      <div style={{ width: (this.state.CountCommissionResult &&  this.state.Commission[game] && this.state.Commission[game]['all']) ? 
                        ( 70  + (JSON.stringify(this.state.Commission[game]['all']).length * 8)) : 80, textAlign: 'center', marginRight: 5,fontWeight: 'bold'}} />
                      {(['B', 'S', 'SA', 'CA','CF', 'PA', '5D1', '6D']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                          {(item !== '5D1' && item !== '6D') ? <Form.Control disabled
                            style={{ backgroundColor: '#C3f3fb',  width:  (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][item]).length * 8)) : 80, 
                            textAlign: 'center',fontWeight: 'bold', 
                            color: ((/[-]/).test(this.state.CountCommissionResult && this.state.CountCommissionResult[game] && this.state.CountCommissionResult[game][item])) ? 'red' : 'black' }} 
                            type="text" value={(this.state.CountCommissionResult && this.state.CountCommissionResult[game] && this.state.CountCommissionResult[game][item]) || 0}/> : 
                            ( this.props.AdminModal && <div style={{ paddingBottom: (this.state.activated === false) ? 0 : (this.props.Role === 'agent' || this.props.Role === 'player') ? 0 : 38}}/>)}
                        </div>
                      })}
                    </div>}
                    <hr />
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5, marginBottom: 5 }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                        <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 1'}</div>
                      </div>
                      {([ 'all', 'B', 'S', 'SA', 'CA','CF1' , 'PA', '5D', '6D']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                          {(item === 'all') ? 
                            <div style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D') ? '5D' : item]).length * 8)) : 80, 
                            textAlign: 'center', fontWeight: 'bold'}} />
                            :<Form.Control 
                              onChange={(e) => this.oddChange(game, 
                                (item === 'B' || item === 'S' || item === 'SA') ? '1ST' : 
                                  (item === 'PA' || item === 'CA') ? 'P' : 
                                    (item === '5D') ? '5D' : 
                                      item
                                , 
                                (item === '5D') ? '5D1' : item, e)}
                              ref={(input) => { this[`Input${game}1${item}`] = input;}}
                              onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? '1S' : (item === 'S') ? '1SA' : (item === 'SA') ? '1CA' : 
                                (item === 'CA') ? '1CF1' :   (item === 'CF1') ? '1PA' : (item === 'PA') ? '15D' : 
                                  (item === '5D') ? '16D' : '22B')}
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D') ? '5D' : (item === 'CF1') ? 'CF' : item]).length * 8)) : 80, 
                              textAlign: 'center',fontWeight: 'bold'}} 
                              value={this.state.Odd && this.state.Odd[game] ? (item === 'B' || item === 'S' || item === 'SA') ?this.state.Odd[game]['1ST'][item] 
                                : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.Odd[game][item] 
                                  : (item === '5D') ?  this.state.Odd[game]['5D']['5D1'] 
                                    :   this.state.Odd[game]['6D'][item] : ''
                              } 
                              type="text" />}
                          {(item === 'all') ? 
                            <div style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D') ? '5D' : item]).length * 8)) : 80, 
                            textAlign: 'center', fontWeight: 'bold'}} />
                            :<div
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D') ? '5D' : (item === 'CF1') ? 'CF' : item]).length * 8)) : 80, 
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}}  >
                              {this.state.ParentOdd && this.state.ParentOdd[game] ? (item === 'B' || item === 'S' || item === 'SA') ?this.state.ParentOdd[game]['1ST'][item] 
                                : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.ParentOdd[game][item] 
                                  : (item === '5D') ?  this.state.ParentOdd[game]['5D']['5D1'] 
                                    :   this.state.ParentOdd[game]['6D'][item] : 
                                (item === 'B' || item === 'S' || item === 'SA') ?this.state.ParentOdd['Default']['1ST'][item] 
                                  : (item === 'CA' || item === 'PA'|| item === 'CF1') ? this.state.ParentOdd['Default'][item] 
                                    : (item === '5D') ?  this.state.ParentOdd['Default']['5D']['5D1'] 
                                      :   this.state.ParentOdd['Default']['6D'][item]
                              } 
                            </div>}
                        </div>
                      })}
                    </div>
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                        <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 2'}</div>
                      </div>
                      {([ 'all', '2B', '2S', 'SB', 'CB', 'CF2','PB', '5D2', '6D2']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                          {(item === 'all') ? 
                            <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 80, 
                            textAlign: 'center', fontWeight: 'bold'}} />
                            :<Form.Control 
                              readOnly={(item === 'SB' || item === 'CB' || item === 'PB')}
                              onChange={(e) => this.oddChange(game, 
                                (item === '2B' || item === '2S' || item === 'SB') ? '2ND' : 
                                  (item === 'CB' || item === 'PB') ? 'P' : (item === '5D2') ? '5D' : '6D', 
                                (item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item, e)}
                              style={{ width: (this.state.Commission[game] ) ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                                  (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' : (item === 'CF2') ? 'CF' : 
                                    (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold'}} 
                              ref={(input) => { this[`Input${game}2${item}`] = input;}}
                              onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === '2B') ? '22S' : (item === '2S') ? '2SB' :  
                                (item === 'SB') ? '2CB' : (item === 'CB') ? '2CF2' : (item === 'CF2') ? '2PB' : (item === 'PB') ? '25D2' :
                                  (item === '5D2') ? '26D2' : '33B')}
                              value={(this.state.Odd[game]) ? (item === '2B' || item === '2S' || item === 'SB') ?   this.state.Odd[game]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                                : (item === 'CB' || item === 'PB'|| item === 'CF2') ?  this.state.Odd[game][item] 
                                  : (item === '5D2') ?  this.state.Odd[game]['5D'][item] 
                                    :  this.state.Odd[game]['6D'][item] 
                                : ''}
                              type="text" />}
                          {(item === 'all') ? 
                            <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 80, 
                            textAlign: 'center', fontWeight: 'bold'}} />
                            :<div
                              style={{ width: (this.state.Commission[game] ) ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '2B') ? 'B' : (item === '2S') ? 'S' : 
                                  (item === 'SB') ? 'SA' : (item === 'CB') ? 'CA' : (item === 'CF2') ? 'CF' : 
                                    (item === 'PB') ? 'PA' : (item === '5D2') ? '5D' : '6D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} 
                            >
                              {(this.state.ParentOdd[game]) ? (item === '2B' || item === '2S' || item === 'SB') ?   this.state.ParentOdd[game]['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                                : (item === 'CB' || item === 'PB'|| item === 'CF2') ?  this.state.ParentOdd[game][item] 
                                  : (item === '5D2') ?  this.state.ParentOdd[game]['5D'][item] 
                                    :  this.state.ParentOdd[game]['6D'][item] 
                                : (item === '2B' || item === '2S' || item === 'SB') ?   this.state.ParentOdd['Default']['2ND'][(item=== '2B') ? 'B': (item=== '2S') ? 'S' :  item] 
                                  : (item === 'CB' || item === 'PB'|| item === 'CF2') ?  this.state.ParentOdd['Default'][item] 
                                    : (item === '5D2') ?  this.state.ParentOdd['Default']['5D'][item] 
                                      :  this.state.ParentOdd['Default']['6D'][item] }
                            </div>}
                        </div>
                      })}
                    </div>
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5, }}>
                        <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 3'}</div>
                      </div>
                      {([ 'all', '3B', '3S', 'SC', 'CC','CF3', 'PC', '5D3', '6D3']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                          {(item === 'all') ? 
                            <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                            :<Form.Control 
                              readOnly={(item === 'SC' || item === 'CC' || item === 'PC' )}
                              onChange={(e) => this.oddChange(game, 
                                (item === '3B' || item === '3S' || item === 'SC') ? '3RD' :
                                  (item === 'CF3') ? 'CF3' : (item === 'CC' || item === 'PC') ? 'P' :  
                                    (item === '5D3') ? '5D' : 
                                      '6D', 
                                (item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item, e)}
                              ref={(input) => { this[`Input${game}3${item}`] = input;}}
                              onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === '3B') ? '33S' : (item === '3S') ? '3SC' :
                                (item === 'SC') ? '3CC' : (item === 'CC') ? '3CF3' :  (item === 'CF3') ? '3PC' : (item === 'PC') ? '35D3' :  (item === '5D3') ? '36D3' : '4B')}
                              value={this.state.Odd[game] ? (item === '3B' || item === '3S' || item === 'SC') ?  this.state.Odd[game]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                                : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.Odd[game][item] 
                                  : (item === '5D3') ?  this.state.Odd[game]['5D'][item] 
                                    :   this.state.Odd[game]['6D'][item] : ''}
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                                  (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                                    (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold'}} 
                              type="text" />}
                            
                          {(item === 'all') ? 
                            <div style={{ width: (this.state.Commission[game] && this.state.Commission[game][item]) ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === '5D1') ? '5D' : item]).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                            :<div 
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === '3B') ? 'B' : (item === '3S') ? 'S' : 
                                  (item === 'SC') ? 'SA' : (item === 'CC') ? 'CA' : (item === 'CF3') ? 'CF' :
                                    (item === 'PC') ? 'PA' : (item === '5D3') ? '5D' : '6D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} 
                            >
                              {this.state.ParentOdd[game] ? (item === '3B' || item === '3S' || item === 'SC') ?  this.state.ParentOdd[game]['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                                : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.ParentOdd[game][item] 
                                  : (item === '5D3') ?  this.state.ParentOdd[game]['5D'][item] 
                                    :   this.state.ParentOdd[game]['6D'][item] : 
                                (item === '3B' || item === '3S' || item === 'SC') ?  this.state.ParentOdd['Default']['3RD'][(item=== '3B') ? 'B': (item=== '3S') ? 'S' :  item] 
                                  : (item === 'CC' || item === 'PC' || item === 'CF3') ?  this.state.ParentOdd['Default'][item] 
                                    : (item === '5D3') ?  this.state.ParentOdd['Default']['5D'][item] 
                                      :   this.state.ParentOdd['Default']['6D'][item] }
                            </div>}
                        </div>
                      })}
                    </div>
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                        <div style={{ width: 80, fontWeight: 'bold'}}>{'特别'}</div>
                      </div>
                      {([ 'all', 'B', 'empty', 'SD', 'CD', 'emptyC', 'PD', '5D4', '6D4']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                          {(item === 'all' || item === 'empty' || item === 'emptyC' ) ? 
                            <div style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']).length * 8)) : 
                              80
                            , textAlign: 'center', fontWeight: 'bold'}} />
                            : <Form.Control 
                              readOnly={(item === 'SD' || item === 'CD' || item === 'PD')}
                              onChange={(e) => this.oddChange(game, 
                                (item === 'B' || item === 'SD' ) ? '4TH' : (item === 'CD' || item === 'PD' ) ? 'P' : 
                                  (item === '5D4') ? '5D' :  '6D', 
                                item, e)}
                              onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? '4SD' : (item === 'SD') ? '4CD' :
                                (item === 'CD') ? '4PD' : (item === 'PD') ? '45D4' : (item === '5D4') ? '46D4' : '5B')}
                              ref={(input) => { this[`Input${game}4${item}`] = input;}}
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                                  (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                                    (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold'}} 
                              value={ this.state.Odd[game] ? (item === 'B'  || item === 'SD') ?  this.state.Odd[game]['4TH'][item] 
                                : (item === 'CD' || item === 'PD') ?  this.state.Odd[game][item] 
                                  : (item === '5D4') ?  this.state.Odd[game]['5D'][item] 
                                    : this.state.Odd[game]['6D'][item] : ''
                              }
                              type="text" />}
                            
                          {(item === 'all' || item === 'empty' || item === 'emptyC' ) ? 
                            <div style={{ width: this.state.Commission[game] ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']).length * 8)) : 
                              80
                            , textAlign: 'center', fontWeight: 'bold'}} />
                            : <div
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : (item === '') ? 'S' : 
                                  (item === 'SD') ? 'SA' : (item === 'CD') ? 'CA' : 
                                    (item === 'PD') ? 'PA' : (item === '5D4') ? '5D' : '6D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} >
                              { this.state.ParentOdd[game] ? (item === 'B'  || item === 'SD') ?  this.state.ParentOdd[game]['4TH'][item] 
                                : (item === 'CD' || item === 'PD') ?  this.state.ParentOdd[game][item] 
                                  : (item === '5D4') ?  this.state.ParentOdd[game]['5D'][item] 
                                    : this.state.ParentOdd[game]['6D'][item] : 
                                (item === 'B'  || item === 'SD') ?  this.state.ParentOdd['Default']['4TH'][item] 
                                  : (item === 'CD' || item === 'PD') ?  this.state.ParentOdd['Default'][item] 
                                    : (item === '5D4') ?  this.state.ParentOdd['Default']['5D'][item] 
                                      : this.state.ParentOdd['Default']['6D'][item]
                              }
                            </div>}
                        </div>
                      })}
                    </div>
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                        <div style={{ width: 80, fontWeight: 'bold'}}>{'安慰奖'}</div>
                      </div>
                      {([ 'all', 'B', 'empty', 'SE', 'CE', 'emptyC','PE', '5D5', '6D5']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                          {(item === 'all' || item === 'empty' || item === 'emptyC') ? 
                            <div style={{ width: this.state.Commission[game]  ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                            :<Form.Control 
                              readOnly={(item === 'SE' || item === 'CE' || item === 'PE')}
                              onChange={(e) => this.oddChange(game, 
                                (item === 'B' || item === 'SE') ? '5TH' : 
                                  (item === 'CE' || item === 'PE') ? 'P' :  (item === '5D5') ? '5D' : '6D', 
                                item, e)}
                              ref={(input) => { this[`Input${game}5${item}`] = input;}}
                              onKeyPress={(e) => this.KeyChangeIndividual(game, e, (item === 'B') ? '5SE' :  (item === 'SE') ? '5CE' :
                                (item === 'CE') ? '5PE' : (item === 'PE') ? '55D5' :(item === '5D5') ? '56D5' : '6SF')}
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : 
                                  (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                                    (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold'}} 
                              value={this.state.Odd[game] ? (item === 'B' || item === 'SE') ? this.state.Odd[game]['5TH'][item] 
                                : (item === 'CE' || item === 'PE') ? this.state.Odd[game][item] 
                                  : (item === '5D5') ? this.state.Odd[game]['5D'][item] 
                                    : this.state.Odd[game]['6D'][item] : ''
                              }
                              type="text" /> }

                          {(item === 'all' || item === 'empty' || item === 'emptyC') ? 
                            <div style={{ width: this.state.Commission[game]  ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'S' : (item === 'emptyC') ? 'CF' : 'all']).length * 8)) : 80, textAlign: 'center', fontWeight: 'bold'}} />
                            :<div
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'B') ? 'B' : 
                                  (item === 'SE') ? 'SA' : (item === 'CE') ? 'CA' : 
                                    (item === 'PE') ? 'PA' : (item === '5D5') ? '5D' : '6D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}} >
                              {this.state.ParentOdd[game] ? (item === 'B' || item === 'SE') ? this.state.ParentOdd[game]['5TH'][item] 
                                : (item === 'CE' || item === 'PE') ? this.state.ParentOdd[game][item] 
                                  : (item === '5D5') ? this.state.ParentOdd[game]['5D'][item] 
                                    : this.state.ParentOdd[game]['6D'][item] : 
                                (item === 'B' || item === 'SE') ? this.state.ParentOdd['Default']['5TH'][item] 
                                  : (item === 'CE' || item === 'PE') ? this.state.ParentOdd['Default'][item] 
                                    : (item === '5D5') ? this.state.ParentOdd['Default']['5D'][item] 
                                      : this.state.ParentOdd['Default']['6D'][item]
                              }
                            </div> }
                        </div>
                      })}
                    </div>
                    <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 5,  marginBottom: 5}}>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 8.5,}}>
                        <div style={{ width: 80, fontWeight: 'bold'}}>{'奖金 6'}</div>
                      </div>
                      {([ 'all',  'empty', 'empty2', 'SF', 'emptyD',  'emptyC', 'PF', '5D6', 'empty3']).map((item) => {
                        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5,}}>
                          {(item === 'all' || item === 'empty'|| item === 'empty2' || item === 'emptyD' || item === 'emptyC' || item === 'empty3') ? 
                            <div style={{ width: this.state.Commission[game]  ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'B' : 
                                (item === 'empty2') ? 'S' : (item === 'emptyD') ? 'CA' :(item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all']).length * 8)) : 80 , textAlign: 'center', fontWeight: 'bold'}} />
                            :<Form.Control 
                              ref={(input) => { this[`Input${game}6${item}`] = input;}}
                              onKeyPress={(e) => this.KeyChangeIndividual(game, e,  (item === 'SF') ?  '6PF' : (item === 'PF') ? '65D6' :
                                'All')}
                              onChange={(e) => this.oddChange(game, (item === 'SF' ) ? '1ST' : (item === 'PF' ) ? 'PF' : '5D', item, e)}
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                                  (item === 'PF') ? 'PA' : '5D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold'}} 
                              value={this.state.Odd[game] ? (item === 'SF') ?  this.state.Odd[game]['1ST'][item] 
                                : (item === 'CD' || item === 'PF') ?  this.state.Odd[game][item] :
                                  this.state.Odd[game]['5D'][item] 
                                : ''}
                              type="text" /> }

                          {(item === 'all' || item === 'empty'|| item === 'empty2' || item === 'emptyD' || item === 'emptyC' || item === 'empty3') ? 
                            <div style={{ width: this.state.Commission[game]  ? 
                              ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'empty') ? 'B' : 
                                (item === 'empty2') ? 'S' : (item === 'emptyD') ? 'CA' :(item === 'empty3') ? '6D' : (item === 'emptyC') ? 'CF' : 'all']).length * 8)) : 80 , textAlign: 'center', fontWeight: 'bold'}} />
                            :<div
                              style={{ width: this.state.Commission[game] ? 
                                ( 70  + (JSON.stringify(this.state.Commission[game][(item === 'SF') ? 'SA' : (item === 'CD') ? 'CA' : 
                                  (item === 'PF') ? 'PA' : '5D']).length * 8)) : 80,
                              textAlign: 'center',fontWeight: 'bold', color: game === 'Default' ? 'red' : 'auto'}}  >
                              {this.state.ParentOdd[game] ? (item === 'SF') ?  this.state.ParentOdd[game]['1ST'][item] 
                                : (item === 'CD' || item === 'PF') ?  this.state.ParentOdd[game][item] :
                                  this.state.ParentOdd[game]['5D'][item] 
                                : 
                                (item === 'SF') ?  this.state.ParentOdd['Default']['1ST'][item] 
                                  : (item === 'CD' || item === 'PF') ?  this.state.ParentOdd['Default'][item] :
                                    this.state.ParentOdd['Default']['5D'][item] }
                            </div> }
                        </div>
                      })}
                    </div>

                  </div>
                </div>})}
            </div>
          </div>
          <div style={{ width: '95%', marginTop: 20, textAlign: 'center', marginBottom: 20 }}>
            <button style={{ backgroundColor: this.state.ButtonSend, width: '20%' }}
              disabled={this.state.disabled}
              onMouseEnter={() => {
                this.setState({ ButtonSend: '#00AFE2' });
              }}
              onMouseLeave={() => {
                this.setState({ ButtonSend: '#3C96F4' });
              }}
              onClick={() => this.ConfirmCreate()}
              className="btn my-cusomized-button button2 bettingFunctionsButton TextFontSize">
              {language[this.props.currentLanguage].ConfirmRegister}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FastRegister);
