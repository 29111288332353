import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import Modal from 'react-bootstrap/Modal';

class SearchUsersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  render() {
    return (
      <Modal centered show={this.props.SearchUSersModal} onHide={this.props.handleCloseSearchModal}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`选择寻找用户`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(this.props.SearchedID.length === 0 ? (
            <div style={{ fontWeight: 'bold', fontSize: '20px'}}>{language[this.props.currentLanguage].NoUserFound}</div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div className='dcc' style={{ maxHeight: '75vh', overflowY: 'scroll', display: 'flex', flexDirection: 'row', whiteSpace: 'pre-wrap', flexWrap: 'wrap', padding: 2 }}>
                {this.props.SearchedID.map((items, index) => {
                  return(
                    <button key={index} className='dcc buttonUSerList' style={{ display: 'flex', flexDirection: 'column'}}
                      onClick={() => this.props.SelectedUser({value: items.ID, label: items.Username}, '')}>
                      <div>{` ${items.Username}`}</div>
                      <div>{`${items.Role}`}</div>
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

export default connect(mapStateToProps, null)(SearchUsersModal);
