import React from "react";
import { connect } from 'react-redux';
import { language } from '../language';
import moment from 'moment-timezone';
import Alert from 'react-bootstrap/Alert';
// import { Link } from 'react-router-dom';
import {ReturnBetValidation} from '../static/ReturnBetValidation';
import {ReturnCancelValidation} from '../static/ReturnCancelValidation';
const { ReturnReceiptOnly, CreateBetOrder, CancelBet } = require('../Api');

var timer

class OpenWindowReceipt extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      DisplayReceipt: "",
      CopyReceipt: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      ServerID: '',
      loading: false,
      Authority: {},
      UserID: '',
      UserName: '',
      OriginalWord: '',
      Red: '',
      lineData: [
        {
          originalWord: '',
          translatedWord: '',
          Mode: '',
        }
      ],
    }
  }

  componentDidMount () {
    this.setState({ Authority: JSON.parse(this.props.UserAuthority)})
    this.ReturnReceipt();
  }

  async CancelBetAPI() {
    this.setState({ loading: true })
    const Hours = moment().hours();
    const ServerId = this.state.ServerID

    if(ServerId === '') {
      this.openNotification('EmptyServerID')
      this.setState({ notificationType: 'error', loading: false });
    } else if (Hours >= 19) {
      this.openNotification('ExceededHours')
      this.setState({ notificationType: 'error', loading: false });
    } else {
      this.CancelAPI();
    }
  }

  async ReturnReceipt() {
    let searchState = this.props.location.search.substring(1, this.props.location.search.length)
  
    if(searchState !== '') {
      searchState = `{"${decodeURI(searchState.replace(/&/g, "\",\"").replace(/=/g,"\":\""))}"}`
      searchState = JSON.parse(searchState)
      const ReturnR = await ReturnReceiptOnly(searchState.OrderID, this.props.token, this.props.username)
      this.setState({ ServerID: searchState.OrderID })
      if(ReturnR) {
        this.setState({ CopyReceipt: (ReturnR.error === 'No data') ? '' : ReturnR.Receipt, UserID: searchState.UserID, UserName: searchState.Username, OriginalWord: ReturnR.Oriword, Red: ReturnR.Red })
        this.ConvertResultRed(this.state.CopyReceipt)
      }
    }
  }

  async CancelAPI() {
    const username = this.props.username
    const token = this.props.token
    const ServerId = this.state.ServerID

    this.openNotification('CancelSuccessful')
    this.setState({ notificationType: 'success' })
    const CancelReceipt = await CancelBet(ServerId, username, token)
    if(CancelReceipt.error) {
      this.openNotification(ReturnCancelValidation(CancelReceipt.error))
      this.setState({ notificationType: 'error', loading: false });
    } else {
      if(CancelReceipt.message === 'Cancel Successful') {
        this.openNotification('CancelSuccessful')
        this.setState({ notificationType: 'success', loading: false }, () => {
          window.location.reload();
          clearTimeout(timer)
          timer = setTimeout(() => {
            window.close()
          }, 3000);
        });
      }
    }
    // if(CancelReceipt.error === 'database error') {
    //   this.openNotification('databaseError')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else if (CancelReceipt.error === 'Exceed Date') {
    //   this.openNotification('CancelExceededDate')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else if(CancelReceipt.error === 'OverTimeLimit') {
    //   this.openNotification('ExceededHours')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else if(CancelReceipt.error === 'DontHaveAutho') {
    //   this.openNotification('DontHaveAutho')
    //   this.setState({ notificationType: 'error', loading: false });
    // } else {
    //   if(CancelReceipt.message === 'Cancel Successful') {
    //     this.openNotification('CancelSuccessful')
    //     this.setState({ notificationType: 'success', loading: false }, () => {
    //       window.location.reload();
    //       clearTimeout(timer)
    //       timer = setTimeout(() => {
    //         window.close()
    //       }, 3000);
    //     });
    //   }
    // }
  }

  async ConvertResultRed(Receipt) {
    let Word = Receipt
    let returnWord = [];

    if (Word !== '') {
      if (Word.includes('<red>')) {
        const WordArray = Word.split('<red>').join('</red>').split('</red>');
        for (let i = 0; i < WordArray.length; i += 1) {
          if (i === 0) {
            returnWord.push(<span key={i}>{WordArray[i]}</span>);
          } else if (i % 2 === 1) {
            returnWord.push(
              <span key={i} style={{color: 'red'}}>
                {WordArray[i]}
              </span>,
            );
          } else if (i % 2 === 0) {
            returnWord.push(<span key={i}>{WordArray[i]}</span>);
          }
        }
        this.setState({DisplayReceipt: returnWord});
      } else {
        this.setState({DisplayReceipt: Word});
      }
    } else {
      this.setState({DisplayReceipt: 'No Order Detect'});
    }
  }

  async getReorderBet() {
    const ReOrderTimeOut = this.state.Authority.RebuyTimeoutOrder

    if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
      if(ReOrderTimeOut === 1) {
        this.EditReorderAutho();
      } else {
        if(moment().hours() >= 19) {
          this.openNotification('AuthoExceededHoursRebuy')
          this.setState({ notificationType: 'error' });
        } else {
          this.EditReorderAutho();
        }
      }
    } else {
      // if(moment().hours() >= 19) {
      //   this.openNotification('ExceededHoursNormal')
      //   this.setState({ notificationType: 'error' });
      // } else {
      this.EditReorderAutho();
      // }
    }
  }

  async EditReorderAutho() {
    const SelectedUser = {
      "value": this.state.UserID,
      "label": this.state.UserName
    }
    const TextResult = this.state.OriginalWord
    if(TextResult === '') {
      this.openNotification('EmptyServerID')
      this.setState({ notificationType: 'error' });
    } else {
      let OriginalText = TextResult.split('\n');
      let LineData = this.state.lineData;
      let AmountCopy = '';
      for (let i = 0; i < OriginalText.length; i++) {
        if ((LineData[i] || '') !== '') {
          LineData[0].originalWord = OriginalText[i];
        } else {
          let OriWord = OriginalText[i]
          if (OriWord.startsWith('##')) {
            OriWord = OriWord.replace('##', '');
          } else if (OriWord.startsWith('#')) {
            OriWord = OriWord.replace('#', '');
          }
          const OriWordArray = OriWord.split('#');
          if (OriWordArray.length > 2 && OriWordArray[0] !== '') {
            AmountCopy = OriginalText[i].substring(
              OriginalText[i].indexOf('#') + 1,
            );
            OriWord = OriginalText[i];
          } else if (OriWordArray.length === 2 && OriWordArray[1] === '') {
            OriWord = OriginalText[i] + AmountCopy;
          } else {
            OriWord = OriginalText[i];
          }
          LineData.push({
            originalWord: OriWord,
            translatedWord: '',
            Mode: '',
          });
        }
      }

      if(this.state.Red !== '') {
        this.props.history.push({
          pathname: '/bettingRed',
          ReOrderBet: LineData,
          SelectedUser: SelectedUser,
        })
      } else {
        this.props.history.push({
          pathname: '/betting',
          ReOrderBet: LineData,
          SelectedUser: SelectedUser,
        })
      }
    }
  }

  async ReorderTeicket() {
    const ReOrderTimeOut = this.state.Authority.RebuyTimeoutOrder

    if(this.props.Role === 'admin' || this.props.Role === 'superAdmin') {
      if(ReOrderTimeOut === 1) {
        this.RebuyOrderAutho();
      } else {
        if(moment().hours() >= 19) {
          this.openNotification('AuthoExceededHoursRebuy')
          this.setState({ notificationType: 'error' });
        } else {
          this.RebuyOrderAutho();
        }
      }
    } else {
      // if(moment().hours() >= 19) {
      //   this.openNotification('ExceededHoursNormal')
      //   this.setState({ notificationType: 'error' });
      // } else {
      this.RebuyOrderAutho();
      // }
    }
  }
  
  async RebuyOrderAutho() {
    const Red = this.state.Red
    const Authority = this.state.Authority
    const OriginalTextResult = this.state.OriginalWord
    const userID = this.state.UserID

    if(Authority.RebuyOrder === 1) {
      if(OriginalTextResult === '') {
        this.openNotification('EmptyServerID')
        this.setState({ notificationType: 'error' });
      } else {
        const r = (window.confirm(`${language[this.props.currentLanguage].Areyousuretorepurchasethisorder}`));
        if (r === true) {
          this.setState({ loadingforBet: true })
          const Rebuy = await CreateBetOrder(userID, OriginalTextResult, this.props.token, this.props.username, Red, true)
          if(Rebuy.error) {
            this.openNotification(ReturnBetValidation(Rebuy.error))
            this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
          } else {
            if(Rebuy.message === 'success') {
              this.openNotification('SuccessRebuyReceipt')
              this.setState({ notificationType: 'success', loadingforBet: false })
              clearTimeout(timer)
              timer = setTimeout(() => {
                window.close()
              }, 3000);
            }
          }
          // if (Rebuy.error === 'User No Package') {
          //   this.openNotification('NoPackage')
          //   this.setState({ notificationType: 'error', loadingforBet: false });
          // } else if(Rebuy.error === 'No Date to Bet') {
          //   this.openNotification('DateCantbeBet')
          //   this.setState({ notificationType: 'error', loadingforBet: false });
          // } else if(Rebuy.error === '5D6D in wrong game') {
          //   this.openNotification('OnlyBTandTOTOallow')
          //   this.setState({ notificationType: 'error' , loadingforBet: false});
          // } else if(Rebuy.error === 'Not enough date') {
          //   this.openNotification('UnableThoseDate')
          //   this.setState({ notificationType: 'error', loadingforBet: false });
          // }  else if(Rebuy.error === 'Date error') {
          //   this.openNotification('UnableThoseDate')
          //   this.setState({ notificationType: 'error', loadingforBet: false });
          // } else if(Rebuy.error === 'S Game ShutDown') {
          //   this.openNotification('singaporeGameClose')
          //   this.setState({ notificationType: 'error', loadingforBet: false });
          // } else if(Rebuy.error === 'All Game ShutDown') {
          //   this.openNotification('AllGameClose')
          //   this.setState({ notificationType: 'error', loadingforBet: false });
          // } else if(Rebuy.error === 'MKTSWBD ShutDown') {
          //   this.openNotification('MKTGameShutDown')
          //   this.setState({ notificationType: 'error', loadingforBet: false });
          // } else if(Rebuy.error === 'No Game on that day') {
          //   this.openNotification('NoGameThatDay')
          //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
          // } else if(Rebuy.error === 'Platform error') {
          //   this.openNotification('ErrorPlatform')
          //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
          // } else if(Rebuy.error === 'Account Inactive') {
          //   this.openNotification('AccountInactive')
          //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false});
          // } else if(Rebuy.error === 'Exceed Personal Daily Limit') {
          //   this.openNotification('ExceedDAilyLimit')
          //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
          // } else if(Rebuy.error === 'Repeat Order') {
          //   this.openNotification('RepeatOrder')
          //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
          // } else if(Rebuy.error === 'Over Number Limit') {
          //   this.openNotification('OverNumberLimit')
          //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
          // } else if(Rebuy.error === 'Format Error') {
          //   this.openNotification('FormatError')
          //   this.setState({ notificationType: 'error', loading: false, loadingforBet: false });
          // }else {
          //   if(Rebuy.message === 'success') {
          //     this.openNotification('SuccessRebuyReceipt')
          //     this.setState({ notificationType: 'success', loadingforBet: false })
          //     clearTimeout(timer)
          //     timer = setTimeout(() => {
          //       window.close()
          //     }, 3000);
          //   }
          // }
        } else {
          return null;
        }
      }
    } else {
      this.openNotification('DontHaveAutho')
      this.setState({ notificationType: 'error' });
    }
  }

  ErrorMessage(Type) {
    if(Type === '1') {
      this.openNotification('DontHaveAutho')
      this.setState({ notificationType: 'error'});
    } 
    else if(Type === '2'){
      this.openNotification('ExceedDateExist')
      this.setState({ notificationType: 'error'});
    } else if (Type === '4') {
      this.openNotification('ExceededHoursNormal')
      this.setState({ notificationType: 'error'});
    }
    else {
      this.openNotification('ErrorDueToCancelled')
      this.setState({ notificationType: 'error'});
    }
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 3000);
  };

  CopyToC(TextResult) {
    const DownloadWord = TextResult.split('<red>').join('</red>').split('</red>');
    navigator.clipboard.writeText(DownloadWord.join(''))
    this.openNotification('SuccessCopied')
    this.setState({ notificationType: true });
  }

  render () {
    return (
      <div style={{ backgroundColor: '#E6E6E6', height: '100vh', overflowX: 'auto' }}>
        <div className="dcc">
          <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 20, width: '100%', left: 0 }} 
            show={this.state.showNotification}
            variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div className="column" style={{ marginBottom: 30, fontSize: '16px', whiteSpace: 'pre-wrap', fontWeight: 'bold', textAlign: 'start', marginLeft: 10, overflowX: 'auto' }}>
          <div style={{ marginBottom: 10 }}>{'Receipt => '}</div>
          <div style={{ marginBottom: 10 }}>{this.state.DisplayReceipt}</div>
          {this.state.DisplayReceipt !== 'No Order Detect' && 
            <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'}}>
              <button style={{ backgroundColor: '#EE3E13', color: 'white', fontWeight: 'bold' }}
                disabled={this.state.disabledCancle}
                onClick={() => this.CancelBetAPI()}
                className="btn ReceiptButton button2">
                {language[this.props.currentLanguage].CancelTicket}
              </button>
              {/* <button style={{ backgroundColor: '#3C96F4', color: 'white', fontWeight: 'bold'}}
                disabled={this.state.disabledEditRebuy}
                onClick={() => this.getReorderBet()}
                className="btn ReceiptButton">
                {language[this.props.currentLanguage].EditAndRebuy}
              </button> */}
              <button style={{ backgroundColor: '#3C96F4', color: 'white', fontWeight: 'bold' }}
                disabled={this.state.loadingforBet}
                onClick={() => this.ReorderTeicket()}
                className="btn ReceiptButton button2">
                {language[this.props.currentLanguage].Rebuy}
              </button>
              <button style={{ backgroundColor: '#3C96F4', color: 'white', fontWeight: 'bold', cursor: 'copy' }}
                className="btn ReceiptButton button2" onClick={() => {this.CopyToC(this.state.CopyReceipt)}}>{language[this.props.currentLanguage].CopyReceipt}</button>
              {/* <button style={{ backgroundColor: '#3C96F4', color: 'white', fontWeight: 'bold', cursor: (this.props.Role === 'agent' || this.props.Role === 'Player') ? 'not-allowed' : (this.state.Authority.EditOrder === 0) ? 'not-allowed' : null }}
                disabled={(this.props.Role === 'agent') ? true : (this.props.Role === 'player') ? true : (this.state.Authority.EditOrder === 0) ? true : false}
                readOnly={(this.state.Authority.EditOrder === 0) ? true : false}
                className="btn button2">
                {(this.props.Role === 'agent') ? 
                  <div>{language[this.props.currentLanguage].Edit}</div>
                  : (this.props.Role === 'player') ?
                    <div>{language[this.props.currentLanguage].Edit}</div>
                    : (this.state.Authority.EditOrder === 0) ?
                      <div onClick={() => this.ErrorMessage('1')}>{language[this.props.currentLanguage].Edit}</div>
                      : (this.state.DateValidated === false) ?
                        <div onClick={() => this.ErrorMessage('2')}>{language[this.props.currentLanguage].Edit}</div>
                        : (this.state.CheckStatus === 'Cancelled') ?
                          <div onClick={() => this.ErrorMessage('3')}>{language[this.props.currentLanguage].Edit}</div>
                          :
                          <>
                            {(this.state.Authority.EditTimeoutOrder === 1) ?
                              <Link
                                to={`/EditBetReceipt?id=${this.state.ServerID}&type=OrderID&OrderID=${this.state.ServerID}`}
                                target="_blank" rel="noopener noreferrer" style={{ color: 'white'}}>
                                {language[this.props.currentLanguage].Edit}
                              </Link>
                              : <>
                                {(moment().hours() >= 19) ? 
                                  <div onClick={() => this.ErrorMessage('4')}>{language[this.props.currentLanguage].Edit}</div>
                                  : 
                                  <Link 
                                    to={`/EditBetReceipt?id=${this.state.ServerID}&type=OrderID&OrderID=${this.state.ServerID}`}
                                    target="_blank" rel="noopener noreferrer" style={{ color: 'white'}}>
                                    {language[this.props.currentLanguage].Edit}
                                  </Link>
                                }
                              </> }
                          </>
                }
              </button> */}
            </div>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    userID: state.userID,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(OpenWindowReceipt);