import React, { Component } from 'react';
import { connect } from 'react-redux'
import GDicon from '../../Images/Cards/GrandDragonLotto.png';
import { language } from '../../language';
import Moment from 'moment'
// import { isMobile } from 'react-device-detect';

class JackpotBT extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    // const Special = [1,2,3,4,5,6,7,8,9,10]
    // const D1 = '5D1'
    // const D2 = '5D2'
    // const D3 = '5D3'
    const D16 = '6D1'
    // const D7 = '7D1'
    // ffc107
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="draganborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={GDicon} alt="Logo" className="logoiconimages"/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'white', fontWeight: 'bold' }}><b>{language[this.props.currentLanguage].GdToto} 6D</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white', fontWeight: 'bold' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{this.props.gd.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        {/* <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc americaborderclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'white' }}>
            5D
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="column americaborder" style={{ width: '100%', backgroundColor: '#6d100e', color: 'white' }}>
            <div className = "dcc column" style={{ height: '35px' }}>{language[this.props.currentLanguage].first}</div>
            <div className = "dcc column" style={{ height: '35px' }}>{language[this.props.currentLanguage].second}</div>
            <div className = "dcc column" style={{ height: '35px' }}>{language[this.props.currentLanguage].third}</div>
          </div>
          <div className="column numberborderdspecon" style={{ width: '100%', backgroundColor: '#eeeeee', fontWeight: 'bold'}}>
            <div className = "dcc column" style={{ height: '35px' }}>{this.props.bt4[D1] || '----'}</div>
            <div className = "dcc column" style={{ height: '35px' }}>{this.props.bt4[D2] || '----'}</div>
            <div className = "dcc column" style={{ height: '35px' }}>{this.props.bt4[D3] || '----'}</div>
          </div>
          <div className="column americaborder" style={{ width: '100%', backgroundColor: '#6d100e', color: 'white' }}>
            <div className = "dcc column" style={{ height: '35px' }}>{language[this.props.currentLanguage].fourth}</div>
            <div className = "dcc column" style={{ height: '35px' }}>{language[this.props.currentLanguage].fifth}</div>
            <div className = "dcc column" style={{ height: '35px' }}>{language[this.props.currentLanguage].sixth}</div>
          </div>
          <div className="column numberborderdspecon" style={{ width: '100%', backgroundColor: '#eeeeee', fontWeight: 'bold' }}>
            <div className = "dcc column" style={{ height: '35px' }}>{(this.props.bt4[D1] && String(this.props.bt4[D1]).substring(1,5)) || '----'}</div>
            <div className = "dcc column" style={{ height: '35px' }}>{(this.props.bt4[D1] && String(this.props.bt4[D1]).substring(2,5)) || '----'}</div>
            <div className = "dcc column" style={{ height: '35px' }}>{(this.props.bt4[D1] && String(this.props.bt4[D1]).substring(3,5)) || '----'}</div>
          </div>
        </div> */}
        {/* <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc americaborderclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'white' }}>
            6D/6+1D
          </div>
        </div> */}
        <div className="numberbordergorjackport" style={{ marginBottom: 10 }}>
          <div className="dcc" style={{ display: 'flex', marginBottom: 2 }}>
            <div className="dcc gdborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px',  height: '35px', color: 'white' }}>{language[this.props.currentLanguage].first}</div>
            <div className="dcc numberborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>{this.props.gd[D16] || '----'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="dcc gdborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px',  height: '35px', color: 'white' }}>{language[this.props.currentLanguage].second}</div>
            <div className="dcc numberborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{(this.props.gd[D16] && String(this.props.gd[D16]).substring(0,5)) || '----'}</div>
              <div>or </div>
              <div>{(this.props.gd[D16] && String(this.props.gd[D16]).substring(1,6)) || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="dcc gdborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px',  height: '35px', color: 'white' }}>{language[this.props.currentLanguage].third}</div>
            <div className="dcc numberborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{(this.props.gd[D16] && String(this.props.gd[D16]).substring(0,4)) || '----'}</div>
              <div>or </div>
              <div>{(this.props.gd[D16] && String(this.props.gd[D16]).substring(2,6)) || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="dcc gdborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px',  height: '35px', color: 'white' }}>{language[this.props.currentLanguage].fourth}</div>
            <div className="dcc numberborderdspecon" style={{ width: 'calc(100% / 1) ', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{(this.props.gd[D16] && String(this.props.gd[D16]).substring(0,3)) || '----'}</div>
              <div>or </div>
              <div>{(this.props.gd[D16] && String(this.props.gd[D16]).substring(3,6)) || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="dcc gdborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px',  height: '35px', color: 'white' }}>{language[this.props.currentLanguage].fifth}</div>
            <div className="dcc numberborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{(this.props.gd[D16] && String(this.props.gd[D16]).substring(0,2)) || '----'}</div>
              <div>or </div>
              <div>{(this.props.gd[D16] && String(this.props.gd[D16]).substring(4,6)) || '----'}</div>
            </div>
          </div>
        </div>
        {/* <div style={{ display: 'flex', marginBottom: 30 }}>
          <div className="dcc americaborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, fontWeight: 'bold', color: 'white' }}>
            6+1D
          </div>
          <div className="dcc numberbordergorjackport" style={{ width: 'calc(100% / 1)', marginLeft: 4, marginRight: 4, fontWeight: 'bold', justifyContent: 'space-evenly' }}>
            <div>{(this.props.bt4[D7] && String(this.props.bt4[D7]).substring(0,6)) || '----'}</div>
            <div>+ </div>
            <div>{(this.props.bt4[D7] && String(this.props.bt4[D7]).substring(6,7)) || '----'}</div>
          </div>
        </div> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(JackpotBT)
