import '../App.css';
import React, {Fragment}  from 'react';
import { language } from '../language';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { Button } from 'react-bootstrap';
// import { isMobile } from 'react-device-detect';
import Header2 from '../Component/Header';
// import tickicons from '../Images/tick.png';
import Table from 'react-bootstrap/Table';
import Moment from 'moment-timezone';
import { Alert, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { ImArrowLeft, ImArrowRight } from 'react-icons/im';
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
const { UserActionRecord, ActionUserRecordUserListF } = require('../Api');
var timer

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class GameResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // showNotification: false,
      // GamesDetail: [],
      // GameLimitPackage: {},
      // JackpotNumb : ['jackpot1', 'jackpot2'],
      // TodayGameAvailable: [],
      // Games: [],
      // notificationType: 'error',
      // autoUpdate: false,
      // GameStatus: 0,
      // buttondisabled: false,
      // GDisplay: '',
      // notificationMessage: '',
      // S_Switch: false,
      // Switch: false,
      // Eight_Switch: false,
      // StoreObjectSocket: {},
      showNotification: false,
      Min_Date: Moment().toDate(),
      Max_Date: Moment().toDate(),
      SelectUser: 'All',
      SelectPage: 'All',
      RecordActionTable: [],
      page: 1,
      countperpage: 10,
      countpagemax: 1,
      countpagemin: 1,
      OptionsUserList: [],
      PageList: [
        {value: 'All', label: language[this.props.currentLanguage].ShowAll},
        {value:'Home', label: language[this.props.currentLanguage].Home},
        {value: 'betting', label: language[this.props.currentLanguage].BettingFastVersion },
        {value: 'BettingVersion2', label: language[this.props.currentLanguage].BetSimple },
        {value: 'BoxVersion', label: language[this.props.currentLanguage].BettingBoxVersion },
        {value: 'BettingRed', label: language[this.props.currentLanguage].BettingRed },
        {value: 'TextfileConvert', label: language[this.props.currentLanguage].Document },
        {value: 'ViewReceipt', label: language[this.props.currentLanguage].BrowseTicket },
        {value: 'LongTermReceipt', label: language[this.props.currentLanguage].ShowLongTermOrder },
        {value: 'DisplayEditPage', label: language[this.props.currentLanguage].EditedBetHistory },
        {value: 'FindNumber', label: language[this.props.currentLanguage].FindNumber },
        {value: 'DownlineReport', label: language[this.props.currentLanguage].Report },
        {value: 'GrandTotalNumberResult', label: '销售报告' },
        {value: 'GrandTotalNumberResultFight', label: '销售报告(吃字)' },
        {value: 'SimpleReport', label: '销售报告 (总图)' },
        {value: 'Hit_Sales', label: language[this.props.currentLanguage].AccountSales },
        {value: 'ActionUserRecord',  label: language[this.props.currentLanguage].UserAction },
        {value: 'VIPuser',  label: language[this.props.currentLanguage].ExtraLimit },
        {value: 'RedNumber',  label: language[this.props.currentLanguage].RedNumber },
        {value: 'HR',  label: language[this.props.currentLanguage].HR },
        {value: 'UpdateLatestNews',  label: language[this.props.currentLanguage].NewsManage },
        {value: 'AddGame',  label: language[this.props.currentLanguage].Addgame },
        {value: 'GameResult',  label: language[this.props.currentLanguage].GameResult },
        {value: 'Dading',  label: '打钉' },
        {value: 'SuperAdminManagePage',  label: language[this.props.currentLanguage].ManageAdminAuthority  },
        {value: 'ViewResult',  label: language[this.props.currentLanguage].FourDResult  },
        {value: 'Setting',  label: language[this.props.currentLanguage].setting  },
        {value: 'SubAcc',  label: language[this.props.currentLanguage].SubAcc  },
      ],
      SelectedPage: [ {value: 'All', label: language[this.props.currentLanguage].ShowAll},],
      SelectedUser: [ {value: 'All', label: language[this.props.currentLanguage].ShowAll},],
      PopScrollBackButton: false,
      UserID: this.props.userID,
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: 'All',
    }
    this.SelectedUser = this.SelectedUser.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ UserID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID }, () => {
      this.CallAPI();
    })
  }

  async CallAPI(){
    const UserID = this.state.UserID
    const Min_Date = Moment(this.state.Min_Date).format('YYYY-MM-DD')
    const Max_Date = Moment(this.state.Max_Date).format('YYYY-MM-DD')
    const page = this.state.page
    const countperpage = this.state.countperpage
    const UserActionRecordResult = await UserActionRecord( 
      Min_Date, Max_Date, this.state.SelectUser, this.state.SelectPage, 
      page, countperpage, UserID, this.props.username, this.props.token)
    // const UserIDListResult = await UserIDList (UserID, this.props.username, this.props.token)
    // let NewArray = [ {value: 'All', label: language[this.props.currentLanguage].ShowAll},]
    // UserIDListResult.forEach((item)=>{
    //   const filterPackage = {
    //     value: item.ID, label: item.Username
    //   }
    //   NewArray.push(filterPackage)
    // })
    // this.setState({    
    //   OptionsUserList: NewArray,
    //   RecordActionTable : UserActionRecordResult,
    // })
    if (UserActionRecordResult.length > 0) {
      this.setState({
        countpagemax: Math.ceil(
          UserActionRecordResult[0].FullCount / this.state.countperpage,
        ),
        // OptionsUserList: NewArray,
        RecordActionTable : UserActionRecordResult,
      });
    } else if (UserActionRecordResult.error === 'fromDate bigger than toDate'){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].FormDateError)
      });
    } else if (UserActionRecordResult.error === 'Invalid toDate'){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification('Invalid toDate')
      });
    } else if (UserActionRecordResult.error === 'Invalid fromDate'){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification('Invalid fromDate')
      });
    } else if (UserActionRecordResult.error === 'Invalid userID'){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification('Invalid userID')
      });
    } else if (UserActionRecordResult.error === 'database error'){
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(language[this.props.currentLanguage].databaseError)
      });
    } else {
      this.setState({
        countpagemax: 1,
        page: 1,
        // OptionsUserList: NewArray,
        RecordActionTable: [],
      });
    }
  }

  async setSelectedDate(Min_Date) {
    this.setState({ Min_Date }, ()=>{
      this.props.setMinDate(Min_Date);
      this.CallAPI();
    })
  }

  async MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date }, ()=>{
      this.props.setMaxDate(Max_Date)
      this.CallAPI();
    })
  }

  changePage() {
    this.setState({ DisplayEdit: [] }, () => {
      this.CallAPI();
    });
  }

  handleChangeUser = OptionsSelect => {
    this.setState({ SelectedUser: OptionsSelect, SelectUser: OptionsSelect.value }, ()=>{
      this.CallAPI();
    });
  };

  handleChangePage = OptionsSelect => {
    this.setState({ SelectedPage: OptionsSelect,  SelectPage: OptionsSelect.value }, ()=>{
      this.CallAPI();
    });
  };

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 2000);
  };

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.SearchUser !== '') {
      const Searchresult = await ActionUserRecordUserListF(this.state.SearchUser, this.props.Role, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification('InvalidDownlineIDorUsername')
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID : [],
    })
  }
  
  SelectedUser(e) {
    this.setState({ SelectUser: e.value, SearchUser: e.label }, () => {
      this.CallAPI()
    })
    this.handleCloseSearchModal();
  }

  async getSelf() {
    // const getMyself = await getMyselfF(this.props.userID, this.props.token, this.props.username);
    // if(getMyself) {
    this.setState({ SelectUser: this.props.userID, SearchUser: this.props.username }, () => {
      this.CallAPI()
    })
    // }
  }
  
  render() {
    return(
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowX: 'auto' }}> 
        <div style={{ marginBottom: '0%'}}>
          <Header2 />
          <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
        </div>
        <div ref={this.elScroll.elRef} className="dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white',  marginBottom: '1%', flexDirection: null, fontWeight: 'bold',}}>
          <div>{language[this.props.currentLanguage].UserAction}</div>
        </div>
        <div className="dcc" >
          <div className="dcc" style={{ width: '100%' ,marginBottom: '1%',  flexDirection: 'row' }}>
            {/* <div className="dcc" style={{ flexWrap: 'wrap'}}>
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>From</div>
              <DatePicker
                onChange = {(date) => this.setSelectedDate(date)}
                dateFormat = 'dd-MMM-yyyy'
                placeholder="Date"
                maxDate={new Date()} // temp remove
                popperPlacement="top-right"
                selected={this.state.Min_Date}
                className={(isMobile) ? "form-control StyleDatePickerMobile" : "form-control StyleDatePicker"}
                withPortal
              />
              { (isMobile) ? <>
                <div style={{fontSize: "18px", width: 150,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].SelectedUsername}</div>
                <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'column' }}>
                  <div><Form.Control style={{ width: (isMobile) ? '100%' : 150, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/></div>
                  <div><Button  className='button2' variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
                    <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.SelectedUser({value: 'All', label: 'All'})}>{language[this.props.currentLanguage].All}</Button>
                    <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getSelf()}>{language[this.props.currentLanguage].self}</Button></div>
                </div>
              </>
                :
                <>
                  <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>To</div>
                  <DatePicker
                    onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    maxDate={new Date()} // temp remove
                    popperPlacement="top-right"
                    selected={this.state.Max_Date}
                    minDate={new Date(this.state.Min_Date)}
                    className={(isMobile) ? "form-control StyleDatePickerMobile" :"form-control StyleDatePicker"}
                    withPortal
                  />
                </>
              }
            </div> */}
            {/* <div className="dcc" style={{ flexWrap: 'wrap'}}>
              { (isMobile) ?
                <>
                  <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>To</div>
                  <DatePicker
                    onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                    dateFormat = 'dd-MMM-yyyy'
                    placeholder="Date"
                    maxDate={new Date()} // temp remove
                    popperPlacement="top-right"
                    selected={this.state.Max_Date}
                    minDate={new Date(this.state.Min_Date)}
                    className={(isMobile) ? "form-control StyleDatePickerMobile" :"form-control StyleDatePicker"}
                    withPortal
                  />
                </>
                :
                <>
                  <div style={{fontSize: "18px", width: 150,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].SelectedUsername}</div>
                  <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
                    <Form.Control style={{ width: (isMobile) ? '40%' : 150, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
                    <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.SelectedUser({value: 'All', label: 'All'})}>{language[this.props.currentLanguage].All}</Button>
                    <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getSelf()}>{language[this.props.currentLanguage].self}</Button>
                  </div>
                </>
              }
              <div style={{fontSize: "18px", width: 150,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Page} :</div>
              <div style={{ width: 150}}>
                <Select 
                  options={this.state.PageList}
                  value={this.state.SelectedPage}
                  onChange={this.handleChangePage}
                // placeholder={language[this.props.currentLanguage].SelectedUsername}
                ></Select>
              </div>
            </div> */}
            <div className='dcc' style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>From</div>
              <DatePicker
                onChange = {(date) => this.setSelectedDate(date)}
                dateFormat = 'dd-MMM-yyyy'
                placeholder="Date"
                maxDate={new Date()} // temp remove
                // popperPlacement="top-right"
                selected={this.state.Min_Date}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
              <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>To</div>
              <DatePicker
                onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                dateFormat = 'dd-MMM-yyyy'
                placeholder="Date"
                maxDate={new Date()} // temp remove
                // popperPlacement="top-right"
                selected={this.state.Max_Date}
                minDate={new Date(this.state.Min_Date)}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
              <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{fontSize: "18px", width: 150,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].SelectedUsername}</div>
                <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
                  <Form.Control style={{ width: 150, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
                  <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.SelectedUser({value: 'All', label: 'All'})}>{language[this.props.currentLanguage].All}</Button>
                  <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getSelf()}>{language[this.props.currentLanguage].self}</Button>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{fontSize: "18px", width: 150,  textAlign: 'center', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Page} :</div>
                <div style={{ width: 150}}>
                  <Select 
                    options={this.state.PageList}
                    value={this.state.SelectedPage}
                    onChange={this.handleChangePage}
                    // placeholder={language[this.props.currentLanguage].SelectedUsername}
                  ></Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div style= {{ width: '100%', paddingLeft: '1%', paddingRight: '1%', overflowX: 'auto'}}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th><b> No.</b></th>
                <th style={{ minWidth: 60 }}><b> {language[this.props.currentLanguage].usernameLanguage}</b></th>
                <th style={{ minWidth: 60 }}><b> {language[this.props.currentLanguage].Date}</b></th>
                <th style={{ minWidth: 70 }}><b> {language[this.props.currentLanguage].Page}</b></th>
                <th style={{ minWidth: 40 }}><b> {language[this.props.currentLanguage].Table}</b></th>
                <th style={{ minWidth: 80 }}><b>{language[this.props.currentLanguage].FromData}</b></th>
                <th style={{ minWidth: 80 }}><b>{language[this.props.currentLanguage].ToData}</b></th>
              </tr>
            </thead>
            {/* colSpan */}
            {(this.state.RecordActionTable.length > 0) ? 
              <tbody>
                { this.state.RecordActionTable.map((item, Todayindex) =>{
                  return    <tr key={Todayindex}>
                    <td>
                      <div style={{ padding: 5}}>
                        {Todayindex + 1}
                      </div>
                    </td>
                    <td>
                      <div style={{ padding: 5}}>
                        {item.Username || '-'}
                      </div>
                    </td>
                    <td>
                      <div style={{ padding: 5}}>
                        {Moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                    </td>
                    <td>
                      <div style={{ padding: 5}}>
                        {item.Page}
                      </div>
                    </td>
                    <td>
                      <div style={{ padding: 5}}>
                        {item.Table}
                      </div>
                    </td>
                    <td>
                      {
                        ( (JSON.parse(item.FromData)) &&  (JSON.parse(item.FromData).fromData) && (JSON.parse(item.FromData).fromData !== undefined) && (typeof JSON.parse(item.FromData).fromData === 'object')) ?
                          Object.keys(JSON.parse(item.FromData).fromData).map((items) =>{
                            return <div style={{ padding: 5}}>
                              {`${(items === 'ShutDown') ? language[this.props.currentLanguage].Shutdown : 
                                (items === 'S_ShutDown') ? language[this.props.currentLanguage].ShutdownSingapore : 
                                  (items === 'EightShutDown') ? `8 ${language[this.props.currentLanguage].Shutdown}` : 
                                    items } : ${(items === 'Date') ? Moment(JSON.parse(item.FromData).fromData[items]).format('YYYY-MM-DD HH:mm:ss') : JSON.parse(item.FromData).fromData[items]}` }
                            </div>
                          })
                          : ( (JSON.parse(item.FromData)) && (JSON.parse(item.FromData) !== undefined) && (typeof JSON.parse(item.FromData) === 'object')) ?
                            (
                              (typeof JSON.parse(item.FromData) === 'object') ?
                                Object.keys(JSON.parse(item.FromData)).map((items) =>{
                                  return <div style={{ padding: 5}}>
                                    {/* {`${items} : ${JSON.parse(item.FromData)[items]}` } */}
                                    {`${(items === 'ShutDown') ? language[this.props.currentLanguage].Shutdown : 
                                      (items === 'S_ShutDown') ? language[this.props.currentLanguage].ShutdownSingapore : 
                                        (items === 'EightShutDown') ? `8 ${language[this.props.currentLanguage].Shutdown}` : 
                                          items } : ${(items === 'Date') ? Moment(JSON.parse(item.FromData)[items]).format('YYYY-MM-DD HH:mm:ss') : JSON.parse(item.FromData)[items]}` }
                                  </div>
                                })
                                : 
                                <div style={{ padding: 5}}>
                                  {` ${JSON.parse(item.FromData)}`}
                                </div>
                            ) : (
                              <div style={{ padding: 5}}>
                                {` ${JSON.parse(item.FromData)}`}
                              </div>
                            )
                      }
                    </td>
                    <td>
                      {
                        ( (JSON.parse(item.ToData)) && (JSON.parse(item.ToData).toData)&& (JSON.parse(item.ToData).toData !== undefined) &&(typeof JSON.parse(item.ToData).toData === 'object')) ?
                          Object.keys(JSON.parse(item.ToData).toData).map((items) =>{
                            return <div style={{ padding: 5}}>
                              {`${(items === 'ShutDown') ? language[this.props.currentLanguage].Shutdown : 
                                (items === 'S_ShutDown') ? language[this.props.currentLanguage].ShutdownSingapore : 
                                  (items === 'EightShutDown') ? `8 ${language[this.props.currentLanguage].Shutdown}` : 
                                    items } : ${(items === 'Date') ? Moment(JSON.parse(item.ToData).toData[items]).format('YYYY-MM-DD HH:mm:ss') : JSON.parse(item.ToData).toData[items] 
                                // JSON.parse(item.ToData).toData[items]
                              }` 
                              }
                            </div>
                          })
                          : ( (JSON.parse(item.ToData))&& (JSON.parse(item.ToData) !== undefined) &&(typeof JSON.parse(item.ToData) === 'object')) ?
                            (
                              (typeof JSON.parse(item.ToData) === 'object') ?
                                Object.keys(JSON.parse(item.ToData)).map((items) =>{
                                  return <div style={{ padding: 5}}>
                                    {`${(items === 'ShutDown') ? language[this.props.currentLanguage].Shutdown : 
                                      (items === 'S_ShutDown') ? language[this.props.currentLanguage].ShutdownSingapore : 
                                        (items === 'EightShutDown') ? `8 ${language[this.props.currentLanguage].Shutdown}` : 
                                          items } : ${(items === 'Date') ? Moment(JSON.parse(item.ToData)[items]).format('YYYY-MM-DD HH:mm:ss') : JSON.parse(item.ToData)[items]}` }
                                  </div>
                                })
                                : 
                                <div style={{ padding: 5}}>
                                  {` ${JSON.parse(item.ToData)}`}
                                </div>
                            ) : (
                              <div style={{ padding: 5}}>
                                {` ${JSON.parse(item.ToData)}`}
                              </div>
                            )
                      }
                    </td>
                  </tr>
                })}
              </tbody>
              : 
              <>
                <tr><td colSpan="16" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '28px'}}>{language[this.props.currentLanguage].NoData}</td></tr>
              </>
            }
          </Table>
          <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {this.state.page > this.state.countpagemin ? (
              <button  style={{ textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: 100, borderColor: '#1e5e8a' }} 
                class="btn my-cusomized-button button2" 
                onClick={() =>
                  this.setState(
                    {
                      page: this.state.page - 1,
                    },
                    () => {
                      this.changePage(this.state.page);
                    },
                  )
                }>
                <ImArrowLeft size="16px" color="white" />
              </button>
            ) : (
              <div style={{ width: 100 }}/>
            )}
            <div style={{ fontWeight: 'bold', marginLeft: 100, marginRight: 100}}>{this.state.page}</div>
            {this.state.page < this.state.countpagemax ? (
              <button
                style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 100, borderColor: '#1e5e8a' }}
                class="btn my-cusomized-button button2" 
                onClick={() =>
                  this.setState(
                    {
                      page: this.state.page + 1,
                    },
                    () => {
                      this.changePage();
                    },
                  )
                }>
                <ImArrowRight size="16px" color="white" />
              </button>
            ) : (
              <div style={{ width: 100 }} />
            )}
          </div>
        </div>
        {(this.state.PopScrollBackButton)&&<div class={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}
        >
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
          ^
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    Max_Date: state.Max_Date, 
    Min_Date: state.Min_Date,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GameResult);