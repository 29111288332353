import '../App.css';
import React from 'react';
import { language } from '../language';
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import { Platformcolor } from '../static/PlatformColor';
// import { GamePlatformNumb1, GamePlatformNumb2 } from '../static';

class AddRNPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diable: false,
      GamePlatform: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      GamePlatform2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'],
      GameKeys: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D'],
      GDisplay: '',
      tableShowLimit: {
        0: [],
        1: [],
        2: ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'],
        3: ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'],
        4: ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF'],
        5: ['5D'],
        6: ['6D'],
      },
      tableWidth: {
        0: 300,
        1: 300,
        2: 700,
        3: 700,
        4: 800,
        5: 300,
        6: 300,
      },
    }
  }

  componentDidMount() {
    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({ GDisplay });

  }

  textAllChange(limitType, red, game, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = JSON.parse(JSON.stringify(this.props.addRedNumberData))
      const keys = Object.keys(gameObject[limitType][red].Default)
      for (let i = 0; i < keys.length; i += 1) {
        gameObject[limitType][red][game][keys[i]] = Number(value)
      }
      this.props.dataChange('addRedNumberData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  textChange(limitType, red, game, subfield, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = JSON.parse(JSON.stringify(this.props.addRedNumberData))
      gameObject[limitType][red][game][subfield] = Number(value)
      gameObject[limitType][red][game].all = ''
      this.props.dataChange('addRedNumberData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  gameChange(limitType, red, game) {
    let newGameLimitPackage = JSON.parse(JSON.stringify(this.props.addRedNumberData))
    if (newGameLimitPackage[limitType][red][game]) {
      delete newGameLimitPackage[limitType].RedLimit[game]
      delete newGameLimitPackage[limitType].Limit[game]
    } else {
      newGameLimitPackage[limitType].RedLimit[game] = { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 }
      newGameLimitPackage[limitType].Limit[game] = { all: 0, B: 0, S: 0, SA: 0, SD: 0, CA: 0, CD: 0, SB: 0, SC: 0, SE: 0, SF: 0, "5D": 0, "6D": 0, CB: 0, CC: 0, CE: 0, CF: 0, PA: 0, PB: 0, PC: 0, PD: 0, PE: 0, PF: 0 }
    }
    this.props.dataChange('addRedNumberData', newGameLimitPackage)
  }

  // renderBackgroundColor(game) {
  //   if (game === 'Default') return 'lightsteelblue'
  //   else if (game === 'M') return '#fff23e'
  //   else if ((this.state.GDisplay === 'MPTSWBK') ? game === 'P' : game === 'K') return '#414bb2'
  //   else if (game === 'T') return '#ff431b'
  //   else if (game === 'S') return '#32c6f4'
  //   else if (game === 'W') return 'green'
  //   else if (game === 'B') return '#fc0404'
  //   else if ( (this.state.GDisplay === 'MPTSWBK') ? game === 'K' : game === 'D') return '#f8c100'
  //   else if (game === '8') return '#6d100e'
  //   else return null
  // }

  PlatformChange(platform) {
    if (platform === '1') return 'M'
    else if (platform === '2') return (this.state.GDisplay === 'MKTSWBD') ? 'K': 'P'
    else if (platform === '3') return 'T'
    else if (platform === '4') return 'S'
    else if (platform === '5') return 'W'
    else if (platform === '6') return 'B'
    else if (platform === '7') return (this.state.GDisplay === 'MKTSWBD') ? 'D' : 'K'
    else if (platform === '8') return '8'
    else if (platform === '9') return '9'
    return 'Default'
  }

  render() {
    return(
      <div>
        <div style={{width: 'calc(100vw - 655px)'}}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5, fontSize: 24}}>
            <b style={{ marginRight: 10, fontSize: 28 }}>{language[this.props.currentLanguage][this.props.limitType]}</b>
            <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].FollowingGameSettingSameAsDefault} </div>
            <div style={{ display: 'flex'}}>
              {((this.state.GDisplay === 'MPTSWBK') ? this.state.GamePlatform2 : this.state.GamePlatform).map((item, index) => {
                return <Form.Check
                  key={item}
                  style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                  inline
                  label={item}
                  type="checkbox"
                  checked={!this.props.addRedNumberData[this.props.limitType].Limit[index + 1]}
                  onChange={(e) => this.gameChange(this.props.limitType, 'Limit', index + 1)}
                />
              })}
            </div>
          </div>
          <div style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: this.state.tableWidth[this.props.WordLength.word.length], paddingTop: 10, borderRadius: 10, color: '#000', backgroundColor: Platformcolor('Default', '') }}>
            <div style={{ margin: '0px 10px', width: 70, fontSize: 18}}><b>Default</b></div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
              <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
              <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.addRedNumberData[this.props.limitType].Limit['Default'].all} onChange={(e) => this.textAllChange(this.props.limitType, 'Limit', 'Default', e.target.value)}/>
            </div>
            {this.state.tableShowLimit[this.props.WordLength.word.length].map((item) => {
              return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.addRedNumberData[this.props.limitType].Limit['Default'][item]} onChange={(e) => this.textChange(this.props.limitType, 'Limit', 'Default', item, e.target.value)}/>
              </div>
            })}
          </div>
          {Object.keys(this.props.addRedNumberData[this.props.limitType].Limit).filter((item) => item !== 'Default').map((game) => {
            return <div key={game} style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: this.state.tableWidth[this.props.WordLength.word.length],  paddingTop: 10, borderRadius: 10, color: (game === 'Default' || game === '1' || game === '4' || game === '7' || game === '2') || game === 'B' || game === '3' ? '#000' : '#fff', backgroundColor: Platformcolor(game, '') }}>
              <div style={{ margin: '0px 10px', width: 70, fontSize: 18}}><b>{this.PlatformChange(game)}</b></div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.addRedNumberData[this.props.limitType].Limit[game].all} onChange={(e) => this.textAllChange(this.props.limitType, 'Limit', game, e.target.value)}/>
              </div>
              {this.state.tableShowLimit[this.props.WordLength.word.length].map((item) => {
                return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                  <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.addRedNumberData[this.props.limitType].Limit[game][item]} onChange={(e) => this.textChange(this.props.limitType, 'Limit', game, item, e.target.value)}/>
                </div>
              })}
            </div>
          })}
          <div style={{ backgroundColor: 'red', padding: 10, width: '1110px'}}>
            <div style={{ display: 'flex', marginBottom: 5, alignItems: 'center', width: this.state.tableWidth[this.props.WordLength.word.length],  paddingTop: 10, borderRadius: 10, color: '#000', backgroundColor: Platformcolor('Default', '') }}>
              <div style={{ margin: '0px 10px', width: 70, fontSize: 18}}><b>Default</b></div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.addRedNumberData[this.props.limitType].RedLimit['Default'].all} onChange={(e) => this.textAllChange(this.props.limitType, 'RedLimit', 'Default', e.target.value)}/>
              </div>
              {this.state.tableShowLimit[this.props.WordLength.word.length].map((item) => {
                return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                  <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.addRedNumberData[this.props.limitType].RedLimit['Default'][item]} onChange={(e) => this.textChange(this.props.limitType, 'RedLimit', 'Default', item, e.target.value)}/>
                </div>
              })}
            </div>
            {Object.keys(this.props.addRedNumberData[this.props.limitType].Limit).filter((item) => item !== 'Default').map((game) => {
              return <div key={game} style={{ display: 'flex', marginBottom: 5, alignItems: 'center', width: this.state.tableWidth[this.props.WordLength.word.length],  paddingTop: 10, borderRadius: 10, color: game === 'Default' || game === '1' || game === '4' || game === '7' || game === '2' || game === 'B' || game === '3' ? '#000' : '#fff', backgroundColor: Platformcolor(game, '') }}>
                <div style={{ margin: '0px 10px', width: 70, fontSize: 18}}><b>{this.PlatformChange(game)}</b></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                  <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.addRedNumberData[this.props.limitType].RedLimit[game].all} onChange={(e) => this.textAllChange(this.props.limitType, 'RedLimit', game, e.target.value)}/>
                </div>
                {this.state.tableShowLimit[this.props.WordLength.word.length].map((item) => {
                  return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                    <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.addRedNumberData[this.props.limitType].RedLimit[game][item]} onChange={(e) => this.textChange(this.props.limitType, 'RedLimit', game, item, e.target.value)}/>
                  </div>
                })}
              </div>
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(AddRNPackage);