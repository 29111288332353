import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';

class FreeJP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.FreeJPModal}
        contentClassName="br23">
        <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto' }}>
          <GrFormClose
            style={{ position: 'absolute', right: 20, top: 10, cursor: 'pointer' }}
            onClick={() => {
              this.props.propsSetState({ FreeJPModal: false,
                JPFreeJPMultiply: JSON.parse(JSON.stringify(this.props.oriFormData.FreeJPMultiply)),
                JPFreeJPX10: JSON.parse(JSON.stringify(this.props.oriFormData.FreeJPX10)),
                JPFreeJPActive: JSON.parse(JSON.stringify(this.props.oriFormData.FreeJPActive)),
                AddWaterStatusUser: 'self'
              })
            }}
            size="35px"
            color="#000"
          />
          <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
            <b className="dcc" style={{ padding: 20, fontSize: 23}}>
              {'Free JP'}
            </b>
            <div className="dcc" style={{ marginBottom: 10}}>
              <Form.Control as="select" style={{ width: 200,fontWeight: 'bold'}} onChange={(e) => this.props.propsSetState({ AddWaterStatusUser: e.target.value})}
                value={this.props.AddWaterStatusUser}  
              >
                <option value="self">{language[this.props.currentLanguage].self}</option>
                <option value="downlines"> {language[this.props.currentLanguage].downline}</option>
              </Form.Control>
            </div>
            <div className="dcc" style={{ marginBottom: 10}}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                <div style={{ margin: '0px 10px', width: 60, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Active}:</div>
                <Form.Check
                  style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                  inline
                  type="checkbox"
                  checked={((this.props.JPFreeJPActive || 0)=== 1)}
                  onChange={(e) => this.props.propsSetState({ JPFreeJPActive: (!this.props.JPFreeJPActive === true) ? 1 : 0 })} 
                />
              </div>
            </div>
            <div className="dcc" style={{ marginBottom: 10}}>
              <div style={{ justifyContent: 'space-between',  display: 'flex',}}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div style={{ margin: '0px 10px', fontWeight: 'bold', width:  ''}}>{'BTJP'}{language[this.props.currentLanguage].Amount}:</div>
                  <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="number" 
                    onChange={(e) => this.props.propsSetState({ FreeBTJPAmount: e.target.value })} 
                    value={ this.props.FreeBTJPAmount || ''} 
                    placeholder={language[this.props.currentLanguage].Amount}
                  />
                </div>
              </div>
            </div>
            <div className="dcc" style={{ marginBottom: 10}}>
              <div style={{ justifyContent: 'space-between',  display: 'flex',}}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div style={{ margin: '0px 10px', fontWeight: 'bold', width:  ''}}>{language[this.props.currentLanguage].Amount}:</div>
                  <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="number" 
                    onChange={(e) => this.props.propsSetState({ JPFreeJPAmount: e.target.value })} 
                    value={ this.props.JPFreeJPAmount || ''} 
                    placeholder={language[this.props.currentLanguage].Amount}
                  />
                </div>
              </div>
            </div>
            <div className="dcc" style={{ marginBottom: 10}}>
              <div style={{ justifyContent: 'space-between',  display: 'flex',}}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div style={{ margin: '0px 10px', fontWeight: 'bold'}}>{'JPX10:'}</div>
                  <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="number" 
                    onChange={(e) => this.props.propsSetState({ JPFreeJPX10: e.target.value })} 
                    value={ this.props.JPFreeJPX10 || ''} 
                    placeholder={'JPX10'}
                  />
                </div>
              </div>
            </div>
            <div className="dcc" style={{ marginBottom: 10}}>
              <div style={{   display: 'flex',}}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10}}>
                  <div style={{ margin: '0px 10px', marginRight: 10, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Multiply}:</div>
                  <Form.Control style={{ width: 200, marginRight: 10,fontWeight: 'bold'}} type="number" 
                    onChange={(e) => this.props.ModalDataChange('JPFreeJPMultiply', e.target.value)} 
                    value={ this.props.JPFreeJPMultiply || ''} 
                    placeholder={language[this.props.currentLanguage].Multiply}
                  />
                </div>
              </div>
            </div>
            <div className="dcc">
              <Button  className='button2'  style={{ margin: 5, width: '20%'}} variant="danger" 
                onClick={() => this.props.propsSetState({ AddWaterStatusUser: 'self', 
                  FreeJPModal: false, 
                  JPFreeJPMultiply: JSON.parse(JSON.stringify(this.props.oriFormData.FreeJPMultiply)),
                  JPFreeJPX10: JSON.parse(JSON.stringify(this.props.oriFormData.FreeJPX10)),
                  JPFreeJPActive: JSON.parse(JSON.stringify(this.props.oriFormData.FreeJPActive)),
                })}>
                {language[this.props.currentLanguage].Shutdown}
              </Button>
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" 
                onClick={() => this.props.propsSetState({ FreeJPModal: false}, this.props.formData.ID)}>{language[this.props.currentLanguage].Confirm}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeJP);