import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { connect } from 'react-redux'
// import Receipt from '../Images/Receipt.png';
import Lotto9 from '../Images/Cards/9Lottologo.png';
import MagnumLogo from '../Images/Cards/magnumlogo.png';
import TotoLogo from '../Images/Cards/toto.png';
import DamacaiLogo from '../Images/Cards/damacaimobile.png';
import GDLogo from '../Images/Cards/GrandDragonLotto.png';
import Sg4Logo from '../Images/Cards/singaporePool.png';
import SarawakLogo from '../Images/Cards/sarawak.png';
import Sabah88Logo from '../Images/Cards/sabah88.png';
import SansakanLogo from '../Images/Cards/sandakan.png';
import Moment from 'moment-timezone';
import Table from 'react-bootstrap/Table';
import { language } from '../language';
import { Form, Button } from 'react-bootstrap';
import { Platformcolor } from '../static/PlatformColor';
import { exportTotalExcelFight } from '../jsonToXlsx';
const { getAllSalesFight } = require('../Api');

const utilizeScroll = () => {
  
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class GrandTotalNumberResultFight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: this.props.username,
      TodayDate: moment(new Date()).format('DD/MM/YYYY (ddd)'),
      Min_Date: Moment(new Date()).toDate(),
      Max_Date: Moment(new Date()).toDate(),
      SelectedDate: Moment(new Date()).toDate(),
      SearchValue: '',
      TextResult: '',
      showShare: true,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      ChooseStatus: 300, 
      ScrollViewNumb: 6600,
      GameArray: {},
      UseridReport: '', 
      MinimumAmount: 0,
      MinimumType: 'B,S,SA',
      Loading: false,
      ObjectKeys: ['1','2','3','4','5','6','7','8', '9'],
      PopScrollBackButton: false,
      MinimumShowStatus: 'Hide',
      UserID: this.props.userID,
      LoadingPage: false,
    }
    this.SelectedOptionStatus = this.SelectedOptionStatus.bind(this);
    this.elScroll = utilizeScroll();
  }

  async componentDidMount(){
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if (UserAuthorities.GameBetting.includes('9')) {
      let ObjectKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
      this.setState({ ObjectKeys: ObjectKeys })
    }
    this.setState({ UserID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID }, () => {
      this.CallAPI('B + S')
    })
  }

  async CallAPI(type){
    this.setState({ LoadingPage: true })
    const UserID = this.state.UserID
    this.setState({ UseridReport: UserID}, async()=>{
      const CallApiData = {
        fromDate: Moment(this.state.Max_Date).format('YYYY-MM-DD'),
        toDate: Moment(this.state.SelectedDate).format('YYYY-MM-DD'),
        userID: this.state.UseridReport,
      }
      const GameArray = await getAllSalesFight( CallApiData, this.state.MinimumAmount, this.state.MinimumType, this.state.ChooseStatus,  this.props.username, this.props.token)
      console.log('GameArrasssy', GameArray)
      if(GameArray.message === 'No Data'){
        this.setState({GameArray: JSON.parse(JSON.stringify( GameArray.message)), Loading: true, LoadingPage: false })
      } else if (GameArray.error) {
        this.setState({GameArray: JSON.parse(JSON.stringify(GameArray.error)), Loading: true, LoadingPage: false})
      } else {
        let AddBoolean = GameArray
        this.state.ObjectKeys.forEach((item) =>{
          if ( GameArray[item].length !== 0){
            AddBoolean[item] = {...GameArray[item], ...{GameBoolean: true}}
          }
        })
        this.setState({GameArray: JSON.parse(JSON.stringify(AddBoolean)), Loading: true, LoadingPage: false}, () => {
          if (type !== undefined) {
            this.setState({ [(type === 'B + S') ? 'BigBandS' : `Big${type}`]: undefined }, () => {
              this.BigToSmall(type)
            })
          }
        })
      }
    })
  }

  toggleResult(platform) {
    let EditBoolean = this.state.GameArray
    EditBoolean[platform] = {...EditBoolean[platform], ...{GameBoolean: !(EditBoolean[platform].GameBoolean)}}
    this.setState({GameArray: JSON.parse(JSON.stringify( EditBoolean))})
    // this.setState({
    //   [`show${platform}`]: !this.state[`show${platform}`],
    // }, ()=> {
    //   if(this.state[`show${platform}`] === true) {
    //     this.setState({ ScrollViewNumb: this.state.ScrollViewNumb + 500})
    //   } else {
    //     this.setState({ ScrollViewNumb: this.state.ScrollViewNumb - 500})
    //   }
    // })
  }

  textChange(value){
    if (/^[0-9-]+$/.test(value) || value === '') {
      this.setState({ MinimumAmount: value})
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  onKeyEnter(event){
    if (event.key === 'Enter') {
      this.CallAPI();
    }
  }

  SelectedOptionStatus(event) {
    this.setState({ChooseStatus: event.target.value}, ()=>{
      this.CallAPI();
    });
  }

  async setSelectedDate(SelectedDate) {
    this.setState({ SelectedDate }, ()=>{
      this.CallAPI();
    })
  }

  // handleScroll = (event) => {    
  //   var node = event.target;
  //   const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
  //   if (bottom) {
  //     this.setState({PopScrollBackButton: bottom})
  //   } else {
  //     this.setState({PopScrollBackButton: bottom})
  //   }
  // }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  BigToSmall(items) {
    if (this.state.GameArray !== 'No Data') {
      let ReportDetailsFilter = this.state.GameArray
      const BooleanBigorSmall = (items === 'B + S') ? 'BigBandS' : `Big${items}`
      const ArrayNumber = ['1','2','3','4','5','6','7','8', '9']
      let AnswerBoolean = (this.state[`${BooleanBigorSmall}`] === undefined)  ? false : !this.state[`${BooleanBigorSmall}`]
      ArrayNumber.forEach((status)=>{
        let ReportDetails = this.state.GameArray[status]
        const FilterUnwantedBoolean = Object.keys(this.state.GameArray[status]).filter((item) =>  item !== 'GameBoolean')
        const Filters = []
        FilterUnwantedBoolean.forEach((item) => Filters.push(this.state.GameArray[status][item]))
        if(items === 'B + S') {
          if(AnswerBoolean === false) {
            Filters.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
          } else {
            Filters.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
          }
        } else if (items === 'Number') {
          if(AnswerBoolean === false) {
            Filters.sort((a, b) => (Number(a.Word) > Number(b.Word)) ? -1 : 1)
          } else {
            Filters.sort((a, b) => (Number(a.Word) > Number(b.Word)) ? 1 : -1)
          }
        } else {
          if(AnswerBoolean === false) {
            Filters.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
          } else {
            Filters.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
          }
        }
        for (let i = 0; i < Filters.length; i += 1) {
          ReportDetails[i] = Filters[i]
        }

        ReportDetailsFilter[status] = ReportDetails
      })
      this.setState({ GameArray: JSON.parse(JSON.stringify(ReportDetailsFilter)), [`${BooleanBigorSmall}`]: AnswerBoolean })
    }
  }

  async ExportExcelFile() {
    if (this.state.GameArray !== 'No Data') {
      exportTotalExcelFight(this.state.GameArray, Moment(this.state.SelectedDate).format('YYYY-MM-DD'), this.state.ChooseStatus, this.state.MinimumType)
    }
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowY: 'auto' }}> 
        <Header2 />
        {this.state.LoadingPage && 
        <>
          <div className='dcc' style={{ fontWeight: 'bold', fontSize: 30, height: '80vh'}}>{language[this.props.currentLanguage].Loading}</div>
        </>
        }
        {!this.state.LoadingPage && 
        <>
          <div  style={{ width: '100%', fontWeight: 'bold' }}>{`${language[this.props.currentLanguage].SalesReport}(吃字)`}</div>
          <div className="dcc mobileHeader"
            ref={this.elScroll.elRef}
            style={{
              marginTop: 20,
              paddingBottom: 0,
              justifyContent: 'space-between',
              marginLeft: '3.5%',
              flexDirection: 'row',
              width: '95%'
            }}>
            <div className='dcc' style={{ justifyContent: 'center', display: 'flex' }} >
              <div style={{fontSize: "16px", width: 100, fontWeight: 'bold'}}>{language[this.props.currentLanguage].Date} :</div>
              <DatePicker
                onChange = {(Date) => this.setSelectedDate(Date)}
                dateFormat = 'dd-MMM-yyyy'
                placeholder="Date"
                // maxDate={new Date()}
                // popperPlacement="top-right"
                selected={this.state.SelectedDate}
                className={"form-control StyleDatePicker"}
                // withPortal
              />
            </div>
            <div className="dcc" style={{ marginRight:  20 }}>
              <div className="TitleUsername"> ID - {this.props.username}</div>
            </div>
          </div>
          <div style={{ marginTop: 10, marginLeft: '3.5%', display: 'flex', flexDirection:  'row'}} >
            <div className='dcc' style={{ display: 'flex'}}> 
              <div style={{fontSize: "16px", width: 180, fontWeight: 'bold' }}>{'Minimum Filter'} :</div>
              <div className='dcc'>
                <Form.Control
                  type="text"
                  value={this.state.MinimumAmount}
                  onChange={(e) => this.textChange( e.target.value)}
                  onKeyPress={(e) => this.onKeyEnter(e)}
                  placeholder={'Amount'}
                  style={{ textAlign: 'center',fontWeight: 'bold', width: '80%'}}
                />
              </div>
            </div>
            <div className='dcc' style={{ display: 'flex' }}>
              <div style={{fontSize: "16px", width: '50%', fontWeight: 'bold'}}>{'Type'} :</div>
              <div className={'dcc'} style={{ minWidth: 200 }}>
                <Form.Control
                  as="select"
                  type="text"
                  value={this.state.MinimumType}
                  onChange={(e) => this.setState({ MinimumType: e.target.value}, ()=>{
                    // this.CallAPI();
                    if (e.target.value === 'B,S,SA') {
                      // this.BigToSmall('B + S')
                      this.CallAPI('B + S');
                    }
                    if (e.target.value === 'SA,SB,SC,SD,SE,SF') {
                      // this.BigToSmall('SA')
                      this.CallAPI('SA');
                    }
                    if (e.target.value === 'CA,CF') {
                      // this.BigToSmall('CA')
                      this.CallAPI('CA');
                    }
                    if (e.target.value === 'CA,CB,CC,CD,CE,CF') {
                      // this.BigToSmall('CA')
                      this.CallAPI('CA');
                    }
                    if (e.target.value === 'PA,PF') {
                      // this.BigToSmall('PA')
                      this.CallAPI('PA');
                    }
                    if (e.target.value === 'PA,PB,PC,PD,PE,PF') {
                      // this.BigToSmall('PA')
                      this.CallAPI('PA');
                    }
                    if (e.target.value === '5D,6D') {
                      // this.BigToSmall('Number')
                      this.CallAPI('Number');
                    }
                  })}
                  placeholder={'Amount'}
                  style={{ fontWeight: 'bold', width: '100%', marginRight:  null, textAlign: 'center'}}
                >
                  <option value="-">-</option>
                  <option value="B,S,SA">B,S,SA</option>
                  <option value="SA,SB,SC,SD,SE,SF">SA,SB,SC,SD,SE,SF</option>
                  <option value="CA,CF">A,ABC</option>
                  <option value="CA,CB,CC,CD,CE,CF">CA,CB,CC,CD,CE,CF</option>
                  <option value="PA,PF">PA,PF</option>
                  <option value="PA,PB,PC,PD,PE,PF">PA,PB,PC,PD,PE,PF</option>
                  <option value="5D,6D">5D,6D</option>
                </Form.Control>
              </div>
            </div>
            <div className='dcc' style={{ display: 'flex', width:  '25%'}}> 
              <div style={{fontSize: "16px", width: '50%', fontWeight: 'bold', minWidth: 100 }}>{language[this.props.currentLanguage].Singular} :</div>
              <div className={'dcc'} style={{ width: '100%'}}>
                <Form.Control
                  as="select"
                  type="text"
                  value={this.state.MinimumShowStatus}
                  onChange={(e) => this.setState({ MinimumShowStatus: e.target.value}, ()=>{
                    // this.CallAPI();
                  })}
                  style={{ fontWeight: 'bold', width:   '100%', marginRight: null, textAlign: 'center'}}
                >
                  <option value="Hide">{language[this.props.currentLanguage].Hide}</option>
                  <option value="Show">{language[this.props.currentLanguage].Show}</option>
                </Form.Control>
              </div>
            </div>
          </div>
          <div className="dcc mobileHeader"
            style={{
              marginTop: 10,
              paddingBottom: 10,
              justifyContent: 'space-between',
              marginLeft:  '4%',
              marginRight:  '4%',
              flexDirection: 'row',
            }}>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>显示 B,S,SA,SB,SC 销售报告 (吃字)</div>
            <Button className="button2" style={{ fontWeight: 'bold'}} onClick={() => this.ExportExcelFile()} >{language[this.props.currentLanguage].ExportReport}</Button>
          </div>
          <div className="dcc mobileHeader"
            style={{
              marginTop: 0,
              paddingBottom: 0,
              justifyContent: 'space-between',
              marginLeft:  '4%',
              flexDirection: 'row',
            }}>
            <Form.Control as='select' className="form-control" value={this.state.ChooseStatus} onChange={this.SelectedOptionStatus} style={{ textAlign: 'center', marginRight:  20, width:  '10%', fontSize: '15px',}}>
              <option value={300}>{"300"}</option>
              <option value={500}>{"500"}</option>
              <option value={1000}>{"1000"}</option>
              <option value={10000}>{"10000"}</option>
            </Form.Control>
          </div>
          <div style={{ marginTop: 10,marginLeft:  '1.5%', display: 'flex' }} >
            <div style={{fontSize: "16px", width: 150, fontWeight: 'bold'}}>{'Total'} :</div>
            <div className="TitleUsername">{Number(this.state.GameArray['TotalAmount'] || 0).toFixed(0)} </div>
          </div>
          {(this.state.Loading && this.state.GameArray !== 'No Data') &&<div className={`d-flex justify-content-center px-3 `} style={{ marginBottom: 15}}>
            <div className="dcc row" style={{ justifyContent: 'center' }} align="left">
              { this.state.ObjectKeys.map((item, idx) =>{ 
                return <img key={idx} src={ (item === '1') ? MagnumLogo : (item === '2') ? DamacaiLogo : (item === '3') ? TotoLogo : (item === '4') ? Sg4Logo : (item === '5') ? SarawakLogo : (item === '6') ? Sabah88Logo : (item === '7') ? SansakanLogo : (item === '9') ? Lotto9 : GDLogo } 
                  alt="Logo" className={`mainPageToggleLogo ${this.state.GameArray[item].GameBoolean && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult(item)} />
              })}
            </div>
          </div>}
          <div className='dcc' style={{ display: 'flex', flexDirection: 'row', marginBottom: 5}}>
            {(this.state.MinimumType === 'B,S,SA' && this.state.GameArray !== 'No Data') && 
        <>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.BigToSmall('B + S')}>B + S</div>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', marginLeft: 5, fontWeight: 'bold' }} onClick={() => this.BigToSmall('B')}>B</div>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', marginLeft: 5, fontWeight: 'bold' }} onClick={() => this.BigToSmall('S')}>S</div>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', marginLeft: 5, fontWeight: 'bold' }} onClick={() => this.BigToSmall('SA')}>SA</div>
        </>
            }
            {(this.state.MinimumType === 'SA,SB,SC,SD,SE,SF' && this.state.GameArray !== 'No Data') && 
        <>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.BigToSmall('SA')}>SA</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('SB')}>SB</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('SC')}>SC</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('SD')}>SD</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('SE')}>SE</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('SF')}>SF</div>
        </>
            }
            {(this.state.MinimumType === 'CA,CF' && this.state.GameArray !== 'No Data') && 
        <>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.BigToSmall('CA')}>CA</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('CF')}>CF</div>
        </>
            }
            {(this.state.MinimumType === 'CA,CB,CC,CD,CE,CF' && this.state.GameArray !== 'No Data') && 
        <>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.BigToSmall('CA')}>CA</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('CB')}>CB</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('CC')}>CC</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('CD')}>CD</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('CE')}>CE</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('CF')}>CF</div>
        </>
            }
            {(this.state.MinimumType === 'PA,PF' && this.state.GameArray !== 'No Data') && 
        <>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.BigToSmall('PA')}>PA</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('PF')}>PF</div>
        </>
            }
            {(this.state.MinimumType === 'PA,PB,PC,PD,PE,PF' && this.state.GameArray !== 'No Data') && 
        <>
          <div className='button2' style={{ border: '1px solid black', width: 50, borderRadius: 5, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.BigToSmall('PA')}>PA</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('PB')}>PB</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('PC')}>PC</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('PD')}>PD</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('PE')}>PE</div>
          <div className='button2 BigSmallButton' onClick={() => this.BigToSmall('PF')}>PF</div>
        </>
            }
            {(this.state.MinimumType === '5D,6D' && this.state.GameArray !== 'No Data') && 
        <>
          <div className='button2' style={{ border: '1px solid black', width: 80, borderRadius: 5, cursor: 'pointer', fontWeight: 'bold'}} onClick={() => this.BigToSmall('Number')}>Number</div>
        </>
            }
          </div>
          {(this.state.Loading && this.state.GameArray !== 'No Data') && <Table responsive="sm" style={{ backgroundColor: 'white' }}>
            <div className="column" style={{ justifyContent: 'center', display: 'flex' }} >
              {  this.state.ObjectKeys.map((item, index) =>{
                return (this.state.GameArray[item].GameBoolean) && <div key={index} style={{ paddingBottom: 35, marginRight: 20 }}>
                  <Table>
                    <thead>
                      <tr style={{
                        fontWeight: 'bold',
                        backgroundColor: Platformcolor(item, ''),
                        color: (item === '1') ? 'black' : (item === '7') ? 'black' : (item === '4') ? 'black' : 'white'}} >
                        <th colSpan={3} ><div  style= {{ fontSize: '15px'}}>{(item === '1') ? `${language[this.props.currentLanguage].magnum}` : 
                          (item === '2') ? `${language[this.props.currentLanguage].damacai}` : 
                            (item === '3') ? `${language[this.props.currentLanguage].toto}`: 
                              (item === '4') ? `${language[this.props.currentLanguage].singapore}`:
                                (item === '5') ? `${language[this.props.currentLanguage].sarawak}`:
                                  (item === '6') ? `${language[this.props.currentLanguage].sabah}`: 
                                    (item === '7') ? `${language[this.props.currentLanguage].sandakan}`: 
                                      (item === '9') ? `Lotto 9` :
                                        `${language[this.props.currentLanguage].GrandDragon}` }</div></th>
                        <th  colSpan={(this.state.MinimumShowStatus === 'Show') ? 5 : 4}  >
                          <div style= {{ fontSize: '15px'}}>
                            {Number(this.state.GameArray['Amount' + item] || 0).toFixed(0)}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr style={{ backgroundColor: 'lightgrey' }}>
                        <th style={{ paddingRight: 5, paddingLeft: 5 }}><div>Total</div></th>
                        {this.state.MinimumType === 'B,S,SA' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{Number(this.state.GameArray[`Amount${item}B+S`] || 0).toFixed(2)}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}B`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}S`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}SA`]}</div></th>
                        </> : this.state.MinimumType === 'SA,SB,SC,SD,SE,SF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}SA`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}SB`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}SC`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}SD`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}SE`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}SF`]}</div></th>
                        </> : this.state.MinimumType === 'CA,CF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}CA`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}CF`]}</div></th>
                        </> : this.state.MinimumType === 'CA,CB,CC,CD,CE,CF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}CA`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}CB`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}CC`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}CD`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}CE`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}CF`]}</div></th>
                        </> : this.state.MinimumType === 'PA,PF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}PA`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}PF`]}</div></th>
                        </> : this.state.MinimumType === 'PA,PB,PC,PD,PE,PF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}PA`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}PB`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}PC`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}PD`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}PE`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}PF`]}</div></th>
                        </> : <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}5D`]}</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>{this.state.GameArray[`Amount${item}6D`]}</div></th>
                        </>}
                        {(this.state.MinimumShowStatus === 'Show')&&<th style={{  paddingRight: 5, paddingLeft: 5, minWidth: 100 }}><div>{language[this.props.currentLanguage].Singular}</div></th>}
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th style={{ paddingRight: 5, paddingLeft: 5 }}><div>Number</div></th>
                        {this.state.MinimumType === 'B,S,SA' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>B+S</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>B</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>S</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>SA</div></th>
                        </> : this.state.MinimumType === 'SA,SB,SC,SD,SE,SF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>SA</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>SB</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>SC</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>SD</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>SE</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>SF</div></th>
                        </> : this.state.MinimumType === 'CA,CF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>CA</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>CF</div></th>
                        </> : this.state.MinimumType === 'CA,CB,CC,CD,CE,CF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>CA</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>CB</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>CC</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>CD</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>CE</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>CF</div></th>
                        </> : this.state.MinimumType === 'PA,PF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>PA</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>PF</div></th>
                        </> : this.state.MinimumType === 'PA,PB,PC,PD,PE,PF' ? <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>PA</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>PB</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>PC</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>PD</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>PE</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>PF</div></th>
                        </> : <>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>5D</div></th>
                          <th style={{ paddingRight: 5, paddingLeft: 5, width: 50 }}><div>6D</div></th>
                        </>}
                        {(this.state.MinimumShowStatus === 'Show')&&<th style={{  paddingRight: 5, paddingLeft: 5, minWidth: 100  }}><div>{language[this.props.currentLanguage].Singular}</div></th>}
                      </tr>
                    </thead>
                    <tbody>
                      { Object.keys(this.state.GameArray[item]).map((NumberItem, ind) =>{
                        return <tr key={ind}>
                          {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') && <th style={{ color: 'black', paddingRight: 5, paddingLeft: 5, backgroundColor: '#9AFFF0'}} >{this.state.GameArray[item][NumberItem]['Word'] }</th>}
                          {this.state.MinimumType === 'B,S,SA' ? <>
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['B + S']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5, backgroundColor: '#FFF4AE'}}>{Number(this.state.GameArray[item][NumberItem]['B']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['S']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5, backgroundColor: '#FFA5A5'}}>{Number(this.state.GameArray[item][NumberItem]['SA']).toFixed(2)}</th>}
                          </> : this.state.MinimumType === 'SA,SB,SC,SD,SE,SF' ? <>
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SA']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SB']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SC']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SD']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SE']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['SF']).toFixed(2)}</th>}
                          </> : this.state.MinimumType === 'CA,CF' ? <>
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['CA']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['CF']).toFixed(2)}</th>}
                          </> : this.state.MinimumType === 'CA,CB,CC,CD,CE,CF' ? <>
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['CA']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['CB']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['CC']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['CD']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['CE']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['CF']).toFixed(2)}</th>}
                          </> : this.state.MinimumType === 'PA,PF' ? <>
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['PA']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['PF']).toFixed(2)}</th>}
                          </> : this.state.MinimumType === 'PA,PB,PC,PD,PE,PF' ? <>
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['PA']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['PB']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['PC']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['PD']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['PE']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['PF']).toFixed(2)}</th>}
                          </> : <>
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['5D']).toFixed(2)}</th>}
                            {(NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['6D']).toFixed(2)}</th>}
                          </>}
                      
                          {(this.state.MinimumShowStatus === 'Show')&& (NumberItem !== 'Amount' && NumberItem !== 'GameBoolean') &&<th style={{ paddingRight: 5, paddingLeft: 5}}>{Number(this.state.GameArray[item][NumberItem]['TotalOrderCount'])}</th>}
                        </tr>
                      })}
                    </tbody>
                  </Table>
                </div>
              })
              }
            </div>
          </Table>}
          {(this.state.GameArray.message === 'No Data') && <div className= "dcc">
            <div className="TitleUsername" style={{ width:  '30%'}}>
              {language[this.props.currentLanguage].GameHavenStart}
            </div>
          </div>}
          {(this.state.PopScrollBackButton)&&<div className={"scroll-to-top iconpopup button2"}  
            onClick={this.elScroll.executeScroll}
          >
            <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold' }}>
        ^
            </div>
          </div>}
        </>
        }
      </div >
    );
  }
}
// export default Page2;

function mapStateToProps(state, ownProps) {
  return {
    userID: state.userID,
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GrandTotalNumberResultFight);