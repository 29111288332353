import '../../App.css';
import React from 'react';
import { connect } from 'react-redux';
import { language } from '../../language';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

class ActiveMultiplePackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.AlertModal}
        contentClassName="br23">
        <Modal.Body className="dcc" style={{ padding: 10, height: 'auto', width: 'auto', }}>
          <div style={{ width: '100%', border: '1px solid #000', borderRadius: 5, marginBottom: 5, flexDirection: 'column'}}>
            <b className="dcc" style={{ padding: 20, fontSize: 23}}>
              {language[this.props.currentLanguage].MultiplePackageMessage}
            </b>
            <div className="dcc">
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => this.props.propsSetState({ AlertModal: false})}>{language[this.props.currentLanguage].Shutdown}</Button>
              {/* <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" onClick={() => {
                this.props.propsSetState({ AlertModal: false})
                this.props.SubmitMultiplePackage()
              }}>{language[this.props.currentLanguage].Confirm}</Button> */}
              <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="success" onClick={() => {
                this.props.propsSetState({ AlertModal: false})
                this.props.SubmitMultiplePackage('Active')
              }}>{language[this.props.currentLanguage].SelectedActive}</Button>
              {/* <Button  className='button2' style={{ margin: 5, width: '20%'}} variant="danger" onClick={() => {
                this.props.propsSetState({ AlertModal: false})
                this.props.SubmitMultiplePackage('Cancel')
              }}>{language[this.props.currentLanguage].SelectedCancel}</Button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveMultiplePackage);