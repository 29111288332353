export function ReturnBetValidation(ValidateMessage) {
  if(ValidateMessage === 'User No Package') {
    return 'NoPackage'
  } else if(ValidateMessage === 'Repeat Order') {
    return 'RepeatOrder'
  } else if(ValidateMessage === 'No Date to Bet') {
    return 'DateCantbeBet'
  } else if(ValidateMessage === '5D6D in wrong game') {
    return 'OnlyBTandTOTOallow'
  } else if(ValidateMessage === 'Not enough date') {
    return 'UnableThoseDate'
  } else if(ValidateMessage === 'Date error') {
    return 'UnableThoseDate'
  } else if(ValidateMessage === 'S Game ShutDown') {
    return 'singaporeGameClose'
  } else if(ValidateMessage === 'All Game ShutDown') {
    return 'AllGameClose'
  } else if(ValidateMessage === 'MKTSWBD ShutDown') {
    return 'MKTGameShutDown'
  } else if(ValidateMessage === 'No Game on that day') {
    return 'NoGameThatDay'
  } else if(ValidateMessage === 'Platform error') {
    return 'ErrorPlatform'
  } else if(ValidateMessage === 'Account Inactive') {
    return 'AccountInactive'
  } else if(ValidateMessage === 'Exceed Personal Daily Limit') {
    return 'ExceedDAilyLimit'
  } else if(ValidateMessage === 'Over Number Limit') {
    return 'OverNumberLimit'
  } else if(ValidateMessage === 'Format Error') {
    return 'FormatError'
  } else if(ValidateMessage === 'Cannot bet game desired') {
    return 'UnableBetThisPlat'
  } else if(ValidateMessage === 'User No Able Red') {
    return 'UserNotAbleRed'
  } else if(ValidateMessage === 'User Red Lower Than Commission') {
    return 'UserRedLowerThanCommission'
  } else if(ValidateMessage === 'User Negative Commission') {
    return 'CommissionNegative'
  } else if(ValidateMessage === 'database error') {
    return 'databaseError'
  } else if(ValidateMessage === 'Not enough cash wallet') {
    return 'Notenoughcashwallet'
  } else if(ValidateMessage === 'User No Able SGD') {
    return 'UserNobleSGD'
  } else if(ValidateMessage === 'Not enough cash wallet') {
    return 'NotEnoughWallet'
  } else if(ValidateMessage === 'Invalid originalText') {
    return 'InvalidOriginalText'
  } else if(ValidateMessage === 'Demo cannot bet long term') {
    return 'DemoCannotBet'
  } else if(ValidateMessage === 'SGD Thai Wrong Setting') {
    return 'SGDThaiWrongSetting'
  } else if(ValidateMessage === 'User No Able THAI') {
    return 'UserNoAbleTHAI'
  } else if(ValidateMessage === 'SGD cannot bet long term') {
    return 'SGDCannotBet'
  } else if(ValidateMessage === 'THAI cannot bet long term') {
    return 'ThaiCannotBet'
  }
}