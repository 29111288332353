import React, { Component } from 'react';
import { connect } from 'react-redux'
// import toto from '../../Images/toto.png';
import { language } from '../../language';
import Moment from 'moment'
import NineIcon from '../../Images/Cards/9Lottologo.png';

class JackpotToto extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    // const Special = [1,2,3,4,5,6,7,8,9,10]
    const forthprices = this.props.nine['5D1'] || '-----'
    const fifthprices = this.props.nine['5D1'] || '-----'
    const sixprices = this.props.nine['5D1'] || '-----'
    const secondprices = this.props.nine['6D1'] ? this.props.nine['6D1'].substring(0,5) : '----'
    const secondPrices2 = this.props.nine['6D1'] ? this.props.nine['6D1'].substring(1,6) : '----'
    const thirdprices1 = this.props.nine['6D1'] ? this.props.nine['6D1'].substring(0,4) : '----'
    const thirdprices2 = this.props.nine['6D1'] ? this.props.nine['6D1'].substring(2,6) : '----'
    const fourprices1 = this.props.nine['6D1'] ? this.props.nine['6D1'].substring(0,3) : '----'
    const fourprices2 = this.props.nine['6D1'] ? this.props.nine['6D1'].substring(3,6) : '----'
    const fifthprices1 = this.props.nine['6D1'] ? this.props.nine['6D1'].substring(0,2) : '----'
    const fifthprices2 = this.props.nine['6D1'] ? this.props.nine['6D1'].substring(4,6) : '----'
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="nineborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={NineIcon} alt="Logo" className="logoiconimages activeResultnormal"/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'black' }}><b>9 Lotto 5D</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'black' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{this.props.nine.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="column americaborder" style={{ width: '100%', backgroundColor: '#ffc107', color: 'black' }}>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ffc107' }}>{language[this.props.currentLanguage].first}</div>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ffc107' }}>{language[this.props.currentLanguage].second}</div>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ffc107' }}>{language[this.props.currentLanguage].third}</div>
          </div>
          <div className="column numberborder2" style={{ width: '100%', backgroundColor: '#eeeeee', fontWeight: 'bold' }}>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{this.props.nine['5D1'] || '----'}</div>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{this.props.nine['5D2'] || '----'}</div>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{this.props.nine['5D3'] || '----'}</div>
          </div>
          <div className="column americaborder" style={{ width: '100%', backgroundColor: '#ffc107', color: 'black' }}>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ffc107' }}>{language[this.props.currentLanguage].fourth}</div>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ffc107' }}>{language[this.props.currentLanguage].fifth}</div>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ffc107' }}>{language[this.props.currentLanguage].sixth}</div>
          </div>
          <div className="column numberborder2" style={{ width: '100%', backgroundColor: '#eeeeee', fontWeight: 'bold' }}>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{(forthprices && forthprices.substring(1,5)) || '----'}</div>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{(fifthprices && fifthprices.substring(2,5)) || '----'}</div>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{(sixprices && sixprices.substring(3,5)) || '----'}</div>
          </div>
        </div>
        <div className="nineborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={NineIcon} alt="Logo" className="logoiconimages activeResultnormal"/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'black', fontWeight: 'bold' }}><b>9 Lotto 6D</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'black', fontWeight: 'bold' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{this.props.nine.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div className="numberbordergorjackport" style={{ marginBottom: 10, borderradius: '5px' }}>
          <div className="dcc" style={{ display: 'flex', marginBottom: 2, fontWeight: 'bold' }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ffc107', height: '35px', color: 'black' }}>{language[this.props.currentLanguage].first}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>{this.props.nine['6D1'] || '----'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ffc107', height: '35px', color: 'black' }}>{language[this.props.currentLanguage].second}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{secondprices || '----'}</div>
              <div>or </div>
              <div>{secondPrices2 || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ffc107', height: '35px', color: 'black' }}>{language[this.props.currentLanguage].third}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{thirdprices1 || '----'}</div>
              <div>or </div>
              <div>{thirdprices2 || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ffc107', height: '35px', color: 'black'}}>{language[this.props.currentLanguage].fourth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{fourprices1 || '----'}</div>
              <div>or </div>
              <div>{fourprices2 || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 30, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ffc107', height: '35px', color: 'black' }}>{language[this.props.currentLanguage].fifth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{fifthprices1 || '----'}</div>
              <div>or </div>
              <div>{fifthprices2 || '----'}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(JackpotToto)
