import '../App.css';
import React from 'react';
import Alert from 'react-bootstrap/Alert'
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { language } from '../language';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Modal from 'react-bootstrap/Modal';
import Header from '../Component/Header';
import { IoMdCloseCircle } from '@react-icons/all-files/io/IoMdCloseCircle';
import { Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { BiRefresh } from '@react-icons/all-files/bi/BiRefresh';
import { FiCornerRightDown } from '@react-icons/all-files/fi/FiCornerRightDown';
import { FiCornerRightUp } from '@react-icons/all-files/fi/FiCornerRightUp';
// import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import noneIcon from '../Images/none.png';
import MagnumLogo from '../Images/Cards/magnumlogo.png';
import TotoLogo from '../Images/Cards/toto.png';
import DamacaiLogo from '../Images/Cards/damacaimobile.png';
import GDLogo from '../Images/Cards/GrandDragonLotto.png';
import Sg4Logo from '../Images/Cards/singaporePool.png';
import SarawakLogo from '../Images/Cards/sarawak.png';
import Sabah88Logo from '../Images/Cards/sabah88.png';
import NineLogo from '../Images/Cards/9Lottologo.png';
import SansakanLogo from '../Images/Cards/sandakan.png';
import CheckMark from '../Images/CheckMark.png';
import { findPermutations } from '../utility/Permutation'
import Loading3 from '../Images/loading-3.gif';
import {ReturnBetValidation} from '../static/ReturnBetValidation';
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
import {ReturnCancelValidation} from '../static/ReturnCancelValidation';
const { getKeyInFormat, CreateBetOrder, CancelBet, getUserKeyIn, getMyselfF, searchDownlineHR } = require('../Api');
var timer

class BettingVersion2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      UserID: this.props.userID,
      userID: this.props.userID,
      token: this.props.token,
      DDate: `${language[this.props.currentLanguage].ChooseDate} (D#)`,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      modalShow: false,
      date: '',
      Text: '',
      B: '', S: '', SA: '', SB: '', A: '', C: '',
      SC: '', SD: '', SE: '', SF: '',
      fiveD: '', sixD: '', threeD: '',
      PA: '', PB: '', PC: '', PD: '', PE: '',
      CA: '', CD: '', CB: '', CC: '', CE: '', CF: '', PF: '',
      format: '',
      permutationCheck: true,
      words: [],
      selectedDate: [],
      DisplayDate: [],
      Mon: '', Tue: '', Wed: '',
      Thu: '', Fri: '', Sat: '',
      Sun: '',
      Platform: [],
      SelectedPlatform: [],
      insertPlatform: '',
      KeyinFunctions: [],
      totalBetResults: '',
      SelectedExample: 'B,S,C,A,SA,SD',
      ReverseNumber: '',
      platforms: '',
      PlatformLength: '',
      value: 0,
      ArrayforSum: [],
      AddAmount: [],
      ResultAmount: '',
      originalText: '',
      IBox: false,
      Key2: false,
      FiveD: false,
      SixD: false,
      ThreeD: false,
      TwoD: false,
      BoxPlatform: [],
      MG: false,
      KD: false,
      TT: false,
      SG: false,
      SW: false,
      SBH: false,
      STC: false,
      NINE: false,
      8: false,
      ModalDate: [{ Date: '' }],
      Mode: '',
      FinalAnswer: [],
      OriginalWords: '',
      OriginalW: [],
      OriginText : [],
      Key1IBox: [],
      Key12Box: [],
      KeyDisplay1: '',
      KeyDisplay2: '',
      key3D: [],
      key2D: [],
      ShowReceipt: false,
      Receipt: '',
      UserIDforCancel: '',
      OriTextforCancel: '',
      RoleforCancel: '',
      ServerIDforCancel: '',
      loading: false,
      SuccessFullCancel: false,
      Datelength: '1',
      FormatFull: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      GamesDisplay: '',
      ReceiptDisplay: '',
      optionsUsername: '',
      SelectedUser: '',
      MultiplyDivide: '',
      JinNanCheck: 0,
      is_visible: false,
      OneStar: '',
      DisabledCancel: false,
      PlatformSelect: [],
      PFLIne: '',
      Balance: 0,
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: '',
      getUserFormula: {},
    }
    this.submitNumber = this.submitNumber.bind(this);
    this.TotalBetAmount = this.TotalBetAmount.bind(this);
    this.handleChangeGameOrNumber = this.handleChangeGameOrNumber.bind(this);
    this.SelectedOption = this.SelectedOption.bind(this);
    this.ModeOption = this.ModeOption.bind(this);
    this.handleChangeResultNumber = this.handleChangeResultNumber.bind(this);
    this.handleInputDateChange = this.handleInputDateChange.bind(this);
    this.selectGames = this.selectGames.bind(this);
    this.AddToWord = this.AddToWord.bind(this);
    this.CheckhandleInputDateChange =  this.CheckhandleInputDateChange.bind(this);
    this.PlatformSelectFunction = this.PlatformSelectFunction.bind(this);
    this.startPage = this.startPage.bind(this);
    this.SelectedUser = this.SelectedUser.bind(this);
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
  }

  async componentDidMount() {
    this.PlatformShow();
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ userID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID })
    if ((UserAuthorities && UserAuthorities.Bet) === 0) {
      window.location.href='/Home'; 
    } else {
      this.setState({ OneStar: UserAuthorities.OneStarBao }, ()=> {
        this.startPage();
        this.getDownline();
      })
    }
  }

  PlatformShow() {
    const GameBetting = JSON.parse(this.props.UserAuthority).GameBetting
    // const GameBetting = '129456783'
    this.setState({ BoxPlatform: GameBetting.split('')}, () => {
      let PFLIne = {
        '1': 'M',
        '2': (this.state.GamesDisplay === 'MKTSWBD') ? 'K' : 'P',
        '3': 'T',
        '4': 'S',
        '5': 'W',
        '6': 'B',
        '7': (this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K',
        '8': '8',
        '9': '9',
      }
      this.setState({ PFLIne })
    })
  }

  async getDownline() {
    const UserID = this.state.userID;
    const Username = this.state.username;
    const token = this.state.token
    // const DownlineUsername = await getMyDownline(UserID, token, Username)
    const getMyself = await getMyselfF(UserID, token, Username)
    console.log('getMyself', getMyself)
    if(getMyself) {
      this.setState({ UserID: getMyself[0].ID, SelectedUser: {value: getMyself[0].ID, label: getMyself[0].Username}, JinNanCheck: getMyself[0].JinNan, SearchUser: getMyself[0].Username, getUserFormula: getMyself[0].XingJinFormula }, () => {
        this.getTargetUserKeyIn(this.state.userID);
      })
    }
  }

  // async SelectedUsername(e, users) {
  //   this.setState({ UserID: e.target.value}, () => {
  //     this.getTargetUserKeyIn(this.state.UserID)
  //   })
  // }

  SelectedUser(e) {
    this.setState({ UserID: e.value, SelectedUser: e, SearchUser: e.label}, () => {
      this.getTargetUserKeyIn(this.state.UserID);
      this.handleCloseSearchModal();
    })
  }

  async getTargetUserKeyIn(TargetUserID) {
    const UserKeyIn = await getUserKeyIn(TargetUserID, this.props.token, this.props.username)
    if(UserKeyIn) {
      ['MG', 'KD', 'TT', 'SG', 'SW', 'SBH', 'STC', '8', 'NINE'].forEach((item) => {
        this.setState({ PlatformSelect: [], [item]: false})
      })
      // BoxPlatform: UserKeyIn[0].GameBetting.split('')
      // const TestingPlat = '129456783'
      this.setState({ KeyinFunctions: UserKeyIn[0] || [], MultiplyDivide: UserKeyIn[0].MultiplyDivide, JinNanCheck: UserKeyIn[0].JinNan, OneStar: UserKeyIn[0].OneStarBao, BoxPlatform: UserKeyIn[0].GameBetting.split(''), Balance: UserKeyIn[0].CashWallet, getUserFormula: UserKeyIn[0].XingJinFormula, }, () => {
        const KeyInResult = this.state.KeyinFunctions;
        const BtKey1Formula = KeyInResult.KeyIn1 || [];
        const BtKey2Formula = KeyInResult.KeyIn2 || [];
        const BtKey1FormulaLength = BtKey1Formula.length;
        var Key1Box = [];
        var Key2Box = [];
        // let PFLIne = {
        //   '1': 'M',
        //   '2': (this.state.GamesDisplay === 'MKTSWBD') ? 'K' : 'P',
        //   '3': 'T',
        //   '4': 'S',
        //   '5': 'W',
        //   '6': 'B',
        //   '7': (this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K',
        //   '8': '8',
        // }
        // this.setState({ PFLIne })
        for (let i = 0; i < BtKey1FormulaLength; i++) {
          Key1Box.push((`(IB)${BtKey1Formula[i]}`).slice(0))
          Key2Box.push((`(IB)${BtKey2Formula[i]}`).slice(0))
        }
        // if(BtKey1Formula.includes('CA1') && BtKey1Formula.includes('CC')) {
        //   const Key3Formula = BtKey1Formula.filter(function(item) {
        //     return ['CA1', 'CC'].includes(item); 
        //   })
        //   this.setState({Key1IBox: Key1Box || [], Key2IBox: Key2Box || [], key3D: Key3Formula, key2D: []})
        // } else {
        let Key3Formula = ''
        let keyInFormula = BtKey1Formula.filter((item) => item === 'A' || item === 'C')
        if (keyInFormula.length === 0) {
          Key3Formula = ['A', 'C']
        } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
          Key3Formula = ['A']
        } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
          Key3Formula = ['C']
        } else {
          Key3Formula = ['A', 'C']
        }
        // Key3Formula = ['A', 'C']
        const Key2Formula = ['PA', 'PF']
        this.setState({Key1IBox: Key1Box || [], Key2IBox: Key2Box || [], key3D: Key3Formula, key2D: Key2Formula })
        // }
        this.updateWordsValuebySelectedUser(this.state.words);
        this.ConvertToOriginalWord(this.state.words)
      });
    }
  }

  async updateWordsValuebySelectedUser(words) {
    const KeyinFormat = this.state.KeyinFunctions
    const Key1Input = KeyinFormat.KeyIn1
    const Key2Input = KeyinFormat.KeyIn2
    let Key3Formula = ''
    let keyInFormula = Key1Input.filter((item) => item === 'A' || item === 'C')
    if (keyInFormula.length === 0) {
      Key3Formula = ['A', 'C']
    } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
      Key3Formula = ['A']
    } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
      Key3Formula = ['C']
    } else {
      Key3Formula = ['A', 'C']
    }
    const MultiplyDivide = this.state.MultiplyDivide

    // if(Key1Input.includes('CA1') && Key1Input.includes('CC')) {
    //   Key3Formula = Key1Input.filter(function(item) {
    //     return ['CA1', 'CC'].includes(item); 
    //   })
    // } else {
    //   Key3Formula = ['CA1','CC']
    // }

    if(words.length !== 0) {
      for(var i=0; i<words.length; i++) {
        const tempObject = {}
        let FormatChange = []
        if(words[i].Key2 === false && words[i].Word.length === 4 && words[i].Mode !== '*#') {
          const OldFormat = words[i].CurrentFormat
          const Bet = words[i].Bet
          for(let j = 0; j < OldFormat.length; j++) {
            if(words[OldFormat[j]] !== '') {
              tempObject[Key1Input[j]] = Bet[OldFormat[j]]
              Bet[OldFormat[j]] = ''
              FormatChange = Key1Input
            } else {
              tempObject[OldFormat[j]] = Bet[OldFormat[j]]
            }
          }
          words[i].Bet = JSON.parse(JSON.stringify(tempObject))
          words[i].CurrentFormat = JSON.parse(JSON.stringify(FormatChange))
          if(words[i].Divide !== MultiplyDivide) {
            if(MultiplyDivide === 'D') {
              words[i].Divide = "D"
              words[i].TotalAmount = words[i].TotalAmount / this.state.PlatformLength
            } else {
              words[i].Divide = "M"
              words[i].TotalAmount = words[i].TotalAmount * this.state.PlatformLength
            }
          }
        } 
        //Formula 1
        else if (words[i].Mode === '*#' && words[i].Word.length === 4) {
          // ignore
        } 
        else if(words[i].Key2 === true && words[i].Word.length === 4 && words[i].Mode !== '*#') {
          const OldFormat = words[i].CurrentFormat
          const Bet = words[i].Bet
          for(let j = 0; j < OldFormat.length; j++) {
            if(words[OldFormat[j]] !== '') {
              tempObject[Key2Input[j]] = Bet[OldFormat[j]]
              Bet[OldFormat[j]] = ''
              FormatChange = Key2Input
            } else {
              tempObject[OldFormat[j]] = Bet[OldFormat[j]]
            }
          }
          words[i].Bet = JSON.parse(JSON.stringify(tempObject))
          words[i].CurrentFormat = JSON.parse(JSON.stringify(FormatChange))
          if(words[i].Divide !== MultiplyDivide) {
            if(MultiplyDivide === 'D') {
              words[i].Divide = "D"
              words[i].TotalAmount = words[i].TotalAmount / this.state.PlatformLength
            } else {
              words[i].Divide = "M"
              words[i].TotalAmount = words[i].TotalAmount * this.state.PlatformLength
            }
          }
        } else if(words[i].Key2 === false && words[i].Word.length === 3) {
          const OldFormat = words[i].CurrentFormat
          const Bet = words[i].Bet
          for(let j = 0; j < OldFormat.length; j++) {
            if(words[OldFormat[j]] !== '') {
              tempObject[Key3Formula[j]] = Bet[OldFormat[j]]
              Bet[OldFormat[j]] = ''
              FormatChange = Key3Formula
            } else {
              tempObject[OldFormat[j]] = Bet[OldFormat[j]]
            }
          }
          words[i].Bet = JSON.parse(JSON.stringify(tempObject))
          words[i].CurrentFormat = JSON.parse(JSON.stringify(FormatChange))
          if(words[i].Divide !== MultiplyDivide) {
            if(MultiplyDivide === 'D') {
              words[i].Divide = "D"
              words[i].TotalAmount = words[i].TotalAmount / this.state.PlatformLength
            } else {
              words[i].Divide = "M"
              words[i].TotalAmount = words[i].TotalAmount * this.state.PlatformLength
            }
          }
        } else if(words[i].Key2 === false && words[i].Word.includes('-') && words[i].Word.length > 4) {
          const OldFormat = words[i].CurrentFormat
          const Bet = words[i].Bet
          for(let j = 0; j < OldFormat.length; j++) {
            if(words[OldFormat[j]] !== '') {
              tempObject[Key1Input[j]] = Bet[OldFormat[j]]
              Bet[OldFormat[j]] = ''
              FormatChange = Key1Input
            } else {
              tempObject[OldFormat[j]] = Bet[OldFormat[j]]
            }
          }
          words[i].Bet = JSON.parse(JSON.stringify(tempObject))
          words[i].CurrentFormat = JSON.parse(JSON.stringify(FormatChange))
          if(words[i].Divide !== MultiplyDivide) {
            if(MultiplyDivide === 'D') {
              words[i].Divide = "D"
              words[i].TotalAmount = words[i].TotalAmount / this.state.PlatformLength
            } else {
              words[i].Divide = "M"
              words[i].TotalAmount = words[i].TotalAmount * this.state.PlatformLength
            }
          }
        } else if(words[i].Key2 === true && words[i].Word.includes('-') && words[i].Word.length > 4) {
          const OldFormat = words[i].CurrentFormat
          const Bet = words[i].Bet
          for(let j = 0; j < OldFormat.length; j++) {
            if(words[OldFormat[j]] !== '') {
              tempObject[Key2Input[j]] = Bet[OldFormat[j]]
              Bet[OldFormat[j]] = ''
              FormatChange = Key2Input
            } else {
              tempObject[OldFormat[j]] = Bet[OldFormat[j]]
            }
          }
          words[i].Bet = JSON.parse(JSON.stringify(tempObject))
          words[i].CurrentFormat = JSON.parse(JSON.stringify(FormatChange))
          if(words[i].Divide !== MultiplyDivide) {
            if(MultiplyDivide === 'D') {
              words[i].Divide = "D"
              words[i].TotalAmount = words[i].TotalAmount / this.state.PlatformLength
            } else {
              words[i].Divide = "M"
              words[i].TotalAmount = words[i].TotalAmount * this.state.PlatformLength
            }
          }
        }
      }
    }
    this.TotalBetAmount();
  }

  async startPage() {
    const DateLoop = this.GetModalDate();
    this.NumberInput.focus();

    const GDisplay = localStorage.getItem('GameDisplay');

    this.setState({
      username: this.props.username, GamesDisplay: GDisplay,
    })
    const KeyinFunctions = await getKeyInFormat(
      this.state.username,
      this.state.token,
    );
    if (KeyinFunctions) {
      this.setState({ KeyinFunctions: KeyinFunctions || [], ModalDate: DateLoop || [], MultiplyDivide: KeyinFunctions.MultiplyDivide, Balance: KeyinFunctions.CashWallet, getUserFormula: KeyinFunctions.XingJinFormula }, () => {
        const KeyInResult = this.state.KeyinFunctions;
        const BtKey1Formula = KeyInResult.KeyIn1 || [];
        const BtKey2Formula = KeyInResult.KeyIn2 || [];
        const BtKey1FormulaLength = BtKey1Formula.length;
        var Key1Box = [];
        var Key2Box = [];
        for (let i = 0; i < BtKey1FormulaLength; i++) {
          Key1Box.push((`(IB)${BtKey1Formula[i]}`).slice(0))
          Key2Box.push((`(IB)${BtKey2Formula[i]}`).slice(0))
        }
        let Key3Formula = ''
        let keyInFormula = BtKey1Formula.filter((item) => item === 'A' || item === 'C')
        if (keyInFormula.length === 0) {
          Key3Formula = ['A', 'C']
        } else if (keyInFormula.includes('A') && !keyInFormula.includes('C')) {
          Key3Formula = ['A']
        } else if (!keyInFormula.includes('A') && keyInFormula.includes('C')) {
          Key3Formula = ['C']
        } else {
          Key3Formula = ['A', 'C']
        }

        const Key2Formula = ['PA', 'PF']

        this.setState({Key1IBox: Key1Box || [], Key2IBox: Key2Box || [], key3D: Key3Formula, key2D: Key2Formula})
      });
    }
  }

  GetModalDate() {
    const DateLoop = [];
    const TodayDate = Moment().format('YYMMDD');
    if(Moment().day() === 0) {
      DateLoop.push({
        Date: Moment().format('DDMM(ddd)'),
        Number: 7,
        CheckMark: false,
      });
    } else {
      if (Moment().format('HH:mm') >= '19:15') {
        for (let i = 1; i < 8; i++) {
          // const Date = Moment().day(i);
          const Date = Moment(Moment()).add(i, 'days');
          if (Moment(Date).format('YYMMDD') >= TodayDate) {
            DateLoop.push({
              Date: Moment(Date).format('DDMM(ddd)'),
              Number: Moment(Date).isoWeekday(),
              CheckMark: false,
            });
          }
        }
      } else {
        for (let i = 1; i < 8; i++) {
          const Date = Moment().day(i);
          if (Moment(Date).format('YYMMDD') >= TodayDate) {
            DateLoop.push({
              Date: Moment(Date).format('DDMM(ddd)'),
              Number: Moment(Date).isoWeekday(),
              CheckMark: false,
            });
          }
        }
      }
    }
    return { DateLoop };
  }

  async submitNumber(type) {
    const num = this.state.Text;
    const inputnum = num.replace(/[^0-9.]/g, '');
    const Inputnumsplit = inputnum.split('');
    const InputnumLength = Inputnumsplit.length
    if (this.state.platforms.length === 0) {
      this.openNotification('PleaseSelectGame')
      this.setState({ notificationType: 'error' });
    } else if (this.state.Text === '') {
      this.openNotification('pleaseinputyourgames')
      this.setState({ notificationType: 'error' }, ()=> {
        this.NumberInput.focus();
      });
    } else if (InputnumLength < 2) {
      this.openNotification('minimum4Word')
      this.setState({ notificationType: 'error' }, ()=> {
        this.NumberInput.focus();
      });
    } else if(num.includes('-')) {
      let StartNum = num.split('-')[0];
      let ToNum = num.substring(num.indexOf('-') + 1);

      if(StartNum > ToNum) {
        this.openNotification('WrongFormat')
        this.setState({ notificationType: 'error' }, ()=> {
          this.NumberInput.focus();
        });
      } else if(StartNum.split('').length !== 4) {
        this.openNotification('FormatError')
        this.setState({ notificationType: 'error' }, ()=> {
          this.NumberInput.focus();
        });
      } else if(ToNum.split('').length !== 4) {
        this.openNotification('FormatError')
        this.setState({ notificationType: 'error' }, ()=> {
          this.NumberInput.focus();
        });
      } else {
        //SelectedExample: this.state.KeyinFunctions.KeyIn1
        this.setState({ Mode: '-' }, () => {
          this.AddToWord();
        })
      }
    } else if (InputnumLength === 2) {
      if (this.state.PA === '' && this.state.PB === '' && this.state.PC === '' && this.state.PE === '' && this.state.PF === '' && this.state.PD === '') {
        this.openNotification('KeyinAmountError')
        this.setState({ notificationType: 'error' });
      } else {
        this.AddToWord();
      }
    } else if (InputnumLength === 3) {
      if (this.state.CA === '' && this.state.CD === '' && this.state.CB === '' && this.state.CC === '' && this.state.CE === '' && this.state.CF === '' && this.state.A === '' && this.state.C === '') {
        this.openNotification('KeyinAmountError')
        this.setState({ notificationType: 'error' });
      } else {
        this.AddToWord();
      }
    } else if (InputnumLength === 5) {
      if (this.state.fiveD === '') {
        this.openNotification('KeyinAmountError')
        this.setState({ notificationType: 'error' });
      } else {
        this.setState({  Mode: this.state.Mode === 'Box' ? 'Box' : '-', Key2: false, SelectedExample: this.state.KeyinFunctions.KeyIn1}, () => {
          this.AddToWord();
        })
      }
    } else if (InputnumLength === 6)  {
      if (this.state.SixD === '') {
        this.openNotification('KeyinAmountError')
        this.setState({ notificationType: 'error' });
      } else {
        this.setState({ Mode: this.state.Mode === 'Box' ? 'Box' : '-', Key2: false, SelectedExample: this.state.KeyinFunctions.KeyIn1}, () => {
          this.AddToWord();
        })
      }
    } else if(!num.includes('-') && InputnumLength > 6) {
      this.openNotification('FormatError')
      this.setState({ notificationType: 'error' }, ()=> {
        this.NumberInput.focus();
      });
    } else if (
      this.state.B === ''
            && this.state.S === ''
            && this.state.CD === ''
            && this.state.SD === ''
            && this.state.SA === ''
            && this.state.SB === ''
            && this.state.SC === ''
            && this.state.SE === ''
            && this.state.SF === ''
            && this.state.CA === ''
            && this.state.fiveD === ''
            && this.state.sixD === ''
            && this.state.threeD === ''
            && this.state.format === ''
            && this.state.PA === ''
            && this.state.PB === '' 
            && this.state.PC === '' 
            && this.state.PD === '' 
            && this.state.PE === '' 
            && this.state.PF === ''
            && this.state.CB === ''
            && this.state.CC === ''
            && this.state.CE === '' 
            && this.state.CF === ''
            && this.state.A === ''
            && this.state.C === '') {
      this.openNotification('KeyinAmountError')
      this.setState({ notificationType: 'error' });
    } else {
      this.AddToWord();
    }
  }

  async AddToWord() {
    const {
      Text,
      B,
      S,
      words,
      CD, CA, SA, SB, SC, SD, SE, SF,
      fiveD, sixD, threeD, Mode, format,
      PA, PB, PC, PD, PE, A, C,
      PF, CB, CC, CE, CF, selectedDate,
      insertPlatform,

    } = this.state;
    //Setstate to database
    let StartNum = Text.split('-')[0];
    let ToNum = Text.substring(Text.indexOf('-') + 1);
    let WordQuantity = 0;
    let Mode9 = '';
    if (
      StartNum.length === 4 &&
                  ToNum.length === 4
    ) {
      WordQuantity = Math.abs(StartNum - ToNum) + 1;
      Mode9 = '{1234}*(' + WordQuantity + ')';
    } else if (
      StartNum.length === 4 &&
                  ToNum.length === 4
    ) {
      WordQuantity = Math.abs(StartNum - ToNum) + 1;
      Mode9 = '{1234}*(' + WordQuantity + ')';
    }

    // if(Mode === '*#') {
    //   this.FormulaFunction();
    // } else {
      
    // }
    this.state.ArrayforSum.push(B || '0', S || '0', CD || '0', CA || '0', SA || '0', SD || '0', SB || '0', SC || '0', A || '0', C || '0',
      SE || '0', SF || '0', fiveD || '0', sixD || '0', threeD || '0', format || '0', PA || '0', PB || '0', PC || '0', PD || '0', 
      PE || '0', PF || '0', CB || '0', CC || '0', CE || '0', CF || '0')
    const BetAmount = (this.state.MultiplyDivide === 'D') ? this.state.ArrayforSum.reduce(function (a, b) { return Number(a) + Number(b); }, 0)
      : this.state.ArrayforSum.reduce(function (a, b) { return Number(a) + Number(b); }, 0) * this.state.PlatformLength;
    const InputNumber = Text.replace(/[^0-9]/g, '');
    // const InputNumbersplit = InputNumber.split('');
    // const InputNumberLength = InputNumbersplit.length
    // Box Permutation
    const Permutation = findPermutations(InputNumber);
    const permutationLength = Permutation.length
    // {123}0-9 calculation
    const Numeber = InputNumber.slice(0, -1)
    const PermutationBox = findPermutations(Numeber);
    const PermutationBoxLength = PermutationBox.length
    // 1{234} calculation
    const NumeberBox2 = InputNumber.slice(1)
    const PermutationBox2 = findPermutations(NumeberBox2);
    const PermutationBox2Length = PermutationBox2.length

    // calculate Amount
    const Amount = (Mode === 'Box') ? BetAmount * permutationLength 
      : (Mode === '{0-9}123' || Mode === '123{0-9}') ? BetAmount * 10 
        : (Mode === '{123}4') ? BetAmount * PermutationBoxLength 
          : (Mode === '1{234}') ? BetAmount * PermutationBox2Length 
            : (Mode === '7*') ? BetAmount * 100 : (Text.includes('-')) ? (BetAmount * WordQuantity): BetAmount;
  
    const object = {}
    const KeyInResult = this.state.KeyinFunctions
    const CurrentFormat = (this.state.Key2 === true) ? KeyInResult.KeyIn2 || '' :
      (this.state.FiveD === true) ? ['fiveD'] : (this.state.SixD === true) ? ['sixD'] :
        (this.state.ThreeD === true) ? this.state.key3D : (this.state.TwoD === true) ? this.state.key2D : KeyInResult.KeyIn1 || '';
    const CurrentFormatLength = CurrentFormat.length;
    for (let i = 0; i < CurrentFormatLength; i++) {
      object[CurrentFormat[i]] =  ''
    }
    // const Original = (InputNumberLength === 4) ? `${InputNumber}${B!=='' ? `#${B}`: ``}#${S}#${C}#${A}${SA!=='' ? `#${SA}`: ``}${SD!=='' ? `#${SD}`: ``}` : '';
    this.setState({
      words: [
        ...words,
        {
          Word: Text,
          Platform: insertPlatform,
          SelectedDate: (selectedDate[0] === undefined) ? 'D#' : selectedDate[0] ,
          Mode: (Text.includes('-')) ? Mode9 : Mode || '-',
          Key2: (this.state.Key2 === true ? true : false),
          Bet: {
            B: (Number(B) !== 0) ? Number(B) : '',
            S: (Number(S) !== 0) ? Number(S) : '',
            A: (Number(A) !== 0) ? Number(A) : '',
            C: (Number(C) !== 0) ? Number(C) : '',
            CD: (Number(CD) !== 0) ? Number(CD) : '',
            CA: (Number(CA) !== 0) ? Number(CA) : '',
            SA: (Number(SA) !== 0) ? Number(SA) : '',
            SB: (Number(SB) !== 0) ? Number(SB) : '',
            SC: (Number(SC) !== 0) ? Number(SC) : '',
            SD: (Number(SD) !== 0) ? Number(SD) : '',
            SE: (Number(SE) !== 0) ? Number(SE) : '',
            SF: (Number(SF) !== 0) ? Number(SF) : '',
            PA: (Number(PA) !== 0) ? Number(PA) : '',
            PB: (Number(PB) !== 0) ? Number(PB) : '',
            PC: (Number(PC) !== 0) ? Number(PC) : '',
            PD: (Number(PD) !== 0) ? Number(PD) : '',
            PE: (Number(PE) !== 0) ? Number(PE) : '',
            PF: (Number(PF) !== 0) ? Number(PF) : '',
            CB: (Number(CB) !== 0) ? Number(CB) : '',
            CC: (Number(CC) !== 0) ? Number(CC) : '',
            CE: (Number(CE) !== 0) ? Number(CE) : '',
            CF: (Number(CF) !== 0) ? Number(CF) : '',
            '5D': (Number(fiveD) !== 0) ? Number(fiveD) : '',
            '6D': (Number(sixD) !== 0) ? Number(sixD) : '',
            'format' : (Number(format) !== 0) ? Number(format) : '',
          },
          TotalAmount: (Mode === '*#') ? Number(0) : Amount,
          originalWord: '',
          CurrentFormat: CurrentFormat,
          Divide: this.state.MultiplyDivide,
        },
      ],
    }, () => {
      this.ConvertToOriginalWord(this.state.words);
      this.NumberInput.focus();
      this.TotalBetAmount();
    })
  }

  async ConvertToOriginalWord(BetNumbers) {
    if(BetNumbers.length > 0) {
      const DataDate = BetNumbers[0].SelectedDate || 'D#';
      const OriginalWord = [];
      const BetNumberslength = BetNumbers.length;

      const FinalDate = (!(OriginalWord).length === true ? DataDate + '\n' : '')
      if(!(OriginalWord).length === true) {
        OriginalWord.push(FinalDate)
      }

      for(let i=0; i<BetNumberslength; i++) {
        if(BetNumbers[i].Text !== '') {
          const Mode = BetNumbers[i].Mode
          const Text = BetNumbers[i].Word
          const Key2 = BetNumbers[i].Key2;
          const Platform = BetNumbers[i].Platform
          const PrevPlatform = BetNumbers[i - 1 < 0 ? 0 : i - 1].Platform || []
          // const BetKeyValueKeys = Object.values(BetNumbers[i].Bet)
          const Wordsplit = Text.split('')
          const key2Dnew = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'] 
          const key3Dnew = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF']
          const Key2result = (Key2 === true && Wordsplit.length !== 3 && Wordsplit.length !== 2) ? this.state.KeyinFunctions.KeyIn2 : (Wordsplit.length === 3 && Key2 === true) ? key3Dnew : (Wordsplit.length === 2 && Key2 === true) ? key2Dnew
            : (Wordsplit.length === 3 && Key2 === false) ? this.state.key3D : (Wordsplit.length === 2 && Key2 === false) ? this.state.key2D 
              : (Wordsplit.length === 5) ? ['5D'] : (Wordsplit.length === 6) ? ['6D'] : (Mode === '*#' && Wordsplit.length === 4) ? ['format'] :  this.state.KeyinFunctions.KeyIn1
          const BetKeyValueKeys = Key2result.map((Alpha) => { return BetNumbers[i].Bet[Alpha] })
          const Star = this.state.OneStar

          const TextModeFilter = (Mode === 'Box' ? ((Star === 1) ? '*' : '**'): Mode === '{0-9}123' ? '#' : Mode === '123{0-9}' ? '##' 
            : Mode === 'IBox' ? ((Star === 1) ? '**' : '*') : '') 
                    + (Mode === '1{234}' ? `${Text.substring(0,1)}*${Text.substring(1,5)}`
                      : Mode === '{123}4' ? ((Text.split('').length === 3) ? `${Text.substring(0,2)}*${Text.substring(2,4)}` 
                        : `${Text.substring(0,3)}*${Text.substring(3,5)}`): Text)
                    + (BetNumbers[i].Key2 === true ? `**` : '')

          // Generate Bet KeyValues with Originalword Format
          const KeyBetValues = (BetKeyValueKeys[1] === '' && BetKeyValueKeys[2] === '' && BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ? 
            '#' + BetKeyValueKeys[0] : ( BetKeyValueKeys[2] === '' && BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ?
              '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] : ( BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ? 
                '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2]  : (BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ?
                  '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3] : ( BetKeyValueKeys[5] === '') ?
                    '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3]+ '#' + BetKeyValueKeys[4] :
                    '#' + BetKeyValueKeys[0] + '#'  + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3] + '#' + BetKeyValueKeys[4] + '#' + BetKeyValueKeys[5]

          const KeyBetValues3 = '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1]
          const KeyBetValues5 = '#' + BetKeyValueKeys[0]
          const KeyBetFormat = '*#' + BetKeyValueKeys[0]

          // Push into OriginalWord Array
          if(this.state.JinNanCheck === 1) {
            OriginalWord.push(
              `${(OriginalWord.length === 1 || Platform !== PrevPlatform) 
                ? '#' + Platform + '\n'
                : ''}`)
          } else {
            OriginalWord.push(
              `${(OriginalWord.length === 1 || Platform !== PrevPlatform) 
                ? Platform + '\n'
                : ''}`)
          }
    
          // Push into OriginalWord Array
          OriginalWord.push(`${TextModeFilter}${((Wordsplit.length === 4 && Mode !== '*#') || (Wordsplit.length === 9 && Mode !== '*#')) ? KeyBetValues : ((Wordsplit.length === 3 || Wordsplit.length === 2) && Mode !== '*#' && Key2 === false) ? KeyBetValues3 : (Wordsplit.length === 2 && Mode !== '*#' && Key2 === true) ? KeyBetValues : (Wordsplit.length === 3 && Mode !== '*#' && Key2 === true) ? KeyBetValues : (Wordsplit.length === 4 && Mode === '*#') ? KeyBetFormat :  KeyBetValues5}\n`)
        }
      }
      this.ClearGames();
      this.setState({  Text: '', format: '', OriginalWords: OriginalWord.join('') })
    } else {
      //ignore
    }
  }

  // FormulaFunction() {
  //   this.setState({ B: 1, S: 1, Mode: '', format: ''}, () => {
  //     this.AddToWord()
  //   })
  // }

  async TotalBetAmount() {
    const ArrayAmount = this.state.words;
    const TotalSumAmount = ArrayAmount.reduce((total, currentValue) => total = total + currentValue.TotalAmount, 0);
    this.setState({ ResultAmount: (TotalSumAmount).toFixed(2) * this.state.Datelength, ArrayforSum: [] })
  }

  async selectGames( Text ) {
    const { Platform } = this.state;
    if (/^[0-9]+$/.test(Text) || Text === '') {
      this.setState({
        Platform: [
          ...Platform,
          {
            Pform: Text.toString().split('')
          }
        ]
      }, () => {
        const PlatformSelect = this.state.PlatformSelect
        const FilterPF = PlatformSelect.filter(function (item, index) {
          return PlatformSelect.indexOf(item) === index;
        });
        this.setState({
          platforms: FilterPF || '', 
        }, () => {
          const PFMS = this.state.platforms.join('');
          var mapObj = {
            1: "M",
            2: (this.state.GamesDisplay === 'MKTSWBD') ? "K" : "P",
            3: "T",
            4: "S",
            5: "W",
            6: "B",
            7: (this.state.GamesDisplay === 'MKTSWBD') ? "D" : "K",
            8: "8",
            9: '9',
          };
          const str = PFMS.replace(/1|2|3|4|5|6|7|8|9/gi, function (matched) {
            return mapObj[matched];
          });
          const ConvertedPlatforms = str.split('');
          // this.state.SelectedPlatform.push(ConvertedPlatforms);
          this.setState({ SelectedPlatform: ConvertedPlatforms, insertPlatform: PFMS })
        })
        const lengthPF = FilterPF.length;
        this.setState({ PlatformLength: lengthPF })
        this.NumberInput.focus();
      })
    } else {
      alert(`‘${Text}’ is not on the platform Games list.`);
    }
  }

  CheckhandleInputDateChange(event, item, indexPlat){
    this.setState({[item]: event.target.checked}, ()=>{
      this.PlatformSelectFunction(indexPlat);
    })
  }

  toggleResult(platform, indexPlat) {
    if (this.state[platform] === false) {
      this.setState({
        [platform]: true,
      }, () => {
        this.PlatformSelectFunction(indexPlat);
      })
    }else {
      this.setState({ [platform] : false}, () => {
        this.PlatformSelectFunction(indexPlat);
      })
    }
  }

  async PlatformSelectFunction(indexPlat) {
    let PlatformSelect = this.state.PlatformSelect
    if (!PlatformSelect.includes(indexPlat.toString())) {
      PlatformSelect.push(indexPlat.toString())
    } else {
      PlatformSelect = PlatformSelect.filter(function (el) {
        return el !== indexPlat.toString()
      });
    }
    this.setState({ PlatformSelect })
    
    const MG = (this.state.MG === true) ? 1 : '';
    const KD = (this.state.KD === true) ? 2 : '';
    const TT = (this.state.TT === true) ? 3 : '';
    const SG = (this.state.SG === true) ? 4 : '';
    const SW = (this.state.SW === true) ? 5 : '';
    const SBH = (this.state.SBH === true) ? 6 : '';
    const STC = (this.state.STC === true) ? 7 : '';
    const Eight = (this.state[8] === true) ? 8 : '';
    const Nine = (this.state.NINE === true) ? 9 : '';

    const platformsNumber = MG + ',' + KD + ',' + TT + ',' + SG + ',' + SW + ',' + SBH + ',' + STC + ',' + Eight + ',' + Nine;
    const FinalformsNumber =  platformsNumber.replace(/([,])/g, '')
    this.selectGames(FinalformsNumber)
  }

  SelectedOption(event) {
    if (event.target.value === 'KeyIn2') {
      this.ClearGames();
      this.setState({ Key2: true, format: ''})
    } else {
      this.ClearGames();
      this.setState({ Key2: false, format: ''})
    }
    this.setState({ SelectedExample: event.target.value });
  }

  async DeletedNumber(Number, indexs) {
    let Words = this.state.words

    const r = (window.confirm(`${language[this.props.currentLanguage].Areyousureyouwanttoremovethisbet} ?`));
    if (r === true) {
      var people = this.state.words;
      var toRemove = Number;
      var index = people.indexOf(toRemove);
      if (index > -1) {
        people.splice(index, 1);
      }
      this.TotalBetAmount();
      this.ClearGames();
      this.setState({Text: '', format: ''}, ()=> {
        if(Words.length > 0) {
          this.ConvertToOriginalWord(this.state.words);
        } else {
          // ignore
        }
      })
    } else {
      return null;
    }
  }

  handleInputDateChange(event) {
    const target = event.target;
    var value = target.value;
    const name = target.name;
    const { selectedDate } = this.state;

    if (target.checked) {
      this.setState({
        selectedDate: [
          ...selectedDate,
          value
        ],
        [name]: value
      })
    } else {
      this.setState({ [name]: false })
      const toRemove = value;
      const index = this.state.selectedDate.indexOf(toRemove);
      if (index > -1) {
        this.state.selectedDate.splice(index, 1);
      }
    }
    if (target.checked === false) {
      this.setState({ DDate: `${language[this.props.currentLanguage].ChooseDate} (D#)` })
    }
  }

    handleSunday = (e) => {
      if (e.target.checked) {
        const { selectedDate } = this.state;
        const Sun = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7)).toUTCString();
        const Sunday = Moment(Sun).format('YYYYMMDD');
        // this.setState({ Sun : Moment(Sun).format('YYYYMMDD')  })
        this.setState({
          selectedDate: [
            ...selectedDate,
            Sunday
          ]
        })
      }
    }

    async OpenDateModal() {
      this.GetModalDate();
      this.setState({ modalShow: true })
    }

    async onKeyEnter(event, field) {
      if (event.key === '+') {
        event.preventDefault();
        if (field === 'field2') {
          this.field2.focus();
        } else if (field === 'field3') {
          this.field3.focus();
        } else if (field === 'field4') {
          this.field4.focus();
        } else if (field === 'field5') {
          this.field5.focus();
        } else if (field === 'field6') {
          this.field6.focus();
        } else {
          this.field1.focus();
        }
      }
      if (event.key === 'Enter') {
        this.submitNumber();
      }
    }

    handleKeyPress = (event) => {
      if (event.key === '+') {
        event.preventDefault();
        this.NumberInput3.focus();
      }
    }

    handleKeyPressNumberInput = (event) => {
      // const { name } = event.target;
      // const [fieldIndex] = name.split("-");

      // let fieldIntIndex = parseInt(fieldIndex, 10);
      if (event.key === '+') {
        event.preventDefault();
        if (this.state.platforms.length === 0) {
          this.openNotification('PleaseSelectGame')
          this.setState({ notificationType: 'error' });
        } else if (this.state.Text === '') {
          this.openNotification('pleaseinputyourgames')
          this.setState({ notificationType: 'error' });
        } else {
          this.field1.focus();
        }
      }
      if (event.key === 'Enter') {
        this.submitNumber();
      }
    }

    handleChangeGameOrNumber(value, field) {
      const valuesplit = value.split('');
      const valuelength = valuesplit.length;
      const DetectSymbolnegative = value.match(/-/g)
      if ((DetectSymbolnegative || 0).length > 1){
        this.openNotification('FormatError')
        this.setState({ notificationType: 'error', Text: '' });
      } else {
        if (/^[0-9-]+$/.test(value) || value === '') {
          
          if (valuelength === 2) {
            this.setState({ TwoD: true, ThreeD: false, FiveD: false, SixD: false, [field]: value, FormatFull: false })
            this.ClearGames();
          } else if (valuelength === 3) {
            this.setState({ ThreeD: true, FiveD: false, SixD: false, TwoD: false, [field]: value, FormatFull: false })
            this.ClearGames();
          } else if (valuelength === 4) {
            this.setState({ ThreeD: false, FiveD: false, SixD: false, TwoD: false, [field]: value,
              Key2: (this.state.SelectedExample === 'KeyIn2') ? true : false, FormatFull: false})
            this.ClearGames();
          } else if (valuelength === 5) {
            this.setState({ ThreeD: false, FiveD: true, SixD: false, TwoD: false, [field]: value, Key2: false, FormatFull: false })
            this.ClearGames();
          } else if (valuelength === 6) {
            this.setState({ ThreeD: false, FiveD: false, SixD: true, TwoD: false, [field]: value, Key2: false, FormatFull: false })
            this.ClearGames();
          } else if (valuelength === 9 && valuesplit.includes('-')) {
            this.setState({ ThreeD: false, FiveD: false, SixD: false, TwoD: false, [field]: value,
              Key2: (this.state.SelectedExample === 'KeyIn2') ? true : false, FormatFull: true })
          } else {
            this.setState({ ThreeD: false, FiveD: false, SixD: false, TwoD: false, [field]: value, FormatFull: false })
          }
        }
      }

      const MG = (this.state.MG === true) ? true : '';
      const KD = (this.state.KD === true) ? true : '';
      const TT = (this.state.TT === true) ? true : '';
      const SG = (this.state.SG === true) ? true : '';
      const SW = (this.state.SW === true) ? true : '';
      const SBH = (this.state.SBH === true) ? true : '';
      const STC = (this.state.STC === true) ? true : '';
      const Eight = (this.state[8] === true) ? true : '';
      const Nine = (this.state.NINE === true) ? true : '';

      if ( (MG || KD || TT || Eight || SG || SW || SBH || STC || Nine) !== true) {
        this.openNotification('PleaseSelectGame')
        this.setState({ notificationType: 'error', Text: ''});
      }
    }

    handleChangeNum(e, value, field) {
      const num = (value).replace(/[^0-9.*]/g, '');
      // if (/^[0-9.*]+$/.test(value) || value === '') {
      //   var Replacevalue = value.replace(/[*]/g, '.');
      //   var inputstr = Replacevalue.replace(/\./,"#").replace(/\./g,"").replace(/#/,".")
      if (num || num === '') {
        var Replacevalue = num.replace(/[*]/g, '.');
        var inputstr = Replacevalue.replace(/\./,"#").replace(/\./g,"").replace(/#/,".")
        this.setState({ [field]: inputstr });
      } else {
        this.openNotification('NumberOnly')
        this.setState({ notificationType: 'error'});
      }
    }

    handleChangeResultNumber(event) {
      this.setState({ ResultNumber: event.target.ResultNumber });
    }

    async SenddataToApi() {
      this.setState({loading: true});
      const Oriword = this.state.OriginalWords;
      const username = this.state.username;
      const UserID = this.state.UserID;
      const token = this.state.token;
      if (Oriword === '') {
        this.openNotification('EmptyBet')
        this.setState({ notificationType: 'error', loading: false });
      } 
      else {
        // console.log('Oriword', Oriword)
        const toProcessDateData = this.state.ModalDate;

        for(var j = 0; j < toProcessDateData.DateLoop.length; j++) {
          if(toProcessDateData.DateLoop[j].CheckMark === true) {
            toProcessDateData.DateLoop[j].CheckMark = false
          }
        }
        const CreateBet = await CreateBetOrder(UserID, Oriword, token, username)
        if(CreateBet.error) {
          this.openNotification(ReturnBetValidation(CreateBet.error), (CreateBet.balance !== undefined) ? CreateBet.balance : '')
          this.setState({ notificationType: 'error', loading: false });
        } else {
          clearTimeout(timer)
          timer = setTimeout(() => {
            this.SelectedUser(this.state.SelectedUser, '')
          }, 1000);
          this.setState({
            ModalDate: toProcessDateData,
            SuccessFullCancel: false,
            Receipt: CreateBet.receipt,
            ShowReceipt: true,
            UserIDforCancel: CreateBet.UserID,
            OriTextforCancel: CreateBet.OriginalText,
            RoleforCancel: CreateBet.Role,
            ServerIDforCancel: CreateBet.ServerID,
            PlatformSelect: [],
            Mode: '',
          }, ()=> {
            this.ConvertResultRed(this.state.Receipt);
          })
          this.CleanData();
        }
        this.setState({loading: false });
      }
    }

    async CancelBetAPI() {
      this.setState({ DisabledCancel: true })
      const ServerID = this.state.ServerIDforCancel
      const CancelBets = await CancelBet(ServerID, this.props.username, this.props.token)
      if(CancelBets.error) {
        this.openNotification(ReturnCancelValidation(CancelBets.error))
        this.setState({ notificationType: 'error', DisabledCancel: false });
      } else {
        if(CancelBets.message === 'Cancel Successful') {
          this.setState({
            UserIDforCancel: '',
            OriTextforCancel: '',
            SuccessFullCancel: true,
            RoleforCancel: '',
            // ServerIDforCancel: '',
            Receipt: CancelBets.CancelReceipt,
            DisabledCancel: false,
          }, ()=> {
            this.ConvertResultRed(this.state.Receipt);
          });
        }
      }
    }

    async ModeOption(event) {
      const SelectedMode = event.target.value;
      if(SelectedMode === 'IBox') {
        this.setState({ Mode: SelectedMode, IBox: true })
      } else {
        this.setState({ Mode: SelectedMode })
      }
    }

    async SelectDateFunction(Index) {
      const toProcessDateData = this.state.ModalDate;
      if (toProcessDateData.DateLoop[Index.Index].CheckMark === false) {
        toProcessDateData.DateLoop[Index.Index].CheckMark = true;
      } else {
        toProcessDateData.DateLoop[Index.Index].CheckMark = false;
      }
      this.setState({ ModalDate: toProcessDateData });
    }

    onPressConfirmSelectDate() {
      const BettingWords = this.state.words
      const {selectedDate, DisplayDate} = this.state;
      const toProcessDateData = this.state.ModalDate;
      let DateData = '';
      let DateDisplay = '';
      for (let i = 0; i < toProcessDateData.DateLoop.length; i++) {
        if (toProcessDateData.DateLoop[i].CheckMark === true) {
          DateData += toProcessDateData.DateLoop[i].Number;
          DateDisplay += toProcessDateData.DateLoop[i].Date;
        }
      }
      if (DateDisplay !== ''){
        if (!BettingWords.length) {
          selectedDate[0] = `D#*${DateData}`
          // this.setState({ selectedDate:  `D#*${DateData}`})
          DisplayDate[0] = `${language[this.props.currentLanguage].Date}(${DateData.length}): D# ${DateDisplay}`
        } else {
          selectedDate[0] = `D#*${DateData}`
          BettingWords[0].SelectedDate = `D#*${DateData}`
          // const FinalAnswer = [...this.state.OriginalWords]
          // FinalAnswer[0] = `D#*${DateData}\n`

          // const OWords = (FinalAnswer).join('')
          this.setState({ words: BettingWords, Datelength: DateData.length }, ()=> {
            this.ConvertToOriginalWord(this.state.words)
            this.TotalBetAmount();
          })
          DisplayDate[0] = `${language[this.props.currentLanguage].Date}(${DateData.length}): D# ${DateDisplay}`
        }
      } else {
        if(!BettingWords.length) {
          DisplayDate[0] = `${language[this.props.currentLanguage].ChooseDate} (D#)`
          selectedDate[0] = `D#`
        } else {
          DisplayDate[0] = `${language[this.props.currentLanguage].ChooseDate} (D#)`
          BettingWords[0].SelectedDate = `D#`
          this.setState({selectedDate: [], words: BettingWords, Datelength: DateData.length}, ()=> {
            this.ConvertToOriginalWord(this.state.words)
            this.TotalBetAmount();
          })
        }
      }
      this.setState({ modalShow: false, DDate: DisplayDate[0], Datelength: DateData.length  });
    }

    async CleanData() {
      // const r = (window.confirm('您确定要清空?'));
      // if (r === true) {
      this.setState({
        DDate: `${language[this.props.currentLanguage].ChooseDate} (D#)`,
        Text: '',
        words: [],
        selectedDate: [],
        Platform: [],
        SelectedPlatform: [],
        platforms: '',
        format: '',
        MG: false,
        KD: false,
        TT: false,
        SG: false,
        SW: false,
        SBH: false,
        STC: false,
        NINE: false,
        Datelength: '1',
        8: false,
        FinalAnswer: [],
        PlatformSelect: [],
        OriginalWords: '',
        AddAmount: [], Tue: false, Wed: false, Thu: false, Fri: false, Sat: false, Sun: false, Mon: false,
        ResultAmount: 0,
      })
      this.ClearGames();
      this.NumberInput.focus();
    }

    openNotification(message, NotEnough) {
      this.setState({ showNotification: true });
      this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]} ${(NotEnough !== undefined && NotEnough !== '') ? `${language[this.props.currentLanguage].LeftCashWallet}:${NotEnough || ''}` : ``}` });
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.setState({ showNotification: false });
        this.setState({ notificationMessage: '' });
      }, 5000);
    };

    async ConvertResultRed(Receipt) {
      let Word = Receipt
      let returnWord = [];
  
      if (Word.includes('<red>')) {
        const WordArray = Word.split('<red>').join('</red>').split('</red>');
        for (let i = 0; i < WordArray.length; i += 1) {
          if (i === 0) {
            returnWord.push(<span key={i}>{WordArray[i]}</span>);
          } else if (i % 2 === 1) {
            returnWord.push(
              <span key={i} style={{color: 'red'}}>
                {WordArray[i]}
              </span>,
            );
          } else if (i % 2 === 0) {
            returnWord.push(<span key={i}>{WordArray[i]}</span>);
          }
        }
        this.setState({ReceiptDisplay: returnWord});
      } else {
        this.setState({ReceiptDisplay: Word});
      }
    }

    onpressLongTerm() {
      const toProcessDateData = this.state.ModalDate;
      const BettingWords = this.state.words
      const { selectedDate } = this.state;

      if(BettingWords.length > 0) {
        BettingWords[0].SelectedDate = `D#*`
        for(let i = 0; i < toProcessDateData.DateLoop.length; i++) {
          if(toProcessDateData.DateLoop[i].CheckMark === true) {
            toProcessDateData.DateLoop[i].CheckMark = false
          }
        }
        this.setState({ words: BettingWords, DDate: '日期： D#AUTO', modalShow: false, ModalDate: toProcessDateData, Datelength: '1'}, ()=> {
          this.ConvertToOriginalWord(this.state.words)
          this.TotalBetAmount();
        })
      } else {
        selectedDate[0] = `D#*`

        for(let i = 0; i < toProcessDateData.DateLoop.length; i++) {
          if(toProcessDateData.DateLoop[i].CheckMark === true) {
            toProcessDateData.DateLoop[i].CheckMark = false
          }
        }
        this.setState({ modalShow: false, DDate: '日期： D#AUTO', ModalDate: toProcessDateData, Datelength: '1' });
      }
    }

    onpressPeriod(Num) {
      const toProcessDateData = this.state.ModalDate;
      const BettingWords = this.state.words
      const { selectedDate } = this.state;
      let newLineWord = '';
      // let NextDate = [];
      // for (var i = 0; i < Num; i++) {
      //   const CreatedDate = Moment(new Date().setDate(new Date().getDate() + (i + 1))).format('DDMM(ddd)');
      //   NextDate.push(CreatedDate);
      // }
      newLineWord = 'D#' + Num;

      if(BettingWords.length > 0) {
        BettingWords[0].SelectedDate = `D#${Num}`
        for(let i = 0; i < toProcessDateData.DateLoop.length; i++) {
          if(toProcessDateData.DateLoop[i].CheckMark === true) {
            toProcessDateData.DateLoop[i].CheckMark = false
          }
        }
        this.setState({ words: BettingWords, DDate: `日期: ${newLineWord}`, modalShow: false, ModalDate: toProcessDateData, Datelength: '1'}, ()=> {
          this.ConvertToOriginalWord(this.state.words)
          this.TotalBetAmount();
        })
      } else {
        selectedDate[0] = `D#${Num}`

        for(let i = 0; i < toProcessDateData.DateLoop.length; i++) {
          if(toProcessDateData.DateLoop[i].CheckMark === true) {
            toProcessDateData.DateLoop[i].CheckMark = false
          }
        }
        this.setState({ modalShow: false, DDate: `日期: ${newLineWord}`, ModalDate: toProcessDateData, Datelength: '1' });
      }
    }

    CopyToC(TextResult) {
      const DownloadWord = TextResult.split('<red>').join('</red>').split('</red>');
      navigator.clipboard.writeText(DownloadWord.join(''))
      this.openNotification('SuccessCopied')
      this.setState({ notificationType: true });
    }

    handleScroll(event) {
      var node = event.target;
      if (node.scrollTop > 1200) {
        this.setState({
          is_visible: true
        });
      } else {
        this.setState({
          is_visible: false
        });
      }
    }
  
    scrollToTop(){
      const element = document.getElementById('Top')
      element.scrollIntoView({ behavior: "smooth" })
    }

    LaiHui() {
      let words = this.state.words
      if (words.length > 0) {
        this.setState(({
          words: [
            ...this.state.words,
            {
              Word: words[words.length - 1].Word.split("").reverse().join(""),
              Platform: words[words.length - 1].Platform,
              SelectedDate: words[words.length - 1].SelectedDate,
              Mode: words[words.length - 1].Mode,
              Key2: words[words.length - 1].Key2,
              Bet: JSON.parse(JSON.stringify(words[words.length - 1].Bet)),
              TotalAmount: words[words.length - 1].TotalAmount,
              originalWord: words[words.length - 1].originalWord,
              CurrentFormat: words[words.length - 1].CurrentFormat,
              Divide: words[words.length - 1].Divide,
            },
          ],
        }), () => {
          this.ConvertToOriginalWord(this.state.words);
          this.NumberInput.focus();
          this.TotalBetAmount();
        });
      } else {
        this.openNotification('EmptyBet')
        this.setState({ notificationType: 'error' });
      }
    }

    PlatformChange(platform) {
      if (platform === '1') return 'MG'
      else if (platform === '2') return 'KD'
      else if (platform === '3') return 'TT'
      else if (platform === '4') return 'SG'
      else if (platform === '5') return 'SW'
      else if (platform === '6') return 'SBH'
      else if (platform === '7') return 'STC'
      else if (platform === '8') return '8'
      else if (platform === '9') return 'NINE'
      return 'null'
    }

    returnPlatformIcon(platform) {
      if(platform === '1') { return MagnumLogo } else if (platform === '2') { return DamacaiLogo} else if (platform === '3')
      {return TotoLogo} else if (platform === '4') { return Sg4Logo } else if(platform === '5') { return SarawakLogo} else if(platform === '6') { return Sabah88Logo }
      else if(platform === '7') {return SansakanLogo} else if(platform === '8') { return GDLogo} else if(platform === '9') {return NineLogo}
      else if(platform === '-') {return noneIcon} 
    }

    ReturnPlatform(platform) {
      if(platform === '1') { return 'M' } else if (platform === '2') { return ((this.state.GamesDisplay === 'MKTSWBD') ? 'K' : 'P')} else if (platform === '3')
      {return 'T'} else if (platform === '4') { return 'S' } else if(platform === '5') { return 'W'} else if(platform === '6') { return 'B' }
      else if(platform === '7') {return ((this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K')} else if(platform === '8') { return '8'} else if(platform === '9') { return '9'}
    }

    ShotCutKeySearchFunction(event){
      if (event.key === 'Enter') {
        this.SearchFunction();
      }
    }

    async SearchFunction(){
      this.setState({ SearchUSersModal: true })
      if (this.state.SearchUser !== '') {
        const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.props.userID, this.props.token, this.props.username)
        if(Searchresult.message === 'Invalid Downline ID or Username') {
          this.setState({ notificationType: 'error' }, () => {
            this.openNotification('InvalidDownlineIDorUsername')
          });
        } else {
          Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
          this.setState({ SearchedID: Searchresult })
        }
      }
    }

    handleCloseSearchModal() {
      this.setState({
        SearchUSersModal: false,
        SearchedID : [],
      })
    }

    ClearGames() {
      this.setState({
        B: '', S: '', SA: '', SB: '',
        SC: '', SD: '', SE: '', SF: '', fiveD: '', sixD: '', threeD: '',
        PA: '', PB: '', PC: '', PD: '', PE: '', A: '', C: '',
        CA: '', CD: '', CB: '', CC: '', CE: '', CF: '', PF: '',
      })
    }

    ReturnDisplayFormat(KeyinFormat) {
      const getUserFormula = (this.state.getUserFormula === null) ? [] : this.state.getUserFormula
      let result = []
      var getformula = Object.values(getUserFormula).filter(function (el) {
        return el.Num === KeyinFormat.toString()
      });
      if (getformula.length > 0) { 
        const Keyin = Object.keys(getformula[0])
        const Key3Formulas = Keyin.filter(function(item) {
          return !['Num'].includes(item); 
        })
        Key3Formulas.forEach((formula) => {
          result.push(`${formula}: ${getformula[0][formula]}`)
        })
      }
      return (getUserFormula.length === 0) ? '' : result.join(',')
    }

    render() {
      const KeyInResult = this.state.KeyinFunctions;
      // const CurrentFormat = (this.state.Key2 === true) ? KeyInResult.KeyIn2 || '' :
      //   (this.state.FiveD === true) ? ['5D'] : (this.state.SixD === true) ? ['6D'] :
      //   (this.state.ThreeD === true) ? this.state.key3D : (this.state.TwoD === true) ? this.state.key2D : KeyInResult.KeyIn1 || '';
      const CurrentFormat = (this.state.Key2 === true & this.state.ThreeD === false && this.state.TwoD === false) ? KeyInResult.KeyIn2 || '' :
        (this.state.FiveD === true) ? ['5D'] : (this.state.SixD === true) ? ['6D'] :
          (this.state.ThreeD === true && this.state.Key2 === false) ? this.state.key3D : (this.state.TwoD === true && this.state.Key2 === false) ? this.state.key2D : 
            (this.state.ThreeD === true && this.state.Key2 === true) ? ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'] : (this.state.TwoD === true && this.state.Key2 === true) ? ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'] : KeyInResult.KeyIn1 || '';
      return (
        // <div className="dcc App backgroundImage4" style={{ paddingTop: '10%', minHeight: 950, overflowX: 'auto', 
        //   width: (isMobile) ? (this.state.ShowReceipt === true ? 480 : null) : null}}>
        <div onScroll={(e) => this.handleScroll(e)} style={{ backgroundColor: '#EFEFEF', height: '100vh', flexDirection: 'column', overflowX: 'auto' }}>
          <Header />
          <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
          <div id={'Top'} className="dcc">
            <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} show={this.state.showNotification}
              variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
              <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
            </Alert>
          </div>
          {/* {isMobile && 
            <div className="TitleDate">{language[this.props.currentLanguage].Balance}: {this.state.Balance}</div>
          } */}
          <div className={"row d-flex justify-content-center px-3"} style={{ width: '100%'}}>
            <div style={{ width: 50}}></div>
            <div className="BettingV2Width" style={{ backgroundColor: 'white', marginBottom: 50, border: `1px solid white`, height: 'auto', maxWidth: null }}>
              {/* <div className="dcc jackpotborder" style={{ marginTop: 15, marginBottom: 10 }}>
                <FaHome size="25px" color="white" className="logohome" style={{ marginRight: 10 }} />{language[this.props.currentLanguage].BetSimple} 
              </div> */}
              <div className="dcc jackpotborder" style={{ justifyContent: 'space-between', width: '100%', marginTop: 15, marginBottom: 10}}>
                <div style={{width: '33.3%'}}></div>
                <div style={{width: '33.3%'}} className='dcc'><FaHome size="25px" color="white" className="logohome" style={{ marginRight: 10 }} /> {language[this.props.currentLanguage].BetSimple}</div>
                <div style={{ width: '33.3%', textAlign: 'right', paddingRight: 10, fontWeight: 'bold' }}>{`${language[this.props.currentLanguage].Balance} : $ ${this.state.Balance}`}</div>
              </div>
              {/* <div className="dcc TextFontSize">
                <div style={{ width: '90%' }}>
                  <Select
                    isSearchable={this.state.optionsUsername.length === 0 ? false : true}
                    options={this.state.optionsUsername}
                    value={this.state.SelectedUser}
                    onChange={(e) => this.SelectedUser(e)}
                  >
                  </Select>
                </div>
              </div> */}
              <div className='dcc' style={{ display: 'flex', alignItems: 'center', marginBottom: 10, flexDirection: 'row' }}>
                <div style={{ margin: '0px 10px',  fontWeight: 'bold'}}>{language[this.props.currentLanguage].SearchAccount}</div>
                <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
                <Button  className='button2' variant="primary" onClick={() => this.SearchFunction()}>{language[this.props.currentLanguage].Search}</Button>
                <Button  className='button2' variant="primary" style={{ marginLeft: 5 }} onClick={() => this.getDownline()}>{'Self'}</Button>
              </div>
              <div style={{ width: '100%', marginBottom: 0, marginTop: 10, fontWeight: 'bold', padding: 5, backgroundColor: '#EFEFEF', }}>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '95%', fontWeight: 'bold', flexDirection: 'column' }}
                  disabled={this.state.disabled}
                  onClick={() => this.OpenDateModal()}
                  className="btn my-cusomized-button button2 MenubarTitle" >
                  {this.state.DDate}
                  {' '}
                  {/* {this.state.selectedDate.toString().split('')} */}
                </button>
              </div>
              <div style={{ width: '100%', fontWeight: 'bold' }}>
                <div>{`${language[this.props.currentLanguage].Games} (${this.state.SelectedUser.label})`}</div>
              </div>
              <div style={{ width: '100%', flexWrap: 'wrap', fontWeight: 'bold', padding: 5, backgroundColor: '#EFEFEF', marginBottom: 15, display: 'flex', flexDirection: 'row' }}>
                {this.state.BoxPlatform && this.state.BoxPlatform.map((item, index) =>
                  <div className="row" key={index} style={{ width: 'calc(100% / 4)', marginLeft: 15 }}>
                    <input
                      type="checkbox"
                      disabled={item === '-' ? true : false}
                      style={{ marginRight: 10 }}
                      name="Wed"
                      checked={ this.state[this.PlatformChange(item)] }
                      id = {this.PlatformChange(item)}
                      value = {this.state[this.PlatformChange(item)]}
                      onChange={(event) => this.CheckhandleInputDateChange(event, this.PlatformChange(item), index+1)}
                    />
                    {(item === "1") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('1')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('1'), index + 1)}></img>
                      : (item === "2") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('2')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('2'), index + 1)}></img>
                        : (item === "3") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('3')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('3'), index + 1)}></img>
                          : (item === "4") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('4')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('4'), index + 1)}></img>
                            : (item === "5") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('5')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('5'), index + 1)}></img>
                              : (item === "6") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('6')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('6'), index + 1)}></img>
                                : (item === "7") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('7')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('7'), index + 1)}></img>
                                  : (item === "8") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('8')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('8'), index + 1)}></img>
                                    : (item === "9") ? <img style={{ cursor: 'pointer' }} src={this.returnPlatformIcon('9')} alt="Logo" className='Bettingv2PlatformIcon' onClick={() => this.toggleResult(this.PlatformChange('9'), index + 1)}></img>
                                      : <img alt="Logo" src={this.returnPlatformIcon('-')} className='Bettingv2PlatformIcon'></img>}
                  </div>
                )}
              </div>
              <Modal transparent={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.modalShow}
                contentClassName="br23">
                <Modal.Body className="dcc" style={{ padding: 10, height: 500 }}>
                  <GrFormClose
                    className='ButtonCloseModal'
                    onClick={() => {
                      this.setState({ modalShow: false })
                    }}
                    size="35px"
                    color="#000"
                  />
                  <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                    <div style={{ flexDirection: 'column' }}>
                      <div className="dcc" style={{ fontWeight: 'bold', fontSize: '20px' }}> 日期</div>
                      <div className="dcc" style={{ width: '100%', flexWrap: 'wrap', flexDirection: 'column' }}>
                        <div className="dcc" style={{ marginBottom: 15, flexDirection: 'column', width: '100%' }}>
                          {(this.state.ModalDate.DateLoop || []).map((item, idx) => {
                            return (
                              <button
                                key={idx}
                                className="DateSelectButton DateModalButton"
                                style={{ width: '100%' }}
                                onClick={() => this.SelectDateFunction({ Index: idx })}>
                                <div className="dcc row" style={{ justifyContent: 'space-evenly' }}>
                                  <div>{item.Date}</div>
                                  {item.CheckMark && (
                                    <div>
                                      <img src={CheckMark}
                                        alt="Logo"
                                        style={{ width: 40, height: 40 }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </button>
                            );
                          })}
                        </div>
                        <div className="column" style={{ textAlign: 'center' }}>
                          <div style={{ marginBottom: 5 }}>
                            <button style={{ backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold', marginRight: 30 }}
                              disabled={this.state.disabled}
                              onClick={(e) => this.onpressLongTerm(e)}
                              className="btn my-cusomized-button" >
                              {language[this.props.currentLanguage].Auto}
                            </button>
                            <button style={{ backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold' }}
                              disabled={this.state.disabled}
                              onClick={() => this.onPressConfirmSelectDate()}
                              className="btn my-cusomized-button" >
                              {language[this.props.currentLanguage].Confirm}
                            </button>
                          </div>
                          <div>
                            <button style={{ backgroundColor: '#3C96F4', color: 'white', width:  200, fontWeight: 'bold', marginRight: 30 }}
                              disabled={this.state.disabled}
                              onClick={(e) => this.onpressPeriod(2)}
                              className="btn my-cusomized-button" >
                              {language[this.props.currentLanguage].secondPeriod}
                            </button>
                            <button style={{ backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold' }}
                              disabled={this.state.disabled}
                              onClick={() => this.onpressPeriod(3)}
                              className="btn my-cusomized-button" >
                              {language[this.props.currentLanguage].ThreePeriod}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <div className="dcc" style={{ width: '100%', marginBottom: 35 }}>
                <div className='MenubarTitle' style={{ fontWeight: 'bold', width: 'calc(100% / 3)' , marginLeft: null }}>Key</div>
                <select className="TextFontSize DropdownListDesign form-control"
                  style={{
                    width: 'calc(100% / 1)',
                    marginRight: 15,
                    fontWeight: 'bold',
                  }}
                  value={this.state.SelectedExample} onChange={this.SelectedOption}
                  disabled={(this.state.FiveD === true || this.state.SixD === true) ? true : false}>
                  <option value={'KeyIn1'}>{this.state.KeyinFunctions.KeyIn1 + '\n'}</option>
                  <option value={'KeyIn2'}>{this.state.KeyinFunctions.KeyIn2 + '\n'}</option>
                </select>
              </div>
              <div className="dcc" style={{ width: '100%', marginBottom: 35}}>
                <div className='MenubarTitle' style={{ fontWeight: 'bold', width: 'calc(100% / 3)', marginLeft: null }}>{language[this.props.currentLanguage].Special}</div>
                <select className="TextFontSize DropdownListDesign form-control" onChange={this.ModeOption}
                  value={this.state.Mode}
                  style={{ marginLeft: 100, marginRight: 15, width: 'calc(100% / 1)', fontWeight: 'bold' }}
                  disabled={(this.state.FiveD === true || this.state.SixD === true || this.state.FormatFull === true) ? true : false}>
                  {['-', 'IBox', 'Box', '{0-9}123', '123{0-9}', '{123}4', '1{234}', '*#'].map((gametype, idd) => {
                    return(
                      <option key={idd} value={gametype}>{gametype}</option>
                    )
                  })}
                </select>
              </div>
              <div className="column" style={{ width: '100%', marginBottom: 35 }}>
                <div className="dcc" style={{ marginBottom: 10 }}>
                  <div className='MenubarTitle' style={{ fontWeight: 'bold', width: 'calc(100% / 3)'  }}>
                    {language[this.props.currentLanguage].Bet}
                  </div>
                  <div style={{ width: '100%', marginLeft: 95 }}>
                    <div className="column">
                      <div style={{ marginBottom: 5, marginRight: 23, width: 'auto', marginLeft: 0 }}>
                        <input
                          className="form-control TextFontSize"
                          style={{ textAlign: 'center', fontWeight: 'bold' }}
                          value={this.state.Text}
                          type="text"
                          maxLength="9"
                          readOnly={false}
                          onChange={(e) => this.handleChangeGameOrNumber(e.target.value, 'Text')}
                          placeholder={language[this.props.currentLanguage].BetNumber}
                          ref={(input) => { this.NumberInput = input; }}
                          name="field-1"
                          onKeyPress={this.handleKeyPressNumberInput}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        {(this.state.FiveD === false && this.state.SixD === false && this.state.ThreeD === false && this.state.TwoD === false && this.state.Mode !== '*#') &&
                                                <>
                                                  <div className="row" style={{ marginBottom: 5, width: 'auto' }}>
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 23, marginLeft: 15, fontWeight: 'bold', color: 'red'}}
                                                      value={this.state[CurrentFormat[0]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[0])}
                                                      placeholder={CurrentFormat[0]}
                                                      ref={(input) => { this.field1 = input; }}
                                                      name={CurrentFormat[0]}
                                                      // selectTextOnFocus={true}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field2')}
                                                    />
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 23, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[1]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[1])}
                                                      name={CurrentFormat[1]}
                                                      ref={(input) => { this.field2 = input; }}
                                                      placeholder={CurrentFormat[1]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field3')}
                                                    />
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 18, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[2]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[2])}
                                                      name={CurrentFormat[2]}
                                                      ref={(input) => { this.field3 = input; }}
                                                      placeholder={CurrentFormat[2]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field4')}
                                                    />
                                                  </div>
                                                  <div className="row" style={{ width: 'auto'}}>
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 23, marginLeft: 15, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[3]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[3])}
                                                      name={CurrentFormat[3]}
                                                      ref={(input) => { this.field4 = input; }}
                                                      placeholder={CurrentFormat[3]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field5')}
                                                    />
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 23, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[4]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[4])}
                                                      name={CurrentFormat[4]}
                                                      ref={(input) => { this.field5 = input; }}
                                                      placeholder={CurrentFormat[4]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field6')}
                                                    />
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 18, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[5]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[5])}
                                                      name={CurrentFormat[5]}
                                                      ref={(input) => { this.field6 = input; }}
                                                      placeholder={CurrentFormat[5]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field1')}
                                                    />
                                                  </div>
                                                </>
                        }
                        {(this.state.FiveD === true && this.state.Mode !== '*#') &&
                                                <div className="dcc">
                                                  <input
                                                    className="form-control TextFontSize"
                                                    style={{ textAlign: 'center', width: '25%', marginRight: 23, marginLeft: -4, fontWeight: 'bold', color: 'red' }}
                                                    value={this.state.fiveD}
                                                    maxLength={6}
                                                    readOnly={false}
                                                    onChange={(e) => this.handleChangeNum(e, e.target.value, 'fiveD')}
                                                    name="field-3"
                                                    ref={(input) => { this.field1 = input; }}
                                                    placeholder={CurrentFormat[0]}
                                                    onKeyPress={(e) => this.onKeyEnter(e, 'field1')}
                                                  />
                                                </div>
                        }
                        {(this.state.SixD === true && this.state.Mode !== '*#') &&
                                                <div className="dcc">
                                                  <input
                                                    className="form-control TextFontSize"
                                                    style={{ textAlign: 'center', width: '25%', marginRight: 23, marginLeft: -4, fontWeight: 'bold', color: 'red' }}
                                                    value={this.state.sixD}
                                                    maxLength={6}
                                                    readOnly={false}
                                                    onChange={(e) => this.handleChangeNum(e, e.target.value, 'sixD')}
                                                    name="field-3"
                                                    ref={(input) => { this.field1 = input; }}
                                                    placeholder={CurrentFormat[0]}
                                                    onKeyPress={(e) => this.onKeyEnter(e, 'field1')}
                                                  />
                                                </div>
                        }
                        {((this.state.ThreeD === true && this.state.Mode !== '*#') || (this.state.TwoD === true && this.state.Mode !== '*#' && this.state.Mode !== '{0-9}123' && this.state.Mode !== '123{0-9}' && this.state.Mode !== '{123}4'  && this.state.Mode !== '1{234}')) &&
                                                <>
                                                  <div className={(this.state.Key2 === true) ? `row` : `dcc row`} style={{ marginBottom: 5, width: 'auto' }}>
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 23, marginLeft: 15, fontWeight: 'bold', color: 'red'}}
                                                      value={this.state[CurrentFormat[0]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[0])}
                                                      placeholder={`${CurrentFormat[0]}`}
                                                      ref={(input) => { this.field1 = input; }}
                                                      name={CurrentFormat[0]}
                                                      // selectTextOnFocus={true}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field2')}
                                                    />
                                                    <input
                                                      className="form-control TextFontSize"
                                                      hidden={CurrentFormat[1] === undefined ? true : false}
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 23, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[1]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[1])}
                                                      name={CurrentFormat[1]}
                                                      ref={(input) => { this.field2 = input; }}
                                                      placeholder={`${CurrentFormat[1]}`}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field3')}
                                                    />
                                                    <input
                                                      hidden={(this.state.Key2 === true) ? false : true }
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 18, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[2]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[2])}
                                                      name={CurrentFormat[2]}
                                                      ref={(input) => { this.field3 = input; }}
                                                      placeholder={CurrentFormat[2]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field4')}
                                                    />
                                                  </div>
                                                  <div className="row" style={{ width: 'auto'}} hidden={(this.state.Key2 === true) ? false : true }>
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 23, marginLeft: 15, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[3]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[3])}
                                                      name={CurrentFormat[3]}
                                                      ref={(input) => { this.field4 = input; }}
                                                      placeholder={CurrentFormat[3]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field5')}
                                                    />
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 23, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[4]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[4])}
                                                      name={CurrentFormat[4]}
                                                      ref={(input) => { this.field5 = input; }}
                                                      placeholder={CurrentFormat[4]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field6')}
                                                    />
                                                    <input
                                                      className="form-control TextFontSize"
                                                      style={{ textAlign: 'center', width: '25%', marginRight: 18, fontWeight: 'bold', color: 'red' }}
                                                      value={this.state[CurrentFormat[5]]}
                                                      maxLength={6}
                                                      readOnly={false}
                                                      onChange={(e) => this.handleChangeNum(e, e.target.value, CurrentFormat[5])}
                                                      name={CurrentFormat[5]}
                                                      ref={(input) => { this.field6 = input; }}
                                                      placeholder={CurrentFormat[5]}
                                                      onKeyPress={(e) => this.onKeyEnter(e, 'field1')}
                                                    />
                                                  </div>
                                                </>
                        }
                        {this.state.FiveD === false && this.state.SixD === false && this.state.ThreeD === false && this.state.TwoD === false && this.state.Mode === '*#' && this.state.Key2 === false && 
                        <div className="dcc row">
                          <input
                            className="form-control TextFontSize"
                            style={{ textAlign: 'center', width: '25%', marginRight: 23, fontWeight: 'bold', color: 'red' }}
                            value={this.state.format}
                            maxLength={6}
                            readOnly={false}
                            onChange={(e) => this.handleChangeNum(e, e.target.value, 'format')}
                            name="field-3"
                            ref={(input) => { this.field1 = input; }}
                            placeholder={'Format'}
                            onKeyPress={(e) => this.onKeyEnter(e, '')}
                          />
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: 400 }}></div>
              {/* <div className="dcc" 
                style={{
                  width: (isMobile) ? 'auto' : 550,
                  flexWrap: 'wrap',
                }}>
                {this.state.platforms
                                && this.state.platforms.map((item, index) => {
                                  let Itemnumber = [<div key={index} style={{ width: 'calc(100% / 8)', fontSize: (isMobile) ? '14px' : '17px', marginBottom: 50 }}>
                                    <div className="dcc row">
                                      <div className="dcc" style={{ color: '#F53E11', fontWeight: 'bold' }}>
                                        {this.ReturnPlatform(item)}
                                      </div>
                                    </div>
                                  </div>]
                                  return Itemnumber
                                })}
              </div> */}
              <div className='dcc' style={{ width: '100%', paddingBottom: 35, display: 'flex', flexDirection: 'row'}}>
                <button style={{ textAlign: 'center', backgroundColor: '#EE3E13', color: 'white', width: 200, fontWeight: 'bold', marginRight: 20 }}
                  disabled={this.state.disabled}
                  onClick={() => this.CleanData()}
                  className="btn my-cusomized-button button2 TextFontSize">
                  <div className="dcc">
                    <BiRefresh color="white" style={{ marginRight: 10 }} size={25} />
                    {language[this.props.currentLanguage].ClearAll}
                    <FiCornerRightUp color="white" style={{ marginLeft: 10 }} size={20} />
                  </div>
                </button>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 100, fontWeight: 'bold', marginRight: 20 }}
                  disabled={this.state.disabled}
                  onClick={() => this.LaiHui()}
                  className="btn my-cusomized-button button2 TextFontSize">
                  <div className="dcc">
                    {'来回'}
                  </div>
                </button>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 200, fontWeight: 'bold' }}
                  disabled={this.state.disabled}
                  onClick={() => this.submitNumber()}
                  className="btn my-cusomized-button button2 TextFontSize">
                  <div className="dcc">
                    <AiOutlinePlus color="white" style={{ marginRight: 10, color: 'yellow' }} size={25} />
                    {language[this.props.currentLanguage].AddTo}
                    <FiCornerRightDown color="white" style={{ marginLeft: 10 }} size={20} />
                  </div>
                </button>
              </div>
              <div style={{ marginLeft:  null ,width: null, overflowX: 'auto'}}>
                <Table responsive="sm" style={{ backgroundColor: '#EFEFEF' }}>
                  <thead>
                    <tr className="TextFontSize" style={{ backgroundColor: '#454040' }}>
                      <th style={{ minWidth: 50, width: '5%' }}><div style={{ color: 'white' }}>#</div></th>
                      <th style={{ minWidth: 80, width: '13%' }}><div style={{ color: 'white' }}>{language[this.props.currentLanguage].nombor}</div></th>
                      <th style={{ minWidth: 80, width: '25%' }}><div style={{ color: 'white' }}>{language[this.props.currentLanguage].Games}</div></th>
                      <th style={{ minWidth: 100, width: '27%' }}><div style={{ color: 'white' }}>{language[this.props.currentLanguage].TotalAmount}</div></th>
                      <th style={{ minWidth: 80, width: '10%' }}><div style={{ color: 'white' }}>{language[this.props.currentLanguage].Mode}</div></th>
                      <th style={{ minWidth: 80, width: '17%' }}><div style={{ color: 'white' }}>RM</div></th>
                      <th style={{ minWidth: 50, width: '3%' }}><div style={{ color: 'white' }}>X</div></th>
                    </tr>
                  </thead>
                  {this.state.words &&
                                (this.state.words || []).map((item, index) => {
                                  return (
                                    <thead key={index}>
                                      <tr>
                                        <td style={{ width: '5%', flexWrap: 'wrap', fontWeight: 'bold' }}>{index + 1}</td>
                                        <td
                                          style={{
                                            width: '13%',
                                            flexWrap: 'wrap',
                                            color: 'red',
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                          }}>
                                          {item.Word}
                                        </td>
                                        <td style={{ width: '25%', flexWrap: 'wrap', fontWeight: 'bold' }}>
                                          {item.Platform.includes('1') ? (this.state.PFLIne[this.state.BoxPlatform[0]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[0]]) : ''}
                                          {item.Platform.includes('2') ? (this.state.PFLIne[this.state.BoxPlatform[1]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[1]]) : ''}
                                          {item.Platform.includes('3') ? (this.state.PFLIne[this.state.BoxPlatform[2]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[2]]) : ''}
                                          {item.Platform.includes('4') ? (this.state.PFLIne[this.state.BoxPlatform[3]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[3]]) : ''}
                                          {item.Platform.includes('5') ? (this.state.PFLIne[this.state.BoxPlatform[4]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[4]]) : ''}
                                          {item.Platform.includes('6') ? (this.state.PFLIne[this.state.BoxPlatform[5]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[5]]) : ''}
                                          {item.Platform.includes('7') ? (this.state.PFLIne[this.state.BoxPlatform[6]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[6]]) : ''}
                                          {item.Platform.includes('8') ? (this.state.PFLIne[this.state.BoxPlatform[7]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[7]]) : ''}
                                          {item.Platform.includes('9') ? (this.state.PFLIne[this.state.BoxPlatform[8]] === undefined ? '<ERR>' : this.state.PFLIne[this.state.BoxPlatform[8]]) : ''}
                                          {/* {item.Platform} */}
                                        </td>
                                        <td style={{ width: '27%', flexWrap: 'wrap' }}>
                                          <div className="dcc row">
                                            {Object.keys(item.Bet).map((keys, indec) => (
                                              <div className="dcc BetN" key={indec}>
                                                <span style={{ color: 'red', top: 0, left: 3, fontSize: '14px' }}>{(item.Bet[keys] !== '') ? keys : ''}</span>
                                                {(item.Bet[keys] !== '') ? item.Bet[keys] : ''}
                                                {(item.Mode === '*#') && <span style={{ color: 'red', top: 0, left: 3, fontSize: '14px' }}>{`${this.ReturnDisplayFormat(item.Bet[keys])}`}</span>
                                                }
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td style={{ width: '10%' }}><div style={{ color: 'black', fontWeight: 'bold' }}>{item.Mode}</div></td>
                                        <td style={{ width: '17%', flexWrap: 'wrap', fontWeight: 'bold' }}>{(item.TotalAmount).toFixed(2)}</td>
                                        <td style={{ width: '3%' }}><div><IoMdCloseCircle style={{ marginBottom: 5 }} size={25} color="red" onClick={() => this.DeletedNumber(item, index)} /></div></td>
                                      </tr>
                                    </thead>
                                  );
                                })}
                </Table>
              </div>
              <div style={{ marginBottom: 20, fontWeight: 'bold' }}>RM {this.state.ResultAmount || '0'}</div>
              <div style={{ width: '100%', paddingBottom: 40 }}>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 400, fontWeight: 'bold' }}
                  disabled={this.state.disabled}
                  onClick={() => this.SenddataToApi()}
                  className="btn my-cusomized-button button2">
                  {language[this.props.currentLanguage].ConfirmTicket}
                </button>
              </div>
            </div>
            <div style={{ width: 50}}>
              {this.state.loading ? (
                <div style={{ flexDirection: 'column', width: '100%' }}>
                  <img src={Loading3} alt="Logo" className="loadingimages" style={{ marginBottom: 30, marginTop: 50 }} />
                </div>
              ) : (
                <>
                  {this.state.ShowReceipt === true &&
                            <div className="column" style={{ backgroundColor: 'white', width: 400, borderColor: 'black', borderRadius: 5, marginLeft: 10 }}>
                              <div className="dcc row" style={{ marginBottom: 30, fontWeight: 'bold', fontSize: '20px'}}>
                                <div>{`${(this.state.SuccessFullCancel === true) ? 
                                  language[this.props.currentLanguage].CancelSuccessfulldisplay 
                                  : language[this.props.currentLanguage].SuccessOrderBet }`}</div>
                                <div>
                                  <GrFormClose
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      this.setState({ ShowReceipt: false, Receipt: '' })
                                    }}
                                    size="35px"
                                    color="#000"
                                  />
                                </div>
                              </div>
                              <div className='DisplayReceiptValue'>
                                {this.state.ReceiptDisplay}
                              </div>
                              <button style={{ marginRight: 5 }}
                                className="btn my-cusomized-button button2 BoxVersionReceiptButton" onClick={() => {this.CopyToC(this.state.Receipt)}}>{language[this.props.currentLanguage].CopyReceipt}</button>
                              <button
                                onClick={() => this.CancelBetAPI()}
                                disabled={this.state.DisabledCancel}
                                className="btn my-cusomized-button BoxVersionReceiptButton" >
                                {language[this.props.currentLanguage].CancelTicket}
                              </button>
                            </div>
                  }
                </>
              )}
            </div>
          </div>
          {this.state.is_visible && 
            <div className="dcc" style={{ paddingBottom: 30 }}>
              <div class="scrollDesign iconpopup button2"  
                onClick={() => this.scrollToTop()}>
                {/* ↑ ⇈ */}
                <div  className="icon MoveUpArrow"> ⇧ </div>
              </div>
            </div>
          }
          {/* <Footer /> */}
        </div>
      );
    }
}
// export default Home;

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BettingVersion2);