import React from 'react';
import { connect } from 'react-redux';
// import moment from 'moment-timezone';
import { language } from '../../language';
import { Button, Modal } from 'react-bootstrap';

class WaterDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  OpenSelectedReceipt(SelectedViewReceipt) {
    window.open(SelectedViewReceipt, '_blank', 'noopener,noreferrer');
  }

  CalculateTotal(WaterDetails, Type) {
    let TotalAmpunt = {
      Total: 0,
    }
    WaterDetails.forEach((items) => {
      TotalAmpunt.Total += Number(items[Type])
    })
    return TotalAmpunt.Total.toFixed(2)
  }

  render() {
    return (
      <Modal centered show={this.props.OpenWaterDetailsModal} size="lg" onHide={this.props.CloseWaterModal}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`查看净赚细节`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#E7E7E7'}}>
          <div className="dcc" style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 10, overflowX: 'auto ', backgroundColor: 'white' }}>
            <div className='UnApproveDesign' style={{ textAlign: 'center'}}>
              <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{language[this.props.currentLanguage].Username}</div>
              <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{language[this.props.currentLanguage].Water}</div>
              <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{language[this.props.currentLanguage].WinProfit}</div>
            </div>
            {this.props.WaterDetails.length > 0 &&
            <>
              {this.props.WaterDetails.map((items, index) => {
                return(
                  <div className='UnApproveDesign' key={index}>
                    <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{items.Name}</div>
                    <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{this.props.RedMode === 'sgd' ? `${items.waterProfit.toFixed(2)} (${items.sgdwaterProfit.toFixed(2)})` : items.waterProfit.toFixed(2)}</div>
                    <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{this.props.RedMode === 'sgd' ? `${items.winProfit.toFixed(2)} (${items.sgdwinProfit.toFixed(2)})` : items.winProfit.toFixed(2)}</div>
                  </div>
                )
              })}
              <div className='UnApproveDesign' style={{ backgroundColor: 'black', color: 'white' }}>
                <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{language[this.props.currentLanguage].Total}</div>
                <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{this.props.RedMode === 'sgd' ? `${this.CalculateTotal(this.props.WaterDetails, 'waterProfit')} (${this.CalculateTotal(this.props.WaterDetails, 'sgdwaterProfit')})` : this.CalculateTotal(this.props.WaterDetails, 'waterProfit')}</div>
                <div className='UnApproveTableDesign' style={{ width: '33.3%', minWidth: '120px' }}>{this.props.RedMode === 'sgd' ? `${this.CalculateTotal(this.props.WaterDetails, 'winProfit')} (${this.CalculateTotal(this.props.WaterDetails, 'sgdwinProfit')})` : this.CalculateTotal(this.props.WaterDetails, 'winProfit')}</div>
              </div>
            </>
            }
            {this.props.WaterDetails.length === 0 && 
            <div style={{ width: '100%', textAlign: 'center', fontWeight: 'bold'}}> {' No Data'}</div>
            }
          </div>
          <Button variant="danger" onClick={this.props.CloseWaterModal}>关闭</Button>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WaterDetailsModal);
