import '../App.css';
import React from 'react';
import Header2 from '../Component/Header';
// import { Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { connect } from 'react-redux'
// import Receipt from '../Images/Receipt.png';
// import { Navbar, Nav, Container, DropdownButton, Dropdown } from 'react-bootstrap';
// import {
//   EmailShareButton,
//   EmailIcon,
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   LineShareButton,
//   LineIcon,
//   WhatsappShareButton,
//   WhatsappIcon,
// } from "react-share";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { RiArrowDropDownFill } from 'react-icons/ri';
// import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
// import { language } from '../language';

class Page2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: this.props.username,
      TodayDate: moment(new Date()).format('DD/MM/YYYY (ddd)'),
      SearchValue: '',
      TextResult: '',
      showShare: true,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      ChooseStatus: 300,
    }
    this.SelectedOptionStatus = this.SelectedOptionStatus.bind(this);
  }

  toggleResult(platform) {
    this.setState({
      [`show${platform}`]: !this.state[`show${platform}`],
    })
  }

  SelectedOptionStatus(event) {
    this.setState({ChooseStatus: event.target.value});
  }

  async Downloadfile(){

  }

  render() {
    return (
      <div style={{ marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'space-between', minHeight: 350, flexDirection: 'column' }}>
        <Header2 />
        <div className="dcc mobileHeader"
          style={{
            marginTop: 20,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
          }}>
          <div className="TitleDate">日期：{this.state.TodayDate}</div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.state.Username} </div>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft:  '1.5%',
            flexDirection: 'row',
            width: '100%'
          }}>
          <div className="TitleUsername" style={{ width:'20%'}}> Show all (net) only : </div>
          <div style={{ width: '90%'}}>
            <select class="form-control" value={this.state.ChooseStatus} onChange={this.SelectedOptionStatus} style={{ textAlign: 'center', marginRight:  20, width:'auto', fontSize: '15px', height: 45 }}>
              <option value="300">300    </option>
              <option value="500">500    </option>
              <option value="1000">1000    </option>
            </select>
          </div>
        </div>
        <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: null 
          }}>
          <div className="TitleUsername" style={{ width: '51%'}}> Download Records : </div>
        </div>

        <div className ="dcc" style= {{ width: '100%', marginBottom: 20}}>
          <Table responsive="sm" style={{ backgroundColor: 'white', width: '90%', border: '2px solid lightgray'}}>
            <thead>
              <tr>
                <th><div style={{ width: 35, marginLeft: 20}}></div>No.</th>
                <th><div style={{ width: 35}}></div>ID</th>
                <th><div style={{ width: 100}}>Forward On</div></th>
                <th><div style={{ width: 120}}>Forward Type</div></th>
                <th><div style={{ width: 60}}>数目</div></th>
                <th><div style={{ width: 70}}>下传</div></th>
                <th><div style={{ width: 250}}>数目(101 only) B,S,C,A,A1 only (IBox converted)</div></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 230}}><div style={{ padding: 5}}>{'1'}</div></td>
                <td><div style={{ padding: 5}}>{'2'}</div></td>
                <td style={{ }} >
                  {'3'}
                </td>
                <td><div style={{ padding: 5}}>{'4'}</div></td>
                <td><div style={{ padding: 5, }}>{'5'}</div></td>
                <td><div style={{ padding: 5}}>{'6'}</div></td>
                <td><div style={{ padding: 5, }}>{'7'}</div></td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* <div className="row" style={{ width: '100%'}}>
          <div className="TitleUsername" style={{ width: (isMobile) ?  200 : '30%'}}>
              还没有吃到的字 或 已经全部下传。
          </div>
          <div style={{ width: (isMobile) ? 200 : '50%' }}>
            <Button style={{marginLeft: (isMobile) ? "2%" : 5 , width: (isMobile) ? "45%" : 'auto'}} onClick={() => this.Downloadfile()}>Download File</Button>
          </div>
        </div> */}

        <div className="dcc mobileHeader"
          style={{
            marginTop:  0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '1.5%',
            flexDirection: 'row',
            width: '100%'
          }}>
          <div className="TitleUsername" style={{ width: '50%'}}> 还没有吃到的字 或已经全部下传。</div>
          <div style={{ width: '90%'}}>
            <Button style={{marginLeft:  5 , width: 'auto'}} onClick={() => this.Downloadfile()}>Download File</Button>
          </div>
        </div>

        <div className="dcc mobileHeader"
          style={{
            marginTop: 0,
            paddingBottom: 35,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
          }}>
          <div style={{ width: '90%'}}>
            <Table responsive="sm" striped bordered hover style={{ width: '0%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
              <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                <tr>
                  <th style={{ backgroundColor: '#fff23e', color: 'black' }} >{'Magnum'}</th>
                  <th style={{ backgroundColor: '#414bb2' }}>{'Damacai'}</th>
                  <th style={{ backgroundColor: 'red' }}>{'Toto'}</th>
                  <th style={{ backgroundColor: '#32c6f4', color: 'black' }}>{'Singapore'}</th>
                  <th style={{ backgroundColor: '#008000' }}>{'Sarawak'}</th>
                  <th style={{ backgroundColor: '#fc0404' }}>{'Sabah'}</th>
                  <th style={{ backgroundColor: '#6d100e' }}><div style={{ paddingLeft: 5, paddingRight: 5 }}>{'BT'}</div></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                  <th>{'0'}</th>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        
        <div className= "dcc " style= {{ width:  '90%',  flexDirection: 'column', justifyContent: 'center'}}>
          <div style= {{ width: '90%'}} >
            <Accordion>
              <Card style={{ border: 'none', marginBottom: 20 }}>
                <Card.Header style={{ backgroundColor: '#3c7ca7', borderRadius: 0 }}>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{ width: '100%', textAlign: 'left', color: 'white' }}>
                    <span>
                      <span style={{ marginLeft: 10, color: 'white', fontWeight: 'bold' }}>TT</span>
                    </span>
                    <RiArrowDropDownFill
                      style={{ position: 'absolute', right: 20, top: 0, cursor: 'pointer', color: 'white' }}
                      size="35px"
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body >
                    <div className="introductionCard">
                      <div className="introductionCardBody">
                        <div style={{ overflowX: 'auto', width:  ''}}>
                          <Table striped bordered hover style={{ width: '100%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
                            <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                              <tr>
                                <th>{'B'}</th>
                                <th>{'S'}</th>
                                <th>{'A1'}</th>
                                <th>{'A1C'}</th>
                                <th>{'(IB) B'}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th >
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                              </tr>
                            </tbody>
                          </Table>
                          <Table striped bordered hover style={{ width: '100%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
                            <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                              <tr>
                                <th>{'(IB) S'}</th>
                                <th>{'A'}</th>
                                <th>{'C'}</th>
                                <th>{'PA'}</th>
                                <th>{'PC'}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th >
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="row">
                                    <div style={{ width: '50% '}}>
                                      {'1234'}
                                    </div>
                                    <div style={{ borderLeft: '2px solid black', width: '50%'}}>
                                      {'RM 0.00'}
                                    </div>
                                  </div>
                                </th>
                              </tr>
                            </tbody>
                          </Table>
                          <Table striped bordered hover style={{ width: '100%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
                            <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                              <tr>
                                <th>{'(IB) A1'}</th>
                                <th>{'(IB) A1C'}</th>
                                <th>{'A2'}</th>
                                <th>{'(IB) A2'}</th>
                                <th>{'A3'}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>{'(IB) A1'}</th>
                                <th>{'(IB) A1C'}</th>
                                <th>{'A2'}</th>
                                <th>{'(IB) A2'}</th>
                                <th>{'A3'}</th>
                              </tr>
                            </tbody>
                          </Table>
                          <Table striped bordered hover style={{ width: '100%', marginLeft: '0%', marginTop: 10, backgroundColor: 'white' }}>
                            <thead style={{ backgroundColor: '#3c7ca7', color: 'white' }}>
                              <tr>
                                <th>{'(IB)A3'}</th>
                                <th>{'D4'}</th>
                                <th>{'(IB) D4'}</th>
                                <th>{'E4'}</th>
                                <th>{'(IB) E4'}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>{'(IB)A3'}</th>
                                <th>{'D4'}</th>
                                <th>{'(IB) D4'}</th>
                                <th>{'E4'}</th>
                                <th>{'(IB) E4'}</th>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div >
    );
  }
}
// export default Page2;

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(Page2);