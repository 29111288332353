import '../App.css';
import React from 'react';
import { language } from '../language';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Header2 from '../Component/Header';
import Table from 'react-bootstrap/Table';
import Moment from 'moment-timezone';
import ConfirmDelete from '../Component/Modal/ConfirmAddOrDelete';
// import Loading from '../Images/loading.gif';
import { Alert } from 'react-bootstrap';
import moment from 'moment-timezone';
// import { GamePlatformNumb1, GamePlatformNumb2 } from '../static';
// API getGame
const {  createGame, updateGame, updateGameStatus, getConfiguration, updateOpenClose,updateConfigurationLimit, getprocessLongTermOrder, getdeleteLongTermOrder, getSGDRateFNC, getTHAIRateFNC, updateSGDRateFNC, showSGDHistoryFNC, updateSingleShutdownFNC } = require('../Api');
// api deleteGame
var timer

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

  return { executeScroll, elRef };
};

class AddRNPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Min_Date: Moment().toDate(),
      Max_Date: Moment().toDate(),
      Games: ['Default'],
      GamesDetail: {DefaultLimit: ['Default'], Limit610: ['Default'], Limit640: ['Default'], Limit650: ['Default'], LimitBSA: ['Default'], LimitEveryone: ['Default']},
      GamesAvailable : [],
      GameLimitPackage: {},
      OriGameLimitPackage: {},
      redNumberData: {},
      diable: false,
      SelectedDate: new Date(),
      DetailBoolean: false,
      ChooseIDGameBoolean: false,
      selectedID: '',
      CreateGameApiResult: [],
      notificationMessage: '',
      notificationType: 'success',
      showNotification: false,
      AddDisable: false,
      UpdateBoolean: false,
      GameID: '',
      GDisplay: '',
      newArray:[],
      PressCount: 0,
      Authority: {},
      GameSettingArray: ['DefaultLimit', 'Limit610', 'Limit640', 'Limit650', 'LimitBSA', 'LimitEveryone'],
      // Platformss: {1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: '03', 10: '09'},
      Loading: false,
      PopScrollBackButton: false,
      OpenChangePassword: false,
      ButtonsType: '',
      UserPassword: '',
      SetSgd: '',
      SetThai: '',
      ViewSGDHistory: false,
      SGDHistory: [],
    }
    // this.closeAddForm = this.closeAddForm.bind(this);
    this.propsSetState = this.propsSetState.bind(this);
    this.addGameF = this.addGameF.bind(this);
    this.BetAllLongterm = this.BetAllLongterm.bind(this);
    this.DeleteLongTerm = this.DeleteLongTerm.bind(this);
    this.addUpdateF = this.addUpdateF.bind(this);
    this.DetailHide =  this.DetailHide.bind(this);
    this.CallAPI =  this.CallAPI.bind(this);
    this.elScroll = utilizeScroll();
  }

  // closeAddForm() {
  //   this.setState({GameLimitPackage: {Default: { all: '', B: '', S: '', A1: '', A1C: '', A: '', C: '', A2: '', A3: '', D4: '', E4: '', "5D": '', "6D": '' }} }, ()=>{
  //     // this.props.closeAddForm()
  //   })
  // }

  async componentDidMount() {
    this.CallAPI();
    this.SetUsersAuthorities();
    this.getSgdRate();
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  async CallAPI(){
    // const GDisplay = localStorage.getItem('GameDisplay');
    // const ArrayGame = await getGame( this.props.token, new Date(), this.props.username)
    const newArray = await getConfiguration(this.props.token, this.props.username)
    // console.log('newArray', newArray)
    let ArrayGame = newArray
    // console.log('ArrayGame => ', ArrayGame)
    const UpdateDefault = {
      'DefaultLimit': ArrayGame[0]['DefaultLimit'],
      'Limit610': ArrayGame[0]['Limit610'],
      'Limit640': ArrayGame[0]['Limit640'],
      'Limit650': ArrayGame[0]['Limit650'],
      'LimitBSA': ArrayGame[0]['LimitBSA'],
      'LimitEveryone': ArrayGame[0]['LimitEveryone'],
      'LimitStartToBlock': ArrayGame[0]['LimitStartToBlock'],
    }
    let ChangePackage = this.state.GamesDetail
    Object.keys(this.state.GamesDetail).forEach((item)=>{
      ChangePackage[item] = (this.state.GDisplay === 'MPTSWBK') ? ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'] : [ 'M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9']
    })
    this.setState({ 
      CreateGameApiResult: JSON.parse(JSON.stringify(newArray)), 
      GameLimitPackage: UpdateDefault, 
      OriGameLimitPackage: UpdateDefault, 
      GamesDetail: JSON.parse(JSON.stringify(ChangePackage))
    }, ()=>{
      this.setState({ Loading: true})
    })
  }

  textAllChange(game, value, objectkey) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = this.state.GameLimitPackage
      if (!gameObject[game]) gameObject[game] = {}
      const keys = Object.keys(gameObject[game][objectkey])
      for (let i = 0; i < keys.length; i += 1) {
        gameObject[game][objectkey][keys[i]] = Number(value)
      }

      this.setState({ GameLimitPackage: JSON.parse(JSON.stringify(gameObject))}, ()=> {
      })
      // this.props.dataChange('redNumberData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  textChange(game, subfield, value, objectkey) {
    if (/^[0-9]+$/.test(value) || value === '') {
      if (game === 'LimitStartToBlock') {
        const gameObject = this.state.GameLimitPackage
        gameObject[game] = Number(value)
        this.setState({ GameLimitPackage: JSON.parse(JSON.stringify(gameObject))})
      } else {
        const gameObject = this.state.GameLimitPackage
        gameObject[game][subfield][objectkey] = Number(value)
        this.setState({ GameLimitPackage: JSON.parse(JSON.stringify(gameObject))})
      }
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  gameChangeDetail(game, key) {
    let newGamesArray = this.state.GamesDetail[key]
    let GamesArray = this.state.GamesDetail
    // let newGameLimitPackage = this.state.GameLimitPackage
    if (this.state.GamesDetail[key].includes(game)) {
      newGamesArray.splice(this.state.GamesDetail[key].indexOf(game), 1);
      // delete newGameLimitPackage[game]
    } else {
      newGamesArray.push(game);
      // newGameLimitPackage[game] = newGameLimitPackage.Default
    }
    const defaultArray = (this.state.GDisplay === 'MPTSWBK') ? ['Default','M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'] : ['Default', 'M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'];
    const arrangedArray = newGamesArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    GamesArray[key] = arrangedArray
    // GameLimitPackage: JSON.parse(JSON.stringify(newGameLimitPackage))
    this.setState({ GamesDetail: JSON.parse(JSON.stringify(GamesArray)), })
  }

  AvailablegameChangeDetail(game) {
    let newGamesAvailableArray = this.state.GamesAvailable;

    if (game === 'ALL') {
      if (this.state.GamesAvailable.includes(game)) {
        newGamesAvailableArray = []
      } else {
        newGamesAvailableArray = (this.state.GDisplay === 'MPTSWBK') ? ['ALL', 'M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'] : ['ALL', 'M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9']
      }
    } else {
      if (this.state.GamesAvailable.includes(game)) {
        newGamesAvailableArray.splice(this.state.GamesAvailable.indexOf(game), 1);
      } else {
        newGamesAvailableArray.push(game);
      }
    }
    const defaultArray = (this.state.GDisplay === 'MPTSWBK') ? ['Default','M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'] : ['Default', 'M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'];
    const arrangedArrayAvailable = newGamesAvailableArray.sort((a,b) => {
      return defaultArray.indexOf(a) - defaultArray.indexOf(b);
    });
    this.setState({ GamesAvailable: JSON.parse(JSON.stringify(arrangedArrayAvailable))})
  }

  // renderBackgroundColor(game) {
  //   if (game === 'Default') return 'lightsteelblue'
  //   else if (game === 'M') return '#fff23e'
  //   else if ((this.state.GDisplay === 'MPTSWBK') ? game === 'P' : game === 'K') return '#414bb2'
  //   else if (game === 'T') return '#ff431b'
  //   else if (game === 'S') return '#32c6f4'
  //   else if (game === 'W') return 'green'
  //   else if (game === 'B') return '#fc0404'
  //   else if ( (this.state.GDisplay === 'MPTSWBK') ? game === 'K' : game === 'D') return '#f8c100'
  //   else if (game === '8') return '#6d100e'
  //   else return null
  // }

  SelectedDate(Date){
    this.setState({SelectedDate: Date })
  }

  modeChange(modeValue, field) {
    this.setState({ [field]: modeValue })
    if (modeValue === '') {
      this.setState({
        ChooseIDGameBoolean: false,
      })
    } else {
      this.setState({
        ChooseIDGameBoolean: true,
      })
    }
  }

  async CloseGame(){
    const newArray = await updateOpenClose('Close', this.props.token, this.props.username)
    
    if (newArray.message === "Successful Update Configuration Shutdown"){
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].GameUpdateSuccessful}`)
        // this.CallAPI();
      });
    }
  }

  async OpenGame(){
    const newArray = await updateOpenClose('Open', this.props.token, this.props.username)
    if (newArray.message === "Successful Update Configuration Shutdown"){
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].GameUpdateSuccessful}`)
        // this.CallAPI();
      });
    }
  }

  DetailHide(Status, Item){
    if (this.state.DetailBoolean !== false ) {
      this.setState({
        DetailBoolean: false,
        UpdateBoolean: false, 
        GamesDetail: ['Default'], 
        GamesAvailable : [],
        GameLimitPackage: JSON.parse(JSON.stringify(this.state.OriGameLimitPackage)),
      })
    } else {
      if(Status === 'Create' && this.state.CreateGameApiResult.length === 0) {
        this.setState({ DetailBoolean: true,UpdateBoolean: false, SelectedDate: new Date() }, ()=>{
          this.AddGame.focus();
        })
      }
      if(Status === 'Create' && this.state.CreateGameApiResult.length > 0) {
        const array = this.state.CreateGameApiResult
        const LastArray = array[array.length - 1];
        this.setState({ 
          DetailBoolean: true, 
          UpdateBoolean: false,
          SelectedDate: Moment(LastArray['Date']).add(1, 'd'),
          GamesDetail: ['Default'], 
          NewGameID: LastArray.ID + 1,
          GameLimitPackage: JSON.parse(JSON.stringify(this.state.OriGameLimitPackage)),
        }, ()=>{
          this.AddGame.focus();
        })
      }
      if(Status === 'Update' && this.state.CreateGameApiResult.length > 0) {
        const Detail = Object.keys(Item.Limit)
        const arrangedArray = Detail.sort(function (a, b) {
          return a.localeCompare(b); //using String.prototype.localCompare()
        });
        const newArray = [];
        [1,2,3,4,5,6,7,8,9].forEach((itemkey) => {
          if((Item[itemkey] === undefined ? 0 : Item[itemkey])!== 0){
            // const itemresultkey =(this.state.GDisplay === 'MPTSWBK') ? GamePlatformNumb2[itemkey] : GamePlatformNumb1[itemkey]
            newArray.push(itemkey)
          }
        })
        this.setState({ 
          UpdateBoolean: true, 
          DetailBoolean: true, 
          GamesAvailable: JSON.parse(JSON.stringify(newArray)),
          GamesDetail: arrangedArray, 
          GameLimitPackage: Item.Limit,
          SelectedDate: Moment(Item.Date),
          NewGameID: Item.ID ,
        },()=> {
          this.AddGame.focus();
        })
      }
      if(Status === 'Close') {
      }
    }
  }

  async addGameF(){
    this.setState({ AddDisable: true}, async ()=>{
      const ArrayKey = Object.keys(this.state.GameLimitPackage);
      const ArrayOject = ArrayKey.map((item) => {
        const Array = this.state.GameLimitPackage[item];
        delete Array['all']
        const ArrayValue = Object.values(Array)
        const ResultArray = ArrayValue.map((item) => item !== '')
        return ResultArray
      })
      const FinalAnswerArray = ArrayOject.map((item) => {
        const object = Object.values(item)
        return object.includes(false)
      })
      if (FinalAnswerArray.includes(true) ){
        alert(`${language[this.props.currentLanguage].NoBlank}`);
        this.setState({ AddDisable: false})
      } else {
        // alert(`success`);
        const ArrayCreateGame = await createGame(
          Moment(this.state.SelectedDate).format('YYYY-MM-DD'),
          this.state.GamesAvailable, 
          this.state.GameLimitPackage, 
          this.props.username, 
          this.props.token, )
  
        if (ArrayCreateGame.message === "Game created successful" ){
          this.setState({ notificationType: 'success',  AddDisable: false, SelectedDate: Moment(this.state.SelectedDate).add(1, 'd'), }, () => {
            this.openNotification(`${language[this.props.currentLanguage].Gamecreatedsuccessful}`)
          });
        } else {
          this.setState({ notificationType: 'error',  AddDisable: false }, () => {
            this.openNotification(`${language[this.props.currentLanguage].Unsuccess}`)
          });
        }
      }
    })
  }

  async addUpdateF(){
    const ArrayKey = Object.keys(this.state.GameLimitPackage);

    const ArrayOject = ArrayKey.map((item) => {
      const Array = this.state.GameLimitPackage[item];
      delete Array['all']
      const ArrayValue = Object.values(Array)
      const ResultArray = ArrayValue.map((item) => item !== '')
      return ResultArray
    })
    const FinalAnswerArray = ArrayOject.map((item) => {
      const object = Object.values(item)
      return object.includes(false)
    })

    if (FinalAnswerArray.includes(true) ){
      alert(`${language[this.props.currentLanguage].NoBlank}`);
    } else {
      const ArrayUpdateGame = await updateGame(
        this.state.SelectedDate,
        this.state.GamesAvailable, 
        this.state.GameLimitPackage, 
        this.state.NewGameID,
        this.props.username, 
        this.props.token )
      if (ArrayUpdateGame.message === "Game update successful" ){
        this.setState({ notificationType: 'success' }, () => {
          this.openNotification(`${language[this.props.currentLanguage].GameUpdateSuccessful}`)
        });
      } else {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification(`${language[this.props.currentLanguage].Unsuccess}`)
        });
      }
    }
  }


  async toggleDeletedCreateGameForm(ID_Deleted, status){
    const StatusResult = (status === 1) ? 0 : 1 ;
    this.setState({ AddDisable: true }, async ()=> {
      const r = (window.confirm( (status === 1) ? `${language[this.props.currentLanguage].ActiveGame}` : `${language[this.props.currentLanguage].RestoreGame}`));
      if (r === true) {
        const deleteGameResult = await updateGameStatus(ID_Deleted, StatusResult, this.props.username, this.props.token)
        if (deleteGameResult.message === 'Game Status Had Been Updated') {
          this.setState({ notificationType: 'success', AddDisable: false });
          this.openNotification(`GameStatusHadBeenUpdated`)
        }
      } else {
        this.setState({ AddDisable: false });
      }
    })
  }

  openNotification(message) {
    this.setState({ notificationMessage: message }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: '',
        notificationType: 'success',
        // AddDisable: false,
      });
      this.CallAPI();
    }, 1500);
  };

  async getSgdRate() {
    const getRate = await getSGDRateFNC(this.props.username, this.props.token)
    const getThai = await getTHAIRateFNC(this.props.username, this.props.token)
    if(getRate) {
      this.setState({ SetSgd: getRate, SetThai: getThai })
    }
  }

  onKeyEnter(event) {
    if (event.key === 'Enter') {
      this.SubmitData()
    }
  }

  onKeyEnterEditSgd(event, status) {
    if (event.key === 'Enter') {
      this.ConfirmEditSgd(status)
    }
  }

  SetSgdvalue(event, status) { if (status === 'sgd') { this.setState({ SetSgd: event.target.value.replace(/[^0-9.]/g, '') }) } else { this.setState({ SetThai: event.target.value.replace(/[^0-9.]/g, '') }) } }

  async ConfirmEditSgd(status) {
    const SetSgd = (status === 'sgd') ? this.state.SetSgd : this.state.SetThai
    if(SetSgd === '') {
      this.setState({ notificationType: 'error' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].SuccessUpdateRate}`)
      });
    } else {
      const passback = SetSgd.toString()
      const UpdateRate = await updateSGDRateFNC(status, passback, this.props.username, this.props.token)
      if(UpdateRate.message === 'update successful') {
        this.setState({ notificationType: 'success' }, () => {
          this.openNotification(`${language[this.props.currentLanguage].SuccessUpdate}`)
          this.getSgdRate();
        });
      }
    }
  }

  OpenHistorySGDRate() {
    this.setState({ ViewSGDHistory: !this.state.ViewSGDHistory }, () => {
      this.setState({ SGDHistory: [], Min_Date: Moment().toDate(), Max_Date: Moment().toDate() })
    })
  }

  ResetData(){
    this.setState({GameLimitPackage: JSON.parse(JSON.stringify( this.state.OriGameLimitPackage))})
  }

  async SubmitData(){
    const SubmitResult = await updateConfigurationLimit(this.state.GameLimitPackage, this.props.username, this.props.token)
    if (SubmitResult.message === "Successfully Update Limit"){
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].GameUpdateSuccessful}`)
      });
    }
  }

  // handleScroll = (event) => {    
  //   var node = event.target;
  //   const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
  //   if (bottom) {
  //     this.setState({PopScrollBackButton: bottom})
  //   } else {
  //     this.setState({PopScrollBackButton: bottom})
  //   }
  // }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 500) {
      this.setState({
        PopScrollBackButton: true
      });
    } else {
      this.setState({
        PopScrollBackButton: false
      });
    }
  }

  ProcessLongTerm() {
    this.setState({ OpenChangePassword: true, ButtonsType: 'BetAllLong' })
  }

  async BetAllLongterm() {
    const UserPassword = this.state.UserPassword
    if (UserPassword === '') {
      this.openNotification(`${language[this.props.currentLanguage].CurrentPasswordEmpty}`);
      this.setState({ notificationType: 'error' })
    } else {
      const ProccessLong = await getprocessLongTermOrder(UserPassword, this.props.username, this.props.token)
      if (ProccessLong.error === 'wrong password') {
        this.openNotification(`${language[this.props.currentLanguage].PasswordWrong}`);
        this.setState({ notificationType: 'error' })
      } else {
        if (ProccessLong.message === 'all long term have been order') {
          this.setState({ notificationType: 'success', OpenChangePassword: false, UserPassword: ''}, () => {
            this.openNotification(`${language[this.props.currentLanguage].AllLongTermBeenOrder}`)
          });
        }
      }
      
    }
  }

  DEleteLongtermClick() {
    this.setState({ OpenChangePassword: true, ButtonsType: 'DeleteAllLong' })
  }

  async DeleteLongTerm() {
    const UserPassword = this.state.UserPassword

    if (UserPassword === '') {
      this.openNotification(`${language[this.props.currentLanguage].CurrentPasswordEmpty}`);
      this.setState({ notificationType: 'error' })
    } else {
      const DeleteLong = await getdeleteLongTermOrder(UserPassword, this.props.username, this.props.token)
      if (DeleteLong.error === 'wrong password') {
        this.openNotification(`${language[this.props.currentLanguage].PasswordWrong}`);
        this.setState({ notificationType: 'error' })
      } else {
        if (DeleteLong.message === 'Delete Successfully') {
          this.setState({ notificationType: 'success', OpenChangePassword: false, UserPassword: ''}, () => {
            this.openNotification(`${language[this.props.currentLanguage].AllLongTermDelete}`)
          });
        }
      }
    }
  }

  setSelectedDate(Min_Date) {
    this.setState({ Min_Date, Max_Date: Min_Date })
  }

  MaxsetSelectedDate(Max_Date) {
    this.setState({ Max_Date })
  }

  async SearchSGDHistory(){
    const Min_Date = this.state.Min_Date
    const Max_Date = this.state.Max_Date

    const GetHistory = await showSGDHistoryFNC(Min_Date, Max_Date, this.props.token, this.props.username)
    // console.log('GetHistory', GetHistory)
    if (GetHistory) {
      this.setState({ SGDHistory: GetHistory })
    }
  }

  propsSetState(obj) {
    this.setState(obj)
  }

  async CloseSelectedGame(type, opentype) {
    const newArray = await updateSingleShutdownFNC(type, opentype, this.props.token, this.props.username)
    if (newArray.message === "Update Success"){
      this.setState({ notificationType: 'success' }, () => {
        this.openNotification(`${language[this.props.currentLanguage].GameUpdateSuccessful}`)
        // this.CallAPI();
      });
    }
  }

  render() {
    return(
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowY: 'auto' }}> 
        <div >
          <Header2 />
        </div>
        <div ref={this.elScroll.elRef} className="dcc" >
          <div className="redNumberTitle form-control" style={{ width: '25%', fontSize: '18px', marginLeft: 'auto', marginRight: 'auto' }}>
            <b> {'Game Setting'} </b>
          </div>
        </div>
        {(this.state.Loading === true)&&<div className="dcc" style={{ flexDirection: ''}}>
          <div className="redNumberTitle" style={{ width: '65%', marginRight:'5%', fontSize: '17px', marginTop: '1%' }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Status</th>
                  <th>ShutDown Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>MAG , TOTO</td>
                  <td>{(this.state.CreateGameApiResult[0]['ShutDown']) === 0 ? 'ON' : 'OFF'}</td>
                  <td className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>7.00 pm</div>
                    {(this.state.CreateGameApiResult[0]['ShutDown']) === 0 ? 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('MAGTOTO', 'Close')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #d83c44', backgroundColor: '#d83c44', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Close}
                      </div>
                      : 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('MAGTOTO', 'Open')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #48a444', backgroundColor: '#48a444', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Open}
                      </div>
                    }
                  </td>
                </tr>
                <tr>
                  <td>SINGAPORE</td>
                  <td>{(this.state.CreateGameApiResult[0]['S_ShutDown']) === 0 ? 'ON' : 'OFF'}</td>
                  <td className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>6.25 pm</div>
                    {(this.state.CreateGameApiResult[0]['S_ShutDown']) === 0 ? 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('SINGAPORE', 'Close')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #d83c44', backgroundColor: '#d83c44', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Close}
                      </div>
                      : 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('SINGAPORE', 'Open')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #48a444', backgroundColor: '#48a444', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Open}
                      </div>
                    }
                  </td>
                </tr>
                {/* <tr>
                  <td>THAILAND</td>
                  <td>{(this.state.CreateGameApiResult[0]['S_ShutDown']) === 0 ? 'ON' : 'OFF'}</td>
                  <td>6.25 pm</td>
                </tr> */}
                <tr>
                  <td>GRAND DRAGON</td>
                  <td>{(this.state.CreateGameApiResult[0]['EightShutDown']) === 0 ? 'ON' : 'OFF'}</td>
                  <td className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>7.00 pm</div>
                    {(this.state.CreateGameApiResult[0]['EightShutDown']) === 0 ? 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('GRANDDRAGON', 'Close')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #d83c44', backgroundColor: '#d83c44', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Close}
                      </div>
                      : 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('GRANDDRAGON', 'Open')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #48a444', backgroundColor: '#48a444', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Open}
                      </div>
                    }
                  </td>
                </tr>
                <tr>
                  <td>9 LOTTO</td>
                  <td>{(this.state.CreateGameApiResult[0]['NineShutDown']) === 0 ? 'ON' : 'OFF'}</td>
                  <td className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>7.30 pm</div>
                    {(this.state.CreateGameApiResult[0]['NineShutDown']) === 0 ? 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('9LOTTO', 'Close')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #d83c44', backgroundColor: '#d83c44', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Close}
                      </div>
                      : 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('9LOTTO', 'Open')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #48a444', backgroundColor: '#48a444', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Open}
                      </div>
                    }
                  </td>
                </tr>
                {/* <tr>
                  <td>3J</td>
                  <td>{(this.state.CreateGameApiResult[0]['Zero3ShutDown']) === 0 ? 'ON' : 'OFF'}</td>
                  <td className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>3.00 pm</div>
                    {(this.state.CreateGameApiResult[0]['Zero3ShutDown']) === 0 ? 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('3J', 'Close')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #d83c44', backgroundColor: '#d83c44', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Close}
                      </div>
                      : 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('3J', 'Open')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #48a444', backgroundColor: '#48a444', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Open}
                      </div>
                    }
                  </td>
                </tr> */}
                {/* <tr>
                  <td>9J</td>
                  <td>{(this.state.CreateGameApiResult[0]['Zero9ShutDown']) === 0 ? 'ON' : 'OFF'}</td>
                  <td className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>9.00 pm</div>
                    {(this.state.CreateGameApiResult[0]['Zero9ShutDown']) === 0 ? 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('9J', 'Close')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #d83c44', backgroundColor: '#d83c44', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Close}
                      </div>
                      : 
                      <div className='CloseButton' onClick={() => this.CloseSelectedGame('9J', 'Open')} style={{ marginLeft: 10, borderRadius: 10, paddingLeft: 10, paddingRight: 10, border: '1px solid #48a444', backgroundColor: '#48a444', color: 'white', cursor: 'pointer'}}>
                        {language[this.props.currentLanguage].Open}
                      </div>
                    }
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
          <div className="redNumberTitle dcc" style={{ width: '10%', fontSize: '18px', marginTop: '1%', justifyContent: 'space-between' }}>
            <Button className='button2' variant="danger" disabled={this.state.AddDisable }  style={{ minWidth: '40%', fontWeight: 'bold'}} onClick={() => this.CloseGame()}>{language[this.props.currentLanguage].Close}</Button>
            <Button  className='button2' variant="success" disabled={this.state.AddDisable }  style={{ minWidth: '40%',  fontWeight: 'bold'}} onClick={() => this.OpenGame()}>{language[this.props.currentLanguage].Open}</Button>
          </div>
        </div>}
        {(this.props.Role === 'superAdmin' || this.props.Role === 'admin') && 
        <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'bold' }}>
          <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold' }}>
            <div style={{ marginRight: 5 }}>{`${language[this.props.currentLanguage].CurrentSgdRate}`}</div>
            <Form.Control style={{ width: 'auto', textAlign: 'center', fontWeight: 'bold' }} onKeyPress={(e) => this.onKeyEnterEditSgd(e, 'sgd')} type="text" value={this.state.SetSgd} onChange={(e) => this.SetSgdvalue(e, 'sgd')}/>
            <Button className='button2' style={{  fontWeight: 'bold', width: 'auto',  marginRight: 15, marginLeft: 5, backgroundColor: '#32C6F4', borderColor: '#32C6F4' }} onClick={() => this.ConfirmEditSgd('sgd')}>{language[this.props.currentLanguage].Edit}</Button>
            <div style={{ marginRight: 5 }}>{`${language[this.props.currentLanguage].CurrentThaiRate}`}</div>
            <Form.Control style={{ width: 'auto', textAlign: 'center', fontWeight: 'bold' }} onKeyPress={(e) => this.onKeyEnterEditSgd(e, 'thai')} type="text" value={this.state.SetThai} onChange={(e) => this.SetSgdvalue(e, 'thai')}/>
            <Button className='button2' style={{  fontWeight: 'bold', width: 'auto', marginLeft: 5, backgroundColor: '#32C6F4', borderColor: '#32C6F4' }} onClick={() => this.ConfirmEditSgd('thai')}>{language[this.props.currentLanguage].Edit}</Button>
            <Button className='button2' style={{  fontWeight: 'bold', width: 'auto', marginLeft: 5, backgroundColor: (this.state.ViewSGDHistory === true) ? 'green' : '#32C6F4', borderColor: (this.state.ViewSGDHistory === true) ? 'green' : '#32C6F4' }}
              onClick={() => this.OpenHistorySGDRate()}>
              {(this.state.ViewSGDHistory === true) ? language[this.props.currentLanguage].CloseSGDHistory : language[this.props.currentLanguage].ViewSGDHistory}
            </Button>
          </div>
          {this.state.ViewSGDHistory === true && 
            <div className='dcc' style={{ display: 'flex', flexDirection: 'column', marginTop: 10, width: '100%' }}>
              <div className='dcc' style={{ display: 'flex', flexDirection: 'row' }}>
                <DatePicker
                  onChange = {(date) => this.setSelectedDate(date)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  // maxDate={new Date()}
                  // popperPlacement="top-right"
                  selected={this.state.Min_Date}
                  className={"form-control StyleDatePicker"}
                  // withPortal
                />
                <div style={{fontSize: "18px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>To</div>
                <DatePicker
                  onChange = {(Maxdate) => this.MaxsetSelectedDate(Maxdate)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  // maxDate={new Date()}
                  // popperPlacement="top-right"
                  selected={this.state.Max_Date}
                  minDate={new Date(this.state.Min_Date)}
                  maxDate={new Date()}
                  className={ "form-control StyleDatePicker"}
                  // withPortal
                />
                <Button className='button2' style={{ width: 'auto', marginLeft: 5, backgroundColor: '#32C6F4', borderColor: '#32C6F4' }} onClick={() => this.SearchSGDHistory()}>{language[this.props.currentLanguage].Search}</Button>
              </div>
              {this.state.SGDHistory.length > 0 && 
              <div className='dcc' style={{ width: '50%'}}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginTop: 10 }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '50%', border: '1px solid black' }}>{language[this.props.currentLanguage].Date}</div>
                    <div style={{ width: '50%', border: '1px solid black' }}>{language[this.props.currentLanguage].SgdRate}</div>
                    <div style={{ width: '50%', border: '1px solid black' }}>{language[this.props.currentLanguage].ThaiRate}</div>
                  </div>
                  {this.state.SGDHistory.map((items,indexsgd) => {
                    return(
                      <div key={indexsgd} style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '50%', padding: 3, border: '1px solid black' }}>{moment(items.Date).format('YYYY-MM-DD')}</div>
                        <div style={{ width: '50%', padding: 3, border: '1px solid black' }}>{items.Rate}</div>
                        <div style={{ width: '50%', padding: 3, border: '1px solid black' }}>{items.ThaiRate}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              }
            </div>
          }
        </div>
        }
        <div className="dcc" style={{ justifyContent: 'center' }}>
          <Alert style={{ zIndex: 99, position: 'fixed', top: 100}} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        {(this.state.Loading === true)&&<div style={{ border: '1px solid #000', marginBottom: '2.5%', marginTop: '1%', overflowX: 'auto'}}>
          <div>
            <Table responsive="sm" striped bordered hover>
              <thead style={{ backgroundColor: 'lightsteelblue' }}>
                <tr>
                  <th><b> {language[this.props.currentLanguage].DateTime} </b></th>
                  <th className="dcc" style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div></div>
                    <b> {language[this.props.currentLanguage].Limit} </b>
                    <div>
                      {this.props.Role === 'superAdmin' && 
                      <>
                        <Button className='button2' style={{ fontWeight: 'bold', marginRight: 5 }} onClick={() => this.ProcessLongTerm()}>
                          {language[this.props.currentLanguage].BetAllLongterm}
                        </Button>
                        <Button className='button2' style={{ fontWeight: 'bold' }} variant="danger" onClick={() => this.DEleteLongtermClick()}>
                          {language[this.props.currentLanguage].DeleteallLongTermButton}
                        </Button>
                      </>
                      }
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.GameSettingArray.map((item, indexGameS) =>{
                  return<tr key={indexGameS} style={{ backgroundColor: 'white'}}>
                    <td style={{ verticalAlign: 'middle', fontWeight: 'bold'}}>{(item === 'DefaultLimit') ? 'Default Limit' : (item === 'Limit610') ? ' 6.10 pm' : (item === 'Limit640') ? '6.40 pm' : (item === 'Limit650') ? '6.50 pm' : (item === 'LimitEveryone') ? 
                      language[this.props.currentLanguage].LimitEveryone : (item === 'LimitBSA') ? language[this.props.currentLanguage].LimitBSA :  item}</td>
                    <td style={{ verticalAlign: 'middle'}}>
                      {item === 'LimitBSA' && <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <div style={{ marginRight: 10, fontWeight: 'bold'}}><b>开始限制B+S+A1数目</b></div>
                        <Form.Control style={{ width: 70, textAlign: 'center', fontWeight: 'bold'}} onKeyPress={(e) => this.onKeyEnter(e)} maxLength='4' type="text" value={this.state.GameLimitPackage.LimitStartToBlock} onChange={(e) => this.textChange('LimitStartToBlock', '', e.target.value)}/>
                      </div>}
                      {this.state.GamesDetail[item].map((game, index) => {
                        return <>{(this.state.GameLimitPackage[item][index + 1])&&<div key={index}
                          className={(game === 'M') ? 'BackgroundColorM' : ((this.state.GDisplay === 'MPTSWBK') ? game === 'P' : game === 'K')  ? 'BackgroundColorK' :  (game === 'T') ? 'BackgroundColorT' : 
                            (game === 'S') ? 'BackgroundColorS' : (game === 'W') ? 'BackgroundColorW' : (game === 'B') ? 'BackgroundColorB' :  
                              ( (this.state.GDisplay === 'MPTSWBK') ? game === 'K' : game === 'D') ? 'BackgroundColorD' : (game === '8') ? 'BackgroundColor8' :  (game === '9') ? 'BackgroundColor9' : ''} 
                          style={{ marginBottom: 5, display: 'flex', alignItems: 'center', paddingTop: 10, borderRadius: 10, color: ((game === '3') ? '#fff' : (game === '8') ? '#fff' : '#000')}}>
                          <div style={{ margin: '0px 10px', width: 70, fontSize: 18}}><b>{game}</b></div>
                          {game === 'T' || game === '8' || game === '9' ? ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', '5D', '6D'].map((itemkey, indd) => {
                            return <div key={indd} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                              <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{itemkey}</b></div>
                              <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10, fontWeight: 'bold'}}      onKeyPress={(e) => this.onKeyEnter(e)} maxLength='4' type="text" value={this.state.GameLimitPackage[item][index + 1][itemkey] || ''} onChange={(e) => this.textChange(item, (index + 1), e.target.value, itemkey)}/>
                            </div>
                          }) : ['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF'].map((itemkey, indd) => {
                            return <div key={indd} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                              <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{itemkey}</b></div>
                              <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10, fontWeight: 'bold'}}      onKeyPress={(e) => this.onKeyEnter(e)} maxLength='4' type="text" value={this.state.GameLimitPackage[item][index + 1][itemkey]} onChange={(e) => this.textChange(item, (index + 1), e.target.value, itemkey)}/>
                            </div>
                          })}
                        </div>}</>
                      })}
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
            <div className="redNumberTitle dcc" style={{ width: '100%', fontSize: '18px', marginTop: '1%' }}>
              <Button   className='button2' style={{marginRight: '5%', fontWeight: 'bold'}} variant="warning" disabled={this.state.AddDisable }  onClick={() => this.ResetData()}>{language[this.props.currentLanguage].Reset}</Button>
              <Button  className='button2' style={{ fontWeight: 'bold'}} variant="success" disabled={this.state.AddDisable }  onClick={() => this.SubmitData()}>{language[this.props.currentLanguage].Submit}</Button>
            </div>
          </div>
          <hr/>
        </div>}
        {(this.state.PopScrollBackButton)&&<div className={"scroll-to-top iconpopup button2"}  
          onClick={this.elScroll.executeScroll}>
          {/* ⇧ */}
          <div  className="icon" style={{ fontSize: 25, fontWeight: 'bold', cursor: 'pointer' }}>▴</div>
        </div>}
        <ConfirmDelete OpenChangePassword={this.state.OpenChangePassword} propsSetState={this.propsSetState} showNotification={this.state.showNotification} notificationType={this.state.notificationType} notificationMessage={this.state.notificationMessage} ShowPasswordNew={this.state.ShowPasswordNew} UserPassword={this.state.UserPassword} ButtonsType={this.state.ButtonsType} BetAllLongterm={this.BetAllLongterm} DeleteLongTerm={this.DeleteLongTerm}/>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}

export default connect(mapStateToProps, null)(AddRNPackage);