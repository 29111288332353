import React, { Component } from 'react';
import GDicon from '../../Images/Cards/GrandDragonLotto.png'
import Moment from 'moment-timezone';
import {language} from '../../language';
import { connect } from 'react-redux';
import { characterList } from '../../static';
// import { isMobile } from 'react-device-detect';
// import { characterList } from '../../static';

class Gd extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const Special = [1,2,3,4,5,6,7,8,9,10,11,12,13]
    const consolation = [14,15,16,17,18,19,20,21,22,23]
    return(
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="draganborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={GDicon} alt="Logo" className="logoiconimages"/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'white' }}><b>{language[this.props.currentLanguage].GdToto}</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5, color: 'white' }}>{this.props.gd.drawNo}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold', color: 'white' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="dcc dragonborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].first}
          </div>
          <div className="dcc dragonborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].second}
          </div>
          <div className="dcc dragonborderclass" style={{ width: 'calc(100% / 3)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].third}
          </div>
        </div>
        <div className="numberborder" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="resultP1P2P3" style={{ width: 'calc(100% / 3)'}}>{this.props.gd.P1 || '----'}</div>
            <div className="resultP1P2P3"style={{ width: 'calc(100% / 3)'}}>{this.props.gd.P2 || '----'}</div>
            <div className="resultP1P2P3" style={{ width: 'calc(100% / 3)'}}>{this.props.gd.P3 || '----'}</div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10, fontSize: '0.9vw' }}>
          <div className = "dcc dragonborderclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'white' }}>
            {language[this.props.currentLanguage].special}
          </div>
        </div>

        <div className="numberborderdspecon" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              Special
                ? Special.map((item, index) => {
                  let itemList = [<div key={index} style={{ width: 'calc(100% / 5)', padding: '8px', position: 'relative', color: 'black', textDecoration: 'none' }}>
                    <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '15px' }}>{characterList[item]}</span>
                    {this.props.gd[item] || '----'}
                  </div>]
                  if (index === 10) {
                    itemList.unshift(<div key={'14'} style={{ width: 'calc(100% / 5)', padding: '8px' }}>
                      {' '}
                    </div>)
                  }
                  return itemList
                })
                : [...Array(13)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc dragonborderclass" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].consolation}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 30, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              consolation
                ? consolation.map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)', position: 'relative', padding: '8px', color: 'black', textDecoration: 'none' }}>
                  <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '15px' }}>{characterList[item]}</span>
                  {this.props.gd[item] || '----' }
                </div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
      </div>
    );
  }
}

// export default Gd;

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}
  
export default connect(mapStateToProps, null)(Gd)