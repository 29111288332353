import '../App.css';
import React from 'react';
import { language } from '../language';
import Alert from 'react-bootstrap/Alert'
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table'
import Header from '../Component/Header';
import CheckMark from '../Images/CheckMark.png';
import {ReturnBetValidation} from '../static/ReturnBetValidation';
import { isMobile } from 'react-device-detect';
import Moment from 'moment-timezone';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Platformcolor } from '../static/PlatformColor';
const { CreateBetOrder, getKeyInFormat, getMyDownline, CancelBet, getUserKeyIn } = require('../Api');
var timer

class BetBoxVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      token: this.props.token,
      UserID: this.props.userID,
      userID: this.props.userID,
      TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
      BetNumbers: [],
      KeyinFormat: '',
      DDate: '请选择日期 (D#)',
      KeyinType: '1',
      PressCount: 0,
      ClaimMagnumStatus: false,
      PlatformSelected: [],
      PrevPlatformSelected: [],
      platform: '',
      PlatformLength: '',
      TestingChecke: false,
      isChecked: '',
      allChecked: false,
      IBox: false,
      Key2: false,
      Mode: '-',
      AllMagnum: false,
      AllChangeKey2: false,
      Key1IBox: [],
      Key2IBox: [],
      PlatformSelectAll: {
        1: false,
        2: false,
        3: false, 
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
      },
      ModeSelectAll: {
        1: false,
        Mode: '',
      },
      IBoxSelectAll: {
        1: false,
        Mode: '',
      },
      KeyBetAll: {
        0: false,
        1: false,
        2: false, 
        3: false,
        4: false,
        5: false,
      },
      KeyBetAllPassValues: {
        0: '',
        1: '',
        2: '', 
        3: '',
        4: '',
        5: '',
      },
      modalShow: false,
      ModalDate: [{ Date: '' }],
      DisplayDate: [],
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      Receipt: '',
      ShowReceipt: false,
      key3D: [],
      FiveD: false,
      SixD: false,
      getUsers: [],
      UserIDforCancel: '',
      OriTextforCancel: '',
      RoleforCancel: '',
      ServerIDforCancel: '',
      SuccessFullCancel: false,
      five6D: false,
      PhoneNumber: '',
      GamesDisplay:'',
      ReceiptDisplay: '',
      optionsUsername: '',
      SelectedUser: '',
      DisabledClick: false,
      checkEmpty: '',
      JinNanCheck: '',
      CopyReceipt: '',
      ButtonBackgroundblue: '#3C96F4',
      ButtonBackgroundRed: '#EE3E13',
      is_visible: false,
      OneStar: '',
      PlatformShow: [],
    }
    this.handleBetNumbers = this.handleBetNumbers.bind(this);
    this.CheckedAllPlatform = this.CheckedAllPlatform.bind(this);
    this.CheckPlatform = this.CheckPlatform.bind(this);
    this.AllKey2 = this.AllKey2.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.ConvertToOriginalWord = this.ConvertToOriginalWord.bind(this);
    this.StartBetting = this.StartBetting.bind(this);
    this.PassToAPI = this.PassToAPI.bind(this);
  }

  async componentDidMount() {
    this.showPlatform();
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    if ((UserAuthorities && UserAuthorities.Bet) === 0) {
      window.location.href='/Home'; 
    } else {
      this.setState({ OneStar: UserAuthorities.OneStarBao }, ()=> {
        this.StartBetting();
        this.getDownline();
      })
    }
  }

  showPlatform() {
    this.setState({ PlatformShow: ['1', '2', '3', '4', '5', '6', '7', '8'] })
  }

  async StartBetting() {
    const DateLoop = this.GetModalDate();
    const GDisplay = localStorage.getItem('GameDisplay');

    this.setState({
      GamesDisplay: GDisplay,
    })
    const KeyinFunctions = await getKeyInFormat(
      this.state.username,
      this.state.token,
    );
    if (KeyinFunctions) {
      this.setState({ KeyinFormat: KeyinFunctions || [], ModalDate: DateLoop, DDate: `日期: D# ${DateLoop.DateLoop[0].Date}`  || [] }, () => {
        const KeyInResult = this.state.KeyinFormat;
        const CurrentBtFormula = (this.state.AllChangeKey2 === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
        const CurrentBtFormulaLength = CurrentBtFormula.length;
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormula[i]] = ""
        }
        this.setState(prevWordState => ({
          BetNumbers: [
            ...prevWordState.BetNumbers,
            {
              ...prevWordState.BetNumbers,
              // Text: "", Bet: { ...object }, Platform: [], SelectedDate: `D#*${DateLoop.DateLoop[0].Number}`, IBox: false, Mode: '-', Key2: false, input56D: false, five6D: false,
              Text: "", Bet: { ...object }, Platform: [], SelectedDate: `D#`, IBox: false, Mode: '-', Key2: false, input56D: false, five6D: false, Follow: '',
            },
            {
              ...prevWordState.BetNumbers,
              Text: "", Bet: { ...object }, Platform: [], SelectedDate: '', IBox: false, Mode: '-', Key2: false, input56D: false, five6D: false, Follow: '',
            },
          ]
        }));
        const BtKey1Formula = KeyInResult.KeyIn1 || [];
        const BtKey2Formula = KeyInResult.KeyIn2 || [];
        const BtKey1FormulaLength = BtKey1Formula.length;
        var Key1Box = [];
        var Key2Box = [];
        let Key3Formula = []
        for (let i = 0; i < BtKey1FormulaLength; i++) {
          Key1Box.push((`(IB)${BtKey1Formula[i]}`).slice(0))
          Key2Box.push((`(IB)${BtKey2Formula[i]}`).slice(0))
        }
        // Key3Formula = BtKey1Formula.filter(function(item) { 
        //   return ['A', 'C'].includes(item);
        // })

        if(BtKey1Formula.includes('A') && BtKey1Formula.includes('C')) {
          Key3Formula = BtKey1Formula.filter(function(item) {
            return ['A', 'C'].includes(item); 
          })
        } else {
          Key3Formula = ['A', 'C']
        }
        this.setState({Key1IBox: Key1Box || [], Key2IBox: Key2Box || [], key3D: Key3Formula}, ()=> {
          this[`InputBetNumber${'0'}`].focus();
        })
      })
    }
  }

  async getDownline() {
    const UserID = this.state.userID;
    const Username = this.state.username;
    const token = this.state.token
    let tempArray = []
    let tempObject = {}

    const DownlineUsername = await getMyDownline(UserID, token, Username)
    DownlineUsername.forEach((item) => {
      tempObject = {
        ...tempObject,
        value: item.ID,
        label: item.Username,
      }
      tempArray.push(tempObject)
    })
    if(DownlineUsername) {
      this.setState({getUsers: DownlineUsername, UserID: DownlineUsername[0].ID, SelectedUser: tempArray[0], optionsUsername: tempArray, JinNanCheck: DownlineUsername[0].JinNan })
    }
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  // async SelectedUsername(e) {
  //   this.setState({ UserID: e.target.value}, ()=> {
  //     this.getTargetUserKeyIn(this.state.UserID);
  //   })
  // }

  SelectedUser(e) {
    this.setState({ UserID: e.value, SelectedUser: e }, () => {
      this.getTargetUserKeyIn(this.state.UserID);
    })
  }

  async getTargetUserKeyIn(TargetUserID) {
    const UserKeyIn = await getUserKeyIn(TargetUserID, this.props.token, this.props.username)
    if(UserKeyIn) {
      this.setState({ KeyinFormat: UserKeyIn[0] || [], JinNanCheck: UserKeyIn[0].JinNan, OneStar: UserKeyIn[0].OneStarBao  }, () => {
        const KeyInResult = this.state.KeyinFormat;
        const BtKey1Formula = KeyInResult.KeyIn1 || [];
        const BtKey2Formula = KeyInResult.KeyIn2 || [];
        const BtKey1FormulaLength = BtKey1Formula.length;
        var Key1Box = [];
        var Key2Box = [];
        let Key3Formula = []
        for (let i = 0; i < BtKey1FormulaLength; i++) {
          Key1Box.push((`(IB)${BtKey1Formula[i]}`).slice(0))
          Key2Box.push((`(IB)${BtKey2Formula[i]}`).slice(0))
        }

        if(BtKey1Formula.includes('A') && BtKey1Formula.includes('C')) {
          Key3Formula = BtKey1Formula.filter(function(item) {
            return ['A', 'C'].includes(item); 
          })
        } else {
          // Key3Formula = BtKey2Formula.filter(function(item) {
          //   return ['A', 'C'].includes(item); 
          // })
          Key3Formula = ['A', 'C']
        }

        this.updateWordsValuebySelectedUser(this.state.BetNumbers);
        this.setState({Key1IBox: Key1Box || [], Key2IBox: Key2Box || [], key3D: Key3Formula})
      });
    }
  }

  async updateWordsValuebySelectedUser(BetNumbers) {
    const KeyinFormat = this.state.KeyinFormat
    const Key1Input = KeyinFormat.KeyIn1
    const Key2Input = KeyinFormat.KeyIn2
    // let Key3Formula = ''
    
    // if(Key1Input.includes('A') && Key1Input.includes('C')) {
    //   Key3Formula = Key1Input.filter(function(item) {
    //     return ['A', 'C'].includes(item); 
    //   })
    // } else {
    //   Key3Formula = Key2Input.filter(function(item) {
    //     return ['A', 'C'].includes(item); 
    //   })
    // }

    // this.setState({ key3D: Key3Formula }, ()=> {
    // })

    if(BetNumbers.length !== 0) {
      for(var i=0; i<BetNumbers.length; i++) {
        const tempObject = {}
        if(BetNumbers[i].Key2 === false) {
          const Bet = BetNumbers[i].Bet
          for(var j=0; j<Object.keys(Bet).length; j++) {
            tempObject[Key1Input[j]] = Object.values(Bet)[j]
          }
          BetNumbers[i].Bet = JSON.parse(JSON.stringify(tempObject))
        } else if(BetNumbers[i].Key2 === true) {
          const Bet = BetNumbers[i].Bet
          for(var e=0; e<Object.keys(Bet).length; e++) {
            tempObject[Key2Input[e]] = Object.values(Bet)[e]
          }
          BetNumbers[i].Bet = JSON.parse(JSON.stringify(tempObject))
        }
      }
    }
  }

  GetModalDate() {
    const DateLoop = [];
    const TodayDate = Moment().format('YYMMDD');
    if(Moment().day() === 0) {
      DateLoop.push({
        Date: Moment().format('DDMM(ddd)'),
        Number: 7,
        CheckMark: false,
      });
    } else {
      for (let i = 1; i < 8; i++) {
        const Date = Moment().day(i);
        if (Moment(Date).format('YYMMDD') >= TodayDate) {
          DateLoop.push({
            Date: Moment(Date).format('DDMM(ddd)'),
            Number: Moment(Date).isoWeekday(),
            CheckMark: false,
          });
        }
      }
    }
    return { DateLoop };
  }

  async SelectDateFunction(Index) {
    const toProcessDateData = this.state.ModalDate;
    if (toProcessDateData.DateLoop[Index.Index].CheckMark === false) {
      toProcessDateData.DateLoop[Index.Index].CheckMark = true;
    } else {
      toProcessDateData.DateLoop[Index.Index].CheckMark = false;
    }
    this.setState({ ModalDate: toProcessDateData });
  }

  onPressConfirmSelectDate() {
    const { DisplayDate } = this.state;
    const BetNumbers = [...this.state.BetNumbers]
    const toProcessDateData = this.state.ModalDate;
    let DateData = '';
    let DateDisplay = '';
    for (let i = 0; i < toProcessDateData.DateLoop.length; i++) {
      if (toProcessDateData.DateLoop[i].CheckMark === true) {
        DateData += toProcessDateData.DateLoop[i].Number;
        DateDisplay += toProcessDateData.DateLoop[i].Date;
      }
    }
    if (DateDisplay !== ''){
      BetNumbers[0] = { ...BetNumbers[0], SelectedDate: `D#*${DateData}` }
      DisplayDate[0] = `日期： D# ${DateDisplay}`
    } else {
      DisplayDate[0] = `请选择日期 (D#)`
      BetNumbers[0] = { ...BetNumbers[0], SelectedDate: '' }
      this.setState({selectedDate: []})
    }
    this.setState({ modalShow: false, DDate: DisplayDate[0], BetNumbers: BetNumbers  });
  }

  onpressLongTerm() {
    const toProcessDateData = this.state.ModalDate;
    const BetNumbers = [...this.state.BetNumbers]

    for(var i = 0; i < toProcessDateData.DateLoop.length; i++) {
      if(toProcessDateData.DateLoop[i].CheckMark === true) {
        toProcessDateData.DateLoop[i].CheckMark = false
      }
    }

    BetNumbers[0] = { ...BetNumbers[0], SelectedDate: `D#*` }
    this.setState({ modalShow: false, DDate: '日期： D#AUTO', BetNumbers: BetNumbers, ModalDate: toProcessDateData });
  }

  async ConfirmSaveBet() {
    const BetNumbers = this.state.BetNumbers;
    const BetNumberslength = (BetNumbers[BetNumbers.length - 1].Text === '') ? BetNumbers.length - 1 : BetNumbers.length;
    const PlatformCheck = [];
    const SelectedDateCheck = [];
    const EmptyBetN = [];
    const BetNLength = [];
    const KeyNumberValuesCheck = [];
    const BetNLengthArray = [];
    const fiveDErrorArray = [];
    const SixDErrorArray = [];
    let where = '';
    let insertgame = '';
    let platformGame = '';
    let fiveDError = '';
    let SixDError = '';

    for(var i = 0; i < BetNumberslength; i++) {
      BetNLengthArray.push((BetNumbers[i].Text).length)
      const ObjectValueBet = Object.values(BetNumbers[i].Bet);
      if(!BetNumbers[i].Platform.length) {
        PlatformCheck.push('Fail')
        platformGame += `${i + 1},`
      } else {
        PlatformCheck.push('Completed')
      }
      if(BetNLengthArray.includes(0)) {
        EmptyBetN.push('Fail')
        insertgame += `${i + 1},`
      } else if (BetNLengthArray.includes(1) || BetNLengthArray.includes(2)) {
        BetNLength.push('Fail')
      } else {
        BetNLength.push('Completed')
        EmptyBetN.push('Completed')
      }
      if(ObjectValueBet[0] === '' && ObjectValueBet[1] === '' 
            && ObjectValueBet[2] === '' && ObjectValueBet[3] === ''
            && ObjectValueBet[4] === '' && ObjectValueBet[5] === '') {
        KeyNumberValuesCheck.push('Fail')
        where += `${i + 1},`
      } 
      else {
        KeyNumberValuesCheck.push('Completed')
      }
      if(((BetNumbers[i].Text).length === 5 && BetNumbers[i].Mode !== '-') 
      || ((BetNumbers[i].Text).length === 5 && BetNumbers[i].IBox === true)) {
        fiveDErrorArray.push('Fail')
        fiveDError += `${i + 1},`
      } else {
        fiveDErrorArray.push('Completed')
      }
      if(((BetNumbers[i].Text).length === 6 && BetNumbers[i].Mode !== '-')
      || ((BetNumbers[i].Text).length === 6 && BetNumbers[i].IBox === true)) {
        SixDErrorArray.push('Fail')
        SixDError += `${i + 1},`
      } else {
        SixDErrorArray.push('Completed')
      }
    }

    if(BetNumbers[0].SelectedDate === "") {
      SelectedDateCheck.push('Fail');
    } else {
      SelectedDateCheck.push('Completed');
    }

    if(SelectedDateCheck.includes('Fail') === true) {
      this.openNotification('PleaseSelectDate')
      this.setState({ notificationType: 'error' });
    } else if(EmptyBetN.includes('Fail')) {
      this.openNotification('pleaseinputallyourgames', insertgame)
      this.setState({ notificationType: 'error' });
    } else if(KeyNumberValuesCheck.includes('Fail')) {
      this.openNotification('SomeKeyValueNotKeyin', where)
      this.setState({ notificationType: 'error' });
    } else if(PlatformCheck.includes('Fail') === true) {
      this.openNotification('PlatFormError', platformGame)
      this.setState({ notificationType: 'error' });
    } else if(BetNLength.includes('Fail')) {
      this.openNotification('minimum4Word')
      this.setState({ notificationType: 'error' });
    } else if(fiveDErrorArray.includes('Fail') === true) {
      this.openNotification('donotacceptModein5D', fiveDError)
      this.setState({ notificationType: 'error' });
    } else if(SixDErrorArray.includes('Fail') === true) {
      this.openNotification('donotacceptModein6D', SixDError)
      this.setState({ notificationType: 'error' });
    } 
    // else if (Over7pm === false) {
    //   this.openNotification('ExceededHoursNormal')
    //   this.setState({ notificationType: 'error', loading: false });
    // } 
    else {
      for(let a=0; a<BetNumbers.length; a++ ) {
        if(BetNumbers[a].Text.length === 3) {
          const key3 = this.state.key3D
          const tempObject = {}
          // const FilterBetNumbers = Object.values(BetNumbers[a].Bet).filter(item => item !== '');
          for(let i=0; i<key3.length; i++) {
            tempObject[key3[i]] = Object.values(BetNumbers[a].Bet)[i] || ''
          }
          BetNumbers[a] = { ...BetNumbers[a], Bet: { ...tempObject } }
          this.setState({ BetNumbers })
        }
      }

      this.ConvertToOriginalWord(BetNumbers);
    }
  }

  async ConvertToOriginalWord(BetNumbers) {
    const OriginalWord = [];
    const BetNumberslength = BetNumbers.length;
    const PhoneNumber = '';

    for(var i=0; i<BetNumberslength; i++) {
      if(BetNumbers[i].SelectedDate !== '') {
        OriginalWord.push(`${BetNumbers[i].SelectedDate}\n`)
      }
      if(BetNumbers[i].Text !== '') {
        const Mode = BetNumbers[i].Mode
        const Text = BetNumbers[i].Text
        const IBox = BetNumbers[i].IBox
        const Platform = BetNumbers[i].Platform
        const PrevPlatform = BetNumbers[i - 1 < 0 ? 0 : i - 1].Platform || []
        const BetKeyValueKeys = Object.values(BetNumbers[i].Bet)
        const Star = this.state.OneStar
        // Generate Mode with Text Format
        const TextModeFilter = (Mode === 'Box' ? ((Star === 1) ? '*' : '**') : Mode === '{0-9}123' ? '#' : Mode === '123{0-9}' ? '##' 
          : IBox === true ? ((Star === 1) ? '**' : '*') : '')
                    + (Mode === '1{234}' ? `${Text.substring(0,1)}*${Text.substring(1,5)}`
                      : Mode === '{123}4' ? ((Text.split('').length === 3) ? `${Text.substring(0,2)}*${Text.substring(2,4)}` 
                        : `${Text.substring(0,3)}*${Text.substring(3,5)}`): Text)
                    + (BetNumbers[i].Key2 === true ? `**` : '')

        // Generate Bet KeyValues with Originalword Format
        const KeyBetValues = (BetKeyValueKeys[1] === '' && BetKeyValueKeys[2] === '' && BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ? 
          '#' + BetKeyValueKeys[0] : ( BetKeyValueKeys[2] === '' && BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ?
            '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] : ( BetKeyValueKeys[3] === '' && BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ? 
              '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2]  : (BetKeyValueKeys[4] === '' && BetKeyValueKeys[5] === '') ?
                '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3] : ( BetKeyValueKeys[5] === '') ?
                  '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3]+ '#' + BetKeyValueKeys[4] :
                  '#' + BetKeyValueKeys[0] + '#'  + BetKeyValueKeys[1] + '#' + BetKeyValueKeys[2] + '#' + BetKeyValueKeys[3] + '#' + BetKeyValueKeys[4] + '#' + BetKeyValueKeys[5]

        const KeyBetValues3 = '#' + BetKeyValueKeys[0] + '#' + BetKeyValueKeys[1]

        // Push into OriginalWord Array
        if(this.state.JinNanCheck === 1) {
          OriginalWord.push(
            `${(OriginalWord.length === 1 || (Platform.sort()).join('') !== (PrevPlatform.sort()).join('')) 
              ? '#' + (Platform.sort()).join('') + '\n'
              : ''}`)
        } else {
          OriginalWord.push(
            `${(OriginalWord.length === 1 || (Platform.sort()).join('') !== (PrevPlatform.sort()).join('')) 
              ? (Platform.sort()).join('') + '\n'
              : ''}`)
        }
        // Push into OriginalWord Array
        OriginalWord.push(`${TextModeFilter}${(Text.length ===3) ? KeyBetValues3 : KeyBetValues}\n`)
      }
    }
    if(PhoneNumber !== '') {
      OriginalWord.push(PhoneNumber)
    }
    this.PassToAPI(OriginalWord);
  }

  async PassToAPI(OriginalWord) {
    const username = this.state.username;
    const token = this.state.token;
    const UserID = this.state.UserID;
    const OriWord = OriginalWord.join('')
    this.setState({ DisabledClick: true })
    const BetOrder = await CreateBetOrder(UserID, OriWord, token, username)
    if(BetOrder.error) {
      this.openNotification(ReturnBetValidation(BetOrder.error))
      this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    } else {
      this.openNotification('SuccessOrderBet')
      this.setState({
        DisabledClick: false,
        ShowReceipt: true,
        notificationType: 'success',
        SuccessFullCancel: false,
        Receipt: BetOrder.receipt,
        UserIDforCancel: BetOrder.UserID,
        OriTextforCancel: BetOrder.OriginalText,
        RoleforCancel: BetOrder.Role,
        ServerIDforCancel: BetOrder.ServerID,
        CopyReceipt: '',
      }, () => {
        this.ConvertResultRed(this.state.Receipt)
        this.CleanData();
      });
    }
    // if(BetOrder.error === 'User No Package') {
    //   this.openNotification('NoPackage')
    //   this.setState({ notificationType: 'error', DisabledClick: false });
    // }  else if(BetOrder.error === 'No Date to Bet') {
    //   this.openNotification('DateCantbeBet')
    //   this.setState({ notificationType: 'error', DisabledClick: false, loading: false });
    // } else if(BetOrder.error === '5D6D in wrong game') {
    //   this.openNotification('OnlyBTandTOTOallow')
    //   this.setState({ notificationType: 'error', DisabledClick: false, loading: false });
    // } else if(BetOrder.error === 'Not enough date') {
    //   this.openNotification('UnableThoseDate')
    //   this.setState({ notificationType: 'error', DisabledClick: false, loading: false });
    // }  else if(BetOrder.error === 'Date error') {
    //   this.openNotification('UnableThoseDate')
    //   this.setState({ notificationType: 'error', DisabledClick: false, loading: false });
    // } else if(BetOrder.error === 'S Game ShutDown') {
    //   this.openNotification('singaporeGameClose')
    //   this.setState({ notificationType: 'error', DisabledClick: false, loading: false });
    // } else if(BetOrder.error === 'All Game ShutDown') {
    //   this.openNotification('AllGameClose')
    //   this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    // } else if(BetOrder.error === 'MKTSWBD ShutDown') {
    //   this.openNotification('MKTGameShutDown')
    //   this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    // } else if(BetOrder.error === 'No Game on that day') {
    //   this.openNotification('NoGameThatDay')
    //   this.setState({ notificationType: 'error', loading: false, DisabledClick: false });
    // } else if(BetOrder.error === 'Platform error') {
    //   this.openNotification('ErrorPlatform')
    //   this.setState({ notificationType: 'error', loading: false, DisabledClick: false });
    // } else if(BetOrder.error === 'Account Inactive') {
    //   this.openNotification('AccountInactive')
    //   this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    // } else if(BetOrder.error === 'Exceed Personal Daily Limit') {
    //   this.openNotification('ExceedDAilyLimit')
    //   this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    // } else if(BetOrder.error === 'Repeat Order') {
    //   this.openNotification('RepeatOrder')
    //   this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    // } else if(BetOrder.error === 'Over Number Limit') {
    //   this.openNotification('OverNumberLimit')
    //   this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    // } else if(BetOrder.error === 'Format Error') {
    //   this.openNotification('FormatError')
    //   this.setState({ notificationType: 'error', loading: false, disabled: false, DisabledClick: false });
    // } else {
    //   this.openNotification('SuccessOrderBet')
    //   this.setState({
    //     DisabledClick: false,
    //     ShowReceipt: true,
    //     notificationType: 'success',
    //     SuccessFullCancel: false,
    //     Receipt: BetOrder.receipt,
    //     UserIDforCancel: BetOrder.UserID,
    //     OriTextforCancel: BetOrder.OriginalText,
    //     RoleforCancel: BetOrder.Role,
    //     ServerIDforCancel: BetOrder.ServerID,
    //     CopyReceipt: '',
    //   }, () => {
    //     this.ConvertResultRed(this.state.Receipt)
    //     this.CleanData();
    //   });
    // }
  }

  async CancelBetAPI() {
    // const Receipt = this.state.Receipt
    // const UserID = this.state.UserIDforCancel
    // const OriText = this.state.OriTextforCancel
    // const Role = this.props.Role
    const ServerID = this.state.ServerIDforCancel
        
    const CancelBets = await CancelBet(ServerID, this.props.username, this.props.token)
    if(CancelBets.error === 'database error') {
      alert('database error')
    } else if (CancelBets.error === 'Exceed Date') {
      alert('Exceed Date')
    } else if(CancelBets.error === 'OverTimeLimit') {
      alert('ExceededHours')
    } else {
      if(CancelBets.message === 'Cancel Successful') {
        this.setState({
          UserIDforCancel: '',
          SuccessFullCancel: true,
          OriTextforCancel: '',
          RoleforCancel: '',
          ServerIDforCancel: '',
          Receipt: CancelBets.CancelReceipt,
        }, ()=> {
          this.ConvertResultRed(this.state.Receipt)
        });
      }
    }
  }

  // DownloadReceipt = (Receipt) => {
  //   const element = document.createElement('a');
  //   const file = new Blob([Receipt], {type: 'text/plain'});
  //   element.href = URL.createObjectURL(file);
  //   element.download = "Receipt.txt";
  //   document.body.appendChild(element);
  //   element.click();
  // }

  CheckPlatform(e, item, idx) {
    const BetNumbers = JSON.parse(JSON.stringify(this.state.BetNumbers));
    const BetNumberLength = BetNumbers.length
    const target = e.target;

    if(item === 'All') {
      if(target.checked) {
        for(var i = 0; i < BetNumberLength; i ++) {
          !BetNumbers[i].Platform.includes(e.target.name) && BetNumbers[i].Platform.push(e.target.name)
        }
        this.setState(prevState => ({
          PlatformSelectAll: {
            ...prevState.PlatformSelectAll,   
            [target.name]: true
          }
        }))
      } else {
        for(var a = 0; a < BetNumberLength; a ++) {
          const Insertedplatform = BetNumbers[a].Platform;
          var index = Insertedplatform.indexOf(e.target.name);
          delete Insertedplatform[index];
    
          const FilterPlatform = Insertedplatform.filter(x => x !== null)
          // const JoinFilter = (FilterPlatform).join('')
    
          BetNumbers[a] = { ...BetNumbers[a], Platform: FilterPlatform}
        }
        this.setState(prevState => ({
          PlatformSelectAll: {
            ...prevState.PlatformSelectAll,
            [target.name]: false
          }
        }))
      }

      this.setState({ BetNumbers: BetNumbers})
      // this[`InputBetNumber${idx}`].focus();
    } else {
      if(target.checked) {
        // const Insertedplatform = BetNumbers[idx].Platform || [];
        !BetNumbers[idx].Platform.includes(item) && BetNumbers[idx].Platform.push(item)
        // Insertedplatform.push(item)
        // const FilterPlatform = Insertedplatform.filter(x => x !== null)
        // BetNumbers[idx] = { ...BetNumbers[idx], Platform: FilterPlatform }
        this.setState({ BetNumbers: BetNumbers })
      } else {
        const Insertedplatform = BetNumbers[idx].Platform;
        var inde = Insertedplatform.indexOf(item);
        delete Insertedplatform[inde];    
        const FilterPlatform = Insertedplatform.filter(x => x !== null)    
        BetNumbers[idx] = { ...BetNumbers[idx], Platform: FilterPlatform }
        this.setState(prevState => ({
          PlatformSelectAll: {
            ...prevState.PlatformSelectAll,   
            [target.name]: false
          }
        }))
        this.setState({ BetNumbers: BetNumbers })
      }
      // this[`InputBetNumber${idx}`].focus();
    }
  }

    handleRemoveNumbersChange = idx => () => {
      var BetNum = this.state.BetNumbers;
      var toRemove = idx;
      var index = BetNum.indexOf(toRemove);
      if (index > -1) {
        BetNum.splice(index, 1);
      }
      this.setState({
        BetNumbers: this.state.BetNumbers.filter((s, sidx) => idx !== sidx)
      }, () => {
        this.openNotification('SuccessDeleted', idx+1)
        this.setState({ notificationType: 'success' });
      });
    };

    async CheckedAllPlatform(event) {
      let checked = event.target.checked;
      const itemName = event.target.name

      this.setState(prevState => {
        let { allChecked, isChecked } = prevState;
        if (itemName === 'checkAllMagnum') {
          allChecked = checked;
          isChecked = checked;
        } else {
          isChecked = !checked;
        }
        return { allChecked, isChecked };
      })
    }

    async CheckBoxKeyPressEvent(e, idx) {
      if (e.key === 'Enter') {
        const BetNum = this.state.BetNumbers[idx];
        if (BetNum.Text === '') {
          this.openNotification('pleaseinputyourgames')
          this.setState({ notificationType: 'error' });
          this[`InputBetNumber${idx}`].focus();
        } else {
          this.handleAddBetLine(idx);
        }
      }
    }

    async KeyPressEvent(e, idx, Selected, Status) {
      if (Status === 'Edit') {
        const KeyInResult = this.state.KeyinFormat;
        const SelectedBetNumbers = this.state.BetNumbers[idx];
        const BetNumbersKey2 = SelectedBetNumbers.Key2;
        const CurrentBtFormula = (BetNumbersKey2 === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
        const Keyvalue = CurrentBtFormula.map((item, index) => {
          if (Selected === item) {
            return index;
          }
          return undefined
        })
        const finalKeyvalue = Keyvalue.filter(x => x !== undefined)

        if (finalKeyvalue[0] === 0) {
          this.setState({ PressCount: 1 })
        } else if (finalKeyvalue[0] === 1) {
          this.setState({ PressCount: 2 })
        } else if (finalKeyvalue[0] === 2) {
          this.setState({ PressCount: 3 })
        } else if (finalKeyvalue[0] === 3) {
          this.setState({ PressCount: 4 })
        } else if (finalKeyvalue[0] === 4) {
          this.setState({ PressCount: 5 })
        } else if (finalKeyvalue[0] === 5) {
          this.setState({ PressCount: 6 })
        } else {
          this.setState({ PressCount: 0 })
        }
      } else {
        const BetNumbers = this.state.BetNumbers
        const BetNumberLength = BetNumbers.length
        const BetNLengthArray = [];
        // let ObjectValueBet = ''

        for(var i=0; i<BetNumberLength; i++) {
          BetNLengthArray.push((BetNumbers[i].Text).length)
          // ObjectValueBet = Object.values(BetNumbers[i].Bet);
        }
        if (e.key === 'Enter') {
          if (BetNLengthArray.includes(0) && BetNLengthArray.length === 2) {
            if((BetNumbers[0].Text).length === 0 || idx !== 0) {
              this.openNotification('pleaseinputyourgames')
              this.setState({ notificationType: 'error' });
            }
            this[(idx === 0) ? `InputBetNumber${idx + 1}` : `InputBetNumber${idx}`].focus();
          } 
          else if (BetNLengthArray.includes(0)) {
            if((BetNumbers[0].Text).length === 0 || idx !== 0) {
              this.openNotification('pleaseinputyourgames')
              this.setState({ notificationType: 'error' });
            }
            this[`InputBetNumber${idx}`].focus();
          }  else if(BetNLengthArray.includes(1) || BetNLengthArray.includes(2)) {
            this.openNotification('minimum4Word')
            this.setState({ notificationType: 'error' });
          } else {
            this.setState({ PlatformSelected: [], PlatformLength: ''})
            e.preventDefault();
            this.setState({ PressCount: 0 })
            this.handleAddBetLine(idx);
          }
        }
        if (e.key === '+') {
          const SelectedBetNumbers = this.state.BetNumbers[idx];
          if((SelectedBetNumbers.five6D === false && SelectedBetNumbers.input56D === true)) {
            if(Selected === '0') {
              this[`InputBet1${idx}`].focus();
            } else if(Selected === '1') {
              this[`InputBet2${idx}`].focus();
            } else if(Selected === '2') {
              this[`InputBetNumber${idx}`].focus();
            }
          } else if(SelectedBetNumbers.five6D === true) {
            if(Selected === '0') {
              this[`InputBet1${idx}`].focus();
            } else if(Selected === '1') {
              this[`InputBetNumber${idx}`].focus();
            }
          } else {
            if(Selected === '0') {
              this[`InputBet1${idx}`].focus();
            } else if(Selected === '1') {
              this[`InputBet2${idx}`].focus();
            } else if(Selected === '2') {
              this[`InputBet3${idx}`].focus();
            } else if(Selected === '3') {
              this[`InputBet4${idx}`].focus();
            } else if(Selected === '4') {
              this[`InputBet5${idx}`].focus();
            } else if(Selected === '5') {
              this[`InputBet6${idx}`].focus();
            } else if(Selected === '6') {
              this[`InputBetNumber${idx}`].focus();
            }
          }
          e.preventDefault();
        }
        if(e.key === ' ') {
          e.preventDefault();
          this.ConfirmSaveBet();
        }
        if(e.key === 'q') {
          e.preventDefault();
          this.CleanData();
          this.setState({ Receipt: '', ShowReceipt: false})
        }
      }
    }

    handleBetNumbers(idx, evt, item) {
      let inputstr = '';
      if(evt.target.name === 'Text') {
        inputstr = (evt.target.value).replace(/[^0-9*]/g, '');
      } else {
        inputstr = (evt.target.value).replace(/[^0-9.*]/g, '');
      }
      var Replacevalue = inputstr.replace(/[*]/g, '.');
      const num = Replacevalue.replace(/\./,"#").replace(/\./g,"").replace(/#/,".")

      const KeyInResult = this.state.KeyinFormat;
      const BetNumbers = this.state.BetNumbers;
      const SelectedBetnum = this.state.BetNumbers[idx];
      const BetNumLength = BetNumbers.length
      const IBoxCheck = SelectedBetnum.IBox
      const Key2Check = SelectedBetnum.Key2;
      const Key1IBox = this.state.Key1IBox || [];
      const Key2IBox = this.state.Key2IBox || [];

      if(Key2Check === false) {
        const CurrentBtFormulas = (IBoxCheck === true) ? Key1IBox 
          : KeyInResult.KeyIn1 || [];
        const CurrentBtFormulaLength = CurrentBtFormulas.length;
        const Key2FormatBetValues = Object.values(BetNumbers[idx].Bet)
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormulas[i]] = Key2FormatBetValues[i]
        }
        BetNumbers[idx] = { ...BetNumbers[idx], Bet: { ...object }}
      } else {
        const CurrentBtFormulas = (IBoxCheck === true) ? Key2IBox 
          : KeyInResult.KeyIn2 || [];
        const CurrentBtFormulaLength = CurrentBtFormulas.length;
        const Key2FormatBetValues = Object.values(BetNumbers[idx].Bet)
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormulas[i]] = Key2FormatBetValues[i]
        }
        BetNumbers[idx] = { ...BetNumbers[idx], Bet: { ...object }}
      }
      if(IBoxCheck === false) {
        const CurrentBtFormulas = (Key2Check === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
        const CurrentBtFormulaLength = CurrentBtFormulas.length;
        const Key2FormatBetValues = Object.values(BetNumbers[idx].Bet)
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormulas[i]] = Key2FormatBetValues[i]
        }
        BetNumbers[idx] = { ...BetNumbers[idx], Bet: { ...object }}
      }

      const CurrentBtFormula = (Key2Check === true && IBoxCheck === false) ? KeyInResult.KeyIn2
        : (Key2Check === false && IBoxCheck === true) ? Key1IBox
          : (Key2Check === true && IBoxCheck === true) ? Key2IBox
            : (Key2Check === false && IBoxCheck === false) ? KeyInResult.KeyIn1
              : KeyInResult.KeyIn1 || [];

      if(item === 'All') {
        if(evt.target.checked) {
          const targetname = evt.target.name;
          const BetNumberChange = Object.values(BetNumbers[idx].Bet)[targetname];
          for(var i = 0; i < BetNumLength; i++){
            if(BetNumbers[i].IBox === true) {
              const CurrentBtFormulas = (BetNumbers[i].Key2 === true) ? Key2IBox : Key1IBox || [];
              const CurrentBtFormulaLength = CurrentBtFormulas.length;
              const Key2FormatBetValues = Object.values(BetNumbers[i].Bet)
              const object = {}
              for (let a = 0; a < CurrentBtFormulaLength; a++) {
                object[CurrentBtFormulas[a]] = Key2FormatBetValues[a]
              }
              BetNumbers[i] = { ...BetNumbers[i], Bet: { ...object }}
              this.setState({ BetNumbers })
            }
            if((BetNumbers[i].Text).split('').length !== 5 && (BetNumbers[i].Text).split('').length !== 6 
                    && (BetNumbers[i].Text).split('').length !== 3) {
              const BetValuesToChange = {
                ...BetNumbers[i].Bet,
                [Object.keys(BetNumbers[i].Bet)[targetname]]: BetNumberChange || '' ,
              }
              BetNumbers[i] = { ...BetNumbers[i], Bet: { ...BetValuesToChange }}
              // this.setState({ BetNumbers })
            }
          }
          this.setState(prevState => ({
            KeyBetAll: {
              ...prevState.KeyBetAll,   
              [evt.target.name]: true,
            }, 
            KeyBetAllPassValues: {
              ...prevState.KeyBetAllPassValues,
              [evt.target.name]: BetNumberChange,
            }
          }))
        } else {
          this.setState(prevState => ({
            KeyBetAll: {
              ...prevState.KeyBetAll,   
              [evt.target.name]: false,
            },
            KeyBetAllPassValues: {
              ...prevState.KeyBetAllPassValues,
              [evt.target.name]: '',
            }
          }))
        }
        this[`InputBetNumber${idx}`].focus();
      } else {
        const Key2 = this.state.Key2
        // const IBox = this.state.IBox
        // const ModeSelectAll = this.state.ModeSelectAll
        const KeyBetAllPassValues = this.state.KeyBetAllPassValues
        let datatableProps = {}
 
        const newBettingNumbers = this.state.BetNumbers.map((Betting, sidx) => {

          datatableProps =  { ...Betting.Bet, [CurrentBtFormula[this.state.PressCount - 1]]: num || '' };

          // datatableProps =  { ...Betting.Bet, [CurrentBtFormula[this.state.PressCount - 1]]: num || '' };

          // const datatableProps =  { ...Betting.Bet, [CurrentBtFormula[this.state.PressCount - 1]]: num || '' };
          // // calculate the total bet
          // const filterInputBet = Object.values(datatableProps).filter(x => x !== "") || [0]
          // const filterInputBetResult = filterInputBet.map(el => {
          //     const e = el.replaceAll('.', ',').split(",").map((e, i) => i === 0 ? "0" + e : e).filter(e => e).splice(-2).map(e => e.replace(/[^0-9.]/g, '')).map((e, i) => i === 0 ? (+e || "0") : e.substr(0, 2)).join(".");
          //     return e
          // });
          // const AmountData = filterInputBetResult.reduce(function (a, b) { return Number(a) + Number(b); }, 0);
          // Insert bet details into array objects (BetNumbers).
          const KeyInResult = this.state.KeyinFormat;
          const CurrentBtFormula2 = (Key2 === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
          const CurrentBtFormulaLength = CurrentBtFormula2.length;
          const object = {}
          for (let i = 0; i < CurrentBtFormulaLength; i++) {
            object[CurrentBtFormula2[i]] = KeyBetAllPassValues[i]
          }

          if (this.state.PressCount === 0) {
            if (idx !== sidx) return Betting;
            if(num.length === 3)  return ({
              ...Betting, Text: num, input56D: true, Key2: false, five6D: false,
              // IBox: (IBox === true) ? true : false ,
              Bet: { ...datatableProps, C: '', A: '', A1: '', A1C: ''}
            });
            if (num.length === 5 || num.length === 6) return ({
              ...Betting, Text: num, input56D: true, IBox: false, Key2: false, Mode: '-', five6D: true,
              Bet: { ...datatableProps, S: '', A: '', C: '', A1: '', A1C: ''}
            }); 
            if (Key2 === false)
              return ({
                ...Betting, Text: num, input56D: false, five6D: false,
                Key2: (Key2 === true) ? true : false,
                // IBox: (Betting.IBox === true) ? true : false,
                // Mode: (ModeSelectAll['1'] === true ? ModeSelectAll.Mode : '-'),
                Bet: { ...object },
              });
            if (Key2 === true)
              return ({
                ...Betting, Text: num, input56D: false, five6D: false,
                Key2: (Key2 === true) ? true : false,
                // IBox: (Betting.IBox === true) ? true : false, 
                // Mode: (ModeSelectAll['1'] === true ? ModeSelectAll.Mode : '-'),
                Bet: { ...object },
              });
            return ({
              ...Betting, Text: num, input56D: false, five6D: false,
              Key2: (Key2 === true) ? true : false,
              // IBox: (Betting.IBox === true) ? true : false, 
              // Mode: (ModeSelectAll['1'] === true ? ModeSelectAll.Mode : '-'),
              Bet: { ...datatableProps  },
            });
          }

          if (this.state.PressCount && this.state.PressCount > 0) {
            if (idx !== sidx) return Betting;
            if ((Betting.Text).split('').length === 3) return ({ ...Betting, Bet: { ...datatableProps }})
            return ({ ...Betting, Bet: { ...datatableProps }})
          } else if (this.state.PressCount > 6) {
            return Betting;
          };
          return Betting;
        });
        this.setState({ BetNumbers: newBettingNumbers });
        this.setState(prevState => ({
          KeyBetAll: {
            ...prevState.KeyBetAll,   
            [evt.target.name]: false,
          },
          KeyBetAllPassValues: {
            ...prevState.KeyBetAllPassValues,
            [evt.target.name]: '',
          }
        }))
      }
    };

    async handleAddBetLine(idx) {
      // BetNumbers[i].IBox = (BetNumbers[i].Text.length > 4) ? false : CopyReceipt.IBox
      // BetNumbers[i].Mode = (BetNumbers[i].Text.length > 4) ? '-' : CopyReceipt.Mode
      // BetNumbers[i].Platform = CopyReceipt.Platform
      const PF = [];
      const PlatformSelectAll = this.state.PlatformSelectAll
      // const KeyBetAll = this.state.KeyBetAll
      // const KeyBetAllObject = Object.values(KeyBetAll).join(',')
      const KeyBetAllPassValues = this.state.KeyBetAllPassValues

      if (PlatformSelectAll[1] === true) {
        PF.push(Object.keys(PlatformSelectAll)[0])
      }
      if (PlatformSelectAll[2] === true) {
        PF.push(Object.keys(PlatformSelectAll)[1])
      }
      if (PlatformSelectAll[3] === true) {
        PF.push(Object.keys(PlatformSelectAll)[2])
      }
      if (PlatformSelectAll[4] === true) {
        PF.push(Object.keys(PlatformSelectAll)[3])
      }
      if (PlatformSelectAll[5] === true) {
        PF.push(Object.keys(PlatformSelectAll)[4])
      }
      if (PlatformSelectAll[6] === true) {
        PF.push(Object.keys(PlatformSelectAll)[5])
      }
      if (PlatformSelectAll[7] === true) {
        PF.push(Object.keys(PlatformSelectAll)[6])
      }
      if (PlatformSelectAll[8] === true) {
        PF.push(Object.keys(PlatformSelectAll)[7])
      }
      if (this.state.Key2 === true) {
        const KeyInResult = this.state.KeyinFormat;
        const CurrentBtFormula = KeyInResult.KeyIn2 || [];
        const CurrentBtFormulaLength = CurrentBtFormula.length;
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormula[i]] = KeyBetAllPassValues[i]
        }
        this.setState(({
          BetNumbers: [
            ...this.state.BetNumbers,
            {
              Text: '',
              Bet: {
                ...object,
              },
              Platform: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.Platform : PF,
              SelectedDate: '',
              IBox: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.IBox : (this.state.IBoxSelectAll[1] === true) ? true : false,
              Key2: true,
              Mode: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.Mode : (this.state.ModeSelectAll['Mode'] !== '' ? this.state.ModeSelectAll['Mode'] : ''),
              input56D: false,
              five6D: false,
              Follow: (this.state.CopyReceipt !== '') ? 'disable' : '',
            },
          ],
          IBox: (this.state.IBoxSelectAll[1] === true) ? true : false,
        }), ()=> {
          this.copyBetValue(idx);
        });
      } else {
        const KeyInResult = this.state.KeyinFormat;
        const CurrentBtFormula = KeyInResult.KeyIn1 || [];
        const CurrentBtFormulaLength = CurrentBtFormula.length;
        const object = {}
        for (let i = 0; i < CurrentBtFormulaLength; i++) {
          object[CurrentBtFormula[i]] = KeyBetAllPassValues[i]
        }
        this.setState(({
          BetNumbers: [
            ...this.state.BetNumbers,
            {
              Text: '',
              Bet: {
                ...object,
              },
              Platform: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.Platform : PF,
              SelectedDate: '',
              IBox: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.IBox : (this.state.IBoxSelectAll[1] === true) ? true : false,
              Key2: false,
              Mode: (this.state.CopyReceipt !== '') ? this.state.CopyReceipt.Mode : (this.state.ModeSelectAll['Mode'] !== '' ? this.state.ModeSelectAll['Mode'] : ''),
              input56D: false,
              five6D: false,
              Follow: (this.state.CopyReceipt !== '') ? 'disable' : '',
            },
          ],
          IBox: (this.state.IBoxSelectAll[1] === true) ? true : false,
        }), ()=> {
          this.copyBetValue(idx);
        });
      }
    };

    async copyBetValue(idx) {
      let BetArayObject = [...this.state.BetNumbers]
      const KeyInResult = this.state.KeyinFormat;
      const SelectedBetnum = this.state.BetNumbers[idx];
      const IBoxCheck = SelectedBetnum.IBox
      const Key2Check = SelectedBetnum.Key2;
      const Key1IBox = this.state.Key1IBox || [];
      const Key2IBox = this.state.Key2IBox || [];
      const CurrentBtFormula = (Key2Check === true && IBoxCheck === false) ? KeyInResult.KeyIn2
        : (Key2Check === false && IBoxCheck === true) ? Key1IBox
          : (Key2Check === true && IBoxCheck === true) ? Key2IBox
            : (Key2Check === false && IBoxCheck === false) ? KeyInResult.KeyIn1
              : KeyInResult.KeyIn1 || [];
      const tempObject = {}
      let CheckEmptyArray = []

      for(var v = 0; v < 6; v++) {
        if(this.state.BetNumbers[idx].Bet[CurrentBtFormula[v]] !== '') {
          CheckEmptyArray.push('NotEmpty')
        }
      }
      if(CheckEmptyArray.includes('NotEmpty')) {
        //ignore
      } else {
        if(this.state.BetNumbers[idx - 1] === undefined) {
          this.openNotification('pleaseinputyourgames')
          this.setState({ notificationType: 'error' });
        } else {
          for(var t = 0; t < 6; t++) {
            tempObject[CurrentBtFormula[t]] = Object.values(this.state.BetNumbers[idx - 1].Bet)[t]
          }
          BetArayObject[idx] = { ...BetArayObject[idx], Bet: { ...tempObject }};

          this.setState({ BetNumbers: BetArayObject })
        }
      }
    }

    Key2Checked = idx => evt => {
      let checked = evt.target.checked;
      const BetNumbers = JSON.parse(JSON.stringify(this.state.BetNumbers));
      const BetNumberLength = BetNumbers.length
      if(evt.target.value === 'AllKey2') {
        if (checked) {
          for(var a = 0; a < BetNumberLength; a++) {
            if((BetNumbers[a].Text).split('').length !== 5 && (BetNumbers[a].Text).split('').length !== 6 
                    && (BetNumbers[a].Text).split('').length !== 3) {
              const KeyInResult = this.state.KeyinFormat;
              const CurrentBtFormula = (BetNumbers[a].IBox === true) ? this.state.Key2IBox : KeyInResult.KeyIn2 || [];
              const CurrentBtFormulaLength = CurrentBtFormula.length;
              const BetNumberSelectedText = BetNumbers[a].Text
              const CleanText = BetNumberSelectedText.replace(/[^0-9.]/g, '');
              const Key2FormatBetValues = Object.values(BetNumbers[a].Bet)
              const object = {}
              for (let i = 0; i < CurrentBtFormulaLength; i++) {
                object[CurrentBtFormula[i]] = Key2FormatBetValues[i]
              }
              BetNumbers[a] = { ...BetNumbers[a], Text: CleanText, Bet: { ...object }, Key2: true }
              this.setState({ BetNumbers: BetNumbers, Key2: true})
            }
          }
        } else {
          for(var b = 0; b < BetNumberLength; b++) {
            const KeyInResult = this.state.KeyinFormat;
            const CurrentBtFormula = (BetNumbers[b].IBox === true) ? this.state.Key1IBox : KeyInResult.KeyIn1 || [];
            const CurrentBtFormulaLength = CurrentBtFormula.length;
            const Key2FormatBetValues = Object.values(BetNumbers[b].Bet)
    
            const object = {}
            for (let i = 0; i < CurrentBtFormulaLength; i++) {
              object[CurrentBtFormula[i]] = Key2FormatBetValues[i]
            }
            BetNumbers[b] = { ...BetNumbers[b], Bet: { ...object }, Key2: false };
            this.setState({ BetNumbers: BetNumbers, Key2: false })
          }
        }
        this[`InputBetNumber${idx}`].focus();
      }
    }

    // ControlType(e, idx) {
    //   let BetNumbers = this.state.BetNumbers
    //   let ArrayFollow = []
    //   if((BetNumbers[idx].Follow === 'End') && BetNumbers[idx].Follow !== '') {
    //     BetNumbers[idx].Follow = ''

    //     for (var d=idx; d >= 0; d--) {
    //       if(BetNumbers[d].Follow !== 'Follow') {
    //         BetNumbers[d].Follow = ''
    //       }
    //       if(BetNumbers[d].Follow === 'Follow'){
    //         BetNumbers[d].Follow = ''
    //         break;
    //       }
    //     }
    //     this.setState({ BetNumbers })
    //   } else if ((BetNumbers[idx].Follow === 'Follow') && BetNumbers[idx].Follow !== '') {
    //     BetNumbers[idx].Follow = ''
    //     for (var s=idx; s < BetNumbers.length; s++) {
    //       if(BetNumbers[s].Follow !== 'End') {
    //         BetNumbers[s].Follow = ''
    //       }
    //       if(BetNumbers[s].Follow === 'End'){
    //         BetNumbers[s].Follow = ''
    //         break;
    //       }
    //     }
    //     this.setState({ BetNumbers })
    //   } else {
    //     let CopyReceipt = ''
    //     let ArrayIndex = []
    //     for(var a=idx; a >= 0; a--) {
    //       if(BetNumbers[a].Follow !== '') {
    //         ArrayFollow.push(BetNumbers[a].Follow)
    //       } else {
    //         // ignore
    //       }
    //     }
    //     if (ArrayFollow.length === 0) {
    //       BetNumbers[idx].Follow = 'Follow'
    //     } else {
    //       if(ArrayFollow[0] === 'Follow') {
    //         BetNumbers[idx].Follow = 'End'
    //         for (var z=idx; z >= 0; z--) {
    //           if(BetNumbers[z].Follow === 'Follow'){
    //             CopyReceipt = BetNumbers[z]
    //             break;
    //           }
    //           if(BetNumbers[z].Follow !== 'Follow' && BetNumbers[z].Follow !== 'End') {
    //             BetNumbers[z].Follow = 'disable'
    //             ArrayIndex.push(z)
    //           }
    //         }
    //         ArrayIndex.push(idx)
    //         ArrayIndex.forEach((items)=> {
    //           // BetNumbers[items].Bet = CopyReceipt.Bet
    //           BetNumbers[items].IBox = (BetNumbers[items].Text.length > 4) ? false : CopyReceipt.IBox
    //           // BetNumbers[items].Key2 = CopyReceipt.Key2
    //           BetNumbers[items].Mode = (BetNumbers[items].Text.length > 4) ? '-' : CopyReceipt.Mode
    //           BetNumbers[items].Platform = CopyReceipt.Platform
    //         })
    //       } else if (ArrayFollow[0] === 'End') {
    //         BetNumbers[idx].Follow = 'Follow'
    //       }
    //     }
    //     this.setState({ BetNumbers })
    //   }
    // }

    ControlType(e, idx) {
      let BetNumbers = this.state.BetNumbers
      let CopyReceipt = ''
      if (BetNumbers[idx].Follow !== 'Follow') {
        BetNumbers[idx].Follow = 'Follow'
        CopyReceipt = BetNumbers[idx]
        for (var i = idx + 1; i < BetNumbers.length; i++) {
          BetNumbers[i].Follow = 'disable'
          // BetNumbers[i].Bet = CopyReceipt.Bet
          BetNumbers[i].IBox = (BetNumbers[i].Text.length > 4) ? false : CopyReceipt.IBox
          // // BetNumbers[i].Key2 = CopyReceipt.Key2
          BetNumbers[i].Mode = (BetNumbers[i].Text.length > 4) ? '-' : CopyReceipt.Mode
          BetNumbers[i].Platform = CopyReceipt.Platform
        }
        this.setState({ BetNumbers, CopyReceipt })
      } else {
        BetNumbers[idx].Follow = ''
        for (var a = idx + 1; a < BetNumbers.length; a++) {
          BetNumbers[a].Follow = ''
        }
        this.setState({ BetNumbers, CopyReceipt: '' })
      }
    }
    // IBoxChecked = idx => evt => {
    //   let checked = evt.target.checked;
    //   if (checked) {
    //     this.setState({ IBox: true }, () => {
    //       let IBoxFormat = [...this.state.BetNumbers]
    //       const CurrentKey = IBoxFormat[idx].Key2;
    //       const KeyInResult = this.state.KeyinFormat;
    //       const CurrentBtFormula = (CurrentKey === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1|| [];
    //       const CurrentBtFormulaLength = CurrentBtFormula.length;
    //       const BetNumberSelectedText = IBoxFormat[idx].Text
    //       const CleanText = BetNumberSelectedText.replace(/[^0-9.]/g, '');
    //       const IBoxFormatBetValues = Object.values(IBoxFormat[idx].Bet)
    //       const object = {}

    //       for (let i = 0; i < CurrentBtFormulaLength; i++) {
    //         object[`(IB)${CurrentBtFormula[i]}`] = IBoxFormatBetValues[i]
    //       }
    //       IBoxFormat[idx] = { ...IBoxFormat[idx], Text: CleanText, Bet: { ...object }, IBox: true, Mode: '-' }
    //       this.setState({ BetNumbers: IBoxFormat, IBox: false })
    //       this.setState(prevState => ({
    //         ModeSelectAll: {
    //           ...prevState.ModeSelectAll,   
    //           1: false,
    //           Mode: '',
    //         }
    //       }))
    //       this[`InputBetNumber${idx}`].focus();
    //     })
    //   } else {
    //     this.setState({ IBox: false }, () => {
    //       let IBoxFormat = [...this.state.BetNumbers]
    //       const CurrentKey = IBoxFormat[idx].Key2;
    //       const KeyInResult = this.state.KeyinFormat;
    //       const CurrentBtFormula = (CurrentKey === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1|| [];
    //       const CurrentBtFormulaLength = CurrentBtFormula.length;
    //       const IBoxFormatBetValues = Object.values(IBoxFormat[idx].Bet)

    //       const object = {}
    //       for (let i = 0; i < CurrentBtFormulaLength; i++) {
    //         object[CurrentBtFormula[i]] = IBoxFormatBetValues[i]
    //       }
    //       IBoxFormat[idx] = { ...IBoxFormat[idx], Bet: { ...object }, IBox: false };
    //       this.setState({ BetNumbers: IBoxFormat })
    //       this.setState(prevState => ({
    //         ModeSelectAll: {
    //           ...prevState.ModeSelectAll,
    //           1: false,
    //           Mode: '',
    //         }
    //       }))
    //     })
    //     this[`InputBetNumber${idx}`].focus();
    //   }
    // }

    async AllKey2(event){
      let checked = event.target.checked;
      if(checked) {
        this.setState({ AllChangeKey2: true })
      } else {
        this.setState({AllChangeKey2: false})
      }
    }
    
    async SelectedMode(e, idx, Type) {
      const BetNumbers = JSON.parse(JSON.stringify(this.state.BetNumbers));
      const BetNumberLength = BetNumbers.length
      const SelectedMode = e.target.value;
      if(Type === 'All') {
        if(e.target.checked) {
          const BetNumberSelectedText = BetNumbers[idx].Mode
          const BetNumberSelectedIBox = BetNumbers[idx].IBox
          for(var i = 0; i < BetNumberLength; i ++) {
            if((BetNumbers[i].Text).split('').length !== 5 && (BetNumbers[i].Text).split('').length !== 6) {
              BetNumbers[i] = { ...BetNumbers[i], Mode: BetNumberSelectedText, IBox: BetNumberSelectedIBox }
            }
          }
          if(BetNumberSelectedIBox === true) {
            this.setState(prevState => ({
              IBoxSelectAll: {
                ...prevState.IBoxSelectAll,
                1: true,
                Mode: BetNumberSelectedText,
              },
            }))
            this.setState(prevState => ({
              ModeSelectAll: {
                ...prevState.ModeSelectAll,   
                1: true,
                Mode: BetNumberSelectedText,
              },
            }))
          } else {
            this.setState(prevState => ({
              ModeSelectAll: {
                ...prevState.ModeSelectAll,   
                1: true,
                Mode: BetNumberSelectedText,
              },
            }))
          }
        } else {
          this.setState(prevState => ({
            ModeSelectAll: {
              ...prevState.ModeSelectAll,   
              1: false,
              Mode: '',
            },
            IBoxSelectAll: {
              ...prevState.IBoxSelectAll,
              1: false,
              Mode: '',
            },
          }))
        }
        this.setState({ BetNumbers: BetNumbers})
        // this[`InputBetNumber${idx}`].focus();
      } else {
        const BetNumbers = [...this.state.BetNumbers];
        const BetNumberSelectedText = BetNumbers[idx].Text
        const CleanText = BetNumberSelectedText.replace(/[^0-9.]/g, '');

        if(SelectedMode === 'Box') {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: 'Box', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if (SelectedMode === '{0-9}123') {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '{0-9}123', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if (SelectedMode === '123{0-9}') {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '123{0-9}', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if (SelectedMode === '{123}4') {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '{123}4', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if (SelectedMode === '1{234}') {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: '1{234}', IBox: false }
          this.setState({ BetNumbers: BetNumbers, IBox: false})
        } else if(SelectedMode === 'IBox') {
          BetNumbers[idx] = { ...BetNumbers[idx], Mode: 'IBox', IBox: true }
          this.setState({ BetNumbers: BetNumbers, IBox: true})
        } else {
          BetNumbers[idx] = { ...BetNumbers[idx], Text: CleanText, Mode: '-', IBox: false }
          this.setState({ BetNumbers: BetNumbers })
          this.setState(prevState => ({
            ModeSelectAll: {
              ...prevState.ModeSelectAll,   
              1: false,
              Mode: '',
            },
            IBoxSelectAll: {
              ...prevState.IBoxSelectAll,
              1: false,
              Mode: '',
            },
          }))
        }
        // this.setState(prevState => ({
        //   ModeSelectAll: {
        //     ...prevState.ModeSelectAll,   
        //     1: false,
        //     Mode: '',
        //   }
        // }))
        this[`InputBetNumber${idx}`].focus();
      }
    }

    async OpenDateModal() {
      this.setState({ modalShow: true })
    }

    onpressPeriod(Num) {
      const { DisplayDate } = this.state;
      const BetNumbers = [...this.state.BetNumbers]
      const toProcessDateData = this.state.ModalDate;
  
      for(var j = 0; j < toProcessDateData.DateLoop.length; j++) {
        if(toProcessDateData.DateLoop[j].CheckMark === true) {
          toProcessDateData.DateLoop[j].CheckMark = false
        }
      }
      let newLineWord = '';
      // let NextDate = [];
      // for (var i = 0; i < Num; i++) {
      //   const CreatedDate = Moment(new Date().setDate(new Date().getDate() + (i + 1))).format('DDMM(ddd)');
      //   NextDate.push(CreatedDate);
      // }
      newLineWord = 'D#' + Num;

      DisplayDate[0] = `日期： ${newLineWord}`
      BetNumbers[0] = { ...BetNumbers[0], SelectedDate: `D#${Num}` }

      this.setState({ modalShow: false, DDate: DisplayDate[0], BetNumbers: BetNumbers, ModalDate: toProcessDateData });
      this.setState({modalShow: false});
    }

    openNotification(message, Words) {
      this.setState({ showNotification: true });
      this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]} ${(Words !== undefined) ? `Line:${Words || ''}` : ``}` });
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.setState({ showNotification: false });
        this.setState({ notificationMessage: '' });
      }, 5000);
    };
    
    async CleanData() {
      this.setState({
        BetNumbers: [],
        PhoneNumber: '',
        // UserID: this.props.userID,
        Key2: false, 
        IBox: false,
        AllChangeKey2: false,
        DDate: '请选择日期 (D#)',
        DisplayDate: [],
        PlatformSelectAll: {
          1: false,
          2: false,
          3: false, 
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
        },
        ModeSelectAll: {
          1: false,
          Mode: '',
        },
        IBoxSelectAll: {
          1: false,
          Mode: '',
        },
        KeyBetAll: {
          0: false,
          1: false,
          2: false, 
          3: false,
          4: false,
          5: false,
        },
        KeyBetAllPassValues: {
          0: '',
          1: '',
          2: '', 
          3: '',
          4: '',
          5: '',
        },
      }, () => {
        this.StartBetting();
      })
    }

    async ClearReceipt() {
      this.setState({ Receipt: ''})
    }

    async ConvertResultRed(Receipt) {
      let Word = Receipt
      let returnWord = [];
  
      if (Word.includes('<red>')) {
        const WordArray = Word.split('<red>').join('</red>').split('</red>');
        for (let i = 0; i < WordArray.length; i += 1) {
          if (i === 0) {
            returnWord.push(<span key={i}>{WordArray[i]}</span>);
          } else if (i % 2 === 1) {
            returnWord.push(
              <span key={i} style={{color: 'red'}}>
                {WordArray[i]}
              </span>,
            );
          } else if (i % 2 === 0) {
            returnWord.push(<span key={i}>{WordArray[i]}</span>);
          }
        }
        this.setState({ReceiptDisplay: returnWord});
      } else {
        this.setState({ReceiptDisplay: Word});
      }
    }

    CopyToC(TextResult) {
      const DownloadWord = TextResult.split('<red>').join('</red>').split('</red>');
      navigator.clipboard.writeText(DownloadWord.join(''))
      this.openNotification('SuccessCopied')
      this.setState({ notificationType: true });
    }

    PlatformChange(platform) {
      if (platform === '1') return 'M'
      else if (platform === '2') return (this.state.GamesDisplay === 'MKTSWBD') ? 'K': 'P'
      else if (platform === '3') return 'T'
      else if (platform === '4') return 'S'
      else if (platform === '5') return 'W'
      else if (platform === '6') return 'B'
      else if (platform === '7') return (this.state.GamesDisplay === 'MKTSWBD') ? 'D' : 'K'
      else if (platform === '8') return '8'
      return 'null'
    }

    laihui() {
      let BetNumbers = this.state.BetNumbers
      const FilterObject = BetNumbers.filter(function (values) {
        return values.Text !== ''
      });
      const LastObject = FilterObject[FilterObject.length - 1]

      if(LastObject !== undefined) {
        if(BetNumbers.length === 2 && BetNumbers[1].Text === '') {
          BetNumbers[1].Text = LastObject.Text.split("").reverse().join("");
          BetNumbers[1].Bet = LastObject.Bet;
          BetNumbers[1].Follow = LastObject.Follow;
          BetNumbers[1].IBox = LastObject.IBox;
          BetNumbers[1].Key2 = LastObject.Key2;
          BetNumbers[1].Mode = LastObject.Mode;
          BetNumbers[1].Platform = LastObject.Platform;
          BetNumbers[1].SelectedDate = "";
          BetNumbers[1].five6D = LastObject.five6D;
          BetNumbers[1].input56D = LastObject.input56D;

          this.setState({ BetNumbers })
        } else if (BetNumbers.length > 2 && BetNumbers[BetNumbers.length - 1].Text === '') {
          BetNumbers[BetNumbers.length - 1].Text = LastObject.Text.split("").reverse().join("");
          BetNumbers[BetNumbers.length - 1].Bet = LastObject.Bet;
          BetNumbers[BetNumbers.length - 1].Follow = LastObject.Follow;
          BetNumbers[BetNumbers.length - 1].IBox = LastObject.IBox;
          BetNumbers[BetNumbers.length - 1].Key2 = LastObject.Key2;
          BetNumbers[BetNumbers.length - 1].Mode = LastObject.Mode;
          BetNumbers[BetNumbers.length - 1].Platform = LastObject.Platform;
          BetNumbers[BetNumbers.length - 1].SelectedDate = "";
          BetNumbers[BetNumbers.length - 1].five6D = LastObject.five6D;
          BetNumbers[BetNumbers.length - 1].input56D = LastObject.input56D;

          this.setState({ BetNumbers })
        } else {
          this.setState(({
            BetNumbers: [
              ...this.state.BetNumbers,
              {
                Text: LastObject.Text.split("").reverse().join(""),
                Platform: LastObject.Platform,
                Bet: JSON.parse(JSON.stringify(LastObject.Bet)),
                Follow: LastObject.Follow,
                IBox: LastObject.IBox,
                Key2: LastObject.Key2,
                Mode: LastObject.Mode,
                SelectedDate: "",
                five6D: LastObject.five6D,
                input56D: LastObject.input56D,
              },
            ],
          }));
        }
      }
    }

    render() {
      const KeyInResult = this.state.KeyinFormat;
      const CurrentBtFormula = (this.state.Key2 === true) ? KeyInResult.KeyIn2 : KeyInResult.KeyIn1 || [];
      // const Key1IBox = this.state.Key1IBox || [];
      // const Key2IBox = this.state.Key2IBox || [];
      return (
      // <div className="App backgroundImage4" style={{ paddingTop: 25, minHeight: 950 }}>
        <div onScroll={(e) => this.handleScroll(e)} className='StartBackground'>
          <div id={'Top'}/>
          <Header />
          <div className="row TitleBettingVersion" style={{ display: 'flex', marginLeft: 50, marginRight: 50 }}>
            <div className="dcc jackpotborder">
              <FaHome size="25px" color="white" className="logohome" style={{ marginRight: 10 }}/>{language[this.props.currentLanguage].BetBoxVersion}
            </div>
          </div>
          <div className='dcc'>
            <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: (isMobile) ? '100%' : '70%', left: (isMobile) ? 0 : 250 }} show={this.state.showNotification}
              variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
              <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
            </Alert>
          </div>
          <div className="dcc mobileHeader"
            style={{
              paddingBottom: (isMobile) ? 10 : 30,
              justifyContent: 'space-between',
              marginLeft: (isMobile) ? '8%' : '4%',
              flexDirection: 'row',
            }}>
            <div className="TitleDate">{language[this.props.currentLanguage].Date}: {this.state.TodayDate}</div>
            <div className="dcc" style={{ marginRight: (isMobile) ? 0 : 20 }}>
              <div className="TitleUsername"> ID - {this.props.username} </div>
            </div>
          </div>
          <div style={{ border: (isMobile) ? `1px solid black` : 'none', borderRadius: (isMobile) ? 4 : 0, padding: (isMobile) ? 10 : 0 }}>
            <div className="dcc" style={{ marginBottom: (isMobile) ? 10 : 0 }}>
              <div className="dcc row" style={{ width: '99%', paddingBottom: 0, justifyContent: (isMobile) ? 'none' : 'space-between' }}>
                <div style={{ width: 600 }}>
                  <Select
                    options={this.state.optionsUsername}
                    value={this.state.SelectedUser}
                    onChange={(e) => this.SelectedUser(e)}
                  >
                  </Select>
                </div>
                {/* <div className="dcc" style={{ marginRight: 20, flexDirection: (isMobile) ? 'column' : 'row' }}>
                  <div style={{ paddingRight: 10, fontWeight: 'bold'}}>
                    {language[this.props.currentLanguage].PhoneNumber}
                  </div>
                  <div>
                    <input
                      value={this.state.PhoneNumber} name={'PhoneNumber'}
                      className="form-control"
                      style={{ borderWidth: 1, width: (isMobile) ? '100%' : 400, fontWeight: 'bold', textAlign: 'center' }}
                      onChange={(e) => this.setState({ PhoneNumber: e.target.value})}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="dcc">
              <div className="dcc" style={{ width: '99%', paddingBottom: (isMobile) ? 10 : 20, justifyContent: 'space-between', flexDirection: (isMobile) ? 'column' : 'row' }}>
                <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? '100%' : 600, fontWeight: 'bold', marginBottom: (isMobile) ? 5 : 0 }}
                  disabled={this.state.disabled}
                  onClick={() => this.OpenDateModal()}
                  className="btn my-cusomized-button button2">
                  {this.state.DDate}
                  {' '}
                </button>
                <div className='dcc' style={{ flexDirection: 'row', width: (isMobile) ? '100%' : 'auto' }}>
                  <button style={{ textAlign: 'center', backgroundColor: this.state.ButtonBackgroundRed, color: 'white', width: (isMobile) ? '50%' :200, fontWeight: 'bold', marginRight: 20 }}
                    disabled={(this.state.DisabledClick === true) ? true : false}
                    onClick={() => this.CleanData() && this.setState({ Receipt: '', ShowReceipt: false, CopyReceipt: ''})}
                    onMouseEnter={()=> {this.setState({ ButtonBackgroundRed: '#CE3A15' })}}
                    onMouseLeave={()=> {this.setState({ ButtonBackgroundRed: '#EE3E13' })}}
                    className="btn my-cusomized-button button2">
                    {language[this.props.currentLanguage].ClearAll} (Q)
                  </button>
                  <button style={{ textAlign: 'center', backgroundColor: this.state.ButtonBackgroundblue, color: 'white', width: (isMobile) ? '50%' : 200, fontWeight: 'bold' }}
                    disabled={(this.state.DisabledClick === true) ? true : false}
                    onClick={() => this.ConfirmSaveBet()}
                    onMouseEnter={()=> {this.setState({ ButtonBackgroundblue: '#1576DA' })}}
                    onMouseLeave={()=> {this.setState({ ButtonBackgroundblue: '#3C96F4' })}}
                    className="btn my-cusomized-button button2">
                    {language[this.props.currentLanguage].ConfirmTicket} (SpaceBar)
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal transparent={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.modalShow}
            contentClassName="br23">
            <Modal.Body className="dcc" style={{ padding: 10, height: 500 }}>
              <GrFormClose
                className='ButtonCloseModal'
                onClick={() => {
                  this.setState({ modalShow: false })
                }}
                size="35px"
                color="#000"
              />
              <div className="dcc" style={{ width: '100%', marginBottom: 30 }}>
                <div style={{ flexDirection: 'column' }}>
                  <div className="dcc" style={{ fontWeight: 'bold', fontSize: '20px' }}>{language[this.props.currentLanguage].Date}</div>
                  <div className="dcc" style={{ width: '100%', flexWrap: 'wrap', flexDirection: 'column' }}>
                    <div className="dcc" style={{ marginBottom: 15, flexDirection: 'column', width: '100%' }}>
                      {(this.state.ModalDate.DateLoop || []).map((item, idx) => {
                        return (
                          <button
                            index={idx}
                            className="DateSelectButton DateModalButton"
                            style={{ width: (isMobile) ? '80%' : '100%' }}
                            onClick={() => this.SelectDateFunction({ Index: idx })}>
                            <div className="dcc row" style={{ justifyContent: 'space-evenly' }}>
                              <div>{item.Date}</div>
                              {item.CheckMark && (
                                <div>
                                  <img src={CheckMark}
                                    alt="Logo"
                                    style={{ width: 40, height: 40 }}
                                  />
                                </div>
                              )}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                    <div className="column" style={{ textAlign: 'center' }}>
                      <div style={{ marginBottom: 5 }}>
                        <button style={{ backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? 150 : 200, fontWeight: 'bold', marginRight: (isMobile) ? 5 : 30 }}
                          disabled={this.state.disabled}
                          onClick={(e) => this.onpressLongTerm(e)}
                          className="btn my-cusomized-button" >
                          {language[this.props.currentLanguage].Auto}
                        </button>
                        <button style={{ backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? 150 : 200, fontWeight: 'bold' }}
                          disabled={this.state.disabled}
                          onClick={() => this.onPressConfirmSelectDate()}
                          className="btn my-cusomized-button" >
                          {language[this.props.currentLanguage].Confirm}
                        </button>
                      </div>
                      <div>
                        <button style={{ backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? 150 : 200, fontWeight: 'bold', marginRight: (isMobile) ? 5 : 30 }}
                          disabled={this.state.disabled}
                          onClick={(e) => this.onpressPeriod(2)}
                          className="btn my-cusomized-button" >
                          {language[this.props.currentLanguage].secondPeriod}
                        </button>
                        <button style={{ backgroundColor: '#3C96F4', color: 'white', width: (isMobile) ? 150 : 200, fontWeight: 'bold' }}
                          disabled={this.state.disabled}
                          onClick={() => this.onpressPeriod(3)}
                          className="btn my-cusomized-button" >
                          {language[this.props.currentLanguage].ThreePeriod}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div className={`row ${isMobile && 'dcc column'}`}> 
            <div style={{ width: (isMobile) ? '100%' : '83%', paddingBottom: 40, overflowX: 'auto' }}>
              <Table responsive="sm" style={{ backgroundColor: 'white' }}>
                <thead>
                  <tr>
                    <th><div>#</div></th>
                    <th><div>{language[this.props.currentLanguage].BetNumber}</div></th>
                    {[0,1,2,3,4,5].map((Formula, forid) => {
                      return(
                        <th key={forid}><div>{CurrentBtFormula[Formula]}</div></th>
                      )
                    })}
                    {this.state.PlatformShow && this.state.PlatformShow.map((platform, inde) => (
                      <th key={inde} style={{ backgroundColor: Platformcolor(platform, ''), color: (platform === '8') ? 'white':'black' }}><div>{this.PlatformChange(platform)}</div></th>
                    )
                    )}
                    <th><div className="dcc">{language[this.props.currentLanguage].Type}</div></th>
                    <th><div></div></th>
                    {/* <th><div>Key2</div></th> */}
                    {/* <th><div style={{ marginLeft: 5 }}>X</div></th> */}
                    <th className='dcc'>
                      <div>
                        <button className="LaihuiButtonBoxVersion button2" onClick={()=> this.laihui()}>来回</button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <thead style={{ backgroundColor: '#D5D2D2' }}>
                  <tr>
                    <td><div></div></td>
                    <td>
                      <div className="dcc" style={{ fontWeight: 'bold' }}>
                                        Key2：
                        <input
                          checked={this.state.Key2}
                          style={{ width: 15, height: 15}}
                          onChange={this.Key2Checked(0)}
                          value={'AllKey2'}
                          readOnly={false}
                          type="checkbox"
                        />
                      </div>
                    </td>
                    {[0,1,2,3,4,5].map((valuesSelect, valid) => {
                      return(
                        <td key={valid}>
                          <div>
                            <input
                              style={{ width: 15, height: 15}}
                              onChange={(e) => this.handleBetNumbers(0, e, 'All')}
                              readOnly={false}
                              checked={this.state.KeyBetAll[valuesSelect]}
                              name={`${valuesSelect}`}
                              type="checkbox"
                            />
                          </div>
                        </td>
                      );
                    })}
                    {this.state.PlatformShow && this.state.PlatformShow.map((plat,plid) => (
                      <td key={plid} style={{ backgroundColor: Platformcolor(plat, '') }}><div><input
                        readOnly={false}
                        onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                        style={{ width: 18, height: 18}}
                        checked={this.state.PlatformSelectAll[plat]}
                        name={plat}
                        type="checkbox"
                      /></div></td>
                    ))}
                    {/* <td style={{ backgroundColor: Platformcolor('1', '') }}><div><input
                      readOnly={false}
                      onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                      style={{ width: 15, height: 15}}
                      checked={this.state.PlatformSelectAll[1]}
                      name="1"
                      type="checkbox"
                    /></div></td>
                    <td style={{ backgroundColor: Platformcolor('2', '') }}><div><input
                      readOnly={false}
                      onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                      name="2"
                      style={{ width: 15, height: 15}}
                      checked={this.state.PlatformSelectAll[2]}
                      type="checkbox"
                    /></div></td>
                    <td style={{ backgroundColor: Platformcolor('3', '') }}><div><input
                      onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                      readOnly={false}
                      style={{ width: 15, height: 15}}
                      checked={this.state.PlatformSelectAll[3]}
                      name="3"
                      type="checkbox"
                    /></div></td>
                    <td style={{ backgroundColor: Platformcolor('4', '') }}><div><input
                      readOnly={false}
                      style={{ width: 15, height: 15}}
                      onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                      checked={this.state.PlatformSelectAll[4]}
                      name="4"
                      type="checkbox"
                    /></div></td>
                    <td style={{ backgroundColor: Platformcolor('5', '') }}><div><input
                      readOnly={false}
                      style={{ width: 15, height: 15}}
                      onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                      checked={this.state.PlatformSelectAll[5]}
                      name="5"
                      type="checkbox"
                    /></div></td>
                    <td style={{ backgroundColor: Platformcolor('6', '') }}><div><input
                      readOnly={false}
                      style={{ width: 15, height: 15}}
                      onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                      checked={this.state.PlatformSelectAll[6]}
                      name="6"
                      type="checkbox"
                    /></div></td>
                    <td style={{ backgroundColor: Platformcolor('7', '') }}><div><input
                      readOnly={false}
                      style={{ width: 15, height: 15}}
                      onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                      checked={this.state.PlatformSelectAll[7]}
                      name="7"
                      type="checkbox"
                    /></div></td>
                    <td style={{ backgroundColor: Platformcolor('8', '') }}><div><input
                      readOnly={false}
                      style={{ width: 15, height: 15}}
                      onChange={(e) => this.CheckPlatform(e, 'All', 0)}
                      checked={this.state.PlatformSelectAll[8]}
                      name="8"
                      type="checkbox"
                    /></div></td> */}
                    <td><div className="dcc"> <input
                      onChange={(e) => this.SelectedMode(e, 0, 'All')}
                      checked={this.state.ModeSelectAll[1]}
                      style={{ width: 15, height: 15}}
                      value={'All'}
                      readOnly={false}
                      type="checkbox"
                    /></div></td>
                    <td><div></div></td>
                    {/* <td>
                                  <div className="dcc"> 
                                    <input
                                      onChange={this.Key2Checked(0)}
                                      value={'AllKey2'}
                                      readOnly={false}
                                      type="checkbox"
                                    />
                                  </div>
                                </td> */}
                    <th><div></div></th>
                  </tr>
                </thead>
                {this.state.BetNumbers.map((Betting, idx) => (
                  <tbody index={idx}>
                    <tr style={{ backgroundColor: (Betting.IBox === true) ? 'lightblue' : 'white' }}>
                      <td><div style={{ width: 20, fontWeight: 'bold' }}>{idx + 1}</div></td>
                      <td><div style={{ width: 80 }}>
                        <input
                          autoFocus
                          value={(Betting.Text).replace(/[^0-9]/g, '')} name={'Text'} maxLength={6}
                          className="form-control"
                          style={{ borderWidth: 1, width: (isMobile) ? 80 : 120, fontWeight: 'bold', textAlign: 'center' }}
                          ref={(input) => { this[`InputBetNumber${idx}`] = input; }}
                          onChange={(e) => this.handleBetNumbers(idx, e)}
                          onKeyPress={(e) => this.KeyPressEvent(e, idx, '0')}
                          onFocus={(e) => this.KeyPressEvent(e, idx, 'Text', 'Edit')}
                        /></div></td>
                      <td><div style={{ width: 50 }}>
                        <div  style={{ marginRight:  (isMobile) ? 0 : 5 }}>
                          <input
                            value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[0] : KeyInResult.KeyIn1[0]]}
                            className="form-control" name={'0'}
                            style={{
                              borderWidth: 1,
                              width: (isMobile) ? 60 : 85,
                              color: 'red',
                              fontWeight: 'bold', textAlign: 'center'
                            }}
                            ref={(input) => { this[`InputBet1${idx}`] = input; }}
                            onChange={(e) => this.handleBetNumbers(idx, e)}
                            onKeyPress={(e) => this.KeyPressEvent(e, idx, '1')}
                            onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[0] : KeyInResult.KeyIn1[0], 'Edit')}
                            placeholder={(this.state.BetNumbers[idx].Key2 === true)
                              ? KeyInResult.KeyIn2[0] : Betting.five6D === true ? '5/6D' 
                                : (Betting.five6D === false && Betting.input56D === true) ? this.state.key3D[0] : KeyInResult.KeyIn1[0]}
                          />
                        </div></div></td>
                      <td><div style={{ width: 50 }}>
                        <input
                          value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[1] : KeyInResult.KeyIn1[1]]}
                          className="form-control" name={'1'}
                          style={{ borderWidth: 1, width: (isMobile) ? 60 : 85, color: 'red', fontWeight: 'bold', textAlign: 'center' }}
                          ref={(input) => { this[`InputBet2${idx}`] = input; }}
                          onChange={(e) => this.handleBetNumbers(idx, e)}
                          onKeyPress={(e) => this.KeyPressEvent(e, idx, '2')}
                          onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[1] : KeyInResult.KeyIn1[1], 'Edit')}
                          placeholder={(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[1] : (Betting.five6D === false && Betting.input56D === true) ? this.state.key3D[1] 
                              : (Betting.five6D === true && Betting.input56D === true) ? '' : KeyInResult.KeyIn1[1]}
                          disabled={(Betting.Text).split('').length > 4}
                        /></div></td>
                      <td><div style={{ width: 50 }}>
                        <input
                          value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[2] : KeyInResult.KeyIn1[2]]}
                          className="form-control" name={'2'}
                          style={{ borderWidth: 1, width: (isMobile) ? 60 : 85, color: 'red', fontWeight: 'bold', textAlign: 'center' }}
                          ref={(input) => { this[`InputBet3${idx}`] = input; }}
                          onChange={(e) => this.handleBetNumbers(idx, e)}
                          onKeyPress={(e) => this.KeyPressEvent(e, idx, '3')}
                          onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[2] : KeyInResult.KeyIn1[2], 'Edit')}
                          placeholder={(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[2] : Betting.input56D === true ? '' : KeyInResult.KeyIn1[2]}
                          disabled={Betting.input56D}
                        /></div></td>
                      <td><div style={{ width: 50 }}>
                        <input
                          value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[3] : KeyInResult.KeyIn1[3]]}
                          className="form-control" name={'3'}
                          style={{ borderWidth: 1, width: (isMobile) ? 60 : 85, color: 'red', fontWeight: 'bold', textAlign: 'center' }}
                          ref={(input) => { this[`InputBet4${idx}`] = input; }}
                          onChange={(e) => this.handleBetNumbers(idx, e)}
                          onKeyPress={(e) => this.KeyPressEvent(e, idx, '4')}
                          onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[3] : KeyInResult.KeyIn1[3], 'Edit')}
                          placeholder={(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[3] : Betting.input56D === true ? '' : KeyInResult.KeyIn1[3]}
                          disabled={Betting.input56D}
                        /></div></td>
                      <td><div style={{ width: 50 }}>
                        <input
                          value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[4] : KeyInResult.KeyIn1[4]]}
                          className="form-control" name={'4'}
                          style={{ borderWidth: 1, width: (isMobile) ? 60 : 85, color: 'red', fontWeight: 'bold', textAlign: 'center' }}
                          ref={(input) => { this[`InputBet5${idx}`] = input; }}
                          onChange={(e) => this.handleBetNumbers(idx, e)}
                          onKeyPress={(e) => this.KeyPressEvent(e, idx, '5')}
                          onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[4] : KeyInResult.KeyIn1[4], 'Edit')}
                          placeholder={(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[4] : Betting.input56D === true ? ''  : KeyInResult.KeyIn1[4]}
                          disabled={Betting.input56D}
                        /></div></td>
                      <td><div style={{ width: 50 }}>
                        <input
                          value={Betting.Bet[(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[5] : KeyInResult.KeyIn1[5]]}
                          className="form-control" name={'5'}
                          style={{ borderWidth: 1, width: (isMobile) ? 60 : 85, color: 'red', fontWeight: 'bold', textAlign: 'center' }}
                          ref={(input) => { this[`InputBet6${idx}`] = input; }}
                          onChange={(e) => this.handleBetNumbers(idx, e)}
                          onKeyPress={(e) => this.KeyPressEvent(e, idx, '6')}
                          onFocus={(e) => this.KeyPressEvent(e, idx, (this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[5] : KeyInResult.KeyIn1[5], 'Edit')}
                          placeholder={(this.state.BetNumbers[idx].Key2 === true)
                            ? KeyInResult.KeyIn2[5] : Betting.input56D === true ? '' : KeyInResult.KeyIn1[5]}
                          disabled={Betting.input56D}
                        /></div></td>
                      {this.state.PlatformShow && this.state.PlatformShow.map((item, idxs) => {
                        return <td index={idxs} style={{ backgroundColor: Platformcolor(item, '')}}>
                          <div>
                            <input
                              type="checkbox"
                              style={{ marginTop: 12, width: 18, height: 18 }}
                              checked={Betting.Platform.includes(item)}
                              id = {item}
                              name={item}
                              onChange={(e) => this.CheckPlatform(e, item, idx)}
                              onKeyPress={(e) => this.CheckBoxKeyPressEvent(e, idx)}
                            />
                          </div>
                        </td>
                      })}
                      <td>
                        <div>
                          <div className="dcc">
                            <select className="form-control DropdownListBoxV"
                              style={{
                                width: 120,
                              }}
                              value={Betting.Mode}
                              disabled={Betting.five6D}
                              onChange={(e) => this.SelectedMode(e, idx)}>
                              <option value="none">{'none'}</option>
                              <option value="IBox">{'IBox'}</option>
                              <option value="Box">{'Box'}</option>
                              <option value="{0-9}123">{'{0-9}123'}</option>
                              <option value="123{0-9}">{'123{0-9}'}</option>
                              <option value="{123}4">{'{123}4'}</option>
                              <option value="1{234}">{'1{234}'}</option>
                            </select>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button 
                            className='button2'
                            // disabled={(Betting.Follow === 'disable') ? true : false}
                            style={{
                              width: 'auto',
                              backgroundColor: (Betting.Follow === 'Follow' || Betting.Follow === 'End') ? '#3c96f4' : '',
                              color: (Betting.Follow === 'Follow' || Betting.Follow === 'End') ? 'white' : 'black',
                            }}
                            onClick={(e) => this.ControlType(e, idx)}
                            value={'ControlType'}
                            type="button">
                            {(Betting.Follow === 'Follow'|| Betting.Follow === '' || Betting.Follow === 'disable') ? `Follow` : `End`}
                          </button>
                        </div>
                      </td>
                      {/* <td>
                        <div>
                          <input
                            checked={Betting.IBox}
                            // style={{ height: -20 }}
                            onChange={this.IBoxChecked(idx)}
                            value={'IBox'}
                            readOnly={false}
                            type="checkbox"
                            disabled={Betting.five6D}
                          />
                        </div>
                      </td> */}
                      {/* <td>
                                        <div>
                                            <input
                                                onChange={this.Key2Checked(idx)}
                                                value={'Key2'}
                                                readOnly={false}
                                                type="checkbox"
                                            />
                                        </div>
                                    </td> */}
                      <td style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: 5 }}>
                          {idx ?
                            <button
                              disabled={(Betting.Follow === 'Follow') ? true : false}
                              style={{ color: 'white' }}
                              type="button"
                              onClick={this.handleRemoveNumbersChange(idx)}
                              className="ReduceButton button2">
                                                  -
                            </button>
                            :
                            null
                          }
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
              <div className="dcc" style={{ fontWeight: 'bold' }}>{language[this.props.currentLanguage].PressEnterAddNewLine}</div>
            </div>
            <div style={{ backgroundColor: 'white', width: (isMobile) ? '60%': '15%', borderRadius: 5, marginLeft: 10}}>
              {this.state.ShowReceipt === true &&
                    <>
                      <div className="dcc row" style={{ marginBottom: 30, fontWeight: 'bold', fontSize: '20px'}}>
                        <div>{`${(this.state.SuccessFullCancel === true) ? 
                          language[this.props.currentLanguage].CancelSuccessfulldisplay 
                          : language[this.props.currentLanguage].SuccessOrderBet }`}</div>
                        <div>
                          <GrFormClose
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({ ShowReceipt: false, Receipt: '' })
                            }}
                            size="35px"
                            color="#000"
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: 30, fontSize: '16px', whiteSpace: 'pre-wrap', fontWeight: 'bold', textAlign: 'start', marginLeft: 10}}>
                        {this.state.ReceiptDisplay}
                      </div>
                      <button style={{ backgroundColor: '#3C96F4', color: 'white', width: 'auto', fontWeight: 'bold', marginBottom: 10, marginRight: 5 }}
                        className="btn my-cusomized-button button2" onClick={() => {this.CopyToC(this.state.Receipt)}}>{language[this.props.currentLanguage].CopyReceipt}</button>
                      <button style={{ backgroundColor: '#3C96F4', color: 'white', width: 'auto', fontWeight: 'bold', marginBottom: 10 }}
                        onClick={() => this.CancelBetAPI()}
                        className="btn my-cusomized-button" >
                        {language[this.props.currentLanguage].CancelTicket}
                      </button>
                    </>
              }
            </div>
            {this.state.is_visible && 
              <div className="dcc" style={{ paddingBottom: 30 }}>
                <div class="scrollDesign iconpopup button2"  
                  onClick={() => this.scrollToTop()}>
                  {/* ↑ ⇈ */}
                  <div  className="icon MoveUpArrow"> ⇧ </div>
                </div>
              </div>
            }
          </div>
        </div>
      );
    }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BetBoxVersion);
