import React, {Component, Fragment} from 'react';
import '../App.css';
import 'react-datepicker/dist/react-datepicker.css';
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert';
// import {isMobile} from 'react-device-detect';
// import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import { language } from '../language';
import { Form } from 'react-bootstrap';
import Header2 from '../Component/Header';
import { FiRefreshCcw } from '@react-icons/all-files/fi/FiRefreshCcw';
import { ImArrowLeft, ImArrowRight } from 'react-icons/im';
import { connect } from 'react-redux';
import Moment from 'moment-timezone';
import Loading from '../Images/loading.gif';
import SearchUsersModal from '../Component/Modal/SearchUsersModal';
const { getEditedRecords, getMyselfF, secondReportUserListFNC, searchDownlineHR } = require('../Api');
// api PostRecallBet
var timer 

class DisplayEditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      UserID: this.props.userID,
      token: this.props.token,
      loading: false,
      Search_Username: '',
      Min_Date: Moment(localStorage.getItem('Min_Date')).toDate(),
      Max_Date: Moment(localStorage.getItem('Max_Date')).toDate(),
      Available: false,
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      page: 1,
      countperpage: 10,
      countpagemax: 1,
      countpagemin: 1,
      DisplayEdit: [],
      lineData: [
        {
          originalWord: '',
          translatedWord: '',
          Mode: '',
        }
      ],
      SearchServerID: '',
      SearchOrderID: '',
      Authority: '',
      SearchNumber: '',
      SelectedDate: Moment(new Date()).toDate(),
      is_visible: false,
      SelectedUser: '',
      optionsDownlineUsername: '',
      SelectedDownlineUser: '',
      SearchUSersModal: false,
      SearchedID : [],
      SearchUser: '',
    }
    this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
    this.SelectedUser = this.SelectedUser.bind(this);
  }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ UserID: (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID }, async () => {
      if ((UserAuthorities && UserAuthorities.Bet) === 0) {
        window.location.href='/Home'; 
      } else {
        this.CallAPI();
        this.SetUsersAuthorities();
        const UserID = this.state.UserID
        const getMyself = await getMyselfF(UserID, this.props.token, this.props.username)
    
        if(getMyself.length !== 0) {
          this.getSelectedDownline(getMyself[0].ID)
        }
      }
    })
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  SetUsersAuthorities() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({
      Authority: UserAuthorities,
    })
  }

  async setSelectedDate(date, mode) {
    this.setState({
      [mode]: date,
    }, ()=> {
      this.CallAPI();
    });
  }

  async CallAPI() {
    this.setState({ loading: true })
    const token = this.state.token
    const username = this.state.username
    const UserID = this.state.UserID
    const page = this.state.page
    const SelectedDate = Moment(this.state.SelectedDate).format('YYYY-MM-DD')
    const countperpage = this.state.countperpage
    const SearchServerID = JSON.parse(JSON.stringify(this.state.SearchServerID || ''))
    const SearchOrderID = JSON.parse(JSON.stringify(this.state.SearchOrderID || ''))
    const SearchNumber = JSON.parse(JSON.stringify(this.state.SearchNumber || ''))
    const SearchDownline = JSON.parse(JSON.stringify(this.state.SelectedUser || ''))
  

    // let tempArray = []
    // let tempObject = {}
    // if(getMyself.length !== 0) {
    //   const SelectedDownlineUsername = await secondReportUserListFNC(this.props.username, getMyself[0].ID, token)
    //   SelectedDownlineUsername.forEach((item) => {
    //     tempObject = {
    //       ...tempObject,
    //       value: item.ID,
    //       label: item.Username,
    //     }
    //     tempArray.push(tempObject)
    //   })
    //   if(SelectedDownlineUsername) {
    //     console.log('tempArray', tempArray)
    //     this.setState({ DownlineID: tempArray[0].value, SelectedDownlineUser: tempArray[0], optionsDownlineUsername: tempArray })
    //   }
    // }

    let checksearch = 0
    if(SearchServerID !== '' ) {
      checksearch = checksearch + 1
    }
    if(SearchOrderID !== '' && SearchDownline !== '' ) {
      checksearch = checksearch + 1
    }
    if(SearchNumber !== '' ) {
      checksearch = checksearch + 1
    }

    if(SearchServerID === '' && SearchOrderID === '' && (SearchNumber === '' && SearchDownline === '')) {
      checksearch = 0
    }
    if(checksearch === 1) {
      const getEditedData = await getEditedRecords(UserID, page, countperpage, SelectedDate, SearchServerID, SearchOrderID, SearchNumber, SearchDownline, token, username)

      if(getEditedData.error === 'No Data') {
        this.setState({ DisplayEdit: [], loading: false })
      } else {
        this.setState({ DisplayEdit: getEditedData ? getEditedData : '' || [], loading: false })

        if (getEditedData.length > 0) {
          this.setState({
            countpagemax: Math.ceil(
              getEditedData[0].FullCount / this.state.countperpage,
            ),
          });
        } else {
          this.setState({
            countpagemax: 1,
            page: 1,
          });
        }
      }
    } else if(checksearch === 0) {
      this.setState({ DisplayEdit: [], loading: false })
    } else {
      this.setState({ notificationType: 'error' });
      this.openNotification('Enter1box')
      this.setState({ DisplayEdit: [], loading: false })
    }
  }

  async EditReOrderReceipt(idx) {
    const DisplayEdit = this.state.DisplayEdit;
    const OriginalT = DisplayEdit[idx].OriginalText
    let OriginalSplit = OriginalT.split('\n')
    let LineData = this.state.lineData;
    for (let i = 0; i < OriginalSplit.length; i++) {
      if ((LineData[i] || '') !== '') {
        LineData[i].originalWord = OriginalSplit[i];
      } else {
        LineData.push({
          originalWord: OriginalSplit[i],
          translatedWord: '',
          Mode: '',
        });
      }
    }
    this.props.history.push({
      pathname: '/betting',
      ReOrderBet: LineData,
    })
  }

  // async EditReceipt(idx) {
  //   // const DisplayEdit = this.state.DisplayEdit;
  //   const TimeOutAuthority = this.state.Authority.CancelOrRevertTimeoutOrder;
  //   if(this.props.Role === 'admin') {
  //     if(TimeOutAuthority === 1) {
  //       this.RecallBackFunction(idx);
  //     } else {
  //       if(Moment().hours() >= 19) {
  //         this.openNotification('AuthoExceededHours')
  //         this.setState({ notificationType: 'error' });
  //       } else {
  //         this.RecallBackFunction(idx);
  //       }
  //     }
  //   } else {
  //     this.openNotification('DontHaveAutho')
  //     this.setState({ notificationType: 'error' });
  //   }
  // }

  // async RecallBackFunction(idx) {
  //   const DisplayEdit = this.state.DisplayEdit;
  //   // const ServerID = DisplayEdit[idx].ServerID
  //   const OriginalText = DisplayEdit[idx].OriginalText

  //   const Recall = await CreateBetOrder(DisplayEdit[idx].OrderUserID, OriginalText, this.props.token, this.props.username)
  //   if (Recall.error === 'User No Package') {
  //     this.openNotification('NoPackage')
  //     this.setState({ notificationType: 'error' });
  //   } else if(Recall.error === 'No Date to Bet') {
  //     this.openNotification('DateCantbeBet')
  //     this.setState({ notificationType: 'error' });
  //   } else if(Recall.error === '5D6D in wrong game') {
  //     this.openNotification('OnlyBTandTOTOallow')
  //     this.setState({ notificationType: 'error' });
  //   } else if(Recall.error === 'Not enough date') {
  //     this.openNotification('UnableThoseDate')
  //     this.setState({ notificationType: 'error' });
  //   }  else if(Recall.error === 'Date error') {
  //     this.openNotification('UnableThoseDate')
  //     this.setState({ notificationType: 'error' });
  //   } else if(Recall.error === 'S Game ShutDown') {
  //     this.openNotification('singaporeGameClose')
  //     this.setState({ notificationType: 'error' });
  //   } else if(Recall.error === 'Game ShutDown') {
  //     this.openNotification('AllGameClose')
  //     this.setState({ notificationType: 'error' });
  //   } else if(Recall.error === 'Platform error') {
  //     this.openNotification('ErrorPlatform')
  //     this.setState({ notificationType: 'error' });
  //   } else if(Recall.error === 'Account Inactive') {
  //     this.openNotification('AccountInactive')
  //     this.setState({ notificationType: 'error' });
  //   } else if(Recall.error === 'Exceed Personal Daily Limit') {
  //     this.openNotification('ExceedDAilyLimit')
  //     this.setState({ notificationType: 'error' });
  //   } else { 
  //     this.openNotification('RecallSuccessful')
  //     this.setState({ notificationType: 'success'}, () => {
  //       this.CallAPI();
  //     });
  //   }
  // }

  changePage() {
    this.setState({ DisplayEdit: [] }, () => {
      this.CallAPI();
    });
  }

  async handleSearchText(e, field) {
    const value = (e.target.value).replace(/[^0-9]/g, '')
    this.setState({ [field]: value })
  }

  async SearchByOrderID(e, Searchtype) {
    const username = this.state.username
    const UserID = this.state.UserID
    const token = this.state.token
    const countperpage = this.state.countperpage
    const SelectedDate = Moment(this.state.SelectedDate).format('YYYY-MM-DD')
    if(e.key === 'Enter') {
      this.setState({ loading: true })
      if (Searchtype === 'SearchServerID') {
        if(e.target.value === '') {
          this.CallAPI();
        } else {
          const getEditedData = await getEditedRecords(
            UserID,
            1,
            countperpage,
            SelectedDate,
            this.state.SearchServerID,
            '',
            '',
            '',
            token,
            username,
          )
          if(getEditedData.error === 'No Data') {
            this.setState({ DisplayEdit: [],   SearchOrderID: '',SelectedUser: '' ,SelectedDownlineUser: {value: '', label: `${language[this.props.currentLanguage].SelectedUsernameno}`}, loading: false, page: 1 })
          } else {
            this.setState({ DisplayEdit: getEditedData ? getEditedData : '' || [], SearchOrderID: '', SearchNumber: '', SelectedUser: '' , SelectedDownlineUser: {value: '', label: `${language[this.props.currentLanguage].SelectedUsernameno}`},loading: false })
            if (getEditedData.length > 0) {
              this.setState({
                countpagemax: Math.ceil(
                  getEditedData[0].FullCount / this.state.countperpage,
                ),
              });
            } else {
              this.setState({
                countpagemax: 1,
              });
            }
          }
        }
      } else if(Searchtype === 'SearchOrderID') {
      
        if(e.target.value === '' && (this.props.Role === 'admin' || this.props.Role === 'superAdmin')) {
       
          this.CallAPI();
        } else {
       
          if(this.state.SelectedUser === '') {
            this.setState({ notificationType: 'error', DisplayEdit: [], loading: false  });
            this.openNotification('SelectedUsernameno')
          } else if(this.state.SearchOrderID === '') {
            this.setState({ notificationType: 'error', DisplayEdit: [], loading: false  });
            this.openNotification('plsinsert')
          } else {
            const getEditedData = await getEditedRecords(
              UserID,
              1,
              countperpage,
              SelectedDate,
              '',
              this.state.SearchOrderID,
              '',
              this.state.SelectedUser,
              token,
              username,
            )
       
            if(getEditedData.error === 'No Data') {
              this.setState({ DisplayEdit: [],  SearchServerID: '',   loading: false, page: 1 })
            } else {
              this.setState({ DisplayEdit: getEditedData ? getEditedData : '' || [], page: 1, SearchServerID: '', SearchNumber: '',  loading: false })
              if (getEditedData.length > 0) {
                this.setState({
                  countpagemax: Math.ceil(
                    getEditedData[0].FullCount / this.state.countperpage,
                  ),
                });
              } else {
                this.setState({
                  countpagemax: 1,
                });
              }
            }
          }
        }
      } else if(Searchtype === 'SearchNumber') {
        if(this.state.SearchNumber === '') {
          this.setState({ notificationType: 'error', DisplayEdit: [], loading: false  });
          this.openNotification('plsinsertN')
        } else {
          const getEditedData = await getEditedRecords(
            UserID,
            1,
            countperpage,
            SelectedDate,
            '',
            '',
            this.state.SearchNumber,
            '',
            token,
            username,
          )
          if(getEditedData.error === 'No Data') {
            this.setState({ DisplayEdit: [],  SearchServerID: '', SearchOrderID: '',SelectedUser: '',SelectedDownlineUser: {value: '', label: `${language[this.props.currentLanguage].SelectedUsernameno}`}, loading: false, page: 1 })
          } else {
            this.setState({ DisplayEdit: getEditedData ? getEditedData : '' || [], page: 1, SearchServerID: '', SearchOrderID: '',SelectedUser: '',SelectedDownlineUser: {value: '', label: `${language[this.props.currentLanguage].SelectedUsernameno}`}, loading: false })
            if (getEditedData.length > 0) {
              this.setState({
                countpagemax: Math.ceil(
                  getEditedData[0].FullCount / this.state.countperpage,
                ),
              });
            } else {
              this.setState({
                countpagemax: 1,
              });
            }
          }
        }
      }
    }
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  async SearchFunction(){
    this.setState({ SearchUSersModal: true })
    if (this.state.SearchUser !== '') {
      const Searchresult = await searchDownlineHR(this.state.SearchUser, this.props.Role, this.props.userID, this.props.token, this.props.username)
      if(Searchresult.message === 'Invalid Downline ID or Username') {
        this.setState({ notificationType: 'error' }, () => {
          this.openNotification('InvalidDownlineIDorUsername')
        });
      } else {
        Searchresult.sort((a, b) => (a.Role > b.Role) ? 1 : -1)
        this.setState({ SearchedID: Searchresult })
      }
    }
  }

  handleCloseSearchModal() {
    this.setState({
      SearchUSersModal: false,
      SearchedID : [],
    })
  }


  async getSelectedDownline(SelectedUser) {
    const token = this.state.token
    let tempArray = []
    let tempObject = {}
    const SelectedDownlineUsername = await secondReportUserListFNC(this.props.username, SelectedUser, token)
   
    SelectedDownlineUsername.forEach((item) => {
      tempObject = {
        ...tempObject,
        value: item.ID,
        label: item.Username,
      }
      tempArray.push(tempObject)
    })
    tempArray.unshift({value: '', label: `${language[this.props.currentLanguage].SelectedUsernameno}`})
    if(SelectedDownlineUsername) {
      this.setState({ DownlineID: tempArray[0].value, SelectedDownlineUser: tempArray[0], optionsDownlineUsername: tempArray })
    }
  }

  SelectedUser(e, ) {
    if(e.value === '') {
      this.setState({ SelectedDownlineUser: e, SelectedUser: e.value, DisplayEdit: [], SearchServerID: '',  SearchNumber: '',  loading: false, page: 1 })
      this.handleCloseSearchModal();
    } else  {
      this.setState({ SelectedDownlineUser: e, SelectedUser: e.value})
      this.handleCloseSearchModal();
    }
  }

  ShotCutKeySearchFunction(event){
    if (event.key === 'Enter') {
      this.SearchFunction();
    }
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowX: 'auto' }}> 
        {/* <Alert show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
    <Alert.Heading>{this.state.AlertMessage}</Alert.Heading>
  </Alert> */}
        <div id={'Top'}/>
        <Header2 />
        <SearchUsersModal SearchUSersModal={this.state.SearchUSersModal} SearchedID={this.state.SearchedID} SelectedUser={this.SelectedUser} handleCloseSearchModal={this.handleCloseSearchModal}/>
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} 
          show={this.state.showNotification}
          variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <div className="dcc row" style={{ overflowX: 'auto' }}>
          <div style={{ width: 'auto'}}>
            <div className="dcc row">
              <div className="dcc resultP1P2P3" style={{ marginBottom: 10, width: 'auto', fontWeight: 'bold', padding:10}}>
                {language[this.props.currentLanguage].EditedBetHistory}
              </div>
              <div>
                <FiRefreshCcw
                  style={{ cursor: 'pointer', marginLeft: 0, marginBottom: 10 }}
                  onClick={() => {
                    this.CallAPI();
                    this.setState({ SearchServerID: '', SearchOrderID: '' });
                  }}
                  size="30px"
                  color="black"
                />
              </div>
            </div>
            <div className="dcc" style={{ paddingBottom: 10, flexDirection: 'column' }}>
              <div className='dcc' style={{ width: '50%', display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                <div style={{ marginRight: 5, fontWeight: 'bold', minWidth: 100 }}>{'开采日期 :'}</div>
                <DatePicker
                  onChange={(date) => this.setSelectedDate(date, 'SelectedDate')}
                  dateFormat='dd-MMM-yyyy'
                  placeholder="Date"
                  // maxDate={new Date()}
                  selected={this.state.SelectedDate}
                  value={this.state.SelectedDate}
                  className={"form-control StyleDatePicker"}
                  // withPortal
                />
              </div>
              <div className="dcc" style={{ paddingBottom: 10, flexDirection: 'row' }}>
                {this.state.optionsDownlineUsername.length > 0 && 
          <div style={{ width: 220, marginLeft: 5 }}>
            {/* <Select
              options={this.state.optionsDownlineUsername}
              value={this.state.SelectedDownlineUser}
              onChange={(e) => this.SelectedAgent(e)}
            >
            </Select> */}
            <Form.Control style={{ width: 200, marginRight: 10, fontWeight: 'bold'}} type="text" placeholder="ID / Username" value={this.state.SearchUser} onChange={(e) => this.setState({SearchUser: e.target.value})} onKeyPress={(e) => this.ShotCutKeySearchFunction(e)}/>
          </div>
                }
              </div>
              <div className="dcc" style={{ paddingBottom: 10, flexDirection: 'row' }}>
                <Form.Control
                  value={this.state.SearchServerID}
                  className='ReceiptHistorySearch'
                  style={{ width: 250 }}
                  placeholder={'(S)ID'}
                  type="text"
                  onChange={(e) => this.handleSearchText(e, 'SearchServerID')}
                  onKeyPress={(e) => this.SearchByOrderID(e, 'SearchServerID')}
                />
                <Form.Control
                  value={this.state.SearchOrderID}
                  className='ReceiptHistorySearch'
                  style={{ width: 250 }}
                  placeholder={'(#)ID'}
                  type="text"
                  onChange={(e) => this.handleSearchText(e, 'SearchOrderID')}
                  onKeyPress={(e) => this.SearchByOrderID(e, 'SearchOrderID')}
                />
                <Form.Control
                  value={this.state.SearchNumber}
                  className='ReceiptHistorySearch'
                  style={{ width: 250 }}
                  placeholder={'Bet Number'}
                  type="text"
                  onChange={(e) => this.handleSearchText(e, 'SearchNumber')}
                  onKeyPress={(e) => this.SearchByOrderID(e, 'SearchNumber')}
                />
              </div>
            </div>
          </div>
          {this.state.loading  === true ? ( 
            <div className="divLoader" style={{ display: 'flex', flexDirection: 'column'}}>
              <img src={Loading} alt="Logo" className="loadingreceiptimages" style={{ marginBottom: 20 }} />
              <div className="loadingMessageBet">{language[this.props.currentLanguage].LoadingPleaseWaitAMoment}</div>
            </div>
          ) : (
            <div style={{ width: '97%', justifyContent: 'center', overflowX: 'auto'}}>
              <Table responsive="sm" style={{ backgroundColor: 'white', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ width: '30%', textAlign: 'start', fontSize: '16.5px'}}><div></div>{language[this.props.currentLanguage].Details}</th>
                    <th style={{ width: '28%', textAlign: 'start', fontSize: '16.5px'}}><div>{language[this.props.currentLanguage].BeforeEdit}</div></th>
                    <th style={{ width: '27%', textAlign: 'start', fontSize: '16.5px'}}><div>{language[this.props.currentLanguage].AfterEdit}</div></th>
                    {/* <th style={{ width: (isMobile) ? 300 : '27%', textAlign: 'start', fontSize: '20px'}}><div></div></th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.DisplayEdit.length === 0 ? (
                    <>
                      <tr style={{ width: '100%', backgroundColor: '#C0C0C0', fontWeight: 'bold', fontSize: '18px'}}>
                        <td colSpan="4">{language[this.props.currentLanguage].NoData}</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {this.state.DisplayEdit && this.state.DisplayEdit.map((item, idx) => {
                        return (
                          <tr index={idx} style={{ fontSize: '15px' }}>
                            <td style={{ backgroundColor: '#FDD1B5', borderWidth: 2}}>
                              <div className="DisplayEditData" style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold'}}>
                                {`id: ${item.ServerID} \nDate/Time: ${Moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')} \nAction By: ${item.ActionBy}\nAction Code: ${item.ActionCode}`}
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#C0C0C0', fontWeight: 'bold'}}>
                              <div className="DisplayEditData" style={{ whiteSpace: 'pre-wrap'}}>
                                {`${item.Before.split('<red>').join('</red>').split('</red>')}`}
                              </div>
                            </td>
                            <td style={{ backgroundColor: '#C0C0C0', fontWeight: 'bold'}} >
                              <div className="DisplayEditData" style={{ whiteSpace: 'pre-wrap'}}>
                                {item.After.split('<red>').join('</red>').split('</red>')}
                              </div>
                            </td>
                            {/* <td>
                              {item.ActionCode === 'Cancelled' && 
                            <div style={{ padding: 5}}>
                              <button style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: '65%', fontWeight: 'bold', marginBottom: 5 }}
                                disabled={this.state.disabled}
                                onClick={() => this.EditReceipt(idx)}
                                className="btn my-cusomized-button">
                                {language[this.props.currentLanguage].WithdrawTicket}
                              </button>
                            </div>
                              }
                            </td> */}
                          </tr>
                        ); 
                      })}
                    </>
                  )}
                </tbody>
              </Table>
              <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.state.page > this.state.countpagemin ? (
                  <button  style={{ textAlign: 'center',backgroundColor: '#3C96F4', color: 'white', width: 100, borderColor: '#1e5e8a' }} 
                    className="btn my-cusomized-button" 
                    onClick={() =>
                      this.setState(
                        {
                          page: this.state.page - 1,
                        },
                        () => {
                          this.changePage(this.state.page);
                        },
                      )
                    }>
                    <ImArrowLeft size="16px" color="white" />
                  </button>
                ) : (
                  <div style={{ width: 100 }}/>
                )}
                <div style={{ fontWeight: 'bold', marginLeft: 100, marginRight: 100}}>{this.state.page}</div>
                {this.state.page < this.state.countpagemax ? (
                  <button
                    style={{ textAlign: 'center', backgroundColor: '#3C96F4', color: 'white', width: 100, borderColor: '#1e5e8a' }}
                    className="btn my-cusomized-button" 
                    onClick={() =>
                      this.setState(
                        {
                          page: this.state.page + 1,
                        },
                        () => {
                          this.changePage();
                        },
                      )
                    }>
                    <ImArrowRight size="16px" color="white" />
                  </button>
                ) : (
                  <div style={{ width: 100 }} />
                )}
              </div>
            </div>
          )}
          {this.state.is_visible && 
            <div className="dcc" style={{ paddingBottom: 30 }}>
              <div className="scrollDesign iconpopup button2"  
                onClick={() => this.scrollToTop()}>
                {/* ↑ ⇈ */}
                <div  className="icon MoveUpArrow"> ⇧ </div>
              </div>
            </div>
          }
        </div>
      </div>    
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    userID: state.userID,
    UserAuthority: state.UserAuthority,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMinDate: (Min_Date) => {
      dispatch({
        type: 'MINSET_DATE',
        payload: Min_Date,
      });
    },
    setMaxDate: (Max_Date) => {
      dispatch({
        type: 'MAXSET_DATE',
        payload: Max_Date,
      });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayEditPage);