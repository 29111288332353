import React, { Component } from 'react';
import '../App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Table from 'react-bootstrap/Table';
import { language } from '../language';
import Alert from 'react-bootstrap/Alert';
// import {isMobile} from 'react-device-detect';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Header2 from '../Component/Header';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment-timezone';
import updown from '../Images/updown1.png';
const { accountReport, getMyDownline } = require('../Api');
var timer

class Sales extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      page: 1,
      countperpage: 12,
      countpagemax: 1,
      countpagemin: 1,
      ReportDetails: [],
      ReportAll: [],
      SelfDetails: [],
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      SearchUsername: '',
      ShowTable: false,
      ReportUserID: '',
      Authority: {},
      userIDOriginal: '',
      SelectedDate: Moment().toDate(),
      ModeType: 'Details',
      DisplayOption: 'ShowSome',
      AllTotalAmount: '',
      downline: {},
      PopScrollBackButton: false,
      is_visible: false,
      BigTotal: '', BigLastBettingDate: '', BigB: '', BigS: '', BigA: '', BigC: '', BigA1: '', BigAC: '', BigA2: '',
      BigA3: '', BigAD: '', BigAE: '', Big5D: '', BigPA1: '', BigPA2: '', BigPA3: '', BigPC: '', BigPD: '', BigPE: '',
      Big6D: '',
      BigBandS: '',
      BigCA2: '', BigCA3: '', BigCD: '', BigCE: '',
      SelectedType: [
        { Name: 'Normal', check: false},{ Name: 'Red', check: false},
        { Name: 'SGD', check: false}, {Name: 'THAI', check: false}, { Name: 'All', check: true}
      ],
      ViewType: 'All',
    }
    this.setSelectedDate = this.setSelectedDate.bind(this);
  }

  async componentDidMount() {
    const UserAuthorities = JSON.parse(this.props.UserAuthority)
    this.setState({ 
      ReportUserID: (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? 'C' : (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID, 
      userIDOriginal: (this.props.Role === 'admin' || this.props.Role === 'superAdmin') ? 'C' : (UserAuthorities.isSub === 1) ? UserAuthorities.ActingAs : this.props.userID,
      Authority: UserAuthorities
    }, ()=>{
      this.getReport();
      this.getDownline();
    })
  }

  handleScroll(event) {
    var node = event.target;
    if (node.scrollTop > 1200) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop(){
    const element = document.getElementById('Top')
    element.scrollIntoView({ behavior: "smooth" })
  }

  async getDownline() {
    const UserId = this.state.ReportUserID
    const downlines = await getMyDownline(UserId, this.props.token, this.props.username)
    if(downlines) {
      this.setState({ downline: downlines })
    }
  }

  async getReport() {
    this.setState({ loading: true })
    const ViewType = this.state.ViewType
    const AccountReport = await accountReport(
      Moment(this.state.SelectedDate).format('YYYY-MM-DD'),
      this.state.ReportUserID,
      this.state.SearchUsername,
      ViewType,
      this.props.username,
      this.props.token,
    );
    // console.log('AccountReport', AccountReport)
    if(AccountReport) {
      let selfProfitReport = {};
      const filtered = Object.values(AccountReport).filter((item) => {
        if (item.ID !== this.state.ReportUserID) {
          return true;
        } else {
          selfProfitReport = item;
          return false;
        }
      });
      // var filterSelfBet = filtered.filter(function (el) {
      //   return el.Self === true
      // })
      
      // var filterDownlineDetails = filtered.filter(function (values) {
      //   return values.Self === false
      // })
      this.setState({
        ReportDetails: filtered, ShowTable: true, loading: false, SelfDetails: [selfProfitReport], ReportAll: filtered,
      }, ()=> {
        if(this.state.DisplayOption === 'ShowSome') {
          const ReportDetails = this.state.ReportDetails
          var HideZero = ReportDetails.filter(function (item) {
            return item.Total !== '0.00'
          });
          this.setState({ ReportDetails: HideZero })
          this.calculateTotal(ReportDetails);
        } else {
          this.calculateTotal(this.state.ReportDetails);
        }
      })
    }
    this.setState({  BigTotal: '', BigLastBettingDate: '', BigB: '', BigS: '', BigA: '', BigC: '', BigA1: '', BigAC: '', BigA2: '',
      BigA3: '', BigAD: '', BigAE: '', Big5D: '', BigPA1: '', BigPA2: '', BigPA3: '', BigPC: '', BigPD: '', BigPE: '',
      Big6D: '', BigCA2: '', BigCA3: '', BigCD: '', BigCE: '', BigBandS: '' })
  }

  async calculateTotal(ReportDetails) {
    const tempObject = {
      Total: 0,
      'B+S': 0,
      B: 0,
      S: 0,
      CA: 0,
      CD: 0,
      SA: 0,
      SD: 0,
      SB: 0,
      SC: 0,
      SE: 0,
      SF: 0,
      '5D': 0,
      '6D': 0,
      PA: 0,
      PB: 0,
      PC: 0,
      PD: 0,
      PE: 0,
      PF: 0,
      CB: 0,
      CC: 0,
      CE: 0,
      CF: 0,
    }
    Object.values(ReportDetails).forEach((item) => {
      tempObject.Total += Number(item.Total)
      tempObject['B+S'] += Number(item['B+S'])
      tempObject.B += Number(item.B)
      tempObject.S += Number(item.S)
      tempObject.CA += Number(item.CA)
      tempObject.CD += Number(item.CD)
      tempObject.SA += Number(item.SA)
      tempObject.SD += Number(item.SD)
      tempObject.SB += Number(item.SB)
      tempObject.SC += Number(item.SC)
      tempObject.SE += Number(item.SE)
      tempObject.SF += Number(item.SF)
      tempObject['5D'] += Number(item['5D'])
      tempObject['6D'] += Number(item['6D'])
      tempObject.PA += Number(item.PA)
      tempObject.PB += Number(item.PB)
      tempObject.PC += Number(item.PC)
      tempObject.PD += Number(item.PD)
      tempObject.PE += Number(item.PE)
      tempObject.PF += Number(item.PF)
      tempObject.CB += Number(item.CB)
      tempObject.CC += Number(item.CC)
      tempObject.CE += Number(item.CE)
      tempObject.CF += Number(item.CF)
    })
    this.setState({ AllTotalAmount:tempObject })
  }

  TotalResult(TotalObj, Type) {
    let TotalSale = {
      Sale: 0,
    }
    Object.values(TotalObj).forEach((item) => {
      TotalSale.Sale += Number(item[Type])
    })
    return TotalSale.Sale.toFixed(2)
  }

  async ChangeUpperline(ReportUserID){
    this.setState({ReportUserID}, ()=>{
      this.getReport();
    })
  }

  DisplayOptions(e) {
    this.setState({ DisplayOption: e.target.value}, ()=> {
      if(this.state.DisplayOption === 'ShowSome') {
        this.getReport();
      } else {
        const ReportAll = this.state.ReportAll
        // var filterDownlineDetails = ReportAll.filter(function (values) {
        //   return values.Self === false
        // })
        this.setState({ ReportDetails: ReportAll })
      }
    })
  }

  async BackUpline(ReportUserID){
    if (ReportUserID !== "null") {
      this.setState({ReportUserID, SearchUsername: ''}, ()=>{
        this.getReport();
      })
    }
  }

  async BackToAll() {
    this.setState({ReportUserID: this.props.userID}, ()=>{
      this.getReport();
    })
  }

  async setSelectedDate(SelectedDate) {
    this.setState({ SelectedDate }, ()=>{
      this.props.setDate(SelectedDate)
      this.getReport();
    })
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: `${language[this.props.currentLanguage][message]}` });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 5000);
  };

  async handleSearchUsername(e) {
    this.setState({ SearchUsername: e.target.value })
  }

  SearchUser(e) {
    const SearchUsername = this.state.SearchUsername
    const downline = this.state.downline
    if(e.key === 'Enter') {
      if(SearchUsername === '') {
        this.setState({ SearchUsername: '' }, ()=> {
          this.getReport();
        })
      } else {
        var filterdownline = downline.filter(function (item) {
          if(item.Username === SearchUsername) {
            return item.Username === SearchUsername
          } else {
            return ''
          }
        });
        if(filterdownline.length === 0) {
          this.openNotification('NoResult')
          this.setState({ notificationType: 'error', SearchUsername: '' });
        } else {
          this.setState({ ReportUserID: filterdownline[0].ID }, ()=> {
            this.getReport();
          })
        }
      }
    }
  }

  BigToSmall(items) {
    const ReportDetails = this.state.ReportDetails
    if(items === 'B+S') {
      if(this.state.BigBandS === false || this.state.BigBandS === '') {
        ReportDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
        this.setState({ BigBandS: true })
      } else {
        ReportDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
        this.setState({ BigBandS: false })
      }
    } else if(items === 'LastBettingDate') {
      if(this.state[`Big${items}`] === false || this.state[`Big${items}`] === '') {
        ReportDetails.sort((a, b) => (Moment(a[items]).format('YYYYMMDD') > Moment(b[items]).format('YYYYMMDD')) ? -1 : 1)
        this.setState({ [`Big${items}`]: true })
      } else {
        ReportDetails.sort((a, b) => (Moment(a[items]).format('YYYYMMDD') > Moment(b[items]).format('YYYYMMDD')) ? 1 : -1)
        this.setState({ [`Big${items}`]: false })
      }
    } else {
      if(this.state[`Big${items}`] === false || this.state[`Big${items}`] === '') {
        ReportDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? -1 : 1)
        this.setState({ [`Big${items}`]: true })
      } else {
        ReportDetails.sort((a, b) => (Number(a[items]) > Number(b[items])) ? 1 : -1)
        this.setState({ [`Big${items}`]: false })
      }
    }
    this.setState({ ReportDetails })
  }

  CheckedType(Selected, ind) {
    let SelectedType = this.state.SelectedType
    for (var i=0; i<SelectedType.length; i++) {
      SelectedType[i].check = false
    }
    SelectedType[ind].check = true

    this.setState({ SelectedType, ViewType: Selected.Name }, () => {
      this.getReport();
    })
  }

  render() {
    return (
      <div onScroll={(e) => this.handleScroll(e)} style={{ width: '100%', height: '100vh', flexDirection: 'column', overflowX: 'auto' }}> 
        <div id={'Top'}>
          <Header2 />
        </div>
        <div className={`dcc mobileHeader`}
          style={{
            marginTop: 0,
            paddingBottom: 0,
            justifyContent: 'space-between',
            marginLeft: '3.5%',
            flexDirection: 'row',
            width: '95%'
          }}>
          <div className='dcc' style={{ justifyContent: 'center', display: 'flex' }} >
            <div style={{fontSize: "17px", width: 200, fontWeight: 'bold'}}>{language[this.props.currentLanguage].LastBetDate} :</div>
            <DatePicker
              onChange = {(Date) => this.setSelectedDate(Date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              // maxDate={new Date()}
              // popperPlacement="top-right"
              selected={this.state.SelectedDate}
              className={"form-control StyleDatePicker"}
              // withPortal
            />
          </div>
          <div className="dcc" style={{ marginRight: 20 }}>
            <div className="TitleUsername"> ID - {this.props.username} </div>
          </div>
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '18px', marginTop:  0}}>{language[this.props.currentLanguage].AccountSales}</div>
        <div style={{ marginBottom: 10, marginTop: 10, marginLeft: '2.2%', display: 'flex', flexDirection: 'row'}} >
          <div style={{ display: 'flex', width: '26%'}}> 
            <div style={{fontSize: "16px", width: '50%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Mode} :</div>
            <Form.Control
              as="select"
              type="text"
              value={this.state.ModeType}
              onChange={(e) => this.setState({ ModeType: (this.state.ModeType === 'Details') ? 'Simple' : 'Details'}, ()=> { this.getReport(); })}
              placeholder={'Amount'}
              style={{ fontWeight: 'bold', width: '100%', textAlign: 'center' }}
            >
              <option value="Detail">{language[this.props.currentLanguage].Details}</option>
              <option value="Simple">{language[this.props.currentLanguage].Simple}</option>
            </Form.Control>
          </div>
          <div className={`dcc`} style={{ display: 'flex', width: '26%'}}>
            <div style={{fontSize: "16px", width: 'auto', fontWeight: 'bold', marginLeft: 5, marginRight: '10%' }}>{language[this.props.currentLanguage].Search} :</div>
            <Form.Control
              value={this.state.SearchUsername}
              className='HitSalesSEarchInput'
              placeholder={'Username'}
              type="text"
              onChange={(e) => this.handleSearchUsername(e)}
              onKeyPress={(e) => this.SearchUser(e, 'SearchUsername')}
            />
          </div>
        </div>
        {/* {(isMobile) && 
        <div style={{ marginBottom: 10, marginTop: 10,marginLeft: (isMobile) ? 0 : '2.2%', display: 'flex', flexDirection: 'row'}}>
          <div style={{ display: 'flex', width:  (isMobile) ? '80%' :'26%'}}> 
            <div style={{fontSize: "18px", width: '50%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].Search} :</div>
            <Form.Control
              value={this.state.SearchUsername}
              style={{ fontWeight: 'bold', width: (isMobile) ? '70%' :'100%', textAlign: 'center' }}
              placeholder={'Username'}
              type="text"
              onChange={(e) => this.handleSearchUsername(e)}
              onKeyPress={(e) => this.SearchUser(e, 'SearchUsername')}
            />
          </div>
        </div>
        } */}
        <div style={{ marginBottom: 10, marginTop: 10,marginLeft: '2.2%', display: 'flex', flexDirection: 'row'}}>
          <div style={{ display: 'flex', width: '26%'}}> 
            <div style={{fontSize: "16px", width: '50%', fontWeight: 'bold'}}>{language[this.props.currentLanguage].DisplayMode} :</div>
            <Form.Control
              as="select"
              type="text"
              value={this.state.DisplayOption}
              onChange={(e) => this.DisplayOptions(e)}
              placeholder={'Amount'}
              style={{ fontWeight: 'bold', width: '100%', textAlign: 'center' }}
            >
              <option value="ShowSome">{language[this.props.currentLanguage].HideZero}</option>
              <option value="ShowAll">{language[this.props.currentLanguage].ShowAll}</option>
            </Form.Control>
          </div>
        </div>
        <div style={{ marginBottom: 10, width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div style={{fontSize: "17px", width: 100,  textAlign: 'center', fontWeight: 'bold'}}>筛选</div>
          {this.state.SelectedType.map((items, idd) => {
            return(
              <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }} key={idd}>
                {/* <div>{items.Name}</div> */}
                <Form.Check
                  style={{ fontSize: 17, alignItems: 'center' }}
                  inline
                  label={items.Name}
                  type="checkbox"
                  checked={items.check}
                  onChange={(e) => this.CheckedType(items, idd)}
                />
              </div>
            )
          })}
        </div>
        {this.state.SelfDetails.length !== 0 && this.state.SelfDetails[0].ID !== this.state.userIDOriginal && 
            <div style={{ textAlign: 'right', marginBottom: 5 }}>
              <Button style={{ fontWeight: 'bold', width: 'auto' }} onClick={() => this.BackUpline(this.state.SelfDetails[0].Upperline)}>{language[this.props.currentLanguage].BackToUpline}</Button>
            </div>
        }
        <div className="dcc row">
          {this.state.loading  === true ? (
            <div className="divLoader">
              <img alt="" style={{ marginTop: 50, width:  200, height: 200, marginRight: 200}} src={'https://i.imgur.com/GLdqYB2.gif'}/>
            </div>
          ) : (
            <div style= {{ width: '100%'}}>
              <div className="dcc">
                <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 100, width: '70%', left: 250 }} show={this.state.showNotification}
                  variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
                  <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
                </Alert>
              </div>
              <div style={{ overflowY: 'auto', fontSize: '14px' }}>
                <Table responsive="sm" bordered hover>
                  <thead>
                    <tr>
                      <th style={{ minWidth: 80 }}>
                        {language[this.props.currentLanguage].usernameLanguage}</th>
                      <th style={{ minWidth: 80, wordBreak: 'break-word' }}>{language[this.props.currentLanguage].Company}</th>
                      <th style={{ minWidth: 150 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                          {language[this.props.currentLanguage].LastBetDate}
                          <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('LastBettingDate')}>
                            <img src={updown} alt="img" className='button2'
                              style={{
                                height: 15,
                                width: 15,
                              }}
                            />
                          </div>
                        </div>
                      </th>
                      <th style={{ minWidth: 100 }}><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        {language[this.props.currentLanguage].Total}
                        <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('Total')}>
                          <img src={updown} alt="img" className='button2'
                            style={{
                              height: 15,
                              width: 15,
                            }}
                          />
                        </div>
                      </div>
                      </th>
                      {(this.state.ModeType !== 'Simple') && <th>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                          {'B+S'}
                          <div style={{ cursor: 'pointer' }} onClick={() => this.BigToSmall('B+S')}>
                            <img src={updown} alt="img" className='button2'
                              style={{
                                height: 15,
                                width: 15,
                              }}
                            />
                          </div>
                        </div>
                      </th>}
                      {(this.state.ModeType !== 'Simple') && 
                    ['B','S','CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF'].map((items, idn) => {
                      return (
                        <th key={idn}><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                          <div style={{ paddingRight: 5 }}>{items}</div>
                          <div style={{ cursor: 'pointer'}} onClick={() => this.BigToSmall(items)}>
                            <img src={updown} alt="img" className='button2'
                              style={{
                                height: 15,
                                width: 15,
                              }}
                            />
                          </div></div></th>
                      )
                    })}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.SelfDetails.map((Selfitems, ind) => {
                      return(
                        <tr key={ind} style={{ backgroundColor: '#e4f2f7'}}>
                          <td style={{ minWidth: 80, wordBreak: 'break-word' }}>
                            {Selfitems.Username}
                          </td>
                          <td style={{ minWidth: 80, wordBreak: 'break-word' }}><div style={{ cursor: 'not-allowed' }}>{Selfitems.Name}</div></td>
                          <td><div style={{ cursor: 'not-allowed' }}>{(Selfitems.LastBettingDate === null) ? '-' : Moment(Selfitems.LastBettingDate).format('YYYY-MM-DD')}</div></td>
                          <td>
                            {/* <div><div>{item.Total.toFixed(2)}</div></div> */}
                            <Link 
                              to={`/DetailPersonaSales?id=${Selfitems.ID}&Username=${Selfitems.Username}`}
                              target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                              <div style={{ color: 'blue' }}>{(this.state.ViewType === 'SGD') ? `${Selfitems.Total} (${Selfitems.sgdTotal})` : (this.state.ViewType === 'THAI') ? `${Selfitems.Total} (${Selfitems.thaiTotal})` : Selfitems.Total}</div>
                            </Link>
                          </td>
                          {(this.state.ModeType !== 'Simple') &&
                          <td><div>
                            <div style={{ cursor: 'not-allowed' }}>{(this.state.ViewType === 'SGD') ? `${Selfitems['B+S']} (${Selfitems['sgdB+S']})` : (this.state.ViewType === 'THAI') ? `${Selfitems['B+S']} (${Selfitems['thaiB+S']})` :  Selfitems['B+S']|| '0'}</div>
                          </div></td>}
                          {(this.state.ModeType !== 'Simple') && 
                          ['B','S','CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF'].map((type, idx) => {
                            return (
                              <td key={idx}>
                                <div style={{ cursor: 'not-allowed' }}>{(this.state.ViewType === 'SGD') ? `${Selfitems[type]} (${Selfitems[`sgd${type}`]})` : (this.state.ViewType === 'THAI') ? `${Selfitems[type]} (${Selfitems[`thai${type}`]})` : Selfitems[type] || ''}</div></td>
                            )
                          })
                          }
                        </tr>
                      )
                    })}
                    {this.state.ReportDetails.length > 0 &&
                           <tr><td colSpan="30" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '18px', color: 'white'}}>{language[this.props.currentLanguage].Downline}</td></tr>
                    }
                    {this.state.ShowTable === true ? (
                      Object.values(this.state.ReportDetails.filter((item) => item.ID === `${this.state.ReportUserID}-SELF`) || {}).map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td style={{ minWidth: 80, wordBreak: 'break-word' }}>
                              {item.Username}
                            </td>
                            <td style={{ minWidth: 80, wordBreak: 'break-word' }}><div style={{ cursor: 'not-allowed' }}>{item.Name}</div></td>
                            <td><div style={{ cursor: 'not-allowed' }}>{(item.LastBettingDate === null) ? '-' : Moment(item.LastBettingDate).format('YYYY-MM-DD')}</div></td>
                            <td>
                              {/* <div><div>{item.Total.toFixed(2)}</div></div> */}
                              <Link 
                                to={`/DetailPersonaSales?id=${item.ID}&Username=${item.Username}`}
                                target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                <div style={{ color: 'blue' }}>{(this.state.ViewType === 'SGD') ? `${item.Total} (${item.sgdTotal})` : (this.state.ViewType === 'THAI') ? `${item.Total} (${item.thaiTotal})` : item.Total}</div>
                              </Link>
                            </td>
                            {(this.state.ModeType !== 'Simple') &&
                          <td><div>
                            <div style={{ cursor: 'not-allowed' }}>{(this.state.ViewType === 'SGD') ? `${item['B+S']} (${item['sgdB+S']})` : (this.state.ViewType === 'THAI') ? `${item['B+S']} (${item['thaiB+S']})` : item['B+S']|| '0'}</div>
                          </div></td>}
                            {(this.state.ModeType !== 'Simple') && 
                          ['B','S','CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF'].map((type, idx) => {
                            return (
                              <td key={idx}>
                                <div style={{ cursor: 'not-allowed' }}>{(this.state.ViewType === 'SGD') ?  `${item[type]} (${item[`sgd${type}`]})` : (this.state.ViewType === 'THAI') ?  `${item[type]} (${item[`thai${type}`]})` : item[type] || ''}</div></td>
                            )
                          })
                            }
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr><td colSpan="30" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '26px'}}>{language[this.props.currentLanguage].NoData}</td></tr>
                      </>
                    )}
                    {this.state.ShowTable === true ? (
                      Object.values(this.state.ReportDetails.filter((item) => item.ID !== `${this.state.ReportUserID}-SELF`) || {}).map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td><div>
                              <Button className='button2' style={{ width: 100, wordBreak: 'break-word'}} onClick={() => this.ChangeUpperline(item.ID)}>
                                {item.Username}
                              </Button></div>
                            </td>
                            <td style={{ minWidth: 80, wordBreak: 'break-word' }}><div style={{ cursor: 'not-allowed' }}>{item.Name}</div></td>
                            <td><div style={{ cursor: 'not-allowed' }}>{(item.LastBettingDate === null) ? '-' : Moment(item.LastBettingDate).format('YYYY-MM-DD')}</div></td>
                            <td>
                              {/* <div><div>{item.Total.toFixed(2)}</div></div> */}
                              <Link 
                                to={`/DetailPersonaSales?id=${item.ID}&Username=${item.Username}`}
                                target="_blank" rel="noopener noreferrer" style={{ color: 'black'}}>
                                <div style={{ color: 'blue' }}>{(this.state.ViewType === 'SGD') ? `${item.Total} (${item.sgdTotal})` : (this.state.ViewType === 'THAI') ? `${item.Total} (${item.thaiTotal})` : item.Total}</div>
                              </Link>
                            </td>
                            {(this.state.ModeType !== 'Simple') &&
                          <td><div>
                            <div style={{ cursor: 'not-allowed' }}>{(this.state.ViewType === 'SGD') ? `${item['B+S']} (${item['sgdB+S']})` : (this.state.ViewType === 'THAI') ? `${item['B+S']} (${item['thaiB+S']})` : item['B+S']|| '0'}</div>
                          </div></td>}
                            {(this.state.ModeType !== 'Simple') && 
                          ['B','S','CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF'].map((type, idx) => {
                            return (
                              <td key={idx}>
                                <div style={{ cursor: 'not-allowed' }}>{(this.state.ViewType === 'SGD') ?  `${item[type]} (${item[`sgd${type}`]})` : (this.state.ViewType === 'THAI') ?  `${item[type]} (${item[`thai${type}`]})` : item[type] || ''}</div></td>
                            )
                          })
                            }
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr><td colSpan="30" style={{ backgroundColor: 'gray', fontWeight: 'bold', fontSize: '26px'}}>{language[this.props.currentLanguage].NoData}</td></tr>
                      </>
                    )}
                    {this.state.ShowTable === true && 
                  <tr style={{ backgroundColor: '#DDDDDD' }}>
                    <td colSpan="3" style={{ fontWeight: 'bold', color: 'white', backgroundColor: 'black'}}>{language[this.props.currentLanguage].Total}</td>
                    {this.state.ModeType === 'Details' ? (
                      <>
                        {['Total', 'B+S', 'B', 'S', 'CA', 'CD', 'SA', 'SD', 'SB', 'SC', 'SE', 'SF', '5D', '6D', 'PA', 'PB', 'PC', 'PD', 'PE', 'PF', 'CB', 'CC', 'CE', 'CF'].map((totalvalues, idxx) => {
                          return(
                            <td key={idxx}>
                              <div style={{ cursor: 'not-allowed' }}>
                                {(this.state.ViewType === 'SGD') ? `${Number(this.state.AllTotalAmount[totalvalues].toFixed(2))} (${this.TotalResult(this.state.ReportDetails, `sgd${totalvalues}`)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.AllTotalAmount[totalvalues].toFixed(2))} (${this.TotalResult(this.state.ReportDetails, `thai${totalvalues}`)})` : Number(this.state.AllTotalAmount[totalvalues]).toFixed(2) || '0.00'}
                              </div>
                            </td>
                          )
                        })}
                      </>
                    ) : (
                      <td>
                        <div style={{ cursor: 'not-allowed' }}>{(this.state.ViewType === 'SGD') ? `${Number(this.state.AllTotalAmount['Total']).toFixed(2)} (${this.TotalResult(this.state.ReportDetails, `sgdTotal`)})` : (this.state.ViewType === 'THAI') ? `${Number(this.state.AllTotalAmount['Total']).toFixed(2)} (${this.TotalResult(this.state.ReportDetails, `thaiTotal`)})` : Number(this.state.AllTotalAmount['Total']).toFixed(2) || '0.00'}</div>
                      </td>
                    )}
                  </tr>
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
        {this.state.is_visible && 
          <div className="dcc" style={{ paddingBottom: 30 }}>
            <div className="scrollDesign iconpopup button2"  
              onClick={() => this.scrollToTop()}>
              {/* ↑ ⇈ */}
              <div  className="icon MoveUpArrow"> ⇧ </div>
            </div>
          </div>
        }
      </div>    
    )
  }
}

function mapStateToProps(state) {
  return {
    username: state.username,
    token: state.token,
    Role: state.Role,
    hide: state.hide,
    userID: state.userID,
    currentLanguage: state.currentLanguage,
    UserAuthority: state.UserAuthority,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setDate: (Date) => {
      dispatch({
        type: 'SELECTED_DATE',
        payload: Date,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
