import '../App.css';
import React from 'react';
import { language } from '../language';
import { connect } from 'react-redux'
import { Form, Button,  } from 'react-bootstrap';
// import { isMobile } from 'react-device-detect';
import { Platformcolor } from '../static/PlatformColor';
import { GamePlatformNumb1, GamePlatformNumb2 } from '../static';

class EditVIPPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      GamePlatform: ['M', 'K', 'T', 'S', 'W', 'B', 'D', '8', '9'],
      GamePlatform2: ['M', 'P', 'T', 'S', 'W', 'B', 'K', '8', '9'],
      GDisplay: '',
    }
  }  

  async componentDidMount() {
    const GDisplay = localStorage.getItem('GameDisplay');
    this.setState({GDisplay})
  }

  textAllChange(red, game, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = JSON.parse(JSON.stringify(this.props.editVIPData))
      const keys = Object.keys(gameObject.Limit.Default)
      for (let i = 0; i < keys.length; i += 1) {
        gameObject[red][game][keys[i]] = Number(value)
      }
      this.props.dataChange('editVIPData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  textChange(red, game, subfield, value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      const gameObject = JSON.parse(JSON.stringify(this.props.editVIPData))
      gameObject[red][game][subfield] = Number(value)
      this.props.dataChange('editVIPData', gameObject)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  textChangeLPX(value) {
    if (/^[0-9]+$/.test(value) || value === '') {
      this.props.dataChange('editLPX', value)
    } else {
      alert(`${language[this.props.currentLanguage].NumberOnly}`);
    }
  }

  gameChange(game) {
    const tempDetails = JSON.parse(JSON.stringify(this.props.editVIPData))
    let newGamesArray = Object.keys(tempDetails.Limit)
    // console.log(typeof game.toString())
    if (newGamesArray.includes(game.toString())) {
      delete tempDetails.Limit[game]
      delete tempDetails.RedLimit[game]
    } else {
      tempDetails.Limit[game] = tempDetails.Limit.Default
      tempDetails.RedLimit[game] = tempDetails.RedLimit.Default
    }
    this.props.dataChange('editVIPData', tempDetails)
  }

  // renderBackgroundColor(game) {
  //   if (game === 'Default') return 'lightsteelblue'
  //   else if (game === '1') return '#fff23e'
  //   else if (game === '2') return '#414bb2'
  //   else if (game === '3') return '#ff431b'
  //   else if (game === '4') return '#32c6f4'
  //   else if (game === '5') return 'green'
  //   else if (game === '6') return '#fc0404'
  //   else if (game === '7') return '#f8c100'
  //   else if (game === '8') return '#6d100e'
  //   else return null
  // }

  render() {
    return (
      <div>
        <div>
          <b style={{ fontSize: '20px' }}>{language[this.props.currentLanguage].EditVIP}</b>
        </div>
        <hr/>
        {(this.props.showType === 'All Limit' || this.props.showType === 'All Word' || this.props.showType === 'Group Limit') && <div>
          <div style={{width: 'calc(100vw - 655px)'}}>
            <div style={{  alignItems: 'center', width: '90%', marginLeft: 'auto'}}>
              <div className="TitleUsername" style={{ width: '50%',  fontSize: '20px'}}>{language[this.props.currentLanguage].FollowingGameSettingSameAsDefault}</div>
              <div style={{ display: 'flex'}}>
                {((this.state.GDisplay === 'MPTSWBK') ? this.state.GamePlatform2 : this.state.GamePlatform).map((item, index) => {
                  return <Form.Check
                    style={{ fontSize: 20, alignItems: 'center', display: 'flex' }}
                    inline
                    label={item}
                    type="checkbox"
                    checked={!this.props.editVIPData.Limit[index + 1]}
                    onChange={(e) => this.gameChange(index + 1)}
                  />
                })}
              </div>
            </div>
            {Object.keys(this.props.editVIPData.Limit).map((game) => {
              return <div style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: '1090px', paddingTop: 10, borderRadius: 10, color: (game === 'Default' || game === '1' || game === '2' || game === '3' || game === '4' || game === '6' || game === '7' ) ? '#000' : '#fff', backgroundColor: Platformcolor(game, '')}}>
                <div style={{ margin: '0px 10px', width: 70, fontSize: 20}}><b>{(this.state.GDisplay === 'MPTSWBK') ?  GamePlatformNumb2[game] : GamePlatformNumb1[game]}</b></div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                  <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                  <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editVIPData.Limit[game].all} onChange={(e) => this.textAllChange('Limit', game, e.target.value)}/>
                </div>
                {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF', '5D', '6D'].map((item) => {
                  return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                    <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editVIPData.Limit[game][item]} onChange={(e) => this.textChange('Limit', game, item, e.target.value)}/>
                  </div>
                })}
              </div>
            })}
            <div style={{ padding: 10, background: 'red', width: 1110}}>
              {Object.keys(this.props.editVIPData.Limit).map((game) => {
                return <div style={{ marginBottom: 5, display: 'flex', alignItems: 'center', width: '1090px', paddingTop: 10, borderRadius: 10, color: (game === 'Default' || game === '1' || game === '2' || game === '3' || game === '4' || game === '6' || game === '7' ) ? '#000' : '#fff', backgroundColor: Platformcolor(game, '')}}>
                  <div style={{ margin: '0px 10px', width: 70, fontSize: 20}}><b>{(this.state.GDisplay === 'MPTSWBK') ?  GamePlatformNumb2[game] : GamePlatformNumb1[game]}</b></div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                    <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{language[this.props.currentLanguage].Simplicity}</b></div>
                    <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editVIPData.RedLimit[game].all} onChange={(e) => this.textAllChange('RedLimit', game, e.target.value)}/>
                  </div>
                  {['B', 'S', 'SA', 'SB', 'SC', 'SD', 'SE', 'SF', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'PA', 'PB', 'PP', 'PD', 'PE', 'PF', '5D', '6D'].map((item) => {
                    return <div key={item} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5}}>
                      <div style={{ padding: 5, border: '1px solid #000', borderRadius: 5, width: '100%', marginBottom: 5}}><b>{item}</b></div>
                      <Form.Control style={{ width: 70, textAlign: 'center', marginBottom: 10}} maxLength='4' type="text" value={this.props.editVIPData.RedLimit[game][item]} onChange={(e) => this.textChange('RedLimit', game, item, e.target.value)}/>
                    </div>
                  })}
                </div>
              })}
            </div>
          </div>
          <hr/>
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button className='TextFontSize' style={{ marginLeft: 10, width:  null}} variant="danger" disabled={this.props.AddDisable } onClick={() => this.props.closeForm()} >{language[this.props.currentLanguage].Close}</Button>
            <Button className='TextFontSize' style={{ marginLeft: 10, width: null}} variant="success" disabled={this.props.AddDisable } onClick={() => this.props.updateVIPMinF()} >{language[this.props.currentLanguage].Edit}</Button>
          </div>
        </div>}
        {(this.props.showType === 'LPX') && <div>
          <div style={{width: 'calc(100vw - 655px)'}}>
            <div style={{ justifyContent: 'center', display: 'flex' }} >
              <div style={{fontSize: "18px", width: 100, fontWeight: 'bold'}}>{'乘率'} :</div>
              <Form.Control
                className="LoginInputText"
                type="text"
                value={this.props.editLPX}
                onChange={(e) => this.textChangeLPX(e.target.value)}
                style={{ fontWeight: 'bold', width: '100%', marginRight: null}}
              />
            </div>
          </div>
          <hr/>
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button className='TextFontSize' style={{ marginLeft: 10, width: null}} variant="danger" disabled={this.props.AddDisable } onClick={() => this.props.closeForm()} onChange={() => this.props.modeChange('Ban', 'selectedMode')}>
              {language[this.props.currentLanguage].Close}
            </Button>
            <Button className='TextFontSize' style={{ marginLeft: 10, width: null}} variant="success" disabled={this.props.AddDisable } onClick={() => this.props.updateVIPMinF()}>{language[this.props.currentLanguage].Add}</Button>
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    userID: state.userID,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(EditVIPPackage);