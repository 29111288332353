export function HolyWater(string) {
  // if (string.Key === 'ALL') {
  const Game = string.Fields
  const ArrayGame = string.Games
  // const KeyArrayGame = Object.keys(string.Commision);
  let FinalResultArrayGame = {};
  // let B_result = '';
  // let S_result = '';
  // let A1_result = '';  
  // let A1C_result = '';
  // let A_result = '';
  // let C_result = '';
  // let A2_result = '';
  // let A3_result = '';
  // let D4_result = '';
  // let E4_result = '';
  let result='';
  // KeyArrayGame.forEach((Game)=>{
  // const KeyArray = Object.keys(string.Commision[Game]);
  ArrayGame.forEach((KeyItem)=>{
    
    if ( Game.includes('B')){
      result = {...result,'B': (10000 - (string.Commision[KeyItem]['B'] * 100) - (string.Odd[KeyItem]['1ST']['B']) - 
    (string.Odd[KeyItem]['2ND']['B']) - 
    (string.Odd[KeyItem]['3RD']['B']) -
    (string.Odd[KeyItem]['4TH']['B'] * 10) -
    (string.Odd[KeyItem]['5TH']['B'] * 10) )}
    }  
    if ( Game.includes('S')){
      result = {...result, 'S':  10000 - (string.Commision[KeyItem]['S'] * 100) - (string.Odd[KeyItem]['1ST']['S']) - 
    (string.Odd[KeyItem]['2ND']['S']) - 
    (string.Odd[KeyItem]['3RD']['S'])}
    }
    if (Game.includes('SA')){
      result = {...result,'SA':  10000 - (string.Commision[KeyItem]['SA'] * 100) - (string.Odd[KeyItem]['1ST']['SA']) }
    }
    if (Game.includes('CA')){
      result = {...result,'CA':  1000 - (Number(string.Commision[KeyItem]['CA'] || 0) * 10) - (Number(string.Odd[KeyItem]['CA'] || 0))}
    }
    if (Game.includes('CF')){
      result = {...result,'CF':  1000 - ((Number(string.Commision[KeyItem]['CF'] || 0) * 10) + ((Number(string.Odd[KeyItem]['CF1'] || 0) + Number(string.Odd[KeyItem]['CF2'] || 0) + Number(string.Odd[KeyItem]['CF3'] || 0))))}
    }
    if (Game.includes('PA')){
      result = {...result,'PA':  100 - (Number(string.Commision[KeyItem]['PA'] || 0)) - (Number(string.Odd[KeyItem]['PA'] || 0))}
    }
    if (Game.includes('A1C')){
      result = {...result,'A1C':  10000 - (string.Commision[KeyItem]['A1C'] * 100) - (string.Odd[KeyItem]['1ST']['A1C'] * 3)}
    }
    if (Game.includes('A')){
      result = {...result,'A':  1000 - (string.Commision[KeyItem]['A'] * 10) - (string.Odd[KeyItem]['1ST']['A']) }
    }
    if (Game.includes('C')){
      result = {...result,'C': 1000 - (string.Commision[KeyItem]['C'] * 10) - (string.Odd[KeyItem]['1ST']['C'] * 3) }
    }
    if (Game.includes('A2')){
      result = {...result,'A2': 10000 - (string.Commision[KeyItem]['A2'] * 100) -  (string.Odd[KeyItem]['2ND']['A2']) }
    }
    if (Game.includes('A3')){
      result = {...result,'A3': 10000 - (string.Commision[KeyItem]['A3'] * 100) -  (string.Odd[KeyItem]['3RD']['A3']) }
    }
    if (Game.includes('D4')){
      result =  {...result,'D4': 10000 - (string.Commision[KeyItem]['D4'] * 100) -  (string.Odd[KeyItem]['4TH']['D4'] * 10)}
    }
    if (Game.includes('E4')){
      result = {...result,'E4': 10000 - (string.Commision[KeyItem]['E4'] * 100) -  (string.Odd[KeyItem]['5TH']['E4'] * 10)}
    }
    FinalResultArrayGame = { 
      ...FinalResultArrayGame,
      [`${KeyItem}`]: {
        ...result,
        // 'B': B_result || null,
        // 'S': S_result || null,
        // 'A1': A1_result || null,
        // 'A1C': A1C_result || null,
        // 'A': A_result || null,
        // 'C': C_result || null,
        // 'A2': A2_result || null,
        // 'A3': A3_result || null,
        // 'D4': D4_result || null,
        // 'E4': E4_result || null,
      // 'Amount': (B_result + S_result + A1_result + A1C_result + A_result + C_result + A2_result + A3_result + D4_result + E4_result)
      }
    }
    // })

  })
    
  return FinalResultArrayGame;
  // }

  // if (string.Key === 'ALL') {
  //   const KeyArrayGame = Object.keys(string.Commision);
  //   let FinalResultArrayGame = {};
  //   let B_result = '';
  //   let S_result = '';
  //   let A1_result = '';  
  //   let A1C_result = '';
  //   let A_result = '';
  //   let C_result = '';
  //   let A2_result = '';
  //   let A3_result = '';
  //   let D4_result = '';
  //   let E4_result = '';
  //   KeyArrayGame.forEach((Game)=>{
  //     const KeyArray = Object.keys(string.Commision[Game]);
  //     KeyArray.forEach((KeyItem)=>{

  //       if (KeyItem === 'B'){
  //         B_result =  10000 - (string.Commision[Game][KeyItem] * 10) - (string.Odd[Game]['1ST'][KeyItem]) - 
  //   (string.Odd[Game]['2ND'][KeyItem]) - 
  //   (string.Odd[Game]['3RD'][KeyItem]) -
  //   (string.Odd[Game]['4TH'][KeyItem] * 10) -
  //   (string.Odd[Game]['5TH'][KeyItem] * 10) 
  //       }  
  //       if (KeyItem === 'S'){
  //         S_result =  10000 - (string.Commision[Game][KeyItem] * 10) - (string.Odd[Game]['1ST'][KeyItem]) - 
  //   (string.Odd[Game]['2ND'][KeyItem]) - 
  //   (string.Odd[Game]['3RD'][KeyItem])
  //       }
  //       if (KeyItem === 'A1'){
  //         A1_result =  10000 - (string.Commision[Game][KeyItem] * 10) - (string.Odd[Game]['1ST'][KeyItem]) 
  //       }
  //       if (KeyItem === 'A1C'){
  //         A1C_result =  10000 - (string.Commision[Game][KeyItem] * 10) - (string.Odd[Game]['1ST'][KeyItem] * 3)
  //       }
  //       if (KeyItem === 'A'){
  //         A_result =  1000 - (string.Commision[Game][KeyItem] * 10) - (string.Odd[Game]['1ST'][KeyItem]) 
  //       }
  //       if (KeyItem === 'C'){
  //         C_result =  1000 - (string.Commision[Game][KeyItem] * 10) - (string.Odd[Game]['1ST'][KeyItem] * 3) 
  //       }
  //       if (KeyItem === 'A2'){
  //         A2_result =  10000 - (string.Commision[Game][KeyItem] * 10) -  (string.Odd[Game]['2ND'][KeyItem])
  //       }
  //       if (KeyItem === 'A3'){
  //         A3_result =  10000 - (string.Commision[Game][KeyItem] * 10) -  (string.Odd[Game]['3RD'][KeyItem])
  //       }
  //       if (KeyItem === 'D4'){
  //         D4_result =  10000 - (string.Commision[Game][KeyItem] * 10) -  (string.Odd[Game]['4TH'][KeyItem] * 10)
  //       }
  //       if (KeyItem === 'E4'){
  //         E4_result =  10000 - (string.Commision[Game][KeyItem] * 10) -  (string.Odd[Game]['5TH'][KeyItem] * 10)
  //       }
  //       FinalResultArrayGame = { 
  //         ...FinalResultArrayGame,
  //         [`${Game}`]: {
  //           'B': B_result,
  //           'S': S_result,
  //           'A1': A1_result,
  //           'A1C': A1C_result,
  //           'A': A_result,
  //           'C': C_result,
  //           'A2': A2_result,
  //           'A3': A3_result,
  //           'D4': D4_result,
  //           'E4': E4_result,
  //           'Amount': (B_result + S_result + A1_result + A1C_result + A_result + C_result + A2_result + A3_result + D4_result + E4_result)
  //         }
  //       }
  //     })

  //   })
    
  //   return FinalResultArrayGame;
  // }
}